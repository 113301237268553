import { useMemo } from 'react';
import {
  ViewOnContract,
  ListContractsApiArg,
  ListContractsApiResponse,
  useLazyListContractsQuery,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useFetchPaginated } from '@vertice/core/src/hooks/useFetchPaginated';
import { FilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import {
  ContractValue,
  Filter,
  FilterValues,
  RenewalStatus,
} from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/types';
import {
  evaluateFilterView,
  generateFilterViewsWithCount,
} from '@vertice/core/src/modules/saas/contract/components/ContractList/functions';
import useFilterContract from './Filters/hooks/useFilterContract';
import useContractValueFilter from './Filters/hooks/useContractValueFilter';

export type useContractsProps = {
  filterViews?: FilterView[];
  activeFilterView?: FilterView;
  filterValues?: FilterValues;
  activeFilters?: Filter[];
  search?: string;
  renewalStates?: RenewalStatus[];
  departments?: string[];
  owners?: string[];
  contractValues?: ContractValue[];
};

export const useContracts = ({ filterViews, activeFilterView, filterValues, activeFilters }: useContractsProps) => {
  const { accountId } = useAccountContext();
  const { filterContractValue } = useContractValueFilter();

  const [getContracts] = useLazyListContractsQuery();
  const { items: viewsOnContracts, isLoading: isLoadingViewsOnContracts } = useFetchPaginated<
    ListContractsApiResponse,
    ListContractsApiArg,
    ViewOnContract
  >({
    fetchFn: getContracts,
    getNextTokenFn: (data) => data.nextToken,
    getItemsFn: (data) => {
      return data.contracts;
    },
    fetchArgs: {
      accountId,
      maxResults: 1000,
    },
  });

  const { filterContract, isLoading: isFilterLoading } = useFilterContract({
    activeFilters,
    filterValues,
  });

  return useMemo(() => {
    if (isLoadingViewsOnContracts || viewsOnContracts === undefined || isFilterLoading) {
      return { viewsOnContracts: [], allViewsOnContracts: [], filterViewsWithCounts: [], isLoading: true };
    }
    // filters like department, name, renewal status and owner are applied first
    const prefilteredViewsOnContracts = viewsOnContracts.filter(filterContract);

    return {
      viewsOnContracts: prefilteredViewsOnContracts.filter((viewOnContract) =>
        activeFilterView ? evaluateFilterView(activeFilterView, viewOnContract, { filterContractValue }) : true
      ),
      allViewsOnContracts: viewsOnContracts,
      filterViewsWithCounts: generateFilterViewsWithCount(prefilteredViewsOnContracts, filterViews || [], {
        filterContractValue,
      }),
      activeFilterView,
      isLoading: false,
    };
  }, [
    isLoadingViewsOnContracts,
    viewsOnContracts,
    isFilterLoading,
    filterContract,
    filterViews,
    activeFilterView,
    filterContractValue,
  ]);
};
