import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isTerminatedContract } from '@vertice/core/src/modules/saas/contract/computed';
import { DefaultHeader } from './DefaultHeader';
import { getNextRollDate } from '@vertice/core/src/modules/saas/contract/components/NextRollDateComputedField/NextRollDateComputedField';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';

type RollingHeaderProps = {
  contract: Contract;
};

export const RollingHeader = ({ contract }: RollingHeaderProps) => {
  const { t } = useTranslation();
  const isTerminated = isTerminatedContract(contract);
  const startDate = contract.parts.contractual?.lifecycle?.startDate;
  const rollFrequency = contract.parts.contractual?.lifecycle?.rollFrequency;
  const endDate = contract.parts.contractual?.lifecycle?.endDate;
  const nextRollDate = getNextRollDate(
    keepOnlyLocalDatePart(startDate) ?? undefined,
    rollFrequency ?? undefined
  )?.toISOString();

  return (
    <DefaultHeader
      title={isTerminated ? t('ENTITIES.CONTRACT.LABELS.END_DATE') : t('ENTITIES.CONTRACT.LABELS.NEXT_ROLL_DATE')}
      date={isTerminated ? endDate : nextRollDate}
    />
  );
};
