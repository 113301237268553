import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { Card, CardHeaderTitle, CardHeader, Divider, Text } from '@verticeone/design-system';
import { ContractContextDataRequireFetched } from '@vertice/core/src/modules/saas/contract/types';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useContractRenewalRequests } from '../useContractRenewalRequests';
import { useLinkedContracts } from './useLinkedContracts';
import { ContractPageCard } from './components/ContractPageCard';
import {
  isGenericRenewalServiceRef,
  isSaasRenewalServiceRef,
  parseRequestRefSafe,
} from '../../../../../../hooks/workflows/refUtils';
import { useRequestThatCreatedContract } from './useRequestThatCreatedContract';
import { useContractContext } from '../../../ContractContext';
import { RequestCardWithTitle } from './components/RequestCardWithTitle';

const NotEmptyStack = styled(Stack)({
  '&:empty': {
    display: 'none',
  },
});

type LinkedPagesCardProps = {
  contracts: ViewOnContract[];
  requests: Request[];
  isContractListDisplayed?: boolean;
};

const getRenewalRequest = (requests: Request[]): Request | undefined => {
  return (
    requests
      .filter((req) => isSaasRenewalServiceRef(req.serviceRef) || isGenericRenewalServiceRef(req.serviceRef))
      // Check whether the request is a renewal request
      .filter((req) => req.status === 'ACTIVE' || req.status === 'COMPLETED')
      // Check whether the request is active or completed
      .filter(
        (req, _, arr) => (arr.length > 1 ? 'result' in req && req.result && 'createdContractId' in req.result : true)
        // If there are multiple renewal requests, check whether the request has a result and a createdContractId
      )[0]
  );
  // Return the first renewal request that matches the criteria,
  // since we shouldnt have multiple renewal requests that are active or completed
};

const LinkedPagesCard: FC<LinkedPagesCardProps> = ({ contracts, requests, isContractListDisplayed = true }) => {
  const { t } = useTranslation();
  const { fetchedContract } = useContractContext('DONT_REQUIRE_FETCHED');

  const createdByRequest = useRequestThatCreatedContract(fetchedContract?.contract);
  const filteredRequests = [...requests]?.filter((req) =>
    createdByRequest
      ? parseRequestRefSafe(req.ref).requestId !== parseRequestRefSafe(createdByRequest.ref).requestId
      : true
  );

  const renewalRequest = getRenewalRequest(filteredRequests);

  const hasRenderableRequests = !!filteredRequests.length || !!createdByRequest;
  const hasRenderableContracts = !!contracts.length || isContractListDisplayed;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('SAAS.LINKED_PAGES_CARD.TITLE')} />
      </CardHeader>
      <Stack divider={isContractListDisplayed && hasRenderableRequests ? <Divider /> : undefined}>
        <NotEmptyStack gap={2} padding={6}>
          {createdByRequest && <RequestCardWithTitle request={createdByRequest} variant="createdBy" />}
          {filteredRequests?.map((request) => (
            <RequestCardWithTitle
              key={request.ref}
              request={request}
              variant={
                parseRequestRefSafe(renewalRequest?.ref ?? '').requestId === parseRequestRefSafe(request.ref).requestId
                  ? 'renewedBy'
                  : 'default'
              }
            />
          ))}
        </NotEmptyStack>
        {isContractListDisplayed && (
          <NotEmptyStack gap={2} padding={6}>
            {hasRenderableContracts && hasRenderableRequests && (
              <Text variant="body-bold" size="M" color="text1">
                {t('SAAS.LINKED_PAGES_CARD.CONTRACTS')}
              </Text>
            )}
            {fetchedContract?.contract && <ContractPageCard isCurrentContract contract={fetchedContract.contract} />}
            {contracts?.map(({ contract: lineageContract }) => (
              <ContractPageCard contract={lineageContract} key={lineageContract.record.contractId} />
            ))}
          </NotEmptyStack>
        )}
      </Stack>
    </Card>
  );
};

export const useLinkedPagesCard = ({
  contractContext,
  skipContracts = false,
}: {
  contractContext: ContractContextDataRequireFetched;
  skipContracts?: boolean;
}) => {
  const {
    fetchedContract,
    editMode: { isEditing },
  } = contractContext;

  const { isFeatureEnabled } = useAccountContext();
  const { linkedRequests, isLoadingLinkedRequests } = useContractRenewalRequests(fetchedContract.contract);
  const { linkedContracts, isLoadingLinkedContracts } = useLinkedContracts({
    contract: fetchedContract.contract,
    skip: skipContracts,
  });
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
  const isCreatedByRequest = useRequestThatCreatedContract(fetchedContract.contract);

  const isLoading = isLoadingLinkedRequests || isLoadingLinkedContracts;
  const isEmpty = !linkedRequests.length && !linkedContracts.length && !isCreatedByRequest;

  const shouldDisplayCurrentContract = !linkedRequests.length && !linkedContracts.length;

  return useMemo(
    () =>
      !isLoading && !isEmpty && !isEditing && isIWEnabled
        ? () => (
            <LinkedPagesCard
              isContractListDisplayed={!skipContracts || shouldDisplayCurrentContract}
              contracts={linkedContracts}
              requests={linkedRequests}
            />
          )
        : null,
    [
      isLoading,
      isEmpty,
      isEditing,
      isIWEnabled,
      linkedContracts,
      linkedRequests,
      skipContracts,
      shouldDisplayCurrentContract,
    ]
  );
};
