import { DeepPartial } from 'react-hook-form';
import { ContractDatesForm } from './types';
import { TaskContractReviewInput } from '../../types';
import { P, match } from 'ts-pattern';
import { isRollingRollFrequency } from '../../../../../../saas/contract/computed';

export const getContractDatesDefaultValues = (input?: TaskContractReviewInput): DeepPartial<ContractDatesForm> => {
  if (!input) return getFixedDefaultValues();

  return match(input)
    .with({ rollingFrequency: 'NO' }, (values) => getFixedDefaultValues(values))
    .with({ rollingFrequency: 'ONE_OFF' }, (values) => getOneOffDefaultValues(values))
    .with({ rollingFrequency: P.string }, (values) => getRollingDefaultValues(values))
    .otherwise((values) => getFixedDefaultValues(values));
};

const getFixedDefaultValues = (input?: TaskContractReviewInput): DeepPartial<ContractDatesForm> => ({
  contractType: 'FIXED',
  startDate: input?.startDate,
  signDate: input?.signingDate,
  renewalDate: input?.renewalDate,
  autoRenewalDate: input?.autoRenewalDeadline,
});

const getRollingDefaultValues = (input: TaskContractReviewInput): DeepPartial<ContractDatesForm> => {
  const { rollingFrequency } = input;
  const rollFreq = rollingFrequency && isRollingRollFrequency(rollingFrequency) ? rollingFrequency : undefined;

  return {
    contractType: 'ROLLING',
    startDate: input?.startDate,
    signDate: input?.signingDate,
    rollingFrequency: rollFreq,
  };
};

const getOneOffDefaultValues = (input: TaskContractReviewInput): DeepPartial<ContractDatesForm> => ({
  contractType: 'ONE_OFF',
  startDate: input?.startDate,
  signDate: input?.signingDate,
});
