import { DeepPartial } from 'react-hook-form';
import { ContractReviewForm } from '../../../types';
import { SummaryRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { useFormatDate } from '@verticeone/utils/formatting';
import { match } from 'ts-pattern';
import useBillingFrequencyOptions from '../../../../../../../saas/contract/hooks/useBillingFrequencyOptions';
import useRollingFrequencyOptions from '../../../../../../../saas/contract/hooks/useRollingFrequencyOptions';

type UseLifecycleSummaryRecordsProps = {
  values: DeepPartial<ContractReviewForm>;
};

export const useLifecycleSummaryRecords = (props: UseLifecycleSummaryRecordsProps) => {
  const { values } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.LABELS' });
  const { t: tGlobal } = useTranslation();
  const formatDate = useFormatDate();

  const { getLabel: getBillingFreqLabel } = useBillingFrequencyOptions();
  const { getLabel: getRollingFreqLabel } = useRollingFrequencyOptions({ isOneOffIncluded: false });

  if (!values.contractDates) return { records: [] };

  const { signDate, startDate } = values.contractDates;

  let records: SummaryRecord[] = [
    { label: t('SIGN_DATE'), value: formatDate(signDate ?? undefined) ?? '-' },
    { label: t('START_DATE'), value: formatDate(startDate ?? undefined) ?? '-' },
  ];

  const additionalFields = match(values.contractDates)
    .returnType<SummaryRecord[]>()
    .with({ contractType: 'FIXED' }, ({ contractLength, renewalDate, autoRenewalDate, billingFrequency }) => [
      {
        label: t('CONTRACT_LENGTH'),
        value: contractLength ? `${contractLength} ${tGlobal('UNITS.MONTH', { count: contractLength })}` : '-',
      },
      {
        label: t('RENEWAL_DATE'),
        value: formatDate(renewalDate ?? undefined) ?? '-',
      },
      {
        label: t('AUTO_RENEWAL_DATE'),
        value: formatDate(autoRenewalDate ?? undefined) ?? '-',
      },
      {
        label: t('BILLING_FREQUENCY'),
        value: getBillingFreqLabel(billingFrequency) ?? '-',
      },
    ])
    .with({ contractType: 'ROLLING' }, ({ billingFrequency, rollingFrequency }) => [
      {
        label: t('ROLLING_FREQUENCY'),
        value: getRollingFreqLabel(rollingFrequency) ?? '-',
      },
      {
        label: t('BILLING_FREQUENCY'),
        value: getBillingFreqLabel(billingFrequency) ?? '-',
      },
    ])
    .with({ contractType: 'ONE_OFF' }, () => [])
    .exhaustive();

  records.push(...additionalFields);

  return { records };
};
