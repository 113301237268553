import React, { ForwardedRef, forwardRef } from 'react';
import { testProps, Text } from '@verticeone/design-system';
import { Stack, styled } from '@mui/material';
import StatCardIcon from './StatCardIcon';
import { useStatCardColorByVariant } from './utils';
import DashboardCard from '../../../DashboardCard';
import { StatCardProps } from './types';

type BluredCornerProps = {
  $bgColor: string;
};

const BluredCorner = styled('div')<BluredCornerProps>(({ $bgColor }) => ({
  position: 'absolute',
  top: '-40%',
  left: '75%',
  backgroundColor: $bgColor,
  opacity: 0.2,
  filter: 'blur(50px)',
  width: '75%',
  height: '75%',
}));

const BaseStatCard = forwardRef(
  (
    {
      children,
      testId,
      overflow = 'hidden',
      title,
      subtitle,
      value,
      name,
      icon,
      variant = 'plain',
      onClick,
      ...props
    }: StatCardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { bgColor, shadowColor } = useStatCardColorByVariant(variant);

    return (
      <DashboardCard
        {...testProps(testId, 'stat-card')}
        {...props}
        ref={ref}
        overflow={overflow}
        height={1}
        minHeight={145}
        onClick={onClick}
        $isClickable={!!onClick}
        $shadowColor={shadowColor}
      >
        {variant !== 'plain' && <BluredCorner $bgColor={bgColor} />}
        <Stack p={6} gap={4} height={1} justifyContent="space-between">
          <Stack direction="row" gap={4} justifyContent="space-between">
            <Stack direction="column" gap={1} flex={1}>
              <Text variant="heading" size="XS" color="text1">
                {title}
              </Text>
              {subtitle && (
                <Text variant="caption" size="XS" color="text3">
                  {subtitle}
                </Text>
              )}
            </Stack>
            {icon && <StatCardIcon icon={icon} variant={variant} />}
          </Stack>
          <Stack direction="row" alignItems="baseline" gap={1}>
            <Text variant="heading" size="M" color="text1">
              {value}
            </Text>
            {name && (
              <Text variant="caption" size="S" color="text2" flex={1}>
                {name}
              </Text>
            )}
          </Stack>
        </Stack>
      </DashboardCard>
    );
  }
);

export default BaseStatCard;
