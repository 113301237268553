import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Placeholder, Text } from '@verticeone/design-system';
import { JiraIcon } from '../../../../../integrations/Jira/icons';
import { SlackIconWOBG } from '../../../../../integrations/slack/icons';
import { parseApiRequest, parseJiraRequest, parseSlackRequest } from '../../../utils/requestorUtils';
import { CreatedByDetail } from './CreatedByDetail';

type CreatedByContentProps = {
  isCreatedBySystem: boolean;
  requestorRef?: string;
  sourceRef?: string;
  isLoadingUsers: boolean;
  userFullName: string;
};

export const CreatedByContent: FC<CreatedByContentProps> = ({
  isCreatedBySystem,
  requestorRef,
  sourceRef,
  isLoadingUsers,
  userFullName,
}) => {
  const { t } = useTranslation();

  const jiraRequest = parseJiraRequest(requestorRef, sourceRef);
  const slackRequest = parseSlackRequest(requestorRef, sourceRef);
  const apiRequest = parseApiRequest(requestorRef);

  if (isCreatedBySystem) {
    return (
      <Text variant="heading" size="XS">
        {t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.CREATED_BY_SYSTEM')}
      </Text>
    );
  }

  if (jiraRequest) {
    const userDetail = `${jiraRequest.requestorUserName} (${jiraRequest.requestorEmail})`;

    return (
      <CreatedByDetail
        icon={JiraIcon}
        userDetail={userDetail}
        detailLink={jiraRequest.issueLink}
        detailLinkText={t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.JIRA_LINK_TO_TICKET')}
      />
    );
  }

  if (slackRequest) {
    return (
      <CreatedByDetail
        icon={SlackIconWOBG}
        userDetail={slackRequest.userName}
        detailLink={slackRequest.linkToMessage}
        detailLinkText={t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.SLACK_LINK_TO_CONVERSATION')}
      />
    );
  }

  if (apiRequest) {
    return (
      <Text variant="heading" size="XS">
        {t('PAGES.CONTRACT_WORKFLOWS.REQUEST_DETAIL.CREATED_BY_API_REQUEST')}
      </Text>
    );
  }

  return (
    <Text variant="heading" size="XS">
      {isLoadingUsers ? <Placeholder height={19} width={80} /> : userFullName}
    </Text>
  );
};
