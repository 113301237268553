import { useTranslation } from 'react-i18next';
import { RocketLaunchOutlined } from '@mui/icons-material';
import { DesignSystemColor, IconButton, MenuItem, Tooltip } from '@verticeone/design-system';
import type { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowVersionRow } from './types';
import { useActivateWorkflowVersionV2 } from './useActivateWorkflowDialog';
import { useGetCatalogResources } from '../../../hooks/useGetCatalogResources';
import { TOOLTIP_PROPS, getIsWorkflowLive } from './utils';
import { SERVICE_CATALOG_SERVICE_KIND } from '../../../constants';
import { useIsDefaultWorkflow } from '../../../workflow/WorkflowDetailPage/utils';
import { useSetAsDefaultWorkflow } from '../../../workflow/WorkflowDetailPage/components/SetAsDefaultButton';
import { Stack } from '@mui/material';
import { useWorkflowsTableContext } from './WorkflowsTableContext';

type SetAsLiveButtonProps = {
  workflowVersion: WorkflowVersionRow;
  liveWorkflowVersion?: WorkflowVersion;
  color: DesignSystemColor;
  type?: 'menuitem' | 'iconbutton';
  setIsMenuOpen?: (isOpen: boolean) => void;
};

export const SetAsLiveButton = ({
  workflowVersion,
  color,
  type = 'menuitem',
  setIsMenuOpen,
  liveWorkflowVersion,
}: SetAsLiveButtonProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.ACTIONS',
  });

  const isLive = getIsWorkflowLive(workflowVersion);

  const hasErrors = !!workflowVersion.workflowDefinitionStatus?.error;
  const hasWarnings = !!workflowVersion.workflowDefinitionStatus?.warning;
  const { resources, isLoading: isLoadingResources } = useGetCatalogResources({
    resourcesFilter: (item) =>
      item.definition.Kind === SERVICE_CATALOG_SERVICE_KIND && item.urn === workflowVersion.serviceRef,
  });

  const service = isLoadingResources ? undefined : resources?.[0];
  const isDefault = useIsDefaultWorkflow(workflowVersion, service);
  const { setAsDefaultWorkflow } = useSetAsDefaultWorkflow(workflowVersion, service);
  const { setLastEditedWorkflowVersionId } = useWorkflowsTableContext();

  const { activateWorkflow, ActivateDialog } = useActivateWorkflowVersionV2({
    hasWarnings,
    onComplete: () => {
      if (!isDefault) setAsDefaultWorkflow(() => null, { hideSnackbarConfirmation: true });
      setIsMenuOpen?.(false);
    },
    liveWorkflowVersion,
    workflowVersion,
    onStart: () => setLastEditedWorkflowVersionId?.(workflowVersion.versionId),
  });

  const handleSetAsLiveClick = () => {
    activateWorkflow();
  };

  return (
    <>
      {type === 'menuitem' ? (
        <Tooltip
          {...TOOLTIP_PROPS}
          title={isLive ? t('TOOLTIPS.SET_LIVE_ACTIVE') : t('TOOLTIPS.SET_LIVE_DRAFT')}
          disableHoverListener={!isLive && !hasErrors}
        >
          <Stack>
            <MenuItem
              color={color}
              startIcon={RocketLaunchOutlined}
              disabled={isLive || hasErrors}
              onClick={handleSetAsLiveClick}
            >
              {t('SET_AS_LIVE')}
            </MenuItem>
          </Stack>
        </Tooltip>
      ) : (
        <Tooltip {...TOOLTIP_PROPS} title={t('SET_AS_LIVE')}>
          <IconButton
            size="S"
            variant="outline"
            icon={RocketLaunchOutlined}
            color={color}
            disabled={isLive || hasErrors}
            tabIndex={-1}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              // Blur to supress modal aria error
              event.currentTarget.blur();
              activateWorkflow();
            }}
          />
        </Tooltip>
      )}
      {ActivateDialog}
    </>
  );
};
