import { useTranslation } from 'react-i18next';

import { useRoutes } from '@verticeone/router/useRoutes';
import { OneLoginIcon } from '@vertice/assets';
import { DiscoveryAndUsageSetupCard } from '../DiscoveryAndUsageSetupCard/DiscoveryAndUsageSetupCard';

type OneLoginSetupCardProps = {
  providerId: string;
};

export const OneLoginSetupCard = ({ providerId }: OneLoginSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();

  return (
    <DiscoveryAndUsageSetupCard
      providerId={providerId}
      providerName="OneLogin"
      providerIcon={<OneLoginIcon />}
      description={t('INTEGRATIONS.ONELOGIN.DESCRIPTION')}
      wizardRoute={routes.INTEGRATIONS.NATIVE.USAGE.ONELOGIN.ABSOLUTE_PATH}
    />
  );
};
