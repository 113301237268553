import { Box, Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  useDeactivateAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { Button, Loader, Text } from '@verticeone/design-system';
import styles from '../../../common.module.scss';
import { IntegrationsCardContent } from '../../../components/IntegrationsCardContent';
import { IntegrationSetupDialog } from '../../../components/IntegrationSetupDialog';
import { StatusBadge, StatusBadgeVariant } from '../../../components/StatusBadge';
import { isValidIntegrationResponse } from './Slack/common';

type NotificationsSetupCardProps = {
  providerId: string;
  providerName: string;
  providerIcon: ReactNode;
  description: string;
  wizardRoute: string;
};

const getBadgeStatus = (integrationStatus?: string) => {
  switch (integrationStatus) {
    case 'MISSING':
      return StatusBadgeVariant.NEW;
    case 'INACTIVE':
      return StatusBadgeVariant.INACTIVE;
    case 'ACTIVATING':
    case 'TESTING':
      return StatusBadgeVariant.PROGRESS;
    case 'ACTIVE':
      return StatusBadgeVariant.ACTIVE;
    case 'FAILED':
      return StatusBadgeVariant.ERROR;
    default:
      return undefined;
  }
};

export const NotificationsSetupCard: FC<NotificationsSetupCardProps> = ({
  providerId,
  providerName,
  providerIcon,
  description,
  wizardRoute,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const [revokeConfirmationDisplayed, setRevokeConfirmationDisplayed] = useState(false);

  const {
    data: integration,
    isLoading: isIntegrationLoading,
    isError: isGetIntegrationError,
  } = useGetAccountIntegrationQuery({ accountId: accountId, integrationId: providerId }, { skip: !accountId });

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const handleRevoke = async (action: 'DELETE' | 'CANCEL' | 'CONFIRM') => {
    setRevokeConfirmationDisplayed(action === 'CONFIRM');

    if (action === 'DELETE') {
      const response = await deactivateIntegration({
        accountId: accountId,
        integrationId: providerId,
      });
      if (isValidIntegrationResponse(response)) {
        void revokeIntegration({ accountId: accountId, integrationId: providerId });
      }
    }
  };

  const navigateToWizard = () => {
    navigate(wizardRoute);
  };

  const integrationStatus = integration?.status;
  const isIntegrationActive = integrationStatus === 'ACTIVE';

  const badgeStatus = getBadgeStatus(integrationStatus);

  const revokeInProgress = isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress;

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        {isIntegrationLoading ? (
          <Box sx={{ height: '268px' }}>
            <Loader />
          </Box>
        ) : (
          <>
            <CardHeader avatar={providerIcon} />
            <CardContent classes={{ root: styles['card-content'] }}>
              <IntegrationsCardContent
                title={
                  <Text variant="heading" size="S" color="text1">
                    {providerName}
                  </Text>
                }
                subtitle={
                  <Text variant="heading" size="S" color="text1" sx={{ opacity: 0.4 }}>
                    {t('INTEGRATIONS.ALERTING.SUBTITLE')}
                  </Text>
                }
                statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
                description={description}
              />
            </CardContent>
            <CardActions className={styles['card-actions']} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {isGetIntegrationError ? (
                t('INTEGRATIONS.STATUS.ERROR')
              ) : (
                <Stack direction="row" gap="8px">
                  <Button
                    onClick={navigateToWizard}
                    variant="outline"
                    color="neutral"
                    size="S"
                    disabled={revokeInProgress}
                  >
                    {isIntegrationActive ? t('INTEGRATIONS.VIEW') : t('INTEGRATIONS.SETUP')}
                  </Button>
                  {isIntegrationActive && (
                    <Button
                      onClick={() => handleRevoke('CONFIRM')}
                      variant="ghost"
                      color="neutral"
                      size="S"
                      disabled={revokeInProgress}
                    >
                      {t('INTEGRATIONS.REVOKE_ACCESS')}
                    </Button>
                  )}
                </Stack>
              )}
            </CardActions>
          </>
        )}
      </Card>
      <IntegrationSetupDialog
        header={t('INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        content={t('INTEGRATIONS.ALERTING.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        buttonLabel={t('INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
        isOpened={revokeConfirmationDisplayed}
        onSubmit={() => handleRevoke('DELETE')}
        onClose={() => handleRevoke('CANCEL')}
      />
    </div>
  );
};
