import uniqueBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Filter, Owner } from './types';

export const isCustomFieldFilter = (filterName: Filter) => filterName.startsWith('custom_');

export const getContractsOwners = (views: ViewOnContract[]): Owner[] => {
  const owners: Owner[] = [];
  views.forEach((view) => {
    const { computed } = view;
    if (computed?.owners) {
      computed.owners.forEach((owner) => {
        if (owner.userId) {
          owners.push({
            ownerId: owner.userId,
            name: computed.labels?.[owner.userId] || owner.userId,
          });
        }
      });
    }
  });

  const uniqueOwnerOptions = uniqueBy(owners, (owner) => owner.ownerId);
  return orderBy(uniqueOwnerOptions, (owner) => owner.name.toLowerCase());
};
