import { isEmpty, isNil, isObject, mapValues, isArray } from 'lodash';

export type NonEmpty<T> = T extends null | undefined | '' ? never : keyof T extends never ? never : T;

export type NullifiedEmptyValues<T> = {
  // Remove optionality for all keys that are not objects, instead make them nullable.
  [K in keyof T as Record<string, any> extends T[K] ? never : K]-?: NonEmpty<T[K]> extends never
    ? null
    : undefined extends T[K]
    ? Exclude<T[K], undefined> | null
    : T[K];
} & {
  // For objects, keep optionality, don't make them nullable.
  [K in keyof T as Record<string, any> extends T[K] ? K : never]: T[K];
};

export const nullifyEmptyValues = <T extends Record<string, any>>(object: T) =>
  mapValues(object, (i) =>
    isNil(i) || i === '' || (isObject(i) && !isArray(i) && isEmpty(i)) ? null : i
  ) as unknown as NullifiedEmptyValues<T>;

// =========================
// Tests of TypeScript types
// =========================
/* eslint-disable unused-imports/no-unused-vars */
//
// ----------------------------------------------------------------------
// It replaces undefined with null even if null was not there previously.
//
// @ts-expect-no-error It accepts null value.
const t1a: NullifiedEmptyValues<{ a?: number }> = { a: null };
// @ts-expect-no-error It still accepts original value type.
const t1b: NullifiedEmptyValues<{ a?: number }> = { a: 42 };
// @ts-expect-error It rejects undefined.
const t1c: NullifiedEmptyValues<{ a?: number }> = { a: undefined };
// @ts-expect-error It rejects invalid type
const t1d: NullifiedEmptyValues<{ a?: number }> = { a: 'asdf' };

// -------------------------------------------------------------------------------------------
// It removes undefined type and keeps null if it was there previously.
//
// @ts-expect-no-error It accepts null value.
const t2a: NullifiedEmptyValues<{ a?: number | null }> = { a: null };
// @ts-expect-no-error It still accepts original value type.
const t2b: NullifiedEmptyValues<{ a?: number | null }> = { a: 42 };
// @ts-expect-error It rejects undefined.
const t2c: NullifiedEmptyValues<{ a?: number | null }> = { a: undefined };

// -------------------------------------------------------------------------------------------
// For object keys, it does no changes in optionality. It Keeps optional keys, doesn't make stuff nullable.
//
// @ts-expect-no-error It accepts original object.
const t3a: NullifiedEmptyValues<{ a?: { b?: 'asdf' } }> = { a: { b: 'asdf' } };
// @ts-expect-no-error It accepts empty object.
const t3b: NullifiedEmptyValues<{ a?: { b?: 'asdf' } }> = { a: {} };
// @ts-expect-error It rejects null value.
const t3c: NullifiedEmptyValues<{ a?: { b?: 'asdf' } }> = { a: null };
// @ts-expect-no-error It accepts undefined
const t3d: NullifiedEmptyValues<{ a?: { b?: 'asdf' } }> = { a: undefined };
