import { FC, useMemo } from 'react';
import {
  ContractContextDataRequireFetched,
  ContractLocator,
  FetchedContract,
} from '@vertice/core/src/modules/saas/contract/types';
import useFetchContract from '@vertice/core/src/modules/saas/contract/hooks/useFetchContract';
import { Card, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { Button } from '@verticeone/design-system';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { VerticeVWhiteIcon } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { StageIndicator } from '@vertice/core/src/modules/saas/contract/components/StageIndicator/StageIndicator';
import { hasFeDeadlineOrRollingFrequency } from '@vertice/core/src/modules/saas/contract/components/Deadline/utils';
import DeadlineWithLabel from '@vertice/core/src/modules/saas/contract/components/Deadline/DeadlineWithLabel';

type ContractRenewalCardProps = { nextFetchedContract: FetchedContract };
const ContractRenewalCard: FC<ContractRenewalCardProps> = ({ nextFetchedContract }) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { contract } = nextFetchedContract;

  return (
    <Card>
      <HighlightedCardHeader size="S" height="auto">
        <Stack gap={12} flex={1} marginY={6}>
          <CardHeaderTitle text={t('SAAS.CONTRACT_RENEWAL_CARD.TITLE')} icon={VerticeVWhiteIcon} />
          <CardHeaderActions direction="row" gap={6} justifyContent="space-between">
            <Stack gap={0.5}>
              <Text variant="label" size="XS" color="text1">
                {t('ENTITIES.CONTRACT.LABELS.STAGE')}
              </Text>
              <StageIndicator contract={contract} />
            </Stack>
            {hasFeDeadlineOrRollingFrequency(contract) && <DeadlineWithLabel contract={contract} />}
          </CardHeaderActions>
        </Stack>
      </HighlightedCardHeader>

      <Stack p={6} spacing={4} alignItems="start">
        <Button
          fullWidth
          variant="solid"
          color="primary"
          isCaption
          size="M"
          component={RouterLink}
          to={generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, {
            contractId: nextFetchedContract.contract.record.contractId,
          })}
        >
          {t('ENTITIES.CONTRACT.ACTIONS.VIEW_RENEWAL')}
        </Button>
      </Stack>
    </Card>
  );
};

export const shouldCardBeVisible = ({ materialized }: Contract, isEditing: boolean) =>
  !isEditing &&
  materialized?.next &&
  materialized.next.contractOrigin === 'RENEWAL_WITH_VERTICE' &&
  materialized.next.simplifiedStage === 'WORKFLOW_IN_PROGRESS';

export const useContractRenewalCard = ({
  fetchedContract,
  editMode: { isEditing },
}: ContractContextDataRequireFetched) => {
  const nextContractLocator = useMemo((): ContractLocator | undefined => {
    if (!shouldCardBeVisible(fetchedContract.contract, isEditing)) {
      return undefined;
    }
    const nextContractId = fetchedContract.contract.materialized?.next?.contractId;
    return nextContractId ? { accessVia: fetchedContract.accessedVia, contractId: nextContractId } : undefined;
  }, [fetchedContract.accessedVia, fetchedContract.contract, isEditing]);
  const nextFetchedContractQuery = useFetchContract(nextContractLocator);

  return useMemo(
    () =>
      !nextFetchedContractQuery.isLoading
        ? () => <ContractRenewalCard nextFetchedContract={nextFetchedContractQuery.data} />
        : null,
    [nextFetchedContractQuery]
  );
};
