import { useCallback, useState } from 'react';
import { useUpdateRequestNameMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '../../../account/AccountContext';
import { parseRequestRef } from '../../../../hooks/workflows/refUtils';
import { RenameRequestDialog } from './components/RenameRequestDialog';

type UseRequestEditReturn = {
  Component: React.FC;
  open: () => void;
  close: () => void;
};

export const useRequestEdit = (requestRef: string, originalName: string): UseRequestEditReturn => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [updateRequestName] = useUpdateRequestNameMutation();
  const { accountId } = useAccountContext();

  const requestId = parseRequestRef(requestRef).requestId;

  const handleSave = useCallback(
    async (name: string) => {
      updateRequestName({ updateRequestName: { name }, accountId, requestId })
        .unwrap()
        .then(() => {
          setIsDialogOpen(false);
        })
        .catch((err) => console.error(err));
      setIsDialogOpen(false);
    },
    [updateRequestName, accountId, requestId]
  );

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return {
    Component: () => (
      <RenameRequestDialog
        originalName={originalName}
        onClose={closeDialog}
        onSave={handleSave}
        isDialogOpen={isDialogOpen}
      />
    ),
    open: openDialog,
    close: closeDialog,
  };
};
