import { FC, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { Loader } from '@verticeone/design-system';
import { TaskCustomFormResult } from './TaskCustomFormResult';
import { useDynamicFormPresenterConfig } from '@vertice/core/src/modules/forms/dynamicForms/DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';
import { parseFormUrn } from '../../../workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm';
import { useDescribeCatalogResourceCamelQuery } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { isFormCatalogResource } from '../../../utils/catalogResourceUtils';
import { useFilePathPrefix } from './useFilePathPrefix';

export type TaskCustomFormResultSectionProps = {
  formUrn: string;
  result?: FieldValues;
};

export const TaskCustomFormResultSection: FC<TaskCustomFormResultSectionProps> = (props) => {
  const { formUrn, result } = props;

  const { accountId, formPath } = parseFormUrn(formUrn);
  const filePathPrefix = useFilePathPrefix();

  const { data: catalogResource, isLoading: isCatalogResourceLoading } = useDescribeCatalogResourceCamelQuery({
    accountId: accountId ?? 'GLOBAL',
    resourceId: encodeURIComponent(formPath),
  });

  const formConfigQuery = useDynamicFormPresenterConfig({ mode: 'readOnly', filePathPrefix });

  const formSchema = useMemo(() => {
    if (isFormCatalogResource(catalogResource?.resource?.definition)) {
      return catalogResource?.resource?.definition?.form?.formUiModel?.layout;
    }
    return null;
  }, [catalogResource]);

  if (isCatalogResourceLoading || formConfigQuery.isLoading) return <Loader />;

  return (
    formSchema &&
    result && <TaskCustomFormResult formSchema={formSchema} config={formConfigQuery.data} defaultValues={result} />
  );
};
