import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardHeaderDescription, Text } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { AuthType, IntegrationType } from '../utils';

import ApiKeyCreateForm from './components/Form/apiKey/CreateForm';
import IntegrationSetupContainer from '../../components/IntegrationSetupContainer';
import { OutletContextType } from '../../types';

type Params = {
  authType: AuthType;
  integrationType: IntegrationType;
};

const CreateIntegration = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const { authType, integrationType } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { setBreadcrumbsItems } = useOutletContext<OutletContextType>();

  useEffect(() => {
    setBreadcrumbsItems([
      {
        label: t('CREATE_TITLE', { integrationType }),
      },
    ]);

    return () => {
      setBreadcrumbsItems([]);
    };
  }, [integrationType, setBreadcrumbsItems, t]);

  if (!authType || !integrationType) {
    navigate(routes.INTEGRATIONS);
    return null;
  }

  const onSubmit = () => {
    enqueueSnackbar(t('SNACKBAR_MSG.CREATE.SUCCESS'), { variant: 'success' });
    navigate(routes.INTEGRATIONS);
  };

  const onError = () => {
    enqueueSnackbar(t('SNACKBAR_MSG.CREATE.ERROR'), { variant: 'error' });
  };

  return (
    <IntegrationSetupContainer>
      <Card maxWidth={1280} width="100%" marginX="auto">
        <CardHeader>
          <CardHeaderDescription>
            <Text variant="heading" size="S" color="text1">
              {t('CREATE_TITLE', { integrationType })}
            </Text>
          </CardHeaderDescription>
        </CardHeader>
        <Stack gap={4} p={6}>
          {authType === 'ApiKey' && (
            <ApiKeyCreateForm integrationType={integrationType} onSubmit={onSubmit} onError={onError} />
          )}
        </Stack>
      </Card>
    </IntegrationSetupContainer>
  );
};

export default CreateIntegration;
