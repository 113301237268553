import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { GridHeaderCell } from '@verticeone/design-system';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const AnnualCostHeaderCell = (params: GridColumnHeaderParams<ViewOnContract, any, any>) => {
  const { t } = useTranslation();

  return (
    <GridHeaderCell
      {...params}
      tooltip={{
        title: t('ENTITIES.CONTRACT.TOOLTIPS.ANNUALIZED_CONTRACT_VALUE.TITLE'),
        content: t('ENTITIES.CONTRACT.TOOLTIPS.ANNUALIZED_CONTRACT_VALUE.DESCRIPTION'),
      }}
    />
  );
};
