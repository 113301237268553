import { FC } from 'react';
import { DialogContent, DialogActions, Button, DialogText, TextField, DialogHeader } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { t as tGlobal } from 'i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

const MAX_TITLE_LENGTH = 30;

const getMaxLengthTitleMsg = (maximumLength: number) => {
  return [
    tGlobal('VALIDATOR.ERRORS.TOO_BIG.TITLE'),
    tGlobal('VALIDATOR.ERRORS.TOO_BIG.DESCRIPTION', { maximumLength }),
  ].join(', ');
};

const createGenericPurchaseSchema = z.object({
  name: z
    .string()
    .min(1, { message: tGlobal('VALIDATOR.ERRORS.TOO_SMALL.TITLE') })
    .max(MAX_TITLE_LENGTH, { message: getMaxLengthTitleMsg(MAX_TITLE_LENGTH) }),
});

export type CreateGenericPurchaseForm = z.infer<typeof createGenericPurchaseSchema>;

export type CreateNonSaasRequestFormProps = {
  onSubmit: (values: CreateGenericPurchaseForm) => void;
  defaultValues?: CreateGenericPurchaseForm;
  onClose: () => void;
};

export const CreateNonSaasRequestPurchaseForm: FC<CreateNonSaasRequestFormProps> = (props) => {
  const { onClose, onSubmit, defaultValues } = props;
  const { t } = useTranslation();

  const { register, handleSubmit, formState } = useForm<CreateGenericPurchaseForm>({
    defaultValues: defaultValues,
    resolver: zodResolver(createGenericPurchaseSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogHeader>{t(`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.HEADING_GENERIC_PURCHASE`)}</DialogHeader>
      <DialogContent>
        <DialogText variant="body-regular">
          {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.DESCRIPTION_GENERIC_PURCHASE')}
        </DialogText>

        <TextField {...register('name')} error={!!formState.errors.name} helperText={formState.errors.name?.message} />

        <DialogText variant="body-regular" mt={7}>
          {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.NOTE_GENERIC_PURCHASE')}
        </DialogText>
      </DialogContent>

      <DialogActions>
        <Button variant="outline" onClick={onClose} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}>
          {t('DIALOG.BUTTONS.CANCEL')}
        </Button>
        <Button variant="solid" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} type="submit">
          {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.START_REQUEST_BUTTON')}
        </Button>
      </DialogActions>
    </form>
  );
};
