import { FC, useCallback } from 'react';
import { DeepPartial } from 'react-hook-form';
import { t } from 'i18next';
import { FormStepsItem, FormStepsList } from '../components/FormSteps';
import { Stack } from '@mui/material';
import { LinearFormStep, useLinearStepper, useStepperStore } from '../hooks';
import { match } from 'ts-pattern';
import { ContractDocumentsFormStep, getContracDocumentsDefaultValues } from './formSteps/ContractDocumentsFormStep';
import { ContractDatesFormStep, getContractDatesDefaultValues } from './formSteps/ContractDatesFormStep';
import { ContractLineItemsFormStep, getContractLineItemsDefaultValues } from './formSteps/ContractLineItemsFormStep';
import { ContractDetailsFormStep, getContractDetailsDefaultValues } from './formSteps/ContractDetailsFormStep';
import { ContractReviewForm, TaskContractReviewInput, contractReviewSchema } from './types';
import { Divider } from '@verticeone/design-system';
import { ContractSummaryFormStep } from './formSteps/ContractSummaryFormStep';

/**
 * Extra information from outside world needed especially for computation of default values.
 *
 * We pass it from outside via props because it typically needs to be fetched from APIs before the form is rendered
 * for the first time.
 */
export type TaskContractReviewFormConfig = {
  accountPreferredCurrency: string;
};

export type TaskContractReviewFormProps = {
  taskInput?: TaskContractReviewInput;
  config: TaskContractReviewFormConfig;
  onSubmit: (values: ContractReviewForm) => Promise<void>;
};

const I18N_STEPS_PREFIX = 'INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS';

const formSteps: LinearFormStep[] = [
  { id: 'contractDetails', name: t(`${I18N_STEPS_PREFIX}.CONTRACT_DETAILS`), visited: true, valid: false },
  { id: 'contractDates', name: t(`${I18N_STEPS_PREFIX}.CONTRACT_DATES`), visited: false, valid: false },
  { id: 'documents', name: t(`${I18N_STEPS_PREFIX}.DOCUMENTS`), visited: false, valid: false },
  { id: 'lineItems', name: t(`${I18N_STEPS_PREFIX}.LINE_ITEMS`), visited: false, valid: false },
  { id: 'review', name: t(`${I18N_STEPS_PREFIX}.REVIEW`), visited: false, valid: false },
];

const getDefaultValues = (
  config: TaskContractReviewFormConfig,
  input?: TaskContractReviewInput
): DeepPartial<ContractReviewForm> => ({
  contractDetails: getContractDetailsDefaultValues(input),
  contractDates: getContractDatesDefaultValues(input),
  documents: getContracDocumentsDefaultValues(),
  lineItems: getContractLineItemsDefaultValues(config),
});

export const TaskContractReviewForm: FC<TaskContractReviewFormProps> = (props) => {
  const { onSubmit, config, taskInput } = props;
  const { steps, activeStepId, goNext, setStep, goPrevious, goOnStep } = useLinearStepper({ formSteps });
  const { values, getValues, updateValues } = useStepperStore({ defaultValues: getDefaultValues(config, taskInput) });

  const handleSubmit = useCallback(async () => {
    const formResult = contractReviewSchema.safeParse(values);
    if (formResult.error) return;

    await onSubmit(formResult.data);
  }, [onSubmit, values]);

  const handleCompleteStep = useCallback(
    <K extends keyof ContractReviewForm>(step: K) =>
      async (stepValues: DeepPartial<ContractReviewForm[K]>) => {
        if (!activeStepId) return;

        setStep(activeStepId, { valid: true, visited: true });
        updateValues(step, stepValues);
        goNext();
      },
    [goNext, activeStepId, setStep, updateValues]
  );

  return (
    <Stack direction="row" height="100%" width="100%">
      <FormStepsList sx={{ flex: '0 0 250px' }}>
        {steps.map(({ id, name, visited, valid }) => (
          <FormStepsItem key={id} visited={visited} valid={valid} onClick={() => goOnStep(id)}>
            {name}
          </FormStepsItem>
        ))}
      </FormStepsList>

      <Divider orientation="vertical" />

      {match(activeStepId)
        .with('contractDetails', (step) => (
          <ContractDetailsFormStep onSubmit={handleCompleteStep(step)} defaultValues={getValues(step)} />
        ))
        .with('contractDates', (step) => (
          <ContractDatesFormStep
            onSubmit={handleCompleteStep(step)}
            contractForm={values}
            defaultValues={getValues(step)}
            onPrevious={goPrevious}
          />
        ))
        .with('documents', (step) => (
          <ContractDocumentsFormStep
            onSubmit={handleCompleteStep(step)}
            defaultValues={getValues(step)}
            onPrevious={goPrevious}
          />
        ))
        .with('lineItems', (step) => (
          <ContractLineItemsFormStep
            onSubmit={handleCompleteStep(step)}
            contractForm={values}
            defaultValues={getValues(step)}
            onPrevious={goPrevious}
          />
        ))
        .with('review', () => (
          <ContractSummaryFormStep
            onSubmit={handleSubmit}
            values={values as ContractReviewForm}
            onPrevious={goPrevious}
          />
        ))
        .otherwise(() => null)}
    </Stack>
  );
};
