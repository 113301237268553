import { integrationsJiraAPICodegen } from '../openapi/codegen/integrationsJiraAPI';

const TAG_TYPE = 'integrationsJira';
const JIRA_TRIGGER_CONFIG_TAG = 'JIRA_TRIGGER_CONFIG_TAG';

type AllTagType = typeof TAG_TYPE | typeof JIRA_TRIGGER_CONFIG_TAG;

export const enhancedIntegrationsJiraAPI = integrationsJiraAPICodegen.enhanceEndpoints<AllTagType>({
  addTagTypes: [TAG_TYPE, JIRA_TRIGGER_CONFIG_TAG],
  endpoints: {
    getIntegrationConfig: {
      providesTags: [JIRA_TRIGGER_CONFIG_TAG],
    },
    updateIntegrationConfig: {
      invalidatesTags: [JIRA_TRIGGER_CONFIG_TAG],
    },
  },
});
