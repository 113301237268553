import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption } from '@verticeone/design-system';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { SelectColor } from '@verticeone/design-system';
import { SingleValue } from 'react-select';
import { keyBy } from 'lodash';
import { useAccountDepartments } from '@vertice/core/src/modules/departments/useAccountDepartments';
import { SingleSelectComponent } from './types';

type DepartmentsFormEntryProps = {
  width?: number;
  overrideAccountId?: string | null;
};

export const DepartmentsFormEntry: FC<DepartmentsFormEntryProps> = ({ width = 12, overrideAccountId }) => {
  const { t } = useTranslation();
  const { data: allDepartments } = useAccountDepartments({ overrideAccountId });

  const options = allDepartments?.map<SimpleOption>(({ name, departmentId }) => ({
    value: departmentId,
    label: name,
  }));
  const departmentsMap = keyBy(options, 'value');

  return (
    <TaskFormEntry<any, SingleSelectComponent>
      name="department"
      label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
      // TODO: Rewrite to use FormDepartmentSelectField
      component={FormSelectField2}
      componentProps={{
        isClearable: false,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR as SelectColor,
        options,
        boxValue: (value) => departmentsMap[value],
        unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
        testId: 'department-select',
      }}
      width={width}
    />
  );
};
