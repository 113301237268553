import React, { useCallback } from 'react';
import { CommonIntakeFormFormData } from '../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { CostModelProductList } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';
import { AddExistingContractFormData } from '../AddExistingContractWizard/types';

export const IntakeProductList = () => {
  const { setValue } = useFormContext<CommonIntakeFormFormData>();

  const products = useWatch<CommonIntakeFormFormData, 'products'>({ name: 'products' });
  const contractCurrency = useWatch<CommonIntakeFormFormData, 'contractCurrency'>({ name: 'contractCurrency' });
  const vendor = useWatch<CommonIntakeFormFormData, 'vendor'>({ name: 'vendor' });
  const rollingFrequency = useWatch<AddExistingContractFormData, 'rollingFrequency'>({ name: 'rollingFrequency' });

  const setProducts = useCallback(
    (productsToSet: ProductItem[]) => {
      setValue('products', productsToSet);
    },
    [setValue]
  );

  return (
    <CostModelProductList
      withActions
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      currency={contractCurrency}
      vendorId={vendor?.id ?? undefined}
      rollingFrequency={rollingFrequency ? rollingFrequency : 'NO'}
    />
  );
};
