import { orderBy } from 'lodash';

import { ListTagsApiArg, ListTagsApiResponse, Tag, useLazyListTagsQuery } from '@vertice/slices';
import { useAccountContext } from '../account/AccountContext';
import { useFetchPaginated } from '../../hooks/useFetchPaginated';
import { useEffect, useState } from 'react';
import { DEPARTMENT_TAG_PREFIX, isDefaultDepartmentTag } from './tagUtils';
import { Department } from './types';

type UseAccountDepartmentsProps = {
  skip?: boolean;
  overrideAccountId?: string | null;
};

export const useAccountDepartments = ({ skip, overrideAccountId }: UseAccountDepartmentsProps | undefined = {}) => {
  const { accountId } = useAccountContext();
  const [fetchTags] = useLazyListTagsQuery();
  const [departments, setDepartments] = useState<Department[]>();

  const { items: departmentTags, isLoading: isLoadingDepartmentTags } = useFetchPaginated<
    ListTagsApiResponse,
    ListTagsApiArg,
    Tag
  >({
    fetchFn: fetchTags,
    getItemsFn: (data) => data.tags,
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: {
      accountId: overrideAccountId || accountId!,
      tagIdPrefix: DEPARTMENT_TAG_PREFIX,
    },
    skip: skip,
  });

  const mapTagToDepartment = (tag: Tag): Department => ({
    departmentId: tag.tagId,
    name: tag.label,
    code: isDefaultDepartmentTag(tag) ? 'DEFAULT' : undefined,
  });

  useEffect(() => {
    if (departmentTags) {
      setDepartments(departmentTags.map(mapTagToDepartment));
    }
  }, [departmentTags]);

  if (skip) {
    return { data: undefined, isLoading: false };
  }

  return {
    data: orderBy(departments, (dep) => dep.name.toLowerCase()),
    isLoading: isLoadingDepartmentTags || !departments,
  };
};
