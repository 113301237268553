import { KeyboardArrowDown } from '@mui/icons-material';
import { AccordionDetails, Box, Accordion as MuiAccordion, Stack, styled } from '@mui/material';
import MuiAccordionSummary, { accordionSummaryClasses, AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import type { ServiceCatalogResource } from '@vertice/core/src/modules/intelligentWorkflows/catalogResource/types';
import { Text, ToggleSwitch } from '@verticeone/design-system';
import { AddTrigger } from './AddTrigger';
import { TriggerType } from './types';

const Accordion = styled(MuiAccordion)({
  '&:before': {
    display: 'none',
  },
});

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  gap: theme.spacing(2),
  margin: 0,
  padding: 0,
  [`&.${accordionSummaryClasses.expanded} .${accordionSummaryClasses.contentGutters}`]: {
    margin: 0,
    minHeight: 0,
    backgroundColor: 'red',
  },
  [`&.${accordionSummaryClasses.expanded} .${accordionSummaryClasses.contentGutters}`]: {
    margin: 0,
    minHeight: 0,
    backgroundColor: 'red',
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}`]: {
    color: theme.palette.text.color1,
    opacity: '40%',
    transform: 'rotate(-90deg)',
    [`&.${accordionSummaryClasses.expanded}`]: {
      transform: 'none',
    },
  },
  [`& .${accordionSummaryClasses.content}`]: {
    alignItems: 'center',
  },
}));

type WorkflowWrapProps = {
  workflowService: ServiceCatalogResource;
  active: boolean;
  onActivate: (state: boolean) => void;
  triggersCount: number;
  onAddTrigger: (triggerType: TriggerType) => void;
} & PropsWithChildren;

export const WorkflowWrap: FC<WorkflowWrapProps> = ({
  workflowService,
  active,
  onActivate,
  triggersCount,
  onAddTrigger,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Accordion disableGutters>
      <AccordionSummary>
        <Stack flex={1} gap={1} direction="row">
          <Text variant="button-bold" size="S">
            {workflowService.name}
          </Text>
          <Text
            variant="button-bold"
            size="S"
            color="neutral1"
            sx={{ flexGrow: 1, alignItems: 'center', opacity: '0.6' }}
          >
            {t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.X_TRIGGERS', { count: triggersCount })}
          </Text>
        </Stack>
        {/* Toggle clickable area is overflowing its visual box. This wrapper takes care about it. */}
        <Box sx={{ overflow: 'hidden' }}>
          <ToggleSwitch
            checked={active}
            color="neutral"
            size="S"
            onChange={(_event, checked) => {
              onActivate(checked);
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            testId={workflowService.definition.Thumbnail?.Id}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 0 }}>
        {children}
        {active && <AddTrigger onClick={onAddTrigger} />}
      </AccordionDetails>
    </Accordion>
  );
};
