import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Wizard } from '@vertice/core/src/components/Wizard';
import { useLoggedUser } from '@verticeone/auth/src';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import EnterCodeStep from './Steps/EnterCodeStep';
import EnterEmailStep from './Steps/EnterEmailStep';
import StartingStep from './Steps/StartingStep';
import { SlackIntegrationData } from './types';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from '../../../types';

const defaultValues: SlackIntegrationData = {
  slackEmail: '',
  accessCode: null,
};

export const SlackIntegrationWizard = () => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const formMethods = useForm<SlackIntegrationData>({ defaultValues, mode: 'onChange' });
  const { setIsFullHeight } = useOutletContext<OutletContextType>();

  const { email: userEmail } = useLoggedUser();
  useEffect(() => {
    formMethods.setValue('slackEmail', userEmail);
  }, [formMethods, userEmail]);

  useEffect(() => {
    setIsFullHeight(true);

    return () => {
      setIsFullHeight(false);
    };
  }, [setIsFullHeight]);

  const handleOnClose = () => navigate(routes.PREFERENCES.USER);

  return (
    <Stack p={10}>
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={(e) => e.preventDefault()}>
          <Wizard
            initialStep="start"
            onClose={handleOnClose}
            closeButtonText={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.CANCEL_BUTTON_TEXT')}
          >
            <StartingStep stepId="start" />
            <EnterEmailStep stepId="enter_email" />
            <EnterCodeStep stepId="enter_code" />
          </Wizard>
        </Box>
      </FormProvider>
    </Stack>
  );
};
