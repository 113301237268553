import React from 'react';
import { styled } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { API_URLS } from '@vertice/slices';
import { AddExistingContractFormData } from '../AddExistingContractWizardV2/types';

const VendorLogoWrapper = styled('div')(({ theme }) => ({
  width: '160px',
  height: '160px',
  padding: '24px',
  boxShadow: theme.palette.global.getShadow({
    color: 'core',
    type: 'soft',
    depth: '3z',
    distance: '40',
  }),
  borderRadius: 40,
  zIndex: 1,
  backgroundColor: theme.palette.core.bg,
}));

const StyledSvg = styled('svg')({
  width: '100%',
  height: '100%',
  borderRadius: '0px',
  overflow: 'hidden',
});

const StyledImage = styled('image')({
  width: '100%',
});

export const VendorLogoBig: React.FC = () => {
  const { watch } = useFormContext<AddExistingContractFormData>();
  const vendor = watch('vendor');

  return (
    <VendorLogoWrapper>
      {vendor && (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <StyledImage href={`${API_URLS.LOGOS_URL}/${vendor.id}.svg`} />
        </StyledSvg>
      )}
    </VendorLogoWrapper>
  );
};
