import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isNil, isEmpty } from 'lodash';
import { CustomFilterViewFilters } from './types';
import { getUnifiedStage } from '../../functions';
import { RenewalStatusFilterMap } from '../filterFunctions';
import { EvaluateFilterViewContext } from './types';

export const UNASSIGNED_OWNER_OPTION = 'unassigned';

type Evaluator = (
  filter: CustomFilterViewFilters,
  viewOnContract: ViewOnContract,
  ctx?: EvaluateFilterViewContext
) => boolean;

type Evaluators = Required<{
  [key in keyof CustomFilterViewFilters]: Evaluator;
}>;

const evaluateContractOwner: Evaluator = ({ contractOwner }, viewOnContract) =>
  isEmpty(contractOwner) ||
  contractOwner!.some((ownerId) =>
    ownerId === UNASSIGNED_OWNER_OPTION
      ? viewOnContract.computed?.owners?.length === 0
      : viewOnContract.computed?.owners?.some((owner) => owner.userId === ownerId)
  );

const evaluateContractStage: Evaluator = ({ contractStage }, { contract }) =>
  isEmpty(contractStage) || contractStage!.some((stage) => getUnifiedStage(contract) === stage);

const evaluateContractRollingFrequency: Evaluator = ({ contractRollingFrequency }, { contract }) =>
  isEmpty(contractRollingFrequency) ||
  contractRollingFrequency!.some(
    (rollingFrequency) =>
      contract.parts?.contractual?.lifecycle?.rollFrequency === rollingFrequency ||
      (rollingFrequency === 'NO' && isNil(contract.parts?.contractual?.lifecycle?.rollFrequency))
  );

const evaluateContractBillingFrequency: Evaluator = ({ contractBillingFrequency }, { contract }) =>
  isEmpty(contractBillingFrequency) ||
  contractBillingFrequency!.some(
    (billingFrequency) => contract.parts?.contractual?.financial?.billingFrequency === billingFrequency
  );

const evaluateContractCategory: Evaluator = ({ contractCategory }, { contract }) =>
  isEmpty(contractCategory) || contractCategory!.some((category) => contract.classification?.category === category);

const evaluateContractDepartment: Evaluator = ({ contractDepartment }, { contract }) =>
  isEmpty(contractDepartment) ||
  contractDepartment!.some((departmentId) => contract.classification?.departmentId === departmentId);

const evaluateContractOrigin: Evaluator = ({ contractOrigin }, { contract }) =>
  isEmpty(contractOrigin) || contractOrigin!.some((origin) => contract.materialized?.next?.contractOrigin === origin);

const evaluateContractPaymentTerms: Evaluator = ({ contractPaymentTerms }, { contract }) =>
  isEmpty(contractPaymentTerms) ||
  contractPaymentTerms!.some((paymentTerms) => contract.parts?.contractual?.financial?.paymentTerms === paymentTerms);

const evaluateContractRenewalStatus: Evaluator = ({ contractRenewalStatus }, { contract }) =>
  isEmpty(contractRenewalStatus) ||
  contractRenewalStatus!.some((renewalStatus) => RenewalStatusFilterMap[renewalStatus]({ contract }));

const evaluateContractValue: Evaluator = ({ contractValue }, { contract }, ctx) =>
  isEmpty(contractValue) || contractValue!.some((value) => ctx?.filterContractValue?.({ contract }, [value]));

export const evaluators: Evaluators = {
  contractOwner: evaluateContractOwner,
  contractStage: evaluateContractStage,
  contractRollingFrequency: evaluateContractRollingFrequency,
  contractRenewalStatus: evaluateContractRenewalStatus,
  contractValue: evaluateContractValue,
  contractBillingFrequency: evaluateContractBillingFrequency,
  contractCategory: evaluateContractCategory,
  contractDepartment: evaluateContractDepartment,
  contractOrigin: evaluateContractOrigin,
  contractPaymentTerms: evaluateContractPaymentTerms,
};
