import React, { BaseSyntheticEvent, useCallback } from 'react';
import { Stack } from '@mui/material';
import { Control, FieldPath, FieldValues, UseFormRegister, useFormState } from 'react-hook-form';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form';
import { get, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextField, TextFieldCaption, Text } from '@verticeone/design-system';
import FormControl from './FormControl';

export const PROTOCOL = 'https';

type HostnameFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  control: Control<T>;
  disabled?: boolean;
};

const HostnameField = <T extends FieldValues>({
  name,
  register,
  setValue,
  trigger,
  control,
  disabled = false,
}: HostnameFieldProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM.HOST_NAME' });
  const { errors, touchedFields } = useFormState({ control, name });
  const isTouched = get(touchedFields, name, false);
  const hasError = has(errors, name);
  const removeProtocol = useCallback(
    (e: BaseSyntheticEvent) => {
      if (e.target.value.startsWith(PROTOCOL)) {
        setValue(name, e.target.value.trim().replace(new RegExp(`^${PROTOCOL}:\\/\\/`), ''));
        void trigger(name);
      }
    },
    [setValue, trigger, name]
  );

  return (
    <FormControl>
      <Stack>
        <TextFieldCaption label={t('TITLE')} htmlFor="host-name" size="XS" required />
        <Text variant="body-regular" size="XS" color="text3">
          {t('DESCRIPTION')}
        </Text>
      </Stack>
      <TextField
        id="host-name"
        size="M"
        error={isTouched && hasError}
        disabled={disabled}
        startAdornment={
          <Stack pr={2}>
            <Stack py={1.5} borderRight="1px solid" borderColor="core.color3">
              <Text variant="body-regular" size="S" color="inactive1" height="100%" pr={3.5}>
                {PROTOCOL}://
              </Text>
            </Stack>
          </Stack>
        }
        {...register(name, { onChange: removeProtocol })}
      />
    </FormControl>
  );
};

export default HostnameField;
