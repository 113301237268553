import { WorkflowVersionRow } from '../../workflow/WorkflowDetailPage/types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { WORKFLOW_BFFE_VERSIONS, enhancedBffeWorkflowsAPI } from '@vertice/slices';
import { useUpdateWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useAccountContext } from '../../../account/AccountContext';
import { RenameWorkflowDialog } from '../components/Dialogs/RenameWorkflowDialog';

export const useRenameWorkflow = (
  workflowVersion: WorkflowVersionRow,
  params?: { onComplete: () => void; onStart?: () => void }
) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.RENAME_DIALOG',
  });
  const [hasError, setHasError] = useState(false);
  const { accountId } = useAccountContext();
  const [updateWorkflowVersion, { isLoading: isSavingWorkflow }] = useUpdateWorkflowVersionMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const renameWorkflow = () => {
    setIsOpen(true);
  };

  const handleSubmit = (newName: string) => {
    if (!newName) {
      setHasError(true);
      return;
    }
    params?.onStart?.();
    setIsSubmitting(true);
    updateWorkflowVersion({
      workflowId: workflowVersion.id,
      workflowVersion: workflowVersion.versionId,
      accountId: accountId,
      updateWorkflowResource: {
        versionName: newName,
        name: workflowVersion.workflowName,
      },
    })
      .then(() => {
        setIsOpen(false);
        params?.onComplete?.();
        dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_VERSIONS]));
        enqueueSnackbar(t('SUCCESS_RENAME_WORKFLOW_VERSION'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('ERROR_RENAME_WORKFLOW_VERSION'), {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    RenameDialog: isOpen ? (
      <RenameWorkflowDialog
        isOpen={isOpen}
        hasError={hasError}
        onClose={handleClose}
        onSubmit={handleSubmit}
        isSubmitting={isSavingWorkflow || isSubmitting}
        workflowVersion={workflowVersion}
      />
    ) : null,
    renameWorkflow,
  };
};
