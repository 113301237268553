import { useGetStatsForRequestsByVendorsQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useMemo } from 'react';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

type UseRequestsAnalyticsReturn = {
  requestsPerVendor: {
    [vendorId: string]: {
      activeRequestsCount: number;
    };
  };
  isRequestsLoading: boolean;
};

export const useRequestsAnalytics = (): UseRequestsAnalyticsReturn => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
  const { data, isLoading } = useGetStatsForRequestsByVendorsQuery({ accountId }, { skip: !isIWEnabled });

  return useMemo(
    () => ({
      requestsPerVendor: data?.stats || {},
      isRequestsLoading: isLoading,
    }),
    [data?.stats, isLoading]
  );
};
