import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import dayjs from 'dayjs';

const SpendVsCommitmentChartQuery = graphql(`
  query SpendVsCommitmentChart($accountId: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }
    ) {
      ... on DataTableResult {
        __typename
        table(
          columns: [
            "edp_month_start"
            "forecasted_spend"
            "aws_infra_spend"
            "eligible_marketplace_spend"
            "ineligible_marketplace_spend"
            "cumulative_month_spend"
            "cumulative_forecasted_spend"
            "is_forecast"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

type DataColumns = {
  edp_month_start: string;
  forecasted_spend: number;
  aws_infra_spend: number;
  eligible_marketplace_spend: number;
  ineligible_marketplace_spend: number;
  cumulative_month_spend: number;
  cumulative_forecasted_spend: number;
  is_forecast: boolean;
};

export type SpendVsCommitmentChartData = {
  months: string[];
  totalCommitment: number;
  isForecastValues: { key: string; value: boolean }[];
  values: { id: string; data: Array<number | null>; type: string; lineWidth?: number; dashStyle?: string }[];
  usedCategories: string[];
};

export const useSpendVsCommitmentChartData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['SpendVsCommitmentChart'],
    queryFn: () =>
      fetchCloudOptimization(SpendVsCommitmentChartQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        const result = (getTableData(data.athenaViewQuery.table as TableType) as DataColumns[]) ?? null;

        const totalCommitment = result
          .map((item) => item.cumulative_month_spend + item.cumulative_forecasted_spend)
          .slice(-1)[0];

        return {
          months: result.map((item) => dayjs(item.edp_month_start).format()),
          totalCommitment,
          isForecastValues: result.map((item) => ({
            key: item.edp_month_start,
            value: item.is_forecast,
          })),
          values: [
            {
              id: 'commitment',
              data: result.map((item) => item.forecasted_spend),
              type: 'line',
              lineWidth: 1,
              dashStyle: 'Dash',
            },
            {
              id: 'nonEligibleMarketplaceSpend',
              data: result.map((item) => item.ineligible_marketplace_spend),
              type: 'column',
            },
            {
              id: 'eligibleMarketplaceSpend',
              data: result.map((item) => item.eligible_marketplace_spend),
              type: 'column',
            },
            {
              id: 'awsInfraSpend',
              data: result.map((item) => item.aws_infra_spend),
              type: 'column',
            },
          ],
          usedCategories: ['commitment', 'nonEligibleMarketplaceSpend', 'eligibleMarketplaceSpend', 'awsInfraSpend'],
        };
      }

      return null;
    },
  });
};
