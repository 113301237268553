import { useCallback } from 'react';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  VISOTRUST_CONNECTION_ID,
  VISOTRUST_PROVIDER_ID,
} from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import {
  useConfigureAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useGetConnectionQuery,
  useGetWebhookQuery,
  useInvokeConnectionMutation,
  useRemoveConnectionMutation,
} from '@vertice/slices';
import { WEBHOOKS_PATH } from 'pages/Integrations/constants';
import { VISOTRUST_WEBHOOK_CODE } from '../constants';

export const useWebhookConnection = () => {
  const { accountId } = useAccountContext();

  const { data: webhookData } = useGetWebhookQuery({ accountId, webhookCode: VISOTRUST_WEBHOOK_CODE });
  const {
    data: accountIntegrationData,
    isFetching: isFetchingAccountIntegrationData,
    isLoading: isLoadingAccountIntegrationData,
  } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
  });

  const webhookId = accountIntegrationData?.parameters?.webhookId ?? '';

  const {
    data: connectionData,
    refetch: refetchConnectionData,
    isFetching: isFetchingConnectionData,
  } = useGetConnectionQuery(
    {
      accountId,
      integrationId: VISOTRUST_PROVIDER_ID,
      connectionId: VISOTRUST_CONNECTION_ID,
      pathPlus: `${WEBHOOKS_PATH}/${webhookId}`,
    },
    { skip: !webhookId }
  );

  const [invokeConnection, { isLoading: isLoadingInvokeConnection }] = useInvokeConnectionMutation();
  const [configureAccountIntegration, { isLoading: isLoadingConfigureAccountIntegration }] =
    useConfigureAccountIntegrationMutation();
  const [removeConnection, { isLoading: isLoadingRemoveConnection }] = useRemoveConnectionMutation();

  const connectWebhook = useCallback(async () => {
    const webhookUrl = webhookData?.webhookUrl ?? '';

    const response = await invokeConnection({
      accountId,
      integrationId: VISOTRUST_PROVIDER_ID,
      connectionId: VISOTRUST_CONNECTION_ID,
      pathPlus: WEBHOOKS_PATH,
      invokeConnectionRequest: {
        serviceType: 'GENERIC',
        webhookUrl,
        description: 'VisoTrust Webhook',
        eventTypes: ['ASSESSMENT_COMPLETED'],
      },
    });

    if ('data' in response) {
      const prevParameters = accountIntegrationData?.parameters;

      await configureAccountIntegration({
        accountId,
        integrationId: VISOTRUST_PROVIDER_ID,
        integrationConfigInput: {
          parameters: {
            ...prevParameters,
            webhookId: response.data.id,
          },
        },
      });
    }
  }, [
    accountId,
    accountIntegrationData?.parameters,
    configureAccountIntegration,
    invokeConnection,
    webhookData?.webhookUrl,
  ]);

  const disconnectWebhook = useCallback(async () => {
    const response = await removeConnection({
      accountId,
      integrationId: VISOTRUST_PROVIDER_ID,
      connectionId: VISOTRUST_CONNECTION_ID,
      pathPlus: `${WEBHOOKS_PATH}/${webhookId}`,
    });

    if ('data' in response) {
      await refetchConnectionData();
    }
  }, [accountId, refetchConnectionData, removeConnection, webhookId]);

  const isConnectWebhookInProgress =
    isLoadingInvokeConnection ||
    isLoadingConfigureAccountIntegration ||
    isFetchingConnectionData ||
    isFetchingAccountIntegrationData;
  const isDisconnectWebhookInProgress = isLoadingRemoveConnection || isFetchingConnectionData;

  return {
    connectionData,
    isLoadingAccountIntegrationData,
    connectWebhook,
    isConnectWebhookInProgress,
    disconnectWebhook,
    isDisconnectWebhookInProgress,
  };
};
