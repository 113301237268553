import { FC, forwardRef, ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { IconButton, IconWrapper, testProps, Text, Tooltip } from '@verticeone/design-system';
import { OpenInNew } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const StyledIconWrapper = styled(IconWrapper)({
  opacity: 0.4,
});

type DetailRowProps = {
  icon: FC;
  label: ReactNode;
  linkTo?: string;
  testId?: string;
  tooltip?: string;
  color?: 'warning' | 'success' | 'error' | 'info';
  textVariant?: 'bold' | 'regular';
};

type DetailRowBaseProps = Omit<DetailRowProps, 'tooltip'>;

const TEXT_VARIANTS = {
  bold: 'body-bold',
  regular: 'body-regular',
} as const;

export const DetailRowBase = forwardRef<HTMLDivElement, DetailRowBaseProps>(
  ({ icon: Icon, label, linkTo, testId, color, textVariant = 'regular', ...restProps }, ref) => {
    return (
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        height={17}
        {...testProps(testId, 'detailRow')}
        {...restProps}
      >
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
          <StyledIconWrapper icon={Icon} />
          <Text variant={TEXT_VARIANTS[textVariant]} size="S" color={color ? `${color}.color1` : 'text2'}>
            {label}
          </Text>
        </Stack>
        {linkTo && (
          <IconButton
            icon={OpenInNew}
            size="S"
            variant="plain"
            color="transparent"
            component={Link}
            to={linkTo}
            sx={{ marginLeft: -1 }}
          />
        )}
      </Stack>
    );
  }
);

export const DetailRow: FC<DetailRowProps> = ({ tooltip, ...restProps }) => {
  return tooltip ? (
    <Tooltip minified noArrow title={tooltip}>
      <DetailRowBase {...restProps} />
    </Tooltip>
  ) : (
    <DetailRowBase {...restProps} />
  );
};
