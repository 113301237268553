import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const contractCostAPI = createApi({
  reducerPath: 'contractCostApi',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.CONTRACT_COST),
  refetchOnMountOrArgChange: true,
  tagTypes: ['ContractCost'],
  endpoints: () => ({}),
});
