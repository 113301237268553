import { ThumbnailVariants } from './types';
import RequestForProposal from './assets/request-for-proposal.svg';
import RequestPreApproval from './assets/request-pre-approval.svg';
import CustomPurchase from './assets/custom-purchase.svg';
import InternalNegotiation from './assets/internal-negotiation.svg';
import MarketingRetainer from './assets/marketing-retainer.svg';
import SignNDA from './assets/sign-nda.svg';
import VendorDueDiligence from './assets/vendor-due-diligence.svg';
import SaasPurchase from './assets/saas-purchase.svg';
import SaasRenewal from './assets/saas-renewal.svg';
import SecurityReview from './assets/security-review.svg';
import Generic from './assets/generic.svg';
import HireNewEmployee from './assets/hire-a-new-employee.svg';
import HireNewContractor from './assets/hire-a-new-contractor.svg';
import InsuranceServices from './assets/insurance-services.svg';
import MarketingEvent from './assets/marketing-event.svg';
import OfficeCleaning from './assets/office-cleaning.svg';
import OfficeLeaseRenewal from './assets/office-lease-renewal.svg';
import OnboardNewEmployee from './assets/onboard-new-employee.svg';
import PurchaseItHardware from './assets/purchase-it-hardware.svg';
import RequestOfficeSupplies from './assets/request-office-supplies.svg';
import TenderProcess from './assets/tender-process.svg';
import ContractRenewal from './assets/contract-renewal.svg';

export const PREDEFINED_THUMBNAILS_MAP: Record<ThumbnailVariants, string> = {
  'request-for-proposal': RequestForProposal,
  'request-pre-approval': RequestPreApproval,
  'custom-purchase': CustomPurchase,
  'internal-negotiation': InternalNegotiation,
  'marketing-retainer': MarketingRetainer,
  'sign-nda': SignNDA,
  'vendor-due-diligence': VendorDueDiligence,
  'saas-purchase': SaasPurchase,
  'saas-renewal': SaasRenewal,
  'security-review': SecurityReview,
  'hire-a-new-employee': HireNewEmployee,
  'hire-a-new-contractor': HireNewContractor,
  'insurance-services': InsuranceServices,
  'marketing-event': MarketingEvent,
  'office-cleaning': OfficeCleaning,
  'office-lease-renewal': OfficeLeaseRenewal,
  'onboard-new-employee': OnboardNewEmployee,
  'purchase-it-hardware': PurchaseItHardware,
  'request-office-supplies': RequestOfficeSupplies,
  'tender-process': TenderProcess,
  'contract-renewal': ContractRenewal,
  generic: Generic,
};
