import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { STATUS_FILTER_MAP, StatusFilter, StatusFilterType } from './types';
import { useMemo } from 'react';

const STATUS_FILTER_SEARCH_PARAM = 'status';

export const useRequestListStatusFilters = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.WORKFLOW_REQUEST.FILTERS' });
  const [searchParams, setSearchParams] = useSearchParams();

  const activeStatusFilter = (searchParams.get(STATUS_FILTER_SEARCH_PARAM) as StatusFilterType) ?? 'in-progress';
  const setActiveStatusFilter = (statusFilter: StatusFilter) => {
    setSearchParams((oldParams) => {
      oldParams.set(STATUS_FILTER_SEARCH_PARAM, statusFilter.value);
      return oldParams;
    });
  };

  const baseStatusFilters: StatusFilter[] = useMemo(
    () => [
      {
        value: 'all',
        label: t('ALL'),
        active: false,
      },
      {
        value: 'in-progress',
        label: t('IN_PROGRESS'),
        active: false,
      },
      {
        value: 'completed',
        label: t('COMPLETED'),
        active: false,
      },
      {
        value: 'cancelled',
        label: t('CANCELLED'),
        active: false,
      },
      {
        value: 'failed',
        label: t('FAILED'),
        active: false,
      },
    ],
    [t]
  );

  const apiStatusFilter = useMemo(() => {
    if (!activeStatusFilter || activeStatusFilter === 'all') {
      return undefined;
    }
    // although cancelled filter inclued "CANCELLED" status, it's never used on BE
    if (activeStatusFilter === 'cancelled') {
      return 'TERMINATED';
    }
    const statusValues = STATUS_FILTER_MAP[activeStatusFilter].toString();
    return statusValues;
  }, [activeStatusFilter]);

  const statusFilters = useMemo(() => {
    return baseStatusFilters.map((statusFilter) => ({
      ...statusFilter,
      active: statusFilter.value === activeStatusFilter,
    }));
  }, [baseStatusFilters, activeStatusFilter]);

  return { statusFilters, activeStatusFilter, setActiveStatusFilter, apiStatusFilter };
};
