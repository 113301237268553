import { Stack } from '@mui/material';

import { Dropzone, ErrorMessageList, FileList, Fileupload } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';

import {
  acceptedTypes,
  customErrorCodes,
  maxFileBytesSize,
  validator,
} from '@vertice/core/src/modules/saas/contract/components/DocumentUpload/utils';
import { useTaskContext } from '../../../../TaskContext';
import { useTaskFormContext } from '../TaskFormContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import useRequestDocuments from '../../../../../../hooks/useRequestDocuments';

export const DocumentsSection = () => {
  const { taskOverview } = useTaskContext();
  const { readOnly } = useTaskFormContext();

  const requestId = taskOverview?.request?.ref.split('/').pop();
  const { files, isFetching, uploadFiles, deleteFile, downloadFile, allowDeletion } = useRequestDocuments(
    requestId ? requestId : ''
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Fileupload
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        multiple
        hideAcceptedTypes
        acceptedTypes={acceptedTypes}
        maxFileSize={maxFileBytesSize}
        files={files}
        onAddFiles={uploadFiles}
        onRemoveFiles={deleteFile}
        validator={validator}
        customErrorCodes={customErrorCodes}
      >
        <Stack gap={6}>
          <FileList
            size="M"
            hideErrorFiles
            dynamicFileNameLength
            onFileNameClick={downloadFile}
            hiddenColumns={allowDeletion && !readOnly ? ['date'] : ['date', 'remove']}
          />
          {!readOnly && (
            <Stack height={210} alignItems="center">
              <Dropzone size="M" />
            </Stack>
          )}
          <ErrorMessageList size="S" />
        </Stack>
      </Fileupload>
    </Stack>
  );
};
