import { Button, ButtonLabel } from '@verticeone/design-system';
import React from 'react';
import { ToggleButtonFieldProps, ToggleButtonOption } from '../FormToggleButtonField';

export type OptionButtonProps = {
  option: ToggleButtonOption;
  fieldProps: ToggleButtonFieldProps;
  onChange: (...event: any[]) => void;
  value: string;
};

export const OptionButton = ({ option, fieldProps, onChange, value }: OptionButtonProps) => {
  return (
    <Button
      key={option.value}
      value={option.value}
      variant={value === option.value ? 'ghost' : 'outline'}
      color={option.color ? option.color : fieldProps.color || 'primary'}
      isActive={!fieldProps.disabled || option.isActive || option.value === value}
      isLoading={option.isLoading}
      disabled={fieldProps.disabled}
      fullWidth
      onClick={() => {
        onChange(option.value);
        fieldProps.onClick?.(option.value);
      }}
      direction={fieldProps.compact ? 'horizontal' : 'vertical'}
      sx={{
        height: fieldProps.buttonHeight,
        marginTop: option.indentSize ?? 0,
      }}
    >
      {option.icon && <option.icon />}
      {option.element}
      {option.title}
      {option.label && <ButtonLabel>{option.label}</ButtonLabel>}
    </Button>
  );
};
