import React, { useRef } from 'react';
import { Popper, PopperProps, useTheme } from '@mui/material';
import { useOnClickOutside } from 'usehooks-ts';

type PopoverProps = {
  children: React.ReactNode;
  height?: number;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
} & Pick<PopperProps, 'open' | 'anchorEl' | 'id'>;

const Popover = ({ children, id, open, onClose, anchorEl, height = 250 }: PopoverProps) => {
  const { palette } = useTheme();
  const popperEl = useRef(anchorEl);

  useOnClickOutside(popperEl, onClose);

  return (
    <Popper
      id={id}
      open={open}
      ref={popperEl}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 4],
          },
        },
      ]}
      slotProps={{
        root: {
          style: {
            display: 'flex',
            flexDirection: 'column',
            padding: 16,
            gap: 16,
            borderRadius: 8,
            backgroundColor: palette.core.bg,
            border: `1px solid ${palette.core.color3}`,
            boxShadow: palette.global.getShadow({
              color: 'core',
              type: 'soft',
              depth: '3z',
              distance: '30',
            }),
            minWidth: anchorEl?.offsetWidth,
            height,
          },
        },
      }}
    >
      {children}
    </Popper>
  );
};

export default Popover;
