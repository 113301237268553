import React from 'react';
import { useTranslation } from 'react-i18next';
import { HourglassBottom } from '@mui/icons-material';
import RITransactions from './RITransactions';
import { GridRowId } from '@mui/x-data-grid-pro';
import { Dialog, DialogActions, DialogHeader } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import useLoadMoreRiPurchaseData from './useLoadMoreRiPurchaseData';
import { useCancelRiReservationMutation } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useCancelSpReservationMutation } from '../../../../pages/Cloud/CloudRIO/SavingsPlans/CloudOptimizeTab/dataSources/useCancelSpReservationMutation';

const RIPendingTransactions = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RI_PURCHASE' });
  const { data, refetch, onLoadMore, ...loadingStatus } = useLoadMoreRiPurchaseData(true);

  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [transactionsToCancel, setTransactionsToCancel] = React.useState<Map<GridRowId, any>>(new Map());
  const [cancelRiReservations] = useCancelRiReservationMutation();
  const { mutate: cancelSpReservations } = useCancelSpReservationMutation();
  const { accountId } = useAccountContext();
  const [isCancelling, setIsCanceling] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleNotConfirmed = () => setDialogOpen(false);
  const handleConfirmed = () => {
    setIsCanceling(true);

    const transactions = Array.from(transactionsToCancel.values());
    const riTransactionsToCancel = transactions.filter(({ type }) => type === 'ri');
    const spTransactionsToCancel = transactions.filter(({ type }) => type === 'sp');
    const count = transactionsToCancel.size;

    const onSuccess = () => {
      setTimeout(() => {
        enqueueSnackbar(t('SNACKBAR.SUCCESS', { count }), { variant: 'success' });
        if (refetch) {
          void refetch();
        }
        setDialogOpen(false);
        setIsCanceling(false);
        setTransactionsToCancel(new Map());
      }, 1000 * count);
    };

    const onError = () => {
      enqueueSnackbar(t('SNACKBAR.ERROR', { count }), { variant: 'error' });
      setDialogOpen(false);
      setIsCanceling(false);
      setTransactionsToCancel(new Map());
    };

    if (spTransactionsToCancel.length > 0) {
      cancelSpReservations(
        spTransactionsToCancel.map((transaction) => ({
          savingsPlanType: transaction.spType,
          purchaseOrderId: transaction.id,
        })),
        {
          onSuccess: () => {
            onSuccess();
          },
          onError: () => {
            onError();
          },
        }
      );
    }

    if (riTransactionsToCancel.length > 0) {
      cancelRiReservations({
        accountId: accountId!,
        deletions: riTransactionsToCancel.map((transaction) => ({
          service: transaction.riType,
          region: transaction.region,
          purchaseOrderId: transaction.id,
        })),
      })
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    }
  };

  const handleCancelTransactions = (rows: Map<GridRowId, any>) => {
    setDialogOpen(true);
    setTransactionsToCancel(rows);
  };

  return (
    <>
      <RITransactions
        isPending={true}
        title={t('PENDING_TITLE')}
        icon={HourglassBottom}
        testId="ri-pending-transactions"
        visibleRows={10}
        onCancelTransactions={handleCancelTransactions}
        onLoadMore={onLoadMore}
        data={data}
        {...loadingStatus}
      />
      <Dialog open={isDialogOpen} align="left" size="M">
        <DialogHeader>{t('CONFIRMATION_MODAL.TITLE', { count: transactionsToCancel.size })}</DialogHeader>
        <DialogActions>
          <Button onClick={handleNotConfirmed} variant="outline" color="tertiary" disabled={isCancelling}>
            {t('CONFIRMATION_MODAL.BUTTONS.DISCARD')}
          </Button>
          <Button onClick={handleConfirmed} variant="solid" color="tertiary" isLoading={isCancelling}>
            {t('CONFIRMATION_MODAL.BUTTONS.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RIPendingTransactions;
