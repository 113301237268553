import { CostModelBase } from './types';
import { COST_MODEL_CLASS, getCostModelBase } from './AdvancedLinearApproximation/AdvancedLinearApproximation';

export type UseDefaultCostModelReturn = {
  className: string;
  getCostModelBase: () => CostModelBase;
};

export const useDefaultCostModel = (): UseDefaultCostModelReturn => {
  return {
    className: COST_MODEL_CLASS,
    getCostModelBase: getCostModelBase,
  };
};
