import { ChangeEvent, ComponentProps, FC } from 'react';
import { useController } from 'react-hook-form';
import ContractFormEntry from '../../ContractFormEntry';
import { CommonFormFieldProps } from '../../../../forms/types';
import { Checkbox, Text, FormControlLabel } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

type Component = typeof TotalCostOverrideCheckboxField;

type TotalCostOverrideCheckboxFieldProps = CommonFormFieldProps<never> & ComponentProps<typeof Checkbox>;

const TotalCostOverrideCheckboxField: FC<TotalCostOverrideCheckboxFieldProps> = (props) => {
  const { t } = useTranslation();
  const { required, name, labelId, ...otherProps } = props;

  const {
    field: { ref, value, onChange, ...field },
  } = useController({ name, rules: { required } });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);

  return (
    <FormControlLabel
      checked={value ?? false}
      size="XXS"
      control={<Checkbox inputRef={ref} size="XXS" onChange={handleChange} {...field} {...otherProps} />}
      label={
        <Stack>
          <Text variant="caption" size="XS">
            {t('ENTITIES.CONTRACT.LABELS.TOTAL_BASELINE_VALUE_OVERRIDE')}
          </Text>
        </Stack>
      }
    />
  );
};

export const BaselineTotalCostOverrideFormEntry = () => {
  return (
    <ContractFormEntry<Component, Component>
      name="costModel.baseline.model.totalCostOverride.enabled"
      width={12}
      component={TotalCostOverrideCheckboxField}
      readFormFieldDisabled
      writeComponent={TotalCostOverrideCheckboxField}
    />
  );
};
