import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

const ChartFilter = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="row" justifyContent="flex-end" px={3} gap={2}>
      {children}
    </Stack>
  );
};

export default ChartFilter;
