import { FC, useEffect } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContractDatesForm } from './types';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import FormDateField from '../../../../../../forms/fields/FormDateField';
import { getStartedMonthsBetweenDates } from '../../../../../../saas/contract/computed';
import FormNumberField from '../../../../../../forms/fields/FormNumberField';
import { SimpleOption } from '@verticeone/design-system';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../../../../forms/fields/FormSelectField2';
import useBillingFrequencyOptions from '../../../../../../saas/contract/hooks/useBillingFrequencyOptions';

export type FixedTermFieldsProps = {
  control: Control<ContractDatesForm>;
  setValue: UseFormSetValue<ContractDatesForm>;
};

const ContractDatesFormEntry = createTypedFormEntry<ContractDatesForm>();

export const FixedTermFields: FC<FixedTermFieldsProps> = (props) => {
  const { control, setValue } = props;
  const { t } = useTranslation();

  const [startDate, renewalDate] = useWatch({ control, name: ['startDate', 'renewalDate'] });
  const contractLength = useWatch({ control, name: 'contractLength' });

  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  useEffect(() => {
    const computedContractLength = getStartedMonthsBetweenDates(startDate, renewalDate);
    setValue('contractLength', computedContractLength);
  }, [startDate, renewalDate, setValue]);

  return (
    <>
      <ContractDatesFormEntry
        name="contractLength"
        label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH')}
        component={FormNumberField}
        disabled={true}
        componentProps={{ unit: t('UNITS.MONTH', { count: contractLength || 0 }) }}
      />
      <ContractDatesFormEntry<FormSelectFieldComponentType<ContractDatesForm, SimpleOption, false>>
        name="billingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        component={FormSelectField2}
        componentProps={{
          options: billingFrequencyOpts,
          boxValue: (value) => (value ? billingFrequencyMap[value as string] : undefined),
          unboxValue: (selectValue) => selectValue?.value,
        }}
      />
      <ContractDatesFormEntry
        name="renewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_DATE')}
        component={FormDateField}
        required
      />
      <ContractDatesFormEntry
        name="autoRenewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')}
        component={FormDateField}
      />
    </>
  );
};
