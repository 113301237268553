import { FieldValues, useController } from 'react-hook-form';
import { Stack, useTheme } from '@mui/material';
import { CheckboxLabel, Text } from '@verticeone/design-system';
import { Checkbox, CheckboxProps } from '@verticeone/design-system';
import { FormControlLabel } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';

export type FormChecklistItemFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> &
  CheckboxProps & {
    label?: string;
    description?: string;
  };

const FormChecklistItemField = <FormDataType extends FieldValues>({
  required,
  name,
  label,
  description,
  size = 'M',
  labelId, // We don't want to further pass the labelId
  ...otherProps
}: FormChecklistItemFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
  } = useController({ name, rules: { required } });

  const { palette } = useTheme();

  return (
    <FormControlLabel
      checked={value ?? false}
      // This changes the clickable area of the checkbox to be
      // only the checkbox and label instead of the entire row.
      sx={{ maxWidth: 'max-content', gap: 0 }}
      control={<Checkbox inputRef={ref} size={size} {...field} {...otherProps} />}
      label={
        <Stack gap="2px">
          <CheckboxLabel size={size} required={required}>
            {label}
          </CheckboxLabel>
          <Text variant="body-regular" size={size} color={palette.text.color4}>
            {description}
          </Text>
        </Stack>
      }
    />
  );
};

export default FormChecklistItemField;
