import React from 'react';
import { useTranslation } from 'react-i18next';
import StatCard from './StatCard/StatCard';
import { AccountTreeOutlined } from '@mui/icons-material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import useRequestsListStatsData from '../dataSource/useRequestsListStatsData';

const RequestsStatCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { data, isFetching } = useRequestsListStatsData();

  return (
    <StatCard
      isLoading={isFetching}
      onClick={() => {
        navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS);
      }}
      testId="requests-stat-card"
      title={t('OVERVIEW.STATS.REQUESTS.TITLE')}
      subtitle={t('OVERVIEW.STATS.REQUESTS.SUBTITLE', { count: data.totalNumberOfRequests })}
      value={data.totalNumberOfOverdueRequests}
      name={t('TASK_STATUS.OVERDUE')}
      variant="info"
      icon={AccountTreeOutlined}
    />
  );
};

export default RequestsStatCard;
