import { Button, IconWrapper } from '@verticeone/design-system';
import { CodeOffOutlined, CodeOutlined } from '@mui/icons-material';
import { FC } from 'react';
import type { FlowEdgeCondition } from '../../../../definitionsTypes';
import { useTranslation } from 'react-i18next';

type ConditionTypeSwitchProps = {
  isJsonLogicCondition: boolean;
  isJMESPathExpressionCondition: boolean;
  isEdgeFromJoinGateway: boolean;
  isEdgeFromForkGateway: boolean;
  isDefaultEdge: boolean;
  handleConditionChange: (condition: string, conditionType?: FlowEdgeCondition['conditionType']) => void;
};

/**
 * Switch between JsonLogic (created via QueryBuilder) and JMESPathExpression(free textarea edit) condition types
 */

export const ConditionTypeSwitch: FC<ConditionTypeSwitchProps> = ({
  isJsonLogicCondition,
  isJMESPathExpressionCondition,
  isEdgeFromJoinGateway,
  isEdgeFromForkGateway,
  isDefaultEdge,
  handleConditionChange,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY.EDGES' });

  // in existing workflows some edges leaving join have an empty condition, but it's not editable so we hide the switch as well
  return (
    !isEdgeFromJoinGateway && (
      <>
        {/* In existing workflows, some edges leaving forks are default, which is equivalent to an edge with an empty condition. */}
        {/*so we should allow to switch advanced mode in this context*/}
        {(isJsonLogicCondition || (isEdgeFromForkGateway && isDefaultEdge)) && (
          <Button variant="plain" size="XXS" onClick={() => handleConditionChange('', 'JMESPathExpression')}>
            <IconWrapper size="XXS" icon={CodeOutlined} />
            {t('EXPRESSION_SWITCH_TO_ADVANCED')}
          </Button>
        )}
        {isJMESPathExpressionCondition && (
          <Button variant="plain" size="XXS" onClick={() => handleConditionChange('', 'JsonLogic')}>
            <IconWrapper size="XXS" icon={CodeOffOutlined} />
            {t('EXPRESSION_SWITCH_TO_BASIC')}
          </Button>
        )}
      </>
    )
  );
};
