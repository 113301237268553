import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { JiraProject } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/types';
import {
  useGetAccountIntegrationQuery,
  useListJiraFieldOptionsQuery,
  useListJiraIssueTypesQuery,
} from '@vertice/slices';
import { TriggerType } from '../components/WorkflowTriggers/types';
import { JIRA_PROVIDER_ID } from '../constants';

export const useJiraTriggerForm = (triggerType: TriggerType, selectedProjectId: string, selectedIssueId: string) => {
  const { accountId } = useAccountContext();

  const { data: jiraIntegrationData, isLoading: isLoadingJiraIntegration } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: JIRA_PROVIDER_ID,
  });
  const projects = (jiraIntegrationData?.parameters?.projects ?? []) as JiraProject[];

  const { data: jiraIssueTypesData, isFetching: isFetchingJiraIssueTypes } = useListJiraIssueTypesQuery(
    {
      accountId,
      projectId: selectedProjectId,
    },
    { skip: !selectedProjectId }
  );
  const issueTypes = jiraIssueTypesData?.issueTypes ?? [];

  const { data: jiraFieldOptionsData, isFetching: isFetchingJiraFieldOptions } = useListJiraFieldOptionsQuery(
    {
      accountId,
      projectId: selectedProjectId,
      issueTypeId: selectedIssueId,
      fieldId: 'status',
    },
    { skip: !selectedProjectId || !selectedIssueId || triggerType === 'ISSUE_CREATED' }
  );
  const statuses = jiraFieldOptionsData?.items ?? [];

  return {
    projects,
    issueTypes,
    statuses,
    isLoadingJiraIntegration,
    isFetchingJiraIssueTypes,
    isFetchingJiraFieldOptions,
  };
};
