import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useListWorkflowsRequestsPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import {
  isGenericRenewalServiceRef,
  isRequestRef,
  isSaasRenewalServiceRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import {
  NotifMessage,
  useNotificationWebSocketSubscription,
} from '@vertice/core/src/contexts/NotificationWebSocketContext';

const isCurrentContract = (contractId: string) => (message: NotifMessage) => {
  if (isRequestRef(message)) {
    return message.message_attributes.contractRef?.includes(contractId) ?? false;
  }
  return false;
};

export const useContractRenewalRequests = (contract: Contract) => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const {
    data: requests,
    isLoading,
    refetch: refetchLinkedRequests,
  } = useListWorkflowsRequestsPaginatedQuery(
    {
      accountId,
      contractId: contract.record.contractId,
    },
    { skip: !isIWEnabled }
  );

  useNotificationWebSocketSubscription({
    filter: isCurrentContract(contract.record.contractId),
    callback: refetchLinkedRequests,
  });

  const linkedRequests =
    requests?.items.filter(
      (request) => isSaasRenewalServiceRef(request.serviceRef) || isGenericRenewalServiceRef(request.serviceRef)
    ) || [];

  return {
    linkedRequests,
    isLoadingLinkedRequests: isLoading,
  };
};
