import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text, Drawer, Divider, ToggleSwitch } from '@verticeone/design-system';

import { DrawerBody, DrawerContent, DrawerHeader } from '../Drawer';
import { MemoizedWorkflowsTable } from '../WorkflowsTable/WorkflowsTable';
import type { ServiceCatalogResource } from '../../../catalogResource/types';

type ServiceDrawerBodyProps = {
  onClose: () => void;
  service: ServiceCatalogResource;
};

const ServiceDrawerBody: FC<ServiceDrawerBodyProps> = ({ onClose, service }) => {
  const [showArchivedWorkflows, setShowArchivedWorkflows] = useState(false);

  const { t } = useTranslation();

  return (
    <DrawerBody>
      <DrawerHeader title={service.name} onClose={onClose} />
      <Divider />
      <DrawerContent>
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Stack direction="row" gap={2} alignItems="center">
            <ToggleSwitch
              value={showArchivedWorkflows}
              defaultChecked={false}
              size="XS"
              color="primary"
              onChange={(e) => setShowArchivedWorkflows(e.target.checked)}
            />
            <Text variant="body-regular" color="text1" size="XS">
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.LABELS.SHOW_ARCHIVED')}
            </Text>
          </Stack>
        </Stack>
        <Stack minHeight={0}>
          {service && <MemoizedWorkflowsTable service={service} showArchived={showArchivedWorkflows} />}
        </Stack>
      </DrawerContent>
    </DrawerBody>
  );
};

type ServiceDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  service?: ServiceCatalogResource;
};

export const ServiceDrawer: FC<ServiceDrawerProps> = ({ isOpen, onClose, service }) => {
  return (
    <Drawer
      disableRestoreFocus
      onClose={onClose}
      width={`min(max(calc((100vw / 12) * 7), 900px), 1000px)`}
      open={isOpen}
    >
      {service && <ServiceDrawerBody onClose={onClose} service={service} />}
    </Drawer>
  );
};
