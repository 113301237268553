import * as z from 'zod';

export const urlPathSchema = z.string().refine(
  (val) => {
    if (val === '/') {
      return false;
    }
    try {
      // The URL requires a full address, so we are using a dummy domain to fulfill this requirement
      const url = new URL(val, 'https://example.com');
      return url.pathname + url.search === val;
    } catch {
      return false;
    }
  },
  {
    message: 'Invalid URL path',
  }
);

export const createSchema = z.object({
  name: z.string().min(1).max(64),
  description: z.string().optional(),
  connection: z.string().min(1),
  connection_url: urlPathSchema,
  variables: z.array(
    z.object({
      source: z.object({
        type: z.enum(['Const', 'Variable']),
        value: z.string().min(1),
      }),
      target: z.string().min(1),
    })
  ),
  headers: z.array(
    z.object({
      name: z.string().min(1),
      value: z.string().min(1),
      isDeletable: z.boolean(),
      isEditable: z.boolean(),
    })
  ),
  method: z.enum(['GET', 'POST', 'PUT', 'DELETE']),
  body: z.string(),
});

export type FormSchema = z.infer<typeof createSchema>;
