import { PredefinedFormDef } from '../shared/types';
import { RequirementsGatheringForm } from './RequirementsGatheringForm';
import { zodSchema, FormData } from './schema';
import { getApprovedBudget, getNegotiationValues } from '../shared/utils';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/(purchase\/)?requirementsGathering\/v\d+$/,
  component: RequirementsGatheringForm,
  schema: zodSchema,
  draftEnabled: true,
  pages: [
    {
      title: 'Vendor & Products',
      id: 'vendor_and_products',
      fields: ['vendor', 'products'],
    },
    {
      title: 'Documents',
      id: 'documents',
      fields: [],
    },
    {
      title: 'Contract details',
      id: 'contract_details',
      fields: ['targetSignDate', 'approvedBudget', 'contractCurrency', 'additionalNotes', 'department'],
    },
    {
      title: 'Contact & Permissions',
      id: 'contact_and_permissions',
      fields: [],
    },
  ],
  getDefaultValues: (getValue) => ({
    vendor: getValue('vendor', null),
    products: getValue('products', []),
    additionalNotes: getValue('additionalNotes'),
    department: getValue('department', null),
    contractCurrency: getValue('contractCurrency', null),
    targetSignDate: getValue('targetSignDate', null),
    approvedBudget: getValue('approvedBudget', null),
    verticeNegotiationRequested: getValue('verticeNegotiationRequested', false),
    internalNegotiationRequested: getValue('internalNegotiationRequested', false),
    unknownBudget: getValue('unknownBudget', false),
    signingEntity: getValue('signingEntity'),
    desiredContractLength: getValue('desiredContractLength'),

    legalReviewRequired: getValue('legalReviewRequired'),
    securityReviewRequired: getValue('securityReviewRequired'),
    piiExpectedToBeHeld: getValue('piiExpectedToBeHeld'),

    contactVendorDirectly: getValue('contactVendorDirectly'),
    discussMultiYearDeals: getValue('discussMultiYearDeals'),
    discussPlannedGrowth: getValue('discussPlannedGrowth'),
    discussCompetitors: getValue('discussCompetitors'),
    discussCaseStudies: getValue('discussCaseStudies'),
    parentAccountId: getValue('parentAccountId'),
    negotiationTier: getValue('negotiationTier'),
  }),
  transformOutput: (formData) => ({
    ...formData,
    desiredContractLength: formData.desiredContractLength ?? undefined,
    approvedBudget: getApprovedBudget(formData),
    ...getNegotiationValues(formData),
  }),
};
