import { useCallback, useMemo } from 'react';
import { FormSchema } from './form/formSchema';
import { ProcessDefinition, TaskDefinition } from '../../../../../definitionsTypes';
import { IOMapping } from '../../../../../models/ioMapping';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { isTaskIOMappingConfiguration } from '../../../../../pocWorkflowSchema';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { DEFINITION_VERSION } from '../../../../../definitions/constants';
import { Variable } from '../../../types';

type useGetVariableListProps = {
  task: TaskDefinition;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
};

type useTransformTaskDefinitionToFormProps = {
  task: TaskDefinition;
  variables: Array<Variable>;
};

enum TaskVariables {
  INTEGRATION_ID = 'integrationId',
  PATH = 'path',
  METHOD = 'method',
  VERTICE_ACCOUNT_ID = 'verticeAccountId',
  PAYLOAD = 'payload',
  HEADERS = 'headers',
}

const transformValue = (value: string, type: 'Const' | 'Variable') => {
  return type === 'Const' ? `\`${value}\`` : value;
};

/**
 * Transforms the body value by removing surrounding backticks and escaping double quotes.
 *
 * Data interpretation:
 * - Input: "`{\"a\": {{b}}}`"
 * - IAT field interpretation: "'{\"a\": {{b}}}'"
 * - Output: CFA body text area interpretation: {"a": {{b}}}
 *
 * @param value - The input string to be transformed.
 * @returns The transformed string.
 */
export const transformBodyValue = (value: string): string => {
  return `'${value.replace(/"/g, '"')}'`;
};

/**
 * Reverses the transformation of the body value by removing surrounding single quotes
 * and unescaping double quotes.
 *
 * Data interpretation:
 * - Input: "'{\"a\": {{b}}}'"
 * - Output: CFA body text area interpretation: {"a": {{b}}}
 *
 * @param value - The input string to be reversed.
 * @returns The reversed string.
 */
export const reverseTransformBodyValue = (value: string | undefined): string => {
  if (!value) {
    return '';
  }
  if (value?.startsWith("'") && value?.endsWith("'")) {
    value = value.slice(1, -1);
  }
  return value?.replace(/\\"/g, '"');
};

const getValueType = (value: string): 'Const' | 'Variable' => {
  return value.startsWith('`') && value.endsWith('`') ? 'Const' : 'Variable';
};

const parseConstant = <R extends string>(value: R | undefined): R => {
  return value?.replace(/^.(.*).$/s, '$1') as R;
};

export const useTransformFormToTaskDefinition = (task: TaskDefinition) => {
  const { accountId } = useAccountContext();

  return useCallback(
    (form: FormSchema): TaskDefinition => {
      const newMapping = new IOMapping(task.task.configurations?.find(isTaskIOMappingConfiguration));
      newMapping.clearInputMapping();

      form.variables.forEach(({ target, source }) => {
        newMapping.addInputMapping(target, transformValue(source.value, 'Variable'));
      });
      newMapping.addInputMapping(TaskVariables.INTEGRATION_ID, transformValue(form.connection, 'Const'));
      newMapping.addInputMapping(TaskVariables.PATH, transformValue(form.connection_url, 'Const'));
      newMapping.addInputMapping(TaskVariables.METHOD, transformValue(form.method, 'Const'));
      newMapping.addInputMapping(TaskVariables.VERTICE_ACCOUNT_ID, transformValue(accountId, 'Const'));
      newMapping.addInputMapping(TaskVariables.PAYLOAD, transformBodyValue(form.body));
      newMapping.addInputMapping(
        TaskVariables.HEADERS,
        transformValue(
          JSON.stringify(
            form.headers
              .filter((item) => !['Authorization', 'Content-Type'].includes(item.name))
              .reduce(
                (acc, header) => ({
                  ...acc,
                  [header.name]: header.value,
                }),
                {}
              )
          ),
          'Const'
        )
      );

      return {
        ...task,
        task: {
          ...task.task,
          name: form.name,
          description: form.description,
          configurations: [
            ...(task.task.configurations?.filter((config) => !isTaskIOMappingConfiguration(config)) ?? []),
            {
              kind: 'ProcessEngine:TaskIOMapping',
              version: DEFINITION_VERSION,
              mapping: newMapping.getIOMapping(),
            },
          ],
        },
      };
    },
    [task, accountId]
  );
};

export const useGetVariableList = ({ task, processDefinition, workflowServiceRef }: useGetVariableListProps) => {
  const { requestVariables, udfVariables, isFetching } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const variables = useMemo(() => {
    const getVariables = (vars: Array<Variable>): Array<Variable> => {
      return [
        ...vars,
        ...vars.map((variable) => (variable.variables.length ? getVariables(variable.variables) : [])),
      ].flat();
    };

    return getVariables([...udfVariables, ...requestVariables]);
  }, [udfVariables, requestVariables]);

  return { variables, isFetching };
};

export const useTransformTaskDefinitionToForm = ({
  task,
  variables: variableList,
}: useTransformTaskDefinitionToFormProps): FormSchema => {
  const mapping = useMemo(() => new IOMapping(task.task.configurations?.find(isTaskIOMappingConfiguration)), [task]);

  const variables = useMemo(() => {
    return mapping
      .getIOMapping()
      .inputFields.filter((field) => !Object.values<string>(TaskVariables).includes(field.name))
      .map((variable) => {
        if (getValueType(variable.value) === 'Variable') {
          const foundVariable = variableList.find((item) => item.id === variable.value);

          return {
            source: {
              type: foundVariable ? ('Variable' as const) : ('Const' as const),
              value: foundVariable?.id ?? variable.value,
            },
            target: variable.name,
          };
        }

        return {
          source: {
            type: 'Const' as const,
            label: parseConstant(variable.value),
            value: parseConstant(variable.value),
          },
          target: variable.name,
        };
      });
  }, [variableList, mapping]);

  const headers = useMemo(() => {
    const defaultHeaders = {
      Authorization: 'Bearer ****',
      'Content-Type': 'application/json',
    };

    return Object.entries({
      ...defaultHeaders,
      ...JSON.parse(parseConstant(mapping.getInputIOMapping('headers')?.value) ?? '{}'),
    }).map(([key, value]: any) => ({
      name: key as string,
      value: value as string,
      isDeletable: !['Authorization', 'Content-Type'].includes(key),
      isEditable: !['Authorization', 'Content-Type'].includes(key),
    }));
  }, [mapping]);

  return useMemo(
    () => ({
      name: task.task.name ?? '',
      description: task.task.description ?? '',
      connection: parseConstant(mapping.getInputIOMapping(TaskVariables.INTEGRATION_ID)?.value) ?? '',
      connection_url: parseConstant(mapping.getInputIOMapping(TaskVariables.PATH)?.value) ?? '',
      variables,
      headers,
      method: (parseConstant(mapping.getInputIOMapping(TaskVariables.METHOD)?.value) as FormSchema['method']) ?? 'GET',
      body: reverseTransformBodyValue(parseConstant(mapping.getInputIOMapping(TaskVariables.PAYLOAD)?.value)) ?? '',
    }),
    [task, variables, headers, mapping]
  );
};
