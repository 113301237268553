import { useCallback, useMemo, useState } from 'react';
import { CommentMetadata, MentionUser, useCommentContent } from '@verticeone/design-system';
import { AccountUser } from '@vertice/slices';

type UseConversationSearchReturn = {
  commentIndex: number | null;
  commentsLength: number;
  commentId: string | null;
  hasNext: boolean;
  goToNext: () => void;
  hasPrevious: boolean;
  goToPrevious: () => void;
};

export const useConversationSearch = ({
  search,
  messages,
  userId,
  users,
}: {
  search: string | null;
  messages: CommentMetadata[];
  userId: string;
  users: Record<string, AccountUser | MentionUser>;
}): UseConversationSearchReturn => {
  const [currentSearchCommentIndex, setCurrentSearchCommentIndex] = useState<number | null>(null);
  const { getCommentContent } = useCommentContent(userId, users);

  const commentsWithMentions = useMemo(
    () =>
      messages.map((msg) => ({
        ...msg,
        content: getCommentContent(msg.content)
          .map((contentPart) => (typeof contentPart === 'string' ? contentPart : contentPart.label.replace('@', '')))
          .join('')
          .toLowerCase(),
      })),
    [messages, getCommentContent]
  );

  const searchedCommentsIds = useMemo(() => {
    if (!search || search === '') {
      setCurrentSearchCommentIndex(null);
      return [];
    }

    const comments = commentsWithMentions.filter((comment) => comment.content.includes(search.toLowerCase()));

    setCurrentSearchCommentIndex(comments.length > 0 ? comments.length - 1 : null);
    return comments.map((comment) => comment.messageId);
  }, [search, commentsWithMentions]);

  const hasNext =
    currentSearchCommentIndex !== null ? currentSearchCommentIndex < searchedCommentsIds.length - 1 : false;
  const hasPrevious = currentSearchCommentIndex !== null ? currentSearchCommentIndex > 0 : false;

  const goToNext = useCallback(() => {
    if (!hasNext || currentSearchCommentIndex === null) return;

    setCurrentSearchCommentIndex(currentSearchCommentIndex + 1);
  }, [hasNext, currentSearchCommentIndex]);

  const goToPrevious = useCallback(() => {
    if (!hasPrevious || currentSearchCommentIndex === null) return;
    setCurrentSearchCommentIndex(currentSearchCommentIndex - 1);
  }, [hasPrevious, currentSearchCommentIndex]);

  return {
    commentIndex: currentSearchCommentIndex,
    commentsLength: searchedCommentsIds.length,
    commentId: currentSearchCommentIndex !== null ? searchedCommentsIds[currentSearchCommentIndex] : null,
    hasNext,
    goToNext,
    hasPrevious,
    goToPrevious,
  };
};
