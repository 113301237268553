import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';
import { keyBy, Dictionary } from 'lodash';

const useBillingFrequencyOptions = () => {
  const { t } = useTranslation();

  const options = useMemo<SimpleOption[]>(
    () => [
      {
        value: 'MONTHLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_MONTHLY'),
      },
      {
        value: 'QUARTERLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_QUARTERLY'),
      },
      {
        value: 'ANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ANNUALLY'),
      },
      {
        value: 'BIANNUALLY',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_BIANNUALLY'),
      },
      {
        value: 'ONE_OFF',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ONE_OFF'),
      },
      {
        value: 'OTHER',
        label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_OTHER'),
      },
    ],
    [t]
  );

  const optionsMap = useMemo<Dictionary<SimpleOption>>(() => keyBy(options, 'value'), [options]);

  const getLabel = useCallback(
    (value?: string | null) => {
      if (!value) return null;
      return optionsMap[value]?.label ?? null;
    },
    [optionsMap]
  );

  return { options, optionsMap, getLabel };
};

export default useBillingFrequencyOptions;
