import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAccountPreferredCurrency } from '@vertice/core/src/modules/account/useAccountPreferredCurrency';
import { Wizard } from '@vertice/core/src/components/Wizard';
import SelectVendorStep from './Steps/SelectVendor/SelectVendorStep';
import { AddExistingContractFormData } from './types';
import AdditionalInformationStep from './Steps/AdditionalInformation/AdditionalInformationStep';
import ContractTypeStep from './Steps/ContractType/ContractTypeStep';
import UploadExistingContractStep from './Steps/UploadExistingContract/UploadExistingContractStep';
import { SpendDetailsStep } from './Steps/SpendDetails/SpendDetailsStep';
import ContractAddedStep from './Steps/ContractAdded/ContractAddedStep';
import ChooseInitiateRenewalStep from './Steps/ChooseInitiateRenewal/ChooseInitiateRenewalStep';
import ConfirmationStep from './Steps/Confirmation/ConfirmationStep';
import AdditionalRequirementsStep from '../SharedRenewalSteps/AdditionalRequirements/AdditionalRequirementsStep';
import RenewalInProgressStep from '../SharedRenewalSteps/RenewalInProgress/RenewalInProgressStep';
import ConfirmRenewalRequirementsStep from '../SharedRenewalSteps/ConfirmRenewalRequirements/ConfirmRenewalRequirementsStep';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useIntakeForm from '../../../hooks/useIntakeForm';
import useNavigateIf from '@vertice/core/src/hooks/useNavigateIf';
import { useRoutes } from '@verticeone/router/useRoutes';
import { LoadableComponent } from '@verticeone/design-system';
import { useDepartmentOptions } from '@vertice/core/src/modules/departments/FormDepartmentSelectField';
import { ONLY_OWNED_DEPARTMENTS } from './constants';

const defaultValuesBase: AddExistingContractFormData = {
  type: 'add_existing_contract',
  files: [],
  vendor: null,
  products: [],
  lineItems: [],
  totalAnnualCost: null, // Deprecated
  contractCurrency: undefined,
  contractCategory: 'SAAS',
  billingFrequency: null,
  billingFrequencyOther: null,
  billingFrequencySameAsRolling: true,
  rollingFrequency: null,
  departmentId: null,
  contractType: null,
  approvedBudget: null,
  signingEntity: '',
  renewalType: null,
  legalReviewRequired: false,
  securityReviewRequired: false,
  piiExpectedToBeHeld: false,
  contactVendorDirectly: true,
  discussMultiYearDeals: true,
  discussPlannedGrowth: true,
  discussCompetitors: true,
  discussCaseStudies: false,
  initiateRenewal: null,
  signatoryName: '',
  signatoryEmail: '',
  vendorContactEmail: '',
  vendorContactName: '',
  contractLength: null,
  annualCostOverride: {
    amount: 0,
    enabled: false,
  },
  totalCostOverride: {
    amount: 0,
    enabled: false,
  },
};

export const AddExistingContractWizardForm = ({
  defaultValues,
  vendorId,
}: {
  defaultValues: AddExistingContractFormData;
  vendorId?: string;
}) => {
  const formMethods = useForm<AddExistingContractFormData>({ defaultValues, mode: 'onChange' });

  const initialStep = vendorId ? 'upload_existing_contract' : 'select_vendor';

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={() => formMethods.handleSubmit} p={10}>
        <Wizard initialStep={initialStep} options={{ defaultWidth: 800 }}>
          <SelectVendorStep stepId="select_vendor" />
          <ContractTypeStep stepId="contract_type" />
          <UploadExistingContractStep stepId="upload_existing_contract" />
          <SpendDetailsStep stepId="spend_details" fullWidth={true} />
          <AdditionalInformationStep stepId="additional_information" />
          {/* existing contract has been registered */}
          <ContractAddedStep stepId="contract_added" />
          {/* choose whether to initiate renewal */}
          <ChooseInitiateRenewalStep stepId="choose_initiate_renewal" />
          {/* renewal NOT requested */}
          <ConfirmationStep stepId="confirmation" />
          {/* renewal requested */}
          <ConfirmRenewalRequirementsStep stepId="confirm_renewal_requirements" fullWidth={true} />
          <AdditionalRequirementsStep stepId="additional_requirements" />
          <RenewalInProgressStep stepId="renewal_in_progress" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export const AddExistingContractWizardV2 = () => {
  const routes = useRoutes();
  const { userCanAddExistingContract, isLoading } = useIntakeForm();
  useNavigateIf(!userCanAddExistingContract && !isLoading, routes.CONTRACTS);

  const { id: vendorId } = useParams();

  const departmentsQuery = useDepartmentOptions({ onlyOwnedDepartments: ONLY_OWNED_DEPARTMENTS });
  const accountPreferredCurrencyQuery = useAccountPreferredCurrency();

  const defaultValues = useMemo(
    (): AddExistingContractFormData | undefined =>
      !departmentsQuery.isLoading && !accountPreferredCurrencyQuery.isLoading
        ? {
            ...defaultValuesBase,
            departmentId: departmentsQuery.data.find((d) => d.code === 'DEFAULT')?.departmentId ?? null,
            contractCurrency: accountPreferredCurrencyQuery.data,
          }
        : undefined,
    [accountPreferredCurrencyQuery, departmentsQuery]
  );

  return (
    <LoadableComponent isLoading={!defaultValues}>
      <AddExistingContractWizardForm defaultValues={defaultValues!} vendorId={vendorId} />
    </LoadableComponent>
  );
};
