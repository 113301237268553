import { DeepPartial } from 'react-hook-form';
import { ContractReviewForm } from '../../../types';
import { SummaryRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { useDepartmentOptions } from '../../../../../../../departments/FormDepartmentSelectField';
import { useMemo } from 'react';

type UseDetailSummaryRecordsProps = {
  values: DeepPartial<ContractReviewForm>;
};

export const useDetailsSummaryRecords = (props: UseDetailSummaryRecordsProps) => {
  const { values } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.LABELS' });

  const departments = useDepartmentOptions({ onlyOwnedDepartments: false });

  const departmentLabel = useMemo(() => {
    if (departments.isLoading) return '';

    const department = departments.data.find((v) => v.departmentId === values.contractDetails?.departmentId);
    return department?.name ?? null;
  }, [departments, values]);

  const records = useMemo<SummaryRecord[]>(() => {
    if (!values.contractDetails) return [];

    return [
      { label: t('VENDOR'), value: values.contractDetails.vendor?.name ?? '-' },
      { label: t('DEPARTMENT'), value: departmentLabel ?? '-' },
    ];
  }, [departmentLabel, values, t]);

  return { records };
};
