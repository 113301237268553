import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllTasksData } from './useAllTasksData';
import ListCard from './ListCard';
import { ListItem } from './ListItem/ListItem';
import { CelebrationOutlined, FactCheckOutlined } from '@mui/icons-material';
import { Placeholder, Tab, Tabs, TabsBottomLineWrapper } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { Stack } from '@mui/material';
import { useUnassignedTasksData } from './useUnassignedTasksData';
import { LIMIT_ITEMS } from './constants';
import EmptyStateNoData from '../EmptyStateNoData';

type TabType = 'all' | 'unassigned';

const TEST_ID = 'active-tasks-card';

const ActiveTasksCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.ACTIVE_TASKS' });
  const { data: allTasksData, isFetching: isAllTasksFetching, isEmpty: isAllTasksEmpty } = useAllTasksData();
  const {
    data: unassignedTasksData,
    isFetching: isUnassignedTasksFetching,
    isEmpty: isUnassignedTasksEmpty,
  } = useUnassignedTasksData();
  const isLoading = isAllTasksFetching || isUnassignedTasksFetching;
  const isEmpty = isAllTasksEmpty && isUnassignedTasksEmpty;

  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const [activeTab, setActiveTab] = useState<TabType>('all');

  const tabs: TabType[] = ['all', 'unassigned'];

  const data = {
    all: allTasksData,
    unassigned: unassignedTasksData,
  }[activeTab];

  const tabLabels = {
    all: t('TABS.ALL_TAB'),
    unassigned: t('TABS.UNASSIGNED_TAB'),
  };

  const isDisabledTab = {
    all: isAllTasksFetching || !allTasksData?.length,
    unassigned: isUnassignedTasksFetching || !unassignedTasksData?.length,
  };

  const handleClick = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.TASKS, null, {
      search: { status: 'ALL', myTasksOnly: false },
    });
  };

  const handleItemClick = (taskId: string) => {
    navigate(routes.INTELLIGENT_WORKFLOWS.TASKS, {}, { search: { id: taskId, myTasksOnly: false } });
  };

  if (isLoading) {
    return (
      <ListCard.Skeleton testId={TEST_ID}>
        <TabsBottomLineWrapper>
          <Tabs size="M" value="0" variant="outlined" color="neutral">
            {tabs.map((tab, index) => (
              <Tab
                key={`${tab}-skeleton`}
                label={<Placeholder variant="text" width={40} height={16} />}
                value={`${index}`}
                disabled={true}
              />
            ))}
          </Tabs>
        </TabsBottomLineWrapper>
        <Stack p={3} flex={1}>
          {Array(LIMIT_ITEMS)
            .fill(null)
            .map((_, index) => (
              <ListItem.Skeleton key={index} />
            ))}
        </Stack>
      </ListCard.Skeleton>
    );
  }

  const content = isEmpty ? (
    <EmptyStateNoData
      testId="active-tasks-card-no-data"
      title={t('NO_DATA.TITLE')}
      description={t('NO_DATA.DESCRIPTION')}
      icon={CelebrationOutlined}
      variant="success"
    />
  ) : (
    data?.map((item) => (
      <ListItem
        key={item.taskId}
        {...item}
        icon={!item.vendorId ? FactCheckOutlined : undefined}
        onClick={() => handleItemClick(item.taskId)}
      />
    ))
  );

  return (
    <ListCard
      isEmpty={isEmpty}
      testId={TEST_ID}
      title={t('TITLE')}
      showAllText={t('SHOW_ALL')}
      onShowAllClick={handleClick}
    >
      <TabsBottomLineWrapper>
        <Tabs
          size="M"
          variant="outlined"
          value={activeTab}
          onChange={(_, value) => setActiveTab(value)}
          color="neutral"
        >
          {tabs.map((tab) => (
            <Tab key={tab} value={tab} label={tabLabels[tab]} disabled={isDisabledTab[tab]} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>
      <Stack p={3} flex={1}>
        {content}
      </Stack>
    </ListCard>
  );
};

export default ActiveTasksCard;
