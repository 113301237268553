import { TextField } from '@verticeone/design-system';
import { Control, Controller, useFieldArray, UseFormRegister } from 'react-hook-form';
import VariableMapper from '@vertice/core/src/modules/intelligentWorkflows/components/VariableMapper';
import VariableSelector from '@vertice/core/src/modules/intelligentWorkflows/components/VariableMapper/VariableSelector/VariableSelector';
import { ProcessDefinition, TaskDefinition } from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import { FormSchema } from '../formSchema';
import { useVariablesAvailableInNode } from '../../../../../hooks/useVariablesAvailableInNode';

type VariableMapperFieldsProps = {
  control: Control<FormSchema>;
  register: UseFormRegister<FormSchema>;
  task: TaskDefinition;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
  title: {
    left: string;
    right: string;
  };
};

const VariableMapperFields = ({
  control,
  register,
  workflowServiceRef,
  processDefinition,
  task,
  title,
}: VariableMapperFieldsProps) => {
  const { fields, remove, append } = useFieldArray({ control, name: 'variables' });
  const { requestVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  return (
    <VariableMapper.Root>
      <VariableMapper.Row header>
        <VariableMapper.Heading>{title.left}</VariableMapper.Heading>
        <VariableMapper.Heading>{title.right}</VariableMapper.Heading>
      </VariableMapper.Row>
      {fields.map((field, index) => (
        <VariableMapper.Row key={field.id} isDeletable onDelete={() => remove(index)}>
          <Controller
            name={`variables.${index}.source`}
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <VariableSelector key={name} value={value} onChange={onChange} variables={requestVariables} />
            )}
          />
          <VariableMapper.Divider />
          <TextField size="S" sx={{ flex: 1 }} {...register(`variables.${index}.target`)} />
        </VariableMapper.Row>
      ))}
      <VariableMapper.Row>
        <VariableMapper.AddButton onClick={() => append({ source: { type: 'Const', value: '' }, target: '' })} />
      </VariableMapper.Row>
    </VariableMapper.Root>
  );
};

export default VariableMapperFields;
