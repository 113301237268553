import { FC } from 'react';
import { styled } from '@mui/material';
import { Handle, HandleProps, useHandleConnections } from '@xyflow/react';

const StyledHandle = styled(Handle)(({ theme: { palette } }) => ({
  '&.react-flow__handle': {
    background: `${palette.core.color1}`,
    borderRadius: 1000,
    border: `1px solid ${palette.neutral.color2}`,
    height: '8px',
    width: '8px',
    zIndex: 2,
    outline: `2px solid ${palette.core.color1}`,
  },
  '&.connectingfrom, &.connectingto': {
    background: `${palette.secondary.color2}`,
    border: `1px solid ${palette.secondary.color2}`,
    outline: `2px solid ${palette.secondary.color3}`,
  },
}));

type ConnectionPointProps = HandleProps & {
  connectionsLimit?: number | 'no-limit';
};

export const ConnectionPoint: FC<ConnectionPointProps> = (props) => {
  const { connectionsLimit = 1, type, ...restProps } = props;

  const connections = useHandleConnections({
    type,
  });

  return (
    <StyledHandle
      {...restProps}
      type={type}
      isConnectable={connectionsLimit === 'no-limit' || connectionsLimit > connections.length}
    />
  );
};
