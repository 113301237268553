import { Stack } from '@mui/material';
import { Text, ChipButton, Divider } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { isOfferRolling, isOfferTermEqual, normalizeBaselineTotalCost, useOfferFooterValues } from './helpers/utils';
import { Offer } from '../../../../task/TaskDrawer/TaskPredefinedForm/predefinedForms/shared/schemas';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { FC } from 'react';

type OfferFooterItemVariant = 'default' | 'saving' | 'additional' | 'spending' | 'basic';

type OfferFooterItemProps = {
  title: string;
  value: string;
  variant?: OfferFooterItemVariant;
  percentageChipValue?: number;
  percentageSignDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
};

const getFooterItemColor = (variant: OfferFooterItemVariant): string => {
  switch (variant) {
    case 'saving':
      return 'success';
    case 'spending':
      return 'error';
    case 'additional':
      return 'text3';
    case 'basic':
      return 'text1';
    default:
      return 'text1';
  }
};

const OfferFooterItem = ({
  title,
  value,
  variant = 'default',
  percentageChipValue,
  percentageSignDisplay = 'auto',
}: OfferFooterItemProps) => {
  const formatPercentage = useFormatPercentage();
  const isDefault = variant === 'default';
  const isAdditional = variant === 'additional';

  return (
    <Stack gap={3} direction={'row'}>
      <Text variant="heading" size={isDefault ? 'S' : 'XS'} color={isAdditional ? 'text3' : 'text1'} width={'50%'}>
        {title}
      </Text>
      <Stack direction="row" width={'50%'} gap={1} alignItems="end">
        <Text
          variant="heading"
          size={variant === 'default' ? 'S' : 'XS'}
          color={getFooterItemColor(variant)}
          textAlign={{ xs: 'right', md: 'left' }}
        >
          {value}
        </Text>
        {percentageChipValue !== undefined && (
          <ChipButton
            size="S"
            variant="outline"
            isActive={false}
            color={variant === 'saving' ? 'success' : 'error'}
            disabled={variant === 'additional'}
          >
            {formatPercentage(percentageChipValue, { signDisplay: percentageSignDisplay })}
          </ChipButton>
        )}
      </Stack>
    </Stack>
  );
};

type BaselineOfferFooterProps = {
  baselineOffer: Offer;
};

export const BaselineOfferFooter: FC<BaselineOfferFooterProps> = ({ baselineOffer }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { getOfferFooterValues } = useOfferFooterValues({ offer: baselineOffer });

  const { annualCost, totalCost } = getOfferFooterValues();
  const isRollingOffer = baselineOffer.rollingFrequency !== 'NO';

  return (
    <Stack py={4} px={6} gap={1}>
      <OfferFooterItem
        title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.BASELINE_ANNUAL_COST')}
        value={formatCurrency(annualCost, { currency: baselineOffer.baseCurrency })}
        variant={isRollingOffer ? 'basic' : 'additional'}
      />

      {!isRollingOffer && (
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.BASELINE_TOTAL_COST')}
          value={formatCurrency(totalCost, { currency: baselineOffer.baseCurrency })}
          variant="basic"
        />
      )}
    </Stack>
  );
};

type NegotiatedOfferFooterProps = {
  offer: Offer;
  baselineOffer: Offer;
};

export const NegotiatedOfferFooter: FC<NegotiatedOfferFooterProps> = ({ offer, baselineOffer }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  const { getOfferFooterValues: getBaselineOfferFooterValues } = useOfferFooterValues({ offer: baselineOffer });
  const { getOfferFooterValues } = useOfferFooterValues({ offer });

  const { annualCost: baselineAnnualCost, totalCost: baselineTotalCost } = getBaselineOfferFooterValues();
  const { annualCost, totalCost, concessionSavings, offerTermInMonths } = getOfferFooterValues();

  const isRollingOffer = isOfferRolling(offer);
  const isTermEqual = isOfferTermEqual(baselineOffer, offer);

  const baseAnnualSavings = baselineAnnualCost - annualCost;
  const shouldShowAnnualSavings = concessionSavings > 0 || baseAnnualSavings > 0;

  const totalAnnualSavingsPercentage =
    !!baseAnnualSavings && !!baselineAnnualCost ? baseAnnualSavings / baselineAnnualCost : 0;

  const totalOfferSavings = (baseAnnualSavings / 12) * offerTermInMonths + concessionSavings;

  // Baseline annual cost projected to the negotiated offer term for better reporting
  const normalisedProposedCost = (baselineAnnualCost / 12) * offerTermInMonths;

  const totalOfferSavingsPercentage =
    !!totalOfferSavings && !!normalisedProposedCost ? totalOfferSavings / normalisedProposedCost : 0;

  return (
    <Stack py={4} px={6} gap={3}>
      <Stack gap={1}>
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.BASELINE_ANNUAL_COST')}
          value={formatCurrency(baselineAnnualCost, { currency: offer.baseCurrency })}
          variant={isRollingOffer ? 'basic' : 'additional'}
        />

        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.NEGOTIATED_ANNUAL_COST')}
          value={formatCurrency(annualCost, { currency: offer.baseCurrency })}
          variant={isRollingOffer ? 'basic' : 'additional'}
        />

        {shouldShowAnnualSavings && (
          <>
            <Divider />
            <OfferFooterItem
              title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ANNUAL_SAVINGS')}
              value={formatCurrency(baseAnnualSavings, { currency: offer.baseCurrency })}
              percentageChipValue={totalAnnualSavingsPercentage}
              variant={isRollingOffer ? (baseAnnualSavings > 0 ? 'saving' : 'spending') : 'additional'}
            />
          </>
        )}
      </Stack>

      <Stack>
        {!!concessionSavings && (
          <OfferFooterItem
            title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.ADDITIONAL_SAVINGS')}
            value={formatCurrency(concessionSavings, { currency: offer.baseCurrency })}
            variant={'additional'}
          />
        )}
      </Stack>

      <Text variant="caption" size="S">
        {t(
          isTermEqual
            ? 'INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONTRACT_TERM_COSTS'
            : 'INTELLIGENT_WORKFLOWS.OFFER_WIDGET.CONTRACT_TERM_COSTS_NORMALIZED'
        )}
      </Text>

      <Stack gap={1}>
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.BASELINE_TOTAL_COST')}
          value={formatCurrency(
            isTermEqual ? baselineTotalCost : normalizeBaselineTotalCost(baselineTotalCost, baselineOffer, offer),
            { currency: offer.baseCurrency }
          )}
          variant="basic"
        />

        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.NEGOTIATED_TOTAL_COST')}
          value={formatCurrency(totalCost, { currency: offer.baseCurrency })}
          variant="basic"
        />

        <Divider />
        <OfferFooterItem
          title={t('INTELLIGENT_WORKFLOWS.OFFER_WIDGET.TOTAL_SAVINGS')}
          value={formatCurrency(totalOfferSavings, { currency: offer.baseCurrency })}
          variant={totalOfferSavings < 0 ? 'spending' : 'saving'}
          percentageChipValue={totalOfferSavingsPercentage}
        />
      </Stack>
    </Stack>
  );
};
