import { PredefinedFormDef } from '../shared/types';
import { SelectVendorForm } from './SelectVendorForm';
import { zodSchema, FormData } from './schema';
import { Vendor, OfferProduct } from '../shared/schemas';

type Result = {
  finalDecision?: {
    vendor: Vendor;
    product?: OfferProduct;
  };
  vendorsRejected: boolean;
};

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/vendorProductSelection\/v\d+$/,
  component: SelectVendorForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    selectedVendorsAndProducts: getValue('selectedVendorsAndProducts'),
    finalDecision: getValue('finalDecision'),
    vendorsRejected: getValue('vendorsRejected') ?? false,
    vendorId: '',
  }),
  transformOutput: ({ selectedVendorsAndProducts, vendorId }): Result => {
    const vendorO = selectedVendorsAndProducts.find((item) => item.vendor.id === vendorId)?.vendor;

    return {
      finalDecision: vendorO
        ? {
            vendor: vendorO,
            product: undefined,
          }
        : undefined,
      vendorsRejected: !vendorO,
    };
  },
};
