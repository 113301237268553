import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { TaskTwoTone, RequestQuoteTwoTone } from '@mui/icons-material';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content } from '@vertice/core/src/components/Dialog/Components';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { AddExistingContractFormEntry } from '../../types';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useFormContext } from 'react-hook-form';
import { SharedRenewalFormData } from '../../../SharedRenewalSteps/types';
import { useCreateRenewalRequestFlow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateRenewalRequestFlow';
import { CostModelProductItem } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';
import { getProductsWithoutCosts } from '../../../utils/convertors';
import { RenewalProduct } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateWorkflowRequest';
import ToBeNegotiatedByConfirmationDialog from '@vertice/core/src/modules/intelligentWorkflows/request/ToBeNegotiatedByConfirmation/ToBeNegotiatedByConfirmationDialog';
import { useGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CreateRequestDialog } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/CreateRequestDialog';
import { CreateNonSaasRequestPurchaseDialog } from '@vertice/core/src/modules/saas/contract/components/CreateNonSassRequestDialog';
import { isOneOffContract } from '@vertice/core/src/modules/saas/contract/computed';

const useChooseInitiateRenewalWizard = () => {
  const wizard = useWizard();

  return {
    goToConfirmation: () => wizard.goToStep('confirmation'),
    goToConfirmRenewalRequirements: () => wizard.goToStep('confirm_renewal_requirements'),
  };
};

const formCostModelProductItemsToRenewalProducts = (products: CostModelProductItem[]): RenewalProduct[] => {
  return (
    products.map(
      (product) =>
        ({
          id: product.productId ?? undefined,
          name: product.name ?? null ?? undefined,
          productId: product.productId ?? undefined,
          totalCost: product.totalCost ?? undefined,
          licenseCost: product.licenseCost ?? undefined,
          numberOfLicences: product.numberOfLicences ?? undefined,
          licenseType: product.licenseType ?? undefined,
          allocationSpan: product.allocationSpan ?? undefined,
        } ?? undefined)
    ) ?? []
  );
};

const ChooseInitiateRenewalStep: React.FC<WizardStepProps> = () => {
  const { t } = useTranslation();
  const { goToConfirmation, goToConfirmRenewalRequirements } = useChooseInitiateRenewalWizard();
  const { accountId } = useAccountContext();
  const { watch, setValue } = useFormContext<SharedRenewalFormData>();
  const contractId = watch('existingContractId');
  const contractCurrency = watch('contractCurrency');
  const vendor = watch('vendor');
  const products = watch('products');
  const department = watch('departmentId');
  const contractCategory = watch('contractCategory');
  const { isFeatureEnabled, getFeature } = useAccountContext();
  const iwPaywall = getFeature(FEATURES.INTELLIGENT_WORKFLOWS_PAYWALL);
  const isNonSassIWEnabled = iwPaywall?.properties?.nonSaasRequests;
  const { createRenewalRequestFlow, isCreatingRenewalRequest } = useCreateRenewalRequestFlow();
  const [isNonSaasPurchaseDialogOpened, setIsNonSaasPurchaseDialogOpened] = useState<boolean>(false);
  const [isNonSaasRenewalDialogOpened, setIsNonSaasRenewalDialogOpened] = useState<boolean>(false);
  const [isSaasConfirmationDialogOpened, setSaasConfirmationDialogOpened] = useState<boolean>(false);
  const initiateRenewalRequestForContract = useCallback(
    () =>
      createRenewalRequestFlow({
        vendor: vendor!,
        contractId: contractId!,
        products: formCostModelProductItemsToRenewalProducts(products),
        department: department ?? undefined,
        contractCurrency,
      }),
    [contractId, createRenewalRequestFlow, products, vendor, department, contractCurrency]
  );

  const contract = useGetContractQuery(
    { contractId: contractId!, accountId: accountId },
    { skip: !contractId || !accountId }
  ).data?.viewOnContract.contract;

  const onInitiateRenewalChosen = useCallback(
    async (value: string) => {
      if (value === 'no') {
        goToConfirmation();
      } else if (value === 'yes') {
        const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
        if (isIWEnabled) {
          let dialogToOpen;
          if (contractCategory === 'OTHER' && isNonSassIWEnabled) {
            dialogToOpen = isOneOffContract(contract!)
              ? setIsNonSaasPurchaseDialogOpened
              : setIsNonSaasRenewalDialogOpened;
          } else {
            dialogToOpen = setSaasConfirmationDialogOpened;
          }
          dialogToOpen(true);
        } else {
          // clear costs from products as it was just for the Add Existing part, but it's not wanted in the Renewal part
          const productsWithoutCosts = getProductsWithoutCosts(products);
          setValue('products', productsWithoutCosts);
          goToConfirmRenewalRequirements();
        }
      }
    },
    [
      goToConfirmation,
      isFeatureEnabled,
      goToConfirmRenewalRequirements,
      products,
      setValue,
      contract,
      contractCategory,
      isNonSassIWEnabled,
    ]
  );

  return (
    <>
      <Content>
        <Header
          title={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_TITLE')}
          subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_SUBTITLE')}
        />
        <SelectedVendorPanel />
        <InnerScrollable>
          <FormSection>
            <AddExistingContractFormEntry
              name="initiateRenewal"
              width={12}
              component={FormToggleButtonField}
              disabled={isCreatingRenewalRequest}
              componentProps={{
                onClick: onInitiateRenewalChosen,
                buttonHeight: 234,
                options: [
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.UPLOAD_ONLY'),
                    value: 'no',
                    icon: TaskTwoTone,
                  },
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.INITIATE_RENEWAL'),
                    value: 'yes',
                    isLoading: isCreatingRenewalRequest,
                    icon: RequestQuoteTwoTone,
                  },
                ],
              }}
            />
          </FormSection>
        </InnerScrollable>
      </Content>
      {isNonSaasPurchaseDialogOpened && (
        <CreateNonSaasRequestPurchaseDialog open setOpen={setIsNonSaasPurchaseDialogOpened} vendorName={vendor?.name} />
      )}
      {isNonSaasRenewalDialogOpened && (
        <CreateRequestDialog open setOpen={setIsNonSaasRenewalDialogOpened} defaultContract={contract} autoStart />
      )}
      {isSaasConfirmationDialogOpened && (
        <ToBeNegotiatedByConfirmationDialog
          open
          onClose={() => setSaasConfirmationDialogOpened(false)}
          contract={contract!}
          onSuccess={async () => {
            setSaasConfirmationDialogOpened(false);
            await initiateRenewalRequestForContract();
          }}
        />
      )}
    </>
  );
};

export default ChooseInitiateRenewalStep;
