export enum VisoTrustSetupStep {
  CONNECTION = 'connection',
  SUCCESS = 'success',
}

export type StepContentProps = {
  activeStep: VisoTrustSetupStep;
  setStep: (step: VisoTrustSetupStep) => void;
};

export type ConnectionFormSchema = {
  workspaceUrl: string;
};
