import { PropsWithChildren, useContext } from 'react';
import { createRequiredContext } from '@verticeone/utils/contexts';
import { ConversationInfo, useConversations } from '../../useConversations';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { AppTypeContext } from '../../../../../../contexts/AppTypeContext';

type ConversationContextProps = {
  current: ReturnType<typeof useConversations>;
  parent: ReturnType<typeof useConversations>;
  currentUsers: ReturnType<typeof useConversationUsers>;
  parentUsers: ReturnType<typeof useConversationUsers>;
};

const { ConversationContextProvider, useConversationContext } = createRequiredContext<
  ConversationContextProps,
  'Conversation'
>('Conversation');

export type ConversationData = {
  accountId: string;
  requestId: string;
  owner?: string | null;
};

type ConversationProviderProps = {
  request: ConversationData;
  parentRequest?: ConversationData;
  sourceRef?: string;
} & Pick<ConversationInfo, 'onCommentSourceClick'>;

const ConversationProvider = ({
  children,
  request,
  parentRequest,
  sourceRef,
  onCommentSourceClick,
}: PropsWithChildren<ConversationProviderProps>) => {
  const { isIAT } = useContext(AppTypeContext);

  const current = useConversations({
    requestId: request.requestId,
    accountId: request.accountId,
    requestOwner: request.owner,
    sourceRef,
    onCommentSourceClick,
  });
  const parent = useConversations({
    requestId: parentRequest?.requestId,
    accountId: parentRequest?.accountId,
    requestOwner: parentRequest?.owner,
    sourceRef,
    onCommentSourceClick,
  });

  const currentUsers = useConversationUsers(request.accountId, current.mentionedUserIds, {
    skip: false,
    fetchVerticeUsers: isIAT,
  });
  const parentUsers = useConversationUsers(parentRequest?.accountId, parent.mentionedUserIds, {
    skip: false,
    fetchVerticeUsers: isIAT,
  });

  return (
    <ConversationContextProvider
      value={{
        current,
        parent,
        currentUsers,
        parentUsers,
      }}
    >
      {children}
    </ConversationContextProvider>
  );
};

export { ConversationContextProvider, ConversationProvider, useConversationContext };
