import { MutableRefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { EditOutlined, CloseOutlined } from '@mui/icons-material';
import { GridApiPro, GridRenderCellParams, GridRowModes, GridRowModesModel } from '@mui/x-data-grid-pro';

import { useRevokeUserAuthorizationMutation, UserIdentity } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { Button } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';
import { useEditableDataGridContext } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import { getConnectedUser, SLACK_USER_FIELD, SlackConnection, SlackConnectionStatus } from '../../../common';
import { UnlinkUserDialog } from './UnlinkUserDialog';

type ActionCellProps = {
  rowModesModel?: GridRowModesModel;
  connectedUsers?: UserIdentity[];
  isConnectUserLoading: boolean;
  loadingUserId: string;
  refetchConnectedUsers: () => void;
  apiRef: MutableRefObject<GridApiPro>;
} & GridRenderCellParams<SlackConnection>;

export const ActionCell = ({
  rowModesModel = {},
  connectedUsers,
  isConnectUserLoading,
  loadingUserId,
  refetchConnectedUsers,
  apiRef,
  row: { userId },
}: ActionCellProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const [disconnectUser, { isLoading: isDisconnectUserLoading }] = useRevokeUserAuthorizationMutation();

  const [isUnlinkDialogOpened, setIsUnlinkDialogOpened] = useState(false);

  const editableGridContext = useEditableDataGridContext();
  const isRowInEditMode = rowModesModel[userId]?.mode === GridRowModes.Edit;
  const isAnyRowInEditMode = Object.entries(rowModesModel).some(([_key, value]) => value.mode === GridRowModes.Edit);
  const connectionStatus = getConnectedUser(userId, connectedUsers)?.status;
  const isUserConnected = connectionStatus === SlackConnectionStatus.ACTIVE;
  const isUserPending = connectionStatus === SlackConnectionStatus.PENDING;
  const isRowLoading = loadingUserId === userId;

  const handleDisconnectUser = () => {
    void (async () => {
      await disconnectUser({
        accountId,
        userId,
        body: {},
      });
      refetchConnectedUsers();
      apiRef.current.updateRows([{ userId, [SLACK_USER_FIELD]: undefined }]);
      setIsUnlinkDialogOpened(false);
    })();
  };

  if (isRowInEditMode || isRowLoading || isUserPending)
    return (
      <Stack direction="row" gap={1}>
        <Button
          variant="outline"
          size="S"
          color="neutral"
          onClick={() => editableGridContext.handleRowSaveClick(userId)}
          isLoading={isConnectUserLoading || isUserPending}
          sx={{ height: '30px' }}
        >
          {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.LINK')}
        </Button>
        {!isRowLoading && !isUserPending && (
          <IconButton
            variant="outline"
            size="S"
            onClick={() => editableGridContext.handleRowCancelClick(userId)}
            icon={CloseOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DISCARD_CHANGES')}
          />
        )}
      </Stack>
    );

  if (!isUserConnected)
    return (
      <IconButton
        variant="outline"
        size="S"
        onClick={() => editableGridContext.handleRowEditClick(userId)}
        icon={EditOutlined}
        disabled={isAnyRowInEditMode}
      />
    );

  return (
    <>
      <Button variant="outline" size="S" color="neutral" onClick={() => setIsUnlinkDialogOpened(true)}>
        {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.UNLINK')}
      </Button>
      <UnlinkUserDialog
        isOpened={isUnlinkDialogOpened}
        isLoading={isDisconnectUserLoading}
        onSubmit={handleDisconnectUser}
        onClose={() => setIsUnlinkDialogOpened(false)}
      />
    </>
  );
};
