import React from 'react';
import { Button } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

type SaveIntegrationButtonProps = {
  isFormValid: boolean;
  isDirty: boolean;
  isLoading?: boolean;
};

const SaveIntegrationButton = ({ isFormValid, isDirty, isLoading = false }: SaveIntegrationButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });

  return (
    <Button
      variant="solid"
      size="M"
      color="primary"
      type="submit"
      disabled={!isFormValid || isLoading || !isDirty}
      isLoading={isLoading}
    >
      {t('SAVE_BUTTON')}
    </Button>
  );
};

export default SaveIntegrationButton;
