import { AdvancedLinearApproximationCostModel, ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  getProductsFromItems,
  isAdvancedLinearApproximationModel,
} from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

/**
 * Use this function to extract product items from Advanced Linear Approximation cost model
 */
export const mapCostModelProductsToFormDataProductItem = (costModel?: ContractModel2) => {
  if (isAdvancedLinearApproximationModel(costModel)) {
    const advancedCostModel = costModel?.model as AdvancedLinearApproximationCostModel;
    return getProductsFromItems(advancedCostModel?.items);
  }

  return [];
};
