import { fieldDefsByType } from '../../../dynamicForms/fields/fields';
import { DynamicFormField, DynamicFormPresenterConfig } from '../../../dynamicForms';
import React from 'react';
import { useTranslation } from 'react-i18next';

type NestedPresenterProps = {
  field: DynamicFormField;
  config: DynamicFormPresenterConfig;
};

export const NestedPresenter = ({ field, config }: NestedPresenterProps) => {
  const { t } = useTranslation();
  const Presenter = fieldDefsByType[field.type]?.presenter;

  return Presenter ? (
    <Presenter key={field.name} field={field} config={config} />
  ) : (
    <div key={field.name}>{t('DYNAMIC_FORM_PRESENTER.FIELD.UNKNOWN_FIELD.TEXT')}</div>
  );
};
