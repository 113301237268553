import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { OutlinedInput, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { emailRegexp } from '../utils/oldValidators';
import { Label } from '@vertice/components/src/Label';
import useCreateUser, { CreateUserResponse } from '@vertice/hooks/src/useCreateUser';
import { USER_ROLES, UserRoleType } from '@vertice/core/src/constants/userRoles';
import { AppTypeContext } from '../contexts/AppTypeContext';
import { resolver } from './utils';
import styles from './NewContact.module.scss';
import { RoleSelect } from './RoleSelect';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

interface NewContactProps {
  onContactCreated?: (contact: NewUserData) => void;
}

export interface NewContactFormType {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  access: boolean;
  userRole: UserRoleType;
}

export interface NewUserData {
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  accountUserProperties: {
    jobTitle: string;
  };
}

const NewContact: React.FC<NewContactProps> = ({ onContactCreated }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const { isIAT } = useContext(AppTypeContext);
  const allowGrantAccess = isIAT || isUserAdmin;
  const [loading, setLoading] = useState(false);
  const defaultUserRole = USER_ROLES.user;

  const { createUser, defaultUserSettings } = useCreateUser();

  const onSubmit: SubmitHandler<NewContactFormType> = async (data) => {
    const { firstName, lastName, email, jobTitle, userRole = defaultUserRole } = data;

    if (!accountId) {
      return;
    }

    setLoading(true);
    await createContact(firstName, lastName, email, userRole, jobTitle);
    setLoading(false);
  };

  const createContact = async (firstName: string, lastName: string, email: string, role: string, jobTitle: string) => {
    const createdContact = (await createUser({
      firstName,
      lastName,
      email,
      jobTitle,
      role,
      userSettings: defaultUserSettings,
    })) as CreateUserResponse;

    if (createdContact.data && onContactCreated) {
      onContactCreated({
        userId: createdContact.data.userId!,
        userName: email,
        firstName,
        lastName,
        email,
        accountUserProperties: {
          jobTitle,
        },
      });
    }
  };

  const methods = useForm<NewContactFormType>({ resolver });
  const { register, handleSubmit } = methods;

  return (
    <Stack className={styles['new-contact']}>
      <div className={styles['new-contact-title']}>
        <Typography variant="heading-s" color="var(--tokens-color-light-text-3)">
          {t('PREFERENCES.NEW_CONTACT.TITLE')}
        </Typography>
      </div>
      <form className={styles['new-contact-content']} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Label required htmlFor="firstName" text={t('PREFERENCES.NEW_CONTACT.LABELS.FIRST_NAME')} />
          <OutlinedInput
            className={styles['new-contact-input']}
            fullWidth
            {...register('firstName', { required: true })}
            id="firstName"
          />
        </div>
        <div>
          <Label required htmlFor="lastName" text={t('PREFERENCES.NEW_CONTACT.LABELS.LAST_NAME')} />
          <OutlinedInput
            className={styles['new-contact-input']}
            fullWidth
            {...register('lastName', { required: true })}
            id="lastName"
          />
        </div>
        <div>
          <Label required htmlFor="email" text={t('PREFERENCES.NEW_CONTACT.LABELS.EMAIL')} />
          <OutlinedInput
            className={styles['new-contact-input']}
            fullWidth
            {...register('email', { required: true, pattern: emailRegexp })}
            id="email"
          />
        </div>
        <div>
          <Label required htmlFor="jobTitle" text={t('PREFERENCES.NEW_CONTACT.LABELS.JOB_TITLE')} />
          <OutlinedInput
            className={styles['new-contact-input']}
            fullWidth
            {...register('jobTitle', { required: true })}
            id="jobTitle"
          />
        </div>
        {allowGrantAccess && (
          <div>
            <Label required htmlFor="userRole" text={t('PREFERENCES.NEW_CONTACT.LABELS.ROLE')} />
            <RoleSelect id="userRole" register={register} />
          </div>
        )}
      </form>
      <div className={styles['new-contact-actions-container']}>
        <LoadingButton
          fullWidth
          size="small"
          variant="contained"
          className="uppercase"
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          {t('PREFERENCES.NEW_CONTACT.ADD_USER')}
        </LoadingButton>
      </div>
    </Stack>
  );
};

export default NewContact;
