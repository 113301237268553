import {
  bffeSaasAPICodegen,
  CreateContractApiArg,
  CreateContractApiResponse,
  GetSavingsDashboardApiArg,
  GetSavingsDashboardApiResponse,
  ListContractsApiArg,
  ListContractsApiResponse,
  ListRequestsApiArg,
  ListRequestsApiResponse,
  ListTasksForUserApiArg,
  ListTasksForUserApiResponse,
  ListVendorsPageContractsApiArg,
  ListVendorsPageContractsApiResponse,
  Request,
  Task,
  UpdateContractApiArg,
  UpdateContractApiResponse,
  ViewOnContract,
  ViewOnContractForVendorsPage,
} from '../openapi/codegen/bffeSaasAPI';
import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';

export const BFFE_CONTRACTS_TAG = 'SAAS_BFFE_CONTRACTS';

export const BFFE_CONTRACT_LIST_TAG = 'SAAS_BFFE_CONTRACT_LIST';
export const BFFE_USER_PERMISSIONS_TAG = 'SAAS_BFFE_USER_PERMISSIONS';
export const BFFE_CUSTOMIZATION_VIEW_TAG = 'SAAS_BFFE_CUSTOMIZATION_VIEW';
export const BFFE_CUSTOMIZATION_FILTER_TAG = 'SAAS_BFFE_CUSTOMIZATION_FILTER';
export const BFFE_CONTRACTS_CUSTOMIZATION_TAG = 'SAAS_BFFE_CONTRACTS_CUSTOMIZATION';
export const BFFE_CONTRACT_ATTACHMENTS_TAG = 'BFFE_CONTRACT_ATTACHMENTS_TAG';

type AllTagType =
  | typeof BFFE_CONTRACTS_TAG
  | typeof BFFE_USER_PERMISSIONS_TAG
  | typeof BFFE_CONTRACT_LIST_TAG
  | typeof BFFE_CUSTOMIZATION_VIEW_TAG
  | typeof BFFE_CUSTOMIZATION_FILTER_TAG
  | typeof BFFE_CONTRACTS_CUSTOMIZATION_TAG
  | typeof BFFE_CONTRACT_ATTACHMENTS_TAG;

export const enhancedBffeSaasAPI = bffeSaasAPICodegen
  .enhanceEndpoints<AllTagType>({
    addTagTypes: [
      BFFE_CONTRACTS_TAG,
      BFFE_CONTRACT_LIST_TAG,
      BFFE_USER_PERMISSIONS_TAG,
      BFFE_CONTRACTS_CUSTOMIZATION_TAG,
      BFFE_CONTRACT_ATTACHMENTS_TAG,
    ],
    endpoints: {
      resolveUserPermissionsOnContract: {
        providesTags: [BFFE_USER_PERMISSIONS_TAG],
      },

      listContracts: {
        providesTags: [BFFE_CONTRACTS_TAG, BFFE_CONTRACT_LIST_TAG],
      },
      createContract: {
        invalidatesTags: [BFFE_CONTRACTS_TAG],
      },
      getContract: {
        providesTags: [BFFE_CONTRACTS_TAG],
      },
      updateContract: {
        invalidatesTags: [BFFE_CONTRACTS_TAG],
      },
      deleteContract: {
        invalidatesTags: [BFFE_CONTRACT_LIST_TAG],
      },
      getCustomizationView: {
        providesTags: [BFFE_CUSTOMIZATION_VIEW_TAG],
      },
      listCustomizationViews: {
        providesTags: [BFFE_CUSTOMIZATION_VIEW_TAG],
      },
      createCustomizationView: {
        invalidatesTags: [BFFE_CUSTOMIZATION_VIEW_TAG],
      },
      updateCustomizationView: {
        invalidatesTags: [BFFE_CUSTOMIZATION_VIEW_TAG],
      },
      deleteCustomizationView: {
        invalidatesTags: [BFFE_CUSTOMIZATION_VIEW_TAG],
      },
      getCustomContractFields: {
        providesTags: [BFFE_CONTRACTS_CUSTOMIZATION_TAG],
      },
      updateCustomContractFields: {
        invalidatesTags: [BFFE_CONTRACTS_CUSTOMIZATION_TAG],
      },
      getCustomListFiltersConfiguration: {
        providesTags: [BFFE_CUSTOMIZATION_FILTER_TAG],
      },
      updateCustomListFiltersConfiguration: {
        invalidatesTags: [BFFE_CUSTOMIZATION_FILTER_TAG],
      },
      listContractAttachments: {
        providesTags: [BFFE_CONTRACT_ATTACHMENTS_TAG],
      },
      putContractFileMetadata: {
        invalidatesTags: [BFFE_CONTRACT_ATTACHMENTS_TAG],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      createContractWithoutInvalidation: build.mutation<CreateContractApiResponse, CreateContractApiArg>({
        // copied from openapi codegen
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/contracts`,
          method: 'POST',
          body: queryArg.createContractRequest,
          params: {
            nextToken: queryArg.nextToken,
            maxResults: queryArg.maxResults,
            consumer: queryArg.consumer,
          },
        }),
      }),
      updateContractWithoutInvalidation: build.mutation<UpdateContractApiResponse, UpdateContractApiArg>({
        // copied from openapi codegen
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/contracts/${queryArg.contractId}`,
          method: 'POST',
          body: queryArg.updateContractRequest,
          params: { changeNumber: queryArg.changeNumber },
          invalidatesTags: [BFFE_USER_PERMISSIONS_TAG],
        }),
      }),
      listContractsPaginated: build.query<ListContractsApiResponse, ListContractsApiArg>({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<ListContractsApiResponse, ListContractsApiArg, ViewOnContract>({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/contracts`,
                params: {
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                  lineageId: args.lineageId,
                  vendorId: args.vendorId,
                  consumer: args.consumer,
                },
              });
              return { data: queryResponse.data as ListContractsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.contracts,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { contracts: items } };
        },
        providesTags: [BFFE_CONTRACTS_TAG],
      }),
      listVendorsPageContractsPaginated: build.query<
        ListVendorsPageContractsApiResponse,
        ListVendorsPageContractsApiArg
      >({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListVendorsPageContractsApiResponse,
            ListVendorsPageContractsApiArg,
            ViewOnContractForVendorsPage
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/vendors/contracts`,
                params: {
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                  vendorId: args.vendorId,
                },
              });
              return { data: queryResponse.data as ListVendorsPageContractsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.contracts,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { contracts: items } };
        },
        providesTags: [BFFE_CONTRACTS_TAG],
      }),
      listUserTasksPaginated: build.query<ListTasksForUserApiResponse, ListTasksForUserApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<ListTasksForUserApiResponse, ListTasksForUserApiArg, Task>({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/users/${args.userId}/tasks`,
                params: {
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                },
              });
              return { data: queryResponse.data as ListTasksForUserApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [],
      }),
      listRequestsPaginated: build.query<ListRequestsApiResponse, ListRequestsApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<ListRequestsApiResponse, ListRequestsApiArg, Request>({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/requests`,
                params: {
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                },
              });
              return { data: queryResponse.data as ListRequestsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [],
      }),
      getSavingsDashboardPaginated: build.query<GetSavingsDashboardApiResponse, GetSavingsDashboardApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const contracts = await fetchAllPages<
            GetSavingsDashboardApiResponse,
            GetSavingsDashboardApiArg,
            ViewOnContract
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${arg.accountId}/savings`,
                params: {
                  nextToken: arg.nextToken,
                  maxResults: arg.maxResults,
                },
              });
              return { data: queryResponse.data as GetSavingsDashboardApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.contracts || [],
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { contracts } };
        },
        providesTags: [],
      }),
    }),
  });

export const {
  useCreateContractWithoutInvalidationMutation,
  useUpdateContractWithoutInvalidationMutation,
  useListContractsPaginatedQuery,
  useListVendorsPageContractsPaginatedQuery,
  useListUserTasksPaginatedQuery,
  useListRequestsPaginatedQuery,
  useGetSavingsDashboardPaginatedQuery,
} = enhancedBffeSaasAPI;
