import { Box, styled } from '@mui/material';

export const CardsList = styled(Box)(({ theme }) => {
  return {
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    [theme.breakpoints.only('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.only('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    display: 'grid',
    alignItems: 'stretch',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: 16,
  };
});
