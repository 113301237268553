import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Text } from '@verticeone/design-system';
import {
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { IntegrationAccordion, IntegrationInput } from '@vertice/components';
import { Button } from '@verticeone/design-system';
import { STEPS, ONELOGIN_INTEGRATION_ID } from './common';
import { BulletList } from '../../../../../components/WizardSteps';
import WizardStepTemplate from '../../../../../components/WizardStepTemplate';
import { WizardStepProps } from '../../../../../components/Wizard';

type CollectStepProps = WizardStepProps & {
  accountId: string;
  domain: string;
  setDomain: (url: string) => void;
  clientId: string;
  setClientId: (clientId: string) => void;
  clientSecret: string;
  setClientSecret: (clientSecret: string) => void;
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
};

export const CollectStep = ({
  setStep,
  accountId,
  domain,
  setDomain,
  clientId,
  setClientId,
  clientSecret,
  setClientSecret,
  testingInProgress,
  setTestingInProgress,
}: CollectStepProps) => {
  const { t } = useTranslation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const onBack = () => setStep(STEPS.START);
  const onTestConnection = () => {
    // revoke & connect & test
    setTestingInProgress(true);
    void revokeAccountIntegration({
      accountId,
      integrationId: ONELOGIN_INTEGRATION_ID,
    }).then(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      connectAccountIntegrationMutation({
        accountId,
        integrationId: ONELOGIN_INTEGRATION_ID,
        integrationConnectionInput: {
          parameters: {
            secret: {
              domain,
              clientId,
              clientSecret,
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          testAccountIntegrationMutation({
            accountId,
            integrationId: ONELOGIN_INTEGRATION_ID,
            testName: 'connection',
          });
        })
        .then(() => {
          setStep(STEPS.CHECK_CONNECTION);
        });
    });
  };

  const header = (
    <Text variant="heading" size="M">
      {t('INTEGRATIONS.ONELOGIN.USAGE.GET_SETTINGS')}
    </Text>
  );

  const content = (
    <Stack direction="column" gap="32px">
      <Stack gap="16px">
        <IntegrationInput
          setValue={(changeEvent) => {
            setClientId(changeEvent.target.value);
          }}
          value={clientId}
          label={t('INTEGRATIONS.ONELOGIN.USAGE.CLIENT_ID')}
          dataTestId="oneLoginClientId"
        />
        <IntegrationInput
          setValue={(changeEvent) => {
            setClientSecret(changeEvent.target.value);
          }}
          value={clientSecret}
          label={t('INTEGRATIONS.ONELOGIN.USAGE.CLIENT_SECRET')}
          dataTestId="oneLoginClientSecret"
        />
        <IntegrationAccordion
          summary={t('INTEGRATIONS.ONELOGIN.USAGE.HOW_TO_FIND_ONELOGIN_CLIENT_ID')}
          defaultExpanded
          details={
            <BulletList>
              <li>
                <Trans i18nKey="INTEGRATIONS.ONELOGIN.USAGE.CREDENTIALS_INSTRUCTION_1">
                  Navigate to the <Typography variant="body-bold-m">API Credentials</Typography> within{' '}
                  <Typography variant="body-bold-m">Developers</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="INTEGRATIONS.ONELOGIN.USAGE.CREDENTIALS_INSTRUCTION_2">
                  Within <Typography variant="body-bold-m">API Access</Typography>, click{' '}
                  <Typography variant="body-bold-m">Create New Credential</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="INTEGRATIONS.ONELOGIN.USAGE.CREDENTIALS_INSTRUCTION_3">
                  Assign the name <Typography variant="body-bold-m">“Vertice”</Typography> to the credential.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="INTEGRATIONS.ONELOGIN.USAGE.CREDENTIALS_INSTRUCTION_4">
                  Set scope to <Typography variant="body-bold-m">“Read all”</Typography>.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="INTEGRATIONS.ONELOGIN.USAGE.CREDENTIALS_INSTRUCTION_5">
                  Obtain your <Typography variant="body-bold-m">Client ID</Typography> and{' '}
                  <Typography variant="body-bold-m">Client Secret</Typography> and paste into the fields above.
                </Trans>
              </li>
            </BulletList>
          }
        />
      </Stack>

      <IntegrationInput
        setValue={(changeEvent) => {
          setDomain(changeEvent.target.value);
        }}
        value={domain}
        label={t('INTEGRATIONS.ONELOGIN.USAGE.DOMAIN')}
        dataTestId="oneLoginDomain"
        placeholder={t('INTEGRATIONS.ONELOGIN.USAGE.DOMAIN_PLACEHOLDER')}
      />
    </Stack>
  );

  return (
    <WizardStepTemplate
      header={header}
      content={content}
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button
          variant="solid"
          color="primary"
          size="S"
          isCaption
          onClick={onTestConnection}
          disabled={testingInProgress}
        >
          {t('INTEGRATIONS.TEST_CONNECTION')}
        </Button>,
      ]}
    />
  );
};
