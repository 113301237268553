import { FC, useState } from 'react';

import { VariableChip } from '../../VariableSelector/VariableChip';
import { VariablesDialog } from '../../VariableSelector/VariablesDialog';
import type { Variable } from '../../types';
import { VariablesGroup } from '../../VariableSelector/types';

type OtherVariableSelectorProps = {
  variablesGroups: VariablesGroup[];
  selectedVariable?: Variable;
  onSelectedVariableChange: (variable?: Variable) => void;
  isVariableTypeDisplayed?: boolean;
  isDisabled?: boolean;
  typeFilter?: string;
  selectedVariables?: string[];
};

export const OtherVariableSelector: FC<OtherVariableSelectorProps> = ({
  variablesGroups,
  selectedVariable,
  onSelectedVariableChange,
  isVariableTypeDisplayed,
  isDisabled,
  typeFilter,
  selectedVariables,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleVariableClick = (variable: Variable) => {
    onSelectedVariableChange(variable);
    setIsDialogOpen(false);
  };

  return (
    <>
      <VariableChip
        label={selectedVariable?.label}
        typeLabel={isVariableTypeDisplayed ? selectedVariable?.type.labels?.[0] : undefined}
        onClick={() => setIsDialogOpen(true)}
        withIcon
        isDisabled={isDisabled}
      />
      <VariablesDialog
        variablesGroups={variablesGroups}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        typeFilter={typeFilter}
        disabledVariables={selectedVariables}
        selectedVariableId={selectedVariable?.id}
      />
    </>
  );
};
