import { useGetAccountUserQuery } from '@vertice/slices/src/openapi/codegen/accountAPI';
import { USER_ROLES } from '@vertice/core/src/constants/userRoles';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useAuthentication } from '@verticeone/auth/src';

export type UseUserAccountRolesParams = { userId: string };

export type UseUserAccountRolesReturn = {
  isLoading: boolean;
  isUserAdmin: boolean;
  isUserPowerUser: boolean;
  isUserRestrictedUser: boolean;
};

/**
 * Fetches user roles for a given account and user. For logged user, roles from JWT token are prioritized.
 * WARNING: Don't use directly, prefer useUserAccountRoles.
 */
export const useRawAccountUserRoles = (
  { accountId, userId }: { accountId: string; userId: string },
  { skip }: { skip?: boolean }
): { isLoading: boolean; roles: string[] } => {
  const { user: loggedUser } = useAuthentication();

  // Roles defined in JWT token have priority over roles fetched from API.
  const shouldTakeRolesFromJWT = loggedUser?.userId === userId;

  const { data: userData, isLoading: isLoadingUser } = useGetAccountUserQuery(
    { accountId, userId },
    { refetchOnMountOrArgChange: false, skip: shouldTakeRolesFromJWT || skip }
  );

  return {
    isLoading: !shouldTakeRolesFromJWT && isLoadingUser,
    roles: (shouldTakeRolesFromJWT ? loggedUser?.roles : userData?.userRoles) ?? [],
  };
};

/**
 * We don't take userId from context to allow to check permissions of other user in IAT.
 *
 * If you need to get roles of the logged user, you can use our convenience-wrapper `useLoggedUserAccountRoles`.
 */
const useUserAccountRoles = ({ userId }: UseUserAccountRolesParams): UseUserAccountRolesReturn => {
  const { accountId } = useAccountContext();

  const { isLoading, roles } = useRawAccountUserRoles({ accountId, userId }, { skip: !accountId });

  return {
    isUserAdmin: roles.includes(USER_ROLES.admin),
    isUserPowerUser: roles.includes(USER_ROLES.powerUser),
    isUserRestrictedUser: roles.includes(USER_ROLES.user),
    isLoading: isLoading,
  };
};

export default useUserAccountRoles;
