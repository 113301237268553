import React from 'react';
import { useGetCatalogResources } from '../../hooks/useGetCatalogResources';
import { ServicePlaceholderCard } from '../components/ServiceCardsV1/ServicePlaceholderCard';
import { ServiceCard } from '../components/ServiceCardsV1/ServiceCard';
import { CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { isServiceCatalogResource } from '../../catalogResource/utils';

type UseServiceCardsProps = {
  filter?: (resource: CatalogResource) => boolean;
  onClick?: (serviceRef: string) => void;
  getTargetPath?: (serviceRef: string) => string;
};

export const useServiceCardsV1 = (props: UseServiceCardsProps) => {
  const { resources, isLoading } = useGetCatalogResources({
    resourcesFilter: (item) => isServiceCatalogResource(item) && (!props?.filter || props.filter(item)),
    skipGlobalResources: true,
  });

  return (
    <>
      {isLoading
        ? Array.from({ length: 5 }).map((_, i) => <ServicePlaceholderCard key={i} />)
        : resources
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((resource) => resource.status === 'ACTIVE')
            .map((resource) => (
              <ServiceCard
                key={resource.urn}
                service={resource}
                onClick={props?.onClick}
                targetPath={props.getTargetPath?.(resource.urn)}
              />
            ))}
    </>
  );
};
