import { useEffect, useState } from 'react';
import {
  ListApplicationUsersQuery,
  ListApplicationUsersQueryVariables,
  UserWithMeasure,
  useLazyListApplicationUsersQuery,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import { useAccountContext } from '../../../account/AccountContext';

export type User = {
  id: string;
  name: string;
  email?: string;
  index: number;
  activityFrequency?: string | null;
  lastAccess?: string | null;
};

type useUsersProps = {
  applicationId?: string;
};

export const useUsers = ({ applicationId }: useUsersProps, { skip = false }: { skip: boolean }) => {
  const { accountId } = useAccountContext();
  const [isFetching, setIsFetching] = useState(true);
  const [users, setUsers] = useState<Array<User>>([]);

  const [fetchApplicationUsers] = useLazyListApplicationUsersQuery();

  const getUserList = async () => {
    const usersDataAll = await fetchAllPages<
      ListApplicationUsersQuery,
      ListApplicationUsersQueryVariables,
      UserWithMeasure
    >({
      fetchFn: fetchApplicationUsers,
      fetchArgs: { accountId: accountId!, applicationId: applicationId!, limit: 500 },
      getNextTokenFn: (data) => data.listUsersWithMeasure?.nextToken,
      getItemsFn: (data) => data.listUsersWithMeasure?.items || [],
      preferCache: true,
    });

    return (
      usersDataAll?.map<User>((user, index) => ({
        id: user.id,
        name: user.name,
        email: /(?:email:)(?<email>[^$]+)/.exec(user.identityKey ?? '')?.groups?.email ?? undefined,
        lastAccess: user.measureReports?.lastActivity56d || null,
        activityFrequency: user.measureReports?.activityFrequencyLast56d || null,
        index,
      })) ?? []
    );
  };

  useEffect(() => {
    if (skip) return;
    void (async () => {
      setIsFetching(true);

      const userList = await getUserList();
      setUsers(userList);

      setIsFetching(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  return {
    isFetching,
    data: users,
  };
};
