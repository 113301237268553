import { TaskDueDateConfiguration } from '../definitionsTypes';
import { DEFINITION_VERSION } from '../constants';

type Duration = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const parseISODurationToObject = (isoString: string): Duration => {
  const pattern = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  const match = pattern.exec(isoString);

  if (!match) {
    throw new Error(`Invalid ISO 8601 duration: ${isoString}`);
  }

  return {
    years: parseInt(match[1] || '0', 10),
    months: parseInt(match[2] || '0', 10),
    days: parseInt(match[3] || '0', 10),
    hours: parseInt(match[4] || '0', 10),
    minutes: parseInt(match[5] || '0', 10),
    seconds: parseInt(match[6] || '0', 10),
  };
};

const DEFAULT_DURATION: Duration = {
  years: 0,
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

/*
 * Converts the DueDate task configuration to a duration object.
 * We only support ISO 8601 duration format for now, but
 * we can add more formats in the future.
 */
export const getDurationFromDueDateConfiguration = ({ value, valueType }: TaskDueDateConfiguration): Duration => {
  try {
    if (valueType === 'ISO_8601') {
      return parseISODurationToObject(value);
    }
  } catch (error) {
    return DEFAULT_DURATION;
  }

  return DEFAULT_DURATION;
};

export const getDueDateConfigurationFromDuration = (duration: Duration): TaskDueDateConfiguration => {
  return {
    kind: 'Tasks:DueDate',
    valueType: 'ISO_8601',
    value: `P${duration.years}Y${duration.months}M${duration.days}DT${duration.hours}H${duration.minutes}M${duration.seconds}S`,
    version: DEFINITION_VERSION,
  };
};
