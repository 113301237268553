import { useMemo } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

import { useAccountContext } from '../../../../account/AccountContext';
import { FEATURES } from '../../../../features/constants';

export const useContractActiveLinkedRequests = (contract: Contract) => {
  const { isFeatureEnabled } = useAccountContext();
  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  const hasActiveLinkedRequests = useMemo(() => {
    return isIWEnabled && contract.workflow?.hasActiveRenewalRequest;
  }, [contract.workflow?.hasActiveRenewalRequest, isIWEnabled]);

  return {
    hasActiveLinkedRequests,
  };
};
