import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { IconWrapper, Text } from '@verticeone/design-system';
import { useAccountContext } from '../../../../../account/AccountContext';
import { VISOTRUST_PROVIDER_ID } from '../../../../../integrations/VisoTrust/constants';
import { VisoTrustIcon } from '../../../../../integrations/VisoTrust/icons';
import { ConnectionFormSchema } from '../../../../../integrations/VisoTrust/types';
import { EditorDrawerScrollContainer } from '../../../../workflowSchema';
import { TaskDetailLink } from './TaskDetailLink';

type CreateRelationshipCompleteProps = {
  taskOverview?: TaskOverview;
};

export const CreateRelationshipComplete: FC<CreateRelationshipCompleteProps> = ({ taskOverview }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
  });

  const baseUrl = (integrationData?.parameters?.connectionForm as ConnectionFormSchema | undefined)?.workspaceUrl;
  const relationshipId = taskOverview?.task.result?.relationshipId;
  const url = `${baseUrl}/requests/${relationshipId}`;

  return (
    <EditorDrawerScrollContainer>
      <Stack component={TaskDetailLink} href={url} target="_blank" direction="row" gap={2}>
        <IconWrapper icon={VisoTrustIcon} />
        <Text variant="body-bold" size="S" color="text1">
          {t('INTELLIGENT_WORKFLOWS.TASK_DETAIL.VISOTRUST_CREATE_RELATIONSHIP_SERVICE.LINK')}
        </Text>
        <IconWrapper icon={OpenInNewIcon} />
      </Stack>
    </EditorDrawerScrollContainer>
  );
};
