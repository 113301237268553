import { Stack, SxProps, Theme, styled, useTheme } from '@mui/material';
import { FC, ReactNode, useCallback } from 'react';
import { Check } from '@mui/icons-material';
import { Text } from '@verticeone/design-system';
import { match } from 'ts-pattern';

const ProgressCircle = styled(Stack)(({ theme: { palette } }) => ({
  borderRadius: 12,
  border: `3px solid ${palette.core.color5}`,
  width: 24,
  height: 24,
  fill: palette.success.color2,
}));

const NotVisitedCircle = styled(Stack)(({ theme: { palette } }) => ({
  borderRadius: 12,
  border: `2px solid ${palette.inactive.color3}`,
  width: 24,
  height: 24,
}));

const CheckedCircle = styled(Check)(({ theme: { palette } }) => ({
  background: palette.success.color4,
  borderRadius: 12,
  border: `2px solid ${palette.success.color4}`,
  width: 24,
  height: 24,
  fill: palette.success.color2,
}));

type FormStepsItemProps = {
  visited: boolean;
  valid: boolean;
  onClick?: () => void;
  children: ReactNode | ReactNode[];
};

export const FormStepsItem: FC<FormStepsItemProps> = (props) => {
  const { onClick, valid, visited, children } = props;
  const { palette } = useTheme();

  const handleClick = useCallback(() => {
    if (!visited || !onClick) return;
    onClick();
  }, [onClick, visited]);

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      p={2}
      sx={{ cursor: visited ? 'pointer' : 'auto' }}
      onClick={handleClick}
    >
      {match({ visited, valid })
        .with({ visited: false }, () => (
          <>
            <NotVisitedCircle />
            <Text variant="button-regular" size="S" color={palette.text.color4}>
              {children}
            </Text>
          </>
        ))
        .with({ visited: true, valid: true }, () => (
          <>
            <CheckedCircle />
            <Text variant="button-bold" size="S" color={palette.text.color2}>
              {children}
            </Text>
          </>
        ))
        .with({ visited: true, valid: false }, () => (
          <>
            <ProgressCircle />
            <Text variant="button-bold" size="S" color={palette.text.color2}>
              {children}
            </Text>
          </>
        ))
        .exhaustive()}
    </Stack>
  );
};

type FormStepsListProps = {
  children?: ReactNode | ReactNode[];
  sx?: SxProps<Theme>;
};

export const FormStepsList: FC<FormStepsListProps> = (props) => {
  const { children, sx } = props;
  const { palette } = useTheme();

  return (
    <Stack p={6} gap={2} sx={sx} bgcolor={palette.background.default}>
      {children}
    </Stack>
  );
};
