import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Wizard } from '@vertice/core/src/components/Wizard';
import { RenewalFormData } from './types';
import ConfirmRenewalRequirementsStep from '../SharedRenewalSteps/ConfirmRenewalRequirements/ConfirmRenewalRequirementsStep';
import AdditionalRequirementsStep from '../SharedRenewalSteps/AdditionalRequirements/AdditionalRequirementsStep';
import RenewalInProgressStep from '../SharedRenewalSteps/RenewalInProgress/RenewalInProgressStep';
import { useLoadExistingContractV2Data } from './useLoadExistingContractV2Data';
import { Box } from '@mui/material';

const defaultValues: RenewalFormData = {
  type: 'renewal',
  files: [],
  vendor: null,
  products: [],
  lineItems: [],
  contractCurrency: DEFAULT_CURRENCY,
  departmentId: null,
  approvedBudget: null,
  signingEntity: '',
  renewalType: null,
  legalReviewRequired: false,
  securityReviewRequired: false,
  piiExpectedToBeHeld: false,
  contactVendorDirectly: true,
  discussMultiYearDeals: true,
  discussPlannedGrowth: true,
  discussCompetitors: true,
  discussCaseStudies: false,
  signatoryName: '',
  signatoryEmail: '',
  vendorContactEmail: '',
  vendorContactName: '',
  totalAnnualCost: null,
  existingContractId: undefined,
};

const RenewalWizard = () => {
  const formMethods = useForm<RenewalFormData>({ defaultValues, mode: 'onChange' });
  useLoadExistingContractV2Data(defaultValues, formMethods.reset);

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={() => formMethods.handleSubmit} p={10}>
        <Wizard initialStep="confirm_renewal_requirements">
          <ConfirmRenewalRequirementsStep stepId="confirm_renewal_requirements" fullWidth={true} />
          <AdditionalRequirementsStep stepId="additional_requirements" />
          <RenewalInProgressStep stepId="renewal_in_progress" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export default RenewalWizard;
