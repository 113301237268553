import React, { PropsWithChildren, useState } from 'react';
import { FilterAltOutlined, MonetizationOnOutlined } from '@mui/icons-material';
import { ChartWrapper } from '../ChartCard';
import { CreateRequestDialog } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/CreateRequestDialog';
import { useChartContext } from '../ChartCard';
import { EmptyState } from '../../types';

type EmptyGraphWrapperProps = EmptyState & PropsWithChildren;

const EmptyGraphWrapper = ({ isEmpty, isFilteredEmpty, children }: EmptyGraphWrapperProps) => {
  const { resetRange } = useChartContext();
  const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);

  const handleClick = () => {
    setCreateRequestDialogOpen(true);
  };

  return (
    <>
      <ChartWrapper
        isEmpty={isEmpty}
        isFilteredEmpty={isFilteredEmpty}
        testId="completed-requests"
        noFilteredData={{ icon: FilterAltOutlined, onClick: resetRange }}
        noData={{ icon: MonetizationOnOutlined, onClick: handleClick }}
        tKeyPrefix="INTELLIGENT_WORKFLOWS.DASHBOARD.COMPLETED_REQUESTS"
      >
        {children}
      </ChartWrapper>
      {isEmpty && (
        <CreateRequestDialog
          open={createRequestDialogOpen}
          setOpen={(open) => setCreateRequestDialogOpen(open)}
          key={createRequestDialogOpen ? 'open' : 'closed'}
        />
      )}
    </>
  );
};

export default EmptyGraphWrapper;
