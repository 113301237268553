import { FC } from 'react';
import { Stack, styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Loader } from '@verticeone/design-system';

import { DnDNodesGroup } from './DnDNodesGroup';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { DnDNodesGroup as DnDNodesGroupType } from './types';

const NodesGroupList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  paddingTop: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  overflowY: 'auto',
  height: '100%',
  maxHeight: '100%',
}));

const Container = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(2),
  paddingTop: theme.spacing(4),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderRadius: theme.spacing(4),
  border: `1px solid ${theme.palette.core.color3}`,
  backgroundColor: theme.palette.core.color1,
  boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '30' }),
  width: 240,
  position: 'absolute',
  top: 0,
  left: 0,
  height: `calc(100% - ${theme.spacing(4)})`,
  zIndex: 1,
}));

const AnimatedContainer = motion.create(Container);

type NodesPanelProps = {
  isOpen: boolean;
  nodeGroups: DnDNodesGroupType[];
  isLoading: boolean;
  onScroll?: (scrollTop: number) => void;
};

export const DnDNodesPanel: FC<NodesPanelProps> = ({ isOpen, nodeGroups, isLoading, onScroll }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <AnimatedContainer
          initial={{ opacity: 0, x: -240 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -240 }}
          transition={{ duration: 0.3 }}
        >
          {isLoading ? (
            <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />
          ) : (
            <NodesGroupList onScroll={(event) => onScroll?.(event.currentTarget.scrollTop)}>
              {nodeGroups.map((group) => (
                <DnDNodesGroup component="li" key={group.id} group={group} />
              ))}
            </NodesGroupList>
          )}
        </AnimatedContainer>
      )}
    </AnimatePresence>
  );
};
