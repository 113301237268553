import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import {
  GoodFormOnSubmitCallback,
  GoodFormProps,
  useGoodFormUtils,
} from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { JiraProject } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/types';
import { Button, SimpleOption } from '@verticeone/design-system';
import { IntegrationSetupDialog } from '../../../../components/IntegrationSetupDialog';
import { EDIT_MODE_DISABLED, FORM_FIELD_WIDTH } from '../constants';
import { EditCardProps, JiraSetupStep, StepContentProps } from '../types';

const projectFormDataSchema = z.object({
  jiraProject: z.array(z.string()).min(1),
});

export type ProjectFormData = z.infer<typeof projectFormDataSchema>;
type ProjectFormProps = GoodFormProps<ProjectFormData> &
  Pick<StepContentProps, 'activeStep'> &
  EditCardProps & {
    projects: JiraProject[];
    isLoadingProjects: boolean;
    isLoading: boolean;
    isDisabled: boolean;
  };

const ProjectFormEntry = createTypedFormEntry<ProjectFormData>();

export const ProjectForm = ({
  defaultValues,
  onSubmit,
  activeStep,
  projects,
  isLoadingProjects,
  isLoading,
  cardEditMode,
  setCardEditMode,
  isDisabled,
}: ProjectFormProps) => {
  const formMethods = useForm<ProjectFormData>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(projectFormDataSchema),
  });
  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const options = projects.map<SimpleOption>((project) => ({
    value: project.id,
    label: project.name,
  }));

  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  const onSave: GoodFormOnSubmitCallback<ProjectFormData> = async (formData) => {
    if (defaultValues.jiraProject.some((projectId) => !formData.jiraProject.includes(projectId))) {
      setShowConfirmDialog(true);
    } else {
      await save(formData);
    }
  };

  const save: GoodFormOnSubmitCallback<ProjectFormData> = async (formData) => {
    await onSubmit(formData);
    formMethods.reset(formData);
    setShowConfirmDialog(false);
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={goodFormMethods.handleSubmit(onSave)}>
          <Stack gap={8} maxWidth={FORM_FIELD_WIDTH}>
            <ProjectFormEntry
              component={FormSelectField2}
              componentProps={{
                options,
                boxValue: (values?: string[]) =>
                  (values ?? [])
                    .map((value) => options.find((option) => option.value === value))
                    .filter((x) => !isNil(x)),
                unboxValue: (opts?: SimpleOption[]) => opts?.map((option) => option.value) ?? [],
                isClearable: false,
                isLoading: isLoadingProjects,
                placeholder: t('INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_PLACEHOLDER'),
                isMulti: true,
              }}
              disabled={isDisabled}
              label={t('INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_LABEL')}
              name="jiraProject"
              required
              tooltip={{ content: t('INTEGRATIONS.JIRA.PROJECT_FORM.FIELD_1_TOOLTIP') }}
            />
            {(cardEditMode.project || !isSuccessStep) && !cardEditMode.connection && (
              <Stack direction="row" gap={2}>
                {isSuccessStep && (
                  <Button
                    variant="outline"
                    size="M"
                    color="neutral"
                    onClick={() => {
                      setCardEditMode(EDIT_MODE_DISABLED);
                      formMethods.reset();
                    }}
                  >
                    {t('INTEGRATIONS.JIRA.PROJECT_FORM.DISMISS_BUTTON')}
                  </Button>
                )}
                <Button
                  variant="solid"
                  size="M"
                  color="primary"
                  type="submit"
                  isLoading={submitButtonProps.isLoading || isLoading}
                  disabled={submitButtonProps.disabled || isLoading}
                  sx={{ alignSelf: 'flex-start' }}
                >
                  {t('INTEGRATIONS.JIRA.PROJECT_FORM.SUBMIT_BUTTON')}
                </Button>
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
      <IntegrationSetupDialog
        isOpened={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onSubmit={() => save(formMethods.getValues())}
        header={t('INTEGRATIONS.JIRA.PROJECT_CHANGED_DIALOG.HEADER')}
        content={t('INTEGRATIONS.JIRA.PROJECT_CHANGED_DIALOG.TEXT')}
        buttonLabel={t('INTEGRATIONS.JIRA.PROJECT_CHANGED_DIALOG.SAVE_BUTTON')}
        isLoading={submitButtonProps.isLoading || isLoading}
      />
    </>
  );
};
