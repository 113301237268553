import { AddExistingContractFormData, isAddExistingContractFormData } from '../AddExistingContractWizard/types';
import { AddExistingContractFormData as AddExistingContractFormDataV2 } from '../AddExistingContractWizardV2/types';
import { AdvancedLinearCostModelLine } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ExternalType } from '../types';
import { NewPurchaseFormData } from '../NewPurchaseWizard/types';
import { isRenewalFormData, RenewalFormData } from '../RenewalWizard/types';
import { CostModelBase } from '@vertice/core/src/modules/saas/contract/costModels/types';
import {
  buildProductIdentifier,
  convertProductFrequencyToItemFrequency,
} from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/utils';
import { CostModelProductItem } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';

export const formDataToExternalContract = (
  formData: AddExistingContractFormData | NewPurchaseFormData | RenewalFormData
) => {
  const externalContract: Partial<ExternalType> = {
    legalReviewRequired: formData.legalReviewRequired,
    securityReviewRequired: formData.securityReviewRequired,
    isPIIHeldExpected: formData.piiExpectedToBeHeld,
    contactVendorDirectly: formData.contactVendorDirectly,
    multiYear: formData.discussMultiYearDeals,
    caseStudies: formData.discussCaseStudies,
    plannedGrowth: formData.discussPlannedGrowth,
    discussCompetitors: formData.discussCompetitors,
    signatoryCustomer: formData.signatoryName,
    signatoryEmail: formData.signatoryEmail,
  };

  if (isAddExistingContractFormData(formData) || isRenewalFormData(formData)) {
    externalContract.vendorAccountManager = formData.vendorContactName;
    externalContract.vendorAccountManagerEmail = formData.vendorContactEmail;
  }

  return externalContract;
};

export const formDataToRawCostModel = (
  formData: NewPurchaseFormData | RenewalFormData,
  getCostModelBase: () => CostModelBase
) => {
  const costModel = {
    baseline: getCostModelBase(),
    negotiated: {
      ...getCostModelBase(),
      model: {
        items: [...formDataProductsToCostModelProductItems(formData)],
      },
    },
  };
  return {
    configuration: {
      negotiated: costModel.negotiated.id,
      baseline: costModel.baseline.id,
    },
    instances: [costModel.baseline, costModel.negotiated],
  };
};

export const formDataProductsToCostModelProductItems = (
  formData: AddExistingContractFormData | AddExistingContractFormDataV2 | NewPurchaseFormData | RenewalFormData
) => {
  if (formData.products.length === 0) {
    return [];
  }

  const isRolling =
    isAddExistingContractFormData(formData) && formData.rollingFrequency && formData.rollingFrequency !== 'NO';

  return formData.products.map(
    (p: CostModelProductItem) =>
      ({
        id: p.productId,
        lineItemType: 'DEBIT',
        lineItemCategory: 'PRODUCT',
        identifier: buildProductIdentifier(p),
        licenseType: p.licenseType,
        unitPrice: p.licenseCost,
        licenseCount: p.numberOfLicences,
        totalPrice: p.totalCost,
        startDate: isRolling ? undefined : p.allocationSpan?.startDate,
        endDate: isRolling ? undefined : p.allocationSpan?.endDate,
        rollingFrequency: isRolling
          ? undefined
          : convertProductFrequencyToItemFrequency(p.allocationSpan?.rollingFrequency),
      } satisfies AdvancedLinearCostModelLine)
  );
};

export const getProductsWithoutCosts = (prods: CostModelProductItem[]) => {
  return prods.map(({ licenseCost, totalCost, allocationSpan, ...productProps }) => ({
    ...productProps,
    licenseCost: null,
    totalCost: null,
    allocationSpan: null,
  }));
};
