import { AccountUser, useGetAccountUserQuery, useIssueIdentityTokenForRealmForSelfMutation } from '@vertice/slices';
import React, { useCallback, useContext } from 'react';
import { useAccountContext } from '../modules/account/AccountContext';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { AuthType, useAuthentication, useLoggedUser } from '@verticeone/auth/src';
import { FEATURES } from '../modules/features/constants';

type ZendeskHandle = {
  showWidget: () => void;
  prefillUserInfo: () => void;
};

type CustomerSuccessContextState = {
  fetchZendeskHandle: (user?: AccountUser) => Promise<ZendeskHandle>;
};

const EmptyZendeskHandle: ZendeskHandle = {
  showWidget: () => {},
  prefillUserInfo: () => {},
};

const fetchZendeskHandle: (accountUser?: AccountUser) => Promise<ZendeskHandle> = (accountUser?: AccountUser) =>
  initializeZendesk()
    .then(() => ({
      showWidget: () => {
        if (window.zE) {
          window.zE('webWidget', 'helpCenter:reauthenticate');
          window.zE('webWidget', 'show');
          window.zE('webWidget', 'open');

          window.zE('webWidget:on', 'close', function () {
            window.zE('webWidget', 'hide');
          });

          if (accountUser) {
            accountUser && prefillUserInfo(accountUser);
          }
        }
      },
      prefillUserInfo: () => accountUser && prefillUserInfo(accountUser),
    }))
    .catch(() => EmptyZendeskHandle);

const prefillUserInfo = (userProfile: AccountUser) => {
  if (window.zE) {
    window.zE('webWidget', 'prefill', {
      name: {
        value: `${userProfile?.firstName || ''} ${userProfile?.lastName || ''}`,
        readOnly: true,
      },
      email: {
        value: userProfile?.email || '',
        readOnly: true,
      },
    });
  }
};

export const CustomerSuccessContext = React.createContext<CustomerSuccessContextState>({
  fetchZendeskHandle: () => Promise.resolve(EmptyZendeskHandle),
});

const initializeZendesk = () => {
  if (window.zE) {
    return Promise.resolve(window.zE);
  } else {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=aa841990-9a29-4d47-b417-921f3c8f2a48';
      script.id = 'ze-snippet';
      script.async = false;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        if (window.zE) {
          window.zE('webWidget', 'hide');
        }
        resolve(window.zE);
      };
      document.body.appendChild(script);
    });
  }
};

/* A wrapper to be used in Router to allow state sharing between Contract pages - filter from list needs to be preserved when going to detail and back */
export const CustomerSuccessContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const { accountId } = useAccountContext();
  const { type: authType } = useAuthentication();

  const { isEnabled } = useFeatures();
  const zendeskEnabled = isEnabled(FEATURES.ZENDESK) && authType !== AuthType.ASSUME_ROLE;

  const [issueIdentityTokenForSelf] = useIssueIdentityTokenForRealmForSelfMutation();
  const { userId } = useLoggedUser();
  const { data: userProfile } = useGetAccountUserQuery(
    { userId, accountId: accountId! },
    { skip: !userId || !zendeskEnabled }
  );

  if (!window.zESettings && zendeskEnabled) {
    window.zESettings = {
      helpCenter: {
        originalArticleButton: true,
      },
      webWidget: {
        answerBot: {
          contactOnlyAfterQuery: true,
        },
        contactForm: {
          ticketForms: [{ id: 20696895516433 }],
        },
        authenticate: {
          jwtFn: function (callback: (token: string) => null) {
            issueIdentityTokenForSelf({ realm: 'zendesk', accountId })
              .then((result) => {
                if ('data' in result) {
                  callback(result.data.identityToken);
                }
              })
              .catch((error) => {});
          },
        },
      },
    };
  }

  return (
    <CustomerSuccessContext.Provider
      value={{
        fetchZendeskHandle:
          zendeskEnabled && userProfile ? fetchZendeskHandle : () => Promise.resolve(EmptyZendeskHandle),
      }}
    >
      {children}
    </CustomerSuccessContext.Provider>
  );
};

export const useCustomerSuccessWidget = () => {
  const { isEnabled } = useFeatures();
  const zendeskEnabled = isEnabled(FEATURES.ZENDESK);

  const { fetchZendeskHandle: fetchZendeskHandleFromContext } = useContext(CustomerSuccessContext);

  const showWidgetViaHandle = useCallback(async () => {
    const { showWidget } = await fetchZendeskHandleFromContext();
    showWidget();
  }, [fetchZendeskHandleFromContext]);

  return {
    showWidget: showWidgetViaHandle,
    zendeskEnabled,
  };
};
