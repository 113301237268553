import { FC } from 'react';
import { Stack } from '@mui/material';

import { Card } from '@verticeone/design-system';

import { WorkflowViewer } from '../../../../workflowSchema';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { Definitions } from '../../../../definitionsTypes';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { GetWorkflowVersionApiResponse } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRequestContext } from '../../RequestContext';
import { getWorkflowDefinitionsFromDefinitions } from '../../../../definitions/processDefinition';

type TasksSectionProps = {
  request: Request;
  workflowVersion?: GetWorkflowVersionApiResponse;
};

export const VisualizerSection: FC<TasksSectionProps> = ({ request, workflowVersion }) => {
  const routes = useRoutes();
  const requestContext = useRequestContext();

  const workflowDefinitions = getWorkflowDefinitionsFromDefinitions(
    workflowVersion?.workflowDefinition as Definitions | undefined
  );

  return (
    <Card>
      <Stack height={'min(calc(100vh - 300px), 700px)'}>
        {workflowDefinitions ? (
          <WorkflowViewer
            workflowDefinitions={workflowDefinitions}
            requestId={parseRequestRef(request.ref).requestId}
            requestRoute={routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL.ABSOLUTE_PATH}
            allowVerticeServiceNavigation={requestContext.allowVerticeServiceNavigation}
          />
        ) : null}
      </Stack>
    </Card>
  );
};
