import { Stack } from '@mui/material';
import { useState } from 'react';

import { EDIT_MODE_DISABLED } from '../constants';
import { CardEditMode, JiraSetupStep, StepContentProps } from '../types';
import { ConnectionCard } from './ConnectionCard';
import { ProjectCard } from './ProjectCard';
import { SynchronizationCard } from './SynchronizationCard';
import { WorkflowTriggersCard } from './WorkflowTriggers/WorkflowTriggersCard';

export const StepContent = ({ activeStep, setStep }: StepContentProps) => {
  const [cardEditMode, setCardEditMode] = useState<CardEditMode>(EDIT_MODE_DISABLED);
  const handleSetCardEditMode = (newValue: CardEditMode) => {
    setCardEditMode(newValue);
  };

  const isConnectionStep = activeStep === JiraSetupStep.CONNECTION;
  const isConnectionActive = activeStep === JiraSetupStep.SUCCESS;

  return (
    <Stack gap={3}>
      <ConnectionCard
        activeStep={activeStep}
        setStep={setStep}
        cardEditMode={cardEditMode}
        setCardEditMode={handleSetCardEditMode}
      />
      {!isConnectionStep && (
        <ProjectCard
          activeStep={activeStep}
          setStep={setStep}
          cardEditMode={cardEditMode}
          setCardEditMode={handleSetCardEditMode}
        />
      )}
      {isConnectionActive && (
        <>
          <SynchronizationCard />
          <WorkflowTriggersCard />
        </>
      )}
    </Stack>
  );
};
