import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { Card, Text, Button, ToggleSwitch, ChipButton, Loader } from '@verticeone/design-system';
import { useChangeIntegrationStatus } from '../../dataSource/useChangeIntegrationStatus';
import { ConfirmationDialog } from '@vertice/components';
import { useSnackbar } from 'notistack';

type IntegrationCardProps = {
  id: string;
  name: string;
  authType: string;
  enabled: boolean;
};

const IntegrationCard = ({ id, name, authType, enabled }: IntegrationCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.INTEGRATION_CARD' });
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const [confirmDisableIntegration, setConfirmDisableOpen] = useState(false);
  const [integrationStatusChanging, setIntegrationChange] = useState(false);
  const { toggleActiveState, isEnabled } = useChangeIntegrationStatus({ enabled });
  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmedDisable = async () => {
    if (isEnabled) {
      setIntegrationChange(true);
      try {
        await toggleActiveState(id);
      } catch (error) {
        enqueueSnackbar(t('ERRORS.UPDATE_STATE'), { variant: 'error' });
      } finally {
        setIntegrationChange(false);
        setConfirmDisableOpen(false);
      }
    }
  };

  const handleCanceledDisable = () => {
    if (!integrationStatusChanging) {
      setConfirmDisableOpen(false);
    }
  };

  const toggleIntegration = async () => {
    if (isEnabled) {
      setConfirmDisableOpen(true);
    } else {
      setIntegrationChange(true);
      try {
        await toggleActiveState(id);
      } catch (error) {
        enqueueSnackbar(t('ERRORS.UPDATE_STATE'), { variant: 'error' });
      } finally {
        setIntegrationChange(false);
      }
    }
  };

  const goToIntegration = () => navigate(routes.INTEGRATIONS.CUSTOM.VIEW, { integrationId: id });

  return (
    <Card height="100%">
      <Stack p={4} gap={6} direction="column">
        <Text variant="heading" size="S" color="text1">
          {name}
        </Text>
        <Stack direction="row">
          <ChipButton variant="ghost" size="XS" color="neutral" isActive={false}>
            {authType}
          </ChipButton>
        </Stack>
      </Stack>
      {!integrationStatusChanging ? (
        <Stack
          p={6}
          mt={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          borderTop="1px solid"
          borderColor="core.color3"
        >
          <Button variant="outline" size="S" color="neutral" onClick={goToIntegration}>
            {t('VIEW_BUTTON')}
          </Button>
          <Stack gap={2} direction="row" alignItems="center">
            <ToggleSwitch size="S" color="primary" checked={isEnabled} onChange={toggleIntegration} />
            <Text variant="button-regular" size="S" color="inactive2">
              {t(isEnabled ? 'ACTIVE_SWITCH' : 'INACTIVE_SWITCH')}
            </Text>
          </Stack>
        </Stack>
      ) : (
        <Stack
          p={4}
          mt={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          borderTop="1px solid"
          borderColor="core.color3"
        >
          <Loader />
        </Stack>
      )}
      <ConfirmationDialog
        isOpen={confirmDisableIntegration}
        headerText={t('DISABLE_INTEGRATION_DIALOG.HEADING')}
        bodySecondaryText={t('DISABLE_INTEGRATION_DIALOG.MESSAGE')}
        secondaryButtonText={t('DISABLE_INTEGRATION_DIALOG.CANCEL')}
        secondaryButtonAction={handleCanceledDisable}
        primaryButtonText={t('DISABLE_INTEGRATION_DIALOG.YES')}
        primaryButtonAction={handleConfirmedDisable}
        primaryButtonLoading={integrationStatusChanging}
      />
    </Card>
  );
};

export default IntegrationCard;
