import { useLazyRiListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import { parseOperatingSystem } from './utils';
import type { EC2DataEnhanced } from './useEC2Data';
import type { BaseFilter, UseOfferingData } from '../../../types';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type EC2Filter = BaseFilter;

export const useEc2RiOfferingData = (): UseOfferingData<EC2DataEnhanced, EC2Filter> => {
  const { accountId } = useAccountContext();
  const [getRIListOfferings, { isFetching }] = useLazyRiListOfferingsQuery();

  return async (item: EC2DataEnhanced, filter: EC2Filter) => {
    const response = await getRIListOfferings({
      service: 'ec2',
      accountId: accountId!,
      region: item.region,
      instanceFamily: item.instanceFamily,
      instanceSize: item.sizeFlexible ? item.flexGroupMinSize : item.instanceSize,
      productDescription: parseOperatingSystem(item.operatingSystem, item.preInstalledSw),
      ...filter,
    });

    return {
      data: response.data,
      error: response.error,
      isFetching,
    };
  };
};
