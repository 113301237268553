import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { GridColDef } from '@mui/x-data-grid-pro';

import {
  TaskAssignees,
  TaskRequestName,
  TaskName,
  TaskActiveDays,
  TaskStatus,
  TaskDueDate,
  TaskDueDateHeader,
} from './Columns';
import type { TaskRow } from '../../dataSource';
import { getActiveDiffDays } from '../../dataSource/utils';

export const useTaskColumns = (): Array<GridColDef<TaskRow>> => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columns: Array<GridColDef<TaskRow>> = [
      {
        field: 'name',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_NAME_COLUMN'),
        renderCell: ({ row }) => <TaskName taskRow={row} />,
        flex: 2,
      },
      {
        field: 'status',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.TASK_STATUS_COLUMN'),
        renderCell: ({ row }) => <TaskStatus taskRow={row} />,
        width: 150,
        sortable: false,
      },
      {
        field: 'dueDate',
        valueGetter: ({ row }) => row.dueDate,
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.DUE_DATE_COLUMN'),
        renderCell: ({ row }) => <TaskDueDate taskRow={row} />,
        width: 160,
        renderHeader: TaskDueDateHeader,
      },
      {
        field: 'assignees',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.ASSIGNEE_COLUMN'),
        renderCell: ({ row }) => <TaskAssignees taskRow={row} />,
        sortable: false,
        flex: 2,
      },
      {
        field: 'requestName',
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.REQUEST_COLUMN'),
        renderCell: ({ row }) => <TaskRequestName taskRow={row} />,
        flex: 2,
      },
      {
        field: 'activeDays',
        valueGetter: ({ row }) => getActiveDiffDays(row.createdAt, row.closedAt),
        headerName: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.DAYS_ACTIVE_COLUMN'),
        renderCell: ({ row }) => <TaskActiveDays taskRow={row} />,
        width: 150,
      },
    ] as const;

    return columns.map((c) => ({ ...c, disableColumnMenu: true }));
  }, [t]);
};
