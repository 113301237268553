import { FC, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { NodeProps, Node, Position } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { IconWrapper, ifExpression } from '@verticeone/design-system';
import { DragIndicatorOutlined } from '@mui/icons-material';

import { NodeControls } from './NodeControls';
import { GatewayNodeState, WorkflowGatewayNode } from '../../model/types';
import { GatewayIconMap } from '../../../components/icons/Icons';
import { useWorkflowRendererContext } from '../WorkflowRendererContext';

import { ValidationCheck } from './ValidationCheck';
import { ConnectionPoint } from './ConnectionPoint';

const GatewayIconWrapper = styled(IconWrapper)({
  opacity: 1,
  transition: 'opacity 0.3s ease-in-out',
});

const DragIconWrapper = styled(IconWrapper)({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
});

const DragIconContainer = styled(Stack)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

type StyledGatewayNodeProps = {
  $state?: GatewayNodeState;
  $hasValidationMessage: 'error' | 'warning' | null;
  $withDraggingStyles?: boolean;
  $withDragStyles?: boolean;
};

const StyledGatewayNode = styled(Stack)<StyledGatewayNodeProps>(
  ({ theme: { palette }, $state, $hasValidationMessage, $withDraggingStyles = false, $withDragStyles = false }) => ({
    position: 'relative',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    span: {
      color: palette.text.color2,
      position: 'absolute',
      top: 30,
      width: 100,
    },
    zIndex: 1,
    // diamond shape
    '&:before': {
      content: '""',
      position: 'absolute',
      border: 'none',
      inset: '16%',
      transform: 'rotateZ(45deg)',
      background:
        $state?.selected || $withDraggingStyles
          ? palette.secondary.color2
          : $hasValidationMessage
          ? palette.error.color2
          : $state?.passed
          ? palette.success.color2
          : palette.secondary.color4,
      zIndex: 3,
      borderRadius: 4,
    },
    '&:hover:before': {
      background:
        $state?.selected || $state?.passed || $withDraggingStyles ? undefined : palette.secondary.hover.color4,
    },
    // gradient border in diamond shape
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: '16%',
      padding: '2px',
      background:
        $state?.selected || $withDraggingStyles
          ? palette.secondary.color2
          : $hasValidationMessage
          ? palette.error.color4
          : $state?.passed
          ? palette.success.color2
          : palette.secondary.color3,
      transform: 'rotateZ(45deg) scale(1.1)',
      zIndex: 2,
      borderRadius: 4,
    },
    '&:hover:after': {
      background:
        $state?.selected || $state?.passed || $withDraggingStyles ? undefined : palette.secondary.hover.color3,
    },
    svg: {
      zIndex: 4,
      color:
        $state?.selected || $withDraggingStyles
          ? palette.secondary.color4
          : $hasValidationMessage
          ? palette.error.color4
          : $state?.passed
          ? palette.success.color4
          : palette.secondary.color2,
    },
    '&:hover': {
      ...ifExpression($withDragStyles, {
        [DragIconWrapper]: {
          opacity: 1,
        },
        [GatewayIconWrapper]: {
          opacity: 0,
        },
      }),
      svg: {
        color: $state?.selected || $state?.passed || $withDraggingStyles ? undefined : palette.secondary.hover.color2,
      },
    },
  })
);

export const GatewayNodeComponent: FC<NodeProps<Node<WorkflowGatewayNode>>> = (props) => {
  const { data, dragging } = props;

  const { isEditor, isDragAndDropEnabled } = useWorkflowRendererContext();
  const [hasValidationMessage, setHasValidationMessage] = useState<'error' | 'warning' | null>(null);

  const Icon = GatewayIconMap[data.gatewayType];

  return (
    <>
      <NodeControls
        {...props}
        content={
          <Stack direction="row">
            {isDragAndDropEnabled && (
              <ConnectionPoint
                id={`${data.id}-connection-target`}
                type="target"
                position={Position.Left}
                connectionsLimit="no-limit"
              />
            )}
            <StyledGatewayNode
              $withDraggingStyles={dragging && isDragAndDropEnabled}
              $state={data.state}
              $hasValidationMessage={hasValidationMessage}
              $withDragStyles={isDragAndDropEnabled}
            >
              {isDragAndDropEnabled ? (
                <DragIconContainer>
                  <DragIconWrapper icon={DragIndicatorOutlined} />
                </DragIconContainer>
              ) : null}
              <GatewayIconWrapper icon={Icon} />
            </StyledGatewayNode>
            {isDragAndDropEnabled && (
              <ConnectionPoint
                type="source"
                position={Position.Right}
                id={`${data.id}-connection-source`}
                connectionsLimit="no-limit"
              />
            )}
          </Stack>
        }
      />
      {isEditor && <ValidationCheck setValidationResult={setHasValidationMessage} id={data.id} />}
    </>
  );
};
