import { DataSourcedFieldMetadata, DataSourcedFieldType, dataSourcedFieldMetadataSchema } from './types';
import { DialogContent } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTypedFormEntry } from '../../../fields/FormEntry';
import FormTextField from '../../../fields/FormTextField';
import FormTextArea from '../../../fields/FormTextArea';
import { FormToggleSwitchField } from '../../../fields/FormToggleSwitchField';
import { FieldEditDialog } from '../../DynamicFormBuilder';
import { fieldDef } from './index';
import { BuilderDialogProps, BuilderPluginValues, BuilderPlugins, FormWithPlugins } from '../types';
import { addPluginsToDefaultData, addPluginsToSchema } from '../utils';
import { dataSourcesBySource } from './dataSources';
import { useMemo } from 'react';
import { defaultFieldMetadata } from './fieldDef';

const IW_COLOR = INTELLIGENT_WORKFLOWS_BRAND_COLOR;

const FieldMetadataEntry = createTypedFormEntry<DataSourcedFieldMetadata>();

export const DataSourcedFieldBuilderDialog = <T extends BuilderPlugins>(
  props: BuilderDialogProps<DataSourcedFieldType, T>
) => {
  const { onClose, onSubmit, defaultValues, plugins } = props;
  const { t } = useTranslation();

  const form = useForm<FormWithPlugins<DataSourcedFieldMetadata>>({
    defaultValues: addPluginsToDefaultData(defaultValues ?? defaultFieldMetadata, plugins),
    resolver: zodResolver(addPluginsToSchema(dataSourcedFieldMetadataSchema, plugins)),
    mode: 'all',
  });

  const source = useWatch({ control: form.control, name: 'source' });
  const typeLabel = useMemo(
    () => t(dataSourcesBySource[source]?.labelI18nKey ?? fieldDef.typeNameI18nKey),
    [t, source]
  );

  const submitHandler: SubmitHandler<FormWithPlugins<DataSourcedFieldMetadata>> = (values) => {
    if (onSubmit) {
      const { plugins: outputs, ...metadata } = values;
      onSubmit(metadata, outputs as BuilderPluginValues<T>);
    }
  };

  return (
    <FieldEditDialog form={form} schema={dataSourcedFieldMetadataSchema} onSubmit={form.handleSubmit(submitHandler)}>
      <FieldEditDialog.Header mode={defaultValues ? 'edit' : 'create'} titleSuffix={typeLabel}>
        <FieldMetadataEntry
          name="required"
          component={FormToggleSwitchField}
          componentProps={{
            label: t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD'),
            color: IW_COLOR,
          }}
        />
      </FieldEditDialog.Header>
      <DialogContent>
        <FieldMetadataEntry
          name="label"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_LABEL')}
          component={FormTextField}
          componentProps={{ color: IW_COLOR, inputProps: { maxLength: 40 } }}
        />
        <FieldMetadataEntry
          name="description"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.HELPTEXT')}
          component={FormTextArea}
          componentProps={{ color: IW_COLOR, size: 'M', variant: 'outline' }}
        />
        {plugins?.map(({ Component }, index) => (
          <Component key={index} name={`plugins.${index}`} fieldType="DATASOURCE" />
        ))}
      </DialogContent>
      <FieldEditDialog.Footer isFormValid={form.formState.isValid} onClose={onClose} />
    </FieldEditDialog>
  );
};
