import { workflowsV2Api as api } from '../../api/workflowsV2API';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    storeWorkflowDefinition: build.mutation<StoreWorkflowDefinitionApiResponse, StoreWorkflowDefinitionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowDefinitions/${queryArg.fileName}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    listWorkflows: build.query<ListWorkflowsApiResponse, ListWorkflowsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    deployWorkflow: build.mutation<DeployWorkflowApiResponse, DeployWorkflowApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows`,
        method: 'PUT',
        body: queryArg.deployWorkflow,
      }),
    }),
    validateWorkflow: build.mutation<ValidateWorkflowApiResponse, ValidateWorkflowApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/validations/workflowDefinition`,
        method: 'POST',
        body: queryArg.validateWorkflow,
      }),
    }),
    validateWorkflowDefinition: build.mutation<ValidateWorkflowDefinitionApiResponse, ValidateWorkflowDefinitionApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/validations/validateWorkflowDefinition`,
          method: 'POST',
          body: queryArg.validateWorkflowDefinition,
        }),
      }
    ),
    deployWorkflowVersion: build.mutation<DeployWorkflowVersionApiResponse, DeployWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions`,
        method: 'PUT',
        body: queryArg.deployWorkflow,
      }),
    }),
    listWorkflowVersions: build.query<ListWorkflowVersionsApiResponse, ListWorkflowVersionsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions`,
        params: {
          maxResults: queryArg.maxResults,
          nextToken: queryArg.nextToken,
        },
      }),
    }),
    redeployWorkflowVersion: build.mutation<RedeployWorkflowVersionApiResponse, RedeployWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}`,
        method: 'PUT',
        body: queryArg.deployWorkflow,
      }),
    }),
    updateWorkflowVersion: build.mutation<UpdateWorkflowVersionApiResponse, UpdateWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}`,
        method: 'PATCH',
        body: queryArg.updateWorkflowResource,
      }),
    }),
    getWorkflowVersion: build.query<GetWorkflowVersionApiResponse, GetWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}`,
      }),
    }),
    deleteWorkflowVersion: build.mutation<DeleteWorkflowVersionApiResponse, DeleteWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}`,
        method: 'DELETE',
      }),
    }),
    activateWorkflowVersion: build.mutation<ActivateWorkflowVersionApiResponse, ActivateWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/activate`,
        method: 'POST',
        body: queryArg.body,
        params: {
          serviceRef: queryArg.serviceRef,
        },
      }),
    }),
    archiveWorkflowVersion: build.mutation<ArchiveWorkflowVersionApiResponse, ArchiveWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/archive`,
        method: 'POST',
      }),
    }),
    distributeWorkflowVersion: build.mutation<DistributeWorkflowVersionApiResponse, DistributeWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/distribute`,
        method: 'POST',
      }),
    }),
    revalidateWorkflowVersion: build.mutation<RevalidateWorkflowVersionApiResponse, RevalidateWorkflowVersionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/revalidate`,
        method: 'POST',
      }),
    }),
    startWorkflowInstance: build.mutation<StartWorkflowInstanceApiResponse, StartWorkflowInstanceApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/instances`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listWorkflowInstances: build.query<ListWorkflowInstancesApiResponse, ListWorkflowInstancesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflows/${queryArg.workflowId}/versions/${queryArg.workflowVersion}/instances`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    listAccountWorkflowInstances: build.query<
      ListAccountWorkflowInstancesApiResponse,
      ListAccountWorkflowInstancesApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowInstances`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
        },
      }),
    }),
    getWorkflowInstance: build.query<GetWorkflowInstanceApiResponse, GetWorkflowInstanceApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowInstances/${queryArg.workflowInstanceId}`,
      }),
    }),
    getWorkflowInstanceJournal: build.query<GetWorkflowInstanceJournalApiResponse, GetWorkflowInstanceJournalApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowInstances/${queryArg.workflowInstanceId}/journal`,
      }),
    }),
    getWorkflowInstanceSharedMemory: build.query<
      GetWorkflowInstanceSharedMemoryApiResponse,
      GetWorkflowInstanceSharedMemoryApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowInstances/${queryArg.workflowInstanceId}/sharedMemory`,
      }),
    }),
    setWorkflowInstanceSharedMemory: build.mutation<
      SetWorkflowInstanceSharedMemoryApiResponse,
      SetWorkflowInstanceSharedMemoryApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/workflowInstances/${queryArg.workflowInstanceId}/sharedMemory`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    listUserTasks: build.query<ListUserTasksApiResponse, ListUserTasksApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          includeDetails: queryArg.includeDetails,
          includeSystemTasks: queryArg.includeSystemTasks,
        },
      }),
    }),
    listInstanceTasks: build.query<ListInstanceTasksApiResponse, ListInstanceTasksApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/instances/${queryArg.instanceId}/tasks`,
        params: {
          nextToken: queryArg.nextToken,
          maxResults: queryArg.maxResults,
          includeDetails: queryArg.includeDetails,
          includeSystemTasks: queryArg.includeSystemTasks,
        },
      }),
    }),
    getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}` }),
    }),
    getTaskJournal: build.query<GetTaskJournalApiResponse, GetTaskJournalApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/journal` }),
    }),
    draftUserTask: build.mutation<DraftUserTaskApiResponse, DraftUserTaskApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/draft`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    fulfillUserTask: build.mutation<FulfillUserTaskApiResponse, FulfillUserTaskApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/fulfill`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    assignUserTask: build.mutation<AssignUserTaskApiResponse, AssignUserTaskApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/tasks/${queryArg.taskId}/assign`,
        method: 'POST',
        body: queryArg.assignUserTask,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as workflowsV2ApiCodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type StoreWorkflowDefinitionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type StoreWorkflowDefinitionApiArg = {
  accountId: string;
  fileName: string;
  /** File content
   */
  body: Blob;
};
export type ListWorkflowsApiResponse = /** status 200 List of Workflows */ {
  items?: Workflow[];
  /** Token to get the next page of results.
    Example: `thisIsAToken`
     */
  nextToken?: string;
};
export type ListWorkflowsApiArg = {
  accountId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
};
export type DeployWorkflowApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type DeployWorkflowApiArg = {
  accountId: string;
  deployWorkflow: DeployWorkflow;
};
export type ValidateWorkflowApiResponse = /** status 200 List of validation results */ {
  validations?: ValidationItem[];
};
export type ValidateWorkflowApiArg = {
  accountId: string;
  validateWorkflow: ValidateWorkflow;
};
export type ValidateWorkflowDefinitionApiResponse =
  /** status 200 Workflow definition schema enriched with validation results */ {
    /** Number of validation errors */
    errorCount: number;
    /** Number of validation warnings */
    warningCount: number;
    /** Number of validation information messages */
    infoCount: number;
    messages: {
      severity: 'INFO' | 'WARNING' | 'ERROR';
      category: 'GENERAL' | 'STRUCTURE' | 'CONFIGURATION' | 'TYPECHECK';
      code: string;
      message: string | null;
      jsonPaths: string[];
      traversedPath?: string[];
      schemas?: {
        [key: string]: any;
      };
      [key: string]: any;
    }[];
  };
export type ValidateWorkflowDefinitionApiArg = {
  accountId: string;
  validateWorkflowDefinition: ValidateWorkflowDefinition;
};
export type DeployWorkflowVersionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type DeployWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  deployWorkflow: DeployWorkflow;
};
export type ListWorkflowVersionsApiResponse = /** status 200 List of Workflows */ {
  items?: Workflow[];
  /** Token to get the next page of results.
    Example: `thisIsAToken`
     */
  nextToken?: string;
};
export type ListWorkflowVersionsApiArg = {
  accountId: string;
  workflowId: string;
  maxResults?: number;
  nextToken?: PaginationToken;
};
export type RedeployWorkflowVersionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type RedeployWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  deployWorkflow: DeployWorkflow;
};
export type UpdateWorkflowVersionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type UpdateWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  updateWorkflowResource: UpdateWorkflowResource;
};
export type GetWorkflowVersionApiResponse = /** status 200 Workflow */ Workflow;
export type GetWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type DeleteWorkflowVersionApiResponse = /** status 204 Empty Response */ object;
export type DeleteWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type ActivateWorkflowVersionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type ActivateWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  serviceRef?: string;
  /** Version notes
   */
  body: {
    versionNotes?: string;
  };
};
export type ArchiveWorkflowVersionApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type ArchiveWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type DistributeWorkflowVersionApiResponse = /** status 200 Empty Response */ object;
export type DistributeWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type RevalidateWorkflowVersionApiResponse = /** status 200 Empty Response */ object;
export type RevalidateWorkflowVersionApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
};
export type StartWorkflowInstanceApiResponse = /** status 201 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type StartWorkflowInstanceApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  body: {
    /** Optional instance id. If not provided, a random id will be generated.
     */
    instanceId?: string;
    input: object;
    /** Name of the Workflow Instance. If not provided, the name of the workflow will be used.
        Example: `Purchase - Microsoft`
         */
    name?: string;
    /** Reference to the request that started the workflow.
     */
    requestRef?: string;
  };
};
export type ListWorkflowInstancesApiResponse = /** status 200 List of Workflow Instances */ {
  items?: WorkflowInstance[];
  /** Token to get the next page of results.
    Example: `thisIsAToken`
     */
  nextToken?: string;
};
export type ListWorkflowInstancesApiArg = {
  accountId: string;
  workflowId: string;
  workflowVersion: string;
  nextToken?: PaginationToken;
  maxResults?: number;
};
export type ListAccountWorkflowInstancesApiResponse = /** status 200 List of Workflow Instances */ {
  items?: WorkflowInstance[];
  /** Token to get the next page of results.
    Example: `thisIsAToken`
     */
  nextToken?: string;
};
export type ListAccountWorkflowInstancesApiArg = {
  accountId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
};
export type GetWorkflowInstanceApiResponse = /** status 200 Workflow Instance */ WorkflowInstance;
export type GetWorkflowInstanceApiArg = {
  accountId: string;
  workflowInstanceId: string;
};
export type GetWorkflowInstanceJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
};
export type GetWorkflowInstanceJournalApiArg = {
  accountId: string;
  workflowInstanceId: string;
};
export type GetWorkflowInstanceSharedMemoryApiResponse = /** status 200 Get memory response */ {
  sharedMemory?: {
    [key: string]: any;
  };
};
export type GetWorkflowInstanceSharedMemoryApiArg = {
  accountId: string;
  workflowInstanceId: string;
};
export type SetWorkflowInstanceSharedMemoryApiResponse = /** status 200 Set memory response */ {
  /** Reference to the Workflow Instance.
   */
  ref?: string;
};
export type SetWorkflowInstanceSharedMemoryApiArg = {
  accountId: string;
  workflowInstanceId: string;
  body: {
    sharedMemory?: {
      [key: string]: any;
    };
  };
};
export type ListUserTasksApiResponse = /** status 200 List of user tasks */ {
  items: Task[];
  nextToken?: PaginationToken;
};
export type ListUserTasksApiArg = {
  accountId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
  includeDetails?: boolean;
  includeSystemTasks?: boolean;
};
export type ListInstanceTasksApiResponse = /** status 200 List of user tasks */ {
  items: Task[];
  nextToken?: PaginationToken;
};
export type ListInstanceTasksApiArg = {
  accountId: string;
  instanceId: string;
  nextToken?: PaginationToken;
  maxResults?: number;
  includeDetails?: boolean;
  includeSystemTasks?: boolean;
};
export type GetTaskApiResponse = /** status 200 User task details */ TaskDetails;
export type GetTaskApiArg = {
  accountId: string;
  taskId: string;
};
export type GetTaskJournalApiResponse = /** status 200 Request journal response */ {
  items: JournalItem[];
};
export type GetTaskJournalApiArg = {
  accountId: string;
  taskId: string;
};
export type DraftUserTaskApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type DraftUserTaskApiArg = {
  accountId: string;
  taskId: string;
  /** User task draft
   */
  body: {
    [key: string]: any;
  };
};
export type FulfillUserTaskApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type FulfillUserTaskApiArg = {
  accountId: string;
  taskId: string;
  /** User result content
   */
  body: {
    [key: string]: any;
  };
};
export type AssignUserTaskApiResponse = /** status 200 Object reference urn */ {
  /** Reference to the object.
    Example: `123456`
     */
  ref?: string;
};
export type AssignUserTaskApiArg = {
  accountId: string;
  taskId: string;
  /** User task assignment
   */
  assignUserTask: AssignUserTask;
};
export type BasicWorkflowDefinitionValidation = {
  severity: 'INFO' | 'WARNING' | 'ERROR';
  type: 'SCHEMA' | 'STRUCTURE' | 'CONFIGURATION';
  message: string;
  detail?: {
    activityIds?: string[];
  };
}[];
export type Workflow = {
  /** Reference to the Workflow.
    Example: `123456`
     */
  ref: string;
  /** Reference to a Service.
    Example: `urn~3::vertice-contract-workflows:requestType/PURCHASE`
     */
  serviceRef?: string;
  /** Name of the Workflow.
    Example: `My Workflow`
     */
  name: string;
  /** Name of the Workflow version.
   */
  versionName?: string;
  /** Notes for the version.
   */
  versionNotes?: string;
  /** Status of the Workflow.
   */
  status: 'PENDING' | 'ACTIVE' | 'ARCHIVED';
  /** Iso time of the creation of the Workflow.
   */
  createdAt?: string;
  /** Iso time of the last update of the Workflow.
   */
  updatedAt?: string;
  /** Iso time of the activation of the Workflow.
   */
  activatedAt?: string;
  /** Reference to the user that activated the Workflow.
   */
  activatedBy?: string;
  /** Definition of the Workflow.
   */
  workflowDefinition?: {
    [key: string]: any;
  };
  workflowDefinitionRef?: string;
  workflowDefinitionValidation?: BasicWorkflowDefinitionValidation;
  workflowDefinitionValidationRef?: string;
};
export type PaginationToken = string | null;
export type DeployWorkflow = {
  /** Urn of the file.
    Example: `urn~3:account_id:workflows:workflow/workflow_id/workflow_version`
     */
  ref: string;
  /** Name of the Workflow.
    Example: `My Workflow`
     */
  name: string;
  /** Name of the Workflow version.
   */
  versionName?: string;
  /** Notes for the version.
   */
  versionNotes?: string;
  /** Reference to a Service.
    Example: `urn:verticeone:vertice:{{accountId}}:services:service/saas/purchase/v3`
     */
  serviceRef?: string;
};
export type ValidationItem = {
  severity: 'INFO' | 'WARNING' | 'ERROR';
  type: 'SCHEMA' | 'STRUCTURE' | 'CONFIGURATION';
  message: string;
  detail?: {
    activityIds?: string[];
  };
};
export type ValidateWorkflow = {
  /** Process definition.
   */
  processDefinition: string;
};
export type ValidateWorkflowDefinition = {
  /** Process definition.
   */
  processDefinition: string;
  /** Reference to the service.
    Example: `urn:verticeone:vertice::servicecatalog:service/Vertice/SaaS/Contracts/Negotiation/v1`
     */
  serviceUrn: string;
};
export type UpdateWorkflowResource = {
  /** Name of the Workflow.
    Example: `My Workflow`
     */
  name?: string;
  /** Name of the Workflow version.
   */
  versionName?: string;
  /** Notes for the version.
   */
  versionNotes?: string;
};
export type WorkflowInstance = {
  /** Reference to the Workflow Instance.
    Example: `123456`
     */
  ref: string;
  /** Status of the Workflow.
   */
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'TERMINATED' | 'FAILED';
  /** Iso time of the creation of the Workflow Instance.
   */
  createdAt: string;
  /** Ref of the workflow version that the instance is based on.
   */
  workflowRef: string;
  /** Name taken from workflow version that the instance is based on.
   */
  workflowName?: string;
  /** Name provided by whoever starts the instance.
   */
  workflowInstanceName?: string;
  /** List of transition IDs that have been passed at least once
   */
  passedTransitions?: string[];
  /** Error message if workflow instance failed.
   */
  error?: string;
};
export type JournalItem = {
  actor: string | null;
  intents: string[];
  delta: string;
  timestamp: string;
  /** Task ID, if the journal item is related to a task */
  taskId?: string;
  /** Execution ID, if the journal item is related to an execution */
  executionId?: string;
};
export type TaskConfiguration = {
  [key: string]: any;
};
export type Task = {
  /** Reference to the task.
    Example: `123456`
     */
  ref: string;
  name?: string | null;
  description?: string | null;
  workflowInstanceName?: string | null;
  workflowInstanceRef: string;
  type: 'SERVICE' | 'USER';
  /** Status of the task.
   */
  status: 'PENDING' | 'ACTIVE' | 'COMPLETED' | 'TERMINATED' | 'FAILED';
  /** Iso time of the creation of the task.
   */
  createdAt: string;
  /** Iso time of the closing of the task.
   */
  closedAt?: string;
  fulfilledBy?: string;
  /** Task assignees.
   */
  assignment: string[];
  /** Reference to the service resource.
    Example: `urn:verticeone:vertice::servicecatalog:service/Vertice/SaaS/Contracts/Negotiation/v1`
     */
  resourceUrn?: string;
  childRequestRef?: string;
  /** Tasks input.
   */
  input: {
    [key: string]: any;
  };
  /** Task result.
   */
  result?: {
    [key: string]: any;
  } | null;
  /** Tasks configurations.
   */
  configurations?: TaskConfiguration[];
  /** List of transition IDs that have been passed from previous task(s) or start
   */
  passedTransitions?: string[];
  /** Error message if task failed.
   */
  error?: string;
  /** Iso time of the due date of the task.
   */
  dueDate?: string;
};
export type TaskDetails = Task & {
  /** Task draft.
   */
  draft?: {
    [key: string]: any;
  };
};
export type AssignUserTask = {
  /** List of users to assign the task to.
   */
  assignments: string[];
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useStoreWorkflowDefinitionMutation,
  useListWorkflowsQuery,
  useLazyListWorkflowsQuery,
  useDeployWorkflowMutation,
  useValidateWorkflowMutation,
  useValidateWorkflowDefinitionMutation,
  useDeployWorkflowVersionMutation,
  useListWorkflowVersionsQuery,
  useLazyListWorkflowVersionsQuery,
  useRedeployWorkflowVersionMutation,
  useUpdateWorkflowVersionMutation,
  useGetWorkflowVersionQuery,
  useLazyGetWorkflowVersionQuery,
  useDeleteWorkflowVersionMutation,
  useActivateWorkflowVersionMutation,
  useArchiveWorkflowVersionMutation,
  useDistributeWorkflowVersionMutation,
  useRevalidateWorkflowVersionMutation,
  useStartWorkflowInstanceMutation,
  useListWorkflowInstancesQuery,
  useLazyListWorkflowInstancesQuery,
  useListAccountWorkflowInstancesQuery,
  useLazyListAccountWorkflowInstancesQuery,
  useGetWorkflowInstanceQuery,
  useLazyGetWorkflowInstanceQuery,
  useGetWorkflowInstanceJournalQuery,
  useLazyGetWorkflowInstanceJournalQuery,
  useGetWorkflowInstanceSharedMemoryQuery,
  useLazyGetWorkflowInstanceSharedMemoryQuery,
  useSetWorkflowInstanceSharedMemoryMutation,
  useListUserTasksQuery,
  useLazyListUserTasksQuery,
  useListInstanceTasksQuery,
  useLazyListInstanceTasksQuery,
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useGetTaskJournalQuery,
  useLazyGetTaskJournalQuery,
  useDraftUserTaskMutation,
  useFulfillUserTaskMutation,
  useAssignUserTaskMutation,
} = injectedRtkApi;
