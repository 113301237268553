import { DeepPartial } from 'react-hook-form';
import { ContractReviewForm } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SummaryRecord } from '../types';
import usePaymentTermsOptions from '../../../../../../../saas/contract/hooks/usePaymentTermsOptions';
import { useFormatCurrency } from '@verticeone/utils/formatting';

type UseLineItemsSummaryRecordsProps = {
  values: DeepPartial<ContractReviewForm>;
};

export const useLineItemsSummaryRecords = (props: UseLineItemsSummaryRecordsProps) => {
  const { values } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.LABELS' });

  const { getLabel: getPaymentTermLabel } = usePaymentTermsOptions();
  const formatCurrency = useFormatCurrency();

  const records = useMemo<SummaryRecord[]>(() => {
    if (!values.lineItems) return [];
    const currency = values.lineItems.currency;

    const allRecords = [
      { label: t('PAYMENT_TERMS'), value: getPaymentTermLabel(values.lineItems.paymentTerms) ?? '-' },
      { label: t('CURRENCY'), value: currency ?? '-' },
    ];

    const annualCostOverride = values.lineItems.annualCostOverride;
    const totalCostOverride = values.lineItems.totalCostOverride;

    if (annualCostOverride && currency) {
      const annualCostValue = formatCurrency(annualCostOverride.amount, { currency });
      allRecords.push({ label: t('ANNUALIZED_CONTRACT_VALUE'), value: annualCostValue });
    }

    if (totalCostOverride && currency) {
      const totalCostValue = formatCurrency(totalCostOverride.amount, { currency });
      allRecords.push({ label: t('TOTAL_CONTRACT_VALUE'), value: totalCostValue });
    }

    return allRecords;
  }, [getPaymentTermLabel, values, t, formatCurrency]);

  return { records };
};
