import type { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { useQueryParam } from '../../utils';

export const filterItems = ['ALL', 'IN_PROGRESS', 'COMPLETED', 'OVERDUE'] as const;

export type FilterStatus = (typeof filterItems)[number];
export type TasksOnlyParam = 'true' | 'false';

export const useSearchParam = () => useQueryParam<string>('search', '');

export const useFilterParam = () => {
  const queryStatus = useQueryParam<FilterStatus>('status', 'IN_PROGRESS');

  const [queryStatusValue] = queryStatus;

  if (!filterItems.includes(queryStatusValue)) {
    return ['IN_PROGRESS', queryStatus[1]] as const;
  }

  return queryStatus;
};

export const useActiveRowParam = () => useQueryParam<string>('id', '');

export const useMyTasksOnlyParam = () => {
  const [myTasksOnlyValue, setMyTasksOnlyValue] = useQueryParam<TasksOnlyParam>('myTasksOnly', 'true');

  const value = myTasksOnlyValue === 'true';
  const toggle = () => setMyTasksOnlyValue((prev) => (prev !== 'true').toString() as TasksOnlyParam);

  return [value, toggle] as const;
};

export const usePaginationParam = () => {
  const [pageValue, setPageValue] = useQueryParam<string>('page', '0');
  const [pageSizeValue, setPageSizeValue] = useQueryParam<string>('pageSize', '25');

  const pagination = { page: parseInt(pageValue), pageSize: parseInt(pageSizeValue) };

  const updatePagination = ({ page, pageSize }: GridPaginationModel) => {
    if (pagination.page !== page) {
      setPageValue(page.toString());
    }

    if (pagination.pageSize !== pageSize) {
      setPageSizeValue(pageSize.toString());
    }
  };

  return [pagination, updatePagination] as const;
};
