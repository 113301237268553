import { FieldDef } from '../types';
import {
  getDataSourcedFieldJsonSchema,
  getDataSourcedFieldJsonSchemaFromMetadata,
  getDataSourcedFieldPresenterDefault,
  getDataSourcedFieldPresenterSchema,
} from './utils';
import { DataSourcedFieldMetadata, DataSourcedFieldType } from './types';
import { DataSourcedFieldBuilderCard } from './DataSourcedFieldBuilderCard';
import { DataSourcedFieldPresenter } from './DataSourcedFieldPresenter';
import { DataSourcedFieldBuilderDialog } from './DataSourcedFieldBuilderDialog';

export const defaultFieldMetadata: DataSourcedFieldMetadata = {
  label: '',
  required: false,
  description: undefined,
  presenterType: 'DROPDOWN',
  source: '',
};

export const fieldDef: FieldDef<DataSourcedFieldType> = {
  type: 'DATASOURCE',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.DATASOURCE.FIELD_TYPE_NAME',
  builderCard: DataSourcedFieldBuilderCard,
  builderDialog: DataSourcedFieldBuilderDialog,
  presenter: DataSourcedFieldPresenter,
  getPresenterDefault: getDataSourcedFieldPresenterDefault,
  getPresenterSchema: getDataSourcedFieldPresenterSchema,
  getJsonSchemaFromMetadata: getDataSourcedFieldJsonSchemaFromMetadata,
  getJsonSchema: getDataSourcedFieldJsonSchema,
};
