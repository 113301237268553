import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { ApplicationStatus, DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { PageHeader } from '@verticeone/design-system';
import { ErrorLogDrawer } from '../components/ErrorLogDrawer';
import { LastFetchedInfo } from '../components/LastFetchedInfo';
import { useLastFetchedData } from '../hooks/useLastFetchedData';
import { Applications } from './Applications';
import { filterDailyAlarms } from './helpers';
import { useGetApplicationsWithVendors } from './hooks';

const LOG_URL_PARAM = 'logOpen';

type ApplicationsPageParams = {
  /** Router path to the Integrations page. Used as call-to-action if no applications are integrated. */
  integrationsHref?: string;
};

export const ApplicationsPage = ({ integrationsHref }: ApplicationsPageParams) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const [showIgnored, setShowIgnored] = useState(false);
  const [showGoogleSocial, setShowGoogleSocial] = useState(true);
  const [showErrorLog, setShowErrorLog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    applications,
    healthyApplicationsCount,
    unhealthyApplicationsCount,
    isLoading: isLoadingApplications,
  } = useGetApplicationsWithVendors(
    {
      accountId,
      status: showIgnored ? ApplicationStatus.Ignored : ApplicationStatus.Discovered,
      showGoogleSocial,
    },
    { skip: !accountId }
  );

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.Usage);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);

  useEffect(() => {
    const logOpen = searchParams.get(LOG_URL_PARAM);
    if (logOpen === 'true') setShowErrorLog(true);
  }, [searchParams]);

  const handleLogClose = () => {
    searchParams.delete(LOG_URL_PARAM);
    setSearchParams(searchParams);
    setShowErrorLog(false);
  };

  return (
    <Stack>
      <PageHeader
        breadcrumb={
          <PageHeader.Breadcrumbs
            items={[{ label: t('SAAS.NAVBAR.SAAS') }, { label: t('SAAS.NAVBAR.APPLICATIONS') }]}
          />
        }
        color="primary"
        actions={
          <>
            {!isLoadingLastFetchedData && !isLoadingApplications && hasAccessLogs && (
              <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} />
            )}
            {!isLoadingLastFetchedData && (
              <>
                <PageHeader.SecondaryAction onClick={() => setShowErrorLog(true)}>
                  {t('APPLICATIONS.USAGE.ERROR_LOG.BUTTON')}
                </PageHeader.SecondaryAction>
                <ErrorLogDrawer open={showErrorLog} onClose={handleLogClose} alarmsPerDay={alarmsPerDay} />
              </>
            )}
            <PageHeader.MenuAction id="menu">
              <PageHeader.MenuActionItem onClick={() => setShowIgnored(!showIgnored)}>
                {showIgnored
                  ? t('APPLICATIONS.USAGE.SHOW_ACTIVE_APPLICATIONS')
                  : t('APPLICATIONS.USAGE.SHOW_HIDDEN_APPLICATIONS')}
              </PageHeader.MenuActionItem>
              <PageHeader.MenuActionItem onClick={() => setShowGoogleSocial(!showGoogleSocial)}>
                {showGoogleSocial
                  ? t('APPLICATIONS.USAGE.HIDE_GOOGLE_SOCIAL_APPLICATIONS')
                  : t('APPLICATIONS.USAGE.SHOW_GOOGLE_SOCIAL_APPLICATIONS')}
              </PageHeader.MenuActionItem>
            </PageHeader.MenuAction>
          </>
        }
      />
      <Stack direction="column" sx={{ width: 1 }} p={6} pt={0}>
        <Applications
          applications={applications}
          loading={isLoadingApplications}
          healthyApplicationsCount={healthyApplicationsCount}
          unhealthyApplicationsCount={unhealthyApplicationsCount}
          integrationsHref={integrationsHref}
        />
      </Stack>
    </Stack>
  );
};
