import dayjs from 'dayjs';
import { z } from 'zod';
import { t } from 'i18next';

const fixedTermSchema = z.object({
  contractType: z.literal('FIXED'),
  renewalDate: z.string(),
  autoRenewalDate: z.string().nullish(),
  contractLength: z.number().nullish(),
  billingFrequency: z.enum(['MONTHLY', 'QUARTERLY', 'ANNUALLY', 'BIANNUALLY', 'ONE_OFF', 'OTHER']).nullish(),
});

const rollingSchema = z.object({
  contractType: z.literal('ROLLING'),
  rollingFrequency: z.enum(['MONTHLY', 'QUARTERLY', 'ANNUALLY', 'BIANNUALLY']),
  billingFrequency: z.enum(['MONTHLY', 'QUARTERLY', 'ANNUALLY', 'BIANNUALLY', 'ONE_OFF', 'OTHER']).nullish(),
});

const oneofSchema = z.object({
  contractType: z.literal('ONE_OFF'),
});

const baseSchema = z.object({
  signDate: z.string().nullish(),
  startDate: z.string().min(1),
});

export const contractDatesSchema = z
  .union([baseSchema.merge(fixedTermSchema), baseSchema.merge(rollingSchema), baseSchema.merge(oneofSchema)])
  .refine(
    (values) => {
      if (values.contractType !== 'FIXED') return true;
      return dayjs(values.startDate).isBefore(values.renewalDate);
    },
    { path: ['renewalDate'], message: t('ENTITIES.CONTRACT.VALIDATION.RENEWAL_DATE_BEFORE_START_DATE') }
  )
  .refine(
    (values) => {
      if (values.contractType !== 'FIXED' || !values.autoRenewalDate) return true;
      return dayjs(values.startDate).isBefore(values.autoRenewalDate);
    },
    { path: ['autoRenewalDate'], message: t('ENTITIES.CONTRACT.VALIDATION.AUTO_RENEWAL_DATE_BEFORE_START_DATE') }
  );

export type ContractTypeFields =
  | z.infer<typeof fixedTermSchema>
  | z.infer<typeof rollingSchema>
  | z.infer<typeof oneofSchema>;

export type ContractDatesForm = z.infer<typeof contractDatesSchema>;
