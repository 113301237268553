import { Select } from '@verticeone/design-system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { Box } from '@mui/material';
import { uniqWith, isEqual, orderBy } from 'lodash';
import { useGetSavingsDashboardPaginatedQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type DepartmentFilterProps = {
  selectedDepartment?: string;
  setSelectedDepartment: (selectedDepartment?: string) => void;
};

type Department = {
  departmentId: string;
  name: string;
};

const DepartmentFilter = ({ selectedDepartment, setSelectedDepartment }: DepartmentFilterProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetSavingsDashboardPaginatedQuery({
    accountId,
    maxResults: 100, // More items can cause time-outs
  });
  const departments = useMemo(() => {
    if (!data?.contracts) {
      return [];
    }
    const contractDepartments = data?.contracts
      .filter((view) => view.contract.classification?.departmentId)
      .map((view) => {
        const departmentId = view.contract.classification?.departmentId!;
        return {
          departmentId,
          name: view.computed?.labels?.[departmentId],
        };
      });

    return orderBy(uniqWith(contractDepartments, isEqual), 'name');
  }, [data?.contracts]);

  const onChange = (option: SingleValue<Department>) => setSelectedDepartment(option?.departmentId);

  return (
    <Box minWidth={256}>
      <Select<Department, false>
        variant="outlined"
        size="S"
        isClearable={true}
        isLoading={isLoading}
        options={departments}
        value={departments?.find(({ departmentId }) => departmentId === selectedDepartment)}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ departmentId }) => departmentId}
        placeholder={t('DASHBOARD.ALL_DEPARTMENTS')}
        onChange={onChange}
        testId={'department-filter'}
      />
    </Box>
  );
};

export default DepartmentFilter;
