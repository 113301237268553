import { IssueCreatedFormData } from './IssueCreatedForm';
import { StatusChangeFormData } from './StatusChangeForm';
import { StatusChangeFilter, Trigger, TriggerFilter, TriggerType } from './types';

export const isStatusChangeFilter = (triggerFilter: TriggerFilter): triggerFilter is StatusChangeFilter => {
  return (triggerFilter as StatusChangeFilter).type === 'STATUS_CHANGED';
};

const isStatusChangeFormData = (
  formData: StatusChangeFormData | IssueCreatedFormData
): formData is StatusChangeFormData => {
  return (formData as StatusChangeFormData).status !== undefined;
};

export const createTrigger = (triggerType: TriggerType): Trigger => {
  switch (triggerType) {
    case 'ISSUE_CREATED':
      return {
        filter: {
          type: triggerType,
          projectId: '',
          issueTypeId: '',
        },
        mapping: {},
      };
    case 'STATUS_CHANGED':
    default:
      return {
        filter: {
          type: triggerType,
          projectId: '',
          issueTypeId: '',
          statusId: '',
        },
        mapping: {},
      };
  }
};

export const getFilledTriggers = (triggers: Trigger[]): Trigger[] => {
  return triggers.filter(
    (trigger) =>
      trigger.filter.projectId &&
      trigger.filter.issueTypeId &&
      (isStatusChangeFilter(trigger.filter) ? trigger.filter.statusId : true)
  );
};

export const updateFilterWithForm = (
  triggerFilter: TriggerFilter,
  formData: StatusChangeFormData | IssueCreatedFormData
) => {
  return {
    ...triggerFilter,
    ...(isStatusChangeFormData(formData)
      ? {
          projectId: formData.jiraProject,
          issueTypeId: formData.issueType,
          statusId: formData.status,
        }
      : {
          projectId: formData.jiraProject,
          issueTypeId: formData.issueType,
        }),
  };
};
