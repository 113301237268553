import { Stack } from '@mui/material';
import { Button, Divider, Fileupload, Text, FileList, Loader, TextFieldCaption } from '@verticeone/design-system';
import { FC, useMemo } from 'react';
import { DeepPartial, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { ContractDocumentsForm } from './types';
import useRequestDocuments from '../../../../../hooks/useRequestDocuments';
import { useRequestContext } from '../../../../../request/RequestDetailPage/RequestContext';
import { useCustomTasksDocuments } from '../../../../../hooks/useCustomTaskDocuments';

export type ContractDocumentsFormStepProps = {
  defaultValues?: DeepPartial<ContractDocumentsForm>;
  onSubmit: (values: ContractDocumentsForm) => Promise<void>;
  onPrevious: () => void;
};

export const ContractDocumentsFormStep: FC<ContractDocumentsFormStepProps> = (props) => {
  const { onSubmit, onPrevious } = props;

  const { t } = useTranslation();
  const { requestId } = useRequestContext();

  const requestDocuments = useRequestDocuments(requestId);
  const tasksDocuments = useCustomTasksDocuments(requestId);

  const isLoading = useMemo(() => {
    return requestDocuments.isFetching && tasksDocuments.isFetching;
  }, [requestDocuments, tasksDocuments]);

  const { handleSubmit } = useForm();

  return (
    <Stack component="form" flexGrow={1} onSubmit={handleSubmit(onSubmit)}>
      <Stack p={6} flexGrow={1} gap={2} sx={{ overflow: 'auto' }}>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.LABELS.EXISTING_CONTRACT_FILES')}
          size="XS"
        />
        {isLoading && <Loader />}
        {!isLoading && tasksDocuments.files.length === 0 && requestDocuments.files.length === 0 && (
          <Text variant="caption" color="text3" size="S">
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.HELPERS.NO_FILES_IN_REQUEST')}
          </Text>
        )}

        <Fileupload files={tasksDocuments.files}>
          <Stack gap={4}>
            <FileList
              size="M"
              dynamicFileNameLength
              onFileNameClick={tasksDocuments.downloadFile}
              hiddenColumns={['remove']}
            />
          </Stack>
        </Fileupload>

        <Fileupload multiple files={requestDocuments.files}>
          <Stack gap={4}>
            <FileList
              size="M"
              dynamicFileNameLength
              onFileNameClick={requestDocuments.downloadFile}
              hiddenColumns={['remove']}
            />
          </Stack>
        </Fileupload>
      </Stack>

      <Divider />
      <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
        <Button type="button" variant="plain" size="S" onClick={onPrevious}>
          {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
        </Button>

        <Button type="submit" variant="solid" size="S" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}>
          {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
        </Button>
      </Stack>
    </Stack>
  );
};
