import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { Text, ChipButton, Tooltip } from '@verticeone/design-system';
import type { TaskRow } from '../../../dataSource';
import { ShowOnRowHover } from '../../../../request/RequestsListPage/components/ShowOnRowHover';
import { useTaskStatus } from './useTaskStatus';

type TaskStatusProps = {
  taskRow: TaskRow;
};

export const TaskStatus = ({ taskRow }: TaskStatusProps) => {
  const { palette } = useTheme();
  const { chipText, chipColor, hoverText, hoverTextColor, tooltip } = useTaskStatus(taskRow);

  return (
    <Tooltip title={tooltip} size="S" disableHoverListener={!tooltip} noArrow minified>
      <Stack direction="column" justifyContent="center" spacing={1}>
        <Stack flexDirection="row">
          <ChipButton variant={chipColor === 'error' ? 'solid' : 'ghost'} size="XS" color={chipColor} isActive={false}>
            {chipText}
          </ChipButton>
        </Stack>
        {hoverText && (
          <ShowOnRowHover>
            <Stack justifyContent="center" direction="row">
              <Text variant="caption" color={palette[hoverTextColor || 'neutral'].color1} size="XS">
                {hoverText}
              </Text>
            </Stack>
          </ShowOnRowHover>
        )}
      </Stack>
    </Tooltip>
  );
};
