import React, { createContext, useContext, useState, PropsWithChildren } from 'react';

export type Filter = {
  serviceName: string;
};

type TooltipData = {
  values: Record<string, number>;
  category: string | number;
  total: number;
  averageDurationInHours: number;
};

type AverageRequestTimesContextType = {
  filter: Filter;
  updateFilterValue: (key: keyof Filter, value: string) => void;
  resetFilter: () => void;
  tooltipData: TooltipData | null;
  setTooltipData: (data: TooltipData | null) => void;
};

const AverageRequestTimesContext = createContext<AverageRequestTimesContextType | undefined>(undefined);

type AverageRequestTimesProviderProps = PropsWithChildren & {
  defaultFilter: Filter;
};

export const AverageRequestTimesProvider = ({ children, defaultFilter }: AverageRequestTimesProviderProps) => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const [tooltipData, setTooltipData] = React.useState<TooltipData | null>(null);

  const updateFilterValue = (key: keyof Filter, value: string) => {
    setFilter((prevFilter) => ({ ...prevFilter, [key]: value }));
  };

  const resetFilter = () => {
    setFilter(defaultFilter);
  };

  return (
    <AverageRequestTimesContext.Provider
      value={{ filter, updateFilterValue, tooltipData, resetFilter, setTooltipData }}
    >
      {children}
    </AverageRequestTimesContext.Provider>
  );
};

export const useAverageRequestTimesContext = (): AverageRequestTimesContextType => {
  const context = useContext(AverageRequestTimesContext);
  if (!context) {
    throw new Error('useAverageRequestTimesContext must be used within a AverageRequestTimesProvider');
  }
  return context;
};
