import { BreadcrumbsV2 } from '@verticeone/design-system';
import { NavigationNode, Node, useCloudAnalytics } from '../../../CloudAnalyticsContext';
import { Link as RouterLink, useParams } from 'react-router-dom';

type BasicBreadcrumbItem = {
  label: string;
  onClick?: () => void;
};

const TableBreadcrumbs = () => {
  const { node, removeNode } = useCloudAnalytics();
  const { activeTab } = useParams();
  const activeTabId = activeTab as keyof NavigationNode;

  const findPathToNode = (item: Node, newPath: BasicBreadcrumbItem[]): BasicBreadcrumbItem[] => {
    if (!item.children) {
      return [...newPath, { label: item.label }];
    }

    return findPathToNode(item.children, [
      ...newPath,
      {
        label: item.label,
        onClick: () => (item.children ? removeNode(item.children) : undefined),
      },
    ]);
  };

  const breadcrumbItems = findPathToNode(node[activeTabId], []);

  if (breadcrumbItems.length <= 1) {
    return null;
  }

  return (
    <BreadcrumbsV2<typeof RouterLink>
      size="S"
      items={breadcrumbItems.map((breadcrumb) => ({
        component: RouterLink,
        label: breadcrumb.label,
        onClick: breadcrumb.onClick,
        to: '.',
      }))}
    />
  );
};

export default TableBreadcrumbs;
