import { createPortableRoutes } from '@verticeone/router/utils';

export const preferencesRoutes = createPortableRoutes({
  PREFERENCES: {
    path: '/preferences',
    children: {
      COMPANY: {
        path: 'company',
      },
      DIRECTORY: {
        path: 'directory',
      },
      WORKFLOWS: {
        path: 'workflows',
      },
      CUSTOMIZATION: {
        path: 'customization',
      },
      USER: {
        path: 'user',
        children: {
          SLACK_WIZARD: {
            path: 'slack',
          },
        },
      },
    },
  },
} as const);
