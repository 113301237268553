import { FC, useMemo } from 'react';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../../fields/FormSelectField2';
import FormEntry from '../../../../fields/FormEntry';
import { FieldValues, useWatch } from 'react-hook-form';
import { SimpleOption } from '@verticeone/design-system';
import { SingleValue } from 'react-select';
import { isNil, keyBy } from 'lodash';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../intelligentWorkflows/constants';
import { useGetXTypeDatasourceWithParamsQuery } from '@vertice/slices';
import { useAccountContext } from '../../../../../account/AccountContext';
import { XTypeDataSourceItemDef } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useTranslation } from 'react-i18next';
import { DataSourcedFieldPresenterProps } from './types';

type OptionWithJsxLabel = Omit<SimpleOption, 'label'> & {
  label: JSX.Element | string;
  item: XTypeDataSourceItemDef;
};

export const XTypeSourcedDropdownPresenter: FC<DataSourcedFieldPresenterProps> = (props) => {
  const { field, config } = props;
  const { accountId } = useAccountContext();
  const { t } = useTranslation();

  const { label, required, description, source } = field.metadata;

  const fieldValue = useWatch({ name: field.name });
  const disabled = config.mode === 'readOnly' || config.mode === 'preview';

  const dataSourceQuery = useGetXTypeDatasourceWithParamsQuery({
    resourceUrn: source,
    accountId,
    body: {
      params: {},
    },
  });

  const options: OptionWithJsxLabel[] = useMemo(() => {
    if (dataSourceQuery.isLoading || !dataSourceQuery.data) return [];

    return (dataSourceQuery.data?.items || [])
      .filter((item) => typeof item.id === 'string') // filter out items with complex ids
      .map((item) => ({
        value: item.id as string,
        label: item.i18n?.key ? t(item.i18n.key) : item.title,
        item,
      }));
  }, [dataSourceQuery, t]);

  const optionsMap = useMemo(() => keyBy(options, 'value'), [options]);

  return (
    <FormEntry<FieldValues, FormSelectFieldComponentType<FieldValues, OptionWithJsxLabel, false>>
      name={field.name}
      component={FormSelectField2}
      componentProps={{
        options,
        boxValue: (value: string | null) => (value ? optionsMap[value] : undefined),
        unboxValue: (selected: SingleValue<OptionWithJsxLabel>) => selected?.value,
        placeholder: disabled && isNil(fieldValue) ? '—' : undefined,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      }}
      description={description}
      label={label}
      required={required}
      disabled={disabled}
    />
  );
};
