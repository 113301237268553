import { FieldPath, FieldValues, useWatch } from 'react-hook-form';

import { DynamicFormPresenter } from '../../DynamicFormPresenter';
import { useDynamicFormPresenterConfig } from '../../DynamicFormPresenter/hooks/useDynamicFormPresenterConfig';
import { DynamicFormPresenterSkeleton } from '../../DynamicFormPresenter/DynamicFormPresenter';

export const FormPreview = <T extends FieldValues>({ customFormFieldName }: { customFormFieldName: FieldPath<T> }) => {
  const formConfigQuery = useDynamicFormPresenterConfig({ mode: 'preview' });
  const formSchema = useWatch<T>({ name: customFormFieldName });

  return formConfigQuery.isLoading ? (
    <DynamicFormPresenterSkeleton />
  ) : (
    <DynamicFormPresenter formSchema={{ fields: formSchema }} config={formConfigQuery.data} />
  );
};
