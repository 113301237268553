import useDashboardWidgetData from '../../useDashboardWidgetData';
import { getTableData } from '../../utils';
import { TableData, Task, TasksData } from './types';
import { LIMIT_ITEMS } from './constants';

export const useUnassignedTasksData = (): TasksData => {
  const { data, error, isFetching } = useDashboardWidgetData('TasksWithoutAssignee');
  const dashboardData = data?.data as TableData;

  const items = dashboardData
    ? (getTableData(dashboardData, {
        taskName: 'title',
        parentRequestName: 'description',
        daysSinceNotAssigned: 'days',
        overdueDays: 'overdueDays',
        taskId: 'taskId',
        vendorId: 'vendorId',
      }) as Task[])
    : [];

  return {
    data: items
      .sort((a, b) => (b.overdueDays - a.overdueDays === 0 ? b.days - a.days : b.overdueDays - a.overdueDays))
      .slice(0, LIMIT_ITEMS),
    error,
    isFetching,
    isEmpty: items.length === 0,
  };
};
