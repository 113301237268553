import { SvgIconComponent } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { IconWrapper, Text } from '@verticeone/design-system';
import { ChangeEventHandler, FC, ReactNode, useMemo } from 'react';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { match } from 'ts-pattern';

const HiddenInput = styled('input')(() => ({
  display: 'none',
}));

type RadioCardProps = {
  $checked: boolean;
  $disabled?: boolean;
};

const RadioCard = styled('div')<RadioCardProps>(({ theme, $checked, $disabled }) => {
  const { palette, spacing } = theme;

  let borderColor = match({ $checked, $disabled })
    .with({ $checked: true }, () => palette.neutral.color1)
    .with({ $disabled: true }, () => palette.inactive.color3)
    .otherwise(() => palette.text.color4);

  return {
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${borderColor}`,
    color: $checked ? palette.neutral.color1 : palette.text.color4,
    backgroundColor: $disabled && !$checked ? palette.inactive.color4 : 'none',
    cursor: $checked || $disabled ? 'default' : 'pointer',
    padding: spacing(4),
    borderRadius: spacing(3),
    gap: spacing(1),
    transition: '200ms ease-in-out',
  };
});

type FormRadioCardFieldProps<T extends FieldValues> = {
  control?: Control<T>;
  children?: ReactNode | ReactNode[];
  name: FieldPath<T>;
  disabled?: boolean;
  value: string;
  icon?: SvgIconComponent;
};

export const FormRadioCardField = <T extends FieldValues>(props: FormRadioCardFieldProps<T>) => {
  const { control, name, value, children, disabled } = props;
  const { field } = useController({ control, name });

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (disabled) return;
    field.onChange(e.target.value);
  };

  const isChecked = useMemo(() => {
    return value === field.value;
  }, [field, value]);

  return (
    <Box component="label" flex="1 1 0" height="100%">
      <HiddenInput type="radio" value={value} checked={isChecked} name={name} onChange={handleChange} />
      <RadioCard $checked={isChecked} $disabled={disabled}>
        {children}
      </RadioCard>
    </Box>
  );
};

type RadioCardTitleProps = {
  children?: ReactNode | ReactNode[];
};

export const RadioCardTitle: FC<RadioCardTitleProps> = (props) => {
  const { children } = props;

  return (
    <Text variant="button-bold" size="M" color="inherit">
      {children}
    </Text>
  );
};

type RadioCardDescriptionProps = {
  children?: ReactNode | ReactNode[];
};

export const RadioCardDescription: FC<RadioCardDescriptionProps> = (props) => {
  const { children } = props;

  return (
    <Text variant="caption" textAlign="center" size="XS" color="inherit" sx={{ opacity: 0.6 }}>
      {children}
    </Text>
  );
};

type RadioCardIconProps = {
  icon: SvgIconComponent;
};

export const RadioCardIcon: FC<RadioCardIconProps> = (props) => {
  const { icon } = props;

  return <IconWrapper size="M" sx={{ mb: 1 }} icon={icon} />;
};
