import type { FC } from 'react';
import type { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

import { isSaaSApprovalTask } from '../../TaskPredefinedForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { GenericRequestIcon } from '../../../../components/icons/Icons';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import VendorLogo from '../../../../../../components/VendorLogo';
import { DetailRow } from './DetailRow';

type RequestDetailRowProps = { taskOverview?: TaskOverview };

export const RequestDetailRow: FC<RequestDetailRowProps> = ({ taskOverview }) => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();
  const vendor = taskOverview?.task.input.vendor;

  if (isSaaSApprovalTask(taskOverview)) {
    const contractLink = taskOverview?.task.input?.contractId
      ? generatePathForRoute(routes.CONTRACTS.DETAIL, {
          contractId: taskOverview?.task.input?.contractId,
        })
      : undefined;

    return (
      <DetailRow
        label={taskOverview?.workflow?.instanceName || '–'}
        icon={GenericRequestIcon}
        linkTo={contractLink}
        testId="requestName"
      />
    );
  }

  const requestRef = taskOverview?.request?.ref;
  const requestLink = requestRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
        requestId: parseRequestRef(requestRef).requestId,
      })
    : undefined;

  const VendorIcon = () => <VendorLogo vendorId={vendor.id} logoOnly size={16} />;

  const RowIcon = vendor ? VendorIcon : GenericRequestIcon;

  return (
    <DetailRow label={taskOverview?.request?.name || '–'} icon={RowIcon} linkTo={requestLink} testId="requestName" />
  );
};
