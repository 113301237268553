import { nonEmptyString } from '@verticeone/utils/validation';
import * as z from 'zod';

export const variableMapperRowFormSchema = z.object({
  from: nonEmptyString,
  to: nonEmptyString,
  mappingType: nonEmptyString,
});

export type VariableMapperRowFormBase = z.infer<typeof variableMapperRowFormSchema>;
export type VariableMapperRowFormData = VariableMapperRowFormBase & { id: string };

export const variableMapperMapperFields = {
  requiredVariables: z.array(variableMapperRowFormSchema),
  variables: z.array(variableMapperRowFormSchema),
};
