import { useTranslation } from 'react-i18next';
import { VALID_RESULT_TYPE_VALUES } from './schema';

export type EndResultTypeOption = {
  readonly value: string;
  readonly label: string;
  readonly disabled?: boolean;
};

export const useEndResultTypeOptions = (currentValue?: string): EndResultTypeOption[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_END.RESULT_TYPE_OPTIONS',
  });

  const result: EndResultTypeOption[] = [
    {
      value: 'COMPLETED',
      label: t('COMPLETED'),
    },
    {
      value: 'DECLINED',
      label: t('DECLINED'),
    },
    {
      value: 'CANCELLED',
      label: t('CANCELLED'),
    },
  ];

  if (currentValue && !VALID_RESULT_TYPE_VALUES.includes(currentValue)) {
    result.push({
      value: currentValue,
      label: currentValue,
      disabled: true,
    });
  }

  return result;
};
