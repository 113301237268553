import { contractCostAPI as api } from '../../api/contractCostAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiVersion: build.query<GetApiVersionApiResponse, GetApiVersionApiArg>({
      query: () => ({ url: `/version` }),
    }),
    runCostAllocatonProjection: build.mutation<RunCostAllocatonProjectionApiResponse, RunCostAllocatonProjectionApiArg>(
      {
        query: (queryArg) => ({ url: `/project/costs`, method: 'POST', body: queryArg.runSingleCostModelRequest }),
      }
    ),
    runSavingsProjection: build.mutation<RunSavingsProjectionApiResponse, RunSavingsProjectionApiArg>({
      query: (queryArg) => ({ url: `/project/savings`, method: 'POST', body: queryArg.runSingleCostModelRequest }),
    }),
    evaluateCostModel: build.mutation<EvaluateCostModelApiResponse, EvaluateCostModelApiArg>({
      query: (queryArg) => ({ url: `/evaluateCostModel`, method: 'POST', body: queryArg.costModelRequest }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contractCostApiCodegen };
export type GetApiVersionApiResponse = unknown;
export type GetApiVersionApiArg = void;
export type RunCostAllocatonProjectionApiResponse =
  /** status 200 Response for RunCostAllocationProjection */ CostAllocationProjection;
export type RunCostAllocatonProjectionApiArg = {
  runSingleCostModelRequest: {
    runParameters: RunParameters;
    costModelDefinition: CostModelDefinition;
  };
};
export type RunSavingsProjectionApiResponse = /** status 200 Response for RunSavingsProjection */ CostComparisonResult;
export type RunSavingsProjectionApiArg = {
  runSingleCostModelRequest: {
    runParameters?: RunParameters;
    baselineModelDefinition?: CostModelDefinition;
    negotiatedModelDefinition?: CostModelDefinition;
  };
};
export type EvaluateCostModelApiResponse = /** status 200 Response for ComputeCostModelCosts */ {
  /** Duration in months for which the cost model is evaluated */
  length?: number;
  annualCost?: number;
  totalCost?: number;
};
export type EvaluateCostModelApiArg = {
  costModelRequest: {
    startDate?: IsoDate;
    endDate?: IsoDate;
    rollingFrequency?: RollingFrequency;
    costModel: CostModelDefinition;
  };
};
export type IsoDate = string;
export type Granularity = 'month';
export type CostAllocation = {
  start_date: IsoDate;
  end_date: IsoDate;
  /** Amount of cost allocated (formatted as string because of precision) */
  amount: string | null;
};
export type CostAllocationProjection = {
  start_date: IsoDate;
  end_date: IsoDate;
  granularity: Granularity;
  cost_allocations: CostAllocation[];
};
export type RunParameters = {
  start_date: IsoDate;
  end_date: IsoDate;
  granularity: Granularity;
  /** Fallback cost value [default=0] */
  fallback_cost_value?: number | null;
};
export type ModelClass = 'LegacyLinearApproximation' | 'AnnualCostApproximation' | 'AdvancedLinearApproximation';
export type CostAllocationSpan = {
  name: string;
  start_date: IsoDate;
  end_date?: IsoDate;
  /** Amount of cost allocated (formatted as string because of precision) */
  amount: string;
  rolling_frequency?: 'monthly' | 'annually' | 'quarterly' | 'bi-annually';
};
export type LegacyLinearApproximationModel = {
  start_date: IsoDate;
  end_date: IsoDate;
  cost_allocation_spans: CostAllocationSpan[];
};
export type AnnualCostApproximationModel = {
  /** Contract length in months */
  contract_length?: string;
  /** Annual cost (formatted as string because of precision) */
  annual_cost: string;
};
export type RollingFrequency = 'NO' | 'ONE_OFF' | 'MONTHLY' | 'QUARTERLY' | 'BIANNUALLY' | 'ANNUALLY';
export type AdvancedCostApproximationModel = {
  startDate?: IsoDate;
  endDate?: IsoDate;
  rollingFrequency?: RollingFrequency;
  items: {
    [key: string]: any;
  }[];
};
export type CostModelDefinition = {
  model_class: ModelClass;
  /** Model class version */
  model_class_version: string;
  model: LegacyLinearApproximationModel | AnnualCostApproximationModel | AdvancedCostApproximationModel;
};
export type CostComparison = {
  start_date: IsoDate;
  end_date: IsoDate;
  /** Baseline cost (formatted as string because of precision) */
  baseline_amount: string | null;
  /** Negotiated cost (formatted as string because of precision) */
  negotiated_amount: string | null;
  /** Difference (baseline - negotiated) (formatted as string because of precision) */
  difference_amount: string | null;
};
export type CostComparisonResult = {
  start_date: IsoDate;
  end_date: IsoDate;
  granularity: Granularity;
  comparisons: CostComparison[];
};
export const {
  useGetApiVersionQuery,
  useLazyGetApiVersionQuery,
  useRunCostAllocatonProjectionMutation,
  useRunSavingsProjectionMutation,
  useEvaluateCostModelMutation,
} = injectedRtkApi;
