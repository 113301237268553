import { RepeatingFieldMetadata, RepeatingFieldType, repeatingFieldMetadataSchema } from './types';
import { DialogContent } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTypedFormEntry } from '../../../fields/FormEntry';
import FormTextField from '../../../fields/FormTextField';
import FormTextArea from '../../../fields/FormTextArea';
import { FieldEditDialog } from '../../DynamicFormBuilder';
import { fieldDef } from './index';
import { BuilderDialogProps, BuilderPluginValues, BuilderPlugins, FormWithPlugins } from '../types';
import { addPluginsToDefaultData, addPluginsToSchema } from '../utils';
import FormNumberField from '../../../fields/FormNumberField';

type RepeatingFieldBuilderDialogProps<T extends BuilderPlugins> = BuilderDialogProps<RepeatingFieldType, T>;

const DEFAULT_VALUES: RepeatingFieldMetadata = {
  label: '',
  requiredItems: 0,
  limit: 2,
  fields: [],
  description: undefined,
};

const FieldMetadataEntry = createTypedFormEntry<RepeatingFieldMetadata>();

export const RepeatingFieldBuilderDialog = <T extends BuilderPlugins>(props: RepeatingFieldBuilderDialogProps<T>) => {
  const { onClose, onSubmit, defaultValues, plugins } = props;

  const { t } = useTranslation();

  const form = useForm<FormWithPlugins<RepeatingFieldMetadata>>({
    defaultValues: addPluginsToDefaultData(defaultValues ?? DEFAULT_VALUES, plugins ?? []),
    resolver: zodResolver(addPluginsToSchema(repeatingFieldMetadataSchema, plugins) ?? []),
  });

  const submitHandler: SubmitHandler<FormWithPlugins<RepeatingFieldMetadata>> = (values) => {
    if (!onSubmit) return;

    const { plugins: outputs, ...metadata } = values;
    onSubmit(metadata, outputs as BuilderPluginValues<T>);
  };

  return (
    <FieldEditDialog form={form} schema={repeatingFieldMetadataSchema} onSubmit={form.handleSubmit(submitHandler)}>
      <FieldEditDialog.Header mode={defaultValues ? 'edit' : 'create'} titleSuffix={t(fieldDef.typeNameI18nKey)} />
      <DialogContent>
        <FieldMetadataEntry
          name="label"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_LABEL')}
          component={FormTextField}
          componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR, inputProps: { maxLength: 40 } }}
        />
        <FieldMetadataEntry
          name="description"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.HELPTEXT')}
          component={FormTextArea}
          componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR, size: 'M', variant: 'outline' }}
        />
        <FieldMetadataEntry
          name="requiredItems"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.REQUIRED_ITEMS')}
          component={FormNumberField}
          componentProps={{
            minValue: 0,
          }}
        />
        <FieldMetadataEntry
          name="limit"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LIMIT')}
          component={FormNumberField}
          componentProps={{
            minValue: 1,
            maxValue: 100,
          }}
        />
        {plugins?.map(({ Component }, index) => (
          <Component key={index} name={`plugins.${index}`} fieldType="REPEATING" />
        ))}
      </DialogContent>
      <FieldEditDialog.Footer isFormValid={form.formState.isValid} onClose={onClose} />
    </FieldEditDialog>
  );
};
