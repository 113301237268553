import { NoData } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

export const NoJiraVariables = () => {
  const { t } = useTranslation();

  return (
    <NoData
      header={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_FIELDS_HEADER')}
      content={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_FIELDS_CONTENT')}
    />
  );
};
