import React from 'react';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import StatCard from './StatCard/StatCard';
import { CurrencyExchangeOutlined } from '@mui/icons-material';
import useProcuredStatsData from '../dataSource/useProcuredStatsData';

const InFlightSpendStatCard = () => {
  const formatCurrency = useFormatCurrency();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const { data, isFetching } = useProcuredStatsData();

  return (
    <StatCard
      isLoading={isFetching}
      testId="in-flight-spend-stat-card"
      title={t('IN_FLIGHT_SPEND.TITLE')}
      value={formatCurrency(data.inFlightCost.amount, {
        maximumFractionDigits: 0,
        currency: data.inFlightCost.currency,
      })}
      variant="info"
      icon={CurrencyExchangeOutlined}
    />
  );
};

export default InFlightSpendStatCard;
