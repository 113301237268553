import { Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { useFormatDate } from '@verticeone/utils/formatting';
import backgroundGray from './assets/background-gray.jpg';
import { EmptyDateHeader } from './EmptyDateHeader';
import { useTranslation } from 'react-i18next';
import { RenewalDeadline } from '@vertice/core/src/modules/saas/contract/types';

type PlannedExpirationHeaderProps = {
  deadline: RenewalDeadline | undefined;
};

export const PlannedExpirationHeader = ({ deadline }: PlannedExpirationHeaderProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const formatDate = useFormatDate();
  const deadlineDate = deadline?.deadlineDate; // ~ contract.parts.contractual?.lifecycle?.renewalDate

  if (!deadlineDate) {
    return <EmptyDateHeader />;
  }

  return (
    <HighlightedCardHeader
      testId="card-header-gray"
      backgroundColor={palette.neutral.color1}
      backgroundImage={`url(${backgroundGray})`}
      noDivider
    >
      <Stack gap={2} alignItems="center" width="100%">
        <Text testId="card-header-title" variant="caption" size="XS" color="text2">
          {t('ENTITIES.CONTRACT.LABELS.EXPIRY_DATE')}
        </Text>
        <Text testId="card-header-date" variant="heading" size="M" color="text1">
          {formatDate(deadlineDate)}
        </Text>
      </Stack>
    </HighlightedCardHeader>
  );
};
