import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import { IntegrationSetupDialog } from '../../../../components/IntegrationSetupDialog';
import { useDeactivateIntegration } from '../../../../hooks/useDeactivateIntegration';
import { JIRA_PROVIDER_ID } from '../constants';
import { JiraSetupStep, StepContentProps } from '../types';

type DisconnectButtonProps = StepContentProps;

export const DisconnectButton: FC<DisconnectButtonProps> = ({ activeStep }) => {
  const { t } = useTranslation();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);

  const {
    deactivateIntegration,
    isDeactivateAccountIntegrationInProgress,
    isRevokeAccountIntegrationInProgress,
    isRemoveWebhookInProgress,
  } = useDeactivateIntegration({
    providerId: JIRA_PROVIDER_ID,
    closeDialog: () => setIsDisconnectDialogOpened(false),
    shouldRedirect: true,
  });

  const isSuccessStep = activeStep === JiraSetupStep.SUCCESS;

  if (!isSuccessStep) return null;

  return (
    <>
      <Button variant="solid" size="M" color="neutral" onClick={() => setIsDisconnectDialogOpened(true)}>
        {t('INTEGRATIONS.JIRA.DISCONNECT_BUTTON')}
      </Button>
      <IntegrationSetupDialog
        header={t('INTEGRATIONS.JIRA.DISCONNECT_DIALOG.HEADER')}
        content={t('INTEGRATIONS.JIRA.DISCONNECT_DIALOG.CONTENT')}
        buttonLabel={t('INTEGRATIONS.JIRA.DISCONNECT_DIALOG.BUTTON')}
        isLoading={
          isDeactivateAccountIntegrationInProgress || isRevokeAccountIntegrationInProgress || isRemoveWebhookInProgress
        }
        isOpened={isDisconnectDialogOpened}
        onSubmit={deactivateIntegration}
        onClose={() => setIsDisconnectDialogOpened(false)}
      />
    </>
  );
};
