import { FieldMapping, TaskDefinition } from '../../../../../definitionsTypes';
import { isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { ISSUE_TYPE_ID_NAME, PROJECT_ID_NAME } from '../JiraCommon/utils';

/**
 * Get Input mapping variables  used in  Workflow to Create Jira mapping
 * Jira ids are internally prefixed, we need to strip that prefix in preparation for used in variable picker
 * @param task
 */
export const getJiraCreateInputMapping = (task: TaskDefinition) => {
  let projectId: string | undefined;
  let issueTypeId: string | undefined;
  let includeAttachments: boolean | undefined;
  let variables: FieldMapping[] = [];

  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  if (ioMappingConfiguration && inputFields) {
    inputFields.forEach((field) => {
      // required field we need to know
      if (field.name === PROJECT_ID_NAME) {
        projectId = field.value;
        // required field we need to know
      } else if (field.name === ISSUE_TYPE_ID_NAME) {
        issueTypeId = field.value;
      } else if (field.name === 'includeAttachments') {
        includeAttachments = field.value === 'true';
      } else {
        const name = field.name.split('<')[0];
        variables.push({ ...field, name });
      }
    });
  }
  return {
    projectId,
    issueTypeId,
    includeAttachments,
    variables,
  };
};
