import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, styled } from '@mui/material';
import { keyBy } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { z } from 'zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption, Text } from '@verticeone/design-system';
import { useJiraTriggerForm } from '../../hooks/useJiraTriggerForm';
import { TriggerFilter } from './types';
import { isStatusChangeFilter, updateFilterWithForm } from './utils';

const SELECT_FIELD_MAX_WIDTH = '160px';

const TriggerConditionWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  '& .MuiFormControl-root .MuiStack-root:first-child': {
    gap: 0,
  },
}));

const statusChangeFormDataSchema = z.object({
  jiraProject: z.string(),
  issueType: z.string(),
  status: z.string(),
});

type StatusChangeFormProps = {
  triggerFilter: TriggerFilter;
  onChange: (filter: TriggerFilter) => void;
  onIssueTypeChange: (issueTypeId: string, projectId: string) => void;
  disabled?: boolean;
};

export type StatusChangeFormData = z.infer<typeof statusChangeFormDataSchema>;
const StatusChangeFormEntry = createTypedFormEntry<StatusChangeFormData>();

export const StatusChangeForm: FC<StatusChangeFormProps> = ({
  triggerFilter,
  onChange,
  onIssueTypeChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { projectId, issueTypeId } = triggerFilter;
  const statusId = isStatusChangeFilter(triggerFilter) ? triggerFilter.statusId : undefined;

  const formMethods = useForm<StatusChangeFormData>({
    defaultValues: {
      jiraProject: projectId ?? '',
      issueType: issueTypeId ?? '',
      status: statusId ?? '',
    },
    mode: 'all',
    resolver: zodResolver(statusChangeFormDataSchema),
  });

  const selectedProjectId = formMethods.watch('jiraProject');
  const selectedIssueId = formMethods.watch('issueType');
  const selectedStatusId = formMethods.watch('status');

  const prevProjectId = useRef(projectId);
  const prevIssueId = useRef(issueTypeId);
  const prevStatusId = useRef(statusId);

  useEffect(() => {
    if (selectedProjectId !== prevProjectId.current) {
      formMethods.setValue('issueType', '');
      prevProjectId.current = selectedProjectId;
    }

    if (selectedIssueId !== prevIssueId.current) {
      formMethods.setValue('status', '');
      prevIssueId.current = selectedIssueId;
      onIssueTypeChange(selectedIssueId, selectedProjectId);
    }

    if (selectedStatusId !== prevStatusId.current) {
      if (selectedStatusId !== '') {
        onChange(updateFilterWithForm(triggerFilter, formMethods.getValues()));
      }
      prevStatusId.current = selectedStatusId;
    }
  }, [formMethods, selectedIssueId, selectedProjectId, selectedStatusId, triggerFilter, onChange, onIssueTypeChange]);

  const {
    projects,
    issueTypes,
    statuses,
    isLoadingJiraIntegration,
    isFetchingJiraIssueTypes,
    isFetchingJiraFieldOptions,
  } = useJiraTriggerForm(
    triggerFilter.type,
    selectedProjectId,
    selectedProjectId !== prevProjectId.current ? '' : selectedIssueId
  );

  const projectsOptions = projects.map<SimpleOption>((project) => ({
    value: project.id,
    label: project.name,
  }));
  const projectsMap = keyBy(projectsOptions, 'value');
  const issueTypesOptions = issueTypes.map<SimpleOption>((issueType) => ({
    value: issueType.id,
    label: issueType.name,
  }));
  const issuesTypesMap = keyBy(issueTypesOptions, 'value');
  const statusesOptions = statuses.map<SimpleOption>((status) => ({
    value: status.id,
    label: status.label ?? '',
  }));
  const statusesTypesMap = keyBy(statusesOptions, 'value');

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack direction="row" gap={1} flexWrap="wrap">
          <TriggerConditionWrapper>
            <Text variant="caption" size="XS" color="text2">
              {t('INTEGRATIONS.JIRA.STATUS_CHANGE_FORM.CONDITION_PART_1')}
            </Text>
            <StatusChangeFormEntry
              component={FormSelectField2}
              componentProps={{
                options: projectsOptions,
                boxValue: (value: string) => projectsMap[value],
                unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
                isClearable: false,
                isLoading: isLoadingJiraIntegration,
                size: 'XS',
                disabled: disabled,
                sx: { maxWidth: SELECT_FIELD_MAX_WIDTH },
              }}
              name="jiraProject"
            />
          </TriggerConditionWrapper>
          <TriggerConditionWrapper>
            <Text variant="caption" size="XS" color="text2">
              {t('INTEGRATIONS.JIRA.STATUS_CHANGE_FORM.CONDITION_PART_2')}
            </Text>
            <StatusChangeFormEntry
              component={FormSelectField2}
              componentProps={{
                options: issueTypesOptions,
                boxValue: (value: string) => issuesTypesMap[value] ?? null,
                unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
                isClearable: false,
                isLoading: isFetchingJiraIssueTypes && selectedIssueId === '',
                size: 'XS',
                disabled: disabled || selectedProjectId === '',
                sx: { maxWidth: SELECT_FIELD_MAX_WIDTH },
              }}
              name="issueType"
            />
          </TriggerConditionWrapper>
          <TriggerConditionWrapper>
            <Text variant="caption" size="XS" color="text2">
              {t('INTEGRATIONS.JIRA.STATUS_CHANGE_FORM.CONDITION_PART_3')}
            </Text>
            <StatusChangeFormEntry
              component={FormSelectField2}
              componentProps={{
                options: statusesOptions,
                boxValue: (value: string) => statusesTypesMap[value] ?? null,
                unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
                isClearable: false,
                isLoading: isFetchingJiraFieldOptions && selectedStatusId === '',
                size: 'XS',
                disabled: disabled || selectedIssueId === '',
                sx: { maxWidth: SELECT_FIELD_MAX_WIDTH },
              }}
              name="status"
            />
          </TriggerConditionWrapper>
        </Stack>
      </form>
    </FormProvider>
  );
};
