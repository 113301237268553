import { PREDEFINED_THUMBNAILS_MAP } from './constants';
import { Thumbnail } from './types';

export const getThumbnailUrl = (thumbnail: Thumbnail): string => {
  if (thumbnail.type === 'CUSTOM') {
    return thumbnail.image ?? PREDEFINED_THUMBNAILS_MAP['generic'];
  }

  return PREDEFINED_THUMBNAILS_MAP[thumbnail.image];
};
