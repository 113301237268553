import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import FullPageMessage from '@vertice/core/src/components/FullPageMessage';
import useAssumeRoleAuth from '../../modules/auth/assumeRole/useAssumeRoleAuth';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useAuthentication } from '@verticeone/auth/src';
import { waitForNextAnimationFrame } from '@verticeone/utils/async';

const AssumeRole = () => {
  const [searchParams] = useSearchParams();
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const maybeToken = searchParams.get('token');
  const routes = useRoutes();

  const { signOut } = useAuthentication();
  const { signIn, error } = useAssumeRoleAuth();

  const prevTokenRef = useRef<string | undefined>(undefined);

  useEffect(() => {
    void (async () => {
      if (!maybeToken || prevTokenRef.current === maybeToken) return;
      prevTokenRef.current = maybeToken;

      await signOut();

      if (await signIn(maybeToken)) {
        // We need to wait for the sign-in to propagate to the auth context so that we don't get redirected to the login page.
        await waitForNextAnimationFrame();
        await waitForNextAnimationFrame();
        setReadyToRedirect(true);
      }
    })();
  }, [maybeToken, routes, signIn, signOut]);

  if (readyToRedirect) {
    return <Navigate to={routes.DASHBOARD.ABSOLUTE_PATH} replace />;
  }

  if (error) {
    return (
      <FullPageMessage
        verticalFill="window"
        title={error?.status ?? 401}
        subtitle="Unauthorized."
        message={`Your token for assuming customer's role is missing or is expired. Try initiating the "Assume role" process once again from IAT.`}
      />
    );
  }

  return <FullPageMessage verticalFill="window" subtitle="Assuming role..." />;
};

export default AssumeRole;
