import { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledThumbnail, ThumbnailProps } from '../ThumbnailCommon';

export const Thumbnail: FC<ThumbnailProps> = ({ thumbnail }) => {
  return (
    <Stack height={213} position="relative">
      <StyledThumbnail $thumbnail={thumbnail}></StyledThumbnail>
    </Stack>
  );
};
