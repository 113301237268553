import { useCallback, useEffect, useState } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system';
import { autoDownload } from '@verticeone/utils/file';
import {
  FileMetaData,
  useDeleteFileMutation,
  useLazyGetPreSignedLinkQuery,
  useListFilesQuery,
  useUploadFileMutation,
} from '@vertice/slices';
import { useRequestDetails } from './useRequestDetails';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { isTaskDocumentFullPath } from '../utils/isTaskDocumentFullPath';

/**
 * Custom hook for managing request-related documents.
 *
 * @param requestId - The unique identifier of the request for which documents are managed.
 * @returns An object containing:
 *   - `files`: A list of accepted files associated with the request.
 *   - `isFetching`: A boolean indicating whether files are being fetched.
 *   - `uploadFiles`: A function to upload new files.
 *   - `deleteFile`: A function to delete specified files.
 *   - `downloadFile`: A function to download a specified file.
 *   - `allowDeletion`: A boolean indicating whether file deletion is permitted based on request status.
 */
const useRequestDocuments = (requestId: string) => {
  const [files, setFiles] = useState<FileAccepted[]>([]);
  const { accountId } = useAccountContext();
  const { request } = useRequestDetails(requestId, accountId);

  const [uploadRequestFile] = useUploadFileMutation();
  const [deleteRequestFile] = useDeleteFileMutation();
  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();

  const { data: requestAssets, isFetching } = useListFilesQuery(
    { accountId: accountId, 'path+': `requests/${requestId}/files` },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!requestAssets?.files) return;

    const acceptedFiles = requestAssets.files
      .filter(({ type }) => type === 'FILE')
      .filter(({ fullPath }) => !isTaskDocumentFullPath(fullPath))
      .sort((f1, f2) => f1.fileName.localeCompare(f2.fileName))
      .map(fromMetadataToFile);

    setFiles(acceptedFiles);
  }, [requestAssets, setFiles]);

  const uploadFiles = useCallback(
    async (newFiles: FileAccepted[]) => {
      const normalizedNewFiles = newFiles.map(normalizeAcceptedFile);
      setFiles((oldFiles) => [...oldFiles, ...normalizedNewFiles].sort(compareAcceptedFilesByName));

      const fileUploadRequests = newFiles.map((newFile) => {
        const filePath = getFilePath(requestId, newFile.file.name);
        return uploadRequestFile({ accountId, 'path+': filePath, body: newFile.file }).unwrap();
      });

      await Promise.all(fileUploadRequests);
    },
    [accountId, requestId, uploadRequestFile]
  );

  const deleteFile = useCallback(
    async (removedFiles: FileAccepted[]) => {
      const removedNames = removedFiles.map((f) => f.file.name);
      setFiles((oldFiles) => oldFiles.filter(({ file: oldFile }) => !removedNames.includes(oldFile.name)));

      const fileDeleteRequests = removedFiles.map((removedFile) => {
        const filePath = getFilePath(requestId, removedFile.file.name);
        return deleteRequestFile({ accountId, 'path+': filePath }).unwrap();
      });

      await Promise.all(fileDeleteRequests);
    },
    [setFiles, requestId, accountId, deleteRequestFile]
  );

  const downloadFile = async (file: FileAccepted | FileRejected) => {
    const fileName = file.file.name;
    const filePath = getFilePath(requestId, fileName);

    const presidnedLink = await getPreSignedLink({
      accountId,
      'path+': filePath,
      responseContentDisposition: 'attachment',
    }).unwrap();

    if (!presidnedLink.preSignedLink) return;
    autoDownload(presidnedLink.preSignedLink, fileName);
  };

  return {
    files: files,
    isFetching: isFetching && !files,
    uploadFiles,
    deleteFile,
    downloadFile,
    allowDeletion: !(request?.status === 'COMPLETED'),
  };
};

const getFilePath = (requestId: string, fileName: string) => {
  return `requests/${requestId}/files/${fileName}`;
};

const fromMetadataToFile = ({ fileName, fileSize, lastModified }: FileMetaData): FileAccepted => ({
  file: {
    name: fileName,
    size: fileSize,
    lastModified: lastModified ? new Date(lastModified).getTime() : 0,
  } as unknown as File,
});

const normalizeAcceptedFile = ({ file }: FileAccepted): FileAccepted => {
  return { file: new File([file], file.name, { lastModified: new Date().getTime() }) };
};

const compareAcceptedFilesByName = (f1: FileAccepted, f2: FileAccepted) => {
  return f1.file.name.localeCompare(f2.file.name);
};

export default useRequestDocuments;
