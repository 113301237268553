import { FC, useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  Controls,
  EdgeTypes,
  NodeTypes,
  ReactFlow,
} from '@xyflow/react';

import {
  EndNodeComponent,
  GatewayNodeComponent,
  LabelNodeComponent,
  StartNodeComponent,
  TaskNodeComponent,
} from './NodeComponents';
import { Edge } from './EdgeComponents';

import '@xyflow/react/dist/style.css';
import { modelToRendererGraph } from './modelToRendererGraph';
import { useFitActiveTasksIntoView } from './useFitActiveTasksIntoView';
import { useWorkflowRendererContext } from './WorkflowRendererContext';
import { useDragAndDropExtensionContext } from '../WorkflowEditor/DragAndDropExtension/DragAndDropExtensionContext';
import { RendererControls } from './components/RendererControls/RendererControls';
import { MAX_RENDERER_ZOOM, MIN_RENDERER_ZOOM } from './constants';

export type WorkflowRendererProps = {
  // This prop is mainly for visual tests and is used to disable the animation when fitting active tasks into view
  disableFitActiveTask?: boolean;
};

const edgeTypes: EdgeTypes = {
  edge: Edge,
};

const nodeTypes: NodeTypes = {
  start: StartNodeComponent,
  end: EndNodeComponent,
  task: TaskNodeComponent,
  gateway: GatewayNodeComponent,
  label: LabelNodeComponent,
};

const proOptions = { hideAttribution: true };

export const WorkflowRenderer: FC<WorkflowRendererProps> = ({ disableFitActiveTask = false }) => {
  const { palette } = useTheme();
  const counter = useRef<number>(0);
  const {
    isEditor,
    isDragAndDropEnabled,
    drawerTaskId,
    model,
    controlsPosition = 'bottom-left',
    fitViewOptions,
  } = useWorkflowRendererContext();
  const { onNodeDragStop, onConnect } = useDragAndDropExtensionContext();
  const isDragAndDropEnabledInEditor = isEditor && isDragAndDropEnabled;

  const { nodes, edges } = useMemo(() => {
    // re-rendering ReactFlow multiple times with the same nodes and edges makes the nodes and edges invisible.
    // couldn't find any other solution than adding increment to all node and edge IDs, see RED-1209
    counter.current += 1;
    return modelToRendererGraph({
      model,
      counter: counter.current,
      useDefinitionPosition: isDragAndDropEnabledInEditor,
      options: {
        selection: {
          gateway: isEditor,
          label: isEditor,
          edge: isEditor,
          end: isEditor,
        },
      },
    });
  }, [model, isDragAndDropEnabledInEditor, isEditor]);

  const { setReactFlowInstance } = useFitActiveTasksIntoView({
    nodes,
    skip: !!drawerTaskId || disableFitActiveTask,
  });

  return (
    <ReactFlow
      onInit={(instance) => setReactFlowInstance(instance)}
      nodes={nodes}
      edges={edges}
      defaultNodes={isEditor ? nodes : undefined}
      defaultEdges={isEditor ? edges : undefined}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={isDragAndDropEnabledInEditor}
      nodesConnectable={isDragAndDropEnabledInEditor}
      onConnect={onConnect}
      connectionLineStyle={
        isDragAndDropEnabledInEditor
          ? {
              stroke: palette.secondary.color2,
              strokeWidth: 1,
            }
          : undefined
      }
      connectionLineType={isDragAndDropEnabledInEditor ? ConnectionLineType.SmoothStep : undefined}
      maxZoom={MAX_RENDERER_ZOOM}
      minZoom={MIN_RENDERER_ZOOM}
      zoomOnPinch={true}
      zoomOnScroll={false}
      panOnScroll={isEditor}
      preventScrolling={false}
      onNodeDragStop={(_, node) => onNodeDragStop?.(node)}
      // multiSelectionKeyCode - invalid value disables multi-selection completely
      // it's mentioned in github discussions, but not in the docs
      multiSelectionKeyCode={'DISABLED'}
      fitView
      snapToGrid
      snapGrid={[2, 2]}
      attributionPosition="bottom-left"
      selectNodesOnDrag={false}
      fitViewOptions={fitViewOptions}
      defaultEdgeOptions={{
        style: {
          strokeWidth: 1.5,
        },
      }}
      proOptions={proOptions}
    >
      <Background
        color={palette.core.color4}
        variant={BackgroundVariant.Lines}
        style={{
          background: palette.core.color1,
          strokeDasharray: '7 7',
          opacity: 0.5,
        }}
        gap={170}
        offset={2.5}
      />
      <Controls
        orientation="horizontal"
        position={controlsPosition}
        showInteractive={false}
        fitViewOptions={fitViewOptions}
        showZoom={false}
        showFitView={false}
        style={{
          boxShadow: 'none',
        }}
      >
        <RendererControls fitViewOptions={fitViewOptions} />
      </Controls>
    </ReactFlow>
  );
};
