import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Select } from '@verticeone/design-system';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter, RenewalStatus } from '../types';
import { useContractRenewalStatusOptions } from '../../../../hooks/useContractRenewalStatusOptions';

type RenewalStatusFilterOption = { value: RenewalStatus; label: string };

export const RenewalStatusFilter: FC = () => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastRenewalStates = setFilterValue(Filter.RENEWAL_STATUS);

  const { options } = useContractRenewalStatusOptions();

  const onChange = (selection: MultiValue<RenewalStatusFilterOption>) =>
    setLastRenewalStates(selection.length ? selection.map((option) => option.value) : undefined);

  return (
    <Stack minWidth={240}>
      <Select<RenewalStatusFilterOption, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={options}
        value={options?.filter(({ value }) => filterValues?.[Filter.RENEWAL_STATUS]?.includes(value))}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.RENEWAL_STATUS')}
        onChange={onChange}
        testId={'renewal-status-filter'}
      />
    </Stack>
  );
};
