import VariableSelector, { VariableSelectorProps } from './VariableSelector';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';

export type VariableSelectorFieldProps<T extends FieldValues> = {
  control: Control<T>;
  name: FieldPath<T>;
  testPrefix?: string;
} & Omit<VariableSelectorProps, 'value' | 'onChange'>;

export const VariableSelectorField = <T extends FieldValues>(props: VariableSelectorFieldProps<T>) => {
  const { control, name, ...otherProps } = props;

  const { field } = useController({ control, name });

  return <VariableSelector value={field.value} onChange={field.onChange} {...otherProps} />;
};
