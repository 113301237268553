import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Tooltip } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useFormatDate } from '@verticeone/design-system';

import { RequestRow } from '../../types';
import { STATUS_FILTER_MAP } from '../filter/types';
import TwoRowCell from './TwoRowCell';
import { ComponentProps } from 'react';

type KeyDateNameProperties =
  | 'targetSignDate'
  | 'autoRenewalDate'
  | 'renewalDate'
  | 'completionDate'
  | 'cancellationDate'
  | 'failureDate';

type KeyDate = {
  label?: string;
  name: KeyDateNameProperties;
  value: string;
};

export const getKeyDateLabel = (name: KeyDateNameProperties, labelDefault?: string) => {
  switch (name) {
    case 'targetSignDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.TARGET_SIGN_DATE';
    case 'autoRenewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.AUTO_RENEWAL';
    case 'renewalDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.RENEWAL';
    case 'completionDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.COMPLETED';
    case 'cancellationDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.CANCELLED';
    case 'failureDate':
      return 'ENTITIES.WORKFLOW_REQUEST.KEYDATES.FAILED';
    default:
      return labelDefault ?? '-';
  }
};

const sortKeyDates = (keyDates: RequestRow['keyDates'], sortingMode: 'default' | 'basic' = 'default') => {
  const mappedEntries = Object.entries(keyDates ?? {})
    .map(([name, value]) => (value ? { name, value } : null))
    .filter((item): item is KeyDate => item !== null);

  if (sortingMode === 'basic') {
    return mappedEntries.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());
  }

  const renewalEntries = mappedEntries.filter(({ name }) => name === 'renewalDate' || name === 'autoRenewalDate');
  const otherEntries = mappedEntries
    .filter(({ name }) => name !== 'renewalDate' && name !== 'autoRenewalDate')
    .sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());

  return [...renewalEntries, ...otherEntries];
};

export const getKeyDates = (
  { status, closedAt, lastActivityAt, keyDates, keyDateSource }: Omit<KeyDateCellProps['row'], 'sortingMode'>,
  sortingMode: 'default' | 'basic' = 'default'
): KeyDate[] => {
  const isCompleted = STATUS_FILTER_MAP['completed'].includes(status);
  const isCancelled = STATUS_FILTER_MAP['cancelled'].includes(status);
  const isFailed = STATUS_FILTER_MAP['failed'].includes(status);

  const injectedKeyDates = {
    ...keyDates,
    completionDate: isCompleted ? closedAt ?? lastActivityAt ?? '-' : undefined,
    cancellationDate: isCancelled ? closedAt ?? lastActivityAt ?? '-' : undefined,
    failureDate: isFailed ? closedAt ?? lastActivityAt ?? '-' : undefined,
  };

  return sortKeyDates(injectedKeyDates, sortingMode).map(({ name, value }) => ({
    label: getKeyDateLabel(name, keyDateSource),
    name,
    value,
  }));
};

type KeyDateCellProps = {
  row: Pick<RequestRow, 'status' | 'closedAt' | 'lastActivityAt' | 'keyDates' | 'keyDateSource'>;
  sortingMode?: 'default' | 'basic';
};

export const getColumnKeyDate = (
  status: RequestRow['status'],
  keyDates: KeyDate[],
  sortingMode: 'default' | 'basic' = 'default'
) => {
  // Handle status-specific cases first
  const statusPredicates: Record<string, (date: KeyDate) => boolean> = {
    COMPLETED: (date) => date.name === 'completionDate',
    CANCELLED: (date) => date.name === 'cancellationDate',
    TERMINATED: (date) => date.name === 'cancellationDate',
    FAILED: (date) => date.name === 'failureDate',
  };

  if (status in statusPredicates) {
    return keyDates.find(statusPredicates[status]);
  }

  // Handle sorting mode specific logic
  const sortingPredicates: Record<'default' | 'basic', (date: KeyDate) => boolean> = {
    default: (date) => date.name === 'targetSignDate',
    basic: (date) => new Date(date.value).getTime() >= new Date().getTime(),
  };

  return keyDates.find(sortingPredicates[sortingMode]) ?? keyDates[0];
};

export const KeyDateCell = ({ row, sortingMode = 'default' }: KeyDateCellProps) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation();
  const keyDates = getKeyDates(row, sortingMode);

  const isEmpty = keyDates.length === 0;
  const columnKeyDate = getColumnKeyDate(row.status, keyDates, sortingMode);
  const formattedDate = !isEmpty ? formatDate(columnKeyDate?.value ?? '') : '-';

  const cell = <TwoRowCell subtitle={!isEmpty ? t(columnKeyDate?.label ?? '') : undefined} title={formattedDate!} />;

  return (
    <Tooltip
      title={t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.KEY_DATES')}
      size="S"
      placement="bottom"
      content={<KeyDateTooltipContent keyDates={keyDates} />}
      disableHoverListener={keyDates.length <= 1}
    >
      <Stack width="100%">{cell}</Stack>
    </Tooltip>
  );
};

type KeyDateTooltipContentProps = {
  keyDates: KeyDate[];
};

const KeyDateTooltipContent = ({ keyDates }: KeyDateTooltipContentProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();

  const rowTextProps: ComponentProps<typeof Text> = {
    variant: 'body-regular',
    color: 'text4',
    size: 'S',
  };

  if (!keyDates || keyDates.length === 0) return null;

  return (
    <Stack direction="column">
      {keyDates.map(({ label, value }) =>
        label ? (
          <Stack direction="row" key={[label, value].join()}>
            <Text {...rowTextProps} width="150px">
              {t(label)}
            </Text>
            <Text {...rowTextProps}>{formatDate(value)}</Text>
          </Stack>
        ) : null
      )}
    </Stack>
  );
};
