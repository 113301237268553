import { Stack } from '@mui/material';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption, Text, TextFieldCaption } from '@verticeone/design-system';
import FormSelectField2 from '../../../../../../forms/fields/FormSelectField2';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { JiraCreateTaskFormData } from '../JiraCreate/formSchema';
import { JiraIssueType } from './types';

export type IssueTypePickerProps = {
  issueTypes: JiraIssueType[];
  isLoadingIssueTypes: boolean;
};

export const IssueTypePicker = ({ issueTypes, isLoadingIssueTypes }: IssueTypePickerProps) => {
  const { t } = useTranslation();
  const id = useId();

  const options = issueTypes.map<SimpleOption>((issueType) => ({
    value: issueType.id,
    label: issueType.name,
  }));

  return (
    <Stack gap={2}>
      <Stack>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.JIRA.ISSUE_TYPE_PICKER.LABEL')}
          size="XS"
          htmlFor={`${id}-issue-type`}
        />
        <Text variant="body-regular" size="S" color="text3">
          {t('INTELLIGENT_WORKFLOWS.JIRA.ISSUE_TYPE_PICKER.LABEL_DESC')}
        </Text>
      </Stack>
      <FormSelectField2<JiraCreateTaskFormData, SimpleOption, false>
        options={options}
        boxValue={(value) => options.find((option) => option.value === value) ?? null}
        unboxValue={(selectValue) => selectValue?.value}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        id={`${id}-issue-type`}
        isClearable={false}
        isLoading={isLoadingIssueTypes}
        name="issueType"
        placeholder={t('INTELLIGENT_WORKFLOWS.JIRA.ISSUE_TYPE_PICKER.PLACEHOLDER')}
        size="S"
        testId="issue-type-picker"
      />
    </Stack>
  );
};
