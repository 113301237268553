import { Stack, styled } from '@mui/material';
import { Button, Dialog, Divider, IconButton, Text, TextField } from '@verticeone/design-system';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { VariableType } from '../types';
import { TypeBadge } from './TypeBadge';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { Check } from '@mui/icons-material';
import { createValidationObj, validate } from './customVariableUtils';
import {
  fixInvalidCharacters,
  fixInvalidNameFormat,
  fixInvalidUppercaseWords,
  normalizeWhiteSpace,
} from './variableNameUtils';
import camelCase from 'lodash/camelCase';

const DialogHeader = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.neutral.color3}`,
}));

export type VariablesDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onChangeName?: (name: string) => void;
  propertyType?: VariableType;
  withBackdrop?: boolean;
  usedPropertyNames: string[];
};

const DEFAULT_NAME_INPUT_WIDTH = 110;

export const PropertyDialog: FC<VariablesDialogProps> = ({
  isOpen,
  onClose,
  onChangeName,
  propertyType,
  withBackdrop = false,
  usedPropertyNames,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(DEFAULT_NAME_INPUT_WIDTH);
  const validation = useMemo(() => createValidationObj(inputValue, []), [inputValue]);
  const isUnique = useMemo(
    () => !usedPropertyNames.includes(camelCase(normalizeWhiteSpace(inputValue))),
    [usedPropertyNames, inputValue]
  );
  const isValid = useMemo(() => validate(validation, ['alreadyExists']) && isUnique, [validation, isUnique]);

  const typeLabel = propertyType?.labels?.[0];

  const handleFixFormat = () => {
    setInputValue((currentName) => fixInvalidNameFormat(currentName));
  };
  const handleFixCharacters = () => {
    setInputValue((currentName) => fixInvalidCharacters(currentName));
  };
  const handleFixCase = () => {
    setInputValue((currentName) => fixInvalidUppercaseWords(currentName));
  };

  const handleRenameProperty = () => {
    const sanitized = camelCase(normalizeWhiteSpace(inputValue));
    onChangeName?.(sanitized);
    setInputValue('');
    onClose();
  };

  return (
    <Dialog size="M" withTransparentBackdrop={!withBackdrop} open={isOpen} onClose={onClose}>
      {propertyType && (
        <Stack>
          <DialogHeader width="100%" gap={2} direction="column">
            {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.PROPERTY_SELECTOR.HEADER', {
              object: t('DYNAMIC_FORM_BUILDER.FIELD.REPEATING.NAME'),
            })}
          </DialogHeader>
          <Divider />
          <Stack paddingBlock={2} alignItems="start">
            <Stack paddingInline={4} alignItems="center" justifyContent="space-between" direction="row" width="100%">
              <Stack gap={1}>
                <Stack direction="row" gap={1} alignItems="center" justifyContent={'start'} minWidth={0}>
                  <TextField
                    autoFocus
                    hiddenLabel
                    value={inputValue}
                    variant="ghost"
                    size="S"
                    onChange={(e) => {
                      setInputWidth(e.target.value ? e.target.scrollWidth : DEFAULT_NAME_INPUT_WIDTH);
                      setInputValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleRenameProperty();
                      }
                    }}
                    placeholder={t(
                      'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.PROPERTY_SELECTOR.PROPERTY_NAME_PLACEHOLDER'
                    )}
                    inputProps={{
                      style: { width: inputWidth },
                    }}
                  />
                  {typeLabel && <TypeBadge label={typeLabel} />}
                </Stack>
                <Stack direction="row" gap={2} alignItems="center">
                  {!validation.isValidFormat ? (
                    <>
                      <Text variant="body-regular" size="S" color="error1">
                        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES.INVALID_VARIABLE_FORMAT')}
                      </Text>
                      <Button size="XS" onClick={handleFixFormat}>
                        {t('ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                      </Button>
                    </>
                  ) : !validation.hasValidCharacters ? (
                    <>
                      <Text variant="body-regular" size="S" color="error1">
                        {t(
                          'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES.INVALID_VARIABLE_CHARACTERS'
                        )}
                      </Text>
                      <Button size="XS" onClick={handleFixCharacters}>
                        {t('ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                      </Button>
                    </>
                  ) : !validation.hasValidCase && inputValue.length > 0 ? (
                    <>
                      <Text variant="body-regular" size="S" color="error1">
                        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES.INVALID_CASE')}
                      </Text>
                      <Button size="XS" onClick={handleFixCase} disabled={validation.isEmpty}>
                        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.FIX_CUSTOM_VARIABLE_NAME')}
                      </Button>
                    </>
                  ) : !isUnique ? (
                    <Text variant="body-regular" size="S" color="error1">
                      {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.MESSAGES.PROPERTY_EXISTS', {
                        object: t('DYNAMIC_FORM_BUILDER.FIELD.REPEATING.NAME'),
                      })}
                    </Text>
                  ) : undefined}
                </Stack>
              </Stack>
              <IconButton
                variant="plain"
                color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                icon={Check}
                onClick={handleRenameProperty}
                disabled={!isValid}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
};
