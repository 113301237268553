import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import React, { FC } from 'react';
import { ContractFormData } from '../../types';
import useWatchContractFormField from '../../hooks/useWatchContractFormField';
import {
  FormCostModelLineItemsField,
  FormCostModelLineItemsFieldComponentType,
} from '../../../../forms/fields/FormCostModelLineItemsTableField';

type Component = FormCostModelLineItemsFieldComponentType<ContractFormData>;

type CostModelLineItemsContractFormEntryProps = Omit<
  ContractFormEntryProps<Component, Component>,
  'component' | 'writeComponent' | 'componentProps'
>;

export const CostModelLineItemsContractFormEntry: FC<CostModelLineItemsContractFormEntryProps> = (props) => {
  const currency = useWatchContractFormField('parts.contractual.financial.baseCurrency');
  const rollingFrequency = useWatchContractFormField('parts.contractual.lifecycle.rollFrequency');

  const commonProps = {
    currency,
    rollingFrequency: rollingFrequency ?? 'NO',
  };
  return (
    <ContractFormEntry<Component, Component>
      {...props}
      component={FormCostModelLineItemsField}
      componentProps={{ ...commonProps }}
      writeComponentProps={{ ...commonProps, editable: true }}
      writeComponent={FormCostModelLineItemsField}
    />
  );
};
