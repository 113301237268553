import { RollingRollFrequency } from '@vertice/core/src/modules/saas/contract/types';
import { BillingFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { match } from 'ts-pattern';

export const rollingRollFrequencyToBillingFrequency = (rollFrequency: RollingRollFrequency) =>
  match(rollFrequency)
    .returnType<BillingFrequency>()
    .with('MONTHLY', () => 'MONTHLY')
    .with('QUARTERLY', () => 'QUARTERLY')
    .with('ANNUALLY', () => 'ANNUALLY')
    .with('BIANNUALLY', () => 'BIANNUALLY')
    .exhaustive();
