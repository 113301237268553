import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPathByValue, FieldValues, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

import { Grid } from '@verticeone/design-system';

import { CurrencyFormEntry } from './CurrencyFormEntry';
import { DateFormEntry } from './DateFormEntry';
import { TaskFormEntry } from './TaskFormEntry';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { PaymentTermsFormEntry } from './OfferForm/PaymentTermsFormEntry';
import { OfferFrequencyFormEntry } from './OfferForm/OfferFrequencyFormEntry';
import { CheckItemFormEntry } from './CheckFormEntry';
import { SelectVendorPanel } from './SelectVendorPanel';
import { Offer, joinFormPath, useWatchNested } from '../schemas';
import { useTaskFormContext } from '../TaskFormContext';
import { OfferFormProductList } from './OfferForm/OfferFormProductList';
import { SavingsInsightsFormEntry } from './SavingsInsightsFormEntry';
import { OfferFormConcessionsList } from './OfferForm/OfferFormConcessionsList';
import { useOfferFormComputeCosts } from '../../../useOfferFormComputedCosts';
import { BillingFrequencyFormEntry } from './BillingFrequencyFormEntry';
import { OverridableCostFormEntry } from './OfferForm/OverridableCostFormEntry';
import dayjs from 'dayjs';

type OfferFormEntryProps<V extends FieldValues, P extends FieldPathByValue<V, Offer> = FieldPathByValue<V, Offer>> = {
  name: P;
  defaultCurrency?: string;
  editName?: boolean;
};
export const OfferFormEntry = <T extends FieldValues>({
  name: offerName,
  editName,
  defaultCurrency,
}: OfferFormEntryProps<T>) => {
  const { t } = useTranslation();
  const { setValue, trigger } = useFormContext<T>();
  const { readOnly } = useTaskFormContext();

  const { isProcessingAnnualCost } = useOfferFormComputeCosts<T>(offerName);

  const rollingFrequency = useWatchNested({ offerName, fieldName: 'rollingFrequency' });
  const offerCurrency = useWatchNested({ offerName, fieldName: 'baseCurrency' });
  const paymentTerms = useWatchNested({ offerName, fieldName: 'paymentTerms' });
  const billingFrequency = useWatchNested({ offerName, fieldName: 'billingFrequency' });
  const purchasedFromReseller = useWatchNested({ offerName, fieldName: 'purchasedFromReseller' });
  const startDate = useWatchNested({ offerName, fieldName: 'startDate' });
  const endDate = useWatchNested({ offerName, fieldName: 'endDate' });

  useEffect(() => {
    void trigger(joinFormPath<T>(offerName, 'endDate'));
  }, [startDate, trigger, offerName]);

  useEffect(() => {
    void trigger(joinFormPath<T>(offerName, 'autoRenewalDate'));
  }, [startDate, endDate, trigger, offerName]);

  const noRollingFrequency = rollingFrequency === 'NO';

  useEffect(() => {
    if (defaultCurrency && !offerCurrency) {
      setValue(joinFormPath<T>(offerName, 'baseCurrency'), defaultCurrency as any);
    }
  }, [defaultCurrency, setValue, offerCurrency, offerName]);

  return (
    <Stack gap={6}>
      <Grid container spacing={4}>
        <TaskFormEntry<T, typeof FormTextField>
          name={joinFormPath<T>(offerName, 'name')}
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.NAME')}
          component={FormTextField}
          disabled={!editName}
        />
      </Grid>

      <Grid container spacing={4} rowSpacing={6}>
        <OfferFrequencyFormEntry<T>
          name={joinFormPath<T>(offerName, 'rollingFrequency')}
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.ROLLING_FREQUENCY')}
        />
        <CurrencyFormEntry<T> name={joinFormPath<T>(offerName, 'baseCurrency')} />
        <DateFormEntry<T>
          name={joinFormPath<T>(offerName, 'startDate')}
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.START_DATE')}
        />
        {noRollingFrequency && (
          <DateFormEntry<T>
            name={joinFormPath<T>(offerName, 'endDate')}
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.END_DATE')}
            required={!readOnly}
            componentProps={{
              minDate: dayjs(startDate).isValid() ? dayjs(startDate).add(1, 'day').toISOString() : undefined,
            }}
          />
        )}
      </Grid>

      <Grid container spacing={4} rowSpacing={6}>
        <BillingFrequencyFormEntry<T>
          name={joinFormPath<T>(offerName, 'billingFrequency')}
          label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        />
        {billingFrequency === 'OTHER' && (
          <TaskFormEntry<T, typeof FormTextField>
            name={joinFormPath<T>(offerName, 'billingFrequencyOther')}
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY_OTHER')}
            component={FormTextField}
            required={!readOnly}
          />
        )}
      </Grid>

      {noRollingFrequency && (
        <Grid container spacing={4}>
          <DateFormEntry<T>
            name={joinFormPath<T>(offerName, 'autoRenewalDate')}
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.AUTO_RENEWAL_DATE')}
            componentProps={{ minDate: startDate, maxDate: endDate }}
          />
          <CheckItemFormEntry<T>
            name={joinFormPath<T>(offerName, 'autoRenewalWaived')}
            label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.AUTO_RENEWAL_WAIVED')}
          />
        </Grid>
      )}

      <Grid container spacing={4}>
        <PaymentTermsFormEntry<T>
          name={joinFormPath<T>(offerName, 'paymentTerms')}
          label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS')}
        />
        {paymentTerms === 'OTHER' && (
          <TaskFormEntry<T, typeof FormTextField>
            name={joinFormPath<T>(offerName, 'paymentTermsOther')}
            label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS_OTHER')}
            component={FormTextField}
            required={!readOnly}
          />
        )}
      </Grid>

      <Grid container spacing={4}>
        <CheckItemFormEntry<T>
          name={joinFormPath<T>(offerName, 'purchasedFromReseller')}
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.ACTIONS.PURCHASE_FROM_RESELLER')}
        />
        <TaskFormEntry<T, typeof FormTextField>
          name={joinFormPath<T>(offerName, 'resellerName')}
          label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.RESELLER')}
          component={FormTextField}
          disabled={!purchasedFromReseller}
          required={purchasedFromReseller && !readOnly}
        />
      </Grid>

      <SelectVendorPanel<T> vendorName={joinFormPath<T>(offerName, 'vendor')} />
      <Stack>
        <OfferFormProductList<T> name={offerName} />
      </Stack>

      <Grid container>
        <OverridableCostFormEntry<T>
          defaultCostsName={joinFormPath<T>(offerName, 'computedCosts')}
          costsName={joinFormPath<T>(offerName, 'effectiveCosts')}
          overrideCostsName={joinFormPath<T>(offerName, 'overrideEffectiveCost')}
          currency={offerCurrency}
          loadingComputedCosts={isProcessingAnnualCost}
        />
      </Grid>

      <Stack>
        <OfferFormConcessionsList name={joinFormPath<T>(offerName, 'concessions')} currency={offerCurrency} />
      </Stack>
      <SavingsInsightsFormEntry name={joinFormPath<T>(offerName, 'additionalNotes')} />
    </Stack>
  );
};
