import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { FormToggleButtonField } from '../../../../../../forms/fields/FormToggleButtonField';
import { TaskFormEntry } from '../shared/formFields/TaskFormEntry';
import { useWatch } from 'react-hook-form';
import { FormData } from './schema';
import VendorLogo from '../../../../../../../components/VendorLogo';
import { ToggleButtonOption } from '../../../../../../forms/fields/FormToggleButtonField/FormToggleButtonField';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

export const SelectVendorForm = () => {
  const taskData = useWatch<FormData>();
  const [options, setOptions] = useState<ToggleButtonOption[]>([]);
  const [resultValue, setResultValue] = useState<string | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    const finalDecision = taskData.finalDecision?.vendor?.id;
    const vendors = (taskData.selectedVendorsAndProducts || [])
      .map((item) => item.vendor)
      .filter((vendor): vendor is NonNullable<typeof vendor> => vendor !== undefined);

    const opts: ToggleButtonOption[] = vendors
      .map((vendor) => ({
        title: vendor.name ?? '',
        value: vendor.id ?? '',
        isActive: false,
        element: <VendorLogo vendorId={vendor?.id} size={30} logoOnly={true} />,
      }))
      .sort((a, b) => {
        if (a.value === finalDecision) return -1;
        if (b.value === finalDecision) return 1;
        return a.title.localeCompare(b.title);
      });

    const rejection = createRejectionOption(
      t('INTELLIGENT_WORKFLOWS.TASK_FORMS.SELECT_VENDOR.REJECT_ALL_VENDORS'),
      taskData.vendorId && !vendors.some((v) => v.id === taskData.vendorId) ? taskData.vendorId : undefined
    );
    setResultValue(taskData.vendorsRejected ? rejection.value : finalDecision);
    taskData.vendorsRejected
      ? setOptions([rejection, ...opts])
      : setOptions([...opts, { ...rejection, indentSize: 4 }]);
  }, [t, taskData]);

  return (
    <Stack spacing={4}>
      <Text variant="caption" size="M">
        {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.SELECT_VENDOR.HELPER_TEXT')}
      </Text>
      <TaskFormEntry<FormData, typeof FormToggleButtonField>
        name="vendorId"
        required={true}
        component={FormToggleButtonField}
        componentProps={{
          compact: true,
          options: options,
          inGrid: false,
          resultValue: resultValue,
        }}
      />
    </Stack>
  );
};

const createRejectionOption = (text: string, existing_rejection_id?: string): ToggleButtonOption => ({
  value: existing_rejection_id ?? uuid(),
  title: text,
  isActive: false,
  color: 'error',
});
