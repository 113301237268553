import { z } from 'zod';
import { contractDetailsSchema } from './formSteps/ContractDetailsFormStep';
import { contractDatesSchema } from './formSteps/ContractDatesFormStep';
import { contractDocumentsSchema } from './formSteps/ContractDocumentsFormStep';
import { contractLineItemsSchema } from './formSteps/ContractLineItemsFormStep';
import { Vendor } from '../../../../vendor/types';
import { RollingFrequency } from '@vertice/slices/src/openapi/codegen/contractCostAPI';
import {
  BillingFrequency,
  RollFrequency,
  PaymentTerms,
  AdvancedLinearCostModelLine,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type TaskContractReviewOutputOffer = {
  id: string;
  vendor: Vendor;
  rollingFrequency: RollFrequency;
  billingFrequency?: BillingFrequency | null;
  startDate: string;
  endDate?: string;
  autoRenewalDeadline?: string;
  paymentTerms?: PaymentTerms | null;
  baseCurrency: string;
  lineItems?: AdvancedLinearCostModelLine[];
  annualCost?: { value: number; currency: string };
  totalCost?: { value: number; currency: string };
};

export type TaskContractReviewOutput = {
  contract: {
    department: string;
    finalOffer: TaskContractReviewOutputOffer;
    signingDate?: string;
  };
  approved: boolean;
};

export type TaskContractReviewInput = Partial<{
  vendorContactName: string;
  signingDate: string;
  prevContractId: string;
  vendorContactEmail: string;
  signingEntity: string;
  renewalDate: string;
  vendorName: string;
  department: string;
  additionalNotes: string;
  startDate: string;
  autoRenewalDeadline: string;
  rollingFrequency: RollingFrequency;
}>;

export const contractReviewSchema = z.object({
  contractDetails: contractDetailsSchema,
  contractDates: contractDatesSchema,
  documents: contractDocumentsSchema,
  lineItems: contractLineItemsSchema,
});

export type ContractReviewForm = z.infer<typeof contractReviewSchema>;
