import Root from './VariableMapperRoot';
import Row from './VariableMapperRow';
import Heading from './VariableMapperHeading';
import AddButton from './VariableMapperAddButton';
import Divider from './VariableMapperArrowRight';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Root,
  Row,
  Heading,
  Divider,
  AddButton,
}