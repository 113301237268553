import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled, useTheme } from '@mui/material';
import InterestsTwoToneIcon from '@mui/icons-material/InterestsTwoTone';

import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { BannerContent } from '@vertice/core/src/components/Banner/BannerContent';
import { IconWrapper } from '@verticeone/design-system';
import { Banner } from '@vertice/core/src/components/Banner/Banner';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  padding: '2px',
  backgroundColor: theme.palette.transparent.color4,
  color: theme.palette.tertiary.color4,
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const OtherRecommendationsBanner = () => {
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.CLOUD_RECOMMENDATIONS' });
  const { palette } = useTheme();

  return (
    <Banner color={palette.tertiary.color2}>
      <BannerContent
        icon={<StyledIconWrapper size="L" icon={InterestsTwoToneIcon} />}
        title={
          <Text variant="heading" size="XS" color={palette.core.bg}>
            {t('OTHER_RECOMMENDATIONS_NOTE')}
          </Text>
        }
        badge={
          <Button variant="ghost" color="tertiary" size="XS" onClick={() => navigate(routes.CLOUD.OPTIMIZATION)}>
            {t('VIEW')}
          </Button>
        }
      />
    </Banner>
  );
};

export default OtherRecommendationsBanner;
