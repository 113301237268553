import React from 'react';
import ProcuredSpendStatCard from './components/ProcuredSpendStatCard';
import InFlightSpendStatCard from './components/InFlightSpendStatCard';
import TasksStatCard from './components/TasksStatCard';
import RequestsStatCard from './components/RequestsStatCard';
import { Grid } from '@verticeone/design-system';

const OverviewStats = () => (
  <Grid container rowSpacing={4} columnSpacing={4} alignItems="stretch">
    <Grid item xs={12} md={6} lg={3}>
      <TasksStatCard />
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <RequestsStatCard />
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <InFlightSpendStatCard />
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <ProcuredSpendStatCard />
    </Grid>
  </Grid>
);

export default OverviewStats;
