import React from 'react';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Card } from '@verticeone/design-system';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import DisclaimerBanner from './components/DisclaimerBanner';
import HeaderSummary from './components/HeaderSummary';
import { CloudOptimizationProvider } from './CloudOptimizationContext';
import TogglePeriod from './components/TogglePeriod';
import CardDivider from '../../../modules/cloud/cards/shared/CardDivider';
import OptimizationTable from './components/OptimizationTable';
import LoadableOptimization from './components/LoadableOptimization';

const CloudOptimization = () => (
  <LoadableAWSPageWrapper>
    <>
      <DisclaimerBanner />
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid item sm={12}>
          <Card>
            <CloudOptimizationProvider>
              <HeaderSummary />
              <LoadableOptimization>
                <Stack
                  paddingLeft={6}
                  paddingBottom={6}
                  paddingRight={6}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <TogglePeriod />
                </Stack>
              </LoadableOptimization>
              <CardDivider />
              <OptimizationTable />
            </CloudOptimizationProvider>
          </Card>
        </Grid>
      </Grid>
    </>
  </LoadableAWSPageWrapper>
);

export default CloudOptimization;
