import { useState } from 'react';
import { useEventCallback, useEventListener } from 'usehooks-ts';

type UseDialogKeyActionsProps<T extends HTMLElement> = {
  onEscape: () => void;
  onEnter: () => void;
  ref: React.RefObject<T>;
};

export const useDialogKeyActions = <T extends HTMLElement = HTMLDivElement>({
  onEscape,
  onEnter,
  ref,
}: UseDialogKeyActionsProps<T>) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleKeyPress = useEventCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onEscape();
    }
    if (event.key === 'Enter') {
      if (hasSubmitted) return;
      onEnter();
      setHasSubmitted(true);
    }
  });

  useEventListener('keyup', handleKeyPress, ref);
};
