import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { EventNoteOutlined, EventRepeatOutlined } from '@mui/icons-material';
import { match } from 'ts-pattern';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Buttons, Content, Header, InnerScrollable } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData, ContractType } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { RollingContractFields } from './RollingContractFields';
import { FixedTermContractFields } from './FixedTermContractFields';
import { OneOffIcon } from './OneOffIcon';
import { OneOffContractFields } from './OneOffContractFields';
import { isRollingRollFrequency } from '@vertice/core/src/modules/saas/contract/computed';
import { rollingRollFrequencyToBillingFrequency } from './utils';

const useContractTypeWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('upload_existing_contract'),
  };
};

const ContractTypeStep: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { t } = useTranslation();

  const { goBack, goToNext } = useContractTypeWizard();

  const { trigger, setValue, control } = useFormContext<AddExistingContractFormData>();
  const contractType = useWatch({ control, name: 'contractType' });
  const contractCategory = useWatch({ control, name: 'contractCategory' });
  const rollingFrequency = useWatch({ control, name: 'rollingFrequency' });
  const billingFrequency = useWatch({ control, name: 'billingFrequency' });
  const otherMandatoryFields = useWatch({ control, name: ['startDate', 'contractLength', 'renewalDate'] });
  const billingFrequencySameAsRolling = useWatch({ control, name: 'billingFrequencySameAsRolling' });

  useEffect(() => {
    const fieldsToRevalidate = match(contractType)
      .with('FIXED', () => ['startDate', 'contractLength', 'renewalDate'] as const)
      .with('ROLLING', () => ['startDate'] as const)
      .with('ONE_OFF', () => ['startDate'] as const)
      .with(null, () => [])
      .exhaustive();

    void trigger(fieldsToRevalidate).then(setNextButtonEnabled);
  }, [contractType, trigger, otherMandatoryFields]);

  // set default rolling frequency
  useEffect(() => {
    if (contractType === 'ROLLING' && !isRollingRollFrequency(rollingFrequency || 'NO')) {
      setValue('rollingFrequency', 'MONTHLY');
    }
  }, [contractType, rollingFrequency, setValue]);

  // clear props that are not applicable to the selected contract type
  const proceed = useCallback(() => {
    if (contractType === 'FIXED') {
      setValue('rollingFrequency', 'NO');
    }
    if (contractType === 'ROLLING') {
      setValue('contractLength', null);
      setValue('renewalDate', undefined);
      setValue('autoRenewalDate', undefined);

      if (billingFrequencySameAsRolling && rollingFrequency && isRollingRollFrequency(rollingFrequency)) {
        setValue('billingFrequency', rollingRollFrequencyToBillingFrequency(rollingFrequency));
        setValue('billingFrequencyOther', null);
      }

      if (!billingFrequencySameAsRolling && billingFrequency !== 'OTHER') {
        setValue('billingFrequencyOther', null);
      }
    }
    if (contractType === 'ONE_OFF') {
      setValue('rollingFrequency', 'ONE_OFF');
      setValue('contractLength', null);
      setValue('renewalDate', undefined);
      setValue('autoRenewalDate', undefined);
      setValue('billingFrequency', null);
    }
    goToNext();
  }, [contractType, goToNext, setValue, billingFrequencySameAsRolling, rollingFrequency, billingFrequency]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_TYPE.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_TYPE.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <FormToggleButtonField
              name="contractType"
              buttonHeight={140}
              color="neutral"
              options={[
                {
                  title: t('ENTITIES.CONTRACT.LABELS.FIXED'),
                  label: t('ENTITIES.CONTRACT.LABELS.FIXED_DESCRIPTION'),
                  value: 'FIXED' satisfies ContractType,
                  icon: EventNoteOutlined,
                },
                {
                  title: t('ENTITIES.CONTRACT.LABELS.ROLLING'),
                  label: t('ENTITIES.CONTRACT.LABELS.ROLLING_DESCRIPTION'),
                  value: 'ROLLING' satisfies ContractType,
                  icon: EventRepeatOutlined,
                },
                ...(contractCategory === 'OTHER'
                  ? [
                      {
                        title: t('ENTITIES.CONTRACT.LABELS.ONE_OFF'),
                        label: t('ENTITIES.CONTRACT.LABELS.ONE_OFF_DESCRIPTION'),
                        value: 'ONE_OFF' satisfies ContractType,
                        icon: OneOffIcon,
                      },
                    ]
                  : []),
              ]}
            />
          </Grid>
          {contractType === 'ROLLING' && <RollingContractFields />}
          {contractType === 'FIXED' && <FixedTermContractFields />}
          {contractType === 'ONE_OFF' && <OneOffContractFields />}
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: proceed,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled,
        }}
      />
    </Content>
  );
};

export default ContractTypeStep;
