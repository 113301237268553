import React from 'react';
import { WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Content } from '@vertice/core/src/components/Dialog/Components';
import useNavigateToContractWithDelay from '../../../sharedHooks/useNavigateToContractWithDelay';
import { ConfirmationPanel } from '../../../SharedComponents/ConfirmationPanel';
import { useFormContext } from 'react-hook-form';
import { AddExistingContractFormData } from '../../types';

const ConfirmationStep: React.FC<WizardStepProps> = () => {
  const { watch } = useFormContext<AddExistingContractFormData>();
  const { existingContractId } = watch();
  useNavigateToContractWithDelay(existingContractId);

  return (
    <Content>
      <ConfirmationPanel headingTitleKey="INTAKE_FORM.EXISTING_CONTRACT_CONFIRMATION.HEADING_TITLE" />
    </Content>
  );
};

export default ConfirmationStep;
