import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { ContractFormData } from '../../types';
import useBillingFrequencyOptions from '../../hooks/useBillingFrequencyOptions';

type BillingFrequencyFormEntryProps = Omit<ContractFormEntryProps<any, any>, 'component' | 'writeComponent'>;

type BillingFrequencySelectOption = { value: string; label: string };

const BillingFrequencyFormEntry = (options: BillingFrequencyFormEntryProps) => {
  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, BillingFrequencySelectOption, false>
    >
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? billingFrequencyMap[value]?.label : undefined),
        ...options.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...options.writeComponentProps,
        options: billingFrequencyOpts,
        boxValue: (value) => (value ? billingFrequencyMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default BillingFrequencyFormEntry;
