import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useMemo } from 'react';
import { apiCustomizationViewToForm } from '../ViewSettingsDrawer/transformations';
import { useListCustomizationViewsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { FilterView } from '../../../types';
import { chain } from 'lodash';

export type UseCustomViewsListParams = {
  skip?: boolean;
};

/**
 * Wrapper around the custom filter views API.
 *
 * Purpose: Hide conversions between API and our internal FE representation.
 */
export const useCustomViewsList = ({ skip }: UseCustomViewsListParams = {}) => {
  const { accountId } = useAccountContext();

  const { data: customViewsRaw, isLoading } = useListCustomizationViewsQuery({ accountId }, { skip });

  const customViews = useMemo(
    () =>
      customViewsRaw
        ? chain(customViewsRaw.views)
            .orderBy(({ label }) => label?.toLocaleLowerCase(), 'asc')
            .map(
              (customViewRaw): FilterView => ({
                type: 'custom',
                ...apiCustomizationViewToForm(customViewRaw),
              })
            )
            .value()
        : undefined,
    [customViewsRaw]
  );

  return {
    customViews,
    isLoading,
  };
};
