import { FC } from 'react';
import { NumberFieldType } from './types';
import { Stack } from '@mui/material';
import FormNumberField, { FormNumberFieldType } from '../../../fields/FormNumberField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { DynamicFormPresenterProps } from '../types';

export const NumberFieldPresenter: FC<DynamicFormPresenterProps<NumberFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormNumberFieldType<FieldValues>>
        name={field.name}
        component={FormNumberField}
        label={label}
        description={description}
        required={required}
        componentProps={{
          allowsFloat: true,
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
