import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';
import { Dictionary, keyBy } from 'lodash';

const usePaymentTermsOptions = () => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [
      {
        value: 'NET_15',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_15'),
      },
      {
        value: 'NET_30',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_30'),
      },
      {
        value: 'NET_45',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_45'),
      },
      {
        value: 'NET_60',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_60'),
      },
      {
        value: 'NET_90',
        label: t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERM_NET_90'),
      },
      {
        value: 'OTHER',
        label: t('ENTITIES.CONTRACT.LABELS.OTHER'),
      },
    ],
    [t]
  );

  const optionsMap = useMemo<Dictionary<SimpleOption>>(() => keyBy(options, 'value'), [options]);

  const getLabel = useCallback(
    (value?: string | null) => {
      if (!value) return null;
      return optionsMap[value]?.label ?? null;
    },
    [optionsMap]
  );

  return { options, optionsMap, getLabel };
};

export default usePaymentTermsOptions;
