import { docprocAPI as api } from '../../api/docprocAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listQueues: build.query<ListQueuesApiResponse, ListQueuesApiArg>({
      query: () => ({ url: `/queues` }),
    }),
    createQueue: build.mutation<CreateQueueApiResponse, CreateQueueApiArg>({
      query: (queryArg) => ({ url: `/queues`, method: 'POST', body: queryArg.body }),
    }),
    describeQueue: build.query<DescribeQueueApiResponse, DescribeQueueApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}` }),
    }),
    updateQueue: build.mutation<UpdateQueueApiResponse, UpdateQueueApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}`, method: 'PUT', body: queryArg.body }),
    }),
    exportQueue: build.mutation<ExportQueueApiResponse, ExportQueueApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/export`,
        method: 'POST',
        params: {
          exportType: queryArg.exportType,
        },
      }),
    }),
    batchGetQueueAnnotations: build.mutation<BatchGetQueueAnnotationsApiResponse, BatchGetQueueAnnotationsApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/annotations/batch-get`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listQueueFiles: build.query<ListQueueFilesApiResponse, ListQueueFilesApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}/files` }),
    }),
    generateQueueFilePreSignedLink: build.mutation<
      GenerateQueueFilePreSignedLinkApiResponse,
      GenerateQueueFilePreSignedLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/access/presigned/files/${queryArg.fileName}`,
        method: 'POST',
      }),
    }),
    markQueueFileAsGolden: build.mutation<MarkQueueFileAsGoldenApiResponse, MarkQueueFileAsGoldenApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/access/presigned/files/${queryArg.fileName}/mark-as-golden`,
        method: 'POST',
      }),
    }),
    getQueueFileContents: build.query<GetQueueFileContentsApiResponse, GetQueueFileContentsApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}` }),
    }),
    uploadQueueFile: build.mutation<UploadQueueFileApiResponse, UploadQueueFileApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'x-verticeone-doc-processing': queryArg['x-verticeone-doc-processing'],
        },
      }),
    }),
    listQueueFileTasks: build.query<ListQueueFileTasksApiResponse, ListQueueFileTasksApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks` }),
    }),
    createQueueFileTask: build.mutation<CreateQueueFileTaskApiResponse, CreateQueueFileTaskApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    describeQueueFileTask: build.query<DescribeQueueFileTaskApiResponse, DescribeQueueFileTaskApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks/${queryArg.taskCode}`,
      }),
    }),
    getQueueFileTaskResultRaw: build.query<GetQueueFileTaskResultRawApiResponse, GetQueueFileTaskResultRawApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks/${queryArg.taskCode}/result/raw`,
      }),
    }),
    getQueueFileTaskResultStringDict: build.query<
      GetQueueFileTaskResultStringDictApiResponse,
      GetQueueFileTaskResultStringDictApiArg
    >({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks/${queryArg.taskCode}/result/string-dict`,
      }),
    }),
    restartQueueFileTask: build.mutation<RestartQueueFileTaskApiResponse, RestartQueueFileTaskApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/tasks/${queryArg.taskCode}/restart`,
        method: 'POST',
      }),
    }),
    getQueueFileAnnotations: build.query<GetQueueFileAnnotationsApiResponse, GetQueueFileAnnotationsApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/annotations` }),
    }),
    putQueueFileAnnotations: build.mutation<PutQueueFileAnnotationsApiResponse, PutQueueFileAnnotationsApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/annotations`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getQueueFileProgress: build.query<GetQueueFileProgressApiResponse, GetQueueFileProgressApiArg>({
      query: (queryArg) => ({ url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/progress` }),
    }),
    putQueueFileProgress: build.mutation<PutQueueFileProgressApiResponse, PutQueueFileProgressApiArg>({
      query: (queryArg) => ({
        url: `/queues/${queryArg.queueId}/files/${queryArg.fileName}/progress`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    generateNotificationOtp: build.mutation<GenerateNotificationOtpApiResponse, GenerateNotificationOtpApiArg>({
      query: (queryArg) => ({ url: `/notif/authorize/otp`, method: 'POST', body: queryArg.body }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as docprocAPI };
export type ListQueuesApiResponse = /** status 200 List resources response */ CommandResultListQueues;
export type ListQueuesApiArg = void;
export type CreateQueueApiResponse = /** status 200 Create queue response */ CommandResultCreateQueue;
export type CreateQueueApiArg = {
  /** Create queue request */
  body: {
    name: string;
    config: QueueConfig;
  };
};
export type DescribeQueueApiResponse = /** status 200 Get queue response */ CommandResultDescribeQueue;
export type DescribeQueueApiArg = {
  queueId: Identifier;
};
export type UpdateQueueApiResponse = /** status 200 Update queue response */ CommandResultUpdateQueue;
export type UpdateQueueApiArg = {
  queueId: Identifier;
  /** Update queue request */
  body: {
    queue: Queue;
  };
};
export type ExportQueueApiResponse = /** status 200 Export queue response */ Text;
export type ExportQueueApiArg = {
  queueId: Identifier;
  exportType?: 'CSV';
};
export type BatchGetQueueAnnotationsApiResponse =
  /** status 200 Generate notification OTP response */ CommandResultBatchGetQueueAnnotations;
export type BatchGetQueueAnnotationsApiArg = {
  queueId: Identifier;
  /** Batch get queue annotations request */
  body: {
    queueId: string;
    filterFileNames?: string[] | null;
  };
};
export type ListQueueFilesApiResponse = /** status 200 List queue files response */ CommandResultListQueueFiles;
export type ListQueueFilesApiArg = {
  queueId: Identifier;
};
export type GenerateQueueFilePreSignedLinkApiResponse =
  /** status 200 Pre-signed URL response */ CommandResponsePreSignUrl;
export type GenerateQueueFilePreSignedLinkApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type MarkQueueFileAsGoldenApiResponse = /** status 200 Pre-signed URL response */ CommandResponsePreSignUrl;
export type MarkQueueFileAsGoldenApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type GetQueueFileContentsApiResponse = /** status 200 File contents */ string;
export type GetQueueFileContentsApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type UploadQueueFileApiResponse = /** status 200 File updated response */ string;
export type UploadQueueFileApiArg = {
  queueId: Identifier;
  fileName: string;
  'x-verticeone-doc-processing'?: 'PIPELINE:FULL' | 'NONE';
  /** ...
   */
  body: Blob;
};
export type ListQueueFileTasksApiResponse = /** status 200 List resources response */ CommandResultListQueueFileTasks;
export type ListQueueFileTasksApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type CreateQueueFileTaskApiResponse = /** status 200 Create queue response */ CommandResultCreateQueueFileTask;
export type CreateQueueFileTaskApiArg = {
  queueId: Identifier;
  fileName: string;
  /** Create queue request */
  body: {
    taskCode: string;
    taskAttributes?: object;
  };
};
export type DescribeQueueFileTaskApiResponse = /** status 200 Get queue response */ CommandResultDescribeQueueFileTask;
export type DescribeQueueFileTaskApiArg = {
  queueId: Identifier;
  fileName: string;
  taskCode: string;
};
export type GetQueueFileTaskResultRawApiResponse =
  /** status 200 Get queue response */ CommandResultGetQueueFileTaskResultRaw;
export type GetQueueFileTaskResultRawApiArg = {
  queueId: Identifier;
  fileName: string;
  taskCode: string;
};
export type GetQueueFileTaskResultStringDictApiResponse =
  /** status 200 Get queue response */ CommandResultGetQueueFileTaskResultDict;
export type GetQueueFileTaskResultStringDictApiArg = {
  queueId: Identifier;
  fileName: string;
  taskCode: string;
};
export type RestartQueueFileTaskApiResponse = /** status 200 Get queue response */ CommandResultRestartQueueFileTask;
export type RestartQueueFileTaskApiArg = {
  queueId: Identifier;
  fileName: string;
  taskCode: string;
};
export type GetQueueFileAnnotationsApiResponse =
  /** status 200 Get object annotation response */ CommandResultGetObjectAnnotation;
export type GetQueueFileAnnotationsApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type PutQueueFileAnnotationsApiResponse =
  /** status 200 Get object annotation response */ CommandResultPutObjectAnnotation;
export type PutQueueFileAnnotationsApiArg = {
  queueId: Identifier;
  fileName: string;
  /** Put object annotation request */
  body: {
    annotations?: Annotation[];
  };
};
export type GetQueueFileProgressApiResponse = /** status 200  */ CommandResultGetQueueFileProgress;
export type GetQueueFileProgressApiArg = {
  queueId: Identifier;
  fileName: string;
};
export type PutQueueFileProgressApiResponse = /** status 200  */ CommandResultPutQueueFileProgress;
export type PutQueueFileProgressApiArg = {
  queueId: Identifier;
  fileName: string;
  body: {
    status?: QueueFileStatus;
  };
};
export type GenerateNotificationOtpApiResponse =
  /** status 200 Generate notification OTP response */ CommandResultGenerateNotificationOtp;
export type GenerateNotificationOtpApiArg = {
  /** Generate notification OTP request */
  body: object;
};
export type AttributeDefinition = {
  type: string;
  name: string;
};
export type QueueConfig = {
  collectedAttributes?: AttributeDefinition[];
};
export type QueueFileStats = {
  total?: number | null;
  empty?: number | null;
  inProgress?: number | null;
  completed?: number | null;
  skipped?: number | null;
};
export type QueueStats = {
  files?: QueueFileStats;
};
export type Queue = {
  id: string;
  name: string;
  status: 'PENDING' | 'RUNNING' | 'FAILED' | 'COMPLETED' | 'ARCHIVED';
  config?: QueueConfig;
  metadata?: {
    [key: string]: any;
  };
  stats?: QueueStats;
};
export type CommandResultListQueues = {
  queues?: Queue[];
};
export type CommandResultCreateQueue = {
  queue?: Queue;
};
export type CommandResultDescribeQueue = {
  queue?: Queue;
};
export type Identifier = string;
export type CommandResultUpdateQueue = {
  queue: Queue;
};
export type Text = string;
export type QueueFileStatus = 'EMPTY' | 'IN_PROGRESS' | 'COMPLETED' | 'SKIPPED';
export type QueueFile = {
  queueId: string;
  s3Path: string;
  filePath: string;
  fileName: string;
  metadata?: {
    [key: string]: any;
  };
  dataQuality?: string;
  status: QueueFileStatus;
};
export type ArrayOfAnnotations = Annotation[];
export type Annotation = {
  name: string;
  attributeDefinition: AttributeDefinition;
  value?:
    | null
    | string
    | number
    | number
    | boolean
    | object
    | ArrayOfAnnotations
    | (null | object | string | number | number | boolean)[];
};
export type QueueFileAnnotation = {
  queueId: string;
  queueFile?: QueueFile;
  annotations: Annotation[];
};
export type CommandResultBatchGetQueueAnnotations = {
  annotations?: QueueFileAnnotation[];
};
export type CommandResultListQueueFiles = {
  files?: QueueFile[];
};
export type CommandResponsePreSignUrl = {
  preSignedUrl?: string;
};
export type QueueFileTask = {
  queueId: string;
  fileName: string;
  taskCode: string;
  status: 'PENDING' | 'RUNNING' | 'FAILED' | 'SUCCEEDED';
  attributes?: object;
  metadata?: {
    [key: string]: any;
  };
};
export type CommandResultListQueueFileTasks = {
  tasks?: QueueFileTask[];
};
export type CommandResultCreateQueueFileTask = {
  task: QueueFileTask;
};
export type CommandResultDescribeQueueFileTask = {
  task?: QueueFileTask;
};
export type CommandResultGetQueueFileTaskResultRaw = {
  raw?: {
    stringContent?: string;
  };
};
export type CommandResultGetQueueFileTaskResultDict = {
  stringDict?: {
    [key: string]: string;
  };
};
export type CommandResultRestartQueueFileTask = {
  task: QueueFileTask;
};
export type CommandResultGetObjectAnnotation = {
  annotations?: Annotation[];
};
export type CommandResultPutObjectAnnotation = {
  annotations?: Annotation[];
};
export type CommandResultGetQueueFileProgress = {
  queueFile?: QueueFile;
};
export type CommandResultPutQueueFileProgress = {
  queueFile: QueueFile;
};
export type CommandResultGenerateNotificationOtp = {
  ticket?: string;
  ticketTTL?: number;
  purpose?: string;
  protocol?: string;
  scope?: string;
};
export const {
  useListQueuesQuery,
  useLazyListQueuesQuery,
  useCreateQueueMutation,
  useDescribeQueueQuery,
  useLazyDescribeQueueQuery,
  useUpdateQueueMutation,
  useExportQueueMutation,
  useBatchGetQueueAnnotationsMutation,
  useListQueueFilesQuery,
  useLazyListQueueFilesQuery,
  useGenerateQueueFilePreSignedLinkMutation,
  useMarkQueueFileAsGoldenMutation,
  useGetQueueFileContentsQuery,
  useLazyGetQueueFileContentsQuery,
  useUploadQueueFileMutation,
  useListQueueFileTasksQuery,
  useLazyListQueueFileTasksQuery,
  useCreateQueueFileTaskMutation,
  useDescribeQueueFileTaskQuery,
  useLazyDescribeQueueFileTaskQuery,
  useGetQueueFileTaskResultRawQuery,
  useLazyGetQueueFileTaskResultRawQuery,
  useGetQueueFileTaskResultStringDictQuery,
  useLazyGetQueueFileTaskResultStringDictQuery,
  useRestartQueueFileTaskMutation,
  useGetQueueFileAnnotationsQuery,
  useLazyGetQueueFileAnnotationsQuery,
  usePutQueueFileAnnotationsMutation,
  useGetQueueFileProgressQuery,
  useLazyGetQueueFileProgressQuery,
  usePutQueueFileProgressMutation,
  useGenerateNotificationOtpMutation,
} = injectedRtkApi;
