import { FieldValues } from 'react-hook-form/dist/types/fields';
import { CommonFormFieldProps } from '../../types';
import { DesignSystemColor, Dropzone, ErrorMessageList, FileList, Fileupload, Loader } from '@verticeone/design-system';
import { FileTransformer } from '@verticeone/design-system';
import useFiles from './useFiles';
import { Stack } from '@mui/material';
import {
  acceptedTypes,
  customErrorCodes,
  maxFileBytesSize,
  validator,
} from '../../../saas/contract/components/DocumentUpload/utils';
import { useController } from 'react-hook-form';

export type FormFileUploadFieldType<FormDataType extends FieldValues> = (
  props: FormFileUploadFieldProps<FormDataType>
) => JSX.Element;

type FormFileUploadFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  color: DesignSystemColor;
  readOnly: boolean;
  skipData: boolean;
  pathPrefix: string;
  fileTransformer?: FileTransformer;
};

export const FormFileUploadField = <FormDataType extends FieldValues = never>({
  name,
  required,
  readOnly,
  skipData,
  disabled,
  color,
  pathPrefix,
  fileTransformer,
}: FormFileUploadFieldProps<FormDataType>) => {
  const {
    field: { onChange },
  } = useController({
    name,
    rules: { required },
  });

  const { files, isFetching, uploadFiles, deleteFile, downloadFile } = useFiles({
    pathPrefix: pathPrefix ?? '',
    onChange,
    skipData,
  });

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Fileupload
        color={color}
        multiple
        hideAcceptedTypes
        acceptedTypes={acceptedTypes}
        maxFileSize={maxFileBytesSize}
        files={files ?? []}
        onAddFiles={uploadFiles}
        onRemoveFiles={deleteFile}
        validator={validator}
        customErrorCodes={customErrorCodes}
        isDisabled={disabled}
        fileTransformer={fileTransformer}
      >
        <Stack gap={6}>
          <FileList
            size="M"
            hideErrorFiles
            dynamicFileNameLength
            onFileNameClick={downloadFile}
            hiddenColumns={readOnly ? ['date', 'remove'] : ['date']}
          />
          {!readOnly && (
            <Stack height={210} alignItems="center">
              <Dropzone size="M" />
            </Stack>
          )}
          <ErrorMessageList size="S" />
        </Stack>
      </Fileupload>
    </Stack>
  );
};
