import { Text } from '@verticeone/design-system';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import backgroundPurple from './assets/background-purple.jpg';
import { useTranslation } from 'react-i18next';

export const EmptyDateHeader = () => {
  const { t } = useTranslation();

  return (
    <HighlightedCardHeader testId="card-header-purple" backgroundImage={`url(${backgroundPurple})`} noDivider>
      <Text variant="heading" size="M" color="text1" textAlign="center" width="100%">
        {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.TITLE')}
      </Text>
    </HighlightedCardHeader>
  );
};
