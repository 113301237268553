import { FC } from 'react';
import { Grid, useTheme } from '@mui/material';
import { LoadingSkeleton } from './LoadingSkeleton';
import { IconWrapper } from '@verticeone/design-system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type VariableMapperSkeletonProps = { rows?: number; fromWorkflow: boolean };

export const VariableMapperSkeleton: FC<VariableMapperSkeletonProps> = ({ rows, fromWorkflow }) => {
  const theme = useTheme();

  if (typeof rows === 'number') {
    return (
      <>
        {Array.from({ length: rows }, (_, index) => (
          <Grid key={index} container sx={{ alignItems: 'center', zIndex: 1 }}>
            <Grid item xs={fromWorkflow ? 7 : 5.5} display="grid">
              <LoadingSkeleton />
            </Grid>
            <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconWrapper icon={ArrowForwardIcon} htmlColor={theme.palette.core.color5} />
            </Grid>
            <Grid item xs={fromWorkflow ? 4 : 5.5} display="grid">
              <LoadingSkeleton />
            </Grid>
            <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}></Grid>
          </Grid>
        ))}
      </>
    );
  }
};
