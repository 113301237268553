import { MarkerType } from '@xyflow/react';
import dagre from '@dagrejs/dagre';
import { assertExhausted } from '@verticeone/utils/logic';

import { getNodeDimensions } from './NodeComponents/getNodeDimensions';
import { getDagreGraphFromWorkflowModel, getWorkflowLabelNodesWithDimensions } from './getDagreGraphFromWorkflowModel';
import type { WorkflowEdge, WorkflowLabelNode, WorkflowModel, WorkflowNodeFixedDimensions } from '../model/types';
import type { RendererEdge, RendererNode } from './types';

type RendererOptions = {
  selection?: {
    start?: boolean;
    end?: boolean;
    task?: boolean;
    gateway?: boolean;
    label?: boolean;
    edge?: boolean;
  };
};

const ID_PATTERN = /^id-(.+)-\d+$/;

const getId = (originalId: string, counter: number) => `id-${originalId}-${counter}`;

export const getOriginalNodeId = (id: string) => {
  const match = ID_PATTERN.exec(id);

  if (!match) {
    return id;
  }

  return match[1];
};

const modelNodeToRendererNode = ({
  options,
  counter,
  useDefinitionPosition = false,
  positions,
  node,
}: {
  node: WorkflowNodeFixedDimensions;
  positions: dagre.graphlib.Graph;
  counter: number;
  useDefinitionPosition: boolean;
  options?: RendererOptions;
}): RendererNode => {
  const kind = node.kind;

  let position = node.position;

  if (!useDefinitionPosition) {
    const nodeDimensions = getNodeDimensions(node);
    const dagreNodePositions = positions.node(node.id);
    position = {
      x: dagreNodePositions.x - nodeDimensions.width / 2,
      y: dagreNodePositions.y - nodeDimensions.height / 2,
    };
  }

  const id = getId(node.id, counter);

  switch (kind) {
    case 'start':
      return {
        id,
        type: 'start',
        data: node,
        position,
        selectable: options?.selection?.start ?? false,
        deletable: false,
        connectable: true,
      };
    case 'end':
      return {
        id,
        type: 'end',
        data: node,
        position,
        selectable: options?.selection?.end ?? false,
        deletable: false,
        connectable: true,
      };
    case 'task':
      return {
        id,
        type: 'task',
        data: node,
        position,
        selectable: options?.selection?.task ?? false,
        deletable: false,
        connectable: true,
      };
    case 'gateway':
      return {
        id,
        type: 'gateway',
        data: node,
        position,
        deletable: false,
        connectable: true,
        selectable: options?.selection?.gateway ?? true,
      };
    default:
      assertExhausted(kind);
      throw new Error('Unknown node kind');
  }
};

export const LABEL_NODE_HEIGHT = 24;

const modelLabelNodeToRendererLabelNode = ({
  node,
  positions,
  counter,
  useDefinitionPosition = false,
  options,
}: {
  node: WorkflowLabelNode;
  positions: dagre.graphlib.Graph;
  counter: number;
  useDefinitionPosition: boolean;
  options?: RendererOptions;
}): RendererNode => {
  let position = node.position;

  if (!useDefinitionPosition) {
    const dagreNodePositions = positions.node(node.id);
    position = {
      x: dagreNodePositions.x - node.width / 2,
      y: dagreNodePositions.y - LABEL_NODE_HEIGHT / 2,
    };
  }

  const id = getId(node.id, counter);

  return {
    id,
    type: 'label',
    data: node,
    position,
    deletable: false,
    selectable: options?.selection?.label ?? true,
  };
};

const modelEdgeToRendererEdge = ({
  edge,
  counter,
  positions,
  options,
}: {
  edge: WorkflowEdge;
  counter: number;
  positions: dagre.graphlib.Graph;
  options?: RendererOptions;
}): RendererEdge => {
  const source = getId(edge.from, counter);
  const target = getId(edge.to, counter);

  return {
    label: edge.name,
    type: 'edge',
    id: edge.id,
    source,
    target,
    selectable: options?.selection?.edge ?? true,
    deletable: false,
    data: {
      ...edge,
      points: positions.edge(edge.from, edge.to)?.points,
    },
    markerEnd: {
      type: MarkerType.Arrow,
      width: 30,
      height: 30,
      strokeWidth: 0.6,
      color: edge?.state?.selected ? '#0057fa' : edge?.state?.passed ? '#16A249' : '#94A3B8',
    },
  };
};

export const modelToRendererGraph = ({
  model,
  counter,
  useDefinitionPosition = false,
  options,
}: {
  model: WorkflowModel;
  counter: number;
  useDefinitionPosition?: boolean;
  options?: RendererOptions;
}): { nodes: RendererNode[]; edges: RendererEdge[] } => {
  const positions = getDagreGraphFromWorkflowModel(model);

  const labelNodes: WorkflowLabelNode[] = [];
  const otherNodes: WorkflowNodeFixedDimensions[] = [];

  model.nodes.forEach((node) => {
    if (node.kind === 'label') {
      labelNodes.push(node);
    } else {
      otherNodes.push(node);
    }
  });

  const rendererNodes = otherNodes.map((node) =>
    modelNodeToRendererNode({ node, positions, counter, useDefinitionPosition, options })
  );
  const rendererLabelNodes = getWorkflowLabelNodesWithDimensions(labelNodes).map((node) =>
    modelLabelNodeToRendererLabelNode({ node, positions, counter, useDefinitionPosition, options })
  );
  const edges = model.edges
    // sort edges so that passed or selected edges are always on top
    .sort(
      (a, b) =>
        Number((a.state?.passed || a.state?.selected) ?? false) -
        Number((b.state?.passed || b.state?.selected) ?? false)
    )
    .map((edge) => modelEdgeToRendererEdge({ edge, counter, positions, options }));

  return {
    nodes: [...rendererNodes, ...rendererLabelNodes],
    edges,
  };
};
