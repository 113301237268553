import { FC } from 'react';
import { FieldCard } from '../../DynamicFormBuilder';
import { RepeatingFieldMetadata, RepeatingFieldType } from './types';
import { RepeatingFieldSchemaEditor } from './components/RepeatingFieldSchemaEditor';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { PrimitiveDynamicFormField } from '../../primitiveTypes';
import { Text } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Identification = {
  id?: string;
};

export type RepeatingFieldBuilderCardProps = {
  field: RepeatingFieldType & Identification;
  onRemove?: () => void;
  onEdit?: () => void;
  onChange?: (metadata: RepeatingFieldMetadata) => void;
};

export const RepeatingFieldBuilderCard: FC<RepeatingFieldBuilderCardProps> = (props) => {
  const { field, onRemove, onEdit, onChange } = props;
  const { t } = useTranslation();

  const handleFieldsChange = (values: PrimitiveDynamicFormField[]) => {
    onChange?.({ ...field.metadata, fields: values });
  };

  return (
    <FieldCard>
      <FieldCard.Header>
        <FieldCard.Header.Label
          fieldType={field.type}
          required={field.metadata.requiredItems > 0}
          rightAdornment={
            <Stack textAlign="right" justifyContent="center" gap={1}>
              <Text variant="label" size="XXS" color="text2">
                {`${t('DYNAMIC_FORM_BUILDER.FIELD.REPEATING.METADATA.MINIMUM')}: ${field.metadata.requiredItems}`}
              </Text>
              <Text variant="label" size="XXS" color="text2">
                {`${t('DYNAMIC_FORM_BUILDER.FIELD.REPEATING.METADATA.MAXIMUM')}: ${field.metadata.limit}`}
              </Text>
            </Stack>
          }
        >
          {field.metadata.label}
        </FieldCard.Header.Label>
        <FieldCard.Header.Actions>
          <FieldCard.EditAction onClick={onEdit} />
          <FieldCard.RemoveAction onClick={onRemove} />
        </FieldCard.Header.Actions>
      </FieldCard.Header>
      {field.id && (
        <FieldCard.Body>
          <RepeatingFieldSchemaEditor
            name={field.name}
            onFieldsSchemaChange={handleFieldsChange}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            fieldsDefinition={field.metadata.fields ?? []}
          />
        </FieldCard.Body>
      )}
    </FieldCard>
  );
};
