import { z, ZodObject, ZodRawShape } from 'zod';
import { NumberFieldMetadata, NumberFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';
import { isNil } from 'lodash';

export function getNumberPresenterSchema(field: NumberFieldType): ZodObject<ZodRawShape> {
  let schema = z.number();

  return z.object({
    [field.name]: field.metadata.required ? schema : schema.nullish(),
  });
}

export function getNumberPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<NumberFieldType>) {
  if (defaultValues && !isNil(defaultValues[field.name])) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getNumberJsonSchemaFromMetadata(metadata: NumberFieldMetadata): JsonSchema {
  return { type: metadata.required ? ['number'] : ['number', 'null'], title: metadata.label };
}

export function getNumberJsonSchema(field: NumberFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getNumberJsonSchemaFromMetadata(field.metadata) },
  };
}
