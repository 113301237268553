import React, { FC, useEffect, useMemo, useState } from 'react';
import { workflowDefinitionsToModel } from '../model/workflowDefinitionsToModel';
import { useRequestState } from './useRequestState';
import { useServicesThumbnails } from './useServicesThumbnails';
import { useAccountContext } from '../../../account/AccountContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useLoggedUser } from '@verticeone/auth/src';
import { WorkflowRendererContextProvider } from '../WorkflowRenderer/WorkflowRendererContext';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { parseUserRef } from '../../../../hooks/workflows/refUtils';
import { useRequestTasks } from '../../request/RequestDetailPage/useRequestTasks';
import { TaskDrawer } from '../../task/TaskDrawer/TaskDrawer';
import { useSearchParams } from 'react-router-dom';
import { WorkflowRenderer } from '../WorkflowRenderer/WorkflowRenderer';
import { withWorkflowRenderer } from '../WorkflowRenderer/useWorkflowRendererState';
import { WorkflowDefinitions } from '../types';
import useLoggedUserAccountRoles from '../../../../hooks/useLoggedUserAccountRoles';

type WorkflowViewerProps = {
  workflowDefinitions: WorkflowDefinitions;
  requestId?: string;
  requestRoute?: string;
  allowVerticeServiceNavigation?: boolean;
};

const BaseWorkflowViewer: FC<WorkflowViewerProps> = (props) => {
  const { workflowDefinitions, requestRoute, requestId, allowVerticeServiceNavigation } = props;
  const { accountId } = useAccountContext();
  const { userId: loggedUserId } = useLoggedUser();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const requestWorkflowState = useRequestState(requestId, requestRoute);

  const servicesThumbnails = useServicesThumbnails();

  const model = useMemo(() => {
    return workflowDefinitionsToModel({
      workflowDefinitions,
      workflowState: requestWorkflowState,
      servicesThumbnails,
    });
  }, [requestWorkflowState, servicesThumbnails, workflowDefinitions]);

  // users
  const { usersById: accountUsersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const { allTasks } = useRequestTasks(requestId, true);
  const tasksAssignees = useMemo(() => {
    return allTasks.flatMap((task) => task.assignment.map((ref) => parseUserRef(ref).userId));
  }, [allTasks]);
  const { usersById } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  //  drawer
  const [drawerTaskId, setDrawerTaskId] = useState<string | undefined>(undefined);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const taskId = searchParams.get('taskId');
    if (taskId) {
      setDrawerTaskId(taskId);
      setSearchParams({}, { replace: true });
    }
  }, [searchParams, setDrawerTaskId, setSearchParams]);

  const onOpenTask = (taskId: string) => {
    setDrawerTaskId(taskId);
  };
  const drawerTask = useMemo(() => {
    const tasksForGivenTaskId = allTasks.filter((row) => drawerTaskId && row.id.includes(drawerTaskId));

    if (!tasksForGivenTaskId.length) {
      return undefined;
    }

    if (tasksForGivenTaskId.length === 1) {
      return tasksForGivenTaskId[0];
    }

    return tasksForGivenTaskId.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
  }, [allTasks, drawerTaskId]);

  if (!model) {
    return null;
  }

  return (
    <WorkflowRendererContextProvider
      value={{
        model,
        usersById,
        isEditor: false,
        isDragAndDropEnabled: false,
        loggedUserId,
        accountId,
        onOpenTask,
        allowVerticeServiceNavigation,
        workflowDefinitions,
        drawerTaskId,
        isLoggedUserAdmin: isUserAdmin,
      }}
    >
      <WorkflowRenderer key={requestId} />
      {drawerTask ? <TaskDrawer onClose={() => setDrawerTaskId(undefined)} taskRow={drawerTask} /> : null}
    </WorkflowRendererContextProvider>
  );
};

// eslint-disable-next-line testing-library/render-result-naming-convention
export const WorkflowViewer = withWorkflowRenderer(BaseWorkflowViewer);
