import { WizardStepProps } from '../../../../../components/Wizard';

export const JUMPCLOUD_INTEGRATION_ID = 'Provider:JumpCloud';

export const STEPS = {
  START: 'start',
  DATA_SOURCES: 'data_sources',
  COLLECT_API_KEYS: 'collect_api_keys',
  CHECK_CONNECTION: 'check_connection',
  CONNECTION_ERROR: 'connection_error',
  CONNECTION_SUCCESS: 'connection_success',
};

export type ConnectionFinishedStepProps = WizardStepProps & {
  accountId: string;
  testingInProgress: boolean;
  setTestingInProgress: (testingInProgress: boolean) => void;
};
