import { FieldDefPreset } from './types';
import { fieldDefs, legacyDatasourcedFieldDefs } from './fields';
import { fieldPresets as dataSourcedFieldPresets } from './DataSourcedField/fieldPresets';
import { FEATURES } from '../../../features/constants';
import { DynamicFormField } from '../types';

export type FieldDefPresetsOptions = {
  dataSourcedFieldsEnabled: boolean;
  repeatingFieldEnabled: boolean;
};

export const getFieldDefPresetsOptionsFromFeatures = (
  getFeatureProperty: <T>(feature: string, property: string) => T | null
): Omit<FieldDefPresetsOptions, 'repeatingFieldEnabled'> => ({
  dataSourcedFieldsEnabled:
    getFeatureProperty<boolean>(FEATURES.INTELLIGENT_WORKFLOWS, 'workflowsDatasourceFields') ?? false,
});

export const getFieldDefPresets = (features: FieldDefPresetsOptions): FieldDefPreset<DynamicFormField>[] => [
  ...[
    ...(features.repeatingFieldEnabled ? fieldDefs : fieldDefs.filter(({ type }) => type !== 'REPEATING')),
    ...(features.dataSourcedFieldsEnabled ? [] : legacyDatasourcedFieldDefs),
  ].map(({ type, typeNameI18nKey }) => ({ key: type, fieldType: type, nameI18nKey: typeNameI18nKey })),
  ...(features.dataSourcedFieldsEnabled ? dataSourcedFieldPresets : []),
];
