import { Stack } from '@mui/material';
import AnimatedTextNumber from '../AnimatedTextNumber';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartStatsWrapper, ChartStatBar } from '../ChartCard';
import LegendItem from '@vertice/core/src/components/charts/components/Legend/LegendItem';
import { StatItemType } from '../../types';
import { useFormatDuration } from './utils';
import { Placeholder } from '@verticeone/design-system';

const StatItemWrapper = ({ children }: { children: ReactNode }) => {
  return <Stack gap="2px">{children}</Stack>;
};

export type ChartStatLegendProps = {
  items?: StatItemType[];
};

const StatItem = ({ id, value, name, color }: StatItemType) => {
  const formatDuration = useFormatDuration();

  return (
    <Stack gap={2} direction="row" justifyContent="space-between">
      <LegendItem label={name} id={id} color={color} />
      <AnimatedTextNumber variant="body-bold" size="S" color="text1">
        {formatDuration(value)}
      </AnimatedTextNumber>
    </Stack>
  );
};

const ChartStatLegend = ({ items }: ChartStatLegendProps) => {
  const hasItems = items && items.length > 0;
  if (!hasItems) {
    return null;
  }

  return (
    <Stack gap={2} direction="column">
      {items.map((item) => (
        <StatItem key={item.id} {...item} />
      ))}
    </Stack>
  );
};

type AverageRequestTimesStatsProps = {
  total: number;
  averageDurationInHours: number;
  items?: StatItemType[];
};

const AverageRequestTimesStats = ({ total, averageDurationInHours, items }: AverageRequestTimesStatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const formatDuration = useFormatDuration();

  return (
    <ChartStatsWrapper flex={1} justifyContent="flex-end">
      <Stack gap={4}>
        <StatItemWrapper>
          <AnimatedTextNumber tag="div" variant="heading" size="M" color="text1">
            {formatDuration(averageDurationInHours)}
          </AnimatedTextNumber>
          {t('AVERAGE_REQUEST_TIMES.AVERAGE_TIME_TO_COMPLETE')}
        </StatItemWrapper>
      </Stack>
      <ChartStatBar height={12} total={total} items={items} />
      <ChartStatLegend items={items} />
    </ChartStatsWrapper>
  );
};

const Skeleton = () => (
  <ChartStatsWrapper flex={1} justifyContent="flex-end">
    <Stack gap={4}>
      <StatItemWrapper>
        <Placeholder variant="text" width="64px" height={28} />
        <Placeholder variant="text" width="128px" height={14} />
      </StatItemWrapper>
    </Stack>
    <ChartStatBar.Skeleton />
    <Stack gap={2} direction="column">
      {Array(2)
        .fill(null)
        .map((_, index) => (
          <Stack key={index} gap={2} direction="row" justifyContent="space-between">
            <Placeholder variant="rectangular" sx={{ borderRadius: 1 }} width={14} height={14} />
            <Placeholder variant="text" width="80%" height={14} />
            <Placeholder variant="text" width="20%" height={14} />
          </Stack>
        ))}
    </Stack>
  </ChartStatsWrapper>
);

AverageRequestTimesStats.Skeleton = Skeleton;

export { AverageRequestTimesStats };
