import { EmailField } from '@vertice/core/src/components/EmailField';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import { useCreateExistingContract } from './useCreateExistingContract';

const useAdditionalInfoWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('contract_added'),
  };
};

const AdditionalInformationStep: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { t } = useTranslation();

  const { trigger, watch } = useFormContext<AddExistingContractFormData>();
  const validationFields = watch(['signatoryEmail', 'vendorContactEmail']);

  const { goBack, goToNext } = useAdditionalInfoWizard();

  const [createExistingContract, isCreatingExistingContract] = useCreateExistingContract(goToNext);

  const handleCreateExistingContract = () => {
    createExistingContract();
  };

  useEffect(() => {
    void trigger(['signatoryEmail', 'vendorContactEmail']).then(setNextButtonEnabled);
  }, [trigger, validationFields]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_ADDITIONAL_INFORMATION.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_ADDITIONAL_INFORMATION.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel />
      <InnerScrollable>
        <FormSection>
          <AddExistingContractFormEntry
            name="signatoryName"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_NAME'),
            }}
            component={FormTextField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_NAME') }}
          />
          <AddExistingContractFormEntry
            name="signatoryEmail"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_EMAIL'),
            }}
            component={EmailField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_EMAIL') }}
          />
          <AddExistingContractFormEntry
            name="signingEntity"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNING_ENTITY'),
            }}
            component={FormTextField}
            width={12}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNING_ENTITY') }}
          />
          <AddExistingContractFormEntry
            name="vendorContactName"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_NAME')}
            component={FormTextField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR_CONTACT_NAME') }}
          />
          <AddExistingContractFormEntry
            name="vendorContactEmail"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_EMAIL')}
            component={EmailField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR_CONTACT_EMAIL') }}
          />
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: handleCreateExistingContract,
          isLoading: isCreatingExistingContract,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled,
        }}
      />
    </Content>
  );
};

export default AdditionalInformationStep;
