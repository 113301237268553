import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { LineItem, LineItemType } from './../types';
import { TextField, useDataGridContext } from '@verticeone/design-system';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInputFocusableRef } from '../../../../../../hooks/useInputFocusableRef';

export const EditableQuantityCell = ({ id, value, field, hasFocus }: GridCellParams<LineItem, LineItemType>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.LINE_ITEMS_TABLE' });
  const apiRef = useGridApiContext();
  const { color } = useDataGridContext();
  const ref = useInputFocusableRef({ hasFocus });

  return (
    <Box px={4}>
      <TextField
        ref={ref}
        variant="ghost"
        hiddenLabel
        value={value ?? ''}
        placeholder={t('QUANTITY')}
        onChange={(e) => {
          const newValue = e.target.value;
          const digitsOnly = /^[0-9]*$/.test(newValue);
          if (digitsOnly) {
            const parseResult = parseInt(newValue, 10);
            void apiRef.current.setEditCellValue({ id, field, value: isNaN(parseResult) ? null : parseResult });
          }
        }}
        color={color}
      />
    </Box>
  );
};
