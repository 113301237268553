import { FieldMapping } from '../../../definitionsTypes';
import { VariableMapperRowFormBase } from './formSchema';
import { Variable } from '../types';
import { composeVariableDefaults, createVariable, uniqueNameWithNumber } from '../VariableSelector/customVariableUtils';
import { findIndex } from 'lodash';
import { EnhanceWithDefaultParam, RequiredField } from './types';

/**
 * Map workflow type variables into object used in variable mapper
 */
export const workflowToFormVariables = (workflowVariables: FieldMapping[], fromWorkflow: boolean) => {
  const formVariables: VariableMapperRowFormBase[] = workflowVariables.map((inputField) => ({
    from: fromWorkflow ? inputField.value : inputField.name,
    to: fromWorkflow ? inputField.name : inputField.value,
    mappingType: inputField.mappingType,
  }));

  return formVariables;
};

export const getVariableBaseType = (variables: Variable[], variableId: string) => {
  return variables.find((variable) => variable.id === variableId)?.type.baseType?.[0];
};

export const getVariableXType = (variables: Variable[], variableId: string) => {
  return variables.find((variable) => variable.id === variableId)?.type.xType;
};

export const getVariableXDataSourceParams = (variables: Variable[], variableId: string) => {
  const variable = variables.find((v) => v.id === variableId);
  const key = 'dataSourceParams' as keyof Variable;
  if (variable && variable[key]) {
    return variable[key] as Record<string, any>;
  }
};

const createMapperRowData = (requiredField: RequiredField, additionalParams: EnhanceWithDefaultParam) => {
  let newWorkflowVariable: Variable | undefined;
  // for selected external variable, precreate new workflow variable with corresponding type
  if (requiredField.preCreateWfVariable && 'task' in additionalParams) {
    const { task, externalVariables, variablesGroups } = additionalParams;
    const externalVariable = externalVariables.find((exVariable) => exVariable.id === requiredField.variableId);

    // If external variable couldn't be found, we cannot base new workflow on that specified -> end
    if (!externalVariable) return;

    const addVariableDefaults = composeVariableDefaults(externalVariable, task, 'vertice-task-output-mapping');
    const fieldName = externalVariable.label;
    const name = uniqueNameWithNumber(`${task.name} ${fieldName}`, variablesGroups);
    newWorkflowVariable = createVariable(addVariableDefaults, name);
  }
  const workflowVariableId = newWorkflowVariable ? newWorkflowVariable.id : '';
  const mapperRowData = {
    from: requiredField.whereRequired === 'from' ? requiredField.variableId : workflowVariableId,
    to: requiredField.whereRequired === 'to' ? requiredField.variableId : workflowVariableId,
    mappingType: requiredField.mappingType,
  };

  return { mapperRowData, newWorkflowVariable };
};

/**
 * Enhance array of VariableMapperRowFormBase of required ids, if they are not already used.
 * If workflow variables should also be created, they are created and tey id is used in that VariableMapperRowFormBase object. But to be then showed by picker
 * local workflowVariables request  must be updated by those newly created variable objects.
 */
export const enhanceWithRequired = (params: EnhanceWithDefaultParam) => {
  const { formVariables, requiredFields } = params;
  const newWorkflowVariables: Variable[] = [];
  const requiredFormVariables: VariableMapperRowFormBase[] = [];
  const requiredIndexes = new Set<number>();

  for (const requiredField of requiredFields) {
    const existingIndex = findIndex(
      formVariables,
      (formVariable) => formVariable[requiredField.whereRequired] === requiredField.variableId
    );
    if (existingIndex > -1) {
      requiredFormVariables.push(formVariables[existingIndex]);
      requiredIndexes.add(existingIndex);
    } else {
      // Required variable was not yet used
      const createMapperRowDataResult = createMapperRowData(requiredField, params);
      if (createMapperRowDataResult) {
        const { mapperRowData, newWorkflowVariable } = createMapperRowDataResult;
        requiredFormVariables.push(mapperRowData);
        if (newWorkflowVariable) {
          newWorkflowVariables.push(newWorkflowVariable);
        }
      }
    }
  }

  // separate rest of variables which are not in required group
  const restFormVariables = formVariables.filter((_, index) => !requiredIndexes.has(index));

  return { requiredFormVariables, restFormVariables, newWorkflowVariables };
};

export const getRowsCount = (variables: FieldMapping[], defaultIds: string[], whereDefault: 'from' | 'to') => {
  let initialVariableIds = workflowToFormVariables(variables, whereDefault !== 'from').map(
    (variable) => variable[whereDefault]
  );
  const notUsed = defaultIds.filter((defaultId) => !initialVariableIds.includes(defaultId));
  return initialVariableIds.length + notUsed.length;
};
