import { Divider } from '@verticeone/design-system';
import { Grid } from '@verticeone/design-system';
import { NegotiatedTotalCostFormEntry } from '../../form/NegotiatedTotalCostFormEntry';
import { NegotiatedTotalCostOverrideFormEntry } from '../../form/NegotiatedTotalCostOverrideFormEntry';
import { BaselineTotalCostFormEntry } from '../../form/BaselineTotalCostFormEntry';
import { BaselineTotalCostOverrideFormEntry } from '../../form/BaselineTotalCostOverrideFormEntry';
import { TotalSavingsContractFormEntry } from '../../form/TotalSavingsContractFormEntry';

/**
 * Shows sections, where are contract total costs. Meaning the negotiated offer,
 * baseline offer from Vendor and the difference. This should be visible for
 * one off contracts.
 */
export const TotalCostDetails = () => {
  return (
    <>
      <Grid container direction="row" spacing={8} px={6}>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <NegotiatedTotalCostFormEntry />
          <NegotiatedTotalCostOverrideFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <BaselineTotalCostFormEntry />
          <BaselineTotalCostOverrideFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <TotalSavingsContractFormEntry />
        </Grid>
      </Grid>

      <Divider />
    </>
  );
};
