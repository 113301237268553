import { z } from 'zod';

import { reviewRelationshipSchema } from './formSteps/ReviewRelationshipFormStep/schema';

export const assessmentContextSchema = z.object({
  reviewRelationship: reviewRelationshipSchema,
  businessCase: z.record(z.boolean()),
  dataTypes: z.record(z.boolean()),
});

export type AssessmentContextFormData = z.infer<typeof assessmentContextSchema>;
