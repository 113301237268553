import React, { FC } from 'react';
import { useServiceWorkflows } from '../ServiceDetailPage/useServiceWorkflows';
import { Stack } from '@mui/material';
import { Button, IconButton, Tooltip } from '@verticeone/design-system';
import { VisibilityOutlined } from '@mui/icons-material';
import { WorkflowPreviewDialog } from '../../workflow/WorkflowDetailPage/components/WorkflowPreviewDialog';
import { useTranslation } from 'react-i18next';
import type { ServiceCatalogResource } from '../../catalogResource/types';

type CardActionProps = {
  service: ServiceCatalogResource;
  isPreviewOpen?: boolean;
  onManageClick: () => void;
  onPreviewClick: (value: boolean) => void;
};

export const CardActions: FC<CardActionProps> = ({ service, onManageClick, isPreviewOpen = false, onPreviewClick }) => {
  const { t } = useTranslation();
  const defaultWorkflowRef = service?.definition?.Service?.ServiceProvider?.HandlerUrn;

  const { defaultWorkflow, isLoadingWorkflows } = useServiceWorkflows({ serviceRef: service?.urn, defaultWorkflowRef });

  // "EDIT LATEST" functionality removed temporarily. Remove in the future if it hasn't been re-enabled
  // const { navigate } = useRouteNavigate();
  // const routes = useRoutes();
  // const { workflowVersions, isLoading: isLoadingWorkflowVersions } = useWorkflowVersions(defaultWorkflow?.id);
  // const activeWorkflowVersion =
  //   workflowVersions && workflowVersions.ACTIVE && workflowVersions.ACTIVE.length > 0
  //     ? workflowVersions.ACTIVE[0]
  //     : undefined;
  // const { CreateDraftDialog, handleCreateDraftFromActive } = useCreateDraftDialog(activeWorkflowVersion, service);
  // const handleEditLatestWorkflow = () => {
  //   if (workflowVersions.PENDING && workflowVersions.PENDING.length > 0) {
  //     const latestDraftWorkflow = workflowVersions.PENDING.sort(
  //       (a, b) => new Date(b.updatedAt ?? '').getTime() - new Date(a.updatedAt ?? '').getTime()
  //     )[0];
  //
  //     return navigate(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.VERSION.EDITOR, {
  //       serviceId: createServiceIdentifier(service.urn),
  //       workflowId: latestDraftWorkflow.id,
  //       versionId: latestDraftWorkflow.versionId,
  //     });
  //   }
  //
  //   handleCreateDraftFromActive();
  // };

  const handlePreviewClick = (value: boolean) => {
    onPreviewClick(value);
  };

  return (
    <Stack direction="row" gap={1}>
      <Button
        isLoading={isLoadingWorkflows}
        onClick={onManageClick}
        fullWidth
        variant="ghost"
        color="primary"
        disabled={isLoadingWorkflows}
      >
        {t('ENTITIES.SERVICE.ACTIONS.MANAGE')}
      </Button>
      <Stack direction="row" gap={1}>
        <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.PREVIEW')} minified noArrow size="S">
          <Stack>
            <IconButton
              icon={VisibilityOutlined}
              variant="outline"
              disabled={isLoadingWorkflows || !defaultWorkflow}
              onClick={() => handlePreviewClick(true)}
            />
          </Stack>
        </Tooltip>
        {/*<Tooltip title={t('ENTITIES.SERVICE.ACTIONS.EDIT_LATEST')} minified noArrow size="S">*/}
        {/*  <Stack>*/}
        {/*    <IconButton*/}
        {/*      icon={BuildOutlined}*/}
        {/*      onClick={handleEditLatestWorkflow}*/}
        {/*      variant="outline"*/}
        {/*      disabled={isLoadingWorkflows || isLoadingWorkflowVersions}*/}
        {/*      isLoading={isLoadingWorkflows || isLoadingWorkflowVersions}*/}
        {/*    />*/}
        {/*  </Stack>*/}
        {/*</Tooltip>*/}
      </Stack>
      {defaultWorkflow?.activeVersionId && isPreviewOpen && (
        <WorkflowPreviewDialog
          serviceRef={service.urn}
          workflowId={defaultWorkflow.id}
          versionId={defaultWorkflow.activeVersionId}
          onClose={() => handlePreviewClick(false)}
        />
      )}
      {/*{CreateDraftDialog}*/}
    </Stack>
  );
};
