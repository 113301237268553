import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

export const ContentDescriptionDefault = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Text testId="card-description-title" variant="body-bold" color="text1" textAlign="center">
        {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.CONTENT.DEFAULT.TITLE')}
      </Text>
      <Text testId="card-description-text" variant="body-regular" color="text3" textAlign="center">
        {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.CONTENT.DEFAULT.DESCRIPTION')}
      </Text>
    </Stack>
  );
};
