import { Placeholder, Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import React, { FunctionComponent, PropsWithChildren, SVGProps } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import ListItemBase from './ListItemBase';

type ListItemProps = {
  title: string;
  description: string;
  days: number;
  overdueDays: number;
  isLoading?: boolean;
  vendorId?: string;
  icon?: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick?: () => void;
};

const StyledStatusChip = styled(Stack)(({ theme }) => ({
  height: '19px',
  paddingRight: '6px',
  paddingLeft: '6px',
  borderRadius: 4,
}));

type StatusChipProps = PropsWithChildren & {
  variant: 'secondary' | 'error';
};

const StatusChip = ({ children, variant }: StatusChipProps) => {
  const { palette } = useTheme();

  const colorByVariant = {
    secondary: {
      color: 'secondary1',
      bgColor: palette.secondary.color4,
    },
    error: {
      color: 'error4',
      bgColor: palette.error.color1,
    },
  };

  return (
    <StyledStatusChip bgcolor={colorByVariant[variant].bgColor} alignItems="center" justifyContent="center">
      <Text variant="body-bold" size="XS" color={colorByVariant[variant].color}>
        {children}
      </Text>
    </StyledStatusChip>
  );
};

const ListItem = ({ title, description, vendorId, days, overdueDays, onClick }: ListItemProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.TASK_STATUS' });

  return (
    <ListItemBase
      title={
        <Text variant="body-bold" size="S">
          {title}
        </Text>
      }
      description={
        <Text variant="body-regular" size="XS" color="text3">
          {description || <>&nbsp;</>}
        </Text>
      }
      icon={<VendorLogo vendorId={vendorId ?? undefined} size={40} />}
      chip={
        <Stack direction="row" gap={1} justifyContent="flex-end" flexWrap={'wrap'}>
          {overdueDays > 0 ? (
            <StatusChip variant="error">{t('OVERDUE_X_DAYS', { days: overdueDays })}</StatusChip>
          ) : (
            <StatusChip variant="secondary">{t('ACTIVE_X_DAYS', { days })}</StatusChip>
          )}
        </Stack>
      }
      onClick={onClick}
    />
  );
};

const Skeleton = () => (
  <ListItemBase
    title={<Placeholder variant="text" width="80%" height={19} />}
    description={<Placeholder variant="text" width="64px" height={12} />}
    icon={<Placeholder variant="rounded" width={40} height={40} />}
    chip={<Placeholder sx={{ borderRadius: 1 }} variant="rectangular" width="20%" height={19} />}
  />
);

ListItem.Skeleton = Skeleton;

export { ListItem };
