import { nonEmptyString } from '@verticeone/utils/validation';
import { z } from 'zod';

export type LineItemType = 'ITEM' | 'SERVICE';
export const allLineItemTypes: LineItemType[] = ['ITEM', 'SERVICE'];

export const lineItemSchema = z.object({
  id: nonEmptyString,
  lineItemType: z.custom<LineItemType>((val) => allLineItemTypes.includes(val) ?? false),
  description: z.string().nullable(),
  quantity: z.number().nullable(),
});

export type LineItem = z.infer<typeof lineItemSchema>;

export type LineItemsValue = LineItem[];

export enum LineItemsTableColumn {
  LINE_ITEM_TYPE = 'lineItemType',
  DESCRIPTION = 'description',
  QUANTITY = 'quantity',
}
