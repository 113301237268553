import { maxBy, minBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { QUERY_KEY as CONTRACT_LIST_QUERY_KEY } from './useContractListData';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useValidationErrorHandler from './useValidationErrorHandler';

export type ContractYear = {
  commitment: number;
  discount: number;
  endDate: string;
  startDate: string;
};

type UseContractCreateProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', () => void>>;

export const QUERY_KEY = 'EDPContractCreate' as const;

const EDPContractCreateQuery = graphql(`
  mutation EDPContractCreate($accountId: ID!, $costModel: CostModelInput!) {
    createContract(params: { accountId: $accountId, costModel: $costModel }) {
      ... on EDPContract {
        __typename
        costModel {
          startDate
          endDate
          costAllocationSpans {
            startDate
            endDate
            commitment
            discount
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
      ... on EDPValidationError {
        __typename
        context
        code
        fieldName
      }
    }
  }
`);

export const useContractCreate = (options?: UseContractCreateProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const handleValidationError = useValidationErrorHandler();
  const { fetchCloudOptimization } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (newContractYears: Array<ContractYear>) => {
      return fetchCloudOptimization(EDPContractCreateQuery, {
        accountId,
        costModel: {
          startDate: minBy(newContractYears, (year) => new Date(year.startDate).getTime())!.startDate,
          endDate: maxBy(newContractYears, (year) => new Date(year.endDate).getTime())!.endDate,
          costAllocationSpans: newContractYears.map((year, index) => ({
            startDate: year.startDate,
            endDate: year.endDate,
            commitment: year.commitment,
            discount: year.discount,
            name: t('TERM', { term: index + 1 }),
            spanLength: dayjs(year.endDate).add(1, 'day').diff(dayjs(year.startDate), 'month'),
          })),
        },
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: (response) => {
      if (
        (response?.createContract?.__typename === 'ErroredQueryResult' ||
          response?.createContract?.__typename === 'EDPValidationError') &&
        handleValidationError(response.createContract)
      ) {
        options?.onError?.();
        return;
      }

      enqueueSnackbar({
        variant: 'success',
        message: t('ALERT.CONTRACT_SAVED'),
      });

      setTimeout(async () => {
        await queryClient.invalidateQueries({ queryKey: [CONTRACT_LIST_QUERY_KEY] });
        options?.onSuccess?.();
      }, 2000);
    },
    onError: (errors: Array<Error>) => {
      errors.forEach((e) =>
        enqueueSnackbar({
          variant: 'error',
          message: e.message,
        })
      );
      options?.onError?.();
    },
  });
};
