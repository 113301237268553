import React, { FC, MouseEvent, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { AssignRequestOwnerDialog } from './AssignRequestOwnerDialog';
import { TerminateRequestDialog } from './TerminateRequestDialog';
import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAuthorizer, useLoggedUser } from '@verticeone/auth/src';
import { parseUserRef } from '../../../../../hooks/workflows/refUtils';
import useLoggedUserAccountRoles from '../../../../../hooks/useLoggedUserAccountRoles';
import { useRequestEdit } from '../useRequestEdit';
import { useRequestContext } from '../RequestContext';
import { useAccountContext } from '../../../../account/AccountContext';

type RequestHeaderMenuProps = {
  request: Request;
};

export const RequestHeaderMenu: FC<RequestHeaderMenuProps> = ({ request }) => {
  const { t } = useTranslation();
  const { userId } = useLoggedUser();
  const { accountId } = useAccountContext();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const { applicationIdentifier } = useRequestContext();

  const { isLoading, isAllowed: canAssignOwner } = useAuthorizer({
    id: 'RequestHeaderMenu',
    object: `urn:verticeone:vertice:${accountId}:services:request/*`,
    action: 'request:UpdateOwner',
  });

  const isRequestOwner = request.owner && parseUserRef(request.owner).userId === userId;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  // Rename dialog
  const { Component: RequestNameDialog, open: openRequestNameDialog } = useRequestEdit(request.ref, request.name);
  const handleRenameRequestMenuItemClick = () => {
    openRequestNameDialog();
    closeMenu();
  };
  const canEditRequestName = isRequestOwner || isUserAdmin;

  // Assign owner dialog
  const [assignOwnerDialogOpened, setAssignOwnerDialogOpened] = useState(false);
  const openAssignOwnerDialog = () => setAssignOwnerDialogOpened(true);
  const closeAssignOwnerDialog = () => setAssignOwnerDialogOpened(false);
  const handleChangeOwnerMenuItemClick = () => {
    openAssignOwnerDialog();
    closeMenu();
  };

  // Terminate request dialog
  const [terminateRequestOpen, setTerminateRequestDialogOpened] = useState(false);
  const openTerminateRequestDialog = () => setTerminateRequestDialogOpened(true);
  const closeTerminateRequestDialog = () => setTerminateRequestDialogOpened(false);
  const handleTerminateRequestMenuItemClick = () => {
    openTerminateRequestDialog();
    closeMenu();
  };
  const canTerminateRequest =
    applicationIdentifier === 'IAT' && Array<Request['status']>('ACTIVE', 'PENDING').includes(request.status);

  if (!canEditRequestName && (!canAssignOwner || isLoading) && !canTerminateRequest) {
    return null;
  }

  return (
    <>
      <IconButton icon={MoreVertIcon} onClick={openMenu} variant="plain" size="XL" />
      <Menu anchorEl={anchorEl} open={menuOpened} onClose={closeMenu}>
        {canAssignOwner && (
          <MenuItem onClick={handleChangeOwnerMenuItemClick}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.ACTIONS.CHANGE_OWNER')}
          </MenuItem>
        )}
        {canTerminateRequest && (
          <MenuItem onClick={handleTerminateRequestMenuItemClick}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.ACTIONS.TERMINATE_REQUEST')}
          </MenuItem>
        )}
        {canEditRequestName && (
          <MenuItem onClick={handleRenameRequestMenuItemClick}>
            {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.ACTIONS.CHANGE_REQUEST_NAME')}
          </MenuItem>
        )}
      </Menu>
      <AssignRequestOwnerDialog
        key={request.owner ?? 'no-owner'}
        request={request}
        isOpened={assignOwnerDialogOpened}
        onClose={closeAssignOwnerDialog}
      />
      {canTerminateRequest && (
        <TerminateRequestDialog
          request={request}
          isOpened={terminateRequestOpen}
          onClose={closeTerminateRequestDialog}
        />
      )}
      <RequestNameDialog />
    </>
  );
};
