import { Stack } from '@mui/material';
import { Button, Divider } from '@verticeone/design-system';
import { FC, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { ContractReviewForm } from '../../types';
import { SummarySection } from '../../../components/SummarySection';
import { useDocumentsSummaryRecord } from './hooks/useDocumentsSummaryRecord';
import { useDetailsSummaryRecords, useLifecycleSummaryRecords, useLineItemsSummaryRecords } from './hooks';
import { CostModelLineItemsTable } from '../../../../../../saas/contract/components/CostModelLineItemsTable';
import { getRollingFreqFromContractForm } from '../../utils/getRollingFreqFromContractForm';

export type ContractSummaryFormStepProps = {
  values: ContractReviewForm;
  onSubmit: () => Promise<void>;
  onPrevious: () => void;
};

export const ContractSummaryFormStep: FC<ContractSummaryFormStepProps> = (props) => {
  const { onSubmit, onPrevious, values } = props;

  const { t } = useTranslation();

  const { handleSubmit, formState } = useForm();

  const { records: lifecycleRecords } = useLifecycleSummaryRecords({ values });
  const { records: detailsRecords } = useDetailsSummaryRecords({ values });
  const { record: documentsRecord } = useDocumentsSummaryRecord();
  const { records: lineItemsRecords } = useLineItemsSummaryRecords({ values });

  return (
    <Stack component="form" flexGrow={1} onSubmit={handleSubmit(onSubmit)}>
      <Stack p={6} flexGrow={1} gap={12} sx={{ overflow: 'auto' }}>
        <SummarySection>
          <SummarySection.Header>
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.CONTRACT_DETAILS')}
          </SummarySection.Header>
          <SummarySection.Body>
            {detailsRecords.map(({ label, value }) => (
              <Fragment key={label}>
                <SummarySection.Label>{label}</SummarySection.Label>
                <SummarySection.Value>{value}</SummarySection.Value>
              </Fragment>
            ))}
          </SummarySection.Body>
        </SummarySection>

        <SummarySection>
          <SummarySection.Header>
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.CONTRACT_DATES')}
          </SummarySection.Header>
          <SummarySection.Body>
            {lifecycleRecords.map(({ label, value }) => (
              <Fragment key={label}>
                <SummarySection.Label>{label}</SummarySection.Label>
                <SummarySection.Value>{value}</SummarySection.Value>
              </Fragment>
            ))}
          </SummarySection.Body>
        </SummarySection>

        <SummarySection>
          <SummarySection.Header>
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.DOCUMENTS')}
          </SummarySection.Header>
          <SummarySection.Body>
            <SummarySection.Label>{documentsRecord.label}</SummarySection.Label>
            <SummarySection.Value>{documentsRecord.value}</SummarySection.Value>
          </SummarySection.Body>
        </SummarySection>

        <SummarySection>
          <SummarySection.Header>
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.LINE_ITEMS')}
          </SummarySection.Header>
          <SummarySection.Body>
            {lineItemsRecords.map(({ label, value }) => (
              <Fragment key={label}>
                <SummarySection.Label>{label}</SummarySection.Label>
                <SummarySection.Value>{value}</SummarySection.Value>
              </Fragment>
            ))}
          </SummarySection.Body>
          <CostModelLineItemsTable
            editMode={false}
            values={values.lineItems.lineItems ?? []}
            onValuesChange={() => {}}
            currency={values.lineItems.currency}
            rollingFrequency={getRollingFreqFromContractForm(values)}
          />
        </SummarySection>
      </Stack>

      <Divider />
      <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
        <Button type="button" variant="plain" size="S" onClick={onPrevious}>
          {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
        </Button>

        <Button
          type="submit"
          variant="solid"
          size="S"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          disabled={formState.isSubmitting || !formState.isValid}
          isLoading={formState.isSubmitting}
        >
          {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
        </Button>
      </Stack>
    </Stack>
  );
};
