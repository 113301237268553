import React from 'react';
import { Stack } from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { TextFieldCaption, Editor } from '@verticeone/design-system';

export type BodyFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  title: string;
  visible?: boolean;
  required?: boolean;
};

const TextField = <T extends FieldValues>({
  name,
  title,
  control,
  visible = true,
  required = true,
}: BodyFieldProps<T>) =>
  visible && (
    <Stack>
      <Stack>
        <TextFieldCaption label={title} htmlFor={`${title}-field`} size="XS" required={required} />
      </Stack>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <Editor size="S" color="neutral" variant="outline" customToolbar={<></>} {...field} />}
      />
    </Stack>
  );

export default TextField;
