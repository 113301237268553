import { useSaveWorkflow } from '../../../../hooks/useSaveWorkflow';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { WorkflowVersionRow } from '../../types';
import { useLazyGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../../../account/AccountContext';
import { Definitions } from '../../../../definitionsTypes';
import { useInvalidateWorkflowVersions } from './useInvalidateWorkflowVersions';
import { isDeployedVersion } from '../../utils';
import { getWorkflowVersionName } from '../../../../hooks/useGetWorkflowVersionName';
import { useFeatures } from '../../../../../features/useFeatures';
import { FEATURES } from '../../../../../features/constants';

export const useCreateVersionCopyName = () => {
  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;
  return (row: WorkflowVersionRow) => {
    const dialogVersionName = getWorkflowVersionName(
      row,
      isWorkflowsPageV2Enabled,
      false,
      (versionName) => versionName!
    );

    return t(
      'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.WORKFLOW_VERSION_COPY_NAME',

      {
        versionName: dialogVersionName ?? t('ENTITIES.WORKFLOW_VERSION.DRAFT'),
      }
    );
  };
};

export const useDuplicateWorkflowVersion = (
  row: WorkflowVersionRow,
  params?: {
    disableSnackbarConfirmation?: boolean;
    onComplete?: () => void;
    onClose?: () => void;
    onStart?: () => void;
  }
) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();
  const createVersionCopyName = useCreateVersionCopyName();

  const [getWorkflowVersion, { isLoading: isLoadingWorkflowVersion }] = useLazyGetWorkflowVersionQuery();

  const duplicateWorkflow = useCallback(
    async (customName?: string) => {
      const getWorkflowVersionQuery = getWorkflowVersion({
        accountId,
        workflowId: row.id,
        workflowVersion: row.versionId,
      });
      const { data: workflowVersionData } = await getWorkflowVersionQuery;
      getWorkflowVersionQuery.unsubscribe(); // prevent re-fetching version which was duplicated and then deleted

      if (workflowVersionData === undefined) return;

      const workflowVersionName = getWorkflowVersionName(
        row,
        isWorkflowsPageV2Enabled,
        false,
        (versionName) => versionName!
      );
      const versionCopyName = createVersionCopyName(row);
      params?.onStart?.();
      saveWorkflow(
        {
          workflowId: row.id,
          definitions: workflowVersionData.workflowDefinition as Definitions,
          customVersionName: customName && customName !== '' ? customName : versionCopyName,
        },
        () => {
          params?.onComplete?.();
          invalidateWorkflowVersions();
          if (!params?.disableSnackbarConfirmation) {
            enqueueSnackbar(
              t(
                isDeployedVersion(row)
                  ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_DUPLICATED'
                  : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_DUPLICATED',

                { versionName: workflowVersionName }
              ),
              {
                variant: 'success',
              }
            );
          }
        },
        () => {
          enqueueSnackbar(
            t(
              isDeployedVersion(row)
                ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_DUPLICATION_FAILED'
                : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_DUPLICATION_FAILED',
              { versionName: workflowVersionName }
            ),
            {
              variant: 'error',
            }
          );
          params?.onComplete?.();
        }
      );

      params?.onClose?.();
    },
    [
      accountId,
      createVersionCopyName,
      getWorkflowVersion,
      invalidateWorkflowVersions,
      params,
      row,
      isWorkflowsPageV2Enabled,
      saveWorkflow,
      t,
    ]
  );

  return {
    duplicateWorkflow,
    isDuplicatingWorkflow: isLoadingWorkflowVersion || isSavingWorkflow,
  };
};
