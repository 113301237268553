import React from 'react';
import { Stack } from '@mui/material';
import { Placeholder, Text } from '@verticeone/design-system';
import AnimatedTextNumber from '../AnimatedTextNumber';
import ChartStatsWrapper from './ChartStatsWrapper';
import ChartStatBar from './ChartStatBar';
import ChartStatLegend from './ChartStatLegend';
import { StatItemType } from '../../types';

export type ChartStatsProps = {
  total: number;
  totalLabel: string;
  valueFormatter?: (value: number) => string;
  items?: StatItemType[];
};

const ChartStats = ({ total, totalLabel, valueFormatter, items }: ChartStatsProps) => (
  <ChartStatsWrapper>
    <Stack gap={4}>
      <Stack gap={1}>
        <Stack overflow="hidden">
          <AnimatedTextNumber variant="heading" size="M" color="text1">
            {valueFormatter ? valueFormatter(total) : total}
          </AnimatedTextNumber>
        </Stack>
        <Text variant="body-regular" size="S" color="text1">
          {totalLabel}
        </Text>
      </Stack>
      <ChartStatBar total={total} items={items} />
    </Stack>
    <ChartStatLegend items={items} />
  </ChartStatsWrapper>
);

const Skeleton = () => (
  <ChartStatsWrapper flex={1} justifyContent="flex-end">
    <Stack gap={4}>
      <Stack gap={1}>
        <Placeholder variant="text" width={64} height={28} />
        <Placeholder variant="text" width={128} height={16} />
      </Stack>
      <ChartStatBar.Skeleton />
    </Stack>
    <Stack gap={2} direction="row">
      {Array(2)
        .fill(null)
        .map((_, index) => (
          <Stack key={index} gap={1} flex={1}>
            <Placeholder variant="text" width={64} height={28} />
            <Stack direction="row" alignItems="center" gap={2}>
              <Placeholder variant="rectangular" sx={{ borderRadius: 1 }} width={14} height={14} />
              <Placeholder variant="text" width="80%" height={16} />
            </Stack>
          </Stack>
        ))}
    </Stack>
  </ChartStatsWrapper>
);

ChartStats.Skeleton = Skeleton;

export default ChartStats;
