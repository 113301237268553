import { useUsersById } from './useAccountUsersById';
import { useVerticeUsersById } from './useVerticeUsersById';
import { useMemo } from 'react';
import {
  useGetAccountQuery,
  Account,
  useGetUserPublicInformationQuery,
  useListUsersPublicInformationQuery,
} from '@vertice/slices';
import { MentionUser } from '@verticeone/design-system';

export type UsersByIdReturn = Record<string, MentionUser>;

export const useConversationUsers = (
  accountId?: string,
  mentionedUserIds?: string[],
  options?: { skip: boolean; fetchVerticeUsers: boolean }
) => {
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useUsersById(accountId!, {
    skip: options?.skip || !accountId || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
  });
  const { usersById: verticeUsersById, isLoadingUsers: isLoadingVerticeUsers } = useVerticeUsersById({
    skip:
      options?.skip || !accountId || (accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION' && !options?.fetchVerticeUsers),
  });
  const { usersById: accountManagerIds, isLoadingUsers: isLoadingManagerIds } = useAccountManagementUsers(
    accountId!,
    options?.skip || !accountId || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION'
  );

  const knownUsersById = useMemo(() => {
    return { ...accountUsersById, ...asManaged(verticeUsersById), ...asManaged(accountManagerIds) };
  }, [accountUsersById, verticeUsersById, accountManagerIds]);

  const unknownMentionedUsersIds = useMemo(() => {
    return mentionedUserIds?.filter((userId) => !knownUsersById[userId]) ?? [];
  }, [mentionedUserIds, knownUsersById]);

  const { data: unknownUsersWithPublicInformation, isLoading: isLoadingUnknownUsersWithPublicInformation } =
    useListUsersPublicInformationQuery(unknownMentionedUsersIds, {
      skip: options?.skip || !unknownMentionedUsersIds.length,
    });

  const isLoadingUsers = useMemo(
    () =>
      isLoadingAccountUsers ||
      isLoadingVerticeUsers ||
      isLoadingManagerIds ||
      isLoadingUnknownUsersWithPublicInformation,
    [isLoadingAccountUsers, isLoadingVerticeUsers, isLoadingManagerIds, isLoadingUnknownUsersWithPublicInformation]
  );

  const usersById = useMemo(() => {
    const unknownUsersById = unknownUsersWithPublicInformation?.reduce((acc, user) => {
      if (user.userId) {
        acc[user.userId] = {
          ...user,
          userId: user.userId,
          email: user.email ?? undefined,
        };
      }
      return acc;
    }, {} as UsersByIdReturn);

    if (!unknownUsersById) return knownUsersById;

    return {
      ...knownUsersById,
      ...asManaged(unknownUsersById),
    };
  }, [knownUsersById, unknownUsersWithPublicInformation]);

  return {
    usersById,
    isLoadingUsers,
  };
};

const useAccountManagementUsers = (accountId: string, skip?: boolean) => {
  const { data: accountDetails, isLoading } = useGetAccountQuery(
    { accountId },
    {
      skip: skip || accountId === 'VERTICE_WORKFLOW_ADMINISTRATION',
    }
  );

  const { data: pumaProfile, isLoading: isPumaProfileLoading } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedManager }
  );
  const { data: managerProfile, isLoading: isManagerProfileLoading } = useGetUserPublicInformationQuery(
    { userId: (accountDetails as Account)?.associatedSuccessManager! },
    { skip: !accountDetails || !(accountDetails as Account)?.associatedSuccessManager }
  );

  const accountManagementUsers: UsersByIdReturn = useMemo(() => {
    const users: UsersByIdReturn = {};
    if (pumaProfile?.userId) {
      users[pumaProfile.userId] = {
        ...pumaProfile,
        userId: pumaProfile.userId,
        email: pumaProfile.email ?? undefined,
      };
    }
    if (managerProfile?.userId) {
      users[managerProfile.userId] = {
        ...managerProfile,
        userId: managerProfile.userId,
        email: managerProfile.email ?? undefined,
      };
    }
    return users;
  }, [pumaProfile, managerProfile]);

  return {
    isLoadingUsers: isLoading || isPumaProfileLoading || isManagerProfileLoading,
    usersById: accountManagementUsers,
  };
};

const asManaged = (users: UsersByIdReturn) => {
  return Object.entries(users).reduce((acc: UsersByIdReturn, [key, value]) => {
    acc[key] = { ...value, verticeUser: true };
    return acc;
  }, {});
};
