import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { CardsList } from '../../components/CardsList';
import { CardWrapper } from '../../components/CardWrapper';
import { SectionWrapper } from '../../components/SectionWrapper';
import { SCIMSetupType } from './components/common';
import { SCIMSetupCard } from './components/SCIMSetupCard';
import { SSOSetupCard } from './components/SSOSetupCard';

export const Security = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  const isScimEnabled = isEnabled(FEATURES.SAML_SCIM);

  return (
    <SectionWrapper title={t('INTEGRATIONS.SECURITY')}>
      <CardsList>
        <CardWrapper>
          <SSOSetupCard />
        </CardWrapper>
        {isScimEnabled && (
          <>
            <CardWrapper>
              <SCIMSetupCard type={SCIMSetupType.ENTRA} />
            </CardWrapper>
            <CardWrapper>
              <SCIMSetupCard type={SCIMSetupType.OKTA} />
            </CardWrapper>
          </>
        )}
      </CardsList>
    </SectionWrapper>
  );
};
