import { Stack, useTheme } from '@mui/material';

import { parse } from '@verticeone/utils/urn';
import { IconWrapper, Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { OpenInNewTwoTone } from '@mui/icons-material';
import { useRequestTasks } from './useRequestTasks';
import { TaskRow } from '../../task/dataSource';

type CommentSourceRefButtonProps = {
  accountId?: string;
  requestId?: string;
  sourceRef?: string;
  onClick?: (t: TaskRow) => void;
};

export const CommentSourceRefButton = ({ accountId, requestId, sourceRef, onClick }: CommentSourceRefButtonProps) => {
  const isTask = sourceRef ? parse(sourceRef).resourceName === 'task' : false;
  const taskId = sourceRef ? parse(sourceRef).resourceId : null;
  const { allTasks } = useRequestTasks(requestId, false, accountId);

  const { palette } = useTheme();
  const { t } = useTranslation();

  if (!isTask) return null;

  const taskRow = allTasks.find((task) => task.id === taskId);

  if (!taskRow) return null;

  const isClickable = !!onClick;
  return (
    <>
      <Stack
        justifyContent="start"
        gap={1}
        direction="row"
        sx={{
          cursor: isClickable ? 'pointer' : 'default',
          '&:hover': {
            textDecoration: isClickable ? 'underline' : 'none',
          },
        }}
        onClick={isClickable ? () => onClick(taskRow) : undefined}
      >
        <Text size="XS" color="neutral1" variant="button-bold">
          {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.FROM', { taskName: taskRow?.name })}
        </Text>
        {isClickable && <IconWrapper icon={OpenInNewTwoTone} size="XS" htmlColor={palette.neutral.color1} />}
      </Stack>
    </>
  );
};
