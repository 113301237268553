import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { Box, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { getTextVariantStyle } from '@verticeone/design-system';
import { LoadableComponent } from '@verticeone/design-system';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useGraphData } from './useGraphData';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useGraph } from '../../../components/providers/GraphProvider';
import GraphLayout, { GraphLayoutProps } from '../../../components/Graph/GraphLayout';
import { getDiagonalCrossPattern, getDotPattern } from '../../Compute/Graph/utils';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

const useGraphSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { isOnDemandUsageOnly, shoppingCartUnits } = useGraph();
  const { data, isFetching } = useGraphData();

  return useMemo(
    () => ({
      hasDataFetched: !isFetching && data !== null,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spPurchaseRecommendation !== null),
      lastDataTime: data?.find((item) => item.spPurchaseRecommendation !== null),
      series: [
        {
          id: 'averageUsage',
          type: 'spline' as const,
          name: t('AVERAGE_COST'),
          data: data?.map((item) => item.averageUsage) ?? [],
          color: palette.text.color1,
          dashStyle: 'Dash' as const,
          custom: {
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color1,
            legendOutlined: true,
          },
          visible: !isOnDemandUsageOnly,
          zIndex: 100,
        },
        {
          id: 'uncoverableCost',
          type: 'area' as const,
          name: t('INELIGIBLE_RESOURCES'),
          color: palette.visualization.divergent.primary['+20'],
          data: data?.map((item) => item.uncoverableCost) ?? [],
          lineWidth: 1,
          custom: {
            legendColor: palette.visualization.divergent.primary['+20'],
            tooltipColor: palette.visualization.divergent.primary['+20'],
          },
          visible: !!data?.some((item) => item.uncoverableCost !== null),
          zIndex: 1,
          index: 1,
        },
        {
          id: 'onDemandCostPerDay',
          type: 'spline' as const,
          name: t('ON_DEMAND_AVERAGE_COST'),
          color: palette.text.color1,
          data: data?.map((item) => item.onDemandCostPerDay) ?? [],
          dashStyle: 'Dash' as const,
          custom: {
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color1,
            legendOutlined: true,
          },
          visible: isOnDemandUsageOnly,
          zIndex: 100,
        },
        {
          id: 'shoppingCartUnits',
          type: 'column',
          name: t('SHOPPING_CART'),
          color: getColorPattern(palette.warning.color2),
          data: data?.map((item) => item.shoppingCartUnits) ?? [],
          opacity: 1,
          states: {
            hover: { color: getColorPattern(palette.warning.color2), opacity: 1 },
            inactive: { opacity: 0.5 },
          },
          custom: {
            legendOutlined: true,
            legendColor: getColorPattern(palette.warning.color2),
            tooltipColor: getColorPattern(palette.warning.color2),
          },
          index: 1,
          zIndex: 1,
          visible: !!shoppingCartUnits,
        },
        {
          id: 'onDemandUsage',
          type: 'area' as const,
          name: t('ON_DEMAND_USAGE'),
          data: data?.map((item) => item.onDemandUsage) ?? [],
          color: palette.visualization.divergent.secondary['-50'],
          fillColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.secondary['-20']
          ),
          lineWidth: 1,
          custom: {
            legendColor: getDiagonalCrossPattern(palette.text.color3, palette.core.color2),
            tooltipColor: getDiagonalCrossPattern(
              palette.transparent.color2,
              palette.visualization.divergent.secondary['-20']
            ),
            tooltipLabel: t('EC2_ON_DEMAND_LABEL'),
            hideLegend: false,
          },
          index: 2,
          zIndex: 2,
        },
        {
          id: 'spCoveredUsage',
          type: 'area' as const,
          name: t('SAVINGS_PLANS_COVERED_USAGE'),
          data: data?.map((item) => item.spCoveredUsage) ?? [],
          color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20']),
          custom: {
            legendColor: getDotPattern(palette.text.color3, palette.core.color2, 5),
            tooltipColor: getDotPattern(
              palette.transparent.color2,
              palette.visualization.divergent.secondary['-20'],
              5
            ),
            tooltipLabel: t('EC2_SP_RI_LABEL'),
            hideLegend: true,
          },
          visible: !isOnDemandUsageOnly,
          index: 3,
          zIndex: 3,
        },
        {
          id: 'totalUsage',
          type: 'line' as const,
          name: t('SAGEMAKER'),
          color: palette.text.color1,
          dashStyle: 'Dot' as const,
          data: data?.map((item) => item.totalUsage) ?? [],
          custom: {
            legendColor: palette.visualization.divergent.secondary['-20'],
            tooltipColor: getColorPattern(palette.text.color1, palette.core.bg),
          },
        },
        {
          id: 'spPurchaseRecommendation',
          type: 'line' as const,
          name: t('MAXIMUM_COVERAGE'),
          color: palette.primary.color2,
          data: data?.map((item) => item.spPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.primary.color2,
            tooltipColor: palette.primary.color2,
          },
          visible: !isOnDemandUsageOnly,
        },
        {
          id: 'spOnDemandPurchaseRecommendation',
          type: 'line' as const,
          name: t('VERTICE_RECOMMENDATION'),
          color: palette.tertiary.color2,
          data: data?.map((item) => item.spOnDemandPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.tertiary.color2,
            tooltipColor: palette.tertiary.color2,
          },
          visible: isOnDemandUsageOnly,
        },
      ],
    }),
    [data, isFetching, isOnDemandUsageOnly, palette, shoppingCartUnits, t]
  );
};

type GraphProps = Omit<ReturnType<typeof useGraphSeries>, 'hasDataFetched'>;

const Graph = ({ series, categories, lastDataTimeIndex }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.SAGEMAKER.GRAPH' });
  const { palette } = useTheme();
  const { isOnDemandUsageOnly } = useGraph();
  const { locale } = useLocaleContext();
  const formatCurrency = useFormatCurrency();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const legend = series
    .filter(({ visible }) => visible !== false)
    .map(({ custom: { legendColor: color, legendOutlined: outlined }, name: label, id }) => ({
      id,
      color,
      label,
      outlined,
    }));

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12, spacingBottom: 12, height: 392 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            area: {
              stacking: 'normal',
            },
            series: {
              marker: {
                enabled: false,
                states: {
                  inactive: { opacity: 0 },
                },
              },
            },
          },
          rangeSelector: {
            selected: 0,
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 3,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 0, 2, true),
              x: 46,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button-bold', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          tooltip: {
            shared: true,
          },
          series: series as Highcharts.SeriesOptionsType[],
        },
        opts
      ),
  ]);

  return (
    <>
      <Box padding={4} marginLeft="auto">
        <Legend items={legend} testId="sagemaker-graph-legend" />
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ x, points }) => {
          const filteredPoints = points?.filter((point) => {
            const isPurchasedRecommendation = [
              'spPurchaseRecommendation',
              'spOnDemandPurchaseRecommendation',
              'shoppingCartUnits',
            ].includes(point.series.userOptions.id!);
            return points?.length > 2 !== isPurchasedRecommendation && point.series.userOptions.id !== 'totalUsage';
          });

          const totalCost =
            points?.reduce((acc, point) => {
              const id = point.series.userOptions?.id;
              const visible = point.series.visible;
              const validIds = ['onDemandUsage'];
              const visibleIds = ['spCoveredUsage'];

              if (id && (validIds.includes(id) || (visible && visibleIds.includes(id)))) {
                return acc + (point.y ?? 0);
              }
              return acc;
            }, 0) || null;

          return (
            <TooltipWrapper minWidth="370px">
              <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
              {filteredPoints?.map((point) => {
                const custom = point.series.userOptions.custom as any;

                return (
                  <TooltipSeriesValuePair
                    seriesColor={custom.tooltipColor}
                    seriesName={point.series.userOptions.name}
                    value={formatCurrency(point?.y ?? 0, {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    key={point.series.userOptions.id}
                    borderColor={palette.core.color5}
                  />
                );
              })}
              {totalCost && (
                <TooltipTotal
                  value={formatCurrency(totalCost, {
                    currency: AWS_DEFAULT_CURRENCY,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  label={isOnDemandUsageOnly ? t('TOTAL_ON_DEMAND_COST_PER_DAY') : t('TOTAL_COST_PER_DAY')}
                />
              )}
            </TooltipWrapper>
          );
        }}
      </HighchartTooltip>
    </>
  );
};

const LoadableGraph = () => {
  const { hasDataFetched, ...otherProps } = useGraphSeries();

  return (
    <LoadableComponent isLoading={!hasDataFetched} color={AWS_BRAND_COLOR}>
      <Graph {...otherProps} />
    </LoadableComponent>
  );
};

const GraphContainer = ({ shoppingCartUnits, forceOnDemandUsageOnly = false }: GraphLayoutProps) => (
  <GraphLayout shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={forceOnDemandUsageOnly}>
    <LoadableGraph />
  </GraphLayout>
);

export default GraphContainer;
