import React, { FC, useEffect } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { BusinessCaseFormData } from '../../types';
import { zodResolver } from '@hookform/resolvers/zod';
import { BusinessQuestion } from '../../types';
import { createQuestionsSchema } from '../../utils';
import { Button, Divider, Text } from '@verticeone/design-system';
import { FormControl, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../../constants';
import FormChecklistItemField from '../../../../../../../../forms/fields/FormChecklistItemField';

type BusinessCaseStepProps = {
  defaultValues?: DeepPartial<BusinessCaseFormData>;
  questions: BusinessQuestion[];
  onSubmit: (data: BusinessCaseFormData) => void;
  onChange: (data: DeepPartial<BusinessCaseFormData>) => void;
  onPrevious: () => void;
};

export const BusinessCaseStep: FC<BusinessCaseStepProps> = ({
  questions,
  defaultValues,
  onSubmit,
  onPrevious,
  onChange,
}) => {
  const { t } = useTranslation();
  const schema = createQuestionsSchema(questions);
  const formMethods = useForm<BusinessCaseFormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { handleSubmit, formState, watch } = formMethods;

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      onChange(formValues);
    });
    return () => unsubscribe();
  }, [watch, onChange]);

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flex="1 1 0" onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ p: 6, overflow: 'auto', flexGrow: 1, gap: 12 }}>
          <Text variant="heading" size="XS" color="text2">
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.BUSINESS_CASE.TITLE')}
          </Text>
          <Stack flexGrow={1} gap={8}>
            {questions.map((question, index) => (
              <FormControl variant="outlined" key={index}>
                <FormChecklistItemField
                  name={question.name}
                  label={question.name}
                  description={question.description}
                  color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                />
              </FormControl>
            ))}
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
          <Button
            variant="plain"
            size="S"
            onClick={onPrevious}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
          </Button>
          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
