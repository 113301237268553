import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import StatCard from './StatCard/StatCard';
import { MonetizationOnOutlined } from '@mui/icons-material';
import useProcuredStatsData from '../dataSource/useProcuredStatsData';

const ProcuredSpendStatCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.OVERVIEW.STATS' });
  const formatCurrency = useFormatCurrency();
  const { data, isFetching } = useProcuredStatsData();

  return (
    <StatCard
      isLoading={isFetching}
      testId="procured-spend-card"
      title={t('PROCURED_SPEND.TITLE')}
      subtitle={t('PROCURED_SPEND.SUBTITLE')}
      value={formatCurrency(data.procuredCost.amount, {
        maximumFractionDigits: 0,
        currency: data.procuredCost.currency,
      })}
      variant="info"
      icon={MonetizationOnOutlined}
    />
  );
};

export default ProcuredSpendStatCard;
