import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import { EndDefinition } from '../../../../definitionsTypes';
import {
  EditorDrawer,
  EditorDrawerHeader,
  EditorDrawerFooter,
  EditorDrawerScrollContainer,
  EditorDrawerForm,
} from '../../EditorDrawer';
import { EditEndFormData, editEndFormSchema } from '../../forms/EditEndForm/schema';
import { EditEndForm } from '../../forms/EditEndForm/EditEndForm';

type EditEndDrawerProps = {
  isOpen: boolean;
  endDefinition?: EndDefinition;
  onClose: VoidFunction;
  onSave: (newEndDefinition: EndDefinition) => void;
  onDelete: (nodeId: string) => void;
  onDirty: VoidFunction;
};

const EditEndDrawerContent: FC<Omit<Required<EditEndDrawerProps>, 'isOpen'>> = ({
  endDefinition,
  onClose,
  onSave,
  onDirty,
  onDelete,
}) => {
  const { end } = endDefinition;
  const { t } = useTranslation();

  const handlers = useForm<EditEndFormData>({
    resolver: zodResolver(editEndFormSchema),
    defaultValues: {
      name: end.name ?? t('MODULES.WORKFLOW.NODES.END.DEFAULT_LABEL'),
      resultType: end.resultType,
    },
  });

  const onSubmit = (data: EditEndFormData) => {
    const updatedEndDefinition = {
      ...endDefinition,
      end: {
        ...endDefinition.end,
        name: data.name,
        resultType: data.resultType,
      },
    };
    onSave(updatedEndDefinition);
    onClose();
  };

  const title = t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_END.TITLE');

  return (
    <FormProvider {...handlers}>
      <EditorDrawerForm onSubmit={handlers.handleSubmit(onSubmit)}>
        <EditorDrawerHeader
          title={title}
          deleteOptions={{
            onDelete: () => onDelete(end.id),
            nodeName: title,
            buttonLabel: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.DELETE_END'),
            dialogDescription: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DELETE_NODE_DIALOG.DESCRIPTION_END'),
          }}
        />
        <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
          <EditorDrawerScrollContainer>
            <EditEndForm resultType={end.resultType} onDirty={onDirty} />
          </EditorDrawerScrollContainer>
          <EditorDrawerFooter onDiscard={onClose} />
        </Stack>
      </EditorDrawerForm>
    </FormProvider>
  );
};

export const EditEndDrawer: FC<EditEndDrawerProps> = ({
  isOpen,
  onClose,
  onSave,
  onDirty,
  endDefinition,
  onDelete,
}) => {
  return (
    <EditorDrawer open={isOpen}>
      {endDefinition && (
        <EditEndDrawerContent
          onDirty={onDirty}
          onSave={onSave}
          onClose={onClose}
          endDefinition={endDefinition}
          key={endDefinition.end.id}
          onDelete={onDelete}
        />
      )}
    </EditorDrawer>
  );
};
