import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import { useConnectIntegration } from '../hooks/useConnectIntegration';
import { StepContentProps, VisoTrustSetupStep } from '../types';
import { ConnectionCardContent } from './ConnectionCardContent';

type ConnectionCardProps = StepContentProps;

export const ConnectionCard: FC<ConnectionCardProps> = ({ activeStep, setStep }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { connectVisoTrust, connectionStatus, isConnectionInProgress, setIsConnectionInProgress } =
    useConnectIntegration();

  const [isEditMode, setIsEditMode] = useState(false);
  const handleSetIsEditMode = (newValue: boolean) => {
    setIsEditMode(newValue);
  };

  useEffect(() => {
    if (connectionStatus === 'PASSED' && isConnectionInProgress) {
      if (isEditMode) {
        setIsEditMode(false);
      }
      setIsConnectionInProgress(false);
      enqueueSnackbar({
        description: t('INTEGRATIONS.VISO_TRUST.SNACKBAR.ACTIVATE_CONNECTION_SUCCESS_DESC'),
        variant: 'success',
      });
      setStep(VisoTrustSetupStep.SUCCESS);
    }

    if (connectionStatus === 'FAILED' && isConnectionInProgress) {
      setIsConnectionInProgress(false);
      enqueueSnackbar({
        message: t('INTEGRATIONS.VISO_TRUST.SNACKBAR.ACTIVATE_CONNECTION_ERROR_MESSAGE'),
        description: t('INTEGRATIONS.VISO_TRUST.SNACKBAR.ACTIVATE_CONNECTION_ERROR_DESC'),
        variant: 'error',
      });
      setStep(VisoTrustSetupStep.CONNECTION);
    }
  }, [connectionStatus, enqueueSnackbar, isConnectionInProgress, isEditMode, setIsConnectionInProgress, setStep, t]);

  const isSetupFinished = activeStep === VisoTrustSetupStep.SUCCESS && !isEditMode;

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CARD_HEADER_TITLE')} />
        {isSetupFinished && (
          <CardHeaderActions>
            <Button
              variant="outline"
              size="S"
              color="primary"
              onClick={() => setIsEditMode(true)}
              testId="edit-connection"
            >
              {t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.EDIT_BUTTON')}
            </Button>
          </CardHeaderActions>
        )}
      </CardHeader>
      <Box p={6}>
        <ConnectionCardContent
          isSetupFinished={isSetupFinished}
          isEditMode={isEditMode}
          setIsEditMode={handleSetIsEditMode}
          connectVisoTrust={connectVisoTrust}
        />
      </Box>
    </Card>
  );
};
