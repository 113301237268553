import { FC } from 'react';
import { Stack } from '@mui/material';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import { LineItemsFieldType } from './types';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { LineItemsTableField, LineItemsTableFieldComponentType } from './components/LineItemsTableField';
import { DynamicFormPresenterProps } from '../types';

export const LineItemsFieldPresenter: FC<DynamicFormPresenterProps<LineItemsFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;
  return (
    <Stack py={3}>
      <FormEntry<FieldValues, LineItemsTableFieldComponentType<FieldValues>>
        name={field.name}
        component={LineItemsTableField}
        componentProps={{
          editMode: config.mode === 'readWrite',
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
        description={description}
        label={label}
        required={required}
      />
    </Stack>
  );
};
