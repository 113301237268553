import { DesignSystemSize, SelectColor, SelectVariant, TextField } from '@verticeone/design-system';
import { InlineVendor } from '../types';
import { ChangeEvent, forwardRef, ReactNode, useCallback } from 'react';
import { SxProps } from '@mui/material';

export type NonSaasVendorSelectProps = {
  value: InlineVendor | null;
  onChange: (value: InlineVendor | null) => void;
  onBlur?: () => void;
  variant?: SelectVariant;
  size?: DesignSystemSize;
  color?: SelectColor;
  error?: boolean;
  helperText?: ReactNode;
  sx?: SxProps;
  disabled?: boolean;
};

const normalizeValue = (value: string) => value.trim();

export const NonSaasVendorSelect = forwardRef<HTMLDivElement, NonSaasVendorSelectProps>(
  ({ value, onChange, onBlur, ...otherProps }, ref) => {
    const vendorName = value?.name ?? '';
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(normalizeValue(event.target.value) ? { type: 'INLINE', name: event.target.value } : null);
      },
      [onChange]
    );
    const handleBlur = useCallback(() => {
      if (value) {
        onChange({ ...value, name: normalizeValue(value.name) });
        onBlur?.();
      }
    }, [onBlur, onChange, value]);

    return <TextField ref={ref} value={vendorName} onChange={handleChange} onBlur={handleBlur} {...otherProps} />;
  }
);
