import { useCallback, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

export type FormStepValues = Record<string, any>;

export type UseStepperStoreProps<T extends FormStepValues> = {
  defaultValues: DeepPartial<T>;
};

export const useStepperStore = <T extends FormStepValues>(props: UseStepperStoreProps<T>) => {
  const { defaultValues } = props;

  const [values, setValues] = useState<Partial<T>>(defaultValues);

  const updateValues = useCallback(
    <K extends keyof T>(step: K, newValues: DeepPartial<T[K]>) => {
      setValues((prev) => ({ ...prev, [step]: newValues }));
    },
    [setValues]
  );

  const getValues = useCallback(
    <K extends keyof T>(step: K) => {
      return values[step] ?? undefined;
    },
    [values]
  );

  return { values, updateValues, getValues };
};
