import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import React, { useEffect } from 'react';
import { FieldPath, Path, useFormContext, useWatch } from 'react-hook-form';
import { FormOverridableCostField } from '@vertice/core/src/modules/forms/fields/FormOverridableCostField';

type OverridableCostFormEntryProps = {
  name: FieldPath<AddExistingContractFormData>;
  computedCost?: number | null;
  totalContractValue?: number | null;
  isLoading?: boolean;
  label?: string;
  currency?: string | null;
  disabled?: boolean;
  width?: number;
};

export const OverridableCostFormEntry = ({
  name,
  computedCost,
  totalContractValue,
  isLoading,
  label,
  currency,
  disabled,
  width = 12,
}: OverridableCostFormEntryProps) => {
  const { setValue } = useFormContext<AddExistingContractFormData>();
  const overrideEnabled = useWatch({ name: `${name}.enabled` });

  useEffect(() => {
    // Set computed cost as default value for overridable field
    if (!overrideEnabled) {
      setValue(`${name}.amount` as Path<AddExistingContractFormData>, computedCost ?? 0);
    }
  }, [overrideEnabled, computedCost, setValue, name]);

  return (
    <AddExistingContractFormEntry
      name={name}
      label={label}
      component={FormOverridableCostField}
      disabled={disabled}
      componentProps={{
        currency: currency,
        totalContractValue: totalContractValue,
        isLoading: isLoading,
      }}
      width={width}
    />
  );
};
