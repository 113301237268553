import { match } from 'ts-pattern';
import { ContractReviewForm } from '../types';
import { RollingFrequency } from '@vertice/slices/src/openapi/codegen/contractCostAPI';

export const getRollingFreqFromContractForm = (data: ContractReviewForm): RollingFrequency => {
  return match(data.contractDates)
    .returnType<RollingFrequency>()
    .with({ contractType: 'ONE_OFF' }, () => 'ONE_OFF')
    .with({ contractType: 'FIXED' }, () => 'NO')
    .with({ contractType: 'ROLLING' }, ({ rollingFrequency }) => rollingFrequency)
    .exhaustive();
};
