import * as z from 'zod';

import { nonEmptyString } from '@verticeone/utils/validation';
import { variableMapperMapperFields } from '../../../VariableMapper/formSchema';

export const jiraCreateTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  description: z.string().optional(),
  projectId: z.string(),
  issueType: z.string(),
  ...variableMapperMapperFields,
  // TODO: Add attachments feature once it's fully supported on WF side
  // includeAttachments: z.boolean(),
});

export type JiraCreateTaskFormData = z.infer<typeof jiraCreateTaskFormSchema>;
