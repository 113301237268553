import React from 'react';
import { motion } from 'framer-motion';

type AnimatedBarProps = {
  width: number;
  color: string;
  height: number;
};

const AnimatedBar = ({ width, color, height = 8 }: AnimatedBarProps) => {
  return (
    <motion.div
      style={{
        width: width + '%',
        height,
        borderRadius: 2.5,
        backgroundColor: color,
      }}
      animate={{ width: width + '%' }}
      transition={{ type: 'tween', duration: 0.25 }}
    />
  );
};

export default AnimatedBar;
