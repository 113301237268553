import { InfoOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NotificationsPreferences from '@vertice/core/src/modules/notifications/NotificationsPreferences';
import { AuthType, useAuthentication, useLoggedUser } from '@verticeone/auth/src';
import { Alert, Card, CardHeader, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';
import { SlackIntegration } from './SlackIntegration/SlackIntegration';
import UserInformation from './UserInformation/UserInformation';

const UserContent = () => {
  const { userId } = useLoggedUser();
  const { t } = useTranslation();

  return (
    <Stack gap={4}>
      <UserInformation />
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle tag="h1" text={t('PREFERENCES.EMAIL_NOTIFICATIONS.USER.TITLE')} />
          <CardHeaderDescription>
            <Box>{t('PREFERENCES.EMAIL_NOTIFICATIONS.USER.DESCRIPTION')}</Box>
          </CardHeaderDescription>
        </CardHeader>
        <NotificationsPreferences userId={userId} noBorder />
      </Card>
      <SlackIntegration />
    </Stack>
  );
};

export const User = () => {
  const { t } = useTranslation();
  const { type: authType } = useAuthentication();

  return authType === AuthType.ASSUME_ROLE ? (
    <Alert
      icon={InfoOutlined}
      color="info"
      variant="ghost"
      subtitle={t('PREFERENCES.USER.USER_SETTINGS_NOT_AVAILABLE_IN_ASSUME_ROLE')}
    />
  ) : (
    <UserContent />
  );
};
