import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonIntakeFormFormData } from '../types';
import { useLoggedUser } from '@verticeone/auth/src';
import { useUserOwnedDepartments } from '@vertice/core/src/modules/departments/useUserOwnedDepartments';
import { useAccountDepartments } from '@vertice/core/src/modules/departments/useAccountDepartments';
import { Department } from '@vertice/core/src/modules/departments/types';

/**
 * Get the list of departments for the current user as specified in useUserDepartments
 * @param setDefaultValue - If true, set React Hook Form value of departmentId to General department id if it is not set yet
 */

type UseFormDepartmentsOptions = {
  setDefaultValue?: boolean;
  onlyOwnedDepartments?: boolean;
};

/** @deprecated in favor of @vertice/core/src/modules/departments/FormDepartmentSelectField/useDepartmentOptions */
export const useFormDepartments = ({
  setDefaultValue = true,
  onlyOwnedDepartments = true,
}: UseFormDepartmentsOptions): Department[] | undefined => {
  const { setValue, getValues } = useFormContext<CommonIntakeFormFormData>();

  const { userId } = useLoggedUser();
  const { departments: userOwnedDepartments } = useUserOwnedDepartments({ userId, skip: !onlyOwnedDepartments });
  const { data: allDepartments } = useAccountDepartments({ skip: onlyOwnedDepartments });

  const departments = onlyOwnedDepartments ? userOwnedDepartments : allDepartments;

  useEffect(() => {
    if (setDefaultValue && departments && !getValues('departmentId')) {
      const general = departments?.find((department) => department.code === 'DEFAULT');
      if (general) {
        setValue('departmentId', general.departmentId);
      }
    }
  }, [departments, setDefaultValue, setValue, getValues]);

  return departments;
};
