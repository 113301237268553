import { ContractReviewForm } from '../../../types';
import { ComputeCostsInput } from '../hooks/useComputedCosts';
import { CostModelLineItem } from '../../../../../../../saas/contract/components/CostModelLineItemsTable';
import { getRollingFreqFromContractForm } from '../../../utils/getRollingFreqFromContractForm';

export const toComputeCostInput = (
  contractForm: ContractReviewForm,
  lineItems: CostModelLineItem[]
): ComputeCostsInput => {
  const { contractDates } = contractForm;

  const startDate = contractDates.startDate;
  const endDate = contractDates.contractType === 'FIXED' ? contractDates.renewalDate : undefined;

  const rollingFrequency = getRollingFreqFromContractForm(contractForm);

  return { startDate, endDate, lineItems, rollingFrequency };
};
