import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';
import { useFeatures } from '../../../features/useFeatures';
import { FEATURES } from '../../../features/constants';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { keyBy, Dictionary } from 'lodash';

export type UseRollingFrequencyOptionsParams = {
  isOneOffIncluded: boolean;
};

const useRollingFrequencyOptions = (props: UseRollingFrequencyOptionsParams) => {
  const { isOneOffIncluded } = props;
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  const options = useMemo<SimpleOption[]>(
    () =>
      [
        { value: 'NO', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_NO') },
        ...(isEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG) && isOneOffIncluded
          ? [{ value: 'ONE_OFF' as RollFrequency, label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ONE_OFF') }]
          : []),
        { value: 'MONTHLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_MONTHLY') },
        { value: 'QUARTERLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_QUARTERLY') },
        { value: 'BIANNUALLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_BIANNUALLY') },
        { value: 'ANNUALLY', label: t('ENTITIES.CONTRACT.LABELS.FREQUENCY_ANNUALLY') },
      ] satisfies { value: RollFrequency; label: string }[],
    [isEnabled, isOneOffIncluded, t]
  );

  const optionsMap = useMemo<Dictionary<SimpleOption>>(() => keyBy(options, 'value'), [options]);

  const getLabel = useCallback(
    (value?: string | null) => {
      if (!value) return null;
      return optionsMap[value]?.label ?? null;
    },
    [optionsMap]
  );

  return { options, optionsMap, getLabel };
};

export default useRollingFrequencyOptions;
