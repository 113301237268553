import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useAccountContext } from '../../../../../account/AccountContext';
import { useActivateWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useInvalidateWorkflowVersions } from './useInvalidateWorkflowVersions';
import { isDeployedVersion } from '../../utils';
import { useFeatures } from '../../../../../features/useFeatures';
import { FEATURES } from '../../../../../features/constants';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useGetWorkflowVersionName } from '../../../../hooks/useGetWorkflowVersionName';

type ActivateWorkflowVersionParams = {
  workflowId: string;
  workflowVersion: string;
  versionName?: string;
  versionTag?: string;
  status: WorkflowVersion['status'];
};

export const useActivateWorkflowVersion = (params: ActivateWorkflowVersionParams) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { getFeature } = useFeatures();
  const { enqueueSnackbar } = useSnackbar();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();

  const versionName = useGetWorkflowVersionName(params);

  const isWorkflowsV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  const [versionBeingActivated, setVersionBeingActivated] = useState<string | undefined>(undefined);

  const [activateWorkflowVersionMutation] = useActivateWorkflowVersionMutation();
  const activateWorkflowVersion = useCallback(
    async ({ versionNotes, onComplete }: { versionNotes?: string; onComplete?: () => void }): Promise<void> => {
      setVersionBeingActivated(params.workflowVersion);
      const activationResult = await activateWorkflowVersionMutation({
        accountId,
        workflowId: params.workflowId,
        workflowVersion: params.workflowVersion,
        body: {
          versionNotes,
        },
      });
      if ('error' in activationResult) {
        enqueueSnackbar(
          t(
            isDeployedVersion({ status: params.status })
              ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_ACTIVATION_FAILED'
              : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_ACTIVATION_FAILED',
            { versionName }
          ),
          {
            variant: 'error',
          }
        );
      } else {
        invalidateWorkflowVersions();
        const v1Message = t(
          isDeployedVersion({ status: params.status })
            ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_ACTIVATED'
            : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_ACTIVATED',
          { versionName }
        );

        const snackbarMessage = isWorkflowsV2Enabled
          ? t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.SET_AS_LIVE')
          : v1Message;

        enqueueSnackbar(snackbarMessage, {
          variant: 'success',
        });
        onComplete?.();
      }

      setVersionBeingActivated(undefined);
    },
    [
      accountId,
      activateWorkflowVersionMutation,
      enqueueSnackbar,
      invalidateWorkflowVersions,
      isWorkflowsV2Enabled,
      params,
      versionName,
      t,
    ]
  );

  return {
    activateWorkflowVersion,
    versionBeingActivated,
  };
};
