import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  CostModelLineItem,
  CostModelLineItemsTable,
} from '@vertice/core/src/modules/saas/contract/components/CostModelLineItemsTable';
import { AddExistingContractFormData } from '../types';

export const IntakeLineItemsTable = () => {
  const { setValue, control } = useFormContext<AddExistingContractFormData>();

  const lineItems = useWatch({ control, name: 'lineItems' });
  const contractCurrency = useWatch({ control, name: 'contractCurrency' });
  const rollingFrequency = useWatch({ control, name: 'rollingFrequency' });

  const setLineItems = useCallback(
    (lineItemsToSet: CostModelLineItem[]) => {
      setValue('lineItems', lineItemsToSet);
    },
    [setValue]
  );

  return (
    <CostModelLineItemsTable
      editMode
      values={lineItems || []}
      onValuesChange={setLineItems}
      currency={contractCurrency}
      rollingFrequency={rollingFrequency ? rollingFrequency : 'NO'}
    />
  );
};
