import { Text, TextProps } from '@verticeone/design-system';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import React, { forwardRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import { isNil } from 'lodash';

type AnimatedDigitProps = {
  digit: number;
} & TextProps;

const AnimatedDigit = ({ digit, ...textProps }: AnimatedDigitProps) => {
  const count = useMotionValue(digit);
  const rounded = useTransform(() => Math.round(count.get()));

  useEffect(() => {
    if (!isNaN(digit)) {
      const controls = animate(count, digit, { duration: 0.25 });
      return () => controls.stop();
    }
  }, [count, digit]);

  const TextComponent = forwardRef<HTMLElement, TextProps>((_, ref) => {
    return (
      <Text {...textProps} ref={ref}>
        {digit}
      </Text>
    );
  });

  const MotionComponent = motion.create(TextComponent);

  return <MotionComponent>{rounded}</MotionComponent>;
};

type AnimatedTextNumberProps = {
  children: number | string;
} & TextProps;

const AnimatedTextNumber = ({ children, ...textProps }: AnimatedTextNumberProps) => {
  if (isNil(children)) {
    return null;
  }

  const parts = children
    .toString()
    .split(/(\$|,|\.|0+|\d{1,3}(?=\d{3})|\d+)/)
    .flatMap((part) => (part.match(/^0+$/) ? part.split('') : [part]))
    .filter(Boolean);

  return (
    <Stack direction="row" whiteSpace="pre">
      {parts.map((part, index) =>
        /\d/.test(part) ? (
          <AnimatedDigit key={index} digit={Number(part)} {...textProps} />
        ) : (
          <Text key={index} {...textProps}>
            {part}
          </Text>
        )
      )}
    </Stack>
  );
};

export default AnimatedTextNumber;
