import { useMemo } from 'react';
import { ResourceThumbnail } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { TasksThumbnail } from '../../definitionsTypes';
import { useGetCatalogResources } from '../../hooks/useGetCatalogResources';

const convertToTasksThumbnail = (serviceThumbnail: ResourceThumbnail): TasksThumbnail => {
  return {
    id: serviceThumbnail.Id,
    type: serviceThumbnail.Type,
  };
};

export type ServicesThumbnails = Record<string, TasksThumbnail>;

export const useServicesThumbnails = (): ServicesThumbnails => {
  const { resources } = useGetCatalogResources();

  const items = useMemo(() => {
    return resources.reduce((acc, service) => {
      const resourceId = service.urn.slice(service.urn.indexOf('/') + 1);

      if (service.urn && service.definition.Thumbnail) {
        acc[resourceId] = convertToTasksThumbnail(service.definition.Thumbnail);
      }
      return acc;
    }, {} as Record<string, TasksThumbnail>);
  }, [resources]);

  return items;
};
