import { TFunction } from 'i18next';
import { fieldDefsByType } from './fields';
import { BuilderPlugins } from './types';
import { AnyZodObject, z } from 'zod';
import { FieldType } from '@vertice/slices/src/openapi/codegen/catalogAPI';

export const getFieldTypeText = (t: TFunction, type: FieldType) => {
  const fieldDef = fieldDefsByType[type];
  return fieldDef ? t(fieldDef.typeNameI18nKey) : t('DYNAMIC_FORM_BUILDER.FIELD.UNKNOWN');
};

export const addPluginsToSchema = (baseSchema: AnyZodObject, plugins?: any) => {
  if (!plugins) return baseSchema;
  return baseSchema.extend({
    plugins: z.array(z.union(plugins.map((p: any) => p.schema))),
  });
};

export const addPluginsToDefaultData = <T>(baseDefaults: T, plugins?: BuilderPlugins) => {
  if (!plugins) return baseDefaults;
  return {
    ...baseDefaults,
    plugins: plugins?.map((p) => p.defaultValues),
  };
};
