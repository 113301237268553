import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const VariableMapperRoot = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.input.color1,
  gap: theme.spacing(3),
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
}));

export default VariableMapperRoot;
