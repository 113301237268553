import { useMemo } from 'react';
import { useGetExchangeRatesQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type GetCostInCurrencyProps = {
  sourceCurrency: string;
  targetCurrency: string;
  value?: number;
  exchangeRates?: { [arg: string]: any };
};

type UseExchangeCurrencyProps = Omit<GetCostInCurrencyProps, 'exchangeRates'>;

export type UseExchangeCurrencyReturn = {
  exchangeCurrency: (props: UseExchangeCurrencyProps) => number | undefined;
  isLoading: boolean;
  isFetching: boolean;
};

export const getCostInCurrency = ({ sourceCurrency, targetCurrency, value, exchangeRates }: GetCostInCurrencyProps) => {
  if (exchangeRates === undefined || value === undefined) {
    return undefined;
  }

  if (sourceCurrency === targetCurrency) {
    return value;
  }

  const sourceRate = exchangeRates[sourceCurrency];
  const targetRate = exchangeRates[targetCurrency];
  if (sourceRate === undefined || targetRate === undefined) {
    return undefined;
  }

  return (value / sourceRate) * targetRate;
};

export const useExchangeCurrency = (): UseExchangeCurrencyReturn => {
  const { accountId } = useAccountContext();
  const { data: exchangeRates, isLoading, isFetching } = useGetExchangeRatesQuery({ accountId: accountId });

  return useMemo(() => {
    const exchangeCurrency = (props: UseExchangeCurrencyProps) =>
      getCostInCurrency({
        ...props,
        exchangeRates: exchangeRates?.rates,
      });
    return {
      exchangeCurrency,
      isLoading,
      isFetching,
    };
  }, [exchangeRates?.rates, isLoading, isFetching]);
};
