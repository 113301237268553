import { ChangeEvent, FC } from 'react';
import { FieldCard } from '../../DynamicFormBuilder';
import { MoneyFieldMetadata, MoneyFieldType } from './types';

export type MoneyFieldBuilderCardProps = {
  field: MoneyFieldType;
  onRemove?: () => void;
  onEdit?: () => void;
  onChange?: (metadata: MoneyFieldMetadata) => void;
};

export const MoneyFieldBuilderCard: FC<MoneyFieldBuilderCardProps> = (props) => {
  const { field, onRemove, onEdit, onChange } = props;

  const handleRequiredChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) onChange({ ...field.metadata, required: checked });
  };

  return (
    <FieldCard>
      <FieldCard.Header>
        <FieldCard.Header.Label fieldType={field.type} required={field.metadata.required}>
          {field.metadata.label}
        </FieldCard.Header.Label>
        <FieldCard.Header.Actions>
          <FieldCard.RequiredSwitchAction checked={field.metadata.required} onChange={handleRequiredChange} />
          <FieldCard.EditAction onClick={onEdit} />
          <FieldCard.RemoveAction onClick={onRemove} />
        </FieldCard.Header.Actions>
      </FieldCard.Header>
    </FieldCard>
  );
};
