import { Dropzone, ErrorMessageList, ExtraActionsSlotProps, FileList, Fileupload } from '@verticeone/design-system';
import useContractDocuments from './useContractDocuments';
import { Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system';
import { validator, customErrorCodes, maxFileBytesSize, acceptedTypes } from './utils';
import { DocumentTypeSelect } from './DocumentTypeSelect';
import { useCallback } from 'react';
import { FileWithMetadata } from './types';

type DocumentUploadProps = {
  withTypeSelect?: boolean;
};

const DocumentUpload = (props?: DocumentUploadProps) => {
  const withTypeSelect = props?.withTypeSelect ?? false;
  const { files, isFetching, uploadFiles, deleteFiles, downloadFile, canEditFiles, setDocumentType } =
    useContractDocuments();

  const extraActions = useCallback(
    ({ file }: ExtraActionsSlotProps) => (
      <DocumentTypeSelect
        value={(file.file as FileWithMetadata).metadata?.classification ?? null}
        onChange={setDocumentType(file.file.name)}
      />
    ),
    [setDocumentType]
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Fileupload
      multiple
      acceptedTypes={acceptedTypes}
      maxFileSize={maxFileBytesSize}
      files={files}
      onAddFiles={uploadFiles}
      onRemoveFiles={deleteFiles}
      validator={validator}
      customErrorCodes={customErrorCodes}
    >
      <Stack gap={4}>
        <FileList
          size="M"
          hideErrorFiles
          dynamicFileNameLength
          onFileNameClick={downloadFile}
          hiddenColumns={canEditFiles ? undefined : ['actions']}
          slots={{
            fileItem: {
              extraActions: canEditFiles && withTypeSelect ? extraActions : undefined,
            },
          }}
        />
        {canEditFiles && (
          <Stack height={210} alignItems="center">
            <Dropzone size="M" />
          </Stack>
        )}
        <ErrorMessageList size="S" />
      </Stack>
    </Fileupload>
  );
};

export default DocumentUpload;
