import { FC, useMemo } from 'react';

import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { JiraProject } from '../JiraCommon/types';
import { EditServiceTaskBase } from '../types';
import { JiraCreateForm } from './JiraCreateForm';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { getRowsCount } from '../../../VariableMapper/utils';
import { getJiraCreateInputMapping } from './utils';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraCreate: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();

  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const availableProjects = integrationData?.parameters?.projects as JiraProject[] | undefined;

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const mapperRowsCount = useMemo(() => {
    if (task) {
      const { variables } = getJiraCreateInputMapping(task);
      return getRowsCount(variables, REQUIRED_JIRA_CREATE_FIELDS, 'to');
    }
  }, [task]);

  return (
    <JiraCreateForm
      task={task}
      {...restProps}
      projectPickerProps={{
        projects: availableProjects ?? [],
      }}
      integrationWrapperProps={{
        isLoading: isLoadingIntegration,
        isActive: integrationData?.status === 'ACTIVE',
      }}
      issueTypePickerProps={{
        issueTypes: [],
        isLoadingIssueTypes: false,
      }}
      variableMapperProps={{
        workflowVariables: {
          udfs: udfVariables,
          request: requestVariables,
        },
        mapperRowsCount,
      }}
    />
  );
};
