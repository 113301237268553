import { fieldDef as moneyFieldDef } from './MoneyField';
import { fieldDef as dropdownFieldDef } from './DropdownField';
import { fieldDef as rollingFrequencyFieldDef } from './RollingFrequencyField';
import { fieldDef as checkboxFieldDef } from './CheckboxField';
import { fieldDef as dateFieldDef } from './DateField';
import { fieldDef as lineItemsFieldDef } from './LineItemsField';
import { fieldDef as shortTextFieldDef } from './ShortTextField';
import { fieldDef as longTextFieldDef } from './LongTextField';
import { fieldDef as numberFieldDef } from './NumberField';
import { fieldDef as radioGroupFieldDef } from './RadioGroupField';
import { fieldDef as fileUploadFieldDef } from './FileUploadField';
import { fieldDef as departmentFieldDef } from './DepartmentField';
import { fieldDef as repeatingFieldDef } from './RepeatingField';
import { fieldDef as dataSourcedFieldDef } from './DataSourcedField';
import { keyBy, Dictionary } from 'lodash';
import { FieldDef } from './types';

export const fieldDefs = [
  checkboxFieldDef,
  dateFieldDef,
  shortTextFieldDef,
  lineItemsFieldDef,
  longTextFieldDef,
  moneyFieldDef,
  numberFieldDef,
  dropdownFieldDef,
  radioGroupFieldDef,
  fileUploadFieldDef,
  repeatingFieldDef,
];

// these are fields that will be eventually be replaced by DataSourcedField presets
export const legacyDatasourcedFieldDefs = [rollingFrequencyFieldDef, departmentFieldDef];

export const fieldDefsByType: Dictionary<FieldDef<any>> = keyBy(
  [
    ...fieldDefs,
    ...legacyDatasourcedFieldDefs,
    dataSourcedFieldDef,
    // there are no presets present after selecting the new field, so they don't need to be here
  ],
  'type'
);
