import { FC } from 'react';
import { CheckboxFieldType } from './types';
import { Stack } from '@mui/material';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import FormChecklistItemField from '../../../fields/FormChecklistItemField';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { DynamicFormPresenterProps } from '../types';

export const CheckboxFieldPresenter: FC<DynamicFormPresenterProps<CheckboxFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, typeof FormChecklistItemField>
        name={field.name}
        component={FormChecklistItemField}
        componentProps={{
          label,
          description,
          size: 'S',
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
        required={required}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
