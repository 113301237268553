import {
  CloudInventoryProvider,
  Tab,
} from '@vertice/dashboard/src/modules/cloud/cards/RIUtilizationCard/useCloudInventory';
import React from 'react';

import CloudInventoryContent from '../../components/CloudInventoryContent';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import { useRoutes } from '@verticeone/router/useRoutes';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import SageMaker from './products/SageMaker';
import Compute from './products/Compute';
import EC2 from './products/EC2';

const CloudInventoryTab = () => {
  const { subFeatures } = useCloudContext();
  const routes = useRoutes();

  const tabs: Tab[] = [];

  if (subFeatures.sprioComputeSP) {
    tabs.push({
      id: 'compute',
      instanceTypePosition: { instanceFamily: 0, size: 1 },
      component: Compute,
    });
  }

  if (subFeatures.sprioEC2SP) {
    tabs.push({
      id: 'ec2',
      instanceTypePosition: { instanceFamily: 1, size: 2 },
      component: EC2,
    });
  }

  if (subFeatures.sprioSagemakerSP) {
    tabs.push({
      id: 'sagemaker',
      instanceTypePosition: { instanceFamily: 1, size: 2 },
      component: SageMaker,
    });
  }

  const routeConfig = {
    path: ROUTES.CLOUD_RIO_SAVINGS_PLANS_DETAIL,
    route: routes.CLOUD.RIO.SP.INVENTORY.DETAIL,
  };

  return (
    <CloudInventoryProvider tabs={tabs} routeConfig={routeConfig}>
      <CloudInventoryContent />
    </CloudInventoryProvider>
  );
};

export default CloudInventoryTab;
