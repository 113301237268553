import { useNavigate } from 'react-router-dom';

import { useDeactivateAccountIntegrationMutation, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isValidIntegrationResponse, SLACK_PROVIDER_ID } from '../../common';
import { useCallback } from 'react';

export const useDeactivateSlackIntegration = (setIsDisconnectDialogOpened: (value: boolean) => void) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const deactivateSlackIntegration = useCallback(async () => {
    const response = await deactivateIntegration({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeIntegration({ accountId, integrationId: SLACK_PROVIDER_ID });
      setIsDisconnectDialogOpened(false);
      navigate(generatePath(routes.INTEGRATIONS.ABSOLUTE_PATH));
    }
  }, [
    accountId,
    deactivateIntegration,
    generatePath,
    navigate,
    revokeIntegration,
    routes.INTEGRATIONS.ABSOLUTE_PATH,
    setIsDisconnectDialogOpened,
  ]);

  return { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress };
};
