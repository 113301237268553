import { FC, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import type { ProcessDefinition, TaskDefinition, VerticeFormConfiguration } from '../../../../definitionsTypes';
import { getTaskFormConfiguration } from '../../../../definitions/taskDefinition';

import { EditUserTaskForm } from '../../forms/EditUserTaskForm/EditUserTaskForm';
import { EditUserTaskFormData, editUserTaskFormSchema } from '../../forms/EditUserTaskForm/schema';
import {
  EditorDrawer,
  EditorDrawerFooter,
  EditorDrawerForm,
  EditorDrawerHeader,
  EditorDrawerScrollContainer,
} from '../../EditorDrawer';
import useAddEditInviteContactModal from '../../../../../preferences/useAddEditInviteContactModal';
import { useAccountContext } from '../../../../../account/AccountContext';
import useGetWorkflowInfoFromParams from './useGetWorkflowInfoFromParams';
import { useCustomFormDefinition } from './useCustomFormDefinition';
import { useTaskFormDefaultValues } from './useTaskFormDefaultValues';
import { useDeployCustomForm } from './useDeployCustomForm';
import { useTaskFormSubmit, UseTaskFormSubmitProps } from './useTaskFormSubmit';
import { Loader } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { FormPreview } from '../../../../../forms/dynamicForms/DynamicFormBuilder/components/FormPreview';
import { AnimatePresence, motion } from 'framer-motion';
import { isGenericFormUrn } from '../../forms/EditUserTaskForm/utils';
import { FormPreviewAlert } from './formPreview/FormPreviewAlert';
import { PreviewFormButton } from './formPreview/PreviewFormButton';
import { CUSTOM_FORM_FIELD_NAME } from '../../forms/EditUserTaskForm/CustomForm/constants';

export type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

type EditUserTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  onClose: VoidFunction;
  onSave: (task: TaskDefinition) => void;
  onDelete: (taskId: string) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
  isAdvancedWorkflowDefinitionEditModeEnabled?: boolean;
  isWorkflowCustomizationEnabled?: boolean;
};

const isPreviewSupportedForForm = (formConfiguration: VerticeFormConfiguration) =>
  isGenericFormUrn(formConfiguration.formUrn);

const EditUserTaskDrawerContent: FC<Omit<Required<EditUserTaskDrawerProps>, 'isOpen'>> = ({
  isAdvancedWorkflowDefinitionEditModeEnabled,
  onDirty,
  onSave,
  onDelete,
  task,
  onClose,
  workflowServiceRef,
  processDefinition,
  editorConfig,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });
  const { accountId } = useAccountContext();
  const { workflowId, workflowVersion } = useGetWorkflowInfoFromParams();

  const formConfiguration = getTaskFormConfiguration(task);
  const { resource: customForm, isLoading } = useCustomFormDefinition({ formUrn: formConfiguration?.formUrn ?? null });

  const { deployCustomForm } = useDeployCustomForm({ accountId });

  const formSubmitConfig = useMemo<UseTaskFormSubmitProps>(() => {
    return { task, customForm, deployCustomForm, accountId, workflowVersion, workflowId, onSave };
  }, [task, customForm, deployCustomForm, accountId, workflowVersion, workflowId, onSave]);

  const { values } = useTaskFormDefaultValues({ task, customForm });
  const { handleSubmit } = useTaskFormSubmit(formSubmitConfig);

  const hookForm = useForm<EditUserTaskFormData>({
    resolver: zodResolver(editUserTaskFormSchema),
    defaultValues: values,
    values,
  });

  const { modal, handleAddRequest } = useAddEditInviteContactModal({
    adminMode: false,
    warningMessage: t('NEW_USER_WARNING'),
  });

  const [previewVisible, setPreviewVisible] = useState(false);
  const isPreviewFormButtonVisible =
    formConfiguration && isPreviewSupportedForForm(formConfiguration) && !previewVisible;
  const deleteTaskOptions = !previewVisible
    ? {
        nodeName: task.task.name ?? '',
        onDelete: () => onDelete(task.task.id),
      }
    : undefined;

  return (
    <>
      <FormProvider {...hookForm}>
        <EditorDrawerForm onSubmit={hookForm.handleSubmit(handleSubmit)}>
          <FormPreviewAlert visible={previewVisible} onClosePreviewClick={() => setPreviewVisible(false)} />
          <EditorDrawerHeader
            title={task.task.name || ''}
            deleteOptions={deleteTaskOptions}
            actions={isPreviewFormButtonVisible && <PreviewFormButton onClick={() => setPreviewVisible(true)} />}
          />
          <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
            {isLoading ? (
              <Stack flex={1} justifyContent="center" alignItems="center">
                <Loader color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />
              </Stack>
            ) : (
              <EditorDrawerScrollContainer noPadding>
                <AnimatePresence mode="popLayout">
                  {previewVisible ? (
                    <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }} key="preview">
                      <FormPreview<EditUserTaskFormData> customFormFieldName={CUSTOM_FORM_FIELD_NAME} />
                    </motion.div>
                  ) : (
                    <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }} key="edit">
                      <Box p={6}>
                        <EditUserTaskForm
                          nodeId={task.task.id}
                          processDefinition={processDefinition}
                          workflowServiceRef={workflowServiceRef}
                          onDirty={onDirty}
                          onAddUser={handleAddRequest}
                          taskName={task?.task.name}
                          formConfiguration={formConfiguration}
                          allowContractOwnerAssignment={editorConfig.allowContractOwnerAssignment}
                          isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
                        />
                      </Box>
                    </motion.div>
                  )}
                </AnimatePresence>
              </EditorDrawerScrollContainer>
            )}
            {!previewVisible && <EditorDrawerFooter onDiscard={onClose} disabled={isLoading} />}
          </Stack>
        </EditorDrawerForm>
      </FormProvider>
      {modal}
    </>
  );
};

export const EditUserTaskDrawer = ({
  isOpen,
  task,
  onClose,
  onSave,
  onDelete,
  onDirty,
  editorConfig,
  processDefinition,
  workflowServiceRef,
  isAdvancedWorkflowDefinitionEditModeEnabled = false,
  isWorkflowCustomizationEnabled = false,
}: EditUserTaskDrawerProps) => {
  return (
    <EditorDrawer open={isOpen}>
      {task && processDefinition && workflowServiceRef && (
        <EditUserTaskDrawerContent
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
          onDirty={onDirty}
          editorConfig={editorConfig}
          task={task}
          isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
          isWorkflowCustomizationEnabled={isWorkflowCustomizationEnabled}
          workflowServiceRef={workflowServiceRef}
          processDefinition={processDefinition}
          key={task.task.id}
        />
      )}
    </EditorDrawer>
  );
};
