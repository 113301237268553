import { FC } from 'react';
import { NodeProps, Node, Position } from '@xyflow/react';
import { NodeControls } from './NodeControls';
import { WorkflowEndNode } from '../../model/types';
import { IconWrapper } from '@verticeone/design-system';
import { StartEndNode } from './Shared';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { useWorkflowRendererContext } from '../WorkflowRendererContext';
import { ConnectionPoint } from './ConnectionPoint';

const CheckIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.core.bg,
  opacity: 0.6,
}));

export const EndNodeComponent: FC<NodeProps<Node<WorkflowEndNode>>> = (props) => {
  const { data } = props;

  const { t } = useTranslation();
  const { isDragAndDropEnabled } = useWorkflowRendererContext();

  return (
    <NodeControls
      {...props}
      content={
        <Stack>
          {isDragAndDropEnabled && (
            <ConnectionPoint
              type="target"
              position={Position.Left}
              connectionsLimit="no-limit"
              id={`${data.id}-connection-target`}
            />
          )}
          <StartEndNode
            withDraggingStyles={isDragAndDropEnabled}
            passed={data.state?.passed}
            label={data.name || t('MODULES.WORKFLOW.NODES.END.DEFAULT_LABEL')}
          >
            {data.state?.passed && <CheckIconWrapper icon={Check} />}
          </StartEndNode>
        </Stack>
      }
    />
  );
};
