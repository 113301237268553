import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import {
  VISOTRUST_CONNECTION_ID,
  VISOTRUST_PROVIDER_ID,
} from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import { CardsList } from '../../components/CardsList';
import { CardWrapper } from '../../components/CardWrapper';
import { SectionWrapper } from '../../components/SectionWrapper';
import { getActiveCards } from '../../utils';
import { JiraSetupCard } from './Jira';
import { JIRA_PROVIDER_ID } from './Jira/constants';
import { VisoTrustSetupCard } from './VisoTrust';

export const Workflows = () => {
  const { t } = useTranslation();

  const { isEnabled } = useFeatures();
  const cards = [
    { component: <JiraSetupCard providerId={JIRA_PROVIDER_ID} />, enabled: isEnabled(FEATURES.INTEGRATION_JIRA) },
    {
      component: <VisoTrustSetupCard providerId={VISOTRUST_PROVIDER_ID} connectionId={VISOTRUST_CONNECTION_ID} />,
      enabled: isEnabled(FEATURES.INTEGRATION_VISOTRUST),
    },
  ];
  const activeCards = getActiveCards(cards);

  if (activeCards.length === 0) return null;

  return (
    <SectionWrapper title={t('INTEGRATIONS.WORKFLOWS')}>
      <CardsList>
        {activeCards.map((card, index) => (
          <CardWrapper key={index}>{card}</CardWrapper>
        ))}
      </CardsList>
    </SectionWrapper>
  );
};
