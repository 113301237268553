import type { FC } from 'react';
import { Stack } from '@mui/material';
import { IconWrapper, Text } from '@verticeone/design-system';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { useFormatDate } from '@verticeone/utils/formatting';

type TaskDateProps = {
  closedAt: string;
  textColor: string;
};

export const TaskDate: FC<TaskDateProps> = ({ closedAt, textColor }) => {
  const formatDate = useFormatDate();

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <IconWrapper icon={CalendarTodayOutlined} htmlColor={textColor} size="S" />
      <Text variant="button-bold" size="XS" color={textColor}>
        {formatDate(closedAt)}
      </Text>
    </Stack>
  );
};
