import * as z from 'zod';
import { PROTOCOL } from '../components/HostnameField';

export const hasProtocol = (url: string) => !/^[a-zA-Z][a-zA-Z0-9+.-]*:\/\//.test(url);

export const createSchema = z.object({
  name: z.string().min(1),
  auth: z.object({
    apiKey: z.string().min(1),
    authorizationHeaderName: z.string().min(1),
    baseUrl: z.object({
      protocol: z.literal(PROTOCOL),
      hostname: z.string().min(1).refine(hasProtocol, { message: 'protocolError' }),
      path: z.string(),
    }),
  }),
});

export const editSchema = createSchema.extend({
  auth: createSchema.shape.auth.extend({
    apiKey: z.string().optional(),
  }),
});

export type EditSchema = z.infer<typeof editSchema>;
export type CreateSchema = z.infer<typeof createSchema>;
