import { useLoggedUser } from '@verticeone/auth/src';
import { useUserOwnedDepartments } from '../useUserOwnedDepartments';
import { useAccountDepartments } from '../useAccountDepartments';
import { Department } from '../types';
import { Loadable } from '@verticeone/utils/async';

export type UseDepartmentOptionsParams = { onlyOwnedDepartments?: boolean };

export const useDepartmentOptions = ({ onlyOwnedDepartments = false }: UseDepartmentOptionsParams = {}): Loadable<
  Department[]
> => {
  const { userId } = useLoggedUser();
  const userOwnedDepartmentsQuery = useUserOwnedDepartments({ userId, skip: !onlyOwnedDepartments });
  const allDepartmentsQuery = useAccountDepartments({ skip: onlyOwnedDepartments });

  return userOwnedDepartmentsQuery.isLoading || allDepartmentsQuery.isLoading
    ? { isLoading: true }
    : {
        isLoading: false,
        data: (onlyOwnedDepartments ? userOwnedDepartmentsQuery.departments : allDepartmentsQuery.data) ?? [],
      };
};
