import { ResourceThumbnail } from '@vertice/slices/src/openapi/codegen/catalogAPI';

export type ComingSoonTemplate = {
  urn: string;
  name: string;
  definition: {
    Service: {
      Description: string;
    };
    Category: string;
    Thumbnail: ResourceThumbnail;
  };
};

export const COMING_SOON_TEMPLATES: ComingSoonTemplate[] = [
  {
    name: 'NDA Agreement',
    urn: 'nda_agreement',
    definition: {
      Category: 'Procurement',
      Service: {
        Description: 'Automatically send and manage NDA agreement fulfillment',
      },
      Thumbnail: {
        Type: 'PREDEFINED',
        Id: 'nda-agreement',
      },
    },
  },
  {
    name: 'RF[x] Playbook',
    urn: 'rfx_playbook',
    definition: {
      Category: 'Procurement',
      Service: {
        Description:
          'Build and manage your RFI/RFP/RFX playbooks. Automate and manage data collection, Vendor scoring and selection',
      },
      Thumbnail: {
        Type: 'PREDEFINED',
        Id: 'rfx-playbook',
      },
    },
  },
  {
    name: 'Hire External Contractor',
    urn: 'hire_external_contractor',
    definition: {
      Category: 'Resourcing',
      Service: {
        Description:
          'Simplify, automate and manage your contractor resourcing process with Vertice Intelligent Workflows',
      },
      Thumbnail: {
        Type: 'PREDEFINED',
        Id: 'hire-external-contractor',
      },
    },
  },
];
