import type { SingleValue } from 'react-select';
import type { FieldValues } from 'react-hook-form';

import type { SelectColor, SimpleOption } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import useBillingFrequencyOptions from '@vertice/core/src/modules/saas/contract/hooks/useBillingFrequencyOptions';
import { TaskFormEntry } from './TaskFormEntry';
import type { CommonFormEntryProps, SingleSelectComponent } from './types';

export type BillingFrequency = 'MONTHLY' | 'QUARTERLY' | 'ANNUALLY' | 'BIANNUALLY' | 'ONE_OFF' | 'OTHER';
export const BILLING_FREQUENCY_OPTIONS: [BillingFrequency, ...BillingFrequency[]] = [
  'MONTHLY',
  'QUARTERLY',
  'ANNUALLY',
  'BIANNUALLY',
  'ONE_OFF',
  'OTHER',
];

export const BillingFrequencyFormEntry = <FormDataType extends FieldValues>({
  name,
  label,
}: CommonFormEntryProps<FormDataType>) => {
  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  return (
    <TaskFormEntry<any, SingleSelectComponent>
      name={name}
      label={label}
      component={FormSelectField2}
      componentProps={{
        isClearable: true,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR as SelectColor,
        options: billingFrequencyOpts,
        boxValue: (value) => (value ? billingFrequencyMap[value] : undefined),
        unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
      }}
      width={6}
    />
  );
};
