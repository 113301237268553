import { z, ZodObject, ZodRawShape } from 'zod';
import { RollingFrequencyFieldMetadata, RollingFrequencyFieldType, TYPES } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';

export function getRollingFrequencyPresenterSchema(field: RollingFrequencyFieldType): ZodObject<ZodRawShape> {
  const enumValue = z.custom<string>((val) => TYPES.includes(val) ?? false);

  return z.object({
    [field.name]: field.metadata.required ? enumValue : enumValue.nullish(),
  });
}

export function getRollingFrequencyPresenterDefault({
  field,
  defaultValues,
}: GetPresenterDefaultProps<RollingFrequencyFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getRollingFrequencyJsonSchemaFromMetadata(metadata: RollingFrequencyFieldMetadata): JsonSchema {
  return {
    type: ['string', 'null'],
    'x-type': 'urn:verticeone:vertice::services:schema/core/rollingFrequency/v1',
    title: metadata.label,
  };
}

export function getRollingFrequencyJsonSchema(field: RollingFrequencyFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getRollingFrequencyJsonSchemaFromMetadata(field.metadata) },
  };
}
