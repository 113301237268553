import { useTranslation } from 'react-i18next';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

import { SlackUser, UserIdentity } from '@vertice/slices';
import { GridSelect } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { SlackConnection } from '../../../common';
import { Stack } from '@mui/material';

type SlackUserSelectCellProps = {
  connectedUsers?: UserIdentity[];
} & GridRenderCellParams<SlackConnection>;

const filterSlackUsers = (users: SlackUser[], connectedUsers?: UserIdentity[]) => {
  return users
    .filter((user) => user.status === 'ACTIVE')
    .filter((user) => !connectedUsers?.some((connectedUser) => connectedUser.identityId === user.userId));
};

export const SlackUserSelectCell = ({
  connectedUsers,
  row: { userId, slackUsers },
  field,
}: SlackUserSelectCellProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  return (
    <GridSelect<SlackUser, false>
      options={filterSlackUsers(slackUsers, connectedUsers)}
      formatOptionLabel={(option) => (
        <Stack>
          <Text variant="body-regular" size="M" color="text1">
            {option.email}
          </Text>
          <Text variant="body-bold" size="XS" color="text1" sx={{ opacity: 0.4 }}>
            {option.userName}
          </Text>
        </Stack>
      )}
      getOptionValue={(option) => `${option.email} ${option.userName}`}
      onChange={(option) => {
        void apiRef.current.setEditCellValue({ id: userId, field, value: option?.email });
      }}
      placeholder={
        <Text variant="body-regular" size="M" color="text3">
          {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.SLACK_SELECT_PLACEHOLDER')}
        </Text>
      }
      isClearable
      isMulti={false}
      maxMenuHeight={450}
    />
  );
};
