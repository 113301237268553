import React from 'react';
import { useController, Validate } from 'react-hook-form';
import { TextField } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type FormTextFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> &
  React.ComponentProps<typeof TextField> & {
    validate?: Validate<string, FormDataType> | Record<string, Validate<string, FormDataType>>;
  };

export type FormTextFieldType<FormDataType extends FieldValues> = (
  props: FormTextFieldProps<FormDataType>
) => JSX.Element;

const FormTextField = <FormDataType extends FieldValues = never>({
  id,
  validate,
  required,
  disabled,
  name,
  labelId, // We don't want to further pass the labelId
  ...otherProps
}: FormTextFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
    fieldState: { invalid, error },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
      validate,
    },
  });

  return (
    <TextField
      ref={ref}
      id={id}
      value={value === null ? '' : value}
      error={invalid}
      helperText={invalid && error?.message}
      fullWidth
      variant="outlined"
      hiddenLabel
      InputProps={{ sx: { padding: 0 } }} // hide padding on textarea for multiline inputs
      disabled={disabled}
      {...field}
      {...otherProps}
    />
  );
};

export default FormTextField;
