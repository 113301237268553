import { EditServiceTaskBase } from '../types';
import { GenericIntegrationForm } from './form/GenericIntegrationForm';
import { useGetVariableList, useTransformFormToTaskDefinition, useTransformTaskDefinitionToForm } from './utils';

export const GenericIntegration = ({
  task,
  processDefinition,
  workflowServiceRef,
  onSave,
  onClose,
  onDelete,
}: EditServiceTaskBase) => {
  const formToTaskDefinitionTransform = useTransformFormToTaskDefinition(task);
  const { variables, isFetching } = useGetVariableList({ task, processDefinition, workflowServiceRef });
  const defaultValues = useTransformTaskDefinitionToForm({ task, variables });

  if (isFetching) {
    return null;
  }

  return (
    <GenericIntegrationForm
      task={task}
      onClose={onClose}
      defaultValues={defaultValues}
      onDelete={onDelete}
      onSave={(formData) => onSave(formToTaskDefinitionTransform(formData))}
      processDefinition={processDefinition}
      workflowServiceRef={workflowServiceRef}
    />
  );
};
