import { useFormContext, useWatch } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { IntakeLineItemsTable } from './IntakeLineItemsTable';
import { IntakeProductList } from 'pages/IntakeFormWizards/SharedComponents/IntakeProductList';
import { AddExistingContractFormData } from '../types';

/**
 * This component renders either ProductList or LineItemTable with cost columns.
 */
export const IntakeCostModelTable = () => {
  const { control, setValue } = useFormContext<AddExistingContractFormData>();
  const category = useWatch({ control, name: 'contractCategory' });
  const vendor = useWatch({ control, name: 'vendor' });

  useEffect(() => {
    if (category) {
      // Clear products or lineItems if contract category changes
      setValue(category === 'OTHER' ? 'products' : 'lineItems', []);
    }
  }, [category, setValue]);

  if (category === 'OTHER') {
    return (
      <Grid item xs={12}>
        <IntakeLineItemsTable />
      </Grid>
    );
  }

  if (vendor && vendor.type !== 'INLINE') {
    return (
      <Grid item xs={12}>
        <IntakeProductList />
      </Grid>
    );
  }

  return null;
};
