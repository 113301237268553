import { FC } from 'react';
import { LongTextFieldType } from './types';
import { Stack } from '@mui/material';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import FormEditorField, { FormEditorFieldComponentType } from '../../../fields/FormEditorField';
import { DynamicFormPresenterProps } from '../types';

export const LongTextFieldPresenter: FC<DynamicFormPresenterProps<LongTextFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormEditorFieldComponentType<FieldValues>>
        name={field.name}
        component={FormEditorField}
        componentProps={{
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
          textLimit: 10000,
        }}
        label={label}
        description={description}
        required={required}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
