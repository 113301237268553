import { useContext, createContext } from 'react';

import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

export type WorkflowsTableContextType = {
  liveWorkflowVersion?: WorkflowVersion;
  updatingWorkflowVersionId?: string;
  setLastEditedWorkflowVersionId?: (lastEditedWorkflowVersionId: string) => void;
};

const WorkflowsTableContext = createContext<WorkflowsTableContextType>({
  liveWorkflowVersion: undefined,
  updatingWorkflowVersionId: undefined,
  setLastEditedWorkflowVersionId: undefined,
});

const WorkflowsTableProvider = WorkflowsTableContext.Provider;

const useWorkflowsTableContext = () => useContext(WorkflowsTableContext);

export { WorkflowsTableProvider, useWorkflowsTableContext };
