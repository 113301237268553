import { FC, ReactNode } from 'react';
import { NoActiveIntegration } from './NoActiveIntegration';
import { Box, Stack } from '@mui/material';
import { Loader } from '@verticeone/design-system';

export type IntegrationWrapperBaseProps = { isActive: boolean; isLoading: boolean };

type IntegrationWrapperProps = IntegrationWrapperBaseProps & {
  children: ReactNode;
};

export const IntegrationWrapper: FC<IntegrationWrapperProps> = ({ isLoading, isActive, children }) => {
  if (isLoading) {
    return (
      <Stack sx={{ alignItems: 'center', pt: 8 }}>
        <Loader />
      </Stack>
    );
  }

  return (
    <>
      {isActive ? (
        <Stack>
          <Box sx={{ flexGrow: 1 }}>{children}</Box>
        </Stack>
      ) : (
        <NoActiveIntegration />
      )}
    </>
  );
};
