import { Divider } from '@verticeone/design-system';
import { Grid } from '@verticeone/design-system';
import NegotiatedAnnualCostFormEntry from '../../form/NegotiatedAnnualCostFormEntry';
import NegotiatedAnnualCostOverrideFormEntry from '../../form/NegotiatedAnnualCostOverrideFormEntry';
import BaselineAnnualCostFormEntry from '../../form/BaselineAnnualCostFormEntry';
import BaselineAnnualCostOverrideFormEntry from '../../form/BaselineAnnualCostOverrideFormEntry';
import AnnualSavingsContractFormEntry from '../../form/AnnualSavingsContractFormEntry';

/**
 * Shows sections, where are contract anualized costs. Meaning the negotiated offer,
 * baseline offer from Vendor and the difference. This should be visible for contracts
 * with rolling frequency or fixed term.
 */
export const AnnualCostDetails = () => {
  return (
    <>
      <Grid container direction="row" spacing={8} px={6}>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <>
            <NegotiatedAnnualCostFormEntry />
            <NegotiatedAnnualCostOverrideFormEntry />
          </>
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <>
            <BaselineAnnualCostFormEntry />
            <BaselineAnnualCostOverrideFormEntry />
          </>
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <AnnualSavingsContractFormEntry />
        </Grid>
      </Grid>

      <Divider />
    </>
  );
};
