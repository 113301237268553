import { useTranslation } from 'react-i18next';
import { useFormatDate } from '@verticeone/utils/formatting';
import { isAfter } from 'date-fns';

import type { TaskNodeState } from '../../../../../model/types';
import { useWorkflowRendererContext } from '../../../../WorkflowRendererContext';
import { findTaskDefinition } from '../../taskDefinitionUtils';
import { isDueDateConfiguration } from '../../../../../../definitions/taskDefinition';
import { getDurationFromDueDateConfiguration } from '../../../../../../definitions/utils';
import { useDurationFormatters } from '../../../../../../hooks/useDurationFormatters';
import { DesignSystemColor } from '@verticeone/design-system';

type DueDateLabel = {
  label: string;
  colorVariant: DesignSystemColor | 'text';
  note?: string;
  tooltip?: string;
  isInfoTooltipDisplayed?: boolean;
};

export const useTaskDueDateLabel = ({
  taskId,
  taskState,
}: {
  taskId: string;
  taskState?: TaskNodeState;
}): DueDateLabel => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES' });

  const formatDate = useFormatDate();
  const { formatDurationBetweenDates, formatDuration } = useDurationFormatters();

  const { workflowDefinitions, isEditor } = useWorkflowRendererContext();

  const defaultLabel = {
    label: t('NO_DUE_DATE'),
    colorVariant: isEditor ? ('info' as const) : ('text' as const),
    isInfoTooltipDisplayed: !isEditor,
  };

  if (taskState) {
    const { status, closedAt, dueDate: dueAt, createdAt } = taskState;

    const dueAtDate = dueAt ? new Date(dueAt) : undefined;
    const closedAtDate = closedAt ? new Date(closedAt) : undefined;

    if (status === 'COMPLETED' && closedAtDate) {
      const isDueDateAfterCompletedDate = dueAtDate ? isAfter(dueAtDate, closedAtDate) : true;

      return {
        label: t('COMPLETED', {
          date: formatDate(closedAt, {
            formatterOptions: {
              day: 'numeric',
              month: 'short',
            },
          }),
        }),
        ...(isDueDateAfterCompletedDate
          ? {
              colorVariant: 'success',
            }
          : {
              colorVariant: 'error',
              tooltip: dueAtDate
                ? t('COMPLETED_LATE', {
                    duration: formatDurationBetweenDates({
                      end: closedAtDate,
                      start: dueAtDate,
                    }),
                  })
                : undefined,
            }),
      };
    }

    if (status === 'ACTIVE') {
      if (!dueAtDate) {
        return defaultLabel;
      }

      const isDueDateAfterNow = isAfter(dueAtDate, new Date());

      if (isDueDateAfterNow) {
        return {
          label: t('DUE', {
            date: formatDate(dueAt, {
              formatterOptions: {
                day: 'numeric',
                month: 'short',
              },
            }),
          }),
          colorVariant: 'text',
          tooltip: t('DUE_IN', {
            duration: formatDurationBetweenDates({
              end: dueAtDate,
              start: new Date(),
            }),
          }),
        };
      }

      return {
        label: t('DUE', {
          date: formatDate(dueAt, {
            formatterOptions: {
              day: 'numeric',
              month: 'short',
            },
          }),
        }),
        colorVariant: 'error',
        note: t('OVERDUE', {
          duration: formatDurationBetweenDates({
            end: new Date(),
            start: dueAtDate,
          }),
        }),
        tooltip: createdAt
          ? t('ACTIVE_FOR', {
              duration: formatDurationBetweenDates({ start: new Date(createdAt), end: new Date() }),
            })
          : undefined,
      };
    }
  }

  const taskDefinition = findTaskDefinition(workflowDefinitions, taskId);

  if (!taskDefinition) {
    return defaultLabel;
  }

  const taskDueDateConfiguration = taskDefinition.task.configurations?.find(isDueDateConfiguration);

  if (!taskDueDateConfiguration) {
    return defaultLabel;
  }

  const dueDateDurationFormatted = formatDuration({
    duration: getDurationFromDueDateConfiguration(taskDueDateConfiguration),
    format: ['days'],
  });

  return {
    label: t('DURATION_TO_COMPLETE', { duration: dueDateDurationFormatted }),
    colorVariant: 'text',
  };
};
