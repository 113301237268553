import GraphHeader from './GraphHeader';
import { PropsWithChildren } from 'react';
import { GraphProvider } from '../providers/GraphProvider';
import { Stack, useTheme } from '@mui/material';
import { Card } from '@verticeone/design-system';

export type GraphLayoutProps = {
  shoppingCartUnits?: number;
  forceOnDemandUsageOnly?: boolean;
};

const GraphLayout = ({
  shoppingCartUnits,
  forceOnDemandUsageOnly = false,
  children,
}: PropsWithChildren & GraphLayoutProps) => {
  const { palette } = useTheme();

  return (
    <GraphProvider shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={forceOnDemandUsageOnly}>
      <Card>
        <Stack bgcolor={palette.core.color1} height={492}>
          <GraphHeader />
          <Stack justifyContent="space-between" height="100%">
            {children}
          </Stack>
        </Stack>
      </Card>
    </GraphProvider>
  );
};

export default GraphLayout;
