import { useState } from 'react';
import { Template } from '../types';
import { RequestTemplateDialog } from '../components/dialogs/RequestTemplateDialog';

type RequestTemplateFunctionParams = {
  template: Template;
};

export const useRequestTemplate = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [template, setTemplateId] = useState<Template | null>(null);

  const requestTemplate = ({ template: templateToRequest }: RequestTemplateFunctionParams) => {
    setIsDialogOpen(true);
    setTemplateId(templateToRequest);
  };

  const closeRequest = () => {
    setIsDialogOpen(false);
    setTemplateId(null);
  };

  return {
    requestTemplate,
    closeRequest,
    RequestTemplateDialog: () => (
      <RequestTemplateDialog isOpen={isDialogOpen} onClose={closeRequest} template={template} />
    ),
  };
};
