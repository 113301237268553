import { FC, ReactNode } from 'react';
import { Stack, styled } from '@mui/material';
import { ServiceCardContent } from './ServiceCardContent';
import type { ServiceCatalogResource } from '../../../catalogResource/types';

export const StyledStackCard = styled(Stack)(() => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'unset',
  flexBasis: 320,
  flexGrow: 0,
  flexShrink: 0,
}));

type ServiceCardProps = {
  service: ServiceCatalogResource;
  onClick?: (serviceRef: string) => void;
  actions: ReactNode;
};

export const ServiceCard: FC<ServiceCardProps> = ({ service, onClick, actions }) => {
  return (
    <StyledStackCard onClick={() => onClick?.(service.urn)} style={{ cursor: !!onClick ? 'pointer' : 'default' }}>
      <ServiceCardContent service={service} actions={actions} />
    </StyledStackCard>
  );
};
