import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import clsx from 'clsx';
import {
  DataGridProProps,
  GridColDef,
  GridComparatorFn,
  GridInitialState,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { Box, Checkbox, Radio, Typography } from '@mui/material';
import { Check as CheckIcon, ForwardToInbox as ForwardToInboxIcon } from '@mui/icons-material';
import { AccountUser, useListAccountUsersQuery, useRefreshUserInvitationMutation } from '@vertice/slices';
import Loader from '@vertice/components/src/Loader/Loader';
import DataGrid from '@vertice/components/src/DataGrid';
import { MeFlag } from '@vertice/components/src/MeFlag';
import { emailRegexp } from '../utils/oldValidators';
import { getFullName } from '@verticeone/utils/formatting';
import { useLoggedUser } from '@verticeone/auth/src';
import { createUsersMap } from './utils';
import CustomFooter from './CustomFooter';
import styles from './GridList.module.scss';
import { USER_ROLES } from '@vertice/core/src/constants/userRoles';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

enum InviteStatus {
  Resend = 'resend',
  RefreshingInvite = 'refreshingInvite',
  Sent = 'sent',
  Failed = 'failed',
}

export interface UpdatedItem {
  itemId: string;
  selected?: boolean;
}

export type columnName =
  | 'handler'
  | 'userId'
  | 'userName'
  | 'email'
  | 'jobTitle'
  | 'userRole'
  | 'userStatus'
  | 'directoryStatus'
  | 'ssoStatus';

interface RowControlProps {
  rowClassName: (params: GridRowParams) => string;
  rowControlDisabled: (params: GridRenderCellParams<AccountUser, AccountUser>) => boolean;
  dataLoading: () => boolean;
}

export interface UserListProps {
  except?: string[];
  addedContact?: UpdatedItem | null;

  multiple?: boolean;
  value?: string[];
  onChange?: (users: AccountUser[]) => void;

  hideProxyContacts?: boolean;
  allowedColumns: columnName[];
  extraColumns?: GridColDef[];
  dataGridExtraProps?: Partial<DataGridProProps>;
  dataTestId?: string;
  rowControlProps?: Partial<RowControlProps>;
}

const renderCommonCell = (params: GridRenderCellParams<any, string | undefined>) => (
  <div>
    <Typography variant="body-regular-m">{params.value}</Typography>
  </div>
);

const userNameCell = (params: GridRenderCellParams<any, string | undefined>) => {
  const { userId } = params.row;

  return (
    <div>
      <Typography variant="body-bold-m">{params.value}</Typography>
      <MeFlag userId={userId} />
    </div>
  );
};

const sortByRole: GridComparatorFn<string[] | undefined> = (user1, user2) => {
  const isUser1HasRole = user1 && user1.length;
  const isUser2HasRole = user2 && user2.length;
  if (!isUser1HasRole && !isUser2HasRole) {
    return 0;
  }
  if (!isUser1HasRole) {
    return -1;
  }
  if (!isUser2HasRole) {
    return 1;
  }
  if (user1[0] < user2[0]) return -1;
  if (user1[0] > user2[0]) return 1;
  return 0;
};

const UserList: FC<UserListProps> = ({
  multiple,
  value = [],
  onChange,
  except = [],
  addedContact = null,
  allowedColumns = [],
  hideProxyContacts = false,
  extraColumns = [],
  dataGridExtraProps = {},
  dataTestId,
  rowControlProps = {},
}) => {
  const controlDisabled = rowControlProps?.rowControlDisabled || (() => false);
  const rowClassName = rowControlProps?.rowClassName || (() => '');
  const childLoading = rowControlProps?.dataLoading || (() => false);
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId: currentUserId } = useLoggedUser();
  const [inviteStatusMap, setInviteStatusMap] = useState<{ [key: string]: InviteStatus }>({});

  const userRoleTranslationMap = {
    [USER_ROLES.admin]: t('PREFERENCES.WORKFLOW.USER_TABLE.ROLES.ADMIN'),
    [USER_ROLES.powerUser]: t('PREFERENCES.WORKFLOW.USER_TABLE.ROLES.POWER_USER'),
    [USER_ROLES.user]: t('PREFERENCES.WORKFLOW.USER_TABLE.ROLES.USER'),
  };

  const [refreshUserInvitationMutation] = useRefreshUserInvitationMutation();

  const { data, isLoading, isFetching } = useListAccountUsersQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );

  const users = (data?.users || []).filter(
    (user) => !except.includes(user.userId) && (hideProxyContacts ? !user.userId?.includes('PROXY') : !!user)
  );

  const usersMap = createUsersMap(data?.users || []);

  const sortedUsers = users.reduce((acc: AccountUser[], user: AccountUser) => {
    if (user.userId === currentUserId) {
      return [user, ...acc];
    }

    // Keep the last added item at the end
    if (addedContact && acc.length > 0) {
      const lastItem = acc[acc.length - 1];
      if (lastItem.userId === addedContact.itemId) {
        return [...acc.slice(0, -1), user, lastItem];
      }
    }

    return [...acc, user];
  }, []);

  const multipleBase = (multiple ? value : [value[0]]) as Array<string>;
  const initialValue = Array.isArray(value) ? multipleBase : [value];

  const [selected, setSelected] = useState<Array<string>>(initialValue);

  useEffect(() => {
    if (addedContact?.selected) {
      setSelected(multiple ? [...selected, addedContact.itemId] : [addedContact.itemId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedContact]);

  const handleUsersChange = (newSelection: string[]) => {
    setSelected(newSelection);

    const selectedUsers = newSelection.reduce((acc: AccountUser[], id) => {
      const selectedUser = usersMap.get(id);
      if (selectedUser) {
        acc.push(selectedUser);
      }
      return acc;
    }, []);

    if (onChange) {
      onChange(selectedUsers);
    }
  };

  const onInviteError = (resendUserId: string) =>
    setInviteStatusMap({
      ...inviteStatusMap,
      [resendUserId]: InviteStatus.Failed,
    });

  const onResendInvite = (resendAccountId: string, resendUserId: string) => {
    setInviteStatusMap({
      ...inviteStatusMap,
      [resendUserId]: InviteStatus.RefreshingInvite,
    });

    refreshUserInvitationMutation({
      accountId: resendAccountId,
      userId: resendUserId,
      body: {},
    }).then(
      (res) => {
        if ((res as { error: FetchBaseQueryError | SerializedError }).error) {
          onInviteError(resendUserId);
        } else {
          setInviteStatusMap({
            ...inviteStatusMap,
            [resendUserId]: InviteStatus.Sent,
          });
        }
      },
      () => onInviteError(resendUserId)
    );
  };

  const updateChecked = (userId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const position = selected.findIndex((param) => param === userId);
    if (position === -1) {
      handleUsersChange([...selected, userId]);
    } else {
      handleUsersChange([...selected.slice(0, position), ...selected.slice(position + 1)]);
    }
  };

  const updateRadio = (userId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (!selected.includes(userId)) {
      handleUsersChange([userId]);
    }
  };

  const radioButtonCell = (params: GridRenderCellParams<AccountUser, AccountUser>) => {
    const { userId } = params.row;
    const checked = selected.includes(userId);
    const disabled = controlDisabled(params);

    return (
      <Radio
        checked={checked}
        onChange={updateRadio(userId)}
        name="default-owner"
        className={clsx(styles['radio-button'], { [styles['disabled-svg']]: disabled })}
        disabled={disabled}
      />
    );
  };

  const checkboxCell = (params: GridRenderCellParams<AccountUser, AccountUser>) => {
    const { userId } = params.row;
    const checked = selected.includes(userId);
    const disabled = controlDisabled(params);

    return (
      <Checkbox
        checked={checked}
        onChange={updateChecked(userId)}
        className={clsx(styles.checkbox, { [styles['disabled-svg']]: disabled })}
        disabled={disabled}
      />
    );
  };

  const userRoleCell = (params: GridRenderCellParams<AccountUser, AccountUser>) => (
    <div>
      <Typography className={styles['user-list-role']} variant="body-regular-m">
        {params.row.userRoles?.map((userRole) => userRoleTranslationMap[userRole]).join(', ')}
      </Typography>
    </div>
  );

  const userStatusCell = (params: GridRenderCellParams<AccountUser, AccountUser>) => {
    const inviteStatus = inviteStatusMap[params.row.userId];
    return (
      <div className={styles['user-status']}>
        <Typography
          className={
            styles[
              `user-status-${
                params.row.directoryStatus?.startsWith('INVITED') ? 'invited' : params.row.userStatus?.toLowerCase()
              }`
            ]
          }
          variant="body-bold-m"
        >
          {t(
            `PREFERENCES.WORKFLOW.USER_TABLE.USER_STATUS_MAP.${
              params.row.directoryStatus?.startsWith('INVITED') ? 'PENDING' : params.row.userStatus
            }`
          )}
        </Typography>

        <div className={styles['invite-container']}>
          {(!inviteStatus || inviteStatus === InviteStatus.Resend) &&
            params.row.directoryStatus?.startsWith('INVITED') && (
              <div onClick={() => onResendInvite(accountId!, params.row.userId)}>
                <Typography variant="body-bold-s" className={clsx(styles['invite-label'], styles.resend)}>
                  {t(`PREFERENCES.WORKFLOW.USER_TABLE.RESEND_INVITATION`)}
                </Typography>

                <ForwardToInboxIcon className={clsx(styles['invite-icon'], styles['resend-icon'])} />
              </div>
            )}

          {inviteStatus === InviteStatus.RefreshingInvite && <Loader size={16} />}

          {inviteStatus === InviteStatus.Sent && (
            <div>
              <Typography variant="body-bold-s" className={clsx(styles['invite-label'], styles.sent)}>
                {t(`PREFERENCES.WORKFLOW.USER_TABLE.INVITATION_SENT`)}
              </Typography>

              <CheckIcon className={clsx(styles['invite-icon'], styles['sent-icon'])} />
            </div>
          )}

          {inviteStatus === InviteStatus.Failed && (
            <div>
              <Typography variant="body-bold-s" className={clsx(styles['invite-label'], styles.failed)}>
                {t(`PREFERENCES.WORKFLOW.USER_TABLE.INVITATION_FAILED`)}
              </Typography>

              <ForwardToInboxIcon className={clsx(styles['invite-icon'], styles['failed-icon'])} />
            </div>
          )}
        </div>
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns: GridColDef[] = [
    {
      field: 'handler',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      renderCell: multiple ? checkboxCell : radioButtonCell,
      width: 70,
    },
    {
      field: 'userName',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.NAME'),
      renderCell: userNameCell,
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) =>
        getFullName(params.row) || params.row.userName,
      cellClassName: 'black-text-cell',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.ID'),
      renderCell: renderCommonCell,
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) => params.row.userId,
      cellClassName: 'gray-text-cell',
      flex: 1,
    },
    {
      field: 'email',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.EMAIL'),
      renderCell: renderCommonCell,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = !emailRegexp.test(params.props.value);
        return { ...params.props, error: hasError };
      },
      cellClassName: 'black-text-cell',
      flex: 1,
    },
    {
      field: 'jobTitle',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.JOB_TITLE'),
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) =>
        params.row.accountUserProperties?.jobTitle,
      renderCell: renderCommonCell,
      cellClassName: 'black-text-cell',
      flex: 1,
    },
    {
      field: 'userRole',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.USER_ROLE'),
      renderCell: userRoleCell,
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) => params.row.userRoles,
      cellClassName: 'black-text-cell',
      flex: 1,
      sortComparator: sortByRole,
    },
    {
      field: 'userStatus',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.USER_STATUS'),
      renderCell: userStatusCell,
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) => params.row.userStatus,
      flex: 1,
      sortComparator: sortByRole,
    },
    {
      field: 'directoryStatus',
      headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.DIRECTORY_STATUS'),
      renderCell: renderCommonCell,
      valueGetter: (params: GridValueGetterParams<AccountUser, AccountUser>) => {
        const translation: Record<string, string> = {
          NOT_APPLICABLE: 'N/A',
          INACTIVE: 'Inactive',
          INVITED_MANAGED: 'Vertice Invited',
          INVITED_SSO: 'SSO Invited',
          INVITED_SSO_MIGRATION: 'SSO Migrating',
          ACTIVE_SSO_MIGRATION: 'SSO Active',
          ACTIVE_MANAGED: 'Vertice Active',
          ACTIVE_SSO: 'SSO Active',
          ACTIVE_VERTICE_EMPLOYEE: 'Vertice Employee',
        };
        return params.row.directoryStatus ? translation[params.row.directoryStatus] : 'N/A';
      },
      cellClassName: 'gray-text-cell',
      flex: 1,
    },
  ];

  const filteredColumns = useMemo(
    () => columns.filter(({ field }) => allowedColumns.includes(field as columnName)),
    [columns, allowedColumns]
  );

  const columnsToUse = [...filteredColumns, ...extraColumns];

  const getRowId = (row: GridRowModel) => row.userId;

  const initialState: GridInitialState = {
    sorting: {
      sortModel: [
        {
          field: 'userName',
          sort: 'asc',
        },
      ],
    },
  };

  if (!accountId || isLoading || isFetching || !childLoading) {
    return (
      <Box p={6} className={styles['grid-list']}>
        <Loader />
      </Box>
    );
  }

  return (
    <div data-test-id={dataTestId}>
      <DataGrid
        className={styles['grid-list']}
        rows={sortedUsers}
        columns={columnsToUse}
        getRowId={getRowId}
        initialState={initialState}
        slots={{
          footer: CustomFooter,
        }}
        sortingMode="client"
        hideFooter
        disableRowSelectionOnClick
        getRowClassName={rowClassName}
        {...dataGridExtraProps}
      />
    </div>
  );
};

export default UserList;
