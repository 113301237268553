import React from 'react';
import { NoData, Text } from '@verticeone/design-system';
import { SvgIconComponent } from '@mui/icons-material';
import { Stack, styled, useTheme } from '@mui/material';
import { svgIconClasses } from '@mui/material/SvgIcon';

type Variant = 'default' | 'success';

const StyledIconWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  padding: 16,
  borderRadius: 12,
  [`& .${svgIconClasses.root}, svg`]: {
    height: '24px',
    width: '24px',
  },
}));

type EmptyStateIconProps = {
  icon: SvgIconComponent;
  variant: Variant;
};

const EmptyStateIcon = ({ icon: Icon, variant }: EmptyStateIconProps) => {
  const { palette } = useTheme();

  const iconByVariant = {
    default: {
      bgColor: 'transparent',
      border: `1px dashed ${palette.inactive.color3}`,
      iconColor: palette.inactive.color1,
    },
    success: {
      bgColor: palette.success.color4,
      border: 'none',
      iconColor: palette.success.color1,
    },
  };

  const bgColor = iconByVariant[variant].bgColor;
  const border = iconByVariant[variant].border;
  const iconColor = iconByVariant[variant].iconColor;

  return (
    <StyledIconWrapper bgcolor={bgColor} border={border}>
      <Icon htmlColor={iconColor} />
    </StyledIconWrapper>
  );
};

const StyledNoData = styled(NoData)({
  '& > div': {
    backdropFilter: 'none',
  },
});

type EmptyStateNoDataProps = {
  testId: string;
  title: string;
  description: string;
  icon: SvgIconComponent;
  button?: React.ReactNode;
  minHeight?: number;
  variant?: Variant;
};

const EmptyStateNoData = ({
  testId,
  title,
  description,
  icon,
  variant = 'default',
  minHeight,
  button,
}: EmptyStateNoDataProps) => (
  <Stack flex={1} p={9} direction="row" alignItems="center" justifyContent="center" minHeight={minHeight}>
    <StyledNoData
      testId={testId}
      icon={<EmptyStateIcon icon={icon} variant={variant} />}
      header={title}
      content={
        <Stack maxWidth={280}>
          <Text tag="p" variant="body-regular" size="S" textAlign="center" color="text3">
            {description}
          </Text>
        </Stack>
      }
      backgroundColor="transparent"
      button={button}
    />
  </Stack>
);

export default EmptyStateNoData;
