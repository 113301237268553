import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationSuccess } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { STEPS, ENTRA_INTEGRATION_ID, ConnectionFinishedStepProps } from './common';
import WizardStepTemplate from '../../../../../components/WizardStepTemplate';
import IntegrationStatus from '../../../../../components/IntegrationStatus';
import { useActivateIntegrationAndProvider } from '../../../../../utils';

export const ConnectionSuccessStep = ({ setStep, accountId, setTestingInProgress }: ConnectionFinishedStepProps) => {
  const { t } = useTranslation();

  const { activate } = useActivateIntegrationAndProvider(ENTRA_INTEGRATION_ID, ENTRA_INTEGRATION_ID, accountId);

  useEffect(() => {
    setTestingInProgress(false);
  });

  const navigate = useNavigate();

  const onBack = () => {
    setTestingInProgress(false);
    setStep(STEPS.COLLECT_API_KEYS);
  };

  const onFinish = () => {
    void activate().then(() => navigate(-1));
  };

  return (
    <WizardStepTemplate
      content={
        <IntegrationStatus icon={<IntegrationSuccess />} title={t('INTEGRATIONS.ENTRA.USAGE.CONNECTION_SUCCESS')} />
      }
      buttons={[
        <Button variant="outline" size="S" isCaption onClick={onBack}>
          {t('COMMON.BACK')}
        </Button>,
        <Button variant="solid" color="primary" size="S" isCaption onClick={onFinish}>
          {t('COMMON.FINISH')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
