import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { VISOTRUST_PROVIDER_ID } from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { Button } from '@verticeone/design-system';
import { IntegrationSetupDialog } from '../../../../components/IntegrationSetupDialog';
import { useDeactivateIntegration } from '../../../../hooks/useDeactivateIntegration';
import { useWebhookConnection } from '../hooks/useWebhookConnection';
import { StepContentProps, VisoTrustSetupStep } from '../types';

type DisconnectButtonProps = StepContentProps;

export const DisconnectButton: FC<DisconnectButtonProps> = ({ activeStep }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: accountIntegrationData } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
  });

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);

  const { deactivateIntegration, isDeactivateAccountIntegrationInProgress, isRevokeAccountIntegrationInProgress } =
    useDeactivateIntegration({
      providerId: VISOTRUST_PROVIDER_ID,
      closeDialog: () => setIsDisconnectDialogOpened(false),
      shouldRedirect: true,
    });
  const { disconnectWebhook, isDisconnectWebhookInProgress } = useWebhookConnection();

  const handleSubmit = async () => {
    const webhookId = accountIntegrationData?.parameters?.webhookId;

    if (webhookId) {
      await disconnectWebhook();
    }
    await deactivateIntegration();
  };

  const isSuccessStep = activeStep === VisoTrustSetupStep.SUCCESS;

  if (!isSuccessStep) return null;

  return (
    <>
      <Button
        variant="solid"
        size="M"
        color="neutral"
        onClick={() => setIsDisconnectDialogOpened(true)}
        testId="open-disconnect-dialog"
      >
        {t('INTEGRATIONS.VISO_TRUST.DISCONNECT_BUTTON')}
      </Button>
      <IntegrationSetupDialog
        header={t('INTEGRATIONS.VISO_TRUST.DISCONNECT_DIALOG.HEADER')}
        content={t('INTEGRATIONS.VISO_TRUST.DISCONNECT_DIALOG.CONTENT')}
        buttonLabel={t('INTEGRATIONS.VISO_TRUST.DISCONNECT_DIALOG.BUTTON')}
        isLoading={
          isDeactivateAccountIntegrationInProgress ||
          isRevokeAccountIntegrationInProgress ||
          isDisconnectWebhookInProgress
        }
        isOpened={isDisconnectDialogOpened}
        onSubmit={handleSubmit}
        onClose={() => setIsDisconnectDialogOpened(false)}
      />
    </>
  );
};
