import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { Button, Select, Text } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { IntegrationSetupDialog } from '../../../../components/IntegrationSetupDialog';
import styles from '../../../../common.module.scss';
import { IntegrationsCardContent } from '../../../../components/IntegrationsCardContent';
import { StatusBadge, StatusBadgeVariant } from '../../../../components/StatusBadge';
import { buildProviderCode, providers } from '../../utils/providers';
import { useRevoke } from './hooks';
import { Link } from 'react-router-dom';

type AccountingSetupCardProps = {
  id: keyof typeof providers;
  allowedInstances?: number;
};

export const AccountingSetupCard: FC<AccountingSetupCardProps> = ({ id, allowedInstances = 1 }) => {
  const { t } = useTranslation();
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();

  const {
    providerCode,
    translationId,
    icon: { small: Icon },
  } = providers[id];
  const providerName = t(`INTEGRATIONS.ACCOUNTING.${translationId}.TITLE`);
  const providerDescription = t(`INTEGRATIONS.ACCOUNTING.${translationId}.DESCRIPTION`);
  const { accountId } = useAccountContext();
  const [selectedInstance, setSelectedInstance] = useState<number>(1); // indexing starts from 1 in the UI
  const providerCodeWithInstance = useMemo(
    () => buildProviderCode(providerCode, selectedInstance),
    [providerCode, selectedInstance]
  );

  const { data: integration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: providerCodeWithInstance,
  });
  const onRevokeCompleted = () => setOpenRevokeDialog(false);
  const { isRevoking, revoke } = useRevoke({ providerCode: providerCodeWithInstance, onRevokeCompleted });
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);

  const integrationStatus = useMemo(
    () => ({
      isActive: integration?.status === 'ACTIVE',
      isFailed: integration?.connection?.status === 'FAILED',
    }),
    [integration]
  );

  const badgeStatus = useMemo(() => {
    if (integrationStatus.isActive) {
      return StatusBadgeVariant.ACTIVE;
    }
    if (integrationStatus.isFailed) {
      return StatusBadgeVariant.ERROR;
    }
    return StatusBadgeVariant.NEW;
  }, [integrationStatus]);

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        <CardHeader avatar={<Icon />} />
        <CardContent classes={{ root: styles['card-content'] }}>
          <IntegrationsCardContent
            title={providerName}
            description={providerDescription}
            statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
          />
        </CardContent>
        <CardActions className={styles['card-actions']}>
          {allowedInstances > 1 && (
            <>
              <Text variant="label" size="XS">
                {t('INTEGRATIONS.ACCOUNTING.SELECT_INSTANCE')}
              </Text>
              <Select<{ value: string }, false>
                value={{ value: selectedInstance.toString() }}
                onChange={(selected) => {
                  if (selected) {
                    setSelectedInstance(parseInt(selected.value));
                  }
                }}
                options={Array.from({ length: allowedInstances }, (_, i) => ({ value: (i + 1).toString() }))}
                getOptionLabel={(o) => o.value}
                getOptionValue={(o) => o.value}
              />
            </>
          )}
          {!integrationStatus.isActive && !integrationStatus.isFailed && (
            <Button
              component={Link}
              to={[
                generatePathForRoute(routes.INTEGRATIONS.NATIVE.ACCOUNTING, { providerId: id }),
                new URLSearchParams({ instance: selectedInstance.toString() }),
              ]
                .filter((val) => val !== null)
                .join('?')}
              variant="outline"
              color="neutral"
              size="S"
            >
              {t('INTEGRATIONS.SETUP')}
            </Button>
          )}
          {(integrationStatus.isActive || integrationStatus.isFailed) && (
            <Button
              isLoading={isRevoking}
              onClick={() => setOpenRevokeDialog(true)}
              variant="ghost"
              color="neutral"
              size="S"
            >
              {t('INTEGRATIONS.REVOKE_ACCESS')}
            </Button>
          )}
        </CardActions>
      </Card>
      <IntegrationSetupDialog
        header={t('INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        content={t('INTEGRATIONS.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        buttonLabel={t('INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
        isOpened={openRevokeDialog}
        onSubmit={() => revoke()}
        onClose={() => setOpenRevokeDialog(false)}
      />
    </div>
  );
};
