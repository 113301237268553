import { z } from 'zod';

export const departmentFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const departmentFieldSchema = z.object({
  name: z.string(),
  type: z.literal('DEPARTMENT'),
  metadata: departmentFieldMetadataSchema,
});

export type DepartmentFieldMetadata = z.infer<typeof departmentFieldMetadataSchema>;

export type DepartmentFieldType = z.infer<typeof departmentFieldSchema>;
