import { useQuery } from '@tanstack/react-query';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useCloudClient } from '../../CloudClientProvider';
import { get } from 'lodash';

type UseTestResultProps = {
  code: string;
};

type TestResultSummary = {
  tooltip?: {
    data: Array<Array<string>>;
    types: Array<string>;
  };
  type: string;
  value: string;
};

const TestResultsQuery = graphql(`
  query TestResults($accountId: String!, $code: String!) {
    monitoringLatestQuery(params: { accountId: $accountId, code: $code, monitoringType: TEST }) {
      ... on MonitoringResult {
        __typename
        items {
          results {
            ... on ErroredQueryResult {
              __typename
            }
            ... on MulticlassBinnedTestResult {
              __typename
              summary {
                ...TestResultSummaryFragment
              }
            }
            ... on BooleanTestResult {
              __typename
              summary {
                ...TestResultSummaryFragment
              }
            }
            ... on BinnedTestResult {
              __typename
              summary {
                ...TestResultSummaryFragment
              }
            }
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
      }
    }
  }

  fragment TestResultSummaryFragment on TestResultSummary {
    tooltip {
      data
      types
    }
    type
    value
  }
`);

export const QUERY_KEY = 'TestResults' as const;

const useTestResult = ({ code }: UseTestResultProps) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: [QUERY_KEY, code, accountId],
    queryFn: () => fetchCloudOptimization(TestResultsQuery, { accountId, code }),
    enabled: !!accountId && !!code,
    select: (data) =>
      get(data, 'monitoringLatestQuery.items[0].results.summary', undefined) as TestResultSummary | undefined,
  });
};

export default useTestResult;
