import { FC } from 'react';
import { Stack } from '@mui/material';
import { Loader, Text } from '@verticeone/design-system';
import { EditorDrawerGroup, EditorDrawerScrollContainer } from '../../../../workflowSchema/WorkflowEditor/EditorDrawer';
import VendorLogo from '../../../../../../components/VendorLogo';
import { ServiceTaskDetailContentProps } from '../types';
import { DetailFields } from './types';
import { useTranslation } from 'react-i18next';
import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { RequestPageCard } from '../../../../../saas/contract/cards/SidebarCards/LinkedPagesCard/components/RequestPageCard';

const RequestLink: FC<{ requestRef: string }> = ({ requestRef }) => {
  const { data: { request } = {}, isLoading } = useGetRequestQuery(parseRequestRef(requestRef));

  return request && !isLoading ? <RequestPageCard request={request} /> : <Loader size={20} />;
};

export const InvokeServiceComplete: FC<ServiceTaskDetailContentProps<DetailFields>> = ({ inputs }) => {
  const { vendor, product, requests } = inputs || {};
  const { t } = useTranslation();

  return (
    <EditorDrawerScrollContainer>
      {vendor?.id && (
        <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.TASK_DETAIL.INVOKE_SERVICE.SELECTED_VENDOR')}>
          <Stack direction="row" gap={2} alignItems="center">
            <VendorLogo vendorId={vendor.id} />
            <Text variant="body-bold" size="S" color="text1">
              {vendor.name}
            </Text>
          </Stack>
        </EditorDrawerGroup>
      )}
      {product?.id && (
        <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.TASK_DETAIL.INVOKE_SERVICE.SELECTED_PRODUCT')}>
          <Text variant="body-bold" size="S" color="text1">
            {product.name}
          </Text>
        </EditorDrawerGroup>
      )}

      {requests?.length && (
        <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.TASK_DETAIL.INVOKE_SERVICE.LINKED_REQUESTS')}>
          <Stack gap={2}>
            {requests.map(({ key, ref }) => (
              <RequestLink key={key} requestRef={ref} />
            ))}
          </Stack>
        </EditorDrawerGroup>
      )}
    </EditorDrawerScrollContainer>
  );
};
