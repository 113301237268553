import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipButton } from '@verticeone/design-system';
import { StyledThumbnail, ThumbnailProps } from '../ThumbnailCommon';

export const Thumbnail: FC<ThumbnailProps> = ({ thumbnail, inactive }) => {
  const { t } = useTranslation();
  return (
    <Stack height={160} position="relative">
      <StyledThumbnail $thumbnail={thumbnail}></StyledThumbnail>
      {inactive && (
        <Stack left={8} bottom={8} position="absolute">
          <ChipButton isActive={false} color="info" variant="solid" size="XS">
            {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.COMING_SOON')}
          </ChipButton>
        </Stack>
      )}
    </Stack>
  );
};
