import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipButton } from '@verticeone/design-system';
import { DesignSystemColor } from '@verticeone/design-system';

type ChipProps = {
  active?: boolean;
};

export const Chip = ({ active }: ChipProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.AWS.ACCOUNT_TABLE' });

  const getChipConfig = (isActive?: boolean) => {
    if (isActive) {
      return {
        label: t('STATUS.CONNECTED'),
        color: 'success',
      };
    }

    if (isActive === false) {
      return {
        label: t('STATUS.DISCONNECTED'),
        color: 'warning',
      };
    }

    return {
      label: t('STATUS.LOADING'),
      color: 'neutral',
      loader: true,
    };
  };

  const chipConfig = getChipConfig(active);

  return (
    <ChipButton color={chipConfig.color as DesignSystemColor} size="M" variant="ghost" isActive={false}>
      <Stack direction="row" gap={1} alignItems="center">
        {chipConfig.loader && <CircularProgress size="16px" color="inherit" sx={{ opacity: 0.6 }} />}
        {chipConfig.label}
      </Stack>
    </ChipButton>
  );
};
