import { z, ZodObject, ZodRawShape } from 'zod';
import { DepartmentFieldMetadata, DepartmentFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';

export function getDepartmentPresenterSchema(field: DepartmentFieldType): ZodObject<ZodRawShape> {
  let schema = z.string().max(256);

  return z.object({
    [field.name]: field.metadata.required ? schema.min(1) : schema.nullish(),
  });
}

export function getDepartmentPresenterDefault({ field, defaultValues }: GetPresenterDefaultProps<DepartmentFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getDepartmentJsonSchemaFromMetadata(metadata: DepartmentFieldMetadata): JsonSchema {
  return {
    type: metadata.required ? ['string'] : ['string', 'null'],
    'x-type': 'urn:verticeone:vertice::services:schema/core/department/id/v1',
    title: metadata.label,
  };
}

export function getDepartmentJsonSchema(field: DepartmentFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getDepartmentJsonSchemaFromMetadata(field.metadata) },
  };
}
