import type { SimpleTypes as JsonSchemaType } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { VariableType } from '../WorkflowEditor/types';
import { getVariableTypeLabels } from './getVariableTypeLabels';
import { JsonSchema } from '../WorkflowViewer/utils';
import { XTypeCatalogResource } from '../../catalogResource/types';

type GetVariableTypeFromJsonSchemaParams = {
  jsonSchema: JsonSchema;
  xTypeCatalogResources?: XTypeCatalogResource[];
};

/**
 * Extracts a `VariableType` object from a given JSON schema, optionally enriching it
 * with xType labels from `xTypeResources` if provided.
 *
 * ```ts
 *  const jsonSchema: JsonSchema = { type: ['string', 'boolean'] };
 *  const result = getVariableTypeFromJsonSchema({ jsonSchema });
 *  // { baseType: ['string', 'boolean'], labels: ['Text', 'True/False'] };
 * ```
 *
 * @params An object containing:
 *   - `jsonSchema`: The JSON schema that defines the variable structure, types, and metadata.
 *   - `xTypeResources` (optional): An array of resources to map and enrich the schema's `x-type`.
 *
 * @returns A `VariableType` object. If `xTypeResources` are missing but the schema has an `x-type`,
 * the labels will be empty. If `xTypeResources` are provided, labels are derived from xType.
 */
export const getVariableTypeFromJsonSchema = (params: GetVariableTypeFromJsonSchemaParams): VariableType => {
  const { jsonSchema, xTypeCatalogResources } = params;

  const xTypeCatalogResource = xTypeCatalogResources?.find((resource) => resource.urn === jsonSchema['x-type']);

  let variableType: VariableType = {
    baseType: getNormalizedJsonSchemaTypes(jsonSchema),
    enum: jsonSchema.enum,
    format: jsonSchema.format,
    xType: jsonSchema['x-type'],
    labels: [],
  };

  if (jsonSchema['x-type'] && !xTypeCatalogResource) return variableType;

  variableType.labels = getVariableTypeLabels({ ...jsonSchema, xTypeCatalogResource });

  return variableType;
};

const getNormalizedJsonSchemaTypes = (jsonSchema: JsonSchema): JsonSchemaType[] => {
  if (!jsonSchema.type) return [];
  return Array.isArray(jsonSchema.type) ? jsonSchema.type : [jsonSchema.type];
};
