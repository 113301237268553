import { FC, useCallback, useMemo } from 'react';
import { customAlphabet } from 'nanoid';
import { FieldType } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { useFormContext } from 'react-hook-form';
import { EditUserTaskFormData } from '../schema';
import { fieldDefsByType } from '../../../../../../forms/dynamicForms/fields/fields';
import { BuilderPluginValues, DynamicFormField } from '../../../../../../forms/dynamicForms';
import { CUSTOM_FORM_FIELD_NAME } from './constants';
import { VariablePickerBuilderPlugin, useVariablePickerBuilderPluginDefinition } from './plugins/VariablePicker';

const alphabet = 'abcdefghijklmnopgrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; // only upper and lower case
const generateUniqueFieldName = customAlphabet(alphabet, 16);

export type ActiveEditingField = {
  index: number | null;
  fieldType: FieldType;
  defaultMetadata?: DynamicFormField['metadata'];
};

export type BuilderPlugins = [VariablePickerBuilderPlugin];

export type CustomFormFieldDialogProps = {
  activeField: ActiveEditingField | null;
  onClose: () => void;
  onSubmit: (values: DynamicFormField) => void;
};

export const CustomFormFieldDialog: FC<CustomFormFieldDialogProps> = (props) => {
  const { activeField, onClose, onSubmit } = props;

  const { getValues } = useFormContext<EditUserTaskFormData>();
  const variablePickerBuilderPluginDefinition = useVariablePickerBuilderPluginDefinition();

  const dynamicField = useMemo<DynamicFormField | undefined>(() => {
    if (!activeField) return undefined;
    return activeField.index !== null ? getValues(`${CUSTOM_FORM_FIELD_NAME}.${activeField.index}`) : undefined;
  }, [activeField, getValues]);

  const FieldEditDialog = useMemo(() => {
    if (!activeField) return null;
    return fieldDefsByType[activeField.fieldType].builderDialog;
  }, [activeField]);

  const plugins = useMemo<BuilderPlugins | null>(() => {
    if (!activeField) return null;

    /* define all plugins here */
    const variablePickerPlugin = variablePickerBuilderPluginDefinition.create(dynamicField?.name);

    return [variablePickerPlugin];
  }, [variablePickerBuilderPluginDefinition, activeField, dynamicField]);

  const handleSubmit = useCallback(
    (values: any, pluginValues: BuilderPluginValues<BuilderPlugins>) => {
      if (!activeField) return;
      const [variablePickerValues] = pluginValues;

      const fieldName = dynamicField?.name ?? generateUniqueFieldName();

      /* handle plugin values here */
      variablePickerBuilderPluginDefinition.handleSubmit(fieldName, variablePickerValues);

      onSubmit({
        ...(dynamicField ?? { type: activeField.fieldType }),
        name: fieldName,
        metadata: values,
      });
    },
    [activeField, dynamicField, variablePickerBuilderPluginDefinition, onSubmit]
  );

  if (!FieldEditDialog || !plugins) return <></>;

  return (
    <FieldEditDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={dynamicField?.metadata ?? activeField?.defaultMetadata}
      plugins={plugins}
    />
  );
};
