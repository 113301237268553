import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import dayjs from 'dayjs';

const EDPCumulativeSpendQuery = graphql(`
  query EDPCumulativeSpend($accountId: String!) {
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }
    ) {
      ... on DataTableResult {
        __typename
        table(
          columns: [
            "edp_month_start"
            "spend_current_month"
            "forecasted_spend"
            "cumulative_month_spend"
            "cumulative_forecasted_spend"
            "zero_based_cumulative_month_spend"
            "is_forecast"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

type DataColumns =
  | 'edp_month_start'
  | 'spend_current_month'
  | 'forecasted_spend'
  | 'cumulative_month_spend'
  | 'cumulative_forecasted_spend'
  | 'zero_based_cumulative_month_spend'
  | 'is_forecast';

export type CumulativeSumChartData = {
  months: string[];
  commitment: number;
  totalSpend: number;
  values: { id: string; data: Array<number | null>; type: string; lineWidth?: number; dashStyle?: string }[];
  usedCategories: string[];
};

export const useCumulativeSumChartData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPCumulativeSpend'],
    queryFn: () =>
      fetchCloudOptimization(EDPCumulativeSpendQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data) => {
      if (data.athenaViewQuery?.__typename === 'DataTableResult') {
        const result = (getTableData(data.athenaViewQuery.table as TableType) as Record<DataColumns, number>[]) ?? null;

        const totalCommitment = result
          .map((item) => item.cumulative_month_spend + item.cumulative_forecasted_spend)
          .slice(-1)[0];

        return {
          months: result.map((item) => dayjs(item.edp_month_start).format()),
          commitment: totalCommitment,
          totalSpend: result.map((item) => item.cumulative_month_spend + item.cumulative_forecasted_spend).slice(-1)[0],
          values: [
            {
              id: 'commitment',
              data: result.map(() => totalCommitment),
              type: 'line',
              lineWidth: 1,
              dashStyle: 'Dash',
            },
            {
              id: 'actualSpend',
              data: result.map((item) => (item.is_forecast ? 0 : item.spend_current_month)),
              type: 'column',
            },
            {
              id: 'cumulativeSpendBefore',
              data: result.map((item) => (item.is_forecast ? 0 : item.zero_based_cumulative_month_spend)),
              type: 'column',
            },
          ],
          usedCategories: ['commitment', 'actualSpend', 'cumulativeSpendBefore'],
        };
      }

      return null;
    },
  });
};
