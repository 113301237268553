import { z } from 'zod';
import { formDataProductItemSchema, vendorSchema } from '../shared/schemas';
import { t } from 'i18next';
import { emailOrEmpty } from '@verticeone/utils/validation';

export const zodSchema = z
  .object({
    renewalType: z.enum(['UPGRADE', 'FLAT_RENEWAL', 'DOWNGRADE']).nullable(),
    vendor: vendorSchema,
    products: z.array(formDataProductItemSchema.omit({ annualCost: true })),
    targetSignDate: z.string().nullable(),

    contractCurrency: z.string().nullable(),
    approvedBudget: z.number().nullable(),
    unknownBudget: z.boolean().optional(),
    verticeNegotiationRequested: z.boolean().optional(),
    internalNegotiationRequested: z.boolean().optional(),

    desiredContractLength: z.number().optional().nullish(),

    legalReviewRequired: z.boolean().optional(),
    securityReviewRequired: z.boolean().optional(),
    piiExpectedToBeHeld: z.boolean().optional(),

    additionalNotes: z.string().optional().nullish(),

    mainVendorContact: z.boolean().optional(),
    mainVendorContactName: z.string().optional(),
    mainVendorContactEmail: emailOrEmpty(t),

    contactVendorDirectly: z.boolean().optional(),
    discussMultiYearDeals: z.boolean().optional(),
    discussPlannedGrowth: z.boolean().optional(),
    discussCompetitors: z.boolean().optional(),
    discussCaseStudies: z.boolean().optional(),

    parentAccountId: z.string().nullish().optional(),

    negotiationTier: z
      .enum(['NEGOTIATION_TIER_VERTICE', 'NEGOTIATION_TIER_CUSTOMER', 'NEGOTIATION_TIER_SUPPORT'])
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.mainVendorContact) {
      if (!data.mainVendorContactName) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: ['mainVendorContactName'],
          expected: 'string',
          received: 'null',
        });
      }
      if (!data.mainVendorContactEmail) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: ['mainVendorContactEmail'],
          expected: 'string',
          received: 'null',
        });
      }
    }

    if (!data.unknownBudget && data.approvedBudget === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['approvedBudget'],
        expected: 'number',
        received: 'null',
      });
    }

    if (!data.targetSignDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['targetSignDate'],
        expected: 'string',
        received: 'null',
      });
    }

    if (!data.contractCurrency) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['contractCurrency'],
        expected: 'string',
        received: 'null',
      });
    }

    if (!data.renewalType) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['renewalType'],
        expected: 'string',
        received: 'null',
      });
    }
  });

export type FormData = z.infer<typeof zodSchema>;
