import { z } from 'zod';
import { primitiveDynamicFormFieldSchema } from '../../primitiveTypes';

export const repeatingFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  requiredItems: z.number().min(0).max(100),
  limit: z.number().min(2).max(100),
  fields: primitiveDynamicFormFieldSchema.array().max(5).nullable().optional(),
});

export const repeatingFieldSchema = z.object({
  name: z.string(),
  type: z.literal('REPEATING'),
  metadata: repeatingFieldMetadataSchema,
});

export type RepeatingFieldMetadata = z.infer<typeof repeatingFieldMetadataSchema>;

export type RepeatingFieldType = z.infer<typeof repeatingFieldSchema>;
