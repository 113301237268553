import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const verticeConnectAPI = createApi({
  reducerPath: 'verticeConnectAPI',
  tagTypes: ['verticeConnectAPI'],
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.VERTICE_CONNECT),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
