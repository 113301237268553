import { Stack } from '@mui/material';
import { useEffect, useId } from 'react';
import { Text, TextFieldCaption } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import { ContractFormData, FormPlusComputedFieldPaths } from '../../types';
import { useWatch } from 'react-hook-form';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';
import { AnnualCostBaselineViewOnlyField } from '../AnnualCostBaselineViewOnlyField';
import { useContractContext } from '../../ContractContext';

const BaselineCostTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_BASELINE_COST.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_BASELINE_COST.LINE_2')}
      </Text>
    </Stack>
  );
};

const BaselineAnnualCostFormEntry = () => {
  const { t } = useTranslation();
  const id = useId();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');

  const overrideEnabled = useWatch<ContractFormData>({ name: 'costModel.baseline.model.annualCostOverride.enabled' });

  const namePath: FormPlusComputedFieldPaths = 'costModel.baseline.model.annualCostOverride.amount';
  const isAnnualCostVisible = useIsContractFieldVisible(namePath);
  const isAnnualCostRequired = useIsContractFieldRequired(namePath);
  const isAnnualCostEditable = useIsContractFieldEditable(namePath);

  const defaultAnnualCost = useWatch<ContractFormData>({ name: 'parts.overview.annualCostBaseline' });

  useEffect(() => {
    if (!overrideEnabled) {
      contractContext.hookForm.setValue(namePath, defaultAnnualCost);
    }
  }, [defaultAnnualCost, contractContext.hookForm, overrideEnabled]);

  if (!isAnnualCostVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST_INITIAL_VENDOR_PROPOSAL')}
          tooltip={{ content: <BaselineCostTooltipContent /> }}
          size="XS"
          required={isAnnualCostEditable && isAnnualCostRequired}
        />
        {isAnnualCostEditable ? (
          <FormDecimalNumberField id={id} name={namePath} disabled={!overrideEnabled} required={isAnnualCostRequired} />
        ) : (
          <AnnualCostBaselineViewOnlyField id={id} />
        )}
      </Stack>
    </FormControl>
  );
};

export default BaselineAnnualCostFormEntry;
