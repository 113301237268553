import { formatDuration, Locale } from 'date-fns';
import { enUS } from 'date-fns/locale';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export type Format = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds';

export type DurationFormatOptions = {
  delimiter?: string;
  format?: Format[];
  zero?: boolean;
  locale?: Locale;
};

// NOTE: We sanitize all dates to UTC, because of default behavior
// of dayjs when going through daylight savings when adding days.
// (We were gaining/losing an hour when adding days between DST and non-DST)
// Ref: https://github.com/iamkun/dayjs/issues/1271
dayjs.extend(utc);

/**
 * Formats a `Duration` object (from date-fns) into a human-readable string.
 *
 * - Defaults to formatting `['days','hours']` only.
 * - Uses `' and '` by default as a delimiter.
 * - If the resulting string is empty (i.e., all requested units are zero),
 *   it falls back to `1` of the **last** requested unit (e.g., `1 hour`).
 *
 * @example
 * ```ts
 * getFormattedDuration({
 *   duration: { days: 1, hours: 2, minutes: 30 },
 * });
 * // => "1 day and 2 hours"
 * ```
 *
 * @returns A localized duration string (e.g., `"2 days and 5 hours"`).
 */
export const getFormattedDuration = ({
  duration,
  format = ['days', 'hours'],
  delimiter = ' and ',
  zero = false,
  locale = enUS,
}: {
  duration: Duration;
} & DurationFormatOptions): string => {
  const result = formatDuration(duration, {
    locale,
    format,
    delimiter,
    zero,
  });

  if (result === '') {
    return formatDuration(
      { [format[format.length - 1] ?? 'hours']: 1 },
      {
        locale,
        format,
        delimiter,
      }
    );
  }

  return result;
};

/**
 * Calculates the duration between two `Date` objects, then formats it as a string.
 *
 * - Defaults to `['days','hours']` and a delimiter of `' and '`.
 * - Supports the same fallback logic as `getFormattedDuration`: if zero, it returns `1` of the last unit.
 *
 * @param format - An array of units to include in the formatted duration. Sort order matters. Put the most significant unit first.
 *
 * @example
 * ```ts
 * // From Dec 31, 2021 to Jan 01, 2022
 * getFormattedDurationBetweenDates({
 *   start: new Date('2021-12-31T00:00:00Z'),
 *   end: new Date('2022-01-01T00:00:00Z'),
 * });
 * // => "1 day"
 * ```
 *
 * @returns A localized duration string representing the time between `start` and `end`.
 */
export const getFormattedDurationBetweenDates = ({
  end,
  start,
  format = ['days', 'hours', 'minutes'],
  delimiter = ' and ',
  zero = false,
  locale = enUS,
}: {
  end: Date;
  start: Date;
} & DurationFormatOptions): string => {
  const endDate = dayjs(end).utc();

  const duration: Duration = format.reduce(
    (acc, unit) => {
      acc.duration[unit] = endDate.diff(acc.remaining, unit);
      acc.remaining = acc.remaining.add(acc.duration[unit] ?? 0, unit).utc();
      return acc;
    },
    {
      remaining: dayjs(start).utc(),
      duration: {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      } as Duration,
    }
  ).duration;

  return getFormattedDuration({ duration, format, delimiter, zero, locale });
};
