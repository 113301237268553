import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, Paper, Stack, Tooltip } from '@mui/material';

import { Button } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useIssueAccessTokenForScimMutation } from '@vertice/slices/src/openapi/codegen/scimAPI';
import { SetupStep } from './SetupStep';
import { copyToClipboard, SCIMSetupProps } from './common';

export const SCIMOktaSetup = ({ accountId, accountSCIMEndpointURL, handleCloseSetup }: SCIMSetupProps) => {
  const { t } = useTranslation();

  const [issueAccessToken] = useIssueAccessTokenForScimMutation();

  const [URLCopied, setURLCopied] = useState<boolean>(false);
  const [accessTokenCopiedText, setAccessTokenCopiedText] = useState<string>('');

  return (
    <Stack alignItems="center" sx={{ width: 1, height: 1 }} p={10}>
      <Stack direction="row" justifyContent="space-between" alignItems="stretch" spacing={2} sx={{ width: 1 }}>
        <Text variant="heading" size="S">
          {t('INTEGRATIONS.SCIM.OKTA.WIZARD.TITLE')}
        </Text>
        <Button onClick={handleCloseSetup}>{t('INTEGRATIONS.SCIM.OKTA.WIZARD.CLOSE_SETUP')}</Button>
      </Stack>
      <Paper sx={{ width: 540, height: '100%', padding: 10 }}>
        <Stack direction="column" gap={3}>
          <List sx={{ listStyle: 'decimal', pl: 3 }}>
            <ListItem>
              <ListItemText>
                <Text variant="caption">{t('INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_HEADER')}</Text>
              </ListItemText>
            </ListItem>
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP1" />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP2" components={{ bold: <b /> }} />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP3" components={{ bold: <b /> }} />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP4" components={{ bold: <b /> }} />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP5" components={{ bold: <b /> }}>
              <Button variant="plain" onClick={() => copyToClipboard(accountSCIMEndpointURL, () => setURLCopied(true))}>
                <Tooltip
                  title={
                    URLCopied
                      ? t('INTEGRATIONS.SCIM.OKTA.WIZARD.COPIED')
                      : t('INTEGRATIONS.SCIM.OKTA.WIZARD.COPY_TO_CLIBOARD')
                  }
                  placement="top"
                  onOpen={() => setURLCopied(false)}
                >
                  <Text variant="code" size="S" align="left">
                    {accountSCIMEndpointURL}
                  </Text>
                </Tooltip>
              </Button>
            </SetupStep>
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP6" components={{ bold: <b /> }} />
            <SetupStep
              i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP7"
              components={{ bold: <b />, ul: <ul />, li: <li /> }}
            />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP8" components={{ bold: <b /> }} />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP9" components={{ bold: <b /> }}>
              <Tooltip
                title={accessTokenCopiedText}
                placement="top"
                onOpen={() => setAccessTokenCopiedText(t('INTEGRATIONS.SCIM.OKTA.WIZARD.GENERATE_AND_COPY'))}
              >
                <Button
                  size="S"
                  sx={{ width: 200 }}
                  onClick={() => {
                    issueAccessToken({ accountId })
                      .then((res) => {
                        if ('data' in res) {
                          copyToClipboard(res.data?.accessToken, () =>
                            setAccessTokenCopiedText(t('INTEGRATIONS.SCIM.OKTA.WIZARD.COPIED'))
                          );
                        }
                      })
                      .catch(() => setAccessTokenCopiedText(t('INTEGRATIONS.SCIM.OKTA.WIZARD.ERROR')));
                  }}
                >
                  {t('INTEGRATIONS.SCIM.OKTA.WIZARD.GENERATE_NEW_TOKEN')}
                </Button>
              </Tooltip>
            </SetupStep>
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP10" components={{ bold: <b /> }} />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP11" components={{ bold: <b /> }} />
            <SetupStep
              i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP12"
              components={{ bold: <b />, ul: <ul />, li: <li /> }}
            />
            <SetupStep i18nKey="INTEGRATIONS.SCIM.OKTA.WIZARD.SETUP_STEP13" components={{ bold: <b /> }} />
          </List>
        </Stack>
      </Paper>
    </Stack>
  );
};
