import { FC, useCallback } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, Alert, TextFieldArea, ifExpression } from '@verticeone/design-system';
import { useFormContext, useFormState } from 'react-hook-form';
import FormTextArea from '@vertice/core/src/modules/forms/fields/FormTextArea';

import type { EditGatewayFormData } from '../EditGatewayForm/schema';
import type { Variable } from '../../types';
import type { FlowEdgeCondition, Gateway } from '../../../../definitionsTypes';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { QueryBuilder } from '../../QueryBuilder/QueryBuilder';
import { EdgeNameInput } from './EdgeNameInput';
import { ConditionTypeSwitch } from './ConditionTypeSwitch';

type EdgeFieldContainerProps = {
  $withRedBorder?: boolean;
  $focused?: boolean;
};

export const EdgeFieldContainer = styled(Stack)<EdgeFieldContainerProps>(
  ({ theme, $withRedBorder = false, $focused }) => ({
    backgroundColor: theme.palette.input.color1,
    padding: theme.spacing(4),
    borderRadius: 12,
    outline: $withRedBorder ? `2px solid ${theme.palette.error.color3}` : 'none',
    ...ifExpression($focused, {
      boxShadow: `0 0 0px 4px ${theme.palette.secondary.color3}`,
      outline: `1px solid ${theme.palette.secondary.color1}`,
    }),
  })
);

type EdgeFieldProps = {
  index: number;
  requestVariables: Variable[];
  udfVariables: Variable[];
  gatewayType: Gateway['gatewayType'];
  onSelectionChanged: (edgeId: string) => void;
  selectedEdgeId: string | undefined;
};

export const EdgeField: FC<EdgeFieldProps> = ({
  index,
  requestVariables,
  udfVariables,
  gatewayType,
  onSelectionChanged,
  selectedEdgeId,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_GATEWAY' });
  const { setValue, watch } = useFormContext<EditGatewayFormData>();
  const formState = useFormState<EditGatewayFormData>();
  const hasConditionError = !!formState.errors.edges?.[index]?.condition;

  const isEdgeFromExclusiveGateway = gatewayType === 'Exclusive';
  const isEdgeFromJoinGateway = gatewayType === 'Join';
  const isEdgeFromForkGateway = gatewayType === 'Fork';

  const isExpressionEditorDisplayed = !!requestVariables.length && !!udfVariables.length;

  const edgeId = watch(`edges.${index}.id`);
  const edgeCondition = watch(`edges.${index}.condition`);
  const edgeConditionType = watch(`edges.${index}.conditionType`);

  const isDefaultEdge = edgeConditionType === 'DefaultFlow';
  const isJsonLogicCondition = edgeConditionType === 'JsonLogic';
  const isJMESPathExpressionCondition = edgeConditionType === 'JMESPathExpression';

  const isFocused = edgeId === selectedEdgeId;

  const handleConditionChange = useCallback(
    (condition: string, conditionType?: FlowEdgeCondition['conditionType']) => {
      setValue(`edges.${index}.condition`, condition, { shouldDirty: true });
      if (conditionType) {
        setValue(`edges.${index}.conditionType`, conditionType, { shouldDirty: true });
      }
    },
    [index, setValue]
  );

  return (
    <EdgeFieldContainer
      $withRedBorder={hasConditionError}
      $focused={isFocused}
      gap={isDefaultEdge && isEdgeFromExclusiveGateway ? 5 : 6}
      key={edgeId}
      onFocusCapture={() => onSelectionChanged(edgeId)}
      onClick={() => onSelectionChanged(edgeId)}
    >
      <Stack justifyContent="space-between" direction="row">
        <EdgeNameInput
          index={index}
          name={`edges.${index}.name`}
          isDefaultEdge={edgeConditionType === 'DefaultFlow'}
          isEdgeFromExclusiveGateway={gatewayType === 'Exclusive'}
        />
        <ConditionTypeSwitch
          handleConditionChange={handleConditionChange}
          isJsonLogicCondition={isJsonLogicCondition}
          isJMESPathExpressionCondition={isJMESPathExpressionCondition}
          isEdgeFromJoinGateway={isEdgeFromJoinGateway}
          isEdgeFromForkGateway={isEdgeFromForkGateway}
          isDefaultEdge={isDefaultEdge}
        />
      </Stack>
      {!isEdgeFromJoinGateway && (
        <>
          {isDefaultEdge && isEdgeFromExclusiveGateway ? (
            <Text variant="body-regular" color="text3" size="S">
              {t('EDGES.DEFAULT_DESCRIPTION')}
            </Text>
          ) : (
            isExpressionEditorDisplayed && (
              <>
                {isDefaultEdge || isJsonLogicCondition ? (
                  <QueryBuilder
                    udfVariables={udfVariables}
                    requestVariables={requestVariables}
                    onConditionChange={handleConditionChange}
                    initialCondition={isDefaultEdge ? undefined : edgeCondition}
                    isConditionRemovalEnabled={isEdgeFromForkGateway}
                  />
                ) : isJMESPathExpressionCondition ? (
                  <Stack gap={3}>
                    <Alert size="S" color="warning" variant="ghost" title={t('EDGES.EDIT_JMESPATH_WARNING')} />
                    <TextFieldArea
                      onChange={(e) => handleConditionChange(e.target.value, 'JMESPathExpression')}
                      value={edgeCondition}
                      placeholder={t('EDGES.EDIT_JMESPATH_PLACEHOLDER')}
                      size="S"
                      variant="outline"
                      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                      name={`edges.${index}.condition`}
                    />
                  </Stack>
                ) : (
                  <FormTextArea<EditGatewayFormData>
                    id={`edge-condition-text-area-${index}`}
                    size="S"
                    label={edgeConditionType}
                    variant="outline"
                    color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                    name={`edges.${index}.condition`}
                  />
                )}
              </>
            )
          )}
        </>
      )}
    </EdgeFieldContainer>
  );
};
