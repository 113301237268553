import React, { forwardRef, useCallback } from 'react';
import { Vendor } from './types';
import { useLazyListVendorsQuery } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import {
  components,
  GroupBase,
  type OptionProps,
  type SingleValueProps,
  type PlaceholderProps,
  SelectInstance,
} from 'react-select';
import { Stack, useTheme } from '@mui/material';
import { AddOutlined, Search } from '@mui/icons-material';
import { SelectAsyncCreatable, SelectAsyncCreatableProps } from '@verticeone/design-system';
import VendorLogo from '../../components/VendorLogo';

export type VendorSelectProps = Omit<SelectAsyncCreatableProps<Vendor, false>, 'loadOptions' | 'value' | 'onChange'> & {
  /**
   * If enabled, you can add a vendor that is not listed in the search results.
   * @default false
   */
  inlineEnabled?: boolean;
  value: Vendor | null;
  onChange: (value: Vendor | null) => void;
  sortOrder?: 'asc' | 'desc';
};

const VendorOption = (props: JSX.IntrinsicAttributes & OptionProps<Vendor, false, GroupBase<Vendor>>) => {
  const { palette } = useTheme();
  return (
    <components.Option {...props}>
      <Stack direction="row" gap={2} alignItems="center">
        {props.data.id === NOT_LISTED_VENDOR_ID ? (
          <AddOutlined stroke={palette.text.color3} />
        ) : (
          <VendorLogo vendorId={props.data.id ?? ''} size={25} logoOnly />
        )}
        {props.children}
      </Stack>
    </components.Option>
  );
};

const VendorSingleValue = (props: SingleValueProps<Vendor>) => (
  <components.SingleValue {...props}>
    <Stack direction="row" gap={2} alignItems="center">
      <VendorLogo vendorId={props.data.id ?? ''} size={20} logoOnly />
      {props.children}
    </Stack>
  </components.SingleValue>
);

const Placeholder = ({ children, ...props }: PlaceholderProps<Vendor>) => {
  const { palette } = useTheme();
  return (
    <components.Placeholder {...props}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Search htmlColor={palette.text.color3} />
        {children}
      </Stack>
    </components.Placeholder>
  );
};

// Don't leak NOR use this ID outside!
const NOT_LISTED_VENDOR_ID = 'unknown_vendor';

const VendorSelect = forwardRef(
  (
    { inlineEnabled = false, placeholder, sortOrder, noOptionsMessage, onChange, ...otherProps }: VendorSelectProps,
    ref: React.Ref<SelectInstance<Vendor>> | undefined
  ) => {
    const [findVendorsTrigger] = useLazyListVendorsQuery();
    const { t } = useTranslation();

    const loadOptions = useCallback(
      async (inputValue: string): Promise<Vendor[]> => {
        const response = await findVendorsTrigger({
          query: inputValue,
          sortOrder: 'asc',
          limit: 10,
          restrict: 'VENDORS',
        });

        const responses =
          response.data?.map((vendor): Vendor => ({ type: 'PREDEFINED', id: vendor.id!, name: vendor.name! })) || [];
        const sortedResponses =
          sortOrder && inputValue === ''
            ? responses.sort((a, b) => {
                const aName = (sortOrder === 'asc' ? a : b).name!;
                const bName = (sortOrder === 'asc' ? b : a).name!;

                return aName.localeCompare(bName);
              })
            : responses;

        return sortedResponses;
      },
      [sortOrder, findVendorsTrigger]
    );

    const handleChange = useCallback(
      (value: Vendor | null) => {
        if (value !== null && value.id === NOT_LISTED_VENDOR_ID && value.name) {
          // We don't want to leak the NOT_LISTED_VENDOR_ID, immediately convert it to an item that gets rendered
          // as selected item.
          onChange({
            type: 'INLINE',
            id: '',
            name: value.name,
          });
        } else {
          onChange(value);
        }
      },
      [onChange]
    );

    return (
      <SelectAsyncCreatable
        ref={ref}
        loadOptions={loadOptions}
        getOptionValue={(v) => (v.type === 'INLINE' ? v.name : v.id)}
        getOptionLabel={(v) => (v?.id === NOT_LISTED_VENDOR_ID ? t('ENTITIES.VENDOR.LABELS.NOT_LISTED') : v.name ?? '')}
        components={{ Option: VendorOption, SingleValue: VendorSingleValue, Placeholder }}
        onChange={handleChange}
        menuPortalTarget={document.body}
        getNewOptionData={
          inlineEnabled
            ? (inputValue, optionLabel): Vendor => ({
                id: NOT_LISTED_VENDOR_ID,
                type: 'INLINE',
                name: inputValue,
              })
            : undefined
        }
        isValidNewOption={
          inlineEnabled
            ? undefined // default validator
            : () => false // never valid => "+ Vendor not listed" is never shown
        }
        placeholder={placeholder ?? t('ENTITIES.VENDOR.PLACEHOLDERS.SEARCH_VENDORS')}
        noOptionsMessage={noOptionsMessage ?? (() => t('ENTITIES.VENDOR.LABELS.NO_VENDORS_FOUND'))}
        {...otherProps}
      />
    );
  }
);

export default VendorSelect;
