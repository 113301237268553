import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import VariableMapper from '../../../../../components/VariableMapper';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EditUserTaskFormData } from '../schema';
import { Variable } from '../../../types';
import { VariableSelectorField } from '../../../../../components/VariableMapper/VariableSelector/VariableSelectorField';

type ContractReviewMappingProps = {
  contractVariables: Variable[];
  requestVariables: Variable[];
};

export const ContractReviewMapping: FC<ContractReviewMappingProps> = (props) => {
  const { contractVariables, requestVariables } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });

  const { control } = useFormContext<EditUserTaskFormData>();

  const { append, fields, remove } = useFieldArray({ control, name: 'contractReviewMapping' });

  const handleAddRow = useCallback(() => {
    append({
      source: { type: 'Const', value: '' },
      target: { type: 'Const', value: '' },
    });
  }, [append]);

  return (
    <VariableMapper.Root>
      <VariableMapper.Row header>
        <VariableMapper.Heading>{t('CONTRACT_REVIEW.MAPPING.SOURCE_LABEL')}</VariableMapper.Heading>
        <VariableMapper.Heading>{t('CONTRACT_REVIEW.MAPPING.TARGET_LABEL')}</VariableMapper.Heading>
      </VariableMapper.Row>

      {fields.map((field, index) => (
        <VariableMapper.Row
          key={field.id}
          isDeletable
          onDelete={() => remove(index)}
          data-testid={`variableMapperRow${index}`}
        >
          <VariableSelectorField
            control={control}
            testPrefix="variableSelectorSource"
            name={`contractReviewMapping.${index}.source`}
            variables={requestVariables}
          />
          <VariableMapper.Divider />
          <VariableSelectorField
            control={control}
            testPrefix="variableSelectorTarget"
            name={`contractReviewMapping.${index}.target`}
            variables={contractVariables}
          />
        </VariableMapper.Row>
      ))}

      <VariableMapper.AddButton onClick={handleAddRow} />
    </VariableMapper.Root>
  );
};
