import { useCallback } from 'react';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

export const useRequestsNavigation = () => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();

  const generateRequestPath = useCallback(
    (requestId: string, isLegacy?: boolean): string => {
      if (!isLegacy) {
        return generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
          requestId: requestId,
        });
      }

      return generatePathForRoute(routes.CONTRACTS.DETAIL, {
        contractId: requestId,
      });
    },
    [generatePathForRoute, routes.CONTRACTS.DETAIL, routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL]
  );

  return {
    generateRequestPath,
  };
};
