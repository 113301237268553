import React from 'react';
import { Control, Controller, FieldPath, FieldValues, useFormState } from 'react-hook-form';
import { Select, TextFieldCaption } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { has } from 'lodash';
import { SingleValue } from 'react-select';

export type Option = {
  value: string;
  label: string;
}

export type SelectFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  options: Array<Option>;
  title: string;
  required?: boolean;
  disabled?: boolean;
};

const SelectField = <T extends FieldValues>({
  name,
  title,
  control,
  options,
  required = true,
  disabled = false,
}: SelectFieldProps<T>) => {
  const { errors } = useFormState({ control, name });
  const hasError = has(errors, name);

  return (
    <Stack flex={1}>
      <Stack>
        <TextFieldCaption label={title} htmlFor={`${title}-field`} size="XS" required={required} />
      </Stack>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            value={options.find((option) => option.value === field.value) || null}
            onChange={(selectedOption: SingleValue<Option>) => {
              field.onChange(selectedOption ? selectedOption.value : "");
            }}
            name={name}
            isDisabled={disabled}
            error={hasError}
            variant="outlined"
            size="XS"
            isMulti={false}
            options={options as any}
          />
        )}
      />
    </Stack>
  );
};

export default SelectField;
