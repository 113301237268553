import { P, match } from 'ts-pattern';
import { InlineVendor } from '../../../../../vendor/types';
import { ContractDatesForm } from '../formSteps/ContractDatesFormStep';
import { ContractDetailsForm } from '../formSteps/ContractDetailsFormStep';
import { ContractReviewForm, TaskContractReviewOutput } from '../types';
import { getStartedMonthsBetweenDates } from '../../../../../saas/contract/computed';
import { RollingRollFrequency } from '../../../../../saas/contract/types';
import { ContractLineItemsForm } from '../formSteps/ContractLineItemsFormStep';
import { getLineItemsFromItems } from '../../../../../saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { CostModelLineItem } from '../../../../../saas/contract/components/CostModelLineItemsTable';

export const transformFromOutput = (output: TaskContractReviewOutput): ContractReviewForm => {
  return {
    contractDetails: transformDetails(output),
    contractDates: transformDates(output),
    lineItems: transformLineItems(output),
    documents: {},
  };
};

const transformDetails = ({ contract }: TaskContractReviewOutput): ContractDetailsForm => ({
  departmentId: contract.department,
  vendor: contract.finalOffer.vendor as InlineVendor,
  contractCategory: 'OTHER',
});

const transformDates = (output: TaskContractReviewOutput): ContractDatesForm => {
  const { finalOffer } = output.contract;

  const baseValues = {
    signDate: output.contract.signingDate ?? null,
    startDate: finalOffer.startDate,
  };

  return match(finalOffer)
    .returnType<ContractDatesForm>()
    .with({ rollingFrequency: 'NO' }, (offer) => ({
      ...baseValues,
      renewalDate: offer.endDate ?? offer.startDate,
      contractType: 'FIXED',
      contractLength: getStartedMonthsBetweenDates(offer.startDate, offer.endDate),
      autoRenewalDate: offer.autoRenewalDeadline,
      billingFrequency: offer.billingFrequency,
    }))
    .with({ rollingFrequency: 'ONE_OFF' }, () => ({
      ...baseValues,
      contractType: 'ONE_OFF',
    }))
    .with({ rollingFrequency: P._ }, (offer) => ({
      ...baseValues,
      contractType: 'ROLLING',
      rollingFrequency: offer.rollingFrequency as RollingRollFrequency,
      billingFrequency: offer.billingFrequency,
    }))
    .exhaustive();
};

const transformLineItems = (output: TaskContractReviewOutput): ContractLineItemsForm => {
  const { finalOffer } = output.contract;

  return {
    currency: finalOffer.baseCurrency,
    paymentTerms: finalOffer.paymentTerms,
    lineItems: getLineItemsFromItems(finalOffer.lineItems) as CostModelLineItem[],
    annualCostOverride: finalOffer.annualCost ? { amount: finalOffer.annualCost.value, enabled: false } : undefined,
    totalCostOverride: finalOffer.totalCost ? { amount: finalOffer.totalCost.value, enabled: false } : undefined,
  };
};
