import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Text } from '@verticeone/design-system';
import { isNil } from 'lodash';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { DEFAULT_CURRENCY } from '../../../../../../constants/currency';

type CostCellParams = GridCellParams<any, number> & {
  currency?: string;
};

const CostCell = ({ value, currency }: CostCellParams) => {
  const formatCurrency = useFormatCurrency();
  const effectiveCurrency = currency || DEFAULT_CURRENCY;
  return <Text variant="caption">{isNil(value) ? '' : formatCurrency(value, { currency: effectiveCurrency })}</Text>;
};

export default CostCell;
