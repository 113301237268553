import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import { DataSourcedFieldPresenterProps } from './presenters/types';
import { UnknownFieldPresenter } from './presenters/UnknownFieldPresenter';
import { dataSourcesBySource } from './dataSources';

export const DataSourcedFieldPresenter: FC<DataSourcedFieldPresenterProps> = (props) => {
  const { field } = props;
  const { presenterType, source } = field.metadata;

  const Presenter = useMemo(() => dataSourcesBySource[source]?.presenterMap[presenterType], [presenterType, source]);

  return <Stack py={3}>{Presenter ? <Presenter {...props} /> : <UnknownFieldPresenter />}</Stack>;
};
