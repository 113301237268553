import { Stack } from '@mui/material';

import { StepContentProps, VisoTrustSetupStep } from '../types';
import { ConnectionCard } from './ConnectionCard';
import { SynchronizationCard } from './SynchronizationCard';

export const StepContent = ({ activeStep, setStep }: StepContentProps) => {
  const isConnectionActive = activeStep === VisoTrustSetupStep.SUCCESS;

  return (
    <Stack gap={3}>
      <ConnectionCard activeStep={activeStep} setStep={setStep} />
      {isConnectionActive && <SynchronizationCard />}
    </Stack>
  );
};
