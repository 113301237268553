import { PropsWithChildren } from 'react';
import { AnimatedStack } from '@vertice/core/src/components/animations/MotionWrappers';
import { AnimatePresence } from 'framer-motion';

type TemplateCardsProps = PropsWithChildren;

export const TemplateCards = ({ children }: TemplateCardsProps) => {
  return (
    <AnimatedStack
      display="grid"
      gridTemplateColumns="repeat(auto-fit, minmax(280px, 1fr))"
      gap={4}
      width="100%"
      layout="position"
    >
      <AnimatePresence>{children}</AnimatePresence>
    </AnimatedStack>
  );
};
