import { Stack } from '@mui/material';

import { Dropzone, Text, ErrorMessageList, FileList, Fileupload } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';

import {
  acceptedTypes,
  customErrorCodes,
  maxFileBytesSize,
  validator,
} from '@vertice/core/src/modules/saas/contract/components/DocumentUpload/utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useRequestDocuments from '../../../../hooks/useRequestDocuments';

type RequestDocumentsProps = {
  requestId: string;
};

export const RequestDocuments: FC<RequestDocumentsProps> = (props) => {
  const { requestId } = props;
  const { t } = useTranslation();
  const { files, isFetching, uploadFiles, deleteFile, downloadFile, allowDeletion } = useRequestDocuments(requestId);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Text variant="caption" size="S" mb={3}>
        {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.DOCUMENTS.REQUEST_DOCUMENTS')}
      </Text>
      <Fileupload
        multiple
        acceptedTypes={acceptedTypes}
        maxFileSize={maxFileBytesSize}
        files={files}
        onAddFiles={uploadFiles}
        onRemoveFiles={deleteFile}
        validator={validator}
        customErrorCodes={customErrorCodes}
      >
        <Stack gap={4}>
          <FileList
            size="M"
            hideErrorFiles
            dynamicFileNameLength
            onFileNameClick={downloadFile}
            hiddenColumns={allowDeletion ? [] : ['remove']}
          />
          <Stack height={210} alignItems="center">
            <Dropzone size="M" />
          </Stack>
          <ErrorMessageList size="S" />
        </Stack>
      </Fileupload>
    </Stack>
  );
};
