import { verticeConnectAPI as api } from '../../api/verticeConnectAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    testIntegrationPost: build.mutation<TestIntegrationPostApiResponse, TestIntegrationPostApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/test-integration`, method: 'POST' }),
    }),
    checkConnection: build.query<CheckConnectionApiResponse, CheckConnectionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connections/${queryArg.connectionId}/check`,
        params: {
          correlationId: queryArg.correlationId,
        },
      }),
    }),
    getWebhook: build.query<GetWebhookApiResponse, GetWebhookApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhooks/${queryArg.webhookCode}` }),
    }),
    listWebhookCallbacks: build.query<ListWebhookCallbacksApiResponse, ListWebhookCallbacksApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhooks/${queryArg.webhookCode}/callbacks/` }),
    }),
    getWebhookCallback: build.query<GetWebhookCallbackApiResponse, GetWebhookCallbackApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/webhooks/${queryArg.webhookCode}/callbacks/${queryArg.callbackId}`,
      }),
    }),
    triggerPollingCallback: build.mutation<TriggerPollingCallbackApiResponse, TriggerPollingCallbackApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/polling/callbacks/${queryArg.callbackId}/trigger`,
        method: 'POST',
        params: {
          correlationId: queryArg.correlationId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as verticeConnectAPICodegen };
export type TestIntegrationPostApiResponse = /** status 200 Returns generic object */ object;
export type TestIntegrationPostApiArg = {
  accountId: string;
};
export type CheckConnectionApiResponse =
  /** status 200 Pointer to S3 bucket with HTTP response serialized in JSON file. */ {
    /** The overall status of the check. Set to PASSED only if all the checks PASSED. */
    status: 'PASSED' | 'FAILED' | 'NO_CHECKS';
    /** The status of each check. */
    checks: {
      /** name of the check */
      name: string;
      /** The status of the check. Set to PASSED only if the 'responseEvaluation' evaluated to 'true'. */
      status: 'PASSED' | 'FAILED';
      /** The error Connect will return */
      error?: {
        /** Source of the error
            EXTERNAL: the 3rd party API returned an expected error that was defined in the Spec file
            INTERNAL: usually issue withing the spec file definition or a 3rd party API returned error that was not defined within the Spec file
            WEBHOOK: error from the webhook processing
             */
        errorSource: 'EXTERNAL' | 'INTERNAL' | 'WEBHOOK';
        /** Machine readable error
            if source is EXTERNAL: this is the 'returnedErrorType' specified in Spec file
            if source is INTERNAL: this is the '__name__' of the exception
             */
        errorType: string;
        /** Human readable error message
            if source is EXTERNAL: this is the 'returnedMessage' or 'returnedMessagePath' specified in the Spec file
            if source is INTERNAL: this is the developer's message about the error
             */
        errorMessage: string;
        /** additional info about the original error
            if source is EXTERNAL: this is the 3rd party API's original error
            if source is INTERNAL: this is either:
              - some additional info from the developer
              - the 3rd party API's original error in case it is not handled by the Spec file
              - other INTERNAL error that is wrapped by this one
             */
        originalError?: string | object;
      };
    }[];
  };
export type CheckConnectionApiArg = {
  accountId: string;
  integrationId: Schema;
  connectionId: Schema;
  correlationId?: string;
};
export type GetWebhookApiResponse = /** status 200 Registered webhook object */ {
  /** Internal webhook code/identification */
  webhookCode?: string;
  /** Public webhook ID (UUID) */
  webhookPublicId?: string;
  spec?: {
    /** Webhook spec */
    kind?: 'WEBHOOK';
    /** The default input is the received request (from WebhookLambdaRequest):
        requestId: str
        headers: dict
        queryParams: dict
        bodyStr: str
        body: dict
        pathParams:
          accountId: str
          webhookPublicId: str
          webhookCode: str
        
        WARNING: the HTTP type of prerequisite is not supported for webhook.
         */
    prerequisites?: (any &
      any &
      any &
      any &
      any &
      any & {
        /** Name of the prerequisite step */
        stepName: string;
        /** What kind of step this is */
        type: 'INVOKE_LAMBDA' | 'READ_SECRET' | 'HTTP' | 'DYNAMODB_GET_ITEM' | 'CONST' | 'FAIL_IF_CONDITION';
        /** Array of variables to be stored into the local state */
        responseMapping: {
          /** Name of a variable to be created in the local state */
          name: string;
          /** Value of the variable created in the local state.
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          value: Input;
        }[];
        /** Time period to store the response in a local cache */
        cache?: string;
        invokeLambda?: InvokeLambdaInput;
        readSecret?: {
          /** ARN of the secret to be read.
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          ref: Ref;
        };
        http?: {
          /** A full URL that should be called (with the protocol) including parameters.
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          url: Ref;
          /** HTTP protocol */
          method: 'GET' | 'POST' | 'PUT' | 'DELETE';
          /** HTTP headers to be sent.
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          headers?: Input2;
          /** HTTP body that should be send (if applicable by method).
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          body?: Input2;
        };
        /** Generic object of key-value to be added into the inner state */
        constData?: Input2;
        /** Compare object, if evaluates to true, the prerequisite evaluation is stopped with a given error. */
        failIfCondition?: {
          condition: Compare;
          /** Code of the error that should be returned on match */
          returnedErrorType: string;
          /** Message of the error that should be returned on match
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          returnedMessage: Ref;
        };
        /** Parameters to call dynamodb.get_item
         */
        dynamodbGetItem?: {
          /** The "Key" attribute to call get_items with, should contain the partition and sort key.
                Supports jinja templates with all variables from the inner state.
                Supports JMES Path with "!JMES path.to.variable".
                 */
          key: Input2;
          /** enum value to pick the table */
          table: 'INTEGRATIONS_DATA';
        };
      })[];
    authorizer?: any &
      any & {
        /** What kind of authorizer should run */
        type: 'INVOKE_LAMBDA' | 'COMPARE_FIELDS';
        /** For authorization lambda, the lambda has to return boolean True of False.
         */
        invokeLambda?: InvokeLambdaInput;
        compareFields?: FilterRules;
      };
  };
} & {
  /** The whole URL to be used as a webhook */
  webhookUrl?: string;
};
export type GetWebhookApiArg = {
  accountId: string;
  webhookCode: string;
};
export type ListWebhookCallbacksApiResponse = /** status 200 List of registered callback objects */ Schema2[];
export type ListWebhookCallbacksApiArg = {
  accountId: string;
  webhookCode: string;
};
export type GetWebhookCallbackApiResponse = /** status 200 Registered callback object */ {
  /** Unique identifier of the callback */
  callbackId?: string;
  /** Account ID if the callback is specific for an account,
    or 'GLOBAL' if it is a service-like callback
     */
  accountId: string;
  /** Generic callback specification that other callbacks like webhook or polling should implement */
  spec: any &
    any & {
      /** This object serves for comparing fields from inner state which can be evaluated into True/False.
        It is a generic way to add a condition to some part of the service (e.g. connection error handling,
        webhook authorization, webhook callback filter rules)
        It supports a separate "prerequisites" block that can prepare the values in case you need more advance features
        like the !JMES, or several steps like computing hashes.
        It then supports "anyOf" or "allOf" array of nested values, that are evaluated using Jinja template.
         */
      filterRules?: {
        prerequisites?: Prerequisite[];
      } & {
        /** An array of expressions where at least 1 needs to evaluate to "true".
            The expression can be nested object with another set of expressions.
             */
        anyOf?: (Compare | string)[];
        /** An array of expressions where ALL needs to evaluate to "true".
            The expression can be nested object with another set of expressions.
             */
        allOf?: (Compare | string)[];
      };
      /** Type of the resource that should be called */
      type: 'INVOKE_LAMBDA' | 'COMPLETE_WORKFLOW_TASK';
      invokeLambda?: {
        /** ARN of the lambda to be invoked
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
             */
        ref: string | object;
        /** The payload that should be send to the lambda input.
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
             */
        input?: object;
      };
      /** allows to complete a workflow task with a specific input as result of the task (that will go into the output mapping)
       */
      completeWorkflowTask?: {
        /** Account ID of the workflow task to complete.
            If empty, the pathParams.accountId will be used.
            WARNING: if you have task in the VERTICE_WORKFLOW_ADMINISTRATION account, you need to send that accountId.
             */
        accountId?: string;
        /** Workflow task requestId */
        requestId: string;
        /** Data that will be sent to the output mapping of the Task.
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
            If empty, no data are sent.
             */
        input?: string | number | boolean | object | any | null;
      };
    };
  /** Hash of the callback object to identify the same objects */
  callbackHash?: string;
  /** If set to "true", the callback will automatically be removed once successfully called */
  autoRemove?: boolean;
} & {
  /** Webhook internal code */
  webhookCode: string;
  spec?: GenericCallbackSpecification & {
    /** Webhook callback spec */
    kind?: 'WEBHOOK_CALLBACK';
  };
};
export type GetWebhookCallbackApiArg = {
  accountId: string;
  webhookCode: string;
  callbackId: Schema;
};
export type TriggerPollingCallbackApiResponse = /** status 202 Data about the triggered polling callback */ {
  /** The generated ID based on the scheduler lambda request and callback request */
  requestId?: string;
  /** The account ID used for the call */
  accountId?: string;
  /** The callback ID used for the call */
  callbackId?: string;
};
export type TriggerPollingCallbackApiArg = {
  accountId: string;
  callbackId: Schema;
  correlationId?: string;
};
export type Schema = string;
export type Input = string | number | boolean | object | any | null;
export type InvokeLambdaInput = {
  /** ARN of the lambda to be invoked
    Supports jinja templates with all variables from the inner state.
    Supports JMES Path with "!JMES path.to.variable".
     */
  ref: string | object;
  /** The payload that should be send to the lambda input.
    Supports jinja templates with all variables from the inner state.
    Supports JMES Path with "!JMES path.to.variable".
     */
  input?: object;
};
export type Ref = string | object;
export type Input2 = object;
export type Compare = {
  /** An array of expressions where at least 1 needs to evaluate to "true".
    The expression can be nested object with another set of expressions.
     */
  anyOf?: (Compare | string)[];
  /** An array of expressions where ALL needs to evaluate to "true".
    The expression can be nested object with another set of expressions.
     */
  allOf?: (Compare | string)[];
};
export type Prerequisite = any &
  any &
  any &
  any &
  any &
  any & {
    /** Name of the prerequisite step */
    stepName: string;
    /** What kind of step this is */
    type: 'INVOKE_LAMBDA' | 'READ_SECRET' | 'HTTP' | 'DYNAMODB_GET_ITEM' | 'CONST' | 'FAIL_IF_CONDITION';
    /** Array of variables to be stored into the local state */
    responseMapping: {
      /** Name of a variable to be created in the local state */
      name: string;
      /** Value of the variable created in the local state.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      value: Input;
    }[];
    /** Time period to store the response in a local cache */
    cache?: string;
    invokeLambda?: InvokeLambdaInput;
    readSecret?: {
      /** ARN of the secret to be read.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      ref: Ref;
    };
    http?: {
      /** A full URL that should be called (with the protocol) including parameters.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      url: Ref;
      /** HTTP protocol */
      method: 'GET' | 'POST' | 'PUT' | 'DELETE';
      /** HTTP headers to be sent.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      headers?: Input2;
      /** HTTP body that should be send (if applicable by method).
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      body?: Input2;
    };
    /** Generic object of key-value to be added into the inner state */
    constData?: Input2;
    /** Compare object, if evaluates to true, the prerequisite evaluation is stopped with a given error. */
    failIfCondition?: {
      condition: Compare;
      /** Code of the error that should be returned on match */
      returnedErrorType: string;
      /** Message of the error that should be returned on match
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      returnedMessage: Ref;
    };
    /** Parameters to call dynamodb.get_item
     */
    dynamodbGetItem?: {
      /** The "Key" attribute to call get_items with, should contain the partition and sort key.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      key: Input2;
      /** enum value to pick the table */
      table: 'INTEGRATIONS_DATA';
    };
  };
export type FilterRules = {
  prerequisites?: Prerequisite[];
} & {
  /** An array of expressions where at least 1 needs to evaluate to "true".
    The expression can be nested object with another set of expressions.
     */
  anyOf?: (Compare | string)[];
  /** An array of expressions where ALL needs to evaluate to "true".
    The expression can be nested object with another set of expressions.
     */
  allOf?: (Compare | string)[];
};
export type GenericCallbackSpecification = any &
  any & {
    /** This object serves for comparing fields from inner state which can be evaluated into True/False.
    It is a generic way to add a condition to some part of the service (e.g. connection error handling,
    webhook authorization, webhook callback filter rules)
    It supports a separate "prerequisites" block that can prepare the values in case you need more advance features
    like the !JMES, or several steps like computing hashes.
    It then supports "anyOf" or "allOf" array of nested values, that are evaluated using Jinja template.
     */
    filterRules?: {
      prerequisites?: Prerequisite[];
    } & {
      /** An array of expressions where at least 1 needs to evaluate to "true".
        The expression can be nested object with another set of expressions.
         */
      anyOf?: (Compare | string)[];
      /** An array of expressions where ALL needs to evaluate to "true".
        The expression can be nested object with another set of expressions.
         */
      allOf?: (Compare | string)[];
    };
    /** Type of the resource that should be called */
    type: 'INVOKE_LAMBDA' | 'COMPLETE_WORKFLOW_TASK';
    invokeLambda?: {
      /** ARN of the lambda to be invoked
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      ref: string | object;
      /** The payload that should be send to the lambda input.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
         */
      input?: object;
    };
    /** allows to complete a workflow task with a specific input as result of the task (that will go into the output mapping)
     */
    completeWorkflowTask?: {
      /** Account ID of the workflow task to complete.
        If empty, the pathParams.accountId will be used.
        WARNING: if you have task in the VERTICE_WORKFLOW_ADMINISTRATION account, you need to send that accountId.
         */
      accountId?: string;
      /** Workflow task requestId */
      requestId: string;
      /** Data that will be sent to the output mapping of the Task.
        Supports jinja templates with all variables from the inner state.
        Supports JMES Path with "!JMES path.to.variable".
        If empty, no data are sent.
         */
      input?: string | number | boolean | object | any | null;
    };
  };
export type Schema2 = {
  /** Unique identifier of the callback */
  callbackId?: string;
  /** Account ID if the callback is specific for an account,
    or 'GLOBAL' if it is a service-like callback
     */
  accountId: string;
  /** Generic callback specification that other callbacks like webhook or polling should implement */
  spec: any &
    any & {
      /** This object serves for comparing fields from inner state which can be evaluated into True/False.
        It is a generic way to add a condition to some part of the service (e.g. connection error handling,
        webhook authorization, webhook callback filter rules)
        It supports a separate "prerequisites" block that can prepare the values in case you need more advance features
        like the !JMES, or several steps like computing hashes.
        It then supports "anyOf" or "allOf" array of nested values, that are evaluated using Jinja template.
         */
      filterRules?: {
        prerequisites?: Prerequisite[];
      } & {
        /** An array of expressions where at least 1 needs to evaluate to "true".
            The expression can be nested object with another set of expressions.
             */
        anyOf?: (Compare | string)[];
        /** An array of expressions where ALL needs to evaluate to "true".
            The expression can be nested object with another set of expressions.
             */
        allOf?: (Compare | string)[];
      };
      /** Type of the resource that should be called */
      type: 'INVOKE_LAMBDA' | 'COMPLETE_WORKFLOW_TASK';
      invokeLambda?: {
        /** ARN of the lambda to be invoked
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
             */
        ref: string | object;
        /** The payload that should be send to the lambda input.
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
             */
        input?: object;
      };
      /** allows to complete a workflow task with a specific input as result of the task (that will go into the output mapping)
       */
      completeWorkflowTask?: {
        /** Account ID of the workflow task to complete.
            If empty, the pathParams.accountId will be used.
            WARNING: if you have task in the VERTICE_WORKFLOW_ADMINISTRATION account, you need to send that accountId.
             */
        accountId?: string;
        /** Workflow task requestId */
        requestId: string;
        /** Data that will be sent to the output mapping of the Task.
            Supports jinja templates with all variables from the inner state.
            Supports JMES Path with "!JMES path.to.variable".
            If empty, no data are sent.
             */
        input?: string | number | boolean | object | any | null;
      };
    };
  /** Hash of the callback object to identify the same objects */
  callbackHash?: string;
  /** If set to "true", the callback will automatically be removed once successfully called */
  autoRemove?: boolean;
} & {
  /** Webhook internal code */
  webhookCode: string;
  spec?: GenericCallbackSpecification & {
    /** Webhook callback spec */
    kind?: 'WEBHOOK_CALLBACK';
  };
};
export const {
  useTestIntegrationPostMutation,
  useCheckConnectionQuery,
  useLazyCheckConnectionQuery,
  useGetWebhookQuery,
  useLazyGetWebhookQuery,
  useListWebhookCallbacksQuery,
  useLazyListWebhookCallbacksQuery,
  useGetWebhookCallbackQuery,
  useLazyGetWebhookCallbackQuery,
  useTriggerPollingCallbackMutation,
} = injectedRtkApi;
