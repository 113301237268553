import type { CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import type {
  XTypeCatalogResource,
  FormCatalogResource,
  FunctionCatalogResource,
  ServiceCatalogResource,
} from './types';

export const isFormCatalogResource = (catalogResource?: CatalogResource): catalogResource is FormCatalogResource =>
  catalogResource?.definition.Kind === 'Vertice/ServiceCatalog/Form/FormDefinition';

export const isFunctionCatalogResource = (
  catalogResource?: CatalogResource
): catalogResource is FunctionCatalogResource =>
  catalogResource?.definition.Kind === 'Vertice/ServiceCatalog/Function/FunctionDefinition';

export const isServiceCatalogResource = (
  catalogResource?: CatalogResource
): catalogResource is ServiceCatalogResource =>
  catalogResource?.definition.Kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const isXTypeCatalogResource = (catalogResource?: CatalogResource): catalogResource is XTypeCatalogResource =>
  catalogResource?.definition.Kind === 'Vertice/ServiceCatalog/XType/XTypeDefinition';
