import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@verticeone/design-system';

import { DeleteObjectDialog } from '../DeleteObjectDialog/DeleteObjectDialog';
import { useEventListener } from 'usehooks-ts';

export type DeleteButtonProps = {
  nodeName: string;
  dialogDescription?: string;
  buttonLabel?: string;
  variant?: 'icon' | 'button';
  onDelete: () => void;
  isHotKeyEnabled?: boolean;
};

type DeleteHotKeyProps = {
  onPress: (event: KeyboardEvent) => void;
};

const DeleteHotKey: FC<DeleteHotKeyProps> = ({ onPress }) => {
  useEventListener('keydown', (event) => {
    if (event.key === 'Backspace' && event.target === document.body) {
      onPress(event);
    }
  });

  return null;
};

export const DeleteObjectButton: FC<DeleteButtonProps> = (props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });

  const {
    nodeName,
    variant = 'button',
    onDelete,
    buttonLabel = t('ACTIONS.DELETE_TASK'),
    dialogDescription = t('DELETE_NODE_DIALOG.DESCRIPTION_TASK'),
    isHotKeyEnabled = false,
  } = props;

  const isButtonVariant = variant === 'button';

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleDialogCancel = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setIsDialogOpen(false);
  };

  return (
    <>
      {isButtonVariant ? (
        <Button onClick={handleButtonClick} variant="ghost" color="error">
          {buttonLabel}
        </Button>
      ) : (
        <Tooltip minified noArrow size="XS" title={t('ACTIONS.DELETE')}>
          <IconButton onClick={handleButtonClick} icon={DeleteOutlined} size="XS" variant="outline" />
        </Tooltip>
      )}
      {isHotKeyEnabled ? <DeleteHotKey onPress={handleButtonClick} /> : null}
      <DeleteObjectDialog
        onCancel={handleDialogCancel}
        onDelete={handleDelete}
        isOpen={isDialogOpen}
        title={t('DELETE_NODE_DIALOG.TITLE', { nodeName })}
        subTitle={t('DELETE_NODE_DIALOG.SUB_TITLE')}
        description={dialogDescription}
      />
    </>
  );
};
