import { useMemo } from 'react';
import { useListServiceWorkflowsQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../account/AccountContext';
import { parseWorkflowRef } from '../../../../hooks/workflows/refUtils';
import { WorkflowRow } from './types';

type UseServiceWorkflowsProps = {
  serviceRef?: string;
  defaultWorkflowRef?: string;
};

export const useServiceWorkflows = ({ serviceRef, defaultWorkflowRef }: UseServiceWorkflowsProps) => {
  const { accountId } = useAccountContext();
  const { data: workflowTypeData, isLoading: isLoadingWorkflows } = useListServiceWorkflowsQuery({
    accountId,
    serviceRef: serviceRef,
  });

  const workflows: WorkflowRow[] = useMemo(() => {
    const { workflowId: defaultWorkflowId } = parseWorkflowRef(defaultWorkflowRef);
    return (
      workflowTypeData?.workflows.map((workflow) => ({
        ...workflow,
        isDefault: workflow.id === defaultWorkflowId,
      })) || []
    );
  }, [defaultWorkflowRef, workflowTypeData?.workflows]);

  const defaultWorkflow = useMemo(() => workflows.find((w) => w.isDefault), [workflows]);

  return {
    defaultWorkflow,
    workflows,
    isLoadingWorkflows,
  };
};
