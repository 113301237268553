import React from 'react';
import { FieldValues } from 'react-hook-form';
import SelectField, { SelectFieldProps, Option } from './SelectField';
import { useGetAccountIntegrationsQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type ConnectionSelectFieldProps<T extends FieldValues> = Omit<SelectFieldProps<T>, 'options'>;

const ConnectionSelectField = <T extends FieldValues>(props: ConnectionSelectFieldProps<T>) => {
  const { accountId } = useAccountContext();
  const { data: options } = useGetAccountIntegrationsQuery(
    {
      accountId: accountId,
      category: 'CUSTOM_OUTBOUND',
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data:
          result?.data
            ?.filter((item) => item.status === 'ACTIVE')
            ?.map(
              (item) =>
                ({
                  value: item.id,
                  label: item.id.split(':').pop(),
                } as Option)
            ) ?? [],
      }),
    }
  );

  return <SelectField {...props} options={options} />;
};

export default ConnectionSelectField;
