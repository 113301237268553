import React, { useEffect } from 'react';
import Layout from './Layout';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import { Box } from '@mui/material';
import LastEvaluated from '../components/LastEvaluated/LastEvaluated';
import { useSpendBreakdownData } from './BreakdownChart/SpendBreakdownChart/useSpendBreakdownData';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from '../CloudLayout';

type ContentProps = {
  updatedAt?: number;
};

const Content = ({ updatedAt }: ContentProps) => {
  const { setHeaderActions } = useOutletContext<OutletContextType>();

  useEffect(() => {
    if (updatedAt) {
      setHeaderActions([<LastEvaluated key={updatedAt} updatedAt={new Date(updatedAt)} textSize={'S'} />]);
    }
    return () => {
      setHeaderActions([]);
    };
  }, [setHeaderActions, updatedAt]);

  return (
    <Box position="relative">
      <Layout />
    </Box>
  );
};

const CloudInsightsEDP = () => {
  const { data, isFetching } = useSpendBreakdownData();

  return (
    <LoadableAWSPageWrapper isLoading={isFetching}>
      <Content updatedAt={data?.updated_at} />
    </LoadableAWSPageWrapper>
  );
};

export default CloudInsightsEDP;
