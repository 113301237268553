import { FC, useEffect } from 'react';
import { useWorkflowValidationContext } from './WorkflowValidationContext';
import { WorkflowDefinitions } from '../../types';

type ValidatorProps = {
  workflowDefinitions: WorkflowDefinitions;
};
export const Validator: FC<ValidatorProps> = ({ workflowDefinitions }) => {
  const { validate } = useWorkflowValidationContext();

  useEffect(() => {
    void validate(workflowDefinitions);
  }, [validate, workflowDefinitions]);

  return null;
};
