import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridFilterItem,
  GridFilterOperator,
  GridLogicOperator,
  gridNumberComparator,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

import { DataGrid, GridHeaderCell, Text } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { getDaysFromNow } from '@vertice/slices/src/slices/UsageAnalytics/helpers';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';

import { DiscoverySourcesCell } from '../components/DiscoverySourcesCell';
import LastUsedCellWithSkeleton from '../components/LastUsedCellWithSkeleton';
import { Bins } from '../UsageDetail/components/ActivityFrequency/utils';
import { HealthFilter, UsageTabs } from '../UsageDetail/utils';
import { UtilizationCell } from './UtilizationCell';
import { VendorCell } from './VendorCell';
import { ApplicationGridNoRows } from './ApplicationGridNoRows';
import ActivityFrequencyCell from './ActivityFrequencyCell';
import { UsageContext } from './UsageContext';
import { getActivityFrequency, getEstimatedUtilization, getUtilization, isCheckWarning } from './helpers';

const StyledDataGrid = styled(DataGrid<ApplicationWithMeasureReportsListItem>)(({ theme }) => ({
  '.MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
}));

type ApplicationsGridProps = {
  applications?: readonly ApplicationWithMeasureReportsListItem[];
  unhealthyApplicationsCount?: number;
  loading?: boolean;
  search?: string;
  dataHealthFilter?: HealthFilter;
  /** Router path to the Integrations page. Used as call-to-action if no applications are integrated. */
  integrationsHref?: string;
};

export const ApplicationsGrid = ({
  applications,
  unhealthyApplicationsCount,
  loading,
  search,
  dataHealthFilter,
  integrationsHref,
}: ApplicationsGridProps) => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const { setActiveTab } = useContext(UsageContext);

  useEffect(() => {
    const items: Array<GridFilterItem> = [
      {
        field: 'vendor',
        value: search || '',
        operator: 'contains',
        id: 'vendor',
      },
    ];

    (dataHealthFilter === HealthFilter.HEALTHY || dataHealthFilter === HealthFilter.UNHEALTHY) &&
      items.push({
        field: 'healthiness',
        value: dataHealthFilter === HealthFilter.UNHEALTHY,
        operator: 'booleanIs',
        id: 'healthiness',
      });

    apiRef.current.setFilterModel({
      logicOperator: GridLogicOperator.And,
      items,
    });
  }, [search, dataHealthFilter, loading, apiRef]);

  const columns: GridColDef<ApplicationWithMeasureReportsListItem>[] = useMemo(
    () => [
      {
        field: 'vendor',
        headerName: t('INTEGRATIONS.USAGE.APPLICATION'),
        minWidth: 300,
        flex: 2,
        renderHeader: (params) => <GridHeaderCell {...params} />,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => VendorCell(params.row),
      },
      {
        field: 'source',
        headerName: t('APPLICATIONS.USAGE.SOURCE.HEADER'),
        minWidth: 100,
        flex: 1,
        editable: false,
        sortable: false,
        renderHeader: (params) => <GridHeaderCell {...params} />,
        valueGetter: (params) => params.row.sources,
        renderCell: (params) => <DiscoverySourcesCell sources={params.value} />,
      },
      {
        field: 'utilization',
        headerName: t('INTEGRATIONS.USAGE.UTILIZATION'),
        minWidth: 250,
        maxWidth: 250,
        flex: 1,
        editable: false,
        sortable: true,
        valueGetter: (params) => {
          const utilization = getUtilization(params.row);

          return utilization
            ? utilization
            : getEstimatedUtilization(
                params.row.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value ?? '0',
                params.row.measureReports?.totalUsers
              );
        },
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('APPLICATIONS.TOOLTIPS.UTILIZATION.TITLE'),
              content: t('APPLICATIONS.TOOLTIPS.UTILIZATION.TOOLTIP'),
            }}
            {...params}
          />
        ),
        renderCell: (params) => (
          <UtilizationCell
            utilization={params.value}
            license={params.row.license ?? {}}
            activeUsers={params.row.measureReports?.uniqueUserAccessCountLast28d?.at(0)?.value ?? '0'}
            userCount={params.row.measureReports?.totalUsers}
          />
        ),
      },
      {
        field: 'issues',
        headerName: t('APPLICATIONS.USAGE.ISSUES'),
        minWidth: 300,
        flex: 2,
        valueGetter: (params) =>
          params.row.dataHealth?.checks
            ?.filter(isCheckWarning)
            .map((check) => t(`APPLICATIONS.USAGE.DATA_HEALTH.${check.code}.HEADER`))
            .join(', '),
        renderHeader: (params) => <GridHeaderCell {...params} />,
        renderCell: (params) => (
          <Text variant="body-regular" size="M">
            {params.value}
          </Text>
        ),
      },
      {
        field: 'activityFrequency',
        headerName: t('INTEGRATIONS.USAGE.TYPICALLY_USED'),
        minWidth: 150,
        flex: 1,
        editable: false,
        sortable: true,
        sortComparator: (a, b) => Bins.indexOf(a) - Bins.indexOf(b),
        valueGetter: (params) => getActivityFrequency(params.row),
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('APPLICATIONS.TOOLTIPS.TYPICALLY_USED.TITLE'),
              content: t('APPLICATIONS.TOOLTIPS.TYPICALLY_USED.TOOLTIP'),
            }}
            {...params}
          />
        ),
        renderCell: (params) => <ActivityFrequencyCell activityFrequency={params.value} />,
      },
      {
        field: 'lastUsed',
        headerName: t('INTEGRATIONS.USAGE.LAST_ACTIVITY'),
        minWidth: 150,
        flex: 1,
        editable: false,
        sortable: true,
        sortComparator: gridNumberComparator,
        valueGetter: (params) => getDaysFromNow(params.row.measureReports?.lastActivity56d),
        renderHeader: (params) => (
          <GridHeaderCell
            tooltip={{
              title: t('APPLICATIONS.TOOLTIPS.LAST_USED.TITLE'),
              content: t('APPLICATIONS.TOOLTIPS.LAST_USED.TOOLTIP'),
            }}
            {...params}
          />
        ),
        renderCell: (params) => <LastUsedCellWithSkeleton lastUsed={params.value} />,
      },
      {
        field: 'healthiness',
        type: 'boolean',
        minWidth: 100,
        valueGetter: (params) => params.row.hasWarnings,
        filterOperators: [booleanFilterOperator],
      },
    ],
    [t]
  );

  const columnVisibilityModel = useMemo(
    () => ({
      healthiness: false,
      issues: dataHealthFilter === HealthFilter.UNHEALTHY,
      utilization: dataHealthFilter !== HealthFilter.UNHEALTHY,
      activityFrequency: dataHealthFilter !== HealthFilter.UNHEALTHY,
      lastUsed: dataHealthFilter !== HealthFilter.UNHEALTHY,
    }),
    [dataHealthFilter]
  );

  const renderNoRowsOverlay = useCallback(
    () => (
      <ApplicationGridNoRows
        filter={dataHealthFilter}
        unhealthyApplicationsCount={unhealthyApplicationsCount}
        integrationsHref={integrationsHref}
      />
    ),
    [dataHealthFilter, unhealthyApplicationsCount, integrationsHref]
  );

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setActiveTab(UsageTabs.APPLICATIONS);
    navigate(routes.APPLICATIONS.USAGE_DETAIL, { applicationId: params.row.id });
  };

  return (
    <StyledDataGrid
      noRowsOverlayHeight={400}
      autoHeight
      getRowHeight={() => 60} // defines actual rows height
      apiRef={apiRef}
      columns={columns}
      rows={applications || []}
      disableRowSelectionOnClick
      disableColumnMenu
      hideFooterRowCount
      loadingStyle="skeleton"
      loading={loading}
      hideFooter
      initialState={{
        sorting: {
          sortModel: [{ field: 'activeUsers', sort: 'desc' }],
        },
      }}
      columnVisibilityModel={columnVisibilityModel}
      onRowClick={handleRowClick}
      slots={{
        noRowsOverlay: renderNoRowsOverlay,
        noResultsOverlay: renderNoRowsOverlay,
      }}
    />
  );
};

// there is a bug in MUI DataGrid where the boolean filter operator doesn't work
export const booleanFilterOperator: GridFilterOperator<ApplicationWithMeasureReportsListItem, boolean> = {
  label: 'Boolean',
  value: 'booleanIs',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || filterItem.value === null || !filterItem.operator) {
      return () => true;
    }

    return (params: GridCellParams): boolean => {
      return params.value === filterItem.value;
    };
  },
};
