import { AccountContextType, useAccountContext } from '../modules/account/AccountContext';
import React, { FC, ReactNode } from 'react';
import { PageNotFound } from '../modules/errors/PageNotFound';

export type PageBehindFeatureFlagProps = {
  isAccessible: (accountContext: AccountContextType) => boolean;
  children: ReactNode;
  fallback?: ReactNode;
};

export const ConditionalPage: FC<PageBehindFeatureFlagProps> = ({
  isAccessible,
  fallback = <PageNotFound />,
  children,
}) => {
  const accountContextData = useAccountContext();
  if (isAccessible(accountContextData)) {
    return <>{children}</>;
  }
  return <>{fallback}</>;
};
