import { useTranslation } from 'react-i18next';
import useBillingFrequencyOptions from '../../../../../../hooks/useBillingFrequencyOptions';
import { useContractCategoryOptions as useBaseContractCategoryOptions } from '../../../../../../hooks/useContractCategoryOptions';
import useContractDepartmentOptions from '../../../../../../hooks/useContractDepartmentOptions';
import { useContractOriginOptions } from '../../../../../../hooks/useContractOriginOptions';
import usePaymentTermsOptions from '../../../../../../hooks/usePaymentTermsOptions';
import { useContractRenewalStatusOptions } from '../../../../../../hooks/useContractRenewalStatusOptions';
import useRollingFrequencyOptions from '../../../../../../hooks/useRollingFrequencyOptions';
import useUnifiedStageOptions from '../../../../../../hooks/useUnifiedStageOptions';
import { useContractThresholdOptions } from '../../../../../../hooks/useContractThresholdOptions';
import { SimpleOption } from '@verticeone/design-system';
import { ViewSettingsFormData } from '../schema';
import { useContractOwnerOptions } from '../../../../../../hooks/useContractOwnerOptions';

const useContractRollingFrequencyOptions = () => {
  const { options } = useRollingFrequencyOptions({ isOneOffIncluded: true });
  return { options };
};

const useContractCategoryOptions = () => {
  const options = useBaseContractCategoryOptions();
  return { options };
};

type FormFilterField = {
  name: keyof ViewSettingsFormData['filters'];
  label: string;
  placeholder: string;
  useOptions: () => { options: SimpleOption[] };
};

export const useFormFilterFields = (): FormFilterField[] => {
  const { t } = useTranslation();

  return [
    {
      name: 'contractRenewalStatus',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_RENEWAL_STATUS'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractRenewalStatusOptions,
    },
    {
      name: 'contractValue',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_VALUE'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractThresholdOptions,
    },
    {
      name: 'contractDepartment',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.DEPARTMENT'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractDepartmentOptions,
    },
    {
      name: 'contractOwner',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_OWNER'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractOwnerOptions,
    },
    {
      name: 'contractRollingFrequency',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_ROLLING_FREQUENCY'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractRollingFrequencyOptions,
    },
    {
      name: 'contractBillingFrequency',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_BILLING_FREQUENCY'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useBillingFrequencyOptions,
    },
    {
      name: 'contractPaymentTerms',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_PAYMENT_TERMS'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: usePaymentTermsOptions,
    },
    {
      name: 'contractStage',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_STAGE'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useUnifiedStageOptions,
    },
    {
      name: 'contractOrigin',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_ORIGIN'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractOriginOptions,
    },
    {
      name: 'contractCategory',
      label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.CONTRACT_CATEGORY'),
      placeholder: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.ALL_DEFAULT'),
      useOptions: useContractCategoryOptions,
    },
  ];
};
