import { useAccountContext } from './AccountContext';
import { useGetAccountSettingsQuery } from '@vertice/slices';
import { DEFAULT_CURRENCY } from '../../constants/currency';
import { Loadable } from '@verticeone/utils/async';
import { useMemo } from 'react';

export const useAccountPreferredCurrency = (): Loadable<string> => {
  const { accountId } = useAccountContext();
  const { data: accountSettings, isLoading: isAccountSettingsLoading } = useGetAccountSettingsQuery({ accountId });

  return useMemo(
    () =>
      isAccountSettingsLoading
        ? { isLoading: true }
        : { isLoading: false, data: accountSettings?.preferredCurrency || DEFAULT_CURRENCY },
    [accountSettings, isAccountSettingsLoading]
  );
};
