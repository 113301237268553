import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Text } from '@verticeone/design-system';

import {
  EditorDrawerForm,
  EditorDrawerGroup,
  EditorDrawerHeader,
  EditorDrawerScrollContainer,
} from '../../../../EditorDrawer';
import { createSchema, FormSchema } from './formSchema';
import TextField from './components/TextField';
import { EditorDrawerBaseFooter } from '../../../../EditorDrawer/EditorDrawerFooter';
import SelectField from './components/SelectField';
import ConnectionUrlField from './components/ConnectionUrlField';
import ConnectionSelectField from './components/ConnectionSelectField';
import BodyField from './components/BodyField';
import VariableMapper from './components/VariableMapper';
import HeaderMapperFields from './components/HeaderMapper';
import { ProcessDefinition, TaskDefinition } from '../../../../../../definitionsTypes';
import { useTranslation } from 'react-i18next';

type GenericIntegrationFormProps = {
  task: TaskDefinition;
  defaultValues: FormSchema;
  onSave: (data: FormSchema) => void;
  onClose: () => void;
  onDelete: (taskId: string) => void;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
};

export const GenericIntegrationForm = ({
  task,
  defaultValues,
  onSave,
  onClose,
  onDelete,
  processDefinition,
  workflowServiceRef,
}: GenericIntegrationFormProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.CUSTOM_INTEGRATION' });

  const { register, formState, handleSubmit, control, watch } = useForm<FormSchema>({
    resolver: zodResolver(createSchema),
    mode: 'onChange',
    defaultValues,
  });

  return (
    <EditorDrawerForm onSubmit={handleSubmit(onSave)}>
      <EditorDrawerHeader
        title={task?.task.name ?? ''}
        deleteOptions={{
          nodeName: task.task.name ?? '',
          onDelete: () => onDelete(task.task.id),
        }}
      />
      <EditorDrawerScrollContainer>
        <EditorDrawerGroup title={t('SECTION.TASK_DETAILS')}>
          <TextField
            name="name"
            required
            register={register}
            control={control}
            placeholder={t('FORM.NAME.PLACEHOLDER')}
            title={t('FORM.NAME.TITLE')}
          />
          <TextField
            name="description"
            register={register}
            control={control}
            placeholder={t('FORM.DESCRIPTION.PLACEHOLDER')}
            title={t('FORM.DESCRIPTION.TITLE')}
            required={false}
          />
        </EditorDrawerGroup>
        <EditorDrawerGroup title={t('SECTION.CONNECTION_DETAILS')}>
          <ConnectionSelectField name="connection" title={t('FORM.CONNECTION.TITLE')} control={control} />
        </EditorDrawerGroup>
        <EditorDrawerGroup title="Fields">
          <VariableMapper
            register={register}
            control={control}
            task={task}
            processDefinition={processDefinition}
            workflowServiceRef={workflowServiceRef}
            title={{
              left: t('FORM.VARIABLE_MAPPER.LEFT_TITLE'),
              right: t('FORM.VARIABLE_MAPPER.RIGHT_TITLE'),
            }}
          />
        </EditorDrawerGroup>
        <EditorDrawerGroup title={t('SECTION.PAYLOAD')}>
          <Stack direction="row" width="calc(50% - 12px)">
            <SelectField
              name="method"
              title={t('FORM.METHOD.TITLE')}
              control={control}
              options={
                [
                  { value: 'GET', label: 'GET' },
                  { value: 'POST', label: 'POST' },
                  { value: 'PUT', label: 'PUT' },
                  { value: 'DELETE', label: 'DELETE' },
                ] as const
              }
            />
          </Stack>
          <ConnectionUrlField
            name="connection_url"
            connectionName="connection"
            register={register}
            control={control}
            title={t('FORM.CONNECTION_URL.TITLE')}
          />
          <HeaderMapperFields
            register={register}
            control={control}
            title={{
              header: t('FORM.HEADER_MAPPER.HEADER'),
              left: t('FORM.HEADER_MAPPER.LEFT_TITLE'),
              right: t('FORM.HEADER_MAPPER.RIGHT_TITLE'),
            }}
          />
          <BodyField name="body" control={control} visible={watch('method') !== 'GET'} title={t('FORM.BODY.TITLE')} />
        </EditorDrawerGroup>
        <EditorDrawerGroup title={t('SECTION.ADDITIONAL_DETAILS')}>
          <Stack gap={2}>
            <Text variant="caption" color="text2" size="XS">
              {t('ADDITIONAL_INFO.ERROR_STATE.TITLE')}
            </Text>
            <Text variant="body-regular" color="text1" size="S">
              {t('ADDITIONAL_INFO.ERROR_STATE.DESCRIPTION')}
            </Text>
          </Stack>
          <Stack gap={2}>
            <Text variant="caption" color="text2" size="XS">
              {t('ADDITIONAL_INFO.HTTP_REQUEST.TITLE')}
            </Text>
            <Text variant="body-regular" color="text1" size="S">
              {t('ADDITIONAL_INFO.HTTP_REQUEST.DESCRIPTION')}
            </Text>
          </Stack>
          <Stack gap={2}>
            <Text variant="caption" color="text2" size="XS">
              {t('ADDITIONAL_INFO.AUTO_RETRY.TITLE')}
            </Text>
            <Text variant="body-regular" color="text1" size="S">
              {t('ADDITIONAL_INFO.AUTO_RETRY.DESCRIPTION')}
            </Text>
          </Stack>
        </EditorDrawerGroup>
        <Text variant="body-regular" size="XS" color="inactive1">
          {t('ADDITIONAL_INFO.HELP')}
        </Text>
      </EditorDrawerScrollContainer>
      <EditorDrawerBaseFooter onDiscard={onClose} isSubmitting={formState.isSubmitting} disabled={!formState.isValid} />
    </EditorDrawerForm>
  );
};
