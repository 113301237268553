import React, { ReactNode, useState } from 'react';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import CloudClientProvider from './CloudClientProvider';
import { BreadcrumbItem, PageHeader } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useRoutes } from '@verticeone/router/useRoutes';
import { Stack } from '@mui/material';

export type OutletContextType = { headerActions: ReactNode; setHeaderActions: (actions: ReactNode) => void };

const CloudLayout = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const location = useLocation();
  const [headerActions, setHeaderActions] = useState<ReactNode>();

  const currentPath = location.pathname;
  const baseBreadcrumbItems: BreadcrumbItem[] = (
    ['OVERVIEW', 'ANALYTICS', 'INSIGHTS', 'EDP', 'OPTIMIZATION', 'RIO'] as const
  )
    .map((link) => {
      const basePath = routes.CLOUD[link].ABSOLUTE_PATH;
      if (!currentPath.startsWith(basePath)) return null;

      const labels = {
        OVERVIEW: 'OVERVIEW',
        ANALYTICS: 'ANALYTICS',
        INSIGHTS: 'INSIGHTS',
        EDP: 'EDP',
        OPTIMIZATION: 'OPTIMIZATION',
        RIO: 'RIO_NEW.MAIN',
      };

      const item: BreadcrumbItem<typeof RouterLink> = {
        label: t(`CLOUD.NAVBAR.${labels[link]}`),
        to: basePath,
        component: RouterLink,
      };

      if (link === 'OPTIMIZATION') {
        const subPages = ['RECOMMENDATION'] as const;
        const subItems = subPages
          .map((subLink) => {
            const subBasePath = routes.CLOUD.OPTIMIZATION[subLink].ABSOLUTE_PATH;
            if (!currentPath.startsWith(subBasePath)) return null;
            const code = currentPath.replace(subBasePath, '').replace(/^\//, '');

            return {
              label: code,
              to: currentPath,
              component: RouterLink,
            };
          })
          .filter(Boolean);

        return [item, ...subItems];
      }

      if (link === 'RIO') {
        const subPages = ['SP', 'RI', 'TRANSACTIONS'] as const;
        const subItems = subPages
          .map((subLink) => {
            const subBasePath = routes.CLOUD.RIO[subLink].ABSOLUTE_PATH;
            if (!currentPath.startsWith(subBasePath)) return null;

            const subLabels = {
              SP: 'RIO_NEW.SAVINGS_PLANS',
              RI: 'RIO_NEW.RESERVED_INSTANCES',
              TRANSACTIONS: 'RIO_NEW.TRANSACTIONS',
            };

            return {
              label: t(`CLOUD.NAVBAR.${subLabels[subLink]}`),
              to: subBasePath,
              component: RouterLink,
            };
          })
          .filter(Boolean);

        return [item, ...subItems];
      }

      return item;
    })
    .flat()
    .filter(Boolean) as BreadcrumbItem[];

  const items: BreadcrumbItem<typeof RouterLink>[] = [
    { label: t('CLOUD.NAVBAR.CLOUD_COST_OPTIMIZATION') },
    ...baseBreadcrumbItems,
  ];

  return (
    <CloudClientProvider>
      <Stack height="100%" width="100%">
        <PageHeader breadcrumb={<PageHeader.Breadcrumbs items={items} />} color="tertiary" actions={headerActions} />
        <Stack padding={6} paddingTop={0} gap={8} width="100%">
          <Outlet context={{ setHeaderActions }} />
        </Stack>
      </Stack>
    </CloudClientProvider>
  );
};

export default CloudLayout;
