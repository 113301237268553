import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import { Box, useTheme } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import patternFill from 'highcharts/modules/pattern-fill';
import { useTranslation } from 'react-i18next';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { HighchartTooltip } from '@vertice/core/src/components/charts/components/Tooltip/HighchartTooltip';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import TooltipWrapper from '@vertice/core/src/components/charts/components/Tooltip/TooltipWrapper';
import TooltipValueWithTimeInfo from '@vertice/core/src/components/charts/components/Tooltip/TooltipValueWithTimeInfo';
import TooltipSeriesValuePair from '@vertice/core/src/components/charts/components/Tooltip/TooltipSeriesValuePair';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { getTextVariantStyle } from '@verticeone/design-system';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import {
  dayInMonthFormatter,
  yLabelCurrencyFormatter,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { useGraphData } from './useGraphData';
import Legend from '@vertice/core/src/components/charts/components/Legend/Legend';
import { getDiagonalCrossPattern, getDotPattern } from './utils';
import { LoadableComponent } from '@verticeone/design-system';
import { AWS_BRAND_COLOR, AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import TooltipTotal from '@vertice/core/src/components/charts/components/Tooltip/TooltipTotal';
import { useGraph } from '../../../components/providers/GraphProvider';
import GraphLayout, { GraphLayoutProps } from '../../../components/Graph/GraphLayout';
import { getColorPattern } from '@vertice/core/src/components/charts/components/Legend/dashedColor';

highchartsAccessibility(Highcharts);
patternFill(Highcharts);

const useFormatDate = () => {
  const { locale } = useLocaleContext();

  return (value: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(new Date(value));
};

const useGraphSeries = () => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE.GRAPH' });
  const { isOnDemandUsageOnly, shoppingCartUnits } = useGraph();
  const { data, isFetching } = useGraphData();

  const dynamicItems = useMemo(
    () => [
      {
        id: 'shoppingCartUnits',
        type: 'column',
        name: t('SHOPPING_CART'),
        color: getColorPattern(palette.warning.color2),
        data: data?.map((item) => item.shoppingCartUnits) ?? [],
        opacity: 1,
        states: {
          hover: { color: getColorPattern(palette.warning.color2), opacity: 1 },
          inactive: { opacity: 0.5 },
        },
        custom: {
          legendOutlined: true,
          legendColor: getColorPattern(palette.warning.color2),
          tooltipColor: getColorPattern(palette.warning.color2),
        },
        index: 1,
        zIndex: 1,
        visible: !!shoppingCartUnits,
      },
      {
        id: 'fargateOnDemandCost',
        type: 'area' as const,
        name: t('FARGATE_LABEL'),
        color: palette.visualization.divergent.tertiary['+50'],
        lineWidth: 1,
        fillColor: getDiagonalCrossPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
        data: data?.map((item) => item.fargateOnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.tertiary['+20'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.tertiary['+20']
          ),
          tooltipLabel: t('FARGATE_ON_DEMAND_LABEL'),
          hideLegend: false,
        },
        index: 2,
        zIndex: 2,
      },
      {
        id: 'fargateSpCoveredCost',
        type: 'area' as const,
        name: t('FARGATE_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
        data: data?.map((item) => item.fargateSpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.tertiary['+20'], 5),
          tooltipLabel: t('FARGATE_SP_RI_LABEL'),
          hideLegend: true,
        },
        index: 3,
        zIndex: 3,
        visible: !isOnDemandUsageOnly,
      },
      {
        id: 'ec2OnDemandCost',
        type: 'area' as const,
        name: t('EC2_LABEL'),
        color: palette.visualization.divergent.secondary['-50'],
        fillColor: getDiagonalCrossPattern(
          palette.transparent.color2,
          palette.visualization.divergent.secondary['-20']
        ),
        lineWidth: 1,
        data: data?.map((item) => item.ec2OnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.secondary['-20'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.secondary['-20']
          ),
          tooltipLabel: t('EC2_ON_DEMAND_LABEL'),
          hideLegend: false,
        },
        index: 4,
        zIndex: 4,
      },
      {
        id: 'ec2SpCoveredCost',
        type: 'area' as const,
        name: t('EC2_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20']),
        data: data?.map((item) => item.ec2SpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.secondary['-20'], 5),
          tooltipLabel: t('EC2_SP_RI_LABEL'),
          hideLegend: true,
        },
        index: 5,
        zIndex: 5,
        visible: !isOnDemandUsageOnly,
      },
      {
        id: 'lambdaOnDemandCost',
        type: 'area' as const,
        name: t('LAMBDA_LABEL'),
        color: palette.visualization.divergent.primary['-50'],
        fillColor: getDiagonalCrossPattern(palette.transparent.color2, palette.visualization.divergent.primary['-30']),
        lineWidth: 1,
        data: data?.map((item) => item.lambdaOnDemandCost) ?? [],
        custom: {
          legendColor: palette.visualization.divergent.primary['-30'],
          tooltipColor: getDiagonalCrossPattern(
            palette.transparent.color2,
            palette.visualization.divergent.primary['-30']
          ),
          tooltipLabel: t('LAMBDA_ON_DEMAND_LABEL'),
          hideLegend: false,
        },
        index: 6,
        zIndex: 6,
      },
      {
        id: 'lambdaSpCoveredCost',
        type: 'area' as const,
        name: t('LAMBDA_LABEL'),
        color: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20']),
        data: data?.map((item) => item.lambdaSpCoveredCost) ?? [],
        custom: {
          legendColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20']),
          tooltipColor: getDotPattern(palette.transparent.color2, palette.visualization.divergent.primary['-20'], 5),
          tooltipLabel: t('LAMBDA_SP_RI_LABEL'),
          hideLegend: true,
        },
        index: 7,
        zIndex: 7,
        visible: !isOnDemandUsageOnly,
      },
    ],
    [data, isOnDemandUsageOnly, palette, shoppingCartUnits, t]
  );

  const series = useMemo(
    () =>
      [
        {
          id: 'costEWMA',
          type: 'spline' as const,
          name: t('AVERAGE_COST_LABEL'),
          color: palette.text.color1,
          dashStyle: 'Dash' as const,
          data: data?.map((item) => item.costEWMA) ?? [],
          custom: {
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color1,
            hideLegend: false,
          },
          visible: !isOnDemandUsageOnly,
          zIndex: 100,
        },
        {
          id: 'spRiPurchaseRecommendation',
          type: 'spline' as const,
          name: t('MAXIMUM_COVERAGE_LABEL'),
          color: palette.primary.color2,
          data: data?.map((item) => item.spRiPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.primary.color2,
            tooltipColor: palette.primary.color2,
            hideLegend: false,
          },
          visible: !isOnDemandUsageOnly,
        },
        {
          id: 'costOnDemandEWMA',
          type: 'spline' as const,
          name: t('ON_DEMAND_AVERAGE_COST_LABEL'),
          color: palette.text.color1,
          dashStyle: 'Dash' as const,
          data: data?.map((item) => item.costOnDemandEWMA) ?? [],
          custom: {
            legendColor: palette.text.color1,
            tooltipColor: palette.text.color1,
            hideLegend: false,
          },
          visible: isOnDemandUsageOnly,
          zIndex: 100,
        },
        {
          id: 'spOnDemandPurchaseRecommendation',
          type: 'spline' as const,
          name: t('VERTICE_RECOMMENDATION_LABEL'),
          color: palette.tertiary.color2,
          data: data?.map((item) => item.spOnDemandPurchaseRecommendation) ?? [],
          custom: {
            legendColor: palette.tertiary.color2,
            tooltipColor: palette.tertiary.color2,
            hideLegend: false,
          },
          visible: isOnDemandUsageOnly,
        },
        {
          id: 'uncoverableCost',
          type: 'area' as const,
          name: t('INELIGIBLE_RESOURCES_LABEL'),
          color: palette.visualization.divergent.primary['+20'],
          data: data?.map((item) => item.uncoverableCost) ?? [],
          custom: {
            legendColor: palette.visualization.divergent.primary['+20'],
            tooltipColor: palette.visualization.divergent.primary['+20'],
            hideLegend: false,
          },
          visible: !!data?.some((item) => item.uncoverableCost !== null),
        },
        {
          id: 'onDemandUsage',
          type: 'line' as const,
          name: t('ON_DEMAND_USAGE_LABEL'),
          data: [],
          custom: {
            legendColor: getDiagonalCrossPattern(palette.text.color3, palette.core.color2),
            hideLegend: false,
          },
        },
        {
          id: 'coveredBySPRI',
          type: 'line' as const,
          name: t('USAGE_COVERED_BY_SP_RI_LABEL'),
          data: [],
          custom: {
            legendColor: getDotPattern(palette.text.color3, palette.core.color2, 5),
            hideLegend: false,
          },
        },
        ...dynamicItems,
      ].filter(Boolean),
    [data, dynamicItems, isOnDemandUsageOnly, palette, t]
  );

  const legend = useMemo(
    () =>
      series
        .filter(({ visible, custom: { hideLegend } }) => visible !== false && hideLegend !== true)
        .map(({ custom: { legendColor: color }, name: label, id }) => ({
          id,
          color,
          label,
          outlined: ['costEWMA', 'costOnDemandEWMA'].includes(id),
        })),
    [series]
  );

  const visibleSeries = useMemo(() => series.filter(({ visible }) => visible !== false), [series]);

  return useMemo(
    () => ({
      isFetching,
      categories: data?.map((item) => item.date) ?? [],
      lastDataTimeIndex: data?.findIndex((item) => item.spRiPurchaseRecommendation !== null),
      series: visibleSeries,
      legend,
    }),
    [data, isFetching, legend, visibleSeries]
  );
};

type GraphProps = Omit<ReturnType<typeof useGraphSeries>, 'isFetching'>;

const Graph = ({ series, legend, categories, lastDataTimeIndex }: GraphProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OPTIMIZE.COMPUTE.GRAPH' });
  const { locale } = useLocaleContext();
  const { isOnDemandUsageOnly } = useGraph();
  const { palette } = useTheme();
  const [chart, setChartRef] = useChartRef();
  const applyColumnHover = useStackedColumnHover();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const formatDate = useFormatDate();
  const formatCurrency = useFormatCurrency();

  const options = buildOptions([
    applyStyledHighcharts,
    applyColumnHover,
    applyXAxisOffset,
    (opts) =>
      mergeOptions(
        {
          chart: { spacingTop: 12, spacingBottom: 12, height: 352 },
          plotOptions: {
            column: {
              groupPadding: 0.1,
            },
            area: {
              stacking: 'normal',
            },
            series: {
              marker: {
                radius: 4,
                enabled: false,
                states: {
                  inactive: { opacity: 0 },
                },
              },
            },
          },
          rangeSelector: {
            selected: 0,
          },
          xAxis: {
            categories,
            minPadding: 0,
            maxPadding: 0,
            labels: { formatter: dayInMonthFormatter },
            plotLines: [
              {
                color: palette.core.color6,
                value: lastDataTimeIndex,
                width: 1,
                zIndex: 100,
              },
            ],
          },
          yAxis: {
            labels: {
              formatter: yLabelCurrencyFormatter(palette, locale, AWS_DEFAULT_CURRENCY, 0, 2, true),
              x: 46,
            },
            title: {
              offset: -36,
              text: t('Y_AXIS_TITLE'),
              style: {
                ...(getTextVariantStyle({ variant: 'button-bold', size: 'S' }) as Highcharts.CSSObject),
                color: palette.text.color2,
              },
            },
          },
          tooltip: {
            shared: true,
          },
          series: series as Highcharts.SeriesOptionsType[],
        },
        opts
      ),
  ]);

  return (
    <>
      <Box padding={4} marginLeft="auto">
        <Legend items={legend} testId="compute-graph-legend" />
      </Box>
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      <HighchartTooltip chart={chart}>
        {({ x, points }) => {
          const totalCost =
            points?.reduce((acc, point) => {
              const id = point.series.userOptions?.id;
              const visible = point.series.visible;
              const validIds = ['fargateOnDemandCost', 'ec2OnDemandCost', 'lambdaOnDemandCost'];
              const visibleIds = ['fargateSpCoveredCost', 'ec2SpCoveredCost', 'lambdaSpCoveredCost'];

              if (id && (validIds.includes(id) || (visible && visibleIds.includes(id)))) {
                return acc + (point.y ?? 0);
              }
              return acc;
            }, 0) || null;

          const filteredPoints = points?.filter((point) => {
            const isPurchasedRecommendation = [
              'spRiPurchaseRecommendation',
              'spOnDemandPurchaseRecommendation',
              'shoppingCartUnits',
            ].includes(point.series.userOptions?.id!);
            return points?.length > 2 !== isPurchasedRecommendation;
          });

          if (!!filteredPoints?.length) {
            return (
              <TooltipWrapper>
                <TooltipValueWithTimeInfo value="" timeInfo={formatDate(x!.toString())} />
                {filteredPoints?.map(
                  (point) =>
                    point.series.userOptions && (
                      <TooltipSeriesValuePair
                        seriesColor={(point.series.userOptions.custom as any).tooltipColor}
                        seriesName={
                          (point.series.userOptions.custom as any).tooltipLabel || point.series.userOptions.name
                        }
                        value={formatCurrency(point!.y ?? 0, {
                          currency: AWS_DEFAULT_CURRENCY,
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                        key={point.series.userOptions.id}
                        borderColor={palette.core.color5}
                      />
                    )
                )}
                {totalCost && (
                  <TooltipTotal
                    value={formatCurrency(totalCost, {
                      currency: AWS_DEFAULT_CURRENCY,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    label={isOnDemandUsageOnly ? t('TOTAL_ON_DEMAND_COST_PER_DAY') : t('TOTAL_COST_PER_DAY')}
                  />
                )}
              </TooltipWrapper>
            );
          }
        }}
      </HighchartTooltip>
    </>
  );
};

const LoadableGraph = () => {
  const { isFetching, ...otherProps } = useGraphSeries();

  return (
    <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
      <Graph {...otherProps} />
    </LoadableComponent>
  );
};

const GraphContainer = ({ shoppingCartUnits, forceOnDemandUsageOnly = false }: GraphLayoutProps) => (
  <GraphLayout shoppingCartUnits={shoppingCartUnits} forceOnDemandUsageOnly={forceOnDemandUsageOnly}>
    <LoadableGraph />
  </GraphLayout>
);

export default GraphContainer;
