import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  Text,
  Button,
  useSimpleDialogContext,
} from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { parseIntegrationId } from '../utils';

import ApiKeyViewForm from './components/Form/apiKey/ViewForm';
import IntegrationSetupContainer from '../../components/IntegrationSetupContainer';
import { OutletContextType } from '../../types';

const ViewIntegration = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const { integrationId } = useParams();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { getConfirmation } = useSimpleDialogContext();
  const { setBreadcrumbsItems } = useOutletContext<OutletContextType>();

  const { integrationName, authType } = parseIntegrationId(integrationId || '');

  useEffect(() => {
    setBreadcrumbsItems([
      {
        label: t('VIEW_TITLE', { integrationName }),
      },
    ]);

    return () => {
      setBreadcrumbsItems([]);
    };
  }, [integrationName, setBreadcrumbsItems, t]);

  if (!integrationId) {
    navigate(routes.INTEGRATIONS);
    return null;
  }

  const onEditClick = async () => {
    const confirm = await getConfirmation({
      title: t('EDIT_APPROVAL_DIALOG.TITLE'),
      description: (
        <Stack gap={2} direction="column" textAlign="center">
          <Text variant="body-bold" size="M" color="text2">
            {t('EDIT_APPROVAL_DIALOG.SUB_TITLE')}
          </Text>
          <Text variant="body-regular" size="M" color="text2">
            {t('EDIT_APPROVAL_DIALOG.DESCRIPTION')}
          </Text>
        </Stack>
      ),
      okButton: {
        label: t('EDIT_BUTTON'),
      },
    });
    if (confirm.status === 'ok') {
      navigate(routes.INTEGRATIONS.CUSTOM.EDIT, { integrationId });
    }
  };

  return (
    <IntegrationSetupContainer>
      <Card maxWidth={1280} width="100%" marginX="auto">
        <CardHeader>
          <CardHeaderDescription>
            <Stack direction="row" justifyContent="space-between">
              <Text variant="heading" size="S" color="text1">
                {t('VIEW_TITLE', { integrationName })}
              </Text>
              <Button variant="outline" size="S" color="neutral" onClick={onEditClick}>
                {t('EDIT_BUTTON')}
              </Button>
            </Stack>
          </CardHeaderDescription>
        </CardHeader>
        <Stack gap={4} p={6}>
          {authType === 'ApiKey' && <ApiKeyViewForm integrationId={integrationId} />}
        </Stack>
      </Card>
    </IntegrationSetupContainer>
  );
};

export default ViewIntegration;
