import { ZodType, z } from 'zod';
import { CostModelLineItem } from '../../../../../../saas/contract/components/CostModelLineItemsTable';
import { AllocationSpan } from '../../../../../../saas/contract/components/CostModelProductList/types';

export const overridableCostSchema = z.object({
  amount: z.number(),
  enabled: z.boolean(),
});

export const allocationSpanSchema = z.object({
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
}) satisfies ZodType<AllocationSpan>;

export const costModelLineItemSchema = z.object({
  id: z.string(),
  lineItemType: z.enum(['SERVICE', 'ITEM']),
  description: z.string().nullable(),
  quantity: z.number().nullable(),
  unitCost: z.number().nullish(),
  totalCost: z.number().nullish(),
  allocationSpan: allocationSpanSchema.nullish(),
}) satisfies ZodType<CostModelLineItem>;

export const contractLineItemsSchema = z.object({
  lineItems: z.array(costModelLineItemSchema).optional(),
  paymentTerms: z.enum(['NET_15', 'NET_30', 'NET_45', 'NET_60', 'NET_90', 'OTHER']).nullish(),
  currency: z.string(),
  annualCostOverride: overridableCostSchema.optional(),
  totalCostOverride: overridableCostSchema.optional(),
});

export type ContractLineItemsForm = z.infer<typeof contractLineItemsSchema>;
