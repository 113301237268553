import { createAttachedRoutes, createPortableRoutes } from '@verticeone/router/utils';
import { useMemo } from 'react';

import { applicationsRoutes } from '../modules/applications/routes';
import { intelligentWorkflowsRoutes } from '../modules/intelligentWorkflows/routes';
import { pageNotFoundRoutes } from '../modules/errors/PageNotFound/routes';
import { integrationsRoutes } from '../modules/preferences/routes';

export const contractRoutes = createPortableRoutes({
  CONTRACTS: {
    path: '/contracts',
    children: {
      DETAIL: {
        path: ':contractId',
        children: {
          EDIT: {
            path: '#edit',
          },
        },
      },
    },
  },
} as const);

export const coreRoutes = {
  ...pageNotFoundRoutes,
  ...applicationsRoutes,
  ...contractRoutes,
  ...intelligentWorkflowsRoutes,
  ...integrationsRoutes,
};

export const useCoreRoutes = () => {
  return useMemo(
    () =>
      createAttachedRoutes({
        ...coreRoutes,
      }),
    []
  );
};
