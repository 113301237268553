import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Stack, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { VISOTRUST_PROVIDER_ID } from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { IconWrapper, Text } from '@verticeone/design-system';
import { ConnectionFormSchema } from '../types';
import { ConnectionForm, ConnectionFormData } from './ConnectionForm';

type ConnectionCardContentProps = {
  isSetupFinished: boolean;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  connectVisoTrust: GoodFormOnSubmitCallback<ConnectionFormData>;
};

export const ConnectionCardContent: FC<ConnectionCardContentProps> = ({
  isSetupFinished,
  isEditMode,
  setIsEditMode,
  connectVisoTrust,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { accountId } = useAccountContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
  });

  const workspaceUrl = (integrationData?.parameters?.connectionForm as ConnectionFormSchema | undefined)?.workspaceUrl;
  const workspaceUrlHost = workspaceUrl ? new URL(workspaceUrl).host : '';

  if (isSetupFinished) {
    return (
      <Stack gap={8}>
        <Stack gap={1}>
          <Text variant="caption" size="XS" color="success">
            {t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.WORKSPACE_CONNECTED')}
          </Text>
          <Text variant="heading" size="M" color="success">
            {workspaceUrlHost}
          </Text>
        </Stack>
        <Stack gap={2}>
          <Text variant="caption" size="XS" color="text2">
            {t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.API_TOKEN_LABEL')}
          </Text>
          <Stack
            direction="row"
            gap={2}
            sx={{
              alignSelf: 'flex-start',
              alignItems: 'center',
              border: `1px solid ${palette.input.color2}`,
              borderRadius: '12px',
              backgroundColor: palette.input.color1,
              padding: '12px 16px',
            }}
          >
            <Box sx={{ borderRadius: '10px', backgroundColor: palette.core.bg, padding: '6px' }}>
              <IconWrapper icon={LockOutlinedIcon} size="XL" htmlColor={palette.text.color4} />
            </Box>
            <Text variant="caption" size="XS" color={palette.core.color4}>
              {t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.API_TOKEN_CONNECTED')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <ConnectionForm
      defaultValues={{
        workspaceUrl: workspaceUrl ?? '',
        apiToken: '',
      }}
      onSubmit={connectVisoTrust}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
    />
  );
};
