import { useId, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';

import Popover from './Popover';
import { VariablesList } from '../../../workflowSchema/WorkflowEditor/VariableSelector/VariablesList';
import { VariableChip } from '../../../workflowSchema/WorkflowEditor/VariableSelector/VariableChip';
import {
  filterVariablesGroupsByGroupId,
  filterVariablesGroupsBySearchValue,
  setVariablesGroupsByVariableId,
} from '../../../workflowSchema/WorkflowEditor/VariableSelector/utils';
import GroupFilters, { useGetFilterOptions } from './GroupFilters';
import { Variable } from '../../../workflowSchema';
import { useWorkflowVariablesGroups } from '../../../workflowSchema/WorkflowEditor/forms/EditGatewayForm/useWorkflowVariablesGroups';

export type VariableValue = {
  type: 'Const' | 'Variable';
  value: string;
};

export type VariableSelectorProps = {
  onChange: (value: VariableValue) => void;
  value: VariableValue;
  variables: Variable[];
  disabled?: boolean;
  testPrefix?: string;
};

const VariableSelector = ({ value, onChange, testPrefix, variables, disabled = false }: VariableSelectorProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR' });

  const popoverId = useId();
  const searchFieldRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [groupFilter, setGroupFilter] = useState('all');
  const variableId = value?.type === 'Variable' ? value.value : undefined;
  const searchValue = value?.type === 'Const' ? value.value : undefined;

  const variablesGroups = useWorkflowVariablesGroups({ udfVariables: [], allRequestVariables: variables });

  const { filterOptions } = useGetFilterOptions(variablesGroups);
  const setVariablesGroups = setVariablesGroupsByVariableId({ variableId, variablesGroups });
  const filteredVariablesGroupsBySearch = filterVariablesGroupsBySearchValue({
    variablesGroups: setVariablesGroups,
    searchValue,
  });

  const filteredVariableGroupsByGroupId = filterVariablesGroupsByGroupId({
    variablesGroups: filteredVariablesGroupsBySearch,
    groupId: groupFilter,
  });

  const selectedVariable = useMemo<Variable | null>(() => {
    if (!value || value.type === 'Const') return null;
    return variables.find(({ id }) => id === value.value) ?? null;
  }, [variables, value]);

  return (
    <>
      <Stack flex={1}>
        {!anchorEl && value.type === 'Variable' && (
          <VariableChip
            label={selectedVariable?.label}
            typeLabel={getVariableUserTypeLabel(selectedVariable?.type.labels)}
            isDisabled={disabled}
            onClick={() => setAnchorEl(searchFieldRef.current)}
            customIcon={KeyboardArrowDownIcon}
            testId={testPrefix ? `${testPrefix}Chip` : undefined}
            withIcon
          />
        )}
        <TextField
          sx={{ display: !anchorEl && value.type === 'Variable' ? 'none' : 'flex' }}
          aria-describedby={popoverId}
          size="S"
          value={value.value}
          onFocus={() => setAnchorEl(searchFieldRef.current)}
          disabled={disabled}
          ref={searchFieldRef}
          testId={testPrefix ? `${testPrefix}TextField` : undefined}
          onChange={(e) => onChange({ type: 'Const', value: e.currentTarget.value })}
        />
      </Stack>
      <Popover id={popoverId} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <GroupFilters selectedOptionId={groupFilter} selectOptionId={setGroupFilter} filterOptions={filterOptions} />
        <Stack overflow="auto" height="100%">
          {filteredVariableGroupsByGroupId.map((variableGroup) => (
            <VariablesList
              highlightedLabel={searchValue}
              key={searchValue ? `${variableGroup.id}-searching` : variableGroup.id}
              title={variableGroup.title}
              originsWithVariables={variableGroup.originsWithVariables}
              onVariableClick={(item) => {
                onChange({ type: 'Variable', value: item.id });
                setAnchorEl(null);
              }}
              selectedVariableId={variableId}
              expandedSectionsIds={variableGroup.expandedSectionsIds || undefined}
              variablesCount={variableGroup.count}
            />
          ))}
          {!filteredVariableGroupsByGroupId.length &&
            (searchValue
              ? t('MESSAGES.NO_VARIABLES_MATCHING_QUERY', { query: searchValue })
              : t('MESSAGES.NO_VARIABLES'))}
        </Stack>
      </Popover>
    </>
  );
};

const getVariableUserTypeLabel = (labels?: string[]) => {
  if (!labels) return undefined;

  return labels.filter((label) => label !== 'Null').at(0);
};

export default VariableSelector;
