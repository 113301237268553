import { CloseTwoTone, FilterAltTwoTone, SearchTwoTone } from '@mui/icons-material';
import { IconButton, Stack, StackProps, styled, useTheme } from '@mui/material';
import { Checkbox, IconWrapper, Menu, MenuItem, Text, TextField } from '@verticeone/design-system';
import { ChevronDown, ChevronUp } from 'react-feather';
import { AnimatePresence, motion } from 'framer-motion';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

type Option = { label: string; value: string[] };

type ConversationControlsProps = {
  searchExpanded: boolean;
  setSearchExpanded: (expanded: boolean) => void;
  filterExpanded: boolean;
  setFilterExpanded: (expanded: boolean) => void;
  filterDisabled: boolean;
  filterOptions: Option[];
  filterValue: string[];
  setFilterValue: (value: string[]) => void;
  searchValue: string | null;
  setSearchValue: (value: string | null) => void;
  parentWidth: number;
  hasNext: boolean;
  hasPrevious: boolean;
  goToNext: () => void;
  goToPrevious: () => void;
  currentIndex: number | null;
  commentsLength: number;
};

const PanelButton = styled(IconButton)<{ disabled?: boolean; $highlighted?: boolean }>(
  ({ theme, disabled = false, $highlighted = false }) => ({
    width: 28,
    height: 28,
    flexShrink: 0,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: disabled ? 'not-allowed' : 'pointer',
    zIndex: 100,

    ...(disabled && {
      opacity: 0.4,
    }),

    ...($highlighted && {
      backgroundColor: theme.palette.neutral.color1,
      color: theme.palette.common.white,
    }),

    '&:hover': {
      backgroundColor: disabled ? theme.palette.neutral.hover.color3 : theme.palette.neutral.hover.color4,
    },
  })
);

const StackWrapper = forwardRef<HTMLDivElement, StackProps>((props, ref) => <Stack ref={ref} {...props} />);

const AnimatedStack = motion.create(StackWrapper);
const AnimatedIconWrapper = motion.create(IconWrapper);
const AnimatedPanelButton = motion.create(PanelButton);

const BASE_PADDING = 16;
const EXPANDED_PADDING = 24;
const BUTTON_WIDTH = 36;

export const ConversationControls = ({
  searchExpanded,
  setSearchExpanded,
  filterExpanded,
  setFilterExpanded,
  filterDisabled = true,
  filterOptions,
  filterValue,
  setFilterValue,
  searchValue,
  setSearchValue,
  parentWidth,
  hasNext,
  hasPrevious,
  goToNext,
  goToPrevious,
  currentIndex,
  commentsLength,
}: ConversationControlsProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLDivElement>(null);

  // The layout is weirdly split in order for the visibility toggle to keep it's position and
  // transition properly. This is because we are animating a flex layout, which can be problematic.
  // Both parent stacks need to have a width value, else framer cannot calculate the width and transition cleanly.
  return (
    <AnimatedStack
      height={36}
      direction="row"
      alignItems="center"
      justifyContent="center"
      bgcolor={palette.core.bg}
      border={`1px solid ${palette.core.color3}`}
      borderRadius={3}
      ref={containerRef}
      overflow="hidden"
      animate={{
        width: searchExpanded ? parentWidth - BASE_PADDING : filterDisabled ? BUTTON_WIDTH : BUTTON_WIDTH * 2,
      }}
    >
      <AnimatedStack
        gap={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        flexShrink={0}
        animate={{ width: searchExpanded ? parentWidth - EXPANDED_PADDING : 'auto' }}
        layout
      >
        {searchExpanded && (
          <AnimatedStack
            direction="row"
            width="100%"
            gap={1}
            initial={{ opacity: 0, display: 'none' }}
            animate={{ opacity: 1, display: 'flex' }}
            exit={{ opacity: 0, display: 'none' }}
            transition={{ duration: 0.1, delay: 0.3 }}
            onAnimationComplete={() => inputRef.current?.focus()}
          >
            <TextField
              inputRef={inputRef}
              placeholder={t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.SEARCH')}
              value={searchValue ?? ''}
              onChange={(e) => setSearchValue(e.target.value)}
              size="S"
              fullWidth
              variant="outlined"
              color="secondary"
              endAdornment={
                currentIndex !== null ? (
                  <Text variant="button-regular" color="text4" size="S">
                    {commentsLength - currentIndex}/{commentsLength}
                  </Text>
                ) : undefined
              }
            />
            <PanelButton onClick={goToPrevious} disabled={!hasPrevious}>
              <IconWrapper size="S" icon={ChevronUp} />
            </PanelButton>
            <PanelButton onClick={goToNext} disabled={!hasNext}>
              <IconWrapper size="S" icon={ChevronDown} />
            </PanelButton>
          </AnimatedStack>
        )}

        <AnimatePresence initial={false} mode="popLayout">
          {searchExpanded ? (
            <AnimatedPanelButton
              key="exit"
              onClick={() => {
                setSearchExpanded(false);
                setSearchValue(null);
              }}
              exit={{ opacity: 0, transition: { duration: 0.05, delay: 0 } }}
              animate={{ opacity: 1, transition: { duration: 0.1, delay: 0.2 } }}
            >
              <AnimatedIconWrapper size="S" icon={CloseTwoTone} />
            </AnimatedPanelButton>
          ) : (
            <AnimatedPanelButton
              key="search"
              onClick={() => setSearchExpanded(true)}
              width="100%"
              direction="row"
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.05, delay: 0 } }}
              transition={{ duration: 0.1, delay: 0.3 }}
            >
              <AnimatedIconWrapper size="S" icon={SearchTwoTone} />
            </AnimatedPanelButton>
          )}
          {!searchExpanded && !filterDisabled && (
            <AnimatedPanelButton
              key="filter"
              $highlighted={filterValue.length > 0 || filterExpanded || false}
              onClick={() => setFilterExpanded?.(true)}
              width="100%"
              direction="row"
            >
              <AnimatedIconWrapper size="S" icon={FilterAltTwoTone} />
            </AnimatedPanelButton>
          )}
        </AnimatePresence>
        {!filterDisabled && filterOptions && filterValue && (
          <Menu
            width={280}
            open={filterExpanded || false}
            onClose={() => setFilterExpanded?.(false)}
            anchorEl={containerRef.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {/* TODO: Will need refactor with crocodile to have correct menu groups */}
            {filterOptions?.map((option) => (
              <MenuItem key={option.label}>
                <CheckboxFilterOption
                  key={option.value.join('-')}
                  option={option}
                  checked={option.value.every((val) => filterValue.includes(val))}
                  onChange={(value) => {
                    setFilterValue?.(
                      option.value.every((val) => filterValue.includes(val))
                        ? filterValue.filter((v) => !value.includes(v))
                        : [...filterValue, ...value]
                    );
                  }}
                />
              </MenuItem>
            ))}
            <MenuItem onClick={() => setFilterValue?.([])}>
              <Stack py={1}>
                <Text variant="button-regular" size="S" color="error1">
                  {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.CLEAR_FILTERS')}
                </Text>
              </Stack>
            </MenuItem>
          </Menu>
        )}
      </AnimatedStack>
    </AnimatedStack>
  );
};

const CheckboxFilterOption = ({
  checked,
  option,
  onChange,
}: {
  checked: boolean;
  option: Option;
  onChange: (value: string[]) => void;
}) => {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      gap={2}
      borderRadius={2}
      py={1}
      sx={{ '&:hover': { backgroundColor: palette.neutral.color4 } }}
      onClick={() => onChange(option.value)}
      alignItems="center"
    >
      <Checkbox size="S" color="secondary" checked={checked} />
      <Text variant="button-regular" size="S" color="text1">
        {option.label}
      </Text>
    </Stack>
  );
};
