import { useTranslation } from 'react-i18next';
import { RenewalStatus } from '../components/ContractList/Filters/types';

type RenewalStatusFilterOption = { value: RenewalStatus; label: string };

export const useContractRenewalStatusOptions = () => {
  const { t } = useTranslation();
  const options: RenewalStatusFilterOption[] = [
    {
      value: RenewalStatus.NOT_YET_RENEWED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.NOT_YET_RENEWED'),
    },
    {
      value: RenewalStatus.NOT_YET_RENEWED_DEADLINE_90_DAYS,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.NOT_YET_RENEWED_90_DAYS'),
    },
    {
      value: RenewalStatus.RENEWAL_IN_PROGRESS,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_IN_PROGRESS'),
    },
    {
      value: RenewalStatus.RENEWAL_WITH_VERTICE_COMPLETED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_WITH_VERTICE_COMPLETED'),
    },
    {
      value: RenewalStatus.RENEWAL_OUTSIDE_VERTICE_COMPLETED,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.RENEWAL_OUTSIDE_VERTICE_COMPLETED'),
    },
    {
      value: RenewalStatus.PLANNED_FOR_EXPIRATION,
      label: t('ENTITIES.FILTERS.RENEWAL_STATUS_FILTER.PLANNED_FOR_EXPIRATION'),
    },
  ];

  return { options };
};
