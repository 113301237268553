import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Loader } from '@vertice/components';
import styles from './SwitchComponent.module.scss';

interface SwitchComponentProps {
  checked: boolean;
  onChange: () => void;
  loading?: boolean;
}

const SwitchComponent = ({ checked, onChange, loading }: SwitchComponentProps) => {
  const { t } = useTranslation();

  if (loading) {
    return <Loader size={20} />;
  }

  return (
    <FormControlLabel
      disableTypography
      labelPlacement="start"
      control={<Switch size="small" classes={{ root: styles.root }} checked={checked} onChange={onChange} />}
      label={
        <Text variant="label" size="S" color={checked ? 'primary2' : 'text3'}>
          {checked ? t('INTEGRATIONS.ON') : t('INTEGRATIONS.OFF')}
        </Text>
      }
    />
  );
};

export default SwitchComponent;
