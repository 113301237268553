import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { VisoTrustIcon } from '@vertice/assets';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { IntegrationCardIcon } from '../../../components/IconWrappers';
import { IntegrationSetupCard } from '../../../components/IntegrationSetupCard';

type VisoTrustSetupCardProps = {
  providerId: string;
  connectionId: string;
};

export const VisoTrustSetupCard: FC<VisoTrustSetupCardProps> = ({ providerId, connectionId }) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  return (
    <IntegrationSetupCard
      providerId={providerId}
      connectionId={connectionId}
      providerName={t('INTEGRATIONS.VISO_TRUST.SETUP_CARD.TITLE')}
      providerIcon={
        <IntegrationCardIcon>
          <VisoTrustIcon />
        </IntegrationCardIcon>
      }
      description={t('INTEGRATIONS.VISO_TRUST.SETUP_CARD.DESCRIPTION')}
      setupRoute={generatePath(routes.INTEGRATIONS.NATIVE.WORKFLOWS.VISO_TRUST.ABSOLUTE_PATH)}
    />
  );
};
