import WorkflowsV2 from '@vertice/core/src/modules/workflows/Workflows';
import { Card } from '@verticeone/design-system';

export const Workflows = () => {
  return (
    <Card>
      <WorkflowsV2 disabled />
    </Card>
  );
};
