import React from 'react';
import { Stack } from '@mui/material';
import { FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Placeholder, Text } from '@verticeone/design-system';
import TextField, { TextFieldProps } from './TextField';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { useTranslation } from 'react-i18next';

type ConnectionUrlFieldProps<T extends FieldValues> = {
  connectionName: FieldPath<T>;
} & TextFieldProps<T>;

const ConnectionUrlField = <T extends FieldValues>({
  name,
  connectionName,
  control,
  register,
  title,
}: ConnectionUrlFieldProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.CUSTOM_INTEGRATION' });
  const integrationId = useWatch({ control, name: connectionName });
  const { accountId } = useAccountContext();
  const { data, isFetching } = useGetAccountIntegrationQuery(
    {
      accountId: accountId,
      integrationId,
    },
    { skip: !integrationId }
  );

  return (
    <TextField
      name={name}
      title={title}
      control={control}
      register={register}
      sx={{
        '.MuiInputBase-adornedStart': {
          overflow: 'hidden',
          paddingLeft: '0 !important',
        },
      }}
      startAdornment={
        <Stack pr={2} width="100%" minWidth="50%" maxWidth="75%">
          {isFetching ? (
            <Placeholder width="100%" height="28px" variant="rectangular" />
          ) : (
            <Stack
              py={1.5}
              px={3.5}
              borderRight="1px solid"
              borderColor="core.color3"
              bgcolor="inactive.color4"
              sx={{
                height: '28px',
                overflow: 'hidden',
              }}
            >
              <Text
                variant="body-regular"
                size="S"
                color="inactive1"
                height="100%"
                sx={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  paddingBottom: '16px',
                  marginBottom: '-32px',
                  height: '48px',
                  whiteSpace: 'nowrap',
                }}
              >
                {integrationId ? data?.parameters?.baseUrl : t('FORM.CONNECTION_URL.PLACEHOLDER')}
              </Text>
            </Stack>
          )}
        </Stack>
      }
    />
  );
};

export default ConnectionUrlField;
