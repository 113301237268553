import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountSettings } from '@vertice/core/src/hooks/useAccountSettings';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { ContractValue } from '../components/ContractList/Filters/types';
import { THRESHOLD_CURRENCY } from '../components/ContractList/Filters/hooks/useContractValueFilter';

export type ThresholdFilterOption = { value: ContractValue; label: string };

export const useContractThresholdOptions = () => {
  const { t } = useTranslation();
  const { data: accountSettings } = useAccountSettings();
  const formatCurrency = useFormatCurrency();
  const formattedThreshold = useMemo(() => {
    const thresholdValue = accountSettings?.verticeManagedContractThreshold;
    return thresholdValue
      ? formatCurrency(thresholdValue, { currency: THRESHOLD_CURRENCY, maximumFractionDigits: 0 })
      : undefined;
  }, [accountSettings, formatCurrency]);

  const options: ThresholdFilterOption[] = useMemo(
    () => [
      {
        value: ContractValue.ABOVE_THRESHOLD,
        label: formattedThreshold
          ? t('ENTITIES.FILTERS.THRESHOLD_FILTER.ABOVE_THRESHOLD_WITH_VALUE', { value: formattedThreshold })
          : t('ENTITIES.FILTERS.THRESHOLD_FILTER.ABOVE_THRESHOLD'),
      },
      {
        value: ContractValue.BELOW_THRESHOLD,
        label: t('ENTITIES.FILTERS.THRESHOLD_FILTER.BELOW_THRESHOLD'),
      },
    ],
    [t, formattedThreshold]
  );

  return { options };
};
