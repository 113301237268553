import { EmailField } from '@vertice/core/src/components/EmailField';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormEntry from '@vertice/core/src/modules/forms/fields/FormEntry';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { useFormContext } from 'react-hook-form';
import { useInitSlackUserAuthorizationMutation } from '@vertice/slices';
import { useLoggedUser } from '@verticeone/auth/src';
import { SlackIntegrationData } from '../types';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

const useEnterEmailStep = () => {
  const wizard = useWizard();
  return {
    goBack: wizard.goBack,
    goNext: () => wizard.goToStep('enter_code'),
  };
};

const EnterEmailStep: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { goBack, goNext } = useEnterEmailStep();
  const { watch, trigger } = useFormContext<SlackIntegrationData>();
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId: currentUserId } = useLoggedUser();

  const { slackEmail } = watch();

  const [initiateSlackUserAuthorization, { isLoading }] = useInitSlackUserAuthorizationMutation();

  const initiate = useCallback(() => {
    if (slackEmail !== undefined && slackEmail !== '') {
      void initiateSlackUserAuthorization({
        accountId: accountId!,
        userId: currentUserId,
        body: {
          userEmail: slackEmail,
        },
      }).then(() => {
        goNext();
      });
    }
  }, [accountId, currentUserId, goNext, initiateSlackUserAuthorization, slackEmail]);

  useEffect(() => {
    void trigger(['slackEmail']).then(setNextButtonEnabled);
  }, [trigger, slackEmail]);

  return (
    <Content>
      <Header
        title={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_EMAIL_STEP.TITLE')}
        subtitle={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_EMAIL_STEP.SUBTITLE')}
      />
      <InnerScrollable>
        <FormEntry<SlackIntegrationData, typeof EmailField>
          width={12}
          required
          name="slackEmail"
          label={t('PREFERENCES.SLACK_NOTIFICATIONS.WIZARD.ENTER_EMAIL_STEP.EMAIL_LABEL')}
          component={EmailField}
        />
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: initiate,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextButtonEnabled,
          isLoading,
        }}
      />
    </Content>
  );
};

export default EnterEmailStep;
