import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TimeToCompleteTaskGraph from './TimeToCompleteTaskGraph';
import { ChartCard } from '../ChartCard';
import { ifExpression } from '@verticeone/utils/logic';
import { useTheme } from '@mui/material';
import useTimeToCompleteTaskData, { TimeToCompleteTaskData } from './useTimeToCompleteTaskData';
import { Placeholder } from '@verticeone/design-system';
import { getTooltipValue } from '../ChartCard/utils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { TimeToCompleteTaskProvider, useTimeToCompleteTaskContext } from './TimeToCompleteTaskContext';
import { TimeToCompleteTaskStats } from './TimeToCompleteTaskStats';
import EmptyGraphWrapper from './EmptyGraphWrapper';
import { EmptyState } from '../../types';

type TimeToCompleteTaskCardProps = EmptyState & {
  data: TimeToCompleteTaskData['data'];
};

const PERIOD = 30;

const TimeToCompleteTaskCard = ({ data, isEmpty, isFilteredEmpty }: TimeToCompleteTaskCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { tooltipData } = useTimeToCompleteTaskContext();
  const { palette } = useTheme();

  const total = getTooltipValue(tooltipData?.total, data.stats.total);

  const newPurchaseLabel = t('TIME_TO_COMPLETE_TASK.LEGEND.NEW_SPEND');

  const series = useMemo(() => {
    return data.values.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'timeToCompleteTask', {
          name: newPurchaseLabel,
          color: palette.visualization.divergent.primary['-50'],
          type: 'column',
        }),
      };
    }) as SeriesOptionsWithData[];
  }, [data.values, newPurchaseLabel, palette.visualization.divergent.primary]);

  return (
    <ChartCard
      variant="horizontal"
      title={t('TIME_TO_COMPLETE_TASK.TITLE')}
      subtitle={t('LAST_X_DAYS', { count: PERIOD })}
      stats={<TimeToCompleteTaskStats averageDaysSinceClosed={data.stats.averageDaysSinceClosed} total={total} />}
      testId="time-to-complete-task"
    >
      <EmptyGraphWrapper isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty}>
        <TimeToCompleteTaskGraph
          series={series}
          categories={data.categories}
          filterOptions={data.filterOptions}
          isEmpty={isEmpty || isFilteredEmpty}
        />
      </EmptyGraphWrapper>
    </ChartCard>
  );
};

const LoadableTimeToCompleteTaskCard = () => {
  const { data, isFetching, isEmpty, isFilteredEmpty } = useTimeToCompleteTaskData();

  if (isFetching) {
    return (
      <ChartCard.Skeleton
        variant="horizontal"
        stats={<TimeToCompleteTaskStats.Skeleton />}
        testId="time-to-complete-task-skeleton"
      >
        <Placeholder variant="rounded" width="100%" height="436px" />
      </ChartCard.Skeleton>
    );
  }

  return <TimeToCompleteTaskCard data={data} isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty} />;
};

const TimeToCompleteTaskCardWithContext = () => (
  <TimeToCompleteTaskProvider
    defaultFilter={{
      taskType: 'All',
      requestType: 'All',
    }}
  >
    <LoadableTimeToCompleteTaskCard />
  </TimeToCompleteTaskProvider>
);

export default TimeToCompleteTaskCardWithContext;
