import type { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';

import type { FlowEdgeDefinition } from '../../../../definitionsTypes';
import {
  EditorDrawer,
  EditorDrawerHeader,
  EditorDrawerFooter,
  EditorDrawerScrollContainer,
  EditorDrawerForm,
} from '../../EditorDrawer';
import { EditEdgeForm } from '../../forms/EditEdgeForm/EditEdgeForm';
import { type EditEdgeFormData, editEdgeFormSchema } from '../../forms/EditEdgeForm/schema';

type EditEdgeDrawerProps = {
  isOpen: boolean;
  edgeDefinition?: FlowEdgeDefinition;
  onClose: VoidFunction;
  onDelete: (edgeId: string) => void;
  onSave: (newEdgeDefinition: FlowEdgeDefinition) => void;
  onDirty: VoidFunction;
};

const EditEdgeDrawerContent: FC<Omit<Required<EditEdgeDrawerProps>, 'isOpen'>> = ({
  edgeDefinition,
  onClose,
  onSave,
  onDirty,
  onDelete,
}) => {
  const { edge } = edgeDefinition;
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });

  const handlers = useForm<EditEdgeFormData>({
    resolver: zodResolver(editEdgeFormSchema),
    defaultValues: {
      name: edge.name || '',
    },
  });

  const onSubmit = (data: EditEdgeFormData) => {
    const updatedEdgeDefinition: FlowEdgeDefinition = {
      ...edgeDefinition,
      edge: {
        ...edge,
        name: data.name,
      },
    };

    onSave(updatedEdgeDefinition);
    onClose();
  };

  const title = t('EDIT_EDGE.TITLE');

  return (
    <FormProvider {...handlers}>
      <EditorDrawerForm onSubmit={handlers.handleSubmit(onSubmit)}>
        <EditorDrawerHeader
          deleteOptions={{
            nodeName: title,
            onDelete: () => onDelete(edge.id),
            buttonLabel: t('ACTIONS.DELETE_ROUTE'),
            dialogDescription: t('DELETE_NODE_DIALOG.DESCRIPTION_ROUTE'),
          }}
          title={title}
        />
        <Stack direction="column" flexGrow={1} width="100%" minHeight={0}>
          <EditorDrawerScrollContainer>
            <EditEdgeForm onDirty={onDirty} />
          </EditorDrawerScrollContainer>
          <EditorDrawerFooter onDiscard={onClose} />
        </Stack>
      </EditorDrawerForm>
    </FormProvider>
  );
};

export const EditEdgeDrawer: FC<EditEdgeDrawerProps> = ({
  isOpen,
  onClose,
  onSave,
  onDirty,
  edgeDefinition,
  onDelete,
}) => {
  return (
    <EditorDrawer open={isOpen}>
      {edgeDefinition && (
        <EditEdgeDrawerContent
          onDirty={onDirty}
          onSave={onSave}
          onClose={onClose}
          edgeDefinition={edgeDefinition}
          key={edgeDefinition.edge.id}
          onDelete={onDelete}
        />
      )}
    </EditorDrawer>
  );
};
