import { useEffect, useState } from 'react';
import { FieldItem } from './types';
import { mapAPIFieldToFieldItem, mapFieldItemToAPIField } from './utils';
import useFieldTypes from './useFieldTypes';
import { keyBy } from 'lodash';
import { useAccountContext } from '../../../../account/AccountContext';
import {
  useGetCustomContractFieldsQuery,
  useUpdateCustomContractFieldsMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const useCustomFieldsListData = () => {
  const { accountId } = useAccountContext();
  const [changeNumber, setChangeNumber] = useState<number>(0);
  const [customFields, setCustomFields] = useState<FieldItem[]>();
  const [isSaveInProgress, setSaveInProgress] = useState(false);

  const { data, isLoading } = useGetCustomContractFieldsQuery({ accountId });
  const [setData] = useUpdateCustomContractFieldsMutation();

  const { allFieldTypes } = useFieldTypes({});

  useEffect(() => {
    if (!data || !allFieldTypes) {
      return;
    }

    const allFieldTypesMap = keyBy(allFieldTypes, 'id');
    const mappedFields = data.customFields.map((field) => mapAPIFieldToFieldItem(field, allFieldTypesMap));
    setCustomFields(mappedFields);
    setChangeNumber(data.changeNumber);
  }, [data, allFieldTypes]);

  const overrideFieldItem = (fieldToSet: FieldItem, fields?: FieldItem[]) => {
    const effectiveId = fieldToSet.id;
    return fields?.reduce((acc: FieldItem[], item: FieldItem) => {
      if (item.id === effectiveId) {
        acc.push(fieldToSet);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  };

  const updateField = async (updatedField: FieldItem) => {
    if (!customFields) {
      return; // Prevent updating new fields if we don't have fetched data
    }

    setSaveInProgress(true);

    // Let's be optimistic and update the item in the list
    // We set isBeingProcessed flag to indicate that the item is not yet saved
    setCustomFields((oldFields) => overrideFieldItem({ ...updatedField, isBeingProcessed: true }, oldFields));

    // Save to BE
    const newData = overrideFieldItem({ ...updatedField }, customFields);
    if (newData) {
      const mappedNewData = newData.map(mapFieldItemToAPIField);
      const response = await setData({
        accountId,
        updateCustomContractFieldsRequest: {
          changeNumber,
          customFields: mappedNewData,
        },
      }).unwrap();

      // Update change number before clear of in-progress flag
      setChangeNumber(response.changeNumber);
      setSaveInProgress(false);
    }
  };

  const addField = async (newField: FieldItem) => {
    if (!customFields) {
      return; // Prevent adding new fields if we don't have fetched data
    }

    setSaveInProgress(true);

    // Let's be optimistic and add the item into the list
    // We set isBeingProcessed flag to indicate that the item is not yet saved
    setCustomFields((oldFields) => [...(oldFields ?? []), { ...newField, isBeingProcessed: true }]);

    // Save to BE
    const newData = [...(customFields ?? []), newField];
    const mappedNewData = newData.map(mapFieldItemToAPIField);
    const response = await setData({
      accountId,
      updateCustomContractFieldsRequest: {
        changeNumber,
        customFields: mappedNewData,
      },
    }).unwrap();

    // Update change number before clear of in-progress flag
    setChangeNumber(response.changeNumber);
    setSaveInProgress(false);
  };

  return {
    data: customFields,
    isLoading: !customFields || isLoading,
    isSaveInProgress: isSaveInProgress,
    updateField,
    addField,
  };
};
