import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowModel, GridRowParams, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { DeepPartial } from 'react-hook-form';
import { Product, useListVendorProductsQuery } from '@vertice/slices';
import { DesignSystemColor } from '@verticeone/design-system';
import { DataGrid, DataGridProps } from '@verticeone/design-system';
import { EditableDataGridContextProvider } from '../../../../../components/EditableDataGrid/EditableDataGridContext';
import { useEditableDataGrid, ValidateRowResult } from '../../../../../components/EditableDataGrid/useEditableDataGrid';
import { getRowId } from './utils';
import { ProductItem } from './types';
import { NoRows } from './NoRows';
import { VendorProductsContextProvider } from './VendorProductsContextProvider';
import { useProductColumns } from './hooks/useProductColumns';
import { useDetailRow } from './hooks/useDetailRow';
import { useProductListActions } from './hooks/useProductListActions';

export type ProductListProps<T extends GridValidRowModel> = {
  selectedProducts: T[];
  setSelectedProducts: (newProducts: T[]) => void;
  getProductItem?: (r: GridRowModel, p: Product[]) => T;
  validateProductItem?: (row: GridRowModel<T>) => ValidateRowResult<T>;
  vendorId?: string;

  withActions?: boolean;
  extraColumns?: GridColDef<T>[];
  detailRowRenderer?: (params: GridRowParams) => ReactNode;
  productCellDecorator?: (row: ProductItem) => ReactNode;
  color?: DesignSystemColor;
  dataGridExtraProps?: Partial<DataGridProps>;
};

/**
 * Product list for a single vendor.
 * @param vendorId
 * @param selectedProducts Shown products
 * @param setSelectedProducts Product setter
 * @param getProductItem Function to convert row data to Product list item type.
 * @param withActions Makes the list editable with action column at the end and add button in the footer.
 * @param extraColumns Define columns after main product column and their order. Use useProductExtraColumnBuilder.
 * @param detailRowRenderer Function to render content of detail row.
 * @param color Color of Add button and select fields.
 * @param dataGridExtraProps Other properties passed directly to DataGrid component
 * @constructor
 */
export const ProductList = <T extends ProductItem>({
  vendorId,
  selectedProducts,
  setSelectedProducts,
  getProductItem,
  validateProductItem,

  withActions,
  extraColumns,
  detailRowRenderer,
  productCellDecorator,
  color = 'primary',
  dataGridExtraProps = {},
}: ProductListProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.PRODUCT_LIST' });
  const apiRef = useGridApiRef();

  const { data: vendorProductsData } = useListVendorProductsQuery({ vendorId: vendorId! }, { skip: !vendorId });
  const vendorProducts = useMemo(() => vendorProductsData?.products ?? [], [vendorProductsData]);

  const { validateRow, onAddRow, onUpdateRow, onBeforeDeleteRow, onDeleteRow, onErrorsChange } =
    useProductListActions<T>({
      selectedProducts,
      setSelectedProducts,
      getProductItem,
      validateProductItem,
      vendorProducts,
    });

  const editableDataGrid = useEditableDataGrid<T>({
    apiRef,
    editMode: !!withActions,
    defaultMode: 'confirm',
    validateRow: validateRow,
    onAddRow: onAddRow,
    onUpdateRow: onUpdateRow,
    onBeforeDeleteRow: onBeforeDeleteRow,
    onDeleteRow: onDeleteRow,
    onErrorsChange,
    fieldToFocus: 'productId',

    // Add-button-specific
    withAddButton: vendorProductsData && vendorProducts.length > selectedProducts.length,
    createTmpAddRow: (id) => ({ id, productId: '', numberOfLicences: null, licenseType: null } as DeepPartial<T>),
    addItemButtonLabel: t('ADD_NEW'),
    color,
  });

  const detailRowProperties = useDetailRow({ detailRowRenderer });

  const staticColumns = useProductColumns({
    vendorProducts,
    selectedProducts,
    extraColumns,
    showDetailRowSelector: !!detailRowRenderer,
    productCellDecorator,
  });

  const columns = useMemo(
    () => [...staticColumns, ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : [])],
    [staticColumns, editableDataGrid.actionsColumn]
  );

  const rows = useMemo(
    () => [...selectedProducts, ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : [])],
    [selectedProducts, editableDataGrid.tmpAddRow]
  );

  return (
    <VendorProductsContextProvider vendorId={vendorId} color={color} selectedProducts={selectedProducts}>
      <EditableDataGridContextProvider value={editableDataGrid.context}>
        <DataGrid
          {...editableDataGrid.dataGridProps}
          apiRef={apiRef}
          getRowId={getRowId}
          rows={rows}
          columns={columns}
          slots={{
            ...editableDataGrid.dataGridProps.slots,
            noRowsOverlay: () => <NoRows readOnlyMode={!withActions} />,
          }}
          {...detailRowProperties} // benchmarking detail row on contract detail in view mode only
          sortingMode="client"
          disableRowSelectionOnClick
          columnHeaderHeight={48}
          autoHeight={true}
          color={color}
          {...dataGridExtraProps}
        />
      </EditableDataGridContextProvider>
    </VendorProductsContextProvider>
  );
};
