import { Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssessmentQuestionsQuery, useGetAccountUserQuery, useGetVendorByIdQuery } from '@vertice/slices';
import { Loader, Text } from '@verticeone/design-system';
import { parseRequestRefSafe, parseUserRef } from '../../../../../../../hooks/workflows/refUtils';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { VISOTRUST_CONNECTION_ID, VISOTRUST_PROVIDER_ID } from '../../../../../../integrations/VisoTrust/constants';
import { useTaskContext } from '../../../TaskContext';
import { AssessmentContextForm } from './AssessmentContextForm';
import { AssessmentContextFormData } from './schema';
import { AssessmentContextOutput } from './types';
import { transformToOutput } from './utils';
import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';

export type AssessmentContextFormSectionProps = {
  onSubmit: (values: AssessmentContextOutput) => Promise<void>;
};

export const AssessmentContextFormSection: FC<AssessmentContextFormSectionProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { taskOverview } = useTaskContext();
  const vendorId: string | undefined = useMemo(() => taskOverview?.task.input.vendor.id, [taskOverview]);
  const requestId = useMemo(
    () => taskOverview?.request && parseRequestRefSafe(taskOverview?.request.ref).requestId,
    [taskOverview]
  );

  // Request context not known in drawer from tasks list => fetch it
  const { data: requestData, isLoading: isLoadingRequest } = useGetRequestQuery(
    {
      accountId,
      requestId: requestId!,
    },
    { skip: !requestId }
  );
  const ownerUserId = useMemo(
    () => requestData?.request.owner && parseUserRef(requestData?.request.owner).userId,
    [requestData]
  );

  const { data: vendor, isLoading: isLoadingVendor } = useGetVendorByIdQuery(
    { vendorId: vendorId! },
    { skip: !vendorId }
  );
  const { data: businessCaseQuestions, isLoading: isLoadingBusinessQuestions } = useAssessmentQuestionsQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
    connectionId: VISOTRUST_CONNECTION_ID,
    pathPlus: 'api/v1/business-cases',
  });
  const { data: dataTypesQuestions, isLoading: isLoadingDataQuestions } = useAssessmentQuestionsQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
    connectionId: VISOTRUST_CONNECTION_ID,
    pathPlus: 'api/v1/data-types',
  });
  const { data: requestOwner, isLoading: isLoadingRequestOwner } = useGetAccountUserQuery(
    { userId: ownerUserId!, accountId: accountId },
    { skip: !ownerUserId }
  );

  const handleSubmit = useCallback(
    async (values: AssessmentContextFormData) => {
      await onSubmit(transformToOutput(values));
    },
    [onSubmit]
  );

  if (
    isLoadingRequestOwner ||
    isLoadingBusinessQuestions ||
    isLoadingDataQuestions ||
    isLoadingVendor ||
    isLoadingRequest
  ) {
    return (
      <Stack alignItems="center" flexGrow={1}>
        <Loader />
      </Stack>
    );
  }

  if (vendor && businessCaseQuestions && dataTypesQuestions) {
    return (
      <AssessmentContextForm
        onSubmit={handleSubmit}
        taskDraft={taskOverview?.task.draft}
        vendor={vendor}
        requestOwner={requestOwner}
        businessCaseQuestions={businessCaseQuestions}
        dataTypesQuestions={dataTypesQuestions}
      />
    );
  }

  return (
    <Text variant="caption">
      {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.ERROR_GETTING_DATA')}
    </Text>
  );
};
