import { useTranslation } from 'react-i18next';

import { JiraIcon } from '@vertice/assets';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { IntegrationSetupCard } from '../../../components/IntegrationSetupCard';

type JiraSetupCardProps = {
  providerId: string;
};

export const JiraSetupCard = ({ providerId }: JiraSetupCardProps) => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  return (
    <IntegrationSetupCard
      providerId={providerId}
      providerName={t('INTEGRATIONS.JIRA.SETUP_CARD.TITLE')}
      providerIcon={<JiraIcon />}
      description={t('INTEGRATIONS.JIRA.SETUP_CARD.DESCRIPTION')}
      setupRoute={generatePath(routes.INTEGRATIONS.NATIVE.WORKFLOWS.JIRA.ABSOLUTE_PATH)}
    />
  );
};
