import { Link, LinkProps, styled } from '@mui/material';
import { forwardRef, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink, To } from 'react-router-dom';

import { Text } from '@verticeone/design-system';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { AppTypeContext } from '../../../../../../../contexts/AppTypeContext';
import { useCoreRoutes } from '../../../../../../../router/routes';
import { JIRA_INTEGRATION_GUIDE_URL } from './constants';

type StyledLinkProps = LinkProps & { to?: To };

export const StyledLink = styled(
  forwardRef<HTMLAnchorElement, StyledLinkProps>((props: StyledLinkProps, ref) => (
    <Link {...props} ref={props.ref ?? ref} />
  ))
)(({ theme }) => ({
  color: theme.palette.text.color3,
  textDecorationColor: theme.palette.text.color3,
  '&:hover': {
    textDecoration: 'none',
  },
}));

export const JiraSetupLink = () => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useCoreRoutes();
  const { isIAT } = useContext(AppTypeContext);

  return (
    <Text variant="body-regular" size="XS" color="inactive" marginTop="auto">
      {isIAT ? (
        <Trans
          i18nKey="INTELLIGENT_WORKFLOWS.JIRA.FOOTER_IAT"
          components={{ a: <StyledLink href={JIRA_INTEGRATION_GUIDE_URL} /> }}
        />
      ) : (
        <Trans
          i18nKey="INTELLIGENT_WORKFLOWS.JIRA.FOOTER"
          components={{
            a1: (
              <StyledLink component={RouterLink} to={generatePathForRoute(routes.INTEGRATIONS.NATIVE.WORKFLOWS.JIRA)} />
            ),
            a2: <StyledLink href={JIRA_INTEGRATION_GUIDE_URL} />,
          }}
        />
      )}
    </Text>
  );
};
