import { EllipsisTextCell } from './EllipsisTextCell';
import { BillingFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import useBillingFrequencyOptions from '../../../hooks/useBillingFrequencyOptions';

export type BillingFrequencyCellProps = { value: BillingFrequency };

export const BillingFrequencyCell = ({ value }: BillingFrequencyCellProps) => {
  const { getLabel } = useBillingFrequencyOptions();

  return <EllipsisTextCell value={getLabel(value)} />;
};
