import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { useParams } from 'react-router-dom';
import { useSetFormVendor } from '../../../sharedHooks/useSetFormVendor';
import { IntakeCostModelTable } from '../../components/IntakeCostModelTable';
import { CostOverridePanel } from '../../components/CostOverridePanel/CostOverridePanel';
import { PaymentTermsEntryForm } from '../../components/PaymentTermsEntryForm';
import { CurrencyEntryForm } from '../../components/CurrencyEntryForm';

const useSpendDetailsWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('additional_information'),
  };
};

export const SpendDetailsStep: React.FC<WizardStepProps> = () => {
  const { id: vendorId } = useParams();
  const { t } = useTranslation();

  const { goBack, goToNext } = useSpendDetailsWizard();

  const { formState } = useFormContext<AddExistingContractFormData>();

  useSetFormVendor(vendorId);

  const nextEnabled = useMemo(() => formState.isValid, [formState]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_SPEND_DETAILS.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_SPEND_DETAILS.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel showProducts={false} />
      <InnerScrollable>
        <FormSection>
          <IntakeCostModelTable />
          <PaymentTermsEntryForm width={'auto'} />
          <CurrencyEntryForm width={'auto'} />
          <CostOverridePanel width={'auto'} />
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextEnabled,
        }}
      />
    </Content>
  );
};
