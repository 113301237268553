import { useQuery } from '@tanstack/react-query';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import type { TableType } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';

import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

type CommitmentTableData = Record<
  'aws_infra_spend' | 'eligible_marketplace_spend' | 'commitment' | 'total_edp_eligible_spend',
  number
>;

type SpendTableData = Record<'edp_month_start' | 'edp_discount_cumm_spend' | 'is_forecast', number>;

const EDPTotalEligibleQuery = graphql(`
  query EDPTotalEligibleSpend($accountId: String!) {
    commitment: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_commitment_insight_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["aws_infra_spend", "eligible_marketplace_spend", "commitment", "total_edp_eligible_spend"]) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        error
      }
    }
    spend: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_cum_sum_spend_v1", parameters: ["{accountId}"] }
    ) {
      ... on DataTableResult {
        __typename
        table(columns: ["edp_month_start", "edp_discount_cumm_spend", "is_forecast"]) {
          columns
          data
          dataTypes
        }
      }
      ... on DeferredQueryResult {
        __typename
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useEDPTotalEligibleSpend = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: ['EDPTotalEligibleSpend'],
    queryFn: () =>
      fetchCloudOptimization(EDPTotalEligibleQuery, {
        accountId,
      }),
    enabled: !!accountId,
    refetchInterval: (data) => {
      return data.state.data?.commitment?.__typename === 'DeferredQueryResult' ||
        data.state.data?.spend?.__typename === 'DeferredQueryResult'
        ? 2000
        : false;
    },
    select: (data) => {
      if (data.commitment?.__typename !== 'DataTableResult') {
        return null;
      }

      if (data.spend?.__typename !== 'DataTableResult') {
        return null;
      }

      const commitment = getTableData(data.commitment.table as TableType)?.[0] as CommitmentTableData;
      const spend = getTableData(data.spend.table as TableType) as SpendTableData[];

      const lastSpend = spend
        ?.filter((item) => !item.is_forecast)
        .sort((a, b) => new Date(a.edp_month_start).getTime() - new Date(b.edp_month_start).getTime())
        .findLast((item) => item);

      return {
        spendInTerm: commitment.aws_infra_spend + commitment.eligible_marketplace_spend,
        totalSpend: commitment.commitment,
        termSavingsToDate: lastSpend?.edp_discount_cumm_spend,
      };
    },
  });
};
