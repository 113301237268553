import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { useFormatNumber } from '@verticeone/utils/formatting';

export const useFormatDuration = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.AVERAGE_REQUEST_TIMES' });
  const formatNumber = useFormatNumber();

  return (hours: number) => {
    if (isNaN(hours) || isNil(hours)) return t('NA');

    const days = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    const daysText = days >= 1 ? t('DAY', { count: days, days: formatNumber(days, { maximumFractionDigits: 0 }) }) : '';
    const hoursText =
      remainingHours >= 1 || (hours > 0 && hours < 1)
        ? t('HOUR', {
            count: remainingHours,
            hours: formatNumber(remainingHours, {
              maximumFractionDigits: hours < 1 ? 2 : 0,
            }),
          })
        : '';

    return [daysText, hoursText].filter(Boolean).join(' ') || t('NA');
  };
};
