import { z } from 'zod';

export const dataSourcedFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
  source: z.string(),
  presenterType: z.enum(['DROPDOWN']),
});

export const dataSourcedFieldSchema = z.object({
  name: z.string(),
  type: z.literal('DATASOURCE'),
  metadata: dataSourcedFieldMetadataSchema,
});

export type DataSourcedFieldMetadata = z.infer<typeof dataSourcedFieldMetadataSchema>;

export type DataSourcedFieldType = z.infer<typeof dataSourcedFieldSchema>;
