import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopyOutlined } from '@mui/icons-material';

import { DesignSystemColor, DesignSystemSize, IconButton } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { WorkflowVersionRow } from '../types';
import { useDuplicateWorkflowVersion } from './hooks/useDuplicateWorkflowVersion';
import { FEATURES } from '../../../../features/constants';
import { useFeatures } from '../../../../features/useFeatures';

type DuplicateButtonProps = {
  row: WorkflowVersionRow;
  color?: DesignSystemColor;
  size?: DesignSystemSize;
  tooltipTitle?: string;
  onStart?: () => void;
  onClose?: () => void;
};

export const DuplicateButton: FC<DuplicateButtonProps> = ({
  row,
  color = INTELLIGENT_WORKFLOWS_BRAND_COLOR,
  size,
  tooltipTitle,
  onClose,
  onStart,
}) => {
  const { t } = useTranslation();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(row, {
    disableSnackbarConfirmation: isWorkflowsPageV2Enabled,
    onClose,
    onStart,
  });

  const isDisabled = isDuplicatingWorkflow;

  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await duplicateWorkflow();
  };

  const Button = (
    <IconButton
      icon={ContentCopyOutlined}
      variant="outline"
      color={color}
      onClick={handleButtonClick}
      isLoading={isDuplicatingWorkflow}
      disabled={isDisabled}
      size={size}
    />
  );

  // disabled button cannot have a tooltip
  return isDisabled ? (
    Button
  ) : (
    <Tooltip title={tooltipTitle || t('ENTITIES.WORKFLOW_VERSION.ACTIONS.DUPLICATE')} minified noArrow size="S">
      {Button}
    </Tooltip>
  );
};
