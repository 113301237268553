import { ValidationItem } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { createRequiredContext } from '@verticeone/utils/contexts';
import { WorkflowDefinitions } from '../../types';

type WorkflowValidationContextType = {
  errors: ValidationItem[] | null;
  warnings: ValidationItem[] | null;
  infos: ValidationItem[] | null;
  isValidatingWorkflow: boolean;
  isWorkflowValid: boolean;
  validate: (workflowDefinitions: WorkflowDefinitions) => Promise<void>;
  setIsValidationDrawerOpen: (visibility: boolean) => void;
  isValidationDrawerOpen: boolean;
};

const { WorkflowValidationContextProvider, useWorkflowValidationContext } = createRequiredContext<
  WorkflowValidationContextType,
  'WorkflowValidation'
>('WorkflowValidation');

export { WorkflowValidationContextProvider, useWorkflowValidationContext };
