import React from 'react';
import { useTranslation } from 'react-i18next';
import StatCard from './StatCard/StatCard';
import { ApprovalOutlined } from '@mui/icons-material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import useTasksListStatsData from '../dataSource/useTasksListStatsData';

const TasksStatCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { data, isFetching } = useTasksListStatsData();

  return (
    <StatCard
      isLoading={isFetching}
      onClick={() => {
        navigate(routes.INTELLIGENT_WORKFLOWS.TASKS);
      }}
      testId="in-flight-spend-card"
      title={t('OVERVIEW.STATS.TASKS.TITLE')}
      subtitle={t('OVERVIEW.STATS.TASKS.SUBTITLE', { count: data.totalNumberOfTasks })}
      value={data.totalNumberOfOverdueTasks}
      name={t('TASK_STATUS.OVERDUE')}
      variant="info"
      icon={ApprovalOutlined}
    />
  );
};

export default TasksStatCard;
