import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { keyBy } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { z } from 'zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption, Text } from '@verticeone/design-system';
import { useJiraTriggerForm } from '../../hooks/useJiraTriggerForm';
import { TriggerFilter } from './types';
import { updateFilterWithForm } from './utils';

const issueCreatedFormDataSchema = z.object({
  jiraProject: z.string(),
  issueType: z.string(),
});

type IssueCreatedFormProps = {
  triggerFilter: TriggerFilter;
  onChange: (filter: TriggerFilter) => void;
  onIssueTypeChange: (issueTypeId: string, projectId: string) => void;
  disabled?: boolean;
};

export type IssueCreatedFormData = z.infer<typeof issueCreatedFormDataSchema>;
const IssueCreatedFormEntry = createTypedFormEntry<IssueCreatedFormData>();

export const IssueCreatedForm: FC<IssueCreatedFormProps> = ({
  triggerFilter,
  onChange,
  onIssueTypeChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { projectId, issueTypeId } = triggerFilter;

  const formMethods = useForm<IssueCreatedFormData>({
    defaultValues: {
      jiraProject: projectId ?? '',
      issueType: issueTypeId ?? '',
    },
    mode: 'all',
    resolver: zodResolver(issueCreatedFormDataSchema),
  });

  const selectedProjectId = formMethods.watch('jiraProject');
  const selectedIssueId = formMethods.watch('issueType');

  const prevProjectId = useRef(projectId);
  const prevIssueId = useRef(issueTypeId);

  useEffect(() => {
    if (selectedProjectId !== prevProjectId.current) {
      formMethods.setValue('issueType', '');
      prevProjectId.current = selectedProjectId;
    }

    if (selectedIssueId !== prevIssueId.current) {
      if (selectedIssueId !== '') {
        onChange(updateFilterWithForm(triggerFilter, formMethods.getValues()));
      }
      prevIssueId.current = selectedIssueId;
      onIssueTypeChange(selectedIssueId, selectedProjectId);
    }
  }, [formMethods, onChange, onIssueTypeChange, selectedIssueId, selectedProjectId, triggerFilter]);

  const { projects, issueTypes, isLoadingJiraIntegration, isFetchingJiraIssueTypes } = useJiraTriggerForm(
    triggerFilter.type,
    selectedProjectId,
    selectedProjectId !== prevProjectId.current ? '' : selectedIssueId
  );

  const projectsOptions = projects.map<SimpleOption>((project) => ({
    value: project.id,
    label: project.name,
  }));
  const projectsMap = keyBy(projectsOptions, 'value');
  const issueTypesOptions = issueTypes.map<SimpleOption>((issueType) => ({
    value: issueType.id,
    label: issueType.name,
  }));
  const issuesTypesMap = keyBy(issueTypesOptions, 'value');

  return (
    <FormProvider {...formMethods}>
      <form>
        <Stack direction="row" gap={1} alignItems="center">
          <Text variant="caption" size="XS" color="text2">
            {t('INTEGRATIONS.JIRA.ISSUE_CREATED_FORM.CONDITION_PART_1')}
          </Text>
          <IssueCreatedFormEntry
            component={FormSelectField2}
            componentProps={{
              options: projectsOptions,
              boxValue: (value: string) => projectsMap[value],
              unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
              isClearable: false,
              isLoading: isLoadingJiraIntegration,
              size: 'XS',
              disabled: disabled,
            }}
            name="jiraProject"
          />
          <Text variant="caption" size="XS" color="text2">
            {t('INTEGRATIONS.JIRA.ISSUE_CREATED_FORM.CONDITION_PART_2')}
          </Text>
          <IssueCreatedFormEntry
            component={FormSelectField2}
            componentProps={{
              options: issueTypesOptions,
              boxValue: (value: string) => issuesTypesMap[value] ?? null,
              unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
              isClearable: false,
              isLoading: isFetchingJiraIssueTypes && selectedIssueId === '',
              size: 'XS',
              disabled: disabled || selectedProjectId === '',
            }}
            name="issueType"
          />
        </Stack>
      </form>
    </FormProvider>
  );
};
