import useDashboardWidgetData from '../../useDashboardWidgetData';
import { getTableData } from '../../utils';
import { keyBy, range } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useChartContext } from '../ChartCard';
import { useMemo } from 'react';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { EmptyState, LoadableData } from '../../types';

type TableData = {
  columns: string[];
  types: string[];
  data: string[][];
};

type Item = {
  late: number | null;
  onTime: number | null;
  month: string;
};

export type CompletedRequestsData = EmptyState &
  LoadableData<{
    values: {
      id: string;
      data: (number | null)[];
    }[];
    categories: string[];
    stats: {
      totalNumberOfOntimeRequests: number;
      totalNumberOfOverdueRequests: number;
      total: number;
    };
  }>;

const fillMissingMonthsData = <DataType extends { month: string }>(
  data: Array<DataType>,
  startDate: Dayjs,
  numberOfMonths = 6,
  fallbackData: Partial<DataType>
): Array<DataType> => {
  const dataByDate = keyBy(data, (p) => dayjs(p.month).format('YYYY-MM-DD'));

  return range(numberOfMonths)
    .map((i) => {
      const monthStartStr = startDate.subtract(i, 'month').format('YYYY-MM-DD');

      return (
        dataByDate[monthStartStr] ?? {
          month: monthStartStr,
          ...fallbackData,
        }
      );
    })
    .sort((a, b) => dayjs(a.month).diff(dayjs(b.month)));
};

const calculateCompletedRequestsData = ({ widgetData, months }: { widgetData: Widget | undefined; months: number }) => {
  const dashboardData = widgetData?.data as TableData;

  const tableData = dashboardData
    ? (
        getTableData(dashboardData, {
          onTimeCount: 'onTime',
          notOnTimeCount: 'late',
          month: 'month',
        }) as Item[]
      ).map((item) => ({
        ...item,
        month: dayjs(item.month).startOf('month').format('YYYY-MM-DD'),
      }))
    : [];

  const startDate = dayjs().startOf('month').subtract(1, 'month');

  const rangeData = fillMissingMonthsData<Item>(tableData, startDate, months, {
    onTime: null,
    late: null,
  });

  const values = [
    {
      id: 'onTime',
      data: rangeData.map((data) => data.onTime),
    },
    {
      id: 'late',
      data: rangeData.map((data) => data.late),
    },
  ];

  const isEmpty = !tableData || !tableData.length || tableData.every((item) => !item.onTime && !item.late);
  const isFilteredEmpty = !isEmpty && (!rangeData || rangeData.every((item) => !item.onTime && !item.late));

  const totalNumberOfOntimeRequests = rangeData.reduce((acc, item) => acc + (item.onTime || 0), 0);
  const totalNumberOfOverdueRequests = rangeData.reduce((acc, item) => acc + (item.late || 0), 0);
  const total = totalNumberOfOntimeRequests + totalNumberOfOverdueRequests;

  return {
    data: {
      values,
      categories: rangeData.map((data) => data.month),
      stats: {
        totalNumberOfOntimeRequests,
        totalNumberOfOverdueRequests,
        total,
      },
    },
    isEmpty,
    isFilteredEmpty,
  };
};

const useCompletedRequestsData = (): CompletedRequestsData => {
  const { data: widgetData, error, isFetching } = useDashboardWidgetData('CompletedRequests');
  const { months } = useChartContext();

  const memoizedData = useMemo(() => calculateCompletedRequestsData({ widgetData, months }), [widgetData, months]);

  return {
    ...memoizedData,
    error,
    isFetching,
  };
};

export default useCompletedRequestsData;
