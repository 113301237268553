import { integrationsApi as api } from '../../api/integrationsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSupportedIntegrations: build.query<GetSupportedIntegrationsApiResponse, GetSupportedIntegrationsApiArg>({
      query: () => ({ url: `/integrations/supported` }),
    }),
    getIntegrationSchema: build.query<GetIntegrationSchemaApiResponse, GetIntegrationSchemaApiArg>({
      query: (queryArg) => ({ url: `/integrations/schemas/${queryArg.integrationId}` }),
    }),
    getAccountIntegrations: build.query<GetAccountIntegrationsApiResponse, GetAccountIntegrationsApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations`,
        params: {
          category: queryArg.category,
        },
      }),
    }),
    getAccountIntegration: build.query<GetAccountIntegrationApiResponse, GetAccountIntegrationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}`,
      }),
    }),
    configureAccountIntegration: build.mutation<
      ConfigureAccountIntegrationApiResponse,
      ConfigureAccountIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/config`,
        method: 'POST',
        body: queryArg.integrationConfigInput,
        params: {
          override: queryArg['override'],
        },
      }),
    }),
    connectAccountIntegration: build.mutation<ConnectAccountIntegrationApiResponse, ConnectAccountIntegrationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connect`,
        method: 'POST',
        body: queryArg.integrationConnectionInput,
        params: {
          override: queryArg['override'],
        },
      }),
    }),
    deactivateAccountIntegration: build.mutation<
      DeactivateAccountIntegrationApiResponse,
      DeactivateAccountIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/deactivate`,
        method: 'POST',
      }),
    }),
    revokeAccountIntegration: build.mutation<RevokeAccountIntegrationApiResponse, RevokeAccountIntegrationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/revoke`,
        method: 'POST',
      }),
    }),
    testIntegrationConnection: build.mutation<TestIntegrationConnectionApiResponse, TestIntegrationConnectionApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/connection/test`,
        method: 'POST',
        body: queryArg.connectionTestInput,
        params: {
          existingId: queryArg.existingId,
        },
      }),
    }),
    testAccountIntegration: build.mutation<TestAccountIntegrationApiResponse, TestAccountIntegrationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/tests/${queryArg.testName}/run`,
        method: 'POST',
      }),
    }),
    activateAccountIntegration: build.mutation<ActivateAccountIntegrationApiResponse, ActivateAccountIntegrationApiArg>(
      {
        query: (queryArg) => ({
          url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/activate`,
          method: 'POST',
        }),
      }
    ),
    initiateOauth2AccountIntegration: build.mutation<
      InitiateOauth2AccountIntegrationApiResponse,
      InitiateOauth2AccountIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/oauth2/initiate`,
        method: 'POST',
      }),
    }),
    listSlackUserIdentities: build.query<ListSlackUserIdentitiesApiResponse, ListSlackUserIdentitiesApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/users`,
        params: {
          pageToken: queryArg.pageToken,
          pageSize: queryArg.pageSize,
          status: queryArg.status,
        },
      }),
    }),
    getSlackUserIdentity: build.query<GetSlackUserIdentityApiResponse, GetSlackUserIdentityApiArg>({
      query: (queryArg) => ({ url: `/integrations/accounts/${queryArg.accountId}/slack/users/${queryArg.userId}` }),
    }),
    adminConnectUserIdentity: build.mutation<AdminConnectUserIdentityApiResponse, AdminConnectUserIdentityApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/users/${queryArg.userId}/authorization/connect`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listSlackChannels: build.query<ListSlackChannelsApiResponse, ListSlackChannelsApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/channels`,
        params: {
          pageToken: queryArg.pageToken,
          pageSize: queryArg.pageSize,
          types: queryArg.types,
        },
      }),
    }),
    listSlackUsers: build.query<ListSlackUsersApiResponse, ListSlackUsersApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/discovery/slackUsers`,
        params: {
          pageToken: queryArg.pageToken,
          pageSize: queryArg.pageSize,
          status: queryArg.status,
        },
      }),
    }),
    listUserSuggestions: build.query<ListUserSuggestionsApiResponse, ListUserSuggestionsApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/vertice/discovery/users`,
        params: {
          pageToken: queryArg.pageToken,
          pageSize: queryArg.pageSize,
          status: queryArg.status,
        },
      }),
    }),
    getSlackIntegrationConfig: build.query<GetSlackIntegrationConfigApiResponse, GetSlackIntegrationConfigApiArg>({
      query: (queryArg) => ({ url: `/integrations/accounts/${queryArg.accountId}/slack/integration/config` }),
    }),
    updateSlackIntegrationConfig: build.mutation<
      UpdateSlackIntegrationConfigApiResponse,
      UpdateSlackIntegrationConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/integration/config`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    initSlackUserAuthorization: build.mutation<InitSlackUserAuthorizationApiResponse, InitSlackUserAuthorizationApiArg>(
      {
        query: (queryArg) => ({
          url: `/integrations/accounts/${queryArg.accountId}/slack/users/${queryArg.userId}/authorization/init`,
          method: 'POST',
          body: queryArg.body,
        }),
      }
    ),
    fulfillUserAuthorization: build.mutation<FulfillUserAuthorizationApiResponse, FulfillUserAuthorizationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/users/${queryArg.userId}/authorization/fulfill`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    revokeUserAuthorization: build.mutation<RevokeUserAuthorizationApiResponse, RevokeUserAuthorizationApiArg>({
      query: (queryArg) => ({
        url: `/integrations/accounts/${queryArg.accountId}/slack/users/${queryArg.userId}/authorization/revoke`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as integrationsAPICodegen };
export type GetSupportedIntegrationsApiResponse = /** status 200 OK */ IntegrationDef[];
export type GetSupportedIntegrationsApiArg = void;
export type GetIntegrationSchemaApiResponse = /** status 200 OK */ {
  id?: string;
  name?: string;
  parameters?: {
    [key: string]: any;
  };
};
export type GetIntegrationSchemaApiArg = {
  integrationId: string;
};
export type GetAccountIntegrationsApiResponse = /** status 200 OK */ Integration[];
export type GetAccountIntegrationsApiArg = {
  accountId: UuidIdentifier;
  category?: 'ALL' | 'NATIVE' | 'CUSTOM_INBOUND' | 'CUSTOM_OUTBOUND';
};
export type GetAccountIntegrationApiResponse = /** status 200 OK */ Integration;
export type GetAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
};
export type ConfigureAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type ConfigureAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
  override?: boolean;
  integrationConfigInput: IntegrationConfigInput;
};
export type ConnectAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type ConnectAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
  override?: boolean;
  integrationConnectionInput: IntegrationConnectionInput;
};
export type DeactivateAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type DeactivateAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
};
export type RevokeAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type RevokeAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
};
export type TestIntegrationConnectionApiResponse = /** status 200 Successful connection test */ ConnectionTestOutput;
export type TestIntegrationConnectionApiArg = {
  accountId: UuidIdentifier;
  existingId?: string;
  connectionTestInput: ConnectionTestInput;
};
export type TestAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type TestAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
  testName: string;
};
export type ActivateAccountIntegrationApiResponse = /** status 200 OK */ IntegrationConnectionStatus;
export type ActivateAccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
};
export type InitiateOauth2AccountIntegrationApiResponse = /** status 200 OK */ Oauth2ConnectionStatus;
export type InitiateOauth2AccountIntegrationApiArg = {
  accountId: UuidIdentifier;
  integrationId: string;
};
export type ListSlackUserIdentitiesApiResponse = /** status 200 OK */ {
  items?: UserIdentity[];
  nextToken?: PageToken;
};
export type ListSlackUserIdentitiesApiArg = {
  accountId: UuidIdentifier;
  pageToken?: PageToken;
  pageSize?: number;
  status?: string;
};
export type GetSlackUserIdentityApiResponse = /** status 200 OK */ UserIdentity;
export type GetSlackUserIdentityApiArg = {
  accountId: UuidIdentifier;
  userId: string;
};
export type AdminConnectUserIdentityApiResponse = /** status 202 Empty Response */ object;
export type AdminConnectUserIdentityApiArg = {
  accountId: UuidIdentifier;
  userId: string;
  body: {
    /** User email */
    userEmail: string;
    /** The Slack username */
    slackId?: string;
  };
};
export type ListSlackChannelsApiResponse = /** status 200 OK */ {
  items?: SlackChannel[];
  nextToken?: PageToken;
};
export type ListSlackChannelsApiArg = {
  accountId: UuidIdentifier;
  pageToken?: PageToken;
  pageSize?: number;
  types?: 'PUBLIC' | 'PRIVATE' | 'ALL';
};
export type ListSlackUsersApiResponse = /** status 200 OK */ {
  items?: SlackUser[];
  nextToken?: PageToken;
};
export type ListSlackUsersApiArg = {
  accountId: UuidIdentifier;
  pageToken?: PageToken;
  pageSize?: number;
  status?: string;
};
export type ListUserSuggestionsApiResponse = /** status 200 OK */ {
  items?: UserWithSuggestion[];
  nextToken?: PageToken;
};
export type ListUserSuggestionsApiArg = {
  accountId: UuidIdentifier;
  pageToken?: PageToken;
  pageSize?: number;
  status?: string;
};
export type GetSlackIntegrationConfigApiResponse = /** status 200 Slack integration configuration response */ {
  automaticallyConnectMatchedUsers?: boolean;
  workspaceInfo?: {
    url: string;
    name: string;
    updatedAt: string;
  };
  notificationSettings?: {
    [key: string]: NotificationSettingsItem[];
  };
  triggerSettings?: {
    [key: string]: TriggerSettingsItem;
  };
};
export type GetSlackIntegrationConfigApiArg = {
  accountId: UuidIdentifier;
};
export type UpdateSlackIntegrationConfigApiResponse = /** status 202 Slack integration configuration response */ {
  automaticallyConnectMatchedUsers?: boolean;
  workspaceInfo?: {
    url: string;
    name: string;
    updatedAt: string;
  };
  notificationSettings?: {
    [key: string]: NotificationSettingsItem[];
  };
  triggerSettings?: {
    [key: string]: TriggerSettingsItem;
  };
};
export type UpdateSlackIntegrationConfigApiArg = {
  accountId: UuidIdentifier;
  body: {
    /** if the automatic connection should be done or not */
    automaticallyConnectMatchedUsers?: boolean;
    /** settings for channel wide notifications */
    notificationSettings?: {
      [key: string]: NotificationSettingsItem[];
    };
    triggerSettings?: {
      [key: string]: TriggerSettingsItem;
    };
  };
};
export type InitSlackUserAuthorizationApiResponse = /** status 202 Empty Response */ object;
export type InitSlackUserAuthorizationApiArg = {
  accountId: UuidIdentifier;
  userId: string;
  body: {
    /** User email */
    userEmail: string;
  };
};
export type FulfillUserAuthorizationApiResponse = /** status 202 202 response */ {
  ok?: boolean;
  reason?: string;
};
export type FulfillUserAuthorizationApiArg = {
  accountId: UuidIdentifier;
  userId: string;
  body: {
    authorizationToken?: string;
  };
};
export type RevokeUserAuthorizationApiResponse = /** status 202 Empty Response */ object;
export type RevokeUserAuthorizationApiArg = {
  accountId: UuidIdentifier;
  userId: string;
  body: object;
};
export type IntegrationDef = {
  id: string;
  name: string;
};
export type IntegrationConnectionStatus = {
  id: string;
  status: string;
  errorMessage?: string;
  errorType?: string;
  error?: {
    [key: string]: any;
  };
};
export type Integration = {
  id: string;
  parameters?: {
    [key: string]: string;
  };
  enabled: boolean;
  status?: string;
  connection?: IntegrationConnectionStatus;
};
export type UuidIdentifier = string;
export type Features = {
  id: 'spendInsights' | 'discovery';
  enabled: boolean;
  allowedRecords?: (
    | 'invoice'
    | 'invoice:read'
    | 'invoice:write'
    | 'contact'
    | 'contact:read'
    | 'contact:write'
    | 'item'
    | 'item:read'
    | 'item:write'
  )[];
}[];
export type IntegrationConfigInput = {
  parameters?: {
    features?: Features;
    [key: string]: any;
  };
};
export type IntegrationConnectionSecret = {
  [key: string]: any;
};
export type IntegrationConnectionInput = {
  parameters?: {
    secret?: IntegrationConnectionSecret;
    [key: string]: any;
  };
};
export type ConnectionTestOutput = {
  /** Error when e.g. the connection to the endpoint failed otherwise "ok" */
  status?: 'ok' | 'error';
  /** The response from the test request to the integration endpoint */
  testResponse?: {
    statusCode?: number;
    headers?: {
      [key: string]: string;
    };
    body?: string;
  };
};
export type ConnectionTestInput = {
  /** The specific url to test the connection. */
  url: string;
  /** The HTTP method to use for the test (GET, POST, PUT, DELETE, etc.). */
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';
  body?: {
    [key: string]: any;
  };
  auth: {
    /** The type of authentication being used (e.g., "apiKey", "oauth"). */
    authType: 'apiKey';
    /** The API key for authentication (if using API key method). */
    apiKey?: string;
  };
};
export type Oauth2ConnectionStatus = {
  id: string;
  /** *INITIATED* - OAuth2 authorization flow has been initiated
   *AUTHORIZED* - OAuth2 authorization flow has been completed
   *ERROR* - OAuth2 authorization flow has failed
   */
  status: 'INITIATED' | 'AUTHORIZED' | 'ERROR';
  /** URL to start OAuth2 authorization flow */
  authorizationUrl?: string;
  errorMessage?: string;
  errorType?: string;
  error?: {
    [key: string]: any;
  };
};
export type UserIdentity = {
  accountId: string;
  userId: string;
  identityId?: string | null;
  identityEmail?: string | null;
  createdAt?: string | null;
  status?: string;
};
export type PageToken = string | null;
export type SlackChannel = {
  channelId: string;
  channelName?: string;
  type?: 'PUBLIC' | 'PRIVATE';
};
export type SlackUser = {
  accountId: string;
  userId: string;
  userName?: string;
  email?: string;
  status?: string;
};
export type DiscoveryMatch = {
  entityId: string;
  entityName: string;
  entityType: string;
};
export type DiscoverySuggestion = {
  entityId: string;
  entityName: string;
  entityType: string;
  score?: number;
};
export type UserWithSuggestion = {
  accountId: string;
  userId: string;
  userName?: string;
  email?: string;
  discoveryMatches?: DiscoveryMatch[];
  discoverySuggestions?: DiscoverySuggestion[][];
  status?: string;
};
export type NotificationSettingsItem = {
  channelId: string;
  channelName?: string;
  type?: 'PUBLIC' | 'PRIVATE';
  timeThreshold?: string;
  saveStatus?: string;
};
export type TriggerSettingsItem = {
  workflow: string;
  enabled: boolean;
};
export const {
  useGetSupportedIntegrationsQuery,
  useLazyGetSupportedIntegrationsQuery,
  useGetIntegrationSchemaQuery,
  useLazyGetIntegrationSchemaQuery,
  useGetAccountIntegrationsQuery,
  useLazyGetAccountIntegrationsQuery,
  useGetAccountIntegrationQuery,
  useLazyGetAccountIntegrationQuery,
  useConfigureAccountIntegrationMutation,
  useConnectAccountIntegrationMutation,
  useDeactivateAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestIntegrationConnectionMutation,
  useTestAccountIntegrationMutation,
  useActivateAccountIntegrationMutation,
  useInitiateOauth2AccountIntegrationMutation,
  useListSlackUserIdentitiesQuery,
  useLazyListSlackUserIdentitiesQuery,
  useGetSlackUserIdentityQuery,
  useLazyGetSlackUserIdentityQuery,
  useAdminConnectUserIdentityMutation,
  useListSlackChannelsQuery,
  useLazyListSlackChannelsQuery,
  useListSlackUsersQuery,
  useLazyListSlackUsersQuery,
  useListUserSuggestionsQuery,
  useLazyListUserSuggestionsQuery,
  useGetSlackIntegrationConfigQuery,
  useLazyGetSlackIntegrationConfigQuery,
  useUpdateSlackIntegrationConfigMutation,
  useInitSlackUserAuthorizationMutation,
  useFulfillUserAuthorizationMutation,
  useRevokeUserAuthorizationMutation,
} = injectedRtkApi;
