import { FC, useMemo } from 'react';

import { ServiceTaskDetailDef } from './types';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getTaskServiceUrn } from './useServiceTaskDetailDef';
import { getServiceResourceInputProperties, useServiceResource } from './useServiceResource';

type ServiceTaskDetailProps = {
  taskOverview: TaskOverview;
  definition: ServiceTaskDetailDef;
};

export const ServiceTaskDetail: FC<ServiceTaskDetailProps> = ({ taskOverview, definition }) => {
  const serviceUrn = useMemo(() => getTaskServiceUrn(taskOverview), [taskOverview]);
  const { resource } = useServiceResource(serviceUrn);

  const { DetailContent, props } = useMemo(() => {
    const inputSchemas = resource ? getServiceResourceInputProperties(resource) : {};

    return {
      DetailContent: definition.component,
      props: {
        taskOverview,
        inputs: definition.getInputValues?.({
          getInputValue: (key: string) => taskOverview?.task.input[key],
          getInputSchema: (key: string) => inputSchemas?.[key],
          taskOverview,
        }),
      },
    };
  }, [taskOverview, definition, resource]);

  return <DetailContent {...props} />;
};
