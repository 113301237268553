import { useAccountContext } from '../../account/AccountContext';
import { useLoggedUser } from '@verticeone/auth/src';
import { useCallback, useState } from 'react';
import { useCreateRequestMutation } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWaitForRequestReady } from './useWaitForRequestReady';
import { useWaitForRequestActiveTask } from './useWaitForRequestActiveTask';
import {
  formatUserRef,
  isUserRef,
  parseRequestRef,
  parseUserRef,
  parseUserTaskRef,
} from '../../../hooks/workflows/refUtils';
import { Vendor } from '../../vendor/types';
import { Tier } from '../../saas/contract/components/ProductList/types';
import { AllocationSpan } from '../../saas/contract/components/CostModelProductList/types';

export type CreateRequestParams = {
  name: string;
  sourceRef: string;
  serviceRef: string;
  input?: {
    [key: string]: any;
  };
};

export type RenewalProduct = {
  id?: string;
  name?: string;
  productId?: string;
  annualCost?: number;
  numberOfLicences?: number;
  licenseType?: Tier;
  totalCost?: number;
  licenseCost?: number;
  allocationSpan?: AllocationSpan;
};

export type CreateRenewalRequestParams = {
  contractId: string;
  contractCurrency?: string;
  products?: RenewalProduct[];
  vendor: Vendor;
  source?: string;
  department?: string;
};

export const useCreateWorkflowRequest = () => {
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [createdRequestId, setRequestId] = useState<string | undefined>(undefined);
  const [createdRequestTaskId, setRequestTaskId] = useState<string | undefined>(undefined);
  const [failedToCreate, setFailedToCreate] = useState<boolean>(false);

  const [createRequestMutation] = useCreateRequestMutation();
  const waitForRequestReady = useWaitForRequestReady();
  const waitForRequestActiveTask = useWaitForRequestActiveTask();

  const createRequest = useCallback(
    async ({ name, sourceRef, input, serviceRef }: CreateRequestParams): Promise<void> => {
      try {
        setIsRequestPending(true);

        const createRequestResponse = await createRequestMutation({
          accountId: accountId!,
          createRequest: {
            sourceRef: sourceRef,
            serviceRef: serviceRef,
            name: name,
            input: input ?? {},
            owner: formatUserRef(userId),
          },
        });

        const requestReference = 'data' in createRequestResponse ? createRequestResponse.data?.ref : undefined;
        if (!requestReference) {
          return Promise.resolve();
        }

        const requestId = parseRequestRef(requestReference).requestId;

        const waitForRequestAndTask = Promise.all([
          waitForRequestReady(accountId, requestId),
          waitForRequestActiveTask(accountId, requestId),
        ]);
        const wait20Seconds = new Promise<undefined>((resolve) => {
          setTimeout(resolve, 20000);
        });

        const result = await Promise.race([waitForRequestAndTask, wait20Seconds]);
        setRequestId(requestId);

        if (result) {
          const [requestReady, task] = result;
          const isTaskAssignedToLoggedUser = task.assignment.some(
            (assignment) => isUserRef(assignment) && parseUserRef(assignment).userId === userId
          );
          if (requestReady && task.status === 'ACTIVE' && task.type === 'USER' && isTaskAssignedToLoggedUser) {
            setRequestTaskId(parseUserTaskRef(task.ref).taskId);
          }
          if (requestReady) {
            setFailedToCreate(false);
          }
        } else {
          setFailedToCreate(true);
        }
      } finally {
        setIsRequestPending(false);
      }
    },
    [createRequestMutation, accountId, userId, waitForRequestReady, waitForRequestActiveTask]
  );

  return {
    createNewRequest: createRequest,
    createdNewRequestId: createdRequestId,
    createdNewRequestTaskId: createdRequestTaskId,
    isCreatingNewRequest: isRequestPending,
    failedToCreate: failedToCreate,
  };
};

export const useDefaultCreateRequests = () => {
  const { accountId } = useAccountContext();

  const { createNewRequest, createdNewRequestId, createdNewRequestTaskId, isCreatingNewRequest, failedToCreate } =
    useCreateWorkflowRequest();

  return {
    createNewPurchaseRequest: (vendor?: Vendor) =>
      createNewRequest({
        name: `${vendor ? `${vendor.name} ` : ''}New Purchase`,
        sourceRef: `urn:verticeone:vertice:${accountId}:cfa:page/vendor/${vendor ? vendor.id : ''}`,
        serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/purchase/v4`,
        input: { vendor },
      }),
    createNewGenericPurchaseRequest: (name: string) =>
      createNewRequest({
        name: `${name} - Purchase`,
        sourceRef: `urn:verticeone:vertice:${accountId}:cfa:page/vendor/to_be_specified`,
        serviceRef: `urn:verticeone:vertice:${accountId}:services:service/generic/purchase/v1`,
        input: {},
      }),
    createGenericRenewalRequest: ({ source, ...renewalInput }: CreateRenewalRequestParams) =>
      createNewRequest({
        name: `${renewalInput.vendor.name} Renewal`,
        sourceRef:
          source ?? `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${renewalInput.contractId}`,
        serviceRef: `urn:verticeone:vertice:${accountId}:services:service/generic/renewal/v1`,
        input: renewalInput,
      }),
    createNewRequestForProposalRequest: (name: string) =>
      createNewRequest({
        name: name,
        sourceRef: `urn:verticeone:vertice:${accountId}:cfa:page/vendor/to_be_specified`,
        serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/request-for-proposal/v1`,
        input: {},
      }),
    createRenewalRequest: ({ source, ...renewalInput }: CreateRenewalRequestParams) =>
      createNewRequest({
        name: `${renewalInput.vendor.name} Renewal`,
        sourceRef:
          source ?? `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${renewalInput.contractId}`,
        serviceRef: `urn:verticeone:vertice:${accountId}:services:service/saas/renewal/generic/v4`,
        input: renewalInput,
      }),
    createdNewRequestId: createdNewRequestId,
    createdNewRequestTaskId: createdNewRequestTaskId,
    isCreatingNewRequest: isCreatingNewRequest,
    failedToCreate: failedToCreate,
  };
};
