import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import AnimatedBar from '../AnimatedBar';
import { StatItemType } from '../../types';
import { Placeholder } from '@verticeone/design-system';

const STAT_BAR_HEIGHT = 12;

export type ChartStatBarProps = {
  isLoading?: boolean;
  total: number;
  items?: StatItemType[];
  height?: number;
};

const ChartStatBarWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Stack direction="row" gap="2px" alignItems="center">
      {children}
    </Stack>
  );
};

const ChartStatBar = ({ total, items, height = STAT_BAR_HEIGHT }: ChartStatBarProps) => {
  const hasItems = items && items.length > 0;
  if (!hasItems) {
    return null;
  }

  return (
    <ChartStatBarWrapper>
      {items.map((item) => (
        <AnimatedBar height={height} key={item.id} width={(item.value / total) * 100} color={item.color} />
      ))}
    </ChartStatBarWrapper>
  );
};

const Skeleton = ({ height = STAT_BAR_HEIGHT }: Pick<ChartStatBarProps, 'height'>) => (
  <ChartStatBarWrapper>
    {Array(3)
      .fill(null)
      .map((_, index) => (
        <Placeholder key={index} variant="text" width="33%" height={height} />
      ))}
  </ChartStatBarWrapper>
);

ChartStatBar.Skeleton = Skeleton;

export default ChartStatBar;
