import { useMemo } from 'react';
import { useIsContractFieldVisible } from '../../../hooks/fieldsRulesHooks';
import { Divider } from '@verticeone/design-system';
import { Grid } from '@verticeone/design-system';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import ChevronRight from '@mui/icons-material/ChevronRightTwoTone';
import { ContractTab, useContractTabNavigationContext } from '../../../ContractTabNavigationContext';
import SavingsVsInitialContractFormEntry from '../../form/SavingsVsInitialContractFormEntry';
import FutureSavingsContractFormEntry from '../../form/FutureSavingsContractFormEntry';
import AdditionalSavingsContractFormEntry from '../../form/AdditionalSavingsContractFormEntry';
import ScopeReductionSavingsContractFormEntry from '../../form/ScopeReductionSavingsContractFormEntry';

/**
 * Detail savings section below the main cost information part. It covers additional detail and insights
 * of saving. It is preview of the Savings Breakdown tab.
 */
export const SavingsDetails = () => {
  const { t } = useTranslation();

  const savingsVsInitial = useIsContractFieldVisible('parts.overview.totalCostValueSavings');
  const additionalSavings = useIsContractFieldVisible('parts.overview.annualRealizedConcessions');
  const futureSavings = useIsContractFieldVisible('parts.overview.annualUnrealizedConcessions');
  const scopeReductionSavings = useIsContractFieldVisible('parts.overview.annualScopeReductionSavings');

  const areSavingsDetailsVisible = useMemo(() => {
    const fieldsInRow = [savingsVsInitial, additionalSavings, futureSavings, scopeReductionSavings];
    return fieldsInRow.some((field) => field);
  }, [savingsVsInitial, additionalSavings, futureSavings, scopeReductionSavings]);

  const tabNavigationContext = useContractTabNavigationContext();

  const handleDetailsClick = () => {
    tabNavigationContext.setTabId(ContractTab.SAVINGS_BREAKDOWN, { scrollIntoView: true });
  };

  if (!areSavingsDetailsVisible) return <></>;

  return (
    <>
      <Stack
        direction="row"
        px={6}
        justifyContent="space-between"
        alignItems="center"
        // Compensate the Button's white padding without making the click-area smaller
        m="-6px -12px -6px 0"
      >
        <Text variant="caption" size="XS" color="text1">
          {t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS_BREAKDOWN')}
        </Text>
        <Button onClick={handleDetailsClick} variant="plain" color="primary" size="XS">
          {t('ENTITIES.CONTRACT.ACTIONS.VIEW_DETAILS')}
          <ChevronRight opacity={0.6} />
        </Button>
      </Stack>

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item hideIfEmpty xs={12} md={6} lg={3}>
          <SavingsVsInitialContractFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={3}>
          <AdditionalSavingsContractFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={3}>
          <FutureSavingsContractFormEntry />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={3}>
          <ScopeReductionSavingsContractFormEntry />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
