import { useFormContext, useWatch } from 'react-hook-form';
import { AddExistingContractFormData } from '../../types';
import { TotalCostOverridePanel } from './TotalCostOverridePanel';
import { AnnualCostOverridePanel } from './AnnualCostOverridePanel';
import { Grid } from '@verticeone/design-system';
import { GridSize } from '@mui/material/Grid';

type CostOverridePanelProps = {
  width?: GridSize;
};

export const CostOverridePanel = ({ width = 12 }: CostOverridePanelProps) => {
  const { control } = useFormContext<AddExistingContractFormData>();
  const contractCategory = useWatch({ control, name: 'contractCategory' });
  const rollingFrequency = useWatch({ control, name: 'rollingFrequency' });
  const isNonSaas = contractCategory === 'OTHER';
  const isOneOff = rollingFrequency === 'ONE_OFF';

  return (
    <Grid item xs={width}>
      {isNonSaas && isOneOff ? <TotalCostOverridePanel /> : <AnnualCostOverridePanel />}
    </Grid>
  );
};
