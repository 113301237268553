import { FC, forwardRef } from 'react';
import { Stack, useTheme } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { DesignSystemColor, IconWrapper, Text, Tooltip } from '@verticeone/design-system';

import type { TaskNodeState } from '../../../../../model/types';
import { MissingDueDateInfoIcon } from '../../../../../../task/components/MissingDueDateInfoIcon';
import { useTaskDueDateLabel } from './hooks';

type TaskDueDateProps = {
  taskId: string;
  textColor: string;
  taskState?: TaskNodeState;
  isInfoTooltipDisplayed: boolean;
};

type ContentProps = {
  label: string;
  colorVariant: DesignSystemColor | 'text';
  note?: string;
  isInfoTooltipDisplayed: boolean;
};

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ label, colorVariant, note, isInfoTooltipDisplayed, ...restProps }, ref) => {
    const { palette } = useTheme();
    const color = palette[colorVariant].color1;

    return (
      <Stack ref={ref} gap={1} direction="row" alignItems="center" {...restProps}>
        <Text variant="button-bold" size="XS" color={color}>
          {label}
        </Text>
        {note ? (
          <Text variant="caption" size="XS" color={color} sx={{ opacity: 0.6 }}>
            {note}
          </Text>
        ) : null}
        {isInfoTooltipDisplayed ? (
          <Stack sx={{ pointerEvents: 'all', cursor: 'pointer' }}>
            <MissingDueDateInfoIcon />
          </Stack>
        ) : null}
      </Stack>
    );
  }
);

export const TaskDueDate: FC<TaskDueDateProps> = ({
  taskId,
  textColor,
  taskState,
  isInfoTooltipDisplayed: isInfoTooltipDisplayedByProp,
}) => {
  const {
    tooltip,
    isInfoTooltipDisplayed: isInfoTooltipDisplayedByHook,
    ...contentProps
  } = useTaskDueDateLabel({ taskId, taskState });

  const isInfoTooltipDisplayed = !!isInfoTooltipDisplayedByHook && isInfoTooltipDisplayedByProp;

  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      sx={tooltip ? { pointerEvents: 'all', cursor: 'pointer' } : undefined}
    >
      <IconWrapper icon={CalendarTodayOutlined} htmlColor={textColor} size="S" />
      {tooltip ? (
        <Tooltip minified size="S" noArrow title={tooltip}>
          <Content isInfoTooltipDisplayed={isInfoTooltipDisplayed} {...contentProps} />
        </Tooltip>
      ) : (
        <Content isInfoTooltipDisplayed={isInfoTooltipDisplayed} {...contentProps} />
      )}
    </Stack>
  );
};
