import { Loadable } from '@verticeone/utils/async';
import { useAuthorizer } from '@verticeone/auth/src';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

export type CloudPermissions = {
  canUserAccessCCO: boolean;
};

const useCloudPermissions = (): Loadable<CloudPermissions> => {
  const { accountId } = useAccountContext();
  const { isLoading, isAllowed } = useAuthorizer({
    id: 'AwsPageWrapper',
    object: `urn:verticeone:vertice:${accountId}:cco-aws:cost/*`,
    action: 'cost:ReadCost',
  });

  return isLoading
    ? { isLoading: true }
    : {
        isLoading: false,
        data: {
          canUserAccessCCO: isAllowed,
        },
      };
};

export default useCloudPermissions;
