import { FC } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogHeader, DialogText } from '@verticeone/design-system';

type IntegrationSetupDialogProps = {
  header: string;
  content: string;
  cancelButtonLabel?: string;
  buttonLabel: string;
  isOpened: boolean;
  isLoading?: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const IntegrationSetupDialog: FC<IntegrationSetupDialogProps> = ({
  header,
  content,
  cancelButtonLabel,
  buttonLabel,
  isOpened,
  isLoading,
  onSubmit,
  onClose,
}) => {
  return (
    <Dialog size="M" open={isOpened} setOpen={onClose}>
      <DialogHeader>{header}</DialogHeader>
      <DialogContent>
        <DialogText variant="body-regular" sx={{ whiteSpace: 'pre-line' }}>
          {content}
        </DialogText>
      </DialogContent>
      <DialogActions>
        {cancelButtonLabel && (
          <Button onClick={onClose} variant="outline">
            {cancelButtonLabel}
          </Button>
        )}
        <Button onClick={onSubmit} isLoading={isLoading} variant="solid" testId="disconnect-integration">
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
