import { AddExistingContractFormData, AddExistingContractFormEntry } from '../types';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption } from '@verticeone/design-system';
import { currencies } from '@verticeone/utils/currency';
import { GridSize } from '@mui/material/Grid';

type CurrencyEntryFormProps = {
  width?: GridSize;
};

export const CurrencyEntryForm = ({ width }: CurrencyEntryFormProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<AddExistingContractFormData>();
  const annualCostOverride = useWatch({ control, name: 'annualCostOverride.enabled' });
  const totalCostOverride = useWatch({ control, name: 'totalCostOverride.enabled' });
  const products = useWatch({ control, name: 'products' });
  const lineItems = useWatch({ control, name: 'lineItems' });

  const isRequired = annualCostOverride || totalCostOverride || !!products.length || !!lineItems.length;

  const currencyOptions = currencies.map((currency: string) => ({ value: currency, label: currency }));

  return (
    <AddExistingContractFormEntry
      name="contractCurrency"
      label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
      component={FormSelectField2}
      componentProps={{
        options: currencyOptions,
        boxValue: (value: string) => (value ? { label: value, value: value } : undefined),
        unboxValue: (selectValue: SimpleOption) => selectValue?.value,
        sx: { minWidth: 256 },
      }}
      required={isRequired}
      width={width}
    />
  );
};
