import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { ChartLayout, ChartDropDownMenu } from '../ChartCard';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useAverageRequestTimesContext } from './AverageRequestTimesContext';
import { useTranslation } from 'react-i18next';
import { EnhancedData, FilterOptions } from './useAverageRequestTimesData';
import {
  cssObjectToString,
  monthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useTheme } from '@mui/material';
import { isNil } from 'lodash';

type AverageRequestTimesGraphProps = {
  series: SeriesOptionsWithData[];
  categories: string[];
  enhancedData: EnhancedData;
  filterOptions: FilterOptions;
  isEmpty?: boolean;
};

const AverageRequestTimesGraph = ({
  categories,
  series,
  enhancedData,
  filterOptions,
  isEmpty,
}: AverageRequestTimesGraphProps) => {
  const [setChartRef] = useChartRef();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { palette } = useTheme();

  const { filter, setTooltipData, updateFilterValue } = useAverageRequestTimesContext();

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        applyStackedColumnHover,
        mergeOptions({
          chart: {
            type: 'column',
            height: 376,
            marginTop: 30,
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    const categoryData = this.series.chart.series.reduce((acc, item) => {
                      acc[`${item.userOptions.id}`] = item.points[point.index].y ?? 0;
                      return acc;
                    }, {} as Record<string, number>);

                    setTooltipData({
                      values: {
                        ...categoryData,
                      },
                      category: point.category,
                      total: Object.values(categoryData).reduce((acc, value) => acc + (value || 0), 0),
                      averageDurationInHours: enhancedData[point.category]?.averageDurationInHours,
                    });
                  },
                  mouseOut() {
                    setTooltipData(null);
                  },
                },
              },
            },
          },
          yAxis: {
            ...(isEmpty
              ? {
                  max: 24 * 5,
                }
              : {}),
            min: 0,
            tickPositioner: function () {
              const hours = 24;
              const min = this.min || 0;
              const maxDays = (this.max || 0) / hours;
              const ticks = Math.min(maxDays, 4); // Max 4 ticks
              const roundBy = maxDays >= 20 ? 20 : ticks;
              const max = Math.ceil(maxDays / roundBy) * roundBy;
              const maxHours = max * hours;

              let tick = Math.floor(min);
              const positions = [],
                increment = Math.ceil(maxHours / ticks);

              for (tick; tick - increment < maxHours; tick += increment) {
                positions.push(tick);
              }
              return positions;
            },
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                `<span style="${cssObjectToString(yLabelStyles(palette))}">${
                  !isNil(value) ? Math.round(Number(value) / 24) : ''
                }</span>`,
            },
          },
          xAxis: {
            tickLength: 0,
            categories,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true, enabled: false },
          series: [...(getSeriesWithBorderRadius(series, 4) as SeriesOptionsType[])],
        }),
      ]),
    [
      applyXAxisOffset,
      applyStyledHighcharts,
      applyStackedColumnHover,
      isEmpty,
      categories,
      series,
      setTooltipData,
      enhancedData,
      palette,
    ]
  );

  return (
    <ChartLayout
      filterItems={[
        <ChartDropDownMenu
          key="service-name"
          label={t('AVERAGE_REQUEST_TIMES.FILTER.SERVICE_TYPE.LABEL')}
          placeholder={filter.serviceName || t('AVERAGE_REQUEST_TIMES.FILTER.SERVICE_TYPE.PLACEHOLDER')}
          items={filterOptions.serviceName.map((serviceName) => ({ id: serviceName, label: serviceName }))}
          onChange={(item) => {
            updateFilterValue('serviceName', item.label);
          }}
        />,
      ]}
    >
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
    </ChartLayout>
  );
};

export default AverageRequestTimesGraph;
