import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { ViewSettingsFormData } from '../ViewSettingsDrawer/schema';
import { apiCustomizationViewToForm, formToApiCustomizationView } from '../ViewSettingsDrawer/transformations';
import { useCreateCustomizationViewMutation } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CustomFilterView } from '../types';

export const useCustomViewCreate = () => {
  const { accountId } = useAccountContext();
  const [createCustomViewMutation] = useCreateCustomizationViewMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return {
    createCustomView: async (formData: ViewSettingsFormData): Promise<CustomFilterView | null> => {
      const res = await createCustomViewMutation({
        accountId,
        createCustomizationViewRequest: {
          view: formToApiCustomizationView(formData),
        },
      });

      if (!('data' in res)) {
        enqueueSnackbar(t('ENTITIES.CONTRACT_CUSTOM_VIEW.SNACKBARS.FAILED_TO_CREATE'), { variant: 'error' });
        return null;
      }

      return apiCustomizationViewToForm(res.data.view);
    },
  };
};
