import { Box, type BoxProps } from '@mui/material';

type CardWrapperProps = {
  isVisible?: boolean;
} & BoxProps;

export const CardWrapper = ({ children, isVisible = true, ...otherProps }: CardWrapperProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Box flex={1} {...otherProps}>
      {children}
    </Box>
  );
};
