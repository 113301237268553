import React, { FC } from 'react';
import { RepeatingFieldType } from './types';
import { Stack } from '@mui/material';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';
import { DynamicFormPresenterConfig } from '../../types';
import { FormRepeatingField, FormRepeatingFieldType } from '../../../fields/FormRepeatingField';

type RepeatingFieldPresenterProps = {
  field: RepeatingFieldType;
  config: DynamicFormPresenterConfig;
};

export const RepeatingFieldPresenter: FC<RepeatingFieldPresenterProps> = (props) => {
  let { field, config } = props;
  const { label, requiredItems, limit, fields, description } = field.metadata;

  if (fields?.length === 0 && config.mode !== 'readWrite') {
    return null;
  }

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormRepeatingFieldType<FieldValues>>
        name={field.name}
        component={FormRepeatingField}
        componentProps={{
          itemDefFields: fields ?? [],
          requiredItems: requiredItems,
          limit: limit,
          config: config,
        }}
        label={label}
        description={description}
        required={false}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
