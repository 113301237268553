import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBlocker, useParams } from 'react-router-dom';

import { Placeholder, Divider, ChipButton } from '@verticeone/design-system';

import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

import { useConfirmPageExit } from '../../../../hooks/useConfirmPageExit';
import { useAccountContext } from '../../../account/AccountContext';
import { useFeatures } from '../../../features/useFeatures';
import { Definitions } from '../../definitionsTypes';
import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { WorkflowValidationProvider, WorkflowEditor, Validator } from '../../workflowSchema';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useListCatalogResourcesQuery } from '@vertice/slices/src/openapi/codegen/catalogAPI';

import { DiscardChangesDialog } from './components/DiscardChangesDialog';
import { getWorkflowDefinitionsWithAutoLayoutPositions } from '../../workflowSchema/WorkflowEditor/utils';
import { FEATURES } from '../../../features/constants';
import { Actions } from './Actions';
import { getWorkflowDefinitionsFromDefinitions } from '../../definitions/processDefinition';
import { WorkflowDefinitions } from '../../workflowSchema/types';
import { useGetWorkflowVersionName } from '../../hooks/useGetWorkflowVersionName';
import { isServiceCatalogResource } from '../../catalogResource/utils';

export const WorkflowEditorPage: FC = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const params = useParams() as { workflowId: string; versionId: string; serviceId: string };

  const { getFeature } = useFeatures();
  const isWorkflowCustomizationEnabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties
    ?.workflowCustomisation;
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  const {
    data: workflowVersionData,
    isLoading: isWorkflowVersionLoading,
    refetch: refetchWorkflowVersion,
  } = useGetWorkflowVersionQuery({
    accountId,
    workflowId: params.workflowId,
    workflowVersion: params.versionId,
  });

  const workflowDefinitions = useMemo(
    () => getWorkflowDefinitionsFromDefinitions(workflowVersionData?.workflowDefinition as Definitions | undefined),
    [workflowVersionData?.workflowDefinition]
  );

  const [workingDefinitions, setWorkingDefinitions] = useState<WorkflowDefinitions | undefined>(
    workflowDefinitions && isWorkflowCustomizationEnabled
      ? getWorkflowDefinitionsWithAutoLayoutPositions(workflowDefinitions)
      : workflowDefinitions
  );

  const versionName = useGetWorkflowVersionName(workflowVersionData);

  const [hasChanges, setHasChanges] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isChangingPage = nextLocation.pathname !== currentLocation.pathname;
    return (hasChanges || isDirty) && isChangingPage;
  });

  const { data: resourcesData } = useListCatalogResourcesQuery({
    accountId,
  });
  const resource = resourcesData?.items?.find((s) => s.urn === workflowVersionData?.serviceRef);
  const service = isServiceCatalogResource(resource) ? resource : undefined;

  useEffect(
    () => setHasChanges(!isEqual(workingDefinitions, workflowDefinitions)),
    [workingDefinitions, workflowDefinitions]
  );

  useEffect(() => {
    if (workflowDefinitions) {
      setWorkingDefinitions(
        isWorkflowCustomizationEnabled
          ? getWorkflowDefinitionsWithAutoLayoutPositions(workflowDefinitions)
          : workflowDefinitions
      );
    }
  }, [workflowDefinitions, isWorkflowCustomizationEnabled]);

  useConfirmPageExit(hasChanges || isDirty);

  const handleEditNodeDrawerTaskDirty = useCallback(() => {
    setIsDirty(true);
  }, []);

  const handleEditNodeDrawerTaskClose = useCallback(() => {
    setIsDirty(false);
  }, []);

  if (isWorkflowVersionLoading) {
    return <Placeholder height="100%" />;
  }

  const backPath = !isWorkflowsPageV2Enabled
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
        workflowId: params.workflowId,
        serviceId: params.serviceId,
      })
    : generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS);

  const workflowsPageV2Params = isWorkflowsPageV2Enabled && service ? { service: service.urn } : '';

  const parentPagePath = `${backPath}?${new URLSearchParams(
    isWorkflowsPageV2Enabled ? workflowsPageV2Params : { tab: 'drafts' }
  ).toString()}`;

  const parentPageName = isWorkflowsPageV2Enabled
    ? `${service?.name} - ${workflowVersionData?.workflowName}`
    : workflowVersionData?.workflowName;

  return (
    <Stack height={'100%'} minWidth={'100%'}>
      <WorkflowValidationProvider>
        <IntelligentWorkflowsLayout
          parentPageAccountId={accountId}
          parentPageName={parentPageName}
          parentPagePath={parentPagePath}
          grandparentPageName={isWorkflowsPageV2Enabled ? undefined : service?.name}
          grandparentPagePath={
            isWorkflowsPageV2Enabled
              ? undefined
              : generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
                  serviceId: params.serviceId,
                })
          }
          currentPageName={versionName}
          currentPageAdornments={{
            end: (
              <ChipButton variant="ghost" color="primary" size="XS" sx={{ flexShrink: 0 }}>
                {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.DRAFT')}
              </ChipButton>
            ),
          }}
          actions={
            workingDefinitions && workflowVersionData && service ? (
              <Actions
                service={service}
                workflowDefinitions={workingDefinitions}
                workflowVersionData={workflowVersionData}
                workflowId={params.workflowId}
                backPath={parentPagePath}
                refetch={refetchWorkflowVersion}
                hasChanges={hasChanges}
              />
            ) : undefined
          }
          padding={0}
          gap={0}
        >
          <Divider />
          <Stack flexGrow={1}>
            {workingDefinitions ? (
              <WorkflowEditor
                workflowDefinitions={workingDefinitions}
                setDefinitions={setWorkingDefinitions}
                serviceRef={workflowVersionData?.serviceRef}
                onEditNodeDrawerTaskDirty={handleEditNodeDrawerTaskDirty}
                onEditNodeDrawerTaskClose={handleEditNodeDrawerTaskClose}
                onEditNodeDrawerTaskSave={handleEditNodeDrawerTaskClose}
              />
            ) : (
              <Placeholder height="100%" />
            )}
          </Stack>
          <DiscardChangesDialog
            open={blocker.state === 'blocked'}
            onClose={() => blocker.reset?.()}
            onDiscard={() => blocker.proceed?.()}
          />
        </IntelligentWorkflowsLayout>
        {workingDefinitions && <Validator workflowDefinitions={workingDefinitions} />}
      </WorkflowValidationProvider>
    </Stack>
  );
};
