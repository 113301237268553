import { FC } from 'react';
import { Stack } from '@mui/material';
import { DialogContent } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { ProgressText } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/ProgressText';
import { GenericRequestIcon } from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

type CreateNonSaasRequestProgressProps = { purchaseName: string };

export const CreateNonSaasRequestProgress: FC<CreateNonSaasRequestProgressProps> = (props) => {
  const { purchaseName } = props;
  const { t } = useTranslation();

  return (
    <DialogContent>
      <Stack height={320} justifyContent="center" alignItems="center" gap={1}>
        <GenericRequestIcon size="XL" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />
        <Text variant="body-bold" color="text1" mt={8}>
          {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.PREPARING_REQUEST')}
        </Text>
        <ProgressText vendorName={purchaseName} />
      </Stack>
    </DialogContent>
  );
};
