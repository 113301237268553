import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const DASHBOARD_BFFE_TAG_PREFIX = 'DashboardBffe';

export const bffeDashboardAPI = createApi({
  reducerPath: 'bffeDashboardAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.BFFE_DASHBOARD),
  tagTypes: [DASHBOARD_BFFE_TAG_PREFIX],
  endpoints: () => ({}),
});
