import { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useGetCatalogResources } from './useGetCatalogResources';
import { isServiceCatalogResource } from '../catalogResource/utils';

export const useGetRequestService = (request?: Request) => {
  const { resources, isLoading } = useGetCatalogResources({
    resourcesFilter: isServiceCatalogResource,
  });

  // find the service by the generated identifier from the URL
  return {
    isLoading,
    service: resources?.find((item) => item.urn === request?.serviceRef),
  };
};
