import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { useTheme } from '@mui/material';
import {
  cssObjectToString,
  monthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { useChartContext, ChartLayout, ChartDropDownMenu } from '../ChartCard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { extractTooltipValues } from '../../utils';

type CompletedRequestsGraphProps = {
  series: SeriesOptionsWithData[];
  categories: string[];
  isEmpty?: boolean;
};

const CompletedRequestsGraph = ({ categories, series, isEmpty }: CompletedRequestsGraphProps) => {
  const [setChartRef] = useChartRef();
  const { palette } = useTheme();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();
  const { setTooltipData, range, setRange } = useChartContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        applyStackedColumnHover,
        mergeOptions({
          chart: { type: 'column', height: 300, marginTop: 30 },
          plotOptions: {
            column: {
              maxPointWidth: 32,
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    const tooltipValues = extractTooltipValues(this.series.chart.series, point.index);

                    setTooltipData({
                      values: tooltipValues,
                      category: point.category,
                      total: Object.values(tooltipValues).reduce((acc, value) => acc + value, 0),
                    });
                  },
                  mouseOut() {
                    setTooltipData(null);
                  },
                },
              },
            },
          },
          yAxis: {
            ...(isEmpty
              ? {
                  min: 0,
                  max: 10,
                }
              : {}),
            minTickInterval: 1,
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`,
            },
          },
          xAxis: {
            tickLength: 0,
            categories,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true, enabled: false },
          series: [...(getSeriesWithBorderRadius(series, 4) as SeriesOptionsType[])],
        }),
      ]),
    [
      applyXAxisOffset,
      applyStyledHighcharts,
      applyStackedColumnHover,
      isEmpty,
      categories,
      series,
      setTooltipData,
      palette,
    ]
  );

  return (
    <ChartLayout
      filterItems={[
        <ChartDropDownMenu
          key="date-range"
          label={t('FILTER.DATE_RANGE.LABEL')}
          placeholder={t('LAST_X_MONTHS', {
            count: range.endDate.diff(range.startDate, 'month') + 1,
          })}
          items={[
            { id: 3, label: t('LAST_X_MONTHS', { count: 3 }) },
            { id: 6, label: t('LAST_X_MONTHS', { count: 6 }) },
            { id: 12, label: t('LAST_X_MONTHS', { count: 12 }) },
          ]}
          onChange={(item) => {
            const endDate = dayjs().subtract(1, 'months').endOf('month');
            const startDate = endDate.subtract(Number(item.id) - 1, 'months').startOf('month');

            setRange(startDate, endDate);
          }}
        />,
      ]}
    >
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
    </ChartLayout>
  );
};

export default CompletedRequestsGraph;
