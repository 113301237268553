import { useTranslation } from 'react-i18next';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import { Filter } from '../types';
import { useContractOriginOptions } from '../../../../hooks/useContractOriginOptions';

const ContractOriginFilter = () => {
  const { t } = useTranslation();
  const { options } = useContractOriginOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.CONTRACT_ORIGIN}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.CONTRACT_ORIGIN')}
      testId={'contract-origin-filter'}
    />
  );
};

export default ContractOriginFilter;
