import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { TextFieldCaption } from '@verticeone/design-system';
import { Divider } from '@vertice/core/src/components/Dialog/Components';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { AddExistingContractFormData } from '../AddExistingContractWizard/types';
import { AddExistingContractFormData as AddExistingContractFormDataV2 } from '../AddExistingContractWizardV2/types';
import { useFormatCurrency, useFormatDate } from '@verticeone/utils/formatting';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import VendorLogo from '@vertice/core/src/components/VendorLogo';

const SelectedContractPanel: React.FC = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const formatCurrency = useFormatCurrency();
  const { watch } = useFormContext<AddExistingContractFormData | AddExistingContractFormDataV2>();
  const vendor = watch('vendor');
  const deadline = watch('existingContractDeadline');
  const stage = watch('existingContractStage');
  const annualCost = watch('totalAnnualCost');
  const contractCurrency = watch('existingContractCurrency') ?? DEFAULT_CURRENCY;

  if (!vendor) {
    return null;
  }

  return (
    <>
      <Box bgcolor={palette.core.color1} px={8} py={4}>
        <Stack direction="column" spacing={1}>
          <TextFieldCaption label={t('INTAKE_FORM.CONTRACT_SELECTION.SELECTED_CONTRACT')} size="XS" />
          <Stack direction="row" spacing={2} alignItems="center">
            <VendorLogo vendorId={vendor.id ?? undefined} bg={palette.core.bg} />
            <Box flexGrow={1}>
              <Stack direction="row" gap={1} alignItems="center">
                <Text variant="body-regular" color="text1" textAlign="center">
                  {vendor.name}
                </Text>
                {stage && (
                  <>
                    <Text variant="body-regular" color="text4">
                      •
                    </Text>
                    <Text variant="body-regular" color="text2" textAlign="center">
                      {t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${stage}`)}
                    </Text>
                  </>
                )}
                {annualCost && (
                  <>
                    <Text variant="body-regular" color="text4">
                      •
                    </Text>
                    <Text variant="body-regular" color="text2" textAlign="center">
                      {formatCurrency(annualCost, { currency: contractCurrency, maximumFractionDigits: 0 })}
                    </Text>
                  </>
                )}
                {deadline && (
                  <>
                    <Text variant="body-regular" color="text4">
                      •
                    </Text>
                    <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="center">
                      <Text variant="body-regular" color="text2" textAlign="center">
                        {t('ENTITIES.CONTRACT.LABELS.DEADLINE')}
                      </Text>
                      <Text variant="body-regular" color="text2" textAlign="center">
                        {formatDate(dayjs(deadline).toISOString())}
                      </Text>
                    </Stack>
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default SelectedContractPanel;
