import * as z from 'zod';
import { nonEmptyString } from '@verticeone/utils/validation';
import {
  allContractCategories,
  allBillingFrequencies,
  allRollFrequencies,
  allContractOrigins,
  allContractPaymentTerms,
  allRenewalStatuses,
  allContractValues,
} from '../../../../../types';

import {
  BillingFrequency,
  ContractEntityCategory,
  ContractOrigin,
  PaymentTerms,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { unifiedContractStageSchema } from '../../../types';
import { ContractValue, RenewalStatus } from '../../types';

export const viewSettingsFormSchema = z.object({
  id: z.string().optional(),
  label: nonEmptyString,
  filters: z.object({
    contractStage: z.array(unifiedContractStageSchema).nullish(),
    contractRollingFrequency: z
      .array(z.custom<RollFrequency>((val) => allRollFrequencies.includes(val as RollFrequency)))
      .nullish(),
    contractBillingFrequency: z
      .array(z.custom<BillingFrequency>((val) => allBillingFrequencies.includes(val as BillingFrequency)))
      .nullish(),
    contractCategory: z
      .array(z.custom<ContractEntityCategory>((val) => allContractCategories.includes(val as ContractEntityCategory)))
      .nullish(),
    contractOrigin: z
      .array(z.custom<ContractOrigin>((val) => allContractOrigins.includes(val as ContractOrigin)))
      .nullish(),
    contractOwner: z.array(z.string()).nullish(),
    contractPaymentTerms: z
      .array(z.custom<PaymentTerms>((val) => allContractPaymentTerms.includes(val as PaymentTerms)))
      .nullish(),
    contractRenewalStatus: z
      .array(z.custom<RenewalStatus>((val) => allRenewalStatuses.includes(val as RenewalStatus)))
      .nullish(),
    contractValue: z
      .array(z.custom<ContractValue>((val) => allContractValues.includes(val as ContractValue)))
      .nullish(),
    contractDepartment: z.array(z.string()).nullish(),
  }),
  columns: z.array(z.string()),
});

export type ViewSettingsFormData = z.infer<typeof viewSettingsFormSchema>;
