import dayjs, { Dayjs } from 'dayjs';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { WidgetId } from './types';

const getProcuredStatsDemoData = () => {
  return {
    name: 'ProcuredStats',
    data: {
      columns: ['status', 'procuredCost', 'currency'],
      types: ['string', 'float', 'string'],
      data: [],
    },
    stats: {
      procuredCost: {
        amount: 742069,
        currency: 'USD',
      },
      inFlightCost: {
        amount: 215042,
        currency: 'USD',
      },
    },
  };
};

const getRequestsListDemoData = () => {
  return {
    name: 'RequestsList',
    data: {
      columns: ['requestDueDate', 'requestsCost', 'currency', 'name', 'owner', 'vendorID'],
      types: ['date', 'float', 'string', 'string', 'string', 'string'],
      data: [],
    },
    stats: {
      totalNumberOfRequests: 8,
      totalNumberOfOverdueRequests: 2,
      totalCost: {
        amount: 0,
        currency: 'USD',
      },
      totalCostOverdue: {
        amount: 0,
        currency: 'USD',
      },
    },
  };
};

const getTaskListDemoData = () => {
  return {
    name: 'TasksList',
    data: {
      columns: ['taskName', 'parentName', 'taskId', 'vendorId', 'days'],
      types: ['string', 'string', 'string', 'string', 'integer'],
      data: [
        ['InfoSec Approval', 'SaaS - New Purchase', 'Adobe', '61a63d7c-fb29-5829-be2e-e9dadebedade', '5'],
        ['Requirements Gathering', 'SaaS - Contract Renewal', 'Microsoft', '40c6b64f-780c-569a-b8ed-e716d36dc6a3', '3'],
        ['Commercial Approval', 'SaaS - Contract Renewal', 'Hubspot', '0079f31a-400f-5c0b-8b12-fc86c5177e46', '0'],
        ['Legal Approval', 'SaaS - Contract Renewal', 'Loom', '1a8874c9-41cc-5146-b933-17d885c1dbde', '0'],
        ['Requirements Gathering', 'SaaS - New Purchase', 'LinkedIN', '65235894-7167-53f0-8d2f-b16e1b4fcb20', '0'],
      ],
    },
    stats: {
      totalNumberOfTasks: 15,
      totalNumberOfOverdueTasks: 3,
    },
  };
};

const getTaskWithoutAssigneeDemoData = () => {
  return {
    name: 'TasksWithoutAssignee',
    data: {
      columns: ['taskName', 'taskId', 'vendorId', 'daysSinceCreated', 'daysSinceNotAssigned', 'parentRequestName'],
      types: ['string', 'string', 'string', 'integer', 'integer', 'string'],
      data: [],
    },
    stats: {
      totalUnassignedTasks: 0,
    },
  };
};

const getCompletedRequestsByOutcomeDemoData = () => {
  return {
    name: 'CompletedRequestsStats',
    stats: {
      succeededRequests: 15,
      totalRequests: 17,
      rejectedRequests: 2,
      successRatio: 15 / 17,
    },
  };
};

const getTimeToCompleteTaskDemoData = () => {
  return {
    name: 'TimeToCompleteHistogram',
    data: {
      columns: [
        'taskType',
        'requestType',
        'days0To5',
        'days6To10',
        'days11To15',
        'days16To20',
        'days21To25',
        'days26More',
        'averageDaysSinceClosed',
        'totalTasksClosed',
      ],
      types: ['string', 'string', 'integer', 'integer', 'integer', 'integer', 'integer', 'integer', 'float', 'integer'],
      data: [['All', 'All', 42, 12, 6, 2, 1, 0, 46, 6]],
    },
  };
};

const getAverageRequestTimesDemoData = () => {
  const startDate = dayjs().startOf('month');
  const getStartOfMonth = (date: Dayjs, month: number) => date.subtract(month, 'month').format('YYYY-MM-DD');

  return {
    name: 'AverageRequestTimes',
    data: {
      columns: ['requestCount', 'averageDurationInHours', 'month', 'serviceName'],
      types: ['integer', 'float', 'string', 'string'],
      data: [
        [1, '41.5', getStartOfMonth(startDate, 6), 'SaaS - Contract Renewal'],
        [1, '48.4', getStartOfMonth(startDate, 5), 'SaaS - Contract Renewal'],
        [1, '37.4', getStartOfMonth(startDate, 4), 'SaaS - Contract Renewal'],
        [1, '39.8', getStartOfMonth(startDate, 3), 'SaaS - Contract Renewal'],
        [1, '44.9', getStartOfMonth(startDate, 2), 'SaaS - Contract Renewal'],
        [1, '47.5', getStartOfMonth(startDate, 1), 'SaaS - Contract Renewal'],
        [1, '26.3', getStartOfMonth(startDate, 6), 'SaaS - New Purchase'],
        [1, '33.6', getStartOfMonth(startDate, 5), 'SaaS - New Purchase'],
        [1, '52.4', getStartOfMonth(startDate, 4), 'SaaS - New Purchase'],
        [1, '51.7', getStartOfMonth(startDate, 3), 'SaaS - New Purchase'],
        [1, '64.1', getStartOfMonth(startDate, 2), 'SaaS - New Purchase'],
        [1, '71.1', getStartOfMonth(startDate, 1), 'SaaS - New Purchase'],
        [2, '67.8', getStartOfMonth(startDate, 6), 'All'],
        [2, '76.9', getStartOfMonth(startDate, 5), 'All'],
        [2, '89.8', getStartOfMonth(startDate, 4), 'All'],
        [2, '91.5', getStartOfMonth(startDate, 3), 'All'],
        [2, '109.0', getStartOfMonth(startDate, 2), 'All'],
        [2, '118.6', getStartOfMonth(startDate, 1), 'All'],
      ],
    },
  };
};

const getProcuredSpendDemoData = () => {
  const startDate = dayjs().startOf('month');
  const getStartOfMonth = (date: Dayjs, month: number) => date.subtract(month, 'month').format('YYYY-MM-DD');

  const data = [
    [32324, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 6)],
    [32143, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 5)],
    [38298, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 4)],
    [41143, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 3)],
    [34688, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 2)],
    [38353, 'USD', 'NEW_PURCHASE', getStartOfMonth(startDate, 1)],
    [106712, 'USD', 'RENEWAL', getStartOfMonth(startDate, 6)],
    [84223, 'USD', 'RENEWAL', getStartOfMonth(startDate, 5)],
    [122811, 'USD', 'RENEWAL', getStartOfMonth(startDate, 4)],
    [90021, 'USD', 'RENEWAL', getStartOfMonth(startDate, 3)],
    [72243, 'USD', 'RENEWAL', getStartOfMonth(startDate, 2)],
    [85987, 'USD', 'RENEWAL', getStartOfMonth(startDate, 1)],
  ];

  return {
    name: 'ProcuredSpend',
    data: {
      columns: ['cost', 'currency', 'spendType', 'month'],
      types: ['integer', 'string', 'string', 'string'],
      data,
    },
  };
};

const getCompletedRequestsDemoData = () => {
  const startDate = dayjs().startOf('month');
  const getStartOfMonth = (date: Dayjs, month: number) => date.subtract(month, 'month').format('YYYY-MM-DD');

  const data = [
    [getStartOfMonth(startDate, 6), 7, 5, 2],
    [getStartOfMonth(startDate, 5), 4, 3, 1],
    [getStartOfMonth(startDate, 4), 4, 4, 0],
    [getStartOfMonth(startDate, 3), 2, 2, 0],
    [getStartOfMonth(startDate, 2), 6, 5, 1],
    [getStartOfMonth(startDate, 1), 4, 3, 1],
  ];

  return {
    name: 'CompletedRequests',
    data: {
      columns: ['month', 'requestCount', 'onTimeCount', 'notOnTimeCount'],
      types: ['string', 'integer', 'integer', 'integer'],
      data,
    },
  };
};

export const mapWidgetIdToDemoData: {
  [key in WidgetId]: () => Widget;
} = {
  CompletedRequests: getCompletedRequestsDemoData,
  ProcuredSpend: getProcuredSpendDemoData,
  AverageRequestTimes: getAverageRequestTimesDemoData,
  TimeToCompleteHistogram: getTimeToCompleteTaskDemoData,
  CompletedRequestsStats: getCompletedRequestsByOutcomeDemoData,
  TasksWithoutAssignee: getTaskWithoutAssigneeDemoData,
  TasksList: getTaskListDemoData,
  RequestsList: getRequestsListDemoData,
  ProcuredStats: getProcuredStatsDemoData,
};

export const useDashboardDemoData = ({ widgetId }: { widgetId: WidgetId; accountId: string }) => {
  const widgetCallback = mapWidgetIdToDemoData[widgetId];
  if (!widgetCallback) {
    throw new Error(`Invalid widgetId: ${widgetId}`);
  }

  return {
    error: false,
    isFetching: false,
    data: widgetCallback(),
  };
};
