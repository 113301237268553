import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import styles from './SamlIntegration.module.scss';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

const SamlIntegration = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const handleCloseSetup = () => navigate(routes.INTEGRATIONS);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.TITLE')}</Typography>
        <Button className={styles.close} variant="outlined" onClick={handleCloseSetup}>
          {t('SAML.CLOSE_BUTTON')}
        </Button>
      </div>
      <div className={styles.body}>
        <Outlet />
      </div>
    </div>
  );
};

export default SamlIntegration;
