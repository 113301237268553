import { Stack, useTheme } from '@mui/material';
import { StyledStackCard } from './ServiceCard';

import { Placeholder } from '@verticeone/design-system';

export const ServicePlaceholderCard = () => {
  const { palette } = useTheme();
  return (
    <StyledStackCard
      sx={{ background: palette.background.default, borderRadius: 4, border: `1px solid ${palette.core.color2}` }}
    >
      <Placeholder height={160} variant="rectangular" />
      <Stack padding={6} gap={2} alignItems="start">
        <Placeholder height={15} width={100} />
        <Placeholder height={30} width={190} />
      </Stack>
    </StyledStackCard>
  );
};
