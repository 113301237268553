import { AccountUser, UserInfo } from '@vertice/slices';
import { useMemo } from 'react';
import { UserOption } from '../../user/UserSelect/types';
import { parseUserRef } from '../../../hooks/workflows/refUtils';
import { safePick } from '@verticeone/utils/objects';
import { TokenInfo, useRelevantUsersById } from '../../../hooks/useRelevantUsersById';
import { useResolveUsersInfo } from '../../../hooks/useResolveUsersInfo';

const isAccountUser = (user: AccountUser | UserInfo | TokenInfo): user is AccountUser => 'userStatus' in user;
const accountUserToSelectUser = (user: AccountUser | UserInfo | TokenInfo): UserOption => ({
  id: user.userId,
  email: user.email ?? '',
  isVerticeUser: !isAccountUser(user),
  ...safePick(user, 'firstName', 'middleName', 'lastName'),
});

export const useGetAssignableUsers = (refs: string[]) => {
  const userIds = useMemo(() => refs.map((ref) => parseUserRef(ref).userId), [refs]);

  const { usersById: accountUsersById, isLoadingUsers } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const { usersById, isLoading } = useResolveUsersInfo(userIds, accountUsersById);

  return useMemo(() => {
    const assignableUsers = Object.values(accountUsersById ?? {}).map(accountUserToSelectUser);
    const originalUsers: UserOption[] = [];

    for (const userId of userIds) {
      const maybeUser = usersById[userId];
      if (maybeUser) {
        const isAdditional = !accountUsersById[userId];
        const selectedUser = accountUserToSelectUser(maybeUser);
        const option: UserOption = {
          ...selectedUser,
          isVerticeUser: selectedUser?.isVerticeUser || isAdditional,
        };
        originalUsers.push(option);

        if (isAdditional) {
          assignableUsers.push(option);
        }
      }
    }

    return {
      assignableUsers,
      isLoadingAssignableUsers: isLoadingUsers || isLoading,
      originalUsers,
    };
  }, [isLoadingUsers, isLoading, accountUsersById, userIds, usersById]);
};
