import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../fields/FormSelectField2';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues, useWatch } from 'react-hook-form';
import { DropdownFieldType } from './types';
import { SimpleOption } from '@verticeone/design-system';
import { SingleValue } from 'react-select';
import { isNil } from 'lodash';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { DynamicFormPresenterProps } from '../types';

export const DropdownFieldPresenter: FC<DynamicFormPresenterProps<DropdownFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description, values } = field.metadata;
  const fieldValue = useWatch({ name: field.name });
  const disabled = config.mode === 'readOnly' || config.mode === 'preview';

  const options = useMemo(
    () =>
      (values ?? []).map<SimpleOption>((value) => ({
        value,
        label: value,
      })),
    [values]
  );

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormSelectFieldComponentType<FieldValues, SimpleOption, false>>
        name={field.name}
        component={FormSelectField2}
        componentProps={{
          options,
          boxValue: (value: string | null) => (value ? { value, label: value } : null),
          unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
          placeholder: disabled && isNil(fieldValue) ? '—' : undefined,
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
        description={description}
        label={label}
        required={required}
        disabled={disabled}
      />
    </Stack>
  );
};
