import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { UserIdentity } from '@vertice/slices';
import { Text } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';
import { getConnectedUser, SlackConnection, SlackConnectionStatus } from '../../../common';

type SlackUserCellProps = {
  connectedUsers?: UserIdentity[];
} & GridRenderCellParams<SlackConnection>;

export const SlackUserCell = ({
  connectedUsers,
  row: { userId, slackUsers },
  value: selectedSlackUserEmail,
}: SlackUserCellProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const connectedUser = getConnectedUser(userId, connectedUsers);
  const connectedSlackUserName = slackUsers?.find((user) => user.email === connectedUser?.identityEmail)?.userName;
  const isConnectedUserActiveOnSlack =
    slackUsers?.find((user) => user.email === connectedUser?.identityEmail)?.status === 'ACTIVE';
  const selectedSlackUserName = slackUsers?.find((user) => user.email === selectedSlackUserEmail)?.userName;

  if (connectedUser?.status === SlackConnectionStatus.ACTIVE) {
    return (
      <Stack flex={1} direction="row" justifyContent="space-between" alignItems="center" gap={2}>
        <Stack gap={1}>
          <Text
            variant="body-regular"
            size="M"
            color={isConnectedUserActiveOnSlack ? palette.text.color1 : palette.inactive.color1}
          >
            {connectedUser.identityEmail}
          </Text>
          <Text variant="caption" size="XS" color="text3">
            {connectedSlackUserName}
          </Text>
        </Stack>
        {!isConnectedUserActiveOnSlack && (
          <Tooltip
            title={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.USER_INACTIVE_TITLE')}
            content={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.USER_INACTIVE_CONTENT')}
            size="M"
          >
            <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.error.color1} />
          </Tooltip>
        )}
      </Stack>
    );
  }

  if (!selectedSlackUserEmail) {
    return (
      <Text variant="body-regular" size="M" color="text3">
        {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.NO_USER_LINKED')}
      </Text>
    );
  }

  return (
    <Stack gap={1}>
      <Text variant="body-regular" size="M" color="text1">
        {selectedSlackUserEmail}
      </Text>
      <Text variant="caption" size="XS" color="text3">
        {selectedSlackUserName}
      </Text>
    </Stack>
  );
};
