import {
  AssessmentContextOutput,
  AssessmentQuestion,
  BusinessCaseFormData,
  BusinessQuestion,
  DataTypesFormData,
  DataTypesQuestion,
} from './types';
import { ReviewRelationshipFormData } from './formSteps/ReviewRelationshipFormStep/schema';
import { AssessmentContextFormData } from './schema';
import { AccountUser, Url, Vendor } from '@vertice/slices';
import { Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { z } from 'zod';

const correctUrl = (url?: Url) => {
  if (!url) return '';

  if (url.startsWith('http')) {
    return url;
  }

  return `https://${url}`;
};

const getReviewRelationshipDefaults = (props: {
  vendor: Vendor;
  data?: Task['draft'];
  requestOwner?: AccountUser;
}): ReviewRelationshipFormData => {
  const { vendor, data, requestOwner } = props;

  return {
    vendorWebsite: data?.vendorWebsite || correctUrl(vendor.website) || '',
    businessOwner: {
      email: data?.businessOwner.email || requestOwner?.email || '',
      firstName: data?.businessOwner.firstName || requestOwner?.firstName || '',
      lastName: data?.businessOwner.lastName || requestOwner?.lastName || '',
    },
    businessPurpose: data?.businessPurpose || '',
    tags: data?.tags || [],
  };
};

const questionsToDefaults = (questions: AssessmentQuestion[], responses: Task['draft']) =>
  questions.reduce<Record<string, boolean>>((acc, { name }) => {
    acc[name] = responses?.[name] || false;
    return acc;
  }, {});

const getDataTypesDefaults = (questions: DataTypesQuestion[], responses?: Task['draft']): DataTypesFormData =>
  questionsToDefaults(questions, responses);

const getBusinessCaseDefaults = (questions: BusinessQuestion[], responses?: Task['draft']): BusinessCaseFormData =>
  questionsToDefaults(questions, responses);

export const getDefaultFormValues = (props: {
  vendor: Vendor;
  requestOwner?: AccountUser;
  businessCaseQuestions: BusinessQuestion[];
  dataTypesQuestions: DataTypesQuestion[];
  taskDraft?: Task['draft'];
}): AssessmentContextFormData => {
  const { vendor, requestOwner, businessCaseQuestions, dataTypesQuestions, taskDraft } = props;

  return {
    reviewRelationship: getReviewRelationshipDefaults({ vendor, data: taskDraft?.reviewRelationship, requestOwner }),
    businessCase: getBusinessCaseDefaults(businessCaseQuestions, taskDraft?.businessCase),
    dataTypes: getDataTypesDefaults(dataTypesQuestions, taskDraft?.dataTypes),
  };
};

export const createQuestionsSchema = (questions: { name: string }[]) => {
  return z.object(
    questions.reduce<Record<string, z.ZodBoolean>>((acc, question) => {
      acc[question.name] = z.boolean();
      return acc;
    }, {})
  );
};

const formQuestionsToOutput = (data: Record<string, boolean>) =>
  Object.entries(data)
    .filter(([_, boolValue]) => boolValue)
    .map(([name, _]) => ({ name }));

export const transformToOutput = (formData: AssessmentContextFormData): AssessmentContextOutput => {
  return {
    homepage: formData.reviewRelationship.vendorWebsite,
    businessOwnerEmail: formData.reviewRelationship.businessOwner.email,
    businessOwnerFirstName: formData.reviewRelationship.businessOwner.firstName,
    businessOwnerLastName: formData.reviewRelationship.businessOwner.lastName,
    description: formData.reviewRelationship.businessPurpose,
    tags: formData.reviewRelationship.tags,
    contextTypes: formQuestionsToOutput(formData.businessCase),
    dataTypes: formQuestionsToOutput(formData.dataTypes),
  };
};
