import { CatalogResource, useListCatalogResourcesQuery } from '@vertice/slices/src/openapi/codegen/catalogAPI';

import { CatalogResources, CatalogResourceMap } from './types';
import { useAccountContext } from '../../../account/AccountContext';
import { convertJsonSchemaToVariable, getVariableFromFunctionDefinition } from './utils';
import { useMemo } from 'react';
import { XTypeCatalogResource } from '../../catalogResource/types';
import {
  isFormCatalogResource,
  isFunctionCatalogResource,
  isServiceCatalogResource,
  isXTypeCatalogResource,
} from '../../catalogResource/utils';

const getCatalogResourceMapFromCatalogResources = ({
  catalogResources,
  xTypeCatalogResources,
  catalog,
}: {
  catalogResources: CatalogResource[];
  xTypeCatalogResources: XTypeCatalogResource[];
  catalog: 'account' | 'global';
}): CatalogResourceMap => {
  return catalogResources.reduce<CatalogResourceMap>((catalogResourceMap, resource) => {
    const { name, urn } = resource;

    if (isServiceCatalogResource(resource)) {
      if (!resource.definition.Service?.ServiceProvider?.Interface) {
        return catalogResourceMap;
      }

      const { Output, Input } = resource.definition.Service.ServiceProvider.Interface;

      if (!Output?.JsonSchema || !Input?.JsonSchema) {
        return catalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${catalog}-service` } as const;

      catalogResourceMap[urn] = {
        output: convertJsonSchemaToVariable({
          jsonSchema: Output.JsonSchema,
          origin,
          variableKey: '',
          xTypeCatalogResources,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeCatalogResources,
        }).variables,
      };

      return catalogResourceMap;
    }

    if (isFormCatalogResource(resource)) {
      if (!resource.definition.Form?.Interface) {
        return catalogResourceMap;
      }

      const { Output, Input } = resource.definition.Form.Interface;

      if (!Output?.JsonSchema || !Input?.JsonSchema) {
        return catalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${catalog}-form` } as const;

      catalogResourceMap[urn] = {
        output: convertJsonSchemaToVariable({
          jsonSchema: Output.JsonSchema,
          origin,
          variableKey: '',
          xTypeCatalogResources,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeCatalogResources,
        }).variables,
      };
    }

    if (isFunctionCatalogResource(resource)) {
      if (!resource.definition.Function?.FunctionProvider.Interface) {
        return catalogResourceMap;
      }

      const { Input, Output } = resource.definition.Function.FunctionProvider.Interface;

      if (!Input?.JsonSchema || !Output?.JsonSchema) {
        return catalogResourceMap;
      }

      const origin = { id: urn, label: name, kind: `vertice-${catalog}-udf` } as const;

      catalogResourceMap[urn] = {
        output: getVariableFromFunctionDefinition({
          functionCatalogResource: resource,
          xTypeCatalogResources,
          catalog,
        }).variables,
        input: convertJsonSchemaToVariable({
          jsonSchema: Input.JsonSchema,
          origin,
          variableKey: '',
          xTypeCatalogResources,
        }).variables,
      };
    }
    return catalogResourceMap;
  }, {});
};

export const useCatalogResources = (): CatalogResources => {
  const { accountId } = useAccountContext();
  const { data: accountServiceCatalogData, isLoading: isAccountServiceCatalogLoading } = useListCatalogResourcesQuery({
    accountId,
    includeAll: true,
  });
  const { data: globalServiceCatalogData, isLoading: isGlobalServiceCatalogLoading } = useListCatalogResourcesQuery({
    accountId: 'GLOBAL',
    includeAll: true,
  });
  return useMemo(() => {
    if (!accountServiceCatalogData?.items || !globalServiceCatalogData?.items) {
      return {
        account: {},
        global: {},
        xTypeCatalogResources: [],
        isFetching: isAccountServiceCatalogLoading || isGlobalServiceCatalogLoading,
      };
    }

    const xTypeCatalogResources = [
      ...globalServiceCatalogData.items.filter(isXTypeCatalogResource),
      ...accountServiceCatalogData.items.filter(isXTypeCatalogResource),
    ];

    const account = getCatalogResourceMapFromCatalogResources({
      catalogResources: accountServiceCatalogData.items,
      xTypeCatalogResources,
      catalog: 'account',
    });

    const global = getCatalogResourceMapFromCatalogResources({
      catalogResources: globalServiceCatalogData.items,
      xTypeCatalogResources,
      catalog: 'global',
    });

    return {
      account,
      global,
      xTypeCatalogResources,
      isFetching: isAccountServiceCatalogLoading || isGlobalServiceCatalogLoading,
    };
  }, [
    accountServiceCatalogData,
    globalServiceCatalogData,
    isAccountServiceCatalogLoading,
    isGlobalServiceCatalogLoading,
  ]);
};
