import { createPortableRoutes } from '@verticeone/router/utils';

export const cloudRoutes = createPortableRoutes({
  CLOUD: {
    path: '/cloud',
    children: {
      OVERVIEW: {
        path: 'overview',
      },
      ANALYTICS: {
        path: 'analytics',
        children: {
          TAB: {
            path: ':activeTab',
          },
        },
      },
      INSIGHTS: {
        path: 'insights',
        children: {
          TAB: {
            path: ':activeTab',
          },
        },
      },
      EDP: {
        path: 'edp',
      },
      OPTIMIZATION: {
        path: 'optimization',
        children: {
          RECOMMENDATION: {
            path: 'recommendation',
            children: {
              CODE: {
                path: ':code',
              },
            },
          },
        },
      },
      RIO: {
        path: 'rio',
        children: {
          SP: {
            path: 'sp',
            children: {
              INVENTORY: {
                path: 'inventory',
                children: {
                  DETAIL: {
                    path: ':product',
                  },
                },
              },
              OPTIMIZE: {
                path: 'optimize',
                children: {
                  DETAIL: {
                    path: ':product',
                  },
                },
              },
            },
          },
          RI: {
            path: 'ri',
            children: {
              INVENTORY: {
                path: 'inventory',
                children: {
                  DETAIL: {
                    path: ':product',
                  },
                },
              },
              OPTIMIZE: {
                path: 'optimize',
                children: {
                  DETAIL: {
                    path: ':product',
                  },
                },
              },
            },
          },
          /* to be removed when SPRIO is released */
          OPTIMIZE: {
            path: 'optimize',
            children: {
              DETAIL: {
                path: ':product',
              },
            },
          },
          /* to be removed when SPRIO is released */
          INVENTORY: {
            path: 'inventory',
            children: {
              DETAIL: {
                path: ':product',
              },
            },
          },
          TRANSACTIONS: {
            path: 'transactions',
          },
        },
      },
    },
  },
} as const);
