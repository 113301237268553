import { useTranslation } from 'react-i18next';

import { SlackIcon } from '@vertice/assets';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { NotificationsSetupCard } from '../NotificationsSetupCard';

type SlackSetupCardProps = {
  providerId: string;
};

export const SlackSetupCard = ({ providerId }: SlackSetupCardProps) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return (
    <NotificationsSetupCard
      providerId={providerId}
      providerName={t('INTEGRATIONS.SLACK.TITLE')}
      providerIcon={<SlackIcon />}
      description={t('INTEGRATIONS.SLACK.DESCRIPTION')}
      wizardRoute={generatePath(routes.INTEGRATIONS.NATIVE.NOTIFICATIONS.SLACK.ABSOLUTE_PATH)}
    />
  );
};
