import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useConnectAccountIntegrationMutation } from '@vertice/slices';
import { IntegrationItem, Secret } from '../utils';

export const useCreateIntegration = <S extends Secret>() => {
  const { accountId } = useAccountContext();
  const [connectIntegration, ...otherProps] = useConnectAccountIntegrationMutation();

  const connect = ({ secret: { type, ...secret }, ...data }: Required<IntegrationItem<S>>) =>
    connectIntegration({
      accountId,
      integrationId: data.integrationId,
      override: false,
      integrationConnectionInput: {
        parameters: {
          authorizationHeaderName: data.authorizationHeaderName,
          baseUrl: data.baseUrl,
          secret,
        },
      },
    });

  return [connect, otherProps] as const;
};
