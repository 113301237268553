import { TemplateCards } from './types';

export const TEMPLATES: TemplateCards = [
  {
    id: 'request-for-proposal',
    name: 'RFP Submission',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'request-for-proposal',
    },
  },
  {
    id: 'new-employee-hire',
    name: 'New Employee Hire',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'hire-a-new-employee',
    },
  },
  {
    id: 'onboard-new-employee',
    name: 'Onboard New Employee',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'onboard-new-employee',
    },
  },
  {
    id: 'hire-a-contractor',
    name: 'Hire a Contractor',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'hire-a-new-contractor',
    },
  },
  {
    id: 'office-lease-renewal',
    name: 'Office Lease Renewal',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'office-lease-renewal',
    },
  },
  {
    id: 'request-office-supplies',
    name: 'Request Office Supplies',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'request-office-supplies',
    },
  },
  {
    id: 'professional-services-project',
    name: 'Professional Services Project',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'contract-renewal',
    },
  },
  {
    id: 'vendor-due-diligence',
    name: 'Vendor Due Diligence',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'vendor-due-diligence',
    },
  },
  {
    id: 'insurance-services',
    name: 'Insurance Services',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'insurance-services',
    },
  },
  {
    id: 'tender-process',
    name: 'Tender Process',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'tender-process',
    },
  },
  {
    id: 'purchase-it-hardware',
    name: 'Purchase IT Hardware',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'purchase-it-hardware',
    },
  },
  {
    id: 'marketing-retainer',
    name: 'Marketing Retainer',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'marketing-retainer',
    },
  },
  {
    id: 'marketing-event',
    name: 'Marketing Event',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'marketing-event',
    },
  },
  {
    id: 'sign-nda',
    name: 'Sign NDA',
    thumbnail: {
      type: 'PREDEFINED',
      image: 'sign-nda',
    },
  },
];
