import { useDroppable, DndContext, DragEndEvent, DragStartEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Stack, useTheme } from '@mui/material';
import { FieldCard } from '../../../DynamicFormBuilder';
import { fieldToComponent } from '../../../../../intelligentWorkflows/workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm/CustomFormEdit';
import { useState } from 'react';
import { DynamicFormField } from '../../../types';
import { ActiveEditingField } from '../../../../../intelligentWorkflows/workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm/CustomFormFieldDialog';
import { PrimitiveDynamicFormField } from '../../../primitiveTypes';

type RepeatingFieldSchemaFieldsProps = {
  fields: PrimitiveDynamicFormField[];
  repeatingFieldName: string;
  onSchemaFieldsMove: (oldIndex: number, newIndex: number) => void;
  onSchemaFieldsRemove: (removeIndex: number) => void;
  onSchemaFieldsUpdate: (updateIndex: number, value: PrimitiveDynamicFormField) => void;
  onEdit: (val: ActiveEditingField) => void;
};

export const RepeatingFieldSchemaFields = ({
  fields,
  repeatingFieldName,
  onSchemaFieldsMove,
  onSchemaFieldsRemove,
  onSchemaFieldsUpdate,
  onEdit,
}: RepeatingFieldSchemaFieldsProps) => {
  const { palette } = useTheme();
  const { setNodeRef } = useDroppable({ id: `schema-fields-list.${repeatingFieldName}` });
  const [activeDrag, setActiveDrag] = useState<(DynamicFormField & { id: string }) | null>(null);

  const fieldsWithId = fields.map((field) => ({ ...field, id: field.name }));

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveDrag(fieldsWithId.find((f) => f.id === active.id) ?? null);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fieldsWithId.findIndex((f) => f.name === active.id);
      const newIndex = fieldsWithId.findIndex((f) => f.id === over.id);

      return onSchemaFieldsMove(oldIndex, newIndex);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} collisionDetection={closestCenter}>
      <SortableContext items={fieldsWithId}>
        <Stack gap={4} ref={setNodeRef}>
          {fieldsWithId.map((field, index) => {
            const removeField = () => onSchemaFieldsRemove(index);
            const editField = () => {
              onEdit({ index, fieldType: field.type });
            };
            const changeField = (metadata: any) => onSchemaFieldsUpdate(index, { ...field, metadata });

            return (
              <FieldCard.Draggable
                key={field.id}
                id={field.name}
                style={{ border: `1px solid ${palette.core.color4}` }}
              >
                {fieldToComponent(field, removeField, editField, changeField)}
              </FieldCard.Draggable>
            );
          })}
        </Stack>
      </SortableContext>
      <FieldCard.DragOverlay id={activeDrag?.id ?? null}>
        {activeDrag ? fieldToComponent(activeDrag) : null}
      </FieldCard.DragOverlay>
    </DndContext>
  );
};
