import { Button, DesignSystemColor } from '@verticeone/design-system';
import React from 'react';
import { DynamicFormPresenterConfig } from '../../dynamicForms';
import { Stack } from '@mui/material';

type ActionButtonProps = {
  presenterConfig: DynamicFormPresenterConfig;
  text: string;
  color?: DesignSystemColor;
  onClick?: () => void;
};

export const ActionButton = ({ presenterConfig, text, onClick, color }: ActionButtonProps) => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: 6,
        right: 0,
        zIndex: 10,
      }}
    >
      <Button
        onClick={onClick}
        size="XS"
        variant="plain"
        color={color ?? 'warning'}
        disabled={['readOnly', 'preview'].some((mode) => mode === presenterConfig.mode)}
      >
        {text}
      </Button>
    </Stack>
  );
};
