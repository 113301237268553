import { useCallback, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  TextFieldCaption,
  TextField,
  Button,
  DialogActions,
} from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useGetWorkflowVersionName } from '../../../hooks/useGetWorkflowVersionName';
import { WorkflowVersionRow } from '../../../workflow/WorkflowDetailPage/types';
import { useDialogKeyActions } from './useDialogKeyActions';

type RenameWorkflowDialogProps = {
  hasError?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (newName: string) => void;
  isSubmitting: boolean;
  workflowVersion: WorkflowVersionRow;
};

export const RenameWorkflowDialog = ({
  hasError = false,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  workflowVersion,
}: RenameWorkflowDialogProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.RENAME_DIALOG',
  });

  const workflowVersionName = useGetWorkflowVersionName(workflowVersion);
  const [versionName, setVersionName] = useState(workflowVersionName ?? '');

  const dialogRef = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback(() => {
    if (isSubmitting || !isOpen) return;
    onSubmit(versionName);
  }, [isSubmitting, isOpen, versionName, onSubmit]);

  useDialogKeyActions({
    onEscape: onClose,
    onEnter: handleSubmit,
    ref: dialogRef,
  });

  return (
    <Dialog open={isOpen} onClose={onClose} size="S">
      <Stack ref={dialogRef}>
        <DialogHeader>{t('RENAME_VERSION')}</DialogHeader>
        <DialogContent>
          <Stack direction="column" gap={1}>
            <TextFieldCaption label={t('NAME')} htmlFor="copy-version-input" size="XS" required={hasError} />
            <TextField
              size="M"
              color="primary"
              id="copy-version-input"
              value={versionName}
              onChange={(e) => setVersionName(e.target.value)}
              placeholder={workflowVersionName}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap={2}>
            <Button size="M" variant="plain" color="primary" onClick={onClose} disabled={isSubmitting}>
              {t('CANCEL')}
            </Button>
            <Button
              size="M"
              variant="solid"
              color="primary"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={() => onSubmit(versionName)}
            >
              {t('RENAME')}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
