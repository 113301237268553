import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { Text } from '@verticeone/design-system';
import { TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useFormatDate, useFormatCurrency } from '@verticeone/utils/formatting';
import { useTaskContext } from '../../../../TaskContext';
import { useGetContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '../../../../../../../account/AccountContext';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

type SelectedContractPanelProps = {
  overrideAccountId?: string | null;
  cardStyle?: boolean;
};

export const SelectedContractPanel: React.FC<SelectedContractPanelProps> = ({ overrideAccountId, cardStyle }) => {
  const account = useAccountContext();
  const { palette } = useTheme();
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const formatDate = useFormatDate();
  const { taskOverview } = useTaskContext();
  const contractId = taskOverview?.task?.input?.contractId;
  const contractAccountId = overrideAccountId || account.accountId;
  const contract = useGetContractQuery(
    { contractId, accountId: contractAccountId },
    { skip: !contractId || !contractAccountId }
  );

  if (!contract.data) {
    return null;
  }

  const vendor = contract?.data?.viewOnContract?.contract?.parts?.contractual?.vendor;
  const deadline = contract?.data?.viewOnContract?.contract?.materialized?.renewalDeadline;
  const stage = contract?.data?.viewOnContract?.contract?.materialized?.simplifiedStage;
  const annualCost = contract?.data?.viewOnContract?.contract?.parts?.overview?.effectiveTotalCostValue;
  const contractCurrency = contract?.data?.viewOnContract?.contract?.parts?.contractual?.financial?.baseCurrency;

  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        style={
          cardStyle
            ? {
                backgroundColor: palette.input.color1,
                border: `1px solid ${palette.input.color2}`,
                borderRadius: 8,
                padding: 12,
              }
            : {}
        }
      >
        {!cardStyle && <TextFieldCaption label={t('INTAKE_FORM.CONTRACT_SELECTION.SELECTED_CONTRACT')} size="XS" />}

        <Stack direction="row" spacing={2} alignItems="center">
          <Box padding={1} bgcolor={palette.core.bg} borderRadius={2}>
            <VendorLogo vendorId={vendor?.vendorId} bg="transparent" />
          </Box>
          <Box flexGrow={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <Text variant="body-regular" color="text1" textAlign="center">
                {vendor?.vendorName}
              </Text>
              {stage && (
                <>
                  <Text variant="body-regular" color="text4">
                    •
                  </Text>
                  <Text variant="body-regular" color="text2" textAlign="center">
                    {t(`ENTITIES.CONTRACT.STAGES_ON_DETAIL.${stage}`)}
                  </Text>
                </>
              )}
              {annualCost !== undefined && (
                <>
                  <Text variant="body-regular" color="text4">
                    •
                  </Text>
                  <Text variant="body-regular" color="text2" textAlign="center">
                    {formatCurrency(annualCost, {
                      currency: contractCurrency ?? DEFAULT_CURRENCY,
                      maximumFractionDigits: 0,
                    })}
                  </Text>
                </>
              )}
              {deadline && (
                <>
                  <Text variant="body-regular" color="text4">
                    •
                  </Text>
                  <Stack direction="row" gap={1} flexWrap="wrap" justifyContent="center">
                    <Text variant="body-regular" color="text2" textAlign="center">
                      {t('ENTITIES.CONTRACT.LABELS.DEADLINE')}
                    </Text>
                    <Text variant="body-regular" color="text2" textAlign="center">
                      {formatDate(deadline.deadlineDate)}
                    </Text>
                  </Stack>
                </>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
