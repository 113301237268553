import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ServiceCatalogResource } from '@vertice/core/src/modules/intelligentWorkflows/catalogResource/types';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useUpdateIntegrationConfigMutation } from '@vertice/slices';
import { JiraTrigger } from './JiraTrigger';
import { Trigger, TriggerSettings, TriggerType } from './types';
import { createTrigger, getFilledTriggers } from './utils';
import { WorkflowWrap } from './WorkflowWrap';

type WorkflowTriggersProps = {
  workflowService: ServiceCatalogResource;
  workflowType: string;
  config: TriggerSettings;
};

export const WorkflowTriggers: FC<WorkflowTriggersProps> = ({ workflowService, workflowType, config }) => {
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [updateIntegrationConfig] = useUpdateIntegrationConfigMutation();

  const [triggers, setTriggers] = useState(config.triggers);
  const [active, setActive] = useState(config?.enabled ?? true);
  const [triggersCount, setTriggersCount] = useState(0);

  const handleUpdateTriggers = useCallback(
    async (isActive: boolean, newTriggers?: Trigger[]) => {
      const filledTriggers = getFilledTriggers(newTriggers ?? triggers);

      if (isActive !== active) {
        setActive(isActive);
      }
      try {
        await updateIntegrationConfig({
          accountId,
          body: {
            triggerSettings: {
              [workflowType]: {
                ...config,
                enabled: isActive,
                triggers: filledTriggers,
              },
            },
          },
        });
      } catch (error) {
        enqueueSnackbar({
          message: t('INTEGRATIONS.JIRA.JIRA_TRIGGER.SNACKBAR_ERROR_MESSAGE'),
          description: t('INTEGRATIONS.JIRA.JIRA_TRIGGER.SNACKBAR_ERROR_DESCRIPTION'),
          variant: 'error',
          persist: true,
        });
      }
    },
    [accountId, active, config, enqueueSnackbar, t, triggers, updateIntegrationConfig, workflowType]
  );

  useEffect(() => {
    setTriggersCount(triggers.length);
  }, [triggers.length]);

  const handleAddTrigger = useCallback((triggerType: TriggerType) => {
    setTriggers((currentTriggers) => [...currentTriggers, createTrigger(triggerType)]);
  }, []);

  const handleOnChange = useCallback(
    (index: number, trigger?: Trigger) => {
      setTriggers((currentTriggers) => {
        const newTriggers = trigger
          ? currentTriggers.toSpliced(index, 1, trigger)
          : currentTriggers.toSpliced(index, 1);

        void handleUpdateTriggers(active, newTriggers);

        return newTriggers;
      });
    },
    [active, handleUpdateTriggers]
  );

  return (
    <WorkflowWrap
      workflowService={workflowService}
      active={active}
      onActivate={handleUpdateTriggers}
      triggersCount={triggersCount}
      onAddTrigger={handleAddTrigger}
    >
      {triggers.map((trigger, index) => (
        <JiraTrigger
          key={trigger?.triggerId ?? index}
          name={`Trigger ${String.fromCharCode(65 + index)}`}
          trigger={trigger}
          index={index}
          active={active}
          onChange={handleOnChange}
        />
      ))}
    </WorkflowWrap>
  );
};
