import React, { useMemo } from 'react';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { DateCell } from '../ContractList/Cells/DateCell';
import DataGridPriceCell from '../../../../../components/DataGridPriceCell/DataGridPriceCell';
import DataGridDatePickerCell from '../../../../../components/DataGridDatePickerCell/DataGridDatePickerCell';
import { UnitCostHeaderCell } from './cells/UnitCostHeaderCell';
import { TotalCostHeaderCell } from './cells/TotalCostHeaderCell';
import CostCell from './cells/CostCell';
import { CostModelLineItem } from './types';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useLineItemColumnBuilder } from '../LineItemsTable/hooks/useLineItemColumnBuilder';
import { LineItemsTableColumn } from '../LineItemsTable';
import { isNotNil } from '@verticeone/utils/validation';
import { GridValueSetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { isOneOffRollFrequency, isRollingRollFrequency } from '../../computed';

export type UseColumnsParams = {
  currency?: string;
  rollingFrequency: RollFrequency;
};

export const useColumns = ({ currency, rollingFrequency }: UseColumnsParams) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.COST_MODEL_LINE_ITEMS_TABLE' });
  const { buildColumn } = useLineItemColumnBuilder<CostModelLineItem>();

  return useMemo(() => {
    const isRolling = !!rollingFrequency && isRollingRollFrequency(rollingFrequency);
    const isOneOff = !!rollingFrequency && isOneOffRollFrequency(rollingFrequency);
    const unitCostLabel = isRolling ? t(`COST_PER_ITEM_${rollingFrequency}`) : t('COST_PER_ITEM');
    const totalCostLabel = isRolling ? t(`TOTAL_COST_${rollingFrequency}`) : t('TOTAL_COST');

    const columns: GridColDef<CostModelLineItem>[] = [
      buildColumn(LineItemsTableColumn.LINE_ITEM_TYPE),
      buildColumn(LineItemsTableColumn.DESCRIPTION),
      {
        field: 'unitCost',
        headerName: unitCostLabel,
        renderHeader:
          isRolling || isOneOff
            ? undefined
            : (params: GridColumnHeaderParams<CostModelLineItem>) => <UnitCostHeaderCell {...params} />,
        renderCell: (params: GridRenderCellParams) => <CostCell currency={currency} {...params} />,
        renderEditCell: (params: GridRenderCellParams) => (
          <DataGridPriceCell placeholder={t('COST_PER_ITEM')} {...params} />
        ),
        disableColumnMenu: true,
        valueSetter: ({ row, value: unitCost }: GridValueSetterParams) => {
          const { quantity, totalCost, ...rest } = row;
          return {
            ...rest,
            quantity,
            unitCost,
            totalCost: quantity && unitCost ? quantity * unitCost : totalCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        ...(buildColumn(LineItemsTableColumn.QUANTITY) as GridColDef<CostModelLineItem>),
        valueSetter: ({ row, value: quantity }: GridValueSetterParams) => {
          const { unitCost, totalCost, ...rest } = row;
          return {
            ...rest,
            quantity,
            unitCost,
            totalCost: quantity && unitCost ? quantity * unitCost : totalCost,
          };
        },
      },
      {
        field: 'totalCost',
        headerName: totalCostLabel,
        renderHeader:
          isRolling || isOneOff
            ? undefined
            : (params: GridColumnHeaderParams<CostModelLineItem>) => <TotalCostHeaderCell {...params} />,
        renderCell: (params: GridRenderCellParams) => <CostCell currency={currency} {...params} />,
        renderEditCell: (params: GridRenderCellParams) => (
          <DataGridPriceCell placeholder={totalCostLabel} {...params} />
        ),
        disableColumnMenu: true,
        valueSetter: ({ row, value }: GridValueSetterParams) => {
          const { quantity, unitCost, totalCost, ...rest } = row;
          return {
            ...rest,
            quantity: value && unitCost && !quantity ? value / unitCost : quantity,
            unitCost: value && quantity && !unitCost ? value / quantity : unitCost,
            totalCost: !unitCost || !quantity ? value : totalCost,
          };
        },
        editable: true,
        flex: 1,
      },
      {
        field: 'startDate',
        headerName: t('START_DATE'),
        valueGetter: ({ row }: GridValueGetterParams) => row.allocationSpan?.startDate,
        valueSetter: ({ row, value }: GridValueSetterParams) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, startDate: value },
        }),
        renderCell: ({ value }: GridRenderCellParams) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params: GridRenderCellParams) => <DataGridDatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
        maxWidth: 235,
      },
      {
        field: 'endDate',
        headerName: t('END_DATE'),
        valueGetter: ({ row }: GridValueGetterParams) => row.allocationSpan?.endDate,
        valueSetter: ({ row, value }: GridValueSetterParams) => ({
          ...row,
          allocationSpan: { ...row.allocationSpan, endDate: value } as CostModelLineItem['allocationSpan'],
        }),
        renderCell: ({ value }: GridRenderCellParams) => (value ? <DateCell date={value} /> : null),
        renderEditCell: (params: GridRenderCellParams) => <DataGridDatePickerCell {...params} />,
        disableColumnMenu: true,
        editable: true,
        flex: 1,
        maxWidth: 235,
      },
    ].filter(isNotNil);

    return {
      columns,
      columnVisibilityModel: {
        startDate: !isRolling && !isOneOff,
        endDate: !isRolling && !isOneOff,
      },
    };
  }, [currency, rollingFrequency, buildColumn, t]);
};
