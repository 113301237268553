import { useTheme } from '@mui/material';
import { Variant } from './types';

export const useStatCardColorByVariant = (variant: Variant) => {
  const { palette } = useTheme();

  const colorByVariant: {
    [key in Variant]: {
      bgColor: string;
      iconColor: string;
      shadowColor: string;
    };
  } = {
    info: {
      bgColor: palette.info.color1,
      iconColor: palette.info.color2,
      shadowColor: 'secondary',
    },
    success: {
      bgColor: palette.success.color1,
      iconColor: palette.success.color2,
      shadowColor: 'success',
    },
    warning: {
      bgColor: palette.warning.color1,
      iconColor: palette.warning.color2,
      shadowColor: 'warning',
    },
    error: {
      bgColor: palette.error.color1,
      iconColor: palette.error.color2,
      shadowColor: 'error',
    },
    plain: {
      bgColor: palette.neutral.color1,
      iconColor: palette.neutral.color2,
      shadowColor: 'core',
    },
  };

  return colorByVariant[variant];
};
