import { Stack } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { Control, DeepPartial, UseFormSetValue, useWatch } from 'react-hook-form';
import { ContractLineItemsForm } from './types';
import { FormOverridableCostField } from '../../../../../../forms/fields/FormOverridableCostField';
import { ComputeCostsInput, useComputedCosts } from './hooks/useComputedCosts';
import { ContractReviewForm } from '../../types';
import { match } from 'ts-pattern';
import { CostModelLineItem } from '../../../../../../saas/contract/components/CostModelLineItemsTable';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import { useTranslation } from 'react-i18next';
import { getComputedCostKind, toComputeCostInput } from './utils';

export type OverrideCostsPanelProps = {
  control: Control<ContractLineItemsForm>;
  setValue: UseFormSetValue<ContractLineItemsForm>;
  contractForm: DeepPartial<ContractReviewForm>;
};

const ContractLineItemsFormEntry = createTypedFormEntry<ContractLineItemsForm>();

export const OverrideCostsPanel: FC<OverrideCostsPanelProps> = (props) => {
  const { control, contractForm, setValue } = props;
  const { t } = useTranslation();

  const lineItems = useWatch({ control, name: 'lineItems' });
  const currency = useWatch({ control, name: 'currency' });

  const isTotalCostOverriden = useWatch({ control, name: 'totalCostOverride.enabled' });
  const isAnnualCostOverriden = useWatch({ control, name: 'annualCostOverride.enabled' });

  const computeCostsInput = useMemo<ComputeCostsInput>(() => {
    return toComputeCostInput(contractForm as ContractReviewForm, lineItems as CostModelLineItem[]);
  }, [lineItems, contractForm]);

  const { data, isLoading } = useComputedCosts({ computeCostsInput });

  useEffect(() => {
    if (!isTotalCostOverriden) setValue('totalCostOverride.amount', data?.totalCost ?? 0);
    if (!isAnnualCostOverriden) setValue('annualCostOverride.amount', data?.annualCost ?? 0);
  }, [data, setValue, isTotalCostOverriden, isAnnualCostOverriden]);

  return (
    <Stack direction="row">
      {match(getComputedCostKind(contractForm as ContractReviewForm))
        .with('total', () => (
          <ContractLineItemsFormEntry
            label={t('ENTITIES.CONTRACT.LABELS.TOTAL_CONTRACT_VALUE')}
            name="totalCostOverride"
            component={FormOverridableCostField}
            componentProps={{ currency, isLoading }}
          />
        ))
        .with('annual', () => (
          <ContractLineItemsFormEntry
            label={t('ENTITIES.CONTRACT.LABELS.ANNUALIZED_CONTRACT_VALUE')}
            name="annualCostOverride"
            component={FormOverridableCostField}
            componentProps={{ currency, isLoading, totalContractValue: data?.totalCost }}
          />
        ))
        .exhaustive()}
    </Stack>
  );
};
