import { GridRowModel } from '@mui/x-data-grid-pro';
import { convertRowToLineItem } from '../LineItemsTable/utils';
import { CostModelLineItem } from './types';
import { TFunction } from 'i18next';
import { ValidateRowResult } from '../../../../../components/EditableDataGrid/useEditableDataGrid';
import dayjs from 'dayjs';

export const getCostModelLineItem = (newRow: GridRowModel): CostModelLineItem => {
  const hasSpan =
    newRow.allocationSpan?.startDate || newRow.allocationSpan?.endDate || newRow.allocationSpan?.rollingFrequency;

  return {
    ...convertRowToLineItem(newRow),
    unitCost: newRow.unitCost,
    totalCost: newRow.totalCost,
    allocationSpan: hasSpan
      ? {
          startDate: newRow.allocationSpan?.startDate,
          endDate: newRow.allocationSpan?.endDate,
          rollingFrequency: newRow.allocationSpan?.rollingFrequency,
        }
      : undefined,
  };
};

export const validateLineItem =
  (t: TFunction) =>
  (row: CostModelLineItem): ValidateRowResult<CostModelLineItem> => {
    const startDate = row.allocationSpan?.startDate;
    const endDate = row.allocationSpan?.endDate;
    if (startDate && endDate && dayjs(endDate).isBefore(dayjs(startDate))) {
      return {
        isValid: false,
        message: t('ENTITIES.COST_MODEL_LINE_ITEMS_TABLE.ERROR_DIALOG.END_BEFORE_START_MESSAGE'),
      };
    }

    return { isValid: true };
  };
