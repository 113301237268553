import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetSsoDetailsQuery } from '@vertice/slices';
import { Loader } from '@vertice/components';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import StepsIndicator from './StepsIndicator';

import styles from './SamlIntegrationRestart.module.scss';
import commonStyles from './SamlIntegration.module.scss';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

const SamlIntegrationRestart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigate: newNavigate } = useRouteNavigate();
  const routes = useRoutes();
  const { accountId } = useAccountContext();
  const toCreateAppStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_CREATE_APP}`);
  const toTeamMembersStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_TEAM_MEMBERS}`);

  const { data: ssoDetails } = useGetSsoDetailsQuery({ accountId: accountId! }, { skip: !accountId });

  useEffect(() => {
    if (ssoDetails && ssoDetails.status !== 'ACTIVE') {
      newNavigate(routes.INTEGRATIONS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoDetails]);

  if (!ssoDetails) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.RESTART.TITLE')}</Typography>
      </div>

      <div className={commonStyles['inner-body']}>
        <div className={`${commonStyles.description} ${styles.alert}`}>
          <Typography variant="body-bold-m">{t('SAML.RESTART.BODY_1')}</Typography>
        </div>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.RESTART.BODY_2')}</Typography>
        </div>

        <div className={commonStyles['nav-buttons']}>
          <Button variant="outlined" className={commonStyles['nav-button']} onClick={toCreateAppStep}>
            {t('SAML.RESTART.EDIT_CONFIGURATION')}
          </Button>
          <Button variant="contained" className={commonStyles['nav-button']} onClick={toTeamMembersStep}>
            {t('SAML.RESTART.MIGRATE_USERS')}
          </Button>
        </div>
      </div>

      <div className={commonStyles.steps}>
        <StepsIndicator total={5} current={1} />
      </div>
    </div>
  );
};

export default SamlIntegrationRestart;
