import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextFieldCaption, Text, TextField } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import FormControl from './FormControl';

type ApiKeyFieldProps<T extends FieldValues> = {
  apiKeyName: FieldPath<T>;
  authHeaderName: FieldPath<T>;
  control: Control<T>;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
};

const ApiKeyField = <T extends FieldValues>({
  apiKeyName,
  authHeaderName,
  control,
  placeholder,
  required = true,
  disabled = false,
}: ApiKeyFieldProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });

  return (
    <FormControl gap={6}>
      <Stack>
        <Stack>
          <TextFieldCaption label={t('AUTH_HEADER.TITLE')} htmlFor="auth-header" size="XS" required={required} />
          <Text variant="body-regular" size="XS" color="text3">
            {t('AUTH_HEADER.DESCRIPTION')}
          </Text>
        </Stack>
        <Controller
          name={authHeaderName}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <TextField
              id="auth-header"
              size="M"
              placeholder={t('AUTH_HEADER.PLACEHOLDER')}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </Stack>
      <Stack>
        <Stack>
          <TextFieldCaption label={t('API_KEY.TITLE')} htmlFor="auth-header" size="XS" required={required} />
          <Text variant="body-regular" size="XS" color="text3">
            {t('API_KEY.DESCRIPTION')}
          </Text>
        </Stack>
        <Controller
          name={apiKeyName}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <TextField
              id="api-key"
              size="M"
              placeholder={placeholder ?? t('API_KEY.PLACEHOLDER')}
              disabled={disabled}
              {...field}
            />
          )}
        />
      </Stack>
    </FormControl>
  );
};

export default ApiKeyField;
