import { useMemo } from 'react';
import { ViewOnContractForVendorsPage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useListVendorsPageContractsPaginatedQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { chain } from 'lodash';
import { VendorData } from '../types';
import { getContractCounts } from '../utils';

export type UseVendorAnalyticsReturn = {
  analyticsPerVendor?: {
    [vendorId: string]: VendorData;
  };
  myVendorIds?: string[];
  isLoading: boolean;
};

const useVendorAnalytics = (): UseVendorAnalyticsReturn => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useListVendorsPageContractsPaginatedQuery({
    accountId,
  });

  const analyticsPerVendor = useMemo(() => {
    if (!data?.contracts) {
      return undefined;
    }

    return chain(data?.contracts)
      .groupBy((view) => view.vendor?.vendorId)
      .mapValues((allVendorContracts, vendorId) => {
        const contractsPerLineage = chain(allVendorContracts)
          .groupBy((view) => view.lineageId)
          .values()
          .value();

        const productNames = chain(allVendorContracts)
          .flatMap(
            (view: ViewOnContractForVendorsPage) =>
              view.products?.map((product) => product.productName).filter(Boolean) as string[]
          )
          .uniq()
          .value();

        return {
          contractCounts: getContractCounts(contractsPerLineage),
          productNames,
        };
      })
      .value();
  }, [data?.contracts]);

  const myVendorIds = useMemo(() => {
    if (!analyticsPerVendor) {
      return undefined;
    }

    return Object.entries(analyticsPerVendor).reduce((acc: string[], [key, value]) => {
      if (value.contractCounts.existing || value.contractCounts.purchaseInProgress) {
        acc.push(key);
      }
      return acc;
    }, []);
  }, [analyticsPerVendor]);

  return {
    analyticsPerVendor,
    myVendorIds,
    isLoading: isLoading || !analyticsPerVendor || !myVendorIds,
  };
};

export default useVendorAnalytics;
