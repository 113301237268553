import { Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { useFormatDate } from '@verticeone/utils/formatting';
import backgroundRed from './assets/background-red.jpg';
import { EmptyDateHeader } from './EmptyDateHeader';
import { RenewalDeadline } from '@vertice/core/src/modules/saas/contract/types';
import { getDeadlineOriginLabel } from './utils';
import { useTranslation } from 'react-i18next';

type SoonExpirationHeaderProps = {
  deadline: RenewalDeadline | undefined;
};

export const SoonExpirationHeader = ({ deadline }: SoonExpirationHeaderProps) => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { palette } = useTheme();
  const { deadlineDate, origin } = deadline ?? {};

  if (!deadlineDate) {
    return <EmptyDateHeader />;
  }

  return (
    <HighlightedCardHeader
      testId="card-header-red"
      backgroundColor={palette.error.color1}
      backgroundImage={`url(${backgroundRed})`}
      noDivider
    >
      <Stack gap={2} alignItems="center" width="100%">
        <Text testId="card-header-title" variant="caption" size="XS" color="text2">
          {getDeadlineOriginLabel(origin, t)}
        </Text>
        <Text testId="card-header-date" variant="heading" size="M" color="text1">
          {formatDate(deadlineDate)}
        </Text>
      </Stack>
    </HighlightedCardHeader>
  );
};
