import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BuildOutlined,
  ContentCopyOutlined,
  DeleteOutline,
  DescriptionOutlined,
  EditOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';

import {
  DesignSystemColor,
  DesignSystemSize,
  IconButton,
  Menu,
  MenuItem,
  Text,
  Tooltip,
} from '@verticeone/design-system';

import type { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

import { useDuplicateWorkflowVersion } from '../../../workflow/WorkflowDetailPage/components/hooks/useDuplicateWorkflowVersion';
import { useGoToWorkflowEditor } from '../../../workflow/WorkflowDetailPage/components/hooks/useGoToWorkflowEditor';
import type { WorkflowVersionRow } from './types';
import { SetAsLiveButton } from './SetAsLiveButton';
import { useRenameWorkflow } from '../../hooks/useRenameWorkflow';
import { Stack, useTheme } from '@mui/material';
import { useDeleteWorkflowV2 } from '../../hooks/useDeleteWorkflowV2';
import { useSnackbar } from 'notistack';
import { VersionNotesDialog } from '../Dialogs/VersionNotesDialog';
import { TOOLTIP_PROPS, getIsWorkflowLive, getIsWorkflowLocked } from './utils';
import { useWorkflowsTableContext } from './WorkflowsTableContext';

type MenuButtonProps = {
  workflowVersion: WorkflowVersionRow;
  liveWorkflowVersion?: WorkflowVersion;
  color: DesignSystemColor;
  size?: DesignSystemSize;
};

export const MenuButton: FC<MenuButtonProps> = ({ workflowVersion, color, size = 'S', liveWorkflowVersion }) => {
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isVersionNotesModalOpen, setIsVersionNotesModalOpen] = useState(false);
  const isLocked = getIsWorkflowLocked(workflowVersion);
  const isLive = getIsWorkflowLive(workflowVersion);
  const { setLastEditedWorkflowVersionId } = useWorkflowsTableContext();

  const isDraft = !isLive && !isLocked;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.ACTIONS',
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const closeMenu = () => setIsMenuOpen(false);

  const { goToEditor } = useGoToWorkflowEditor(workflowVersion);

  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(workflowVersion, {
    disableSnackbarConfirmation: true,
    onClose: () => {
      closeMenu();
      enqueueSnackbar(t('COPYING'), {
        variant: 'info',
      });
    },
    onStart: () => setLastEditedWorkflowVersionId?.(workflowVersion.versionId),
  });

  const { renameWorkflow, RenameDialog } = useRenameWorkflow(workflowVersion, {
    onComplete: closeMenu,
    onStart: () => setLastEditedWorkflowVersionId?.(workflowVersion.versionId),
  });

  const { deleteWorkflowVersion, DeleteDialog } = useDeleteWorkflowV2(workflowVersion, {
    onComplete: closeMenu,
    onStart: () => setLastEditedWorkflowVersionId?.(workflowVersion.versionId),
  });

  const handleIconButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsMenuOpen(true);
    event.stopPropagation();
  };

  const openVersionNotes = () => {
    setIsVersionNotesModalOpen(true);
  };

  return (
    <div ref={containerRef}>
      <IconButton onClick={handleIconButtonClick} icon={MoreVertOutlined} variant="outline" color={color} size={size} />
      <Menu anchorEl={containerRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Tooltip
          {...TOOLTIP_PROPS}
          title={isLive ? t('TOOLTIPS.RENAME_ACTIVE') : t('TOOLTIPS.RENAME_ARCHIVED')}
          disableHoverListener={isDraft}
        >
          <Stack>
            <MenuItem onClick={() => renameWorkflow()} disabled={!isDraft} startIcon={EditOutlined}>
              {t('RENAME')}
            </MenuItem>
          </Stack>
        </Tooltip>
        {!isLocked && (
          <Stack>
            <MenuItem onClick={goToEditor} disabled={isDuplicatingWorkflow} startIcon={BuildOutlined}>
              {t('EDIT')}
            </MenuItem>
          </Stack>
        )}
        <Stack>
          <MenuItem
            disabled={isDuplicatingWorkflow}
            onClick={() => duplicateWorkflow()}
            startIcon={ContentCopyOutlined}
          >
            {t('CREATE_COPY')}
          </MenuItem>
        </Stack>
        {isLocked && (
          <Stack>
            <MenuItem onClick={() => openVersionNotes()} startIcon={DescriptionOutlined}>
              {t('VERSION_NOTES')}
            </MenuItem>
          </Stack>
        )}
        <Tooltip
          {...TOOLTIP_PROPS}
          title={isLive ? t('TOOLTIPS.DELETE_ACTIVE') : t('TOOLTIPS.DELETE_ARCHIVED')}
          disableHoverListener={isDraft}
        >
          <Stack>
            <MenuItem
              startIcon={DeleteOutline}
              onClick={() => deleteWorkflowVersion()}
              iconColor={!isDraft ? 'inherit' : palette.error.color1}
              disabled={!isDraft}
            >
              <Text color={!isDraft ? 'inherit' : 'error1'} variant="body-regular">
                {t('DELETE')}
              </Text>
            </MenuItem>
          </Stack>
        </Tooltip>
        <SetAsLiveButton
          liveWorkflowVersion={liveWorkflowVersion}
          workflowVersion={workflowVersion}
          color={color}
          setIsMenuOpen={setIsMenuOpen}
        />
      </Menu>
      {RenameDialog}
      {DeleteDialog}
      <VersionNotesDialog
        workflowVersion={workflowVersion}
        isOpen={isVersionNotesModalOpen}
        onClose={() => setIsVersionNotesModalOpen(false)}
      />
    </div>
  );
};
