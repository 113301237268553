import { PredefinedFormDef } from '../shared/types';
import { NegotiationOfferForm } from './NegotiationOfferForm';
import { zodSchema, FormData, DEFAULT_OFFER_LIMIT } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/offers\/negotiation\/v\d+$/,
  component: NegotiationOfferForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    maxOffers: getValue('maxOffers', DEFAULT_OFFER_LIMIT),
    baselineOffer: getValue('baselineOffer'),
    negotiationOffers: getValue('negotiationOffers', { instances: [] }),
  }),
  transformOutput: ({ negotiationOffers }): Pick<FormData, 'negotiationOffers'> => {
    return {
      negotiationOffers: {
        instances: negotiationOffers.instances.map((offer, id) => ({
          id: `${id}`,
          ...offer,
          // clear fields that are not relevant for rolling offers
          endDate: offer.rollingFrequency === 'NO' ? offer.endDate : undefined,
          autoRenewalDate: offer.rollingFrequency === 'NO' ? offer.autoRenewalDate : undefined,
          autoRenewalWaived: offer.rollingFrequency === 'NO' ? offer.autoRenewalWaived : undefined,
        })),
      },
    };
  },
};
