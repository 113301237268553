import type { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import { Divider, Text, Link } from '@verticeone/design-system';

import { NegotiatedCost } from './costsAndSavings/NegotiatedCost';
import { BaselineCost } from './costsAndSavings/BaselineCost';
import { TotalSavings } from './costsAndSavings/TotalSavings';
import { BreakdownSavingsVsInitial } from './costsAndSavings/BreakdownSavingsVsInitial';
import { BreakdownAdditionalSavings } from './costsAndSavings/BreakdownAdditionalSavings';
import { BreakdownFutureSavings } from './costsAndSavings/BreakdownFutureSavings';
import { BreakdownScopeReductionSavings } from './costsAndSavings/BreakdownScopeReductionSavings';
import { PropsWithContract } from './types';
import { ContractStageChip } from '@vertice/core/src/modules/saas/contract/components/ContractStageChip';
import { Lifecycle } from './Lifecycle/Lifecycle';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { getTotalSavings } from '@vertice/core/src/modules/saas/savings/computed';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

export const LinkedContractContent: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const contractId = contract.record.contractId;
  const contractAccountId = contract.record.accountId;

  const isSameAccount = contractAccountId === accountId;
  const params = contractId && contractAccountId ? { contractId, account_id: contractAccountId } : undefined;
  const linkedContractPath = params ? generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, params) : undefined;

  const totalSavings = getTotalSavings(contract);

  return (
    <Stack divider={<Divider />}>
      <Stack gap={4} padding={4}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={2}>
            <VendorLogo vendorId={contract.parts.contractual?.vendor?.vendorId} />
            <Text variant="heading" size="M" color="text1">
              {contract.parts.contractual?.vendor?.vendorName}
            </Text>
            <ContractStageChip stage={contract.materialized?.simplifiedStage} />
          </Stack>
          {linkedContractPath && (
            <Link
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              withArrow
              target={isSameAccount ? '_self' : '_blank'}
              component={RouterLink}
              to={linkedContractPath}
            >
              {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.VIEW_CONTRACT')}
            </Link>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" padding={4}>
        <BaselineCost contract={contract} />
        <NegotiatedCost contract={contract} />
        <TotalSavings contract={contract} />
      </Stack>
      {totalSavings === 0 ? null : (
        <Stack gap={4} padding={4}>
          <Text variant="caption" size="S" color="text1">
            {t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS_BREAKDOWN')}
          </Text>
          <Stack direction="row">
            <BreakdownSavingsVsInitial contract={contract} />
            <BreakdownAdditionalSavings contract={contract} />
            <BreakdownFutureSavings contract={contract} />
            <BreakdownScopeReductionSavings contract={contract} />
          </Stack>
        </Stack>
      )}
      <Lifecycle contract={contract} />
    </Stack>
  );
};
