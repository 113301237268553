import React from 'react';
import { Box } from '@mui/material';
import LoadableAWSPageWrapper, { LoadableAWSPageWrapperProps } from 'pages/Cloud/LoadableAWSPageWrapper';
import { Tabs } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { Tab } from '@verticeone/design-system';
import { TabsBottomLineWrapper } from '@verticeone/design-system';

type CloudRIOLayoutProps = React.PropsWithChildren & {
  title: string;
  breadcrumbs?: {
    label: string;
    to?: string;
  }[];
  activeTab?: string;
  tabs?: {
    value: string;
    label: string;
    onClick?: () => void;
  }[];
  getCanBeViewed: LoadableAWSPageWrapperProps['getCanBeViewed'];
};

const LayoutInner = ({ activeTab, tabs, children }: CloudRIOLayoutProps) => (
  <>
    {tabs && (
      <TabsBottomLineWrapper>
        <Tabs variant="outlined" value={activeTab} color={AWS_BRAND_COLOR}>
          {tabs.map(({ value, label, onClick }) => (
            <Tab key={value} value={value} label={label} onClick={onClick} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>
    )}
    <Box position="relative">{children}</Box>
  </>
);

const Layout = (props: CloudRIOLayoutProps) => {
  const { getCanBeViewed } = props;
  return (
    <LoadableAWSPageWrapper getCanBeViewed={getCanBeViewed}>
      <LayoutInner {...props} />
    </LoadableAWSPageWrapper>
  );
};

export default Layout;
