import { useTranslation } from 'react-i18next';

import { SlackDialog } from '../../SlackDialog';

type UnlinkUserDialogProps = {
  isOpened: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
};

export const UnlinkUserDialog = (props: UnlinkUserDialogProps) => {
  const { t } = useTranslation();

  return (
    <SlackDialog
      header={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.UNLINK_DIALOG_HEADER')}
      contentText={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.UNLINK_DIALOG_TEXT')}
      buttonLabel={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.UNLINK_DIALOG_BUTTON')}
      {...props}
    />
  );
};
