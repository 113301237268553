import React from 'react';
import { Control, FieldPath, FieldValues, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@verticeone/design-system';

type TestConnectionButtonProps<T extends FieldValues = FieldValues> = {
  validateFields: Array<FieldPath<T>>;
  control: Control<T>;
} & ButtonProps;

const TestConnectionButton = <T extends FieldValues>({
  control,
  validateFields,
  ...buttonProps
}: TestConnectionButtonProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM.TEST_CONNECTION_URL' });
  const fieldValues = useFormState({ control, name: validateFields });

  return (
    <Button variant="outline" size="M" color="primary" disabled={!fieldValues.isValid} {...buttonProps}>
      {t('TEST_BUTTON')}
    </Button>
  );
};

export default TestConnectionButton;
