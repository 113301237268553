import { SvgIconComponent } from '@mui/icons-material';
import React, { FunctionComponent, SVGProps } from 'react';
import { IconWrapper } from '@verticeone/design-system';
import { Variant } from './types';
import { useStatCardColorByVariant } from './utils';
import { Stack, styled } from '@mui/material';

type StyledIconWrapperProps = {
  $bgColor: string;
};

export const StyledIconWrapper = styled(Stack)<StyledIconWrapperProps>(({ theme, $bgColor }) => ({
  display: 'flex',
  width: theme.spacing(9),
  height: theme.spacing(9),
  backgroundColor: `${$bgColor}0D`, // opacity 5%
  borderRadius: theme.spacing(3),
  padding: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
}));

type StatCardIconProps = {
  icon: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
  variant: Variant;
};

const StatCardIcon = ({ icon, variant }: StatCardIconProps) => {
  const { bgColor, iconColor } = useStatCardColorByVariant(variant);

  return (
    <StyledIconWrapper $bgColor={bgColor}>
      <IconWrapper icon={icon} htmlColor={iconColor} size="XL" />
    </StyledIconWrapper>
  );
};

export default StatCardIcon;
