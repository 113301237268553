import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '../../../account/AccountContext';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { isCurrentRequest, isTaskRef, parseWorkflowInstanceRef } from '../../../../hooks/workflows/refUtils';
import { useGetWorkflowInstanceQuery } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';

export const useWorkflowPassedTransitions = (requestId?: string) => {
  const { accountId } = useAccountContext();

  const { data: requestData } = useGetRequestQuery(
    {
      accountId,
      requestId: requestId!,
    },
    { skip: !requestId }
  );

  const request = requestData?.request;

  const workflowInstanceId = request?.executionRef
    ? parseWorkflowInstanceRef(request?.executionRef).workflowInstanceId
    : undefined;

  const { data: instanceData, refetch: refetchInstance } = useGetWorkflowInstanceQuery(
    {
      accountId,
      workflowInstanceId: workflowInstanceId!,
    },
    {
      skip: !workflowInstanceId,
    }
  );

  useNotificationWebSocketSubscription({
    filter: (msg) => isCurrentRequest(workflowInstanceId!)(msg) || isTaskRef(msg),
    callback: () => {
      void refetchInstance();
    },
  });

  return {
    passedTransitions: instanceData?.passedTransitions ?? [],
  };
};
