import { Stack } from '@mui/material';
import { testProps } from '@verticeone/design-system';

import { useTaskContext } from '../TaskContext';
import { TaskDescription } from './TaskDescription';
import { TaskDateDetailRow, RequestDetailRow, AssigneeDetailRow } from './components';

export const TaskDrawerInfo = () => {
  const { taskOverview, task } = useTaskContext();

  return (
    <Stack gap={4} padding={6} overflow="auto" {...testProps('taskDrawerContent')}>
      <Stack direction="row" width="100%" gap={4}>
        <Stack gap={4} width="50%">
          <AssigneeDetailRow task={task} taskOverview={taskOverview} />
          <RequestDetailRow taskOverview={taskOverview} />
        </Stack>
        <Stack gap={4}>
          <TaskDateDetailRow dueDate={task.dueDate} closedAt={task.closedAt} status={task.status} />
        </Stack>
      </Stack>
      <TaskDescription description={taskOverview?.task.description} />
    </Stack>
  );
};
