import { Dialog, DialogHeader, DialogContent, DialogText, DialogActions } from '@verticeone/design-system';
import { Button, IconButton, Divider } from '@verticeone/design-system';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import VendorSelect from '../../../../../vendor/VendorSelect';
import { Vendor } from '../../../../../vendor/types';
import { ContractSelect } from '../../../../../saas/contract/components/ContractSelect/ContractSelect';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';
import { useServiceCardsV1 } from '../../../../service/hooks/useServiceCardsV1';
import {
  isSaasNewPurchaseServiceRef,
  isSaasRenewalServiceRef,
  isGenericNewPurchaseServiceRef,
  isGenericRenewalServiceRef,
  isSaasRequestForProposal,
} from '../../../../../../hooks/workflows/refUtils';
import { contractVendorToVendor } from '../../../../../vendor/utils';
import { ContractOption } from '../../../../../saas/contract/components/ContractSelect/types';
import { ProgressDialogContent } from './ProgressDialogContent';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { FEATURES } from '../../../../../features/constants';
import { mapCostModelProductsToRenewalProducts } from '../../../../utils';
import { TextField } from '@verticeone/design-system';
import { useFeatures } from '../../../../../features/useFeatures';
import { useDefaultCreateRequests } from '../../../../hooks/useCreateWorkflowRequest';
import ToBeNegotiatedByConfirmation from '../../../ToBeNegotiatedByConfirmation/ToBeNegotiatedByConfirmation';

type CreateRequestDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onReset: () => void;
  autoStart?: boolean;
  defaultVendor?: Vendor;
  defaultContract?: Contract;
};

type DialogState =
  | 'INITIAL'
  | 'PURCHASE'
  | 'RENEWAL'
  | 'PROGRESS'
  | 'GENERIC_PURCHASE'
  | 'GENERIC_RENEWAL'
  | 'REQUEST_FOR_PROPOSAL';
const MAX_TITLE_LENGTH = 30;

const CreateRequestDialogInstance: FC<CreateRequestDialogProps> = ({
  open,
  setOpen,
  onReset,
  defaultVendor,
  defaultContract,
  autoStart,
}) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { getFeature, getFeatureProperty } = useFeatures();
  const paywallFeature = getFeature(FEATURES.INTELLIGENT_WORKFLOWS_PAYWALL);
  const requestForProposalEnabled = getFeatureProperty<boolean>(
    FEATURES.INTELLIGENT_WORKFLOWS,
    'requestForProposalsWorkflow'
  );

  const [dialogState, setDialogState] = useState<DialogState>(() => {
    if (defaultContract && defaultContract.classification?.category === 'SAAS') {
      return 'RENEWAL';
    } else if (defaultContract && defaultContract.classification?.category === 'OTHER') {
      return 'GENERIC_RENEWAL';
    } else if (defaultVendor) {
      return 'PURCHASE';
    } else {
      return 'INITIAL';
    }
  });

  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(defaultVendor ?? null);
  const [purchaseTitle, setPurchaseTitle] = useState<string>('');
  const [selectedContract, setSelectedContract] = useState<ContractOption | null>(
    defaultContract
      ? {
          contract: defaultContract,
          id: defaultContract.record.contractId,
        }
      : null
  );
  const isInInitialState = dialogState === 'INITIAL';

  const {
    createNewPurchaseRequest,
    createNewGenericPurchaseRequest,
    createGenericRenewalRequest,
    createNewRequestForProposalRequest,
    createRenewalRequest,
    isCreatingNewRequest,
    createdNewRequestId,
    createdNewRequestTaskId,
  } = useDefaultCreateRequests();

  const isReady =
    dialogState === 'PURCHASE' ||
    (['RENEWAL', 'GENERIC_RENEWAL'].includes(dialogState) && selectedContract) ||
    ((dialogState === 'GENERIC_PURCHASE' || dialogState === 'REQUEST_FOR_PROPOSAL') &&
      purchaseTitle.length > 0 &&
      purchaseTitle.length <= MAX_TITLE_LENGTH);

  const serviceCards = useServiceCardsV1({
    filter: (service) =>
      isSaasNewPurchaseServiceRef(service.urn) ||
      isSaasRenewalServiceRef(service.urn) ||
      (requestForProposalEnabled && isSaasRequestForProposal(service.urn)) ||
      (isGenericNewPurchaseServiceRef(service.urn) && paywallFeature?.properties?.nonSaasRequests) ||
      (isGenericRenewalServiceRef(service.urn) && paywallFeature?.properties?.nonSaasRequests),
    onClick: (serviceRef) => {
      if (isSaasNewPurchaseServiceRef(serviceRef)) {
        setDialogState('PURCHASE');
      }
      if (isSaasRenewalServiceRef(serviceRef)) {
        setDialogState('RENEWAL');
      }
      if (isGenericNewPurchaseServiceRef(serviceRef)) {
        setDialogState('GENERIC_PURCHASE');
      }
      if (isGenericRenewalServiceRef(serviceRef)) {
        setDialogState('GENERIC_RENEWAL');
      }
      if (isSaasRequestForProposal(serviceRef)) {
        setDialogState('REQUEST_FOR_PROPOSAL');
      }
    },
  });

  const vendorName =
    (selectedVendor ? selectedVendor.name : selectedContract?.contract.parts.contractual?.vendor?.vendorName) ?? '';

  const handleCreateRequest = useCallback(
    () =>
      setDialogState((state) => {
        switch (state) {
          case 'PROGRESS':
            return state;
          case 'GENERIC_PURCHASE':
            void createNewGenericPurchaseRequest(purchaseTitle);
            break;
          case 'GENERIC_RENEWAL':
            if (selectedContract && selectedContract?.contract?.parts?.contractual?.vendor) {
              void createGenericRenewalRequest({
                contractId: selectedContract.id,
                department: selectedContract.contract.classification?.departmentId,
                contractCurrency: selectedContract.contract.parts.contractual?.financial?.baseCurrency,
                vendor: contractVendorToVendor(selectedContract.contract.parts.contractual.vendor),
              });
            }
            break;
          case 'PURCHASE':
            void createNewPurchaseRequest(selectedVendor ?? undefined);
            break;
          case 'REQUEST_FOR_PROPOSAL':
            void createNewRequestForProposalRequest(purchaseTitle);
            break;
          case 'RENEWAL':
            const contractVendor = selectedContract?.contract?.parts?.contractual?.vendor;
            if (selectedContract && selectedContract.id && contractVendor) {
              const products = mapCostModelProductsToRenewalProducts(
                selectedContract.contract.parts.model?.cost?.configuration?.negotiated
              );

              void createRenewalRequest({
                vendor: contractVendorToVendor(contractVendor),
                products: products,
                contractId: selectedContract.id,
                department: selectedContract.contract.classification?.departmentId,
                contractCurrency: selectedContract.contract.parts.contractual?.financial?.baseCurrency,
              });
            }
            break;
        }
        return 'PROGRESS';
      }),
    [
      selectedVendor,
      selectedContract,
      createNewGenericPurchaseRequest,
      createGenericRenewalRequest,
      purchaseTitle,
      createNewPurchaseRequest,
      createRenewalRequest,
      createNewRequestForProposalRequest,
    ]
  );

  useEffect(() => {
    if (autoStart) {
      handleCreateRequest();
    }
  }, [autoStart, handleCreateRequest]);

  const goToRequestDetails = useCallback(
    (openDrawerForTask?: string) => {
      if (createdNewRequestId) {
        navigate(
          routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL,
          {
            requestId: createdNewRequestId,
          },
          { search: openDrawerForTask ? { taskId: openDrawerForTask } : undefined }
        );
      }
    },
    [createdNewRequestId, navigate, routes]
  );

  const stepBack = () => {
    setDialogState('INITIAL');
    setSelectedContract(null);
  };

  useEffect(() => {
    if (createdNewRequestTaskId) {
      goToRequestDetails(createdNewRequestTaskId);
    }
  }, [createdNewRequestId, createdNewRequestTaskId, goToRequestDetails]);

  const showCloseButton = isInInitialState || (dialogState === 'PROGRESS' && !isCreatingNewRequest);

  return (
    <Dialog
      open={open}
      setOpen={showCloseButton ? setOpen : undefined}
      size="M"
      width={serviceCards.props.children.length === 3 ? 1153 : 783}
      onClose={() => dialogState !== 'PROGRESS' && setOpen(false)}
    >
      <DialogHeader>
        {dialogState !== 'PROGRESS' && (
          <Stack gap={4} direction="row" alignItems="center">
            {!isInInitialState && (
              <IconButton variant="outline" icon={ChevronLeftOutlined} onClick={() => stepBack()} />
            )}
            {t(`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.HEADING_${dialogState}`)}
          </Stack>
        )}
      </DialogHeader>
      {dialogState !== 'PROGRESS' && <Divider />}
      <DialogContent>
        <Stack gap={4} minHeight={isInInitialState ? 300 : 219}>
          {/* 219 because divider is 1px, 80px is the footer (actions) */}
          {dialogState !== 'PROGRESS' && (
            <DialogText variant="body-regular">
              <Trans
                i18nKey={`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.DESCRIPTION_${dialogState}`}
                components={{ break: <br /> }}
              />
            </DialogText>
          )}

          {isInInitialState && (
            <Stack direction="row" gap={2} flexWrap="wrap">
              {serviceCards}
            </Stack>
          )}
          {dialogState === 'PURCHASE' && (
            <VendorSelect
              value={selectedVendor}
              onChange={(v) => setSelectedVendor(v)}
              isDisabled={isCreatingNewRequest}
              inlineEnabled
            />
          )}
          {dialogState === 'REQUEST_FOR_PROPOSAL' && (
            <>
              <TextField
                disabled={isCreatingNewRequest}
                value={purchaseTitle}
                onChange={(e) => setPurchaseTitle(e.target.value)}
                error={purchaseTitle.length > MAX_TITLE_LENGTH}
                helperText={
                  purchaseTitle.length > MAX_TITLE_LENGTH
                    ? `${t('VALIDATOR.ERRORS.TOO_BIG.TITLE')}, ${t('VALIDATOR.ERRORS.TOO_BIG.DESCRIPTION', {
                        maximumLength: MAX_TITLE_LENGTH,
                      })}`
                    : null
                }
              />
              <DialogText variant="body-regular">
                <Trans
                  i18nKey={`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.NOTE_${dialogState}`}
                  components={{ break: <br /> }}
                />
              </DialogText>
            </>
          )}
          {dialogState === 'RENEWAL' && (
            <Stack gap={6}>
              <ContractSelect
                isDisabled={isCreatingNewRequest}
                value={selectedContract}
                onChange={(c) => setSelectedContract(c)}
                optionsPermissionFilter={(permissions) => permissions.userCanExecuteContract}
                allowedCategories={['SAAS']}
              />
              {selectedContract ? <ToBeNegotiatedByConfirmation contract={selectedContract.contract} /> : null}
            </Stack>
          )}
          {dialogState === 'GENERIC_RENEWAL' && (
            <ContractSelect
              isDisabled={isCreatingNewRequest}
              value={selectedContract}
              onChange={(c) => setSelectedContract(c)}
              optionsPermissionFilter={(permissions) => permissions.userCanExecuteContract}
              allowedCategories={['OTHER']}
            />
          )}
          {dialogState === 'GENERIC_PURCHASE' && (
            <>
              <TextField
                disabled={isCreatingNewRequest}
                value={purchaseTitle}
                onChange={(e) => setPurchaseTitle(e.target.value)}
                error={purchaseTitle.length > MAX_TITLE_LENGTH}
                helperText={
                  purchaseTitle.length > MAX_TITLE_LENGTH
                    ? `${t('VALIDATOR.ERRORS.TOO_BIG.TITLE')}, ${t('VALIDATOR.ERRORS.TOO_BIG.DESCRIPTION', {
                        maximumLength: MAX_TITLE_LENGTH,
                      })}`
                    : null
                }
              />
              <DialogText variant="body-regular">
                <Trans
                  i18nKey={`INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.NOTE_${dialogState}`}
                  components={{ break: <br /> }}
                />
              </DialogText>
            </>
          )}
          {dialogState === 'PROGRESS' && (
            <ProgressDialogContent
              isCreatingRequest={isCreatingNewRequest}
              vendorName={vendorName || purchaseTitle}
              onGoToRequestDetails={() => goToRequestDetails()}
              onCreateNewRequest={onReset}
              requestType={selectedVendor || purchaseTitle ? 'PURCHASE' : 'RENEWAL'}
            />
          )}
        </Stack>
      </DialogContent>
      <Divider />
      {['PURCHASE', 'RENEWAL', 'GENERIC_PURCHASE', 'GENERIC_RENEWAL', 'REQUEST_FOR_PROPOSAL'].includes(dialogState) && (
        <DialogActions>
          <Button
            variant="outline"
            disabled={isCreatingNewRequest}
            onClick={() => setOpen(false)}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('DIALOG.BUTTONS.CANCEL')}
          </Button>
          <Button
            variant="solid"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            onClick={handleCreateRequest}
            disabled={isCreatingNewRequest || !isReady}
            isLoading={isCreatingNewRequest}
          >
            {t('INTELLIGENT_WORKFLOWS.CREATE_REQUEST_DIALOG.START_REQUEST_BUTTON')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export const CreateRequestDialog = ({ autoStart, ...restProps }: Omit<CreateRequestDialogProps, 'onReset'>) => {
  const [instanceNumber, setInstanceNumber] = useState(0);
  const isFirstInstance = instanceNumber === 0;
  return (
    <CreateRequestDialogInstance
      autoStart={isFirstInstance ? autoStart : undefined}
      {...restProps}
      key={instanceNumber}
      onReset={() => setInstanceNumber(instanceNumber + 1)}
    />
  );
};
