import React, { PropsWithChildren, useState } from 'react';
import { AccountTreeOutlined, FilterAltOutlined } from '@mui/icons-material';
import { ChartWrapper } from '../ChartCard';
import { useAverageRequestTimesContext } from './AverageRequestTimesContext';
import { CreateRequestDialog } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/CreateRequestDialog';
import { EmptyState } from '../../types';

type EmptyGraphWrapperProps = EmptyState & PropsWithChildren;

const EmptyGraphWrapper = ({ isEmpty, isFilteredEmpty, children }: EmptyGraphWrapperProps) => {
  const { resetFilter } = useAverageRequestTimesContext();
  const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);

  const handleClick = () => {
    setCreateRequestDialogOpen(true);
  };

  return (
    <>
      <ChartWrapper
        isEmpty={isEmpty}
        isFilteredEmpty={isFilteredEmpty}
        testId="average-request-times"
        noFilteredData={{ icon: FilterAltOutlined, onClick: resetFilter }}
        noData={{ icon: AccountTreeOutlined, onClick: handleClick }}
        tKeyPrefix="INTELLIGENT_WORKFLOWS.DASHBOARD.AVERAGE_REQUEST_TIMES"
      >
        {children}
      </ChartWrapper>
      {isEmpty && (
        <CreateRequestDialog
          open={createRequestDialogOpen}
          setOpen={(open) => setCreateRequestDialogOpen(open)}
          key={createRequestDialogOpen ? 'open' : 'closed'}
        />
      )}
    </>
  );
};

export default EmptyGraphWrapper;
