import { FieldDefPreset } from '../types';
import { DataSourceDef, dataSources } from './dataSources';
import { fieldDef, defaultFieldMetadata } from './fieldDef';
import { DataSourcedFieldType } from './types';

export const createDataSourcedFieldPreset = (dataSource: DataSourceDef): FieldDefPreset<DataSourcedFieldType> => ({
  key: `${fieldDef.type}.${dataSource.name}`,
  fieldType: fieldDef.type,
  nameI18nKey: dataSource.labelI18nKey,
  metadataDefaultValues: {
    ...defaultFieldMetadata,
    source: dataSource.source,
    presenterType: dataSource.defaultPresenterType ?? 'DROPDOWN',
  },
});

export const fieldPresets: FieldDefPreset<DataSourcedFieldType>[] = dataSources.map(createDataSourcedFieldPreset);
