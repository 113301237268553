import { Button, Dialog, DialogActions, Text } from '@verticeone/design-system';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Template } from '../../types';
import { getThumbnailUrl } from '../../thumbnails/utils';

type RequestTemplateDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  template: Template | null;
};

const Fade = styled(Stack)({
  background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
  zIndex: 100,
  height: 24,
  transform: 'translateY(25%)',
  position: 'absolute',
  width: '100%',
  bottom: 0,
});

export const RequestTemplateDialog = ({ isOpen, onClose, template }: RequestTemplateDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TEMPLATES' });

  if (!template) {
    return null;
  }

  const templateImage = getThumbnailUrl(template.thumbnail);

  return (
    <Dialog open={isOpen} onClose={onClose} size="M">
      <Stack position="relative">
        <img src={templateImage} alt={template.name} />
        <Fade />
      </Stack>
      <Stack direction="column" gap={4} alignItems="center" textAlign="center" m={6} mb={0}>
        <Text variant="heading" size="M" color="text1">
          {t('DIALOG.TEMPLATE_REQUESTED')}
        </Text>
        <Stack gap={1}>
          <Text variant="body-regular" size="S" color="text1">
            {t('DIALOG.THANK_YOU_MESSAGE')}
          </Text>
          <Text variant="body-regular" size="S" color="text1">
            {t('DIALOG.OUR_TEAM_WILL_REACH_OUT')}
          </Text>
        </Stack>
      </Stack>
      <DialogActions>
        <Button fullWidth variant="ghost" color="primary" size="M" onClick={onClose}>
          {t('DIALOG.CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
