import { useMemo } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isContractExisting, isOneOffContract } from '../computed';
import useContractFormDataSelector from './useContractFormDataSelector';
import { useFeatures } from '../../../features/useFeatures';
import { FEATURES } from '../../../features/constants';

export type UseCanSeeConcessionTabParams = {
  contract: Contract;
};

export const useCanSeeConcessionTab = ({ contract }: UseCanSeeConcessionTabParams) => {
  const { isEnabled } = useFeatures();
  const isOneOff = useContractFormDataSelector(isOneOffContract);

  return useMemo(() => {
    if (!contract) {
      return false;
    }
    const nonSaasFeatureEnabled = isEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG);
    return !isContractExisting(contract) && !(isOneOff && nonSaasFeatureEnabled);
  }, [contract, isOneOff, isEnabled]);
};
