import { Stack, useTheme } from '@mui/material';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import IntegrationSetupContainer from './IntegrationSetupContainer';
import { OutletContextType } from '../types';

type IntegrationSetupProps = {
  breadcrumb: {
    label: string;
    icon: ReactElement;
  };
  actionButton: ReactNode;
  stepContent: ReactNode;
};

export const IntegrationSetup: FC<IntegrationSetupProps> = ({
  breadcrumb: { label, icon },
  actionButton,
  stepContent,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { setHeaderActions, setBreadcrumbsItems } = useOutletContext<OutletContextType>();

  useEffect(() => {
    setBreadcrumbsItems([
      {
        label: label,
        startAdornment: icon,
      },
    ]);

    if (actionButton) {
      setHeaderActions(actionButton);
    }
    return () => {
      setHeaderActions([]);
      setBreadcrumbsItems([]);
    };
  }, [actionButton, icon, label, setBreadcrumbsItems, setHeaderActions, t]);

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <IntegrationSetupContainer>{stepContent}</IntegrationSetupContainer>
    </Stack>
  );
};
