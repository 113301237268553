import useDashboardWidgetData from '../../useDashboardWidgetData';
import { getTableData } from '../../utils';
import { groupBy, keyBy, range } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useChartContext } from '../ChartCard';
import { useMemo } from 'react';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { EmptyState, LoadableData } from '../../types';

type SpendType = 'new_purchase' | 'renewal';

type TableData = {
  columns: string[];
  types: string[];
  data: string[][];
};

type Item = {
  cost: number;
  currency: string;
  spendType: string;
  month: string;
};

type TransformedItem = {
  month: string;
  new_purchase: number | null;
  renewal: number | null;
};

export type ProcuredSpendData = EmptyState &
  LoadableData<{
    values: {
      id: string;
      data: (number | null)[];
    }[];
    categories: string[];
    currency: string;
    stats: {
      totalCostOfNewPurchases: number;
      totalCostOfRenewals: number;
      total: number;
    };
  }>;

const fillMissingMonthsData = <DataType extends { month: string }>(
  data: Array<DataType>,
  startDate: Dayjs,
  numberOfMonths = 6,
  fallbackData: Partial<DataType>
): Array<DataType> => {
  const dataByDate = keyBy(data, (p) => dayjs(p.month).format('YYYY-MM-DD'));

  return range(numberOfMonths)
    .map((i) => {
      const monthStartStr = startDate.subtract(i, 'month').format('YYYY-MM-DD');

      return (
        dataByDate[monthStartStr] ?? {
          month: monthStartStr,
          ...fallbackData,
        }
      );
    })
    .sort((a, b) => dayjs(a.month).diff(dayjs(b.month)));
};

const transformTableData = (data: Item[]) => {
  const groupedByMonth = groupBy(data, 'month');

  return Object.keys(groupedByMonth).map((month) => {
    return groupedByMonth[month].reduce(
      (acc: TransformedItem, item) => {
        acc[item.spendType.toLowerCase() as SpendType] = item.cost;
        return acc;
      },
      { month, new_purchase: null, renewal: null }
    );
  });
};

const calculateProcuredSpendData = ({ widgetData, months }: { widgetData: Widget | undefined; months: number }) => {
  const dashboardData = widgetData?.data as TableData;

  const tableData = dashboardData
    ? (
        getTableData(dashboardData, {
          cost: 'cost',
          currency: 'currency',
          spendType: 'spendType',
          month: 'month',
        }) as Item[]
      ).map((item) => ({
        ...item,
        month: dayjs(item.month).startOf('month').format('YYYY-MM-DD'),
      }))
    : [];

  const transformedData = transformTableData(tableData) as TransformedItem[];
  const startDate = dayjs().startOf('month').subtract(1, 'month');

  const rangeData = fillMissingMonthsData<TransformedItem>(transformedData, startDate, months, {
    new_purchase: null,
    renewal: null,
  });

  const values = [
    {
      id: 'new_purchase',
      data: rangeData.map((data) => data.new_purchase),
    },
    {
      id: 'renewal',
      data: rangeData.map((data) => data.renewal),
    },
  ];

  const isEmpty = !tableData || !tableData.length || tableData.every((item) => item.cost === 0);
  const isFilteredEmpty = !isEmpty && (!rangeData || rangeData.every((item) => !item.new_purchase && !item.renewal));

  const totalCostOfNewPurchases = rangeData.reduce((acc, data) => acc + (data.new_purchase || 0), 0);
  const totalCostOfRenewals = rangeData.reduce((acc, data) => acc + (data.renewal || 0), 0);
  const total = totalCostOfNewPurchases + totalCostOfRenewals;

  return {
    data: {
      values,
      categories: rangeData.map((data) => data.month),
      currency: tableData[0]?.currency || 'USD',
      stats: {
        totalCostOfNewPurchases,
        totalCostOfRenewals,
        total,
      },
    },
    isEmpty,
    isFilteredEmpty,
  };
};

const useProcuredSpendData = (): ProcuredSpendData => {
  const { data: widgetData, error, isFetching } = useDashboardWidgetData('ProcuredSpend');
  const { months } = useChartContext();

  const memoizedData = useMemo(() => calculateProcuredSpendData({ widgetData, months }), [widgetData, months]);

  return {
    ...memoizedData,
    error,
    isFetching,
  };
};

export default useProcuredSpendData;
