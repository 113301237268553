import { Stack } from '@mui/material';
import { Button, Divider } from '@verticeone/design-system';
import { FC } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContractDetailsForm, contractDetailsSchema } from './types';
import { FormDepartmentSelectField } from '../../../../../../departments/FormDepartmentSelectField';
import { FormNonSaasVendorSelectField } from '../../../../../../vendor/nonSaas/FormNonSaasVendorSelectField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import {
  FormRadioCardField,
  RadioCardDescription,
  RadioCardTitle,
} from '../../../../../../forms/fields/FormRadioCardField/FormRadioCardField';

export type ContractDetailsFormStepProps = {
  defaultValues?: DeepPartial<ContractDetailsForm>;
  onSubmit: (values: ContractDetailsForm) => Promise<void>;
};

const ContractDetailFormEntry = createTypedFormEntry<ContractDetailsForm>();

export const ContractDetailsFormStep: FC<ContractDetailsFormStepProps> = (props) => {
  const { onSubmit, defaultValues } = props;
  const { t } = useTranslation();

  const formMethods = useForm<ContractDetailsForm>({
    resolver: zodResolver(contractDetailsSchema),
    defaultValues,
  });

  const { handleSubmit, formState, control } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flex="1 1 0" onSubmit={handleSubmit(onSubmit)}>
        <Stack p={6} flexGrow={1} gap={6} sx={{ overflow: 'auto' }}>
          <ContractDetailFormEntry
            required
            name="departmentId"
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            component={FormDepartmentSelectField}
          />

          <Stack direction="row" gap={2} maxHeight="min-content">
            <FormRadioCardField control={control} name="contractCategory" value="SAAS" disabled>
              <RadioCardTitle>{t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.SAAS')}</RadioCardTitle>
              <RadioCardDescription>{t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EG_HUBSPOT')}</RadioCardDescription>
            </FormRadioCardField>
            <FormRadioCardField control={control} name="contractCategory" value="OTHER" disabled>
              <RadioCardTitle>{t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EVERYTHING_ELSE')}</RadioCardTitle>
              <RadioCardDescription>
                {t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EG_OFFICE_SUPPLIES')}
              </RadioCardDescription>
            </FormRadioCardField>
          </Stack>

          <ContractDetailFormEntry
            required
            name="vendor"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR')}
            component={FormNonSaasVendorSelectField}
          />
        </Stack>

        <Divider />

        <Stack direction="row" padding={6} gap={2} justifyContent="flex-end">
          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
