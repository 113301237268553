import { FC } from 'react';
import { Stack, styled, StackProps } from '@mui/material';
import { IconWrapper, Text } from '@verticeone/design-system';

import { DnDNodeTileDraggable } from './DnDNodeTile';
import { DnDNodesGroup as DnDNodesGroupType } from './types';

const DnDNodesGroupList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const GroupIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.text.color3,
}));

type NodesGroupProps = {
  group: DnDNodesGroupType;
  component?: StackProps['component'];
};

export const DnDNodesGroup: FC<NodesGroupProps> = ({ group, component = 'div' }) => {
  const { nodes, icon, title } = group;

  return (
    <Stack component={component} gap={3}>
      <Stack direction="row" alignItems="center" gap={1}>
        <GroupIconWrapper size="S" icon={icon} />
        <Text variant="caption" size="XS" color="text3">
          {title}
        </Text>
      </Stack>
      <DnDNodesGroupList>
        {nodes.map((node) => (
          <DnDNodeTileDraggable component="li" key={node.id} node={node} />
        ))}
      </DnDNodesGroupList>
    </Stack>
  );
};
