import React, { useCallback, useMemo } from 'react';
import { Grid } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { useController } from 'react-hook-form';

import { type DesignSystemColor, getTextWidth } from '@verticeone/design-system';
import { OptionButton } from './components/OptionButton';
import { VerticalOptionButton } from './components/VerticalOptionButton';

export type ToggleButtonOption = {
  value: string;
  title: string;
  label?: string;
  icon?: SvgIconComponent;
  element?: JSX.Element;
  color?: DesignSystemColor;
  isLoading?: boolean;
  isActive?: boolean;
  indentSize?: number;
};

export type ToggleButtonFieldProps = {
  name: string;
  options: ToggleButtonOption[];
  optionsFilter?: (selected: boolean, disabled?: boolean) => boolean;
  onClick?: (value: string) => void;
  required?: boolean;
  buttonHeight?: number;
  compact?: boolean;
  color?: DesignSystemColor;
  disabled?: boolean;
  inGrid?: boolean;
  resultValue?: string;
};

export const FormToggleButtonField = (props: ToggleButtonFieldProps) => {
  const { name, options, required, disabled, optionsFilter, inGrid, resultValue } = props;

  const {
    field: { onChange, value },
  } = useController({ name, rules: { required } });

  const filter = useCallback(
    (option: ToggleButtonOption) => (optionsFilter ? optionsFilter(value === option.value, disabled) : true),
    [value, disabled, optionsFilter]
  );

  const maxTextWidth = useMemo(() => {
    return Math.max(
      ...options
        .filter(filter)
        .filter((option) => !!option.icon || !!option.element)
        .map((option) =>
          getTextWidth(option.title, {
            fontName: 'Inter',
            fontSize: `${parseInt(getComputedStyle(document.documentElement).fontSize)}px`,
            fontWeight: '600',
          })
        )
    );
  }, [filter, options]);

  return (
    <Grid container direction="row" gap={2} sx={{ width: '100%' }}>
      {options.filter(filter).map((option) => {
        if (inGrid ?? true) {
          return (
            <Grid item xs key={option.value} alignItems="center">
              <OptionButton option={option} fieldProps={props} value={resultValue ?? value} onChange={onChange} />
            </Grid>
          );
        }

        return (
          <VerticalOptionButton
            key={option.value}
            option={option}
            fieldProps={props}
            value={resultValue ?? value}
            onChange={onChange}
            maxTextWidth={maxTextWidth}
          />
        );
      })}
    </Grid>
  );
};
