import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import { Dayjs } from 'dayjs';

export type Range = {
  startDate: Dayjs;
  endDate: Dayjs;
};

type TooltipData = {
  values: Record<string, number>;
  category: string | number;
  total: number;
};

type ChartContextType = {
  range: Range;
  setRange: (startDate: Dayjs, endDate: Dayjs) => void;
  resetRange: () => void;
  tooltipData: TooltipData | null;
  setTooltipData: (data: TooltipData | null) => void;
  months: number;
};

const ChartContext = createContext<ChartContextType | undefined>(undefined);

type ChartProviderProps = PropsWithChildren & {
  defaultRange: {
    startDate: Dayjs;
    endDate: Dayjs;
  };
};

export const ChartProvider = ({ children, defaultRange }: ChartProviderProps) => {
  const [range, setRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>(defaultRange);
  const [tooltipData, setTooltipData] = React.useState<TooltipData | null>(null);

  const updateRange = (startDate: Dayjs, endDate: Dayjs) => {
    setRange({ startDate, endDate });
  };

  const resetRange = () => {
    setRange(defaultRange);
  };

  const months = range?.endDate.diff(range.startDate, 'month') + 1;

  return (
    <ChartContext.Provider value={{ range, setRange: updateRange, resetRange, tooltipData, setTooltipData, months }}>
      {children}
    </ChartContext.Provider>
  );
};

export const useChartContext = (): ChartContextType => {
  const context = useContext(ChartContext);
  if (!context) {
    throw new Error('useChartContext must be used within a ChartProvider');
  }
  return context;
};
