import { Stack } from '@mui/material';
import { FC } from 'react';
import { FieldValues } from 'react-hook-form';
import FormEntry from '../../../fields/FormEntry';
import { FormFileUploadField, FormFileUploadFieldType } from '../../../fields/FormFileUploadField';
import { FileUploadFieldType } from './types';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { DynamicFormPresenterProps } from '../types';

export const FileUploadFieldPresenter: FC<DynamicFormPresenterProps<FileUploadFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  // Added field name to prefix so we can distinguish between file-uploads for different fields
  const pathPrefix = config.filePathPrefix && field?.name ? `${config.filePathPrefix}/field/${field.name}` : '';

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormFileUploadFieldType<FieldValues>>
        name={field.name}
        component={FormFileUploadField}
        componentProps={{
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
          pathPrefix: pathPrefix,
          readOnly: config.mode === 'readOnly', // Droparea won't be visible in readOnly mode
          skipData: config.mode === 'preview', // Files won't be loaded when in preview mode
          fileTransformer: config.fileTransformer,
        }}
        label={label}
        description={description}
        required={required}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
