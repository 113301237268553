import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import AccountManagerSection from './AccountManagerSection';

import { useCustomerSuccessWidget } from '@vertice/core/src/contexts/CustomerSuccessContext';
import { Alert, AlertButton, BreadcrumbsV2, PageHeader } from '@verticeone/design-system';

import { Link } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Card } from '@verticeone/design-system';

export const KnowledgeBase = () => {
  const { t } = useTranslation();

  const { zendeskEnabled, showWidget } = useCustomerSuccessWidget();

  return (
    <Stack direction="column" pb={6}>
      <PageHeader
        breadcrumb={<BreadcrumbsV2 items={[{ label: t('KNOWLEDGE_BASE.TITLE') }]} />}
        actions={undefined}
        color="primary"
      />
      <Stack px={6}>
        <Card p={8}>
          <Stack gap={8}>
            {zendeskEnabled && (
              <Alert
                variant="solid"
                title={t('KNOWLEDGE_BASE.ZENDESK_ALERT.TITLE')}
                subtitle={t('KNOWLEDGE_BASE.ZENDESK_ALERT.TEXT')}
              >
                <AlertButton color="primary" onClick={showWidget}>
                  {t('KNOWLEDGE_BASE.ZENDESK_ALERT.HELP_BUTTON')}
                </AlertButton>
              </Alert>
            )}
            <AccountManagerSection />
            <Stack direction="column" spacing={4}>
              <Text variant="heading" size="S">
                {t('KNOWLEDGE_BASE.PRODUCT_DOCUMENTATION')}
              </Text>
              <Stack direction="row" spacing={2}>
                <Text variant="body-regular" size="M">
                  {t('KNOWLEDGE_BASE.BROWSE_PRODUCT_DOCUMENTATION_IN_OUR')}
                </Text>
                <Link variant="body-bold-m" href="https://support.vertice.one/hc/en-us" target="_blank">
                  {t('KNOWLEDGE_BASE.ZENDESK_HELP_CENTRE')}
                </Link>
              </Stack>
            </Stack>
            <Grid container spacing={4} width={3 / 4}>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/21513132326161-SaaS-Purchasing"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.SAAS_PURCHASING')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/21514129892881-Cloud-Cost-Optimization"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.CLOUD_COST_OPTIMIZATION')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link href="https://support.vertice.one/hc/en-us/sections/22327430609297-Savings-FAQs" target="_blank">
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.SAVINGS_FAQ')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link href="https://support.vertice.one/hc/en-us/sections/22260673087121-Contract-FAQs" target="_blank">
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.CONTRACTS_FAQ')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link href="https://support.vertice.one/hc/en-us/sections/23603760650257-Features" target="_blank">
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.FEATURES_FAQ')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/21506408327569-Your-profile-account"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.PROFILE_N_ACCOUNT')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/21686186962705-Integrations"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.INTEGRATIONS')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/21514564144913-Trust-&-Security"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.TRUST_N_SECURITY')}
                  </Text>
                </Link>
              </Grid>
              <Grid item xs={5}>
                <Link
                  href="https://support.vertice.one/hc/en-us/categories/26005366037649-Intelligent-Workflows"
                  target="_blank"
                >
                  <Text variant="body-bold" size="M">
                    {t('KNOWLEDGE_BASE.LINKS.WORKFLOWS_FAQ')}
                  </Text>
                </Link>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};
