import { FunctionComponent, useMemo } from 'react';

import { getTaskServiceConfiguration } from '../../../../definitions/taskDefinition';
import { ProcessDefinition, TaskDefinition } from '../../../../definitionsTypes';
import { useGetCatalogResources } from '../../../../hooks/useGetCatalogResources';
import { getLastPartDiversioned } from '../../../../utils';
import { EditorDrawer } from '../../EditorDrawer';
import { JiraCreate } from './JiraCreate/JiraCreate';
import { JiraSync } from './JiraSync/JiraSync';
import { GenericIntegration } from './GenericIntegration';
import { EditServiceTaskBase } from './types';

const EditServiceTaskComponentMapping: Record<string, FunctionComponent<EditServiceTaskBase>> = {
  'service/jira/createTicket': JiraCreate,
  'service/jira/syncTicket': JiraSync,
  'service/integrations/jira/createTicket': JiraCreate,
  'service/integrations/jira/syncTicket': JiraSync,
  'service/integrations/sendOutbound': GenericIntegration,
};

type EditorConfig = {
  allowContractOwnerAssignment: boolean;
};

export type EditServiceTaskDrawerProps = {
  isOpen: boolean;
  task?: TaskDefinition;
  onClose: VoidFunction;
  onSave: (task: TaskDefinition) => void;
  onDelete: (taskId: string) => void;
  onDirty: () => void;
  editorConfig: EditorConfig;
  processDefinition: ProcessDefinition;
  workflowServiceRef?: string;
  isWorkflowCustomizationEnabled?: boolean;
};

export const EditServiceTaskDrawer = ({
  isOpen,
  task,
  processDefinition,
  isWorkflowCustomizationEnabled = false,
  ...restProps
}: EditServiceTaskDrawerProps) => {
  const { resources } = useGetCatalogResources({});

  const EditServiceTaskComponent = useMemo(() => {
    if (task) {
      const taskServiceConfiguration = getTaskServiceConfiguration(task);
      const correspondingTaskService = resources.find(
        (service) => service.urn === taskServiceConfiguration?.resourceUrn
      );

      const componentKey = getLastPartDiversioned(correspondingTaskService?.urn);
      return componentKey ? EditServiceTaskComponentMapping[componentKey] : null;
    }
  }, [resources, task]);

  return (
    <EditorDrawer open={isOpen}>
      {EditServiceTaskComponent && task && (
        <EditServiceTaskComponent
          task={task}
          resources={resources}
          processDefinition={processDefinition}
          isWorkflowCustomizationEnabled={isWorkflowCustomizationEnabled}
          {...restProps}
        />
      )}
    </EditorDrawer>
  );
};
