import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { ContractFormData } from '../../types';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { isNil } from 'lodash';
import { FormControl, Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import React from 'react';
import { useFormatDate } from '@verticeone/utils/formatting';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import { useContractContext } from '../../ContractContext';
import { isRollingRollFrequency, isTerminatedContract, rollFrequencyToMonths } from '../../computed';

export const getNextRollDate = (startDate?: string, rollFrequency?: RollFrequency, today = dayjs()) => {
  if (!startDate || !rollFrequency || !isRollingRollFrequency(rollFrequency)) {
    return undefined;
  }
  let startDateDay = dayjs(startDate);
  const endOfToday = today.endOf('day');

  const monthsMultiplier = rollFrequencyToMonths(rollFrequency);

  if (startDateDay.isAfter(endOfToday)) {
    return startDateDay.add(monthsMultiplier, 'months');
  }

  const diff = endOfToday.diff(startDateDay, 'month');
  return startDateDay.add(Math.floor(diff / monthsMultiplier) * monthsMultiplier + monthsMultiplier, 'months');
};

const NextRollDateComputedField = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const startDate = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.startDate' });
  const rollFrequency = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.rollFrequency' });
  const endDate = useWatch<ContractFormData>({ name: 'parts.contractual.lifecycle.endDate' });
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');
  const isTerminated = contractContext.fetchedContract
    ? isTerminatedContract(contractContext.fetchedContract.contract)
    : false;

  const isVisible = useIsContractFieldVisible('nextRollDate');
  if (!isVisible) return null;

  const nextRollDate = getNextRollDate(keepOnlyLocalDatePart(startDate) ?? undefined, rollFrequency ?? undefined);
  const formattedNextRollDate = isNil(nextRollDate) ? 'N/A' : formatDate(nextRollDate.toISOString());
  const formattedEndDate = isNil(endDate) ? 'N/A' : formatDate(endDate);

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor="nextRollDate"
          label={isTerminated ? t('ENTITIES.CONTRACT.LABELS.END_DATE') : t('ENTITIES.CONTRACT.LABELS.NEXT_ROLL_DATE')}
          size="XS"
        />
        <Text id="nextRollDate" variant="body-regular">
          {isTerminated ? formattedEndDate : formattedNextRollDate}
        </Text>
      </Stack>
    </FormControl>
  );
};

export default NextRollDateComputedField;
