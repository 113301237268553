import React from 'react';
import { useFormContext } from 'react-hook-form';
import { WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Content } from '@vertice/core/src/components/Dialog/Components';
import useNavigateToContractWithDelay from '../../sharedHooks/useNavigateToContractWithDelay';
import { ConfirmationPanel } from '../../SharedComponents/ConfirmationPanel';
import { SharedRenewalFormData } from '../types';

const RenewalInProgressStep: React.FC<WizardStepProps> = () => {
  const { watch } = useFormContext<SharedRenewalFormData>();
  const { newContractId, vendor } = watch();

  useNavigateToContractWithDelay(newContractId);

  return (
    <Content>
      <ConfirmationPanel
        headingTitleKey="INTAKE_FORM.EXISTING_CONTRACT_RENEWAL_IN_PROGRESS.HEADING_TITLE"
        vendorName={vendor?.name ?? undefined}
      />
    </Content>
  );
};

export default RenewalInProgressStep;
