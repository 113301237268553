import { DropDownChipButton, Menu, MenuItem } from '@verticeone/design-system';
import { BaseSyntheticEvent, useMemo, useState } from 'react';
import { xor } from 'lodash';
import { useTranslation } from 'react-i18next';
import { defaultDocumentType, documentTypes } from './constants';

type DocumentTypeSelectProps = {
  value: string[] | null;
  onChange: (value: string[]) => void;
};

export const DocumentTypeSelect = ({ value, onChange }: DocumentTypeSelectProps) => {
  const { t } = useTranslation();
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const closeMenu = () => setMenuAnchorElement(null);
  const [selectedItems, setSelectedItems] = useState<string[]>(value ?? []);

  const options = useMemo(
    () =>
      documentTypes.map((documentType) => ({ value: documentType, label: t(`SAAS.DOCUMENT_TYPES.${documentType}`) })),
    [t]
  );

  const chipLabel = useMemo(() => {
    const defaultLabel = t(`SAAS.DOCUMENT_TYPES.${defaultDocumentType}`);
    if (value && value.length) {
      const label = options.find((option) => value.includes(option.value))?.label ?? defaultLabel;
      const more = value.length > 1 ? ', ...' : '';
      return `${label}${more}`;
    }

    return defaultLabel;
  }, [t, options, value]);

  const handleItemClick = (item: string) => {
    setSelectedItems((oldSelectedItems) => xor(oldSelectedItems ?? [], [item]));
  };

  const handleClose = () => {
    if (xor(selectedItems, value).length) {
      onChange(selectedItems);
    }
    closeMenu();
  };

  return (
    <>
      <DropDownChipButton
        open={!!menuAnchorElement}
        onClick={(event: BaseSyntheticEvent) => {
          setMenuAnchorElement(event.currentTarget);
        }}
        variant={value && value.length ? 'solid' : 'ghost'}
        sx={{ flexShrink: 0 }}
      >
        {chipLabel}
      </DropDownChipButton>
      <Menu open={!!menuAnchorElement} anchorEl={menuAnchorElement} onClose={handleClose}>
        {options.map((item) => (
          <MenuItem
            key={item.value}
            selected={selectedItems?.includes(item.value)}
            onClick={() => handleItemClick(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
