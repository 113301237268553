import { useSavingsPlanUtilizationQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useDeferredQuery } from '@verticeone/utils/api';
import { useMemo } from 'react';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { SPInventoryItem } from '../../useSavingsPlanInventoryData';
import { sumBy } from 'lodash';
import { LoadableAdvanced } from '@verticeone/utils/async';
import dayjs from 'dayjs';

type SPUtilizationItem = {
  savingsPlanId: string;
  usageDay: string;
  usedCommitment: number;
  unusedCommitment: number;
  utilization: number;
  savings: number;
};

export type StatsData = {
  totalConsumption: number;
  totalSavings: number;
  avgUtilization: number;
};

export type ChartData = {
  dates: string[];
  usedCommitment: number[];
  unusedCommitment: number[];
  usedCategories: string[];
};

export type SPUtilizationData = {
  statsData?: StatsData;
  chartData?: ChartData;
};

export const useSavingsPlanUtilizationData = ({
  savingsPlanId,
}: SPInventoryItem): LoadableAdvanced<SPUtilizationData> => {
  const { accountId } = useAccountContext();

  const skip = !accountId;

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useSavingsPlanUtilizationQuery,
    {
      accountId: accountId!,
      savingsPlanId,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedData = useMemo(() => {
    if (rawData?.__typename !== 'DataTableResult' || !rawData?.table || rawData?.table?.data?.length === 0) {
      return null;
    }

    const tableData = getTableData(rawData.table, {
      savings_plan_id: 'savingsPlanId',
      usage_day: 'usageDay',
      used_commitment: 'usedCommitment',
      unused_commitment: 'unusedCommitment',
      utilization: 'utilization',
      savings: 'savings',
    }) as SPUtilizationItem[];

    const statsData = {
      totalConsumption: sumBy(tableData, 'usedCommitment') + sumBy(tableData, 'unusedCommitment'),
      totalSavings: sumBy(tableData, 'savings'),
      avgUtilization: sumBy(tableData, 'utilization') / tableData.length,
    };

    const chartData = {
      dates: tableData.map((item) => dayjs(Number(item.usageDay)).format()),
      usedCommitment: tableData.map((item) => item.usedCommitment),
      unusedCommitment: tableData.map((item) => item.unusedCommitment),
      usedCategories: ['usedCommitment', 'unusedCommitment'],
    };

    return {
      statsData,
      chartData,
    };
  }, [rawData]);

  return {
    error,
    isEmpty: !computedData,
    isLoading,
    data: computedData || undefined,
  };
};
