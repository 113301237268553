import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components,
  GroupBase,
  type OptionProps,
  type SingleValueProps,
  type PlaceholderProps,
  SelectInstance,
} from 'react-select';
import { Select, SelectProps } from '@verticeone/design-system';
import { ContractSelectItem } from './ContractSelectItem';
import { ContractOption } from './types';
import { useSelectContractsOptions } from './useSelectContractsOptions';
import Stack from '@mui/material/Stack';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { ContractPermissions } from '../../types';
import { ContractEntityCategory } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type ContractSelectProps = Omit<SelectProps<ContractOption, false>, 'value' | 'onChange'> & {
  value: ContractOption | null;
  onChange: (value: ContractOption | null) => void;
  optionsPermissionFilter?: (contract: ContractPermissions) => boolean;
  allowedCategories?: Array<ContractEntityCategory>;
};

const ContractSelectOption = (
  props: JSX.IntrinsicAttributes & OptionProps<ContractOption, false, GroupBase<ContractOption>>
) => (
  <components.Option {...props}>
    <ContractSelectItem option={props.data} />
  </components.Option>
);

const ContractSingleValue = (props: SingleValueProps<ContractOption>) => (
  <components.SingleValue {...props}>
    <ContractSelectItem option={props.data} />
  </components.SingleValue>
);

const Placeholder = ({ children, ...props }: PlaceholderProps<ContractOption>) => {
  const { palette } = useTheme();

  return (
    <components.Placeholder {...props}>
      <Stack direction="row" alignItems="center" gap={2}>
        <Search htmlColor={palette.text.color3} />
        {children}
      </Stack>
    </components.Placeholder>
  );
};

// used for search
const getOptionLabel = ({ contract }: ContractOption) => {
  return `${contract.parts.contractual?.vendor?.vendorName || ''} ${contract.parts.contractual?.products
    ?.map((p) => p.productName)
    .join(' ')}`;
};

export const ContractSelect = forwardRef(
  (
    {
      placeholder,
      noOptionsMessage,
      onChange,
      optionsPermissionFilter,
      allowedCategories,
      ...otherProps
    }: ContractSelectProps,
    ref: React.Ref<SelectInstance<ContractOption>> | undefined
  ) => {
    const { t } = useTranslation();
    const { options, isLoading } = useSelectContractsOptions({
      permissionsFilter: optionsPermissionFilter,
      allowedCategories,
    });
    return (
      <Select
        ref={ref}
        options={options}
        getOptionValue={(c) => c.id}
        isLoading={isLoading}
        getOptionLabel={getOptionLabel}
        components={{ Option: ContractSelectOption, SingleValue: ContractSingleValue, Placeholder }}
        onChange={onChange}
        menuPortalTarget={document.body}
        placeholder={placeholder ?? t('ENTITIES.FILTERS.PLACEHOLDERS.SEARCH_CONTRACTS')}
        noOptionsMessage={noOptionsMessage ?? (() => t('ENTITIES.WORKFLOW_REQUEST.ERRORS.NOT_FOUND'))}
        {...otherProps}
      />
    );
  }
);
