import {
  DataTableResult,
  ErroredQueryResult,
  useAnalyticsDrawerTagsServicesUsageQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useDeferredQuery, HookResult } from '@verticeone/utils/api';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useCloudAnalytics } from '../../CloudAnalyticsContext';
import { useComparisonDrawer } from './ComparisonDrawerContext';

export type DrawerTagsServicesUsageDataProps = {
  productCode: string;
};

const useDrawerTagsServicesUsageData = ({ productCode }: DrawerTagsServicesUsageDataProps) => {
  const { accountId } = useAccountContext();
  const {
    period: { periodOneStart: periodStart, periodOneEnd: periodEnd },
    granularity,
    filter,
  } = useCloudAnalytics();
  const { selectionIds, isOpened } = useComparisonDrawer();

  const tagName = filter.tagName as string;
  const tagValues = ((filter.tagValues as []) || []).join(',');
  const skip = !accountId || !tagName || !isOpened;

  return useDeferredQuery(
    useAnalyticsDrawerTagsServicesUsageQuery,
    {
      accountId,
      productCode,
      periodStart,
      periodEnd,
      tagName,
      tagValues,
      granularity,
      selectionIds: selectionIds.join(','),
      limit: 200,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  ) as HookResult<DataTableResult | ErroredQueryResult>;
};

export default useDrawerTagsServicesUsageData;
