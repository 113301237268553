import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import { Wizard } from '@vertice/core/src/components/Wizard';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { buildProviderCode, providers } from '../../utils/providers';
import IntegrationDetails from './steps/IntegrationDetails';
import SetUp from './steps/SetUp';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { OutletContextType } from '../../../../types';
import { Stack } from '@mui/material';

export const AccountingSetupWizard = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { setIsFullHeight } = useOutletContext<OutletContextType>();

  useEffect(() => {
    setIsFullHeight(true);

    return () => {
      setIsFullHeight(false);
    };
  }, [setIsFullHeight]);

  const backToIntegration = () => navigate(routes.INTEGRATIONS.NATIVE);
  useEffect(() => {
    if (providerId === undefined || !providers[providerId as keyof typeof providers]) {
      navigate(routes.PAGE_NOT_FOUND, undefined, { replace: true });
    }
  }, [navigate, providerId, routes]);

  const {
    mergeId,
    providerCode,
    translationId,
    icon: { big: icon },
    feature,
  } = providers[providerId as keyof typeof providers];

  const [searchParams] = useSearchParams();
  const providerInstance = parseInt(searchParams.get('instance') ?? '1') ?? 1;
  const { getFeatureProperty } = useFeatures();
  const allowedInstances: number = getFeatureProperty(feature, 'numberOfAllowedConnections') ?? 1;

  useEffect(() => {
    if (!Number.isInteger(providerInstance) || allowedInstances < providerInstance) {
      navigate(routes.INTEGRATIONS.NATIVE);
    }
  });

  const sanitizedProviderCode = buildProviderCode(providerCode, providerInstance);

  return (
    <Stack p={10}>
      <Wizard initialStep="setup" onClose={backToIntegration} closeButtonText={t('INTEGRATIONS.CANCEL_SETUP')}>
        <SetUp stepId="setup" icon={icon} translationId={translationId} />
        <IntegrationDetails
          stepId="integration_details"
          translationId={translationId}
          mergeIntegrationId={mergeId}
          providerCode={sanitizedProviderCode}
          onSuccess={backToIntegration}
        />
      </Wizard>
    </Stack>
  );
};
