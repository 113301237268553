import { NestedPresenter } from './NestedPresenter';
import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicFormPresenterConfig, PrimitiveDynamicFormField } from '../../../dynamicForms';
import { ActionButton } from '../../components/ActionButton';

type RepeatingFieldArrayItemProps = {
  definition: PrimitiveDynamicFormField[];
  makeName: (fieldName: string) => string;
  config: DynamicFormPresenterConfig;
  onRemove: () => void;
  canRemove: boolean;
};

export const RepeatingFieldArrayItem = ({
  definition,
  makeName,
  config,
  onRemove,
  canRemove,
}: RepeatingFieldArrayItemProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_PRESENTER.FIELD.REPEATING' });

  return (
    <Stack border={1} borderRadius={3} borderColor={palette.core.color4} padding={4}>
      <Stack sx={{ position: 'relative' }}>
        {canRemove && config.mode === 'readWrite' && (
          <ActionButton onClick={() => onRemove()} text={t('ACTIONS.REMOVE_ITEM')} presenterConfig={config} />
        )}
        {definition.map((field) => {
          const newField = { ...field, name: makeName(field.name) };
          return <NestedPresenter key={newField.name} field={newField} config={config} />;
        })}
      </Stack>
    </Stack>
  );
};
