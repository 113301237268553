import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetAccountIntegrationsQuery } from '@vertice/slices';
import { AuthType, IntegrationCategory, parseIntegrationId } from '../utils';

type UseCustomIntegrationsDataProps = {
  category: IntegrationCategory;
};

export const useGetIntegrations = ({ category }: UseCustomIntegrationsDataProps) => {
  const { accountId } = useAccountContext();

  return useGetAccountIntegrationsQuery(
    {
      accountId: accountId,
      category,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        data: result?.data?.map((item) => {
          const { integrationName, authType } = parseIntegrationId(item.id);

          return {
            integrationId: item.id,
            integrationName,
            enabled: item.status === 'ACTIVE',
            secret: {
              type: authType as AuthType,
            },
          };
        }),
      }),
    }
  );
};
