import { forwardRef } from 'react';
import { Grid, GridProps, Stack, type StackProps } from '@mui/material';
import { motion } from 'framer-motion';

const StackWrapper = forwardRef<HTMLDivElement, StackProps>((props, ref) => <Stack ref={ref} {...props} />);

const AnimatedStack = motion.create(StackWrapper);

const GridWrapper = forwardRef<HTMLDivElement, GridProps>((props, ref) => <Grid ref={ref} {...props} />);

const AnimatedGrid = motion.create(GridWrapper);

export { AnimatedStack, AnimatedGrid };
