import { createPortableRoutes } from '@verticeone/router/utils';

export const integrationsRoutes = createPortableRoutes({
  INTEGRATIONS: {
    path: '/integrations',
    children: {
      NATIVE: {
        path: 'native',
        children: {
          WORKFLOWS: {
            path: 'workflows',
            children: {
              JIRA: {
                path: 'jira',
              },
            },
          },
        },
      },
    },
  },
} as const);
