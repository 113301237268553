import useDashboardWidgetData from '../../useDashboardWidgetData';
import { getTableData } from '../../utils';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { useMemo } from 'react';
import { uniq } from 'lodash';
import { Filter, useTimeToCompleteTaskContext } from './TimeToCompleteTaskContext';
import { EmptyState, LoadableData } from '../../types';

type TableData = {
  columns: string[];
  types: string[];
  data: string[][];
};

type TableDataItem = {
  taskType: string;
  requestType: string;
  averageDaysSinceClosed: number;
  totalTasksClosed: number;
  [key: string]: string | number;
};

export type FilterOptions = {
  taskType: string[];
  requestType: string[];
};

export type TimeToCompleteTaskData = EmptyState &
  LoadableData<{
    values: {
      id: string;
      data: (number | null)[];
    }[];
    categories: string[];
    filterOptions: FilterOptions;
    stats: {
      averageDaysSinceClosed: number;
      total: number;
    };
  }>;

const calculateData = ({ widgetData, filter }: { widgetData: Widget | undefined; filter: Filter }) => {
  const dashboardData = widgetData?.data as TableData;

  const tableData = dashboardData ? (getTableData(dashboardData) as TableDataItem[]) : [];

  const categories = dashboardData?.columns.filter((column) => column.startsWith('days')) || [];

  const filteredData = tableData.find(
    (item) => item.taskType === filter.taskType && item.requestType === filter.requestType
  );

  const data = categories.map((category) => {
    return filteredData ? Number(filteredData[category]) : null;
  });

  const values = [
    {
      id: 'timeToCompleteTask',
      data: data,
    },
  ];

  const formattedCategories = categories.map((category) => {
    const moreMatch = category.match(/days(\d+)More/);
    if (moreMatch) {
      return `${moreMatch[1]}+`;
    }
    const match = category.match(/days(\d+)To(\d+)/);
    return match ? `${match[1]}-${match[2]}` : category;
  });

  const isEmpty = !tableData || tableData.length === 0 || tableData.every((item) => item.totalTasksClosed === 0);
  const isFilteredEmpty = !isEmpty && (!filteredData || filteredData.totalTasksClosed === 0);

  return {
    data: {
      values,
      categories: formattedCategories,
      filterOptions: {
        taskType: uniq(tableData.map((item) => item.taskType)).sort(),
        requestType: uniq(tableData.map((item) => item.requestType)).sort(),
      },
      stats: {
        averageDaysSinceClosed: filteredData?.averageDaysSinceClosed || 0,
        total: filteredData?.totalTasksClosed || 0,
      },
    },
    isEmpty,
    isFilteredEmpty,
  };
};

const useTimeToCompleteTaskData = (): TimeToCompleteTaskData => {
  const { data: widgetData, error, isFetching } = useDashboardWidgetData('TimeToCompleteHistogram');
  const { filter } = useTimeToCompleteTaskContext();

  const memoizedData = useMemo(() => calculateData({ widgetData, filter }), [filter, widgetData]);

  return {
    error,
    isFetching,
    ...memoizedData,
  };
};

export default useTimeToCompleteTaskData;
