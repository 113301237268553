import { Stack } from '@mui/material';
import { CostModelProductListWithBenchmark } from '../components/CostModelProductListWithBenchmark';
import useWatchContractFormField from '@vertice/core/src/modules/saas/contract/hooks/useWatchContractFormField';
import { CostModelLineItemsContractFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/CostModelLineItemsContractFormEntry';
import React, { useEffect } from 'react';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFormContext } from 'react-hook-form';
import { ContractFormData } from '@vertice/core/src/modules/saas/contract/types';

const ProductsTab = () => {
  const { isEnabled } = useFeatures();
  const nonSaasPurchasingFeature = isEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG);
  const contractCategory = useWatchContractFormField('classification.category');
  const isNonSaas = contractCategory === 'OTHER';
  const { setValue } = useFormContext<ContractFormData>();

  useEffect(() => {
    const fieldToClear = isNonSaas ? 'costModel.negotiated.model.products' : 'costModel.negotiated.model.lineItems';
    setValue(fieldToClear, []);
  }, [isNonSaas, setValue]);

  return (
    <Stack p={6}>
      {isNonSaas && nonSaasPurchasingFeature ? (
        <CostModelLineItemsContractFormEntry name="costModel.negotiated.model.lineItems" width={12} />
      ) : (
        <CostModelProductListWithBenchmark />
      )}
    </Stack>
  );
};

export default ProductsTab;
