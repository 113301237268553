import { Stack } from '@mui/material';
import { FC, useEffect, useId } from 'react';
import { TextFieldCaption } from '@verticeone/design-system';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import { ContractFormData } from '../../types';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';
import { Text } from '@verticeone/design-system';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';

const TotalCostTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_COST.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_COST.LINE_2')}
      </Text>
    </Stack>
  );
};

const TotalCostNegotiatedViewOnlyField: FC<{ id: string; control: Control<ContractFormData> }> = (props) => {
  const { id, control } = props;
  const formatContractCurrency = useFormatContractCurrency();

  const totalCostValueWithVertice = useWatch({ control, name: 'parts.overview.totalCostValueNegotiated' });

  return (
    <LargeValue
      id={id}
      primaryValue={formatContractCurrency(totalCostValueWithVertice)}
      primaryTestId="totalCostNegotiated"
      primaryColor="text1"
    />
  );
};

const AMOUNT_OVERRIDE_PATH = 'costModel.negotiated.model.totalCostOverride.amount';
const ENABLED_OVERRIDE_PATH = 'costModel.negotiated.model.totalCostOverride.enabled';

export const NegotiatedTotalCostFormEntry = () => {
  const { t } = useTranslation();
  const id = useId();

  const { control, setValue } = useFormContext<ContractFormData>();

  const overrideEnabled = useWatch({ control, name: ENABLED_OVERRIDE_PATH });
  const defaultTotalCost = useWatch({ control, name: 'parts.overview.totalCostValueNegotiated' });

  const isTotalCostVisible = useIsContractFieldVisible(AMOUNT_OVERRIDE_PATH);
  const isTotalCostRequired = useIsContractFieldRequired(AMOUNT_OVERRIDE_PATH);
  const isTotalCostEditable = useIsContractFieldEditable(AMOUNT_OVERRIDE_PATH);

  useEffect(() => {
    if (!overrideEnabled) {
      setValue(AMOUNT_OVERRIDE_PATH, defaultTotalCost ?? 0);
    }
  }, [defaultTotalCost, setValue, overrideEnabled]);

  if (!isTotalCostVisible) return null;

  return (
    <Stack gap={1}>
      <TextFieldCaption
        htmlFor={id}
        label={t('ENTITIES.CONTRACT.LABELS.TOTAL_CONTRACT_VALUE')}
        size="XS"
        required={isTotalCostEditable && isTotalCostRequired}
        tooltip={{ content: <TotalCostTooltipContent /> }}
      />
      {isTotalCostEditable ? (
        <FormDecimalNumberField
          id={id}
          name={AMOUNT_OVERRIDE_PATH}
          disabled={!overrideEnabled}
          required={isTotalCostRequired}
        />
      ) : (
        <TotalCostNegotiatedViewOnlyField id={id} control={control} />
      )}
    </Stack>
  );
};
