import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enhancedBffeWorkflowsAPI, WORKFLOW_BFFE_VERSIONS } from '@vertice/slices';
import { CircularProgress, Stack } from '@mui/material';
import { useSaveWorkflow } from '../../hooks/useSaveWorkflow';
import { ValidationChip, useWorkflowValidationContext } from '../../workflowSchema';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { IconButton, PageHeader, Tooltip } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WorkflowDefinitions } from '../../workflowSchema/types';
import { getDefinitionsFromWorkflowDefinitions } from '../../definitions/processDefinition';
import { useInvalidateWorkflowList } from '../../../../hooks/useInvalidateWorkflowList';
import { Close } from '@mui/icons-material';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { useActivateWorkflowVersionV2 } from '../../service/components/WorkflowsTable/useActivateWorkflowDialog';
import { useFlatServiceWorkflowsWithAllVersions } from '../../service/hooks/useFlatServiceWorkflowsWithAllVersions';
import { getIsWorkflowLive } from '../../service/components/WorkflowsTable/utils';
import { ServiceCatalogResource } from '../../catalogResource/types';
import { useSetAsDefaultWorkflow } from '../WorkflowDetailPage/components/SetAsDefaultButton';
import { useIsDefaultWorkflow } from '../WorkflowDetailPage/utils';
import { FEATURES } from '../../../features/constants';
import { useFeatures } from '../../../features/useFeatures';

type ActionsProps = {
  workflowDefinitions: WorkflowDefinitions;
  workflowVersionData: WorkflowVersion;
  hasChanges: boolean;
  workflowId: string;
  backPath: string;
  refetch: () => void;
  service: ServiceCatalogResource;
};

export const Actions = ({
  workflowDefinitions,
  workflowVersionData,
  workflowId,
  backPath,
  refetch,
  hasChanges,
  service,
}: ActionsProps) => {
  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const { isWorkflowValid, errors, warnings, infos, isValidatingWorkflow, setIsValidationDrawerOpen, validate } =
    useWorkflowValidationContext();
  const invalidateWorkflowList = useInvalidateWorkflowList();
  const { enqueueSnackbar } = useSnackbar();
  const { workflowsWithVersions, isLoading } = useFlatServiceWorkflowsWithAllVersions({ service });
  const liveWorkflowVersion = workflowsWithVersions.find(getIsWorkflowLive);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  const handleClose = useCallback(() => navigate(backPath), [navigate, backPath]);

  const handleSaveWorkflow = () => {
    const definitions = getDefinitionsFromWorkflowDefinitions(workflowDefinitions);
    saveWorkflow(
      {
        workflowId,
        definitions,
        workflowVersion: workflowVersionData?.versionId,
        serviceRef: workflowVersionData?.serviceRef,
        customVersionName: workflowVersionData?.versionName,
      },
      async () => {
        invalidateWorkflowList();
        refetch();
        enqueueSnackbar({ variant: 'success', message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_SUCCESS') });
      },
      () => {
        enqueueSnackbar({
          variant: 'error',
          message: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE'),
          description: t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.SAVE_FAILURE_DESCRIPTION'),
        });
      }
    );
  };

  const hasErrors = (errors?.length ?? 0) !== 0;
  const hasWarnings = (warnings?.length ?? 0) !== 0;

  const { setAsDefaultWorkflow } = useSetAsDefaultWorkflow(workflowVersionData, service);
  const isDefault = useIsDefaultWorkflow(workflowVersionData, service);

  const handleActivateWorkflowComplete = useCallback(() => {
    if (!isDefault && isWorkflowsPageV2Enabled) {
      setAsDefaultWorkflow(undefined, { hideSnackbarConfirmation: true });
      dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_VERSIONS]));
    }
    handleClose();
  }, [isDefault, setAsDefaultWorkflow, handleClose, dispatch, isWorkflowsPageV2Enabled]);

  const { activateWorkflow, ActivateDialog } = useActivateWorkflowVersionV2({
    hasWarnings,
    onComplete: handleActivateWorkflowComplete,
    workflowVersion: workflowVersionData,
    liveWorkflowVersion,
  });

  return (
    <>
      <ValidationChip
        onClick={() => {
          void validate(workflowDefinitions);
          setIsValidationDrawerOpen(true);
        }}
        isLoading={isValidatingWorkflow}
        isValid={isWorkflowValid}
        errors={errors?.length}
        warnings={warnings?.length}
        infos={infos?.length}
      />
      <PageHeader.PrimaryAction disabled={!hasChanges || isSavingWorkflow} onClick={handleSaveWorkflow}>
        <Stack direction="row" alignItems="center" gap={2}>
          {isSavingWorkflow && <CircularProgress size="XS" color="neutral" />}
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS.SAVE')}
        </Stack>
      </PageHeader.PrimaryAction>
      <Tooltip
        title={
          hasErrors
            ? t('ENTITIES.WORKFLOW_VERSION.ACTIONS.TOOLTIPS.SET_LIVE_DRAFT')
            : t('ENTITIES.WORKFLOW_VERSION.ACTIONS.TOOLTIPS.SET_LIVE_SAVE_CHANGES')
        }
        size="S"
        minified
        noArrow
        disableHoverListener={!hasErrors && !hasChanges}
      >
        <Stack>
          <PageHeader.PrimaryAction
            isLoading={isLoading}
            onClick={activateWorkflow}
            disabled={hasErrors || hasChanges || isLoading}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              {t('ENTITIES.WORKFLOW_VERSION.ACTIONS.SET_AS_LIVE')}
            </Stack>
          </PageHeader.PrimaryAction>
        </Stack>
      </Tooltip>
      <IconButton
        variant="outline"
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        onClick={() => {
          handleClose();
        }}
        icon={Close}
      />
      {ActivateDialog}
    </>
  );
};
