import { parseRequestRefSafe } from '../../../../../../hooks/workflows/refUtils';
import { SERVICE_TASK_URN_REGEXES } from '../constants';
import { ServiceTaskDetailDef } from '../types';
import { InvokeServiceComplete } from './InvokeServiceComplete';
import { DetailFields } from './types';

export const invokeServiceCompleteDef: ServiceTaskDetailDef<DetailFields> = {
  urnRegex: SERVICE_TASK_URN_REGEXES.INVOKE_SERVICE,
  component: InvokeServiceComplete,
  getInputValues: ({ getInputValue, taskOverview }) => ({
    vendor: getInputValue('vendor'),
    product: getInputValue('product'),
    requests: Object.entries(taskOverview?.task.result ?? {})
      .filter(([_, requestRef]) => typeof requestRef === 'string' && !!parseRequestRefSafe(requestRef).requestId)
      .map(([key, ref]) => ({ key, ref })),
  }),
};
