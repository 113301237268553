import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { useRecommendationData } from './RecommendationProvider';
import LastEvaluated from '../../../components/LastEvaluated/LastEvaluated';
import { useOutletContext } from 'react-router-dom';
import { OutletContextType } from '../../../CloudLayout';

const Header = () => {
  const { item } = useRecommendationData();
  const { t: tRecommendations } = useTranslation('cloudRecommendations', { keyPrefix: item.code });
  const {
    item: { updatedAt },
  } = useRecommendationData();
  const { setHeaderActions } = useOutletContext<OutletContextType>();

  useEffect(() => {
    if (updatedAt) {
      setHeaderActions([<LastEvaluated key={updatedAt} updatedAt={new Date(updatedAt)} textSize={'S'} />]);
    }
    return () => {
      setHeaderActions([]);
    };
  }, [setHeaderActions, updatedAt]);

  const scope = item.tags?.map(([tagName, value]) => (tagName === 'Scope' ? [value] : [])).flat();

  return (
    <Stack gap={1}>
      <Stack gap={2}>
        <Text variant="heading" size="M" color="text1" tag="h1">
          {tRecommendations('NAME')}
        </Text>
        <Stack justifyContent="space-between" direction="row">
          <Text variant="body-regular" size="M" color="text2">
            {scope}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
