import { RequestTask, useListRequestTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAuthorizer } from '@verticeone/auth/src';
import { useAccountContext } from '../../../account/AccountContext';
import { EdgeState, WorkflowState } from '../model/types';
import { parseUserRef, parseUserTaskRef } from '../../../../hooks/workflows/refUtils';
import { useMemo } from 'react';
import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useWorkflowPassedTransitions } from './useWorkflowPassedTransitions';

// get task definition id from task instance id
const taskIdToTaskDefinitionId = (taskInstanceId: string) => {
  const lastUnderscoreIndex = taskInstanceId.lastIndexOf('_');
  return taskInstanceId.substring(0, lastUnderscoreIndex);
};

type TaskDefinitionId = string;

const getTheLatestTask = (tasks: RequestTask[]): RequestTask => {
  if (tasks.length === 1) {
    return tasks[0];
  }

  const tasksWithoutPendingTasks = tasks.filter((task) => task.status !== 'PENDING');

  if (tasksWithoutPendingTasks.length === 0) {
    return tasks[0];
  }

  return tasksWithoutPendingTasks.reduce((latestTask, task) => {
    if (task.createdAt > latestTask.createdAt) {
      return task;
    }
    return latestTask;
  });
};

/**
 * As there is a possibility that a task can be completed multiple times, we need to remove the recurrent completed tasks
 * and keep only the latest one. This allows the User to continue in the workflow.
 */
const removeRecurrentCompletedTasks = (allTasks: RequestTask[] = []): RequestTask[] => {
  const tasksByTaskDefinitionId: Record<
    TaskDefinitionId,
    {
      tasks: RequestTask[];
    }
  > = {};

  for (const task of allTasks) {
    const { taskId } = parseUserTaskRef(task.ref);
    const taskDefinitionId = taskIdToTaskDefinitionId(taskId);

    if (!tasksByTaskDefinitionId[taskDefinitionId]) {
      tasksByTaskDefinitionId[taskDefinitionId] = {
        tasks: [task],
      };
    } else {
      tasksByTaskDefinitionId[taskDefinitionId].tasks.push(task);
    }
  }

  return Object.values(tasksByTaskDefinitionId).reduce<RequestTask[]>((finalTasks, { tasks }) => {
    const latestTask = getTheLatestTask(tasks);
    finalTasks.push(latestTask);
    return finalTasks;
  }, []);
};

const getAuthorizerReassignTaskId = (taskId?: string) => `ReassignTask_${taskId}`;

export const useRequestState = (requestId?: string, requestRoute?: string): WorkflowState => {
  const { accountId } = useAccountContext();
  const { data: tasks } = useListRequestTasksQuery({ accountId, requestId: requestId! }, { skip: !requestId });
  const { data: requestData } = useGetRequestQuery(
    {
      accountId,
      requestId: requestId!,
    },
    { skip: !requestId }
  );

  const { passedTransitions } = useWorkflowPassedTransitions(requestId);

  const activeTasksIds = (tasks?.items ?? []).filter((task) => task.status === 'ACTIVE').map((task) => task.taskId);
  const authorizerPayload = activeTasksIds.map((taskId) => ({
    id: getAuthorizerReassignTaskId(taskId),
    object: `urn:verticeone:vertice:${accountId}:workflows:task/${taskId}`,
    action: 'task:Assign',
  }));

  const authorizerData = useAuthorizer(authorizerPayload);

  return useMemo(
    () => ({
      tasks:
        removeRecurrentCompletedTasks(tasks?.items).reduce<WorkflowState['tasks']>((acc, task) => {
          const { taskId } = parseUserTaskRef(task.ref);
          const taskDefinitionId = taskIdToTaskDefinitionId(taskId);
          acc[taskDefinitionId] = {
            status: task.status,
            assigneeIds: task.assignment.map((a) => parseUserRef(a).userId),
            closedAt: task.closedAt,
            createdAt: task.createdAt,
            dueDate: task.dueDate,
            resourceUrn: task.resourceUrn,
            requestRef: task.childRequestRef,
            executionRef: task.childExecutionRef,
            fulfilledBy: task.fulfilledBy,
            canUserReassign: authorizerData.some(
              ({ metadata, isLoading, isAllowed }) =>
                !isLoading && isAllowed && metadata.id === getAuthorizerReassignTaskId(taskId)
            ),
          };
          return acc;
        }, {}) || {},
      edges: passedTransitions.reduce<Record<string, EdgeState>>((acc, edgeId) => {
        return {
          ...acc,
          [`${edgeId}`]: {
            passed: true,
          },
        };
      }, {}),
      gateways: {},
      requestRoute: requestRoute,
      parentRequest: requestData?.request?.parentRequestRef
        ? {
            ref: requestData.request.parentRequestRef,
            name: requestData.request.parentRequestName || '',
            route: requestRoute || '',
          }
        : undefined,
    }),
    [requestRoute, tasks?.items, passedTransitions, requestData, authorizerData]
  );
};
