import {
  catalogAPICodegen,
  DescribeCatalogResourceApiArg,
  DescribeCatalogResourceApiResponse,
  CatalogResource,
  FormDefinition,
  Resource,
} from '../openapi/codegen/catalogAPI';
import { camelCase } from 'lodash';

import { CATALOG_TAG_RESOURCES } from './catalogAPI';

export type CatalogTagType = typeof CATALOG_TAG_RESOURCES;

function transformKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(transformKeysToCamelCase);
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = camelCase(key);
      acc[camelCaseKey] = transformKeysToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

type PascalToCamelCase<S extends string> = S extends `${infer T}${infer U}` ? `${Lowercase<T>}${U}` : S;

type CamelCaseKeys<T> = T extends Array<infer U>
  ? Array<CamelCaseKeys<U>>
  : T extends object
  ? {
      [K in keyof T as PascalToCamelCase<string & K>]: CamelCaseKeys<T[K]>;
    }
  : T;

export type DescribeCatalogResourceApiResponseCamelCase = CamelCaseKeys<DescribeCatalogResourceApiResponse>;
export type CatalogResourceCamelCase = CamelCaseKeys<CatalogResource>;
export type ResourceCamelCase = CamelCaseKeys<Resource>;
export type FormDefinitionCamelCase = CamelCaseKeys<FormDefinition>;

export const enhancedCatalogAPI = catalogAPICodegen
  .enhanceEndpoints<CatalogTagType>({
    addTagTypes: [CATALOG_TAG_RESOURCES],
    endpoints: {
      createCatalogResource: {
        invalidatesTags: [CATALOG_TAG_RESOURCES],
      },
      describeCatalogResource: {
        providesTags: [CATALOG_TAG_RESOURCES],
      },
      listCatalogResources: {
        providesTags: [CATALOG_TAG_RESOURCES],
      },
      deployCatalogResourceDefinition: {
        invalidatesTags: [CATALOG_TAG_RESOURCES],
      },
      updateCatalogResource: {
        invalidatesTags: [CATALOG_TAG_RESOURCES],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      describeCatalogResourceCamel: build.query<
        DescribeCatalogResourceApiResponseCamelCase,
        DescribeCatalogResourceApiArg
      >({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const queryResponse = await baseQuery({
            url: `/accounts/${arg.accountId}/catalog-resources/${arg.resourceId}`,
          });
          const data = queryResponse.data as DescribeCatalogResourceApiResponse;
          const camelCaseData: DescribeCatalogResourceApiResponseCamelCase = transformKeysToCamelCase(data);
          return { data: camelCaseData };
        },
        providesTags: [CATALOG_TAG_RESOURCES],
      }),
    }),
  });

export const { useDescribeCatalogResourceCamelQuery } = enhancedCatalogAPI;
