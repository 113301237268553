import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { IconWrapper } from '@verticeone/design-system';
import { NoData } from '@verticeone/design-system';
import { DataGrid, DataGridProps } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { TabsBottomLineWrapper } from '@verticeone/design-system';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { useWorkflowVersions } from './useWorkflowVersions';
import { useDeployedVersionColumns } from './useDeployedVersionColumns';
import { WorkflowVersionRow } from './types';
import { useDraftVersionColumns } from './useDraftVersionColumns';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { SetAsDefaultButton } from './components/SetAsDefaultButton';
import { useAccountContext } from '../../../account/AccountContext';
import { useListCatalogResourcesQuery } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { useIsDefaultWorkflow } from './utils';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isServiceCatalogResource } from '../../catalogResource/utils';

type WorkflowTabs = 'deployed' | 'drafts';

export const WorkflowDetailPage: FC = () => {
  const { t } = useTranslation();
  const params = useParams() as { workflowId: string; serviceId: string };
  const paths = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const [searchParams, setSeachParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState<WorkflowTabs>(
    searchParams.get('tab') === 'drafts' ? 'drafts' : 'deployed'
  );
  useEffect(() => {
    const currentTabParam = searchParams.get('tab') || 'invalid';
    if (['drafts', 'deployed'].includes(currentTabParam)) {
      setActiveTab(currentTabParam as any);
    }
  }, [searchParams]);
  const handleTabChange = (tab: WorkflowTabs) => {
    setActiveTab(tab);
    setSeachParams({ tab });
  };

  const { workflowVersions, isLoading, activeVersion } = useWorkflowVersions(params.workflowId);

  const { accountId } = useAccountContext();
  const { data: servicesData } = useListCatalogResourcesQuery({
    accountId,
  });
  const resource = servicesData?.items?.find((s) => s.urn === activeVersion?.serviceRef);
  const service = isServiceCatalogResource(resource) ? resource : undefined;

  const isDefault = useIsDefaultWorkflow(activeVersion, service);

  const columns = useDeployedVersionColumns();
  const draftColumns = useDraftVersionColumns();

  const commonDataGridProps: Partial<DataGridProps> = useMemo(
    () => ({
      loadingStyle: 'skeleton',
      loading: isLoading,
      color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      sortingMode: 'client',
      disableRowSelectionOnClick: true,
      getRowHeight: () => 60,
      autoHeight: true,
      noRowsOverlayHeight: 120,
      getRowId: (row: WorkflowVersionRow) => `${row.id}-${row.versionId}`,
      slots: {
        noRowsOverlay: () => <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.NO_WORKFLOWS`)} />,
      },
      initialState: {
        sorting: {
          sortModel: [
            { field: 'versionName', sort: 'desc' },
            { field: 'workflowName', sort: 'desc' },
          ],
        },
      },
    }),
    [isLoading, t]
  );

  const firstDraft = workflowVersions['PENDING']?.[0];
  const deployedVersions = [...(workflowVersions['ACTIVE'] || []), ...(workflowVersions['ARCHIVED'] || [])];
  const drafts = workflowVersions['PENDING'] || [];

  return (
    <IntelligentWorkflowsLayout
      parentPageName={service?.name}
      parentPagePath={
        service &&
        generatePathForRoute(paths.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
          serviceId: params.serviceId,
        })
      }
      currentPageName={activeVersion?.workflowName || firstDraft?.workflowName}
      currentPageAdornments={{
        end: isDefault && (
          <Tooltip content={t('ENTITIES.WORKFLOW.DEFAULT_TOOLTIP')} minified size="S">
            <ChipButton variant="solid" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size="XS" isActive={false}>
              {t('ENTITIES.WORKFLOW.DEFAULT')}
              <IconWrapper icon={InfoOutlined} size="M" opacity={0.6} />
            </ChipButton>
          </Tooltip>
        ),
      }}
      actions={<SetAsDefaultButton activeVersion={activeVersion} service={service} />}
      isLoading={isLoading}
    >
      <Stack gap={8}>
        <TabsBottomLineWrapper paddingX={4}>
          <Tabs
            variant="outlined"
            value={activeTab}
            onChange={(_, value) => handleTabChange(value)}
            scrollButtons="auto"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            <Tab value="deployed" label={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DEPLOYED_TAB`)} />
            <Tab value="drafts" label={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.DRAFTS_TAB`)} />
          </Tabs>
        </TabsBottomLineWrapper>
        <Stack>
          {activeTab === 'deployed' && (
            <DataGrid<WorkflowVersionRow>
              columns={columns}
              rows={deployedVersions}
              loadingRowCount={deployedVersions.length || 5}
              {...commonDataGridProps}
            ></DataGrid>
          )}
          {activeTab === 'drafts' && (
            <DataGrid<WorkflowVersionRow>
              columns={draftColumns}
              rows={drafts}
              loadingRowCount={drafts.length || 5}
              {...commonDataGridProps}
            ></DataGrid>
          )}
        </Stack>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
