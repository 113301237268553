import { useResolveUserPermissionsOnContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '../../../account/AccountContext';

export const useUserContractPermissions = (contractId: string) => {
  const { accountId } = useAccountContext();

  const { data: userContractPermissions } = useResolveUserPermissionsOnContractQuery({ accountId, contractId });

  return {
    userCanViewContract: userContractPermissions?.permissions.r ?? false,
    userCanEditContract: userContractPermissions?.permissions.w ?? false,
    userCanExecuteContract: userContractPermissions?.permissions.x ?? false,
  };
};
