/**
 * @file
 * Currently, we support just one hardcoded form.
 * To be replaced with dynamic form rendering.
 */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RadioGroup, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

import { RadioSwitch } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FormData } from './schema';
import { useTaskContext } from '../../../TaskContext';
import { TaskFormControlLabel } from '../shared/formFields/TaskFormControlLabel';
import { AdditionalNotesFormEntry } from '../shared/formFields/AdditionalNotesFormEntry';

export const ApprovalSimpleForm = () => {
  const { t } = useTranslation();
  const {
    field: { ref, value, ...field },
  } = useController<FormData>({ name: 'approved' });
  const formState = useFormContext<FormData>();
  const { taskOverview } = useTaskContext();

  const buttonLabels = taskOverview?.task.input.buttonLabels;
  const formHeading = taskOverview?.task.input.formHeading;

  const handleChange = (newValue: string) => {
    field.onChange(newValue === 'true');

    // Have to manually trigger the approvalNotes field in order for the refine to execute
    // Not sure if this is a bug in react-hook-form or zod or something else
    // This seems to be related: https://github.com/react-hook-form/resolvers/issues/661
    // FIXME: This is a hack to get the approvalNotes field to validate
    void formState.trigger('approvalNotes');
  };

  return (
    <Stack gap={6}>
      <Stack sx={{ wordWrap: 'break-word', width: '100%', alignItems: 'center' }}>
        <Text variant="body-regular" size="M">
          {formHeading || (
            <Trans
              i18nKey={'INTELLIGENT_WORKFLOWS.TASK_MODAL.WHAT_IS_THE_FINAL_DECISION'}
              components={{ bold: <b /> }}
              values={{ taskName: taskOverview?.task.name }}
            />
          )}
        </Text>
      </Stack>
      <RadioGroup ref={ref} value={value} onChange={(_event, newValue) => handleChange(newValue)}>
        <TaskFormControlLabel
          control={<RadioSwitch value="true" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={buttonLabels?.approve || t('INTELLIGENT_WORKFLOWS.TASK_MODAL.APPROVED')}
        />
        <TaskFormControlLabel
          control={<RadioSwitch value="false" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />}
          label={buttonLabels?.reject || t('INTELLIGENT_WORKFLOWS.TASK_MODAL.DECLINED')}
        />
      </RadioGroup>
      <AdditionalNotesFormEntry name="approvalNotes" />
    </Stack>
  );
};
