import { Alert, AlertButton } from '@verticeone/design-system';
import React from 'react';
import { useDashboardContext } from '../DashboardContext';
import { useTranslation } from 'react-i18next';

const NewAccountDemoAlert = () => {
  const { isNewAccount, isDemoVisible, toggleDemo } = useDashboardContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.DEMO' });

  return (
    isNewAccount &&
    isDemoVisible && (
      <Alert size="M" color="warning" testId="new-account-demo-alert" title={t('ALERT_TITLE')}>
        <AlertButton color="primary" onClick={toggleDemo}>
          {t('ALERT_BUTTON')}
        </AlertButton>
      </Alert>
    )
  );
};

export default NewAccountDemoAlert;
