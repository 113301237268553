import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogHeader,
  Text,
  useFormatDate,
  dateTimeFormatterOptions,
} from '@verticeone/design-system';
import { WorkflowVersionRow } from '../WorkflowsTable/types';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useUsersById } from '../../../../../hooks/useAccountUsersById';
import { useAccountContext } from '../../../../account/AccountContext';
import { getFullName } from '@verticeone/utils/formatting';
import { isUserRef, parseUserId } from '../../../../user/refUtils';
import { useGetWorkflowVersionName } from '../../../hooks/useGetWorkflowVersionName';

type VersionNotesDialogProps = {
  workflowVersion: WorkflowVersionRow;
  isOpen: boolean;
  onClose: () => void;
};

type VersionNoteProps = {
  title: string;
  versionNotes: string | undefined;
  activatedBy: string | undefined;
  activatedAt: string | undefined;
};

const VersionNote = ({ versionNotes, title, activatedBy, activatedAt }: VersionNoteProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION',
  });
  const { accountId } = useAccountContext();
  const { usersById } = useUsersById(accountId);
  const formatDate = useFormatDate();

  const isVertice = !activatedBy || !isUserRef(activatedBy);
  const userName = activatedBy && !isVertice && getFullName(usersById[parseUserId(activatedBy)]);
  const activatedByLabel = isVertice ? t('VERTICE_AUTOMATION') : userName !== '' ? userName : t('VERTICE_TEAM');

  const formattedActivatedAt = activatedAt
    ? formatDate(activatedAt, {
        formatterOptions: dateTimeFormatterOptions,
      })
    : undefined;

  const subtitle = t('VERSION_NOTES_SUBTITLE', { activatedAt: formattedActivatedAt, activatedBy: activatedByLabel });

  return (
    <Stack direction="column" gap={6}>
      <Stack direction="column" gap={2}>
        <Text variant="body-bold" size="M" color="text1">
          {title}
        </Text>
        <Text variant="body-regular" size="M" color="text3">
          {subtitle}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text1">
        {versionNotes ? versionNotes : t('NO_VERSION_NOTES')}
      </Text>
    </Stack>
  );
};

export const VersionNotesDialog = ({ workflowVersion, isOpen, onClose }: VersionNotesDialogProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION',
  });

  const versionName = useGetWorkflowVersionName(workflowVersion);
  const fullWorkflowName = `${workflowVersion.workflowName} ${versionName}`;

  return (
    <Dialog open={isOpen} onClose={onClose} size="M">
      <DialogHeader>{t('VERSION_NOTES')}</DialogHeader>
      <DialogContent>
        <VersionNote
          title={fullWorkflowName}
          versionNotes={workflowVersion.versionNotes}
          activatedBy={workflowVersion.activatedBy}
          activatedAt={workflowVersion.activatedAt}
        />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={2}>
          <Button color="primary" size="M" variant="solid" onClick={onClose}>
            {t('ACTIONS.CLOSE')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
