import { Stack, useTheme } from '@mui/material';
import { Button, NoData } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { AnimatedGrid } from '@vertice/core/src/components/animations/MotionWrappers';

import { WorkflowsTwoTone } from '../icons/WorkflowsTwoTone';

type TemplateNoDataProps = {
  onReset: () => void;
};

export const TemplateNoData = ({ onReset }: TemplateNoDataProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TEMPLATES' });
  const { palette } = useTheme();

  return (
    <AnimatedGrid
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      item
      xs={12}
      height="calc(100vh - 166px)"
      layoutId="no-data"
    >
      {/* 166px is the height of search and page header */}
      <NoData
        header={t('NO_DATA.TITLE')}
        content={t('NO_DATA.DESCRIPTION')}
        icon={
          <Stack p={4} mb={-4} borderRadius={4} border={`1px dashed ${palette.core.color4}`}>
            <WorkflowsTwoTone color={palette.primary.color1} width={48} height={48} />
          </Stack>
        }
        backgroundColor={palette.core.color1}
        button={
          <Stack mt={-4}>
            <Button variant="ghost" color="primary" onClick={onReset}>
              {t('NO_DATA.ACTION')}
            </Button>
          </Stack>
        }
      />
    </AnimatedGrid>
  );
};
