import React, { FC, useEffect } from 'react';
import { Button, Divider, Text } from '@verticeone/design-system';
import { FormControl, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormChecklistItemField from '../../../../../../../../forms/fields/FormChecklistItemField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../../constants';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { createQuestionsSchema } from '../../utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { DataTypesFormData, DataTypesQuestion } from '../../types';

type DataTypesStepProps = {
  defaultValues?: DeepPartial<DataTypesFormData>;
  questions: DataTypesQuestion[];
  onSubmit: (data: DataTypesFormData) => void;
  onChange: (data: DeepPartial<DataTypesFormData>) => void;
  onPrevious: () => void;
};

export const DataTypesStep: FC<DataTypesStepProps> = ({ questions, defaultValues, onSubmit, onPrevious, onChange }) => {
  const { t } = useTranslation();
  const schema = createQuestionsSchema(questions);
  const formMethods = useForm<DataTypesFormData>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const { handleSubmit, formState, watch } = formMethods;

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      onChange(formValues);
    });
    return () => unsubscribe();
  }, [watch, onChange]);

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flex="1 1 0" onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ p: 6, overflow: 'auto', flexGrow: 1, gap: 12 }}>
          <Text variant="heading" size="XS" color="text2">
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.DATA_TYPES.TITLE')}
          </Text>
          <Stack flexGrow={1} gap={8}>
            {questions.map((question, index) => (
              <FormControl variant="outlined" key={index}>
                <FormChecklistItemField
                  name={question.name}
                  label={question.name}
                  description={question.description}
                  color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
                />
              </FormControl>
            ))}
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
          <Button variant="plain" size="S" onClick={onPrevious} disabled={formState.isSubmitting}>
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
          </Button>
          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.COMPLETE_TASK')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
