import { useMemo } from 'react';

import { RequestRow } from '../../types';
import { useFilterUtils } from './useFilterUtils';

type RequestFilter = {
  searchQuery: string;
  myRequestsOnly: boolean;
};

export const useRequestFilter = (requests: RequestRow[], filter: RequestFilter) => {
  const { filterBySearchQuery, filterByMyRequests } = useFilterUtils();

  // This needs some optimizing, but works pretty good for a "full text search"
  const filteredRequests = useMemo(
    () =>
      filterByMyRequests(
        filter.searchQuery ? filterBySearchQuery(requests, filter.searchQuery) : requests,
        filter.myRequestsOnly
      ),
    [requests, filterByMyRequests, filter.myRequestsOnly, filterBySearchQuery, filter.searchQuery]
  );

  return { filteredRequests };
};
