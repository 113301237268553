import React, { PropsWithChildren, ReactNode } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Grid, IconWrapper, Placeholder, testProps, Text } from '@verticeone/design-system';
import DashboardCard from '../DashboardCard';
import { CalendarTodayOutlined } from '@mui/icons-material';

type Variant = 'horizontal' | 'vertical';

const DEFAULT_VARIANT = 'vertical';

type ChartCardBaseProps = PropsWithChildren & {
  testId: string;
  title: ReactNode;
  icon: ReactNode;
  subtitle: ReactNode;
  stats?: ReactNode;
  variant?: Variant;
  footer?: ReactNode;
};

const ChartCardBase = ({
  testId,
  title,
  icon,
  subtitle,
  children,
  stats,
  variant = DEFAULT_VARIANT,
  footer,
}: ChartCardBaseProps) => (
  <DashboardCard {...testProps(testId, testId)} height={1}>
    <Grid container flex={1} columnSpacing={variant === 'horizontal' ? 4 : 0}>
      <Grid item xs={12} lg={variant === 'horizontal' ? 4 : 12}>
        <Stack justifyContent="space-between" height={1}>
          <Stack gap={1} p={6}>
            <Text variant="heading" size="S" color="text1">
              {title}
            </Text>
            <Stack direction="row" alignItems="center" gap={2}>
              {icon}
              <Text variant="caption" color="text3" size="S">
                {subtitle}
              </Text>
            </Stack>
          </Stack>
          {stats}
        </Stack>
      </Grid>
      <Grid item xs={12} lg={variant === 'horizontal' ? 8 : 12} alignSelf="flex-end">
        <Stack p={3}>{children}</Stack>
      </Grid>
    </Grid>
    {footer}
  </DashboardCard>
);

type ChartCardProps = PropsWithChildren & {
  testId: string;
  title: string;
  subtitle: string;
  stats?: ReactNode;
  variant?: Variant;
  footer?: ReactNode;
};

const ChartCard = ({ testId, title, subtitle, children, stats, variant, footer }: ChartCardProps) => {
  const { palette } = useTheme();

  return (
    <ChartCardBase
      testId={testId}
      title={title}
      icon={<IconWrapper icon={CalendarTodayOutlined} size="S" htmlColor={palette.text.color3} />}
      subtitle={subtitle}
      stats={stats}
      variant={variant}
      footer={footer}
    >
      {children}
    </ChartCardBase>
  );
};

type ChartCardSkeletonProps = {
  testId: string;
  stats?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  variant?: Variant;
};

const Skeleton = ({ testId, stats, footer, children, variant }: ChartCardSkeletonProps) => (
  <ChartCardBase
    testId={testId}
    title={<Placeholder variant="text" width={128} height={24} />}
    icon={<Placeholder sx={{ borderRadius: 1 }} variant="rectangular" width={14} height={14} />}
    subtitle={<Placeholder variant="text" width={64} height={14} />}
    stats={stats}
    variant={variant}
    footer={footer}
  >
    {children}
  </ChartCardBase>
);

ChartCard.Skeleton = Skeleton;

export default ChartCard;
