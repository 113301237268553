import React from 'react';
import LoadableAWSPageWrapper from '@vertice/dashboard/src/pages/Cloud/LoadableAWSPageWrapper';
import RecommendationProvider from './components/RecommendationProvider';
import RecommendationContent from './RecommendationContent';

const Recommendation = () => (
  <LoadableAWSPageWrapper>
    <RecommendationProvider>
      <RecommendationContent />
    </RecommendationProvider>
  </LoadableAWSPageWrapper>
);

export default Recommendation;
