import { CostModelProductsContractFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/CostModelProductsContractFormEntry';
import React, { useCallback, useMemo } from 'react';
import { useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { ProductItem } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { PricingBenchmarkDetailRow } from '../../product/components/PricingBenchmarkDetailRow/PricingBenchmarkDetailRow';
import { BenchmarkingProductCellDecorator } from './BenchmarkingProductCellDecorator';

export const CostModelProductListWithBenchmark = () => {
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const vendorId = contractContext.fetchedContract.contract.parts.contractual?.vendor?.vendorId;

  const benchmarkingDetailRowRenderer = useMemo(
    () =>
      vendorId
        ? ({ row }: GridRowParams<ProductItem>) => (
            <PricingBenchmarkDetailRow vendorId={vendorId} productId={row.productId} />
          )
        : undefined,
    [vendorId]
  );

  const benchmarkingProductCellDecorator = useCallback(
    (row: ProductItem) => {
      return <BenchmarkingProductCellDecorator vendorId={vendorId} row={row} />;
    },
    [vendorId]
  );

  return (
    <CostModelProductsContractFormEntry
      detailRowRenderer={benchmarkingDetailRowRenderer}
      productCellDecorator={benchmarkingProductCellDecorator}
      name="costModel.negotiated.model.products"
      width={12}
    />
  );
};
