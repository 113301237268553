import { useEffect } from 'react';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import { getStartedMonthsBetweenDates } from '@vertice/core/src/modules/saas/contract/components/ContractLengthComputedField';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import useBillingFrequencyOptions from '@vertice/core/src/modules/saas/contract/hooks/useBillingFrequencyOptions';
import { SimpleOption } from '@verticeone/design-system';
import dayjs from 'dayjs';

export const FixedTermContractFields = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AddExistingContractFormData>();

  const contractLength = watch('contractLength');
  const startDate = watch('startDate');
  const renewalDate = watch('renewalDate');

  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  useEffect(() => {
    // Contract length is computed for V2 contracts
    const computedContractLength = getStartedMonthsBetweenDates(startDate, renewalDate);
    setValue('contractLength', computedContractLength);
  }, [startDate, renewalDate, setValue]);

  return (
    <>
      <AddExistingContractFormEntry
        name="signDate"
        label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
        component={FormDateField}
      />
      <AddExistingContractFormEntry
        name="startDate"
        label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        component={FormDateField}
        required
      />
      <AddExistingContractFormEntry
        name="contractLength"
        label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_LENGTH')}
        component={FormNumberField}
        disabled={true}
        componentProps={{
          minValue: 0,
          unit: t('UNITS.MONTH', { count: contractLength || 0 }),
        }}
      />
      <AddExistingContractFormEntry<FormSelectFieldComponentType<AddExistingContractFormData, SimpleOption, false>>
        name="billingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        component={FormSelectField2}
        componentProps={{
          options: billingFrequencyOpts,
          boxValue: (value) => (value ? billingFrequencyMap[value as string] : undefined),
          unboxValue: (selectValue) => selectValue?.value,
        }}
      ></AddExistingContractFormEntry>
      <AddExistingContractFormEntry
        name="renewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.RENEWAL_DATE')}
        component={FormDateField}
        required
        componentProps={{
          validate: (value) =>
            !dayjs(startDate).isAfter(dayjs(value)) || t('ENTITIES.CONTRACT.VALIDATION.RENEWAL_DATE_BEFORE_START_DATE'),
        }}
      />
      <AddExistingContractFormEntry
        name="autoRenewalDate"
        label={t('ENTITIES.CONTRACT.LABELS.AUTO_RENEWAL_DATE')}
        component={FormDateField}
      />
    </>
  );
};
