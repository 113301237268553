import React, { FC } from 'react';
import { Text } from '@verticeone/design-system';
import {
  GenericDateIcon,
  GenericUpdateIcon,
} from '@vertice/core/src/modules/intelligentWorkflows/components/icons/Icons';
import { RequestStatusChip } from '@vertice/core/src/modules/intelligentWorkflows/request/components/RequestStatusChip';
import { Request as WorkflowRequest } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Request as ServicesRequest } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useFormatDate } from '@verticeone/utils/formatting';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { parseRequestRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { getDataForWidget } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestDetailPage/widgets/Details/widgetDef';
import { PageCard } from './PageCard';
import { CardLine, CardTooltip, CardValue } from './CardContentElements';

type RequestPageCardProps = { request: WorkflowRequest | ServicesRequest };

export const RequestPageCard: FC<RequestPageCardProps> = ({ request }) => {
  const formatDate = useFormatDate();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  if (!request) {
    return null;
  }

  const deadline =
    getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.deadline ?? request?.verticeReserved?.request?.deadline;

  return (
    <PageCard
      key={request.ref}
      path={generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
        requestId: parseRequestRef(request.ref).requestId,
      })}
    >
      <CardLine>
        <Text variant="body-bold" color="text1">
          {request.name}
        </Text>
        <RequestStatusChip status={request.status} size="XS" />
      </CardLine>
      <CardTooltip translationKey="INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.CREATION_DATE">
        <CardLine>
          <GenericDateIcon size="S" />
          <CardValue>{formatDate(request.createdAt)}</CardValue>
        </CardLine>
      </CardTooltip>
      <CardTooltip translationKey="INTELLIGENT_WORKFLOWS.REQUESTS_LIST.COLUMNS.NEXT_KEY_DATE">
        <CardLine>
          <GenericUpdateIcon size="S" />
          <CardValue>{deadline ? formatDate(deadline) : '-'}</CardValue>
        </CardLine>
      </CardTooltip>
    </PageCard>
  );
};
