import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { Button, IconWrapper, Text, Tooltip } from '@verticeone/design-system';

import { DUE_DATE_HOW_TO_GUIDE } from '../../constants';

const StyledIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.text.color4,
  cursor: 'pointer',
}));

const Content = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={3.5}>
      <Text variant="body-regular" size="S" color="text5">
        {t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.MISSING_DUE_DATE.DESCRIPTION')}
      </Text>
      <Stack width="fit-content">
        <Button to={DUE_DATE_HOW_TO_GUIDE} target="_blank" component={Link} size="S" variant="solid" color="neutral">
          {t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.HOW_TO')}
        </Button>
      </Stack>
    </Stack>
  );
};

export const MissingDueDateInfoIcon = () => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.MISSING_DUE_DATE.TITLE')}
      content={<Content />}
      size="S"
      maxWidth="250px"
      noArrow
    >
      <StyledIconWrapper size="S" icon={InfoOutlined} />
    </Tooltip>
  );
};
