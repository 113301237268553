import React from 'react';
import { Box, useTheme } from '@mui/material';
import { NoVendorLogo } from './assets';
import { BaseVendorLogo } from './BaseVendorLogo';

/**
 * VendorLogo component which should follow the latest designs.
 */

export type VendorLogoProps = {
  vendorId?: string;
  size?: number;
  bg?: string;
  logoOnly?: boolean;
};

const BASE_SIZE = 32;
const PADDING = 6;

const VendorLogo = ({ vendorId, size = BASE_SIZE, bg, logoOnly = false }: VendorLogoProps) => {
  const { palette } = useTheme();

  const innerSize = size - PADDING * 2;
  const bgColor = bg ?? palette.core.color1;

  if (!vendorId) {
    return logoOnly ? (
      <NoVendorLogo width={size} height={size} />
    ) : (
      <Box p={`${PADDING}px`} borderRadius={3} bgcolor={bgColor} height={size} maxWidth={size}>
        <NoVendorLogo width={innerSize} height={innerSize} />
      </Box>
    );
  }

  if (logoOnly) {
    return <BaseVendorLogo vendorId={vendorId} width={size} height={size} />;
  }

  return (
    <Box p={`${PADDING}px`} borderRadius={3} bgcolor={bgColor} height={size} maxWidth={size}>
      <BaseVendorLogo vendorId={vendorId} width={innerSize} height={innerSize} />
    </Box>
  );
};

export default VendorLogo;
