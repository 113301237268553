import React, { ReactNode, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { PageHeader } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';

import { useSimpleDialogContext } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../../account/AccountContext';
import { useIsDefaultWorkflow } from '../utils';
import { enqueueSnackbar } from 'notistack';
import type { ServiceCatalogResource } from '../../../catalogResource/types';
import { useUpdateCatalogResourceMutation } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { parseResourceRef } from '../../../../../hooks/workflows/refUtils';

type UseSetAsDefaultProps = {
  activeVersion?: WorkflowVersion;
  service?: ServiceCatalogResource;
};

const ConfirmationWorkflowName = ({ children }: { children?: ReactNode }) => {
  return (
    <Stack paddingY={2}>
      <Text variant="body-bold">{children}</Text>
    </Stack>
  );
};

const getResourceIdWithResourceNameEncoded = (resource: ServiceCatalogResource) => {
  const { resourceName, resourceId } = parseResourceRef(resource.urn);

  return encodeURIComponent(`${resourceName}/${resourceId}`);
};

export const useSetAsDefaultWorkflow = (activeVersion?: WorkflowVersion, resource?: ServiceCatalogResource) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [updateCatalogResource, { isLoading: isUpdatingCatalogResource }] = useUpdateCatalogResourceMutation();

  const setAsDefaultWorkflow = useCallback(
    (onComplete?: () => void, params?: { hideSnackbarConfirmation?: boolean }) => {
      if (resource === undefined || activeVersion === undefined) return;

      void updateCatalogResource({
        accountId,
        resourceId: getResourceIdWithResourceNameEncoded(resource),
        updateCatalogResourceHandler: {
          handlerUrn: activeVersion.workflowRef,
        },
      }).then((response) => {
        if (!('error' in response)) {
          if (!params?.hideSnackbarConfirmation) {
            enqueueSnackbar(
              t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.SET_AS_DEFAULT', {
                workflowName: activeVersion.workflowName,
              }),
              {
                variant: 'success',
              }
            );
          }
          onComplete?.();
        } else {
          enqueueSnackbar(
            t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.SET_AS_DEFAULT_FAILED', {
              workflowName: activeVersion.workflowName,
            }),
            {
              variant: 'error',
            }
          );
        }
      });
    },
    [accountId, resource, activeVersion, updateCatalogResource, t]
  );

  return {
    setAsDefaultWorkflow,
    isUpdatingCatalogResource,
  };
};

export const SetAsDefaultButton = ({ activeVersion, service }: UseSetAsDefaultProps) => {
  const { t } = useTranslation();
  const { getConfirmation } = useSimpleDialogContext();
  const isDefault = useIsDefaultWorkflow(activeVersion, service);
  const { setAsDefaultWorkflow, isUpdatingCatalogResource } = useSetAsDefaultWorkflow(activeVersion, service);
  const isDisabled = activeVersion === undefined || service === undefined;

  const confirmationDescription = useMemo(
    () => (
      <Stack gap={8}>
        <Text variant="body-regular">
          {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.DESCRIPTION')}
        </Text>
        <Stack textAlign="center" style={{ lineHeight: 0.5 }}>
          <Text variant="body-regular">
            <Trans
              i18nKey="INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.DESCRIPTION_QUESTION"
              components={{ workflow: <ConfirmationWorkflowName /> }}
              values={{
                workflowName: activeVersion?.workflowName,
                requestTypeName: service?.name,
              }}
            />
          </Text>
        </Stack>
      </Stack>
    ),
    [activeVersion?.workflowName, service?.name, t]
  );

  const handleSetAsDefault = async () => {
    const confirmResult = await getConfirmation({
      title: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.TITLE', {
        requestTypeName: service?.name,
      }),
      description: confirmationDescription,
      okButton: {
        label: t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.SET_AS_DEFAULT_CONFIRMATION.CONFIRM'),
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
      },
    });

    if (confirmResult.status === 'cancel') {
      return;
    }

    setAsDefaultWorkflow();
  };

  return (
    <PageHeader.PrimaryAction
      onClick={handleSetAsDefault}
      disabled={isDefault || isDisabled || isUpdatingCatalogResource}
      isLoading={isUpdatingCatalogResource}
    >
      {t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.ACTIONS.SET_AS_DEFAULT')}
    </PageHeader.PrimaryAction>
  );
};
