import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack } from '@mui/material';
import { BuildOutlined, ExpandLess, Inventory2Outlined } from '@mui/icons-material';
import { GridGroupingColDefOverride, GridRenderCellParams, GridExpandMoreIcon } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useResizeObserver } from 'usehooks-ts';

import {
  ChipButton,
  Text,
  DataGridColDef,
  IconWrapper,
  getTextWidth,
  EllipsisText,
  Tooltip,
  Loader,
} from '@verticeone/design-system';
import { useFormatDate, dateTimeFormatterOptions } from '@verticeone/utils/formatting';

import { ShowOnRowHover, ShowOnRowHoverWrapper } from '../../../request/RequestsListPage/components/ShowOnRowHover';
import { ValidationChip } from '../../../workflowSchema';
import { DuplicateButton as DuplicateButtonBase } from '../../../workflow/WorkflowDetailPage/components/DuplicateButton';
import { EditButton } from '../../../workflow/WorkflowDetailPage/components/EditButton';
import { MenuButton } from './MenuButton';
import type { WorkflowVersionRow } from './types';
import { getWorkflowVersionName } from '../../../hooks/useGetWorkflowVersionName';
import {
  getIsWorkflowDraft,
  getIsWorkflowLive,
  getIsWorkflowLocked,
  getValueForSorting,
  isAutoGenerated,
} from './utils';
import { useLocalizeVersionName } from '../../../workflow/WorkflowDetailPage/utils';
import { SetAsLiveButton } from './SetAsLiveButton';
import { useWorkflowsTableContext } from './WorkflowsTableContext';

type WorkflowNameProps = {
  row: WorkflowVersionRow;
};

const WorkflowName: FC<WorkflowNameProps> = ({ row: workflowVersion }) => {
  const isLive = getIsWorkflowLive(workflowVersion);
  const isDraft = getIsWorkflowDraft(workflowVersion);
  const isArchived = workflowVersion.isArchived;

  const containerRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const { updatingWorkflowVersionId } = useWorkflowsTableContext();
  const isUpdating = updatingWorkflowVersionId === workflowVersion.versionId;

  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION',
  });
  const localizeVersionName = useLocalizeVersionName();

  const versionName = getWorkflowVersionName(workflowVersion, true, false, localizeVersionName);

  const { width: containerWidth = 0 } = useResizeObserver({
    ref: containerRef,
    box: 'border-box',
  });

  const chipRef = useRef<HTMLButtonElement>(null);

  const { width: chipWidth = 0 } = useResizeObserver({
    ref: chipRef,
    box: 'border-box',
  });

  const iconWrapperRef = useRef<HTMLDivElement>(null);

  const { width: iconWrapperWidth = 0 } = useResizeObserver({
    ref: iconWrapperRef,
    box: 'border-box',
  });

  useEffect(() => {
    if (!containerRef.current || !versionName || !containerWidth) return;

    const textSize = getTextWidth(versionName, { fontName: 'Inter', fontSize: '14px', fontWeight: '400' });

    setIsTruncated(textSize + iconWrapperWidth + chipWidth > containerWidth);
  }, [containerWidth, iconWrapperWidth, chipWidth, versionName]);

  return (
    <Stack
      position="relative"
      gap={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      ref={containerRef}
    >
      {isUpdating ? (
        <Stack height="100%" position="absolute" top={0} left={-12} alignItems="center" direction="row">
          <Loader size={10} />
        </Stack>
      ) : null}
      <Stack pl={1} gap={1} direction="row" alignItems="center">
        {!isTruncated ? (
          <Text size="S" variant="body-bold" color={isLive ? 'primary' : isArchived ? 'text3' : 'text1'}>
            {versionName}
          </Text>
        ) : (
          <Tooltip title={versionName} size="S" minified noArrow>
            <EllipsisText
              maxWidth={containerWidth - iconWrapperWidth - chipWidth}
              size="S"
              variant="body-bold"
              color={isLive ? 'primary' : isArchived ? 'text3' : 'text1'}
            >
              {versionName}
            </EllipsisText>
          </Tooltip>
        )}
        {(isLive || isDraft) && (
          <ChipButton variant={isLive ? 'solid' : 'ghost'} color="primary" size="S" ref={chipRef}>
            {isLive ? t('LIVE') : t('DRAFT')}
          </ChipButton>
        )}
      </Stack>
      <Stack ref={iconWrapperRef}>{isArchived && <IconWrapper icon={Inventory2Outlined} size="S" />}</Stack>
    </Stack>
  );
};

type WorkflowInfoProps = {
  row: WorkflowVersionRow;
};

const WorkflowInfo: FC<WorkflowInfoProps> = ({ row: workflowVersion }) => {
  const { activeInstances, completedInstances, workflowDefinitionStatus, totalInstances, isArchived } = workflowVersion;
  const isLive = getIsWorkflowLive(workflowVersion);
  const isLocked = getIsWorkflowLocked(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.CONTENT',
  });

  if (isLocked || isLive) {
    return (
      <ShowOnRowHoverWrapper gap={1}>
        <Text variant="body-regular" size="S" color={isLive ? 'primary' : isArchived ? 'text3' : 'text1'}>
          {t('ACTIVE_REQUESTS', { count: activeInstances })}
        </Text>
        <ShowOnRowHover>
          <Text variant="caption" color={isLive ? 'primary' : 'text3'} size="XS">
            {t('COMPLETED_AND_TOTAL', { completed: completedInstances, total: totalInstances })}
          </Text>
        </ShowOnRowHover>
      </ShowOnRowHoverWrapper>
    );
  }

  if (workflowDefinitionStatus) {
    return (
      <ValidationChip
        size="S"
        minified
        isActive={false}
        isValid={!workflowDefinitionStatus.error && !workflowDefinitionStatus.warning}
        errors={workflowDefinitionStatus.error}
        warnings={workflowDefinitionStatus.warning}
        infos={workflowDefinitionStatus.info}
        isLoading={false}
      />
    );
  }

  return null;
};

type WorkflowLastEditedProps = {
  row: WorkflowVersionRow;
};

const WorkflowLastEdited: FC<WorkflowLastEditedProps> = ({ row: workflowVersion }) => {
  const { updatedAt, activatedAt, isArchived } = workflowVersion;
  const isLive = getIsWorkflowLive(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.CONTENT',
  });

  const formatDate = useFormatDate();

  const updatedAtFormatted = formatDate(updatedAt, { formatterOptions: dateTimeFormatterOptions });
  const activatedAtFormatted = formatDate(activatedAt, { formatterOptions: dateTimeFormatterOptions });

  if (!updatedAtFormatted) {
    return null;
  }

  return (
    <ShowOnRowHoverWrapper gap={1}>
      <Text variant="body-regular" size="S" color={isLive ? 'primary' : isArchived ? 'text3' : 'text1'}>
        {updatedAtFormatted}
      </Text>
      {activatedAtFormatted && isLive ? (
        <ShowOnRowHover>
          <Text variant="caption" color={isLive ? 'primary' : 'text3'} size="XS">
            {t('LIVE_SINCE', { date: activatedAtFormatted })}
          </Text>
        </ShowOnRowHover>
      ) : null}
    </ShowOnRowHoverWrapper>
  );
};

type DuplicateButtonProps = {
  row: WorkflowVersionRow;
  color?: 'primary' | 'neutral';
};

const DuplicateButton: FC<DuplicateButtonProps> = ({ row: workflowVersion, color }) => {
  const { t } = useTranslation();
  const { setLastEditedWorkflowVersionId } = useWorkflowsTableContext();
  const { enqueueSnackbar } = useSnackbar();

  const handleStart = () => {
    setLastEditedWorkflowVersionId?.(workflowVersion.versionId);
  };

  const handleClose = () => {
    enqueueSnackbar(t('ENTITIES.WORKFLOW_VERSION.ACTIONS.COPYING'), {
      variant: 'info',
    });
  };

  return (
    <DuplicateButtonBase
      row={workflowVersion}
      color={color}
      onStart={handleStart}
      onClose={handleClose}
      size="S"
      tooltipTitle={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.CREATE_COPY')}
    />
  );
};

type WorkflowActionsProps = {
  row: WorkflowVersionRow;
};

const WorkflowActions: FC<WorkflowActionsProps> = ({ row: workflowVersion }) => {
  const isLive = getIsWorkflowLive(workflowVersion);
  const isLocked = getIsWorkflowLocked(workflowVersion);
  const hasErrors = !!workflowVersion.workflowDefinitionStatus?.error;
  const buttonColor = isLive ? 'primary' : 'neutral';
  const { liveWorkflowVersion } = useWorkflowsTableContext();

  return (
    <Stack direction="row" gap={1}>
      <ShowOnRowHover>
        {!hasErrors && !isLive && (
          <SetAsLiveButton
            liveWorkflowVersion={liveWorkflowVersion}
            type="iconbutton"
            color={buttonColor}
            workflowVersion={workflowVersion}
          />
        )}
      </ShowOnRowHover>
      <ShowOnRowHover>
        {isLocked ? (
          <DuplicateButton row={workflowVersion} color={buttonColor} />
        ) : (
          <EditButton icon={BuildOutlined} size="S" row={workflowVersion} color="neutral" />
        )}
      </ShowOnRowHover>
      <MenuButton liveWorkflowVersion={liveWorkflowVersion} workflowVersion={workflowVersion} color={buttonColor} />
    </Stack>
  );
};

export const GroupingCell: FC<GridRenderCellParams> = ({ rowNode }) => {
  if (rowNode.type !== 'group') {
    return null;
  }

  return (
    <Stack direction="row" gap={1} alignItems="center" sx={{ userSelect: 'none', marginLeft: '-10px' }}>
      <IconButton size="M">{rowNode.childrenExpanded ? <ExpandLess /> : <GridExpandMoreIcon />}</IconButton>
      <Stack direction="row" gap={1} alignItems="center">
        <Text variant="body-bold" size="S" color="text1">
          {rowNode.groupingKey}
        </Text>
      </Stack>
    </Stack>
  );
};

export const useWorkflowColumns = (defaultWorkflowName: string): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.HEADER',
  });

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'info',
        headerName: t('INFO'),
        width: 170,
        disableColumnMenu: true,
        sortable: false,
        renderCell: WorkflowInfo,
      },
      {
        field: 'lastEdited',
        headerName: t('LAST_EDITED'),
        width: 220,
        disableColumnMenu: true,
        renderCell: WorkflowLastEdited,
        valueGetter: (params) => getValueForSorting(params, defaultWorkflowName),
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        renderCell: WorkflowActions,
        width: 120,
        align: 'right',
      },
    ];
    return columns;
  }, [t, defaultWorkflowName]);
};

export const useWorkflowGroupingColumn = (
  columns: DataGridColDef<WorkflowVersionRow>[]
): GridGroupingColDefOverride<WorkflowVersionRow> => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.HEADER',
  });

  return useMemo(
    () => ({
      renderCell: (params) =>
        isAutoGenerated(params.id.toString()) ? <GroupingCell {...params} /> : <WorkflowName {...params} />,
      field: 'versionName',
      valueGetter: ({ id, row }) => {
        if (isAutoGenerated(id.toString())) {
          return row.workflowName;
        }

        return row.versionName;
      },
      sortable: true,
      headerName: t('NAME'),
      colSpan: ({ id }) => {
        if (isAutoGenerated(id.toString())) {
          // Remove '+ 1' to get actions column
          return columns.length + 1;
        }

        return undefined;
      },
      flex: 1,
    }),
    [t, columns]
  );
};
