import React from 'react';
import { useParams } from 'react-router-dom';

import EC2Instance from './products/EC2/Layout';
import RDSInstance from './products/RDS/Layout';
import type { ProductKey } from './types';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';
import { PageNotFound } from '@vertice/core/src/modules/errors/PageNotFound';
const Products: Record<ProductKey, React.ReactNode> = {
  ec2: <EC2Instance />,
  rds: <RDSInstance />,
};

const CloudRioInstanceTypes = () => {
  const { product } = useParams<{ product: ProductKey }>();
  const { subFeatures } = useCloudContext();

  if (
    !subFeatures.sprio ||
    (product === 'ec2' && !subFeatures.sprioEC2RI) ||
    (product === 'rds' && !subFeatures.sprioRDSRI)
  ) {
    return <PageNotFound />;
  }

  return product ? Products[product] : null;
};

export default CloudRioInstanceTypes;
