import React from 'react';
import { Grid, Stack } from '@mui/material';
import OverviewStats from './components/OverviewStats/OverviewStats';
import ActiveTasksCard from './components/ActiveTasksCard/ActiveTasksCard';
import CompletedRequestsByOutcomeCard from './components/CompletedRequestsByOutcomeCard/CompletedRequestsByOutcomeCard';
import CompletedRequestsCard from './components/CompletedRequestsCard/CompletedRequestsCard';
import { IntelligentWorkflowsLayout } from '@vertice/core/src/modules/intelligentWorkflows/components/IntelligentWorkflowsLayout';
import ProcuredSpendCard from './components/ProcuredSpendCard/ProcuredSpendCard';
import TimeToCompleteTaskCard from './components/TimeToCompleteTaskCard/TimeToCompleteTaskCard';
import AverageRequestTimesCard from './components/AverageRequestTimesCard/AverageRequestTimesCard';
import { DashboardContextProvider } from './DashboardContext';
import DemoModeToggle from './components/DemoModeToggle';
import NewAccountDemoAlert from './components/NewAccountDemoAlert';
import DemoOverlay from './components/DemoOverlay';

const Dashboard = () => (
  <DashboardContextProvider>
    <IntelligentWorkflowsLayout actions={[<DemoModeToggle key="demo-mode-toggle" />]}>
      <NewAccountDemoAlert />
      <Stack position="relative">
        <Grid container rowSpacing={4} columnSpacing={4} alignItems="stretch">
          <Grid item xs={12}>
            <OverviewStats />
          </Grid>
          <Grid item xs={12} md={6}>
            <ActiveTasksCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <CompletedRequestsByOutcomeCard />
          </Grid>
          <Grid item xs={12}>
            <TimeToCompleteTaskCard />
          </Grid>
          <Grid item xs={12}>
            <AverageRequestTimesCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProcuredSpendCard />
          </Grid>
          <Grid item xs={12} md={6}>
            <CompletedRequestsCard />
          </Grid>
        </Grid>
        <DemoOverlay />
      </Stack>
    </IntelligentWorkflowsLayout>
  </DashboardContextProvider>
);

export default Dashboard;
