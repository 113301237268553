import { Stack, styled } from '@mui/material';
import { IconWrapper, ifExpression, Text } from '@verticeone/design-system';
import { DragIndicatorOutlined } from '@mui/icons-material';
import React, { FC, PropsWithChildren } from 'react';

const DragIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.core.bg,
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}));

const DragIcon = () => {
  return (
    <Stack height="100%" width="100%" alignItems="center" justifyContent="center" position="absolute" top={0} left={0}>
      <DragIconWrapper icon={DragIndicatorOutlined} />
    </Stack>
  );
};

const Label = styled(Text)({
  opacity: 1,
  transition: 'opacity 0.3s ease-in-out',
});

type StyledStartEndNodeProps = {
  $passed?: boolean;
  $withDraggingStyles?: boolean;
};

const StyledStartEndNode = styled(Stack)<StyledStartEndNodeProps>(
  ({ theme: { palette }, $passed, $withDraggingStyles = false }) => ({
    position: 'relative',
    backgroundColor: $passed ? palette.success.color2 : palette.neutral.color2,
    padding: '4px 6px',
    borderRadius: 16,
    alignItems: 'center',
    flexDirection: 'row',
    gap: 4,
    height: 24,
    ...ifExpression($withDraggingStyles, {
      '&:hover': {
        [DragIconWrapper]: {
          opacity: 1,
        },
        [Label]: {
          opacity: 0,
        },
      },
    }),
  })
);

type StartEndNodeProps = {
  label: string;
  passed?: boolean;
  withDraggingStyles?: boolean;
} & PropsWithChildren;

export const StartEndNode: FC<StartEndNodeProps> = ({
  label,
  children,
  passed = false,
  withDraggingStyles = false,
}) => {
  return (
    <StyledStartEndNode $withDraggingStyles={withDraggingStyles} $passed={passed}>
      <DragIcon />
      <Label variant="button-bold" size="M" color="text5">
        {label}
      </Label>
      {children}
    </StyledStartEndNode>
  );
};
