import React, { PropsWithChildren, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Placeholder, testProps, Text } from '@verticeone/design-system';
import DashboardCard from '../DashboardCard';
import ShowAllButton from '../ShowAllButton';

type ListCardBaseProps = PropsWithChildren & {
  testId: string;
  title: ReactNode;
  description?: ReactNode;
  footer: ReactNode;
};

const ListCardBase = ({ testId, title, description, children, footer }: ListCardBaseProps) => (
  <DashboardCard {...testProps(testId, 'dashboard-list-card')} height={1}>
    <Stack p={6} gap={4} justifyContent="space-between">
      <Stack gap="6px">
        <Text variant="heading" size="S" color="text1">
          {title}
        </Text>
        {description && (
          <Text variant="caption" color="text3" size="S">
            {description}
          </Text>
        )}
      </Stack>
    </Stack>
    <Stack flex={1}>{children}</Stack>
    {footer}
  </DashboardCard>
);

type ListCardProps = PropsWithChildren & {
  testId: string;
  title: string;
  description?: ReactNode;
  showAllText: string;
  onShowAllClick: () => void;
  isEmpty?: boolean;
};

const ListCard = ({ testId, title, description, children, showAllText, onShowAllClick, isEmpty }: ListCardProps) => (
  <ListCardBase
    testId={testId}
    title={title}
    description={description}
    footer={!isEmpty && <ShowAllButton onClick={onShowAllClick} label={showAllText} />}
  >
    {children}
  </ListCardBase>
);

const Skeleton = ({ testId, children }: Pick<ListCardProps, 'testId' | 'children'>) => (
  <ListCardBase
    testId={`${testId}-skeleton`}
    title={<Placeholder variant="text" width="20%" height={24} />}
    footer={<ShowAllButton.Skeleton />}
  >
    {children}
  </ListCardBase>
);

ListCard.Skeleton = Skeleton;

export default ListCard;
