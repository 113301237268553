import { useTranslation } from 'react-i18next';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import Stack from '@mui/material/Stack';
import { BreadcrumbsV2, PageHeader } from '@verticeone/design-system';
import { Link, Navigate } from 'react-router-dom';
import React from 'react';
import CompletedContractsCard from '../cards/CompletedContractsCard/CompletedContractsCard';
import useSavingsDashboard from '../useSavingsDashboard';
import SavingsStatsCard from '../cards/SavingsStatsCard/SavingsStatsCard';

const Savings = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const { enabled: savingsDashboardEnabled } = useSavingsDashboard();

  if (!savingsDashboardEnabled) {
    return <Navigate to={generatePathForRoute(routes.DASHBOARD)} replace />;
  }

  return (
    <Stack pb={6}>
      <PageHeader
        breadcrumb={
          <BreadcrumbsV2
            items={[
              { label: t('SAAS.NAVBAR.SAAS') },
              { label: t('SAAS.NAVBAR.DASHBOARD'), component: Link, to: generatePathForRoute(routes.DASHBOARD) },
              { label: t('SAAS.NAVBAR.DASHBOARD_SAVINGS') },
            ]}
          />
        }
        color="primary"
        actions={undefined}
      />
      <Stack px={6} spacing={4}>
        <SavingsStatsCard />
        <CompletedContractsCard />
      </Stack>
    </Stack>
  );
};

export default Savings;
