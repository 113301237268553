import { useTranslation } from 'react-i18next';

import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle, Text } from '@verticeone/design-system';
import { WebhookActions } from './WebhookActions';

export const SynchronizationCard = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('INTEGRATIONS.VISO_TRUST.SYNC_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="caption" size="S" color="text3">
            {t('INTEGRATIONS.VISO_TRUST.SYNC_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
      </CardHeader>
      <WebhookActions />
    </Card>
  );
};
