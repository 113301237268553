import { integrationsJiraApi as api } from '../../api/integrationsJiraAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getIntegrationConfig: build.query<GetIntegrationConfigApiResponse, GetIntegrationConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/integration/config` }),
    }),
    updateIntegrationConfig: build.mutation<UpdateIntegrationConfigApiResponse, UpdateIntegrationConfigApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integration/config`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    listJiraProjects: build.query<ListJiraProjectsApiResponse, ListJiraProjectsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/projects` }),
    }),
    listJiraIssueTypes: build.query<ListJiraIssueTypesApiResponse, ListJiraIssueTypesApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types` }),
    }),
    listCreateJiraIssueTypeFields: build.query<
      ListCreateJiraIssueTypeFieldsApiResponse,
      ListCreateJiraIssueTypeFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/create-fields`,
      }),
    }),
    listEditJiraIssueTypeFields: build.query<ListEditJiraIssueTypeFieldsApiResponse, ListEditJiraIssueTypeFieldsApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/edit-fields`,
        }),
      }
    ),
    listJiraFieldOptions: build.query<ListJiraFieldOptionsApiResponse, ListJiraFieldOptionsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueTypeId}/fields/${queryArg.fieldId}/options`,
      }),
    }),
    getJiraWebhookConfig: build.query<GetJiraWebhookConfigApiResponse, GetJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook` }),
    }),
    setJiraWebhookConfig: build.mutation<SetJiraWebhookConfigApiResponse, SetJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook`, method: 'POST' }),
    }),
    removeJiraWebhookConfig: build.mutation<RemoveJiraWebhookConfigApiResponse, RemoveJiraWebhookConfigApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/webhook`, method: 'DELETE' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as integrationsJiraAPICodegen };
export type GetIntegrationConfigApiResponse = /** status 200 The Jira integration configuration */ Schema;
export type GetIntegrationConfigApiArg = {
  accountId: string;
};
export type UpdateIntegrationConfigApiResponse = /** status 200 The Jira integration configuration */ Schema;
export type UpdateIntegrationConfigApiArg = {
  accountId: string;
  body: {
    /** Schema of a workflow trigger settings.
     */
    triggerSettings: {
      [key: string]: {
        /** The service ref of the triggered workflow */
        workflow: string;
        /** A list of Jira workflow triggers */
        triggers: {
          /** A unique id of the trigger. It is generated if not specified. */
          triggerId?: string;
          filter:
            | {
                /** Type of the workflow trigger filter. */
                type: 'ISSUE_CREATED';
                /** ID of the Jira project */
                projectId: string;
                /** ID of the Jira issue type */
                issueTypeId: string;
              }
            | {
                /** Type of the workflow trigger filter. */
                type: 'STATUS_CHANGED';
                /** ID of the Jira project */
                projectId: string;
                /** ID of the Jira issue type */
                issueTypeId: string;
                /** ID of the Jira issue status */
                statusId: string;
              };
          /** A mapping of Jira fields to workflow request fields. */
          mapping: {
            [key: string]: string;
          };
          /** If set to true, we will download all attachments from the Jira ticket and attach it to the request. */
          includeAttachments?: boolean;
        }[];
        /** Enable or disable this group of workflow triggers */
        enabled?: boolean;
      };
    };
  };
};
export type ListJiraProjectsApiResponse = /** status 200 List of Jira projects. */ {
  projects?: {
    /** ID of the project */
    id: string;
    /** Name of the project */
    name: string;
    /** Key of the project */
    key: string;
  }[];
};
export type ListJiraProjectsApiArg = {
  accountId: string;
};
export type ListJiraIssueTypesApiResponse = /** status 200 List of Jira issue types for a given projects. */ {
  issueTypes?: {
    /** ID of the issue type */
    id: string;
    /** Name of the issue type */
    name: string;
    /** URL of the issue type icon */
    iconUrl?: string;
  }[];
};
export type ListJiraIssueTypesApiArg = {
  accountId: string;
  projectId: Schema2;
};
export type ListCreateJiraIssueTypeFieldsApiResponse =
  /** status 200 List of fields for a Jira issue types in a given projects. */ {
    fields?: {
      /** ID of the field */
      id: string;
      /** Name of the field */
      name: string;
      /** Key of the field */
      key: string;
      /** JSON Schema type of the field used by Workflows engine.
       */
      type: string;
      /** XType of the field used by Workflows engine.
       */
      xType?: string;
      /** Parameters for the data source for enumerated fields. Will be converted to "x-datasource-params" and sent to
        datasource lambda to list possible options.
         */
      dataSourceParams?: object;
      /** Whether the field is required */
      required: boolean;
      /** Whether the field has a default value. Optional. */
      hasDefaultValue?: boolean;
    }[];
  };
export type ListCreateJiraIssueTypeFieldsApiArg = {
  accountId: string;
  projectId: Schema2;
  issueTypeId: Schema2;
};
export type ListEditJiraIssueTypeFieldsApiResponse =
  /** status 200 List of fields for a Jira issue types in a given projects. */ {
    fields?: {
      /** ID of the field */
      id: string;
      /** Name of the field */
      name: string;
      /** Key of the field */
      key: string;
      /** JSON Schema type of the field used by Workflows engine.
       */
      type: string;
      /** XType of the field used by Workflows engine.
       */
      xType?: string;
      /** Parameters for the data source for enumerated fields. Will be converted to "x-datasource-params" and sent to
        datasource lambda to list possible options.
         */
      dataSourceParams?: object;
      /** Whether the field is required */
      required: boolean;
      /** Whether the field has a default value. Optional. */
      hasDefaultValue?: boolean;
    }[];
  };
export type ListEditJiraIssueTypeFieldsApiArg = {
  accountId: string;
  projectId: Schema2;
  issueTypeId: Schema2;
};
export type ListJiraFieldOptionsApiResponse =
  /** status 200 List of key-value options for a field of a Jira issue types in a given projects. */ {
    items?: {
      /** ID of the option */
      id: string;
      /** Value of the option */
      label?: string;
    }[];
  };
export type ListJiraFieldOptionsApiArg = {
  accountId: string;
  projectId: Schema2;
  issueTypeId: Schema2;
  fieldId: string;
};
export type GetJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type GetJiraWebhookConfigApiArg = {
  accountId: string;
};
export type SetJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type SetJiraWebhookConfigApiArg = {
  accountId: string;
};
export type RemoveJiraWebhookConfigApiResponse = /** status 200 Returns the Jira webhook and it's validity. */ {
  webhook?: {
    /** ID of the webhook */
    id?: number;
    /** Name of the webhook */
    name: string;
    /** Url the webhook should use */
    url: string;
    /** Filter for the jira webhook */
    filters?: object;
    /** Events the webhook should listen to */
    events: string[];
    /** Whether the webhook is enabled */
    enabled: boolean;
    /** Whether the webhook is using signature */
    isSigned: boolean;
  };
  /** Whether the webhook in Jira seems to be valid */
  isValid?: boolean;
};
export type RemoveJiraWebhookConfigApiArg = {
  accountId: string;
};
export type Schema = {
  /** Schema of a workflow trigger settings.
   */
  triggerSettings: {
    [key: string]: {
      /** The service ref of the triggered workflow */
      workflow: string;
      /** A list of Jira workflow triggers */
      triggers: {
        /** A unique id of the trigger. It is generated if not specified. */
        triggerId?: string;
        filter:
          | {
              /** Type of the workflow trigger filter. */
              type: 'ISSUE_CREATED';
              /** ID of the Jira project */
              projectId: string;
              /** ID of the Jira issue type */
              issueTypeId: string;
            }
          | {
              /** Type of the workflow trigger filter. */
              type: 'STATUS_CHANGED';
              /** ID of the Jira project */
              projectId: string;
              /** ID of the Jira issue type */
              issueTypeId: string;
              /** ID of the Jira issue status */
              statusId: string;
            };
        /** A mapping of Jira fields to workflow request fields. */
        mapping: {
          [key: string]: string;
        };
        /** If set to true, we will download all attachments from the Jira ticket and attach it to the request. */
        includeAttachments?: boolean;
      }[];
      /** Enable or disable this group of workflow triggers */
      enabled?: boolean;
    };
  };
};
export type Schema2 = string;
export const {
  useGetIntegrationConfigQuery,
  useLazyGetIntegrationConfigQuery,
  useUpdateIntegrationConfigMutation,
  useListJiraProjectsQuery,
  useLazyListJiraProjectsQuery,
  useListJiraIssueTypesQuery,
  useLazyListJiraIssueTypesQuery,
  useListCreateJiraIssueTypeFieldsQuery,
  useLazyListCreateJiraIssueTypeFieldsQuery,
  useListEditJiraIssueTypeFieldsQuery,
  useLazyListEditJiraIssueTypeFieldsQuery,
  useListJiraFieldOptionsQuery,
  useLazyListJiraFieldOptionsQuery,
  useGetJiraWebhookConfigQuery,
  useLazyGetJiraWebhookConfigQuery,
  useSetJiraWebhookConfigMutation,
  useRemoveJiraWebhookConfigMutation,
} = injectedRtkApi;
