import type { DesignSystemColor } from '@verticeone/design-system';
import { TaskStatus as TaskStatusType } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useTranslation } from 'react-i18next';
import { taskStatusToFilterStatus } from '../../../dataSource/utils';
import { useDurationFormatters } from '../../../../hooks/useDurationFormatters';
import { differenceInDays, differenceInHours, isAfter, isBefore, isToday, isTomorrow } from 'date-fns';
import { useFormatDate } from '@verticeone/utils/formatting';
import dayjs from 'dayjs';

type UseTaskStatusResult = {
  chipText: string;
  chipColor: DesignSystemColor;
  hoverText?: string;
  hoverTextColor?: DesignSystemColor;
  tooltip?: string;
};

export type UseTaskStatusProps = {
  status: TaskStatusType;
  createdAt: string;
  closedAt?: string;
  dueDate?: string;
};

const getDurationInBetween = (
  start: Date,
  end: Date,
  unit: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds'
) => {
  const startDate = dayjs(start).utc();
  const endDate = dayjs(end).utc();
  return startDate.diff(endDate, unit, false);
};

export const useTaskStatus = ({ status, dueDate, closedAt, createdAt }: UseTaskStatusProps): UseTaskStatusResult => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const filterStatus = taskStatusToFilterStatus(status, dueDate);
  const { formatDurationBetweenDates } = useDurationFormatters();

  const now = new Date();
  const createdAtDate = new Date(createdAt);

  // filterStatus includes multiple task statuses
  if (filterStatus === 'COMPLETED' && closedAt) {
    const closedAtDate = new Date(closedAt);

    if (dueDate) {
      const dueAtDate = new Date(dueDate);
      const closedOverdue = isAfter(closedAtDate, dueAtDate);
      let hoverTextColor: DesignSystemColor = 'neutral';
      if (status === 'COMPLETED') {
        hoverTextColor = closedOverdue ? 'error' : 'success';
      }
      return {
        chipText: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.FILTER.COMPLETED'),
        chipColor: 'success',
        hoverText: formatDate(closedAt),
        hoverTextColor,
        tooltip:
          closedOverdue && status === 'COMPLETED'
            ? t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.COMPLETED_LATE', {
                duration: formatDurationBetweenDates({ start: dueAtDate, end: closedAtDate }),
              })
            : undefined,
      };
    } else {
      // if due date is not defined then show just success chip with closed date below on hover
      return {
        chipText: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.FILTER.COMPLETED'),
        chipColor: 'success',
        hoverText: formatDate(closedAt),
        hoverTextColor: 'success',
      };
    }
  }

  if (dueDate) {
    const dueAtDate = new Date(dueDate);
    const overdueDays = differenceInDays(now, dueAtDate);
    const overdueHours = differenceInHours(now, dueAtDate, { roundingMethod: 'ceil' });

    const dueInDays = getDurationInBetween(dueAtDate, now, 'days');
    const dueToday = isToday(dueAtDate);
    const dueTomorrow = isTomorrow(dueAtDate);
    const dueInHours = getDurationInBetween(dueAtDate, now, 'hours');

    const isOverdue = isBefore(dueAtDate, now);
    if (isOverdue) {
      return {
        chipText:
          overdueDays === 0
            ? t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.OVERDUE_HOURS', { hours: overdueHours })
            : t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.OVERDUE_DAYS', { days: overdueDays }),
        chipColor: 'error',
        tooltip: t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.ACTIVE_FOR', {
          duration: formatDurationBetweenDates({ start: createdAtDate, end: now }),
        }),
      };
    } else {
      return {
        chipText: t(
          dueToday
            ? 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.DUE_IN_HOURS'
            : dueTomorrow
            ? 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.DUE_TOMORROW'
            : 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.DUE_IN_DAYS',
          { days: dueInDays, hours: dueInHours }
        ),
        chipColor: 'secondary',
        tooltip: t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.DUE_IN', {
          duration: formatDurationBetweenDates({ start: now, end: dueAtDate }),
        }),
      };
    }
  } else {
    return {
      chipText: t('INTELLIGENT_WORKFLOWS.TASKS_LIST.FILTER.IN_PROGRESS'),
      chipColor: 'secondary',
      tooltip: t('ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES.ACTIVE_FOR', {
        duration: formatDurationBetweenDates({ start: createdAtDate, end: now }),
      }),
    };
  }
};
