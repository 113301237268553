import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NoData, NoDataButton } from '@verticeone/design-system';
import { Stack, styled, useTheme } from '@mui/material';
import CableIcon from '@mui/icons-material/CableTwoTone';
import { ReactComponent as VerticeV } from 'vendor/vertice_v.svg';
import { ReactComponent as AwsLogo } from './aws.svg';
import { useRoutes } from '@verticeone/router/useRoutes';

const ICONS_HEIGHT = 30;

const IconsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),

  '> *': {
    width: 'auto',
    height: ICONS_HEIGHT,
  },
}));

const StyledCableIcon = styled(CableIcon)(({ theme }) => ({
  color: theme.palette.core.color5,
  opacity: 0.4,
}));

const NotConfigured = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD' });
  const navigate = useNavigate();
  const { palette } = useTheme();
  const routes = useRoutes();

  return (
    <Stack bgcolor={palette.background.default} height="100%">
      <Stack maxWidth="30rem" height="100%" margin="auto">
        <NoData
          icon={
            <IconsWrapper>
              <AwsLogo />
              <StyledCableIcon />
              <VerticeV />
            </IconsWrapper>
          }
          header={t(`NOT_CONFIGURED.HEADER`)}
          content={t(`NOT_CONFIGURED.CONTENT`)}
          button={
            <NoDataButton
              text={t(`NOT_CONFIGURED.BUTTON`)}
              onClick={() => navigate(routes.INTEGRATIONS.NATIVE.ABSOLUTE_PATH)}
            />
          }
        />
      </Stack>
    </Stack>
  );
};

export default NotConfigured;
