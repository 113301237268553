import { useTranslation } from 'react-i18next';
import { PageHeader, BreadcrumbsV2, Button } from '@verticeone/design-system';
import VendorSearchFilter from './filters/VendorSearchFilter';
import { CategoryFilter } from './filters/CategoryFilter';
import MyVendorButton from './filters/MyVendorButton';
import { Stack } from '@mui/material';
import { CreateRequestDialog } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/components/CreateRequestDialog/CreateRequestDialog';
import React, { useState } from 'react';

const VendorsPageHeader = () => {
  const { t } = useTranslation();
  const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);

  return (
    <>
      <Stack width="100%" flexDirection="row" justifyContent="space-between">
        <PageHeader
          breadcrumb={<BreadcrumbsV2 items={[{ label: t('SAAS.NAVBAR.SAAS') }, { label: t('SAAS.NAVBAR.VENDORS') }]} />}
          actions={
            <Stack direction="row" gap={2} alignItems="stretch" width="100%">
              <VendorSearchFilter />
              <CategoryFilter />
              <MyVendorButton />
            </Stack>
          }
          color="primary"
        />
        <Stack padding={8} paddingTop={8} gap={2}>
          <Button onClick={() => setCreateRequestDialogOpen(true)} color="secondary" variant="solid">
            {t('INTELLIGENT_WORKFLOWS.REQUESTS_LIST.CREATE_REQUEST_BUTTON')}
          </Button>
        </Stack>
      </Stack>
      <CreateRequestDialog
        open={createRequestDialogOpen}
        setOpen={(open) => setCreateRequestDialogOpen(open)}
        key={createRequestDialogOpen ? 'open' : 'closed'}
      />
    </>
  );
};

export default VendorsPageHeader;
