import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Loader, Text } from '@verticeone/design-system';
import type { ConnectionTestOutput } from '@vertice/slices';

type TestConnectionResponseProps = {
  isLoading: boolean;
  data?: ConnectionTestOutput;
};

const MultiLineContent = ({ text }: Partial<Record<'text', string>>) => (
  <Stack border="1px solid" borderColor="core.color3" borderRadius={2} padding={2}>
    <Text variant="code" size="S" color="text1" sx={{ whiteSpace: 'pre-line' }}>
      {text}
    </Text>
  </Stack>
);

const TestConnectionResponse = ({ isLoading, data }: TestConnectionResponseProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM.TEST_RESPONSE' });
  const isValidResponse = useMemo(() => data?.testResponse?.statusCode && data.testResponse.statusCode < 400, [data]);
  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return (
      <Text variant="body-regular" size="XS" color="inactive1">
        {t('NO_DATA')}
      </Text>
    );
  }

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Text variant="caption" size="XS" color="text2">
          {t('CODE')}
        </Text>
        <Text variant="body-regular" size="S" color={isValidResponse ? 'success1' : 'error1'}>
          {data?.testResponse?.statusCode}
        </Text>
      </Stack>

      <Stack gap={2}>
        <Text variant="caption" size="XS" color="text2">
          {t('HEADERS')}
        </Text>
        <MultiLineContent
          text={Object.entries(data?.testResponse?.headers ?? {})
            .map(([key, value]) => `${key}: ${value}`)
            .join('\n')}
        />
      </Stack>

      <Stack gap={2}>
        <Text variant="caption" size="XS" color="text2">
          {t('BODY')}
        </Text>
        <MultiLineContent text={data?.testResponse?.body ?? ''} />
      </Stack>
    </Stack>
  );
};

export default TestConnectionResponse;
