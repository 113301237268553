import { Button, IconWrapper, NoData, Text } from '@verticeone/design-system';
import { BugReportOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Link } from '@mui/material';

type UnknownErrorProps = {
  backButtonLabel?: string;
};

const REPORT_BUG_URL = undefined; //TODO provide link to Zendesk when available BUG-507

export const UnknownError = ({ backButtonLabel }: UnknownErrorProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'UNKNOWN_ERROR' });

  return (
    <Stack justifyContent="center" width="100%" height="100vh">
      <NoData
        icon={null}
        header={t('TITLE')}
        content={
          <Text variant="body-regular" color="text3" maxWidth={300} textAlign="center">
            {t('EXPLANATION')}
          </Text>
        }
        button={
          <Stack direction="row" gap={2}>
            <Button component={Link} href={'/'} underline="none" color="neutral" variant="ghost">
              {backButtonLabel ?? t('RETURN_TO_WEBSITE')}
            </Button>
            {REPORT_BUG_URL && (
              <Button
                component={Link}
                target="_blank"
                href={REPORT_BUG_URL}
                rel="noreferrer"
                underline="none"
                color="neutral"
                variant="solid"
                startIcon={<IconWrapper icon={BugReportOutlined} sx={{ mr: 1.5 }} />}
              >
                {t('REPORT_A_BUG')}
              </Button>
            )}
          </Stack>
        }
      />
    </Stack>
  );
};
