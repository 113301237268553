import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import 'react-querybuilder/dist/query-builder.css';

import FormSelectField2 from '../../../../../forms/fields/FormSelectField2';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../constants';

import { End } from '../../../../definitionsTypes';
import { EditEndFormData, END_NAME_CHAR_LIMIT } from './schema';
import { EndResultTypeOption, useEndResultTypeOptions } from './endResultOptions';
import FormTextField from '../../../../../forms/fields/FormTextField';

type EditEndFormProps = {
  onDirty: VoidFunction;
  resultType: End['resultType'];
};

const END_NAME_INPUT_ID = 'end-name-input';
const END_RESULT_SELECT_ID = 'end-result-select';
const END_NAME_CHAR_THRESHOLD = END_NAME_CHAR_LIMIT - 20;

export const EditEndForm: FC<EditEndFormProps> = ({ onDirty, resultType }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR' });

  const { formState, watch } = useFormContext<EditEndFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  const endResultOptions = useEndResultTypeOptions(resultType);

  const endName = watch('name');
  const endNameCharCount = endName.trim().length;
  const endNameCharCountLeft = END_NAME_CHAR_LIMIT - endNameCharCount;
  const isCharLimitHelperTextVisible = endName?.length && endName.length > END_NAME_CHAR_THRESHOLD;

  return (
    <Stack gap={8} direction="column" width="100%">
      <Stack gap={1} direction="column" width="100%">
        <TextFieldCaption label={t('EDIT_END.NAME')} size="XS" htmlFor={END_RESULT_SELECT_ID} />
        <FormTextField
          id={END_NAME_INPUT_ID}
          name="name"
          variant="outlined"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size="S"
          helperText={
            isCharLimitHelperTextVisible
              ? t('EDIT_END.CHAR_COUNT', { count: Math.max(0, endNameCharCountLeft) })
              : undefined
          }
        />
      </Stack>
      <Stack direction="column" width="100%">
        <TextFieldCaption label={t('EDIT_END.RESULT_TYPE')} size="XS" htmlFor={END_RESULT_SELECT_ID} />
        <FormSelectField2<EditEndFormData, EndResultTypeOption, false>
          id={END_RESULT_SELECT_ID}
          unboxValue={(selectValue) => selectValue?.value}
          isOptionDisabled={(option) => option.disabled ?? false}
          boxValue={(value) => endResultOptions.find((option) => option.value === value)}
          name="resultType"
          variant="outlined"
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          options={endResultOptions}
          isClearable={false}
          size="S"
        />
      </Stack>
    </Stack>
  );
};
