import useDashboardWidgetData from '../../../useDashboardWidgetData';

type RequestsListData = {
  totalNumberOfOverdueRequests: number;
  totalNumberOfRequests: number;
};

const FALLBACK_VALUE = 0;

const useRequestsListStatsData = () => {
  const { data, error, isFetching } = useDashboardWidgetData('RequestsList');
  const stats = data?.stats as RequestsListData;

  return {
    data: {
      totalNumberOfOverdueRequests: stats?.totalNumberOfOverdueRequests || FALLBACK_VALUE,
      totalNumberOfRequests: stats?.totalNumberOfRequests || FALLBACK_VALUE,
    },
    error,
    isFetching,
  };
};

export default useRequestsListStatsData;
