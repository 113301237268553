import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { RollingFrequency, useEvaluateCostModelMutation } from '@vertice/slices/src/openapi/codegen/contractCostAPI';
import { transformationsForAdvancedLinearApproximation } from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { isRollingRollFrequency } from '@vertice/core/src/modules/saas/contract/computed';
import dayjs from 'dayjs';
import { CostModelLineItem } from '../../../../../../../saas/contract/components/CostModelLineItemsTable';

export type ComputeCostsInput = {
  startDate: string;
  endDate?: string;
  rollingFrequency: RollingFrequency;
  lineItems: CostModelLineItem[];
};

type UseComputedCostsProps = {
  computeCostsInput: ComputeCostsInput;
};

export const useComputedCosts = (props: UseComputedCostsProps) => {
  const { computeCostsInput } = props;
  const { t } = useTranslation();

  const { formToCostModel } = transformationsForAdvancedLinearApproximation;
  const { enqueueSnackbar } = useSnackbar();

  const [calculateCosts, { isLoading, data }] = useEvaluateCostModelMutation();

  const computeAllCosts = useCallback(async () => {
    let { startDate, endDate, rollingFrequency, lineItems } = computeCostsInput;
    const isRollingContract = rollingFrequency && isRollingRollFrequency(rollingFrequency);
    const costModel = formToCostModel({ lineItems });

    if (isRollingContract) {
      endDate = dayjs(startDate).isBefore(dayjs()) ? dayjs().format('YYYY-MM-DD') : startDate;
    }

    try {
      await calculateCosts({ costModelRequest: { startDate, endDate, rollingFrequency, costModel } });
    } catch (error) {
      enqueueSnackbar(t('SNACKBAR.ERRORS.FAILED_TO_COMPUTE_COST'), { variant: 'error' });
    }
  }, [enqueueSnackbar, formToCostModel, t, computeCostsInput, calculateCosts]);

  useEffect(() => {
    void computeAllCosts();
  }, [computeCostsInput, computeAllCosts]);

  return { data, isLoading };
};
