import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { match } from 'ts-pattern';
import {
  hasDeadlineWithinDays,
  isOneOffContract,
  isPlannedForExpiration,
  isRollingContract,
} from '@vertice/core/src/modules/saas/contract/computed';
import { CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import {
  getFeDeadline,
  hasFeDeadlineOrRollingFrequency,
} from '@vertice/core/src/modules/saas/contract/components/Deadline/utils';
import DeadlineWithLabel from '@vertice/core/src/modules/saas/contract/components/Deadline/DeadlineWithLabel';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { DefaultHeader } from './DefaultHeader';
import { PlannedExpirationHeader } from './PlannedExpirationHeader';
import { SoonExpirationHeader } from './SoonExpirationHeader';
import { RollingHeader } from './RollingHeader';
import { getDeadlineOriginLabel } from './utils';

export type ContractNotYetRenewedContentBodyProps = {
  contract: Contract;
};

export const ContractNotYetRenewedCardHeader = ({ contract }: ContractNotYetRenewedContentBodyProps) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAccountContext();
  const isNonSaasFeatureEnabled = isFeatureEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG);

  if (isNonSaasFeatureEnabled) {
    return match({
      isOneOff: isOneOffContract(contract),
      isRolling: isRollingContract(contract),
      isSoonToExpire: hasDeadlineWithinDays(contract, 90),
      isPlannedForExpiration: isPlannedForExpiration(contract),
      deadline: getFeDeadline(contract),
    })
      .with({ isOneOff: false, isPlannedForExpiration: true }, (matched) => (
        <PlannedExpirationHeader deadline={matched.deadline} />
      ))
      .with({ isOneOff: false, isSoonToExpire: true, isPlannedForExpiration: false }, (matched) => (
        <SoonExpirationHeader deadline={matched.deadline} />
      ))
      .with({ isRolling: true }, () => <RollingHeader contract={contract} />)
      .with({ isOneOff: true }, () => (
        <DefaultHeader
          title={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
          date={contract.parts.contractual?.lifecycle?.startDate}
        />
      ))
      .with({ isOneOff: false }, (matched) => (
        <DefaultHeader
          title={getDeadlineOriginLabel(matched.deadline?.origin, t)}
          date={matched.deadline?.deadlineDate}
        />
      ))
      .exhaustive();
  }

  return (
    <HighlightedCardHeader size="S">
      <CardHeaderTitle text={t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.TITLE')} />
      <CardHeaderActions>
        {hasFeDeadlineOrRollingFrequency(contract) && <DeadlineWithLabel contract={contract} />}
      </CardHeaderActions>
    </HighlightedCardHeader>
  );
};
