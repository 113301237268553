import React from 'react';

import SelectApprovers from './SelectApprovers';
import { useGetAccountConfigQuery } from '@vertice/slices/src/openapi/codegen/contractWorkflowsV2Api';
import { useAccountContext } from '../account/AccountContext';
import { Loader } from '@verticeone/design-system';
import { Box } from '@mui/material';

type WorkflowsProps = {
  disabled?: boolean;
};
const Workflows = ({ disabled }: WorkflowsProps) => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetAccountConfigQuery({ accountId });

  if (isLoading) {
    return (
      <Box p={6} height={846}>
        <Loader />
      </Box>
    );
  }

  return (
    <SelectApprovers
      accountId={accountId}
      approvalConfiguration={data?.config?.approvalConfiguration}
      disabled={disabled}
    />
  );
};

export default Workflows;
