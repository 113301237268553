import type { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogHeader, Text, Button, Divider } from '@verticeone/design-system';

type DeleteNodeDialogProps = {
  onCancel: () => void;
  onDelete: () => void;
  isOpen: boolean;
  title: string;
  subTitle: string;
  description: string;
};

export const DeleteObjectDialog: FC<DeleteNodeDialogProps> = ({
  onCancel,
  onDelete,
  description,
  title,
  isOpen,
  subTitle,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS' });

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDelete();
  };

  const handleCancel = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onCancel();
  };

  return (
    <Dialog open={isOpen} size="M" onClose={handleCancel}>
      <DialogHeader>{title}</DialogHeader>
      <Divider />
      <DialogContent>
        <Text color="text2" variant="body-bold">
          {subTitle}
        </Text>
        <Text color="text3" variant="body-regular">
          {description}
        </Text>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCancel} variant="outline" color="neutral">
          {t('CANCEL')}
        </Button>
        <Button onClick={handleDelete} variant="solid" color="error">
          {t('DELETE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
