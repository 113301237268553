import { useMemo, useState } from 'react';
import { API_URLS, useApiFileFetching } from '@vertice/slices';
import { useAccountContext } from '../../../account/AccountContext';
import { autoDownloadBlob } from '@verticeone/utils/file';
import { useContracts } from '../components/ContractList/useContracts';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type UseContractListExportProps = {
  /**
   * Set filter if some contracts should be omitted from export (e.g. cancelled contracts in CFA)
   */
  filter?: (contract: ViewOnContract) => boolean;
};

export type UseContractListExportReturn = {
  canExportContracts: boolean;
  downloadFile: () => void;
  isExportInProgress: boolean;
};

const useContractListExport = (props?: UseContractListExportProps): UseContractListExportReturn => {
  const { filter } = props ?? {};
  const { t } = useTranslation();
  const { fetchFile } = useApiFileFetching();
  const { accountId } = useAccountContext();
  const { allViewsOnContracts } = useContracts({});
  const { enqueueSnackbar } = useSnackbar();
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const downloadFile = () => {
    setIsExportInProgress(true);
    void fetchFile(`${API_URLS.BASE_URLS.BFFE_SAAS}/accounts/${accountId!}/contracts/export`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv',
      },
    })
      .then((blob: Blob) => {
        autoDownloadBlob(blob, `export-${accountId}.csv`);
      })
      .catch(() => {
        enqueueSnackbar(t('SNACKBAR.ERRORS.FAILED_TO_EXPORT'), { variant: 'error' });
      })
      .finally(() => {
        setIsExportInProgress(false);
      });
  };

  const canExportContracts = useMemo(
    () => allViewsOnContracts && allViewsOnContracts.filter(filter ? filter : Boolean).length > 0,
    [allViewsOnContracts, filter]
  );

  return {
    canExportContracts,
    downloadFile,
    isExportInProgress,
  };
};

export default useContractListExport;
