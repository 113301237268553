import { bffeDashboardAPI as api } from '../../api/bffeDashboardAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiInfo: build.query<GetApiInfoApiResponse, GetApiInfoApiArg>({
      query: () => ({ url: `/info` }),
    }),
    getAvailableWidgets: build.query<GetAvailableWidgetsApiResponse, GetAvailableWidgetsApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/widgets/available` }),
    }),
    getDashboardWidget: build.query<GetDashboardWidgetApiResponse, GetDashboardWidgetApiArg>({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}/widgets/${queryArg.widgetId}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bffeDashboardAPICodegen };
export type GetApiInfoApiResponse = /** status 200 Api info */ {
  /** Api version */
  version: string;
};
export type GetApiInfoApiArg = void;
export type GetAvailableWidgetsApiResponse = /** status 200 List of available widgets */ {
  items: Widget[];
};
export type GetAvailableWidgetsApiArg = {
  /** Account ID */
  accountId: string;
};
export type GetDashboardWidgetApiResponse = /** status 200 Widget response */ Widget;
export type GetDashboardWidgetApiArg = {
  /** Widget ID */
  widgetId: string;
  /** Account ID */
  accountId: string;
};
export type Widget = {
  name: string;
  data?: object;
  stats?: object;
  cacheable?: boolean;
  ttl?: number;
};
export const {
  useGetApiInfoQuery,
  useLazyGetApiInfoQuery,
  useGetAvailableWidgetsQuery,
  useLazyGetAvailableWidgetsQuery,
  useGetDashboardWidgetQuery,
  useLazyGetDashboardWidgetQuery,
} = injectedRtkApi;
