import {
  BillingFrequency,
  ContractEntityCategory,
  PaymentTerms,
  RenewalType,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CommonIntakeFormFormData, CommonRenewalFormData } from '../types';
import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';

export type ContractType = 'FIXED' | 'ROLLING' | 'ONE_OFF';

export type AddExistingContractFormData = CommonIntakeFormFormData &
  CommonRenewalFormData & {
    type: 'add_existing_contract';
    annualCostOverride?: {
      enabled: boolean;
      amount: number;
    };
    totalCostOverride?: {
      enabled: boolean;
      amount: number;
    };
    paymentTerms?: PaymentTerms;
    startDate?: string;
    signDate?: string;
    billingFrequency: BillingFrequency | null;
    billingFrequencySameAsRolling: boolean;
    rollingFrequency: RollFrequency | null;
    billingFrequencyOther: string | null;
    contractLength?: number | null;
    contractCategory?: ContractEntityCategory;
    renewalDate?: string;
    autoRenewalDate?: string;
    vendorContactName?: string;
    vendorContactEmail?: string;
    renewalType: RenewalType | null;
    // for UI only
    contractType: ContractType | null;
    initiateRenewal: 'yes' | 'no' | null;
  };

export const AddExistingContractFormEntry = createTypedFormEntry<AddExistingContractFormData>();

export const isAddExistingContractFormData = (
  formData: CommonIntakeFormFormData
): formData is AddExistingContractFormData => formData.type === 'add_existing_contract';
