import { agentsAPI as api } from '../../api/agentsAPI';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createSession: build.mutation<CreateSessionApiResponse, CreateSessionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sessions`,
        method: 'POST',
        body: queryArg.createSessionRequest,
      }),
    }),
    deleteSession: build.mutation<DeleteSessionApiResponse, DeleteSessionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/sessions/${queryArg.sessionId}`,
        method: 'DELETE',
      }),
    }),
    converseWithAgent: build.mutation<ConverseWithAgentApiResponse, ConverseWithAgentApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/agents/${queryArg.agentId}/converse`,
        method: 'POST',
        body: queryArg.converseWithAgentRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as agentsAPI };
export type CreateSessionApiResponse = /** status 200 Session created successfully */ {
  sessionId?: string;
};
export type CreateSessionApiArg = {
  accountId: string;
  createSessionRequest: {
    userSessionAttributes?: {
      [key: string]: any;
    };
  };
};
export type DeleteSessionApiResponse = unknown;
export type DeleteSessionApiArg = {
  accountId: string;
  sessionId: string;
};
export type ConverseWithAgentApiResponse = /** status 200 Conversation completed successfully */ {
  response?: string;
};
export type ConverseWithAgentApiArg = {
  accountId: string;
  agentId: string;
  converseWithAgentRequest: {
    message: string;
    sessionId: string;
  };
};
export const { useCreateSessionMutation, useDeleteSessionMutation, useConverseWithAgentMutation } = injectedRtkApi;
