import { PredefinedFormDef } from '../shared/types';
import { BaselineOfferForm } from './BaselineOfferForm';
import { zodSchema, FormData } from './schema';

export const formDef: PredefinedFormDef<FormData> = {
  urnSuffix: /^form\/saas\/offers\/baseline\/v\d+$/,
  component: BaselineOfferForm,
  schema: zodSchema,
  draftEnabled: true,
  getDefaultValues: (getValue) => ({
    vendor: getValue('vendor'),
    products: getValue('products'),
    contractCurrency: getValue('contractCurrency'),
    parentAccountId: getValue('parentAccountId'),

    baselineOffer: {
      id: getValue('baselineOffer.id'),
      name: getValue('baselineOffer.name', 'Baseline Offer'),
      rollingFrequency: getValue('baselineOffer.rollingFrequency'),
      baseCurrency: getValue('baselineOffer.baseCurrency', getValue('contractCurrency')),
      startDate: getValue('baselineOffer.startDate'),
      endDate: getValue('baselineOffer.endDate'),
      autoRenewalDate: getValue('baselineOffer.autoRenewalDate'),
      autoRenewalWaived: getValue('baselineOffer.autoRenewalWaived', false),
      billingFrequency: getValue('baselineOffer.billingFrequency'),
      billingFrequencyOther: getValue('baselineOffer.billingFrequencyOther'),
      paymentTerms: getValue('baselineOffer.paymentTerms'),
      paymentTermsOther: getValue('baselineOffer.paymentTermsOther'),
      purchasedFromReseller: getValue('baselineOffer.purchasedFromReseller', false),
      resellerName: getValue('baselineOffer.resellerName'),
      vendor: getValue('baselineOffer.vendor', getValue('vendor')),
      products: getValue('baselineOffer.products', getValue('products', [])),
      concessions: getValue('baselineOffer.concessions', []),
      computedCosts: getValue('baselineOffer.computedCosts'),
      effectiveCosts: getValue('baselineOffer.effectiveCosts'),
      overrideEffectiveCost: getValue('baselineOffer.overrideEffectiveCost', false),
      additionalNotes: getValue('baselineOffer.additionalNotes'),
    },
  }),
  transformOutput: ({ baselineOffer }) => ({
    baselineOffer: {
      ...baselineOffer,
      // clear fields that are not relevant for rolling offers
      endDate: baselineOffer.rollingFrequency === 'NO' ? baselineOffer.endDate : undefined,
      autoRenewalDate: baselineOffer.rollingFrequency === 'NO' ? baselineOffer.autoRenewalDate : undefined,
      autoRenewalWaived: baselineOffer.rollingFrequency === 'NO' ? baselineOffer.autoRenewalWaived : undefined,
    },
  }),
};
