import {
  DynamicFormField,
  dynamicFormFieldSchema,
  primitiveDynamicFormFieldSchema,
} from '../../../../../../forms/dynamicForms';
import * as z from 'zod';
export type GenericFormField = DynamicFormField;

const dynamicFieldExtensionSchema = z.object({ variable: z.any().nullable().optional() });
export const genericFormFieldSchema = z.intersection(dynamicFormFieldSchema, dynamicFieldExtensionSchema);
export const primitiveFormFieldSchema = primitiveDynamicFormFieldSchema;
