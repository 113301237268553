import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { Variable } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { NoJiraVariables } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/NoJiraVariables';
import {
  createJiraVariablesGroups,
  enhanceInputFieldsName,
} from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/utils';
import { getBaseName } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraSync/utils';
import { JIRA_FIELD_PREFIX_V2 } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/utils';
import {
  variableMapperMapperFields,
  VariableMapperRowFormBase,
} from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/VariableMapper/formSchema';
import { WorkflowVariables } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/VariableMapper/types';
import { VariableMapper } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/VariableMapper/VariableMapper';
import { FC, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { TriggerMapping } from './types';

const toBaseName = (mapping: TriggerMapping) => {
  const baseMapping: TriggerMapping = {};
  Object.entries(mapping).forEach(([compoundKey, value]) => (baseMapping[getBaseName(compoundKey)] = value));
  return baseMapping;
};

const toFormVariables = (mapping: TriggerMapping) => {
  return Object.entries(mapping).map(([key, value]) => ({
    from: key,
    to: value,
    mappingType: 'Value',
  }));
};

const cleanNotSetRows = (rows: VariableMapperRowFormBase[]) => {
  return rows.filter((row) => row.to && row.from);
};

/** Custom hook, because parameter "variables" is created via RHF watch, and since we need to check also changes in objects in array and not only
 *  array itself, there has to be special hook (as recommended in docs). useEffect would not trigger.
 */
const useVariablesHasChanged = (variables: VariableMapperRowFormBase[]) => {
  const [changeDetected, setChangeDetected] = useState<boolean | undefined>();
  const prevState = useRef<string | undefined>();
  const validRows = cleanNotSetRows(variables);

  if (prevState.current === undefined) {
    prevState.current = JSON.stringify(validRows);
  } else if (prevState.current !== JSON.stringify(validRows)) {
    setChangeDetected((old) => !old);
    prevState.current = JSON.stringify(validRows);
  }

  return changeDetected;
};

export const fieldSyncFormSchema = z.object({
  ...variableMapperMapperFields,
});

export type FieldSyncFormData = z.infer<typeof fieldSyncFormSchema>;

type FieldSyncFormProps = {
  initialMapping: TriggerMapping;
  otherVariables?: Variable[];
  workflowVariables: WorkflowVariables;
  setWorkflowVariables: (variables: WorkflowVariables) => void;
  onChange: (mapping: TriggerMapping) => void;
  resetMapping?: boolean;
  isLoading: boolean;
  disabled: boolean;
};

/**
 * Showing VariableMapper and transforming values from FieldMapping to be used in VariableMapping and back
 */
export const FieldSyncForm: FC<FieldSyncFormProps> = ({
  initialMapping,
  otherVariables,
  workflowVariables,
  setWorkflowVariables,
  onChange,
  resetMapping,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();

  const defaultVariables = toFormVariables(toBaseName(initialMapping));

  /*** Setup form ***/
  const formHandlers = useForm<FieldSyncFormData>({
    resolver: zodResolver(fieldSyncFormSchema),
    defaultValues: {
      variables: defaultVariables,
      requiredVariables: [],
    },
  });
  const { setValue } = formHandlers;

  const variables = formHandlers.watch('variables');

  const hasChanged = useVariablesHasChanged(variables);

  useEffect(() => {
    if (resetMapping !== undefined) {
      setValue('variables', []);
    }
  }, [resetMapping, setValue]);

  useEffect(() => {
    if (hasChanged !== undefined) {
      const validRows = cleanNotSetRows(variables);

      const mapping: TriggerMapping = {};
      for (const row of validRows) {
        mapping[enhanceInputFieldsName(workflowVariables, otherVariables || [], row, false, JIRA_FIELD_PREFIX_V2)] =
          row.to;
      }
      onChange(mapping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged]);

  return (
    <FormProvider {...formHandlers}>
      <Stack gap={3}>
        <VariableMapper<FieldSyncFormData>
          formFieldName={'variables'}
          requiredFormFieldName={'requiredVariables'}
          fromWorkflow={false}
          workflowVariables={workflowVariables}
          otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER')}
          otherVariables={otherVariables}
          components={{ noOtherVariables: <NoJiraVariables /> }}
          variableMapperRowProps={{
            createOtherVariableGroups: createJiraVariablesGroups,
            createVariableOrigin: { kind: 'vertice-account-service', id: 'jiraSync', label: 'Jira Sync' },
          }}
          allowCreateVariable={true}
          setWorkflowVariables={setWorkflowVariables}
          disabled={disabled}
          isLoading={isLoading}
          mapperRowsCount={defaultVariables.length}
        />
      </Stack>
    </FormProvider>
  );
};
