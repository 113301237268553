import { useMemo, useState } from 'react';
import {
  useGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { usePolling } from '@vertice/core/src/hooks/usePolling';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type UseRevokeProps = {
  providerCode: string;
  onRevokeCompleted: () => void;
};

export const useRevoke = ({ providerCode, onRevokeCompleted }: UseRevokeProps) => {
  const { accountId } = useAccountContext();
  const [waitingForRevoke, setWaitingForRevoke] = useState(false);
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();
  const [testIntegration, { isLoading: isTestingIntegration }] = useTestAccountIntegrationMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const {
    data: integration,
    isLoading: isFetchingIntegration,
    refetch: refetchIntegrationDetails,
  } = useGetAccountIntegrationQuery({ accountId: accountId!, integrationId: providerCode }, { skip: !accountId });

  const handshakeCommitPolling = usePolling(async () => {
    if (!isFetchingIntegration) {
      void refetchIntegrationDetails();
      if (integration?.status !== 'INACTIVE') {
        void refetchIntegrationDetails();
      } else if (integration?.status === 'INACTIVE') {
        await revokeIntegration({ accountId: accountId!, integrationId: providerCode });
        handshakeCommitPolling.stop();
        setWaitingForRevoke(false);
        onRevokeCompleted();
      } else if (integration?.connection?.status === 'FAILED') {
        enqueueSnackbar(t('INTEGRATIONS.ACCOUNTING.ERRORS.UNEXPECTED_ERROR'), { variant: 'error' });
        handshakeCommitPolling.stop();
        onRevokeCompleted();
      }
    }
  }, 2000);

  const revoke = async () => {
    await testIntegration({
      accountId: accountId!,
      integrationId: providerCode,
      testName: 'revoke:access',
    });
    setWaitingForRevoke(true);
    handshakeCommitPolling.start();
  };

  const isRevoking = useMemo(
    () => waitingForRevoke || isIntegrationRevokeInProgress || isTestingIntegration,
    [waitingForRevoke, isIntegrationRevokeInProgress, isTestingIntegration]
  );

  return { isRevoking, revoke };
};
