import { AddOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { FieldArray, FieldArrayPath, FieldValues, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@verticeone/design-system';
import { TaskDefinition } from '../../../definitionsTypes';
import { Variable } from '../types';
import { VariableMapperHeader } from './VariableMapperHeader';
import { VariableMapperRow, VariableMapperRowProps } from './VariableMapperRow';
import { VariableMapperRowFormBase, VariableMapperRowFormData } from './formSchema';
import { WorkflowVariables } from './types';
import { VariableMapperSkeleton } from './VariableMapperSkeleton';

export const DEFAULT_MAPPING_TYPE = 'JMESPathExpression';

export const VariableMapperBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  borderRadius: 12,
  padding: theme.spacing(4),
}));

type HasRichSelector = {
  requiredRows?: boolean;
  restRows?: boolean;
};

type VariableMapperComponentsProps = {
  noOtherVariables: ReactNode;
};

export type VariableMapperProps<T extends FieldValues> = {
  requiredFormFieldName: FieldArrayPath<T>;
  formFieldName: FieldArrayPath<T>;
  fromWorkflow: boolean; // To distinguish the direction of mapping
  workflowVariables: WorkflowVariables;
  otherVariables?: Variable[];
  otherVariablesTitle: string;
  components: VariableMapperComponentsProps;
  variableMapperRowProps: Pick<
    VariableMapperRowProps<T>,
    'createOtherVariableGroups' | 'task' | 'createVariableOrigin'
  >;
  setWorkflowVariables?: (variables: WorkflowVariables) => void;
  task?: TaskDefinition;
  allowCreateVariable?: boolean;
  hasRichSelector?: HasRichSelector;
  isLoading?: boolean;
  mapperRowsCount?: number;
  disabled?: boolean;
};

/**
 * Component maps between workflowVariables and some other variables - otherVariables.
 * Position of each column is set by fromWorkflow
 * @param workflowVariables
 * @param otherVariables Variables from some other group/system
 * @param fromWorkflow Specifies position of each column workflow vs other variables
 * @param allowCreateVariable Allowing creating workflow variables from VariablePicker component
 * @param components Components to pass to show states specific to otherVariables resource
 * @param setWorkflowVariables To update local state when new workflow variable is created
 * @param formFieldName path to variables mapping in form
 * @param requiredFormFieldName path to required variables mapping in form
 * @param otherVariablesTitle Column title for other variables
 * @param mapperRowsCount Initial number of rows. Initial content of rows may vary as some data might be created after api calls to variable servers complete, but the number is known.
 */
export const VariableMapper = <T extends FieldValues>({
  formFieldName,
  requiredFormFieldName,
  otherVariables,
  fromWorkflow,
  workflowVariables,
  setWorkflowVariables,
  otherVariablesTitle,
  allowCreateVariable = false,
  components,
  variableMapperRowProps,
  hasRichSelector,
  isLoading,
  mapperRowsCount,
  disabled = false,
}: VariableMapperProps<T>) => {
  const { t } = useTranslation();

  const { fields: requiredFields } = useFieldArray<T>({
    name: requiredFormFieldName,
  });

  const { fields, append, remove } = useFieldArray<T>({
    name: formFieldName,
  });

  const selectedOtherVariables = useMemo(() => {
    const selected = [];
    for (const field of fields as unknown as VariableMapperRowFormBase[]) {
      selected.push(fromWorkflow ? field.to : field.from);
    }
    for (const field of requiredFields as unknown as VariableMapperRowFormBase[]) {
      selected.push(fromWorkflow ? field.to : field.from);
    }
    return selected;
  }, [fields, fromWorkflow, requiredFields]);

  const handleRemoveRow = useCallback((index: number) => remove(index), [remove]);

  const handleAddRow = () => {
    const newOption = { from: '', to: '', mappingType: DEFAULT_MAPPING_TYPE } as FieldArray<T, typeof formFieldName>;
    append(newOption);
  };

  if (!isLoading && (otherVariables === undefined || otherVariables?.length === 0)) {
    return components.noOtherVariables;
  }

  return (
    <>
      <VariableMapperHeader
        worflowVariablesTitle={t('INTELLIGENT_WORKFLOWS.VARIABLE_MAPPER.COLUMN_HEADER.VERTICE')}
        otherVariablesTitle={otherVariablesTitle}
        fromWorkflow={fromWorkflow}
      />
      {isLoading ? (
        <VariableMapperSkeleton rows={mapperRowsCount} fromWorkflow={fromWorkflow} />
      ) : (
        <>
          {/* Required fields */}
          {requiredFields.map((field, index) => {
            const rowField = field as unknown as VariableMapperRowFormData;
            return (
              <VariableMapperRow
                field={rowField}
                index={index}
                key={field.id}
                fromWorkflow={fromWorkflow}
                formFieldPrefix={requiredFormFieldName}
                workflowVariables={workflowVariables}
                setWorkflowVariables={setWorkflowVariables}
                otherVariables={otherVariables || []}
                selectedOtherVariables={selectedOtherVariables}
                allowCreateVariable={allowCreateVariable}
                hasRichSelector={!!hasRichSelector?.requiredRows}
                disabled={disabled}
                {...variableMapperRowProps}
              />
            );
          })}
          {/* Other fields */}
          {fields.map((field, index) => {
            const rowField = field as unknown as VariableMapperRowFormData;
            return (
              <VariableMapperRow
                field={rowField}
                index={index}
                key={field.id}
                fromWorkflow={fromWorkflow}
                formFieldPrefix={formFieldName}
                onRemove={handleRemoveRow}
                workflowVariables={workflowVariables}
                setWorkflowVariables={setWorkflowVariables}
                otherVariables={otherVariables || []}
                selectedOtherVariables={selectedOtherVariables}
                allowCreateVariable={allowCreateVariable}
                hasRichSelector={!!hasRichSelector?.restRows}
                disabled={disabled}
                {...variableMapperRowProps}
              />
            );
          })}
        </>
      )}
      <IconButton
        icon={AddOutlined}
        variant="outline"
        size="S"
        color="neutral"
        onClick={handleAddRow}
        disabled={disabled || isLoading}
        aria-label={t('ENTITIES.CONTRACT.LABELS.LIST_FILTERS.NEW_VIEW')}
      />
    </>
  );
};
