import { createPortableRoutes } from '@verticeone/router/utils';

export const integrationsRoutes = createPortableRoutes({
  INTEGRATIONS: {
    path: '/integrations',
    children: {
      NATIVE: {
        path: 'native',
        children: {
          ACCOUNTING: {
            path: 'accounting/:providerId',
          },
          USAGE: {
            path: 'usage',
            children: {
              OKTA: {
                path: 'okta',
              },
              ENTRA: {
                path: 'entra',
              },
              ONELOGIN: {
                path: 'onelogin',
              },
              JUMPCLOUD: {
                path: 'jumpcloud',
              },
              GOOGLE: {
                path: 'google',
              },
            },
          },
          NOTIFICATIONS: {
            path: 'notifications',
            children: {
              SLACK: {
                path: 'slack',
              },
            },
          },
          WORKFLOWS: {
            path: 'workflows',
            children: {
              JIRA: {
                path: 'jira',
              },
              VISO_TRUST: {
                path: 'viso_trust',
              },
            },
          },
          AWS: {
            path: 'aws',
          },
        },
      },
      CUSTOM: {
        path: 'custom',
        children: {
          CREATE: {
            path: 'create/:integrationType/:authType',
          },
          VIEW: {
            path: 'view/:integrationId',
          },
          EDIT: {
            path: 'edit/:integrationId',
          },
        },
      },
    },
  },
} as const);
