import { useCallback, useEffect, useState } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system';
import { autoDownload } from '@verticeone/utils/file';
import { FileMetaData } from '@vertice/slices';
import {
  useDeleteFileMutation,
  useLazyGetPreSignedLinkQuery,
  useListFilesQuery,
  useUploadFileMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { isTaskDocumentFullPath } from '../../../intelligentWorkflows/utils/isTaskDocumentFullPath';

type FileIdentifier = {
  fullPath: string;
  name: string;
};

type UseFilesProps = {
  pathPrefix: string;
  onChange?: (files: FileIdentifier[]) => void;
  skipData?: boolean;
};

const useFiles = (props: UseFilesProps) => {
  const { pathPrefix, onChange, skipData } = props;

  const [files, setFiles] = useState<FileAccepted[]>([]);
  const { accountId } = useAccountContext();

  const [uploadFile] = useUploadFileMutation();
  const [removeFile] = useDeleteFileMutation();
  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();

  const { data: requestTaskAssets, isFetching } = useListFilesQuery(
    { accountId: accountId, 'path+': `${pathPrefix}` },
    { refetchOnMountOrArgChange: true, skip: skipData }
  );

  useEffect(() => {
    onChange?.(files.map(toFileIdentifier(pathPrefix)));
  }, [files, pathPrefix, onChange]);

  useEffect(() => {
    if (!requestTaskAssets?.files) return;

    const acceptedFiles = requestTaskAssets.files
      .filter(({ type }) => type === 'FILE')
      .filter(({ fullPath }) => isTaskDocumentFullPath(fullPath))
      .sort((f1, f2) => f1.fileName.localeCompare(f2.fileName))
      .map(fromMetadataToFile);

    setFiles(acceptedFiles);
  }, [requestTaskAssets, setFiles]);

  const uploadFiles = useCallback(
    async (newFiles: FileAccepted[]) => {
      const normalizedNewFiles = newFiles.map(normalizeAcceptedFile);
      setFiles((oldFiles) => [...oldFiles, ...normalizedNewFiles].sort(compareAcceptedFilesByName));

      const fileUploadRequests = newFiles.map((newFile) => {
        const filePath = getFilePath(pathPrefix, newFile.file.name);
        return uploadFile({ accountId, 'path+': filePath, body: newFile.file }).unwrap();
      });

      await Promise.all(fileUploadRequests);
    },
    [accountId, pathPrefix, uploadFile]
  );

  const deleteFile = useCallback(
    async (removedFiles: FileAccepted[]) => {
      const removedNames = removedFiles.map((f) => f.file.name);
      setFiles((oldFiles) => oldFiles.filter(({ file: oldFile }) => !removedNames.includes(oldFile.name)));

      const fileDeleteRequests = removedFiles.map((removedFile) => {
        const filePath = getFilePath(pathPrefix, removedFile.file.name);
        return removeFile({ accountId, 'path+': filePath }).unwrap();
      });

      await Promise.all(fileDeleteRequests);
    },
    [accountId, pathPrefix, removeFile]
  );

  const downloadFile = async (file: FileAccepted | FileRejected) => {
    const fileName = file.file.name;
    const filePath = getFilePath(pathPrefix, fileName);

    const result = await getPreSignedLink({
      accountId: accountId,
      'path+': filePath,
      responseContentDisposition: 'attachment',
    }).unwrap();

    if (!result.preSignedLink) return;
    autoDownload(result.preSignedLink, fileName);
  };

  return {
    files: files,
    isFetching: isFetching && !files,
    uploadFiles,
    deleteFile,
    downloadFile,
  };
};

const fromMetadataToFile = ({ fileName, fileSize, lastModified }: FileMetaData): FileAccepted => ({
  file: {
    name: fileName,
    size: fileSize,
    lastModified: lastModified ? new Date(lastModified).getTime() : 0,
  } as unknown as File,
});

const getFilePath = (fullPath: string, fileName: string) => `${fullPath}/${fileName}`;

const normalizeAcceptedFile = ({ file }: FileAccepted): FileAccepted => {
  return { file: new File([file], file.name, { lastModified: new Date().getTime() }) };
};

const toFileIdentifier =
  (fullPath: string) =>
  ({ file }: FileAccepted): FileIdentifier => ({
    name: file.name,
    fullPath: getFilePath(fullPath, file.name),
  });

const compareAcceptedFilesByName = (f1: FileAccepted, f2: FileAccepted) => {
  return f1.file.name.localeCompare(f2.file.name);
};

export default useFiles;
