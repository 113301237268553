import React, { useMemo } from 'react';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { GridHeaderCell } from '@verticeone/design-system';
import { CostModelLineItem } from '../types';
import MultilineHeaderTooltip from './shared/MultilineHeaderTooltip';

export const TotalCostHeaderCell = (params: GridColumnHeaderParams<CostModelLineItem, any, any>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.COST_MODEL_LINE_ITEMS_TABLE.TOTAL_COST_TOOLTIP' });
  const lines = useMemo(() => [t('LINE_1'), t('LINE_2')], [t]);

  return <GridHeaderCell {...params} tooltip={{ content: <MultilineHeaderTooltip lines={lines} /> }} />;
};
