import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Button, IconWrapper } from '@verticeone/design-system';
import type { FilterOption, VariablesGroup } from '../../../workflowSchema/WorkflowEditor/VariableSelector/types';

type useFilterOptionsProps = {
  variablesGroups: Array<VariablesGroup>;
  totalCount: number;
};

type GroupFiltersProps = {
  filterOptions: Array<FilterOption>;
  selectedOptionId: string;
  selectOptionId: (option: string) => void;
};

const useFilterOptions = ({ variablesGroups, totalCount }: useFilterOptionsProps): Array<FilterOption> => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.FILTER',
  });

  return useMemo(() => {
    return [
      {
        id: 'all',
        label: t('ALL') + ` (${totalCount})`,
      },
      ...variablesGroups
        .filter((variablesGroup) => !!variablesGroup.filterLabel)
        .map((group) => ({
          id: group.id,
          label: group.filterLabel + ` (${group.count})`,
        })),
    ];
  }, [variablesGroups, totalCount, t]);
};

export const useGetFilterOptions = (variablesGroups: Array<VariablesGroup>) => {
  const totalCount = variablesGroups.reduce((count, group) => count + group.count, 0);
  const filterOptions = useFilterOptions({ totalCount, variablesGroups });

  return {
    totalCount,
    filterOptions,
  };
};

const GroupFilters = ({ filterOptions, selectedOptionId, selectOptionId }: GroupFiltersProps) => (
  <Stack direction="row" gap={1} flexWrap="wrap">
    {filterOptions.map((filterOption) => (
      <Button
        key={filterOption.id}
        color={filterOption.id === selectedOptionId ? 'secondary' : 'neutral'}
        variant={filterOption.id === selectedOptionId ? 'ghost' : 'outline'}
        onClick={() => selectOptionId(filterOption.id)}
        size="XS"
      >
        {filterOption.icon && <IconWrapper size="XS" icon={filterOption.icon} />}
        {filterOption.label}
      </Button>
    ))}
  </Stack>
);

export default GroupFilters;
