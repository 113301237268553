import { Task } from '../../../../definitionsTypes';
import { isVerticeServiceConfiguration } from '../../../../pocWorkflowSchema';
import { checkServiceTaskHasDetailDef } from '../../../../task/TaskDrawer/ServiceTaskDetail/utils';
import { getLastPartDiversioned } from '../../../../utils';
import { TaskNodeState } from '../../../model/types';

export const JIRA_FIELD_PREFIX_V2 = 'issue.fields.';
// eslint-disable-next-line no-useless-escape
export const JIRA_FIELD_PREFIX_V2_ESCAPED = JIRA_FIELD_PREFIX_V2.replace('.', `\.`);
export const REQUIRED_JIRA_CREATE_FIELDS = ['summary', 'description'];
export const REQUIRED_JIRA_SYNC_FIELDS = ['status', 'resolution'];

const JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX_ARCHIVED = 'service/jira/createTicket';
const JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX_ARCHIVED = 'service/jira/syncTicket';
const JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX = 'service/integrations/jira/createTicket';
const JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX = 'service/integrations/jira/syncTicket';
export const GENERIC_INTEGRATION_OUTBOUND_SERVICE_URN_SUFFIX = 'service/integrations/sendOutbound';

export const ISSUE_KEY_INPUT_FIELD_VALUE = '__issueKey__';
export const SUMMARY_INPUT_FIELD_VALUE = '__summary__';
// Not added by user, but needed to show some specific information
export const SUPPORTING_INPUT_FIELD_VALUES = [SUMMARY_INPUT_FIELD_VALUE];

export const JIRA_CREATE_TICKET_SERVICE_URN_SUFFIXES = [
  JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX_ARCHIVED,
  JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX,
];
export const JIRA_SYNC_TICKET_SERVICE_URN_SUFFIXES = [
  JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX_ARCHIVED,
  JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX,
];

const EDITABLE_SERVICES_URN_SUFFIXES = [
  GENERIC_INTEGRATION_OUTBOUND_SERVICE_URN_SUFFIX,
  JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX_ARCHIVED,
  JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX_ARCHIVED,
  JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX,
  JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX,
];

/**
 * Among its configurations, there is some which can be configured via editable drawer
 */
export const checkServiceTaskIsEditable = (task: Task) => {
  const serviceConfigUrn = task.configurations?.find(isVerticeServiceConfiguration)?.resourceUrn;

  return EDITABLE_SERVICES_URN_SUFFIXES.includes(getLastPartDiversioned(serviceConfigUrn) ?? '');
};

export const checkServiceTaskHasDetail = (state?: TaskNodeState) => {
  const resourceUrn = state?.resourceUrn;

  if (resourceUrn && checkServiceTaskHasDetailDef(resourceUrn)) {
    return true;
  }

  const serviceConfigUrn = getLastPartDiversioned(resourceUrn);

  return serviceConfigUrn ? EDITABLE_SERVICES_URN_SUFFIXES.includes(serviceConfigUrn) : false;
};
