import React from 'react';
import { useTranslation } from 'react-i18next';
import CompletedRequestsByOutcomeGraph from './CompletedRequestsByOutcomeGraph';
import dayjs from 'dayjs';
import { ChartCard, ChartLayout, ChartProvider } from '../ChartCard';
import useCompletedRequestsByOutcomeData, { CompletedRequestsByOutcomeData } from './useCompletedRequestsByOutcomeData';
import { Placeholder } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import ShowAllButton from '../ShowAllButton';
import EmptyStateNoData from '../EmptyStateNoData';
import { AccountTreeOutlined } from '@mui/icons-material';

const DAYS = 90;

type CompletedRequestsByOutcomeCardProps = {
  data: CompletedRequestsByOutcomeData['data'];
  isEmpty: boolean;
};

const CompletedRequestsByOutcomeCard = ({ data, isEmpty }: CompletedRequestsByOutcomeCardProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD',
  });
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  const handleClick = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS, null, {
      search: { status: 'all' },
    });
  };

  return (
    <ChartCard
      title={t('COMPLETED_REQUESTS_BY_OUTCOME.TITLE')}
      subtitle={t('LAST_X_DAYS', { count: DAYS })}
      testId="completed-requests-by-outcome"
      footer={!isEmpty && <ShowAllButton onClick={handleClick} label={t('COMPLETED_REQUESTS_BY_OUTCOME.SHOW_ALL')} />}
    >
      <ChartLayout>
        {isEmpty ? (
          <EmptyStateNoData
            testId="completed-requests-by-outcome-empty"
            title={t('COMPLETED_REQUESTS_BY_OUTCOME.NO_DATA.TITLE')}
            description={t('COMPLETED_REQUESTS_BY_OUTCOME.NO_DATA.DESCRIPTION')}
            icon={AccountTreeOutlined}
            minHeight={412}
          />
        ) : (
          <CompletedRequestsByOutcomeGraph data={data} />
        )}
      </ChartLayout>
    </ChartCard>
  );
};

const LoadableCompletedRequestsByOutcomeCard = () => {
  const { data, isFetching, isEmpty } = useCompletedRequestsByOutcomeData();

  if (isFetching) {
    return (
      <ChartCard.Skeleton testId="completed-requests-by-outcome-skeleton" footer={<ShowAllButton.Skeleton />}>
        <Placeholder variant="rounded" width="100%" height="372px" />
      </ChartCard.Skeleton>
    );
  }

  return <CompletedRequestsByOutcomeCard data={data} isEmpty={isEmpty} />;
};

const CompletedRequestsByOutcomeCardWithContext = () => {
  const initialMonths = 6;
  const endDate = dayjs().subtract(1, 'months').startOf('month');
  const startDate = endDate.subtract(initialMonths - 1, 'months').startOf('month');

  return (
    <ChartProvider
      defaultRange={{
        startDate,
        endDate,
      }}
    >
      <LoadableCompletedRequestsByOutcomeCard />
    </ChartProvider>
  );
};

export default CompletedRequestsByOutcomeCardWithContext;
