import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetIntegrationConfigQuery } from '@vertice/slices';
import { useListCatalogResourcesQuery } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle, Loader, Text } from '@verticeone/design-system';
import { ServiceCatalogResource } from '@vertice/core/src/modules/intelligentWorkflows/catalogResource/types';
import { isServiceCatalogResource } from '@vertice/core/src/modules/intelligentWorkflows/catalogResource/utils';
import { TriggerSettings } from './types';
import { WorkflowTriggers } from './WorkflowTriggers';

export const WorkflowTriggersCard = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: catalogResourcesData, isLoading: isLoadingCatalogResources } = useListCatalogResourcesQuery({
    accountId,
  });
  const { data: jiraIntegrationConfigData, isLoading: isLoadingJiraIntegrationConfig } = useGetIntegrationConfigQuery({
    accountId,
  });

  const triggerSettings = useMemo(() => {
    if (jiraIntegrationConfigData && catalogResourcesData?.items) {
      const settings: Record<string, { config: TriggerSettings; workflowService: ServiceCatalogResource }> = {};

      for (const key of Object.keys(jiraIntegrationConfigData.triggerSettings)) {
        const settingsItem = jiraIntegrationConfigData.triggerSettings[key];
        const catalogResource = catalogResourcesData.items.find((service) =>
          service.urn.startsWith(settingsItem.workflow)
        );
        const workflowService = isServiceCatalogResource(catalogResource) ? catalogResource : undefined;
        if (workflowService) {
          settings[key] = {
            config: settingsItem,
            workflowService: workflowService,
          };
        }
      }

      return settings;
    }
  }, [jiraIntegrationConfigData, catalogResourcesData]);

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
      </CardHeader>
      <Stack gap={1} p={6}>
        {isLoadingCatalogResources || isLoadingJiraIntegrationConfig ? (
          <Box p={6}>
            <Loader />
          </Box>
        ) : (
          triggerSettings &&
          Object.keys(triggerSettings).map((key) => (
            <WorkflowTriggers
              key={key}
              workflowType={key}
              workflowService={triggerSettings[key].workflowService}
              config={triggerSettings[key].config}
            />
          ))
        )}
      </Stack>
    </Card>
  );
};
