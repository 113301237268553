import { FC } from 'react';
import { createPortal } from 'react-dom';
import { Stack, StackProps, styled, useTheme } from '@mui/material';
import { DragIndicatorOutlined } from '@mui/icons-material';
import { baseDragOverlayStyles, IconWrapper, Text } from '@verticeone/design-system';
import { DragOverlay, useDraggable } from '@dnd-kit/core';

import type { DnDNode } from './types';

const DragIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.core.color3,
  transition: 'color 0.3s ease-in-out',
}));

const DnDNodeTileContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingLeft: 0,
  borderRadius: theme.spacing(3),
  border: `1px solid ${theme.palette.core.color3}`,
  backgroundColor: theme.palette.core.bg,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    border: `1px solid ${theme.palette.core.color4}`,
    boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '50' }),
    [DragIconWrapper]: {
      color: theme.palette.neutral.color1,
    },
  },
  '&:active': {
    border: `1px solid ${theme.palette.core.color5}`,
  },
}));

type DnDNodeTileContentProps = {
  node: DnDNode;
};

const DnDNodeTileContent: FC<DnDNodeTileContentProps> = ({ node }) => {
  const { name, description, icon } = node;

  return (
    <>
      <DragIconWrapper icon={DragIndicatorOutlined} />
      <Stack gap={0.5} flex={1}>
        <Text variant="button-regular" size="S" color="text1">
          {name}
        </Text>
        {description ? (
          <Text variant="caption" size="XS" color="text3">
            {description}
          </Text>
        ) : null}
      </Stack>
      {icon ? <IconWrapper icon={icon} /> : null}
    </>
  );
};

export type DnDNodeTileProps = DnDNodeTileContentProps & {
  component?: StackProps['component'];
};

export const DnDNodeTileDraggable: FC<DnDNodeTileProps> = ({ node, component }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: node.id,
    data: { node },
  });

  return (
    <DnDNodeTileContainer
      ref={setNodeRef}
      forwardedAs={component}
      direction="row"
      alignItems="center"
      {...listeners}
      {...attributes}
      sx={{
        opacity: isDragging ? 0.2 : 1,
      }}
    >
      <DnDNodeTileContent node={node} />
    </DnDNodeTileContainer>
  );
};

export const DnDNodeTileDragOverlay: FC<Partial<DnDNodeTileProps>> = ({ node, component }) => {
  const theme = useTheme();

  return createPortal(
    <DragOverlay dropAnimation={null}>
      {node ? (
        <DnDNodeTileContainer
          id={node.id}
          forwardedAs={component}
          direction="row"
          alignItems="center"
          sx={{ ...baseDragOverlayStyles({ theme }) }}
        >
          <DnDNodeTileContent node={node} />
        </DnDNodeTileContainer>
      ) : null}
    </DragOverlay>,
    document.getElementById('root')!
  );
};
