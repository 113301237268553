import React from 'react';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useDataGridContext } from '@verticeone/design-system';
import { DatePicker } from '@verticeone/design-system';
import { useInputFocusableRef } from '../../hooks/useInputFocusableRef';

const DataGridDatePickerCell = ({ id, field, value, hasFocus }: GridRenderCellParams) => {
  const { size } = useDataGridContext();
  const apiRef = useGridApiContext();
  const { color } = useDataGridContext();
  const ref = useInputFocusableRef({ hasFocus });

  return (
    <DatePicker
      onChange={(newValue) => {
        void apiRef.current.setEditCellValue({ id, field, value: newValue });
      }}
      size={size}
      value={value ?? null}
      color={color}
      textFieldProps={{ ref }}
    />
  );
};

export default DataGridDatePickerCell;
