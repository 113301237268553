import { Stack, useTheme } from '@mui/material';
import { IntelligentWorkflowsLayout } from '../components/IntelligentWorkflowsLayout';
import { useSearchParams } from 'react-router-dom';
import { IconWrapper, TextField } from '@verticeone/design-system';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@mui/icons-material';

import { TemplateCard } from './components/TemplateCard';
import { TemplateCards } from './components/TemplateCards';
import { TEMPLATES } from './constants';
import { useRequestTemplate } from './hooks/useRequestTemplate';
import { TemplateNoData } from './components/TemplateNoData';

const FILTER_KEY = 'search';

export const WorkflowTemplatesPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TEMPLATES' });
  const { palette } = useTheme();
  const { requestTemplate, RequestTemplateDialog } = useRequestTemplate();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchFilter = searchParams.get(FILTER_KEY) ?? '';
  const setSearchFilter = (filter: string) => {
    setSearchParams((prev) => {
      if (filter === '') {
        prev.delete(FILTER_KEY);
      } else {
        prev.set(FILTER_KEY, filter);
      }
      return prev;
    });
  };

  // Debounced search to not start animation early
  const [debouncedSearchFilter] = useDebounce(searchFilter, 200);
  // Debounced boolean value if we had a search string to determine proper animation
  const [hadSearch] = useDebounce(debouncedSearchFilter, 200);

  const filteredTemplates = debouncedSearchFilter
    ? TEMPLATES.filter((template) => template.name.toLowerCase().includes(debouncedSearchFilter.toLowerCase()))
    : TEMPLATES;

  // Debouncing makes the animation smoother
  const [hasResults] = useDebounce(filteredTemplates.length > 0, 200);

  return (
    <Stack>
      <IntelligentWorkflowsLayout currentPageName="Templates" withDivider>
        <Stack direction="column" gap={6} mt={6}>
          <Stack width={256}>
            <TextField
              placeholder={t('SEARCH_PLACEHOLDER')}
              size="S"
              variant="outlined"
              color="primary"
              endAdornment={<IconWrapper icon={SearchOutlined} htmlColor={palette.text.color4} />}
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
          </Stack>
          {hasResults ? (
            <TemplateCards>
              {filteredTemplates.map((card, index) => (
                <TemplateCard
                  key={card.id}
                  index={index}
                  shouldAnimateInitial={!searchFilter && !hadSearch}
                  onActionClick={requestTemplate}
                  {...card}
                />
              ))}
            </TemplateCards>
          ) : (
            <TemplateNoData onReset={() => setSearchFilter('')} />
          )}
        </Stack>
      </IntelligentWorkflowsLayout>
      <RequestTemplateDialog />
    </Stack>
  );
};
