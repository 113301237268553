import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Palette, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { LoadableComponent } from '@vertice/components';
import { ApplicationWithMeasureReports } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { useGetLastUsed, prepareDataForApplicationAndMeasures } from '../helpers';
import { UsageBox, UsageBoxBadge, UsageBoxContent, UsageBoxTitle } from './UsageBox';

type UsageDataProps = {
  application?: ApplicationWithMeasureReports;
};

const getColor = (severity: 'success' | 'warning' | 'error' | 'info', palette: Palette) => {
  switch (severity) {
    case 'success':
      return palette.success.color2;
    case 'warning':
      return palette.warning.color2;
    case 'error':
      return palette.error.color2;
    default:
      return palette.info.color2;
  }
};

const UsageRow = ({ application }: UsageDataProps) => {
  const { palette } = useTheme();

  const { t } = useTranslation();

  const applicationData = useMemo(
    () =>
      prepareDataForApplicationAndMeasures({
        application,
      }),
    [application]
  );

  const hasSeatBasedLicense = application?.license?.type === 'SEAT_BASED';
  const showUtilizationValue = applicationData.utilization !== null;

  const { getLastUsedFromNowInString } = useGetLastUsed();

  const showLastUsedSection = !!applicationData.lastUsed;
  const showActiveUsersSection = applicationData.activeUsers !== null;
  const showUtilizationSection = hasSeatBasedLicense;

  if (!showLastUsedSection && !showActiveUsersSection && !showUtilizationSection) {
    return null;
  }

  const gridSize = 12 / [showLastUsedSection, showActiveUsersSection, showUtilizationSection].filter(Boolean).length;

  const activeUsersTitle = applicationData.activeUsers?.licenses
    ? 'APPLICATIONS.TOOLTIPS.ACTIVE_USERS.TITLE_WITH_LICENSE'
    : 'APPLICATIONS.TOOLTIPS.ACTIVE_USERS.TITLE';

  return (
    <Grid container>
      <LoadableComponent isLoading={false}>
        {showLastUsedSection && (
          <Grid item xs={12} md={gridSize}>
            <UsageBox>
              <UsageBoxTitle
                tooltip={{
                  header: t('APPLICATIONS.TOOLTIPS.LAST_USED.TITLE'),
                  content: t('APPLICATIONS.TOOLTIPS.LAST_USED.TOOLTIP'),
                }}
              >
                {t('INTEGRATIONS.USAGE.LAST_ACTIVITY')}
              </UsageBoxTitle>
              <UsageBoxContent text={getLastUsedFromNowInString(applicationData.lastUsed)} dataTestId="lastUsed" />
            </UsageBox>
          </Grid>
        )}
        {showActiveUsersSection && (
          <Grid item xs={12} md={gridSize}>
            <UsageBox>
              <UsageBoxTitle
                tooltip={{
                  header: t(activeUsersTitle),
                  content: t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.TOOLTIP'),
                }}
              >
                {t(activeUsersTitle)}
              </UsageBoxTitle>
              <UsageBoxContent
                text={applicationData?.activeUsers?.users}
                dataTestId="activeUsers"
                append={applicationData.activeUsers!.licenses ? `/${applicationData.activeUsers!.licenses}` : ''}
              />
              <UsageBoxBadge
                text={applicationData?.activeUsers?.delta.value}
                severity={applicationData.activeUsers!.delta.severity || 'info'}
                append="%"
              />

              <Text
                variant="caption"
                size="M"
                ml={1}
                color={getColor(applicationData.activeUsers!.delta.severity, palette)}
              >
                {t('APPLICATIONS.TOOLTIPS.ACTIVE_USERS.BADGE_DESCRIPTION')}
              </Text>
            </UsageBox>
          </Grid>
        )}
        {showUtilizationSection && (
          <Grid item xs={12} md={gridSize}>
            <UsageBox>
              <UsageBoxTitle
                tooltip={{
                  header: t('APPLICATIONS.TOOLTIPS.UTILIZATION.TITLE'),
                  content: t('APPLICATIONS.TOOLTIPS.UTILIZATION.TOOLTIP'),
                }}
              >
                {t('INTEGRATIONS.USAGE.UTILIZATION')}
              </UsageBoxTitle>
              {showUtilizationValue && (
                <UsageBoxContent text={applicationData.utilization} append="%" dataTestId="utilization" />
              )}
              {!showUtilizationValue && !hasSeatBasedLicense && (
                <Text variant="heading" size="L">
                  {t('APPLICATIONS.TOOLTIPS.UTILIZATION.NO_LICENSED_SEATS')}
                </Text>
              )}
            </UsageBox>
          </Grid>
        )}
      </LoadableComponent>
    </Grid>
  );
};

export default UsageRow;
