import { Stack } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import { ConversationSection } from '../../request/RequestDetailPage/sections';

export const TaskCommentsSection = ({
  accountId,
  requestId,
  taskRef,
}: {
  accountId: string;
  requestId: string;
  taskRef: string;
}) => {
  return (
    <>
      <Divider orientation="vertical" />
      <Stack bgcolor="white" minHeight="100%">
        <Stack minHeight={0} width={384} height="100%">
          <ConversationSection height="100%" variant="none" request={{ accountId, requestId }} sourceRef={taskRef} />
        </Stack>
      </Stack>
    </>
  );
};
