import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogHeader, DialogActions, Text } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { useCallback, useRef } from 'react';
import { useDialogKeyActions } from './useDialogKeyActions';

type DeleteWorkflowDialogProps = {
  versionName: string;
  onClose: () => void;
  open: boolean;
  onComplete: () => void;
  isSubmitting?: boolean;
};

export const DeleteWorkflowDialog = ({
  versionName,
  open,
  isSubmitting,
  onClose,
  onComplete,
}: DeleteWorkflowDialogProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.DELETE_DIALOG',
  });
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback(() => {
    if (isSubmitting || !open) return;

    onComplete();
  }, [isSubmitting, open, onComplete]);

  useDialogKeyActions({
    onEscape: onClose,
    onEnter: handleSubmit,
    ref: dialogRef,
  });

  return (
    <Dialog open={open} onClose={onClose} size="M">
      <Stack ref={dialogRef}>
        <DialogHeader>{t('TITLE')}</DialogHeader>
        <DialogContent>
          <Stack gap={2}>
            <Text variant="body-bold" size="M" color="text1">
              {t('MESSAGE', { versionName })}
            </Text>
            <Text variant="body-regular" size="M" color="text3">
              {t('WARNING')}
            </Text>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="plain" color="primary" size="M" disabled={isSubmitting}>
            {t('CANCEL')}
          </Button>
          <Button
            onClick={onComplete}
            variant="solid"
            color="primary"
            size="M"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('DELETE')}
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
