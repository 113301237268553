import React, { useCallback } from 'react';
import { Card, CardHeader, CardHeaderTitle } from '@verticeone/design-system';
import { useGetVendorByIdQuery } from '@vertice/slices';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import { Grid } from '@verticeone/design-system';
import { LinkSection } from './components/LinkSection';
import { InfoField } from './components/InfoField';
import { StackWithDivider } from './components/StackWithDivider';
import { isNil } from 'lodash';
import { IconLinkButton } from './components/IconLinkButton';
import { LinkedIn, Twitter } from '@mui/icons-material';

export type VendorInfoCardProps = {
  vendorId: string;
};

export const VendorInfoCard = ({ vendorId }: VendorInfoCardProps) => {
  const { t } = useTranslation();
  const { data: vendor, isLoading } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });

  const topProducts = vendor?.topProducts?.join(', ') || undefined;
  const topCategories = vendor?.topCategories?.join(', ') || undefined;
  const publiclyTraded = isNil(vendor?.publiclyTradedFlag)
    ? undefined
    : vendor.publiclyTradedFlag
    ? t('COMMON.YES')
    : t('COMMON.NO');

  const fieldVisible = useCallback(
    (fieldValue: unknown) => {
      return isLoading || !isNil(fieldValue);
    },
    [isLoading]
  );

  return (
    <Card minWidth={380} height="100%">
      <CardHeader size="S">
        <CardHeaderTitle text={t('VENDOR.ABOUT.TITLE', { vendor: vendor?.name })} />
      </CardHeader>
      <Stack direction="row" height="100%">
        <StackWithDivider flexGrow={1} p={6} gap={4}>
          <InfoField
            label={t('VENDOR.ABOUT.LABELS.COMPANY_BIO')}
            value={vendor?.description}
            expandable={true}
            isLoading={isLoading}
          />
          <Grid container hideIfEmpty={true} spacing={4}>
            {fieldVisible(vendor?.category) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField label={t('VENDOR.ABOUT.LABELS.CATEGORY')} value={vendor?.category} isLoading={isLoading} />
              </Grid>
            )}
            {fieldVisible(vendor?.yearFounded) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField
                  label={t('VENDOR.ABOUT.LABELS.YEAR_FOUNDED')}
                  value={vendor?.yearFounded}
                  isLoading={isLoading}
                />
              </Grid>
            )}
            {fieldVisible(publiclyTraded) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField
                  label={t('VENDOR.ABOUT.LABELS.PUBLICLY_TRADED')}
                  value={publiclyTraded}
                  isLoading={isLoading}
                />
              </Grid>
            )}
          </Grid>
          <Grid container hideIfEmpty={true} spacing={4}>
            {fieldVisible(vendor?.country) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField label={t('VENDOR.ABOUT.LABELS.COUNTRY')} value={vendor?.country} isLoading={isLoading} />
              </Grid>
            )}
            {fieldVisible(vendor?.revenue) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField label={t('VENDOR.ABOUT.LABELS.REVENUE')} value={vendor?.revenue} isLoading={isLoading} />
              </Grid>
            )}
            {fieldVisible(vendor?.numberOfEmployees) && (
              <Grid item xs={12} sm={6} lg={4}>
                <InfoField
                  label={t('VENDOR.ABOUT.LABELS.EMPLOYEE_COUNT')}
                  value={vendor?.numberOfEmployees}
                  isLoading={isLoading}
                />
              </Grid>
            )}
          </Grid>
          <InfoField label={t('VENDOR.ABOUT.LABELS.TOP_CATEGORIES')} value={topCategories} isLoading={isLoading} />
          <InfoField label={t('VENDOR.ABOUT.LABELS.TOP_PRODUCTS')} value={topProducts} isLoading={isLoading} />
        </StackWithDivider>
        <Divider orientation="vertical" />
        <Stack flexShrink={0} flexBasis={232} p={6} gap={4}>
          <Stack gap={4} flexGrow={1}>
            <LinkSection title={t('VENDOR.ABOUT.LABELS.GENERAL_LINKS')} links={vendor?.generalLinks} />
            <LinkSection title={t('VENDOR.ABOUT.LABELS.G2_LINKS')} links={vendor?.g2Links} />
          </Stack>
          <Stack direction="row" gap={1}>
            <IconLinkButton icon={LinkedIn} label={t('VENDOR.ABOUT.LABELS.LINKEDIN')} url={vendor?.linkedIn} />
            <IconLinkButton icon={Twitter} label={t('VENDOR.ABOUT.LABELS.TWITTER')} url={vendor?.twitter} />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
