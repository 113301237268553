import { FieldDef } from '../types';
import {
  getRollingFrequencyJsonSchema,
  getRollingFrequencyJsonSchemaFromMetadata,
  getRollingFrequencyPresenterDefault,
  getRollingFrequencyPresenterSchema,
} from './utils';
import { RollingFrequencyFieldType } from './types';
import { RollingFrequencyFieldBuilderCard } from './RollingFrequencyFieldBuilderCard';
import { RollingFrequencyFieldPresenter } from './RollingFrequencyFieldPresenter';
import { RollingFrequencyFieldBuilderDialog } from './RollingFrequencyFieldBuilderDialog';

export type { RollingFrequencyFieldType, RollingFrequencyFieldMetadata } from './types';
export { rollingFrequencyFieldSchema, rollingFrequencyFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<RollingFrequencyFieldType> = {
  type: 'ROLLING_FREQUENCY',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.ROLLING_FREQUENCY',
  builderCard: RollingFrequencyFieldBuilderCard,
  builderDialog: RollingFrequencyFieldBuilderDialog,
  presenter: RollingFrequencyFieldPresenter,
  getPresenterDefault: getRollingFrequencyPresenterDefault,
  getPresenterSchema: getRollingFrequencyPresenterSchema,
  getJsonSchemaFromMetadata: getRollingFrequencyJsonSchemaFromMetadata,
  getJsonSchema: getRollingFrequencyJsonSchema,
};
