import { useAccountContext } from '../../../../../../account/AccountContext';
import { useLoggedUser } from '@verticeone/auth/src';
import {
  useGetCustomListFiltersConfigurationQuery,
  useUpdateCustomListFiltersConfigurationMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useCallback, useContext, useEffect } from 'react';
import { Filter } from '../types';
import { ContractListContext } from '../../../../contexts/ContractListContext';

export type UseSavedFiltersConfigurationParams = {
  skip?: boolean;
};

export type UseSavedFiltersConfigurationReturn = {
  isLoading: boolean;
  saveFilterConfiguration: (filters: Filter[]) => Promise<void>;
};

const useSavedFiltersConfiguration = ({
  skip,
}: UseSavedFiltersConfigurationParams = {}): UseSavedFiltersConfigurationReturn => {
  const { setFiltersVisible } = useContext(ContractListContext);
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { data: savedConfiguration, isLoading } = useGetCustomListFiltersConfigurationQuery(
    {
      accountId,
      userId,
    },
    { skip }
  );
  const [save] = useUpdateCustomListFiltersConfigurationMutation();

  useEffect(() => {
    const loadedFilters = savedConfiguration?.filters;
    if (loadedFilters) {
      setFiltersVisible(loadedFilters as Filter[]);
    }
  }, [setFiltersVisible, savedConfiguration]);

  const saveFilterConfiguration = useCallback(
    async (filters: Filter[]) => {
      // Let's be optimistic and set the visible filters right away
      setFiltersVisible(filters as Filter[]);

      if (!skip) {
        // Save configuration on BE
        await save({
          accountId,
          userId,
          updateCustomListFiltersConfigurationRequest: {
            filters: filters,
          },
        });
      }

      return;
    },
    [accountId, save, setFiltersVisible, skip, userId]
  );

  return {
    isLoading,
    saveFilterConfiguration,
  };
};

export default useSavedFiltersConfiguration;
