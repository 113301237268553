import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ProductColDef, ProductListColumn } from '../types';
import { assertExhausted } from '@verticeone/utils/logic';
import { Text } from '@verticeone/design-system';
import { TierCell, EditableLicensesCell, EditableTierCell } from '../cells';
import { valueComparator, tierComparator } from '../utils';

export const useProductExtraColumnBuilder = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.PRODUCT_LIST' });

  const buildColumn = (column: ProductListColumn): ProductColDef | null => {
    switch (column) {
      case ProductListColumn.LICENSE_TYPE:
        return {
          field: 'licenseType',
          headerName: t('TIER'),
          renderCell: (params: GridRenderCellParams) => <TierCell {...params} />,
          renderEditCell: (params: GridRenderCellParams) => <EditableTierCell {...params} />,
          sortComparator: tierComparator,
          disableColumnMenu: true,
          editable: true,
          flex: 1.3,
        };
      case ProductListColumn.NUMBER_OF_LICENSES:
        return {
          field: 'numberOfLicences',
          headerName: t('LICENSES'),
          renderCell: (params) => <Text variant="caption">{params.row.numberOfLicences ?? ''}</Text>,
          renderEditCell: (params) => <EditableLicensesCell {...params} />,
          sortComparator: valueComparator,
          disableColumnMenu: true,
          editable: true,
          flex: 1,
        };
      default:
        assertExhausted(column);
    }
    return null;
  };

  return { buildColumn };
};
