import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetSavingsDashboardPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeSaasAPI';
import { DataRowModel, FooterRowModel } from './types';
import { useMemo } from 'react';
import { vendorCellValueGetter } from './cells/VendorCell';
import { departmentCellValueGetter } from '@vertice/core/src/modules/saas/contract/components/ContractList/Cells/DepartmentCell';
import doubleCurrencyMoneyCellValueGetter from './cells/doubleCurrencyMoneyCellValueGetter';
import totalSavingsCellValueGetter from './cells/totalSavingsCellValueGetter';
import { useExchangeToAccountCurrency } from '@vertice/core/src/modules/currency/useExchangeToAccountCurrency';
import savingsVsInitialProposalCellValueGetter from './cells/savingsVsInitialProposalCellValueGetter';

import {
  getAnnualAdditionalSavings,
  getAnnualFutureSavings,
  getAnnualScopeReductionSavings,
} from '@vertice/core/src/modules/saas/savings/computed';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type UseCompletedContractsProps = {
  department?: string;
};

const useCompletedContractsData = ({ department }: UseCompletedContractsProps) => {
  const { accountId } = useAccountContext();
  const { exchangeToAccountCurrency, accountCurrency } = useExchangeToAccountCurrency();

  const { data, isLoading: isLoadingViewOnContracts } = useGetSavingsDashboardPaginatedQuery({
    accountId,
    maxResults: 100, // More items can cause time-outs
  });

  const { data: contractsStatsData, isLoading: isLoadingStats } = useGetContractsStatisticsQuery({ accountId });

  const tableRows = useMemo(() => {
    const filteredContracts = department
      ? data?.contracts?.filter((view) => view.contract.classification?.departmentId === department)
      : data?.contracts;

    return filteredContracts?.map((row): DataRowModel => {
      const enhanceWithAccountCurrencyValue = (contractCurrencyValue: number | undefined) =>
        doubleCurrencyMoneyCellValueGetter(
          contractCurrencyValue,
          row.contract,
          accountCurrency,
          exchangeToAccountCurrency
        );
      return {
        isFooterRow: false,
        id: row.contract.record.contractId,
        viewOnContract: row,
        vendor: vendorCellValueGetter(row),
        department: departmentCellValueGetter(row),
        initialVendorProposal: enhanceWithAccountCurrencyValue(row.contract.parts.overview?.annualCostWithoutVertice),
        negotiatedCost: enhanceWithAccountCurrencyValue(row.contract.parts.overview?.annualCostWithVertice),
        totalSavings: totalSavingsCellValueGetter(row.contract, accountCurrency, exchangeToAccountCurrency),
        savingsVsInitial: savingsVsInitialProposalCellValueGetter(
          row.contract,
          accountCurrency,
          exchangeToAccountCurrency
        ),
        additionalSavings: enhanceWithAccountCurrencyValue(getAnnualAdditionalSavings(row.contract)),
        futureSavings: enhanceWithAccountCurrencyValue(getAnnualFutureSavings(row.contract)),
        scopeReduction: enhanceWithAccountCurrencyValue(getAnnualScopeReductionSavings(row.contract)),
      };
    });
  }, [department, data?.contracts, accountCurrency, exchangeToAccountCurrency]);

  const footerRow = useMemo(
    () =>
      tableRows?.reduce<FooterRowModel>(
        (acc, row) => ({
          ...acc,
          initialVendorProposal: acc.initialVendorProposal + (row.initialVendorProposal?.accountCurrencyValue ?? 0),
          negotiatedCost: acc.negotiatedCost + (row.negotiatedCost?.accountCurrencyValue ?? 0),
          totalSavings: acc.totalSavings + (row.totalSavings?.accountCurrencyValue ?? 0),

          savingsVsInitial: acc.savingsVsInitial + (row.savingsVsInitial?.accountCurrencyValue ?? 0),
          additionalSavings: acc.additionalSavings + (row.additionalSavings?.accountCurrencyValue ?? 0),
          futureSavings: acc.futureSavings + (row.futureSavings?.accountCurrencyValue ?? 0),
          scopeReduction: acc.scopeReduction + (row.scopeReduction?.accountCurrencyValue ?? 0),
        }),
        {
          id: 'footer',
          isFooterRow: true,
          initialVendorProposal: 0,
          negotiatedCost: 0,
          totalSavings: 0,
          // We prefer the value from backend to avoid rounding deviations
          totalSavingsPercentage: (contractsStatsData?.stats.averageSavingsPercentage ?? 0) / 100,

          savingsVsInitial: 0,
          additionalSavings: 0,
          futureSavings: 0,
          scopeReduction: 0,
        }
      ),
    [contractsStatsData?.stats.averageSavingsPercentage, tableRows]
  );

  return useMemo(
    () => ({
      tableRows,
      footerRow,
      isLoading: isLoadingViewOnContracts || isLoadingStats,
    }),
    [tableRows, footerRow, isLoadingViewOnContracts, isLoadingStats]
  );
};

export default useCompletedContractsData;
