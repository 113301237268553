import { FC, useMemo } from 'react';
import { NodeId } from '../../../types';
import { ProcessDefinition } from '../../../../../definitionsTypes';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { ContractReviewMapping } from './ContractReviewMapping';
import { useContractReviewFormResource } from './hooks/useContractReviewFormDefinition';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';

type ContractReviewFormEditProps = {
  nodeId: NodeId;
  processDefinition: ProcessDefinition;
};

export const ContractReviewFormEdit: FC<ContractReviewFormEditProps> = (props) => {
  const { nodeId, processDefinition } = props;
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.EDIT_USER_TASK' });

  const { isLoading, contractVariables } = useContractReviewFormResource();
  const { requestVariables } = useVariablesAvailableInNode({ nodeId, processDefinition });

  const availableVariables = useMemo(() => {
    return requestVariables.filter(({ origin }) => origin.id !== nodeId);
  }, [requestVariables, nodeId]);

  if (isLoading || !contractVariables) return <></>;

  return (
    <EditorDrawerGroup title={t('TITLES.TASK_FIELDS')}>
      <Stack gap={1}>
        <Text variant="caption" size="XS" color="text2">
          {t('CONTRACT_REVIEW.FIELD_MAPPING_TITLE')}
        </Text>
        <Text variant="body-regular" size="S" color="text4">
          {t('CONTRACT_REVIEW.FIELD_MAPPING_DESCRIPTION')}
        </Text>
      </Stack>
      <ContractReviewMapping contractVariables={contractVariables} requestVariables={availableVariables} />
    </EditorDrawerGroup>
  );
};
