import { AdvancedLinearApproximationCostModel, ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isAdvancedLinearApproximationModel } from '../costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { isValidConcessionItem } from '../costModels/AdvancedLinearApproximation/utils';

export const filterConcessionsFromCostModel = (costModel?: ContractModel2) => {
  if (!isAdvancedLinearApproximationModel(costModel)) return costModel;

  const model = costModel?.model as AdvancedLinearApproximationCostModel;
  const items = model.items.filter((item) => !isValidConcessionItem(item));

  return { ...costModel, model: { ...model, items } } as ContractModel2;
};
