import useDashboardWidgetData from '../../useDashboardWidgetData';
import { getTableData } from '../../utils';
import { groupBy, keyBy, range, uniq, sumBy } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { Filter, useAverageRequestTimesContext } from './AverageRequestTimesContext';
import { MONTHS } from './constants';
import { EmptyState, LoadableData } from '../../types';

export type FilterOptions = {
  serviceName: string[];
};

type TableData = {
  columns: string[];
  types: string[];
  data: string[][];
};

type Item = {
  requestCount: number;
  averageDurationInHours: number;
  serviceName: string;
  month: string;
};

type TransformedItem = {
  month: string;
  requestCount: number;
  averageDurationInHours: number;
  [key: string]: number | string;
};

export type EnhancedData = Record<string, { averageDurationInHours: number }>;

export type AverageRequestTimesData = EmptyState &
  LoadableData<{
    values: {
      id: string;
      name: string;
      type: string;
      isVisible: boolean;
      data: (number | null)[];
    }[];
    enhancedData: EnhancedData;
    categories: string[];
    filterOptions: FilterOptions;
    stats: {
      averageDurationInHours: number;
      total: number;
    };
  }>;

const fillMissingMonthsData = <DataType extends { month: string }>(
  data: Array<DataType>,
  startDate: Dayjs,
  numberOfMonths = 6,
  fallbackData: Partial<DataType>
): Array<DataType> => {
  const dataByDate = keyBy(data, (p) => dayjs(p.month).format('YYYY-MM-DD'));

  return range(numberOfMonths)
    .map((i) => {
      const monthStartStr = startDate.subtract(i, 'month').format('YYYY-MM-DD');

      return (
        dataByDate[monthStartStr] ?? {
          month: monthStartStr,
          ...fallbackData,
        }
      );
    })
    .sort((a, b) => dayjs(a.month).diff(dayjs(b.month)));
};

const transformTableData = (data: Item[], filter: Filter) => {
  const groupedByMonth = groupBy(data, 'month');

  const emptyStateData = uniq(data.map((item) => item.serviceName)).reduce(
    (acc, serviceName) => ({
      ...acc,
      [serviceName]: 0,
    }),
    {}
  );

  return Object.keys(groupedByMonth).map((month) => {
    return groupedByMonth[month].reduce(
      (acc: TransformedItem, item) => {
        if (item.serviceName === filter.serviceName || filter.serviceName === 'All') {
          acc[item.serviceName] = item.averageDurationInHours;
          acc.requestCount = item.requestCount;
          acc.averageDurationInHours = item.averageDurationInHours;
        }
        acc.month = item.month;
        return acc;
      },
      { month, requestCount: 0, averageDurationInHours: 0, ...emptyStateData }
    );
  });
};

const calculateAverageDurationInHours = (rangeData: TransformedItem[]) => {
  const totalWeightedDuration = sumBy(
    rangeData,
    (data) => (data.averageDurationInHours || 0) * (data.requestCount || 0)
  );
  const totalRequests = sumBy(rangeData, (data) => data.requestCount || 0);
  return totalWeightedDuration / totalRequests;
};

const calculateData = ({ widgetData, filter }: { widgetData: Widget | undefined; filter: Filter }) => {
  const dashboardData = widgetData?.data as TableData;

  const tableData = dashboardData
    ? (getTableData(dashboardData) as Item[]).map((item) => ({
        ...item,
        month: dayjs(item.month).startOf('month').format('YYYY-MM-DD'),
      }))
    : [];

  const transformedData = transformTableData(tableData, filter) as TransformedItem[];
  const startDate = dayjs().startOf('month').subtract(1, 'month');
  const serviceNames = uniq(tableData.map((item) => item.serviceName)).sort();
  const emptyStateData = serviceNames.reduce(
    (acc, serviceName) => ({
      ...acc,
      [serviceName]: 0,
    }),
    {}
  );

  const rangeData = fillMissingMonthsData<TransformedItem>(transformedData, startDate, MONTHS, emptyStateData);

  const enhancedData = rangeData.reduce((acc, data) => {
    acc[data.month] = {
      averageDurationInHours: data.averageDurationInHours || 0,
    };

    return acc;
  }, {} as EnhancedData);

  const categories = rangeData.map((data) => data.month);

  const values = serviceNames
    .filter((serviceName) => serviceName !== 'All')
    .map((serviceName) => {
      return {
        id: serviceName,
        name: serviceName,
        type: 'line',
        isVisible: filter.serviceName === 'All' || serviceName === filter.serviceName,
        data: rangeData.map((data) => Number(data[serviceName])),
      };
    });

  const isEmpty = !tableData.length;
  const isFilteredEmpty =
    !isEmpty && values.filter((item) => item.isVisible).every((item) => item.data.every((value) => !value));

  return {
    data: {
      values:
        isEmpty || isFilteredEmpty
          ? [
              {
                id: '',
                name: '',
                type: 'column',
                isVisible: true,
                data: categories.map(() => 0),
              },
            ]
          : values,
      enhancedData: enhancedData,
      categories: rangeData.map((data) => data.month),
      filterOptions: {
        serviceName: serviceNames,
      },
      stats: {
        averageDurationInHours: calculateAverageDurationInHours(rangeData),
        total: sumBy(rangeData, (data) => Number(data.averageDurationInHours) || 0),
      },
    },
    isEmpty,
    isFilteredEmpty,
  };
};

const useAverageRequestTimesData = (): AverageRequestTimesData => {
  const { data: widgetData, error, isFetching } = useDashboardWidgetData('AverageRequestTimes');
  const { filter } = useAverageRequestTimesContext();

  const memoizedData = useMemo(() => calculateData({ widgetData, filter }), [widgetData, filter]);

  return {
    ...memoizedData,
    error,
    isFetching,
  };
};

export default useAverageRequestTimesData;
