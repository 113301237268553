import Stack from '@mui/material/Stack';
import { SavingsDetails } from './components/SavingsDetails';
import { AnnualCostDetails } from './components/AnnualCostDetails';
import { ContractFormData } from '../../types';
import { useFormContext, useWatch } from 'react-hook-form';
import { TotalCostDetails } from './components/TotalCostDetails';

export const FinancialSection = () => {
  const { control } = useFormContext<ContractFormData>();

  const rollFrequency = useWatch({ control, name: 'parts.contractual.lifecycle.rollFrequency' });
  const isOneOff = rollFrequency === 'ONE_OFF';

  if (isOneOff) return <TotalCostDetails />;

  return (
    <Stack gap={6}>
      <AnnualCostDetails />
      <SavingsDetails />
    </Stack>
  );
};
