import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Content } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData } from '../../types';
import useAutoProgressDelay from '../../../sharedHooks/useAutoProgressDelay';
import { VendorLogoConfirmationPanel } from '../../../SharedComponents/VendorLogoConfirmationPanel';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { SharedRenewalFormData } from '../../../SharedRenewalSteps/types';

const useContractAddedWizard = () => {
  const wizard = useWizard();

  return {
    goToInitiateRenewal: () => wizard.goToStep('choose_initiate_renewal'),
    goToConfirmation: () => wizard.goToStep('confirmation'),
  };
};

const ContractAddedStep: React.FC<WizardStepProps> = () => {
  const { goToInitiateRenewal, goToConfirmation } = useContractAddedWizard();
  const { control } = useFormContext<SharedRenewalFormData>();
  const contractCategory = useWatch({ control, name: 'contractCategory' });
  const { getFeature } = useAccountContext();
  const iwPaywall = getFeature(FEATURES.INTELLIGENT_WORKFLOWS_PAYWALL);
  const isNonSassRenewalEnabled = iwPaywall?.properties?.nonSaasRequests;
  const goToInitiateRenewalStep = contractCategory !== 'OTHER' || isNonSassRenewalEnabled;

  useAutoProgressDelay(goToInitiateRenewalStep ? goToInitiateRenewal : goToConfirmation);

  const { watch } = useFormContext<AddExistingContractFormData>();
  const vendor = watch('vendor');
  return (
    <Content>
      <VendorLogoConfirmationPanel
        headingTitleKey="INTAKE_FORM.EXISTING_CONTRACT_CONTRACT_ADDED.HEADING_TITLE"
        vendorName={vendor?.name ?? undefined}
      />
    </Content>
  );
};

export default ContractAddedStep;
