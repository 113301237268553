import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, EllipsisText, Tooltip } from '@verticeone/design-system';
import { AnimatedStack } from '@vertice/core/src/components/animations/MotionWrappers';

import { SMARTLOOK_DATA_ATTR } from '../../../../constants/analytics';
import { Template } from '../types';

type TemplateCardControlsProps = {
  expanded?: boolean;
  template: Template;
  onClick?: ({ template }: { template: Template }) => void;
};

const Fade = styled(Stack)({
  background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
  zIndex: 10,
  aspectRatio: '373/48',
  transform: 'translateY(1%)',
});

const animationTransition = {
  type: 'spring',
  duration: 0.4,
  bounce: 0,
};

export const TemplateCardControls = ({ template, onClick }: TemplateCardControlsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TEMPLATES' });

  const animationVariants = {
    expanded: { y: 0 },
    collapsed: { y: 48 },
  };

  return (
    <AnimatedStack
      sx={{ width: '100%', zIndex: 10 }}
      variants={animationVariants} // Variants are changed on hover from the parent motion.div
      transition={animationTransition}
      layoutId={[template.id, 'controls'].join('-')} // Prevents hover state getting stuck on the card
    >
      <Fade />
      <Stack p={6} bgcolor="white">
        <Tooltip title={template.name} minified size="S" placement="top-start" noArrow>
          <EllipsisText variant="heading" size="S" color="text1" minHeight="22px" maxHeight="44px" lineClamp={2}>
            {template.name}
          </EllipsisText>
        </Tooltip>
      </Stack>
      <Stack p={4} pt={0} bgcolor="white">
        <Button
          dataAttributes={{
            [SMARTLOOK_DATA_ATTR]: `{"templateId":"${template.id}","templateName":"${template.name}","type":"requestTemplate"}`,
          }}
          variant="ghost"
          color="primary"
          onClick={() => onClick?.({ template })}
        >
          {t('REQUEST_TEMPLATE')}
        </Button>
      </Stack>
    </AnimatedStack>
  );
};
