import React from 'react';
import { Control, FieldPath, FieldValues, UseFormRegister, useFormState } from 'react-hook-form';
import {
  TextField as BaseTextField,
  TextFieldCaption,
  TextFieldProps as BaseTextFieldProps,
} from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { get, has } from 'lodash';

export type TextFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  title: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
} & BaseTextFieldProps;

const TextField = <T extends FieldValues>({
  name,
  title,
  register,
  control,
  placeholder,
  required = true,
  disabled = false,
  ...otherProps
}: TextFieldProps<T>) => {
  const { errors, touchedFields } = useFormState({ control, name });
  const isTouched = get(touchedFields, name, false);
  const hasError = has(errors, name);

  return (
    <Stack flex={1}>
      <Stack>
        <TextFieldCaption label={title} htmlFor={`${title}-field`} size="XS" required={required} />
      </Stack>
      <BaseTextField
        id={`${title}-field`}
        size="S"
        error={isTouched && hasError}
        placeholder={placeholder}
        disabled={disabled}
        {...register(name)}
        {...otherProps}
      />
    </Stack>
  );
};

export default TextField;
