import useDashboardWidgetData from '../../../useDashboardWidgetData';

type Cost = {
  amount: number;
  currency: string;
};

type ProcuredStatsData = {
  procuredCost: Cost;
  inFlightCost: Cost;
};

const FALLBACK_COST = {
  amount: 0,
  currency: 'USD',
};

const useProcuredStatsData = () => {
  const { data, error, isFetching } = useDashboardWidgetData('ProcuredStats');
  const stats = data?.stats as ProcuredStatsData;

  return {
    data: {
      procuredCost: stats?.procuredCost || FALLBACK_COST,
      inFlightCost: stats?.inFlightCost || FALLBACK_COST,
    },
    error,
    isFetching,
  };
};

export default useProcuredStatsData;
