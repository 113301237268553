import { z } from 'zod';
import { zodSchema as purchaseIntakeSimpleFormSchema } from '../PurchaseIntakeSimpleForm/schema';
import { vendorSchema } from '../shared/schemas';

export const zodSchema = z
  .object({
    ...purchaseIntakeSimpleFormSchema.shape,

    vendor: vendorSchema.nullable(),
    department: z.string().nullable(),
    targetSignDate: z.string().nullable(),

    contractCurrency: z.string().nullable(),
    approvedBudget: z.number().nullable(),
    unknownBudget: z.boolean().optional(),
    verticeNegotiationRequested: z.boolean().optional(),
    internalNegotiationRequested: z.boolean().optional(),

    signingEntity: z.string().optional(),
    desiredContractLength: z.number().optional().nullish(),

    legalReviewRequired: z.boolean().optional(),
    securityReviewRequired: z.boolean().optional(),
    piiExpectedToBeHeld: z.boolean().optional(),

    contactVendorDirectly: z.boolean().optional(),
    discussMultiYearDeals: z.boolean().optional(),
    discussPlannedGrowth: z.boolean().optional(),
    discussCompetitors: z.boolean().optional(),
    discussCaseStudies: z.boolean().optional(),

    parentAccountId: z.string().nullish().optional(),

    negotiationTier: z
      .enum(['NEGOTIATION_TIER_VERTICE', 'NEGOTIATION_TIER_CUSTOMER', 'NEGOTIATION_TIER_SUPPORT'])
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (!data.vendor) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['vendor'],
        expected: 'object',
        received: 'null',
      });
    }

    if (!data.contractCurrency) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['contractCurrency'],
        expected: 'string',
        received: 'null',
      });
    }

    if (!data.unknownBudget && data.approvedBudget === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['approvedBudget'],
        expected: 'number',
        received: 'null',
      });
    }

    if (!data.targetSignDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['targetSignDate'],
        expected: 'string',
        received: 'null',
      });
    }

    if (!data.department) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        path: ['department'],
        expected: 'string',
        received: 'null',
      });
    }
  });

export type FormData = z.infer<typeof zodSchema>;
