import { AddExistingContractFormData } from './types';
import {
  BillingFrequency,
  ContractClassificationCreate,
  ContractCreate,
  ContractPartContractualFinancial,
  ContractPartContractualLifecycle,
  PaymentTerms,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import {
  getCostModelBase,
  getItemsFromFormData,
} from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

const existingFormDataToCostModelConfiguration = (formData: AddExistingContractFormData) => {
  return {
    baseline: getCostModelBase(),
    negotiated: {
      ...getCostModelBase(),
      model: {
        items: getItemsFromFormData(formData),
      },
    },
  };
};

const getPaymentTerms = (paymentTerms?: PaymentTerms | null): ContractPartContractualFinancial['paymentTerms'] => {
  if (!paymentTerms) {
    return undefined;
  }
  return paymentTerms;
};

const getBillingFrequency = (
  billingFrequency?: BillingFrequency | null
): ContractPartContractualFinancial['billingFrequency'] => {
  if (!billingFrequency) {
    return undefined;
  }
  return billingFrequency;
};

const getRollingFrequency = (
  rollingFrequency?: RollFrequency | null
): ContractPartContractualLifecycle['rollFrequency'] => {
  if (!rollingFrequency) {
    return undefined;
  }
  return rollingFrequency;
};

export const formDataToExistingContractCreate = (formData: AddExistingContractFormData): ContractCreate => {
  return {
    record: {
      contractCategory: undefined, // Deprecated field
      contractOrigin: 'EXISTING',
    },
    parts: {
      model: {
        cost: {
          configuration: existingFormDataToCostModelConfiguration(formData),
        },
      },
      contractual: omitEmptyValues({
        vendor: omitEmptyValues({
          vendorType: formData.vendor?.type ?? 'PREDEFINED',
          vendorId: formData.vendor?.id || undefined,
          vendorName: formData.vendor?.name ?? undefined,
          vendorContact: omitEmptyValues({
            name: formData.vendorContactName,
            email: formData.vendorContactEmail,
          }),
        }),
        financial: omitEmptyValues<ContractPartContractualFinancial>({
          baseCurrency: formData.contractCurrency,
          billingFrequency: getBillingFrequency(formData.billingFrequency),
          billingFrequencyOther: formData.billingFrequencyOther || undefined,
          paymentTerms: getPaymentTerms(formData.paymentTerms),
        }),
        lifecycle: omitEmptyValues({
          startDate: keepOnlyLocalDatePart(formData.startDate) ?? undefined,
          renewalDate: keepOnlyLocalDatePart(formData.renewalDate) ?? undefined,
          autoRenewalDeadline: keepOnlyLocalDatePart(formData.autoRenewalDate) ?? undefined,
          rollFrequency: getRollingFrequency(formData.rollingFrequency),
        }),
        signatory: omitEmptyValues({
          signingDate: keepOnlyLocalDatePart(formData.signDate || formData.startDate) ?? undefined,
          signer: omitEmptyValues({
            name: formData.signatoryName,
            email: formData.signatoryEmail,
          }),
          signingEntity: omitEmptyValues({
            name: formData.signingEntity,
          }),
        }),
      }),
    },
    classification: omitEmptyValues<ContractClassificationCreate>({
      departmentId: formData?.departmentId ?? undefined,
      category: formData.contractCategory ?? 'SAAS',
    }),
  };
};
