import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { CardsList } from '../../components/CardsList';
import { CardWrapper } from '../../components/CardWrapper';
import { SectionWrapper } from '../../components/SectionWrapper';
import { getActiveCards } from '../../utils';
import { AccountingSetupCard } from './components';

export const Accounting = () => {
  const { t } = useTranslation();
  const { isEnabled, getFeatureProperty } = useFeatures();

  const cards = [
    {
      component: <AccountingSetupCard id="netsuite" />,
      enabled: isEnabled(FEATURES.INTEGRATION_NETSUITE),
    },
    {
      component: <AccountingSetupCard id="quickbooks" />,
      enabled: isEnabled(FEATURES.INTEGRATION_QUICKBOOKS),
    },
    {
      component: <AccountingSetupCard id="msDynamics" />,
      enabled: isEnabled(FEATURES.INTEGRATION_MS_DYNAMICS),
    },
    {
      component: (
        <AccountingSetupCard
          id="xero"
          allowedInstances={getFeatureProperty(FEATURES.INTEGRATION_XERO, 'numberOfAllowedConnections') ?? 1}
        />
      ),
      enabled: isEnabled(FEATURES.INTEGRATION_XERO),
    },
  ];
  const activeCards = getActiveCards(cards);

  if (activeCards.length === 0) return null;

  return (
    <SectionWrapper title={t('INTEGRATIONS.ACCOUNTING.HEADER')}>
      <CardsList>
        {activeCards.map((card, index) => (
          <CardWrapper key={index}>{card}</CardWrapper>
        ))}
      </CardsList>
    </SectionWrapper>
  );
};
