import { useMemo } from 'react';
import { SimpleOption } from '@verticeone/design-system';
import { useContracts } from '../components/ContractList/useContracts';
import { getContractsOwners } from '../components/ContractList/Filters/utils';
import { useTranslation } from 'react-i18next';
import { UNASSIGNED_OWNER_OPTION } from '../components/ContractList/Filters/customViews/evaluateFunctions';

export const useContractOwnerOptions = (): { options: SimpleOption[] } => {
  const { t } = useTranslation();
  const { allViewsOnContracts } = useContracts({});

  const owners = getContractsOwners(allViewsOnContracts);

  const contractOwnerOptions = useMemo(
    () => [
      ...owners.map(({ ownerId, name }) => ({
        value: ownerId,
        label: name,
      })),
      { value: UNASSIGNED_OWNER_OPTION, label: t('ENTITIES.CONTRACT_CUSTOM_VIEW.LABELS.UNASSIGNED_OWNER') },
    ],
    [owners, t]
  );

  return { options: contractOwnerOptions };
};
