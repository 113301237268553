import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { IconButton, Text } from '@verticeone/design-system';
import { CloseOutlined } from '@mui/icons-material';

import { type DeleteButtonProps, DeleteObjectButton } from '../components/DeleteObjectButton/DeleteObjectButton';
import { FEATURES } from '../../../../features/constants';
import { useFeatures } from '../../../../features/useFeatures';

type EditorDrawerHeaderProps = {
  title: string;
  subtitle?: string;
  gap?: number;
  onClose?: VoidFunction;
  actions?: ReactNode;
  deleteOptions?: DeleteButtonProps;
};

export const EditorDrawerHeader: FC<EditorDrawerHeaderProps> = ({
  title,
  subtitle,
  onClose,
  gap,
  actions,
  deleteOptions,
}) => {
  const { getFeature } = useFeatures();
  const isWorkflowCustomizationEnabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties
    ?.workflowCustomisation;

  return (
    <Stack
      direction="row"
      alignItems="center"
      padding={6}
      spacing={2}
      borderBottom={({ palette }) => `1px solid ${palette.core.color3}`}
    >
      <Stack direction="column" gap={gap} flex={1}>
        <Text variant="heading" size="M" color="text1">
          {title}
        </Text>
        {subtitle && (
          <Text variant="body-regular" size="S" color="text4">
            {subtitle}
          </Text>
        )}
      </Stack>
      {deleteOptions && isWorkflowCustomizationEnabled ? (
        <DeleteObjectButton {...deleteOptions} isHotKeyEnabled />
      ) : null}
      {actions}
      {onClose && <IconButton variant="outline" size="M" color="neutral" icon={CloseOutlined} onClick={onClose} />}
    </Stack>
  );
};
