import { ListVendorsApiArg, useListVendorsQuery } from '@vertice/slices/src/openapi/codegen/vendorAPI';
import { useListVendorsOptimisedQuery } from '@vertice/slices/src/api/vendorAPI';
import { VENDORS_LIST_FILTERS_LIMIT } from '@vertice/slices/src/constants';

export default function useListVendorsSmartQuery(listVendorsParams: ListVendorsApiArg, options?: { skip?: boolean }) {
  const vendorFilterCnt = listVendorsParams?.filters?.filter((f) => f.includes('vendorId:')).length || 0;
  const vendorFilterLimitExceeded = vendorFilterCnt > VENDORS_LIST_FILTERS_LIMIT;

  const hookResult = useListVendorsQuery(listVendorsParams, {
    skip: vendorFilterLimitExceeded || options?.skip,
  });

  /* Note: If optimized query is used, sort param is not taken into account because of multiple requests, vendors are sorted internally by rank(asc) */
  const optimisedHookResult = useListVendorsOptimisedQuery(listVendorsParams, {
    skip: !vendorFilterLimitExceeded || options?.skip,
  });

  return vendorFilterLimitExceeded ? optimisedHookResult : hookResult;
}
