import usePaymentTermsOptions from '@vertice/core/src/modules/saas/contract/hooks/usePaymentTermsOptions';
import React from 'react';
import FormSelectField2 from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { AddExistingContractFormEntry } from '../types';
import { useTranslation } from 'react-i18next';
import { PaymentTerms } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { SimpleOption } from '@verticeone/design-system';
import { GridSize } from '@mui/material/Grid';

type PaymentTermsEntryFormProps = {
  width?: GridSize;
};

export const PaymentTermsEntryForm = ({ width }: PaymentTermsEntryFormProps) => {
  const { t } = useTranslation();
  const { options, optionsMap } = usePaymentTermsOptions();

  return (
    <AddExistingContractFormEntry
      name="paymentTerms"
      label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS')}
      component={FormSelectField2}
      componentProps={{
        options: options,
        boxValue: (value: PaymentTerms) => (value ? optionsMap[value] : undefined),
        unboxValue: (selectValue: SimpleOption) => selectValue?.value,
        sx: { minWidth: 256 },
      }}
      width={width}
    ></AddExistingContractFormEntry>
  );
};
