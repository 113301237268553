import { SummaryRecord } from '../types';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useRequestContext } from '../../../../../../request/RequestDetailPage/RequestContext';
import useRequestDocuments from '../../../../../../hooks/useRequestDocuments';
import { useCustomTasksDocuments } from '../../../../../../hooks/useCustomTaskDocuments';

export const useDocumentsSummaryRecord = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.LABELS' });

  const { requestId } = useRequestContext();

  const { files: requestFiles } = useRequestDocuments(requestId);
  const { files: taskFiles } = useCustomTasksDocuments(requestId);

  const record = useMemo<SummaryRecord>(() => {
    const fileNames = [...taskFiles.map(({ file }) => file.name), ...requestFiles.map(({ file }) => file.name)];

    return { label: t('ATTACHED_FILES'), value: fileNames.length === 0 ? '-' : fileNames.join('\n') };
  }, [requestFiles, taskFiles, t]);

  return { record };
};
