import { Stack, styled } from '@mui/material';

type BaseDashboardCardProps = {
  $isClickable?: boolean;
};

type DashboardCardProps = {
  $isClickable?: boolean;
} & (BaseDashboardCardProps['$isClickable'] extends true ? { $shadowColor: string } : { $shadowColor?: string });

const DashboardCard = styled(Stack)<DashboardCardProps>(({ theme, $isClickable, $shadowColor }) => ({
  backgroundColor: theme.palette.core.bg,
  borderRadius: theme.spacing(4),
  position: 'relative',
  transition: 'all 0.3s ease',
  border: `1px solid ${theme.palette.core.color3}`,
  ...($isClickable
    ? {
        cursor: 'pointer',
        ':hover': {
          boxShadow: theme.palette.global.getShadow({
            color: $shadowColor,
            type: 'soft',
            depth: '1z',
            distance: '60',
          }),
        },
      }
    : {}),
}));

export default DashboardCard;
