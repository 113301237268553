import {
  ListTagAssignmentsApiArg,
  ListTagAssignmentsApiResponse,
  TagAssignment,
  useLazyListTagAssignmentsQuery,
} from '@vertice/slices';
import { useMemo } from 'react';

import { getUserRef } from '../user/refUtils';
import { useAccountContext } from '../account/AccountContext';
import { useFetchPaginated } from '../../hooks/useFetchPaginated';
import useLoggedUserAccountRoles from '../../hooks/useLoggedUserAccountRoles';

import { useAccountDepartments } from './useAccountDepartments';
import { Department } from './types';
import { getOwnedDepartmentIdsFromTagAssignments } from './tagUtils';

export type UseUserOwnedDepartmentsProps = {
  userId: string;
  skip?: boolean;
};

export type UseUserOwnedDepartmentsResult = { departments: Department[] | undefined; isLoading: boolean };

export const useUserOwnedDepartments = ({
  userId,
  skip = false,
}: UseUserOwnedDepartmentsProps): UseUserOwnedDepartmentsResult => {
  const { accountId } = useAccountContext();
  const { isUserAdmin, isUserPowerUser, isLoading: isUserAccountRolesLoading } = useLoggedUserAccountRoles();

  const { data: allDepartments, isLoading: isAllDepartmentsLoading } = useAccountDepartments({ skip: skip });

  const [listTagAssignments] = useLazyListTagAssignmentsQuery();

  const { items: userTagAssignments, isLoading: isLoadingTagAssignments } = useFetchPaginated<
    ListTagAssignmentsApiResponse,
    ListTagAssignmentsApiArg,
    TagAssignment
  >({
    fetchFn: listTagAssignments,
    getItemsFn: (data) => data.tagAssignments,
    getNextTokenFn: (data) => data.nextToken,
    fetchArgs: {
      accountId: accountId!,
      ref: getUserRef(userId),
      maxResults: 500,
    },
  });

  const availableDepartmentIDs = getOwnedDepartmentIdsFromTagAssignments(userTagAssignments);

  return useMemo(() => {
    const isLoading = isAllDepartmentsLoading || isUserAccountRolesLoading || isLoadingTagAssignments;
    if (isLoading) {
      return { isLoading, departments: undefined };
    }

    return {
      isLoading,
      departments:
        isUserAdmin || isUserPowerUser
          ? allDepartments
          : allDepartments?.filter((department) => availableDepartmentIDs?.includes(department.departmentId)),
    };
  }, [
    isAllDepartmentsLoading,
    isUserAccountRolesLoading,
    isLoadingTagAssignments,
    isUserAdmin,
    isUserPowerUser,
    allDepartments,
    availableDepartmentIDs,
  ]);
};
