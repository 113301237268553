import { ContractOrigin } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ContractOriginOption = {
  value: ContractOrigin;
  label: string;
};

export const useContractOriginOptions = (): { options: ContractOriginOption[] } => {
  const { t } = useTranslation();

  const options: ContractOriginOption[] = useMemo(
    () => [
      {
        value: 'EXISTING',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.EXISTING'),
      },
      {
        value: 'RENEWAL_WITHOUT_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.RENEWAL_WITHOUT_VERTICE'),
      },
      {
        value: 'RENEWAL_WITH_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.RENEWAL_WITH_VERTICE'),
      },
      {
        value: 'PURCHASE_WITH_VERTICE',
        label: t('ENTITIES.FILTERS.CONTRACT_ORIGIN_FILTER.PURCHASE_WITH_VERTICE'),
      },
    ],
    [t]
  );

  return { options };
};
