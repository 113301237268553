import { FC } from 'react';
import { Stack } from '@mui/material';

import { Text, FileList, Fileupload } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { useCustomTasksDocuments } from '../../../../hooks/useCustomTaskDocuments';

type RequestDocumentsProps = {
  requestId: string;
};

export const CustomTaskDocuments: FC<RequestDocumentsProps> = (props) => {
  const { requestId } = props;
  const { t } = useTranslation();
  const { files, isFetching, downloadFile } = useCustomTasksDocuments(requestId);

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Stack>
      <Text variant="caption" size="S" mb={3}>
        {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.DOCUMENTS.TASK_DOCUMENTS')}
      </Text>
      <Fileupload files={files}>
        <FileList
          size="M"
          hideErrorFiles
          dynamicFileNameLength
          onFileNameClick={downloadFile}
          hiddenColumns={['remove']}
        />
      </Fileupload>
    </Stack>
  );
};
