import { Stack, styled } from '@mui/material';
import React, { ReactNode } from 'react';

type ListItemBaseProps = {
  title: ReactNode;
  description: ReactNode;
  icon: ReactNode;
  chip?: ReactNode;
  onClick?: () => void;
};

const ListItemBaseWrapper = styled(Stack)(({ theme, onClick }) => ({
  cursor: onClick ? 'pointer' : 'default',
  transition: 'all 0.3s',
  borderRadius: theme.spacing(3),
  border: '4px solid transparent',
  ...(onClick
    ? {
        '&:hover': {
          backgroundColor: theme.palette.core.color1,
        },
        '&:active': {
          borderColor: theme.palette.core.color3,
        },
      }
    : {}),
}));

const ListItemBase = ({ title, description, chip, icon, onClick }: ListItemBaseProps) => (
  <ListItemBaseWrapper direction="row" gap={3} p={3} onClick={onClick}>
    {icon}
    <Stack flex={1} gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1} flex={1}>
        {title}
        {chip}
      </Stack>
      {description}
    </Stack>
  </ListItemBaseWrapper>
);

export default ListItemBase;
