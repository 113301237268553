/* eslint-disable react/no-this-in-sfc */
import { useEffect, useMemo, useState } from 'react';
import { Box, Stack, styled, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { DataGrid, type GridColDef } from '@verticeone/design-system';
import { GridInitialState, GridRowModel, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Chip } from './Chip';
import {
  AccountValue,
  OrgResourceType,
  useUpdateAccountSettingsMutation,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { accountTypeComparator, nameComparator, statusComparator } from './utils';
import { ToggleSwitch } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { useSnackbar } from 'notistack';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

const ROW_HEIGHT = 60;
const HEADER_HEIGHT = 48;

type AccountTableProps = {
  data: AccountValue[];
  refetchAccounts?: () => void;
};

const StyledDataGrid = styled(DataGrid<AccountValue>)(({ theme }) => ({
  width: '100%',
  borderRadius: '0',
  border: 'none',

  '.MuiDataGrid-columnHeader': {
    paddingLeft: '24px',
  },

  '.MuiDataGrid-cell': {
    paddingLeft: '24px',
  },

  '.billing-account-row': {
    backgroundColor: theme.palette.input.color1,
  },
}));

const ChipContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export const AccountTable = ({ data, refetchAccounts }: AccountTableProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.AWS.ACCOUNT_TABLE' });
  const { palette } = useTheme();
  const [toggledRows, setToggledRows] = useState(new Map());
  const [isSaving, setIsSaving] = useState(false);
  const [updateAccountSettings] = useUpdateAccountSettingsMutation();
  const { accountId, accountType } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getFeature } = useFeatures();

  useEffect(() => {
    setToggledRows(new Map());
  }, [data]);

  const getRowId = (row: GridRowModel) => row.id;

  const billingAccount = useMemo(() => data.find((item) => item.type === OrgResourceType.ManagementAccount), [data]);
  const sortedData = useMemo(() => data?.slice().sort(accountTypeComparator), [data]);

  const columns: GridColDef<AccountValue>[] = [
    {
      field: 'name',
      headerName: t('COLUMN.ACCOUNT_NAME'),
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => {
        const isBillingAccount = params.row.type === OrgResourceType.ManagementAccount;
        return (
          <Text variant={isBillingAccount ? 'body-bold' : 'body-regular'} size="M">
            {isBillingAccount && `${t('BILLING_ACCOUNT')}: `}
            {params.value}
          </Text>
        );
      },
      sortComparator: nameComparator(billingAccount?.id),
      flex: 1,
    },
    {
      field: 'id',
      headerName: t('COLUMN.ACCOUNT_ID'),
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => {
        const isBillingAccount = params.row.type === OrgResourceType.ManagementAccount;
        return (
          <Text variant={isBillingAccount ? 'body-bold' : 'body-regular'} size="M">
            {params.value}
          </Text>
        );
      },
      sortComparator: nameComparator(billingAccount?.id),
      flex: 1,
    },
    {
      field: 'probeStatus',
      headerName: t('COLUMN.ACCOUNT_IAM_ROLE'),
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => (
        <ChipContainer>
          <Chip active={params.value} />
        </ChipContainer>
      ),
      sortComparator: statusComparator(billingAccount?.id),
      align: 'right',
      headerAlign: 'right',
      width: 220,
    },
  ];

  if (getFeature(FEATURES.AWS_COST_INSIGHT)?.properties?.activeSpendAudit || accountType === 'DEMO') {
    columns.push({
      field: 'settings.excludedFromCco',
      headerName: t('COLUMN.EXCLUDE_FROM_OPTIMIZATIONS'),
      disableColumnMenu: true,
      editable: false,
      align: 'center',
      valueGetter: (params) => params.row.settings?.excludedFromCco,
      renderCell: (params) => {
        const isBillingAccount = params.row.type === OrgResourceType.ManagementAccount;

        return (
          <ToggleSwitch
            disabled={isBillingAccount}
            checked={
              toggledRows.get(params.row.id)?.value !== undefined ? toggledRows.get(params.row.id)?.value : params.value
            }
            onChange={(e, checked) => handleToggle(params.row, checked)}
          />
        );
      },
      minWidth: 270,
    });
  }

  const handleToggle = (row: AccountValue, checked: boolean) => {
    setToggledRows((prev) => {
      const newToggledRows = new Map(prev);
      if (newToggledRows.has(row.id)) {
        // If the row is already selected, remove it from the selection
        newToggledRows.delete(row.id);
      } else {
        // If the row is not selected, add it to the selection
        newToggledRows.set(row.id, {
          value: checked,
        });
      }
      return newToggledRows;
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    if (toggledRows.size > 0) {
      const accounts = Array.from(toggledRows.entries()).map(([id, { value }]) => ({
        awsAccountId: id,
        accountSettings: {
          excludedFromCco: Boolean(value),
        },
      }));

      // update account settings
      await updateAccountSettings({
        accountId: accountId!,
        accounts,
      });

      // waiting for new data to be fetched
      setTimeout(() => {
        refetchAccounts?.();
        setIsSaving(false);
        enqueueSnackbar(t('EXCLUDE_FROM_OPTIMIZATIONS.SNACKBAR.SUCCESS', { count: toggledRows.size }), {
          variant: 'success',
        });
      }, toggledRows.size * 500);
    } else {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setToggledRows(new Map());
  };

  const gridInitialState: GridInitialState = {
    sorting: {
      sortModel: [
        {
          field: 'name',
          sort: 'asc',
        },
      ],
    },
  };

  return (
    <Box height={(sortedData?.length ?? 0) * ROW_HEIGHT + HEADER_HEIGHT} sx={{ overflow: 'auto' }}>
      <StyledDataGrid
        rows={sortedData || []}
        columns={columns}
        getRowId={getRowId}
        sortingMode="client"
        initialState={gridInitialState}
        disableRowSelectionOnClick
        hideFooter={toggledRows.size === 0}
        rowHeight={ROW_HEIGHT}
        columnHeaderHeight={HEADER_HEIGHT}
        getRowClassName={(params: GridRowParams) =>
          params.row.type === OrgResourceType.ManagementAccount ? 'billing-account-row' : ''
        }
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight={false}
        sx={{ flex: 1 }}
        slots={{
          footer: () => (
            <Stack
              padding={6}
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                borderTop: `1px solid ${palette.core.color2}`,
              }}
            >
              <Stack direction="row" gap={4} alignItems="center">
                <Button
                  color="primary"
                  variant="solid"
                  size="M"
                  disabled={isSaving}
                  isLoading={isSaving}
                  onClick={handleSave}
                >
                  {t('ACTIONS.SAVE')}
                </Button>
                <Button color="primary" variant="outline" size="M" disabled={isSaving} onClick={handleCancel}>
                  {t('ACTIONS.CANCEL')}
                </Button>
              </Stack>
            </Stack>
          ),
        }}
      />
    </Box>
  );
};
