import { Dictionary, keyBy } from 'lodash';
import { ComponentType } from 'react';
import { DataSourcedFieldMetadata } from './types';
import { DataSourcedFieldPresenterProps } from './presenters/types';
import { XTypeSourcedDropdownPresenter } from './presenters/XTypeSourcedDropdownPresenter';

export type DataSourceDef = {
  name: string;
  source: string;
  labelI18nKey: string;
  /**
   * Default presenterType chosen at creation of field
   */
  defaultPresenterType: DataSourcedFieldMetadata['presenterType'];
  /**
   * Map of presenters tied to applicable presenterTypes
   */
  presenterMap: Partial<
    Record<DataSourcedFieldMetadata['presenterType'], ComponentType<DataSourcedFieldPresenterProps>>
  >;
};

const defaultXTypeDataSourceDef: Pick<DataSourceDef, 'defaultPresenterType' | 'presenterMap'> = {
  defaultPresenterType: 'DROPDOWN',
  presenterMap: {
    DROPDOWN: XTypeSourcedDropdownPresenter,
  },
};

export const xTypeDataSources: DataSourceDef[] = [
  {
    name: 'DEPARTMENT',
    labelI18nKey: `DYNAMIC_FORM_BUILDER.FIELD.DATASOURCE.XTYPE_NAME.DEPARTMENT`,
    source: 'urn:verticeone:vertice::services:schema/core/department/id/v1',
    ...defaultXTypeDataSourceDef,
  },
  {
    name: 'ROLLING_FREQUENCY',
    labelI18nKey: `DYNAMIC_FORM_BUILDER.FIELD.DATASOURCE.XTYPE_NAME.ROLLING_FREQUENCY`,
    source: 'urn:verticeone:vertice::services:schema/core/rollingFrequency/v1',
    ...defaultXTypeDataSourceDef,
  },
];
export const xTypeDataSourcesBySource: Dictionary<DataSourceDef> = keyBy(xTypeDataSources, 'source');

export const dataSources: DataSourceDef[] = [...xTypeDataSources];
export const dataSourcesBySource: Dictionary<DataSourceDef> = keyBy(dataSources, 'source');
