import { CardHeader } from '@verticeone/design-system';
import { CardHeaderProps } from '@verticeone/design-system';
import { styled } from '@mui/material';
import { FC } from 'react';
import { InvertedThemeProvider } from '@verticeone/design-system';
import { StyledCardHeaderTitleIcon } from '@verticeone/design-system';
import { StyledCardHeaderActions } from '@verticeone/design-system';

type HighlightedCardHeaderProps = CardHeaderProps & {
  height?: number | 'auto';
  backgroundImage?: string;
  backgroundColor?: string;
};

type StyledCardHeaderProps = Omit<HighlightedCardHeaderProps, 'backgroundImage' | 'backgroundColor'> & {
  $backgroundImage?: string;
  $backgroundColor?: string;
};

const StyledCardHeader = styled<FC<StyledCardHeaderProps>>(CardHeader)(
  ({ theme: { palette }, height = 110, $backgroundColor, $backgroundImage }) => ({
    background: $backgroundColor ?? palette.gradient.purpleToBlue,
    backgroundImage: $backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [`& ${StyledCardHeaderTitleIcon}`]: {
      backgroundColor: 'transparent',
      marginRight: 2,
      svg: {
        fill: 'white',
      },
    },
    [`& ${StyledCardHeaderActions}`]: {
      position: 'relative',
      top: 'unset',
      right: 'unset',
    },
  })
);

const HighlightedCardHeader: FC<HighlightedCardHeaderProps> = ({
  children,
  backgroundColor,
  backgroundImage,
  ...restProps
}) => {
  return (
    <StyledCardHeader $backgroundColor={backgroundColor} $backgroundImage={backgroundImage} {...restProps}>
      <InvertedThemeProvider>{children}</InvertedThemeProvider>
    </StyledCardHeader>
  );
};

export default HighlightedCardHeader;
