import {
  useOnSelectionChange,
  useReactFlow,
  type OnSelectionChangeFunc,
  useStoreApi,
  Node,
  ReactFlowProvider,
  Edge,
} from '@xyflow/react';
import React, { useCallback, useMemo, useState } from 'react';
import { DRAWER_WIDTH, TRANSITION_DURATION } from '../WorkflowViewer/constants';
import isEqual from 'lodash/isEqual';

export type SelectionAllowed = (
  prev: { node?: Node; edge?: Edge },
  next: { nodes?: Node[]; edges?: Edge[] }
) => Promise<{ allowed: boolean; reset: boolean }>;

export type SelectionChanged = (node?: Node, edge?: Edge, resetDirty?: boolean) => void;

type UseWorkflowRendererStateProps = {
  onSelectionChanged: SelectionChanged;
  checkChangeSelectionAllowed: SelectionAllowed;
};

const getId = (v: Node | Edge) => v.id;

export const useWorkflowRendererState = ({
  onSelectionChanged,
  checkChangeSelectionAllowed,
}: UseWorkflowRendererStateProps) => {
  const flow = useReactFlow();
  const flowApi = useStoreApi();

  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);
  const [selectedEdges, setSelectedEdges] = useState<Edge[]>([]);

  const handleSelectionChange: OnSelectionChangeFunc = useCallback(
    async ({ nodes: changedNodes, edges: changedEdges }) => {
      if (changedNodes.length === 0 && changedEdges.length === 0) {
        return;
      }

      const selectedNodeIds = selectedNodes.map(getId);
      const selectedEdgeIds = selectedEdges.map(getId);

      if (isEqual(changedNodes.map(getId), selectedNodeIds) && isEqual(changedEdges.map(getId), selectedEdgeIds)) {
        return;
      }

      const { allowed, reset } = await checkChangeSelectionAllowed(
        { node: selectedNodes[0], edge: selectedEdges[0] },
        { nodes: changedNodes, edges: changedEdges }
      );

      // if change is not allowed then reset to last selection
      if (!allowed) {
        flowApi.setState((s) => {
          flow.setNodes((n) => n.map((node) => ({ ...node, selected: false })));
          flow.setEdges((e) => e.map((edge) => ({ ...edge, selected: false })));
          s.addSelectedNodes(selectedNodeIds);
          s.addSelectedEdges(selectedEdgeIds);
          return s;
        });
        return;
      }

      const newSelectedNode = changedNodes.find((node) => !selectedNodeIds.includes(node.id)) ?? changedNodes[0];
      const newSelectedEdge = changedEdges.find((edge) => !selectedEdgeIds.includes(edge.id)) ?? changedEdges[0];

      setSelectedNodes(changedNodes);
      setSelectedEdges(changedEdges);

      onSelectionChanged(newSelectedNode, newSelectedEdge, reset);

      const edgeSourceNode = newSelectedEdge
        ? flowApi.getState().nodes.find((n) => n.id === newSelectedEdge.source)
        : undefined;
      const nodeToZoom = edgeSourceNode || newSelectedNode;
      if (nodeToZoom) {
        const targetZoom = Math.max(flow.getZoom(), 0.7);
        const x = nodeToZoom.position.x + (nodeToZoom?.measured?.width ?? 0) / 2 + DRAWER_WIDTH / 2 / targetZoom;
        const y = nodeToZoom.position.y + (nodeToZoom?.measured?.height ?? 0) / 2;
        void flow.setCenter(x, y, { zoom: targetZoom, duration: TRANSITION_DURATION });
      }
    },
    [checkChangeSelectionAllowed, flow, flowApi, onSelectionChanged, selectedEdges, selectedNodes]
  );

  const clearRendererSelection = useCallback(() => {
    flow.setNodes((n) => n.map((node) => ({ ...node, selected: false })));
    flow.setEdges((e) => e.map((edge) => ({ ...edge, selected: false })));

    setSelectedNodes([]);
    setSelectedEdges([]);
  }, [flow]);

  useOnSelectionChange({
    onChange: handleSelectionChange,
  });

  return useMemo(
    () => ({
      clearRendererSelection,
    }),
    [clearRendererSelection]
  );
};

// A component using useWorkflowRendererState must be wrapped in ReactFlowProvider which this HOC does
export const withWorkflowRenderer =
  <T extends object>(InnerComponent: React.FC<T>) =>
  (props: T) =>
    (
      <ReactFlowProvider>
        <InnerComponent {...props} />
      </ReactFlowProvider>
    );
