import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { CardWrapper } from '../../components/CardWrapper';
import { CardsList } from '../../components/CardsList';
import { SectionWrapper } from '../../components/SectionWrapper';
import { getActiveCards } from '../../utils';
import { SlackSetupCard } from './components/Slack/SlackSetupCard';
import { SLACK_PROVIDER_ID } from './components/Slack/common';

export const Notifications = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  const cards = [
    { component: <SlackSetupCard providerId={SLACK_PROVIDER_ID} />, enabled: isEnabled(FEATURES.INTEGRATION_SLACK) },
  ];
  const activeCards = getActiveCards(cards);

  if (activeCards.length === 0) return null;

  return (
    <SectionWrapper title={t('INTEGRATIONS.NOTIFICATIONS')}>
      <CardsList>
        {activeCards.map((card, index) => (
          <CardWrapper key={index}>{card}</CardWrapper>
        ))}
      </CardsList>
    </SectionWrapper>
  );
};
