import FormSelectField2 from '../../../../../../../../forms/fields/FormSelectField2';
import ViewSettingsFormEntry from '../ViewSettingsFormEntry';
import { ViewSettingsFormData } from '../schema';
import { isNil } from 'lodash';

type GenericOption<T extends string> = { label: string; value: T };

type FormContractSelectProps<T extends string> = {
  name: keyof ViewSettingsFormData['filters'];
  label: string;
  placeholder: string;
  useOptions: () => { options: GenericOption<T>[] };
};

export const FormContractSelect = <T extends string>({
  name,
  label,
  placeholder,
  useOptions,
}: FormContractSelectProps<T>) => {
  const { options } = useOptions?.();

  return (
    <ViewSettingsFormEntry
      name={`filters.${name}`}
      label={label}
      component={FormSelectField2}
      componentProps={{
        isMulti: true,
        size: 'S',
        options: options,
        placeholder: placeholder,
        boxValue: (values?: string[]) =>
          (values ?? []).map((value) => options.find((option) => option.value === value)).filter((x) => !isNil(x)),
        unboxValue: (opts?: GenericOption<T>[]) => opts?.map((option) => option.value) ?? [],
      }}
      width={12}
    />
  );
};
