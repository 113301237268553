import { ZodObject, ZodRawShape, z } from 'zod';
import { DynamicFormField, DynamicFormSchema } from '../../types';
import { fieldDefsByType } from '../../fields/fields';

export function createDynamicSchema(form: DynamicFormSchema) {
  const schemas = form.fields.map(mapFieldToSchema);
  return schemas.reduce((prev, curr) => curr.merge(prev), z.object({}));
}

export function mapFieldToSchema(dynamicField: DynamicFormField): ZodObject<ZodRawShape> {
  const getPresenterSchema = fieldDefsByType[dynamicField.type]?.getPresenterSchema;
  return getPresenterSchema ? getPresenterSchema(dynamicField) : z.object({});
}
