import { FC } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTaskContext } from '../../../TaskContext';
import { Stack } from '@mui/material';
import { Text, TextFieldCaption } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Vendor } from '../../../../../../vendor/types';

type AssessmentContextResultSectionProps = {
  result?: FieldValues;
};

export const AssessmentContextResultSection: FC<AssessmentContextResultSectionProps> = ({ result }) => {
  const { taskOverview } = useTaskContext();
  const { t } = useTranslation();

  const vendor: Vendor | undefined = taskOverview?.task.input.vendor;

  return (
    <>
      {result && (
        <Stack gap={8} alignSelf="start">
          <Stack gap={2}>
            <TextFieldCaption
              size="XS"
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.VENDOR_WEBSITE'
              )}
            />
            <Text variant="heading" size="XS">
              {result.homepage}
            </Text>
          </Stack>

          <Stack gap={2}>
            <TextFieldCaption
              size="XS"
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.VENDOR_NAME'
              )}
            />
            <Text variant="heading" size="XS">
              {vendor?.name}
            </Text>
          </Stack>

          {result.description && (
            <Stack gap={2}>
              <TextFieldCaption
                size="XS"
                label={t(
                  'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_PURPOSE'
                )}
              />
              <Text variant="heading" size="XS">
                {result.description}
              </Text>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};
