import { DeepPartial } from 'react-hook-form';
import { ContractLineItemsForm } from '../types';
import { TaskContractReviewFormConfig } from '../../../TaskContractReviewForm';

export const getContractLineItemsDefaultValues = (
  config: TaskContractReviewFormConfig
): DeepPartial<ContractLineItemsForm> => ({
  lineItems: [],
  currency: config.accountPreferredCurrency,
  annualCostOverride: {
    amount: 0,
    enabled: false,
  },
  totalCostOverride: {
    amount: 0,
    enabled: false,
  },
});
