import React, { FC } from 'react';
import { Text } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Stack, styled, useTheme } from '@mui/material';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { Tooltip } from '@verticeone/design-system';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { IconWrapper } from '@verticeone/design-system';
import { isContractRequirementsGathering, isContractWithVertice } from '../../../computed';
import { isNil } from 'lodash';
import { useExchangeCurrencyHint } from '@vertice/core/src/modules/currency/useGetExchangeCurrencyHint';
import { getAnnualTotalSavings, getAnnualTotalSavingsPercentage } from '../../../../savings/computed';
import useFormatSavingsPercentage from '../../../../savings/useFormatSavingsPercentage';

const ChipContainer = styled('div')({
  flexBasis: '65px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export type Savings = {
  annualTotalSavings?: number;
  annualTotalSavingsExchanged?: number;
  annualTotalSavingsPercentage?: number;
  contractCurrency?: string;
};

export type SavingsCellProps = Savings & {
  accountCurrency: string;
};

export const savingsCellValueGetter = (
  contract: Contract,
  accountCurrency: string,
  exchangeCurrency: (value: number, sourceCurrency: string) => number
): Savings | null => {
  if (!isContractWithVertice(contract) || isContractRequirementsGathering(contract)) {
    return null;
  }

  const annualTotalSavings = getAnnualTotalSavings(contract);
  if (isNil(annualTotalSavings) || annualTotalSavings === 0) {
    return null;
  }

  const contractCurrency = contract?.parts.contractual?.financial?.baseCurrency;
  const annualTotalSavingsExchanged =
    contractCurrency && contractCurrency !== accountCurrency
      ? exchangeCurrency(annualTotalSavings, contractCurrency)
      : undefined;
  return {
    annualTotalSavings,
    annualTotalSavingsExchanged,
    annualTotalSavingsPercentage: getAnnualTotalSavingsPercentage(contract),
    contractCurrency,
  };
};

export const SavingsCell: FC<SavingsCellProps> = ({
  annualTotalSavingsPercentage,
  annualTotalSavings,
  annualTotalSavingsExchanged,
  accountCurrency,
  contractCurrency,
}) => {
  const formatCurrency = useFormatCurrency();
  const { palette } = useTheme();
  const format = (value: number, currency: string) => formatCurrency(value, { currency, maximumFractionDigits: 0 });
  const formatSavingsPercentage = useFormatSavingsPercentage();
  const { getExchangeCurrencyHint } = useExchangeCurrencyHint();

  if (isNil(annualTotalSavingsPercentage) || isNil(annualTotalSavings) || !contractCurrency) {
    return (
      <Stack alignItems="flex-end">
        <Text variant="body-regular" size="M" color="inactive2">
          —
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center" justifyContent="flex-end">
      <Stack direction="column" alignItems="end">
        <Text variant="body-regular" size="M" color="text1">
          {format(annualTotalSavings, contractCurrency)}
        </Text>
        {!isNil(annualTotalSavingsExchanged) && (
          <Stack direction="row" alignItems="center" gap={1}>
            <Text variant="caption" size="S" color="text3">
              {format(annualTotalSavingsExchanged, accountCurrency)}
            </Text>
            <Tooltip content={getExchangeCurrencyHint([contractCurrency, accountCurrency])} size="M">
              <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.text.color3} />
            </Tooltip>
          </Stack>
        )}
      </Stack>

      <ChipContainer>
        {annualTotalSavingsPercentage > 0 && (
          <ChipButton color="success" variant="ghost" size="M">
            {formatSavingsPercentage(annualTotalSavingsPercentage)}
          </ChipButton>
        )}
      </ChipContainer>
    </Stack>
  );
};
