import { Stack } from '@mui/material';
import React from 'react';
import { StatItemType } from '../../types';
import AnimatedTextNumber from '../AnimatedTextNumber';
import LegendItem from '@vertice/core/src/components/charts/components/Legend/LegendItem';

export type ChartStatLegendProps = {
  items?: StatItemType[];
};

const StatItem = ({ id, value, valueFormatter, name, color }: StatItemType) => {
  const formattedValue = valueFormatter ? valueFormatter(value) : value;

  return (
    <Stack gap={1} flex={1}>
      <AnimatedTextNumber variant="heading" size="M" color="text1">
        {formattedValue}
      </AnimatedTextNumber>
      <LegendItem label={name} id={id} color={color} />
    </Stack>
  );
};

const ChartStatLegend = ({ items }: ChartStatLegendProps) => {
  const hasItems = items && items.length > 0;
  if (!hasItems) {
    return null;
  }

  return (
    <Stack gap={2} direction="row">
      {items.map((item) => (
        <StatItem key={item.id} {...item} />
      ))}
    </Stack>
  );
};

export default ChartStatLegend;
