import { DeadlineOrigin } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { TFunction } from 'i18next/typescript/t';
import { match } from 'ts-pattern';

export const getDeadlineOriginLabel = (origin: DeadlineOrigin | undefined, t: TFunction<'translation'>) => {
  if (!origin) {
    return undefined;
  }

  return match(origin)
    .with('USERDEADLINE', () =>
      t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.HEADER.DEADLINE_ORIGIN.PURCHASE_TARGET_SIGN_DATE')
    )
    .with('FOLLOWINGVERSIONUSERDEADLINE', () =>
      t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.HEADER.DEADLINE_ORIGIN.RENEWAL_TARGET_SIGN_DATE')
    )
    .with('RENEWALDATE', () => t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.HEADER.DEADLINE_ORIGIN.RENEWAL_DATE'))
    .with('AUTORENEWALDEADLINE', () => t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.HEADER.DEADLINE_ORIGIN.AUTO_RENEWAL_DATE'))
    .exhaustive();
};
