import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  createServiceIdentifier,
  isGenericNewPurchaseServiceRef,
  isSaasNewPurchaseServiceRef,
} from '@vertice/core/src/hooks/workflows/refUtils';
import { useGetCatalogResources } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useGetCatalogResources';
import i18n from '@vertice/i18n/src/i18n';
import { TriggerSettingsItem, useUpdateSlackIntegrationConfigMutation } from '@vertice/slices';
import {
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
  Checkbox,
  FormControlLabel,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Text,
} from '@verticeone/design-system';
import { isServiceCatalogResource } from '@vertice/core/src/modules/intelligentWorkflows/catalogResource/utils';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isValidIntegrationResponse } from '../../../common';

type WorkflowTriggersCardProps = {
  triggerSettings?: { [p: string]: TriggerSettingsItem };
};

type SaveSettingsData = {
  'saas-purchase'?: TriggerSettingsItem;
  'generic-purchase'?: TriggerSettingsItem;
};

type SaveSettingsDataSection = keyof SaveSettingsData;

export const WorkflowTriggersCard: FC<WorkflowTriggersCardProps> = ({ triggerSettings }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();

  const { resources: workflows } = useGetCatalogResources({
    resourcesFilter: (item) =>
      isServiceCatalogResource(item) &&
      (isSaasNewPurchaseServiceRef(item.urn) || isGenericNewPurchaseServiceRef(item.urn)),
    skipGlobalResources: true,
  });

  const workflowOrder: SaveSettingsDataSection[] = ['saas-purchase', 'generic-purchase'];
  const workflowComparator = (a: string, b: string) =>
    workflowOrder.indexOf(a as SaveSettingsDataSection) - workflowOrder.indexOf(b as SaveSettingsDataSection);

  const [saveTriggerSettings] = useUpdateSlackIntegrationConfigMutation();

  const [triggerState, setTriggerState] = useState<Record<string, TriggerSettingsItem>>();

  useEffect(() => {
    triggerSettings && setTriggerState(triggerSettings);
  }, [triggerSettings]);

  const onSave = useCallback(
    async (workflowKey: string, enabled: boolean) => {
      const setupTriggerSettings = () => {
        const settings = {} as Record<SaveSettingsDataSection, any>;
        triggerState &&
          Object.keys(triggerState).forEach((k) => {
            const key = k as SaveSettingsDataSection;
            settings[key] =
              key === workflowKey ? { workflow: triggerState?.[key]?.workflow, enabled: enabled } : triggerState[key];
          });
        return settings;
      };

      const response = await saveTriggerSettings({
        accountId: accountId,
        body: {
          triggerSettings: setupTriggerSettings(),
        },
      });

      if (isValidIntegrationResponse(response) && response.data?.triggerSettings) {
        const settingsResponse = response.data?.triggerSettings;
        setTriggerState(settingsResponse);
      }
    },
    [accountId, saveTriggerSettings, triggerState]
  );

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle
          text={
            <Text variant="heading" size="S" color="text1">
              {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.CARD_HEADER')}
            </Text>
          }
        />
        <CardHeaderDescription>
          <Trans
            i18n={i18n}
            i18nKey={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.CARD_SUB_HEADER_DESCRIPTION')}
            components={{ bold: <Text variant="body-bold" size="S" /> }}
          />
        </CardHeaderDescription>
      </CardHeader>
      <Grid container spacing={6} sx={{ p: 6 }}>
        <Grid item xs={12} sm={6}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.WORKFLOW_TRIGGERS.COLUMN_WORKFLOW')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {triggerState
                  ? Object.keys(triggerState)
                      .sort(workflowComparator)
                      .map((triggerKey) => {
                        const trigger = triggerState[triggerKey];
                        const workflow = workflows.find((w) => w.urn.startsWith(trigger.workflow));
                        if (!workflow) return null;
                        const link = generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
                          serviceId: createServiceIdentifier(workflow.urn),
                        });
                        return (
                          <TableRow key={workflow.urn}>
                            <TableCell>
                              <Link href={link}>
                                <Stack direction="row" gap="8px">
                                  {workflow.name}
                                  <KeyboardArrowRightIcon />
                                </Stack>
                              </Link>
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    sx={{ display: 'inline-flex' }}
                                    checked={trigger.enabled}
                                    onChange={(_e, checked) => onSave(triggerKey, checked)}
                                  />
                                }
                                label={triggerKey}
                                sx={{
                                  '& .MuiFormControlLabel-label': {
                                    display: 'none',
                                  },
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};
