import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CompletedRequestsGraph from './CompletedRequestsGraph';
import dayjs from 'dayjs';
import { ChartCard, ChartProvider, useChartContext, ChartStats } from '../ChartCard';
import useCompletedRequestsData, { CompletedRequestsData } from './useCompletedRequestsData';
import { Placeholder } from '@verticeone/design-system';
import { ifExpression } from '@verticeone/utils/logic';
import { getTooltipValue } from '../ChartCard/utils';
import EmptyGraphWrapper from './EmptyGraphWrapper';
import { EmptyState } from '../../types';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';

type CompletedRequestsCardProps = EmptyState & {
  data: CompletedRequestsData['data'];
};

const CompletedRequestsCard = ({ data, isEmpty, isFilteredEmpty }: CompletedRequestsCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { tooltipData, months } = useChartContext();
  const { palette } = useTheme();

  const onTime = getTooltipValue(tooltipData?.values.onTime, data.stats.totalNumberOfOntimeRequests);
  const late = getTooltipValue(tooltipData?.values.late, data.stats.totalNumberOfOverdueRequests);
  const total = getTooltipValue(tooltipData?.total, data.stats.total);

  const onTimeLabel = t('COMPLETED_REQUESTS.LEGEND.ON_TIME');
  const lateLabel = t('COMPLETED_REQUESTS.LEGEND.OVERDUE');

  const series = useMemo(() => {
    return data.values.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'onTime', {
          id: 'onTime',
          name: onTimeLabel,
          color: palette.visualization.divergent.tertiary['+50'],
          type: 'column',
        }),
        ...ifExpression(item.id === 'late', {
          id: 'late',
          name: lateLabel,
          color: palette.visualization.divergent.secondary['+30'],
          type: 'column',
        }),
      };
    }) as SeriesOptionsWithData[];
  }, [data.values, lateLabel, onTimeLabel, palette.visualization.divergent]);

  return (
    <ChartCard
      title={t('COMPLETED_REQUESTS.TITLE')}
      subtitle={
        tooltipData?.category ? dayjs(tooltipData?.category).format('MMM, YYYY') : t('LAST_X_MONTHS', { count: months })
      }
      stats={
        <ChartStats
          total={total}
          totalLabel={t('COMPLETED_REQUESTS.TOTAL')}
          items={[
            {
              id: 'onTime',
              name: onTimeLabel,
              value: onTime,
              color: palette.visualization.divergent.tertiary['+50'],
            },
            {
              id: 'late',
              name: lateLabel,
              value: late,
              color: palette.visualization.divergent.secondary['+30'],
            },
          ]}
        />
      }
      testId="completed-requests"
    >
      <EmptyGraphWrapper isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty}>
        <CompletedRequestsGraph series={series} categories={data.categories} isEmpty={isEmpty || isFilteredEmpty} />
      </EmptyGraphWrapper>
    </ChartCard>
  );
};

const LoadableCompletedRequestsCard = () => {
  const { data, isFetching, isEmpty, isFilteredEmpty } = useCompletedRequestsData();

  if (isFetching) {
    return (
      <ChartCard.Skeleton stats={<ChartStats.Skeleton />} testId="completed-requests-skeleton">
        <Placeholder variant="rounded" width="100%" height="360px" />
      </ChartCard.Skeleton>
    );
  }

  return <CompletedRequestsCard data={data} isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty} />;
};

const CompletedRequestsCardWithContext = () => {
  const initialMonths = 6;
  const endDate = dayjs().subtract(1, 'months').startOf('month');
  const startDate = endDate.subtract(initialMonths - 1, 'months').startOf('month');

  return (
    <ChartProvider
      defaultRange={{
        startDate,
        endDate,
      }}
    >
      <LoadableCompletedRequestsCard />
    </ChartProvider>
  );
};

export default CompletedRequestsCardWithContext;
