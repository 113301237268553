import { DesignSystemColor } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { ActiveEditingField } from '../../../../../intelligentWorkflows/workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm/CustomFormFieldDialog';
import { DynamicFormField } from '../../../types';
import { RepeatingFieldSchemaFields } from './RepeatingFieldSchemaFields';
import { PrimitiveDynamicFormField } from '../../../primitiveTypes';
import { FieldDefPresetsOptions, getFieldDefPresetsOptionsFromFeatures } from '../../fieldPresets';
import { useFeatures } from '../../../../../features/useFeatures';
import { SelectNewField } from '../../../DynamicFormBuilder';
import { CustomPropertyFieldDialog } from './CustomPropertyFieldDialog';
import { moveArrayItem } from '@verticeone/utils/arrays';

const MAX_ARRAY_ITEMS = 5;

type RepeatingFormFieldProps = {
  name: string;
  fieldsDefinition: PrimitiveDynamicFormField[];
  onFieldsSchemaChange: (fields: PrimitiveDynamicFormField[]) => void;
};

type RepeatingFieldSchemaEditorProps = RepeatingFormFieldProps & {
  color: DesignSystemColor;
};

export const RepeatingFieldSchemaEditor = ({
  name,
  fieldsDefinition,
  onFieldsSchemaChange,
}: RepeatingFieldSchemaEditorProps) => {
  const [activeEditorField, setActiveEditorField] = useState<ActiveEditingField | undefined>(undefined);
  const { getFeatureProperty } = useFeatures();

  const fieldPresetsOptions: FieldDefPresetsOptions = useMemo(
    () => ({
      ...getFieldDefPresetsOptionsFromFeatures(getFeatureProperty),
      repeatingFieldEnabled: false,
    }),
    [getFeatureProperty]
  );

  const onSchemaFieldsUpdate = useCallback(
    (updateIndex: number, value: PrimitiveDynamicFormField) => {
      const updatedFields = [...fieldsDefinition];
      updatedFields[updateIndex] = value;
      onFieldsSchemaChange(updatedFields);
    },
    [fieldsDefinition, onFieldsSchemaChange]
  );

  const onSchemaFieldsMove = (oldIndex: number, newIndex: number) => {
    onFieldsSchemaChange(moveArrayItem(fieldsDefinition, oldIndex, newIndex));
  };

  const onSchemaFieldsRemove = (removeIndex: number) => {
    onFieldsSchemaChange(fieldsDefinition.filter((_, i) => i !== removeIndex));
  };

  const handleEditorSubmit = useCallback(
    (field: DynamicFormField) => {
      if (field.type !== 'REPEATING') {
        if (activeEditorField && activeEditorField.index !== null) {
          onSchemaFieldsUpdate(activeEditorField.index!, field);
        } else {
          onFieldsSchemaChange([...fieldsDefinition, field]);
        }
        setActiveEditorField(undefined);
      }
    },
    [activeEditorField, onSchemaFieldsUpdate, onFieldsSchemaChange, fieldsDefinition]
  );

  return (
    <Stack key={name} gap={4} border={1} borderRadius={3} padding={4} borderColor="core.color4" width={'100%'}>
      <RepeatingFieldSchemaFields
        fields={fieldsDefinition}
        repeatingFieldName={name}
        onSchemaFieldsMove={onSchemaFieldsMove}
        onSchemaFieldsRemove={onSchemaFieldsRemove}
        onSchemaFieldsUpdate={onSchemaFieldsUpdate}
        onEdit={(activeEditingField) => setActiveEditorField(activeEditingField)}
      />
      {activeEditorField && (
        <CustomPropertyFieldDialog
          activeField={activeEditorField}
          field={activeEditorField.index ? fieldsDefinition[activeEditorField.index] : undefined}
          usedFieldNames={fieldsDefinition.map((f) => f.name)}
          onClose={() => setActiveEditorField(undefined)}
          onSubmit={handleEditorSubmit}
        />
      )}
      {MAX_ARRAY_ITEMS > fieldsDefinition.length && (
        <SelectNewField
          fieldPresetsOptions={fieldPresetsOptions}
          onAdd={(fieldType, defaultMetadata) => setActiveEditorField({ index: null, fieldType, defaultMetadata })}
        />
      )}
    </Stack>
  );
};
