import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { LineItem, LineItemType } from '../../types';
import { useTranslation } from 'react-i18next';
import { GridSelect, SelectColor, SimpleOption, useDataGridContext } from '@verticeone/design-system';
import { useLineItemTypeOptions } from './useLineItemTypeOptions';

export const EditableLineItemTypeCell = ({ id, value, field, hasFocus }: GridCellParams<LineItem, LineItemType>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const options = useLineItemTypeOptions();
  const { color } = useDataGridContext();
  return (
    <GridSelect<SimpleOption, false>
      isMulti={false}
      value={options.find((opt: SimpleOption) => opt.value === value)}
      options={options}
      placeholder={t('ENTITIES.LINE_ITEMS_TABLE.LINE_TYPE')}
      menuPlacement="auto"
      onChange={(newValue) => {
        if (!newValue) return;
        void apiRef.current.setEditCellValue({ id, field, value: newValue.value });
      }}
      color={color as SelectColor}
      hasFocus={hasFocus}
    />
  );
};
