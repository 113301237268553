import { ProcessEdge, ProcessNode, ProcessNodeType, TemplateEdge, TemplateNode, TemplateNodePosition } from './types';
import { Node, XYPosition } from '@xyflow/react';
import { keyBy } from 'lodash';

export const getRandomFlowElementId = (prefix = 'rand') => {
  return `${prefix}_${Math.random().toString(36).substring(2)}`;
};

export const createProcessNode = <DataType extends { id: string }, NodeType extends ProcessNodeType>(
  type: NodeType,
  position: XYPosition,
  data: Omit<DataType, 'id'>
): Node<DataType, NodeType> => {
  const dta = {
    id: getRandomFlowElementId(type),
    ...data,
  } as DataType;
  const nodeId = getRandomFlowElementId('shape');
  return buildNode(nodeId, type, position, dta);
};

export const createTemplateNodes = (position: XYPosition, configuration: any): ProcessNode[] => {
  if (!configuration || !configuration?.template?.nodes) {
    return [];
  }

  const nodes = configuration?.template?.nodes;
  return nodes.map((node: TemplateNode) =>
    buildNode(
      node.nodeId || getRandomFlowElementId('shape'),
      node.type,
      resolveNodePosition(position, node.position),
      node.data
    )
  );
};

export const createTemplateEdges = (templateConfiguration: any, nodes: ProcessNode[]): ProcessEdge[] => {
  if (!templateConfiguration?.template?.edges) {
    return [];
  }

  const nodesById = keyBy(nodes, 'data.id');

  return templateConfiguration?.template?.edges.map((edge: TemplateEdge) => ({
    target: nodesById[edge.to].id || '',
    source: nodesById[edge.from].id || '',
    id: edge.id,
    data: {},
  }));
};

function buildNode<NodeType, DataType>(nodeId: string, type: NodeType, position: XYPosition, dta: DataType) {
  return {
    id: nodeId,
    type,
    position,
    data: dta,
  };
}

const resolveNodePosition = (basePosition: XYPosition, position: TemplateNodePosition): XYPosition => {
  if (position.type === 'ABSOLUTE') {
    return {
      x: position.x,
      y: position.y,
    };
  }

  return {
    x: basePosition.x + position.x,
    y: basePosition.y + position.y,
  };
};
