import { DesignSystemColor } from '@verticeone/design-system';

export const GENERIC_FORM_FIELD_LIMIT = 30;

export const INTELLIGENT_WORKFLOWS_BRAND_COLOR: 'secondary' = 'secondary' satisfies DesignSystemColor;

export const SERVICE_CATALOG_SERVICE_KIND = 'Vertice/ServiceCatalog/Service/ServiceDefinition';

export const COMMON_BUTTON_PROPS = {
  fullWidth: true,
  color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
  size: 'S' as const,
};

// This account id is used for the internal workflow admin account which is used for managing internal workflows
// and their distribution to customer accounts
export const WORKFLOW_ADMIN_ACCOUNT_ID = 'VERTICE_WORKFLOW_ADMINISTRATION';

export const EDITED_CUSTOM_FORM_URN_REGEX = /:[a-f0-9-]+:services:form\/core\/generic\/(.+\/v[0-9]+\/.+)/g;
export const SIMPLE_APPROVAL_FORM_URN_REGEX = /:[a-f0-9-]+:services:form\/core\/approval\/simple\/v[0-9]+/g;
export const REVIEW_DRAFT_CONTRACT_URN_REGEX = /:[a-f0-9-]+:services:form\/core\/reviewContract\/v[0-9]+/g;
export const CREATE_CONTRACT_SERVICE_URN_REGEX = /:services:service\/(create_contract|core\/create_contract)\/v[0-9]+/g;
export const ADVISORY_SERVICE_TASK_URN_REGEX = /:services:service\/saas\/renewal\/advisory\/v[0-9]+/g;

// This is just a placeholder, for now the value has no meaning as BE ignores it
export const DEFINITION_VERSION = '2023-10-11';

export const DUE_DATE_HOW_TO_GUIDE =
  'https://support.vertice.one/hc/en-us/articles/32958706531601-How-to-set-task-due-dates';
