import { FC, useState } from 'react';
import { Placeholder } from '@verticeone/design-system';
import { useAuthorizer } from '@verticeone/auth/src';
import type { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Task, useAssignUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';

import type { TaskRow } from '../../../dataSource';
import AssigneeSelect from '../../../../components/AssigneeSelect';
import { TaskAssignees } from '../TaskAssignees';
import { GenericUserIcon } from '../../../../components/icons/Icons';
import { useAccountContext } from '../../../../../account/AccountContext';
import { DetailRow } from './DetailRow';

type AssigneeDetailRowProps = { taskOverview?: TaskOverview; task: TaskRow };

const canReassignTask = (task: Task, useCanReassignTask: boolean) =>
  useCanReassignTask && !Array<Task['status']>('COMPLETED', 'TERMINATED').includes(task.status);

export const AssigneeDetailRow: FC<AssigneeDetailRowProps> = ({ taskOverview, task }) => {
  const [editingAssignees, setEditingAssignees] = useState(false);

  const { accountId } = useAccountContext();
  const { isAllowed: canReassign } = useAuthorizer({
    id: `ReassignTask_${task.taskId}`,
    object: `urn:verticeone:vertice:${accountId}:workflows:task/${task.taskId}`,
    action: 'task:Assign',
  });

  const [assignUserMutation, { isLoading: isAssigningUserTask }] = useAssignUserTaskMutation();
  const handleAssignmentChange = async (assignment: string[]) => {
    await assignUserMutation({
      accountId,
      taskId: task.id,
      assignUserTask: {
        assignments: assignment,
      },
    });
  };

  const taskCompletedAt =
    (task.status === 'COMPLETED' || task.status === 'FAILED' || task.status === 'TERMINATED') && task.closedAt;
  const isEditingAssigneesEnabled = !taskCompletedAt && canReassign;

  return (
    <DetailRow
      label={
        taskOverview?.task ? (
          canReassignTask(taskOverview.task, canReassign) && editingAssignees ? (
            <AssigneeSelect
              task={taskOverview.task}
              onChange={handleAssignmentChange}
              isLoading={isAssigningUserTask}
              size="S"
              color="secondary"
              variant="solid"
            />
          ) : (
            <TaskAssignees
              assignees={task.assignees}
              setEdit={isEditingAssigneesEnabled ? setEditingAssignees : undefined}
            />
          )
        ) : (
          <Placeholder width={150} />
        )
      }
      testId="assignees"
      icon={GenericUserIcon}
    />
  );
};
