import { Request } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Request as BaseRequest } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Request as LegacyRequest } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isRequestRef, parseRequestRef, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { RequestRow } from './types';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useListRequestsQuery as useListRequestsLegacyQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { getDataForWidget } from '../RequestDetailPage/widgets/shared/utils';
import { DETAILS_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/Details/widgetDef';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { useListServiceRequestsPaginatedQuery } from '@vertice/slices';
import { LINKED_CONTRACT_WIDGET_URN_PATTERN } from '../RequestDetailPage/widgets/LinkedContracts/widgetDef';
import {
  FINAL_OFFER_WIDGET_URN_PATTERN,
  BASELINE_OFFER_WIDGET_URN_PATTERN,
} from '../RequestDetailPage/widgets/Offer/widgetDef';
import { Vendor } from '../../../vendor/types';

export const SAAS_KEY_DATES_WIDGET_URN_PATTERN = /^widget\/saas\/key-dates\/v\d+$/;
export const SAAS_KEY_COSTS_WIDGET_URN_PATTERN = /^widget\/saas\/key-costs\/v\d+$/;
export const GENERIC_KEY_DATES_WIDGET_URN_PATTERN = /^widget\/core\/key-dates\/simple\/v\d+$/;
export const GENERIC_KEY_COSTS_WIDGET_URN_PATTERN = /^widget\/core\/key-costs\/simple\/v\d+$/;

export const mapRequestRow = (request: BaseRequest | Request): RequestRow => {
  const { requestId } = parseRequestRef(request.ref);
  const parentRequestId = request.parentRequestRef ? parseRequestRef(request.parentRequestRef).requestId : undefined;
  const vendor: Vendor | undefined =
    getDataForWidget(request, DETAILS_WIDGET_URN_PATTERN)?.vendor ??
    // Fallback to input, otherwise fallback to verticeReserved, however it's deprecated
    ('input' in request && request.input ? request.input?.vendor : request.verticeReserved?.vendor);
  const contractsWidget =
    getDataForWidget(request, LINKED_CONTRACT_WIDGET_URN_PATTERN) ??
    ('input' in request
      ? { contractId: request.input?.contractId }
      : { contractId: request.verticeReserved?.contractId });
  const saasKeyDateWidget = getDataForWidget(request, SAAS_KEY_DATES_WIDGET_URN_PATTERN);
  let genKeyDateWidget = getDataForWidget(request, GENERIC_KEY_DATES_WIDGET_URN_PATTERN);
  const saasKeyCostWidget = getDataForWidget(request, SAAS_KEY_COSTS_WIDGET_URN_PATTERN);
  let genKeyCostWidget = getDataForWidget(request, GENERIC_KEY_COSTS_WIDGET_URN_PATTERN);
  const baselineOfferWidget = getDataForWidget(request, BASELINE_OFFER_WIDGET_URN_PATTERN);
  const finalOfferWidget = getDataForWidget(request, FINAL_OFFER_WIDGET_URN_PATTERN);

  let keyDateSource, keyCostSource;
  if (genKeyDateWidget?.keyDateSource) {
    ({ keyDateSource, ...genKeyDateWidget } = genKeyDateWidget);
  }
  if (genKeyCostWidget?.keyCostSource) {
    ({ keyCostSource, ...genKeyCostWidget } = genKeyCostWidget);
  }

  return {
    requestId: requestId,
    requestName: request.name,
    requestType: request.serviceName,
    resultType: request.resultType,
    createdAt: request.createdAt,
    lastActivityAt: request.updatedAt,
    closedAt: request.closedAt,
    status: request.status,
    parentId: parentRequestId,
    vendor: vendor,
    ownerId: request.owner ? parseUserRef(request.owner).userId : undefined,
    keyDates: { ...saasKeyDateWidget, ...genKeyDateWidget },
    keyDateSource: keyDateSource,
    keyCosts: { ...saasKeyCostWidget, ...genKeyCostWidget },
    keyCostSource: keyCostSource,
    contracts: contractsWidget,
    offersFinal: finalOfferWidget,
    offersBaseline: baselineOfferWidget,
    isPartial: true,
    sourceRef: request.sourceRef,
  };
};

const getLegacyRequestRows = (items: LegacyRequest[]): RequestRow[] => {
  const groupedByContractId = groupBy(items, 'contractId');
  const uniqueItems = Object.values(groupedByContractId).map((group) => {
    return group.reduce((previousItem, item) => {
      if (!previousItem || new Date(item.createdAt) > new Date(previousItem.createdAt)) {
        return item;
      }
      return previousItem;
    });
  });

  return uniqueItems.map((request) => {
    const { requestId } = parseRequestRef(request.ref);
    const vendor = request.vendor;

    return {
      requestId: request.contractId || requestId,
      requestName: request.name,
      createdAt: request.createdAt,
      lastActivityAt: request.lastActivityAt,
      closedAt: request.closedAt,
      status: request.status,
      isLegacy: true,
      vendor,
    };
  });
};

export const useRequests = ({
  skip,
  statusFilter,
}: {
  skip?: boolean;
  statusFilter?: string;
} = {}) => {
  const { accountId } = useAccountContext();

  const {
    data: requests,
    isLoading: isLoadingRequests,
    isFetching: isFetchingRequests,
    refetch,
  } = useListServiceRequestsPaginatedQuery(
    {
      accountId,
      status: statusFilter,
    },
    { skip }
  );

  const {
    data: legacyRequests,
    isLoading: isLoadingLegacyRequests,
    refetch: refetchLegacyRequests,
  } = useListRequestsLegacyQuery({ accountId }, { skip });
  // while IW requests are filtered on BE, the legacy ones need to be filtered here
  const legacyRequestItems = useMemo(
    () => legacyRequests?.items?.filter((item) => statusFilter === undefined || item.status === statusFilter),
    [legacyRequests, statusFilter]
  );

  const isLoading = useMemo(
    () => isLoadingRequests || isLoadingLegacyRequests,
    [isLoadingRequests, isLoadingLegacyRequests]
  );
  const refetchAll = useCallback(async () => {
    await Promise.all([refetch(), refetchLegacyRequests()]);
  }, [refetch, refetchLegacyRequests]);

  useNotificationWebSocketSubscription({
    filter: isRequestRef,
    callback: () => {
      void refetchAll();
    },
  });

  const { rows, ids } = useMemo(() => {
    if (!requests?.items || !legacyRequestItems) {
      return { rows: [], ids: new Set() };
    }

    const legacyRequestRows = getLegacyRequestRows(legacyRequestItems);
    const iwRequestRows = requests?.items.map(mapRequestRow) || [];
    let requestRows: RequestRow[] = [...iwRequestRows, ...legacyRequestRows];
    if (accountId !== 'VERTICE_WORKFLOW_ADMINISTRATION') {
      requestRows = requestRows.filter((row) => !row.parentId);
    }
    const requestIds = new Set(requestRows.map((row) => row.requestId));
    return { rows: requestRows, ids: requestIds };
  }, [accountId, requests?.items, legacyRequestItems]);

  return {
    requests: rows,
    requestIds: ids,
    isLoadingRequests: isLoading,
    isFetchingRequests: isFetchingRequests,
    refetch: refetchAll,
  };
};
