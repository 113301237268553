import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Select } from '@verticeone/design-system';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { Filter, Owner } from '../types';
import { getContractsOwners } from '../utils';

type OwnerFilterProps = {
  views?: ViewOnContract[];
};

export const OwnerFilter: FC<OwnerFilterProps> = ({ views = [] }) => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastOwners = setFilterValue(Filter.OWNER);
  const onChange = (selection: MultiValue<Owner>) =>
    setLastOwners(selection.length ? selection?.map((option) => option.ownerId) : undefined);
  const owners = getContractsOwners(views);

  return (
    <Stack minWidth={240}>
      {/* TODO: Use our unified @vertice/core/src/modules/user/UserSelect instead */}
      <Select<Owner, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={owners}
        value={owners?.filter(({ ownerId }) => filterValues?.[Filter.OWNER]?.includes(ownerId))}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ ownerId }) => ownerId}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.OWNER')}
        onChange={onChange}
        testId={'owner-filter'}
      />
    </Stack>
  );
};
