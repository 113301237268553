import { ZodType, z } from 'zod';
import { InlineVendor } from '../../../../../../vendor/types';
import { ContractEntityCategory } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export const contractDetailsSchema = z.object({
  departmentId: z.string({ message: '' }),
  contractCategory: z.enum(['SAAS', 'OTHER']) satisfies ZodType<ContractEntityCategory>,
  vendor: z.object(
    {
      type: z.literal('INLINE'),
      name: z.string(),
      id: z.string().nullish(),
    },
    { message: '' }
  ) satisfies ZodType<InlineVendor>,
});

export type ContractDetailsForm = z.infer<typeof contractDetailsSchema>;
