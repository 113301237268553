import { ContractReviewForm } from '../../../types';

export type ComputedCostKind = 'total' | 'annual';

/**
 * Determines the computed cost kind based on the contract review form data.
 *
 * @param contractForm - The contract review form containing contract details.
 * @returns The computed cost kind, either 'total' for one-off non-SaaS contracts or 'annual' otherwise.
 */
export const getComputedCostKind = (contractForm: ContractReviewForm): ComputedCostKind => {
  const isNonSaas = contractForm.contractDetails.contractCategory === 'OTHER';
  const isOneOff = contractForm.contractDates.contractType === 'ONE_OFF';

  return isNonSaas && isOneOff ? 'total' : 'annual';
};
