import { useDiscoveredApplicationMutation } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import {
  ApplicationStatus,
  useGetApplicationWithMeasureReportsQuery,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { useMemo } from 'react';
import { useAccountContext } from '../../../account/AccountContext';

export const useApplication = (id?: string) => {
  const { accountId } = useAccountContext();
  const [updateDiscoveredApplication] = useDiscoveredApplicationMutation();

  const { data, isSuccess, isFetching, refetch } = useGetApplicationWithMeasureReportsQuery(
    {
      accountId: accountId!,
      applicationId: id!,
    },
    {
      skip: !accountId || !id,
    }
  );
  const application = data?.getApplicationWithMeasure;

  const res = useMemo(() => {
    const isHidden = application?.status === ApplicationStatus.Ignored;

    return {
      application,
      isFetching,
      refetch,
      isSuccess,
      isHidden,
      toggleStatus: () =>
        updateDiscoveredApplication({
          accountId: accountId!,
          input: {
            id: id!,
            status: isHidden ? ApplicationStatus.Discovered : ApplicationStatus.Ignored,
          },
        }),
    };
  }, [accountId, application, id, isFetching, isSuccess, refetch, updateDiscoveredApplication]);
  return res;
};
