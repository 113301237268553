import { Box, styled, Theme } from '@mui/material';
import { FC } from 'react';

import { Text } from '@verticeone/design-system';
import { Variable } from '../../types';

const LabelWrapper = styled(Text)<{ $theme: Theme; $disabled: boolean }>(({ $theme, $disabled }) => ({
  display: 'inline-flex',
  gap: $theme.spacing(1),
  alignItems: 'center',
  backgroundColor: $disabled ? $theme.palette.inactive.color4 : $theme.palette.secondary.color4,
  borderRadius: $theme.spacing(1),
  padding: '2px 4px',
  color: $disabled ? $theme.palette.inactive.color1 : $theme.palette.secondary.color1,
}));

const TypeWrapper = styled(Text)<{ $theme: Theme; $disabled: boolean }>(({ $theme, $disabled }) => ({
  backgroundColor: $disabled ? $theme.palette.inactive.color3 : $theme.palette.secondary.color3,
  borderRadius: $theme.spacing(1),
  padding: '2px 4px',
  color: $disabled ? $theme.palette.inactive.color2 : $theme.palette.secondary.color1,
}));

type VariableTokenProps = {
  theme: Theme;
  variable: Variable;
  disabled?: boolean;
};

export const VariableToken: FC<VariableTokenProps> = ({ theme, variable, disabled = false }) => {
  return (
    <>
      <Box component="span" display="none" className="variable-id">{`{{${variable.id}}}`}</Box>
      <LabelWrapper
        variant="button-bold"
        size="S"
        contentEditable={false}
        $theme={theme}
        $disabled={disabled}
        className="variable-label"
      >
        {variable.label}
        {variable.type.labels !== undefined && (
          <TypeWrapper variant="button-bold" size="XXS" $theme={theme} $disabled={disabled} className="variable-type">
            {variable.type.labels[0]}
          </TypeWrapper>
        )}
      </LabelWrapper>
    </>
  );
};
