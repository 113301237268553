import { useCallback, useMemo } from 'react';
import { useRoutes } from '@verticeone/router/useRoutes';
import { mapContract2Schedule, mapRequest2Schedule } from '../components/utils';
import { generatePath } from 'react-router-dom';
import { useContracts } from '@vertice/core/src/modules/saas/contract/components/ContractList/useContracts';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { DASHBOARD_FILTERS } from '../components/types';
import { PREDEFINED_VIEW_ALL } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { useRequests } from '@vertice/core/src/modules/intelligentWorkflows/request/RequestsListPage/useRequests';
import { ScheduleDetails } from '@vertice/core/src/modules/saas/schedule/components/ScheduleView/types';
import { useRequestsNavigation } from '@vertice/core/src/modules/intelligentWorkflows/request/hooks/useRequestsNavigation';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import useContractValueFilter from '@vertice/core/src/modules/saas/contract/components/ContractList/Filters/hooks/useContractValueFilter';

export const useScheduleItems = (): ScheduleDetails[] => {
  const routes = useRoutes();

  const { generateRequestPath } = useRequestsNavigation();
  const { filterContractValue } = useContractValueFilter();

  const generateContractPath = useCallback(
    (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, { contractId }),
    [routes.CONTRACTS.DETAIL.ABSOLUTE_PATH]
  );

  const { getFeature } = useFeatures();
  const legacyPipeline = getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.legacyPipeline;

  const { requests, isLoadingRequests } = useRequests({ skip: legacyPipeline });
  const {
    viewsOnContracts: contracts,
    isLoading: isLoadingContracts,
    allViewsOnContracts,
  } = useContracts({
    activeFilterView: legacyPipeline ? PREDEFINED_VIEW_ALL : DASHBOARD_FILTERS.UPCOMING,
  });
  const allContracts = useMemo(() => {
    if (legacyPipeline) {
      return {};
    }
    return allViewsOnContracts.reduce((acc, contract) => {
      return { ...acc, [contract.contract.record.contractId]: contract };
    }, {} as Record<string, ViewOnContract>);
  }, [allViewsOnContracts, legacyPipeline]);

  return useMemo(() => {
    if (isLoadingRequests || isLoadingContracts) {
      return [];
    }

    const items: ScheduleDetails[] = [];
    for (const view of contracts) {
      items.push(mapContract2Schedule(view, generateContractPath, filterContractValue));
    }

    for (const request of requests) {
      const schedule = mapRequest2Schedule(request, allContracts, generateContractPath, generateRequestPath);
      if (schedule) {
        items.push(schedule);
      }
    }
    return items;
  }, [
    isLoadingRequests,
    isLoadingContracts,
    requests,
    contracts,
    allContracts,
    generateContractPath,
    generateRequestPath,
    filterContractValue,
  ]);
};
