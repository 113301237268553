import { useCallback, useState } from 'react';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  useLazyGetAccountIntegrationQuery,
  useActivateAccountIntegrationMutation,
  useDeactivateAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';

type UseChangeIntegrationStatusProps = {
  enabled: boolean;
};

export const useChangeIntegrationStatus = (props?: UseChangeIntegrationStatusProps) => {
  const { accountId } = useAccountContext();
  const [isEnabled, setIsEnabled] = useState(props?.enabled ?? false);
  const [getIntegrationQuery] = useLazyGetAccountIntegrationQuery();
  const [testIntegrationMutate] = useTestAccountIntegrationMutation();
  const [activateIntegrationMutate] = useActivateAccountIntegrationMutation();
  const [deactivateIntegrationMutate] = useDeactivateAccountIntegrationMutation();

  const pollConnectionStatus = useCallback(
    (integrationId: string, status: string) => {
      const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

      const getIntegration = async (): Promise<string> => {
        const { data: integration, isSuccess } = await getIntegrationQuery({ accountId, integrationId });
        if (isSuccess) {
          if (integration.connection?.status === status) {
            return Promise.resolve(status);
          }
          if (integration.connection?.status === 'FAILED') {
            return Promise.reject('FAILED');
          }
        }

        await delay(1500);
        return await getIntegration();
      };

      return getIntegration();
    },
    [accountId, getIntegrationQuery]
  );

  const activateIntegration = useCallback(
    (integrationId: string) => {
      return testIntegrationMutate({ accountId, integrationId, testName: 'connection' })
        .then(() => pollConnectionStatus(integrationId, 'OK'))
        .then(() => activateIntegrationMutate({ accountId, integrationId }))
        .finally(() => setIsEnabled(true));
    },
    [accountId, activateIntegrationMutate, pollConnectionStatus, testIntegrationMutate, setIsEnabled]
  );

  const deactivateIntegration = useCallback(
    (integrationId: string) => {
      return deactivateIntegrationMutate({ accountId, integrationId }).finally(() => setIsEnabled(false));
    },
    [accountId, deactivateIntegrationMutate, setIsEnabled]
  );

  const toggleActiveState = useCallback(
    (integrationId: string) => {
      return getIntegrationQuery({ accountId, integrationId }).then(({ data }) => {
        if (data?.status === 'ACTIVE') {
          return deactivateIntegration(integrationId);
        } else if (data?.status === 'INACTIVE') {
          return activateIntegration(integrationId);
        }
      });
    },
    [accountId, activateIntegration, deactivateIntegration, getIntegrationQuery]
  );

  return {
    toggleActiveState,
    activateIntegration,
    deactivateIntegration,
    isEnabled,
  };
};
