import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFilesDropArea from '@vertice/core/src/modules/forms/fields/FormFilesDropArea';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { TextFieldCaption } from '@verticeone/design-system';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import FilesList from '../../../SharedComponents/FilesList';
import { useParams } from 'react-router-dom';
import { useSetFormVendor } from '../../../sharedHooks/useSetFormVendor';

const useUploadExistingContractWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('spend_details'),
  };
};

const UploadExistingContractStep: React.FC<WizardStepProps> = () => {
  const { id: vendorId } = useParams();
  const { t } = useTranslation();

  const { goBack, goToNext } = useUploadExistingContractWizard();

  const { formState, trigger, control } = useFormContext<AddExistingContractFormData>();
  const files = useWatch({ control, name: 'files' });

  useSetFormVendor(vendorId);

  const nextEnabled = useMemo(() => formState.isValid, [formState]);
  useEffect(() => {
    void trigger(['files']);
  }, [trigger, files]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel showProducts={false} />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <TextFieldCaption
                label={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.LABELS.EXISTING_CONTRACT_FILE')}
                size="XS"
                required
              />
              <FormFilesDropArea name="files" required />
              <FilesList />
            </Stack>
          </Grid>
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextEnabled,
        }}
      />
    </Content>
  );
};

export default UploadExistingContractStep;
