import React, { createContext, useContext, useState, PropsWithChildren } from 'react';

export type Filter = {
  taskType: string;
  requestType: string;
};

type TooltipData = {
  values: Record<string, number>;
  category: string | number;
  total: number;
};

type TimeToCompleteTaskContextType = {
  filter: Filter;
  updateFilterValue: (key: keyof Filter, value: string) => void;
  resetFilter: () => void;
  tooltipData: TooltipData | null;
  setTooltipData: (data: TooltipData | null) => void;
};

const TimeToCompleteTaskContext = createContext<TimeToCompleteTaskContextType | undefined>(undefined);

type TimeToCompleteTaskProviderProps = PropsWithChildren & {
  defaultFilter: Filter;
};

export const TimeToCompleteTaskProvider = ({ children, defaultFilter }: TimeToCompleteTaskProviderProps) => {
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const [tooltipData, setTooltipData] = React.useState<TooltipData | null>(null);

  const updateFilterValue = (key: keyof Filter, value: string) => {
    setFilter((prevFilter) => ({ ...prevFilter, [key]: value }));
  };

  const resetFilter = () => {
    setFilter(defaultFilter);
  };

  return (
    <TimeToCompleteTaskContext.Provider value={{ filter, updateFilterValue, resetFilter, tooltipData, setTooltipData }}>
      {children}
    </TimeToCompleteTaskContext.Provider>
  );
};

export const useTimeToCompleteTaskContext = (): TimeToCompleteTaskContextType => {
  const context = useContext(TimeToCompleteTaskContext);
  if (!context) {
    throw new Error('useTimeToCompleteTaskContext must be used within a TimeToCompleteTaskProvider');
  }
  return context;
};
