import { useMemo } from 'react';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

const useSavingsDashboard = () => {
  const { isUserAdmin, isUserPowerUser } = useLoggedUserAccountRoles();
  const userCanSeeDashboard = isUserAdmin || isUserPowerUser;
  return useMemo(
    () => ({
      enabled: userCanSeeDashboard,
    }),
    [userCanSeeDashboard]
  );
};

export default useSavingsDashboard;
