import React, { PropsWithChildren } from 'react';
import { ApprovalOutlined, FilterAltOutlined } from '@mui/icons-material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useTimeToCompleteTaskContext } from './TimeToCompleteTaskContext';
import { ChartWrapper } from '../ChartCard';
import { EmptyState } from '../../types';

type EmptyGraphWrapperProps = EmptyState & PropsWithChildren;

const EmptyGraphWrapper = ({ isEmpty, isFilteredEmpty, children }: EmptyGraphWrapperProps) => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { resetFilter } = useTimeToCompleteTaskContext();

  const handleClick = () => {
    navigate(routes.INTELLIGENT_WORKFLOWS.TASKS, null, {
      search: { status: 'ALL', myTasksOnly: false },
    });
  };

  return (
    <ChartWrapper
      isEmpty={isEmpty}
      isFilteredEmpty={isFilteredEmpty}
      testId="time-to-complete-task"
      noFilteredData={{ icon: FilterAltOutlined, onClick: resetFilter }}
      noData={{ icon: ApprovalOutlined, onClick: handleClick }}
      tKeyPrefix="INTELLIGENT_WORKFLOWS.DASHBOARD.TIME_TO_COMPLETE_TASK"
    >
      {children}
    </ChartWrapper>
  );
};

export default EmptyGraphWrapper;
