import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, BreadcrumbsV2 } from '@verticeone/design-system';
import { Link } from 'react-router-dom';
import VendorLogo from '@vertice/core/src/components/VendorLogo/VendorLogo';
import { Skeleton } from '@mui/material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { Button } from '@verticeone/design-system';
import { useListVendorProductsQuery } from '@vertice/slices';
import { RequestBenchmarkingDataButton } from '../../modules/saas/vendor/buttons/RequestBenchmarkingDataButton';
import { RequestPurchaseButton } from '../../modules/saas/vendor/buttons/RequestPurchaseButton';
import useIntakeForm from '../../hooks/useIntakeForm';

export type VendorPageHeaderProps = {
  vendorId: string;
};

const VendorPageHeader = ({ vendorId }: VendorPageHeaderProps) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();
  const { isLoading: isIntakeFormLoading, userCanAddExistingContract } = useIntakeForm();

  const { data: vendorData, isLoading: isLoadingVendorData } = useListVendorProductsQuery({
    vendorId,
    benchmarkedOnly: true,
  });
  const numberOfProducts = vendorData?.products?.length ?? 0;
  const vendorName = vendorData?.vendor?.name;

  const showAddExistingButton = !isLoadingVendorData && !isIntakeFormLoading && userCanAddExistingContract;
  const showBenchmarkingButton = !isLoadingVendorData && numberOfProducts === 0;
  const showPurchaseButton = !isLoadingVendorData && !isIntakeFormLoading;

  return (
    <PageHeader
      breadcrumb={
        <BreadcrumbsV2
          items={[
            { label: t('SAAS.NAVBAR.SAAS') },
            { label: t('SAAS.NAVBAR.VENDORS'), component: Link, to: generatePath(routes.VENDORS.ABSOLUTE_PATH) },
            {
              label: vendorName || <Skeleton width="6em" />,
              startAdornment: <VendorLogo vendorId={vendorId} size={16} logoOnly />,
            },
          ]}
        />
      }
      color="primary"
      actions={
        <>
          {showAddExistingButton && (
            <Button
              component={Link}
              to={generatePath(routes.VENDORS.VENDOR.ADD_EXISTING.ABSOLUTE_PATH, { id: vendorId })}
              variant="outline"
            >
              {t('VENDOR.ADD_EXISTING')}
            </Button>
          )}
          {showBenchmarkingButton && <RequestBenchmarkingDataButton vendorId={vendorId} buttonSize="M" />}
          {showPurchaseButton && <RequestPurchaseButton vendorId={vendorId} vendorName={vendorName} />}
        </>
      }
    />
  );
};

export default VendorPageHeader;
