import React, { useCallback } from 'react';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Stack, useTheme } from '@mui/material';
import { DynamicFormPresenterConfig, PrimitiveDynamicFormField } from '../../dynamicForms';
import { AddFieldButton } from '../../dynamicForms/DynamicFormBuilder/components/AddFieldButton';
import { useTranslation } from 'react-i18next';
import { Path, useController, useFormContext } from 'react-hook-form';
import { getItemDefaultValues } from '../../dynamicForms/fields/RepeatingField/utils';
import { RepeatingFieldArrayItem } from './components/RepeatingFieldArrayItem';
import { Text } from '@verticeone/design-system';

export type FormRepeatingFieldType<FormDataType extends FieldValues> = (
  props: FormRepeatingFieldProps<FormDataType>
) => JSX.Element;

type FormRepeatingFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  itemDefFields: PrimitiveDynamicFormField[];
  requiredItems: number;
  limit: number;
  config: DynamicFormPresenterConfig;
};

export const FormRepeatingField = <FormDataType extends FieldValues = never>({
  limit,
  requiredItems,
  itemDefFields,
  config,
  name,
}: FormRepeatingFieldProps<FormDataType>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'DYNAMIC_FORM_PRESENTER.FIELD.REPEATING' });
  const { palette } = useTheme();
  const getFieldNamePrefix = buildItemFieldName(name);

  const { getValues, setValue } = useFormContext<FormDataType>();
  const {
    field: { value, onChange },
  } = useController<FormDataType>({
    name,
  });

  const handleAddItem = useCallback(() => {
    // updating parent through child is not supported => value is not updated
    // https://github.com/react-hook-form/react-hook-form/issues/11007
    const vals: unknown[] = getValues(name) ?? [];
    onChange([...vals, { ...(getItemDefaultValues({ fields: itemDefFields, config }) ?? {}) }]);
  }, [config, getValues, itemDefFields, name, onChange]);

  const handleRemoveItem = useCallback(
    (index: number) => {
      for (let i = index; i < value.length - 1; i++) {
        setValue(`${name}.${i}` as Path<FormDataType>, value[i + 1], { shouldValidate: true });
      }
      onChange(value.slice(0, -1));
    },
    [value, onChange, setValue, name]
  );

  return (
    <Stack border={2} borderRadius={3} borderColor={palette.core.color3} gap={4} padding={4}>
      <Text
        variant="caption"
        size="XS"
        color="text.color2"
        sx={{
          textAlign: 'right',
        }}
      >
        {`${value.length} out of max. ${limit}`}
      </Text>
      {Array.from({ length: value.length }, (_, i) => (
        <RepeatingFieldArrayItem
          key={`${name}.${i}`}
          definition={itemDefFields}
          makeName={getFieldNamePrefix(i)}
          config={config}
          onRemove={() => handleRemoveItem(i)}
          canRemove={value.length > requiredItems}
        />
      ))}
      {limit > value.length && config.mode !== 'readOnly' && (
        <AddFieldButton
          onClick={() => handleAddItem()}
          label={t('ACTIONS.ADD_ITEM')}
          disabled={config.mode === 'preview'}
        />
      )}
    </Stack>
  );
};

const buildItemFieldName = (repeatingFieldName: string) => (itemIndex: number) => (fieldName: string) => {
  return `${repeatingFieldName}.${itemIndex}.${fieldName}`;
};
