import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { SectionWrapper } from '../../components/SectionWrapper';
import CustomIntegrationCategory from './components/CustomIntegrationCategory';

const CustomSection = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM' });

  const { isEnabled } = useFeatures();
  const isOutboundIntegrationEnabled = isEnabled(FEATURES.INTEGRATION_GENERIC_OUTBOUND);

  return (
    <SectionWrapper title={t('TITLE')} description={t('DESCRIPTION')}>
      <Stack gap={8}>
        <CustomIntegrationCategory
          integrationType="Outbound"
          title={t('OUTBOUND.TITLE')}
          description={t('OUTBOUND.DESCRIPTION')}
          isVisible={isOutboundIntegrationEnabled}
        />
      </Stack>
    </SectionWrapper>
  );
};

export default CustomSection;
