import { Control, FieldValues, useController } from 'react-hook-form';
import { CommonFormFieldProps } from '../../forms/types';
import { NonSaasVendorSelect } from './NonSaasVendorSelect';

export type FormNonSaasVendorSelectFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  control?: Control<FormDataType>;
};

export type FormNonSaasVendorSelectFieldComponentType<FormDataType extends FieldValues> = (
  props: FormNonSaasVendorSelectFieldProps<FormDataType>
) => JSX.Element;

export const FormNonSaasVendorSelectField = <FormDataType extends FieldValues = never>({
  name,
  control,
  required,
  disabled,
  labelId,
  ...otherProps
}: FormNonSaasVendorSelectFieldProps<FormDataType>) => {
  const {
    field: { ref, value, onChange, onBlur, disabled: formDisabled },
    fieldState: { invalid, error },
  } = useController({ control, name, rules: { required } });

  return (
    <NonSaasVendorSelect
      ref={ref}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled || formDisabled}
      error={invalid}
      helperText={invalid && error?.message}
      {...otherProps}
    />
  );
};
