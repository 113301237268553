import { useAccountDepartments } from '../../../departments/useAccountDepartments';

export const useContractDepartmentOptions = () => {
  const { data: departments } = useAccountDepartments();

  const options = (departments ?? []).map((department) => ({
    label: department.name,
    value: department.departmentId,
  }));

  return { options };
};

export default useContractDepartmentOptions;
