import {
  isContractWithOtherBillingFrequency,
  isContractWithoutVertice,
  isRollingContract,
  isInWorkflowStages,
  isContractWithVertice,
  isContractLiveOrNotYetLive,
  isExpiredContract,
  isContractCompleted,
  isContractDraftLiveNotLiveOrExpired,
  isContractWithOtherPaymentTerms,
  isContractExisting,
  isFixedTermContract,
  isOneOffContract,
} from '@vertice/core/src/modules/saas/contract/computed';
import {
  ContractFormData,
  FieldsConfig,
  ResolvedFieldRulesPerField,
} from '@vertice/core/src/modules/saas/contract/types';
import * as z from 'zod';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { emailOrEmpty, nonEmptyString, setRequiredTo } from '@verticeone/utils/validation';
import { AdvancedLinearApproximationCostModelFormType } from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

export const getCostModelRules = (contract: Contract, formData: ContractFormData): ResolvedFieldRulesPerField => {
  const isExisting = isContractExisting(formData);
  const isWithVertice = isContractWithVertice(formData);
  const isNegotiatingApprovalContractingCompleted =
    isInWorkflowStages(formData, ['NEGOTIATING', 'APPROVAL', 'CONTRACTING', 'COMPLETED']) ||
    isContractCompleted(contract);

  const baselineCostModel = formData.costModel.baseline as AdvancedLinearApproximationCostModelFormType;
  const negotiatedCostModel = formData.costModel.negotiated as AdvancedLinearApproximationCostModelFormType;
  const isOneOff = formData.parts.contractual.lifecycle?.rollFrequency === 'ONE_OFF';

  const baselineAdvancedCostModelRules: ResolvedFieldRulesPerField = {
    'costModel.baseline.model.annualCostOverride.enabled': (() => {
      if (isExisting || isOneOff) {
        return ['HIDDEN'];
      }
      if (!isWithVertice) {
        return ['WRITABLE', z.boolean().nullish(), { showIn: 'EDIT_MODE_ONLY' }];
      }
      return ['HIDDEN'];
    })(),
    'costModel.baseline.model.annualCostOverride.amount': (() => {
      if (isExisting || isOneOff) {
        return ['HIDDEN'];
      }
      if (!isWithVertice) {
        return ['WRITABLE', baselineCostModel?.model.annualCostOverride?.enabled ? z.number() : z.number().nullish()];
      }
      if (isNegotiatingApprovalContractingCompleted) {
        return ['READABLE'];
      }
      return ['HIDDEN'];
    })(),
    'costModel.baseline.model.totalCostOverride.amount': (() => {
      if (isExisting || !isOneOff) return ['HIDDEN'];

      if (!isWithVertice) {
        return ['WRITABLE', baselineCostModel?.model.totalCostOverride?.enabled ? z.number() : z.number().nullish()];
      }
      if (isNegotiatingApprovalContractingCompleted) {
        return ['READABLE'];
      }
      return ['HIDDEN'];
    })(),
    'costModel.baseline.model.totalCostOverride.enabled': (() => {
      if (isExisting || !isOneOff) return ['HIDDEN'];

      if (!isWithVertice) {
        return ['WRITABLE', z.boolean().nullish(), { showIn: 'EDIT_MODE_ONLY' }];
      }
      return ['HIDDEN'];
    })(),
  };

  const negotiatedAdvancedCostModelRules: ResolvedFieldRulesPerField = {
    'costModel.negotiated.model.annualCostOverride.enabled': (() => {
      if (isWithVertice || isOneOff) {
        return ['HIDDEN'];
      }
      return ['WRITABLE', z.boolean().nullish(), { showIn: 'EDIT_MODE_ONLY' }];
    })(),
    'costModel.negotiated.model.annualCostOverride.amount': (() => {
      if (isOneOff) return ['HIDDEN'];

      if (isWithVertice) {
        return isNegotiatingApprovalContractingCompleted ? ['READABLE'] : ['HIDDEN'];
      }

      return ['WRITABLE', negotiatedCostModel?.model.annualCostOverride?.enabled ? z.number() : z.number().nullish()];
    })(),

    'costModel.negotiated.model.totalCostOverride.enabled': (() => {
      if (isWithVertice) {
        return ['HIDDEN'];
      }
      if (isOneOff) {
        return ['WRITABLE', z.boolean().nullish(), { showIn: 'EDIT_MODE_ONLY' }];
      }

      return ['HIDDEN'];
    })(),

    'costModel.negotiated.model.totalCostOverride.amount': (() => {
      if (!isOneOff) return ['HIDDEN'];
      if (isWithVertice) return ['READABLE'];

      const overrideEnabled = negotiatedCostModel?.model.annualCostOverride?.enabled;

      return ['WRITABLE', overrideEnabled ? z.number() : z.number().nullish()];
    })(),

    'costModel.negotiated.model.scopeReductionSavingsOverride.enabled': ['HIDDEN'],
    'costModel.negotiated.model.scopeReductionSavingsOverride.amount': ['READABLE'],
    'costModel.negotiated.model.scopeReductionSavingsOverride.description': (() => {
      if (negotiatedCostModel?.model?.scopeReductionSavingsOverride?.enabled) {
        return ['READABLE'];
      }
      return ['HIDDEN'];
    })(),
    'parts.overview.scopeReductionSavings': ['READABLE'], // Field in Savings Breakdown tab
  };

  return {
    ...baselineAdvancedCostModelRules,
    ...negotiatedAdvancedCostModelRules,
  };
};

/** Rules common for both parent and next contract */
const getCommonFieldsRules = (contract: Contract, formData: ContractFormData): ResolvedFieldRulesPerField => {
  const isNegotiatingApprovalContractingCompleted =
    isInWorkflowStages(formData, ['NEGOTIATING', 'APPROVAL', 'CONTRACTING', 'COMPLETED']) ||
    isContractCompleted(contract);
  const isWithVertice = isContractWithVertice(formData);
  const isOneOff = isOneOffContract(contract);
  const isRolling = isRollingContract(formData);
  return {
    // Costs & Savings
    ...getCostModelRules(contract, formData),

    'parts.overview.annualSavings':
      isWithVertice && isNegotiatingApprovalContractingCompleted
        ? ['READABLE', { showIn: 'READ_MODE_ONLY' }]
        : ['HIDDEN'],
    'parts.overview.totalCostValueSavings':
      isWithVertice && isNegotiatingApprovalContractingCompleted
        ? ['READABLE', { showIn: 'READ_MODE_ONLY' }]
        : ['HIDDEN'],
    'parts.overview.annualRealizedConcessions':
      isWithVertice && isNegotiatingApprovalContractingCompleted
        ? ['READABLE', { showIn: 'READ_MODE_ONLY' }]
        : ['HIDDEN'],
    'parts.overview.annualUnrealizedConcessions':
      isWithVertice && isNegotiatingApprovalContractingCompleted
        ? ['READABLE', { showIn: 'READ_MODE_ONLY' }]
        : ['HIDDEN'],
    'parts.overview.annualScopeReductionSavings':
      isWithVertice && isNegotiatingApprovalContractingCompleted
        ? ['READABLE', { showIn: 'READ_MODE_ONLY' }]
        : ['HIDDEN'],
    'parts.overview.totalSavings':
      isWithVertice && isNegotiatingApprovalContractingCompleted ? ['READABLE'] : ['HIDDEN'],
    'parts.overview.annualTotalSavings':
      isWithVertice && isNegotiatingApprovalContractingCompleted ? ['READABLE'] : ['HIDDEN'],

    'parts.contractual.lifecycle.renewalDate': (() => {
      if (isRolling || isOneOff) {
        return ['HIDDEN'];
      }
      if (!isWithVertice && !formData.parts.contractual.renewal?.markedForExpiration) {
        return ['WRITABLE', setRequiredTo(z.string(), isFixedTermContract(formData))];
      }
      return ['READABLE'];
    })(),
    'classification.departmentId': ['WRITABLE', z.string()],
    'classification.contractOwner': ['WRITABLE', z.any()],
    'classification.contractWatchers': ['WRITABLE', z.any()],
    'classification.negotiationTier': ['READABLE'],
    'classification.departmentOwner': ['READABLE'],
    'classification.departmentWatchers': ['READABLE'],
    nextRollDate: !isRolling ? ['HIDDEN'] : ['READABLE'],
    'customFields.checkbox01': ['WRITABLE', z.boolean().nullish()],
    'customFields.checkbox02': ['WRITABLE', z.boolean().nullish()],
    'customFields.checkbox03': ['WRITABLE', z.boolean().nullish()],
    'customFields.checkbox04': ['WRITABLE', z.boolean().nullish()],
    'customFields.checkbox05': ['WRITABLE', z.boolean().nullish()],
    'customFields.text01': ['WRITABLE', z.string().nullish()],
    'customFields.text02': ['WRITABLE', z.string().nullish()],
    'customFields.text03': ['WRITABLE', z.string().nullish()],
    'customFields.text04': ['WRITABLE', z.string().nullish()],
    'customFields.text05': ['WRITABLE', z.string().nullish()],
    'customFields.decimal01': ['WRITABLE', z.number().nullish()],
    'customFields.decimal02': ['WRITABLE', z.number().nullish()],
    'customFields.decimal03': ['WRITABLE', z.number().nullish()],
    'customFields.decimal04': ['WRITABLE', z.number().nullish()],
    'customFields.decimal05': ['WRITABLE', z.number().nullish()],
    'customFields.date01': ['WRITABLE', z.string().nullish()],
    'customFields.date02': ['WRITABLE', z.string().nullish()],
    'customFields.date03': ['WRITABLE', z.string().nullish()],
    'customFields.date04': ['WRITABLE', z.string().nullish()],
    'customFields.date05': ['WRITABLE', z.string().nullish()],
    'customFields.dropdown01': ['WRITABLE', z.string().nullish()],
    'customFields.dropdown02': ['WRITABLE', z.string().nullish()],
    'customFields.dropdown03': ['WRITABLE', z.string().nullish()],
    'customFields.dropdown04': ['WRITABLE', z.string().nullish()],
    'customFields.dropdown05': ['WRITABLE', z.string().nullish()],
    'customFields.textarea01': ['WRITABLE', z.string().nullish()],
    'customFields.textarea02': ['WRITABLE', z.string().nullish()],
  };
};

export const getFieldsConfig = (t: TFunction): FieldsConfig<'REQUIRE_FETCHED'> => ({
  isVisibleAsDefault: true,
  isWritableAsDefault: false,
  getRules: ({ contract, formData }) => {
    const isWithoutVertice = isContractWithoutVertice(contract);
    const isRolling = isRollingContract(formData);
    const isOneOff = isOneOffContract(contract);
    return {
      ...getCommonFieldsRules(contract, formData),
      'parts.contractual.financial.baseCurrency': isWithoutVertice
        ? ['WRITABLE', z.string(), { showIn: 'EDIT_MODE_ONLY' }]
        : ['READABLE', { showIn: 'EDIT_MODE_ONLY' }],
      'costModel.negotiated.model.products': ['WRITABLE', z.any()],
      'costModel.negotiated.model.lineItems': ['WRITABLE', z.any()],
      'parts.contractual.financial.billingFrequency': isWithoutVertice
        ? ['WRITABLE', z.string().nullish()]
        : ['READABLE'],
      'parts.contractual.financial.billingFrequencyOther': !isContractWithOtherBillingFrequency(formData)
        ? ['HIDDEN']
        : isWithoutVertice
        ? ['WRITABLE', nonEmptyString]
        : ['READABLE'],
      'parts.contractual.financial.paymentTerms': isWithoutVertice ? ['WRITABLE', z.string().nullish()] : ['READABLE'],
      'parts.contractual.financial.paymentTermsOther': !isContractWithOtherPaymentTerms(formData)
        ? ['HIDDEN']
        : isWithoutVertice
        ? ['WRITABLE', nonEmptyString]
        : ['READABLE'],
      // lifecycle & renewal
      'parts.contractual.lifecycle.rollFrequency':
        isWithoutVertice && isContractDraftLiveNotLiveOrExpired(contract) ? ['WRITABLE', z.string()] : ['READABLE'],
      'parts.contractual.lifecycle.startDate': isWithoutVertice ? ['WRITABLE', z.string()] : ['READABLE'],
      'parts.contractual.lifecycle.autoRenewalDeadline': (() => {
        if (isRolling || isOneOff) return ['HIDDEN'];
        if (isWithoutVertice) return ['WRITABLE', z.string().nullish()];
        return ['READABLE'];
      })(),
      'parts.contractual.renewal.autoRenewalWaived': (() => {
        if (isRolling || isOneOff) return ['HIDDEN'];
        return ['WRITABLE', z.boolean().nullish()];
      })(),
      'parts.contractual.renewal.markedForExpiration':
        isRolling || !formData.parts.contractual.lifecycle?.renewalDate
          ? ['HIDDEN']
          : isContractLiveOrNotYetLive(contract) || isExpiredContract(contract)
          ? ['WRITABLE', z.boolean().nullish()]
          : ['HIDDEN'],
      'parts.contractual.signatory.signingDate': isWithoutVertice ? ['WRITABLE', z.string().nullish()] : ['READABLE'],
      contractLength: isRolling ? ['HIDDEN'] : ['READABLE'],
      'parts.contractual.signatory.signer.name': ['WRITABLE', z.string().nullish()],
      'parts.contractual.signatory.signer.email': ['WRITABLE', emailOrEmpty(t)],
      'parts.contractual.signatory.signingEntity.name': ['WRITABLE', z.string().nullish()],
      'parts.contractual.vendor.vendorOption': ['READABLE', { showIn: 'EDIT_MODE_ONLY' }],
      'parts.contractual.vendor.vendorContact.name': ['WRITABLE', z.string().nullish()],
      'parts.contractual.vendor.vendorContact.email': ['WRITABLE', emailOrEmpty(t)],
      'parts.contractual.vendor.purchasedFromReseller': isWithoutVertice
        ? ['WRITABLE', z.boolean().nullish()]
        : ['READABLE'],
      'parts.contractual.vendor.resellerName': !formData?.parts?.contractual?.vendor?.purchasedFromReseller
        ? ['HIDDEN']
        : isWithoutVertice
        ? ['WRITABLE', z.string().nullish()]
        : ['READABLE'],
      'classification.region': ['WRITABLE', z.string().nullish()],
      'additionalDetails.external.notes': ['WRITABLE', z.string().nullish()],
    };
  },
});

export const useFieldsConfig = () => {
  // Please don't read contract context directly here. Stick to just reading translation context.
  const { t } = useTranslation();
  return useMemo(() => getFieldsConfig(t), [t]);
};

export const getNextContractFieldsConfig = (t: TFunction): FieldsConfig<'REQUIRE_FETCHED'> => ({
  isVisibleAsDefault: true,
  isWritableAsDefault: false,
  getRules: ({ contract, formData }) => ({
    ...getCommonFieldsRules(contract, formData),
    'parts.contractual.financial.billingFrequencyOther': isContractWithOtherBillingFrequency(contract)
      ? ['READABLE']
      : ['HIDDEN'],
    'parts.contractual.financial.paymentTermsOther': isContractWithOtherPaymentTerms(contract)
      ? ['READABLE']
      : ['HIDDEN'],
    contractLength: isRollingContract(contract) ? ['HIDDEN'] : ['READABLE'],
    'parts.contractual.vendor.resellerName': !contract?.parts?.contractual?.vendor?.purchasedFromReseller
      ? ['HIDDEN']
      : ['READABLE'],
  }),
});

export const useNextContractFieldsConfig = () => {
  // Please don't read contract context directly here. Stick to just reading translation context.
  const { t } = useTranslation();
  return useMemo(() => getNextContractFieldsConfig(t), [t]);
};
