import { fetchAllPages } from '@vertice/core/src/hooks/useFetchPaginated';
import {
  ListServiceWorkflowsApiResponse,
  ListServiceWorkflowsApiArg,
  ListWorkflowVersionsApiResponse,
  ListWorkflowVersionsApiArg,
  ListTasksApiArg,
  ListTasksApiResponse,
  Task,
  ListRequestsApiArg,
  ListRequestsApiResponse,
  bffeWorkflowsAPICodegen,
} from '../openapi/codegen/bffeWorkflowsAPI';

import { WORKFLOWS_BFFE_TAG_PREFIX } from './bffeWorkflowsAPI';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
export const WORKFLOW_BFFE_VERSIONS = `${WORKFLOWS_BFFE_TAG_PREFIX}WorkflowVersions`;
export const WORKFLOW_BFFE_INSTANCES = `${WORKFLOWS_BFFE_TAG_PREFIX}WorkflowInstances`;
export const WORKFLOW_BFFE_TASKS = `${WORKFLOWS_BFFE_TAG_PREFIX}WorkflowTasks`;
export const WORKFLOW_BFFE_REQUESTS = `${WORKFLOWS_BFFE_TAG_PREFIX}WorkflowRequests`;
export const WORKFLOW_BFFE_JOURNAL = `${WORKFLOWS_BFFE_TAG_PREFIX}Journal`;

export type WorkflowsBffeTagType =
  | typeof WORKFLOW_BFFE_VERSIONS
  | typeof WORKFLOW_BFFE_INSTANCES
  | typeof WORKFLOW_BFFE_TASKS
  | typeof WORKFLOW_BFFE_REQUESTS
  | typeof WORKFLOW_BFFE_JOURNAL;

export const enhancedBffeWorkflowsAPI = bffeWorkflowsAPICodegen
  .enhanceEndpoints<WorkflowsBffeTagType>({
    addTagTypes: [WORKFLOW_BFFE_VERSIONS],
    endpoints: {
      listServiceWorkflows: {
        providesTags: [WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES],
      },
      listWorkflowVersions: {
        providesTags: [WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES],
      },
      getWorkflowVersion: {
        providesTags: [WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES],
      },
      listTasks: {
        providesTags: [WORKFLOW_BFFE_TASKS],
      },
      listRequestTasks: {
        providesTags: [WORKFLOW_BFFE_TASKS],
      },
      getTaskOverview: {
        providesTags: (result) => [{ type: WORKFLOW_BFFE_TASKS, id: result?.task.taskId }],
      },
      getRequestJournal: {
        providesTags: [WORKFLOW_BFFE_JOURNAL],
      },
      countTasks: {
        providesTags: [WORKFLOW_BFFE_TASKS],
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      listServiceWorkflowPaginated: build.query<
        { items: ListServiceWorkflowsApiResponse['workflows'] },
        ListServiceWorkflowsApiArg
      >({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListServiceWorkflowsApiResponse,
            ListServiceWorkflowsApiArg,
            ListServiceWorkflowsApiResponse['workflows'][0]
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/workflows`,
                params: { serviceRef: args.serviceRef, nextToken: args.nextToken, maxResults: args.maxResults },
              });
              return { data: queryResponse.data as ListServiceWorkflowsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.workflows,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES],
      }),
      listWorkflowVersionsPaginated: build.query<
        { items: ListWorkflowVersionsApiResponse['workflows'] },
        ListWorkflowVersionsApiArg
      >({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListWorkflowVersionsApiResponse,
            ListWorkflowVersionsApiArg,
            ListWorkflowVersionsApiResponse['workflows'][0]
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/workflows/${args.workflowId}/versions`,
                params: { nextToken: args.nextToken, maxResults: args.maxResults },
              });
              return { data: queryResponse.data as ListWorkflowVersionsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.workflows,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES],
      }),
      listTasksPaginated: build.query<ListTasksApiResponse, ListTasksApiArg>({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const items = await fetchAllPages<ListTasksApiResponse, ListTasksApiArg, Task>({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/tasks`,
                params: {
                  nextToken: args.nextToken,
                  maxResults: args.maxResults,
                  taskStatus: args.taskStatus,
                },
              });
              return { data: queryResponse.data as ListTasksApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response?.items || [],
            fetchArgs: arg,
            preferCache: true,
          });
          return { data: { items } };
        },
        providesTags: [WORKFLOW_BFFE_TASKS],
      }),
      listWorkflowsRequestsPaginated: build.query<{ items: ListRequestsApiResponse['items'] }, ListRequestsApiArg>({
        queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
          const items = await fetchAllPages<
            ListRequestsApiResponse,
            ListRequestsApiArg,
            ListRequestsApiResponse['items'][0]
          >({
            fetchFn: async (args) => {
              const queryResponse = await baseQuery({
                url: `/accounts/${args.accountId}/requests`,
                params: { nextToken: args.nextToken, maxResults: args.maxResults, contractId: args.contractId },
              });
              return { data: queryResponse.data as ListRequestsApiResponse };
            },
            getNextTokenFn: (response) => response.nextToken,
            getItemsFn: (response) => response.items,
            fetchArgs: arg,
            preferCache: true,
          });

          return { data: { items } };
        },
        providesTags: [WORKFLOW_BFFE_REQUESTS],
      }),
      listMultipleWorkflowVersions: build.query<
        ListWorkflowVersionsApiResponse[],
        { accountId: string; workflowIds: string[] }
      >({
        queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
          const { accountId, workflowIds } = arg;

          try {
            const results = await Promise.all(
              workflowIds.map(async (workflowId) => {
                const response = await baseQuery({
                  url: `/accounts/${accountId}/workflows/${workflowId}/versions`,
                  method: 'GET',
                });

                if (response.error) {
                  throw response.error;
                }

                return response.data as ListWorkflowVersionsApiResponse;
              })
            );
            return { data: results };
          } catch (error) {
            return { error: error as FetchBaseQueryError };
          }
        },
        providesTags: [WORKFLOW_BFFE_VERSIONS],
      }),
    }),
  });

export const {
  useListServiceWorkflowPaginatedQuery,
  useListWorkflowVersionsPaginatedQuery,
  useListTasksPaginatedQuery,
  useListWorkflowsRequestsPaginatedQuery,
  useListMultipleWorkflowVersionsQuery,
} = enhancedBffeWorkflowsAPI;
