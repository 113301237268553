import { useContext, createContext } from 'react';
import type { Connection } from '@xyflow/react';

import type { RendererNode } from '../../WorkflowRenderer/types';

export type DragAndDropExtensionContextType = {
  onConnect?: (connection: Connection) => void;
  onNodeDragStop?: (node: RendererNode) => void;
  onNodeDelete?: (nodeId: string) => void;
  onAutoLayout?: () => void;
  isAutoLayoutEnabled?: boolean;
};

const DragAndDropExtensionContext = createContext<DragAndDropExtensionContextType>({
  onConnect: undefined,
  onNodeDragStop: undefined,
  onNodeDelete: undefined,
  onAutoLayout: undefined,
  isAutoLayoutEnabled: false,
});

const DragAndDropExtensionProvider = DragAndDropExtensionContext.Provider;

const useDragAndDropExtensionContext = () => useContext(DragAndDropExtensionContext);

export { DragAndDropExtensionProvider, useDragAndDropExtensionContext };
