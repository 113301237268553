import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconWrapper, IconWrapperProps } from '@verticeone/design-system';
import { Stack } from '@mui/material';

const VariableMapperArrowRight = (props: Omit<IconWrapperProps, 'icon' | 'size'>) => (
  <Stack padding="7px" color="core.color5">
    <IconWrapper icon={ArrowForwardIcon} size="S" {...props} />
  </Stack>
);

export default VariableMapperArrowRight;
