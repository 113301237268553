import { z } from 'zod';
import { checkboxFieldSchema, CheckboxFieldType } from './fields/CheckboxField';
import { shortTextFieldSchema, ShortTextFieldType } from './fields/ShortTextField';
import { lineItemsFieldSchema, LineItemsFieldType } from './fields/LineItemsField';
import { longTextFieldSchema, LongTextFieldType } from './fields/LongTextField';
import { dateFieldSchema, DateFieldType } from './fields/DateField';
import { dropdownFieldSchema, DropdownFieldType } from './fields/DropdownField';
import { radioGroupFieldSchema, RadioGroupFieldType } from './fields/RadioGroupField';
import { moneyFieldSchema, MoneyFieldType } from './fields/MoneyField';
import { numberFieldSchema, NumberFieldType } from './fields/NumberField';
import { fileUploadFieldSchema, FileUploadFieldType } from './fields/FileUploadField';
import { rollingFrequencyFieldSchema, RollingFrequencyFieldType } from './fields/RollingFrequencyField';
import { departmentFieldSchema, DepartmentFieldType } from './fields/DepartmentField';
import { dataSourcedFieldSchema, DataSourcedFieldType } from './fields/DataSourcedField/types';

export type PrimitiveDynamicFormField =
  | CheckboxFieldType
  | DateFieldType
  | DropdownFieldType
  | LineItemsFieldType
  | LongTextFieldType
  | MoneyFieldType
  | NumberFieldType
  | RadioGroupFieldType
  | ShortTextFieldType
  | FileUploadFieldType
  | RollingFrequencyFieldType
  | DepartmentFieldType
  | DataSourcedFieldType;

export const primitiveDynamicFormFieldSchema = z.union([
  checkboxFieldSchema,
  shortTextFieldSchema,
  lineItemsFieldSchema,
  longTextFieldSchema,
  dateFieldSchema,
  dropdownFieldSchema,
  radioGroupFieldSchema,
  moneyFieldSchema,
  numberFieldSchema,
  fileUploadFieldSchema,
  rollingFrequencyFieldSchema,
  departmentFieldSchema,
  dataSourcedFieldSchema,
]);
