import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Loader } from '@verticeone/design-system';
import { addNotification } from '@vertice/slices';
import { useInvitationToken } from './hooks';

const AuthRoutesWrapper = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { route } = useAuthenticator((context) => [context.route]);
  const { hasToken, isLoading, tokenError } = useInvitationToken();

  useEffect(() => {
    if (tokenError?.message) {
      dispatch(addNotification(tokenError.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenError]);

  if ((hasToken && isLoading) || route === 'idle' || !route) {
    return <Loader />;
  }

  if (tokenError?.redirect) {
    return <Navigate to={tokenError.redirect} replace />;
  }

  if (route === 'authenticated') {
    return <Navigate to={location.state?.from || '/'} replace />;
  }

  return children;
};

export default AuthRoutesWrapper;
