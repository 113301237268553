import { Stack } from '@mui/material';
import { FilterButton } from '../../filtering/FilterButton';
import SearchInput from '@vertice/core/src/components/SearchInput';
import { ApplicationsGrid } from './ApplicationsGrid';
import { UsageContext } from './UsageContext';
import { useContext, useState } from 'react';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import { t } from 'i18next';
import { HealthFilter } from '../UsageDetail/utils';

type ApplicationsProps = {
  applications?: ApplicationWithMeasureReportsListItem[];
  loading?: boolean;
  healthyApplicationsCount?: number;
  unhealthyApplicationsCount?: number;
  /** Router path to the Integrations page. Used as call-to-action if no applications are integrated. */
  integrationsHref?: string;
};

export const Applications = ({
  applications,
  loading,
  healthyApplicationsCount = 0,
  unhealthyApplicationsCount = 0,
  integrationsHref,
}: ApplicationsProps) => {
  const [search, setSearch] = useState('');
  const { lastActiveHealthFilter, setLastActiveHealthFilter } = useContext(UsageContext);
  return (
    <Stack gap={4}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <FilterButton
            label={t('APPLICATIONS.USAGE.DEFAULT')}
            active={lastActiveHealthFilter === HealthFilter.HEALTHY}
            onClick={() => setLastActiveHealthFilter(HealthFilter.HEALTHY)}
            count={healthyApplicationsCount}
            disabled={loading}
          />
          <FilterButton
            label={t('APPLICATIONS.USAGE.ALL')}
            active={lastActiveHealthFilter === HealthFilter.ALL}
            onClick={() => setLastActiveHealthFilter(HealthFilter.ALL)}
            count={healthyApplicationsCount + unhealthyApplicationsCount}
            disabled={loading}
          />
          <FilterButton
            label={t('APPLICATIONS.USAGE.NEEDING_ATTENTION')}
            active={lastActiveHealthFilter === HealthFilter.UNHEALTHY}
            onClick={() => setLastActiveHealthFilter(HealthFilter.UNHEALTHY)}
            count={unhealthyApplicationsCount}
            disabled={loading}
          />
        </Stack>
        <SearchInput
          disabled={!applications?.length}
          placeholder={t('APPLICATIONS.USAGE.SEARCH_APPLICATIONS')}
          value={search}
          onSearch={setSearch}
          debounce={100}
        />
      </Stack>
      <ApplicationsGrid
        applications={applications}
        unhealthyApplicationsCount={unhealthyApplicationsCount}
        loading={loading}
        search={search}
        dataHealthFilter={lastActiveHealthFilter}
        integrationsHref={integrationsHref}
      />
    </Stack>
  );
};
