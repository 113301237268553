import { Stack } from '@mui/material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Text,
  TextFieldArea,
} from '@verticeone/design-system';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowVersionRow } from '../../../workflow/WorkflowDetailPage/types';
import { useActivateWorkflowVersion } from '../../../workflow/WorkflowDetailPage/components/hooks/useActivateWorkflowVersion';
import { useGetWorkflowVersionName } from '../../../hooks/useGetWorkflowVersionName';

type SetAsLiveDialogProps = {
  workflowName: string;
  liveWorkflowName?: string;
  versionName?: string;
  versionTag?: string;
  liveVersionTag?: string;
  liveVersionName?: string;
  versionNotes?: string;
  hasWarnings: boolean;
  open: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onSubmit: (notes?: string) => void;
};

const SetAsLiveDialog = ({
  workflowName,
  liveWorkflowName,
  versionName,
  liveVersionName,
  versionTag,
  liveVersionTag,
  versionNotes,
  hasWarnings,
  open,
  isSubmitting,
  onClose,
  onSubmit,
}: SetAsLiveDialogProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(versionNotes);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.SET_AS_LIVE_DIALOG',
  });

  const localizedVersionName = useGetWorkflowVersionName({
    versionName: versionName,
    versionTag: versionTag,
  });

  const localizedLiveVersionName = useGetWorkflowVersionName({
    versionName: liveVersionName,
    versionTag: liveVersionTag,
  });

  const selectedVersion = (workflowName + ' ' + localizedVersionName).trim();
  const liveVersion = (liveWorkflowName + ' ' + localizedLiveVersionName).trim();

  return (
    <Dialog open={open} onClose={onClose} size="M">
      <DialogHeader>{t('TITLE')}</DialogHeader>
      <DialogContent>
        <Stack gap={6}>
          <Stack gap={2}>
            {liveVersion ? (
              <Text variant="body-regular" size="M" color="text1">
                <Trans
                  i18nKey="ENTITIES.WORKFLOW_VERSION.SET_AS_LIVE_DIALOG.REPLACE"
                  components={{ b: <Text variant="body-bold" /> }}
                  values={{ selectedVersion, liveVersion }}
                />
              </Text>
            ) : (
              <Text variant="body-bold" size="M" color="text1">
                {selectedVersion}
              </Text>
            )}
            <Text variant="body-regular" size="M" color="text3">
              {t('MESSAGE')}
            </Text>
          </Stack>
          {hasWarnings && (
            <Alert variant="ghost" color="warning" title={t('WARNINGS')} subtitle={t('WARNINGS_MESSAGE')} />
          )}
          <Stack gap={2}>
            <Text variant="caption" size="XS" color="text2">
              {t('VERSION_NOTES')}
            </Text>
            <TextFieldArea
              size="S"
              variant="outline"
              color="primary"
              placeholder={t('VERSION_NOTES_PLACEHOLDER')}
              minHeight={20}
              style={{ resize: 'none' }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button onClick={onClose} variant="plain" color="primary" disabled={isSubmitting}>
            {t('CANCEL')}
          </Button>
          <Button
            onClick={() => onSubmit(inputValue)}
            variant="solid"
            color="primary"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('SET_AS_LIVE')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export const useActivateWorkflowVersionV2 = ({
  workflowVersion,
  liveWorkflowVersion,
  hasWarnings,
  onComplete,
  onStart,
}: {
  workflowVersion: WorkflowVersionRow;
  liveWorkflowVersion?: WorkflowVersion;
  hasWarnings: boolean;
  onComplete?: () => void;
  onStart?: () => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { activateWorkflowVersion } = useActivateWorkflowVersion({
    workflowId: workflowVersion.id,
    workflowVersion: workflowVersion.versionId,
    versionName: workflowVersion.versionName,
    versionTag: workflowVersion.versionTag,
    status: workflowVersion.status,
  });

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const activateWorkflow = () => {
    openDialog();
  };

  return {
    activateWorkflow,
    ActivateDialog: (
      <SetAsLiveDialog
        workflowName={workflowVersion.workflowName}
        liveWorkflowName={liveWorkflowVersion?.workflowName}
        hasWarnings={hasWarnings}
        open={isDialogOpen}
        onClose={closeDialog}
        onSubmit={(notes?: string) => {
          setIsSubmitting(true);
          onStart?.();
          void activateWorkflowVersion({
            versionNotes: notes,
            onComplete: () => {
              setIsSubmitting(false);
              closeDialog();
              onComplete?.();
            },
          });
        }}
        versionName={workflowVersion.versionName}
        liveVersionName={liveWorkflowVersion?.versionName}
        versionTag={workflowVersion.versionTag}
        liveVersionTag={liveWorkflowVersion?.versionTag}
        versionNotes={workflowVersion.versionNotes}
        isSubmitting={isSubmitting}
      />
    ),
  };
};
