import { TimeThreshold } from '../types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TimeThresholdSelect from './TimeThresholdSelect';
import { LabelCellProps } from '../NotificationsPreferences';
import { Box, Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
const ContractRenewalLabelCell = ({
  labelTKey,
  setting,
  saveSetting,
  isSaving,
}: LabelCellProps<'contractRenewal' | 'nonSaasContractRenewal'>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PREFERENCES.EMAIL_NOTIFICATIONS.TABLE' });
  return (
    setting && (
      <Stack>
        <Stack direction="row" alignItems="center" height={48}>
          <Box mr={2}>{t(`LABELS.${labelTKey}`)}</Box>
          <TimeThresholdSelect
            sx={{ display: 'inline-flex', width: '8em' }}
            value={setting.timeThreshold as TimeThreshold}
            onChange={(value) => saveSetting({ ...setting, timeThreshold: value })}
            disabled={isSaving}
          />
        </Stack>
        <Text variant="body-regular" size="XS" color="text2">
          {t('LABELS.RENEWALS_DESCRIPTION_NOTE')}
        </Text>
      </Stack>
    )
  );
};

export default ContractRenewalLabelCell;
