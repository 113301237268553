import {
  Definitions,
  ProcessDefinition,
  TaskDefinition,
  GatewayDefinition,
  FlowEdgeDefinition,
  StartDefinition,
  EndDefinition,
} from '../definitionsTypes';
import { isProcessDefinition, isLayoutDefinition } from '../pocWorkflowSchema';
import { WorkflowDefinitions } from '../workflowSchema/types';

type NodeDefinition = TaskDefinition | GatewayDefinition | StartDefinition | EndDefinition;

export const isTaskDefinition = (node?: NodeDefinition): node is TaskDefinition => node?.kind === 'ProcessEngine:Task';
export const isGatewayDefinition = (node?: NodeDefinition): node is GatewayDefinition =>
  node?.kind === 'ProcessEngine:Gateway';
export const isStartDefinition = (node?: NodeDefinition): node is StartDefinition =>
  node?.kind === 'ProcessEngine:Start';
export const isEndDefinition = (node?: NodeDefinition): node is EndDefinition => node?.kind === 'ProcessEngine:End';

export const updateNodeInProcessDefinition = (
  workflowDefinitions: WorkflowDefinitions,
  node: NodeDefinition
): WorkflowDefinitions => {
  const { processDefinition } = workflowDefinitions;

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      ...(isTaskDefinition(node)
        ? {
            tasks: (processDefinition.process.tasks ?? []).map((t) => (t.task.id === node.task.id ? node : t)),
          }
        : {}),
      ...(isGatewayDefinition(node)
        ? {
            gateways: (processDefinition.process.gateways ?? []).map((g) =>
              g.gateway.id === node.gateway.id ? node : g
            ),
          }
        : {}),
      ...(isEndDefinition(node)
        ? {
            end: (processDefinition.process.end ?? []).map((e) => (e.end.id === node.end.id ? node : e)),
          }
        : {}),
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
  };
};

export const updateEdgesInProcessDefinition = (
  workflowDefinitions: WorkflowDefinitions,
  edges: FlowEdgeDefinition[]
): WorkflowDefinitions => {
  const { processDefinition } = workflowDefinitions;

  const originalEdges = processDefinition.process.flow?.flow.edges ?? [];
  const updatedEdges = originalEdges.map((originalEdge) => {
    const updatedEdge = edges.find((e) => e.edge.id === originalEdge.edge.id);

    return updatedEdge ?? originalEdge;
  });

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      ...(processDefinition.process.flow
        ? { flow: { ...processDefinition.process.flow, flow: { edges: updatedEdges } } }
        : {}),
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
  };
};

export const getWorkflowDefinitionsFromDefinitions = (definitions?: Definitions): WorkflowDefinitions | undefined => {
  const processDefinition = definitions?.definitions.find(isProcessDefinition);
  const layoutDefinition = definitions?.definitions.find(isLayoutDefinition);

  return processDefinition && layoutDefinition ? { processDefinition, layoutDefinition } : undefined;
};

export const getDefinitionsFromWorkflowDefinitions = (workflowDefinitions: WorkflowDefinitions): Definitions => {
  return {
    version: '2023-10-11',
    kind: 'Process:Definitions',
    definitions: [workflowDefinitions.layoutDefinition, workflowDefinitions.processDefinition],
  };
};
