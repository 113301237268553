import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { useDashboardContext } from '../DashboardContext';
import { useTranslation } from 'react-i18next';

const DemoOverlay = () => {
  const { isNewAccount, isDemoVisible } = useDashboardContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.DEMO' });

  return (
    isNewAccount &&
    isDemoVisible && (
      <Stack position={'absolute'} height="100%" width="100%" zIndex={1000}>
        <Text
          variant="display"
          size="L"
          color="warning"
          sx={{
            opacity: 0.2,
            position: 'sticky',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            userSelect: 'none',
            placeSelf: 'center',
            fontSize: '15rem',
          }}
        >
          {t('OVERLAY')}
        </Text>
      </Stack>
    )
  );
};

export default DemoOverlay;
