import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { ApplicationWithMeasureReportsListItem } from '@vertice/slices/src/graphql/bffeUsage/derivedTypes';
import {
  ApplicationStatus,
  useListApplicationsWithMeasureReportsQuery,
} from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { ProviderStatus, useGetProvidersQuery } from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import React, { useMemo } from 'react';
import { hasInfo, hasWarnings } from './helpers';

const initialRowSortingFunction = (
  a: ApplicationWithMeasureReportsListItem,
  b: ApplicationWithMeasureReportsListItem
) => {
  const aVendorName = a.matches?.find((match) => match.entityType === 'Vendor')?.entityName || '';
  const bVendorName = b.matches?.find((match) => match.entityType === 'Vendor')?.entityName || '';

  if (aVendorName === bVendorName) {
    return a.name?.localeCompare(b.name || '') || 0;
  }

  return aVendorName.localeCompare(bVendorName);
};

type useGetApplicationsWithVendorsProps = {
  accountId: string;
  status?: ApplicationStatus;
  showGoogleSocial?: boolean;
};

type useGetApplicationsWithVendorsReturn = {
  applications: ApplicationWithMeasureReportsListItem[] | undefined;
  healthyApplicationsCount: number;
  unhealthyApplicationsCount: number;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

type useGetApplicationsWithVendorsOptions = {
  skip?: boolean;
};

export const useGetApplicationsWithVendors = (
  { accountId, status = ApplicationStatus.Discovered, showGoogleSocial = true }: useGetApplicationsWithVendorsProps,
  { skip = false }: useGetApplicationsWithVendorsOptions
): useGetApplicationsWithVendorsReturn => {
  const { someProviderEnabled, isLoading: providersLoading } = useHasEnabledSSOProvider();
  const [currentGoogleSocialStatus, setCurrentGoogleSocialStatus] = React.useState<boolean>(showGoogleSocial);

  const {
    data: applicationsWithMeasures,
    isError,
    isLoading: applicationsLoading,
  } = useListApplicationsWithMeasureReportsQuery(
    {
      accountId: accountId,
      nextToken: null,
      limit: 500,
      statuses: [status],
    },
    { skip }
  );

  //   we only work with applications that have a vendor
  const applicationsWithCounts = useMemo(() => {
    const applications: ApplicationWithMeasureReportsListItem[] | undefined =
      isError || !someProviderEnabled
        ? undefined
        : applicationsWithMeasures?.listApplicationsWithMeasure?.items
            ?.filter(
              (application) =>
                (currentGoogleSocialStatus || // if 'false', Google Social is only apps are filtered out
                  application.sources?.length === 0 || // header rows
                  application.sources?.some((source) => source.id !== 'Provider:Google:Workspace:SocialLogin')) &&
                application.matches?.find((match) => match.entityType === 'Vendor')
            )
            .map(
              (application): ApplicationWithMeasureReportsListItem => ({
                ...application,
                hasWarnings: hasWarnings(application.dataHealth),
                hasInfo: hasInfo(application.dataHealth),
              })
            )
            .sort(initialRowSortingFunction);

    return {
      applications,
      healthyApplicationsCount: applications?.filter((application) => !application.hasWarnings).length,
      unhealthyApplicationsCount: applications?.filter((application) => application.hasWarnings).length,
    };
  }, [isError, applicationsWithMeasures, currentGoogleSocialStatus, someProviderEnabled]);

  if (showGoogleSocial !== currentGoogleSocialStatus) {
    setCurrentGoogleSocialStatus(showGoogleSocial);
  }

  return {
    applications: applicationsWithCounts.applications,
    healthyApplicationsCount: applicationsWithCounts.healthyApplicationsCount || 0,
    unhealthyApplicationsCount: applicationsWithCounts.unhealthyApplicationsCount || 0,
    isLoading: applicationsLoading || providersLoading || someProviderEnabled === undefined,
    isSuccess: applicationsWithCounts !== undefined,
    isError,
  };
};

export const useHasEnabledSSOProvider = () => {
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetProvidersQuery({ accountId: accountId! }, { skip: !accountId });

  return {
    isLoading,
    someProviderEnabled: data?.providers?.some((provider) => provider.status === ProviderStatus.Active) || false,
  };
};
