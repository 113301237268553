import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FitViewOptions, useReactFlow, useViewport } from '@xyflow/react';
import { AddOutlined, FitScreenOutlined, RemoveOutlined, SvgIconComponent } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { IconButton, Text, Tooltip, FlowchartIcon } from '@verticeone/design-system';

import { useDragAndDropExtensionContext } from '../../../WorkflowEditor/DragAndDropExtension/DragAndDropExtensionContext';
import { MAX_RENDERER_ZOOM, MIN_RENDERER_ZOOM } from '../../constants';

const ZoomControlsContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.core.bg,
  borderRadius: theme.spacing(3),
  'button:first-of-type': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  'button:last-of-type': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

const ZoomControls = () => {
  const { zoomIn, zoomOut } = useReactFlow();
  const { zoom } = useViewport();

  return (
    <ZoomControlsContainer direction="row" alignItems="center">
      <IconButton
        disabled={zoom <= MIN_RENDERER_ZOOM}
        variant="outline"
        size="XL"
        icon={RemoveOutlined}
        onClick={() => zoomOut()}
      />
      <ZoomWrapper direction="row" alignItems="center" justifyContent="center">
        <Text align="center" color="text2" variant="button-bold" size="S">
          {formatZoom(zoom)}
        </Text>
      </ZoomWrapper>
      <IconButton
        disabled={zoom >= MAX_RENDERER_ZOOM}
        variant="outline"
        size="XL"
        icon={AddOutlined}
        onClick={() => zoomIn()}
      />
    </ZoomControlsContainer>
  );
};

const ZoomWrapper = styled(Stack)(({ theme }) => ({
  width: 64,
  height: '100%',
  borderTop: `1px solid ${theme.palette.core.color2}`,
  borderBottom: `1px solid ${theme.palette.core.color2}`,
  padding: theme.spacing(3),
}));

const ButtonWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.core.bg,
  borderRadius: theme.spacing(3),
}));

const formatZoom = (zoom: number) => `${Math.round(zoom * 100)}%`;

type RendererControlsProps = {
  fitViewOptions?: FitViewOptions;
};

export const RendererControls: FC<RendererControlsProps> = ({ fitViewOptions }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.ACTIONS' });
  const { fitView } = useReactFlow();
  const { onAutoLayout, isAutoLayoutEnabled } = useDragAndDropExtensionContext();

  const isAutoLayoutVisible = !!onAutoLayout;

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {isAutoLayoutVisible ? (
        <Tooltip minified noArrow title={t(isAutoLayoutEnabled ? 'AUTO_LAYOUT' : 'AUTO_LAYOUT_FIX')}>
          <ButtonWrapper>
            <IconButton
              disabled={!isAutoLayoutEnabled}
              variant="outline"
              size="XL"
              icon={FlowchartIcon as SvgIconComponent}
              onClick={onAutoLayout}
            />
          </ButtonWrapper>
        </Tooltip>
      ) : null}
      <ButtonWrapper>
        <IconButton variant="outline" size="XL" icon={FitScreenOutlined} onClick={() => fitView(fitViewOptions)} />
      </ButtonWrapper>
      <ZoomControls />
    </Stack>
  );
};
