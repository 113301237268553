import { PanelPosition, FitViewOptions } from '@xyflow/react';
import { createRequiredContext } from '@verticeone/utils/contexts';
import { UsersByIdReturn } from '../../../../hooks/useRelevantUsersById';
import { WorkflowModel } from '../model/types';
import { WorkflowDefinitions } from '../types';

export type WorkflowRendererContextType = {
  isEditor: boolean;
  isDragAndDropEnabled?: boolean;
  usersById: UsersByIdReturn;
  loggedUserId: string;
  isLoggedUserAdmin?: boolean;
  accountId: string;
  onOpenTask?: (taskId: string) => void;
  allowVerticeServiceNavigation?: boolean;
  workflowDefinitions: WorkflowDefinitions;
  drawerTaskId?: string;
  model: WorkflowModel;
  controlsPosition?: PanelPosition;
  fitViewOptions?: FitViewOptions;
};

const { WorkflowRendererContextProvider, useWorkflowRendererContext } = createRequiredContext<
  WorkflowRendererContextType,
  'WorkflowRenderer'
>('WorkflowRenderer');

export { useWorkflowRendererContext, WorkflowRendererContextProvider };
