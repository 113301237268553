import {
  AdvancedLinearApproximationCostModel,
  AdvancedLinearCostModelLine,
  ContractModel2,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CostModelDefinition } from '@vertice/slices/src/openapi/codegen/contractCostAPI';
import { ConcessionItem } from '../../components/ConcessionsList/types';
import { DefaultOverviewValues } from '../types';
import { v1 as uuid } from 'uuid';

import { CostModelProductItem } from '../../components/CostModelProductList';
import { CostModelLineItem } from '../../components/CostModelLineItemsTable';
import { isNil } from 'lodash';
import {
  ANNUAL_COST_IDENTIFIER,
  buildProductIdentifier,
  getProductIdFromProductItem,
  getVendorIdFromProductItem,
  isAnnualCostOverride,
  isScopeReductionOverride,
  isValidConcessionItem,
  isValidProductItem,
  convertConcessionStatusToItemStatus,
  convertItemStatusToConcessionStatus,
  convertRollingFrequencyToProductFrequency,
  convertProductFrequencyToItemFrequency,
  ANNUAL_SCOPE_REDUCTION_IDENTIFIER,
  isTotalCostOverride,
  TOTAL_COST_IDENTIFIER,
  isValidLineItemItem,
} from './utils';
import { CostModelMaterialized } from '../../types';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { isNotNil } from '@verticeone/utils/validation';
import { LineItemType } from '../../components/LineItemsTable';

export const COST_MODEL_CLASS = 'AdvancedLinearApproximation';
export const COST_MODEL_VERSION = '2024-10-14';

export type AdvancedLinearApproximationCostModelFormType = {
  id: string;
  class: string;
  version: string;
  model: {
    items?: AdvancedLinearCostModelLine[];
    annualCostOverride?: {
      id?: string;
      amount: number;
      enabled: boolean;
    };
    totalCostOverride?: {
      id?: string;
      amount: number;
      enabled: boolean;
    };
    concessions?: ConcessionItem[];
    scopeReductionSavingsOverride?: {
      id?: string;
      amount?: number;
      description?: string;
      enabled: boolean;
    };
    products?: CostModelProductItem[];
    lineItems?: CostModelLineItem[];
  };
};

type FormModel = AdvancedLinearApproximationCostModelFormType['model'];

/**
 * Generates empty model base.
 */
export const getCostModelBase = () => ({
  id: uuid(),
  class: COST_MODEL_CLASS,
  version: COST_MODEL_VERSION,
  model: {},
});

export const isAdvancedLinearApproximationModel = (model?: CostModelMaterialized | ContractModel2) => {
  return model?.class === COST_MODEL_CLASS;
};

export const getConcessionsFromItems = (items?: AdvancedLinearCostModelLine[]) => {
  return (
    items?.filter(isValidConcessionItem).map((concessionItem) => ({
      id: concessionItem.id ?? uuid(),
      type: (concessionItem.concessionType as ConcessionItem['type']) ?? null,
      status: convertItemStatusToConcessionStatus(concessionItem.status),
      amount: !isNil(concessionItem.unitPrice) ? concessionItem.unitPrice : null,
      description: concessionItem.description ?? null,
    })) ?? []
  );
};

const getConcessionItemsFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  return (
    model.concessions?.map((concession: ConcessionItem) => ({
      id: concession.id,
      identifier: `vertice/concession/${concession.type}`,
      lineItemType: 'CREDIT',
      lineItemCategory: 'CONCESSION',
      concessionType: concession.type,
      status: convertConcessionStatusToItemStatus(concession.status),
      unitPrice: !isNil(concession.amount) ? concession.amount : undefined,
      description: concession.description,
    })) ?? []
  );
};

export const getProductsFromItems = (items?: AdvancedLinearCostModelLine[]) => {
  return (
    items
      ?.filter(isValidProductItem)
      .map((productItem) => ({
        id: productItem.id ?? uuid(),
        name: '',
        vendorId: getVendorIdFromProductItem(productItem.identifier),
        productId: getProductIdFromProductItem(productItem.identifier)!,
        licenseType: productItem.licenseType
          ? {
              id: productItem.licenseType?.id!,
              label: productItem.licenseType?.label!,
            }
          : undefined,
        licenseCost: productItem.unitPrice,
        numberOfLicences: productItem.licenseCount,
        totalCost: productItem.totalPrice,
        allocationSpan: {
          startDate: productItem.startDate,
          endDate: productItem.endDate,
          rollingFrequency: convertRollingFrequencyToProductFrequency(productItem.rollingFrequency),
        },
      }))
      .filter((prod) => isNotNil(prod.productId)) ?? []
  );
};

export const getLineItemsFromItems = (items?: AdvancedLinearCostModelLine[]) => {
  return (
    items?.filter(isValidLineItemItem).map((lineItem) => ({
      id: lineItem.id ?? uuid(),
      lineItemType: lineItem.lineItemCategory as LineItemType,
      description: lineItem.description ?? null,
      quantity: lineItem.licenseCount ?? null,
      unitCost: lineItem.unitPrice,
      totalCost: lineItem.totalPrice,
      allocationSpan: {
        startDate: lineItem.startDate,
        endDate: lineItem.endDate,
        rollingFrequency: lineItem.rollingFrequency,
      },
    })) ?? []
  );
};

const getProductItemsFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  return (
    model.products?.map((product: CostModelProductItem) => ({
      id: product.id,
      lineItemType: 'DEBIT',
      lineItemCategory: 'PRODUCT',
      identifier: buildProductIdentifier(product),
      licenseType: product.licenseType,
      unitPrice: product.licenseCost,
      licenseCount: product.numberOfLicences,
      totalPrice: product.totalCost ?? undefined,
      startDate: product.allocationSpan?.startDate,
      endDate: product.allocationSpan?.endDate,
      rollingFrequency: convertProductFrequencyToItemFrequency(product.allocationSpan?.rollingFrequency),
    })) ?? []
  );
};

const getLineItemsFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  return (
    model.lineItems?.map((lineItem: CostModelLineItem) => ({
      id: lineItem.id,
      lineItemType: 'DEBIT',
      lineItemCategory: lineItem.lineItemType,
      description: lineItem.description,
      identifier: lineItem.id,
      unitPrice: lineItem.unitCost,
      licenseCount: lineItem.quantity,
      totalPrice: lineItem.totalCost,
      startDate: lineItem.allocationSpan?.startDate,
      endDate: lineItem.allocationSpan?.endDate,
      rollingFrequency: lineItem.allocationSpan?.rollingFrequency,
    })) ?? []
  );
};

export const getScopeReductionSavingsFromItems = (
  items?: AdvancedLinearCostModelLine[],
  defaultScopeReductionSavings?: number
) => {
  const scopeReductionItem = items?.find(isScopeReductionOverride);
  return {
    id: scopeReductionItem?.id ?? uuid(),
    amount: scopeReductionItem?.totalPrice ?? defaultScopeReductionSavings ?? 0,
    description: scopeReductionItem?.description ?? null,
    enabled: !isNil(scopeReductionItem),
  };
};

const getScopeReductionSavingsFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  if (model.scopeReductionSavingsOverride?.enabled && !isNil(model.scopeReductionSavingsOverride?.amount)) {
    return [
      omitEmptyValues({
        id: model.scopeReductionSavingsOverride.id ?? uuid(),
        lineItemType: 'OVERRIDE',
        lineItemCategory: 'FORM_OVERRIDE',
        identifier: ANNUAL_SCOPE_REDUCTION_IDENTIFIER,
        totalPrice: model.scopeReductionSavingsOverride.amount,
        description: model.scopeReductionSavingsOverride.description,
      }),
    ];
  }

  return [];
};

export const getAnnualCostOverrideFromItems = (items?: AdvancedLinearCostModelLine[], defaultAnnualCost?: number) => {
  const overrideItem = items?.find(isAnnualCostOverride);
  return {
    id: overrideItem?.id ?? uuid(),
    amount: overrideItem?.totalPrice ?? defaultAnnualCost ?? 0,
    enabled: !isNil(overrideItem),
  };
};

export const getTotalCostOverrideFromItems = (items?: AdvancedLinearCostModelLine[], defaultTotalCost?: number) => {
  const overrideItem = items?.find(isTotalCostOverride);
  return {
    id: overrideItem?.id ?? uuid(),
    amount: overrideItem?.totalPrice ?? defaultTotalCost ?? 0,
    enabled: !isNil(overrideItem),
  };
};

const getAnnualCostOverrideFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  if (model.annualCostOverride?.enabled && !isNil(model.annualCostOverride?.amount)) {
    return [
      {
        id: model.annualCostOverride.id ?? uuid(),
        lineItemType: 'OVERRIDE',
        lineItemCategory: 'FORM_OVERRIDE',
        identifier: ANNUAL_COST_IDENTIFIER,
        totalPrice: model.annualCostOverride.amount,
      },
    ];
  }

  return [];
};

const getTotalCostOverrideFromFormModel = (model: FormModel): AdvancedLinearCostModelLine[] => {
  if (model.totalCostOverride?.enabled && !isNil(model.totalCostOverride?.amount)) {
    return [
      {
        id: model.totalCostOverride.id ?? uuid(),
        lineItemType: 'OVERRIDE',
        lineItemCategory: 'FORM_OVERRIDE',
        identifier: TOTAL_COST_IDENTIFIER,
        totalPrice: model.totalCostOverride.amount,
      },
    ];
  }

  return [];
};

export const getOtherItems = (model: FormModel): AdvancedLinearCostModelLine[] => {
  return (
    model?.items?.filter((item) => {
      return (
        !isValidProductItem(item) &&
        !isValidLineItemItem(item) &&
        !isValidConcessionItem(item) &&
        !isAnnualCostOverride(item) &&
        !isTotalCostOverride(item) &&
        !isScopeReductionOverride(item)
      );
    }) ?? []
  );
};

export const getItemsFromFormData = (model: FormModel): AdvancedLinearCostModelLine[] => {
  return [
    ...getProductItemsFromFormModel(model),
    ...getLineItemsFromFormModel(model),
    ...getConcessionItemsFromFormModel(model),
    ...getScopeReductionSavingsFromFormModel(model),
    ...getAnnualCostOverrideFromFormModel(model),
    ...getTotalCostOverrideFromFormModel(model),
    ...getOtherItems(model),
  ];
};

export const transformationsForAdvancedLinearApproximation = {
  apiToForm: (costModel?: ContractModel2, defaultOverviewValues?: DefaultOverviewValues) => {
    const model = costModel?.model as AdvancedLinearApproximationCostModel;
    return {
      id: costModel?.id ?? uuid(),
      class: costModel?.class ?? COST_MODEL_CLASS,
      version: costModel?.version ?? COST_MODEL_VERSION,
      model: {
        items: model?.items,
        annualCostOverride: getAnnualCostOverrideFromItems(model?.items, defaultOverviewValues?.defaultAnnualCost),
        totalCostOverride: getTotalCostOverrideFromItems(model?.items, defaultOverviewValues?.defaultTotalCost),
        concessions: getConcessionsFromItems(model?.items),
        scopeReductionSavingsOverride: getScopeReductionSavingsFromItems(
          model?.items,
          defaultOverviewValues?.defaultScopeReductionSavings
        ),
        products: getProductsFromItems(model?.items),
        lineItems: getLineItemsFromItems(model?.items),
      },
    };
  },
  formToApi: (
    formData?: AdvancedLinearApproximationCostModelFormType,
    mode: 'create' | 'update' = 'create'
  ): ContractModel2 | undefined => {
    if (!formData) {
      return undefined;
    }

    return {
      id: formData.id ?? uuid(),
      class: formData.class ?? COST_MODEL_CLASS,
      version: formData.version ?? COST_MODEL_VERSION,
      model: { items: formData.model ? getItemsFromFormData(formData.model) : [] },
    };
  },
  formToCostModel: (model: FormModel): CostModelDefinition => {
    return {
      model_class: COST_MODEL_CLASS,
      model_class_version: COST_MODEL_VERSION,
      model: { items: model ? getItemsFromFormData(model) : [] },
    };
  },
};
