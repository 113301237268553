import useAwsRum from '@vertice/hooks/src/useAwsRum';
import { enableRum, rumConfigs, rumCredentials } from '../config';
import { Outlet } from 'react-router-dom';
import SnackbarProvider from '@vertice/slices/src/slices/Notifications/Provider';
import { ErrorBoundary } from 'react-error-boundary';
import { SmallScreenWarning } from '@vertice/components';
import { SimpleDialogContextProvider } from '@verticeone/design-system';
import { UnknownError } from '@vertice/core/src/modules/errors/UnknownError';
import React from 'react';
import { useTranslation } from 'react-i18next';

declare function cwr(operation: string, payload: any): void;

export const ProviderTree = () => {
  const { t } = useTranslation();
  useAwsRum({ config: rumConfigs, rumCredentials, enableRum });

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    if (typeof cwr === 'function') {
      cwr('recordError', error);
    }
  };

  return (
    <SnackbarProvider>
      <ErrorBoundary
        fallbackRender={() => <UnknownError backButtonLabel={t('UNKNOWN_ERROR.BACK_TO_DASHBOARD')} />}
        onError={errorHandler}
      >
        <SimpleDialogContextProvider>
          <Outlet />
        </SimpleDialogContextProvider>
      </ErrorBoundary>
      <SmallScreenWarning />
    </SnackbarProvider>
  );
};
