import { FC, useCallback, useEffect } from 'react';
import { FormStepsItem, FormStepsList } from '../../../components/FormSteps';
import { Divider } from '@verticeone/design-system';
import { match } from 'ts-pattern';
import { Stack } from '@mui/material';
import { LinearFormStep, useLinearStepper, useStepperStore } from '../../../hooks';
import { t } from 'i18next';
import { DeepPartial } from 'react-hook-form';
import { AssessmentContextFormData, assessmentContextSchema } from './schema';
import { ReviewRelationshipFormStep } from './formSteps/ReviewRelationshipFormStep/ReviewRelationshipFormStep';
import { AccountUser, Vendor } from '@vertice/slices';
import { getDefaultFormValues } from './utils';
import { Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { BusinessCaseStep } from './formSteps/BusinessCaseStep/BusinessCaseStep';
import { BusinessQuestion, DataTypesQuestion } from './types';
import { DataTypesStep } from './formSteps/DataTypesStep/DataTypesStep';
import { useSaveDraftDebounced } from '../../../hooks/useSaveDraftDebounced';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { useTaskContext } from '../../../TaskContext';
import { useLinearStepperInitialsFromDraft, useStepperWithDraft } from '../../../hooks/stepperWithDraft';

const I18N_STEPS_PREFIX = 'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.STEPS';

const formSteps: LinearFormStep[] = [
  { id: 'reviewRelationship', name: t(`${I18N_STEPS_PREFIX}.REVIEW_RELATIONSHIP`), visited: true, valid: false },
  { id: 'businessCase', name: t(`${I18N_STEPS_PREFIX}.BUSINESS_CASE`), visited: false, valid: false },
  { id: 'dataTypes', name: t(`${I18N_STEPS_PREFIX}.DATA_TYPES`), visited: false, valid: false },
];

export type AssessmentContextFormProps = {
  onSubmit: (values: AssessmentContextFormData) => Promise<void>;
  vendor: Vendor;
  requestOwner?: AccountUser;
  businessCaseQuestions: BusinessQuestion[];
  dataTypesQuestions: DataTypesQuestion[];
  taskDraft: Task['draft'];
};

export const AssessmentContextForm: FC<AssessmentContextFormProps> = ({
  onSubmit,
  vendor,
  requestOwner,
  businessCaseQuestions,
  dataTypesQuestions,
  taskDraft,
}) => {
  const { accountId } = useAccountContext();
  const taskContextData = useTaskContext();

  const linearStepperInitials = useLinearStepperInitialsFromDraft({ taskDraft, defaultSteps: formSteps });

  const { steps, activeStepId, goNext, setStep, goOnStep, goPrevious } = useLinearStepper(linearStepperInitials);
  const defaultValues = getDefaultFormValues({
    vendor,
    requestOwner,
    businessCaseQuestions,
    dataTypesQuestions,
    taskDraft,
  });
  const { values, getValues, updateValues } = useStepperStore({ defaultValues });

  const visitedPagesForDraft = useStepperWithDraft({ steps, activeStepId });
  const saveDraftDebounced = useSaveDraftDebounced({ accountId, taskId: taskContextData.task.id });

  useEffect(() => {
    saveDraftDebounced(values, visitedPagesForDraft);
  }, [values, visitedPagesForDraft, saveDraftDebounced]);

  const handleChangeStep = useCallback(
    <K extends keyof AssessmentContextFormData>(step: K) =>
      async (stepValues: DeepPartial<AssessmentContextFormData[K]>) => {
        updateValues(step, stepValues);
      },
    [updateValues]
  );

  const handleCompleteStep = useCallback(
    <K extends keyof AssessmentContextFormData>(step: K) =>
      async (stepValues: DeepPartial<AssessmentContextFormData[K]>) => {
        if (!activeStepId) return;

        setStep(activeStepId, { valid: true, visited: true });
        updateValues(step, stepValues);
        goNext();
      },
    [goNext, activeStepId, setStep, updateValues]
  );

  const handleSubmit = useCallback(
    <K extends keyof AssessmentContextFormData>(step: K) =>
      async (stepValues: DeepPartial<AssessmentContextFormData[K]>) => {
        if (!activeStepId) return;

        setStep(activeStepId, { valid: true, visited: true });
        updateValues(step, stepValues);
        const currentValues = { ...values, [step]: stepValues };
        const formResult = assessmentContextSchema.safeParse(currentValues);
        if (formResult.error) return;

        await onSubmit(formResult.data);
      },
    [onSubmit, values, activeStepId, setStep, updateValues]
  );

  return (
    <Stack direction="row" height="100%" width="100%">
      <FormStepsList sx={{ flex: '0 0 250px' }}>
        {steps.map(({ id, name, visited, valid }) => (
          <FormStepsItem key={id} visited={visited} valid={valid} onClick={() => goOnStep(id)}>
            {name}
          </FormStepsItem>
        ))}
      </FormStepsList>

      <Divider orientation="vertical" />

      {match(activeStepId)
        .with('reviewRelationship', (step) => (
          <ReviewRelationshipFormStep
            onSubmit={handleCompleteStep(step)}
            onChange={handleChangeStep(step)}
            defaultValues={getValues(step)}
            vendor={vendor}
          />
        ))
        .with('businessCase', (step) => (
          <BusinessCaseStep
            onSubmit={handleCompleteStep(step)}
            onChange={handleChangeStep(step)}
            onPrevious={goPrevious}
            defaultValues={getValues(step)}
            questions={businessCaseQuestions}
          />
        ))
        .with('dataTypes', (step) => (
          <DataTypesStep
            onSubmit={handleSubmit(step)}
            onChange={handleChangeStep(step)}
            onPrevious={goPrevious}
            defaultValues={getValues(step)}
            questions={dataTypesQuestions}
          />
        ))
        .otherwise(() => null)}
    </Stack>
  );
};
