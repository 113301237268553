import React, { useState } from 'react';
import { Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, KeyOutlined, LockOutlined } from '@mui/icons-material';
import { Text, getTextVariantStyle } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import type { AuthType, IntegrationType } from '../../utils';

type NewIntegrationCardContentProps = {
  hover: boolean;
  integrationType: IntegrationType;
};

type NewIntegrationCardProps = {
  integrationType: IntegrationType;
};

const StyledPlusIcon = styled((props) => <Add {...props} />)(({ theme }) => ({
  color: theme.palette.neutral.color1,
  opacity: 0.6,
  width: '14px',
  height: '14px',
}));

const IntegrationTypeButton = styled(Stack)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.neutral.color4,
  ...(disabled
    ? {
        cursor: 'not-allowed',
      }
    : {
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.neutral.hover.color1,
          backgroundColor: theme.palette.neutral.hover.color4,
        },
      }),
  color: theme.palette.neutral.color1,
  ...getTextVariantStyle({ size: 'S', variant: 'button-bold' }),
}));

const NewIntegrationCardContent = ({ hover, integrationType }: NewIntegrationCardContentProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.CREATE_NEW_INTEGRATION_CARD' });
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const apiKey: AuthType = 'ApiKey';

  if (!hover) {
    return (
      <Stack gap={1} direction="row" alignItems="center">
        <StyledPlusIcon />
        <Text variant="label" size="XXS" color="neutral1">
          {t('ADD_BUTTON')}
        </Text>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={1} height="100%" width="100%">
      <IntegrationTypeButton
        onClick={() => navigate(routes.INTEGRATIONS.CUSTOM.CREATE, { authType: apiKey, integrationType })}
      >
        <Stack direction="column" alignItems="center">
          <KeyOutlined />
          {t('TYPES.API_KEY')}
        </Stack>
      </IntegrationTypeButton>
      <IntegrationTypeButton disabled>
        <Stack direction="column" alignItems="center">
          <LockOutlined />
          {t('TYPES.OAUTH')}
        </Stack>
      </IntegrationTypeButton>
    </Stack>
  );
};

const NewIntegrationCard = ({ integrationType }: NewIntegrationCardProps) => {
  const [hover, setHover] = useState(false);

  return (
    <Stack
      p={4}
      bgcolor="transparent"
      border="1px dashed"
      borderColor="core.color4"
      borderRadius={4}
      height="100%"
      justifyContent="center"
      alignItems="center"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <NewIntegrationCardContent hover={hover} integrationType={integrationType} />
    </Stack>
  );
};

export default NewIntegrationCard;
