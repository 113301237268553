import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { ViewSettingsFormData } from '../ViewSettingsDrawer/schema';
import { useCallback, useMemo } from 'react';
import { apiCustomizationViewToForm, formToApiCustomizationView } from '../ViewSettingsDrawer/transformations';
import { isNil } from 'lodash';
import {
  useDeleteCustomizationViewMutation,
  useGetCustomizationViewQuery,
  useUpdateCustomizationViewMutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export type UseCustomViewUpdateParams = {
  filterViewId?: string;
  skip?: boolean;
};

/**
 * Wrapper around the custom filter views API.
 *
 * Purpose: Hide conversions between API and our internal FE representation.
 */
export const useCustomViewUpdate = ({ filterViewId }: UseCustomViewUpdateParams) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: customViewRaw,
    isLoading,
    isFetching,
  } = useGetCustomizationViewQuery({ accountId, viewId: filterViewId! }, { skip: isNil(filterViewId) });

  const [updateCustomViewMutation] = useUpdateCustomizationViewMutation();
  const [deleteCustomViewMutation, { isLoading: isDeleting }] = useDeleteCustomizationViewMutation();

  const customView = useMemo(
    () => (customViewRaw ? apiCustomizationViewToForm(customViewRaw.view) : undefined),
    [customViewRaw]
  );

  const updateCustomView = useCallback(
    async (formData: ViewSettingsFormData) => {
      if (isNil(filterViewId)) return;

      const res = await updateCustomViewMutation({
        accountId,
        viewId: filterViewId,
        updateCustomizationViewRequest: {
          view: formToApiCustomizationView(formData),
        },
      });

      if (!('data' in res)) {
        enqueueSnackbar(t('ENTITIES.CONTRACT_CUSTOM_VIEW.SNACKBARS.FAILED_TO_UPDATE'), { variant: 'error' });
        return null;
      }

      return apiCustomizationViewToForm(res.data.view);
    },
    [accountId, enqueueSnackbar, filterViewId, t, updateCustomViewMutation]
  );

  const deleteCustomView = useCallback(async () => {
    if (isNil(filterViewId)) return;
    await deleteCustomViewMutation({ accountId, viewId: filterViewId });
  }, [accountId, deleteCustomViewMutation, filterViewId]);

  return {
    customView,
    isLoading,
    isFetching,
    isDeleting,
    updateCustomView,
    deleteCustomView,
  };
};
