import React, { ComponentProps, ElementType, JSXElementConstructor, ReactNode, useId } from 'react';
import { TextFieldCaption } from '@verticeone/design-system';
import { TooltipProps } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import Grid, { GridSize } from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import type { CommonFormFieldProps } from '../../types';
import { FieldPath, FieldValues, useController } from 'react-hook-form';
// We need to import the type tests here to make sure they're run.
// Don't worry, they don't do anything at runtime.
import { runTsTests } from './FormEntry.typeTest';
import { useIsSchemaFieldRequired } from '../../schema/FormSchemaContext';
import { FormFieldDescription } from '../FormFieldDescription';

export type ComponentPropsMinusTheAutoFilledOnes<
  CompType extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = Omit<ComponentProps<CompType>, keyof CommonFormFieldProps<never> | 'children'>;

/**
 * Returns union of all required keys of the specified object type.
 * Source: https://stackoverflow.com/a/52991061
 */
type RequiredKeysOf<T> = keyof {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T as string extends K ? never : number extends K ? never : {} extends Pick<T, K> ? never : K]: 0;
};

export type FormEntryProps<FormDataType extends FieldValues, CompType extends ElementType> = {
  name: FieldPath<FormDataType>;
  label?: string;
  description?: ReactNode | ReactNode[];

  /**
   * If true, the field will be marked as required.
   * If ommited or false, requiredness is taken from schema (through FormSchemaContext)
   * If there's no schema, the field is not marked as required.
   */
  required?: boolean;
  disabled?: boolean;
  width?: GridSize;
  tooltip?: Pick<TooltipProps, 'title' | 'content' | 'maxWidth'>;
  component: CompType;
  children?: React.ReactNode;
} & (RequiredKeysOf<ComponentPropsMinusTheAutoFilledOnes<CompType>> extends never
  ? { componentProps?: ComponentPropsMinusTheAutoFilledOnes<CompType> }
  : { componentProps: ComponentPropsMinusTheAutoFilledOnes<CompType> });

export type FormEntryComponentWithPreFilledFormDataType<FormDataType extends FieldValues> = <
  CompType extends ElementType
>(
  props: FormEntryProps<FormDataType, CompType>
) => JSX.Element;

/**
 * You can use this function to save repeating the generics for each form entry.
 * @example
 * // Before
 * <FormEntry<DogFormData, typeof ComponentWithAutoFilledProps>
 *   name="address.city"
 *   component={ComponentWithAutoFilledProps}
 * />
 * // After
 * // ...put above the form
 * const DogFormEntry = createTypedFormEntry<DogFormData>();
 * // ...use in the form
 * <DogFormEntry name="address.city" component={ComponentWithAutoFilledProps} />
 * // (^^ the FormData generic is pre-filled, the CompType generic gets inferred)
 */
export const createTypedFormEntry = <FormDataType extends FieldValues = never>() =>
  FormEntry as FormEntryComponentWithPreFilledFormDataType<FormDataType>;

/**
 * A wrapper of a form field that's supposed to be used within a FormSection.
 * Optionally it adds a label and a tooltip and connects the label and the field with a generated ID.
 *
 * It takes two generic parameters:
 * - FormDataType: The type of the form data. It allows us to check `name` prop for typos.
 * - CompType: The type of the wrapped component. It allows us to check the passed `componentProps`.
 */
const FormEntry = <FormDataType, CompType extends ElementType>({
  name,
  label,
  description,
  required: requiredByProp = false,
  disabled = false,
  width = 6,
  tooltip,
  component: Component,
  componentProps,
  children,
}: // We have this ? in the type so that FormDataType generic parameter is always required.
FormEntryProps<FormDataType extends Record<string, unknown> ? FormDataType : never, CompType>) => {
  const ComponentUntyped = Component as ElementType<CommonFormFieldProps<any>>;
  const id = useId();
  const labelId = useId();

  const requiredBySchema = useIsSchemaFieldRequired(name) ?? false;
  const required = requiredByProp || requiredBySchema;

  const { fieldState } = useController({ name, rules: { required } });
  const { error } = fieldState;
  const isRequiredError = error?.type === 'invalid_type' && !disabled;

  return (
    <Grid item xs={width}>
      <FormControl variant="outlined" fullWidth>
        <Stack gap={description ? 2 : 1}>
          <Stack>
            {label && (
              <TextFieldCaption
                id={labelId}
                htmlFor={id}
                label={label}
                required={required}
                requiredError={required && isRequiredError}
                size="XS"
                tooltip={tooltip}
              />
            )}
            {description && <FormFieldDescription>{description}</FormFieldDescription>}
          </Stack>
          <ComponentUntyped
            name={name}
            id={id}
            labelId={labelId}
            required={required}
            disabled={disabled}
            {...componentProps}
          >
            {children}
          </ComponentUntyped>
        </Stack>
      </FormControl>
    </Grid>
  );
};

export default FormEntry;

runTsTests();
