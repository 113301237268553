import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@verticeone/design-system';

import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { CreateNonSaasRequestProgress } from './CreateNonSaasRequestProgress';
import { match } from 'ts-pattern';
import { CreateNonSaasRequestPurchaseForm, CreateGenericPurchaseForm } from './CreateNonSaasRequestPurchaseForm';
import { useDefaultCreateRequests } from '../../../../intelligentWorkflows/hooks/useCreateWorkflowRequest';

type CreateRequestDialogProps = {
  vendorName?: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
};

type DialogState = 'PROGRESS' | 'GENERIC_PURCHASE';

/**
 * This Dialog is copy paste from original CreateReqestDialog and will be in near future
 * replaced with redesigned and refactored version of the dialog from IW. For now, here is
 * only part of the logic to create new NonSass IW.
 */
export const CreateNonSaasRequestPurchaseDialog: FC<CreateRequestDialogProps> = (props) => {
  const { open, setOpen, vendorName } = props;
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const [dialogState, setDialogState] = useState<DialogState>('GENERIC_PURCHASE');
  const [purchaseName, setPurchaseName] = useState<string>('');

  const {
    createNewGenericPurchaseRequest,
    createdNewRequestId: createdNewGenericPurchaseRequestId,
    createdNewRequestTaskId: createdNewGenericPurchaseRequestTaskId,
  } = useDefaultCreateRequests();

  const handleSubmit = (values: CreateGenericPurchaseForm) => {
    setPurchaseName(values.name);
    setDialogState('PROGRESS');
    void createNewGenericPurchaseRequest(values.name);
  };

  const handleRedirectAfterCompletion = useCallback(
    (requestId: string, taskId?: string) => {
      const search = taskId ? { taskId } : undefined;
      navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, { requestId }, { search });
    },
    [navigate, routes]
  );

  const defaultValues = useMemo<CreateGenericPurchaseForm>(() => {
    const vendor = vendorName || '';
    return { name: `${vendor} non saas` };
  }, [vendorName]);

  useEffect(() => {
    if (createdNewGenericPurchaseRequestId) {
      handleRedirectAfterCompletion(createdNewGenericPurchaseRequestId, createdNewGenericPurchaseRequestTaskId);
    }
  }, [createdNewGenericPurchaseRequestId, createdNewGenericPurchaseRequestTaskId, handleRedirectAfterCompletion]);

  const handleClose = () => {
    if (dialogState === 'PROGRESS') return;
    setOpen(false);
  };

  return (
    <Dialog open={open} setOpen={setOpen} size="M" width={780} onClose={handleClose}>
      {match(dialogState)
        .with('PROGRESS', () => <CreateNonSaasRequestProgress purchaseName={purchaseName} />)
        .with('GENERIC_PURCHASE', () => (
          <CreateNonSaasRequestPurchaseForm
            onClose={handleClose}
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
          />
        ))
        .exhaustive()}
    </Dialog>
  );
};
