import { Button, IconWrapper, Placeholder } from '@verticeone/design-system';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const ShowAllButtonWrapper = ({ children }: PropsWithChildren) => (
  <Stack px={3} pb={2} pt={0}>
    {children}
  </Stack>
);

type ShowAllButtonProps = {
  onClick: () => void;
  label: string;
};

const ShowAllButton = ({ onClick, label }: ShowAllButtonProps) => {
  return (
    <ShowAllButtonWrapper>
      <Button
        onClick={onClick}
        variant="plain"
        color="primary"
        endIcon={<IconWrapper icon={ArrowForwardIosOutlined} size="S" />}
        size="S"
      >
        <Stack p="14px">{label}</Stack>
      </Button>
    </ShowAllButtonWrapper>
  );
};

const Skeleton = () => (
  <ShowAllButtonWrapper>
    <Placeholder variant="text" width="100%" height={56} />
  </ShowAllButtonWrapper>
);

ShowAllButton.Skeleton = Skeleton;

export default ShowAllButton;
