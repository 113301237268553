import React, { useMemo } from 'react';
import { ContractContextDataRequireFetched, FetchedContract } from '../../saas/contract/types';
import { useAccountContext } from '../../account/AccountContext';
import VendorDiligenceInsights from './VendorDiligenceInsights';
import { isNonSaasContract } from '../../saas/contract/computed';
import { FEATURES } from '../../features/constants';

export const shouldCardBeVisible = (
  fetchedContract: FetchedContract,
  isNonSaasPurchasingContractManagementEnabled = false
) => {
  const isNonSaas = isNonSaasContract(fetchedContract.contract);
  if (isNonSaasPurchasingContractManagementEnabled && isNonSaas) return false;
  const vendorId = fetchedContract?.contract?.parts?.contractual?.vendor?.vendorId;
  return !!vendorId;
};
export const useDiligenceInsightsCard = ({ fetchedContract }: ContractContextDataRequireFetched) => {
  const { accountId, isFeatureEnabled } = useAccountContext();
  const isNonSaasPurchasingContractManagementEnabled = isFeatureEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG);

  return useMemo(
    () =>
      shouldCardBeVisible(fetchedContract, isNonSaasPurchasingContractManagementEnabled)
        ? () => (
            <VendorDiligenceInsights
              {...{
                accountId: accountId!,
                vendorId: fetchedContract?.contract?.parts?.contractual?.vendor?.vendorId!,
              }}
            />
          )
        : null,
    [fetchedContract, accountId, isNonSaasPurchasingContractManagementEnabled]
  );
};
