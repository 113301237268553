import { FC } from 'react';
import { Stack, styled, Box } from '@mui/material';
import { TaskColorVariant } from './types';
import { IconWrapper, ifExpression } from '@verticeone/design-system';
import { DragIndicatorOutlined } from '@mui/icons-material';

const DragIconWrapper = styled(IconWrapper)(({ theme }) => ({
  color: theme.palette.neutral.color1,
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
}));

type DragIconProps = {
  isIconVisible?: boolean;
};

export const DragIcon: FC<DragIconProps> = ({ isIconVisible = false }) => {
  return (
    <Stack width={16} height="100%" direction="row" justifyContent="center">
      {isIconVisible ? <DragIconWrapper size="XXS" icon={DragIndicatorOutlined} /> : null}
    </Stack>
  );
};

export const ShowOnHover = styled(Box)({
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
});

export const StyledTaskNode = styled(Stack)<{
  $colorVariant: TaskColorVariant;
  $withShadow?: boolean;
  $withColoredBorder?: boolean;
  $selected?: boolean;
  $selectable?: boolean;
  $active?: boolean;
  $withDragStyles?: boolean;
  $withDraggingStyles?: boolean;
}>(
  ({
    theme,
    $colorVariant,
    $withShadow,
    $withColoredBorder,
    $selected,
    $selectable = true,
    $active = false,
    $withDragStyles = false,
    $withDraggingStyles = false,
  }) => ({
    pointerEvents: 'auto',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexGrow: 1,
    border: $withColoredBorder ? 'none' : `1px solid ${theme.palette.core.color4}`,
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'box-shadow 0.3s ease-in-out',
    ...($selectable
      ? {
          cursor: 'pointer',
        }
      : {
          pointerEvents: 'none',
        }),
    '&:hover': {
      [ShowOnHover]: {
        opacity: 1,
      },
      ...ifExpression(!$withColoredBorder, {
        borderColor: $colorVariant === 'neutral' ? theme.palette.core.color5 : theme.palette[$colorVariant].color3,
      }),
      ...ifExpression($withDragStyles && !$withDraggingStyles, {
        boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '50' }),
        [DragIconWrapper]: {
          opacity: 1,
        },
      }),
    },
    boxShadow: $withShadow
      ? theme.palette.global.getShadow({
          // shadows are not defined for neutral and transparent colors - use core color instead
          color: $colorVariant === 'neutral' || $colorVariant === 'transparent' ? 'core' : $colorVariant,
          type: 'soft',
          depth: '1z',
          distance: '50',
        })
      : 'none',
    ...ifExpression(!$active, {
      borderColor: $withColoredBorder ? theme.palette[$colorVariant].color2 : theme.palette.core.color4,
      borderWidth: 1,
      borderStyle: 'solid',
    }),
    ...ifExpression($withDraggingStyles, {
      boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '3z', distance: '20' }),
      cursor: 'grabbing',
      [DragIconWrapper]: {
        opacity: 1,
      },
      [ShowOnHover]: {
        opacity: 1,
      },
    }),
    ...ifExpression($selected, {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: $colorVariant === 'neutral' ? theme.palette.core.color6 : theme.palette[$colorVariant].color4,
    }),
    ...ifExpression($withShadow && $colorVariant === 'error', {
      animation: 'glow 3s linear alternate infinite',

      '@keyframes glow': {
        '0%': {
          boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '50' }),
        },
        '50%': {
          boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '70' }),
        },
        '100%': {
          boxShadow: theme.palette.global.getShadow({ color: 'error', depth: '2z', type: 'soft', distance: '100' }),
        },
      },
    }),
  })
);
