import { useCallback, useEffect } from 'react';
import { useAccountContext } from '../../account/AccountContext';
import { Vendor } from '../../vendor/types';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { RenewalProduct, useDefaultCreateRequests } from './useCreateWorkflowRequest';

type CreateRenewalRequestFlowParams = {
  vendor: Vendor;
  products?: RenewalProduct[];
  contractId: string;
  contractCurrency?: string;
  department?: string;
  source?: string;
};

export const useCreateRenewalRequestFlow = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const {
    createRenewalRequest,
    createdNewRequestId,
    isCreatingNewRequest: isCreatingRenewalRequest,
    failedToCreate,
  } = useDefaultCreateRequests();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const { enqueueSnackbar } = useSnackbar();

  const createRenewalRequestFlow = useCallback(
    async ({ products, ...otherParams }: CreateRenewalRequestFlowParams) => {
      await createRenewalRequest({
        ...otherParams,
        source:
          otherParams.source ||
          `urn:verticeone:vertice:${accountId}:cfa:page/intake/existingContract/${otherParams.contractId}`,
        products: products,
      });
    },
    [accountId, createRenewalRequest]
  );

  useEffect(() => {
    if (failedToCreate) {
      enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.FAILED_TO_CREATE'), { variant: 'error' });
    }
    if (!isCreatingRenewalRequest) {
      if (createdNewRequestId) {
        enqueueSnackbar(t('ENTITIES.WORKFLOW_REQUEST.SNACKBARS.CREATED'), { variant: 'success' });

        navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, {
          requestId: createdNewRequestId,
        });
      }
    }
  }, [
    failedToCreate,
    enqueueSnackbar,
    isCreatingRenewalRequest,
    createdNewRequestId,
    navigate,
    routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL,
    t,
  ]);

  return {
    createRenewalRequestFlow,
    isCreatingRenewalRequest,
  };
};
