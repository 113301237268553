import { useCallback, useMemo } from 'react';
import { AccountUser, UserInfo, useListUsersPublicInformationQuery, UsersPublicInformation } from '@vertice/slices';
import { useUsersContext } from '../contexts/UsersContext';
import { UsersByIdReturn } from './useRelevantUsersById';

export const getUsersInfo = (usersPublicInfo: UsersPublicInformation | undefined = []) => {
  const accountUserInfo: (AccountUser | UserInfo)[] = [];
  for (const { userId, email, firstName, lastName, middleName } of usersPublicInfo) {
    if (!userId || !email) {
      continue;
    }
    accountUserInfo.push({
      userId,
      email,
      firstName,
      lastName,
      middleName,
    });
  }

  return accountUserInfo;
};

export const useResolveUsersInfo = (userIds: string[], usersById: UsersByIdReturn) => {
  const [missingUsers, knownUsers] = useMemo(() => {
    if (!userIds.length) {
      return [[], usersById];
    }

    const missing: string[] = [];
    for (const userId of userIds) {
      if (userId.includes('token')) {
        const [accountId, tokenId] = userId.split(':', 2);
        usersById[userId] = {
          userId: tokenId,
          email: '',
          middleName: '',
          parentAccountId: accountId,
        };
      }
      if (!usersById[userId]) {
        missing.push(userId);
      }
    }
    return [missing, usersById];
  }, [userIds, usersById]);

  const { data: additionalUsers, isLoading: isLoadingAdditional } = useListUsersPublicInformationQuery(missingUsers);

  const { allUsers, isLoading } = useMemo(() => {
    if (!missingUsers.length) {
      return { allUsers: knownUsers, isLoading: false };
    } else if (!additionalUsers) {
      return { allUsers: {}, isLoading: true };
    }

    const additionalUsersById: UsersByIdReturn = { ...knownUsers };
    for (const user of getUsersInfo(additionalUsers)) {
      additionalUsersById[user.userId] = user;
    }

    return { allUsers: additionalUsersById, isLoading: false };
  }, [knownUsers, missingUsers, additionalUsers]);

  return {
    isLoading: isLoading || isLoadingAdditional,
    usersById: allUsers,
  };
};

export const useResolveUsersContextInfo = (userIds: string[]) => {
  const { getFullName: getAccountFullName, usersById: accountUsersById } = useUsersContext();
  const { usersById, isLoading } = useResolveUsersInfo(userIds, accountUsersById);

  const getFullName = useCallback(
    (userId: string) => getAccountFullName(userId, usersById),
    [getAccountFullName, usersById]
  );

  return { getFullName, isLoading };
};
