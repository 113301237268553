import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { match } from 'ts-pattern';
import {
  hasDeadlineWithinDays,
  isOneOffContract,
  isPlannedForExpiration,
} from '@vertice/core/src/modules/saas/contract/computed';
import { ContentDescriptionOneOff } from './ContentDescriptionOneOff';
import { ContentDescriptionDefault } from './ContentDescriptionDefault';
import { ContentDescriptionPlannedExpiration } from './ContentDescriptionPlannedExpiration';
import { ContentDescriptionSoonExpiration } from './ContentDescriptionSoonExpiration';

export type ContractNotYetRenewedContentBodyProps = {
  contract: Contract;
};

export const ContractNotYetRenewedCardBody = ({ contract }: ContractNotYetRenewedContentBodyProps) => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAccountContext();
  const isNonSaasFeatureEnabled = isFeatureEnabled(FEATURES.NON_SAAS_PURCHASING_CONTRACT_MNG);

  if (isNonSaasFeatureEnabled) {
    return match({
      isOneOff: isOneOffContract(contract),
      isSoonToExpire: hasDeadlineWithinDays(contract, 90),
      isPlannedForExpiration: isPlannedForExpiration(contract),
    })
      .with({ isOneOff: false, isPlannedForExpiration: true }, () => <ContentDescriptionPlannedExpiration />)
      .with({ isOneOff: false, isSoonToExpire: true, isPlannedForExpiration: false }, () => (
        <ContentDescriptionSoonExpiration />
      ))
      .with({ isOneOff: true }, () => <ContentDescriptionOneOff />)
      .with({ isOneOff: false }, () => <ContentDescriptionDefault />)
      .exhaustive();
  }

  return (
    <Text variant="body-regular" color="text2" size="S">
      {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.BODY')}
    </Text>
  );
};
