import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, styled, useTheme } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  DialogText,
  FormControlLabel,
  IconWrapper,
  Text,
  ToggleSwitch,
  Tooltip,
} from '@verticeone/design-system';
import { FieldSync, ResetFieldSync } from './FieldSync';
import { IssueCreatedForm } from './IssueCreatedForm';
import { StatusChangeForm } from './StatusChangeForm';
import { Trigger, TriggerFilter, TriggerMapping } from './types';
import { isStatusChangeFilter } from './utils';

const TriggerBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.input.color1,
  padding: theme.spacing(4),
}));

type JiraTriggerProps = {
  name: string;
  trigger: Trigger;
  index: number;
  active: boolean;
  onChange: (index: number, trigger?: Trigger) => void;
};

export const JiraTrigger: FC<JiraTriggerProps> = ({ name, trigger, index, active, onChange }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [isDeleteTriggerDialogOpen, setIsDeleteTriggerDialogOpen] = useState(false);
  const [resetFieldSyncProps, setResetFieldSyncProps] = useState<ResetFieldSync | undefined>();

  const handleDeleteTrigger = useCallback(() => {
    onChange(index);
    setIsDeleteTriggerDialogOpen(false);
  }, [index, onChange]);

  const handleChangeFilter = useCallback(
    (filter: TriggerFilter) => {
      onChange(index, {
        ...trigger,
        filter,
      });
    },
    [index, onChange, trigger]
  );

  const handleChangeMapping = useCallback(
    (mapping: TriggerMapping) => {
      onChange(index, {
        ...trigger,
        mapping,
      });
    },
    [index, onChange, trigger]
  );

  const handleChangeAttachments = useCallback(
    (includeAttachments: boolean) => {
      onChange(index, {
        ...trigger,
        includeAttachments,
      });
    },
    [index, onChange, trigger]
  );

  const handleIssueTypeChange = useCallback(
    (issueTypeId: string, projectId: string) => setResetFieldSyncProps({ issueTypeId, projectId }),
    []
  );

  return (
    <TriggerBox>
      <Stack direction="row" gap={4} justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Text variant="button-bold" size="S" color="neutral1">
            {name}
          </Text>
          <Tooltip content={t('INTEGRATIONS.JIRA.JIRA_TRIGGER.TOOLTIP')} size="S">
            <IconWrapper icon={InfoOutlinedIcon} size="XS" htmlColor={palette.text.color3} />
          </Tooltip>
        </Stack>
        <Button variant="plain" size="XXS" onClick={() => setIsDeleteTriggerDialogOpen(true)}>
          {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.DELETE')}
        </Button>
        <Dialog size="M" open={isDeleteTriggerDialogOpen} setOpen={() => setIsDeleteTriggerDialogOpen(false)}>
          <DialogHeader>{t('INTEGRATIONS.JIRA.JIRA_TRIGGER.DELETE_TRIGGER_DIALOG.HEADER')}</DialogHeader>
          <DialogContent>
            <DialogText variant="body-bold" size="M" color="text2">
              {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.DELETE_TRIGGER_DIALOG.CONTENT_TITLE')}
            </DialogText>
            <DialogText variant="body-regular" size="M" color="text2">
              {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.DELETE_TRIGGER_DIALOG.CONTENT')}
            </DialogText>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" onClick={handleDeleteTrigger}>
              {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.DELETE_TRIGGER_DIALOG.BUTTON')}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <Stack gap={4}>
        <Text variant="label" size="XXS" color="text3" sx={{ textTransform: 'uppercase' }}>
          {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.CONDITIONS_TITLE')}
        </Text>
        {isStatusChangeFilter(trigger.filter) ? (
          <StatusChangeForm
            triggerFilter={trigger.filter}
            onChange={handleChangeFilter}
            onIssueTypeChange={handleIssueTypeChange}
            disabled={!active}
          />
        ) : (
          <IssueCreatedForm
            triggerFilter={trigger.filter}
            onChange={handleChangeFilter}
            onIssueTypeChange={handleIssueTypeChange}
            disabled={!active}
          />
        )}
      </Stack>
      <Stack gap={6}>
        <Stack gap={1}>
          <Text variant="label" size="XXS" color="text3" sx={{ textTransform: 'uppercase' }}>
            {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.SYNC_TITLE')}
          </Text>
          <Text variant="body-regular" size="S" color="text1">
            {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.SYNC_DESCRIPTION')}
          </Text>
        </Stack>
        <FieldSync
          projectId={trigger.filter.projectId}
          issueTypeId={trigger.filter.issueTypeId}
          mapping={trigger.mapping}
          onChange={handleChangeMapping}
          resetMapper={resetFieldSyncProps}
          disabled={!active}
        />
      </Stack>
      <Stack gap={4}>
        <Text variant="label" size="XXS" color="text3" sx={{ textTransform: 'uppercase' }}>
          {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.INCLUDE_ATTACHMENT')}
        </Text>
        <Stack direction="row" gap={2} alignItems="center">
          <FormControlLabel
            control={
              <ToggleSwitch
                checked={trigger.includeAttachments ?? false}
                onChange={(_event, checked) => {
                  handleChangeAttachments(checked);
                }}
                size="XS"
                color="neutral"
                disabled={!active}
              />
            }
            label={
              <Text variant="body-regular" size="XS" color="text2">
                {t('INTEGRATIONS.JIRA.JIRA_TRIGGER.INCLUDE_ATTACHMENT_TOGGLE')}
              </Text>
            }
            sx={{ margin: 0 }}
          />
        </Stack>
      </Stack>
    </TriggerBox>
  );
};
