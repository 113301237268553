import { Stack } from '@mui/material';
import { useController } from 'react-hook-form';
import { Button } from '@verticeone/design-system';
import { EditOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { CommonFormFieldProps } from '../../types';

export type FormOverrideButtonFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  editLabel?: string;
  revertLabel?: string;
};

export const FormOverrideButtonField = <FormDataType extends FieldValues = never>({
  name,
  editLabel,
  revertLabel,
  disabled,
}: FormOverrideButtonFieldProps<FormDataType>) => {
  const { t } = useTranslation();

  const {
    field: { onChange, value },
  } = useController({ name });

  const handleClick = () => {
    onChange(!value);
  };

  const effectiveEditLabel = editLabel ?? t('ENTITIES.CONTRACT.ACTIONS.EDIT_TOTAL');
  const effectiveRevertLabel = revertLabel ?? t('ENTITIES.CONTRACT.ACTIONS.REVERT_TOTAL');

  return (
    <Button variant="ghost" color={value ? 'warning' : 'neutral'} onClick={handleClick} disabled={disabled}>
      <Stack direction="row" gap={1} alignItems="center">
        <EditOutlined sx={{ opacity: 0.6 }} />
        {value ? effectiveRevertLabel : effectiveEditLabel}
      </Stack>
    </Button>
  );
};
