import {
  FlowDefinition,
  FlowEdge,
  Process,
  VisualElement,
} from '@vertice/core/src/modules/intelligentWorkflows/definitionsTypes';
import {
  WorkflowModel,
  WorkflowStartNode,
  WorkflowEndNode,
  WorkflowTaskNode,
  WorkflowEdge,
  WorkflowGatewayNode,
  WorkflowState,
  WorkflowLabelNode,
  WorkflowNode,
  EdgeState,
} from './types';
import { resolveTaskNodeType } from './resolveTaskNodeType';
import { resolveTaskNodeThumbnailConfiguration } from './resolveTaskNodeThumbnailConfiguration';
import { findResourceUrnFromConfigurations } from './configurationUtils';
import { ServicesThumbnails } from '../WorkflowViewer/useServicesThumbnails';
import { isFlowEdgeConditionConfiguration } from '../../pocWorkflowSchema';
import { WorkflowDefinitions } from '../types';

const isNumber = (value: unknown): value is number => typeof value === 'number';

const getPositionsForNode = (nodeId: string, elementsPositions?: VisualElement[], fallbackNodeId?: string) => {
  const elementPosition = elementsPositions?.find((element) => element.ref === nodeId);

  if (isNumber(elementPosition?.boundary?.x) && isNumber(elementPosition?.boundary?.y)) {
    const { x, y } = elementPosition.boundary;

    return { x, y };
  }

  const fallbackElementPosition = elementsPositions?.find((element) => element.ref === fallbackNodeId);

  if (isNumber(fallbackElementPosition?.boundary?.x) && isNumber(fallbackElementPosition?.boundary?.y)) {
    const { x, y } = fallbackElementPosition.boundary;

    return { x: x - 200, y: y + 60 };
  }

  return { x: 0, y: 0 };
};

const convertStartNodes = ({
  startNodes,
  elementsPositions,
  workflowState,
}: {
  startNodes: Process['start'];
  workflowState?: WorkflowState;
  elementsPositions?: VisualElement[];
}): WorkflowStartNode[] =>
  startNodes.map(({ start }) => ({
    id: start.id,
    name: start.name,
    kind: 'start',
    state: {
      parentRequest: workflowState?.parentRequest,
    },
    position: getPositionsForNode(start.id, elementsPositions),
  }));

const convertEndNodes = ({
  endNodes,
  elementsPositions,
}: {
  endNodes: Process['end'];
  elementsPositions?: VisualElement[];
}): WorkflowEndNode[] =>
  endNodes.map(({ end }) => ({
    id: end.id,
    name: end.name,
    kind: 'end',
    position: getPositionsForNode(end.id, elementsPositions),
  }));

const convertTaskNodes = ({
  taskNodes,
  workflowState,
  servicesThumbnails,
  elementsPositions,
}: {
  taskNodes: Process['tasks'];
  workflowState?: WorkflowState;
  servicesThumbnails?: ServicesThumbnails;
  elementsPositions?: VisualElement[];
}): WorkflowTaskNode[] =>
  taskNodes?.map(({ task }) => {
    const resourceUrn = findResourceUrnFromConfigurations(task.configurations);
    const resourceId = resourceUrn?.slice(resourceUrn.indexOf('/') + 1);
    const serviceThumbnail = resourceId ? servicesThumbnails?.[resourceId] : null;

    const taskThumbnail = resolveTaskNodeThumbnailConfiguration(task.configurations || []);
    const isTaskThumbnailFallback = taskThumbnail.id === '' || taskThumbnail.type === '';

    return {
      id: task.id,
      name: task.name,
      kind: 'task',
      state: workflowState?.tasks[task.id],
      type: resolveTaskNodeType(workflowState?.tasks[task.id], task.configurations),
      requestRoute: workflowState?.requestRoute,
      thumbnail: isTaskThumbnailFallback && serviceThumbnail ? serviceThumbnail : taskThumbnail,
      position: getPositionsForNode(task.id, elementsPositions),
    };
  }) || [];

const convertGatewayNodes = ({
  gatewayNodes,
  workflowState,
  elementsPositions,
}: {
  gatewayNodes: Process['gateways'];
  workflowState?: WorkflowState;
  elementsPositions?: VisualElement[];
}): WorkflowGatewayNode[] =>
  gatewayNodes?.map(({ gateway }) => ({
    id: gateway.id,
    name: gateway.name,
    kind: 'gateway',
    state: workflowState?.gateways[gateway.id],
    gatewayType: gateway.gatewayType,
    position: getPositionsForNode(gateway.id, elementsPositions),
  })) || [];

export const AVERAGE_CHAR_WIDTH = 9;

export const getLabelNodeId = (id: string) => `${id}-label`;

const splitEdgeToLabelNodeAndEdges = ({
  edge,
  state,
  elementsPositions,
}: {
  edge: FlowEdge;
  state?: EdgeState;
  elementsPositions?: VisualElement[];
}) => {
  if (!edge?.name) {
    return;
  }

  const nodeId = getLabelNodeId(edge.id);

  const node: WorkflowLabelNode = {
    id: nodeId,
    kind: 'label',
    name: edge.name,
    state,
    to: edge.to,
    from: edge.from,
    originalEdgeId: edge.id,
    width: edge.name.length * AVERAGE_CHAR_WIDTH,
    position: getPositionsForNode(nodeId, elementsPositions, edge.to),
  };

  const edgeEnd: WorkflowEdge = {
    id: `${edge.id}-end`,
    name: '',
    from: nodeId,
    originalEdgeId: edge.id,
    to: edge.to,
    state,
  };

  const edgeStart: WorkflowEdge = {
    id: `${edge.id}-start`,
    name: '',
    from: edge.from,
    to: nodeId,
    originalEdgeId: edge.id,
    state,
  };

  return {
    node,
    edgeStart,
    edgeEnd,
  };
};

const convertEdges = ({
  process,
  workflowState,
  elementsPositions,
}: {
  process?: Process;
  workflowState?: WorkflowState;
  elementsPositions?: VisualElement[];
}): {
  edges: WorkflowEdge[];
  labelNodes: WorkflowLabelNode[];
} => {
  const labelNodes: WorkflowLabelNode[] = [];
  const edges: WorkflowEdge[] = [];

  process?.flow?.flow.edges?.forEach(({ edge }) => {
    const nodeAndEdges = splitEdgeToLabelNodeAndEdges({
      edge,
      state: workflowState?.edges[edge.id],
      elementsPositions,
    });
    const isDefault = edge.configurations?.some(
      (config) => isFlowEdgeConditionConfiguration(config) && config.condition.conditionType === 'DefaultFlow'
    );

    if (nodeAndEdges) {
      const { edgeEnd, edgeStart, node } = nodeAndEdges;
      labelNodes.push(node);
      edges.push({ ...edgeStart, isDefault });
      edges.push(edgeEnd);
    } else {
      edges.push({
        ...edge,
        state: workflowState?.edges[edge.id],
        isDefault,
      });
    }
  });

  return {
    labelNodes,
    edges,
  };
};

const isNodeAfterEdge = (node: WorkflowNode, edge: FlowEdge) => edge.from === node.id;
const isEndNodeWithEdge = (node: WorkflowNode, edge: FlowEdge) => edge.to === node.id && node.kind === 'end';
const isLabelNodeWithinEdge = (node: WorkflowNode, edge: FlowEdge) =>
  node.kind === 'label' && node.id === getLabelNodeId(edge.id);

const addNodesPassedState = (nodes: WorkflowNode[], flow?: FlowDefinition, workflowState?: WorkflowState) => {
  const passedEdges = flow?.flow.edges?.filter(({ edge }) => workflowState?.edges[edge.id]?.passed);

  return nodes.map((node) => {
    return {
      ...node,
      state: {
        ...node.state,
        passed: passedEdges?.some(
          ({ edge }) =>
            isNodeAfterEdge(node, edge) || isEndNodeWithEdge(node, edge) || isLabelNodeWithinEdge(node, edge)
        ),
      },
    };
  });
};

type ProcessDefinitionToModel = {
  workflowDefinitions: WorkflowDefinitions;
  workflowState?: WorkflowState;
  servicesThumbnails?: ServicesThumbnails;
};

export const workflowDefinitionsToModel = ({
  workflowDefinitions,
  workflowState,
  servicesThumbnails,
}: ProcessDefinitionToModel): WorkflowModel => {
  const {
    processDefinition: { process },
    layoutDefinition,
  } = workflowDefinitions;
  const elementsPositions = layoutDefinition.layout.planes?.[0].elements;

  const { labelNodes, edges } = convertEdges({ process, workflowState, elementsPositions });
  const nodes = addNodesPassedState(
    [
      ...convertStartNodes({ startNodes: process.start, workflowState, elementsPositions }),
      ...convertEndNodes({ endNodes: process.end, elementsPositions }),
      ...convertTaskNodes({
        taskNodes: process.tasks,
        workflowState,
        servicesThumbnails,
        elementsPositions,
      }),
      ...convertGatewayNodes({ gatewayNodes: process.gateways, workflowState, elementsPositions }),
      ...labelNodes,
    ],
    process.flow,
    workflowState
  );

  return {
    nodes,
    edges,
  };
};
