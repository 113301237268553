import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

type DashboardContextType = {
  isDemo: boolean;
  isNewAccount: boolean;
  isDemoVisible: boolean;
  toggleDemo: () => void;
};

const DEMO_VISIBLE_KEY = 'isIWFDashboardDemoVisible';
const NEW_ACCOUNT_DAYS_THRESHOLD = 33;

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const DashboardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accountId, accountType, accountDetails } = useAccountContext();
  const isDemo = accountType === 'DEMO';
  const isNewAccount = useMemo(() => {
    if (!accountDetails?.createdAt) return false;
    return !isDemo && dayjs().diff(dayjs(accountDetails.createdAt), 'day') < NEW_ACCOUNT_DAYS_THRESHOLD;
  }, [accountDetails, isDemo]);

  const demoVisibleKey = `${DEMO_VISIBLE_KEY}-${accountId}`;

  const [isDemoVisible, setDemoVisible] = useState<boolean>(() => {
    const cookieValue = Cookies.get(demoVisibleKey);
    return cookieValue ? JSON.parse(cookieValue) : isDemo || isNewAccount;
  });

  useEffect(() => {
    if (isDemo || isNewAccount) {
      try {
        Cookies.set(demoVisibleKey, JSON.stringify(isDemoVisible), { expires: NEW_ACCOUNT_DAYS_THRESHOLD });
      } catch (error) {
        console.error(`Error storing demo visibility state:`, error);
      }
    } else {
      Cookies.remove(demoVisibleKey);
    }
  }, [demoVisibleKey, isDemo, isDemoVisible, isNewAccount]);

  const toggleDemo = () => setDemoVisible((prev: boolean) => !prev);

  return (
    <DashboardContext.Provider value={{ isDemo, isNewAccount, isDemoVisible, toggleDemo }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = (): DashboardContextType => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }
  return context;
};
