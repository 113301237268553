import useDashboardWidgetData from '../../useDashboardWidgetData';
import { useMemo } from 'react';
import { Widget } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { LoadableData } from '../../types';

type Stats = {
  rejectedRequests: number;
  succeededRequests: number;
  totalRequests: number;
};

export type CompletedRequestsByOutcomeData = LoadableData<{
  rejected: number;
  succeeded: number;
  total: number;
}> & {
  isEmpty: boolean;
};

const calculateData = ({ widgetData }: { widgetData: Widget | undefined }) => {
  const dashboardStats = widgetData?.stats as Stats;

  return {
    rejected: dashboardStats?.rejectedRequests || 0,
    succeeded: dashboardStats?.succeededRequests || 0,
    total: dashboardStats?.totalRequests || 0,
  };
};

const useCompletedRequestsByOutcomeData = (): CompletedRequestsByOutcomeData => {
  const { data: widgetData, error, isFetching } = useDashboardWidgetData('CompletedRequestsStats');

  const memoizedData = useMemo(() => calculateData({ widgetData }), [widgetData]);

  return {
    data: memoizedData,
    error,
    isFetching,
    isEmpty: !memoizedData.rejected && !memoizedData.succeeded && !memoizedData.total,
  };
};

export default useCompletedRequestsByOutcomeData;
