import { UseUserSettingsReturn } from '../../../hooks/useUserSettings';
import { NotificationsSettings, TimeThreshold } from './types';
import useUserAccountRoles from '../../../hooks/useUserAccountRoles';

export type UseNotificationsSettingsParams = {
  userSettingsHook: UseUserSettingsReturn;
  userId: string;
};

export type UseNotificationsSettingsReturn = {
  isLoading: boolean;
  notificationsSettings?: NotificationsSettings;
  saveNotificationsSettings: (notificationsSettings: NotificationsSettings) => Promise<void>;
  userCanReceiveAllContractsNotifications: boolean;
};

const DEFAULT_CONTRACT_RENEWAL_TIME_THRESHOLD: TimeThreshold = 'P3M';
const DEFAULT_NOTIFICATIONS_SETTINGS: NotificationsSettings = {
  newContractsAdded: { scope: 'NOTHING' },
  pipelineUpdates: { scope: 'NOTHING' },
  proposalsAwaitingApproval: { scope: 'NOTHING' },
  contractRenewal: { scope: 'NOTHING', timeThreshold: DEFAULT_CONTRACT_RENEWAL_TIME_THRESHOLD },
  nonSaasContractRenewal: { scope: 'NOTHING', timeThreshold: DEFAULT_CONTRACT_RENEWAL_TIME_THRESHOLD },
};

const useNotificationsSettings = ({
  userSettingsHook,
  userId,
}: UseNotificationsSettingsParams): UseNotificationsSettingsReturn => {
  const { isLoading: areRolesLoading, isUserAdmin, isUserPowerUser } = useUserAccountRoles({ userId });

  const saveSettings = async (notificationsSettings: NotificationsSettings) => {
    if (userSettingsHook.isLoading) return;
    return userSettingsHook.saveSettings({ notificationsSettings }, true);
  };

  return {
    isLoading: userSettingsHook.isLoading || areRolesLoading,
    notificationsSettings: userSettingsHook.settings?.notificationsSettings ?? DEFAULT_NOTIFICATIONS_SETTINGS,
    saveNotificationsSettings: saveSettings,
    userCanReceiveAllContractsNotifications: isUserPowerUser || isUserAdmin,
  };
};

export default useNotificationsSettings;
