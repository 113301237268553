import { FC, ReactNode } from 'react';
import { Stack, styled } from '@mui/material';

import { Text, testProps } from '@verticeone/design-system';

import { Thumbnail } from './Thumbnail';
import { createServiceIdentifier } from '../../../../../hooks/workflows/refUtils';
import type { ServiceCatalogResource } from '../../../catalogResource/types';

type ServiceCardContentProps = {
  actions: ReactNode;
  service: ServiceCatalogResource;
};

const getServiceTestId = (service: ServiceCatalogResource) =>
  createServiceIdentifier(service.urn).replace(/^(.*)-v\d$/, 'card-$1');

const CardActions = styled(Stack)({
  height: 0,
  opacity: 0,
  paddingTop: 0,
  transition: 'ease-in-out 300ms all',
});

const StyledServiceCardContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  paddingBottom: 50,
  borderRadius: 16,
  border: '1px solid',
  borderColor: theme.palette.core.color2,
  background: theme.palette.background.default,
  '&:hover': {
    transition: 'ease-in-out 300ms all',
    boxShadow: theme.palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '80' }),
  },
  [`&:hover ${CardActions}`]: {
    height: 42,
    opacity: 1,
    paddingTop: 8,
  },
}));

const CardOverlay = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: theme.palette.background.default,
  boxShadow: `0 0 60px 60px ${theme.palette.background.default}`,
}));

export const ServiceCardContent: FC<ServiceCardContentProps> = ({ service, actions }) => {
  const serviceDefinition = service.definition;

  return (
    <StyledServiceCardContent {...testProps(getServiceTestId(service))}>
      <Thumbnail thumbnail={serviceDefinition.Thumbnail} />
      <CardOverlay padding={4}>
        <Stack padding={2}>
          <Text variant="heading" size="XXS" color="primary2">
            {/*reserve the space even if empty*/}
            {serviceDefinition.Category || '\u200b'}
          </Text>
          <Text variant="heading" size="XS" color="text1">
            {service.name}
          </Text>
        </Stack>
        {!!actions && <CardActions>{actions}</CardActions>}
      </CardOverlay>
    </StyledServiceCardContent>
  );
};
