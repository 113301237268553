import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { DataGrid, NoData } from '@verticeone/design-system';

import { useFlatServiceWorkflowsWithAllVersions } from '../../hooks/useFlatServiceWorkflowsWithAllVersions';
import { useWorkflowColumns, useWorkflowGroupingColumn } from './useWorkflowColumns';
import { parseWorkflowRef } from '../../../../../hooks/workflows/refUtils';
import { WorkflowPreviewDialog } from '../../../workflow/WorkflowDetailPage/components/WorkflowPreviewDialog';
import type { WorkflowVersionRow } from './types';
import { getIsWorkflowLive, isAutoGenerated } from './utils';
import { GridGroupNode, useGridApiRef } from '@mui/x-data-grid-pro';
import { gridClasses } from '@mui/x-data-grid-pro';
import { getIsDefaultWorkflow } from '../../hooks/utils';
import type { ServiceCatalogResource } from '../../../catalogResource/types';
import { WorkflowsTableContextType, WorkflowsTableProvider } from './WorkflowsTableContext';

type WorkflowsTableProps = {
  service: ServiceCatalogResource;
  showArchived?: boolean;
};

const ROW_HEIGHT = 60;
const GROUPING_ROW_HEIGHT = 38;
const LOADING_ROW_COUNT = 8;

const DataGridWithStyledRow = styled(DataGrid<WorkflowVersionRow>)(({ theme }) => ({
  '& .row-with-primary-background': {
    backgroundColor: theme.palette.primary.color4,
    '&:hover': {
      backgroundColor: theme.palette.primary.color3,
    },
  },
  [`& .${gridClasses['cell--withRightBorder']}`]: {
    borderRight: 'none',
  },
  '& .group-row': {
    backgroundColor: theme.palette.common.white,
  },
  '& .clickable': {
    cursor: 'pointer',
  },
  '& .row': {
    backgroundColor: theme.palette.neutral.color4,
    '&:hover': {
      backgroundColor: theme.palette.neutral.color3,
    },
  },
}));

const NoWorkflowsData = () => {
  const { t } = useTranslation();
  return <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.NO_WORKFLOWS`)} />;
};

const getRowId = ({ workflowName, versionId }: Pick<WorkflowVersionRow, 'workflowName' | 'versionId'>) =>
  `${workflowName}-${versionId}`;

const getTreeDataPath = (row: WorkflowVersionRow) => [row.workflowName, getRowId(row)];

export const WorkflowsTable: FC<WorkflowsTableProps> = ({ service, showArchived = false }) => {
  const [workflowForPreview, setWorkflowForPreview] = useState<{ workflowId: string; versionId: string }>();
  const [lastEditedWorkflowVersionId, setLastEditedWorkflowVersionId] = useState<string>();

  const apiRef = useGridApiRef();

  const handleCompleted = useCallback(() => {
    setLastEditedWorkflowVersionId(undefined);
  }, []);

  const { workflowsWithVersions, isLoading, isFetching } = useFlatServiceWorkflowsWithAllVersions({
    service,
    showArchived,
    onCompleted: handleCompleted,
  });

  const defaultWorkflowRef = service.definition.Service?.ServiceProvider?.HandlerUrn;
  const defaultWorkflowId = parseWorkflowRef(defaultWorkflowRef)?.workflowId;

  const workflowsWithVersionsRef = useRef(workflowsWithVersions);

  const defaultWorkflow = workflowsWithVersions.find((w) => getIsDefaultWorkflow(w.workflowRef, defaultWorkflowId));
  const defaultWorkflowName = defaultWorkflow?.workflowName;

  const columns = useWorkflowColumns(defaultWorkflowName || '');
  const groupingColumn = useWorkflowGroupingColumn(columns);

  const getIsGroupExpandedByDefault = useCallback(
    (node: GridGroupNode) => {
      return node.groupingKey === defaultWorkflowName;
    },
    [defaultWorkflowName]
  );

  // Explanation: https://github.com/mui/mui-x/issues/7771#issuecomment-1920224215
  useEffect(() => {
    const curRows = apiRef.current.getRowModels();
    const curIds = curRows.keys();
    const newIds = new Set(workflowsWithVersions.map(getRowId));
    const removeIds = [...curIds].filter((id) => !newIds.has(id.toString()));

    // Create delete actions for the removed IDs and merge them with the new data
    // MUI DataGrid does NOT track this automatically
    const deleteActions = removeIds.map((id) => ({ ...curRows.get(id), _action: 'delete' as const }));
    apiRef.current.updateRows([...workflowsWithVersions, ...deleteActions]);
  }, [apiRef, workflowsWithVersions]);

  const workflowsTableProviderValue: WorkflowsTableContextType = useMemo(() => {
    const liveWorkflowVersion = workflowsWithVersions.find(getIsWorkflowLive);

    return {
      liveWorkflowVersion,
      updatingWorkflowVersionId: isFetching ? lastEditedWorkflowVersionId : undefined,
      setLastEditedWorkflowVersionId,
    };
  }, [workflowsWithVersions, isFetching, setLastEditedWorkflowVersionId, lastEditedWorkflowVersionId]);

  return (
    <WorkflowsTableProvider value={workflowsTableProviderValue}>
      <DataGridWithStyledRow
        treeData
        getTreeDataPath={getTreeDataPath}
        columns={columns}
        groupingColDef={groupingColumn}
        rows={workflowsWithVersionsRef.current}
        isGroupExpandedByDefault={getIsGroupExpandedByDefault}
        headerSize="S"
        loadingStyle="skeleton"
        loading={isLoading}
        disableRowSelectionOnClick
        disableColumnResize
        sortingMode="client"
        autoHeight={workflowsWithVersions.length < 1}
        onRowClick={({ row, id }, e) => {
          if (isAutoGenerated(id.toString())) {
            const rowNode = apiRef.current.getRowNode(id.toString());
            const isExpanded = rowNode && 'childrenExpanded' in rowNode ? rowNode.childrenExpanded : false;

            apiRef.current.setRowChildrenExpansion(id, !isExpanded);
            apiRef.current.setCellFocus(id, 'workflowName');
            e.stopPropagation();
            return;
          }

          const workflowId = parseWorkflowRef(row.workflowRef)?.workflowId;
          if (workflowId) {
            setWorkflowForPreview({ workflowId, versionId: row.versionId });
          }
        }}
        getRowId={getRowId}
        loadingRowCount={LOADING_ROW_COUNT}
        getRowHeight={({ id }) => (isAutoGenerated(id.toString()) ? GROUPING_ROW_HEIGHT : ROW_HEIGHT)}
        noRowsOverlayHeight={ROW_HEIGHT * LOADING_ROW_COUNT}
        apiRef={apiRef}
        getRowClassName={({ row, id }) => {
          if (isLoading) return '';

          return getIsWorkflowLive(row)
            ? 'row-with-primary-background clickable'
            : isAutoGenerated(id.toString())
            ? 'group-row clickable'
            : 'clickable row';
        }}
        slots={{
          noRowsOverlay: NoWorkflowsData,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastEdited', sort: 'desc' }],
          },
        }}
      />
      {workflowForPreview ? (
        <WorkflowPreviewDialog
          serviceRef={service.urn}
          workflowId={workflowForPreview.workflowId}
          versionId={workflowForPreview.versionId}
          onClose={() => setWorkflowForPreview(undefined)}
        />
      ) : null}
    </WorkflowsTableProvider>
  );
};

export const MemoizedWorkflowsTable = memo(WorkflowsTable);
