import { useTranslation } from 'react-i18next';
import { Filter } from '../types';
import { GenericMultiselectFilter } from './GenericMultiselectFilter';
import useBillingFrequencyOptions from '../../../../hooks/useBillingFrequencyOptions';

const BillingFrequencyFilter = () => {
  const { t } = useTranslation();
  const { options } = useBillingFrequencyOptions();

  return (
    <GenericMultiselectFilter
      name={Filter.BILLING_FREQUENCY}
      options={options}
      placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.BILLING_FREQUENCY')}
      testId={'billing-frequency-filter'}
    />
  );
};

export default BillingFrequencyFilter;
