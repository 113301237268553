import { createRequiredContext } from '@verticeone/design-system';
import { useMemo, useState } from 'react';
import type { Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';

export type LocaleContextData = {
  locale: string;
  setLocale: (locale: string) => void;
  dateFnsLocale: Locale;
};

export const { LocaleContextProvider, useLocaleContext } = createRequiredContext<LocaleContextData, 'Locale'>('Locale');

const DEFAULT_LOCALE = 'en-US';

const LOCALE_TO_DATE_FNS_LOCALE: Record<string, Locale> = {
  'en-US': enUS,
};

/** Prepares LocaleContextData */
export const useLocale = (): LocaleContextData => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  return useMemo(
    () => ({
      locale,
      setLocale,
      dateFnsLocale: LOCALE_TO_DATE_FNS_LOCALE[locale] ?? enUS,
    }),
    [locale, setLocale]
  );
};
