import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Stack, styled } from '@mui/material';
import { forwardRef } from 'react';

import { IconButton, ifExpression } from '@verticeone/design-system';

const RichSelectorWrapper = styled(Stack)<{ $disabled: boolean }>(({ theme, $disabled }) => ({
  border: `1px solid ${theme.palette.core.color3}`,
  '&:hover, &:focus-within': {
    borderColor: $disabled ? theme.palette.core.color3 : theme.palette.info.hover.color1,
  },
  borderRadius: theme.spacing(1),
  outline: 'none',
  padding: '2px 24px 2px 8px',
  position: 'relative',
  fontSize: '0.875rem',
  color: $disabled ? theme.palette.text.color4 : theme.palette.text.color1,
  ...ifExpression($disabled, {
    userSelect: 'none',
  }),
}));

const OpenDialogButton = styled(IconButton)({
  position: 'absolute',
  top: '-2px',
  right: 0,
});

type RichSelectorProps = {
  value: string;
  onChange?: (value: string) => void;
  onOpenInNewClick?: () => void;
  disabled?: boolean;
};

export const RichSelector = forwardRef<HTMLDivElement, RichSelectorProps>(
  ({ value, onChange, onOpenInNewClick, disabled = false }, ref) => {
    return (
      <RichSelectorWrapper $disabled={disabled}>
        {onOpenInNewClick && (
          <OpenDialogButton icon={OpenInNewIcon} variant="plain" size="S" onClick={onOpenInNewClick} />
        )}
        <Box
          ref={ref}
          contentEditable={!disabled}
          dangerouslySetInnerHTML={{ __html: value }}
          onBlur={(e) => onChange?.(e.currentTarget.innerHTML)}
          sx={{ outline: 0, whiteSpace: 'pre-line' }}
        />
      </RichSelectorWrapper>
    );
  }
);
