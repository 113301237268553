import React, { ForwardedRef, forwardRef } from 'react';
import { StatCardProps } from './types';
import BaseStatCard from './BaseStatCard';
import { Placeholder } from '@verticeone/design-system';

const StatCard = forwardRef(({ isLoading = false, ...props }: StatCardProps, ref: ForwardedRef<HTMLDivElement>) => {
  if (isLoading) {
    return (
      <BaseStatCard
        testId={`${props.testId}-skeleton`}
        title={<Placeholder variant="text" width="50%" height={19} />}
        subtitle={<Placeholder variant="text" width="80%" height={12} />}
        value={<Placeholder variant="text" width="64px" height={29} />}
        name={<Placeholder variant="text" width="100%" height={14} />}
      />
    );
  }

  return <BaseStatCard {...props} ref={ref} />;
});

export default StatCard;
