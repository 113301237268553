import { useTranslation } from 'react-i18next';
import { ApprovalTwoTone } from '@mui/icons-material';
import { useTasksCount } from '@vertice/core/src/modules/intelligentWorkflows/task/dataSource/useTasksCount';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';

import NavigationItem from '../../../../../layouts/Main/Sidebar/components/NavigationItem';
import useCfaRoutes from '../../../../../router/routes';

const formatTasksCount = (count: number) => (count > 99 ? '99+' : count.toString());

export const TasksNavbarItem = () => {
  const { t } = useTranslation();
  const routes = useCfaRoutes();

  const { data: tasksCount } = useTasksCount({ allTasks: false, status: 'ACTIVE' });
  const tasksCountFormatted = tasksCount ? formatTasksCount(tasksCount) : undefined;

  return (
    <NavigationItem
      to={routes.INTELLIGENT_WORKFLOWS.TASKS.ABSOLUTE_PATH}
      icon={ApprovalTwoTone}
      label={t('INTELLIGENT_WORKFLOWS.NAVBAR.TASKS')}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      notificationContent={tasksCountFormatted}
      notificationTestId="tasks-navbar-item-notification"
    />
  );
};
