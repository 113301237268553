import React, { useMemo } from 'react';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import { keyBy } from 'lodash';
import { ContractFormData } from '../../types';
import { useDepartmentOptions } from '@vertice/core/src/modules/departments/FormDepartmentSelectField/useDepartmentOptions';
import {
  FormDepartmentSelectField,
  FormDepartmentSelectFieldType,
} from '@vertice/core/src/modules/departments/FormDepartmentSelectField/FormDepartmentSelectField';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormDepartmentSelectFieldType<ContractFormData>;

const DepartmentFormEntry = (options: ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>) => {
  const departmentOptionsQuery = useDepartmentOptions();
  const departments = departmentOptionsQuery.isLoading ? undefined : departmentOptionsQuery.data;
  const departmentsById = useMemo(() => keyBy(departments, 'departmentId'), [departments]);

  return (
    <ContractFormEntry<ReadComponent, WriteComponent>
      {...options}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? departmentsById[value]?.name : '-'),
        ...options.componentProps,
      }}
      writeComponent={FormDepartmentSelectField}
      writeComponentProps={{ ...options.writeComponentProps, isClearable: false }}
    />
  );
};

export default DepartmentFormEntry;
