import {
  BalanceTwoTone,
  CheckTwoTone,
  DriveFileRenameOutlineTwoTone,
  FactCheckTwoTone,
  ListAlt,
  LowPriorityTwoTone,
  MonetizationOnTwoTone,
  QuizOutlined,
  SecurityTwoTone,
  SubdirectoryArrowRightTwoTone,
  SvgIconComponent,
  TaskTwoTone,
  ThumbUpTwoTone,
} from '@mui/icons-material';
import { Stack, styled, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VerticeVOutlineIcon } from '@vertice/assets';
import { TaskStatus } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { DesignSystemSize, IconWrapper, RelationshipIcon } from '@verticeone/design-system';
import { TasksThumbnail } from '../../definitionsTypes';
import { TaskColorVariant } from '../../workflowSchema/WorkflowRenderer/NodeComponents/TaskNode/types';
import { useIconColors } from './getIconColors';
import { isAfter } from 'date-fns';

type TaskStyle = {
  Icon?: SvgIconComponent | FC<React.SVGProps<SVGSVGElement>>;
  colorVariant: TaskColorVariant;
  withShadow?: boolean;
  withColoredBorder?: boolean;
  invertedColors?: boolean;
  invertedHeaderColors?: boolean;
  invertedIconColors?: boolean;
};

export const useTaskStyle = ({
  userId,
  status,
  thumbnail,
  type,
  assignees,
  taskDueDate,
}: {
  userId: string;
  status?: TaskStatus;
  assignees?: string[];
  type?: string;
  thumbnail?: TasksThumbnail;
  taskDueDate?: string;
}): TaskStyle => {
  const isAssignedToCurrentUser = assignees?.includes(userId) ?? false;
  const isOverdue = taskDueDate ? isAfter(new Date(), new Date(taskDueDate)) : false;

  switch (type) {
    case 'VERTICE_SERVICE':
      return getTaskStyleForVerticeServiceTask(status, thumbnail);
    case 'SERVICE':
      return getTaskStyleForUserServiceTask(status, thumbnail);
    default:
      return getTaskStyleForUserTask(isAssignedToCurrentUser, status, thumbnail, isOverdue);
  }
};

const resolvePredefinedTaskIcon = (id: string) => {
  switch (id) {
    case 'negotiation':
      return MonetizationOnTwoTone;
    case 'approval':
      return ThumbUpTwoTone;
    case 'legal-approval':
      return BalanceTwoTone;
    case 'approval-it-security':
      return SecurityTwoTone;
    case 'contracting':
      return DriveFileRenameOutlineTwoTone;
    case 'form':
      return FactCheckTwoTone;
    case 'triage':
      return LowPriorityTwoTone;
    case 'list':
      return ListAlt;
    case 'relationship':
      return RelationshipIcon;
    case 'assessment':
      return QuizOutlined;
    default:
      return TaskTwoTone;
  }
};

const resolveUserTaskIcon = ({ id, type }: TasksThumbnail) => {
  switch (type) {
    case 'PREDEFINED':
      return resolvePredefinedTaskIcon(id);
    default:
      return TaskTwoTone;
  }
};

const getTaskStyleForUserTask = (
  isAssignedToCurrentUser?: boolean,
  status?: TaskStatus,
  thumbnail?: TasksThumbnail,
  isOverDue?: boolean
): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
        withColoredBorder: true,
        invertedIconColors: true,
      };
    case 'ACTIVE':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: isOverDue ? 'error' : isAssignedToCurrentUser ? 'secondary' : 'neutral',
        withShadow: true,
        withColoredBorder: true,
        invertedHeaderColors: !!isAssignedToCurrentUser,
        invertedIconColors: true,
      };
    case 'FAILED':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedHeaderColors: !!isAssignedToCurrentUser,
        invertedIconColors: true,
      };
    default:
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'neutral',
      };
  }
};

const getTaskStyleForVerticeServiceTask = (status?: TaskStatus, thumbnail?: TasksThumbnail): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
        withColoredBorder: true,
        invertedIconColors: true,
        withShadow: true,
      };
    case 'ACTIVE':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'primary',
        withShadow: true,
        withColoredBorder: true,
        invertedIconColors: true,
      };
    case 'FAILED':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedIconColors: true,
        invertedHeaderColors: true,
        invertedColors: false,
      };
    default:
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'primary',
      };
  }
};

const getTaskStyleForUserServiceTask = (status?: TaskStatus, thumbnail?: TasksThumbnail): TaskStyle => {
  switch (status) {
    case 'COMPLETED':
      return {
        Icon: CheckTwoTone,
        colorVariant: 'success',
      };
    case 'ACTIVE':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'neutral',
        withShadow: true,
        withColoredBorder: true,
        invertedIconColors: true,
      };
    case 'FAILED':
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'error',
        withShadow: true,
        withColoredBorder: true,
        invertedIconColors: true,
        invertedHeaderColors: true,
        invertedColors: false,
      };
    default:
      return {
        Icon: resolveUserTaskIcon({ ...thumbnail! }),
        colorVariant: 'neutral',
      };
  }
};

const sizes: Record<DesignSystemSize, string> = {
  XL: '40px',
  L: '36px',
  M: '32px',
  S: '28px',
  XS: '24px',
  XXS: '20px',
};

const StyledTaskIcon = styled(Stack)<{
  $iconColor: string;
  $iconBgColor: string;
  $size: DesignSystemSize;
  $withRelativePosition?: boolean;
}>(({ theme, $iconBgColor, $iconColor, $withRelativePosition, $size }) => ({
  color: $iconColor,
  backgroundColor: $iconBgColor,
  borderRadius: 4,
  width: sizes[$size],
  height: sizes[$size],
  position: $withRelativePosition ? 'relative' : 'static',
  padding: 4,
  '& > svg:first-of-type': {
    width: '100%',
    height: '100%',
  },
}));

const FloatingVerticeIcon = styled(VerticeVOutlineIcon)<{
  $colorVariant: TaskColorVariant;
  $invertedColors?: boolean;
}>(({ theme, $colorVariant, $invertedColors }) => ({
  position: 'absolute',
  top: '90%',
  left: '90%',
  transform: 'translate(-50%, -50%)',
  width: '16px',
  height: '16px',
  color: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
}));

const StyledSubProcessIcon = styled(IconWrapper)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(-30%, -30%)',
  color: theme.palette.core.bg,
  backgroundColor: theme.palette.secondary.color2,
  borderRadius: 5,
  border: `2px solid ${theme.palette.core.bg}`,
}));

type TaskIconProps = {
  userId: string;
  status?: TaskStatus;
  taskType?: string;
  type?: string;
  id?: string;
  assigneeIds?: string[];
  size?: DesignSystemSize;
  sx?: SxProps<Theme>;
  withSubProcessIcon?: boolean;
  overrideColorVariant?: TaskColorVariant;
  taskDueDate?: string;
};

export const TaskIcon: FC<TaskIconProps> = ({
  userId,
  status,
  assigneeIds,
  taskType,
  type = '',
  id = '',
  size = 'M',
  sx,
  withSubProcessIcon = false,
  overrideColorVariant,
  taskDueDate,
}) => {
  const { Icon, colorVariant, invertedHeaderColors, invertedIconColors } = useTaskStyle({
    userId,
    status,
    thumbnail: {
      id,
      type,
    },
    type: taskType,
    assignees: assigneeIds,
    taskDueDate,
  });
  const { iconBgColor, iconColor } = useIconColors(
    overrideColorVariant ?? colorVariant,
    invertedIconColors ?? !!overrideColorVariant ?? false
  );

  return (
    <StyledTaskIcon
      $iconColor={iconColor}
      $iconBgColor={iconBgColor}
      $size={size}
      $withRelativePosition={taskType === 'VERTICE_SERVICE' || withSubProcessIcon}
      sx={sx}
    >
      {Icon && <Icon />}
      {taskType === 'VERTICE_SERVICE' && (
        <FloatingVerticeIcon $colorVariant={colorVariant} $invertedColors={invertedHeaderColors} />
      )}
      {withSubProcessIcon && <StyledSubProcessIcon size="XL" icon={SubdirectoryArrowRightTwoTone} />}
    </StyledTaskIcon>
  );
};
