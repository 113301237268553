import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProcuredSpendGraph from './ProcuredSpendGraph';
import dayjs from 'dayjs';
import { ChartCard, ChartProvider, useChartContext, ChartStats, ChartWrapper } from '../ChartCard';
import { ifExpression } from '@verticeone/utils/logic';
import { useTheme } from '@mui/material';
import useProcuredSpendData, { ProcuredSpendData } from './useProcuredSpendData';
import { Placeholder } from '@verticeone/design-system';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { getTooltipValue } from '../ChartCard/utils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { FilterAltOutlined, MonetizationOnOutlined } from '@mui/icons-material';
import { EmptyState } from '../../types';

type ProcuredSpendCardProps = EmptyState & {
  data: ProcuredSpendData['data'];
};

const ProcuredSpendCard = ({ data, isEmpty, isFilteredEmpty }: ProcuredSpendCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { months, tooltipData, resetRange } = useChartContext();
  const { palette } = useTheme();
  const formatCurrency = useFormatCurrency();

  const newPurchase = getTooltipValue(tooltipData?.values.new_purchase, data.stats.totalCostOfNewPurchases);
  const renewal = getTooltipValue(tooltipData?.values.renewal, data.stats.totalCostOfRenewals);
  const total = getTooltipValue(tooltipData?.total, data.stats.total);

  const newPurchaseLabel = t('PROCURED_SPEND.LEGEND.NEW_SPEND');
  const renewalLabel = t('PROCURED_SPEND.LEGEND.RENEWED_SPEND');

  const series = useMemo(() => {
    return data.values.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'new_purchase', {
          name: newPurchaseLabel,
          color: palette.visualization.divergent.primary['-50'],
          type: 'column',
        }),
        ...ifExpression(item.id === 'renewal', {
          name: renewalLabel,
          color: palette.visualization.divergent.primary['-20'],
          type: 'column',
        }),
      };
    }) as SeriesOptionsWithData[];
  }, [data.values, newPurchaseLabel, palette.visualization.divergent.primary, renewalLabel]);

  return (
    <ChartCard
      title={t('PROCURED_SPEND.TITLE')}
      subtitle={
        tooltipData?.category ? dayjs(tooltipData?.category).format('MMM, YYYY') : t('LAST_X_MONTHS', { count: months })
      }
      stats={
        <ChartStats
          total={total}
          totalLabel={t('PROCURED_SPEND.TOTAL')}
          valueFormatter={(v: number) => formatCurrency(v, { currency: data.currency, maximumFractionDigits: 2 })}
          items={[
            {
              id: 'new_purchase',
              name: newPurchaseLabel,
              value: newPurchase,
              valueFormatter: (v: number) => formatCurrency(v, { currency: data.currency, maximumFractionDigits: 2 }),
              color: palette.visualization.divergent.primary['-50'],
            },
            {
              id: 'renewal',
              name: renewalLabel,
              value: renewal,
              valueFormatter: (v: number) => formatCurrency(v, { currency: data.currency, maximumFractionDigits: 2 }),
              color: palette.visualization.divergent.primary['-20'],
            },
          ]}
        />
      }
      testId="procured-spend"
    >
      <ChartWrapper
        isEmpty={isEmpty}
        isFilteredEmpty={isFilteredEmpty}
        testId="procured-spend"
        noFilteredData={{ icon: FilterAltOutlined, onClick: resetRange }}
        noData={{ icon: MonetizationOnOutlined }}
        tKeyPrefix="INTELLIGENT_WORKFLOWS.DASHBOARD.PROCURED_SPEND"
      >
        <ProcuredSpendGraph
          series={series}
          categories={data.categories}
          currency={data.currency}
          isEmpty={isEmpty || isFilteredEmpty}
        />
      </ChartWrapper>
    </ChartCard>
  );
};

const LoadableProcuredSpendCard = () => {
  const { data, isFetching, isEmpty, isFilteredEmpty } = useProcuredSpendData();

  if (isFetching) {
    return (
      <ChartCard.Skeleton stats={<ChartStats.Skeleton />} testId="procured-spend-skeleton">
        <Placeholder variant="rounded" width="100%" height="360px" />
      </ChartCard.Skeleton>
    );
  }

  return <ProcuredSpendCard data={data} isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty} />;
};

const ProcuredSpendCardWithContext = () => {
  const initialMonths = 6;
  const endDate = dayjs().subtract(1, 'months').startOf('month');
  const startDate = endDate.subtract(initialMonths - 1, 'months').startOf('month');

  return (
    <ChartProvider
      defaultRange={{
        startDate,
        endDate,
      }}
    >
      <LoadableProcuredSpendCard />
    </ChartProvider>
  );
};

export default ProcuredSpendCardWithContext;
