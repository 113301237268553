import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VisoTrustIcon } from '@vertice/assets';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  VISOTRUST_CONNECTION_ID,
  VISOTRUST_PROVIDER_ID,
} from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import { useCheckConnectionQuery } from '@vertice/slices';
import { IconWrapper, Loader } from '@verticeone/design-system';
import { IntegrationBreadcrumbIcon } from '../../../components/IconWrappers';
import { IntegrationSetup } from '../../../components/IntegrationSetup';
import { DisconnectButton } from './components/DisconnectButton';
import { StepContent } from './components/StepContent';
import { VisoTrustSetupStep } from './types';

export const VisoTrustSetup = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data: connection, isLoading: isConnectionLoading } = useCheckConnectionQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
    connectionId: VISOTRUST_CONNECTION_ID,
  });

  const [step, setStep] = useState<VisoTrustSetupStep>(VisoTrustSetupStep.CONNECTION);
  const handleSetStep = (newStep: VisoTrustSetupStep) => {
    setStep(newStep);
  };

  const activeStep = useMemo(() => {
    if (step === VisoTrustSetupStep.CONNECTION && connection?.status === 'PASSED') {
      return VisoTrustSetupStep.SUCCESS;
    }
    return step;
  }, [connection?.status, step]);

  if (isConnectionLoading) {
    return (
      <Box sx={{ height: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <IntegrationSetup
      breadcrumb={{
        label: t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.TITLE'),
        icon: (
          <IntegrationBreadcrumbIcon>
            <IconWrapper icon={VisoTrustIcon} />
          </IntegrationBreadcrumbIcon>
        ),
      }}
      actionButton={<DisconnectButton activeStep={activeStep} setStep={handleSetStep} />}
      stepContent={<StepContent activeStep={activeStep} setStep={handleSetStep} />}
    />
  );
};
