import { useWatch } from 'react-hook-form';
import { FormControl, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert } from '@verticeone/design-system';
import { useFormatCurrency } from '@verticeone/utils/formatting';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../constants';
import FormChecklistItemField from '../../../../../../../forms/fields/FormChecklistItemField';
import { useTaskFormContext } from '../TaskFormContext';
import { MoneyFormEntry } from '../formFields/MoneyFormEntry';
import { CurrencyFormEntry } from '../formFields/CurrencyFormEntry';
import type { FormData as PurchaseRGFormData } from '../../RequirementsGatheringForm/schema';
import type { FormData as RenewalRGFormData } from '../../RenewalRequirementsForm/schema';

type FormData = PurchaseRGFormData | RenewalRGFormData;

export const ApprovedBudgetSection = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.LABELS',
  });

  const formatCurrency = useFormatCurrency();
  const { readOnly } = useTaskFormContext();

  const approvedBudget = useWatch<FormData, 'approvedBudget'>({ name: 'approvedBudget' });
  const unknownBudget = useWatch<FormData, 'unknownBudget'>({ name: 'unknownBudget' });
  const contractCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });

  const { subTitle, title } = unknownBudget
    ? { title: t('UNKNOWN_BUDGET_TITLE'), subTitle: t('UNKNOWN_BUDGET_SUBTITLE') }
    : {
        title: t('ZERO_BUDGET_TITLE', {
          money: contractCurrency ? formatCurrency(0, { currency: contractCurrency, maximumFractionDigits: 0 }) : '0',
        }),
        subTitle: t('ZERO_BUDGET_SUBTITLE'),
      };

  const isAlertDisplayed = approvedBudget === 0 || unknownBudget;
  const isDisabled = readOnly || unknownBudget;

  return (
    <>
      <MoneyFormEntry<FormData>
        name="approvedBudget"
        label={t('APPROVED_BUDGET')}
        currency={contractCurrency}
        isDisabled={isDisabled}
      />
      <CurrencyFormEntry<FormData> name="contractCurrency" isDisabled={isDisabled} />
      <Stack pl={6} width="100%">
        <FormControl variant="outlined">
          <FormChecklistItemField
            name="unknownBudget"
            label={t('UNKNOWN_BUDGET')}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            size="S"
            disabled={readOnly}
            testId="unknown-budget"
          />
        </FormControl>
        {isAlertDisplayed ? (
          <Alert
            icon={InfoOutlinedIcon}
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            title={title}
            subtitle={subTitle}
            variant="ghost"
            size="S"
          />
        ) : null}
      </Stack>
    </>
  );
};
