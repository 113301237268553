import FormSelectField2, { FormSelectFieldProps } from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Department } from '../types';
import { useDepartmentOptions } from './useDepartmentOptions';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { keyBy } from 'lodash';

export type FormDepartmentSelectFieldProps<FormDataType extends FieldValues> = FormSelectFieldProps<
  FormDataType,
  Department,
  false
> & {
  onlyOwnedDepartments?: boolean;
};

export type FormDepartmentSelectFieldType<FormDataType extends FieldValues> = (
  props: FormDepartmentSelectFieldProps<FormDataType>
) => JSX.Element;

export const FormDepartmentSelectField = <FormDataType extends FieldValues = never>({
  onlyOwnedDepartments = false,
  ...otherProps
}: FormDepartmentSelectFieldProps<FormDataType>) => {
  const { t } = useTranslation();
  const departmentsQuery = useDepartmentOptions({ onlyOwnedDepartments });
  const departments = departmentsQuery.isLoading ? undefined : departmentsQuery.data;
  const departmentsById = useMemo(() => keyBy(departments, 'departmentId'), [departments]);

  return (
    <FormSelectField2<any, Department, false>
      options={departmentsQuery.isLoading ? [] : departmentsQuery.data}
      placeholder={t('ENTITIES.DEPARTMENT.SELECT_DEPARTMENT')}
      isLoading={departmentsQuery.isLoading}
      boxValue={(value) => (value ? departmentsById[value] : null)}
      unboxValue={(selectValue) => (selectValue ? selectValue.departmentId : null)}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.departmentId}
      {...otherProps}
    />
  );
};
