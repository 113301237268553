import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export const OneOffIcon: typeof SvgIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor">
      <path d="M7.236 1.846a.85.85 0 0 0-.623.263.83.83 0 0 0-.263.6v.576H4.908q-.767 0-1.33.565a1.85 1.85 0 0 0-.564 1.355v14.637q0 .768.564 1.332.563.564 1.33.564h14.158q.793 0 1.358-.564a1.82 1.82 0 0 0 .562-1.332V5.205q.001-.792-.562-1.355a1.85 1.85 0 0 0-1.358-.565h-1.439v-.553a.87.87 0 0 0-.264-.61.83.83 0 0 0-.623-.276.88.88 0 0 0-.648.263.83.83 0 0 0-.264.6v.576h-7.68v-.553a.87.87 0 0 0-.263-.61.86.86 0 0 0-.649-.276m-2.328 3.36h14.158v14.636H4.908Zm5.7 2.235a.8.8 0 0 0-.596.254.85.85 0 0 0-.24.608q0 .355.24.595t.62.24h1.241v7.753q0 .33.254.57a.85.85 0 0 0 .607.24q.382 0 .61-.24a.83.83 0 0 0 .226-.596V8.303a.84.84 0 0 0-.24-.62.8.8 0 0 0-.596-.242Z" />
    </svg>
  </SvgIcon>
);

OneOffIcon.muiName = 'OneOffIcon';
