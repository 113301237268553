import { useCallback, useState } from 'react';

export type LinearFormStep = {
  id: string;
  name: string;
  visited: boolean;
  valid: boolean;
};

export type UseLinearStepperProps = {
  initStep?: string;
  formSteps: LinearFormStep[];
};

export const useLinearStepper = (props: UseLinearStepperProps) => {
  const { formSteps, initStep } = props;

  const [activeStepId, setActiveStepId] = useState<string | null>(initStep ?? formSteps.at(0)?.id ?? null);
  const [steps, setSteps] = useState<LinearFormStep[]>(formSteps);

  const setStep = useCallback(
    (code: string, state: { valid?: boolean; visited?: boolean }) => {
      setSteps((prevSteps) => prevSteps.map((step) => (step.id === code ? { ...step, ...state } : step)));
    },
    [setSteps]
  );

  const goNext = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.id === activeStepId);
    if (currentIndex === -1) return;

    const nextIndex = currentIndex + 1;
    const nextStepId = steps.at(nextIndex < steps.length ? nextIndex : currentIndex)?.id;
    if (!nextStepId) return;

    setStep(nextStepId, { visited: true });
    setActiveStepId(nextStepId);
  }, [setActiveStepId, activeStepId, steps, setStep]);

  const goPrevious = useCallback(() => {
    const currentIndex = steps.findIndex((step) => step.id === activeStepId);
    if (currentIndex === -1) return;

    const prevIndex = currentIndex - 1;
    const prevStepId = steps.at(prevIndex >= 0 ? prevIndex : currentIndex)?.id;
    if (!prevStepId) return;

    setActiveStepId(prevStepId);
  }, [setActiveStepId, activeStepId, steps]);

  const goOnStep = useCallback(
    (id: string) => {
      const stepIdExists = steps.some((step) => step.id === id);
      if (!stepIdExists) return;

      setActiveStepId(id);
    },
    [setActiveStepId, steps]
  );

  return { activeStepId, steps, goNext, goPrevious, goOnStep, setStep };
};
