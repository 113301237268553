import { parseWorkflowRef } from '../../../../hooks/workflows/refUtils';
import { WorkflowVersionRow } from '../components/WorkflowsTable/types';

export const getIsDefaultWorkflow = (workflowRef: string, defaultWorkflowId?: string) => {
  const workflowId = parseWorkflowRef(workflowRef)?.workflowId;

  if (!defaultWorkflowId || !workflowId) return false;

  return workflowId === defaultWorkflowId;
};

export const isWorkflowArchived = ({
  status,
  isWorkflowDefault,
  activeInstances,
}: Omit<WorkflowVersionRow, 'isArchived'>) => {
  if (status === 'PENDING') return false;

  if (isWorkflowDefault && status === 'ACTIVE') return false;

  return new Array<typeof status>('ARCHIVED', 'ACTIVE').includes(status) && activeInstances === 0;
};
