import { useTranslation } from 'react-i18next';
import { BreadcrumbsV2, PageHeader } from '@verticeone/design-system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { ContractLocator, FetchedContract } from '@vertice/core/src/modules/saas/contract/types';
import useFetchContract from '@vertice/core/src/modules/saas/contract/hooks/useFetchContract';
import useContract from '@vertice/core/src/modules/saas/contract/useContract';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { ContractStageChip } from '@vertice/core/src/modules/saas/contract/components/ContractStageChip';
import { SimplifiedStage } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

export type PageHeaderProps = {
  contractLocator: ContractLocator;
};

const PageHeaderContent = ({
  vendorId,
  vendorName,
  stage,
}: {
  vendorId?: string;
  vendorName?: string;
  stage?: SimplifiedStage;
}) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  return (
    <PageHeader
      breadcrumb={
        <BreadcrumbsV2<typeof RouterLink>
          items={[
            { label: t('SAAS.NAVBAR.SAAS') },
            { label: t('SAAS.NAVBAR.CONTRACTS'), component: RouterLink, to: generatePath(routes.CONTRACTS.PATH) },
            {
              label: vendorName ?? <Skeleton width="6em" />,
              startAdornment: <VendorLogo vendorId={vendorId} size={16} logoOnly />,
              endAdornment: stage ? <ContractStageChip stage={stage} size="XS" /> : undefined,
            },
          ]}
        />
      }
      color="primary"
      actions={undefined}
    />
  );
};

const PageHeaderLoaded = ({ fetchedContract }: { fetchedContract: FetchedContract }) => {
  const contractContextData = useContract('REQUIRE_FETCHED', fetchedContract.accessedVia, fetchedContract);
  const vendor = contractContextData.fetchedContract.contract.parts.contractual?.vendor;
  const stage = contractContextData.fetchedContract.contract.materialized?.simplifiedStage;
  return (
    <PageHeaderContent
      vendorId={vendor?.vendorId ?? undefined}
      vendorName={vendor?.vendorName ?? undefined}
      stage={stage}
    />
  );
};

const ContractPageHeader = ({ contractLocator }: PageHeaderProps) => {
  const { contractId } = useParams();
  const fetchContractQuery = useFetchContract(contractLocator);

  const fetchingContract =
    fetchContractQuery.isLoading || fetchContractQuery.data.contract.record.contractId !== contractId;

  return fetchingContract ? <PageHeaderContent /> : <PageHeaderLoaded fetchedContract={fetchContractQuery.data} />;
};

export default ContractPageHeader;
