import { useState } from 'react';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import {
  VISOTRUST_CONNECTION_ID,
  VISOTRUST_PROVIDER_ID,
} from '@vertice/core/src/modules/integrations/VisoTrust/constants';
import {
  useCheckConnectionQuery,
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { ConnectionFormData } from '../components/ConnectionForm';

export const useConnectIntegration = () => {
  const { accountId } = useAccountContext();

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();
  const [connectAccountIntegration] = useConnectAccountIntegrationMutation();

  const { data: connection, refetch: refetchConnection } = useCheckConnectionQuery({
    accountId,
    integrationId: VISOTRUST_PROVIDER_ID,
    connectionId: VISOTRUST_CONNECTION_ID,
  });

  const [isConnectionInProgress, setIsConnectionInProgress] = useState(false);
  const handleSetIsConnectionInProgress = (newValue: boolean) => {
    setIsConnectionInProgress(newValue);
  };

  const connectVisoTrust: GoodFormOnSubmitCallback<ConnectionFormData> = async (formData) => {
    await revokeAccountIntegration({
      accountId,
      integrationId: VISOTRUST_PROVIDER_ID,
    });

    await connectAccountIntegration({
      accountId,
      integrationId: VISOTRUST_PROVIDER_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {
            domain: formData.workspaceUrl,
            token: formData.apiToken,
          },
          connectionForm: {
            workspaceUrl: formData.workspaceUrl,
          },
        },
      },
    });

    await refetchConnection();

    setIsConnectionInProgress(true);
  };

  return {
    connectVisoTrust,
    connectionStatus: connection?.status,
    isConnectionInProgress,
    setIsConnectionInProgress: handleSetIsConnectionInProgress,
  };
};
