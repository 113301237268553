import { BuilderPlugin } from '../../../../../../../forms/dynamicForms';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useCatalogResources } from '../../../../../WorkflowViewer/useCatalogResources';
import { fieldDefsByType } from '../../../../../../../forms/dynamicForms/fields/fields';
import { getVariableTypeFromJsonSchema } from '../../../../../utils';
import { Stack } from '@mui/material';
import { TextFieldCaption } from '@verticeone/design-system';
import { VariableChip } from '../../../../VariableSelector/VariableChip';
import { PropertyDialog } from '../../../../VariableSelector/PropertyDialog';
import { z } from 'zod';
import { FieldType } from '@vertice/slices/src/openapi/codegen/catalogAPI';

type PropertyNameValue = {
  propertyName: string | null;
};

export type PropertyNameBuilderPlugin = BuilderPlugin<PropertyNameValue>;

type PropertyNameComponentProps = {
  fieldName?: string;
  fieldType: FieldType;
  setOverrideName?: (name: string) => void;
  usedPropertyNames?: string[];
};

const PropertyNameComponent = (props: PropertyNameComponentProps) => {
  const { fieldName, fieldType, setOverrideName, usedPropertyNames } = props;
  const { t } = useTranslation();
  const { plugins, ...metadata } = useWatch();
  const { xTypeCatalogResources } = useCatalogResources();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const propertyType = useMemo(() => {
    const jsonSchema = fieldDefsByType[fieldType].getJsonSchemaFromMetadata(metadata);
    return getVariableTypeFromJsonSchema({ jsonSchema, xTypeCatalogResources });
  }, [fieldType, metadata, xTypeCatalogResources]);

  return (
    <>
      <Stack gap={0.5} alignItems="start" sx={{ zIndex: 1 }}>
        <TextFieldCaption label={t('DYNAMIC_FORM_BUILDER.VARIABLE_PLUGIN.PROPERTY_LABEL')} size="XS" />
        <VariableChip
          label={fieldName ?? ''}
          typeLabel={fieldName ? propertyType.labels?.[0] : undefined}
          onClick={() => setIsDialogOpen(true)}
          placeholder={t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.NAME_PROPERTY')}
          withIcon
        />
      </Stack>
      <PropertyDialog
        isOpen={isDialogOpen}
        onChangeName={setOverrideName}
        usedPropertyNames={usedPropertyNames ?? []}
        onClose={() => setIsDialogOpen(false)}
        propertyType={propertyType}
        withBackdrop
      />
    </>
  );
};

export const usePropertyBuilderPluginDefinition = (usedPropertyNames: string[]) => {
  const [fieldName, setFieldName] = useState<string>('');
  const [overrideName, setOverrideName] = useState<string | undefined>();

  const Component = useCallback(
    (props: PropertyNameComponentProps) => (
      <PropertyNameComponent
        {...props}
        fieldName={overrideName ?? fieldName}
        setOverrideName={setOverrideName}
        usedPropertyNames={usedPropertyNames}
      />
    ),
    [overrideName, fieldName, usedPropertyNames]
  );

  const create = useCallback(
    (fieldPath?: string): PropertyNameBuilderPlugin => {
      if (fieldPath && fieldName.length === 0) {
        setFieldName(fieldPath);
      }

      return {
        defaultValues: {
          propertyName: fieldName,
        },
        schema: z.object({
          propertyName: z.string().nullable(),
        }),
        Component: Component,
      };
    },
    [fieldName, Component]
  );

  return { create, overrideName };
};
