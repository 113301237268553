import { useMemo } from 'react';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useListTasksPaginatedQuery } from '@vertice/slices/src/api/enhancedBffeWorkflowsAPI';
import { getAssignedUsersFromTaskList, getTaskRows, statusMap } from './utils';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { isTaskRef } from '../../../../hooks/workflows/refUtils';

type FilterType = 'ALL' | 'IN_PROGRESS' | 'COMPLETED' | 'OVERDUE';

const isValidStatusMapKey = (key?: string): key is keyof typeof statusMap => !!key && !['ALL', 'OVERDUE'].includes(key);

const getTaskStatusFilter = (selectedFilter?: FilterType): string | undefined => {
  if (isValidStatusMapKey(selectedFilter)) {
    return statusMap[selectedFilter].join(',');
  }
  if (selectedFilter === 'OVERDUE') {
    return statusMap['IN_PROGRESS'].join(',');
  }
  return undefined;
};

export const useTasks = (selectedFilter?: FilterType) => {
  const { accountId } = useAccountContext();
  const {
    data: tasksData,
    isFetching: isLoadingTasksData,
    refetch,
  } = useListTasksPaginatedQuery({
    accountId,
    taskStatus: getTaskStatusFilter(selectedFilter),
  });

  const filteredTasksData = useMemo(() => {
    if (selectedFilter === 'OVERDUE' && tasksData) {
      return {
        ...tasksData,
        items: tasksData.items.filter((task) => {
          const dueDate = new Date(task.dueDate!);
          return dueDate < new Date() && task.status === 'ACTIVE';
        }),
      };
    }
    return tasksData;
  }, [tasksData, selectedFilter]);

  const tasksAssignees = useMemo(() => getAssignedUsersFromTaskList(filteredTasksData), [filteredTasksData]);
  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useRelevantUsersById();
  const { usersById, isLoading: isLoadingUsers } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  useNotificationWebSocketSubscription({
    filter: isTaskRef,
    callback: () => {
      void refetch();
    },
  });

  const taskRows = useMemo(
    () => getTaskRows(filteredTasksData?.items ?? [], usersById),
    [filteredTasksData, usersById]
  );

  return {
    data: taskRows,
    isLoading: isLoadingUsers || isLoadingAccountUsers || isLoadingTasksData,
    refetch,
  };
};
