import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import useGetContractEffectiveCurrency from '@vertice/core/src/modules/saas/contract/hooks/useGetContractEffectiveCurrency';
import { useExchangeToAccountCurrency } from '@vertice/core/src/modules/currency/useExchangeToAccountCurrency';
import { useGetSavingsDashboardPaginatedQuery } from '@vertice/slices';
import { useMemo } from 'react';
import { sumBy } from 'lodash';
import {
  getAdditionalSavings,
  getAnnualAdditionalSavings,
  getAnnualFutureSavings,
  getAnnualSavingsVsInitial,
  getAnnualScopeReductionSavings,
  getAnnualTotalSavings,
  getFutureSavings,
  getSavingsVsInitial,
  getScopeReductionSavings,
  getTotalSavings,
} from '@vertice/core/src/modules/saas/savings/computed';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const useSavingsStatsData = () => {
  const { accountId } = useAccountContext();
  const getContractEffectiveCurrency = useGetContractEffectiveCurrency();
  const { exchangeToAccountCurrency, accountCurrency } = useExchangeToAccountCurrency();
  const { data: contractStatsData } = useGetContractsStatisticsQuery({ accountId });

  const { data } = useGetSavingsDashboardPaginatedQuery({
    accountId,
    maxResults: 100, // More items can cause time-outs
  });

  return useMemo(() => {
    if (!data?.contracts) {
      return null;
    }
    const contracts = data.contracts.map((viewOnContract) => viewOnContract.contract);

    return {
      currency: accountCurrency,

      savingsVsInitial: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getSavingsVsInitial(c), getContractEffectiveCurrency(c))
      ),
      annualSavingsVsInitial: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAnnualSavingsVsInitial(c), getContractEffectiveCurrency(c))
      ),

      totalSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getTotalSavings(c), getContractEffectiveCurrency(c))
      ),

      // Will be implemented in the next PR.
      annualTotalSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAnnualTotalSavings(c), getContractEffectiveCurrency(c))
      ),

      totalSavingsPercentage: (contractStatsData?.stats.averageSavingsPercentage ?? 0) / 100,

      additionalSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAdditionalSavings(c), getContractEffectiveCurrency(c))
      ),
      annualAdditionalSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAnnualAdditionalSavings(c), getContractEffectiveCurrency(c))
      ),

      futureSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getFutureSavings(c), getContractEffectiveCurrency(c))
      ),
      annualFutureSavings: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAnnualFutureSavings(c), getContractEffectiveCurrency(c))
      ),

      scopeReduction: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getScopeReductionSavings(c), getContractEffectiveCurrency(c))
      ),
      annualScopeReduction: sumBy(contracts, (c) =>
        exchangeToAccountCurrency(getAnnualScopeReductionSavings(c), getContractEffectiveCurrency(c))
      ),
    };
  }, [
    accountCurrency,
    contractStatsData?.stats.averageSavingsPercentage,
    data?.contracts,
    exchangeToAccountCurrency,
    getContractEffectiveCurrency,
  ]);
};

export default useSavingsStatsData;
