import { GridRowModes, GridRowModesModel, GridRowParams } from '@mui/x-data-grid-pro';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import React from 'react';
import { useEditableDataGridContext } from './EditableDataGridContext';
import { useTranslation } from 'react-i18next';
import { GridValidRowModel } from '@mui/x-data-grid';
import { GridIconButton } from '@verticeone/design-system';

type ActionsCellProps<R extends GridValidRowModel> = GridRowParams<R> & {
  rowModesModel: GridRowModesModel;
};

export const ActionsCell = <R extends GridValidRowModel>(params: ActionsCellProps<R>) => {
  const { t } = useTranslation();
  const editableGridContext = useEditableDataGridContext();
  const rowId = params.id;

  // There is an issue with getRowMode function: https://github.com/mui/mui-x/issues/10030
  // const isRowInEditMode = apiRef.current.getRowMode(rowId) === GridRowModes.Edit;
  const isRowInEditMode = params.rowModesModel[rowId]?.mode === GridRowModes.Edit;
  const isRowEditable = editableGridContext.isRowEditable?.(params.row) ?? true;

  // Edit mode
  if (editableGridContext.mode === 'confirm' && isRowInEditMode) {
    return (
      <>
        <GridIconButton
          variant="ghost"
          color="success"
          onClick={() => editableGridContext.handleRowSaveClick(rowId)}
          icon={CheckOutlined}
          aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.SAVE_CHANGES')}
        />
        <GridIconButton
          variant="ghost"
          color="error"
          onClick={() => editableGridContext.handleRowCancelClick(rowId)}
          icon={CloseOutlined}
          aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DISCARD_CHANGES')}
        />
      </>
    );
  }

  // Read-only mode
  if (editableGridContext.mode === 'confirm' && !isRowInEditMode) {
    return (
      <>
        <GridIconButton
          variant="ghost"
          onClick={() => editableGridContext.handleRowEditClick(rowId)}
          icon={EditOutlined}
          aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.EDIT_ROW')}
          disabled={!isRowEditable}
        />
        {(!editableGridContext.isRowDeletable || editableGridContext.isRowDeletable?.(params.row)) && (
          <GridIconButton
            variant="ghost"
            color="error"
            onClick={() => editableGridContext.handleRowDeleteClick(rowId)}
            icon={DeleteOutlined}
            aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DELETE_ROW')}
            disabled={!isRowEditable}
          />
        )}
      </>
    );
  }

  // Auto-save mode
  return (
    <GridIconButton
      variant="ghost"
      onClick={() => editableGridContext.handleRowDeleteClick(rowId)}
      icon={DeleteOutlined}
      aria-label={t('CORE.COMPONENTS.EDITABLE_DATA_GRID.ACTIONS.DELETE_ROW')}
    />
  );
};
