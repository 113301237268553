import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMergeLink } from '@mergeapi/react-merge-link';
import {
  useActivateAccountIntegrationMutation,
  useConfigureAccountIntegrationMutation,
  useConnectAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { usePolling } from '@vertice/core/src/hooks/usePolling';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type SpendInsightsFeature = {
  id: 'spendInsights';
  enabled: boolean;
  allowedRecords: Array<'invoice' | 'contact' | 'item'>;
};

type ConnectProps = {
  mergeIntegrationId: string;
  category: 'accounting' | 'ticketing';
  features: Array<SpendInsightsFeature>;
};

type UseRevokeProps = {
  providerCode: string;
  onSuccess?: () => void;
};

export const useConnectIntegration = ({ providerCode, onSuccess }: UseRevokeProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useAccountContext();
  const [isInitializing, setIsInitializing] = useState(false);

  const {
    data: integration,
    isLoading: isFetchingIntegration,
    refetch: refetchIntegrationDetails,
  } = useGetAccountIntegrationQuery({ accountId: accountId!, integrationId: providerCode }, { skip: !accountId });
  const [configureIntegration, { isLoading: isUpdatingConfiguration }] = useConfigureAccountIntegrationMutation();
  const [connectIntegration, { isLoading: isConnectingIntegration, isError: connectingIntegrationFailed }] =
    useConnectAccountIntegrationMutation();
  const [activateIntegration, { isLoading: isActivatingIntegration }] = useActivateAccountIntegrationMutation();
  const [testIntegration, { isLoading: isTestingIntegration }] = useTestAccountIntegrationMutation();

  const mergeLinkTokenPolling = usePolling(async () => {
    if (!isFetchingIntegration && !integration?.parameters?.mergeLinkToken) {
      void refetchIntegrationDetails();
    } else if (isReady) {
      openMergeComponent();
      mergeLinkTokenPolling.stop();
    }
  }, 2000);

  const handshakeCommitPolling = usePolling(async () => {
    if (!isFetchingIntegration) {
      if (integration?.connection?.status === 'TESTING' || integration?.connection?.status === 'DISCONNECTED') {
        void refetchIntegrationDetails();
      } else if (integration?.connection?.status === 'OK') {
        void activateIntegration({
          accountId: accountId!,
          integrationId: providerCode,
        });
        handshakeCommitPolling.stop();
        onSuccess?.();
      } else if (integration?.connection?.status === 'FAILED') {
        enqueueSnackbar(t('INTEGRATIONS.ACCOUNTING.ERRORS.UNEXPECTED_ERROR'), { variant: 'error' });
        handshakeCommitPolling.stop();
      }
    }
  }, 2000);

  const handleSetup = useCallback(
    async (publicToken: string) => {
      void connectIntegration({
        accountId: accountId!,
        integrationId: providerCode,
        integrationConnectionInput: {
          parameters: {
            ...integration?.parameters,
            secret: {
              publicToken,
            },
          },
        },
      }).then(() => {
        if (connectingIntegrationFailed) {
          return;
        }

        void testIntegration({
          accountId: accountId!,
          integrationId: providerCode,
          testName: 'handshake:commit',
        }).then(() => handshakeCommitPolling.start());
      });
    },
    [
      accountId,
      connectIntegration,
      connectingIntegrationFailed,
      handshakeCommitPolling,
      integration,
      providerCode,
      testIntegration,
    ]
  );

  const handleMergeLinkExit = () => {
    if (!integration?.parameters?.mergeLinkToken) {
      return;
    }

    void configureIntegration({
      accountId: accountId!,
      integrationId: providerCode,
      integrationConfigInput: {
        parameters: {
          ...integration.parameters,
          mergeLinkToken: undefined,
        },
      },
    });
  };

  const { open: openMergeComponent, isReady } = useMergeLink({
    linkToken: integration?.parameters?.mergeLinkToken,
    onSuccess: handleSetup,
    onExit: handleMergeLinkExit,
    tenantConfig: {
      apiBaseURL: 'https://api-eu.merge.dev',
    },
  });

  const connect = useCallback(
    ({ mergeIntegrationId, category, features }: ConnectProps) => {
      setIsInitializing(true);

      void configureIntegration({
        accountId: accountId!,
        integrationId: providerCode,
        integrationConfigInput: {
          parameters: {
            integration: mergeIntegrationId,
            category,
            features,
          },
        },
      })
        .then(() => {
          return testIntegration({
            accountId: accountId!,
            integrationId: providerCode,
            testName: 'handshake:init',
          });
        })
        .then(() => {
          mergeLinkTokenPolling.start();
          mergeLinkTokenPolling.onStop(() => setIsInitializing(false));
        });
    },
    [accountId, configureIntegration, mergeLinkTokenPolling, providerCode, testIntegration]
  );

  const isConnecting = useMemo(
    () =>
      isInitializing ||
      isUpdatingConfiguration ||
      isTestingIntegration ||
      mergeLinkTokenPolling.isPolling ||
      isConnectingIntegration ||
      isActivatingIntegration ||
      handshakeCommitPolling.isPolling,
    [
      isInitializing,
      isUpdatingConfiguration,
      isTestingIntegration,
      mergeLinkTokenPolling,
      isConnectingIntegration,
      isActivatingIntegration,
      handshakeCommitPolling,
    ]
  );

  return { isConnecting, connect };
};
