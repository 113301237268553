import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, FieldValues, Path, PathValue, useFormContext, useWatch } from 'react-hook-form';
import { TaskFormEntry } from '../TaskFormEntry';
import { FormOverridableCostField } from '../../../../../../../../forms/fields/FormOverridableCostField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../../constants';

type OverridableCostFormEntryProps<FormDataType extends FieldValues> = {
  costsName: FieldPath<FormDataType>;
  defaultCostsName: FieldPath<FormDataType>;
  overrideCostsName: FieldPath<FormDataType>;
  currency: string;
  loadingComputedCosts?: boolean;
};

export const OverridableCostFormEntry = <T extends FieldValues>({
  costsName,
  defaultCostsName,
  overrideCostsName,
  currency,
  loadingComputedCosts,
}: OverridableCostFormEntryProps<T>) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<T>();
  const computedAnnualCost = useWatch({ control, name: defaultCostsName });
  const overrideEnabled = useWatch({ control, name: overrideCostsName });

  useEffect(() => {
    // Set computed cost as default value for overridable field
    if (!overrideEnabled) {
      setValue(costsName, computedAnnualCost ?? (0 as PathValue<T, Path<T>>), { shouldValidate: true });
    }
  }, [overrideEnabled, computedAnnualCost, setValue, costsName]);

  const label = overrideEnabled
    ? t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.TOTAL_ANNUAL_COST')
    : t('INTELLIGENT_WORKFLOWS.TASK_FORMS.OFFERS.LABELS.TOTAL_COST');

  return (
    <TaskFormEntry<any, typeof FormOverridableCostField>
      name={costsName}
      label={label}
      component={FormOverridableCostField}
      componentProps={{
        currency,
        isLoading: loadingComputedCosts,
        overrideName: overrideCostsName,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        changeImmediately: true,
      }}
      width={6}
    />
  );
};
