import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CustomFilterView, EvaluateFilterViewContext } from './types';
import { every } from 'lodash';
import { isCancelledContract } from '../../../../computed';
import { evaluators } from './evaluateFunctions';
import { objectKeys } from 'react-querybuilder';

const evaluateCustomFilterView = (
  { filters }: CustomFilterView,
  viewOnContract: ViewOnContract,
  ctx?: EvaluateFilterViewContext
) =>
  every([
    !isCancelledContract(viewOnContract.contract),
    ...objectKeys(filters).map((key) => evaluators[key](filters, viewOnContract, ctx)),
  ]);

export default evaluateCustomFilterView;
