import unique from 'lodash/uniq';
import { useMemo } from 'react';
import type { FlowEdge, ProcessDefinition, Task } from '../../../definitionsTypes';
import type { SimpleTypes as JsonSchemaType } from '@vertice/slices/src/openapi/codegen/catalogAPI';

import {
  isFlowEdgeMappingConfiguration,
  isTaskIOMappingConfiguration,
  isVerticeFormConfiguration,
  isVerticeServiceConfiguration,
} from '../../../pocWorkflowSchema';
import { getVariableTypeLabels } from '../../utils';
import { getDagreGraphFromWorkflowModel } from '../../WorkflowRenderer/getDagreGraphFromWorkflowModel';
import { useWorkflowRendererContext } from '../../WorkflowRenderer/WorkflowRendererContext';
import type { CatalogResources, CatalogResourceMap } from '../../WorkflowViewer/types';
import { useCatalogResources } from '../../WorkflowViewer/useCatalogResources';
import { JsonSchema, convertJsonSchemaToVariable, formatVariableLabel } from '../../WorkflowViewer/utils';
import { NodeId, Variable, VariableId, VariableOrigin } from '../types';
import type { XTypeCatalogResource } from '../../../catalogResource/types';
import {
  findAllPathsBetweenNodes,
  getProcessDefinitionStartNodeId,
  replaceFirstSegmentOfVariableId,
  sortVariablesAlphabetically,
} from './utils';

export const getMappedVariableProperties = ({
  mappedPropertyValue,
  mappedPropertyName,
  mappedPropertySchema,
  serviceInterfaceOutput,
  origin,
  xTypeCatalogResources,
  displayArrayType,
}: {
  mappedPropertyValue: string;
  mappedPropertyName: string;
  mappedPropertySchema?: JsonSchema;
  serviceInterfaceOutput: Variable[];
  origin: VariableOrigin;
  xTypeCatalogResources: XTypeCatalogResource[];
  displayArrayType?: boolean;
}): Variable => {
  let variable;

  if (mappedPropertySchema) {
    variable = convertJsonSchemaToVariable({
      jsonSchema: mappedPropertySchema,
      origin: origin,
      variableKey: mappedPropertyName,
      xTypeCatalogResources,
      displayArrayType,
    });
  } else {
    variable = serviceInterfaceOutput?.find((outputVariable) => outputVariable.id === mappedPropertyValue);
  }

  const variableLabel = formatVariableLabel(mappedPropertyName);

  if (!variable) {
    return {
      id: mappedPropertyName,
      values: [],
      label: variableLabel,
      isVisible: false,
      isSelectable: false,
      required: false,
      origin,
      type: {},
      variables: [],
      operators: [],
      path: [],
    };
  }

  const mappedVariables = changeOriginForVariables({
    id: mappedPropertyName,
    variables: variable.variables,
    origin,
    label: variableLabel,
  });

  return {
    ...variable,
    origin,
    id: mappedPropertyName,
    idOverride: variable.idOverride
      ? replaceFirstSegmentOfVariableId(variable.idOverride, mappedPropertyName)
      : variable.idOverride,
    path: [variableLabel],
    label: variableLabel,
    variables: mappedVariables,
  };
};

const getVariablesFromTask = ({
  task,
  catalogResources,
  displayArrayType,
}: {
  task: Task;
  catalogResources: CatalogResources;
  displayArrayType?: boolean;
}): Variable[] => {
  if (!task.configurations) {
    return [];
  }

  const { account: accountCatalogResources, global: globalCatalogResources, xTypeCatalogResources } = catalogResources;

  const { configurations } = task;

  const iOMappingConfigurationOutputFields = configurations.find(isTaskIOMappingConfiguration)?.mapping.outputFields;
  const verticeFormConfigurationFormUrn = configurations.find(isVerticeFormConfiguration)?.formUrn;
  const verticeServiceConfigurationResourceUrn = configurations.find(isVerticeServiceConfiguration)?.resourceUrn;

  const resourceUrn = verticeFormConfigurationFormUrn || verticeServiceConfigurationResourceUrn;

  /** The Task has no IO Mapping Configuration. In this case, the Output schema of the Vertice Form Configuration of the Task is used */
  if (!iOMappingConfigurationOutputFields?.length) {
    if (!resourceUrn) {
      return [];
    }

    const outputInterface = (accountCatalogResources[resourceUrn] || globalCatalogResources[resourceUrn])?.output;

    if (!outputInterface?.length) {
      return [];
    }

    return changeOriginForVariables({
      variables: outputInterface,
      origin: {
        id: task.id,
        label: task.name,
        kind: 'vertice-task-output-mapping',
      },
    });
  }

  /** The Task has IO Mapping Configuration. */

  /** The resourceUrn is not known for given IOMapping so we do not show these mappings as we are not able to present types */
  if (!resourceUrn) {
    return [];
  }

  const accountServiceInterfaceOutput = accountCatalogResources[resourceUrn];
  const globalServiceInterfaceOutput = globalCatalogResources[resourceUrn];
  const serviceInterfaceOutput = (accountServiceInterfaceOutput || globalServiceInterfaceOutput)?.output;

  /** The resourceUrn is known but the service does not have output interface. We do not show these mapping as we are not able to present types */
  if (!serviceInterfaceOutput?.length && !iOMappingConfigurationOutputFields.some((field) => !!field.schema)) {
    return [];
  }

  const variables: Variable[] = iOMappingConfigurationOutputFields
    .map((outputField) => {
      /** The types of the mapped variables can be determined from the Output Interface of Account Service or Global Service */
      const mappedVariable = getMappedVariableProperties({
        mappedPropertyValue: outputField.value,
        mappedPropertyName: outputField.name,
        mappedPropertySchema: outputField.schema,
        origin: {
          id: task.id,
          label: task.name,
          kind: 'vertice-task-output-mapping',
        },
        serviceInterfaceOutput,
        xTypeCatalogResources,
        displayArrayType,
      });

      return mappedVariable;
    })
    .filter((variable) => variable.isVisible);

  return variables;
};

const changeOriginForVariables = ({
  variables,
  origin,
  id,
  label,
}: {
  variables: Variable[];
  origin: VariableOrigin;
  id?: VariableId;
  label?: string;
}): Variable[] => {
  return variables.map((variable) => {
    const updatedId = !id ? variable.id : replaceFirstSegmentOfVariableId(variable.id, id);
    const updatedOverrideId =
      id && variable.idOverride ? replaceFirstSegmentOfVariableId(variable.idOverride, id) : variable.idOverride;
    const [, ...restPathLabels] = variable.path;
    const updatedPath = !label ? variable.path : [label, ...restPathLabels];

    const updatedVariable = {
      ...variable,
      idOverride: updatedOverrideId,
      id: updatedId,
      path: updatedPath,
      origin: {
        ...origin,
      },
    };

    if (variable.variables.length > 0) {
      return {
        ...updatedVariable,
        variables: changeOriginForVariables({
          variables: variable.variables,
          origin,
          id: id,
          label: label,
        }),
      };
    }

    return updatedVariable;
  });
};

// Currently, we do not want to (RED-1903) display the variables derived from the Edge Mapping Configuration.
// eslint-disable-next-line unused-imports/no-unused-vars
const getVariablesFromEdge = ({
  edge,
  catalogResources,
}: {
  edge: FlowEdge;
  catalogResources: CatalogResources;
}): Variable[] => {
  if (!edge.configurations) {
    return [];
  }

  const { global: globalCatalogResources } = catalogResources;

  const edgeMappingConfigurationFields = edge.configurations.find(isFlowEdgeMappingConfiguration)?.mapping.fields;

  if (!edgeMappingConfigurationFields?.length) {
    return [];
  }

  return edgeMappingConfigurationFields
    .map((field) => {
      const { name, value } = field;

      const variableId = name;
      const variableLabel = formatVariableLabel(variableId);
      const origin: VariableOrigin = {
        id: edge.id,
        label: edge.name,
        kind: 'vertice-edge-mapping',
      };

      /** Mapping is simple string value being written into the shared memory */
      if (value.startsWith('`') && value.endsWith('`')) {
        const variableType: JsonSchemaType[] = ['string'];
        return {
          id: variableId,
          label: variableLabel,
          isVisible: true,
          isSelectable: true,
          origin,
          type: {
            baseType: variableType,
            labels: getVariableTypeLabels({ type: variableType }),
          },
          required: false,
          variables: [],
          path: [variableLabel],
        };
      }

      /** Mapping is udf call */
      if (value.startsWith('udf(`urn:verticeone:vertice')) {
        const udfUrn = value.match(/`([^`]+)`/)?.[1];

        if (udfUrn) {
          const udfInterface = Object.entries(globalCatalogResources).find(([serviceUrn]) =>
            serviceUrn.includes(udfUrn)
          );

          if (udfInterface && udfInterface[1].output.length > 0) {
            const udfReturnVariable = udfInterface[1].output[0];
            const udfReturnVariablesMappedToEdgeVariable = changeOriginForVariables({
              variables: udfReturnVariable.variables,
              origin,
              id: variableId,
            });

            return {
              ...udfReturnVariable,
              id: variableId,
              label: variableLabel,
              isVisible: true,
              origin,
              variables: udfReturnVariablesMappedToEdgeVariable,
              path: [variableLabel],
            };
          }
        }
      }

      /*
       * Mapping is anything else and right now we are not able to determine types for these variables
       * so we are not showing them in the UI
       */
      return {
        id: variableId,
        label: variableLabel,
        isVisible: false,
        isSelectable: false,
        required: false,
        variables: [],
        origin,
        type: {},
        path: [],
      };
    })
    .filter((variable) => variable.isVisible);
};

const getUdfVariablesFromCatalogResourceMap = (catalogResourceMap: CatalogResourceMap): Variable[] =>
  Object.values(catalogResourceMap)
    .flatMap((schema) => schema.output)
    .filter((variable) => variable.origin.kind.includes('udf'));

export type AvailableVariables = {
  requestVariables: Variable[];
  udfVariables: Variable[];
  isFetching: boolean;
};

export const useVariablesAvailableInNode = ({
  nodeId,
  processDefinition,
  workflowServiceRef,
  includeNodeVariables = true,
  displayArrayType = false,
}: {
  nodeId: NodeId;
  processDefinition?: ProcessDefinition;
  workflowServiceRef?: string;
  /*
   * If true, the output variables of the node are included in the result.
   */
  includeNodeVariables?: boolean;
  displayArrayType?: boolean;
}): AvailableVariables => {
  const { model } = useWorkflowRendererContext();
  const catalogResources = useCatalogResources();
  const graph = useMemo(() => getDagreGraphFromWorkflowModel(model), [model]);

  return useMemo(() => {
    if (!processDefinition) {
      return {
        udfVariables: [],
        requestVariables: [],
        isFetching: catalogResources.isFetching,
      };
    }

    const allPaths = findAllPathsBetweenNodes({
      graph,
      fromNodeId: getProcessDefinitionStartNodeId(processDefinition),
      toNodeId: nodeId,
    });

    const allNodesIdsFromStartToNode = unique(allPaths.map((path) => path.nodes).flat()).filter(
      (nodeIdInPath) => includeNodeVariables || nodeIdInPath !== nodeId
    );
    const allTasksFromStartToNode = (processDefinition.process.tasks ?? []).filter(({ task }) =>
      allNodesIdsFromStartToNode.includes(task.id)
    );

    /*
     * Currently, we do not want to (RED-1903) display the variables derived from the Edge Mapping Configuration.
     *
     * const allEdgesIdsFromStartToNode = unique(
     *     allPaths.map((path) => path.edges.map(removeLabelPostfixFromEdgeId)).flat()
     * );
     *
     * const allEdgesFromStartToNode = (processDefinition.process.flow?.flow?.edges ?? []).filter(({ edge }) =>
     *     allEdgesIdsFromStartToNode.includes(edge.id)
     * );
     */

    const { account: accountCatalogResources, global: globalCatalogResources } = catalogResources;

    /*
     * There are several sources of variables:
     * 1. The Service Provider Input of the Workflow's service
     * 2. IO Mapping Configuration of the Task
     * 3. The Output schema of the Vertice Form Configuration of the Task
     * 4. Edge Mapping Configuration of the Edge
     * 5. User Defined Functions (UDFs)
     */
    const variables: Variable[] = [];

    /** 1. The Service Provider Input of the Workflow's service */
    if (workflowServiceRef && accountCatalogResources[workflowServiceRef]) {
      const workflowServiceInputInterface = accountCatalogResources[workflowServiceRef].input;
      variables.push(...workflowServiceInputInterface);
    }

    /*
     * 2. IO Mapping Configuration of the Task
     * 3. The Output schema of the Vertice Form Configuration of the Task
     */
    for (const { task } of allTasksFromStartToNode) {
      variables.push(...getVariablesFromTask({ task, catalogResources, displayArrayType }));
    }

    /*
     * 4. Edge Mapping Configuration of the Edge
     *
     * Currently, we do not want to (RED-1903) display the variables derived from the Edge Mapping Configuration.
     * Thus, commenting out the code below.
     *
     *   for (const { edge } of allEdgesFromStartToNode) {
     *     variables.push(...getVariablesFromEdge({ edge, servicesInterfaces }));
     *   }
     */

    /** 5. User Defined Functions (UDFs) */
    const globalUDFVariables = getUdfVariablesFromCatalogResourceMap(globalCatalogResources);
    const accountUDFVariables = getUdfVariablesFromCatalogResourceMap(accountCatalogResources);

    return {
      requestVariables: sortVariablesAlphabetically(variables),
      udfVariables: sortVariablesAlphabetically([...globalUDFVariables, ...accountUDFVariables]),
      isFetching: catalogResources.isFetching,
    };
  }, [displayArrayType, graph, includeNodeVariables, nodeId, processDefinition, catalogResources, workflowServiceRef]);
};
