import * as z from 'zod';
import { genericFormFieldSchema } from './CustomForm/types';

export const MIN_ASSIGNEES = 1;
const DUE_IN_ERROR = 'EDIT_USER_TASK.ERRORS.DUE_IN';

const replaceNewlines = (value: string | null | undefined) => (value ? value.replace(/\n/g, ' ') : value);

const conditionalAssigneesSchema = z.object({
  assignees: z.array(z.string()).min(MIN_ASSIGNEES),
  condition: z.string().min(1),
});

const contractReviewMappingRecord = z.object({
  source: z.object({
    type: z.enum(['Const', 'Variable']),
    value: z.string().min(1),
  }),
  target: z.object({
    type: z.enum(['Const', 'Variable']),
    value: z.string().min(1),
  }),
});

export type ContractReviewMappingRecord = z.infer<typeof contractReviewMappingRecord>;

export const editUserTaskFormSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().trim().min(1),
    defaultAssignees: z.array(z.string()),
    conditionalAssignees: z.array(conditionalAssigneesSchema),
    description: z.string().optional(),
    dueIn: z
      .number({ message: DUE_IN_ERROR })
      .int({ message: DUE_IN_ERROR })
      .positive({ message: DUE_IN_ERROR })
      .min(1, { message: DUE_IN_ERROR })
      .nullable(),
    buttonLabels: z
      .object({
        approve: z.string().trim().min(1).transform(replaceNewlines),
        reject: z.string().trim().min(1).transform(replaceNewlines),
      })
      .nullable()
      .optional(),
    formHeading: z.string().trim().nullable().optional().transform(replaceNewlines),
    customFormFields: z.array(genericFormFieldSchema).nullable().optional(),
    /* this record maps customFieldName to variableId */
    customFormVariables: z.record(z.string(), z.string().nullable()).optional(),
    isSimpleApprovalForm: z.boolean(),
    contractReviewMapping: z.array(contractReviewMappingRecord).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.isSimpleApprovalForm) {
      if (!data.buttonLabels) {
        ctx.addIssue({
          path: ['buttonLabels'],
          code: z.ZodIssueCode.custom,
          message: 'buttonLabels is required when isSimpleApprovalForm is true',
        });
      }
      if (!data.formHeading?.trim()) {
        ctx.addIssue({
          path: ['formHeading'],
          code: z.ZodIssueCode.custom,
          message: 'formHeading is required when isSimpleApprovalForm is true',
        });
      }
    }
  });

export type EditUserTaskFormData = z.infer<typeof editUserTaskFormSchema>;
