import type { FormData as PurchaseRGFormData } from '../RequirementsGatheringForm/schema';
import type { FormData as RenewalRGFormData } from '../RenewalRequirementsForm/schema';

type FormData = PurchaseRGFormData | RenewalRGFormData;

export const getApprovedBudget = (formData: FormData) => {
  const { approvedBudget, unknownBudget } = formData;

  if (!approvedBudget && unknownBudget) {
    return 0;
  }

  return approvedBudget;
};

export const getNegotiationValues = (formData: FormData) => {
  const { verticeNegotiationRequested, unknownBudget, approvedBudget, internalNegotiationRequested } = formData;

  if (approvedBudget === 0 || unknownBudget) {
    return {
      verticeNegotiationRequested: true,
      internalNegotiationRequested: false,
    };
  }

  return {
    verticeNegotiationRequested,
    internalNegotiationRequested,
  };
};
