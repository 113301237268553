import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Connection } from '@xyflow/react';
import { DndContext, DragEndEvent, DragStartEvent, useDroppable } from '@dnd-kit/core';
import { Stack } from '@mui/material';

import { DnDNodesPanel } from './DnDNodesPanel/DnDNodesPanel';
import { DnDNodeTileDragOverlay } from './DnDNodesPanel/DnDNodeTile';
import {
  addEdgeToWorkflowDefinitions,
  addNodeToWorkflowDefinitions,
  autoLayoutNodesInWorkflowDefinitions,
  getAreAllNodesConnected,
  updatePositionOfNodeInWorkflowDefinitions,
} from '../utils';
import type { RendererNode } from '../../WorkflowRenderer/types';
import type { DnDNode } from './DnDNodesPanel/types';
import { useDnDNodesGroups } from './DnDNodesPanel/useDnDNodesGroups';
import { useWorkflowEditing } from '../useWorkflowEditing';
import { DragAndDropExtensionProvider } from './DragAndDropExtensionContext';
import { WorkflowDefinitions } from '../../types';

const DroppableContainer: FC<PropsWithChildren> = ({ children }) => {
  const { setNodeRef } = useDroppable({ id: 'workflow-editor-droppable' });

  return (
    <Stack width="100%" height="100%" position="relative">
      <Stack ref={setNodeRef} width="calc(100% - 240px)" height="100%" position="absolute" top={0} left={240} />
      {children}
    </Stack>
  );
};

type DragAndDropExtensionProps = {
  workflowDefinitions: WorkflowDefinitions;
  setDefinitions: (workflowDefinitions: WorkflowDefinitions) => void;
  isNodesPanelOpen: boolean;
  onNodeDelete: (nodeId: string) => void;
} & PropsWithChildren;

export const DragAndDropExtension: FC<DragAndDropExtensionProps> = ({
  children,
  setDefinitions,
  workflowDefinitions,
  isNodesPanelOpen,
  onNodeDelete,
}) => {
  const { processDefinition } = workflowDefinitions;
  const [activeNodeTile, setActiveNodeTile] = useState<DnDNode>();
  const [nodesPanelScrollTopValue, setNodesPanelScrollTopValue] = useState(0);

  const { reactFlowInstance } = useWorkflowEditing({
    processDefinition,
  });

  const { nodeGroups, isLoading } = useDnDNodesGroups();

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;

      setActiveNodeTile(() => {
        return nodeGroups.flatMap((group) => group.nodes).find((node) => node.id === active.id);
      });
    },
    [nodeGroups]
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const pointerEvent = event.activatorEvent as PointerEvent;
      const nodeData = event.active.data.current?.node;
      const overNode = event.over;

      if (!nodeData || !overNode) {
        return;
      }

      /**
       * The pointerEvent.client(X/Y) is the position of where the drag started.
       * The event.delta(X/Y) is the distance the drag has moved.
       * The nodesPanelScrollTopValue is the scroll position of the nodes panel.
       * - It must be added to the Y position to get the correct position of the node in the react flow.
       */
      const positions = reactFlowInstance.screenToFlowPosition(
        {
          x: pointerEvent.clientX + event.delta.x,
          y: pointerEvent.clientY + event.delta.y + nodesPanelScrollTopValue,
        },
        { snapToGrid: false }
      );

      const updatedWorkflowDefinitions = addNodeToWorkflowDefinitions(workflowDefinitions, nodeData, {
        x: positions.x,
        y: positions.y,
      });

      setDefinitions(updatedWorkflowDefinitions);
    },
    [workflowDefinitions, setDefinitions, reactFlowInstance, nodesPanelScrollTopValue]
  );

  const onNodeDragStop = useCallback(
    (node: RendererNode) => {
      const { position, data } = node;

      const updatedProcessDefinition = updatePositionOfNodeInWorkflowDefinitions(workflowDefinitions, data.id, {
        x: position.x,
        y: position.y,
      });

      setDefinitions(updatedProcessDefinition);
    },
    [workflowDefinitions, setDefinitions]
  );

  const onConnect = useCallback(
    ({ source, target }: Connection) => {
      const updatedProcessDefinition = addEdgeToWorkflowDefinitions(workflowDefinitions, source, target);
      setDefinitions(updatedProcessDefinition);
    },
    [workflowDefinitions, setDefinitions]
  );

  const onAutoLayout = useCallback(() => {
    const updatedWorkflowDefinitions = autoLayoutNodesInWorkflowDefinitions(workflowDefinitions);

    setDefinitions(updatedWorkflowDefinitions);
  }, [workflowDefinitions, setDefinitions]);

  const isAutoLayoutEnabled = useMemo(() => {
    return getAreAllNodesConnected(workflowDefinitions);
  }, [workflowDefinitions]);

  return (
    <DndContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
      <DragAndDropExtensionProvider
        value={{
          onConnect,
          onNodeDragStop,
          onNodeDelete,
          onAutoLayout,
          isAutoLayoutEnabled,
        }}
      >
        <Stack position="relative" width="100%" height="100%">
          <DroppableContainer>{children}</DroppableContainer>
          <DnDNodesPanel
            onScroll={setNodesPanelScrollTopValue}
            nodeGroups={nodeGroups}
            isLoading={isLoading}
            isOpen={isNodesPanelOpen}
          />
        </Stack>
        <DnDNodeTileDragOverlay node={activeNodeTile} />
      </DragAndDropExtensionProvider>
    </DndContext>
  );
};
