import { Stack } from '@mui/material';
import { useRequestContext } from '../../RequestContext';
import { CustomTaskDocuments } from './CustomTaskDocuments';
import { RequestDocuments } from './RequestDocuments';

export const DocumentsSection = () => {
  const { requestId } = useRequestContext();

  return (
    <Stack gap={4}>
      <CustomTaskDocuments requestId={requestId} />
      <RequestDocuments requestId={requestId} />
    </Stack>
  );
};
