import { useMemo } from 'react';
import { CatalogResource, useListCatalogResourcesQuery } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type GetServiceCatalogProps = {
  resourcesFilter?: (item: CatalogResource) => boolean;
  skipAccountResources?: boolean;
  skipGlobalResources?: boolean;
};

export const useGetCatalogResources = (props: GetServiceCatalogProps = {}) => {
  const { resourcesFilter, skipAccountResources, skipGlobalResources } = props;
  const { accountId } = useAccountContext();
  const { data: accountResources, isLoading: isLoadingAccount } = useListCatalogResourcesQuery(
    {
      accountId,
    },
    { skip: skipAccountResources }
  );

  const { data: globalResources, isLoading: isLoadingGlobal } = useListCatalogResourcesQuery(
    {
      accountId: 'GLOBAL',
    },
    { skip: skipGlobalResources }
  );

  const resources = useMemo(() => {
    const data = [
      ...((skipAccountResources ? undefined : accountResources?.items) || []),
      ...((skipGlobalResources ? undefined : globalResources?.items) || []),
    ];

    if (!resourcesFilter) {
      return data;
    }

    return data.filter(resourcesFilter);
  }, [globalResources, accountResources, resourcesFilter, skipAccountResources, skipGlobalResources]);

  const isLoading = isLoadingGlobal || isLoadingAccount;

  return {
    resources,
    isLoading: isLoading,
  };
};
