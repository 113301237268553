import * as z from 'zod';

export const END_NAME_CHAR_LIMIT = 30;

export const VALID_RESULT_TYPE_VALUES = ['COMPLETED', 'DECLINED', 'CANCELLED'];

export const editEndFormSchema = z
  .object({
    name: z.string().trim().max(END_NAME_CHAR_LIMIT), // allow string as input
    resultType: z.string().min(1).optional(),
  })
  // restrict newly assigned values to the limited set above
  .refine(({ resultType }) => !resultType || VALID_RESULT_TYPE_VALUES.includes(resultType), { path: ['resultType'] });

export type EditEndFormData = z.infer<typeof editEndFormSchema>;
