import { useMemo } from 'react';
import { isContractReviewFormUrn } from '../utils';
import { Variable, VariableOrigin } from '../../../../types';
import { JsonSchema, formatVariableLabel, isJsonSchema } from '../../../../../WorkflowViewer/utils';
import { useCatalogResources } from '../../../../../WorkflowViewer/useCatalogResources';
import { getVariableTypeFromJsonSchema } from '../../../../../utils';
import { useGetCatalogResources } from '../../../../../../hooks/useGetCatalogResources';
import { isFormCatalogResource } from '../../../../../../catalogResource/utils';
import type { XTypeCatalogResource } from '../../../../../../catalogResource/types';

const ENABLED_DATE_FIELDS = ['startDate', 'renewalDate', 'signingDate', 'autoRenewalDeadline'];
const ENABLED_XTYPE_FIELDS = ['rollingFrequency', 'department', 'vendorName'];

/**
 * Custom React hook that retrieves contract review form variables based on the input interface.
 *
 * This hook:
 * - Extracts variables from the form's input interface JSON schema.
 * - Filters out only enabled fields that can be mapped to a contract variable.
 *
 * @returns An object containing:
 * - `isLoading`: A boolean indicating whether resources are still being loaded.
 * - `contractVariables`: An array of extracted contract variables corresponding to the form's input interface
 */
export const useContractReviewFormResource = () => {
  const { isLoading, resources } = useGetCatalogResources({});
  const { xTypeCatalogResources } = useCatalogResources();

  const contractReviewForm = useMemo(() => {
    const resource = resources.find(({ urn }) => isContractReviewFormUrn(urn));
    return isFormCatalogResource(resource) ? resource : null;
  }, [resources]);

  const contractVariables = useMemo<Variable[]>(() => {
    const inputInterface = contractReviewForm?.definition?.Form?.Interface?.Input?.JsonSchema || undefined;

    if (!inputInterface || !isJsonSchema(inputInterface) || !inputInterface.properties) return [];

    return Object.entries(inputInterface.properties)
      .map(([key, property]) =>
        isJsonSchema(property) ? toContractFieldVariable(key, property, xTypeCatalogResources) : null
      )
      .filter(canBeMapped);
  }, [contractReviewForm, xTypeCatalogResources]);

  if (isLoading) return { isLoading: true, contractVariables: undefined };

  return { isLoading: false, contractVariables };
};

const canBeMapped = (variable: Variable | null): variable is Variable => {
  if (!variable) return false;

  const enabledFields = [ENABLED_DATE_FIELDS, ENABLED_XTYPE_FIELDS].flat();
  return enabledFields.includes(variable.id);
};

const toContractFieldVariable = (
  variableKey: string,
  jsonSchema: JsonSchema,
  xTypeCatalogResources: XTypeCatalogResource[]
): Variable => {
  const origin: VariableOrigin = { id: '', label: '', kind: 'vertice-account-service' };
  const variableType = getVariableTypeFromJsonSchema({ jsonSchema, xTypeCatalogResources });

  return {
    id: variableKey,
    label: formatVariableLabel(variableKey),
    type: variableType,
    origin: origin,
    path: [''],
    isVisible: false,
    isSelectable: true,
    required: false,
    variables: [],
  };
};
