import { Point } from '../../model/types';
import { EDGE_BORDER_RADIUS } from '../../../components/sharedVisualStyle/edgeStyle';

export const generateSVGPath = (points: Point[]) => {
  if (!points || points.length < 2) {
    throw new Error('At least two points are required to form a path.');
  }

  let path = `M ${points[0].x},${points[0].y}`;

  for (let i = 1; i < points.length - 1; i++) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];
    const previousPoint = points[i - 1];

    const dx1 = currentPoint.x - previousPoint.x;
    const dy1 = currentPoint.y - previousPoint.y;
    const dx2 = nextPoint.x - currentPoint.x;
    const dy2 = nextPoint.y - currentPoint.y;

    const dist1 = Math.sqrt(dx1 * dx1 + dy1 * dy1);
    const dist2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);

    const ratio1 = Math.min(EDGE_BORDER_RADIUS / dist1, 0.5);
    const ratio2 = Math.min(EDGE_BORDER_RADIUS / dist2, 0.5);

    // The start point of the curve.
    const curvePoint1 = {
      x: currentPoint.x - dx1 * ratio1,
      y: currentPoint.y - dy1 * ratio1,
    };

    // The end point of the curve.
    const curvePoint2 = {
      x: currentPoint.x + dx2 * ratio2,
      y: currentPoint.y + dy2 * ratio2,
    };

    /*
     * 1. Draw the straight line from the previous point to the point {curvePoint1.x, curvePoint1.y} which is start of the curve. "L" command.
     * 2. Draw the curve from the point {curvePoint1.x, curvePoint1.y} to the point {curvePoint2.x, curvePoint2.y} with control point {currentPoint.x, currentPoint.y}. "Q" command.
     */
    path += ` L ${curvePoint1.x},${curvePoint1.y} Q ${currentPoint.x},${currentPoint.y} ${curvePoint2.x},${curvePoint2.y}`;
  }

  const last = points[points.length - 1];
  path += ` L ${last.x},${last.y}`;

  return [path];
};
