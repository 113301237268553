import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Wizard } from '@vertice/core/src/components/Wizard';
import SelectVendor from './Steps/SelectVendor/SelectVendor';
import { AddExistingContractFormData } from './types';
import AdditionalInformation from './Steps/AdditionalInformation/AdditionalInformation';
import ContractType from './Steps/ContractType/ContractType';
import UploadExistingContract from './Steps/UploadExistingContract/UploadExistingContract';
import ContractAdded from './Steps/ContractAdded/ContractAdded';
import ChooseInitiateRenewal from './Steps/ChooseInitiateRenewal/ChooseInitiateRenewal';
import Confirmation from './Steps/Confirmation/Confirmation';
import AdditionalRequirementsStep from '../SharedRenewalSteps/AdditionalRequirements/AdditionalRequirementsStep';
import RenewalInProgressStep from '../SharedRenewalSteps/RenewalInProgress/RenewalInProgressStep';
import ConfirmRenewalRequirementsStep from '../SharedRenewalSteps/ConfirmRenewalRequirements/ConfirmRenewalRequirementsStep';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import useIntakeForm from '../../../hooks/useIntakeForm';
import useNavigateIf from '@vertice/core/src/hooks/useNavigateIf';
import { useRoutes } from '@verticeone/router/useRoutes';

const defaultValues: AddExistingContractFormData = {
  type: 'add_existing_contract',
  files: [],
  vendor: null,
  products: [],
  lineItems: [],
  totalAnnualCost: null,
  contractCurrency: DEFAULT_CURRENCY,
  billingFrequency: null,
  rollingFrequency: null,
  departmentId: null,
  contractType: null,
  approvedBudget: null,
  signingEntity: '',
  renewalType: null,
  legalReviewRequired: false,
  securityReviewRequired: false,
  piiExpectedToBeHeld: false,
  contactVendorDirectly: true,
  discussMultiYearDeals: true,
  discussPlannedGrowth: true,
  discussCompetitors: true,
  discussCaseStudies: false,
  initiateRenewal: null,
  signatoryName: '',
  signatoryEmail: '',
  vendorContactEmail: '',
  vendorContactName: '',
  contractLength: null,
};

/** @deprecated With the new Non-SaaS feature flag, we want to use the newer AddExistingContractWizardV2 */
const AddExistingContractWizard = () => {
  const routes = useRoutes();
  const { userCanAddExistingContract, isLoading } = useIntakeForm();
  useNavigateIf(!userCanAddExistingContract && !isLoading, routes.CONTRACTS);

  const formMethods = useForm<AddExistingContractFormData>({ defaultValues, mode: 'onChange' });
  const { id: vendorId } = useParams();

  const initialStep = vendorId ? 'upload_existing_contract' : 'select_vendor';

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={() => formMethods.handleSubmit} p={10}>
        <Wizard initialStep={initialStep} options={{ defaultWidth: 800 }}>
          <SelectVendor stepId="select_vendor" />
          <ContractType stepId="contract_type" />
          <UploadExistingContract stepId="upload_existing_contract" fullWidth={true} />
          <AdditionalInformation stepId="additional_information" />
          {/* existing contract has been registered */}
          <ContractAdded stepId="contract_added" />
          {/* choose whether to initiate renewal */}
          <ChooseInitiateRenewal stepId="choose_initiate_renewal" />
          {/* renewal NOT requested */}
          <Confirmation stepId="confirmation" />
          {/* renewal requested */}
          <ConfirmRenewalRequirementsStep stepId="confirm_renewal_requirements" fullWidth={true} />
          <AdditionalRequirementsStep stepId="additional_requirements" />
          <RenewalInProgressStep stepId="renewal_in_progress" />
        </Wizard>
      </Box>
    </FormProvider>
  );
};

export default AddExistingContractWizard;
