import { FieldDef } from '../types';
import {
  getDepartmentJsonSchema,
  getDepartmentJsonSchemaFromMetadata,
  getDepartmentPresenterDefault,
  getDepartmentPresenterSchema,
} from './utils';
import { DepartmentFieldType } from './types';
import { DepartmentFieldBuilderCard } from './DepartmentFieldBuilderCard';
import { DepartmentFieldPresenter } from './DepartmentFieldPresenter';
import { DepartmentFieldBuilderDialog } from './DepartmentFieldBuilderDialog';

export type { DepartmentFieldType, DepartmentFieldMetadata } from './types';
export { departmentFieldSchema, departmentFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<DepartmentFieldType> = {
  type: 'DEPARTMENT',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.DEPARTMENT',
  builderCard: DepartmentFieldBuilderCard,
  builderDialog: DepartmentFieldBuilderDialog,
  presenter: DepartmentFieldPresenter,
  getPresenterDefault: getDepartmentPresenterDefault,
  getPresenterSchema: getDepartmentPresenterSchema,
  getJsonSchemaFromMetadata: getDepartmentJsonSchemaFromMetadata,
  getJsonSchema: getDepartmentJsonSchema,
};
