import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@verticeone/design-system';
import { Card } from '@verticeone/design-system';

import { JournalItemContentComponent } from './types';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useRelevantUsersById } from '../../../../../../../hooks/useRelevantUsersById';
import { getFullName } from '@verticeone/utils/formatting';
import { useLoggedUser } from '@verticeone/auth/src';
import { useResolveUsersInfo } from '../../../../../../../hooks/useResolveUsersInfo';

type DeltaPropertyChange = {
  added: string[];
  removed: string[];
};

type DeltaPropertiesChanges = Record<string, DeltaPropertyChange>;

const deltaAdd = (changes: DeltaPropertiesChanges, property: string, add?: string, remove?: string) => {
  return {
    ...changes,
    [property]: {
      added: [...(changes[property]?.added || []), ...(add ? [add] : [])],
      removed: [...(changes[property]?.added || []), ...(remove ? [remove] : [])],
    },
  };
};

// not tested, going to be changed in RED-668
export const getDelta = (delta: any[]): DeltaPropertiesChanges => {
  let result: DeltaPropertiesChanges = {};
  delta.forEach((deltaItem) => {
    const [operation, target, changes] = deltaItem;
    if (operation === 'add') {
      changes.forEach((change: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_index, value] = change;
        result = deltaAdd(result, target, value, undefined);
      });
    } else if (operation === 'remove') {
      changes.forEach((change: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_index, value] = change;
        result = deltaAdd(result, target, undefined, value);
      });
    } else if (operation === 'change') {
      const property = Array.isArray(target) ? target[0] : target;
      const [from, to] = changes;
      result = deltaAdd(result, property, to, from);
    }
  });

  return result;
};

export const ReassignedTaskJournalItem: JournalItemContentComponent = ({ item }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const loggedUser = useLoggedUser();
  const { usersById: accountUsersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const { data: taskOverview } = useGetTaskOverviewQuery({ accountId, taskId: item.taskId! }, { skip: !item.taskId });

  const { newAssignees, oldAssignees, allAssignees } = useMemo(() => {
    if (!taskOverview) {
      return { newAssignees: undefined, oldAssignees: undefined, allAssignees: [] };
    }
    const assignmentDelta = getDelta(item.delta)['Assignment'];
    if (!assignmentDelta) {
      return { newAssignees: undefined, oldAssignees: undefined, allAssignees: [] };
    }
    const { added, removed } = assignmentDelta;
    const addedAssignees = added.map((urn) => parseUserRef(urn).userId);
    const removedAssignees = removed.map((urn) => parseUserRef(urn).userId);
    return {
      newAssignees: addedAssignees,
      oldAssignees: removedAssignees,
      allAssignees: [...addedAssignees, ...removedAssignees],
    };
  }, [item.delta, taskOverview]);

  const { usersById } = useResolveUsersInfo(allAssignees, accountUsersById);

  if (newAssignees === undefined || oldAssignees === undefined) {
    return null;
  }

  const idsToNames = (userIds: string[]) =>
    userIds
      .map((userId) => {
        const user = usersById[userId];
        if (user) {
          const fullName = getFullName(user) || userId;
          return userId === loggedUser.userId
            ? t('ENTITIES.ACCOUNT_USER.NAME_WITH_YOU_SUFFIX', { userName: fullName })
            : fullName;
        }
        return userId;
      })
      .join(', ');

  return (
    <Card padding={4}>
      <Text variant="body-regular" color="text1">
        <Trans
          i18nKey={'INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.REASSIGNMENT_MESSAGE'}
          components={{ bold: <b /> }}
          values={{
            taskName: taskOverview?.task.name,
            oldAssignees: oldAssignees.length
              ? idsToNames(oldAssignees)
              : t('ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE'),
            newAssignees: newAssignees.length
              ? idsToNames(newAssignees)
              : t('ENTITIES.WORKFLOW_TASK.LABELS.NO_ASSIGNEE'),
          }}
        />
      </Text>
    </Card>
  );
};
