import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { Stack } from '@mui/material';
import { DiscoveryAndUsage } from './DiscoveryAndUsage';
import { Security } from './Security';
import { CloudCostOptimization } from './AWS/AWS';
import { Accounting } from './Accounting';
import { Notifications } from './Notifications';
import { Workflows } from './Workflows';
import React from 'react';

export const NativeIntegrations = () => {
  const { getFeature, isEnabled } = useFeatures();

  const isUsageAnalyticsEnabled = isEnabled(FEATURES.USAGE_ANALYTICS);
  const isSsoEnabled = isEnabled(FEATURES.SSO);
  const isAwsCostInsightEnabled = isEnabled(FEATURES.AWS_COST_INSIGHT);
  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const mergeAccountingIntegrationAllowed =
    mergeIntegrationFeature?.enabled && mergeIntegrationFeature.properties?.accounting;
  const isAccountingEnabled = isEnabled(FEATURES.INTEGRATIONS) && !!mergeAccountingIntegrationAllowed;
  const isNotificationsIntegrationEnabled = isEnabled(FEATURES.INTEGRATION_SLACK);
  const isWorkflowsIntegrationEnabled =
    isEnabled(FEATURES.INTEGRATION_JIRA) || isEnabled(FEATURES.INTEGRATION_VISOTRUST);

  return (
    <Stack gap={8}>
      {isUsageAnalyticsEnabled && <DiscoveryAndUsage />}
      {isSsoEnabled && <Security />}
      {isAwsCostInsightEnabled && <CloudCostOptimization />}
      {isAccountingEnabled && <Accounting />}
      {isNotificationsIntegrationEnabled && <Notifications />}
      {isWorkflowsIntegrationEnabled && <Workflows />}
    </Stack>
  );
};
