import { useMainLayout } from '../../../layouts/Main/LayoutWrapper';
import { useEffect } from 'react';
import { WorkflowEditorPage as CoreWorkflowEditorPage } from '@vertice/core/src/modules/intelligentWorkflows';

export const WorkflowEditorPage = () => {
  const { setOpenOverride } = useMainLayout();

  useEffect(() => {
    setOpenOverride(false);
    return () => setOpenOverride(undefined);
  }, [setOpenOverride]);

  return <CoreWorkflowEditorPage />;
};
