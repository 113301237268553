import { useDebouncedCallback } from 'use-debounce';
import { enqueueSnackbar } from 'notistack';
import { FieldValues, FormState } from 'react-hook-form';
import { useDraftUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useTranslation } from 'react-i18next';

type UseSaveDraftDebouncedParams = {
  accountId: string;
  taskId: string;
  formState?: FormState<FieldValues>;
};

export const useSaveDraftDebounced = ({ accountId, taskId, formState }: UseSaveDraftDebouncedParams) => {
  const { t } = useTranslation();
  const [updateDraftUserTask] = useDraftUserTaskMutation();
  return useDebouncedCallback(
    (formValues, visitedPages) => {
      if (formState && (formState.isSubmitting || formState.isSubmitted)) return;
      updateDraftUserTask({
        accountId,
        taskId,
        body: {
          ...formValues,
          ...visitedPages,
        },
      }).catch((e) => {
        enqueueSnackbar(t('PREFERENCES.USER.SNACKBAR.ERROR'), {
          variant: 'error',
        });
      });
    },
    500,
    { maxWait: 8000 }
  );
};
