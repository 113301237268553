import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@verticeone/design-system';
import { useRecommendationData } from './Recommendation/useRecommendationData';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { AWS_DEFAULT_CURRENCY } from '@vertice/dashboard/src/modules/cloud/constants';

const TotalCostInfo = () => {
  const { data } = useRecommendationData();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.TOTAL_COST_INFO' });
  const formatCurrency = useFormatCurrency();

  if (!!data?.uncoverableCost) {
    return (
      <Alert
        color="warning"
        size="M"
        title={t('TITLE')}
        subtitle={t('DESCRIPTION', {
          cost: formatCurrency(data.uncoverableCost, {
            currency: AWS_DEFAULT_CURRENCY,
            maximumFractionDigits: 0,
          }),
        })}
        testId="total-cost-alert"
        variant="ghost"
      />
    );
  }
};

export default TotalCostInfo;
