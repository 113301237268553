import { Stack } from '@mui/material';
import { toConstantCase } from '@verticeone/utils/strings';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { BreadcrumbItem, Loader, PageHeader, Tab, Tabs, TabsBottomLineWrapper } from '@verticeone/design-system';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { PageNotFound } from '@vertice/core/src/modules/errors/PageNotFound';

const getTabLabelKey = (tabId: string) => `INTEGRATIONS.TABS.${toConstantCase(tabId)}`;

export const Integrations = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { INTEGRATIONS } = useRoutes();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const routes = useRoutes();
  const [headerActions, setHeaderActions] = useState<ReactNode>([]);
  const [breadcrumbsItems, setBreadcrumbsItems] = useState<BreadcrumbItem[]>([]);
  const [isFullHeight, setIsFullHeight] = useState(false);
  const { isEnabled, getFeature } = useFeatures();

  const mergeIntegrationFeature = getFeature(FEATURES.INTEGRATION_MERGE);
  const mergeAccountingIntegrationAllowed =
    mergeIntegrationFeature?.enabled && mergeIntegrationFeature.properties?.accounting;
  const isAccountingEnabled = isEnabled(FEATURES.INTEGRATIONS) && !!mergeAccountingIntegrationAllowed;

  const isNativeVisible =
    [
      FEATURES.USAGE_ANALYTICS,
      FEATURES.SSO,
      FEATURES.AWS_COST_INSIGHT,
      FEATURES.INTEGRATION_SLACK,
      FEATURES.INTEGRATION_JIRA,
      FEATURES.INTEGRATION_VISOTRUST,
    ].some((feature) => isEnabled(feature)) || isAccountingEnabled;

  const isCustomVisible = [FEATURES.INTEGRATION_GENERIC_OUTBOUND, FEATURES.VERTICE_API].some((feature) =>
    isEnabled(feature)
  );

  const availableTabs = useMemo(
    () =>
      [
        {
          key: INTEGRATIONS.NATIVE.PATH,
          path: INTEGRATIONS.NATIVE.ABSOLUTE_PATH,
          isVisible: isUserAdmin && isNativeVisible,
        },
        {
          key: INTEGRATIONS.CUSTOM.PATH,
          path: INTEGRATIONS.CUSTOM.ABSOLUTE_PATH,
          isVisible: isUserAdmin && isCustomVisible,
        },
      ].filter(({ isVisible }) => isVisible),
    [INTEGRATIONS, isCustomVisible, isNativeVisible, isUserAdmin]
  );

  const activeTab = availableTabs.find(({ path }) => matchPath(`${path}/*`, location.pathname));
  const isCurrentPathTabPage =
    INTEGRATIONS.CUSTOM.ABSOLUTE_PATH === location.pathname || INTEGRATIONS.NATIVE.ABSOLUTE_PATH === location.pathname;
  const isTabsVisible = availableTabs.length > 1 && isCurrentPathTabPage && !isFullHeight;

  useEffect(() => {
    if (!activeTab && availableTabs.length > 0) {
      navigate(availableTabs[0].path, { replace: true });
    }
  }, [activeTab, navigate, availableTabs, INTEGRATIONS]);

  const items: BreadcrumbItem<typeof Link>[] = [
    {
      label: t('INTEGRATIONS.TITLE'),
      component: Link,
      to: generatePath(routes.INTEGRATIONS.ABSOLUTE_PATH),
    },
    ...(activeTab
      ? [
          {
            label: t(getTabLabelKey(activeTab.key)),
            component: Link,
            to: activeTab.path,
          },
        ]
      : []),
    ...breadcrumbsItems,
  ];

  if (!isUserAdmin) {
    return <PageNotFound />;
  }

  const gap = isFullHeight ? 0 : 8;
  const padding = isFullHeight ? 0 : 6;

  return (
    <>
      {!isFullHeight && (
        <PageHeader breadcrumb={<PageHeader.Breadcrumbs items={items} />} color="primary" actions={headerActions} />
      )}
      <Stack gap={gap} p={padding} pt={0} flex={1}>
        {activeTab ? (
          <>
            {isTabsVisible && (
              <TabsBottomLineWrapper>
                <Tabs variant="outlined" value={activeTab.path} onChange={(_, value) => navigate(value)}>
                  {availableTabs.map(({ path, key }) => (
                    <Tab key={path} value={path} label={t(getTabLabelKey(key))} />
                  ))}
                </Tabs>
              </TabsBottomLineWrapper>
            )}
            <Stack flex={1}>
              <Outlet context={{ setHeaderActions, setBreadcrumbsItems, setIsFullHeight }} />
            </Stack>
          </>
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
