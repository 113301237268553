import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type MonitoringValueCheckResult,
  useLatestOptimizationCheckQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { productKeyToTitleMap } from '../../../../../../utils';
import type { DataEnhanced, UseData } from '../../../types';
import { useCreateLabel } from '../../../utils';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

type RDSData = {
  region: string;
  instanceFamily: string;
  databaseEngine: string;
  databaseEdition: string;
  deploymentOption: string;
  licenseModel: string;
  instanceMemoryType: string;
  instanceSize: string;
  sizeFlexibilityGroup: string;
  riInstanceNormalizationFactor: number;
  flexGroupMinSize: string;
  sizeFlexible: boolean;
  riCoverage: number;
  newRiCoverage: number;
  proposedNumberOfInstances: number;
  riUnits: number;
  newRiUnits: number;
  totalUnits: number;
  onDemandUsage: number;
  newOnDemandUsage: number;
  reservedUsage: number;
  newReservedUsage: number;
  addedUnusedUsage: number;
  onDemandCost: number;
  newOnDemandCost: number;
  reservedCost: number;
  newReservedCost: number;
  totalCost: number;
  savingsCostFrom: number;
  savingsCostTo: number;
  savingsPercentFrom: number;
  savingsPercentTo: number;
  onDemandRate: number;
  effectiveRate: number;
  reservedUtilization: number;
  missingRiLookup: boolean;
  from: string;
  to: string;
};

export type RDSDataEnhanced = DataEnhanced<RDSData>;

export const useRDSData = (): UseData<RDSData> => {
  const createLabel = useCreateLabel();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO.OPTIMIZE' });
  const { accountId } = useAccountContext();

  const { data, isFetching } = useLatestOptimizationCheckQuery(
    {
      accountId: accountId!,
      checkCode: 'RDS_RI_COVERAGE_LAST_DAYS',
    },
    {
      skip: !accountId,
    }
  );

  const computedData = useMemo(() => {
    if (
      data?.monitoringLatestQuery?.__typename !== 'MonitoringResult' ||
      data.monitoringLatestQuery?.items?.length === 0
    ) {
      return undefined;
    }

    return getTableData(
      // The array of all data is under the 'result' property, not under 'items'.
      // The 'items' property will always have only one array item.
      data.monitoringLatestQuery?.items?.[0]?.results as MonitoringValueCheckResult,
      {
        region: 'region',
        instance_family: 'instanceFamily',
        database_engine: 'databaseEngine',
        database_edition: 'databaseEdition',
        deployment_option: 'deploymentOption',
        license_model: 'licenseModel',
        instance_memory_type: 'instanceMemoryType',
        instance_size: 'instanceSize',
        size_flexibility_group: 'sizeFlexibilityGroup',
        ri_instance_normalization_factor: 'riInstanceNormalizationFactor',
        flex_group_min_size: 'flexGroupMinSize',
        size_flexible: 'sizeFlexible',
        ri_coverage: 'riCoverage',
        new_ri_coverage: 'newRiCoverage',
        proposed_number_of_instances: 'proposedNumberOfInstances',
        ri_units: 'riUnits',
        new_ri_units: 'newRiUnits',
        total_units: 'totalUnits',
        on_demand_usage: 'onDemandUsage',
        new_on_demand_usage: 'newOnDemandUsage',
        reserved_usage: 'reservedUsage',
        new_reserved_usage: 'newReservedUsage',
        added_unused_usage: 'addedUnusedUsage',
        on_demand_cost: 'onDemandCost',
        new_on_demand_cost: 'newOnDemandCost',
        reserved_cost: 'reservedCost',
        new_reserved_cost: 'newReservedCost',
        total_cost: 'totalCost',
        savings_cost_from: 'savingsCostFrom',
        savings_cost_to: 'savingsCostTo',
        savings_percent_from: 'savingsPercentFrom',
        savings_percent_to: 'savingsPercentTo',
        on_demand_rate: 'onDemandRate',
        effective_rate: 'effectiveRate',
        reserved_utilization: 'reservedUtilization',
        missing_ri_lookup: 'missingRiLookup',
        from: 'from',
        to: 'to',
      }
    ) as RDSData[];
  }, [data]);

  return {
    isFetching,
    data: computedData?.map(
      (item): RDSDataEnhanced => ({
        ...item,
        id: `${item.instanceFamily}-${item.databaseEngine}-${item.region}-${item.sizeFlexible}`,
        isOverCommitted: item.riCoverage > item.newRiCoverage + 0.1,
        isUnderCommitted: item.riCoverage < item.newRiCoverage - 0.1,
        chips: {
          showCount: 4,
          items: [
            createLabel('PRODUCT', productKeyToTitleMap.rds),
            createLabel('REGION', item.region),
            createLabel('SIZE_FLEXIBILITY', t(`CHIPS.SIZE_FLEXIBILITY.${item.sizeFlexible ? 'TRUE' : 'FALSE'}`)),
            createLabel('DATABASE_ENGINE', item.databaseEngine),
            createLabel(
              'DATABASE_EDITION',
              t(`CHIPS.DATABASE_EDITION_${item.databaseEdition.toUpperCase()}`, { defaultValue: item.databaseEdition })
            ),
            createLabel('DEPLOYMENT_OPTION', item.deploymentOption),
            createLabel('LICENSE_MODEL', item.licenseModel),
            createLabel('INSTANCE_MEMORY_TYPE', item.instanceMemoryType),
          ],
        },
      })
    ),
  };
};
