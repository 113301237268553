import { GridRowModel } from '@mui/x-data-grid-pro';
import { Product } from '@vertice/slices';
import { asProductItem } from '../ProductList/utils';
import { CostModelProductItem } from './types';
import { TFunction } from 'i18next';
import { ValidateRowResult } from '../../../../../components/EditableDataGrid/useEditableDataGrid';
import dayjs from 'dayjs';

export const asCostModelProductItem =
  (vendorId?: string) =>
  (newRow: GridRowModel, vendorProducts: Product[]): CostModelProductItem => {
    const hasSpan =
      newRow.allocationSpan?.startDate || newRow.allocationSpan?.endDate || newRow.allocationSpan?.rollingFrequency;

    return {
      ...asProductItem(newRow, vendorProducts),
      licenseCost: newRow.licenseCost,
      totalCost: newRow.totalCost,
      vendorId: vendorId,
      allocationSpan: hasSpan
        ? {
            startDate: newRow.allocationSpan?.startDate,
            endDate: newRow.allocationSpan?.endDate,
            rollingFrequency: newRow.allocationSpan?.rollingFrequency,
          }
        : undefined,
    };
  };

export const validateProductItem =
  (t: TFunction) =>
  (row: CostModelProductItem): ValidateRowResult<CostModelProductItem> => {
    const startDate = row.allocationSpan?.startDate;
    const endDate = row.allocationSpan?.endDate;
    if (startDate && endDate && dayjs(endDate).isBefore(dayjs(startDate))) {
      return { isValid: false, message: t('ENTITIES.COST_MODEL_PRODUCT_LIST.ERROR_DIALOG.END_BEFORE_START_MESSAGE') };
    }

    return { isValid: true };
  };
