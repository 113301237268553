import { useGetColumnBuilder } from '../../useGetColumnBuilder';
import { ContractListColumn } from '../../types';
import { useMemo } from 'react';
import { useVisibleCustomFields } from '../../../CustomFields/useVisibleCustomFields';

const AVAILABLE_PREDEFINED_COLUMNS: ContractListColumn[] = [
  // Note: No custom fields here

  ContractListColumn.ANNUAL_COST,
  ContractListColumn.AUTO_RENEWAL_DATE,
  ContractListColumn.BILLING_FREQUENCY,
  ContractListColumn.CATEGORY,
  ContractListColumn.CONTRACT_LENGTH,
  ContractListColumn.DEADLINE,
  ContractListColumn.DEPARTMENT,
  ContractListColumn.KEY_COST,
  ContractListColumn.NEXT_ROLL_DATE,
  ContractListColumn.OWNER,
  ContractListColumn.PAYMENT_TERMS,
  ContractListColumn.REGION,
  ContractListColumn.RENEWAL_DATE,
  ContractListColumn.ROLLING_FREQUENCY,
  ContractListColumn.SIGNING_ENTITY,
  ContractListColumn.STAGE,
  ContractListColumn.TARGET_SIGN_DATE,
  ContractListColumn.TOTAL_COST,
  ContractListColumn.VENDOR,
];

export const useContractColumns = () => {
  const { buildColumn } = useGetColumnBuilder({ resolveCustomFields: true });
  const { visibleFields: visibleCustomFieldsRaw } = useVisibleCustomFields();

  const visibleFields = useMemo(() => {
    return [...AVAILABLE_PREDEFINED_COLUMNS, ...(visibleCustomFieldsRaw?.map((f) => f.id) ?? [])];
  }, [visibleCustomFieldsRaw]);

  return useMemo(
    () => (buildColumn ? visibleFields.map((column) => buildColumn(column as ContractListColumn)) : []),
    [buildColumn, visibleFields]
  );
};
