import { Stack } from '@mui/material';
import { Placeholder, Text } from '@verticeone/design-system';
import AnimatedTextNumber from '../AnimatedTextNumber';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTimeToCompleteTaskContext } from './TimeToCompleteTaskContext';
import { ChartStatsWrapper } from '../ChartCard';

const StatItemWrapper = ({ children }: { children: ReactNode }) => {
  return <Stack gap="2px">{children}</Stack>;
};

type StatItemDescriptionProps = {
  values?: { [key: string]: number | string };
  i18nKey: string;
};

const StatItemDescription = ({ values, i18nKey }: StatItemDescriptionProps) => {
  return (
    <Text variant="body-regular" size="S" color="text1">
      <Trans
        i18nKey={i18nKey}
        components={{ b: <Text variant="body-bold" size="S" color="text1" /> }}
        values={values}
      />
    </Text>
  );
};

type AnimatedStatItemType = {
  value: number;
  label: ReactNode;
};

const AnimatedStatItem = ({ value, label }: AnimatedStatItemType) => {
  return (
    <Stack overflow="hidden" direction="row">
      <AnimatedTextNumber variant="heading" size="M" color="text1">
        {value}
      </AnimatedTextNumber>
      &nbsp;
      <Text variant="heading" size="M" color="text1">
        {label}
      </Text>
    </Stack>
  );
};

const TimeToCompleteTaskStatsBase = ({ children }: PropsWithChildren) => (
  <ChartStatsWrapper flex={1} justifyContent="flex-end">
    <Stack gap={4}>{children}</Stack>
  </ChartStatsWrapper>
);

type TimeToCompleteTaskStatsProps = {
  averageDaysSinceClosed: number;
  total: number;
};

const TimeToCompleteTaskStats = ({ averageDaysSinceClosed, total }: TimeToCompleteTaskStatsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { tooltipData } = useTimeToCompleteTaskContext();

  return (
    <TimeToCompleteTaskStatsBase>
      {tooltipData?.category !== undefined ? (
        <StatItemWrapper>
          <Text variant="heading" size="M" color="text1">
            {t('TIME_TO_COMPLETE_TASK.TASK', { count: tooltipData?.total || 0 })}
          </Text>
          <StatItemDescription
            values={{ count: tooltipData?.total || 0, time: `${tooltipData?.category}d` || 0 }}
            i18nKey="INTELLIGENT_WORKFLOWS.DASHBOARD.TIME_TO_COMPLETE_TASK.TO_COMPLETE_TASK"
          />
        </StatItemWrapper>
      ) : (
        <StatItemWrapper>
          <AnimatedStatItem
            value={averageDaysSinceClosed}
            label={t('TIME_TO_COMPLETE_TASK.DAY', { count: averageDaysSinceClosed })}
          />
          <StatItemDescription
            values={{ count: total || 0 }}
            i18nKey="INTELLIGENT_WORKFLOWS.DASHBOARD.TIME_TO_COMPLETE_TASK.ON_AVERAGE_TO_COMPLETE"
          />
        </StatItemWrapper>
      )}
    </TimeToCompleteTaskStatsBase>
  );
};

const Skeleton = () => (
  <TimeToCompleteTaskStatsBase>
    <StatItemWrapper>
      <Placeholder variant="text" width="64px" height={28} />
      <Placeholder variant="text" width="128px" height={14} />
    </StatItemWrapper>
  </TimeToCompleteTaskStatsBase>
);

TimeToCompleteTaskStats.Skeleton = Skeleton;

export { TimeToCompleteTaskStats };
