import { Stack } from '@mui/material';
import styled from 'styled-components';

export const ShowOnRowHover = styled(Stack)({
  opacity: 0,
  maxHeight: 0,
  transitionDuration: '200ms',
  transitionTimingFunction: 'ease-in-out',
  transitionProperty: 'opacity, max-height',
  overflow: 'hidden',

  '.Mui-hovered &': {
    opacity: 1,
    maxHeight: '30px',
  },
});

export const ShowOnRowHoverWrapper = styled(Stack)(({ gap, theme }) => ({
  gap: 0,
  transition: '200ms ease-in-out gap',
  '.Mui-hovered &': {
    gap: theme.spacing(gap),
  },
}));
