import { Node, Edge } from '@xyflow/react';
import {
  WorkflowStartNode,
  WorkflowEndNode,
  WorkflowTaskNode,
  WorkflowGatewayNode,
  WorkflowLabelNode,
} from '../model/types';

export type RendererStartNode = Node<WorkflowStartNode, 'start'>;
export type RendererEndNode = Node<WorkflowEndNode, 'end'>;
export type RendererTaskNode = Node<WorkflowTaskNode, 'task'>;
export type RendererGatewayNode = Node<WorkflowGatewayNode, 'gateway'>;
export type RendererLabelNode = Node<WorkflowLabelNode, 'label'>;

export const isNodeTaskNode = (node?: Node): node is RendererTaskNode => node?.data.kind === 'task';
export const isNodeEndNode = (node?: Node): node is RendererEndNode => node?.data.kind === 'end';
export const isNodeGatewayNode = (node?: Node): node is RendererGatewayNode => node?.data.kind === 'gateway';
export const isNodeLabelNode = (node?: Node): node is RendererLabelNode => node?.data.kind === 'label';

export type RendererNode =
  | RendererStartNode
  | RendererEndNode
  | RendererTaskNode
  | RendererLabelNode
  | RendererGatewayNode;

export type RendererEdge = Edge & { name?: string };
