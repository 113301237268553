import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

type CardsListProps = {
  children: ReactNode;
};

export const CardsList: FC<CardsListProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start',
        flexWrap: 'wrap',
        maxWidth: 1800, // so 4 cards are max for one line
        gap: 4,
      }}
    >
      {children}
    </Box>
  );
};
