import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@verticeone/design-system';
import { Placeholder } from '@verticeone/design-system';
import { IconButton } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import { Dialog } from '@verticeone/design-system';

import { useGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowViewer } from '../../../workflowSchema';
import { Definitions } from '../../../definitionsTypes';
import { useAccountContext } from '../../../../account/AccountContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { WorkflowVersionRow } from '../types';
import { useLocalizeVersionName } from '../utils';
import { Link } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { createServiceIdentifier } from '../../../../../hooks/workflows/refUtils';
import { getWorkflowDefinitionsFromDefinitions } from '../../../definitions/processDefinition';
import { getWorkflowVersionName } from '../../../hooks/useGetWorkflowVersionName';
import { useFeatures } from '../../../../features/useFeatures';
import { FEATURES } from '../../../../features/constants';

export const useCreateWorkflowVersionLabel = () => {
  const { t } = useTranslation();
  const localizeVersionName = useLocalizeVersionName();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  return (row?: WorkflowVersionRow) =>
    row
      ? t('ENTITIES.WORKFLOW_VERSION.LABEL', {
          workflowName: row.workflowName,
          versionName: getWorkflowVersionName(row, isWorkflowsPageV2Enabled, true, localizeVersionName),
        })
      : '';
};

export type WorkflowVersionSchemaProps = {
  serviceRef?: string;
  workflowId: string;
  versionId: string;
  onClose: () => void;
};

export const WorkflowPreviewDialog: FC<WorkflowVersionSchemaProps> = ({
  serviceRef,
  workflowId,
  versionId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const createWorkflowVersionLabel = useCreateWorkflowVersionLabel();
  const {
    data: workflowVersionData,
    isLoading,
    isFetching,
  } = useGetWorkflowVersionQuery({ accountId, workflowId, workflowVersion: versionId });

  const workflowVersion = isLoading || isFetching ? undefined : workflowVersionData;
  const workflowDefinitions = getWorkflowDefinitionsFromDefinitions(
    workflowVersion?.workflowDefinition as Definitions | undefined
  );

  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();
  const editorPath = serviceRef
    ? generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.VERSION.EDITOR, {
        workflowId,
        versionId,
        serviceId: createServiceIdentifier(serviceRef),
      })
    : undefined;

  return (
    <Dialog size="M" fullHeight width={2000} open noPadding onClose={onClose}>
      <Stack padding={6} justifyContent="space-between" direction="row">
        {workflowVersion === undefined ? (
          <Placeholder width="300px" />
        ) : (
          <Stack gap={2} direction="row" alignItems="center">
            <Text variant="heading" size="M">
              {createWorkflowVersionLabel(workflowVersionData)}
            </Text>
            {workflowVersionData?.status === 'PENDING' && (
              <ChipButton variant="ghost" color="primary">
                {t('ENTITIES.WORKFLOW_VERSION.DRAFT')}
              </ChipButton>
            )}
            {workflowVersionData?.status === 'ACTIVE' && (
              <ChipButton variant="ghost" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}>
                {t('ENTITIES.WORKFLOW_VERSION.LIVE')}
              </ChipButton>
            )}
          </Stack>
        )}
        <Stack direction="row" gap={2}>
          {workflowVersionData?.status === 'PENDING' && editorPath && (
            <Button component={Link} to={editorPath} variant="solid" color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}>
              {t('ENTITIES.WORKFLOW_VERSION.ACTIONS.EDIT')}
            </Button>
          )}
          <IconButton icon={Close} variant="ghost" onClick={onClose} />
        </Stack>
      </Stack>
      <Stack height="100vh" width="100%">
        {workflowDefinitions ? (
          <WorkflowViewer workflowDefinitions={workflowDefinitions} />
        ) : (
          <Placeholder height="100%" />
        )}
      </Stack>
    </Dialog>
  );
};
