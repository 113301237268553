import { FC, useEffect, useMemo, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useGetJiraWebhookConfigQuery,
  useListEditJiraIssueTypeFieldsQuery,
  useListJiraFieldOptionsQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { useCatalogResources } from '../../../../WorkflowViewer/useCatalogResources';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { WorkflowVariables } from '../../../VariableMapper/types';
import { getRowsCount } from '../../../VariableMapper/utils';
import {
  getCorrespondingTaskService,
  ISSUE_TYPE_ID_NAME,
  PROJECT_ID_NAME,
  transformJiraToWorkflowLikeVariables,
} from '../JiraCommon/utils';
import { EditServiceTaskBase } from '../types';
import { REQUIRED_JIRA_SYNC_FIELDS } from '../utils';
import { JiraSyncTaskFormData } from './formSchema';
import { JiraSyncForm } from './JiraSyncForm';
import { getAvailableCreateJiraTasks, getJiraSyncInputMapping } from './utils';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraSync: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);
  const { xTypeCatalogResources } = useCatalogResources();
  const syncWebhookQuery = useGetJiraWebhookConfigQuery({ accountId });
  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const isIntegrationActive = integrationData?.status === 'ACTIVE';

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
    displayArrayType: true,
  });

  const [workflowVariables, setWorkflowVariables] = useState<WorkflowVariables>({
    request: requestVariables,
    udfs: udfVariables,
  });
  const handleSetWorkflowVariables = (newValue: WorkflowVariables) => {
    setWorkflowVariables(newValue);
  };

  useEffect(() => {
    setWorkflowVariables({ request: requestVariables, udfs: udfVariables });
  }, [requestVariables, udfVariables]);

  const allVariables = useMemo(() => [...workflowVariables.udfs, ...workflowVariables.request], [workflowVariables]);

  const [referencedTaskId, setReferencedTaskId] = useState<string | undefined>();
  // Initial referencedTaskId setup
  useEffect(() => {
    if (!task || !taskService || !allVariables.length) {
      setReferencedTaskId(undefined);
    } else if (!referencedTaskId) {
      const { createJiraTasksByTickets } = getAvailableCreateJiraTasks(allVariables);
      const { ticketId } = getJiraSyncInputMapping(task);
      setReferencedTaskId((ticketId && createJiraTasksByTickets[ticketId]?.id) || '');
    }
  }, [allVariables, referencedTaskId, task, taskService]);

  // Field type used in Jira create this Jira Sync ticket references.
  // So we can show its properties to be synced.
  const [projectId, issueTypeId] = useMemo(() => {
    if (referencedTaskId === undefined) return [undefined, undefined];

    const referencedTask = processDefinition.process.tasks?.filter(
      (processTask) => processTask.task.id === referencedTaskId
    )[0];

    if (referencedTask) {
      const ioMappingConfiguration = referencedTask.task.configurations?.find(isIOMappingConfiguration);
      const issueTypeField = ioMappingConfiguration?.mapping.inputFields.find(
        (input) => input.name === ISSUE_TYPE_ID_NAME
      );
      const projectField = ioMappingConfiguration?.mapping.inputFields.find((input) => input.name === PROJECT_ID_NAME);
      return [projectField ? projectField.value : null, issueTypeField ? issueTypeField.value : null];
    }

    return [null, null];
  }, [processDefinition, referencedTaskId]);

  const filterStatus = useMemo(() => {
    return taskService ? getJiraSyncInputMapping(task).filterStatus : [];
  }, [task, taskService]);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListEditJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: projectId ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !projectId || !issueTypeId }
  );

  const { data: statuses, isLoading: isLoadingStatuses } = useListJiraFieldOptionsQuery(
    {
      accountId: accountId,
      projectId: projectId ?? '',
      issueTypeId: issueTypeId ?? '',
      fieldId: 'status',
    },
    { skip: !projectId || !issueTypeId }
  );

  const jiraVariables = useMemo(() => {
    return issueTypeFieldsData?.fields
      ? transformJiraToWorkflowLikeVariables(issueTypeFieldsData?.fields, xTypeCatalogResources)
      : undefined;
  }, [issueTypeFieldsData, xTypeCatalogResources]);

  const [defaultValues, setDefaultValues] = useState<JiraSyncTaskFormData | undefined>();

  useEffect(() => {
    if (!task || referencedTaskId === undefined) return undefined;

    setDefaultValues({
      name: task.task.name || '',
      description: task.task.description || '',
      taskId: referencedTaskId || '',
      // This is fallback default. Setup default from previously saved variables is done in JiraSyncForm,
      // because it needs to access RHF api dynamically after values for variables are known/pre-created and this has to wait
      // until taskId is known -> Jira issue is known -> Jira issue's fields are known
      variables: [],
      requiredVariables: [],
      filterStatus: filterStatus,
    });
  }, [task, referencedTaskId, filterStatus]);

  const mapperRowsCount = useMemo(() => {
    if (task) {
      const { variables } = getJiraSyncInputMapping(task);
      return getRowsCount(variables, REQUIRED_JIRA_SYNC_FIELDS, 'from');
    }
  }, [task]);

  if (!taskService || !defaultValues) return null;

  return (
    <JiraSyncForm
      task={task}
      taskService={taskService}
      defaultValues={defaultValues}
      workflowVariables={workflowVariables}
      setWorkflowVariables={handleSetWorkflowVariables}
      isLoadingIntegration={isLoadingIntegration}
      {...restProps}
      hasReferencedIssueType={!!issueTypeId}
      noReferenceIssuePlaceholder={'Není jira field'}
      onTaskChange={setReferencedTaskId}
      syncWebhookQuery={syncWebhookQuery}
      integrationWrapperProps={{
        isLoading: isLoadingIntegration,
        isActive: isIntegrationActive,
      }}
      completeCriteriaProps={{
        isLoading: isLoadingStatuses,
        availableStatuses: statuses?.items ?? [],
      }}
      variableMapperProps={{
        otherVariables: jiraVariables,
        isLoading: jiraVariables === undefined || isFetchingIssueTypeFields,
        mapperRowsCount,
      }}
    />
  );
};
