import React from 'react';
import { useTranslation } from 'react-i18next';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { LineItem, LineItemsTableColumn } from '../types';
import { assertExhausted } from '@verticeone/utils/logic';
import { EditableLineItemTypeCell, LineItemTypeCell } from '../cells/LineItemTypeCell';
import { isNil } from 'lodash';
import { useFormatNumber } from '@verticeone/utils/formatting';
import { DataGridColDef } from '@verticeone/design-system';
import { EditableDescriptionCell } from '../cells/EditableDescriptionCell';
import { EditableQuantityCell } from '../cells/EditableQuantityCell';

export const useLineItemColumnBuilder = <T extends LineItem>() => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.LINE_ITEMS_TABLE' });
  const formatNumber = useFormatNumber();

  const buildColumn = (column: LineItemsTableColumn): DataGridColDef<T> | null => {
    switch (column) {
      case LineItemsTableColumn.LINE_ITEM_TYPE:
        return {
          field: 'lineItemType',
          headerName: t('LINE_TYPE'),
          disableColumnMenu: true,
          renderCell: (params: GridRenderCellParams) => <LineItemTypeCell {...params} />,
          renderEditCell: (params: GridRenderCellParams) => <EditableLineItemTypeCell {...params} />,
          editable: true,
          sortable: false,
          minWidth: 130,
        };
      case LineItemsTableColumn.DESCRIPTION:
        return {
          field: 'description',
          headerName: t('DESCRIPTION'),
          disableColumnMenu: true,
          renderEditCell: (params) => <EditableDescriptionCell {...params} />,
          editable: true,
          sortable: false,
          flex: 1,
        };
      case LineItemsTableColumn.QUANTITY:
        return {
          field: 'quantity',
          headerName: t('QUANTITY'),
          renderCell: (params) => (isNil(params.value) ? '' : formatNumber(params.value)),
          renderEditCell: (params) => <EditableQuantityCell {...params} />,
          disableColumnMenu: true,
          editable: true,
          sortable: false,
          minWidth: 110,
        };
      default:
        assertExhausted(column);
    }
    return null;
  };

  return { buildColumn };
};
