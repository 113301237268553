import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldPath, useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';
import { AddExistingContractFormData } from '../../types';
import {
  EvaluateCostModelApiResponse,
  useEvaluateCostModelMutation,
} from '@vertice/slices/src/openapi/codegen/contractCostAPI';
import { isNil } from 'lodash';
import { transformationsForAdvancedLinearApproximation } from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { isRollingRollFrequency } from '@vertice/core/src/modules/saas/contract/computed';
import dayjs from 'dayjs';

// Fields that trigger cost computation
const COST_MODEL_RELATED_FIELDS: Set<string | number> = new Set<FieldPath<AddExistingContractFormData>>([
  'startDate',
  'renewalDate',
  'rollingFrequency',
  'products',
  'lineItems',
  'vendor',
  'annualCostOverride.enabled',
  'annualCostOverride.amount',
  'totalCostOverride.enabled',
  'totalCostOverride.amount',
]);

export const useComputedCosts = () => {
  const { t } = useTranslation();
  const { formToCostModel } = transformationsForAdvancedLinearApproximation;
  const { enqueueSnackbar } = useSnackbar();
  const { watch, getValues } = useFormContext<AddExistingContractFormData>();
  const [changedField, setChangedField] = useState<string>();
  const [computedCosts, setComputedCosts] = useState<EvaluateCostModelApiResponse>();
  const [calculateCosts, { isLoading }] = useEvaluateCostModelMutation();

  const computeAllCosts = useCallback(
    async (name?: FieldPath<AddExistingContractFormData>) => {
      setChangedField(name);
      try {
        const rollingFrequency = getValues('rollingFrequency');
        const isRollingContract = rollingFrequency && isRollingRollFrequency(rollingFrequency);
        const startDate = getValues('startDate');

        // For rolling contract we set end date to today's date to evaluate TCV value
        let endDate = getValues('renewalDate');

        if (isRollingContract) {
          endDate = dayjs(startDate).isBefore(dayjs()) ? dayjs().format('YYYY-MM-DD') : startDate;
        }

        const products = getValues('products');
        const lineItems = getValues('lineItems');
        const annualCostOverride = getValues('annualCostOverride');
        const totalCostOverride = getValues('totalCostOverride');

        const response = await calculateCosts({
          costModelRequest: {
            startDate,
            endDate,
            rollingFrequency: isNil(rollingFrequency) ? undefined : rollingFrequency,
            costModel: formToCostModel({
              products,
              lineItems,
              annualCostOverride,
              totalCostOverride,
            }),
          },
        });
        if ('data' in response) {
          setComputedCosts(response.data);
        }
      } catch (error) {
        enqueueSnackbar(t('SNACKBAR.ERRORS.FAILED_TO_COMPUTE_COST'), { variant: 'error' });
      } finally {
        setChangedField(undefined);
      }
    },
    [calculateCosts, enqueueSnackbar, getValues, setComputedCosts, formToCostModel, t]
  );

  const debouncedComputeAllCosts = useDebouncedCallback(computeAllCosts, 500, { maxWait: 1000 });

  useEffect(() => {
    void computeAllCosts();
    const subscription = watch((form, { name }) => {
      if (!name) {
        return;
      }
      if (COST_MODEL_RELATED_FIELDS.has(name)) {
        void debouncedComputeAllCosts(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, computeAllCosts, debouncedComputeAllCosts]);

  return {
    data: computedCosts,
    isLoading,
    changedField,
  };
};
