import { z } from 'zod';

export const rollingFrequencyFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const TYPES = ['NO', 'ONE_OFF', 'MONTHLY', 'QUARTERLY', 'BIANNUALLY', 'ANNUALLY'];

export const rollingFrequencyFieldSchema = z.object({
  name: z.string(),
  type: z.literal('ROLLING_FREQUENCY'),
  metadata: rollingFrequencyFieldMetadataSchema,
});

export type RollingFrequencyFieldMetadata = z.infer<typeof rollingFrequencyFieldMetadataSchema>;

export type RollingFrequencyFieldType = z.infer<typeof rollingFrequencyFieldSchema>;
