import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const agentsAPI = createApi({
  reducerPath: 'agentsAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.AGENTS),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
