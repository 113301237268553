import { Stack } from '@mui/material';
import { HeaderConfig } from './types';
import { Text } from '@verticeone/design-system';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import { useFormatDate } from '@verticeone/utils/formatting';
import backgroundPurple from './assets/background-purple.jpg';
import { EmptyDateHeader } from './EmptyDateHeader';

export const DefaultHeader = ({ title, date }: HeaderConfig) => {
  const formatDate = useFormatDate();

  if (!date) {
    return <EmptyDateHeader />;
  }

  return (
    <HighlightedCardHeader testId="card-header-purple" backgroundImage={`url(${backgroundPurple})`} noDivider>
      <Stack gap={2} alignItems="center" width="100%">
        <Text testId="card-header-title" variant="caption" size="XS" color="text2">
          {title}
        </Text>
        <Text testId="card-header-date" variant="heading" size="M" color="text1">
          {formatDate(date)}
        </Text>
      </Stack>
    </HighlightedCardHeader>
  );
};
