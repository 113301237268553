import { CheckCircleOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button, IconWrapper, Loader, Text } from '@verticeone/design-system';
import { useWebhookConnection } from '../hooks/useWebhookConnection';

export const WebhookActions = () => {
  const { t } = useTranslation();

  const {
    connectionData,
    isLoadingAccountIntegrationData,
    connectWebhook,
    isConnectWebhookInProgress,
    disconnectWebhook,
    isDisconnectWebhookInProgress,
  } = useWebhookConnection();

  if (isLoadingAccountIntegrationData) {
    return (
      <Stack p={6} alignItems="flex-start">
        <Loader size={16} />
      </Stack>
    );
  }

  return (
    <Stack gap={6} p={6} direction="row" alignItems="center">
      {connectionData?.successful ? (
        <Stack direction="row" alignItems="center" gap={6}>
          <Button
            variant="outline"
            color="neutral"
            size="M"
            onClick={disconnectWebhook}
            isLoading={isDisconnectWebhookInProgress}
            disabled={isDisconnectWebhookInProgress}
            testId="disconnect-webhook"
          >
            {t('INTEGRATIONS.VISO_TRUST.SYNC_CARD.DISCONNECT_BUTTON')}
          </Button>
          <Stack direction="row" gap={2}>
            <IconWrapper icon={CheckCircleOutlined} color="success" />
            <Text variant="button-bold" size="M" color="success">
              {t('INTEGRATIONS.VISO_TRUST.SYNC_CARD.CONNECTED')}
            </Text>
          </Stack>
        </Stack>
      ) : (
        <Button
          variant="outline"
          color="primary"
          size="M"
          onClick={connectWebhook}
          isLoading={isConnectWebhookInProgress}
          disabled={isConnectWebhookInProgress}
          testId="connect-webhook"
        >
          {t('INTEGRATIONS.VISO_TRUST.SYNC_CARD.CONNECT_BUTTON')}
        </Button>
      )}
    </Stack>
  );
};
