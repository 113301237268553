import { Stack, useTheme } from '@mui/material';
import { AnimatedStack } from '@vertice/core/src/components/animations/MotionWrappers';

import { Template } from '../types';
import { getThumbnailUrl } from '../thumbnails/utils';
import { TemplateCardControls } from './TemplateCardControls';
import { TestProps, testProps } from '@verticeone/design-system';

type TemplateCardProps = Template & {
  index?: number;
  shouldAnimateInitial?: boolean;
  onActionClick?: ({ template }: { template: Template }) => void;
};

export const TemplateCard = ({
  id,
  name,
  thumbnail,
  index = 0,
  shouldAnimateInitial = true,
  testId,
  onActionClick,
}: TemplateCardProps & TestProps) => {
  const { palette } = useTheme();

  const imageComponentPath = getThumbnailUrl(thumbnail);

  return (
    <AnimatedStack
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, y: shouldAnimateInitial ? -25 : 0 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: shouldAnimateInitial ? index * 0.1 : 0 }}
      layoutId={id}
      width="100%"
    >
      <AnimatedStack
        direction="column"
        borderRadius={4}
        border={`1px solid ${palette.core.color3}`}
        bgcolor={palette.background.default}
        overflow="hidden"
        position="relative"
        maxWidth={360}
        minWidth={240}
        height={240}
        variants={{
          expanded: {
            scale: 1.025,
            boxShadow: palette.global.getShadow({ color: 'core', type: 'soft', depth: '1z', distance: '80' }),
          },
          collapsed: {
            scale: 1,
            boxShadow: 'none',
          },
        }}
        initial="collapsed"
        whileHover="expanded"
        {...testProps(testId, 'template-card')}
      >
        <Stack>
          <img src={imageComponentPath} alt={name} />
        </Stack>
        <Stack position="absolute" bottom={0} left={0} right={0}>
          <TemplateCardControls template={{ id, name, thumbnail }} onClick={onActionClick} />
        </Stack>
      </AnimatedStack>
    </AnimatedStack>
  );
};
