import { CommentMetadata } from '@verticeone/design-system';
import { useRequestTasks } from '../../useRequestTasks';
import { useMemo, useState } from 'react';

export const useTaskFilter = (messages: (CommentMetadata & { sourceRef?: string })[], requestId?: string) => {
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [filterValue, setFilterValue] = useState<string[]>([]);

  const { allTasks } = useRequestTasks(requestId);

  const sourceRefs = Array.from(new Set(messages.map((message) => message.sourceRef))).filter(
    (sourceRef): sourceRef is string => sourceRef !== null && sourceRef !== undefined
  );

  const allFilterOptions = sourceRefs.map((sourceRef) => ({
    value: [sourceRef],
    label: allTasks.find((task) => task.ref === sourceRef)?.name ?? '',
  }));

  const filterOptions = allFilterOptions.reduce((acc: { value: string[]; label: string }[], option) => {
    const name = option.label;

    const optionWithSameName = acc.find((opt) => opt.label === name);

    if (!optionWithSameName) {
      return [...acc, option];
    }

    return acc.map((opt) =>
      opt.label === name
        ? {
            value: [...optionWithSameName.value, ...option.value],
            label: name,
          }
        : opt
    );
  }, []);

  const filteredMessages = useMemo(
    () =>
      filterValue.length > 0 ? messages.filter((message) => filterValue.includes(message.sourceRef ?? '')) : messages,
    [messages, filterValue]
  );

  return {
    filterExpanded,
    setFilterExpanded,
    filterValue,
    setFilterValue,
    filterOptions,
    filteredMessages,
  };
};
