import { FC, useEffect, useMemo, useState } from 'react';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { Variable } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema';
import { transformJiraToWorkflowLikeVariables } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/utils';
import { getBaseName } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraSync/utils';
import { WorkflowVariables } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/VariableMapper/types';
import {
  composeVariableDefaults,
  createVariable,
} from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowEditor/VariableSelector/customVariableUtils';
import { useCatalogResources } from '@vertice/core/src/modules/intelligentWorkflows/workflowSchema/WorkflowViewer/useCatalogResources';
import { useListCreateJiraIssueTypeFieldsQuery } from '@vertice/slices';
import { FieldSyncForm } from './FieldSyncForm';
import { TriggerMapping } from './types';

/**
 * Create workflow variables for existing mapping, so it can be shown in Variable Selector Dialog
 */
const createWorkflowVariables = (mapping: TriggerMapping, jiraVariables: Variable[]): Variable[] => {
  const variables = Object.entries(mapping).map(([key, wfVariableName]) => {
    const baseVariableName = getBaseName(key);
    const referencedVariable = jiraVariables.find((otherVariable) => otherVariable.id === baseVariableName);
    if (referencedVariable) {
      const variableDefaults = composeVariableDefaults(
        referencedVariable,
        { id: 'jiraSync', name: 'Jira Sync' },
        'vertice-account-service'
      );
      return createVariable(variableDefaults, wfVariableName);
    }
    return undefined;
  });

  return variables.filter((variable): variable is Variable => variable !== undefined);
};

export type ResetFieldSync = { issueTypeId: string; projectId: string };

type FieldSyncProps = {
  projectId?: string;
  issueTypeId?: string;
  mapping: TriggerMapping;
  onChange: (fieldMapping: TriggerMapping) => void;
  resetMapper?: ResetFieldSync;
  disabled: boolean;
};

export const FieldSync: FC<FieldSyncProps> = ({ projectId, issueTypeId, mapping, onChange, resetMapper, disabled }) => {
  const { accountId } = useAccountContext();

  const [currentProjectId, setCurrentProjectId] = useState(projectId);
  const [currentIssueTypeId, setCurrentIssueTypeId] = useState(issueTypeId);
  const [workflowVariables, setWorkflowVariables] = useState<WorkflowVariables>({
    udfs: [],
    request: [],
  });
  const [resetMapping, setResetMapping] = useState<boolean | undefined>();
  // simulate loading of workflowVariables
  const [isPreparingWorkflowVariables, setIsPreparingWorkflowVariables] = useState(true);
  const { data: issueTypeFieldsData } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: currentProjectId ?? '',
      issueTypeId: currentIssueTypeId ?? '',
    },
    { skip: !currentProjectId || !currentIssueTypeId }
  );

  useEffect(() => {
    setCurrentProjectId(projectId);
  }, [projectId]);

  useEffect(() => {
    setCurrentIssueTypeId(issueTypeId);
  }, [issueTypeId]);

  useEffect(() => {
    if (resetMapper !== undefined) {
      setCurrentProjectId(resetMapper.projectId);
      setCurrentIssueTypeId(resetMapper.issueTypeId);
      setResetMapping((old) => !old);
    }
  }, [resetMapper]);

  const { xTypeCatalogResources } = useCatalogResources();
  const otherVariables = useMemo(
    () =>
      issueTypeFieldsData?.fields &&
      transformJiraToWorkflowLikeVariables(issueTypeFieldsData.fields, xTypeCatalogResources),
    [issueTypeFieldsData?.fields, xTypeCatalogResources]
  );

  useEffect(() => {
    if (otherVariables) {
      setWorkflowVariables({
        udfs: [],
        request: createWorkflowVariables(mapping, otherVariables),
      });
      setIsPreparingWorkflowVariables(false);
    }
  }, [otherVariables, mapping]);

  return (
    <FieldSyncForm
      initialMapping={mapping}
      otherVariables={otherVariables}
      workflowVariables={workflowVariables}
      setWorkflowVariables={setWorkflowVariables}
      onChange={onChange}
      resetMapping={resetMapping}
      isLoading={isPreparingWorkflowVariables}
      disabled={disabled}
    />
  );
};
