import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsV2 } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Link } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import useIntakeForm from '../../../hooks/useIntakeForm';
import useContractListExport from '@vertice/core/src/modules/saas/contract/hooks/useContractListExport';
import { isCancelledContract } from '@vertice/core/src/modules/saas/contract/computed';
import { PageHeader } from '@verticeone/design-system';

export const ContractsPageHeader: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { userCanAddExistingContract } = useIntakeForm();
  const { canExportContracts, downloadFile, isExportInProgress } = useContractListExport({
    filter: (view) => !isCancelledContract(view.contract),
  });

  return (
    <PageHeader
      breadcrumb={
        <BreadcrumbsV2<typeof Link> items={[{ label: t('SAAS.NAVBAR.SAAS') }, { label: t('SAAS.NAVBAR.CONTRACTS') }]} />
      }
      color="primary"
      actions={
        <>
          {canExportContracts && (
            <Button color="neutral" variant="outline" isLoading={isExportInProgress} onClick={downloadFile}>
              {t('ENTITIES.CONTRACT.ACTIONS.EXPORT')}
            </Button>
          )}

          {userCanAddExistingContract && (
            <Button color="primary" variant="solid" component={Link} to={routes.CREATE_EXISTING_CONTRACT.ABSOLUTE_PATH}>
              {t('CONTRACTS.ADD_EXISTING')}
            </Button>
          )}
        </>
      }
    />
  );
};
