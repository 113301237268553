import { z, ZodObject, ZodRawShape } from 'zod';
import { DataSourcedFieldMetadata, DataSourcedFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';
import { xTypeDataSourcesBySource } from './dataSources';

export function getDataSourcedFieldPresenterSchema(field: DataSourcedFieldType): ZodObject<ZodRawShape> {
  return z.object({
    [field.name]: field.metadata.required ? z.string().min(1) : z.string().nullish(),
  });
}

export function getDataSourcedFieldPresenterDefault({
  field,
  defaultValues,
}: GetPresenterDefaultProps<DataSourcedFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
}

export function getDataSourcedFieldJsonSchemaFromMetadata(metadata: DataSourcedFieldMetadata): JsonSchema {
  return {
    type: metadata.required ? ['string'] : ['string', 'null'],
    title: metadata.label,
    'x-type': xTypeDataSourcesBySource[metadata.source] ? metadata.source : undefined,
  };
}

export function getDataSourcedFieldJsonSchema(field: DataSourcedFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getDataSourcedFieldJsonSchemaFromMetadata(field.metadata) },
  };
}
