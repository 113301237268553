import React from 'react';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';

import { getActiveDiffDays } from '../../../dataSource/utils';
import type { TaskRow } from '../../../dataSource';

type TaskActiveDaysProps = {
  taskRow: TaskRow;
};

export const TaskActiveDays = ({ taskRow }: TaskActiveDaysProps) => (
  <Stack minWidth={0} direction="row" alignItems="center" gap={0.5}>
    <Text variant="body-regular" size="S" color="text1">
      {Math.ceil(getActiveDiffDays(taskRow.createdAt, taskRow.closedAt))}
    </Text>
  </Stack>
);
