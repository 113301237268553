import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, styled } from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { isAfter } from 'date-fns';
import { useFormatDate } from '@verticeone/utils/formatting';
import { IconWrapper, Text } from '@verticeone/design-system';

import useLoggedUserAccountRoles from '../../../../../hooks/useLoggedUserAccountRoles';
import { MissingDueDateInfoIcon } from '../../../task/components/MissingDueDateInfoIcon';

const StyledIconWrapper = styled(IconWrapper)({
  opacity: 0.4,
});

type DueDateLabel = {
  label: string;
  color?: 'warning' | 'error' | 'info';
  isInfoTooltipDisplayed?: boolean;
};

const useDueDateLabel = (dueDate?: string): DueDateLabel => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES' });
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const formatDate = useFormatDate();

  if (!dueDate) {
    return {
      label: t('NO_DUE_DATE'),
      color: undefined,
      isInfoTooltipDisplayed: isUserAdmin,
    };
  }

  const isDueDateAfterNow = isAfter(new Date(dueDate), new Date());

  return {
    label: t('DUE', { date: formatDate(dueDate) }),
    color: isDueDateAfterNow ? undefined : 'error',
  };
};

type TaskDueDateProps = {
  dueDate?: string;
};

export const TaskDueDate: FC<TaskDueDateProps> = ({ dueDate }) => {
  const { color, label, isInfoTooltipDisplayed } = useDueDateLabel(dueDate);

  return (
    <Stack minWidth={0} direction="row" alignItems="center" gap={1}>
      <StyledIconWrapper icon={CalendarTodayOutlined} color="inherit" />
      <Text variant="body-bold" size="M" color={color ? `${color}.color1` : 'text2'}>
        {label}
      </Text>
      {isInfoTooltipDisplayed ? <MissingDueDateInfoIcon /> : null}
    </Stack>
  );
};
