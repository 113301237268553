import { CircularProgress, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { Vendor } from '../../../../vendor/types';
import { FC } from 'react';
import VendorLogo from '../../../../../components/VendorLogo';

type VendorPreviewProps = {
  vendor: Vendor;
};

export const VendorPreview: FC<VendorPreviewProps> = ({ vendor }) => {
  const { palette } = useTheme();

  return (
    <Stack bgcolor={palette.input.color1} px={4} py={3} gap={2} direction="row" alignItems="center" borderRadius={3}>
      <VendorLogo vendorId={vendor.id ?? undefined} />
      <Text variant="button-bold" size="S" flexGrow={1} color="text1">
        {vendor.name ? vendor.name : <CircularProgress size={16} />}
      </Text>
    </Stack>
  );
};
