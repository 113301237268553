export const VALID_WIDGET_IDS = [
  'AverageRequestTimes',
  'CompletedRequests',
  'CompletedRequestsStats',
  'ContractsStats',
  'ProcuredSpend',
  'ProcuredStats',
  'RequestsStats',
  'RequestsList',
  'TasksList',
  'TasksWithoutAssignee',
  'TimeToCompleteHistogram',
];
