import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack } from '@mui/material';
import { FC, SVGProps } from 'react';

import { IconWrapper, Link, Text } from '@verticeone/design-system';

type CreatedByDetailProps = {
  icon: FC<SVGProps<SVGSVGElement>>;
  userDetail: string;
  detailLink?: string;
  detailLinkText: string;
};

export const CreatedByDetail: FC<CreatedByDetailProps> = ({ icon, userDetail, detailLink, detailLinkText }) => {
  return (
    <Text variant="heading" size="XS">
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <IconWrapper icon={icon} />
          {userDetail}
        </Stack>
        {detailLink && (
          <Link href={detailLink} target="_blank">
            <Stack direction="row" gap={1} alignItems="center">
              {detailLinkText}
              <IconWrapper icon={OpenInNewIcon} />
            </Stack>
          </Link>
        )}
      </Stack>
    </Text>
  );
};
