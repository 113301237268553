import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Grid, Theme, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { FieldArrayPath, FieldValues, Path, useController } from 'react-hook-form';

import { IconButton, IconWrapper } from '@verticeone/design-system';
import { TaskDefinition } from '../../../definitionsTypes';
import { VariableToken } from '../EditServiceTaskDrawer/JiraCreate/VariableToken';
import { WorkflowRichSelector } from '../forms/EditGatewayForm/WorkflowRichSelector';
import { WorkflowVariableSelector } from '../forms/EditGatewayForm/WorkflowVariableSelector';
import { Variable, VariableOrigin } from '../types';
import { findVariableById } from '../VariableSelector/utils';
import { VariableMapperRowFormData } from './formSchema';
import { OtherVariableSelector } from './variableSelectors/OtherVariableSelector';
import { useTranslation } from 'react-i18next';
import { VariablesGroup } from '../VariableSelector/types';
import { TFunction } from 'i18next';
import { WorkflowVariables } from './types';
import { getVariableXType } from './utils';
import { LoadingSkeleton } from './LoadingSkeleton';

// TODO: Add unit test for this helper function.
// Update .replace() regexp code from line 29, cause it raises potential performance issues.
const parseFromRichSelectorValue = (value: string) => {
  const parsedValue = value
    // Strip HTML markup related to variables
    .replace(/<span class="[^"]*?variable-id[^"]*?">(.*?)<\/span>/g, '$1')
    .replace(/<span class="[^"]*?variable-label[^"]*?"(.*?)>(.*?)<\/span><\/span>/g, '')
    // div tags need to be separated by a new line
    .replace(/<div>(.*?)<\/div>/g, '\n$1')
    .replace(/<\/div>/g, '')
    // Escape special characters, backslash needs to go first
    .replaceAll(String.fromCharCode(92), String.fromCharCode(92, 92))
    .replaceAll('"', '\\"')
    .replaceAll('`', '\\`')
    // Replace new lines and HTML entities with their respective characters
    .replaceAll('<br>', '\n')
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&amp;', '&')
    .trim();

  // Wrap the whole string in `" "`
  return parsedValue !== '' ? `\`"${parsedValue}"\`` : '';
};

// TODO: Add unit test for this helper function
export const parseToRichSelectorValue = (variables: Variable[], value: string, theme: Theme, disabled?: boolean) => {
  return (
    value
      // New line needs to be represented as line break
      .replaceAll('\n', '<br>')
      // Unescape special characters, backslash needs to go last
      .replaceAll('\\"', '"')
      .replaceAll('\\`', '`')
      .replaceAll(String.fromCharCode(92, 92), String.fromCharCode(92))
      // Generate HTML markup for variables
      .replace(/{{([^}]*)}}/g, (_$0: string, $1: string) => {
        const variable = findVariableById(variables, $1);

        return variable ? renderToString(<VariableToken theme={theme} variable={variable} disabled={disabled} />) : '';
      })
      // Remove both wrapping backticks and double quotes
      .replace(/`"(.*?)"`/, '$1')
  );
};

export type VariableMapperRowProps<T extends FieldValues> = {
  index: number;
  field: VariableMapperRowFormData;
  formFieldPrefix: FieldArrayPath<T>;
  onRemove?: (index: number) => void;
  fromWorkflow: boolean; // To distinguish the direction of mapping
  workflowVariables: WorkflowVariables;
  setWorkflowVariables?: (variables: WorkflowVariables) => void;
  otherVariables: Variable[];
  selectedOtherVariables?: string[];
  task?: TaskDefinition;
  allowCreateVariable: boolean;
  createOtherVariableGroups: (variables: Variable[], t: TFunction) => VariablesGroup[];
  hasRichSelector: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  createVariableOrigin?: Partial<VariableOrigin>;
};

export const VariableMapperRow = <T extends FieldValues>({
  formFieldPrefix,
  index,
  onRemove,
  fromWorkflow,
  workflowVariables,
  setWorkflowVariables,
  otherVariables,
  selectedOtherVariables,
  task,
  allowCreateVariable,
  isLoading,
  createOtherVariableGroups,
  hasRichSelector,
  createVariableOrigin = {},
  disabled = false,
}: VariableMapperRowProps<T>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { field: workflowField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'from' : 'to'}` as Path<T>,
  });
  const { field: otherField } = useController({
    name: `${formFieldPrefix}.${index}.${fromWorkflow ? 'to' : 'from'}` as Path<T>,
  });

  const [selectedWFVariable, setSelectedWFVariable] = useState<Variable | undefined>(
    findVariableById(
      [...workflowVariables.request, ...(fromWorkflow ? workflowVariables.udfs : [])],
      workflowField.value
    ) ?? undefined
  );

  useEffect(() => {
    setSelectedWFVariable(
      findVariableById(
        [...workflowVariables.request, ...(fromWorkflow ? workflowVariables.udfs : [])],
        workflowField.value
      ) ?? undefined
    );
  }, [fromWorkflow, workflowField.value, workflowVariables]);
  const [selectedOtherVariable, setSelectedOtherVariable] = useState<Variable | undefined>(
    findVariableById(otherVariables, otherField.value) ?? undefined
  );

  useEffect(() => {
    setSelectedOtherVariable(findVariableById(otherVariables, otherField.value) ?? undefined);
  }, [otherField.value, otherVariables]);

  const otherVariablesGroups = useMemo(
    () => createOtherVariableGroups(otherVariables, t),
    [createOtherVariableGroups, otherVariables, t]
  );

  const [richSelectorValue, setRichSelectorValue] = useState<string>(hasRichSelector ? workflowField.value : '');

  useEffect(() => {
    if (hasRichSelector) {
      setRichSelectorValue(
        parseToRichSelectorValue(
          [...workflowVariables.request, ...(fromWorkflow ? workflowVariables.udfs : [])],
          workflowField.value,
          theme
        )
      );
    }
  }, [fromWorkflow, hasRichSelector, theme, workflowField.value, workflowVariables.request, workflowVariables.udfs]);

  const handleWFVariableChange = (variable: Variable) => {
    setSelectedWFVariable(variable);
    workflowField.onChange(variable.idOverride ?? variable.id);
  };

  const handleRichSelectorValueChange = (newValue: string) => {
    setRichSelectorValue(newValue);
    workflowField.onChange(parseFromRichSelectorValue(newValue));
  };

  const handleOtherVariableChange = (variable?: Variable) => {
    setSelectedOtherVariable(variable);
    otherField.onChange(variable?.id);
  };

  const allowCreateVariableProps = allowCreateVariable
    ? {
        addVariableDefaults: {
          type: {
            baseType: selectedOtherVariable?.type.baseType,
            labels: selectedOtherVariable?.type.labels,
            xType: getVariableXType(otherVariables, otherField.value),
          },
          origin: {
            kind: 'vertice-task-output-mapping',
            id: task?.task.id ?? '',
            label: task?.task.name,
            ...createVariableOrigin,
          } satisfies VariableOrigin,
        },
        onAddLocalVariable: (variable: Variable) => {
          setWorkflowVariables?.({ udfs: workflowVariables.udfs, request: [...workflowVariables.request, variable] });
        },
      }
    : {};

  const workflowSelector = hasRichSelector ? (
    <WorkflowRichSelector
      // TODO: Uncomment this line after https://vertice.atlassian.net/browse/RED-2232 is done
      // udfVariables={fromWorkflow ? workflowVariables.udfs : []}
      udfVariables={[]}
      allRequestVariables={workflowVariables.request}
      selectedVariable={selectedWFVariable}
      richSelectorValue={richSelectorValue}
      onRichSelectorValueChange={handleRichSelectorValueChange}
      typeFilter={selectedOtherVariable?.type.baseType?.[0]}
      disabled={disabled}
      {...allowCreateVariableProps}
    />
  ) : (
    <WorkflowVariableSelector
      selectedVariable={selectedWFVariable}
      onSelectedVariableChange={handleWFVariableChange}
      udfVariables={fromWorkflow ? workflowVariables.udfs : []}
      allRequestVariables={workflowVariables.request}
      isDisabled={disabled || (!fromWorkflow && selectedOtherVariable === undefined)}
      typeFilter={selectedOtherVariable?.type.baseType?.[0]}
      {...allowCreateVariableProps}
    />
  );

  const otherSelector = (
    <OtherVariableSelector
      selectedVariable={selectedOtherVariable}
      onSelectedVariableChange={handleOtherVariableChange}
      variablesGroups={otherVariablesGroups}
      isVariableTypeDisplayed={true}
      isDisabled={disabled || (fromWorkflow && selectedWFVariable === undefined) || !onRemove}
      typeFilter={selectedWFVariable?.type.baseType?.[0]}
      selectedVariables={selectedOtherVariables}
    />
  );

  const fromSelector = fromWorkflow ? workflowSelector : otherSelector;
  const toSelector = fromWorkflow ? otherSelector : workflowSelector;

  return (
    <Grid container sx={{ alignItems: 'center', zIndex: 1 }}>
      <Grid item xs={fromWorkflow ? 7 : 5.5} display="grid">
        {isLoading ? <LoadingSkeleton /> : fromSelector}
      </Grid>
      <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <IconWrapper icon={ArrowForwardIcon} htmlColor={theme.palette.core.color5} />
      </Grid>
      <Grid item xs={fromWorkflow ? 4 : 5.5} display="grid">
        {isLoading ? <LoadingSkeleton /> : toSelector}
      </Grid>

      <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        {onRemove && !isLoading && (
          <IconButton
            icon={DeleteOutlineIcon}
            variant="plain"
            color="neutral"
            onClick={() => onRemove(index)}
            disabled={disabled}
          />
        )}
      </Grid>
    </Grid>
  );
};
