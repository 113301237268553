import { DeepPartial } from 'react-hook-form';
import { ContractDetailsForm } from './types';
import { TaskContractReviewInput } from '../../types';
import { Vendor } from '../../../../../../vendor/types';

export const getContractDetailsDefaultValues = (input?: TaskContractReviewInput): DeepPartial<ContractDetailsForm> => {
  const vendor: Vendor | undefined = input?.vendorName ? { type: 'INLINE', name: input.vendorName } : undefined;

  return {
    departmentId: input?.department,
    contractCategory: 'OTHER',
    vendor,
  };
};
