import React, { useCallback, useId } from 'react';
import { Card, Text } from '@verticeone/design-system';
import { StatsBody, Title, Value } from '@vertice/core/src/components/StatsBar';
import { CardProps, Stack } from '@mui/material';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { FullHeightStatsTile } from './shared/FullHeightStatsTile';
import { GrayStatsBody } from './shared/GrayStatsBody';
import { useDashboardContext } from '@vertice/dashboard/src/pages/Dashboard/DashboardContextProvider';
import { ContractCategory } from '@vertice/dashboard/src/pages/Dashboard/types';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { Money } from '@vertice/slices';

type SpendCardProps = CardProps;

export const SpendCard = (props: SpendCardProps) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const formatCurrency = useFormatCurrency();
  const { allowCategoryChange, contractCategory } = useDashboardContext();
  const { isLoading, data } = useGetContractsStatisticsQuery({ accountId });
  const statsForCategory = data?.stats.categories[contractCategory];
  const showSpendExpected = !allowCategoryChange || contractCategory === ContractCategory.SAAS;

  const getSpendUploadedTooltipText = useCallback(() => {
    if (!allowCategoryChange) {
      return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION');
    }
    switch (contractCategory) {
      case ContractCategory.SAAS:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_SAAS');
      case ContractCategory.NON_SAAS:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_NON_SAAS');
      case ContractCategory.ALL_SPEND:
        return t('DASHBOARD.SPEND_UPLOADED.DESCRIPTION_ALL');
    }
  }, [t, allowCategoryChange, contractCategory]);

  const firstLabelId = useId();
  const secondLabelId = useId();

  const showTCV = !isNil(statsForCategory?.totalSpendUploaded) && !isNil(statsForCategory?.totalSpendUploaded?.amount);

  const formatMoney = useCallback(
    (money: Money | undefined | null) => {
      if (isNil(money) || isNil(money.amount)) {
        return '-';
      }

      return formatCurrency(money.amount, {
        currency: money.currency ?? DEFAULT_CURRENCY,
        maximumFractionDigits: 0,
      });
    },
    [formatCurrency]
  );

  return (
    <Card {...props}>
      <FullHeightStatsTile>
        <Stack spacing={4} height="100%" p={2}>
          <StatsBody
            sx={{ flexGrow: 1, alignItems: 'flex-start', padding: 4 }}
            isLoading={isLoading}
            title={
              <Title
                id={firstLabelId}
                tooltip={{ title: t('DASHBOARD.SPEND_UPLOADED.TITLE'), content: getSpendUploadedTooltipText() }}
              >
                {t('DASHBOARD.SPEND_UPLOADED.TITLE')}
              </Title>
            }
            value={
              <Stack gap={0.5}>
                <Value aria-labelledby={firstLabelId}>{formatMoney(statsForCategory?.spendUploaded)}</Value>
                {showTCV && (
                  <Text testId="spend-uploaded-tcv" variant="body-regular" size="S" color="text4">
                    {t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatMoney(statsForCategory?.totalSpendUploaded) })}
                  </Text>
                )}
              </Stack>
            }
          />
          {showSpendExpected && (
            <GrayStatsBody
              isLoading={isLoading}
              title={
                <Title
                  id={secondLabelId}
                  tooltip={{
                    title: t('DASHBOARD.SPEND_EXPECTED.TITLE'),
                    content: t('DASHBOARD.SPEND_EXPECTED.DESCRIPTION'),
                  }}
                >
                  {t('DASHBOARD.SPEND_EXPECTED.TITLE')}
                </Title>
              }
              value={<Value aria-labelledby={secondLabelId}>{formatMoney(statsForCategory?.spendExpected)}</Value>}
            />
          )}
        </Stack>
      </FullHeightStatsTile>
    </Card>
  );
};
