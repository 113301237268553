import { useDeleteContractMutation, Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '../../../account/AccountContext';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useContractDelete = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [apiDeleteContract] = useDeleteContractMutation();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const deleteFn = async (contract: Contract) => {
    await apiDeleteContract({
      accountId,
      contractId: contract.record.contractId,
    });

    enqueueSnackbar(t('SNACKBAR.CONTRACT_DELETED'), {
      variant: 'success',
    });
    navigate(routes.CONTRACTS);
  };

  return deleteFn;
};

export default useContractDelete;
