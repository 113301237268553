import { Stack, styled, useTheme } from '@mui/material';
import { Node, NodeProps, Position, useStoreApi } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { FC, PropsWithChildren, useState } from 'react';
import { isAfter } from 'date-fns';

import { EllipsisText, testProps } from '@verticeone/design-system';
import { TaskIcon, useTaskStyle } from '../../../../components/sharedVisualStyle/taskStyle';
import { WorkflowTaskNode } from '../../../model/types';
import { checkServiceTaskHasDetail } from '../../../WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/utils';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';
import { getNodeDimensions } from '../getNodeDimensions';
import { NodeControls } from '../NodeControls';
import { ValidationCheck } from '../ValidationCheck';
import { GradientWrapper } from './GradientWrapper';
import { StyledTaskNode, DragIcon, ShowOnHover } from './StyledTaskNode';
import { TaskAssignees } from './TaskAssignees';
import { TaskDate } from './TaskDate';
import { TaskNavigateAction } from './TaskNavigateAction';
import { TaskVerticeAssignment } from './TaskVerticeAssignment';
import { TaskViewButton } from './TaskViewButton';
import { TaskColorVariant } from './types';
import { ConnectionPoint } from '../ConnectionPoint';
import { DeleteObjectButton } from '../../../WorkflowEditor/components/DeleteObjectButton/DeleteObjectButton';
import { useDragAndDropExtensionContext } from '../../../WorkflowEditor/DragAndDropExtension/DragAndDropExtensionContext';
import { TaskDueDate } from './components/TaskDueDate/TaskDueDate';

const TaskHeader = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingLeft: 0,
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
  })
);

const TaskBody = styled(Stack)<{ $colorVariant: TaskColorVariant; $invertedColors?: boolean }>(
  ({ theme, $colorVariant, $invertedColors }) => ({
    borderTop: `1px solid ${$invertedColors ? theme.palette[$colorVariant].color3 : theme.palette.core.color4}`,
    padding: theme.spacing(4),
    width: '100%',
    backgroundColor: $invertedColors ? theme.palette[$colorVariant].color2 : theme.palette.background.default,
    '&:empty': {
      display: 'none',
    },
  })
);

/*
  Task node may vary in height depending on the content,
  so here we use a wrapper with constant width and height which are used for the auto-layouting
  and the task node itself is centered inside the wrapper.
 */
export const VerticalAlignWrapper = styled(Stack)<{ $node: WorkflowTaskNode }>(({ $node }) => ({
  ...getNodeDimensions($node),
  flexDirection: 'row',
  alignItems: 'center',
}));

type ValidationMessageVariant = 'error' | 'warning' | null;

export const TaskNodeComponent: FC<NodeProps<Node<WorkflowTaskNode>>> = (props) => {
  const { data, dragging } = props;
  const { state: taskState } = data;
  const isTaskOverDue = taskState?.dueDate ? isAfter(new Date(), new Date(taskState.dueDate)) : false;

  const isTaskActive = taskState?.status === 'ACTIVE';
  const isTaskFailed = taskState?.status === 'FAILED';
  const isTaskTerminated = taskState?.status === 'TERMINATED';
  const isTaskCompleted = taskState?.status === 'COMPLETED';

  const { palette } = useTheme();
  const [validationMessageVariant, setValidationMessageVariant] = useState<ValidationMessageVariant>(null);
  const { loggedUserId: userId, isEditor, isDragAndDropEnabled, isLoggedUserAdmin } = useWorkflowRendererContext();
  const { addSelectedNodes } = useStoreApi().getState();
  const { onNodeDelete } = useDragAndDropExtensionContext();

  const taskNodeType = data.type;
  const { colorVariant, withShadow, withColoredBorder, invertedColors, invertedHeaderColors } = useTaskStyle({
    userId,
    status: taskState?.status,
    thumbnail: data.thumbnail,
    type: taskNodeType,
    assignees: taskState?.assigneeIds,
    taskDueDate: taskState?.dueDate,
  });

  const textColor = invertedColors ? palette.text.color5 : palette.text.color1;
  // Header text color has an edge case for success and primary color variant
  const variantColor = !Array<TaskColorVariant>('neutral', 'transparent').includes(colorVariant)
    ? colorVariant
    : palette.text.color1;
  const headerTextColor = invertedHeaderColors ? palette.text.color5 : variantColor;

  const wrapperHasShadow = isTaskActive && withShadow;
  const WrapperComponent: FC<PropsWithChildren> = ({ children }) => {
    return isTaskActive ? (
      <GradientWrapper $colorVariant={colorVariant} $withShadow={wrapperHasShadow}>
        {children}
      </GradientWrapper>
    ) : (
      children
    );
  };

  const validationTaskNodePropsOverride = {
    $colorVariant: validationMessageVariant ?? ('neutral' as TaskColorVariant),
    $withShadow: true,
    $withColoredBorder: true,
  };
  // Fallback color variant to satisfy typescript
  const validationTaskHeaderPropsOverride = {
    $colorVariant: validationMessageVariant ?? ('neutral' as TaskColorVariant),
    $invertedColors: false,
  };

  const getEditorOrDefaultColorVariant = <T extends unknown, S extends unknown>(editorValue: T, defaultValue: S) =>
    isEditor && validationMessageVariant ? editorValue ?? defaultValue : defaultValue;

  const handleTaskNodeClick = () => {
    addSelectedNodes([props.id]);
  };

  const isTaskDueDateDisplayed = taskNodeType === 'USER' && !isTaskFailed && !isTaskTerminated;

  return (
    <NodeControls
      {...props}
      content={
        <VerticalAlignWrapper $node={data}>
          {isDragAndDropEnabled && (
            <ConnectionPoint
              type="target"
              position={Position.Left}
              connectionsLimit="no-limit"
              id={`${data.id}-connection-target`}
            />
          )}
          <WrapperComponent>
            <StyledTaskNode
              // Use 'error' or 'warning' if there is validation message present
              {...getEditorOrDefaultColorVariant(validationTaskNodePropsOverride, {
                $colorVariant: colorVariant,
                $withShadow: withShadow && !wrapperHasShadow,
                $withColoredBorder: withColoredBorder,
              })}
              $selected={props.selected}
              $selectable={isEditor}
              $active={isTaskActive}
              onClick={handleTaskNodeClick}
              $withDragStyles={isDragAndDropEnabled}
              $withDraggingStyles={isDragAndDropEnabled && dragging}
              {...testProps(data.name)}
            >
              <TaskHeader
                {...getEditorOrDefaultColorVariant(validationTaskHeaderPropsOverride, {
                  $colorVariant: colorVariant,
                  $invertedColors: invertedHeaderColors,
                })}
              >
                <DragIcon isIconVisible={isDragAndDropEnabled} />
                <Stack direction="row" gap={2} alignItems="center" width="100%">
                  <TaskIcon
                    userId={userId}
                    status={taskState?.status}
                    taskType={taskNodeType}
                    taskDueDate={taskState?.dueDate}
                    type={data.thumbnail?.type}
                    id={data.thumbnail?.id}
                    assigneeIds={taskState?.assigneeIds}
                    size="XS"
                    overrideColorVariant={getEditorOrDefaultColorVariant(validationMessageVariant, undefined)}
                    sx={{ flexShrink: 0 }}
                  />
                  <Stack flexGrow={1}>
                    <EllipsisText
                      variant="heading"
                      size="XS"
                      color={getEditorOrDefaultColorVariant(
                        validationMessageVariant === 'warning' ? 'warning.color1' : validationMessageVariant,
                        headerTextColor
                      )}
                      lineClamp={2}
                      title={data.name}
                      sx={{ pointerEvents: 'all', cursor: 'grab' }}
                    >
                      {data.name}
                    </EllipsisText>
                  </Stack>
                  {isDragAndDropEnabled && onNodeDelete ? (
                    <ShowOnHover>
                      <DeleteObjectButton
                        variant="icon"
                        onDelete={() => onNodeDelete(data.id)}
                        nodeName={data.name ?? ''}
                      />
                    </ShowOnHover>
                  ) : null}
                </Stack>
              </TaskHeader>
              <TaskBody $colorVariant={colorVariant} $invertedColors={invertedColors} gap={1}>
                <TaskAssignees
                  isEditIconDisplayed={false}
                  taskId={data.id}
                  taskType={taskNodeType}
                  textColor={textColor}
                  taskState={taskState}
                />
                <TaskVerticeAssignment status={taskState?.status} textColor={textColor} taskNodeType={taskNodeType} />
                {isTaskDueDateDisplayed ? (
                  <TaskDueDate
                    isInfoTooltipDisplayed={!!isLoggedUserAdmin}
                    taskId={data.id}
                    textColor={textColor}
                    taskState={taskState}
                  />
                ) : null}
                {(isTaskFailed || isTaskCompleted) && !isTaskDueDateDisplayed && taskState?.closedAt ? (
                  <TaskDate
                    closedAt={taskState.closedAt}
                    textColor={
                      Array<TaskColorVariant>('success', 'error').includes(colorVariant) ? variantColor : textColor
                    }
                  />
                ) : null}
                <TaskNavigateAction state={taskState} route={data.requestRoute} taskNodeType={taskNodeType} />
                <TaskViewButton
                  assigneeIds={taskState?.assigneeIds}
                  isHidden={!taskState?.status || taskState.status === 'PENDING' || !!taskState.requestRef}
                  hasServiceTaskDetail={checkServiceTaskHasDetail(taskState)}
                  taskId={data.id}
                  colorVariant={colorVariant}
                  isTaskActiveAndOverdue={isTaskOverDue && isTaskActive}
                />
              </TaskBody>
            </StyledTaskNode>
          </WrapperComponent>
          {isDragAndDropEnabled && (
            <ConnectionPoint type="source" position={Position.Right} id={`${data.id}-connection-source`} />
          )}
          {isEditor && <ValidationCheck setValidationResult={setValidationMessageVariant} id={data.id} />}
        </VerticalAlignWrapper>
      }
    />
  );
};
