import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from '@verticeone/design-system';

import HostnameField, { PROTOCOL } from '../components/HostnameField';
import IntegrationNameField from '../components/IntegrationNameField';
import AuthField from '../components/AuthField';
import FormSection from '../components/FormSection';
import { EditSchema, editSchema } from './scheme';
import { useGetIntegrationById } from '../../../../dataSource/useGetIntegrationById';
import { IntegrationItem } from '../../../../utils';

type ApiKeyFormProps = {
  integration: IntegrationItem;
  integrationId: string;
};

const ApiKeyForm = ({ integrationId }: ApiKeyFormProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const { data } = useGetIntegrationById({ integrationId });

  const { register, setValue, trigger, control } = useForm<EditSchema>({
    resolver: zodResolver(editSchema),
    mode: 'onChange',
    defaultValues: {
      name: data?.integrationName,
      auth: {
        authorizationHeaderName: data?.authorizationHeaderName,
        baseUrl: {
          hostname: data?.baseUrl?.replace(`${PROTOCOL}://`, '') ?? '',
        },
      },
    },
  });

  return (
    <Stack direction="column" gap={6}>
      <IntegrationNameField name="name" register={register} control={control} disabled />
      <AuthField
        authHeaderName="auth.authorizationHeaderName"
        apiKeyName="auth.apiKey"
        control={control}
        placeholder="******************"
        disabled
      />

      <FormSection title={t('SECTION.CONNECTION')}>
        <HostnameField
          name="auth.baseUrl.hostname"
          register={register}
          setValue={setValue}
          control={control}
          trigger={trigger}
          disabled
        />
      </FormSection>
    </Stack>
  );
};

const ApiKeyFormWrapper = ({ integrationId }: Omit<ApiKeyFormProps, 'integration'>) => {
  const { data: integration, isLoading } = useGetIntegrationById({ integrationId });

  if (isLoading) {
    return <Loader />;
  }

  return <ApiKeyForm integration={integration} integrationId={integrationId} />;
};

export default ApiKeyFormWrapper;
