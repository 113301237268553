import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalOutlined, ExtensionOutlined } from '@mui/icons-material';
import { CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import {
  GatewayInclusiveIcon,
  IconWrapperProps,
  JiraIntegrationIcon,
  LineAndSquareIcon,
} from '@verticeone/design-system';

import { parseResourceRef } from '../../../../../../hooks/workflows/refUtils';
import { DnDNodesGroup, TaskDnDNode, TaskType } from './types';
import {
  ADVISORY_SERVICE_TASK_URN_REGEX,
  CREATE_CONTRACT_SERVICE_URN_REGEX,
  DEFINITION_VERSION,
  EDITED_CUSTOM_FORM_URN_REGEX,
  REVIEW_DRAFT_CONTRACT_URN_REGEX,
  SIMPLE_APPROVAL_FORM_URN_REGEX,
} from '../../../../constants';
import { TaskConfigurations } from '../../../../definitionsTypes';
import { useGetCatalogResources } from '../../../../hooks/useGetCatalogResources';
import { ServiceCatalogResource } from '../../../../catalogResource/types';

const JIRA = 'jira';
const INTEGRATIONS = 'integrations';

/*
 * The JIRA integration services are missing the "integrations" root key.
 * Otherwise, all the integrations services follows pattern "/integrations/{integrationName}"
 */
const INTEGRATIONS_KEY_WORDS: string[] = [JIRA, INTEGRATIONS];

const INTEGRATIONS_ICONS_MAP: Record<string, IconWrapperProps['icon']> = {
  [JIRA]: JiraIntegrationIcon,
};

const SERVICE_KIND = 'Vertice/ServiceCatalog/Service/ServiceDefinition';
const FORM_KIND = 'Vertice/ServiceCatalog/Form/FormDefinition';

const TASK_TYPES_MAP: Record<string, TaskType> = {
  [SERVICE_KIND]: 'Service',
  [FORM_KIND]: 'User',
};

const isActiveServiceOrFormResource = ({ definition, status }: CatalogResource) =>
  status === 'ACTIVE' && (definition.Kind === SERVICE_KIND || definition.Kind === FORM_KIND);

const isNotCustomFormCreatedByUser = ({ managedBy, urn }: CatalogResource) =>
  !(managedBy !== 'Vertice' && urn.match(EDITED_CUSTOM_FORM_URN_REGEX));

const isDeprecated = ({ managedBy, urn }: CatalogResource) =>
  !(managedBy === 'Vertice' && urn.match(SIMPLE_APPROVAL_FORM_URN_REGEX));

const isIntegrationResource = ({ urn }: CatalogResource) => {
  const { resourceId } = parseResourceRef(urn);

  const root = resourceId.split('/')[0];

  return INTEGRATIONS_KEY_WORDS.includes(root);
};

const isNotReviewDraftContractResource = ({ urn }: ServiceCatalogResource) =>
  !urn.match(REVIEW_DRAFT_CONTRACT_URN_REGEX);

const isNotCreateContractSeviceResource = ({ urn }: ServiceCatalogResource) =>
  !urn.match(CREATE_CONTRACT_SERVICE_URN_REGEX);

const isNotAdvisoryServiceTaskResource = ({ urn }: ServiceCatalogResource) =>
  !urn.match(ADVISORY_SERVICE_TASK_URN_REGEX);

const getActiveResourcesForTasksNodes = (resources: CatalogResource[]) => {
  const activeResources = resources
    .filter(isActiveServiceOrFormResource)
    .filter(isNotCustomFormCreatedByUser)
    .filter(isDeprecated)
    .filter(isNotReviewDraftContractResource)
    .filter(isNotCreateContractSeviceResource)
    .filter(isNotAdvisoryServiceTaskResource);

  return {
    resourcesForTasks: activeResources.filter((resource) => !isIntegrationResource(resource)),
    resourcesForIntegrations: activeResources.filter(isIntegrationResource),
  };
};

const getTaskConfigurationsFromResource = (resource: CatalogResource): TaskConfigurations[] => {
  if (!isActiveServiceOrFormResource(resource)) {
    return [];
  }

  const { definition, urn } = resource;
  const kind = definition.Kind;

  switch (kind) {
    case SERVICE_KIND:
      return [{ kind: 'Vertice:Service', version: DEFINITION_VERSION, resourceUrn: urn }];
    case FORM_KIND:
      return [
        { kind: 'Vertice:Form', version: DEFINITION_VERSION, formUrn: urn },
        { kind: 'Tasks:DueDate', valueType: 'ISO_8601', value: 'P7D', version: DEFINITION_VERSION },
      ];
    default:
      return [];
  }
};

const getTaskIconFromResource = (resource: CatalogResource) => {
  if (!isIntegrationResource(resource)) {
    return undefined;
  }

  const { resourceId } = parseResourceRef(resource.urn);

  const resourcePath = resourceId.split('/');
  const key = resourcePath[0] === INTEGRATIONS ? resourcePath[1] : resourcePath[0];

  if (key in INTEGRATIONS_ICONS_MAP) {
    return INTEGRATIONS_ICONS_MAP[key];
  }

  return undefined;
};

const mapResourceToTaskNode = (resource: CatalogResource): TaskDnDNode => ({
  id: resource.urn,
  name: resource.name,
  type: 'task',
  taskType: TASK_TYPES_MAP[resource.definition.Kind] ?? 'Service',
  configurations: getTaskConfigurationsFromResource(resource),
  icon: getTaskIconFromResource(resource),
});

export const useDnDNodesGroups = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.NODES_PANEL' });

  const { resources, isLoading } = useGetCatalogResources({});

  const nodeGroups = useMemo((): DnDNodesGroup[] => {
    const { resourcesForTasks, resourcesForIntegrations } = getActiveResourcesForTasksNodes(resources);

    const taskNodes = resourcesForTasks.map(mapResourceToTaskNode);
    const integrationNodes = resourcesForIntegrations.map(mapResourceToTaskNode);

    const tasksNodeGroup: DnDNodesGroup = {
      id: 'group-tasks',
      title: t('TASKS'),
      icon: ApprovalOutlined,
      nodes: taskNodes,
    };

    const integrationsNodeGroup: DnDNodesGroup = {
      id: 'group-integrations',
      title: t('INTEGRATIONS'),
      icon: ExtensionOutlined,
      nodes: integrationNodes,
    };

    const gatewayNodeGroup: DnDNodesGroup = {
      id: 'group-gateway',
      title: t('GATEWAYS'),
      icon: GatewayInclusiveIcon,
      nodes: [
        {
          id: 'node-gateway-exclusive',
          type: 'gateway',
          gatewayType: 'Exclusive',
          name: t('GATEWAY_GROUP.EXCLUSIVE'),
          description: t('GATEWAY_GROUP.EXCLUSIVE_DESCRIPTION'),
        },
        {
          id: 'node-gateway-inclusive',
          type: 'gateway',
          gatewayType: 'Fork',
          name: t('GATEWAY_GROUP.INCLUSIVE'),
          description: t('GATEWAY_GROUP.INCLUSIVE_DESCRIPTION'),
        },
        {
          id: 'node-gateway-join',
          type: 'gateway',
          gatewayType: 'Join',
          name: t('GATEWAY_GROUP.JOIN'),
          description: t('GATEWAY_GROUP.JOIN_DESCRIPTION'),
        },
      ],
    };

    const basicNodeGroup: DnDNodesGroup = {
      id: 'group-basic',
      title: t('NODES'),
      icon: LineAndSquareIcon,
      nodes: [
        {
          id: 'node-end',
          type: 'end',
          name: t('NODES_GROUP.END'),
        },
      ],
    };

    return [tasksNodeGroup, integrationsNodeGroup, gatewayNodeGroup, basicNodeGroup].filter(
      (group) => group.nodes.length > 0
    );
  }, [t, resources]);

  return {
    nodeGroups,
    isLoading,
  };
};
