import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Loader } from '@verticeone/design-system';

import HostnameField, { PROTOCOL } from '../components/HostnameField';
import IntegrationNameField from '../components/IntegrationNameField';
import AuthField from '../components/AuthField';
import TestConnectionField from '../components/TestConnectionField';
import TestConnectionButton from '../components/TestConnectionButton';
import FormSection from '../components/FormSection';
import SaveIntegrationButton from '../components/SaveIntegrationButton';
import TestConnectionResponse from '../components/TestConnectionResponse';
import { EditSchema, editSchema } from './scheme';
import { ApiKeySecret } from './utils';
import { useUpdateIntegration } from '../../../../dataSource/useUpdateIntegration';
import { useGetIntegrationById } from '../../../../dataSource/useGetIntegrationById';
import useTestConnection from '../../../../dataSource/useTestConnection';
import { Enabled, IntegrationItem } from '../../../../utils';
import { useChangeIntegrationStatus } from '../../../../dataSource/useChangeIntegrationStatus';

type ApiKeyFormProps = {
  integration: IntegrationItem & Enabled;
  integrationId: string;
  onDismiss: () => void;
  onSubmit?: () => void;
  onError?: () => void;
};

const serialize = (integrationId: string, data: EditSchema) => ({
  integrationId,
  integrationName: data.name,
  authorizationHeaderName: data.auth.authorizationHeaderName,
  baseUrl: `${data.auth.baseUrl.protocol}://${data.auth.baseUrl.hostname}`,
  secret: {
    type: 'ApiKey' as const,
    apiKey: data.auth.apiKey,
  },
  enabled: true,
});

const ApiKeyForm = ({ integrationId, integration, onSubmit, onError, onDismiss }: ApiKeyFormProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const [updateIntegration] = useUpdateIntegration<ApiKeySecret>();
  const { activateIntegration } = useChangeIntegrationStatus();
  const [isSaving, setIsSaving] = useState(false);

  const { register, formState, handleSubmit, setValue, getValues, trigger, control } = useForm<EditSchema>({
    resolver: zodResolver(editSchema),
    mode: 'onChange',
    defaultValues: {
      name: integration?.integrationName,
      auth: {
        apiKey: '',
        authorizationHeaderName: integration?.authorizationHeaderName ?? '',
        baseUrl: {
          protocol: PROTOCOL,
          hostname: integration?.baseUrl?.replace(`${PROTOCOL}://`, '') ?? '',
          path: '',
        },
      },
    },
  });

  const [testConnection, testConnectionState] = useTestConnection({
    serialize: () => ({
      existingId: integrationId,
      protocol: getValues('auth.baseUrl.protocol'),
      hostname: getValues('auth.baseUrl.hostname'),
      path: getValues('auth.baseUrl.path'),
      auth: {
        authType: 'apiKey',
        authHeaderName: getValues('auth.authorizationHeaderName'),
        apiKey: getValues('auth.apiKey'),
      },
    }),
  });

  const onFormSubmit = async (data: Required<IntegrationItem<ApiKeySecret>>) => {
    setIsSaving(true);
    try {
      const response = await updateIntegration(!!data.secret.apiKey, data);
      if (integration.enabled) {
        await activateIntegration(integrationId);
      }
      if ('error' in response) {
        onError?.();
      } else {
        onSubmit?.();
      }
    } catch {
      onError?.();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit((submittedData) => onFormSubmit(serialize(integrationId, submittedData)))}>
      <Stack direction="column" gap={6}>
        <IntegrationNameField name="name" register={register} control={control} disabled />
        <AuthField
          authHeaderName="auth.authorizationHeaderName"
          apiKeyName="auth.apiKey"
          control={control}
          placeholder="******************"
        />

        <FormSection title={t('SECTION.CONNECTION')}>
          <HostnameField
            name="auth.baseUrl.hostname"
            register={register}
            setValue={setValue}
            control={control}
            trigger={trigger}
          />
          <Stack direction="row" alignItems="flex-end" gap={2}>
            <TestConnectionField
              name="auth.baseUrl.path"
              protocolPath="auth.baseUrl.protocol"
              hostnamePath="auth.baseUrl.hostname"
              register={register}
              control={control}
            />
            <TestConnectionButton
              control={control}
              validateFields={['auth.baseUrl', 'auth.apiKey']}
              onClick={testConnection}
              isLoading={testConnectionState.isLoading}
            />
          </Stack>
        </FormSection>

        <FormSection title={t('SECTION.TEST')}>
          <TestConnectionResponse {...testConnectionState} />
          <Stack direction="row" gap={2}>
            <Button variant="outline" size="M" color="neutral" onClick={onDismiss} disabled={isSaving}>
              {t('DISMISS_BUTTON')}
            </Button>
            <SaveIntegrationButton isLoading={isSaving} isFormValid={formState.isValid} isDirty={formState.isDirty} />
          </Stack>
        </FormSection>
      </Stack>
    </form>
  );
};

const ApiKeyFormWrapper = ({ integrationId, ...otherProps }: Omit<ApiKeyFormProps, 'integration'>) => {
  const { data: integration, isLoading } = useGetIntegrationById({ integrationId });

  if (isLoading) {
    return <Loader />;
  }

  return <ApiKeyForm integration={integration} integrationId={integrationId} {...otherProps} />;
};

export default ApiKeyFormWrapper;
