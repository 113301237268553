import React, { FC, useCallback } from 'react';
import { Box, Stack } from '@mui/material';
import { Link, useSearchParams } from 'react-router-dom';

import { Button } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { ServiceDrawer } from '../components/ServiceDrawer/ServiceDrawer';
import { useServiceCardsV2 } from '../hooks/useServiceCardsV2';
import { CardActions } from './CardActions';
import { useGetCatalogResources } from '../../hooks/useGetCatalogResources';
import { SERVICE_CATALOG_SERVICE_KIND } from '../../constants';
import type { ServiceCatalogResource } from '../../catalogResource/types';
import { useTranslation } from 'react-i18next';
import useLoggedUserAccountRoles from '../../../../hooks/useLoggedUserAccountRoles';

export const ServicesPageV2: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSelectedService = searchParams.get('service');
  const urlPreviewService = searchParams.get('preview');
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const { resources, isLoading: isLoadingServiceResources } = useGetCatalogResources({
    resourcesFilter: (item) => item.definition.Kind === SERVICE_CATALOG_SERVICE_KIND && item.urn === urlSelectedService,
    skipGlobalResources: true,
  });

  const selectedService = isLoadingServiceResources ? undefined : resources?.[0];

  const handlePreviewClick = useCallback(
    (value: boolean, service: ServiceCatalogResource) => {
      setSearchParams((prev) => {
        if (value) {
          prev.set('preview', service.urn);
        } else {
          prev.delete('preview');
        }

        return prev;
      });
    },
    [setSearchParams]
  );

  const handleManageClick = useCallback(
    (service: ServiceCatalogResource) => {
      setSearchParams((prev) => {
        prev.set('service', service.urn);
        return prev;
      });
    },
    [setSearchParams]
  );

  const getCardActions = useCallback(
    (service: ServiceCatalogResource) => {
      return (
        <CardActions
          isPreviewOpen={urlPreviewService === service.urn}
          service={service}
          onPreviewClick={(value) => handlePreviewClick(value, service)}
          onManageClick={() => handleManageClick(service)}
        />
      );
    },
    [handleManageClick, handlePreviewClick, urlPreviewService]
  );

  const serviceCards = useServiceCardsV2({
    getCardActions,
  });

  return (
    <IntelligentWorkflowsLayout
      withDivider
      actions={
        isUserAdmin ? (
          <Button
            variant="solid"
            color="primary"
            size="M"
            component={Link}
            to={routes.INTELLIGENT_WORKFLOWS.TEMPLATES.ABSOLUTE_PATH}
          >
            {t('INTELLIGENT_WORKFLOWS.SERVICES.ACTIONS.BROWSE_TEMPLATES')}
          </Button>
        ) : undefined
      }
    >
      <Stack gap={4} paddingBlock={8}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
            gap: 4,
          }}
        >
          {serviceCards}
        </Box>
      </Stack>
      <ServiceDrawer
        isOpen={!!selectedService}
        onClose={() =>
          setSearchParams((prev) => {
            prev.delete('service');
            return prev;
          })
        }
        service={selectedService}
      />
    </IntelligentWorkflowsLayout>
  );
};
