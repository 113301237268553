import { Stack, useTheme } from '@mui/material';
import { Contract, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useFormatCurrency } from '@verticeone/utils/formatting';
import { ChipButton, IconWrapper, Text, Tooltip } from '@verticeone/design-system';
import { match } from 'ts-pattern';
import {
  annualToRollPeriodCost,
  getEffectiveRollFrequency,
  isContractRequirementsGathering,
  isRollingRollFrequency,
} from '../../../computed';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '../../../../../../constants/currency';
import { useExchangeCurrencyHint } from '../../../../../currency/useGetExchangeCurrencyHint';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { ExchangeToAccountCurrencyFnType } from './AnnualCostCell';
import { useTranslation } from 'react-i18next';

export type KeyCostCellProps = {
  viewOnContract: ViewOnContract;
  accountCurrency: string;
  exchangeCurrency: ExchangeToAccountCurrencyFnType;
};

const KEY_COST_VALUE_TEST_ID = 'key-cost-value';

const KeyCostKindChip = ({ contract }: { contract: Contract }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.CONTRACT.LABELS' });
  return (
    <ChipButton isActive={false} color="secondary" size="XXS" variant="ghost" sx={{ flexShrink: 0 }}>
      {match(getEffectiveRollFrequency(contract))
        .with('ONE_OFF', () => t('TOTAL'))
        .with('NO', () => t('FREQUENCY_ANNUALLY'))
        .with('MONTHLY', () => t('FREQUENCY_MONTHLY'))
        .with('QUARTERLY', () => t('FREQUENCY_QUARTERLY'))
        .with('BIANNUALLY', () => t('FREQUENCY_BIANNUALLY'))
        .with('ANNUALLY', () => t('FREQUENCY_ANNUALLY'))
        .exhaustive()}
    </ChipButton>
  );
};

const getKeyCostInContractCurrency = (contract: Contract) => {
  if (isContractRequirementsGathering(contract)) {
    return undefined;
  }
  const annualCost = contract.parts.overview?.effectiveAnnualCost;
  return match(getEffectiveRollFrequency(contract))
    .returnType<number | undefined>()
    .with('ONE_OFF', () => contract.parts.overview?.effectiveTotalCostValue)
    .with('NO', () => annualCost)
    .when(isRollingRollFrequency, (rollFreq) =>
      !isNil(annualCost) ? annualToRollPeriodCost(annualCost, rollFreq) : undefined
    )
    .exhaustive();
};

/** Returns key cost converted to account currency. */
export const keyCostCellValueGetter = ({ contract }: ViewOnContract, exchange: ExchangeToAccountCurrencyFnType) => {
  const keyCostInContractCurrency = getKeyCostInContractCurrency(contract);
  const contractCurrency = contract.parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY;
  return isNil(keyCostInContractCurrency) ? undefined : exchange(keyCostInContractCurrency, contractCurrency);
};

export const KeyCostCell = ({ viewOnContract, accountCurrency, exchangeCurrency }: KeyCostCellProps) => {
  const formatCurrency = useFormatCurrency();
  const { palette } = useTheme();

  const keyCost = getKeyCostInContractCurrency(viewOnContract.contract);
  const contractCurrency = viewOnContract.contract.parts.contractual?.financial?.baseCurrency || DEFAULT_CURRENCY;
  const { getExchangeCurrencyHint } = useExchangeCurrencyHint();

  const format = (value: number, currency: string) => formatCurrency(value, { currency, maximumFractionDigits: 0 });

  return !isNil(keyCost) ? (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
      <KeyCostKindChip contract={viewOnContract.contract} />
      <Stack alignItems="flex-end">
        <Text testId={KEY_COST_VALUE_TEST_ID} variant="body-regular" textAlign="right">
          {formatCurrency(keyCost, { currency: contractCurrency, maximumFractionDigits: 0 })}
        </Text>

        {accountCurrency !== contractCurrency && (
          <Stack direction="row" gap={1} alignItems="center">
            <Text variant="caption" size="S" color="text3">
              {format(exchangeCurrency(keyCost, contractCurrency), accountCurrency)}
            </Text>
            <Tooltip content={getExchangeCurrencyHint([contractCurrency, accountCurrency])} size="M">
              <IconWrapper icon={InfoIcon} size="M" htmlColor={palette.text.color3} />
            </Tooltip>
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : (
    <Text component="div" testId={KEY_COST_VALUE_TEST_ID} variant="body-regular" color="inactive2" textAlign="right">
      —
    </Text>
  );
};
