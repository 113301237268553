import { useMemo } from 'react';
import { ContractNegotiationTier } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from '../../../account/AccountContext';

export type NegotiationTierOption = {
  value: ContractNegotiationTier;
  label: string;
};

export const useNegotiationTierOptions = () => {
  const { t } = useTranslation();
  const { accountDetails } = useAccountContext();
  return useMemo(
    (): NegotiationTierOption[] => [
      { value: 'NEGOTIATION_TIER_VERTICE', label: t('ENTITIES.CONTRACT.NEGOTIATION_TIERS.VERTICE') },
      { value: 'NEGOTIATION_TIER_SUPPORT', label: t('ENTITIES.CONTRACT.NEGOTIATION_TIERS.SUPPORT') },
      {
        value: 'NEGOTIATION_TIER_CUSTOMER',
        label: accountDetails?.name ?? t('ENTITIES.CONTRACT.NEGOTIATION_TIERS.CUSTOMER'),
      },
    ],
    [accountDetails?.name, t]
  );
};
