import { useDispatch } from 'react-redux';
import { WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES, enhancedBffeWorkflowsAPI } from '@vertice/slices';
import { useCallback } from 'react';

export const useInvalidateWorkflowList = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(enhancedBffeWorkflowsAPI.util.invalidateTags([WORKFLOW_BFFE_VERSIONS, WORKFLOW_BFFE_INSTANCES])),
    [dispatch]
  );
};
