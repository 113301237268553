import { useMemo } from 'react';
import { useAccountContext } from '../../../../account/AccountContext';
import { useContractContext } from '../../ContractContext';
import { useListContractsPaginatedQuery } from '@vertice/slices';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { orderBy } from 'lodash';
import { isCancelledContract } from '../../computed';

export type UseContractLineageDataReturn = {
  data?: ViewOnContract[];
  isLoading: boolean;
};

const useContractLineageData = (): UseContractLineageDataReturn => {
  const { accountId } = useAccountContext();
  const contractContext = useContractContext('REQUIRE_FETCHED');
  const lineageId = contractContext.fetchedContract.contract.record.lineageId;

  const { data } = useListContractsPaginatedQuery({
    accountId,
    lineageId,
    maxResults: 100, // More items can cause time-outs
  });

  const orderedLinkedContracts = useMemo(() => {
    if (!data) {
      return undefined;
    }

    // Filter out cancelled contracts and cancelled requests
    const filteredContracts = data.contracts.filter((contract) => !isCancelledContract(contract.contract));

    // Let's not show linked contract if there is only one
    if (filteredContracts.length <= 1) {
      return [];
    }

    return orderBy(filteredContracts, (view) => view.computed?.chronologicalLineageOrder, 'desc');
  }, [data]);

  return {
    data: orderedLinkedContracts,
    isLoading: !orderedLinkedContracts,
  };
};

export default useContractLineageData;
