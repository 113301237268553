import { SeriesOptionsType, SeriesWordcloudOptions } from 'highcharts';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { RangePricingBenchmark, useGetProductPricingBenchmarksQuery } from '@vertice/slices';
import { useMemo } from 'react';
import {
  getAccountPricingRangePerTier,
  getNumerosityValuesForAllCategories,
  getRelevantCategories,
  getSeries,
  isYouAreHereDataAvailable,
} from './utils';
import { useTheme } from '@mui/material';
import { categories } from './constants';

export enum BenchmarkingPeriods {
  MONTHLY = 1,
  ANNUALLY = 12,
}

export type UseGetBenchmarkDataProps = {
  vendorId: string;
  productId: string;
  contractId?: string;
  period: BenchmarkingPeriods;
  skip?: boolean;
};

export type BenchmarkData = {
  series: SeriesWordcloudOptions[];
  numerosity: SeriesOptionsType[];
  accountPricingRangePerTier: Record<string, RangePricingBenchmark>;
  categories: string[];
  isYouAreHereDataAvailable: boolean;
};

export type UseGetBenchmarkDataReturn = {
  data?: BenchmarkData;
  isLoading: boolean;
};

const useGetBenchmarkData = ({
  vendorId,
  productId,
  contractId,
  period,
  skip = false,
}: UseGetBenchmarkDataProps): UseGetBenchmarkDataReturn => {
  const { palette } = useTheme();
  const { accountId } = useAccountContext();
  const { data, isLoading } = useGetProductPricingBenchmarksQuery(
    {
      accountId,
      vendorId,
      productId,
      contractId,
    },
    { skip }
  );

  const availableColors = useMemo(
    () => [
      palette.visualization.divergent.primary['-50'],
      palette.visualization.divergent.secondary['-50'],
      palette.visualization.divergent.tertiary['-50'],
      palette.visualization.divergent.primary['+50'],
      palette.visualization.divergent.secondary['+50'],
      palette.visualization.divergent.tertiary['+50'],
    ],
    [palette]
  );

  const processedData = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const multiplier: number = period;
    const relevantCategories = data.tiers ? getRelevantCategories(data.tiers) : categories;

    const series = data.tiers
      ? getSeries(data.tiers, {
          availableColors,
          relevantCategories,
          multiplier,
        })
      : [];
    const numerosity: SeriesOptionsType[] = data?.numerosity
      ? [
          {
            type: 'column',
            name: 'numerosity',
            data: getNumerosityValuesForAllCategories(data?.numerosity, { relevantCategories }),
          },
        ]
      : [];

    return {
      series,
      accountPricingRangePerTier: getAccountPricingRangePerTier(data.tiers ?? [], multiplier),
      numerosity,
      categories: relevantCategories,
      isYouAreHereDataAvailable: isYouAreHereDataAvailable(data.tiers ?? []),
    };
  }, [data, period, availableColors]);

  return {
    data: processedData,
    isLoading: isLoading || (!skip && !processedData),
  };
};

export default useGetBenchmarkData;
