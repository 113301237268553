import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { AddExistingContractFormData } from '../../types';
import { useComputedCosts } from './useComputedCosts';
import { OverridableCostFormEntry } from './OverridableCostFormEntry';

export const AnnualCostOverridePanel = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<AddExistingContractFormData>();
  const currency = useWatch({ control, name: 'contractCurrency' });
  const { data, isLoading: loadingComputedCosts, changedField } = useComputedCosts();

  return (
    <OverridableCostFormEntry
      label={t('ENTITIES.CONTRACT.LABELS.ANNUALIZED_CONTRACT_VALUE')}
      name="annualCostOverride"
      currency={currency}
      computedCost={data?.annualCost}
      totalContractValue={data?.totalCost}
      isLoading={loadingComputedCosts && !changedField?.startsWith('annualCostOverride')}
    />
  );
};
