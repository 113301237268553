import { zodResolver } from '@hookform/resolvers/zod';
import { randomId } from '@mui/x-data-grid-generator';
import { FC, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { DEFINITION_VERSION } from '../../../../../definitions/constants';
import { isIOMappingConfiguration, updateTaskIOMapping } from '../../../../../definitions/taskDefinition';
import { FieldMapping, TaskIOMappingConfiguration } from '../../../../../definitionsTypes';
import {
  EditorDrawerFooter,
  EditorDrawerForm,
  EditorDrawerHeader,
  EditorDrawerScrollContainer,
} from '../../../EditorDrawer';
import { TaskProperties } from '../../../forms/EditServiceTask/TaskProperties';
import { IssueTypePickerProps } from '../JiraCommon/IssueTypePicker';
import { JiraSetupLink } from '../JiraCommon/JiraSetupLink';
import {
  enhanceInputFieldsName,
  ISSUE_TYPE_ID_NAME,
  PROJECT_ID_NAME,
  TICKET_ID_NAME,
  transformJiraToWorkflowLikeVariables,
} from '../JiraCommon/utils';
import { VariableMapperProps } from '../../../VariableMapper/VariableMapper';
import { EditServiceTaskBase } from '../types';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { JiraCreateTaskFormData, jiraCreateTaskFormSchema } from './formSchema';
import { JiraCreateSetup } from './JiraCreateSetup';
import { enhanceWithRequired, workflowToFormVariables } from '../../../VariableMapper/utils';
import { getJiraCreateInputMapping } from './utils';
import { RequiredField } from '../../../VariableMapper/types';
import { IntegrationWrapperBaseProps } from '../JiraCommon/IntegrationWrapper';
import { ProjectPickerProps } from '../JiraCommon/ProjectPicker';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { useListCreateJiraIssueTypeFieldsQuery, useListJiraIssueTypesQuery } from '@vertice/slices';
import { useCatalogResources } from '../../../../WorkflowViewer/useCatalogResources';
// import { Attachments } from './Attachments';

export type JiraCreateFormProps = Pick<
  EditServiceTaskBase,
  'task' | 'onSave' | 'onDelete' | 'onDirty' | 'onClose' | 'isWorkflowCustomizationEnabled'
> & {
  projectPickerProps: Omit<ProjectPickerProps, 'defaultProjectId'>;
  issueTypePickerProps: Omit<IssueTypePickerProps, 'defaultIssueTypeId'>;
  variableMapperProps: Pick<
    VariableMapperProps<JiraCreateTaskFormData>,
    'workflowVariables' | 'otherVariables' | 'isLoading' | 'mapperRowsCount'
  >;
  integrationWrapperProps: IntegrationWrapperBaseProps;
};

export const JiraCreateForm: FC<JiraCreateFormProps> = ({
  task,
  onSave,
  onDirty,
  onClose,
  onDelete,
  projectPickerProps,
  issueTypePickerProps,
  variableMapperProps,
  integrationWrapperProps,
}) => {
  const { projectId, issueTypeId, variables } = getJiraCreateInputMapping(task);

  const values = useMemo<JiraCreateTaskFormData>(() => {
    let initialVariables = workflowToFormVariables(variables, true);
    const requiredFields: RequiredField[] = REQUIRED_JIRA_CREATE_FIELDS.map((externalFieldId) => {
      return { variableId: externalFieldId, whereRequired: 'to', preCreateWfVariable: false, mappingType: 'Value' };
    });
    const { requiredFormVariables, restFormVariables } = enhanceWithRequired({
      formVariables: initialVariables,
      requiredFields,
    });

    return {
      name: task.task.name ?? '',
      description: task.task.description ?? '',
      projectId: projectId ?? '',
      issueType: issueTypeId ?? '',
      requiredVariables: requiredFormVariables,
      variables: restFormVariables,
      // TODO: Add attachments feature once it's fully supported on WF side
      // includeAttachments: includeAttachments ?? true,
    };
  }, [projectId, issueTypeId, task, variables]);

  /*** Setup form ***/
  const formHandlers = useForm<JiraCreateTaskFormData>({
    resolver: zodResolver(jiraCreateTaskFormSchema),
    defaultValues: values,
  });

  const { accountId } = useAccountContext();

  const formProjectId = useWatch({ control: formHandlers.control, name: 'projectId' });
  const formIssueTypeId = useWatch({ control: formHandlers.control, name: 'issueType' });

  const { data: issueTypesData, isLoading: isLoadingIssueTypes } = useListJiraIssueTypesQuery(
    {
      accountId,
      projectId: formProjectId ?? '',
    },
    { skip: !formProjectId }
  );

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: formProjectId ?? '',
      issueTypeId: formIssueTypeId ?? '',
    },
    { skip: !formProjectId || !formIssueTypeId }
  );

  const { xTypeCatalogResources } = useCatalogResources();

  const otherVariables = useMemo(
    () =>
      issueTypeFieldsData?.fields &&
      transformJiraToWorkflowLikeVariables(
        (formProjectId && formIssueTypeId && issueTypeFieldsData?.fields) || [],
        xTypeCatalogResources
      ),
    [formProjectId, formIssueTypeId, issueTypeFieldsData?.fields, xTypeCatalogResources]
  );

  const onSubmit = (data: JiraCreateTaskFormData) => {
    if (!task) return;

    const allVariables = [...data.requiredVariables, ...data.variables];
    const inputFields = allVariables.map((mapperVariable) => {
      const inputField: FieldMapping = {
        name: enhanceInputFieldsName(variableMapperProps.workflowVariables, otherVariables || [], mapperVariable),
        value: mapperVariable.from,
        mappingType: 'JMESPathExpression',
      };
      return inputField;
    });

    // Add extra values BE need to know
    inputFields.push({
      name: ISSUE_TYPE_ID_NAME,
      value: data.issueType,
      mappingType: 'Value',
    });

    inputFields.push({
      name: PROJECT_ID_NAME,
      value: data.projectId,
      mappingType: 'Value',
    });
    // TODO: Add attachments feature once it's fully supported on WF side
    // inputFields.push({
    //   name: 'includeAttachments',
    //   value: `\`${data.includeAttachments}\``,
    //   mappingType: 'JMESPathExpression',
    // });

    // Re-save, if already existed, or create some ticketId for first time
    const ioMappingConfigurations = (task.task.configurations ?? []).filter((v) =>
      isIOMappingConfiguration(v)
    ) as TaskIOMappingConfiguration[];
    let outputFields = ioMappingConfigurations.length ? ioMappingConfigurations[0].mapping.outputFields : [];
    const alreadyExists = outputFields.find((outputField) => outputField.value === TICKET_ID_NAME);
    if (!alreadyExists) {
      outputFields = [
        {
          name: `ticketIdX${randomId().split('-')[0]}`,
          value: TICKET_ID_NAME,
          mappingType: 'JMESPathExpression',
        },
      ];
    }

    const newTask = updateTaskIOMapping(task, {
      name: data.name,
      description: data.description,
      ioMappingConfigurations: [
        {
          kind: 'ProcessEngine:TaskIOMapping',
          version: DEFINITION_VERSION,
          mapping: {
            inputFields,
            outputFields,
          },
        },
      ],
    });

    onSave(newTask);
  };

  return (
    <FormProvider {...formHandlers}>
      <EditorDrawerForm onSubmit={formHandlers.handleSubmit(onSubmit)}>
        <EditorDrawerHeader
          title={task?.task.name ?? ''}
          deleteOptions={{
            nodeName: task.task.name ?? '',
            onDelete: () => onDelete(task.task.id),
          }}
        />
        <EditorDrawerScrollContainer>
          {/*TODO: Rethink and refactor use of name and description fields to main schema*/}
          <TaskProperties onDirty={onDirty} />
          <JiraCreateSetup
            projectPickerProps={projectPickerProps}
            issueTypePickerProps={{
              ...issueTypePickerProps,
              isLoadingIssueTypes: isLoadingIssueTypes,
              issueTypes: issueTypesData?.issueTypes ?? [],
            }}
            variableMapperProps={{
              ...variableMapperProps,
              otherVariables: otherVariables,
              isLoading: isFetchingIssueTypeFields,
            }}
            integrationWrapperProps={integrationWrapperProps}
          />
          {integrationWrapperProps.isActive && <JiraSetupLink />}
        </EditorDrawerScrollContainer>
        <EditorDrawerFooter onDiscard={onClose} disabled={!formHandlers.formState.isValid} />
      </EditorDrawerForm>
    </FormProvider>
  );
};
