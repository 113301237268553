import { upperFirst } from 'lodash';

export type AuthType = 'ApiKey';
export type IntegrationType = 'Inbound' | 'Outbound';
export type IntegrationCategory = 'CUSTOM_INBOUND' | 'CUSTOM_OUTBOUND';

export type Enabled = {
  enabled: boolean;
};

export type Secret<K extends AuthType = AuthType, T extends Record<string, string> = Record<any, any>> = {
  type: K;
} & T;

export type IntegrationItem<S extends Secret = Secret> = {
  integrationId?: string;
  authorizationHeaderName: string;
  integrationName: string;
  baseUrl: string;
  secret: S;
};

type GenerateIntegrationIdProps = {
  integrationName: string;
  integrationType: IntegrationType;
  authType: AuthType;
};

type ParseIntegrationIdReturn = {
  category: IntegrationCategory;
  integrationName: string;
  integrationType: IntegrationType;
  authType: AuthType;
};

export const generateIntegrationId = ({ integrationName, integrationType, authType }: GenerateIntegrationIdProps) => {
  const key = integrationName
    .replace(/[^A-Z0-9]/gi, ' ')
    .split(' ')
    .map(upperFirst)
    .join('');
  return `Provider:IntegrationGeneric:${integrationType}:${authType}:${key}`;
};

export const parseIntegrationId = (integrationId: string): ParseIntegrationIdReturn => {
  const [integrationType, authType, integrationName] = integrationId
    .replace('Provider:IntegrationGeneric:', '')
    .split(':');

  return { integrationType, authType, integrationName } as ParseIntegrationIdReturn;
};
