import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { mapKeys, mapValues, toLower } from 'lodash';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import recommendationsByCodeRaw from '@vertice/i18n/src/i18n/cloud/recommendationsByCode_en.json';
import type { OptimizationRecommendation } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useCloudClient } from '../../CloudClientProvider';
import useDataVisibility from './useDataVisibility';

type RecommendationsCode = keyof typeof recommendationsByCodeRaw;

type TOptimizationRecommendationRow = {
  code: string;
  name: string;
  description?: string;
  instructions?: string;
  effort: 'LOW' | 'MEDIUM' | 'HIGH';
};

export type OptimizationRow = Omit<OptimizationRecommendation, keyof TOptimizationRecommendationRow> &
  TOptimizationRecommendationRow & { isVisible: boolean };

const OptimizationsQuery = graphql(`
  query OptimizationsList($accountId: String!) {
    optimizationsQuery(params: { accountId: $accountId }) {
      ... on OptimizationsResult {
        __typename
        summary {
          recsBreaching
          recsCancelled
          recsNonBreaching
          recsTotal
          estimatedSavings {
            category
            count
            saving
          }
        }
        recs {
          breaching
          categories
          isObfuscated
          code
          effort
          products
          proposedCost
          resourceCost
          saving
          status
          tags
          tests {
            code
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export const QUERY_KEY = 'Optimizations' as const;

const transformKeys = <K extends string, V extends any>(object: Record<K, any>): Record<K, V> => {
  return mapValues(object, (item) => mapKeys(item, (_, key) => toLower(key))) as Record<K, V>;
};

const replaceWithHashedStrings = (sentence?: string) => {
  if (!sentence) {
    return '';
  }

  return sentence
    .split(' ')
    .map((word) =>
      Array.from(word, (char, i) => {
        const isUpperCase = char === char.toUpperCase();
        const base = isUpperCase ? 65 : 97; // ASCII value for 'A' or 'a'
        return String.fromCharCode(((char.charCodeAt(0) + i) % 26) + base);
      }).join('')
    )
    .join(' ');
};

export const useOptimizations = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();
  const { canViewFullVersion } = useDataVisibility();

  //data from translations
  const dataRecommendationsByCode = useMemo(
    () => transformKeys<RecommendationsCode, TOptimizationRecommendationRow>(recommendationsByCodeRaw),
    []
  );

  return useQuery({
    queryKey: [QUERY_KEY, accountId],
    queryFn: () => fetchCloudOptimization(OptimizationsQuery, { accountId }),
    enabled: !!accountId,
    select: (result) => {
      if (result?.optimizationsQuery?.__typename === 'OptimizationsResult') {
        return {
          summary: result.optimizationsQuery.summary,
          recs: result.optimizationsQuery.recs
            .map((recommendation) => {
              const isVisible = canViewFullVersion || !recommendation.isObfuscated;
              const translationData = dataRecommendationsByCode[recommendation.code as RecommendationsCode] || {};
              const name = isVisible ? translationData.name : replaceWithHashedStrings(recommendation.code);

              return { ...translationData, ...recommendation, isVisible, name };
            })
            .filter(({ name }) => name) as Array<OptimizationRow>,
        };
      }
      return {
        summary: null,
        recs: [] as Array<OptimizationRow>,
      };
    },
  });
};

export default useOptimizations;
