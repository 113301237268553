import type { FC } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { DesignSystemColor, Text, TestProps } from '@verticeone/design-system';
import { AnimatedStack } from '@vertice/core/src/components/animations/MotionWrappers';

type NotificationBadgeContainerProps = {
  backgroundColor: DesignSystemColor;
};

const NotificationBadgeContainer = styled(AnimatedStack)<NotificationBadgeContainerProps>(
  ({ theme, backgroundColor }) => ({
    borderRadius: '100%',
    backgroundColor: theme.palette[backgroundColor].color2,
    height: 20,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  })
);

const notificationBadgeVariants = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
  minimized: { scale: 1, opacity: 1, x: 0, width: 8, height: 8 },
};

type NotificationBadgeBaseProps = {
  content: string;
  isMinimized: boolean;
} & NotificationBadgeContainerProps &
  TestProps;

const NotificationBadgeBase: FC<NotificationBadgeBaseProps> = ({ backgroundColor, content, isMinimized, testId }) => {
  return (
    <AnimatedStack
      initial="hidden"
      variants={{ minimized: { position: 'absolute', y: '-50%' } }}
      animate={isMinimized ? 'minimized' : 'visible'}
      exit="hidden"
    >
      <NotificationBadgeContainer
        variants={notificationBadgeVariants}
        transition={{ duration: 0.4 }}
        backgroundColor={backgroundColor}
      >
        {!isMinimized && (
          <AnimatePresence mode="wait">
            <AnimatedStack
              key={content}
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Text testId={testId} color="text5" variant="button-bold" size="XXS">
                {content}
              </Text>
            </AnimatedStack>
          </AnimatePresence>
        )}
      </NotificationBadgeContainer>
    </AnimatedStack>
  );
};

export type NotificationBadgeProps = {
  content?: string;
} & Omit<NotificationBadgeBaseProps, 'content'>;

export const NotificationBadge: FC<NotificationBadgeProps> = ({ content, ...restProps }) => (
  <AnimatePresence>{content ? <NotificationBadgeBase {...restProps} content={content} /> : null}</AnimatePresence>
);
