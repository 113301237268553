import uniqueBy from 'lodash/uniqBy';

import {
  Root as JsonSchemaRoot,
  XTypeDataSourceItems,
  SchemaArray,
  SimpleTypes as JsonSchemaType,
} from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { getVariableTypeLabels } from '../utils/getVariableTypeLabels';
import { ResourceUrn, Variable, VariableOrigin, xDatasourceParams } from '../WorkflowEditor/types';
import { FunctionCatalogResource, XTypeCatalogResource, ServiceCatalogResource } from '../../catalogResource/types';

export type JsonSchema = Extract<JsonSchemaRoot, object>;
export const isJsonSchema = (jsonSchema: JsonSchemaRoot | SchemaArray): jsonSchema is JsonSchema =>
  typeof jsonSchema !== 'boolean' && !Array.isArray(jsonSchema);

type XTypeDataSourceItemWithStringId = Omit<XTypeDataSourceItems[number], 'id'> & { id: string };
type XTypeDataSourceItemsWithStringId = XTypeDataSourceItemWithStringId[];
export const isXTypeOptionItems = (items: XTypeDataSourceItems): items is XTypeDataSourceItemsWithStringId => {
  return items.every((item) => typeof item.id === 'string');
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const formatVariableLabel = (variableName: string): string => {
  // Handle snake_case variables
  if (variableName.includes('_')) {
    const parts = variableName.split('_');
    return parts.map(capitalizeFirstLetter).join(' ');
  }

  // Handle camelCase variables
  const parts = variableName.split(/(?=[A-Z])/);
  return parts.map(capitalizeFirstLetter).join(' ');
};

const isUdfPropertyId = (propertyId: string) => propertyId.startsWith('udf');
const isUdfVariableId = (propertyId: string) => propertyId.startsWith('udf');

export const getPropertyId = (propertyName: string, parentPropertyId?: string): string => {
  if (!parentPropertyId) {
    return propertyName;
  }

  if (isUdfPropertyId(parentPropertyId)) {
    return `(${parentPropertyId}).${propertyName}`;
  }

  return `${parentPropertyId}.${propertyName}`;
};

export const PROPERTY_XTYPE_MAP = {
  requestId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/request\/id\/v\d+$/,
    isVisible: false,
  },
  departmentId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/department\/id\/v\d+$/,
    isVisible: true,
  },
  vendor: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/v\d+$/,
    isVisible: true,
  },
  vendorId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/vendor\/id\/v\d+$/,
    isVisible: false,
  },
  product: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/v\d+$/,
    isVisible: true,
  },
  productId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/product\/id\/v\d+$/,
    isVisible: false,
  },
  contractId: {
    regex: /urn:verticeone:vertice::services:schema\/saas\/contract\/id\/v\d+$/,
    isVisible: false,
  },
  userId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/user\/id\/v\d+$/,
    isVisible: true,
  },
  accountId: {
    regex: /urn:verticeone:vertice::services:schema\/core\/account\/id\/v\d+$/,
    isVisible: false,
  },
  money: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/v\d+$/,
    isVisible: true,
  },
  moneyAmount: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/amount\/v\d+$/,
    isVisible: true,
  },
  moneyCurrency: {
    regex: /urn:verticeone:vertice::services:schema\/core\/money\/currency\/v\d+$/,
    isVisible: false,
  },
} as const;

const removeJsonSchemasOfNullType = (jsonSchemas: SchemaArray) => {
  return jsonSchemas.filter(isJsonSchema).filter((jsonSchema) => jsonSchema.type !== 'null');
};

const getUdfInputFromInputJsonSchema = (functionCatalogResource: FunctionCatalogResource): string => {
  const inputJsonSchema = functionCatalogResource.definition?.Function?.FunctionProvider?.Interface?.Input?.JsonSchema;

  if (!inputJsonSchema || !isJsonSchema(inputJsonSchema)) {
    return '';
  }

  if (inputJsonSchema.type === 'object' && inputJsonSchema.properties) {
    let udfInput = '{';

    Object.keys(inputJsonSchema.properties).forEach((key, index, array) => {
      if (index > 0 && index < array.length) {
        udfInput += ', ';
      }
      udfInput += `${key}: ${key}`;
    });

    return udfInput + '}';
  }

  return '';
};

const UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP: Record<ResourceUrn, string> = {
  'urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation':
    'udf(`urn:verticeone:vertice::services:udf/saas/eligible-for-vertice-negotiation`, {approvedBudget: approvedBudget, budgetCurrency: contractCurrency})',
};

const removeVersionFromUrn = (urn: ResourceUrn) => urn.replace(/\/v\d+$/, '');

/*
 * UDFs function calls are not part of the service definition, so we need to manually map them.
 * If the UDF is not in the map, we need to generate the function call based on the input schema.
 * This is temporary until we have a better way to get the UDF function call.
 * It should be built by the user using the UI, but this is not part of this iteration.
 */
const getUdfPropertyId = (service: ServiceCatalogResource) => {
  const serviceUrn = service.urn;

  const serviceUrnWithoutVersion = removeVersionFromUrn(serviceUrn);

  if (serviceUrnWithoutVersion in UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP) {
    return UDF_SERVICE_URN_TO_FUNCTION_CALL_MAP[serviceUrnWithoutVersion];
  }

  const inputJsonSchema = getUdfInputFromInputJsonSchema(service);

  if (!inputJsonSchema) {
    return '';
  }

  return `udf(\`${serviceUrnWithoutVersion}\`, ${inputJsonSchema})`;
};

/*
 * List of UDFs that should not be shown in the UI.
 * This is temporary until we have a generic way to hide UDFs
 * which is to be decided how are we going to do it.
 */
const HIDDEN_UDFS = [
  'urn:verticeone:vertice::services:udf/saas/account/contract/owners',
  'urn:verticeone:vertice::services:udf/core/exchange-currency',
];

export const getVariableFromFunctionDefinition = ({
  functionCatalogResource,
  xTypeCatalogResources,
  catalog,
}: {
  functionCatalogResource: FunctionCatalogResource;
  xTypeCatalogResources: XTypeCatalogResource[];
  catalog: 'account' | 'global';
}): Variable => {
  const origin: VariableOrigin = {
    id: functionCatalogResource.urn,
    kind: `vertice-${catalog}-udf`,
    label: functionCatalogResource.name,
  };
  const defaultVariable: Variable = {
    id: '',
    label: '',
    type: {
      baseType: [],
      xType: '',
      format: '',
      labels: [],
    },
    origin: origin,
    isVisible: false,
    values: [],
    operators: [],
    variables: [],
    required: false,
    isSelectable: false,
    isDeprecated: functionCatalogResource.status === 'ARCHIVED',
    path: [],
  };

  if (HIDDEN_UDFS.includes(removeVersionFromUrn(functionCatalogResource.urn))) {
    return defaultVariable;
  }

  const jsonSchema = functionCatalogResource.definition?.Function?.FunctionProvider?.Interface?.Output?.JsonSchema;

  if (!jsonSchema || !isJsonSchema(jsonSchema)) {
    return defaultVariable;
  }

  const variableId = getUdfPropertyId(functionCatalogResource);

  if (!variableId) {
    return defaultVariable;
  }

  const udfVariableXType = jsonSchema['x-type'];
  const xTypeCatalogResource = xTypeCatalogResources.find((xTypeResource) => xTypeResource.urn === udfVariableXType);

  const isVariableVisible = getIsVariableVisible({
    variableKey: functionCatalogResource.name,
    xTypeCatalogResource,
    xEnabled: jsonSchema['x-enabled'],
    variableType: getVariablePrimitiveTypes(jsonSchema),
  });

  if (!isVariableVisible) {
    return defaultVariable;
  }

  const udfVariable = convertJsonSchemaToVariable({
    jsonSchema,
    origin,
    variableLabelOverride: functionCatalogResource.name,
    variableKey: variableId,
    xTypeCatalogResources,
    variableIsDeprecatedOverride: functionCatalogResource.status === 'ARCHIVED',
  });

  const variableType = getVariablePrimitiveTypes(jsonSchema);
  if ((variableType.includes('object') || variableType.includes('array')) && udfVariable.variables.length === 0) {
    return defaultVariable;
  }

  return {
    ...defaultVariable,
    variables: [udfVariable],
  };
};

const getVariablePrimitiveTypes = (jsonSchema: JsonSchema): JsonSchemaType[] => {
  if (jsonSchema.type) {
    return Array.isArray(jsonSchema.type) ? jsonSchema.type : [jsonSchema.type];
  }

  return [];
};

export const getVariableValues = ({
  jsonSchema,
  xTypeDefinition,
  datasourceParams,
}: {
  jsonSchema: JsonSchema;
  xTypeDefinition?: XTypeCatalogResource['definition'];
  datasourceParams?: xDatasourceParams;
}) => {
  const datasource = xTypeDefinition?.XType?.Datasource;
  if (datasource?.Items && isXTypeOptionItems(datasource.Items)) {
    return datasource.Items.filter((item) => {
      if (item['x-enabled'] !== undefined) {
        return item['x-enabled'];
      }

      return true;
    }).map((item) => ({
      value: item.id,
      name: item.id,
      label: item.title,
    }));
  }

  if (datasource?.Provided) {
    return datasourceParams ?? ('ProvidedAsyncByXTypeDataSource' as const);
  }

  if (jsonSchema.enum) {
    return jsonSchema.enum
      .filter((value) => value !== null)
      .map((value) => ({
        value: value?.toString() ?? '',
        name: value?.toString() ?? '',
        label: value?.toString() ?? '',
      }));
  }

  return [];
};

export const getIsVariableVisible = ({
  variableKey,
  xTypeCatalogResource,
  xEnabled,
  variableType,
  childVariables,
  displayArrayType,
}: {
  xTypeCatalogResource?: XTypeCatalogResource;
  variableKey: string;
  xEnabled?: boolean;
  variableType?: JsonSchemaType[];
  childVariables?: Variable[];
  displayArrayType?: boolean;
}) => {
  // Release 1: We don't want to display variables that return an array
  // ref: https://vertice.atlassian.net/browse/RED-1879
  if (variableType?.includes('array')) {
    return displayArrayType || false;
  }

  if (xEnabled !== undefined) {
    return xEnabled;
  }

  if (childVariables?.length) {
    // if the variable consists of variables, the visibility is determined based on the children variables
    return childVariables.some((variable) => variable.isVisible);
  }

  if (xTypeCatalogResource) {
    const { definition, urn } = xTypeCatalogResource;

    if (definition?.XType?.Operators?.Allowed.length === 0) {
      return false;
    }

    const xTypeDefinitionFE = Object.entries(PROPERTY_XTYPE_MAP).find(([_, { regex }]) => regex.test(urn))?.[1];

    if (xTypeDefinitionFE) {
      return xTypeDefinitionFE.isVisible;
    }
  }

  return variableKey !== 'id';
};

export const getVariableId = (variableKey: string, parentVariableId?: string) => {
  if (!parentVariableId) {
    return variableKey;
  }

  const finalVariableKey = variableKey === '[0]' ? variableKey : `.${variableKey}`;

  if (isUdfVariableId(parentVariableId)) {
    return `(${parentVariableId})${finalVariableKey}`;
  }

  return `${parentVariableId}${finalVariableKey}`;
};

const getIsVariableSelectable = (variableType: JsonSchema['type'], xTypeCatalogResource?: XTypeCatalogResource) => {
  if (variableType === 'array') {
    return false;
  }

  if (variableType === 'object') {
    if (!xTypeCatalogResource) {
      return false;
    }

    return PROPERTY_XTYPE_MAP.money.regex.test(xTypeCatalogResource.urn);
  }

  return true;
};

const getVariableIdOverride = ({
  variables,
  xTypeCatalogResource,
}: {
  xTypeCatalogResource?: XTypeCatalogResource;
  variables: Variable[];
}) => {
  if (!xTypeCatalogResource) {
    return undefined;
  }

  if (PROPERTY_XTYPE_MAP.money.regex.test(xTypeCatalogResource.urn)) {
    const amountVariable = variables.find(
      (variable) => variable.type.xType && PROPERTY_XTYPE_MAP.moneyAmount.regex.test(variable.type.xType)
    );

    if (!amountVariable) {
      return undefined;
    }

    return amountVariable.id;
  }

  return undefined;
};

export const convertJsonSchemaToVariable = ({
  jsonSchema,
  origin,
  variableKey,
  parentVariableId,
  variableLabelOverride,
  variableIsDeprecatedOverride,
  xTypeCatalogResources,
  parentVariableRequired,
  parentVariableIsSelectable,
  parentVariablePath = [],
  parentVariableDataSourceParams = {},
  displayArrayType,
}: {
  jsonSchema: JsonSchemaRoot;
  origin: VariableOrigin;
  parentVariableId?: string;
  parentVariableRequired?: string[];
  variableKey: string;
  variableLabelOverride?: string;
  xTypeCatalogResources: XTypeCatalogResource[];
  parentVariableIsSelectable?: boolean;
  parentVariablePath?: string[];
  parentVariableDataSourceParams?: xDatasourceParams;
  variableIsDeprecatedOverride?: boolean;
  displayArrayType?: boolean;
}): Variable => {
  if (!isJsonSchema(jsonSchema)) {
    return {
      id: '',
      label: '',
      type: {
        baseType: [],
        xType: '',
        format: '',
        labels: [],
      },
      origin,
      path: [],
      isVisible: false,
      isSelectable: false,
      variables: [],
      required: false,
    };
  }

  const variableId = getVariableId(variableKey, parentVariableId);
  const variableLabel = variableLabelOverride ?? jsonSchema.title ?? formatVariableLabel(variableKey);
  const isVariableDeprecated = variableIsDeprecatedOverride ?? undefined;
  const variablePath = variableLabel ? [...parentVariablePath, variableLabel] : parentVariablePath;

  // Handle allOf, anyOf, oneOf
  const combinationJsonSchemas = jsonSchema.allOf ?? jsonSchema.anyOf ?? jsonSchema.oneOf;
  if (combinationJsonSchemas) {
    const combinationJsonSchemasWithoutNullType = removeJsonSchemasOfNullType(combinationJsonSchemas);
    if (combinationJsonSchemasWithoutNullType.length === 1) {
      return convertJsonSchemaToVariable({
        jsonSchema: combinationJsonSchemasWithoutNullType[0],
        origin,
        variableKey,
        parentVariableId,
        xTypeCatalogResources,
        variableLabelOverride,
        parentVariableRequired,
        parentVariablePath,
        variableIsDeprecatedOverride,
      });
    }

    const possibleVariables = combinationJsonSchemasWithoutNullType.map((childJsonSchema) => {
      return convertJsonSchemaToVariable({
        jsonSchema: childJsonSchema,
        origin,
        variableKey,
        parentVariableId,
        xTypeCatalogResources,
        parentVariableRequired,
        variableLabelOverride,
        parentVariablePath,
        variableIsDeprecatedOverride,
      });
    });

    /* For now, we only handle object types as we can afford to merge properties of objects for allOf, anyOf, oneOf combinations.
     * The drawback is that we lose the distinction between the different types of objects.
     */
    if (possibleVariables.every((possibleVariable) => possibleVariable.type?.baseType?.includes('object'))) {
      const uniqueChildVariables = uniqueBy(
        possibleVariables.map((possibleVariable) => possibleVariable.variables).flat(),
        'id'
      );

      return {
        id: variableId,
        label: variableLabel,
        isDeprecated: isVariableDeprecated,
        type: {
          labels: ['Object'],
          baseType: ['object'],
        },
        isSelectable: false,
        origin,
        isVisible: true,
        values: [],
        operators: [],
        required: false,
        variables: uniqueChildVariables,
        path: variablePath,
      };
    }
  }

  const variables: Variable[] = [];

  const variableXType = jsonSchema['x-type'];
  const datasourceParams = { ...parentVariableDataSourceParams, ...jsonSchema['x-datasource-params'] };
  const xTypeCatalogResource = xTypeCatalogResources.find((xTypeResource) => xTypeResource.urn === variableXType);
  const xTypeDefinition = xTypeCatalogResource?.definition;
  const jsonSchemaRootFromXTypeDefinition = xTypeDefinition?.XType?.Schema?.JsonSchema;
  const finalJsonSchema =
    jsonSchemaRootFromXTypeDefinition && isJsonSchema(jsonSchemaRootFromXTypeDefinition)
      ? jsonSchemaRootFromXTypeDefinition
      : jsonSchema;
  const isVariableSelectable = parentVariableIsSelectable
    ? false
    : getIsVariableSelectable(finalJsonSchema.type, xTypeCatalogResource);

  // Handle object type
  if (finalJsonSchema.type === 'object' && finalJsonSchema.properties) {
    for (const [key, childJsonSchema] of Object.entries(finalJsonSchema.properties)) {
      variables.push(
        convertJsonSchemaToVariable({
          jsonSchema: childJsonSchema,
          origin,
          variableKey: key,
          parentVariableId: variableId,
          xTypeCatalogResources,
          parentVariableRequired: finalJsonSchema.required,
          parentVariableIsSelectable: isVariableSelectable ? true : undefined,
          parentVariablePath: variablePath,
          parentVariableDataSourceParams: datasourceParams,
        })
      );
    }
  }

  // Handle array type
  if (finalJsonSchema.type === 'array' && finalJsonSchema.items && isJsonSchema(finalJsonSchema.items)) {
    variables.push(
      convertJsonSchemaToVariable({
        jsonSchema: finalJsonSchema.items,
        origin,
        variableKey: '[0]',
        parentVariableId: variableId,
        variableLabelOverride: `${variableLabel} - Item`,
        xTypeCatalogResources,
        parentVariableRequired: finalJsonSchema.required,
        parentVariableIsSelectable: isVariableSelectable ? true : undefined,
        parentVariablePath: variablePath,
      })
    );
  }

  const variableType = getVariablePrimitiveTypes(finalJsonSchema);

  return {
    id: variableId,
    idOverride: getVariableIdOverride({ variables, xTypeCatalogResource }),
    label: variableLabel,
    type: {
      baseType: variableType,
      xType: variableXType,
      format: finalJsonSchema.format,
      labels: getVariableTypeLabels({
        type: variableType,
        format: finalJsonSchema.format,
        enum: finalJsonSchema.enum,
        xTypeCatalogResource,
      }),
    },
    origin,
    isVisible:
      parentVariableIsSelectable ??
      getIsVariableVisible({
        variableKey,
        xTypeCatalogResource,
        xEnabled: jsonSchema['x-enabled'],
        variableType,
        childVariables: variables,
        displayArrayType,
      }),
    values: getVariableValues({ jsonSchema: finalJsonSchema, xTypeDefinition, datasourceParams }),
    operators: xTypeDefinition?.XType?.Operators?.Allowed || [],
    required: parentVariableRequired?.includes(variableKey) || false,
    variables: variables.filter((variable) => variable.isVisible),
    isSelectable: isVariableSelectable,
    isDeprecated: isVariableDeprecated,
    path: variablePath,
  };
};
