import { useLayoutEffect, useRef } from 'react';

export type UseFocusableRefParams = {
  hasFocus: boolean;
};

export const useInputFocusableRef = ({ hasFocus }: UseFocusableRefParams) => {
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>('input');
      input?.focus();
    }
  }, [hasFocus]);

  return ref;
};
