import React from 'react';
import { Stack, styled } from '@mui/material';
import { Text } from '@verticeone/design-system';
import NewIntegrationCard from './NewIntegrationCard';
import { CardsList } from './CardList';
import { CardWrapper } from './CardWrapper';
import IntegrationCard from './IntegrationCard';
import type { IntegrationCategory, IntegrationType } from '../../utils';
import { useGetIntegrations } from '../../dataSource/useGetIntegrations';

type CustomIntegrationCategoryProps = {
  title: string;
  description: string;
  integrationType: IntegrationType;
  isVisible?: boolean;
};

const StyledCardWrapper = styled(CardWrapper)({
  minHeight: '160px',
});

const categoryMap: Record<IntegrationType, IntegrationCategory> = {
  Inbound: 'CUSTOM_INBOUND',
  Outbound: 'CUSTOM_OUTBOUND',
};

const CustomIntegrationCategory = ({
  integrationType,
  title,
  description,
  isVisible = true,
}: CustomIntegrationCategoryProps) => {
  const { data } = useGetIntegrations({ category: categoryMap[integrationType] });

  if (!isVisible) {
    return null;
  }

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Text variant="label" size="XS" color="text3">
          {title}
        </Text>
        <Text variant="body-regular" size="S" color="text4">
          {description}
        </Text>
      </Stack>
      <CardsList>
        {data?.map(({ integrationId, enabled, integrationName, secret: { type } }) => (
          <StyledCardWrapper key={integrationId}>
            <IntegrationCard id={integrationId} authType={type} enabled={enabled} name={integrationName} />
          </StyledCardWrapper>
        ))}
        <StyledCardWrapper>
          <NewIntegrationCard integrationType={integrationType} />
        </StyledCardWrapper>
      </CardsList>
    </Stack>
  );
};

export default CustomIntegrationCategory;
