import { useTranslation } from 'react-i18next';
import { useFormatDate } from '@verticeone/utils/formatting';
import { isAfter } from 'date-fns';

import type { TaskRow } from '../../../../dataSource';
import { useDurationFormatters } from '../../../../../hooks/useDurationFormatters';

type Args = Pick<TaskRow, 'status' | 'dueDate' | 'closedAt'>;
type Return = null | {
  label: string;
  testId: string;
  tooltip?: string;
  color?: 'warning' | 'success' | 'error' | 'info';
  textVariant: 'bold' | 'regular';
  isInfoTooltipDisplayed?: boolean;
};

export const useTaskDateLabel = ({ status, dueDate, closedAt }: Args): Return => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.WORKFLOW_TASK.COMPOSITE_STATUSES' });

  const formatDate = useFormatDate();
  const { formatDurationBetweenDates } = useDurationFormatters();

  if ((status === 'FAILED' || status === 'TERMINATED') && closedAt) {
    return {
      label: t('COMPLETED', { date: formatDate(closedAt) }),
      testId: 'completedOn',
      textVariant: 'bold',
    };
  }

  if (status === 'COMPLETED' && closedAt) {
    const isCompletedAfterDueDate = dueDate && isAfter(new Date(closedAt), new Date(dueDate));
    const tooltip = isCompletedAfterDueDate
      ? t('COMPLETED_LATE', {
          duration: formatDurationBetweenDates({ end: new Date(closedAt), start: new Date(dueDate) }),
        })
      : undefined;

    return {
      label: t('COMPLETED', { date: formatDate(closedAt) }),
      testId: 'completedOn',
      tooltip,
      color: isCompletedAfterDueDate ? 'error' : 'success',
      textVariant: 'bold',
    };
  }

  if (status === 'ACTIVE') {
    if (!dueDate) {
      return {
        label: t('NO_DUE_DATE'),
        testId: 'noDueDate',
        color: undefined,
        textVariant: 'regular',
        isInfoTooltipDisplayed: true,
      };
    }

    const dueAtDate = new Date(dueDate);
    const isDueDateAfterNow = isAfter(dueAtDate, new Date());

    const tooltip = isDueDateAfterNow
      ? t('DUE_IN', {
          duration: formatDurationBetweenDates({
            end: dueAtDate,
            start: new Date(),
          }),
        })
      : t('OVERDUE', {
          duration: formatDurationBetweenDates({
            end: new Date(),
            start: dueAtDate,
          }),
        });

    return {
      label: t('DUE', { date: formatDate(dueDate) }),
      testId: 'dueDate',
      tooltip,
      color: isDueDateAfterNow ? undefined : 'error',
      textVariant: 'bold',
    };
  }

  return null;
};
