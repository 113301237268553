import { Stack, styled } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { Text } from '@verticeone/design-system';

const StyledStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6),
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.core.color3}`,
  },
}));

type SectionWrapperProps = {
  title: string;
  description?: string;
  actionButtons?: ReactNode;
} & PropsWithChildren;

export const SectionWrapper: FC<SectionWrapperProps> = ({ title, description, actionButtons, children }) => {
  return (
    <StyledStack>
      <Stack direction="row" gap={4} justifyContent="space-between" alignItems="center">
        <Stack gap={2}>
          <Text variant="heading" size="S" color="text1">
            {title}
          </Text>
          {description && (
            <Text variant="body-regular" size="S" color="text2">
              {description}
            </Text>
          )}
        </Stack>
        {actionButtons}
      </Stack>
      {children}
    </StyledStack>
  );
};
