import { TagAssignment } from '@vertice/slices';
import { OWNER_VALUE } from '../../../tagging/constants';

export const CONTRACT_TAG_PREFIX = 'iam_asoc_contract';

export const isContractTagAssignment = (tagAssignment: TagAssignment) => {
  return tagAssignment.tagId !== undefined && tagAssignment.tagId.startsWith(CONTRACT_TAG_PREFIX);
};

export const isContractOwnerTagAssignment = (tagAssignment: TagAssignment) => {
  return isContractTagAssignment(tagAssignment) && tagAssignment.value === OWNER_VALUE;
};
