import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';

import { createTypedFormEntry } from '@vertice/core/src/modules/forms/fields/FormEntry';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import getPropsForSubmitButton from '@vertice/core/src/modules/forms/utils/getPropsForSubmitButton';
import { GoodFormProps, useGoodFormUtils } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { Button } from '@verticeone/design-system';
import { nonEmptyString, url } from '@verticeone/utils/validation';

const connectionFormDataSchema = z.object({
  workspaceUrl: url(t),
  apiToken: nonEmptyString,
});

export type ConnectionFormData = z.infer<typeof connectionFormDataSchema>;
const ConnectionFormEntry = createTypedFormEntry<ConnectionFormData>();

type ConnectionFormProps = GoodFormProps<ConnectionFormData> & {
  isEditMode: boolean;
  setIsEditMode: (newValue: boolean) => void;
};
export const ConnectionForm: FC<ConnectionFormProps> = ({ defaultValues, onSubmit, isEditMode, setIsEditMode }) => {
  const formMethods = useForm<ConnectionFormData>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(connectionFormDataSchema),
  });

  const goodFormMethods = useGoodFormUtils(formMethods);
  const submitButtonProps = getPropsForSubmitButton(formMethods.formState);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={goodFormMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={8}>
          <ConnectionFormEntry
            component={FormTextField}
            componentProps={{
              testId: 'workspace-url',
            }}
            name="workspaceUrl"
            label={t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.WORKSPACE_URL_LABEL')}
            description={t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.WORKSPACE_URL_DESC')}
            required
            width={8}
          />
          <ConnectionFormEntry
            component={FormTextField}
            componentProps={{
              testId: 'api-token',
            }}
            name="apiToken"
            label={t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.API_TOKEN_LABEL')}
            required
            width={8}
          />
          <Grid item xs={12} display="flex" gap={2}>
            {isEditMode && (
              <Button variant="outline" size="M" color="neutral" onClick={() => setIsEditMode(false)}>
                {t('INTEGRATIONS.JIRA.CONNECTION_FORM.DISMISS_BUTTON')}
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              variant="solid"
              isLoading={submitButtonProps.isLoading}
              testId="connect-integration-viso-trust"
            >
              {t('INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CONNECTION_FORM.SUBMIT_BUTTON')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
