import React, { useEffect } from 'react';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { CostModelLineItem } from './types';
import { LineItemsTable, LineItemsTableProps } from '../LineItemsTable';
import { useColumns } from './useColumns';
import { getCostModelLineItem, validateLineItem } from './utils';
import { useTranslation } from 'react-i18next';

export type CostModelLineItemsTableProps = Omit<LineItemsTableProps<CostModelLineItem>, 'columns' | 'getLineItem'> & {
  currency?: string;
  rollingFrequency: RollFrequency;
};

export const CostModelLineItemsTable = ({
  currency,
  rollingFrequency,
  ...otherProps
}: CostModelLineItemsTableProps) => {
  const { t } = useTranslation();
  const { columns, columnVisibilityModel } = useColumns({ currency, rollingFrequency });
  const { values, onValuesChange } = otherProps;

  useEffect(() => {
    const isFixedTermContract = !rollingFrequency || rollingFrequency === 'NO';
    if (!isFixedTermContract && values?.some((lineItem) => !!lineItem.allocationSpan)) {
      onValuesChange(values?.map((item) => ({ ...item, allocationSpan: undefined })));
    }
  }, [values, rollingFrequency, onValuesChange]);

  return (
    <LineItemsTable<CostModelLineItem>
      columns={columns}
      getLineItem={getCostModelLineItem}
      validateLineItem={validateLineItem(t)}
      {...otherProps}
      dataGridExtraProps={{
        ...otherProps.dataGridExtraProps,
        columnVisibilityModel: columnVisibilityModel,
      }}
    />
  );
};
