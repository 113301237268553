import { useId } from 'react';
import { Stack } from '@mui/material';
import { TextFieldCaption, Text } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import { useIsContractFieldVisible } from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import useFormatContractCurrency from '../../hooks/useFormatContractCurrency';
import LargeValue from '../LargeValue';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { getTotalSavings, getTotalSavingsPercentage } from '../../../savings/computed';
import useFormatSavingsPercentage from '../../../savings/useFormatSavingsPercentage';

const TotalSavingsTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_COST_SAVINGS.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_COST_SAVINGS.LINE_2')}
      </Text>
    </Stack>
  );
};

export const TotalSavingsContractFormEntry = () => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency();
  const formatSavingsPercentage = useFormatSavingsPercentage();
  const id = useId();

  const totalSavings = useContractFormDataSelector(getTotalSavings);
  const totalSavingsPct = useContractFormDataSelector(getTotalSavingsPercentage);

  const isTotalSavingsVisible = useIsContractFieldVisible('parts.overview.totalSavings');

  if (!isTotalSavingsVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS')}
          size="XS"
          tooltip={{ content: <TotalSavingsTooltipContent />, maxWidth: '580px' }}
        />
        <LargeValue
          id={id}
          primaryValue={formatContractCurrency(totalSavings)}
          primaryColor={totalSavings > 0 ? 'success' : 'text1'}
          primaryTestId="totalSavings"
          chipValue={totalSavingsPct > 0 ? formatSavingsPercentage(totalSavingsPct) : undefined}
          chipTestId="totalTotalSavingsPercentage"
        />
      </Stack>
    </FormControl>
  );
};
