import { useMemo } from 'react';
import { useGetCatalogResources } from '../../../hooks/useGetCatalogResources';
import { CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { isServiceCatalogResource } from '../../../catalogResource/utils';
import { isJsonSchema } from '../../../workflowSchema/WorkflowViewer/utils';

export const getServiceResourceInputProperties = (resource: CatalogResource) => {
  if (isServiceCatalogResource(resource)) {
    const jsonSchema = resource.definition?.Service?.ServiceProvider.Interface?.Input?.JsonSchema;
    return jsonSchema && isJsonSchema(jsonSchema) ? jsonSchema.properties : undefined;
  }
  return undefined;
};

export const useServiceResource = (serviceUrn?: string) => {
  const { resources, isLoading } = useGetCatalogResources({});

  return useMemo(
    () => ({
      resource: serviceUrn ? resources?.find(({ urn }) => urn === serviceUrn) : undefined,
      isLoading,
    }),
    [serviceUrn, resources, isLoading]
  );
};
