import { Text, ToggleSwitch } from '@verticeone/design-system';
import React from 'react';
import { useDashboardContext } from '../DashboardContext';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DemoModeToggle = () => {
  const { isDemo, isDemoVisible, toggleDemo } = useDashboardContext();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.DEMO' });

  const id = 'demo-mode-toggle';
  return (
    isDemo && (
      <Stack direction="row" alignItems="center" gap={2}>
        <label htmlFor={id} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
          <Text variant="button-bold" size="M" color="text1">
            {t('DEMO_MODE')}
          </Text>
        </label>
        <ToggleSwitch size="M" id={id} checked={isDemoVisible} color="secondary" onChange={toggleDemo} testId="" />
      </Stack>
    )
  );
};

export default DemoModeToggle;
