import { useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';

import { RichSelector } from '../../EditServiceTaskDrawer/JiraCreate/RichSelector';
import { VariableToken } from '../../EditServiceTaskDrawer/JiraCreate/VariableToken';
import type { Variable } from '../../types';
import { VariablesDialog } from '../../VariableSelector/VariablesDialog';
import { useWorkflowVariablesGroups } from './useWorkflowVariablesGroups';

type CaretPosition = {
  nodeIndex: number;
  anchorOffset?: number;
};

const DEFAULT_CARET_POSITION: CaretPosition = { nodeIndex: -1, anchorOffset: undefined };

const getCaretNodeIndex = (nodeList?: NodeListOf<ChildNode>, textContent?: string | null) => {
  return nodeList ? Array.from(nodeList).findIndex((node) => node.textContent === textContent) : -1;
};

type WorkflowRichSelectorProps = {
  udfVariables: Variable[];
  allRequestVariables: Variable[];
  selectedVariable?: Variable;
  richSelectorValue?: string;
  onRichSelectorValueChange?: (value: string) => void;
  typeFilter?: string;
  positionFromTheRight?: number;
  addVariableDefaults?: Pick<Variable, 'origin' | 'type'>;
  onAddLocalVariable?: (variable: Variable) => void;
  disabled?: boolean;
};

export const WorkflowRichSelector: FC<WorkflowRichSelectorProps> = ({
  udfVariables,
  allRequestVariables,
  selectedVariable,
  richSelectorValue = '',
  onRichSelectorValueChange,
  typeFilter,
  positionFromTheRight,
  addVariableDefaults,
  onAddLocalVariable,
  disabled = false,
}) => {
  const theme = useTheme();
  const richSelectorRef = useRef<HTMLDivElement>(null);
  const richSelectorChildNodes = richSelectorRef.current?.childNodes;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [caretPosition, setCaretPosition] = useState<CaretPosition>(DEFAULT_CARET_POSITION);

  const variablesGroups = useWorkflowVariablesGroups({
    udfVariables,
    allRequestVariables,
  });

  const handleOpenInNewClick = () => {
    const selectionTextContent = window.getSelection()?.anchorNode?.textContent;

    setCaretPosition({
      nodeIndex: getCaretNodeIndex(richSelectorChildNodes, selectionTextContent),
      anchorOffset: window.getSelection()?.anchorOffset,
    });
    setIsDialogOpen(true);
  };

  const handleVariableClick = (variable: Variable) => {
    if (caretPosition.nodeIndex > -1 && caretPosition.anchorOffset !== undefined) {
      // TODO: Find proper child text node, split it into two and insert the VariableToken in between
      const trimmedRichSelectorValue = richSelectorValue.replace(/<\/div>([^</div>]*)$/, '$1');

      onRichSelectorValueChange?.(
        `${trimmedRichSelectorValue}${renderToString(<VariableToken theme={theme} variable={variable} />)}</div>`
      );
    } else {
      const trimmedRichSelectorValue = richSelectorValue.replace(/<\/div>([^</div>]*)$/, '$1');

      onRichSelectorValueChange?.(
        `${trimmedRichSelectorValue}${renderToString(<VariableToken theme={theme} variable={variable} />)}</div>`
      );
    }
    setIsDialogOpen(false);
  };

  return (
    <>
      <RichSelector
        ref={richSelectorRef}
        value={richSelectorValue}
        onChange={onRichSelectorValueChange}
        onOpenInNewClick={handleOpenInNewClick}
        disabled={disabled}
      />
      <VariablesDialog
        selectedVariableId={selectedVariable?.id}
        variablesGroups={variablesGroups}
        typeFilter={typeFilter}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onVariableClick={handleVariableClick}
        positionFromTheRight={positionFromTheRight}
        addVariableDefaults={addVariableDefaults}
        onAddLocalVariable={onAddLocalVariable}
      />
    </>
  );
};
