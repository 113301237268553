import { PlotColumnOptions } from 'highcharts';

const parseValue = (field: string, type?: string) => {
  switch (type) {
    case 'double':
      return Number(field);
    case 'integer':
      return Number(field);
    case 'epochtime':
      return Number(field) * 1000;
    case 'timestamp':
      return Number(field);
    case 'datetime':
    case 'isodate':
      return new Date(field).getTime();
    case 'boolean':
      return Boolean(field);
    default:
      return field;
  }
};

export const getTableData = (
  tableData: {
    columns: string[];
    data: string[][];
    types: string[];
  },
  template?: { [key: string]: string }
) => {
  const { columns, data, types } = tableData;

  return data?.map((record: string[]) => {
    return record.reduce((acc, field, index) => {
      const fieldName = columns[index];
      const fieldType = types?.[index];
      const mappedTo = template ? template[fieldName] : fieldName;

      if (mappedTo) {
        return {
          ...acc,
          [mappedTo]: parseValue(field, fieldType),
        };
      }

      return acc;
    }, {});
  });
};

export const extractTooltipValues = (series: Highcharts.Series[], pointIndex: number): Record<string, number> => {
  return series.reduce((acc, item) => {
    const userOptions = item.userOptions;
    const id = (userOptions as PlotColumnOptions).linkedTo || userOptions.id;
    acc[id!] = (acc[id!] || 0) + (item.points[pointIndex].y ?? 0);
    return acc;
  }, {} as Record<string, number>);
};
