import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { useMemo } from 'react';
import { isVerticeWorkflowHandler, parseServiceHandlerRef } from '../../../../hooks/workflows/refUtils';
import { WorkflowVersionRow } from './types';
import { useTranslation } from 'react-i18next';
import { GridComparatorFn } from '@mui/x-data-grid-pro';
import { isServiceCatalogResource } from '../../catalogResource/utils';

export const useIsDefaultWorkflow = (workflowVersion?: WorkflowVersion, service?: CatalogResource) => {
  return useMemo(() => {
    if (workflowVersion !== undefined && isServiceCatalogResource(service)) {
      const parsedServiceHandler = parseServiceHandlerRef(service.definition.Service?.ServiceProvider?.HandlerUrn);
      if (isVerticeWorkflowHandler(parsedServiceHandler)) {
        return parsedServiceHandler.workflowId === workflowVersion.id;
      }
    }
    return false;
  }, [workflowVersion, service]);
};

export const isDeployedVersion = ({ status }: Pick<WorkflowVersion, 'status'>) =>
  status === 'ACTIVE' || status === 'ARCHIVED';

export const useLocalizeVersionName = () => {
  const { t } = useTranslation();

  return (versionName?: string) =>
    versionName ? versionName?.replace(/^v/, () => t('ENTITIES.WORKFLOW_VERSION.NAME') + ' ') : '';
};

export const compareWorkflowIssues: GridComparatorFn<WorkflowVersionRow['workflowDefinitionStatus']> = (s1, s2) => {
  const errors = (s1?.error || 0) - (s2?.error || 0);
  const warnings = (s1?.warning || 0) - (s2?.warning || 0);

  if (errors !== 0) {
    return errors;
  } else if (warnings !== 0) {
    return warnings;
  }
  return (s1?.info || 0) - (s2?.info || 0);
};
