import { Box, Collapse, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { AlertsRow } from '../shared/pricingBenchmark/AlertsRow';
import { Divider } from '@verticeone/design-system';
import { Loader } from '@verticeone/design-system';
import PricingBenchmarkGraph from '../shared/pricingBenchmark/PricingBenchmarkGraph';
import React from 'react';
import useGetBenchmarkData, { BenchmarkingPeriods } from '../shared/pricingBenchmark/useGetBenchmarkData';
import { useGetProductByIdQuery } from '@vertice/slices';

type PricingBenchmarkCardContentProps = {
  vendorId: string;
  productId: string;
  period: BenchmarkingPeriods;
  withNumerosity?: boolean;
};

export const PricingBenchmarkCardContent = ({
  vendorId,
  productId,
  period,
  withNumerosity,
}: PricingBenchmarkCardContentProps) => {
  const { palette } = useTheme();

  const { data: product } = useGetProductByIdQuery({ vendorId, productId });
  const { data, isLoading } = useGetBenchmarkData({
    vendorId,
    productId,
    period,
  });

  return (
    <>
      {
        <Collapse in={data && !isEmpty(data.accountPricingRangePerTier) && !!product}>
          <div>
            <Box p={2}>
              <AlertsRow
                accountPricingRangePerTier={data?.accountPricingRangePerTier!}
                product={product!}
                period={period}
              />
            </Box>
            <Divider />
          </div>
        </Collapse>
      }
      <Box py={6} bgcolor={palette.core.color1} minHeight={404} flex={1}>
        {isLoading ? (
          <Loader />
        ) : (
          <PricingBenchmarkGraph data={data!} withNumerosity={withNumerosity} period={period} />
        )}
      </Box>
    </>
  );
};
