import { nonEmptyString } from '@verticeone/utils/validation';
import * as z from 'zod';
import { variableMapperMapperFields } from '../../../VariableMapper/formSchema';

export const jiraSyncTaskFormSchema = z.object({
  id: z.string().optional(),
  name: nonEmptyString,
  description: z.string().optional(),
  taskId: z.string(),
  filterStatus: z.array(z.string()).optional(),
  ...variableMapperMapperFields,
});

export type JiraSyncTaskFormData = z.infer<typeof jiraSyncTaskFormSchema>;
