import { useLazySpListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useDataSource } from '../../DataSourceProvider';
import { BaseFilter, UseOfferingData } from '../types';

type SPFilter = Pick<BaseFilter, 'duration' | 'offeringType'>;

export const useSPListOfferingsQuery = (): UseOfferingData<SPFilter> => {
  const { accountId } = useAccountContext();
  const [getSpListOfferings, { isFetching }] = useLazySpListOfferingsQuery();
  const { datasource } = useDataSource();

  return async (filter: SPFilter) => {
    const response = await getSpListOfferings({
      accountId: accountId!,
      savingsPlanTypes: datasource,
      offeringTypes: filter.offeringType || ['All Upfront', 'Partial Upfront', 'No Upfront'],
      durations: filter.duration || [31536000, 94608000],
    });

    return {
      data: response.data,
      error: response.error,
      isFetching,
    };
  };
};

/*
  todo - we need to implement lazy query for tanstack/react-query
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import useLazyQuery from './useLazyQuery';
import { ListOfferingsQuery } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql/graphql';

type Offering = {
  id: string; // offeringId
  productTypes: string[];
  planType: string;
  description: string;
  paymentOption: string;
  durationSeconds: number;
  currency: string;
  serviceCode: string;
  usageType: string;
  operation: string;
  ec2InstanceFamily: string;
  ec2Region: string;
};

const UseSpPurchaseListQuery = graphql(`
  query ListOfferings(
    $accountId: String!
    $offeringTypes: [String!]!
    $savingsPlanTypes: [String!]!
    $durations: [Int!]!
  ) {
    spListOfferingsQuery(
      params: {
        accountId: $accountId
        filter: { offeringTypes: $offeringTypes, savingsPlanTypes: $savingsPlanTypes, durations: $durations }
      }
    ) {
      ... on SPOfferingsResult {
        __typename
        items {
          offeringId
          productTypes
          planType
          description
          paymentOption
          durationSeconds
          currency
          serviceCode
          usageType
          operation
          ec2InstanceFamily
          ec2Region
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
    }
  }
`);

export const useSPListOfferingsQuery = ({ offeringType, savingsPlanType, duration }: SPListOfferingsQueryParams) => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();

  return useLazyQuery({
    queryKey: ['SPListOfferingsQuery'],
    queryFn: () =>
      fetchCloudOptimization(UseSpPurchaseListQuery, {
        accountId,
        offeringTypes: [offeringType],
        savingsPlanTypes: [savingsPlanType],
        durations: [duration],
      }),
    select: (data: ListOfferingsQuery) => {
      const spListOfferingsData = data?.spListOfferingsQuery;
      if (spListOfferingsData?.__typename === 'SPOfferingsResult') {
        return spListOfferingsData.items as Offering[];
      }
      return null;
    },
  });
};
 */
