import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useFeatures } from '../../features/useFeatures';
import { FEATURES } from '../../features/constants';
import { useLocalizeVersionName } from '../workflow/WorkflowDetailPage/utils';

type WorkflowVersionProperties = Pick<WorkflowVersion, 'versionName' | 'versionTag'>;

export const getWorkflowVersionName = (
  row: WorkflowVersionProperties | undefined,
  isV2: boolean,
  localize: boolean,
  localizeVersionName: (versionName?: string | undefined) => string
) => {
  if (!row) return '';

  const v2VersionName = row.versionName || row.versionTag;
  const v1VersionName = row.versionTag || row.versionName;

  const versionName = isV2 ? v2VersionName : v1VersionName;

  return localize && !isV2 ? localizeVersionName(versionName) : versionName;
};

export const useGetWorkflowVersionName = (workflow: WorkflowVersionProperties | undefined, localize = true) => {
  const localizeVersionName = useLocalizeVersionName();
  const { getFeature } = useFeatures();
  const isWorkflowsPageV2Enabled = !!getFeature(FEATURES.INTELLIGENT_WORKFLOWS)?.properties?.workflowsPageV2;

  return getWorkflowVersionName(workflow, isWorkflowsPageV2Enabled, localize, localizeVersionName);
};
