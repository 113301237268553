import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { CustomSection } from './widget';
import React from 'react';

export const CustomIntegrations = () => {
  const { isEnabled } = useFeatures();
  const isGenericIntegrationEnabled = isEnabled(FEATURES.INTEGRATION_GENERIC_OUTBOUND);

  return isGenericIntegrationEnabled && <CustomSection />;
};
