import { useExchangeCurrency } from '@vertice/core/src/modules/currency/useExchangeCurrency';
import { useWatch } from 'react-hook-form';
import { Money } from '../../shared/schemas';
import { useTaskContext } from '../../../../TaskContext';
import { FormData } from '../schema';
import { useMemo } from 'react';
import { isValidMoney } from '../../types';
import { nearEqual } from '@verticeone/utils/numbers';

type ReturnType =
  | undefined
  | {
      eligibleForVerticeNegotiation?: boolean;
      threshold?: Money;
    };

export const usePurchaseVerticeNegotiationEligibility = (): ReturnType => {
  const { exchangeCurrency, isLoading: isLoadingExchangeRates } = useExchangeCurrency();
  const { taskOverview } = useTaskContext();
  const approvedBudget = useWatch<FormData, 'approvedBudget'>({ name: 'approvedBudget' });
  const budgetCurrency = useWatch<FormData, 'contractCurrency'>({ name: 'contractCurrency' });

  return useMemo(() => {
    const verticeNegotiationThreshold = taskOverview?.task?.input?.verticeNegotiationThreshold;

    if (
      !isValidMoney(verticeNegotiationThreshold) ||
      typeof approvedBudget !== 'number' ||
      !budgetCurrency ||
      isLoadingExchangeRates
    ) {
      return undefined;
    }

    const convertedBudget = exchangeCurrency({
      sourceCurrency: budgetCurrency,
      targetCurrency: verticeNegotiationThreshold.currency,
      value: approvedBudget,
    });

    if (typeof convertedBudget !== 'number') {
      return undefined;
    }

    return {
      eligibleForVerticeNegotiation:
        convertedBudget >= verticeNegotiationThreshold.value ||
        nearEqual(convertedBudget, verticeNegotiationThreshold.value, 0.001),
      threshold: verticeNegotiationThreshold,
    };
  }, [exchangeCurrency, taskOverview?.task?.input, isLoadingExchangeRates, approvedBudget, budgetCurrency]);
};
