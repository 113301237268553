import React from 'react';
import { useTranslation } from 'react-i18next';
import AverageRequestTimesGraph from './AverageRequestTimesGraph';
import { ChartCard } from '../ChartCard';
import useAverageRequestTimesData, { AverageRequestTimesData } from './useAverageRequestTimesData';
import { Placeholder } from '@verticeone/design-system';
import { getTooltipValue } from '../ChartCard/utils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { AverageRequestTimesProvider, useAverageRequestTimesContext } from './AverageRequestTimesContext';
import { AverageRequestTimesStats } from './AverageRequestTimesStats';
import { MONTHS } from './constants';
import { useGetColoredItems } from '@vertice/core/src/components/charts/highcharts-specific/utils/seriesUtils';
import { EmptyState, StatItemType } from '../../types';
import { sum } from 'lodash';
import dayjs from 'dayjs';
import EmptyGraphWrapper from './EmptyGraphWrapper';

type AverageRequestTimesCardProps = EmptyState & {
  data: AverageRequestTimesData['data'];
};

const AverageRequestTimesCard = ({ data, isEmpty, isFilteredEmpty }: AverageRequestTimesCardProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { tooltipData } = useAverageRequestTimesContext();
  const getColoredItems = useGetColoredItems();
  const total = getTooltipValue(tooltipData?.total, data.stats.total);
  const averageDurationInHours = getTooltipValue(
    tooltipData?.averageDurationInHours,
    data.stats.averageDurationInHours
  );
  const series = (getColoredItems(data.values) || []).filter((item) => item?.isVisible);
  const isNoDataAvailable = isEmpty || isFilteredEmpty;

  const statItems: StatItemType[] = isNoDataAvailable
    ? []
    : series.map((item) => {
        const value = tooltipData?.values[item.name] !== undefined ? tooltipData?.values[item.name] : sum(item.data);
        return {
          id: item.id,
          name: item.name,
          value,
          color: item.color,
        };
      });

  return (
    <ChartCard
      variant="horizontal"
      title={t('AVERAGE_REQUEST_TIMES.TITLE')}
      subtitle={
        tooltipData?.category ? dayjs(tooltipData?.category).format('MMM, YYYY') : t('LAST_X_MONTHS', { count: MONTHS })
      }
      stats={
        <AverageRequestTimesStats total={total} averageDurationInHours={averageDurationInHours} items={statItems} />
      }
      testId="average-request-times-card"
    >
      <EmptyGraphWrapper isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty}>
        <AverageRequestTimesGraph
          series={series as SeriesOptionsWithData[]}
          categories={data.categories}
          filterOptions={data.filterOptions}
          enhancedData={data.enhancedData}
          isEmpty={isNoDataAvailable}
        />
      </EmptyGraphWrapper>
    </ChartCard>
  );
};

const LoadableAverageRequestTimesCard = () => {
  const { data, isFetching, isEmpty, isFilteredEmpty } = useAverageRequestTimesData();

  if (isFetching) {
    return (
      <ChartCard.Skeleton
        variant="horizontal"
        stats={<AverageRequestTimesStats.Skeleton />}
        testId="average-request-times-skeleton"
      >
        <Placeholder variant="rounded" width="100%" height="436px" />
      </ChartCard.Skeleton>
    );
  }

  return <AverageRequestTimesCard data={data} isEmpty={isEmpty} isFilteredEmpty={isFilteredEmpty} />;
};

const AverageRequestTimesCardWithContext = () => (
  <AverageRequestTimesProvider
    defaultFilter={{
      serviceName: 'All',
    }}
  >
    <LoadableAverageRequestTimesCard />
  </AverageRequestTimesProvider>
);

export default AverageRequestTimesCardWithContext;
