import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import TagOutlinedIcon from '@mui/icons-material/TagOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { SingleValue } from 'react-select';
import { NotificationSettingsItem } from '@vertice/slices';
import { IconWrapper, Select, SimpleOption, Text, TextFieldCaption } from '@verticeone/design-system';
import { SaveSettingsData, SaveSettingsDataSections } from './ChannelSetupWrapper';

export type ChannelSettingsFormData = {
  channelId: string;
  timeThreshold?: string;
};

export type ChannelSetupProps = {
  channelSettings: NotificationSettingsItem;
  channels: NotificationSettingsItem[];
  onSave: (formData: SaveSettingsData) => void;
  savingSection?: SaveSettingsDataSections;
  loadingChannels: boolean;

  dataSection: SaveSettingsDataSections;
  hasTimeThreshold: boolean;
  channelLabel: string;
  channelTooltip: string;
  slackPreviewComponent: ReactElement;
};

export const ChannelSetup: FC<ChannelSetupProps> = ({
  channelSettings,
  onSave,
  savingSection,
  channels,
  loadingChannels,
  dataSection,
  hasTimeThreshold,
  channelLabel,
  channelTooltip,
  slackPreviewComponent,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<NotificationSettingsItem>(channelSettings);

  useEffect(() => {
    setFormData(channelSettings);
  }, [channelSettings]);

  const timeThresholdOptions = useMemo(() => {
    return [3, 4, 5].map((months) => ({
      label: t(`INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_${months}_MONTHS`),
      value: `P${months}M`,
    }));
  }, [t]);

  const handleChannelChange = (newValue: SingleValue<NotificationSettingsItem>) => {
    setFormData((current) => ({
      ...current,
      channelId: newValue ? newValue.channelId : '',
    }));

    const newData: ChannelSettingsFormData = {
      channelId: newValue ? newValue.channelId : '',
    };

    if (hasTimeThreshold) {
      newData.timeThreshold = formData.timeThreshold;
    }

    onSave({
      [dataSection]: newData,
    });
  };

  const textFieldLabelId = `${dataSection}Label`;
  const thresholdSelectId = `${dataSection}Threshold`;

  return (
    <Stack direction="row" gap={6} p={6}>
      <Stack flex={1} gap={8}>
        <Stack gap={2}>
          <TextFieldCaption
            id={textFieldLabelId}
            label={channelLabel}
            size="S"
            tooltip={{
              content: channelTooltip,
            }}
          />
          <Select<NotificationSettingsItem, false>
            value={channels.find((channel) => channel.channelId === formData?.channelId)}
            options={channels}
            isMulti={false}
            isClearable={true}
            variant="outlined"
            isLoading={savingSection === dataSection || loadingChannels}
            isDisabled={!!savingSection}
            size="M"
            getOptionLabel={(option) => option.channelName || option.channelId}
            getOptionValue={(option) => option.channelId}
            onChange={handleChannelChange}
            aria-labelledby={textFieldLabelId}
            menuPosition="fixed"
            formatOptionLabel={(option) => {
              return (
                <Stack gap={1} direction="row" alignItems="center">
                  <IconWrapper icon={option.type === 'PUBLIC' ? TagOutlinedIcon : LockOutlinedIcon} size="M" />
                  {option.channelName}
                </Stack>
              );
            }}
          />
          {['ERROR', 'CHANNEL_NOT_FOUND'].includes(formData?.saveStatus ?? '') && (
            <Text variant="body-regular" size="XS" color="error">
              {t(`INTEGRATIONS.SLACK.INTEGRATION_SETUP.ERROR_${formData?.saveStatus}`)}
            </Text>
          )}
        </Stack>
        {hasTimeThreshold && (
          <Stack gap={2}>
            <TextFieldCaption
              htmlFor={thresholdSelectId}
              label={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_THRESHOLD_TITLE')}
              size="S"
            />
            <Select<SimpleOption, false>
              variant="outlined"
              size="M"
              id={thresholdSelectId}
              options={timeThresholdOptions}
              value={timeThresholdOptions?.find(({ value }) => value === formData.timeThreshold)}
              isClearable={false}
              isMulti={false}
              onChange={(option: SingleValue<SimpleOption>) =>
                setFormData((prevSettings) => ({
                  ...prevSettings,
                  timeThreshold: option?.value,
                }))
              }
              isDisabled
            />
            <Text variant="body-regular" size="XS" color="text2">
              {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.CHANNELS.TIME_THRESHOLD_HELPER_TEXT')}
            </Text>
          </Stack>
        )}
      </Stack>
      <Stack flex={1} gap={2}>
        <Text variant="caption" size="S" color="text2">
          {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_PREVIEW_TITLE')}
        </Text>
        {slackPreviewComponent}
      </Stack>
    </Stack>
  );
};
