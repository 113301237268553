import { useController, FieldValues } from 'react-hook-form';
import { CommonFormFieldProps } from '../../types';
import { CostModelLineItemsTable } from '../../../saas/contract/components/CostModelLineItemsTable/CostModelLineItemsTable';
import { CostModelLineItem } from '../../../saas/contract/components/CostModelLineItemsTable/types';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type FormCostModelLineItemsFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  currency?: string;
  editable?: boolean;
  rollingFrequency: RollFrequency;
};

export type FormCostModelLineItemsFieldComponentType<FormDataType extends FieldValues> = (
  props: FormCostModelLineItemsFieldProps<FormDataType>
) => JSX.Element;

export const FormCostModelLineItemsField = <FormDataType extends FieldValues = never>({
  name,
  required,
  editable,
  currency,
  rollingFrequency,
}: FormCostModelLineItemsFieldProps<FormDataType>) => {
  const {
    field: { ref, value, ...field },
  } = useController<FormDataType>({
    name,
    rules: {
      required,
    },
  });

  return (
    <CostModelLineItemsTable
      editMode={!!editable}
      values={(value as CostModelLineItem[]) || []}
      onValuesChange={(newValue) => {
        field.onChange(newValue);
      }}
      currency={currency}
      rollingFrequency={rollingFrequency}
    />
  );
};
