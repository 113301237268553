import { Add } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material';
import { Button, IconWrapper, Text } from '@verticeone/design-system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TriggerType } from './types';

const StyledText = styled(Text)(({ theme }) => ({
  textTransform: 'uppercase',
  gap: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
}));

const StyledButtons = styled('div')(({ theme }) => ({
  display: 'grid',
  flexGrow: 1,
  gridTemplateColumns: '1fr 1fr',
  columnGap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const VisualWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: 46,
  borderRadius: 12,
  border: `1px dashed ${theme.palette.core.color3}`,
  '&:hover .text': {
    display: 'none',
  },
  '& .buttons': {
    display: 'none',
  },
  '&:hover .buttons': {
    display: 'grid',
  },
}));

type AddTriggerButtonProps = {
  onClick: (triggerType: TriggerType) => void;
};

export const AddTrigger: FC<AddTriggerButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <VisualWrapper>
      <StyledText variant="label" size={'XXS'} className="text">
        <IconWrapper icon={Add} htmlColor={palette.neutral.color2} sx={{ opacity: 0.4 }} />
        {t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.ADD_TRIGGER_BUTTON')}
      </StyledText>
      <StyledButtons className="buttons">
        <Button variant="ghost" size="S" color="neutral" onClick={() => onClick('ISSUE_CREATED')}>
          {t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.ISSUE_CREATED_TRIGGER')}
        </Button>
        <Button variant="ghost" size="S" color="neutral" onClick={() => onClick('STATUS_CHANGED')}>
          {t('INTEGRATIONS.JIRA.WORKFLOW_TRIGGER_CARD.STATUS_CHANGED_TRIGGER')}
        </Button>
      </StyledButtons>
    </VisualWrapper>
  );
};
