import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, styled, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';

import { Vendor } from '@vertice/slices';
import { Button, Divider, SimpleOption, Text } from '@verticeone/design-system';
import VendorLogo from '../../../../../../../../../components/VendorLogo';
import { createTypedFormEntry } from '../../../../../../../../forms/fields/FormEntry';
import FormSelectField2 from '../../../../../../../../forms/fields/FormSelectField2';
import FormTextField from '../../../../../../../../forms/fields/FormTextField';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../../../constants';
import { ReviewRelationshipFormData, reviewRelationshipSchema } from './schema';

const REVIEW_RELATIONSHIP_TAGS = ['SCOPS', 'SCGE', 'SCHF'];
const TAGS_OPTIONS = REVIEW_RELATIONSHIP_TAGS.map((option) => ({ label: option, value: option }));

const VendorSpace = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.input.color1,
  borderRadius: 12,
  gap: theme.spacing(2),
  flexDirection: 'row',
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  alignItems: 'center',
}));

const ReviewRelationshipFormEntry = createTypedFormEntry<ReviewRelationshipFormData>();

type ReviewRelationshipFormStepProps = {
  defaultValues?: DeepPartial<ReviewRelationshipFormData>;
  onSubmit: (data: ReviewRelationshipFormData) => void;
  onChange: (data: DeepPartial<ReviewRelationshipFormData>) => void;
  vendor: Vendor;
};

export const ReviewRelationshipFormStep: FC<ReviewRelationshipFormStepProps> = ({
  defaultValues,
  onSubmit,
  onChange,
  vendor,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const formMethods = useForm<ReviewRelationshipFormData>({
    resolver: zodResolver(reviewRelationshipSchema),
    defaultValues,
  });

  const { handleSubmit, formState, watch } = formMethods;

  useEffect(() => {
    const { unsubscribe } = watch((formValues) => {
      onChange(formValues);
    });
    return () => unsubscribe();
  }, [watch, onChange]);

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flex="1 1 0" onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ p: 6, overflow: 'auto', flexGrow: 1, gap: 12 }}>
          <Text variant="heading" size="XS" color="text2">
            {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.TITLE')}
          </Text>
          <Stack flexGrow={1} gap={8}>
            <VendorSpace>
              {vendor.id && <VendorLogo vendorId={vendor.id} size={40} bg={palette.background.default} />}
              <Text variant="button-bold" size="S" color="color1">
                {vendor.name}
              </Text>
            </VendorSpace>
            <ReviewRelationshipFormEntry
              name="vendorWebsite"
              required
              component={FormTextField}
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.VENDOR_WEBSITE_URL'
              )}
              componentProps={{
                placeholder: t(
                  'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.VENDOR_WEBSITE_PLACEHOLDER'
                ),
              }}
            />
            <ReviewRelationshipFormEntry
              name="businessOwner.email"
              required
              component={FormTextField}
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_OWNER.EMAIL'
              )}
              description={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_OWNER.EMAIL_DESCRIPTION'
              )}
              componentProps={{
                placeholder: t(
                  'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_OWNER.EMAIL_PLACEHOLDER'
                ),
              }}
            />
            <ReviewRelationshipFormEntry
              name="businessOwner.firstName"
              required
              component={FormTextField}
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_OWNER.FIRST_NAME'
              )}
            />
            <ReviewRelationshipFormEntry
              name="businessOwner.lastName"
              required
              component={FormTextField}
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_OWNER.LAST_NAME'
              )}
            />

            <ReviewRelationshipFormEntry
              name="businessPurpose"
              label={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_PURPOSE'
              )}
              description={t(
                'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_PURPOSE_DESCRIPTION'
              )}
              component={FormTextField}
              componentProps={{
                placeholder: t(
                  'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.BUSINESS_PURPOSE_PLACEHOLDER'
                ),
              }}
            />
            <ReviewRelationshipFormEntry
              name="tags"
              label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.TAGS')}
              component={FormSelectField2}
              componentProps={{
                options: TAGS_OPTIONS,
                isMulti: true,
                boxValue: (values: string[]) => {
                  return values ? values.map((value) => ({ label: value, value })) : null;
                },
                unboxValue: (selectValues: MultiValue<SimpleOption>) => {
                  return selectValues?.map((value) => value.value);
                },
                placeholder: t(
                  'INTELLIGENT_WORKFLOWS.TASK_FORMS.VISO_TRUST.ASSESSMENT_CONTEXT.REVIEW_RELATIONSHIP.TAGS_PLACEHOLDER'
                ),
              }}
            />
          </Stack>
        </Stack>

        <Divider />
        <Stack direction="row-reverse" padding={6}>
          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
