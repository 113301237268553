import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import React, { ChangeEvent } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextField } from '@verticeone/design-system';
import { useInputFocusableRef } from '../../../../../../hooks/useInputFocusableRef';

const EditableLicensesCell = ({ id, value, field, hasFocus }: GridCellParams) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const ref = useInputFocusableRef({ hasFocus });

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const digitsOnly = /^[0-9]*$/.test(newValue);
    if (digitsOnly) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      apiRef.current.setEditCellValue({ id, field, value: newValue ? parseInt(newValue, 10) : null });
    }
  };

  return (
    <Box px={4}>
      <TextField
        ref={ref}
        variant="ghost"
        hiddenLabel
        value={value ?? ''}
        onChange={handleValueChange}
        placeholder={t('ENTITIES.PRODUCT_LIST.LICENSES')}
        InputProps={{
          disableUnderline: true,
        }}
      />
    </Box>
  );
};

export default EditableLicensesCell;
