import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { VendorInfoCard } from '../cards/VendorInfoCard';
import { VendorPricingCard } from '../cards/VendorPricingCard';
import ProductsPricingCard from '../cards/ProductsPricingCard';
import VendorDiligenceInsights from '@vertice/core/src/modules/vendor/VendorDiligenceInsights/VendorDiligenceInsights';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetVendorByIdQuery } from '@vertice/slices';
import { isNil } from 'lodash';

type AboutVendorSectionProps = {
  vendorId: string;
};

const AboutVendorSection = ({ vendorId }: AboutVendorSectionProps) => {
  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });
  const showPricingCard =
    !isNil(vendor?.simplicityRating) && !isNil(vendor?.transparencyRating) && !isNil(vendor?.parityRating);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
      <Box minWidth={0} flexGrow={1}>
        <VendorInfoCard vendorId={vendorId} />
      </Box>
      {showPricingCard && (
        <Box flexBasis={{ xs: 1, md: 370 }}>
          <VendorPricingCard
            simplicity={vendor.simplicityRating}
            transparency={vendor.transparencyRating}
            parity={vendor.parityRating}
          />
        </Box>
      )}
    </Stack>
  );
};

const OverviewTab = () => {
  const { id: vendorId } = useParams();
  const { accountId } = useAccountContext();

  if (!vendorId) {
    return null;
  }
  return (
    <Stack gap={4} p={6} pt={8}>
      <ProductsPricingCard vendorId={vendorId} />
      <AboutVendorSection vendorId={vendorId} />
      <VendorDiligenceInsights accountId={accountId} vendorId={vendorId} />
    </Stack>
  );
};

export default OverviewTab;
