import { match } from 'ts-pattern';
import { ContractReviewForm, TaskContractReviewOutput, TaskContractReviewOutputOffer } from '../types';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractDatesForm } from '../formSteps/ContractDatesFormStep';
import { getItemsFromFormData } from '../../../../../saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';

/**
 * Transforms form values from a contract review form into the output interface of Task Form.
 *
 * @param formValues - The input contract review form values.
 * @returns The transformed output interface.
 */
export const transformToOutput = (id: string, formValues: ContractReviewForm): TaskContractReviewOutput => {
  return {
    contract: {
      department: formValues.contractDetails.departmentId,
      finalOffer: getOfferFromValues(id, formValues),
      signingDate: formValues.contractDates.signDate ?? undefined,
    },
    approved: true,
  };
};

const getOfferFromValues = (id: string, formValues: ContractReviewForm): TaskContractReviewOutputOffer => {
  const { contractDates, contractDetails, lineItems } = formValues;

  return {
    id: id,
    vendor: contractDetails.vendor,
    rollingFrequency: getRollingFrequency(contractDates),
    startDate: contractDates.startDate,
    endDate: contractDates.contractType === 'FIXED' ? contractDates.renewalDate : undefined,
    billingFrequency: contractDates.contractType !== 'ONE_OFF' ? contractDates.billingFrequency : undefined,
    autoRenewalDeadline:
      contractDates.contractType === 'FIXED' ? contractDates.autoRenewalDate ?? undefined : undefined,
    paymentTerms: lineItems.paymentTerms,
    baseCurrency: lineItems.currency,
    lineItems: getItemsFromFormData({
      lineItems: lineItems.lineItems,
      annualCostOverride: lineItems.annualCostOverride,
      totalCostOverride: lineItems.totalCostOverride,
    }),
    annualCost: lineItems.annualCostOverride
      ? { value: lineItems.annualCostOverride.amount, currency: lineItems.currency }
      : undefined,
    totalCost: lineItems.totalCostOverride
      ? { value: lineItems.totalCostOverride.amount, currency: lineItems.currency }
      : undefined,
  };
};

export const getRollingFrequency = (contractDates: Partial<ContractDatesForm>): RollFrequency => {
  return match(contractDates)
    .returnType<RollFrequency>()
    .with({ contractType: 'FIXED' }, () => 'NO')
    .with({ contractType: 'ONE_OFF' }, () => 'ONE_OFF')
    .with({ contractType: 'ROLLING' }, ({ rollingFrequency }) => rollingFrequency ?? 'MONTHLY')
    .exhaustive();
};
