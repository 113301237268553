import { useMemo } from 'react';
import { LoadableAdvanced } from '@verticeone/utils/async';
import {
  useAnalyticsTagsServicesQuery,
  ViewResult,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useDeferredQuery } from '@verticeone/utils/api';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { prepareTableData, prepareStatsData } from '../../BaseCloudTab/Table/utils';
import { CloudAnalyticsData } from '../../BaseCloudTab/Table/types';
import { useCloudAnalytics } from '../../../CloudAnalyticsContext';

export type TableRow = {
  productCode: string;
  productName: string;
  cost: number;
  previousCost: number;
  difference: number | null;
  charges: number;
  credit: number;
  tax: number;
  previousCharges: number;
  previousCredit: number;
  previousTax: number;
};

const useTagsServicesData = (): LoadableAdvanced<CloudAnalyticsData<TableRow>> => {
  const { accountId } = useAccountContext();
  const { period, filter, chartDataLimit } = useCloudAnalytics();

  const tagName = filter.tagName as string;
  const tagValues = ((filter.tagValues as []) || []).join(',');
  const skip = !accountId || !tagName;

  const {
    data: rawData,
    error,
    isLoading,
  } = useDeferredQuery(
    useAnalyticsTagsServicesQuery,
    {
      accountId,
      ...period,
      tagName,
      tagValues,
      limit: chartDataLimit,
    },
    { skip, pollingInterval: 5000 },
    ({ athenaViewQuery }) =>
      athenaViewQuery?.__typename === 'DeferredQueryResult' ? undefined : { result: athenaViewQuery }
  );

  const computedTableData = useMemo(
    () =>
      prepareTableData<TableRow>(
        {
          product_code: 'productCode',
          product_name: 'productName',
          cost_period_one: 'cost',
          cost_period_two: 'previousCost',
          cost_change: 'difference',
          charges_period_one: 'charges',
          credit_period_one: 'credit',
          tax_period_one: 'tax',
          charges_period_two: 'previousCharges',
          credit_period_two: 'previousCredit',
          tax_period_two: 'previousTax',
        },
        rawData as ViewResult
      )?.map((data) => ({ ...data, productName: data.productName || data.productCode })),
    [rawData]
  );

  const computedStats = useMemo(() => prepareStatsData<TableRow>(computedTableData), [computedTableData]);

  return {
    error,
    isEmpty: Boolean(computedTableData && computedTableData?.length === 0),
    isLoading: isLoading && !skip,
    data: {
      tableData: computedTableData,
      stats: computedStats,
    },
  };
};

export default useTagsServicesData;
