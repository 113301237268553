import React from 'react';
import { FieldPath, FieldValues, useFormState } from 'react-hook-form';
import { UseFormRegister, Control } from 'react-hook-form/dist/types/form';
import { get, has } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextField, TextFieldCaption } from '@verticeone/design-system';
import FormControl from './FormControl';

type IntegrationNameFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  disabled?: boolean;
};

const IntegrationNameField = <T extends FieldValues>({
  name,
  register,
  control,
  disabled = false,
}: IntegrationNameFieldProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM.INTEGRATION_NAME' });
  const { errors, touchedFields } = useFormState({ control, name });
  const isTouched = get(touchedFields, name, false);
  const hasError = has(errors, name);

  return (
    <FormControl>
      <TextFieldCaption label={t('TITLE')} htmlFor="integration-name" size="XS" required />
      <TextField
        size="M"
        id="integration-name"
        error={isTouched && hasError}
        placeholder={t('PLACEHOLDER')}
        disabled={disabled}
        {...register(name)}
      />
    </FormControl>
  );
};

export default IntegrationNameField;
