import { FC } from 'react';
import { DateFieldType } from './types';
import { Stack } from '@mui/material';
import FormDateField, { FormDateFieldType } from '../../../fields/FormDateField';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues } from 'react-hook-form';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { DynamicFormPresenterProps } from '../types';

export const DateFieldPresenter: FC<DynamicFormPresenterProps<DateFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormDateFieldType<FieldValues>>
        name={field.name}
        component={FormDateField}
        componentProps={{ color: INTELLIGENT_WORKFLOWS_BRAND_COLOR }}
        description={description}
        label={label}
        required={required}
        disabled={config.mode === 'readOnly' || config.mode === 'preview'}
      />
    </Stack>
  );
};
