import useDashboardWidgetData from '../../../useDashboardWidgetData';

type TasksListStatsData = {
  totalNumberOfOverdueTasks: number;
  totalNumberOfTasks: number;
};

const FALLBACK_VALUE = 0;

const useTasksListStatsData = () => {
  const { data, error, isFetching } = useDashboardWidgetData('TasksList');
  const stats = data?.stats as TasksListStatsData;

  return {
    data: {
      totalNumberOfOverdueTasks: stats?.totalNumberOfOverdueTasks || FALLBACK_VALUE,
      totalNumberOfTasks: stats?.totalNumberOfTasks || FALLBACK_VALUE,
    },
    error,
    isFetching,
  };
};

export default useTasksListStatsData;
