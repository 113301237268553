import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { useTranslation } from 'react-i18next';
import { TaskFormEntry } from './TaskFormEntry';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTaskFormContext } from '../TaskFormContext';

type AdditionalNotesFormEntryProps<FormDataType extends FieldValues> = {
  name?: FieldPath<FormDataType>;
};

export const AdditionalNotesFormEntry = <FormDataType extends FieldValues>({
  name,
}: AdditionalNotesFormEntryProps<FormDataType>) => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();

  return (
    <TaskFormEntry<any, typeof FormTextField>
      name={name ? name : 'additionalNotes'}
      label={t('INTELLIGENT_WORKFLOWS.TASK_FORMS.COMMON.LABELS.ADDITIONAL_NOTES')}
      component={FormTextField}
      width={12}
      componentProps={{
        multiline: true,
        rows: 4,
        color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        placeholder: !readOnly ? t('INTELLIGENT_WORKFLOWS.TASK_FORMS.COMMON.PLACEHOLDERS.ADDITIONAL_NOTES') : undefined,
      }}
    />
  );
};
