import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { VerticeGoogleIntegration } from '@vertice/assets';
import { Button } from '@verticeone/design-system';
import { STEPS, StepProps } from './common';
import { BulletList } from '../../../../../components/WizardSteps';
import WizardStepTemplate from '../../../../../components/WizardStepTemplate';

export const StartStep = ({ setStep }: StepProps) => {
  const { t } = useTranslation();

  const header = (
    <Stack gap="8px">
      <Stack direction="row" gap="6px">
        <Text variant="heading" size="M">
          {t('INTEGRATIONS.GOOGLE.USAGE.SETUP_GOOGLE')}
        </Text>
        <Text variant="heading" size="M" color="text1" sx={{ opacity: '0.4' }}>
          {t('INTEGRATIONS.GOOGLE.USAGE.FOR_USAGE_STATS')}
        </Text>
      </Stack>
      <Text variant="body-regular" size="M" color="text2">
        {t('INTEGRATIONS.GOOGLE.USAGE.INTRO')}
      </Text>
    </Stack>
  );

  const content = (
    <Stack gap="8px">
      <Text variant="caption" size="XS" color="text2">
        {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITES')}
      </Text>
      <BulletList>
        <li>
          <Text variant="body-regular" size="M">
            {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITE_1')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITE_2')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITE_3')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITE_4')}
          </Text>
        </li>
        <li>
          <Text variant="body-regular" size="M">
            {t('INTEGRATIONS.GOOGLE.USAGE.PREREQUISITE_5')}
          </Text>
        </li>
      </BulletList>
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeGoogleIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="solid" color="primary" size="S" isCaption onClick={() => setStep(STEPS.SELECT_DATA_SOURCE)}>
          {t('COMMON.NEXT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
