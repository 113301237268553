import React, { useCallback, useEffect, useMemo } from 'react';
import type { TaskDefinition, GatewayDefinition, FlowEdgeDefinition, EndDefinition } from '../../definitionsTypes';
import {
  isEndDefinition,
  isGatewayDefinition,
  isTaskDefinition,
  updateEdgesInProcessDefinition,
  updateNodeInProcessDefinition,
} from '../../definitions/processDefinition';
import { EditUserTaskDrawer } from './EditNodeDrawers/EditUserTaskDrawer/EditUserTaskDrawer';
import { EditGatewayDrawer } from './EditNodeDrawers/EditGatewayDrawer/EditGatewayDrawer';
import { EditEndDrawer } from './EditNodeDrawers/EditEndDrawer/EditEndDrawer';
import { EditServiceTaskDrawer } from './EditNodeDrawers/EditServiceTaskDrawer/EditServiceTaskDrawer';
import { EditEdgeDrawer } from './EditNodeDrawers/EditEdgeDrawer/EditEdgeDrawer';
import { useWorkflowValidationContext, ValidationDrawer } from './Validation';
import { WorkflowRendererContextProvider } from '../WorkflowRenderer/WorkflowRendererContext';
import { workflowDefinitionsToModel } from '../model/workflowDefinitionsToModel';
import { useServicesThumbnails } from '../WorkflowViewer/useServicesThumbnails';
import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '../../../account/AccountContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { WorkflowRenderer } from '../WorkflowRenderer/WorkflowRenderer';
import { useWorkflowEditing } from './useWorkflowEditing';
import { WorkflowState } from '../model/types';
import { withWorkflowRenderer } from '../WorkflowRenderer/useWorkflowRendererState';
import { DragAndDropExtension } from './DragAndDropExtension/DragAndDropExtension';
import { removeEdgeFromWorkflowDefinitions, removeNodeFromWorkflowDefinitions } from './utils';
import { WorkflowDefinitions } from '../types';
import useLoggedUserAccountRoles from '../../../../hooks/useLoggedUserAccountRoles';

type WorkflowEditorProps = {
  workflowDefinitions: WorkflowDefinitions;
  serviceRef?: string;
  setDefinitions: (workflowDefinitions: WorkflowDefinitions) => void;
  onEditNodeDrawerTaskDirty: () => void;
  onEditNodeDrawerTaskClose: () => void;
  onEditNodeDrawerTaskSave: () => void;
};

const BaseWorkflowEditor = ({
  workflowDefinitions,
  setDefinitions,
  serviceRef,
  onEditNodeDrawerTaskDirty,
  onEditNodeDrawerTaskClose,
  onEditNodeDrawerTaskSave,
}: WorkflowEditorProps) => {
  const { accountId } = useAccountContext();
  const { userId: loggedUserId } = useLoggedUser();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  const { processDefinition } = workflowDefinitions;
  const { usersById } = useRelevantUsersById({ includeLoggedVerticeUser: true });
  const servicesThumbnails = useServicesThumbnails();

  const {
    selectNode,
    selectedNodeDefinition,
    selectEdge,
    selectedEdgeDefinition,
    clearSelection,
    onNodeChanged,
    isAdvancedWorkflowDefinitionEditModeEnabled,
    activeGatewayLeavingEdges,
    isWorkflowCustomizationEnabled,
  } = useWorkflowEditing({
    processDefinition,
  });

  const editorWorkflowState: WorkflowState = useMemo(
    () => ({
      tasks: {},
      gateways:
        selectedNodeDefinition?.kind === 'ProcessEngine:Gateway'
          ? { [selectedNodeDefinition.gateway.id]: { selected: true } }
          : {},
      edges: selectedEdgeDefinition ? { [selectedEdgeDefinition.edge.id]: { selected: true } } : {},
    }),
    [selectedNodeDefinition, selectedEdgeDefinition]
  );

  const model = useMemo(
    () =>
      workflowDefinitionsToModel({
        workflowDefinitions,
        workflowState: editorWorkflowState,
        servicesThumbnails,
      }),
    [workflowDefinitions, editorWorkflowState, servicesThumbnails]
  );

  const { isValidationDrawerOpen, setIsValidationDrawerOpen } = useWorkflowValidationContext();
  // close validation drawer on selected node change
  useEffect(() => {
    setIsValidationDrawerOpen(false);
  }, [setIsValidationDrawerOpen, selectedNodeDefinition]);

  const editorConfig = {
    allowContractOwnerAssignment:
      !!serviceRef?.includes('service/saas/renewal') ||
      !!serviceRef?.includes('service/saas/securityReview') ||
      !!serviceRef?.includes('service/saas/internal-negotiation') ||
      !!serviceRef?.includes('service/saas/triage'),
  };

  const handleSaveNodeDrawer = useCallback(
    (
      newNodeDefinition: TaskDefinition | GatewayDefinition | EndDefinition,
      newEdgesDefinitions?: FlowEdgeDefinition[]
    ) => {
      const newWorkflowDefinitions = updateNodeInProcessDefinition(workflowDefinitions, newNodeDefinition);
      if (newEdgesDefinitions) {
        const newDefinitionsWithUpdatedEdges = updateEdgesInProcessDefinition(
          newWorkflowDefinitions,
          newEdgesDefinitions
        );
        setDefinitions(newDefinitionsWithUpdatedEdges);
      } else {
        setDefinitions(newWorkflowDefinitions);
      }

      clearSelection();
      onEditNodeDrawerTaskSave();
    },
    [workflowDefinitions, clearSelection, setDefinitions, onEditNodeDrawerTaskSave]
  );

  const handleSaveEdgeDrawer = useCallback(
    (newEdgeDefinition: FlowEdgeDefinition) => {
      const newWorkflowDefinitions = updateEdgesInProcessDefinition(workflowDefinitions, [newEdgeDefinition]);
      setDefinitions(newWorkflowDefinitions);
      clearSelection();
      onEditNodeDrawerTaskSave();
    },
    [workflowDefinitions, clearSelection, setDefinitions, onEditNodeDrawerTaskSave]
  );

  const handleDeleteNode = useCallback(
    (nodeId: string) => {
      const newDefinitions = removeNodeFromWorkflowDefinitions(workflowDefinitions, nodeId);
      setDefinitions(newDefinitions);
      clearSelection();
      onEditNodeDrawerTaskClose();
    },
    [workflowDefinitions, setDefinitions, clearSelection, onEditNodeDrawerTaskClose]
  );

  const handleDeleteEdge = useCallback(
    (edgeId: string) => {
      const newDefinitions = removeEdgeFromWorkflowDefinitions(workflowDefinitions, edgeId);
      setDefinitions(newDefinitions);
      clearSelection();
      onEditNodeDrawerTaskClose();
    },
    [workflowDefinitions, setDefinitions, clearSelection, onEditNodeDrawerTaskClose]
  );

  const commonDrawerProps = useMemo(
    () => ({
      onClose: () => {
        clearSelection();
        onEditNodeDrawerTaskClose();
      },
      onDirty: () => {
        onNodeChanged();
        onEditNodeDrawerTaskDirty();
      },
      processDefinition,
      workflowServiceRef: serviceRef,
    }),
    [onNodeChanged, processDefinition, serviceRef, clearSelection, onEditNodeDrawerTaskDirty, onEditNodeDrawerTaskClose]
  );

  const isEditUserTaskDrawerOpen =
    isTaskDefinition(selectedNodeDefinition) && selectedNodeDefinition.task.taskType === 'User';
  const isEditServiceTaskDrawerOpen =
    isTaskDefinition(selectedNodeDefinition) && selectedNodeDefinition.task.taskType === 'Service';
  const isEditGatewayDrawerOpen = isGatewayDefinition(selectedNodeDefinition);
  const isEditEndDrawerOpen = isEndDefinition(selectedNodeDefinition);
  const isEditNodeDrawerOpen =
    isEditUserTaskDrawerOpen || isEditServiceTaskDrawerOpen || isEditGatewayDrawerOpen || isEditEndDrawerOpen;
  const isEdgeDrawerOpen = isWorkflowCustomizationEnabled && !isEditNodeDrawerOpen && !!selectedEdgeDefinition;
  const isNodesPanelOpen = isWorkflowCustomizationEnabled && !isEditNodeDrawerOpen && !isEdgeDrawerOpen;

  return (
    <WorkflowRendererContextProvider
      value={{
        model,
        usersById,
        isEditor: true,
        isDragAndDropEnabled: isWorkflowCustomizationEnabled,
        loggedUserId,
        isLoggedUserAdmin: isUserAdmin,
        accountId,
        allowVerticeServiceNavigation: false,
        workflowDefinitions,
        drawerTaskId: undefined,
        controlsPosition: isNodesPanelOpen ? 'bottom-right' : 'bottom-left',
        fitViewOptions: isWorkflowCustomizationEnabled ? { padding: 0.5 } : undefined,
      }}
    >
      {isWorkflowCustomizationEnabled ? (
        <DragAndDropExtension
          workflowDefinitions={workflowDefinitions}
          setDefinitions={setDefinitions}
          isNodesPanelOpen={isNodesPanelOpen}
          onNodeDelete={handleDeleteNode}
        >
          <WorkflowRenderer />
        </DragAndDropExtension>
      ) : (
        <WorkflowRenderer />
      )}
      <EditUserTaskDrawer
        isOpen={isEditUserTaskDrawerOpen}
        task={isTaskDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        onDelete={handleDeleteNode}
        editorConfig={editorConfig}
        isAdvancedWorkflowDefinitionEditModeEnabled={isAdvancedWorkflowDefinitionEditModeEnabled}
        isWorkflowCustomizationEnabled={isWorkflowCustomizationEnabled}
        {...commonDrawerProps}
      />
      <EditServiceTaskDrawer
        isOpen={isEditServiceTaskDrawerOpen}
        task={isTaskDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        onDelete={handleDeleteNode}
        editorConfig={editorConfig}
        isWorkflowCustomizationEnabled={isWorkflowCustomizationEnabled}
        {...commonDrawerProps}
      />
      <EditGatewayDrawer
        isOpen={isEditGatewayDrawerOpen}
        gatewayDefinition={isGatewayDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        onDelete={handleDeleteNode}
        gatewayLeavingEdges={activeGatewayLeavingEdges}
        onEdgeSelected={selectEdge}
        selectedEdgeId={selectedEdgeDefinition?.edge.id}
        {...commonDrawerProps}
      />
      <EditEdgeDrawer
        edgeDefinition={selectedNodeDefinition ? undefined : selectedEdgeDefinition}
        onSave={handleSaveEdgeDrawer}
        onDelete={handleDeleteEdge}
        isOpen={isEdgeDrawerOpen}
        {...commonDrawerProps}
      />
      <EditEndDrawer
        isOpen={isEditEndDrawerOpen}
        endDefinition={isEndDefinition(selectedNodeDefinition) ? selectedNodeDefinition : undefined}
        onSave={handleSaveNodeDrawer}
        onDelete={handleDeleteNode}
        {...commonDrawerProps}
      />
      <ValidationDrawer isOpen={isValidationDrawerOpen} handleResolve={selectNode} />
    </WorkflowRendererContextProvider>
  );
};

// eslint-disable-next-line testing-library/render-result-naming-convention
export const WorkflowEditor = withWorkflowRenderer(BaseWorkflowEditor);
