import { Stack, StackProps } from '@mui/material';
import { IconButton, IconButtonProps } from '@verticeone/design-system';
import DeleteIcon from '@mui/icons-material/Delete';

type VariableMapperDeleteButtonProps = Omit<IconButtonProps, 'icon' | 'variant' | 'size' | 'color' | 'children'>;

type VariableMapperRowProps = {
  onDelete?: () => void;
  isDeletable?: boolean;
  header?: boolean;
} & StackProps;

const VariableMapperDeleteButton = (props: VariableMapperDeleteButtonProps) => (
  <IconButton icon={DeleteIcon} variant="plain" size="S" color="neutral" {...props} />
);

const VariableMapperRow = ({
  children,
  onDelete,
  isDeletable = false,
  header = false,
  ...props
}: VariableMapperRowProps) => {
  return (
    <Stack direction="row" alignItems="center" paddingBottom={header ? 1 : 0} {...props}>
      {children}
      {!header && (
        <Stack width={28}>{!!onDelete && isDeletable && <VariableMapperDeleteButton onClick={onDelete} />}</Stack>
      )}
    </Stack>
  );
};
export default VariableMapperRow;
