import { z } from 'zod';
import { email, nonEmptyString, url } from '@verticeone/utils/validation';
import { t } from 'i18next';

export const reviewRelationshipSchema = z.object({
  vendorWebsite: url(t),
  businessOwner: z.object({
    email: email(t),
    firstName: nonEmptyString,
    lastName: nonEmptyString,
  }),
  businessPurpose: z.string(),
  tags: z.array(z.string()),
});

export type ReviewRelationshipFormData = z.infer<typeof reviewRelationshipSchema>;
