import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import { ContractFormData } from '../../types';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { SimpleOption } from '@verticeone/design-system';
import ContractFormEntry, { ContractFormEntryPropsWithFixedComponents } from '../../ContractFormEntry';
import React, { useMemo } from 'react';
import { keyBy } from 'lodash';
import { useNegotiationTierOptions } from '../../hooks/useNegotiationTierOptions';
import { useContractContext } from '../../ContractContext';

type ReadComponent = FormReadOnlyTextType<ContractFormData>;
type WriteComponent = FormSelectFieldComponentType<ContractFormData, SimpleOption, false>;

export const NegotiationTierFormEntry = (
  props: ContractFormEntryPropsWithFixedComponents<ReadComponent, WriteComponent>
) => {
  const negotiationTierOptions = useNegotiationTierOptions();
  const negotiationTierOptionByValue = useMemo(() => keyBy(negotiationTierOptions, 'value'), [negotiationTierOptions]);
  const { fetchedContract } = useContractContext('DONT_REQUIRE_FETCHED');

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, SimpleOption, false>
    >
      {...props}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? negotiationTierOptionByValue[value]?.label : '-'),
        ...props.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...props.writeComponentProps,
        options: negotiationTierOptions,
        isClearable: !fetchedContract?.contract.classification?.negotiationTier, // Value can be cleared only when creating new contract
        boxValue: (value) => (value ? negotiationTierOptionByValue[value] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
      width={12}
    />
  );
};
