import { Box, Skeleton, Stack } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { BreadcrumbItem, PageHeader, Tab, Tabs, TabsBottomLineWrapper } from '@verticeone/design-system';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isNotNil } from '@verticeone/utils/validation';
import { filterDailyAlarms } from '../Applications/helpers';
import { UsageContext } from '../Applications/UsageContext';
import { DiscoverySourcesCell } from '../components/DiscoverySourcesCell';
import { LastFetchedInfo } from '../components/LastFetchedInfo';
import { useLastFetchedData } from '../hooks/useLastFetchedData';
import { useApplication } from './hooks/useApplication';
import { ApplicationLayout, UsersLayout } from './layouts';
import { TabDefinition, UsageTabs } from './utils';

export const Usage = () => {
  const routes = useRoutes();
  const { navigate, generatePathForRoute } = useRouteNavigate();
  const { t } = useTranslation();
  const { applicationId } = useParams();

  const { activeTab, setActiveTab } = useContext(UsageContext);
  const [showHideConfirmation, setShowHideConfirmation] = useState(false);
  const { application, isSuccess, isFetching, isHidden, toggleStatus, refetch } = useApplication(applicationId);

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: UsageTabs.APPLICATIONS,
        label: t('APPLICATIONS.USAGE.APPLICATION'),
        element: <ApplicationLayout />,
        isVisible: true,
      },
      {
        id: UsageTabs.USERS,
        label: t('APPLICATIONS.USAGE.USERS'),
        element: <UsersLayout />,
        isVisible: true,
      },
    ],
    [t]
  );

  const switchApplicationStatus = (skipConfirmation: boolean) => {
    setShowHideConfirmation(!skipConfirmation);

    if (skipConfirmation) {
      void toggleStatus().then(() => refetch());
    }
  };

  useEffect(() => {
    if (!application && isSuccess) {
      navigate(routes.PAGE_NOT_FOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, isSuccess]);

  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.Usage);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);
  const providers = application?.sources?.map((source) => source.id).filter(isNotNil);

  const items: BreadcrumbItem<typeof Link>[] = [
    { label: t('SAAS.NAVBAR.SAAS') },
    {
      label: t('SAAS.NAVBAR.APPLICATIONS'),
      component: Link,
      to: generatePathForRoute(routes.APPLICATIONS),
    },
    {
      label: application?.name !== undefined ? application.name : <Skeleton width={80} />,
      startAdornment: (
        <DiscoverySourcesCell sources={application?.sources} useSourceIcons={false} size={16} spacing={-1} />
      ),
    },
  ];

  if (!applicationId) {
    return null;
  }

  return (
    <>
      <PageHeader
        breadcrumb={<PageHeader.Breadcrumbs items={items} />}
        color="primary"
        actions={
          <>
            {!isFetching && !isLoadingLastFetchedData && hasAccessLogs && (
              <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} providers={providers} />
            )}
            <PageHeader.MenuAction id="menu">
              <PageHeader.MenuActionItem onClick={() => switchApplicationStatus(isHidden)}>
                {isHidden ? t('APPLICATIONS.USAGE.UNHIDE_FROM_LIST') : t('APPLICATIONS.USAGE.HIDE_FROM_LIST')}
              </PageHeader.MenuActionItem>
            </PageHeader.MenuAction>
          </>
        }
      />
      <Stack direction="column" sx={{ width: 1 }} p={6} pt={0}>
        <Stack gap={8}>
          <TabsBottomLineWrapper>
            <Tabs
              variant="outlined"
              value={activeTab}
              onChange={(_, value) => setActiveTab(value)}
              scrollButtons="auto"
            >
              {tabs.map((tab) => (
                <Tab key={tab.id} value={tab.id} label={tab.label} />
              ))}
            </Tabs>
          </TabsBottomLineWrapper>
          <Box>{tabs.find((tab) => tab.id === activeTab)?.element}</Box>
        </Stack>
        <ConfirmationDialog
          isOpen={showHideConfirmation}
          secondaryButtonAction={() => setShowHideConfirmation(false)}
          secondaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CANCEL_BUTTON')}
          primaryButtonAction={() => switchApplicationStatus(true)}
          headerText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.HEADER')}
          bodyPrimaryText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONTENT', {
            application: application?.name,
          })}
          primaryButtonText={t('APPLICATIONS.USAGE.HIDE_FROM_LIST_CONFIRMATION.CONFIRM_BUTTON')}
        />
      </Stack>
    </>
  );
};
