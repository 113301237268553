import { useState } from 'react';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useUuid } from '@vertice/core/src/hooks/useUuid';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import {
  useConnectAccountIntegrationMutation,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { ConnectionFormData } from '../components/ConnectionForm';
import { DEFAULT_POLLING_INTERVAL, JIRA_PROVIDER_ID } from '../constants';

export const useTestConnection = () => {
  const { accountId } = useAccountContext();
  const id = useUuid();

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();
  const [connectAccountIntegration] = useConnectAccountIntegrationMutation();
  const [testAccountIntegration] = useTestAccountIntegrationMutation();

  const [pollingInterval, setPollingInterval] = useState(0);
  const handleSetPollingInterval = (newValue: number) => {
    setPollingInterval(newValue);
  };

  const testConnection: GoodFormOnSubmitCallback<ConnectionFormData> = async (formData) => {
    await revokeAccountIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
    });

    await connectAccountIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {
            domain: formData.workspaceUrl,
            username: formData.administratorEmail,
            api_key: formData.apiKey,
            webhook_secret_key: id,
          },
          connectionForm: {
            domain: formData.workspaceUrl,
            username: formData.administratorEmail,
          },
        },
      },
    });

    await testAccountIntegration({
      accountId,
      integrationId: JIRA_PROVIDER_ID,
      testName: 'connection',
    });

    setPollingInterval(DEFAULT_POLLING_INTERVAL);
  };

  return { pollingInterval, setPollingInterval: handleSetPollingInterval, testConnection };
};
