import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { productComparator, preProcessNonEmptyCell } from '../utils';
import { EditableNameCell } from '../cells';
import { ProductItem, ProductListColumn } from '../types';
import { CustomDetailPanelToggle } from '../cells/CustomDetailPanelToggle';
import ProductCell from '../cells/ProductCell';
import { useProductExtraColumnBuilder } from './useProductExtraColumnBuilder';
import { isNotNil } from '@verticeone/utils/validation';
import { keyBy } from 'lodash';
import { Product } from '@vertice/slices';

type UseProductColumnsProps = {
  vendorProducts?: Product[];
  selectedProducts?: ProductItem[];
  extraColumns?: GridColDef[];
  showDetailRowSelector: boolean;
  productCellDecorator?: (row: ProductItem) => ReactNode;
};

export const useProductColumns = ({
  vendorProducts = [],
  selectedProducts = [],
  extraColumns,
  showDetailRowSelector,
  productCellDecorator,
}: UseProductColumnsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.PRODUCT_LIST' });
  const { buildColumn } = useProductExtraColumnBuilder();
  const vendorProductsMap = useMemo(() => keyBy(vendorProducts, 'id'), [vendorProducts]);

  const effectiveExtraColumns = useMemo(
    () =>
      extraColumns
        ? extraColumns
        : [buildColumn(ProductListColumn.LICENSE_TYPE), buildColumn(ProductListColumn.NUMBER_OF_LICENSES)].filter(
            isNotNil
          ),
    [extraColumns, buildColumn]
  );

  return useMemo(
    (): GridColDef<ProductItem>[] => [
      ...(showDetailRowSelector
        ? [
            {
              field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
              headerName: '',
              renderCell: (params: GridRenderCellParams) => <CustomDetailPanelToggle {...params} />,
              disableColumnMenu: true,
              sortable: false,
              width: 1,
            },
          ]
        : []),
      {
        field: 'productId',
        headerName: t('PRODUCT'),
        renderCell: (params) => <ProductCell {...params} productCellDecorator={productCellDecorator} />,
        renderEditCell: (params) => <EditableNameCell {...params} selectedProducts={selectedProducts} />,
        preProcessEditCellProps: preProcessNonEmptyCell,
        sortComparator: productComparator(vendorProductsMap),
        disableColumnMenu: true,
        editable: true,
        flex: 1.3,
      },
      ...effectiveExtraColumns,
    ],
    [t, effectiveExtraColumns, selectedProducts, vendorProductsMap, showDetailRowSelector, productCellDecorator]
  );
};
