import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

import type { EditEdgeFormData } from './schema';

import { EdgeNameInput, EdgeFieldContainer } from '../components';

type EditEdgeFormProps = {
  onDirty: VoidFunction;
};

export const EditEdgeForm: FC<EditEdgeFormProps> = ({ onDirty }) => {
  const { formState } = useFormContext<EditEdgeFormData>();

  useEffect(() => {
    if (formState.isDirty) {
      onDirty();
    }
  }, [formState.isDirty, onDirty]);

  return (
    <Stack gap={5} direction="column" width="100%">
      <EdgeFieldContainer>
        <EdgeNameInput isDefaultEdge={false} isEdgeFromExclusiveGateway={false} index={1} name="name" />
      </EdgeFieldContainer>
    </Stack>
  );
};
