import React from 'react';
import { Grid } from '@mui/material';
import CloudHierarchyCard from '../../../modules/cloud/cards/CloudHierarchyCard/CloudHierarchyCard';
import OverviewStatsCard from '../../../modules/cloud/cards/OverviewStatsCard/OverviewStatsCard';
import { Card } from '@verticeone/design-system';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import EstimatedSavingsCard from '../../../modules/cloud/cards/EstimatedSavingsCard/EstimatedSavingsCard';
import SavingsOverTimeGraph from './SavingsOverTimeGraph';

const CloudOverview = () => (
  <LoadableAWSPageWrapper>
    <Grid container rowSpacing={4} columnSpacing={4}>
      <Grid item sm={12}>
        <Card>
          <OverviewStatsCard />
          <SavingsOverTimeGraph />
        </Card>
      </Grid>
      <Grid item sm={12}>
        <CloudHierarchyCard />
      </Grid>
      <Grid item sm={12}>
        <EstimatedSavingsCard />
      </Grid>
    </Grid>
  </LoadableAWSPageWrapper>
);

export default CloudOverview;
