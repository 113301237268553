import { z } from 'zod';

export const lineItemsFieldMetadataSchema = z.object({
  label: z.string().min(1),
  description: z.string().optional(),
  required: z.boolean(),
});

export const lineItemsFieldSchema = z.object({
  name: z.string(),
  type: z.literal('LINE_ITEMS'),
  metadata: lineItemsFieldMetadataSchema,
});

export type LineItemsFieldMetadata = z.infer<typeof lineItemsFieldMetadataSchema>;

export type LineItemsFieldType = z.infer<typeof lineItemsFieldSchema>;
