import { useCallback } from 'react';
import {
  type Ec2ReservationsQuery,
  useLazyEc2ReservationsQuery,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

import type { EC2DataEnhanced } from './useEC2Data';
import type { ReservationsData, UseReservationsData } from '../../../types';
import { fillMissingPastReservationsData } from '../../../utils';
import { startOfDay, subDays } from 'date-fns';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

export const useEC2ReservationData = (): UseReservationsData<EC2DataEnhanced> => {
  const { accountId } = useAccountContext();
  const [getReservationData] = useLazyEc2ReservationsQuery();

  const parsedData = useCallback((items: Ec2ReservationsQuery | undefined) => {
    if (items?.athenaViewQuery?.__typename !== 'DataTableResult' || !items.athenaViewQuery.table) {
      return undefined;
    }

    const tableData = getTableData(items.athenaViewQuery.table, {
      timestamp: 'day',
      instance_family: 'instanceFamily',
      reserved_capacity: 'reservedCapacity',
      pre_installed_sw: 'operatingSystem',
      region: 'region',
      license_model: 'licenseModel',
      on_demand_usage: 'onDemandUsage',
      reserved_usage: 'reservedUsage',
      sp_usage: 'spUsage',
    }) as ReservationsData[];

    return fillMissingPastReservationsData<ReservationsData>(tableData, subDays(startOfDay(new Date()), 2));
  }, []);

  const fetch = useCallback(
    async (item: EC2DataEnhanced): Promise<{ error: any; data: ReservationsData[] | undefined }> => {
      const response = await getReservationData({
        accountId: accountId!,
        sizeFlexibilityGroup: item.sizeFlexibilityGroup,
      });

      if (!response.error && response.data?.athenaViewQuery?.__typename === 'DeferredQueryResult') {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return await fetch(item);
      }

      return {
        error: response.error,
        data: parsedData(response.data),
      };
    },
    [getReservationData, accountId, parsedData]
  );

  return async (item: EC2DataEnhanced) => {
    return await fetch(item);
  };
};
