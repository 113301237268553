import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Stack, styled, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAccountIntegrationQuery, useListCreateJiraIssueTypeFieldsQuery } from '@vertice/slices';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { IconWrapper } from '@verticeone/design-system';
import { useAccountContext } from '../../../../../../account/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { isTaskIOMappingConfiguration } from '../../../../../pocWorkflowSchema';
import { useWorkflowRendererContext } from '../../../../WorkflowRenderer/WorkflowRendererContext';
import { EditorDrawerScrollContainer } from '../../../EditorDrawer';
import { RichSelector } from '../../../EditServiceTaskDrawer/JiraCreate/RichSelector';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { LoadingSkeleton } from '../../../VariableMapper/LoadingSkeleton';
import { VariableMapperHeader } from '../../../VariableMapper/VariableMapperHeader';
import { parseToRichSelectorValue } from '../../../VariableMapper/VariableMapperRow';
import { findVariableById } from '../../../VariableSelector/utils';
import { VariableChip } from '../../../VariableSelector/VariableChip';
import { JiraTicketLink } from '../JiraCommon/JiraTicketLink';
import { ConnectionFormSchema } from '../JiraCommon/types';
import { findValueInTaskObj } from '../JiraCommon/utils';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';

const VariableMapperBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  backgroundColor: theme.palette.core.color1,
  borderRadius: 12,
  padding: theme.spacing(4),
}));

type JiraCreateCompleteProps = {
  taskOverview?: TaskOverview;
};

export const JiraCreateComplete: FC<JiraCreateCompleteProps> = ({ taskOverview }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { accountId } = useAccountContext();
  const { workflowDefinitions } = useWorkflowRendererContext();

  const { data: integrationData } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });

  const { processDefinition } = workflowDefinitions;
  const processTaskDefinition = processDefinition?.process?.tasks?.find((processTask) =>
    taskOverview?.task.taskId?.includes(processTask.task.id)
  );
  const processTaskIOMappingConfiguration =
    processTaskDefinition?.task?.configurations?.find(isTaskIOMappingConfiguration);
  const projectId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'projectId'
  )?.value;
  const issueTypeId = processTaskIOMappingConfiguration?.mapping.inputFields.find(
    (field) => field.name === 'issueTypeId'
  )?.value;

  const { data: issueTypeFieldsData, isLoading: isLoadingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: projectId ?? '',
      issueTypeId: issueTypeId ?? '',
    },
    { skip: !projectId || !issueTypeId }
  );

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: processTaskDefinition?.task.id ?? '',
    processDefinition,
    workflowServiceRef: taskOverview?.request?.serviceRef,
  });
  const allVariables = [...requestVariables, ...udfVariables];

  const filteredFields = processTaskIOMappingConfiguration?.mapping.inputFields
    .filter((field) => field.name.match(/(.*)<(.*)>/g))
    ?.map((field) => ({ name: field.name, value: field.value }));

  const domainUrl = (integrationData?.parameters?.connectionForm as ConnectionFormSchema | undefined)?.domain;
  const ticketKey = taskOverview?.task?.result?.ticketKey;
  const ticketSummary = findValueInTaskObj(/summary<(.*?)>/, taskOverview?.task.input);
  const ticketUrl = domainUrl && ticketKey ? `${domainUrl}browse/${ticketKey}` : undefined;
  const ticketTitle = ticketKey && ticketSummary ? `${ticketKey}: ${ticketSummary}` : undefined;

  return (
    <EditorDrawerScrollContainer>
      <VariableMapperBox>
        <VariableMapperHeader
          worflowVariablesTitle={t('INTELLIGENT_WORKFLOWS.VARIABLE_MAPPER.COLUMN_HEADER.VERTICE')}
          otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER')}
          fromWorkflow={true}
        />
        {filteredFields?.map((field, index) => {
          const workflowVariable = findVariableById(allVariables, field.value);
          const jiraVariable = issueTypeFieldsData?.fields?.find(
            (jiraField) => jiraField.id === field.name.split('<')[0]
          );
          const isRichSelector = REQUIRED_JIRA_CREATE_FIELDS.includes(jiraVariable?.id ?? '');
          const richSelectorValue = parseToRichSelectorValue(allVariables, field.value, theme, true);
          const workflowSelector = isRichSelector ? (
            <RichSelector value={richSelectorValue} disabled />
          ) : (
            <VariableChip
              label={workflowVariable?.label}
              typeLabel={workflowVariable?.type.labels?.[0]}
              isDisabled={true}
            />
          );

          return (
            <Grid container sx={{ alignItems: 'center', zIndex: 1 }} key={index}>
              <Grid item xs={7}>
                {isLoadingIssueTypeFields ? <LoadingSkeleton /> : workflowSelector}
              </Grid>
              <Grid item xs={0.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconWrapper icon={ArrowForwardIcon} htmlColor={theme.palette.core.color5} />
              </Grid>
              <Grid item xs={4}>
                {isLoadingIssueTypeFields ? (
                  <LoadingSkeleton />
                ) : (
                  <VariableChip label={jiraVariable?.name} typeLabel={jiraVariable?.type} isDisabled={true} />
                )}
              </Grid>
            </Grid>
          );
        })}
      </VariableMapperBox>
      <JiraTicketLink url={ticketUrl} title={ticketTitle} />
    </EditorDrawerScrollContainer>
  );
};
