import { Grid, Stack } from '@mui/material';
import { Divider, Text } from '@verticeone/design-system';
import { FC, ReactNode } from 'react';

type SummarySectionRootProps = {
  children?: ReactNode | ReactNode[];
};

const SummarySectionRoot: FC<SummarySectionRootProps> = (props) => {
  const { children } = props;

  return <Stack gap={4}>{children}</Stack>;
};

type SummarySectionHeaderProps = {
  children?: ReactNode | ReactNode[];
};

const SummarySectionHeader: FC<SummarySectionHeaderProps> = (props) => {
  const { children } = props;

  return (
    <>
      <Stack direction="row">
        <Text variant="caption" color="text1" size="XS">
          {children}
        </Text>
      </Stack>
      <Divider />
    </>
  );
};

type SummarySectionBodyProps = {
  children?: ReactNode | ReactNode[];
};

const SummarySectionBody: FC<SummarySectionBodyProps> = (props) => {
  const { children } = props;

  return (
    <Grid container rowGap={4}>
      {children}
    </Grid>
  );
};

type SummarySectionLabelProps = {
  children?: ReactNode | ReactNode[];
};

const SummarySectionLabel: FC<SummarySectionLabelProps> = (props) => {
  const { children } = props;

  return (
    <Grid item xs={6}>
      <Text variant="body-regular" size="S" color="text2">
        {children}
      </Text>
    </Grid>
  );
};

type SummarySectionValueProps = {
  children?: ReactNode | ReactNode[];
};

const SummarySectionValue: FC<SummarySectionValueProps> = (props) => {
  const { children } = props;

  return (
    <Grid item xs={6}>
      <Text variant="body-bold" size="S" color="text1" sx={{ whiteSpace: 'pre-wrap' }}>
        {children}
      </Text>
    </Grid>
  );
};

export const SummarySection = Object.assign(SummarySectionRoot, {
  Header: SummarySectionHeader,
  Body: SummarySectionBody,
  Label: SummarySectionLabel,
  Value: SummarySectionValue,
});
