import { FC, useCallback, useMemo } from 'react';
import { v1 as uuid } from 'uuid';
import { TaskContractReviewForm, TaskContractReviewFormConfig } from './TaskContractReviewForm';
import { ContractReviewForm, TaskContractReviewInput, TaskContractReviewOutput } from './types';
import { transformToOutput } from './utils/transformToOutput';
import { useTaskContext } from '../TaskContext';
import { useAccountPreferredCurrency } from '../../../../account/useAccountPreferredCurrency';
import { TaskContractReviewFormSkeleton } from './TaskContractReviewFormSkeleton';

export type TaskContractReviewFormSectionProps = {
  onSubmit: (values: TaskContractReviewOutput) => Promise<void>;
};

export const TaskContractReviewFormSection: FC<TaskContractReviewFormSectionProps> = (props) => {
  const { onSubmit } = props;
  const { taskOverview } = useTaskContext();
  const accountPreferredCurrencyQuery = useAccountPreferredCurrency();

  const config = useMemo((): TaskContractReviewFormConfig | undefined => {
    return accountPreferredCurrencyQuery.isLoading
      ? undefined
      : { accountPreferredCurrency: accountPreferredCurrencyQuery.data };
  }, [accountPreferredCurrencyQuery]);

  const taskInput = useMemo(() => {
    return taskOverview?.task.input as TaskContractReviewInput;
  }, [taskOverview]);

  const handleSubmit = useCallback(
    async (values: ContractReviewForm) => {
      const offerId = uuid();
      const output = transformToOutput(offerId, values);
      await onSubmit(output);
    },
    [onSubmit]
  );

  return config ? (
    <TaskContractReviewForm onSubmit={handleSubmit} config={config} taskInput={taskInput} />
  ) : (
    <TaskContractReviewFormSkeleton />
  );
};
