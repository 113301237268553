import React, { useMemo } from 'react';
import { type FieldPath, type FieldValues, useWatch } from 'react-hook-form';
import type { Control, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text, TextField, TextFieldCaption } from '@verticeone/design-system';
import FormControl from './FormControl';

type TestConnectionFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  protocolPath: FieldPath<T>;
  hostnamePath: FieldPath<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  disabled?: boolean;
};

const TestConnectionField = <T extends FieldValues>({
  name,
  protocolPath,
  hostnamePath,
  register,
  control,
  disabled = false,
}: TestConnectionFieldProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM.TEST_CONNECTION_URL' });
  const [protocol, _hostname, path] = useWatch({ control, name: [protocolPath, hostnamePath, name] });
  const hostname = useMemo(
    () =>
      [
        _hostname,
        !!_hostname.length && !_hostname.endsWith('/') && !!path.length && !path.startsWith('?') ? '/' : '',
      ].join(''),
    [_hostname, path]
  );

  return (
    <FormControl>
      <Stack>
        <TextFieldCaption label={t('TITLE')} htmlFor="test-connection" size="XS" />
        <Text variant="body-regular" size="XS" color="text3">
          {t('DESCRIPTION')}
        </Text>
      </Stack>
      <Stack gap={2} direction="row" alignItems="center">
        <TextField
          size="M"
          id="test-connection"
          fullWidth
          disabled={disabled}
          {...register(name)}
          startAdornment={
            <Stack pr={2} direction="row" maxWidth="75%">
              <Stack py={2} borderRight="1px solid" borderColor="core.color3">
                <Text variant="body-regular" size="S" color="inactive1" height="100%" pr={3.5}>
                  {protocol}://
                </Text>
              </Stack>
              <Stack
                py={2}
                px={3.5}
                borderRight="1px solid"
                borderColor="core.color3"
                bgcolor="inactive.color4"
                minWidth={150}
                sx={{
                  height: '33px',
                  overflow: 'hidden',
                }}
              >
                <Text
                  variant="body-regular"
                  size="S"
                  color="inactive1"
                  pr={3.5}
                  sx={{
                    overflowX: 'auto',
                    overflowY: 'hidden',
                    paddingBottom: '16px',
                    marginBottom: '-32px',
                    height: '48px',
                  }}
                >
                  {hostname}
                </Text>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </FormControl>
  );
};

export default TestConnectionField;
