import React, { ComponentProps, FC, ReactNode } from 'react';
import { Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Divider, Placeholder } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useAccountContext } from '../../account/AccountContext';
import { Account, useGetAccountQuery } from '@vertice/slices';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { VerticeVIcon as VerticeSmallIcon } from '@vertice/assets';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR, WORKFLOW_ADMIN_ACCOUNT_ID } from '../constants';
import { PageHeader } from '@verticeone/design-system';
import { BreadcrumbItem } from '@verticeone/design-system';

type Adornment = {
  start?: ReactNode;
  end?: ReactNode;
};

type IntelligentWorkflowsLayoutProps = {
  children: ReactNode;
  currentPageName?: string;
  currentPageAdornments?: Adornment;
  parentPageName?: string;
  parentPagePath?: string;
  parentPageAccountId?: string;
  parentPageAdornments?: Adornment;
  grandparentPageName?: string;
  grandparentPagePath?: string;
  grandparentPageAdornments?: Adornment;
  isLoading?: boolean;
  withDivider?: boolean;
  actions?: ReactNode;
  padding?: ComponentProps<typeof Stack>['padding'];
  gap?: ComponentProps<typeof Stack>['gap'];
};

export const IntelligentWorkflowsLayout: FC<IntelligentWorkflowsLayoutProps> = ({
  children,
  currentPageName,
  currentPageAdornments,
  parentPageName,
  parentPagePath,
  parentPageAccountId,
  parentPageAdornments,
  grandparentPageName,
  grandparentPagePath,
  grandparentPageAdornments,
  actions,
  isLoading,
  withDivider,
  padding = 6,
  gap = 8,
}) => {
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const paths = useRoutes();

  const isDifferentAccount = parentPageAccountId && parentPageAccountId !== accountId;
  const isWfAdminAccount = accountId === WORKFLOW_ADMIN_ACCOUNT_ID;

  const { data: parentAccountData } = useGetAccountQuery(
    { accountId: parentPageAccountId! },
    { skip: !parentPageAccountId || !isDifferentAccount }
  );

  const currentPath = window.location.pathname;
  const rootBreadcrumbItem: BreadcrumbItem[] = (['DASHBOARD', 'WORKFLOWS', 'TASKS', 'REQUESTS'] as const)
    .map((link) => {
      const basePath = paths.INTELLIGENT_WORKFLOWS[link].ABSOLUTE_PATH.replace(':account_id', accountId);
      if (!currentPath.startsWith(basePath)) return null;

      const item: BreadcrumbItem<typeof RouterLink> = {
        label: t(`INTELLIGENT_WORKFLOWS.NAVBAR.${link}`),
        to: basePath,
        component: RouterLink,
      };

      return item;
    })
    .filter(Boolean) as BreadcrumbItem[];

  const items: BreadcrumbItem<typeof RouterLink>[] = [
    {
      label: isDifferentAccount ? (parentAccountData as Account)?.name : t('INTELLIGENT_WORKFLOWS.NAVBAR.GROUP_TITLE'),
    },
    ...rootBreadcrumbItem,
    ...(grandparentPageName || isLoading
      ? [
          {
            label: isLoading ? <Placeholder width={100} /> : grandparentPageName,
            component: RouterLink,
            to: grandparentPagePath || '../..',
            startAdornment: grandparentPageAdornments?.start,
            endAdornment: grandparentPageAdornments?.end,
          },
        ]
      : []),
    ...(parentPageName || isLoading
      ? [
          {
            label: isLoading ? <Placeholder width={100} /> : parentPageName,
            component: RouterLink,
            to: parentPagePath || '..',
            target: isDifferentAccount ? '_blank' : '_self',
            startAdornment: parentPageAdornments?.start,
            endAdornment: parentPageAdornments?.end,
          },
        ]
      : []),
    ...(currentPageName || isLoading
      ? [
          {
            startAdornment:
              isDifferentAccount && isWfAdminAccount ? (
                <Stack width={20} justifyContent="center" alignItems="center" p={1.5}>
                  <VerticeSmallIcon width={20} height={20} />
                </Stack>
              ) : (
                currentPageAdornments?.start
              ),
            label: isLoading ? <Placeholder width={100} /> : currentPageName,
            endAdornment: currentPageAdornments?.end,
          },
        ]
      : []),
  ];

  return (
    <Stack height="100%" width="100%">
      <PageHeader
        breadcrumb={<PageHeader.Breadcrumbs items={items} />}
        actions={actions}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      />
      {withDivider && <Divider />}
      <Stack padding={padding} paddingTop={0} gap={gap} width="100%" flex={1}>
        {children}
      </Stack>
    </Stack>
  );
};
