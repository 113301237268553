import React, { ReactElement, FC } from 'react';
import { useResolveUserPermissionsOnContractQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import useNavigateIf from '@vertice/core/src/hooks/useNavigateIf';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useParams } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';

export type ContractV2PageWrapperProps = {
  children: ReactElement;
};

const CheckUserPermissionToViewContractWrapper: FC<ContractV2PageWrapperProps> = ({ children }) => {
  const { accountId } = useAccountContext();
  const { contractId } = useParams();
  const routes = useRoutes();

  const { data: userContractPermissions, isLoading: permissionsLoading } = useResolveUserPermissionsOnContractQuery(
    { accountId, contractId: contractId! },
    { skip: !contractId }
  );
  // Check if user is allowed to view contract - if not, navigate to contract list
  const userCanViewContract = !contractId || (userContractPermissions?.permissions.r ?? false);
  useNavigateIf(!userCanViewContract && !permissionsLoading, routes.CONTRACTS);

  if (permissionsLoading || !userCanViewContract) {
    return null;
  }

  return <>{children}</>;
};

export default CheckUserPermissionToViewContractWrapper;
