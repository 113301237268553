import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import {
  useCheckConnectionQuery,
  useDeactivateAccountIntegrationMutation,
  useRemoveJiraWebhookConfigMutation,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isValidIntegrationResponse } from '../utils';
import { JIRA_PROVIDER_ID } from '../Native/Workflows/Jira/constants';

type UseDeactivateIntegrationParams = {
  providerId: string;
  connectionId?: string;
  closeDialog: () => void;
  shouldRedirect?: boolean;
};

export const useDeactivateIntegration = ({
  providerId,
  connectionId,
  closeDialog,
  shouldRedirect = false,
}: UseDeactivateIntegrationParams) => {
  const { accountId } = useAccountContext();
  const navigate = useNavigate();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const [deactivateAccountIntegration, { isLoading: isDeactivateAccountIntegrationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeAccountIntegration, { isLoading: isRevokeAccountIntegrationInProgress }] =
    useRevokeAccountIntegrationMutation();
  const [removeJiraWebhookConfig, { isLoading: isRemoveJiraWebhookConfigInProgress }] =
    useRemoveJiraWebhookConfigMutation();

  const { refetch: refetchConnection } = useCheckConnectionQuery(
    {
      accountId,
      integrationId: providerId,
      connectionId: connectionId ?? '',
    },
    { skip: !connectionId }
  );

  const deactivateIntegration = async () => {
    if (providerId === JIRA_PROVIDER_ID) {
      await removeJiraWebhookConfig({ accountId });
    }

    const response = await deactivateAccountIntegration({
      accountId,
      integrationId: providerId,
    });

    if (isValidIntegrationResponse(response)) {
      await revokeAccountIntegration({ accountId, integrationId: providerId });
      if (connectionId) {
        await refetchConnection();
      }
      closeDialog();
      if (shouldRedirect) {
        navigate(generatePath(routes.INTEGRATIONS.ABSOLUTE_PATH));
      }
    }
  };

  return {
    deactivateIntegration,
    isDeactivateAccountIntegrationInProgress,
    isRevokeAccountIntegrationInProgress,
    isRemoveWebhookInProgress: isRemoveJiraWebhookConfigInProgress,
  };
};
