import { useEffect, useMemo } from 'react';

import { useListMultipleWorkflowVersionsQuery } from '@vertice/slices';

import { useAccountContext } from '../../../account/AccountContext';
import { useServiceWorkflows } from '../ServiceDetailPage/useServiceWorkflows';
import { parseWorkflowRef } from '../../../../hooks/workflows/refUtils';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { WorkflowVersionsResponse } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { getIsDefaultWorkflow, isWorkflowArchived } from './utils';
import type { ServiceCatalogResource } from '../../catalogResource/types';

type Arguments = {
  service: ServiceCatalogResource;
  showArchived?: boolean;
  onCompleted?: () => void;
};

export const useFlatServiceWorkflowsWithAllVersions = ({ service, onCompleted, showArchived }: Arguments) => {
  const { accountId } = useAccountContext();

  const defaultWorkflowRef = service.definition.Service?.ServiceProvider?.HandlerUrn;
  const serviceRef = service.urn;

  const { workflows, isLoadingWorkflows } = useServiceWorkflows({ serviceRef, defaultWorkflowRef });
  const {
    data: workflowsWithVersionsData,
    currentData,
    isLoading,
    refetch: refetchWorkflowsWithVersions,
    isFetching,
  } = useListMultipleWorkflowVersionsQuery(
    { accountId, workflowIds: workflows.map((w) => w.id) },
    { skip: !workflows.length }
  );

  useEffect(() => {
    if (currentData) {
      onCompleted?.();
    }
  }, [currentData, onCompleted]);

  useNotificationWebSocketSubscription({
    filter: (msg) => workflows.some((w) => msg.message_attributes.ref.includes(w.id)),
    callback: () => {
      void refetchWorkflowsWithVersions();
    },
  });

  const defaultWorkflowId = parseWorkflowRef(defaultWorkflowRef)?.workflowId;

  // Change all versions to have the same workflow name for proper grouping
  const workflowsWithVersionsWithFixedWorkflowName = useMemo(
    () =>
      (workflowsWithVersionsData ?? []).map((workflowWithVersions) => {
        const workflowName = workflowWithVersions.workflows.find((w) => w.status === 'ACTIVE')?.workflowName;

        const workflowsData = workflowWithVersions.workflows.map((workflowVersion) => ({
          ...workflowVersion,
          workflowName: workflowName || workflowVersion.workflowName,
        }));

        return {
          ...workflowWithVersions,
          workflows: workflowsData,
        } satisfies WorkflowVersionsResponse;
      }),
    [workflowsWithVersionsData]
  );

  const workflowsWithVersions = useMemo(() => {
    if (!workflowsWithVersionsWithFixedWorkflowName) return [];
    return workflowsWithVersionsWithFixedWorkflowName
      .map((workflowWithVersions) =>
        workflowWithVersions.workflows.map((workflowVersion) => ({
          ...workflowVersion,
          isWorkflowDefault: getIsDefaultWorkflow(workflowVersion.workflowRef, defaultWorkflowId),
        }))
      )
      .flat();
  }, [workflowsWithVersionsWithFixedWorkflowName, defaultWorkflowId]);

  const filteredWorkflows = useMemo(
    () =>
      workflowsWithVersions
        .map((workflow) => ({
          ...workflow,
          isArchived: isWorkflowArchived(workflow),
        }))
        .filter((workflow) => (showArchived ? true : !workflow.isArchived)),

    [workflowsWithVersions, showArchived]
  );

  return {
    isLoading: isLoading || isLoadingWorkflows,
    workflowsWithVersions: filteredWorkflows,
    isFetching,
  };
};
