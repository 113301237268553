import { z } from 'zod';

import { productSchema, vendorSchema } from '../shared/schemas';

const vendorProductSchema = z.object({
  vendor: vendorSchema,
  product: productSchema.optional(),
});

export const zodSchema = z.object({
  selectedVendorsAndProducts: z.array(vendorProductSchema),
  finalDecision: vendorProductSchema.optional(),
  vendorsRejected: z.boolean(),
  vendorId: z.string().min(1),
});

export type FormData = z.infer<typeof zodSchema>;
