import { useSimpleDialogContext } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { deriveRenewedOutsideContractFromCurrent } from '../ContractNotYetRenewedCard';
import { Contract, useCreateContractMutation } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useCallback } from 'react';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import {
  ContractTab,
  useContractTabNavigationContext,
} from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';

type UseRenewOutsideVerticeProps = {
  contract: Contract;
  accountId: string;
};

export const useRenewOutsideVertice = (props: UseRenewOutsideVerticeProps) => {
  const { contract, accountId } = props;

  const { t } = useTranslation();
  const routes = useRoutes();
  const tabNavigationContext = useContractTabNavigationContext();
  const { navigate } = useRouteNavigate();
  const { getConfirmation } = useSimpleDialogContext();

  const [createContract, { isLoading }] = useCreateContractMutation();

  const confirmRenewInDialog = useCallback(async () => {
    const confirmResult = await getConfirmation({
      title: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_QUESTION'),
      description: t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_CONFIRMATION'),
      okButton: { label: t('DIALOG.BUTTONS.CONFIRM') },
    });

    return confirmResult.status === 'ok';
  }, [getConfirmation, t]);

  const getRenewedContract = useCallback(() => {
    const contractCreate = deriveRenewedOutsideContractFromCurrent(contract);
    return { contract: contractCreate };
  }, [contract]);

  const redirectToNewContract = useCallback(
    (contractId: string) => {
      tabNavigationContext.setTabId(ContractTab.REQUEST_DETAILS);
      navigate(routes.CONTRACTS.DETAIL.EDIT, { contractId });
    },
    [navigate, tabNavigationContext, routes]
  );

  const handleRenewOutsideVertice = useCallback(async () => {
    const confirmResult = await confirmRenewInDialog();
    if (!confirmResult) return;

    const createContractRequest = getRenewedContract();
    const creationResult = await createContract({ accountId, createContractRequest }).unwrap();

    if (!creationResult) return;

    const contractId = creationResult.viewOnContract.contract.record.contractId;
    redirectToNewContract(contractId);
  }, [createContract, accountId, confirmRenewInDialog, getRenewedContract, redirectToNewContract]);

  return { handleRenewOutsideVertice, isLoading };
};
