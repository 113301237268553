import { Stack } from '@mui/material';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { SimpleOption, TextFieldCaption } from '@verticeone/design-system';
import FormSelectField2 from '../../../../../../forms/fields/FormSelectField2';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { JiraCreateTaskFormData } from '../JiraCreate/formSchema';
import { JiraProject } from './types';

export type ProjectPickerProps = {
  projects: JiraProject[];
};

export const ProjectPicker = ({ projects }: ProjectPickerProps) => {
  const { t } = useTranslation();
  const id = useId();

  const options = projects.map<SimpleOption>((project) => ({
    value: project.id,
    label: project.name,
  }));

  return (
    <Stack gap={2}>
      <Stack>
        <TextFieldCaption
          label={t('INTELLIGENT_WORKFLOWS.JIRA.PROJECT_PICKER.LABEL')}
          size="XS"
          htmlFor={`${id}-project-id`}
        />
      </Stack>
      <FormSelectField2<JiraCreateTaskFormData, SimpleOption, false>
        options={options}
        boxValue={(value) => options.find((option) => option.value === value)}
        unboxValue={(selectValue) => selectValue?.value}
        color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
        id={`${id}-project-id`}
        isClearable={false}
        name="projectId"
        placeholder={t('INTELLIGENT_WORKFLOWS.JIRA.PROJECT_PICKER.PLACEHOLDER')}
        size="S"
        testId="project-picker"
      />
    </Stack>
  );
};
