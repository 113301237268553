import { LineItem } from '../types';
import { GridRowId, GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { ValidateRowResult } from '../../../../../../components/EditableDataGrid/useEditableDataGrid';
import { convertRowToLineItem, normalizeValue } from '../utils';
import { useTranslation } from 'react-i18next';
import { useSimpleDialogContext } from '@verticeone/design-system';

export type UseLineItemsTableActionsParams<T extends GridValidRowModel> = {
  values: T[];
  onValuesChange: (newValues: T[]) => void;
  getLineItem?: (r: GridRowModel) => T;
  validateLineItem?: (row: GridRowModel<T>) => ValidateRowResult<T>;
};

export type UseLineItemsTableActionsReturn<T extends GridValidRowModel> = {
  validateRow?: (row: T) => { isValid: true; row?: T } | { isValid: false; message: string };
  onErrorsChange?: (errors: string[]) => void;
  onAddRow?: (row: T) => void;
  onUpdateRow?: (row: T) => void;
  onBeforeDeleteRow?: (row: T) => Promise<boolean>;
  onDeleteRow?: (id: GridRowId) => void;
};

export const useLineItemsTableActions = <T extends LineItem>({
  values,
  onValuesChange,
  getLineItem,
  validateLineItem,
}: UseLineItemsTableActionsParams<T>): UseLineItemsTableActionsReturn<T> => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.LINE_ITEMS_TABLE' });
  const { getConfirmation } = useSimpleDialogContext();

  const onAddRow = useCallback(
    (newValue: GridRowModel) => {
      const newLineItem = getLineItem ? getLineItem(newValue) : convertRowToLineItem(newValue);
      onValuesChange([...values, newLineItem as T]);
    },
    [values, onValuesChange, getLineItem]
  );

  const onUpdateRow = useCallback(
    (updatedValue: GridRowModel) => {
      const updatedLineItem = getLineItem ? getLineItem(updatedValue) : convertRowToLineItem(updatedValue);
      onValuesChange(values.map((item) => (item.id === updatedLineItem.id ? (updatedLineItem as T) : item)));
    },
    [values, onValuesChange, getLineItem]
  );

  const onDeleteRow = useCallback(
    (id: GridRowId) => onValuesChange(values.filter((item) => item.id !== id)),
    [values, onValuesChange]
  );

  const validateRow = useCallback(
    (lineItem: T): ValidateRowResult<T> => {
      const description = normalizeValue(lineItem.description ?? '');

      if (!lineItem.lineItemType || !description) {
        return { isValid: false, message: t('ERROR_DIALOG.MISSING_VALUE_MESSAGE') };
      }

      // Keep the external validation at the end
      const result = validateLineItem?.(lineItem);
      if (result && !result.isValid) {
        return result;
      }

      return { isValid: true, row: result?.row ? result.row : { ...lineItem, description } };
    },
    [t, validateLineItem]
  );

  const onBeforeDeleteRow = useCallback(async () => {
    const confirmation = await getConfirmation({
      title: t('REMOVE_DIALOG.TITLE'),
      description: t('REMOVE_DIALOG.MESSAGE'),
      okButton: { label: t('REMOVE_DIALOG.YES') },
    });
    return confirmation.status === 'ok';
  }, [getConfirmation, t]);

  const onErrorsChange = useCallback(
    (errors: string[]) => {
      if (errors.length) {
        void getConfirmation({
          title: t('ERROR_DIALOG.TITLE'),
          description: errors.join(', '),
          cancelButton: { hidden: true },
        });
      }
    },
    [getConfirmation, t]
  );

  return {
    validateRow,
    onErrorsChange,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
  };
};
