import React, { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { IconButton, Text } from '@verticeone/design-system';

type DrawerHeaderProps = {
  onClose: () => void;
  title: string;
};

export const DrawerHeader: FC<DrawerHeaderProps> = ({ onClose, title }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" padding={6} gap={2}>
      <Text variant="heading" size="S" color="text1">
        {title}
      </Text>
      <IconButton variant="outline" size="M" color="neutral" icon={CloseOutlined} onClick={onClose} />
    </Stack>
  );
};

type DrawerContentProps = PropsWithChildren;

export const DrawerContent: FC<DrawerContentProps> = ({ children }) => {
  return (
    <Stack flexGrow={1} minHeight={0} direction="column" p={6} gap={6}>
      {children}
    </Stack>
  );
};

type DrawerBodyProps = PropsWithChildren;

export const DrawerBody: FC<DrawerBodyProps> = ({ children }) => {
  return (
    <Stack height="100%" maxHeight="100%" direction="column">
      {children}
    </Stack>
  );
};
