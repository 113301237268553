import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { ChartLayout, ChartDropDownMenu } from '../ChartCard';
import { useStackedColumnHover } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStackedColumnHover';
import { getSeriesWithBorderRadius } from '@vertice/core/src/components/charts/highcharts-specific/utils/graphBorderRadiusUtils';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { useTimeToCompleteTaskContext } from './TimeToCompleteTaskContext';
import { useTranslation } from 'react-i18next';
import { FilterOptions } from './useTimeToCompleteTaskData';
import {
  cssObjectToString,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useTheme } from '@mui/material';

type TimeToCompleteTaskGraphProps = {
  series: SeriesOptionsWithData[];
  categories: string[];
  filterOptions: FilterOptions;
  isEmpty?: boolean;
};

const TimeToCompleteTaskGraph = ({ categories, series, filterOptions, isEmpty }: TimeToCompleteTaskGraphProps) => {
  const [setChartRef] = useChartRef();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const applyStackedColumnHover = useStackedColumnHover();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { palette } = useTheme();

  const { filter, setTooltipData, updateFilterValue } = useTimeToCompleteTaskContext();

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        applyStackedColumnHover,
        mergeOptions({
          chart: {
            type: 'column',
            height: 376,
            marginTop: 30,
          },
          plotOptions: {
            column: {
              maxPointWidth: 32,
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    const categoryData = this.series.chart.series.reduce((acc, item) => {
                      acc[`${item.userOptions.id}`] = item.points[point.index].y ?? 0;
                      return acc;
                    }, {} as Record<string, number>);

                    setTooltipData({
                      values: {
                        ...categoryData,
                      },
                      category: point.category,
                      total: Object.values(categoryData).reduce((acc, value) => acc + value, 0),
                    });
                  },
                  mouseOut() {
                    setTooltipData(null);
                  },
                },
              },
            },
          },
          yAxis: {
            ...(isEmpty
              ? {
                  min: 0,
                  max: 50,
                }
              : {}),
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`,
            },
          },
          xAxis: {
            categories,
            labels: {
              formatter: ({ value }: { value: number | string }) => `${value}d`,
            },
          },
          tooltip: { shared: true, enabled: false },
          series: [...(getSeriesWithBorderRadius(series, 4) as SeriesOptionsType[])],
        }),
      ]),
    [
      applyXAxisOffset,
      applyStyledHighcharts,
      applyStackedColumnHover,
      isEmpty,
      categories,
      series,
      setTooltipData,
      palette,
    ]
  );

  return (
    <ChartLayout
      filterItems={[
        <ChartDropDownMenu
          key="request-type"
          label={t('TIME_TO_COMPLETE_TASK.FILTER.REQUEST_TYPE.LABEL')}
          placeholder={filter.requestType || t('TIME_TO_COMPLETE_TASK.FILTER.REQUEST_TYPE.PLACEHOLDER')}
          items={filterOptions.requestType.map((requestType) => ({ id: requestType, label: requestType }))}
          onChange={(item) => {
            updateFilterValue('requestType', item.label);
          }}
        />,
        <ChartDropDownMenu
          key="task-type"
          label={t('TIME_TO_COMPLETE_TASK.FILTER.TASK_TYPE.LABEL')}
          placeholder={filter.taskType || t('TIME_TO_COMPLETE_TASK.FILTER.TASK_TYPE.PLACEHOLDER')}
          items={filterOptions.taskType.map((requestType) => ({ id: requestType, label: requestType }))}
          onChange={(item) => {
            updateFilterValue('taskType', item.label);
          }}
        />,
      ]}
    >
      <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
    </ChartLayout>
  );
};

export default TimeToCompleteTaskGraph;
