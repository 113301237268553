import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, TextFieldArea } from '@verticeone/design-system';

type TaskOutcomeDetailsProps = {
  success: boolean;
  statusCode: string;
  headers?: object;
  body?: object | string;
};

const TaskOutcomeDetails = ({ success, statusCode, headers, body }: TaskOutcomeDetailsProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.TASK_MODAL' });

  return (
    <Stack minHeight={0} overflow="hidden" height="100%">
      <Stack gap={4} p={6}>
        <Stack alignItems="center" width="100%" overflow="auto">
          <Text variant="heading" size="M">
            {success ? t('TASK_COMPLETE_HEADING') : t('TASK_COMPLETION_FAILED')}
          </Text>
        </Stack>
        <Text variant="body-bold" size="M">
          {t('STATUS_LABEL')}:{' '}
          <Text variant="body-bold" size="M" color={success ? 'success1' : 'error1'}>
            {t(`STATUSES.${success ? 'SUCCESS' : 'FAILED'}`)}
          </Text>
        </Text>
        <Text variant="body-bold" size="M">
          {t('STATUS_CODE')}:{' '}
          <Text variant="body-bold" size="M">
            {statusCode}
          </Text>
        </Text>
        <Stack gap={2}>
          <Text variant="body-bold" size="M">
            {t('RESPONSE.HEADERS')}:
          </Text>
          <TextFieldArea
            size="S"
            color="primary"
            variant="solid"
            disabled
            value={headers ? JSON.stringify(headers, null, 2) : ''}
          />
        </Stack>
        <Stack gap={2}>
          <Text variant="body-bold" size="M">
            {t('RESPONSE.BODY')}:
          </Text>
          <TextFieldArea
            size="S"
            color="primary"
            variant="solid"
            disabled
            value={typeof body === 'object' ? JSON.stringify(body, null, 2) : body}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TaskOutcomeDetails;
