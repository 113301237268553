import { RollingFrequencyFieldMetadata, RollingFrequencyFieldType, rollingFrequencyFieldMetadataSchema } from './types';
import { DialogContent } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { useTranslation } from 'react-i18next';
import { DeepPartial, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTypedFormEntry } from '../../../fields/FormEntry';
import FormTextField from '../../../fields/FormTextField';
import FormTextArea from '../../../fields/FormTextArea';
import { FormToggleSwitchField } from '../../../fields/FormToggleSwitchField';
import { FieldEditDialog } from '../../DynamicFormBuilder';
import { fieldDef } from './index';
import { BuilderDialogProps, BuilderPluginValues, BuilderPlugins, FormWithPlugins } from '../types';
import { addPluginsToDefaultData, addPluginsToSchema } from '../utils';

type DropdownFieldBuilderDialogProps<T extends BuilderPlugins> = BuilderDialogProps<RollingFrequencyFieldType, T>;

const DEFAULT_VALUES: DeepPartial<RollingFrequencyFieldMetadata> = {
  label: '',
  required: false,
  description: undefined,
};

const IW_COLOR = INTELLIGENT_WORKFLOWS_BRAND_COLOR;

const FieldMetadataEntry = createTypedFormEntry<RollingFrequencyFieldMetadata>();

export const RollingFrequencyFieldBuilderDialog = <T extends BuilderPlugins>(
  props: DropdownFieldBuilderDialogProps<T>
) => {
  const { onClose, onSubmit, defaultValues, plugins } = props;
  const { t } = useTranslation();

  const form = useForm<FormWithPlugins<RollingFrequencyFieldMetadata>>({
    defaultValues: addPluginsToDefaultData(defaultValues ?? DEFAULT_VALUES, plugins),
    resolver: zodResolver(addPluginsToSchema(rollingFrequencyFieldMetadataSchema, plugins)),
    mode: 'all',
  });

  const submitHandler: SubmitHandler<FormWithPlugins<RollingFrequencyFieldMetadata>> = (values) => {
    if (onSubmit) {
      const { plugins: outputs, ...metadata } = values;
      onSubmit(metadata, outputs as BuilderPluginValues<T>);
    }
  };

  return (
    <FieldEditDialog
      form={form}
      schema={rollingFrequencyFieldMetadataSchema}
      onSubmit={form.handleSubmit(submitHandler)}
    >
      <FieldEditDialog.Header mode={defaultValues ? 'edit' : 'create'} titleSuffix={t(fieldDef.typeNameI18nKey)}>
        <FieldMetadataEntry
          name="required"
          component={FormToggleSwitchField}
          componentProps={{
            label: t('DYNAMIC_FORM_BUILDER.REQUIRED_FIELD'),
            color: IW_COLOR,
          }}
        />
      </FieldEditDialog.Header>
      <DialogContent>
        <FieldMetadataEntry
          name="label"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.FIELD_LABEL')}
          component={FormTextField}
          componentProps={{ color: IW_COLOR, inputProps: { maxLength: 40 } }}
        />
        <FieldMetadataEntry
          name="description"
          label={t('DYNAMIC_FORM_BUILDER.DIALOG.LABEL.HELPTEXT')}
          component={FormTextArea}
          componentProps={{ color: IW_COLOR, size: 'M', variant: 'outline' }}
        />
        {plugins?.map(({ Component }, index) => (
          <Component key={index} name={`plugins.${index}`} fieldType="ROLLING_FREQUENCY" />
        ))}
      </DialogContent>
      <FieldEditDialog.Footer isFormValid={form.formState.isValid} onClose={onClose} />
    </FieldEditDialog>
  );
};
