import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle } from '@verticeone/design-system';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { GenericTemplateIcon, GenericWorkflowIcon } from '../../components/icons/Icons';
import { ServiceCard } from '../components/ServiceCardsV1/ServiceCard';
import { COMING_SOON_TEMPLATES } from './constants';
import { useServiceCardsV1 } from '../hooks/useServiceCardsV1';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { createServiceIdentifier, isSaasRequestForProposal } from '../../../../hooks/workflows/refUtils';
import { FEATURES } from '../../../features/constants';
import { useFeatures } from '../../../features/useFeatures';

export const ServicesPage: FC = () => {
  const { t } = useTranslation();
  const routes = useRoutes();
  const { generatePathForRoute } = useRouteNavigate();
  const { getFeatureProperty } = useFeatures();
  const requestForProposalEnabled = getFeatureProperty<boolean>(
    FEATURES.INTELLIGENT_WORKFLOWS,
    'requestForProposalsWorkflow'
  );

  const serviceCards = useServiceCardsV1({
    getTargetPath: (serviceRef) => {
      return generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE, {
        serviceId: createServiceIdentifier(serviceRef),
      });
    },
    filter: (service) => (!isSaasRequestForProposal(service.urn) || requestForProposalEnabled) ?? true,
  });

  return (
    <IntelligentWorkflowsLayout>
      <Stack gap={8}>
        <Card>
          <CardHeader size="S">
            <CardHeaderTitle
              text={t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.TITLE')}
              icon={GenericWorkflowIcon}
            />
            <CardHeaderDescription>
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.DESCRIPTION_1')}
              <br />
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SCENARIOS.DESCRIPTION_2')}
            </CardHeaderDescription>
          </CardHeader>
          <Stack padding={6} direction="row" flexWrap="wrap" gap={2}>
            {serviceCards}
          </Stack>
        </Card>

        <Card>
          <CardHeader size="S">
            <CardHeaderTitle
              text={t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.TITLE')}
              icon={GenericTemplateIcon}
            />
            <CardHeaderDescription>
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.TEMPLATES.DESCRIPTION')}
            </CardHeaderDescription>
          </CardHeader>
          <Stack padding={6} direction="row" flexWrap="wrap" gap={2}>
            {COMING_SOON_TEMPLATES.sort((a, b) => a.name.localeCompare(b.name)).map((resource) => (
              <ServiceCard key={resource.urn} service={resource} inactive />
            ))}
          </Stack>
        </Card>
      </Stack>
    </IntelligentWorkflowsLayout>
  );
};
