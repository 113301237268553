import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorDrawerGroup } from '../../../EditorDrawer';
import { IssueTypePicker } from '../JiraCommon/IssueTypePicker';
import { VariableMapper, VariableMapperBox } from '../../../VariableMapper/VariableMapper';
// import { Attachments } from './Attachments';
import { JiraCreateTaskFormData } from './formSchema';
import { JiraCreateFormProps } from './JiraCreateForm';
import { createJiraVariablesGroups } from '../JiraCommon/utils';
import { NoJiraVariables } from '../JiraCommon/NoJiraVariables';
import { IntegrationWrapper, IntegrationWrapperBaseProps } from '../JiraCommon/IntegrationWrapper';
import { ProjectPicker } from '../JiraCommon/ProjectPicker';
import { useFormContext, useWatch } from 'react-hook-form';

type JiraCreateSetupProps = Pick<
  JiraCreateFormProps,
  'projectPickerProps' | 'issueTypePickerProps' | 'variableMapperProps'
> & {
  integrationWrapperProps: IntegrationWrapperBaseProps;
};

export const JiraCreateSetup: FC<JiraCreateSetupProps> = ({
  projectPickerProps,
  issueTypePickerProps,
  variableMapperProps,
  integrationWrapperProps,
}) => {
  const { t } = useTranslation();
  const { control, setValue, getFieldState } = useFormContext<JiraCreateTaskFormData>();

  const projectId = useWatch({ control, name: 'projectId' });

  useEffect(() => {
    const projectStatus = getFieldState('projectId');
    if (projectStatus.isDirty || projectStatus.isTouched) {
      setValue('issueType', '');
    }
  }, [projectId, setValue, getFieldState]);

  return (
    <IntegrationWrapper {...integrationWrapperProps}>
      <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.JIRA_ISSUE_SETUP')}>
        <ProjectPicker {...projectPickerProps} />
        <IssueTypePicker {...issueTypePickerProps} />
        {!issueTypePickerProps.isLoadingIssueTypes && (
          <VariableMapperBox>
            <VariableMapper<JiraCreateTaskFormData>
              formFieldName="variables"
              requiredFormFieldName="requiredVariables"
              otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER')}
              fromWorkflow={true}
              variableMapperRowProps={{ createOtherVariableGroups: createJiraVariablesGroups }}
              components={{ noOtherVariables: <NoJiraVariables /> }}
              hasRichSelector={{ requiredRows: true }}
              {...variableMapperProps}
            />
          </VariableMapperBox>
        )}
        {/* TODO: Add attachments feature once it's fully supported on WF side */}
        {/* <Attachments /> */}
      </EditorDrawerGroup>
    </IntegrationWrapper>
  );
};
