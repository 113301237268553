import { useMemo } from 'react';
import { ContractReviewMappingRecord, EditUserTaskFormData } from '../../forms/EditUserTaskForm/schema';
import { TaskDefinition } from '../../../../definitionsTypes';
import {
  getIOMappingConfigurations,
  getTaskAssignmentConfigurations,
  getTaskFormConfiguration,
  isDueDateConfiguration,
} from '../../../../definitions/taskDefinition';
import {
  getConditionalAssigneesFromAssignmentConfigurations,
  getDefaultAssigneesFromAssignmentConfigurations,
} from '../../forms/EditUserTaskForm/assignmentOptions';
import { getObjectFromJMESPathExpression } from '../../hooks/utils';
import { isApprovalFormUrn } from '../../forms/EditUserTaskForm/utils';
import { CatalogResourceCamelCase, FormDefinitionCamelCase } from '@vertice/slices/src/api/enhancedCatalogAPI';
import { isFormCatalogResource } from '../../../../utils/catalogResourceUtils';
import { RESERVED_VARIABLES } from './useTaskFormSubmit';
import { getDurationFromDueDateConfiguration } from '../../../../definitions/utils';
import { isContractReviewFormUrn } from '../../forms/EditUserTaskForm/ContractReviewForm';

const TASK_FORM_DEFAULT_VALUES: EditUserTaskFormData = {
  name: '',
  description: '',
  dueIn: null, // days
  isSimpleApprovalForm: false,
  buttonLabels: undefined,
  formHeading: undefined,
  defaultAssignees: [],
  conditionalAssignees: [],
  customFormFields: undefined,
  customFormVariables: undefined,
};

export type UseTaskFormDefaultValuesProps = {
  task?: TaskDefinition;
  customForm?: CatalogResourceCamelCase;
};

export const useTaskFormDefaultValues = (props: UseTaskFormDefaultValuesProps) => {
  const { task, customForm } = props;

  const values = useMemo<EditUserTaskFormData>(() => {
    let defaultValues = TASK_FORM_DEFAULT_VALUES;

    if (!task) return defaultValues;

    defaultValues = attachTaskProperties(defaultValues, task);
    defaultValues = attachTaskAssignees(defaultValues, task);
    defaultValues = attachApprovalTaskConfig(defaultValues, task);
    defaultValues = attachCustomFormConfig(defaultValues, task, customForm?.definition);
    defaultValues = attachContractReviewFormConfig(defaultValues, task);

    return defaultValues;
  }, [task, customForm]);

  return { values };
};

const attachTaskProperties = (baseDefaults: EditUserTaskFormData, { task }: TaskDefinition): EditUserTaskFormData => {
  const dueDateConfiguration = task.configurations?.find(isDueDateConfiguration);

  return {
    ...baseDefaults,
    name: task.name ?? '',
    description: task.description ?? '',
    dueIn: dueDateConfiguration ? getDurationFromDueDateConfiguration(dueDateConfiguration).days : baseDefaults.dueIn,
  };
};

const attachTaskAssignees = (baseDefaults: EditUserTaskFormData, task: TaskDefinition): EditUserTaskFormData => {
  const assignmentConfigurations = getTaskAssignmentConfigurations(task);

  const defaultAssignees = getDefaultAssigneesFromAssignmentConfigurations(assignmentConfigurations);
  const conditionalAssignees = getConditionalAssigneesFromAssignmentConfigurations(assignmentConfigurations);

  return { ...baseDefaults, defaultAssignees, conditionalAssignees };
};

const attachApprovalTaskConfig = (baseDefaults: EditUserTaskFormData, task: TaskDefinition): EditUserTaskFormData => {
  const form = getTaskFormConfiguration(task);
  const isApproval = form && isApprovalFormUrn(form.formUrn);

  if (!isApproval) return baseDefaults;

  let defaultValues: EditUserTaskFormData = { ...baseDefaults, isSimpleApprovalForm: true };
  const ioMapping = getIOMappingConfigurations(task);

  if (!ioMapping) return defaultValues;

  const buttonLabelMapping = ioMapping.mapping.inputFields.find((field) => field.name === 'buttonLabels')?.value;
  const buttonLabels = getObjectFromJMESPathExpression<EditUserTaskFormData['buttonLabels']>(buttonLabelMapping);

  const formHeadingMapping = ioMapping.mapping.inputFields.find((field) => field.name === 'formHeading')?.value;
  const formHeading = getObjectFromJMESPathExpression<EditUserTaskFormData['formHeading']>(formHeadingMapping);

  return { ...defaultValues, buttonLabels: buttonLabels ?? undefined, formHeading: formHeading ?? undefined };
};

const attachCustomFormConfig = (
  baseDefaults: EditUserTaskFormData,
  task: TaskDefinition,
  form?: FormDefinitionCamelCase
): EditUserTaskFormData => {
  if (!form || !isFormCatalogResource(form)) return baseDefaults;

  let customFormVariables = {};
  const customFormFields = form?.form?.formUiModel?.layout?.fields ?? [];

  const ioMapping = getIOMappingConfigurations(task);

  if (ioMapping) {
    customFormVariables = ioMapping.mapping.outputFields
      .filter((outField) => !RESERVED_VARIABLES.includes(outField.name))
      .filter((outField) => outField.mappingType === 'JMESPathExpression')
      .reduce((prev, curr) => ({ ...prev, [curr.value]: curr.name }), {});
  }

  return { ...baseDefaults, customFormFields, customFormVariables };
};

const attachContractReviewFormConfig = (
  baseDefaults: EditUserTaskFormData,
  task: TaskDefinition
): EditUserTaskFormData => {
  const formUrn = getTaskFormConfiguration(task)?.formUrn;
  if (!formUrn || !isContractReviewFormUrn(formUrn)) return baseDefaults;

  const ioMapping = getIOMappingConfigurations(task);
  if (!ioMapping) return { ...baseDefaults, contractReviewMapping: [] };

  const mapping: ContractReviewMappingRecord[] = ioMapping.mapping.inputFields.map((field) => {
    const target: ContractReviewMappingRecord['target'] = { type: 'Variable', value: field.name };

    return getValueType(field.value) === 'Variable'
      ? { target, source: { type: 'Variable', value: field.value } }
      : { target, source: { type: 'Const', value: parseConstant(field.value) } };
  });

  return { ...baseDefaults, contractReviewMapping: mapping };
};

const getValueType = (value: string): 'Const' | 'Variable' => {
  return value.startsWith('`') && value.endsWith('`') ? 'Const' : 'Variable';
};

const parseConstant = <R extends string>(value: R | undefined): R => {
  return value?.replace(/^.(.*).$/s, '$1') as R;
};
