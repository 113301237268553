import { useMemo } from 'react';
import { LinearFormStep } from './useLinearStepper';
import { Task } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

const STEPS_KEY = '__visited_steps';
const CURRENT_STEP_KEY = '__current_step';

type DraftSteps = Record<string, Pick<LinearFormStep, 'visited' | 'valid'>>;

export type UseStepperWithDraftProps = {
  activeStepId: string | null;
  steps: LinearFormStep[];
};

export const useStepperWithDraft = (props: UseStepperWithDraftProps) => {
  const { activeStepId, steps } = props;
  return useMemo(
    () => ({
      [STEPS_KEY]: steps.reduce<DraftSteps>((final, { id, visited, valid }) => {
        final[id] = { visited: visited, valid };
        return final;
      }, {}),
      [CURRENT_STEP_KEY]: activeStepId,
    }),
    [activeStepId, steps]
  );
};

export type UseLinearStepperInitialsFromDraftProps = {
  taskDraft: Task['draft'];
  defaultSteps: LinearFormStep[];
};

export const useLinearStepperInitialsFromDraft = (props: UseLinearStepperInitialsFromDraftProps) => {
  const { taskDraft, defaultSteps } = props;
  return useMemo(() => {
    const draftSteps = taskDraft?.[STEPS_KEY];
    return {
      formSteps: defaultSteps.map((step) => ({ ...step, ...(draftSteps?.[step.id] || {}) })),
      initStep: taskDraft?.[CURRENT_STEP_KEY],
    };
  }, [taskDraft, defaultSteps]);
};
