import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { getFullName } from '@verticeone/utils/formatting';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { createUsersMap, resolveSSOUserStatus } from '@vertice/components/src/UserList/utils';
import { UserList, ModalWindow, columnName } from '@vertice/components';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import {
  AccountUser,
  useGetSsoDetailsQuery,
  useListAccountUsersQuery,
  useRefreshUserInvitationMutation,
} from '@vertice/slices';
import StepsIndicator from './StepsIndicator';
import styles from './SamlIntegrationTeamMembers.module.scss';

const ssoColumnName: columnName = 'ssoStatus';
const disabledClass = 'disabled';

export const ALL_SSO_STATES = {
  inactive: 'INACTIVE',
  active: 'ACTIVE',
  provisioning: 'PROVISIONING',
  error: 'ERROR',
  waitingForManifest: 'WAITING_FOR_MANIFEST',
  waitingForDomains: 'WAITING_FOR_DOMAINS',
};

const ssoStatusClassMap: Record<string, string> = {
  ACTIVE: 'enabled',
  INACTIVE: 'default',
  PENDING: 'active',
  ERROR: 'error',
};

const SamlIntegrationTeamMembers = () => {
  const { t, i18n } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { navigate: newNavigate } = useRouteNavigate();
  const routes = useRoutes();
  const toDomainsStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_SSO_DOMAIN}`);
  const onDoneClick = () => newNavigate(routes.INTEGRATIONS);
  const [selectedUsers, setSelectedUsers] = useState<Array<AccountUser>>([]);
  const [openedInviteModal, setOpenedInviteModal] = useState(false);
  const openInviteModal = () => setOpenedInviteModal(true);
  const closeInviteModal = () => setOpenedInviteModal(false);
  const { data } = useListAccountUsersQuery(
    { accountId: accountId! },
    { skip: !accountId, refetchOnMountOrArgChange: false }
  );
  const usersMap = createUsersMap(data?.users || []);

  const [migrateUserSso, { isLoading: isUserSsoMigrating }] = useRefreshUserInvitationMutation();
  const { isEnabled, isLoading: isFeatureLoading } = useFeatures();
  const ssoEnabled = isEnabled(FEATURES.SSO);
  const { data: ssoDetails, isFetching: isLoadingSsoDetails } = useGetSsoDetailsQuery(
    { accountId: accountId! },
    { skip: !accountId || !ssoEnabled || isFeatureLoading }
  );

  const includesDomain = (domain: string) => (ssoDetails?.properties?.registeredDomains ?? []).includes(domain);
  const extractDomain = (email?: string) => (email || '').toLowerCase().split('@').pop();
  const hasMatchingDomain = (domain?: string) => !ssoEnabled || (domain ? includesDomain(domain) : false);
  const isSSOActive = () => {
    const ssoStatus = ssoDetails?.status ?? '';
    return !ssoStatus || ssoStatus === ALL_SSO_STATES.active;
  };

  const onUsersToSsoMigrateChange = (users: Array<AccountUser>) => setSelectedUsers(users);

  const handleInviteSelected = () => {
    const migrateUserMap = selectedUsers.map(({ userId }) =>
      migrateUserSso({
        accountId: accountId!,
        userId: userId!,
        body: {},
      }).unwrap()
    );

    Promise.all(migrateUserMap)
      .then(() => {
        enqueueSnackbar(t('SAML.TEAM_MEMBERS.USER_MIGRATE_TO_SSO_SUCCESS'), {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar(t('SAML.TEAM_MEMBERS.USER_MIGRATE_TO_SSO_ERROR'), {
          variant: 'error',
        });
      })
      .finally(() => closeInviteModal());
  };

  const ssoStatusCell = (params: GridRenderCellParams<AccountUser, AccountUser>) => {
    const { userStatus, directoryStatus, email } = params.row;
    const ssoUserStatus = resolveSSOUserStatus(userStatus, directoryStatus);
    const local = `PREFERENCES.WORKFLOW.USER_TABLE.SSO_STATUS_MAP.${ssoUserStatus}`;
    const particularClass = ssoStatusClassMap[ssoUserStatus!] || disabledClass;
    const domainMismatch = !hasMatchingDomain(extractDomain(email));

    return (
      <Box className={clsx(styles['sso-cell'])}>
        <Box className={clsx(styles['sso-status-container'])}>
          <Typography className={clsx(styles['sso-status'], styles[particularClass])} variant="body-regular-m">
            {i18n.exists(local) ? t(local) : t('UNKNOWN')}
          </Typography>
        </Box>
        {domainMismatch && (
          <Box className={clsx(styles['domain-mismatch'])}>
            <Tooltip
              title={
                <Typography variant="body-regular-m" color="var(--tokens-color-dark-text-2)">
                  {t('AUTH.ERRORS.DOMAIN_MISMATCH')}
                </Typography>
              }
              placement="top"
              arrow
              disableInteractive
            >
              <InfoIcon className="info-icon" />
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  const ssoStatusColumn: GridColDef = {
    field: ssoColumnName,
    headerName: t('PREFERENCES.WORKFLOW.USER_TABLE.COLUMNS.SSO_STATUS'),
    renderCell: ssoStatusCell,
    flex: 1,
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.TEAM_MEMBERS.TITLE')}</Typography>

        <Button
          variant="contained"
          className={clsx(styles['invite-selected-button'], styles.uppercase)}
          onClick={openInviteModal}
          disabled={!selectedUsers.length}
        >
          {t('SAML.TEAM_MEMBERS.INVITE_BUTTON')}
        </Button>
      </div>

      <div className={styles['inner-body']}>
        <div className={styles.description}>{t('SAML.TEAM_MEMBERS.BODY')}</div>

        <UserList
          allowedColumns={['handler', 'userName', 'email', 'userRole', ssoColumnName]}
          onChange={onUsersToSsoMigrateChange}
          multiple
          withActions
          hideProxyContacts
          extraColumns={[ssoStatusColumn]}
          rowControlProps={{
            rowControlDisabled: (params: GridRenderCellParams<AccountUser, AccountUser>) => {
              const { directoryStatus, email } = params.row;
              const alreadyMigrated = directoryStatus && directoryStatus.startsWith('ACTIVE_SSO');
              const domainMatches = hasMatchingDomain(extractDomain(email));
              return !isSSOActive() || alreadyMigrated || !domainMatches;
            },
            rowClassName: (params: GridRowParams) => {
              const { directoryStatus, email } = params.row;
              return !['INACTIVE', 'NOT_APPLICABLE'].includes(directoryStatus) &&
                hasMatchingDomain(extractDomain(email))
                ? ''
                : `Mui-${disabledClass}`;
            },
            dataLoading: () => isLoadingSsoDetails,
          }}
        />

        <div className={styles['nav-buttons']}>
          <Button onClick={toDomainsStep} className={styles['nav-button']} variant="outlined">
            {t('SAML.BACK')}
          </Button>
          <Button onClick={onDoneClick} className={styles['nav-button']} variant="contained">
            {t('SAML.DONE')}
          </Button>
        </div>
      </div>

      <div className={styles.steps}>
        <StepsIndicator total={5} current={5} />
      </div>

      <ModalWindow
        closeButton={false}
        title={
          <Typography variant="heading-s" className={styles['invite-modal-title']}>
            {t('SAML.TEAM_MEMBERS.INVITE_MODAL.TITLE')}
          </Typography>
        }
        content={
          <>
            <Typography variant="heading-xs" className={styles['invite-modal-subtitle']}>
              {t('SAML.TEAM_MEMBERS.INVITE_MODAL.SUBTITLE')}
            </Typography>
            <div className={clsx(styles['invite-modal'], styles['selected-wrap'])}>
              <Typography variant="body-regular-m">{t('SAML.TEAM_MEMBERS.INVITE_MODAL.SELECTED_CHAPTER')}</Typography>
              <>
                {selectedUsers.map((user) => (
                  <li key={user.userId}>
                    <Typography variant="body-regular-m">
                      {getFullName(usersMap.get(user.userId)) || usersMap.get(user.userId)?.userName}
                    </Typography>
                  </li>
                ))}
              </>
            </div>
          </>
        }
        open={openedInviteModal}
        actions={[
          <Button
            size="small"
            variant="outlined"
            className={styles.uppercase}
            onClick={closeInviteModal}
            key="cancel-action"
          >
            {t('SAML.TEAM_MEMBERS.INVITE_MODAL.CANCEL_BUTTON')}
          </Button>,

          <LoadingButton
            loading={isUserSsoMigrating}
            size="small"
            variant="contained"
            className={styles.uppercase}
            onClick={handleInviteSelected}
            key="confirm-action"
          >
            {t('SAML.TEAM_MEMBERS.INVITE_MODAL.CONFIRM_BUTTON')}
          </LoadingButton>,
        ]}
      />
    </div>
  );
};

export default SamlIntegrationTeamMembers;
