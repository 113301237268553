import { GridRowModel } from '@mui/x-data-grid-pro';
import { LineItem } from './types';

export const normalizeValue = (value: string) => value.trim().replace(/\s\s+/g, ' ');

export const convertRowToLineItem = (newRow: GridRowModel): LineItem => {
  return {
    id: newRow.id,
    lineItemType: newRow.lineItemType,
    quantity: newRow.quantity,
    description: newRow.description,
  };
};
