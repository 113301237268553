import type { FunctionComponent, SVGProps } from 'react';
import {
  MsDynamicsIcon,
  OracleNetSuiteIcon,
  QuickBooksIcon,
  VerticeMsDynamicsIntegration,
  VerticeNetsuiteIntegration,
  VerticeQuickbooksIntegration,
  VerticeXeroIntegration,
  XeroIcon,
} from '@vertice/assets';
import { FEATURES } from '@vertice/core/src/modules/features/constants';

type Provider = {
  mergeId: string;
  translationId: string;
  providerCode: string;
  feature: (typeof FEATURES)[keyof typeof FEATURES];
  icon: {
    small: FunctionComponent<SVGProps<SVGSVGElement>>;
    big: FunctionComponent<SVGProps<SVGSVGElement>>;
  };
};

type Providers = 'netsuite' | 'quickbooks' | 'xero' | 'msDynamics';

export const providers: Record<Providers, Provider> = {
  netsuite: {
    mergeId: 'netsuite',
    translationId: 'NETSUITE',
    providerCode: 'Provider:Merge:Accounting:Netsuite',
    feature: FEATURES.INTEGRATION_NETSUITE,
    icon: {
      small: OracleNetSuiteIcon,
      big: VerticeNetsuiteIntegration,
    },
  },
  quickbooks: {
    mergeId: 'quickbooks-online',
    translationId: 'QUICK_BOOKS',
    providerCode: 'Provider:Merge:Accounting:Quickbooks',
    feature: FEATURES.INTEGRATION_QUICKBOOKS,
    icon: {
      small: QuickBooksIcon,
      big: VerticeQuickbooksIntegration,
    },
  },
  xero: {
    mergeId: 'xero',
    translationId: 'XERO',
    providerCode: 'Provider:Merge:Accounting:Xero',
    feature: FEATURES.INTEGRATION_XERO,
    icon: {
      small: XeroIcon,
      big: VerticeXeroIntegration,
    },
  },
  msDynamics: {
    mergeId: 'microsoft-dynamics-365-business-central',
    translationId: 'MS_DYNAMICS',
    providerCode: 'Provider:Merge:Accounting:MsDynamics',
    feature: FEATURES.INTEGRATION_MS_DYNAMICS,
    icon: {
      small: MsDynamicsIcon,
      big: VerticeMsDynamicsIntegration,
    },
  },
};

export const buildProviderCode = (providerCode: string, instance: number) =>
  // for the first instance, use code without _number, to be compatible with already existing integrations
  // for multiple instances, number is appended to the provider code to be unique, -1 because indexing starts from 1
  instance === 1 ? providerCode : `${providerCode}_${instance - 1}`;
