import React, { useEffect, useState } from 'react';
import { Box, Stack, styled, ToggleButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ListVendorsApiArg, useLazyListVendorsQuery } from '@vertice/slices';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import { Loader } from '@vertice/components';
import { Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { NewPurchaseFormData } from '../../types';

type VendorChipButtonProps = {
  onClick?: () => void;
};

type VendorOption = {
  id: string;
  name: string;
  products: string[];
};

const StyledToggleButton = styled(ToggleButton)(({ theme: { palette } }) => ({
  ':hover': {
    backgroundColor: palette.core.color2,
  },
  color: palette.primary.color2,
  backgroundColor: palette.core.color1,
  borderRadius: '8px',
}));

export const VendorChipButton = ({ onClick }: VendorChipButtonProps) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<NewPurchaseFormData>();
  const [vendors, setVendors] = useState<VendorOption[]>([]);

  const [findVendorsTrigger, { isFetching: isVendorsFetching }] = useLazyListVendorsQuery();
  const lazyLoadVendorOptions = ({ limit }: ListVendorsApiArg) =>
    findVendorsTrigger({ limit, sortOrder: 'asc' }).then(
      (response) =>
        response.data?.map((vendor) => {
          const option: VendorOption = {
            id: vendor.id!,
            name: vendor.name!,
            products: vendor.products?.map((product) => product.name!) || [],
          };
          return option;
        }) || []
    );

  useEffect(() => {
    lazyLoadVendorOptions({ limit: 5 })
      .then((loadedVendors) => setVendors(loadedVendors))
      .catch(() => setVendors([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isVendorsFetching) {
    return <Loader />;
  }

  return (
    <Stack direction="column" gap={2} width="100%">
      {vendors.length === 0
        ? 'nothing found'
        : vendors.map((option) => (
            <StyledToggleButton
              sx={{ width: '100%', justifyContent: 'left' }}
              key={option.id}
              value={option.id}
              onClick={() => {
                setValue('vendor', { id: option.id, name: option.name, type: 'PREDEFINED' });
                onClick?.();
              }}
              disableFocusRipple
              disableRipple
            >
              <Stack direction="row" gap={3} alignItems="center">
                <Box>
                  <VendorLogo vendorId={option.id} size={25} logoOnly />
                </Box>
                <Stack direction="column" gap={1} sx={{ textAlign: 'left' }}>
                  <Text variant="body-regular" size="M" color="text1">
                    {option.name}
                  </Text>

                  <Text variant="caption" size="XS" color="text3">
                    {option.products.slice(0, 3).join(', ')}
                    {option.products.length > 3
                      ? t('INTAKE_FORM.NEW_PURCHASE_SELECT_VENDOR.AND_COUNT_MORE_PRODUCTS', {
                          count: option.products.length - 3,
                        })
                      : null}
                  </Text>
                </Stack>
              </Stack>
            </StyledToggleButton>
          ))}
    </Stack>
  );
};
