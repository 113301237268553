import { useMemo } from 'react';
import { useListVendorsPageContractsPaginatedQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { chain, orderBy } from 'lodash';
import { ContractsTableItem } from './types';

export type UseVendorContractsDataParams = {
  vendorId: string;
};

export type UseVendorContractsDataReturn = {
  data?: ContractsTableItem[];
  isLoading: boolean;
};

const useVendorContractsData = ({ vendorId }: UseVendorContractsDataParams): UseVendorContractsDataReturn => {
  const { accountId } = useAccountContext();
  const { data } = useListVendorsPageContractsPaginatedQuery({
    accountId,
    vendorId,
  });

  const contracts = useMemo(() => {
    if (!data?.contracts) {
      return undefined;
    }

    return chain(data.contracts)
      .groupBy((view) => view.lineageId)
      .map((contractsInLineage, lineageId) => {
        const orderedContractItems = orderBy(
          contractsInLineage,
          (contractData) => contractData.computed?.chronologicalLineageOrder,
          'desc'
        );

        // category item represents a parent row for every lineage
        const categoryItem = { lineageId: lineageId! };

        // For each lineage add single item per lineage and all contract items
        return [categoryItem, ...orderedContractItems];
      })
      .orderBy((item) => item[0].lineageId) // Sort lineages to keep the order always the same
      .map(([categoryItem, ...contractItems], index) => [{ ...categoryItem, ord: index + 1 }, ...contractItems])
      .flatten()
      .value();
  }, [data?.contracts]);

  return {
    data: contracts,
    isLoading: !contracts,
  };
};

export default useVendorContractsData;
