import { useTranslation } from 'react-i18next';
import { AddExistingContractFormEntry } from '../../types';
import React from 'react';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';

export const OneOffContractFields = () => {
  const { t } = useTranslation();
  return (
    <>
      <AddExistingContractFormEntry
        name="signDate"
        label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
        component={FormDateField}
      />
      <AddExistingContractFormEntry
        name="startDate"
        label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        component={FormDateField}
        required
      />
    </>
  );
};
