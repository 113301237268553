import { GridCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { LineItem, LineItemType } from '../types';
import { useTranslation } from 'react-i18next';
import { TextField, useDataGridContext } from '@verticeone/design-system';
import { Box } from '@mui/material';
import { useInputFocusableRef } from '../../../../../../hooks/useInputFocusableRef';

export const EditableDescriptionCell = ({ id, value, field, hasFocus }: GridCellParams<LineItem, LineItemType>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.LINE_ITEMS_TABLE' });
  const apiRef = useGridApiContext();
  const { color } = useDataGridContext();
  const ref = useInputFocusableRef({ hasFocus });

  return (
    <Box px={4}>
      <TextField
        ref={ref}
        variant="ghost"
        hiddenLabel
        value={value ?? ''}
        placeholder={t('DESCRIPTION')}
        onChange={(e) => {
          const newValue = e.target.value;
          void apiRef.current.setEditCellValue({ id, field, value: newValue ?? null });
        }}
        color={color}
      />
    </Box>
  );
};
