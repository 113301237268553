const TASK_DOCUMENT_FULL_PATH_REGEX = /^accounts\/[^/]*\/requests\/[^/]*\/files\/task\/[^/]*\/field\/[^/]*\/[^/]*$/;

/**
 * Detect files uploaded by custom tasks. The path of the custom task file is in format
 *
 * `accounts/<uuid>/requests/<uuid>/files/task/<taskId>/field/<fieldID>/<fileName>`
 *
 * other files uploaded to request have file name after `.../files/<fileName>`
 */
export const isTaskDocumentFullPath = (fullPath: string): boolean => {
  return TASK_DOCUMENT_FULL_PATH_REGEX.test(fullPath);
};
