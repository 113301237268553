import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Button, GridHeaderCell, Text } from '@verticeone/design-system';
import type { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';

import type { TaskRow } from '../../../dataSource';
import { DUE_DATE_HOW_TO_GUIDE } from '../../../../constants';

const DueDateTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={3.5}>
      <Text variant="body-regular" size="S" color="text5">
        {t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.DUE_DATE.DESCRIPTION')}
      </Text>
      <Button to={DUE_DATE_HOW_TO_GUIDE} target="_blank" component={Link} size="S" variant="solid" color="neutral">
        {t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.HOW_TO')}
      </Button>
    </Stack>
  );
};

export const TaskDueDateHeader: FC<GridColumnHeaderParams<TaskRow, any, any>> = (props) => {
  const { t } = useTranslation();
  const { isUserAdmin } = useLoggedUserAccountRoles();

  if (!isUserAdmin) {
    return <GridHeaderCell {...props} />;
  }

  return (
    <GridHeaderCell
      tooltip={{
        content: <DueDateTooltipContent />,
        title: t('ENTITIES.WORKFLOW_TASK.HINTS.DUE_DATE.DUE_DATE.TITLE'),
      }}
      {...props}
    />
  );
};
