import { useDeleteWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { WorkflowVersionRow } from '../components/WorkflowsTable/types';
import { useAccountContext } from '../../../account/AccountContext';
import { useInvalidateWorkflowVersions } from '../../workflow/WorkflowDetailPage/components/hooks/useInvalidateWorkflowVersions';
import { useCallback, useState } from 'react';
import { DeleteWorkflowDialog } from '../components/Dialogs/DeleteWorkflowDialog';
import { useGetWorkflowVersionName } from '../../hooks/useGetWorkflowVersionName';

export const useDeleteWorkflowV2 = (
  row: WorkflowVersionRow,
  params?: { onComplete?: () => void; onStart?: () => void }
) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [deleteWorkflow, { isLoading: isDeletingWorkflowVersion }] = useDeleteWorkflowVersionMutation();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();

  const versionName = useGetWorkflowVersionName(row);

  const deleteWorkflowVersion = useCallback(async () => {
    params?.onStart?.();
    const response = await deleteWorkflow({ accountId, workflowId: row.id, workflowVersion: row.versionId });

    if (!('error' in response)) {
      enqueueSnackbar(t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_DELETED', { name: versionName }), {
        variant: 'success',
      });
      invalidateWorkflowVersions();
    } else {
      enqueueSnackbar(t('INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_DELETE_FAILED', { name: versionName }), {
        variant: 'error',
      });
    }
    params?.onComplete?.();
  }, [params, deleteWorkflow, invalidateWorkflowVersions, versionName, accountId, row.id, row.versionId, t]);

  const onClose = () => {
    setOpen(false);
    params?.onComplete?.();
  };

  const onOpen = () => {
    setOpen(true);
  };

  return {
    deleteWorkflowVersion: onOpen,
    DeleteDialog: open ? (
      <DeleteWorkflowDialog
        versionName={`${row.workflowName} ${versionName}`}
        open={open}
        onClose={() => setOpen(false)}
        onComplete={() => {
          void deleteWorkflowVersion();
          onClose();
        }}
        isSubmitting={isDeletingWorkflowVersion}
      />
    ) : null,
  };
};
