import { FormStepsList } from '../components/FormSteps';
import { Divider } from '@verticeone/design-system';
import { Grid, Stack } from '@mui/material';
import { FormEntrySkeleton } from '../../../../forms/fields/FormEntry/FormEntrySkeleton';

export const TaskContractReviewFormSkeleton = () => (
  <Stack direction="row" height="100%" width="100%">
    <FormStepsList sx={{ flex: '0 0 250px' }} />
    <Divider orientation="vertical" />
    <Grid container p={6} spacing={8} alignContent="start">
      {Array.from({ length: 7 }).map((_, i) => (
        <Grid item xs={6} key={i}>
          <FormEntrySkeleton />
        </Grid>
      ))}
    </Grid>
  </Stack>
);
