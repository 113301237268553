import { useTranslation } from 'react-i18next';

import { CardsList } from '../../components/CardsList';
import { CardWrapper } from '../../components/CardWrapper';
import { SectionWrapper } from '../../components/SectionWrapper';
import { AWSSetupCard } from './components';

export const CloudCostOptimization = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper title={t('INTEGRATIONS.CLOUD_COST_OPTIMIZATION')}>
      <CardsList>
        <CardWrapper>
          <AWSSetupCard />
        </CardWrapper>
      </CardsList>
    </SectionWrapper>
  );
};
