import { Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { filterDailyAlarms } from '@vertice/core/src/modules/applications/Applications/helpers';
import { ErrorLogButton, ErrorLogDrawer } from '@vertice/core/src/modules/applications/components/ErrorLogDrawer';
import { LastFetchedInfo } from '@vertice/core/src/modules/applications/components/LastFetchedInfo';
import { useLastFetchedData } from '@vertice/core/src/modules/applications/hooks/useLastFetchedData';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useGetSupportedIntegrationsQuery } from '@vertice/slices';
import { DataSourceGroup } from '@vertice/slices/src/graphql/bffeUsage/generated/usageGraphQL';
import { CardsList } from '../../components/CardsList';
import { CardWrapper } from '../../components/CardWrapper';
import { SectionWrapper } from '../../components/SectionWrapper';
import { EntraSetupCard } from './components/EntraSetup/EntraSetupCard';
import { ENTRA_INTEGRATION_ID } from './components/EntraSetup/Steps/common';
import { GoogleSetupCard } from './components/Google/GoogleSetupCard';
import { GOOGLE_INTEGRATION_ID } from './components/Google/Steps/common';
import { JumpCloudSetupCard } from './components/JumpCloud/JumpCloudSetupCard';
import { JUMPCLOUD_INTEGRATION_ID } from './components/JumpCloud/Steps/common';
import { OktaSetupCard } from './components/OktaSetup/OktaSetupCard';
import { OKTA_INTEGRATION_ID } from './components/OktaSetup/Steps/common';
import { OneLoginSetupCard } from './components/OneLogin/OneLoginSetupCard';
import { ONELOGIN_INTEGRATION_ID } from './components/OneLogin/Steps/common';

export const DiscoveryAndUsage = () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatures();

  const { data: supportedIntegrations } = useGetSupportedIntegrationsQuery();
  const { data, isLoading: isLoadingLastFetchedData } = useLastFetchedData(DataSourceGroup.All);

  const [showErrorLog, setShowErrorLog] = useState(false);

  const handleLogClose = () => {
    setShowErrorLog(false);
  };

  const googleIntegrationFeatureEnabled = isEnabled(FEATURES.INTEGRATION_GOOGLE);
  const accessLogs = data?.getAlarms?.lastDataUpdate;
  const hasAccessLogs = accessLogs && accessLogs.length > 0;
  const alarmsPerDay = filterDailyAlarms(data?.getAlarms?.alarmsPerDay);

  return (
    <SectionWrapper
      title={t('INTEGRATIONS.DISCOVERY_AND_USAGE')}
      actionButtons={
        !isLoadingLastFetchedData && (
          <Stack direction="row" gap={4}>
            {hasAccessLogs && <LastFetchedInfo accessLogs={accessLogs} alarmsPerDay={alarmsPerDay} />}
            <ErrorLogButton onClick={() => setShowErrorLog(true)} />
            <ErrorLogDrawer open={showErrorLog} onClose={handleLogClose} alarmsPerDay={alarmsPerDay} />
          </Stack>
        )
      }
    >
      <CardsList>
        {supportedIntegrations?.map((supportedIntegration, index) => {
          switch (supportedIntegration.id) {
            case OKTA_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <OktaSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case ENTRA_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <EntraSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case ONELOGIN_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <OneLoginSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case JUMPCLOUD_INTEGRATION_ID:
              return (
                <CardWrapper key={index}>
                  <JumpCloudSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              );
            case GOOGLE_INTEGRATION_ID:
              return googleIntegrationFeatureEnabled ? (
                <CardWrapper key={index}>
                  <GoogleSetupCard providerId={supportedIntegration.id} />
                </CardWrapper>
              ) : null;
            default:
              return null;
          }
        })}
      </CardsList>
    </SectionWrapper>
  );
};
