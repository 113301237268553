import { Stack, useTheme } from '@mui/material';
import React, { Children, FC, isValidElement, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { SlackIcon } from '@vertice/assets';
import { IconWrapper, PageHeader } from '@verticeone/design-system';
import { IntegrationSetupDialog } from '../../../../../components/IntegrationSetupDialog';
import { WizardStepProps } from '../../../../../components/Wizard';
import { Step } from '../common';
import { useDeactivateSlackIntegration } from './hooks/useDeactivateSlackIntegration';
import IntegrationSetupContainer from '../../../../../components/IntegrationSetupContainer';
import { OutletContextType } from '../../../../../types';

type IntegrationSetupProps = {
  activeStep: Step;
} & PropsWithChildren;

export const IntegrationSetup: FC<IntegrationSetupProps> = ({ activeStep, children }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { setHeaderActions, setBreadcrumbsItems } = useOutletContext<OutletContextType>();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);

  const { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress } =
    useDeactivateSlackIntegration(setIsDisconnectDialogOpened);

  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  const actions = useMemo(
    () =>
      activeStep === Step.CONNECTION ? (
        <>
          <PageHeader.PrimaryAction onClick={() => setIsDisconnectDialogOpened(true)}>
            {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT')}
          </PageHeader.PrimaryAction>
          <IntegrationSetupDialog
            header={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_HEADER')}
            content={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_TEXT')}
            buttonLabel={t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_BUTTON')}
            isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
            isOpened={isDisconnectDialogOpened}
            onSubmit={deactivateSlackIntegration}
            onClose={() => setIsDisconnectDialogOpened(false)}
          />
        </>
      ) : undefined,
    [
      activeStep,
      deactivateSlackIntegration,
      isDisconnectDialogOpened,
      isIntegrationDeactivationInProgress,
      isIntegrationRevokeInProgress,
      t,
    ]
  );

  useEffect(() => {
    setBreadcrumbsItems([
      {
        label: t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_SLACK'),
        startAdornment: <IconWrapper icon={SlackIcon} />,
      },
    ]);

    setHeaderActions(actions);

    return () => {
      setHeaderActions([]);
      setBreadcrumbsItems([]);
    };
  }, [actions, setBreadcrumbsItems, setHeaderActions, t]);

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <IntegrationSetupContainer maxWidth="1280px">{stepContent}</IntegrationSetupContainer>
    </Stack>
  );
};
