import React from 'react';
import { Stack } from '@mui/material';
import { TextField, Text, Placeholder } from '@verticeone/design-system';
import { Control, useFieldArray, UseFormRegister, useWatch } from 'react-hook-form';
import VariableMapper from '@vertice/core/src/modules/intelligentWorkflows/components/VariableMapper';
import { FormSchema } from '../formSchema';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';

type FakeDisabledRowProps = {
  name: string;
  value: string;
};

const FakeDisabledRow = ({ name, value }: FakeDisabledRowProps) => (
  <VariableMapper.Row isDeletable={true}>
    <TextField size="S" sx={{ flex: 1 }} disabled value={name} />
    <VariableMapper.Divider />
    <TextField size="S" sx={{ flex: 1 }} disabled value={value} />
  </VariableMapper.Row>
);

type HeaderMapperFieldsProps = {
  control: Control<FormSchema>;
  register: UseFormRegister<FormSchema>;
  title: {
    header: string;
    left: string;
    right: string;
  };
};

const HeaderMapperFields = ({ control, register, title }: HeaderMapperFieldsProps) => {
  const { fields, remove, append } = useFieldArray({ control, name: 'headers' });

  const integrationId = useWatch({ control, name: 'connection' }) ?? '';
  const { accountId } = useAccountContext();
  const { data: integrationData, isFetching } = useGetAccountIntegrationQuery(
    {
      accountId: accountId,
      integrationId,
    },
    { skip: !integrationId }
  );

  return (
    <VariableMapper.Root gap={6}>
      <Text variant="label" size="XXS" color="text3">
        {title.header}
      </Text>
      <Stack gap={4}>
        <VariableMapper.Row header>
          <VariableMapper.Heading>{title.left}</VariableMapper.Heading>
          <VariableMapper.Heading>{title.right}</VariableMapper.Heading>
        </VariableMapper.Row>
        {isFetching
          ? Array(2)
              .fill(0)
              .map((_, index) => (
                <VariableMapper.Row key={`loading-${index}`} isDeletable={false}>
                  <Placeholder variant="text" width="100%" height={24} />
                  <VariableMapper.Divider />
                  <Placeholder variant="text" width="100%" height={24} />
                </VariableMapper.Row>
              ))
          : fields.map((field, index) =>
              field.name === 'Authorization' && integrationData?.parameters?.authorizationHeaderName ? (
                <FakeDisabledRow
                  key={field.id}
                  name={integrationData?.parameters?.authorizationHeaderName}
                  value="******"
                />
              ) : (
                <VariableMapper.Row key={field.id} onDelete={() => remove(index)} isDeletable={field.isDeletable}>
                  <TextField
                    size="S"
                    sx={{ flex: 1 }}
                    disabled={!field.isDeletable}
                    {...register(`headers.${index}.name`)}
                  />
                  <VariableMapper.Divider />
                  <TextField
                    size="S"
                    sx={{ flex: 1 }}
                    disabled={!field.isEditable}
                    {...register(`headers.${index}.value`)}
                  />
                </VariableMapper.Row>
              )
            )}
        <VariableMapper.Row>
          <VariableMapper.AddButton
            onClick={() =>
              append({
                name: '',
                value: '',
                isDeletable: true,
                isEditable: true,
              })
            }
          />
        </VariableMapper.Row>
      </Stack>
    </VariableMapper.Root>
  );
};

export default HeaderMapperFields;
