import React, { FC, useState, ReactElement, cloneElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValueGetterParams,
  GridRowParams,
  MuiEvent,
  GridEventListener,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Stack } from '@mui/material';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ConfirmationDialog } from '@vertice/components';
import { GridIconButton } from '@verticeone/design-system';
import { DepartmentItem } from './types';
import { randomId } from '@mui/x-data-grid-generator';
import { useDepartmentListContext } from './DepartmentListContextProvider';
import { normalizeDepartmentName } from './utils';

interface WithActionsProps {
  children: ReactElement;
  excludeDeleteAction?: boolean;
}

type CannotPerformActionContext = {
  action?: string;
  reason?: string;
};

type DeleteActionContext = {
  departmentId: string;
};

const WithActions: FC<WithActionsProps> = ({ children, excludeDeleteAction, ...rest }) => {
  const MAX_TITLE_LENGTH = 128;
  const { t } = useTranslation();
  const { departments, addDepartment, updateDepartment, removeDepartment } = useDepartmentListContext();
  const [tempoDepartments, setTempoDepartments] = useState<DepartmentItem[]>([]);
  const departmentIds = departments?.map((dep: DepartmentItem) => dep.id) || [];
  const departmentsToUse = [
    ...(departments || []),
    ...tempoDepartments.filter((dep) => !departmentIds.includes(dep.id)),
  ];

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [deleteActionContext, setDeleteActionContext] = useState<DeleteActionContext>();
  const [cannotPerformAction, setCannotPerformAction] = useState<CannotPerformActionContext | undefined>();

  const onAddRow = () => {
    const id = randomId();
    setTempoDepartments((oldRows) => [...oldRows, { id, label: '', owner: null, watchers: [], isNew: true }]);
    setRowModesModel((currentModel) => ({
      ...currentModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'label' },
    }));
  };

  const onRowEditStart = (params: GridRowParams, event: MuiEvent<SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const onRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    if (newRow.label) {
      const departmentData = {
        id: newRow.id,
        departmentId: newRow.departmentId,
        label: normalizeDepartmentName(newRow.label),
        owner: newRow.owner,
        watchers: newRow.watchers,
      };

      if (newRow.isNew) {
        addDepartment(departmentData);
      } else {
        updateDepartment(departmentData);
      }
    }
    setTempoDepartments([]);
    return newRow;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (row: DepartmentItem) => () => {
    const hasAssignedContracts = row.assignedContractCount && row.assignedContractCount > 0;
    if (hasAssignedContracts) {
      setCannotPerformAction({
        action: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.DELETE_DEPARTMENT'),
        reason: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.CONTRACTS_ASSIGNED', {
          count: row.assignedContractCount,
        }),
      });
      return;
    }

    setDeleteActionContext({ departmentId: row.id });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setTempoDepartments([]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleConfirmedDelete = async () => {
    if (deleteActionContext) {
      removeDepartment(deleteActionContext.departmentId);
    }
    setDeleteActionContext(undefined);
  };

  const handleCanceledDelete = () => {
    setDeleteActionContext(undefined);
  };

  const ActionsCell = (params: GridValueGetterParams<DepartmentItem, DepartmentItem>) => {
    const apiRef = useGridApiContext();
    const isRowInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
    const isDefaultDepartment = params.row.isDefaultDepartment;
    const save = () => {
      const updatedRow = apiRef.current.getRowWithUpdatedValues(params.row.id, 'label');
      const departmentName = normalizeDepartmentName(updatedRow.label);
      const departmentId = updatedRow.id;

      if (!departmentName) {
        setCannotPerformAction({
          action: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.SAVE_DEPARTMENT'),
          reason: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.NAME_MISSING'),
        });
        return;
      }
      const labelTooLong = departments.some((dep: DepartmentItem) => departmentName.length > MAX_TITLE_LENGTH);
      if (labelTooLong) {
        setCannotPerformAction({
          action: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.SAVE_DEPARTMENT'),
          reason: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.NAME_TOO_LONG', { maxLen: MAX_TITLE_LENGTH }),
        });
        return;
      }

      const labelExists = departments.some(
        (dep: DepartmentItem) => dep.label === departmentName && dep.id !== departmentId
      );
      if (labelExists) {
        setCannotPerformAction({
          action: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.SAVE_DEPARTMENT'),
          reason: t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.NAME_DUPLICATED', { name: departmentName }),
        });
        return;
      }

      setRowModesModel({ ...rowModesModel, [params.row.id]: { mode: GridRowModes.View } });
    };

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {isRowInEditMode ? (
          <>
            <GridIconButton
              variant="ghost"
              color="success"
              onClick={save}
              icon={CheckOutlined}
              aria-label={t('ENTITIES.DEPARTMENT.SAVE_CHANGES')}
            />
            <GridIconButton
              variant="ghost"
              color="error"
              onClick={handleCancelClick(params.row.id)}
              icon={CloseOutlined}
              aria-label={t('ENTITIES.DEPARTMENT.DISCARD_CHANGES')}
            />
          </>
        ) : (
          <>
            <GridIconButton
              variant="ghost"
              onClick={handleEditClick(params.row.id)}
              icon={EditOutlined}
              disabled={params.row.isBeingProcessed}
              aria-label={t('ENTITIES.DEPARTMENT.EDIT_DEPARTMENT')}
            />
            <GridIconButton
              variant="ghost"
              onClick={handleDeleteClick(params.row)}
              icon={DeleteOutlined}
              disabled={isDefaultDepartment || params.row.isBeingProcessed} // Default department cannot be deleted
              aria-label={t('ENTITIES.DEPARTMENT.DELETE_DEPARTMENT')}
            />
          </>
        )}
      </Stack>
    );
  };

  const actionsColumn = {
    field: 'actions',
    headerName: '',
    sortable: false,
    disableColumnMenu: true,
    renderCell: ActionsCell,
    width: 84,
    align: 'center',
  };

  const addButtonDisabled = !!tempoDepartments.length;

  return (
    <>
      {cloneElement(children, {
        extraColumns: [actionsColumn],
        dataGridExtraProps: {
          rows: departmentsToUse,
          hideFooter: false,
          editMode: 'row',
          isCellEditable: () => true,
          rowModesModel,
          onRowEditStart,
          onRowEditStop,
          processRowUpdate,
          slotProps: {
            footer: { disabledButton: addButtonDisabled, onAddClick: onAddRow },
          },
        },
        ...rest,
      })}
      <ConfirmationDialog
        isOpen={!!deleteActionContext}
        headerText={t('ENTITIES.DEPARTMENT.CONFIRM_DELETE_DIALOG.HEADING')}
        bodySecondaryText={t('ENTITIES.DEPARTMENT.CONFIRM_DELETE_DIALOG.MESSAGE')}
        secondaryButtonText={t('ENTITIES.DEPARTMENT.CONFIRM_DELETE_DIALOG.CANCEL')}
        secondaryButtonAction={handleCanceledDelete}
        primaryButtonText={t('ENTITIES.DEPARTMENT.CONFIRM_DELETE_DIALOG.YES')}
        primaryButtonAction={handleConfirmedDelete}
      />
      <ConfirmationDialog
        isOpen={!!cannotPerformAction}
        headerText={cannotPerformAction?.action}
        bodySecondaryText={cannotPerformAction?.reason}
        primaryButtonText={t('ENTITIES.DEPARTMENT.CANNOT_PERFORM_ACTION_DIALOG.OK')}
        primaryButtonAction={() => setCannotPerformAction(undefined)}
      />
    </>
  );
};

export default WithActions;
