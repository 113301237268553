import { DataGrid, DataGridProps, DesignSystemColor, Text } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { GridColDef, GridRowModel, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import {
  useEditableDataGrid,
  ValidateRowResult,
} from '@vertice/core/src/components/EditableDataGrid/useEditableDataGrid';
import { EditableDataGridContextProvider } from '@vertice/core/src/components/EditableDataGrid/EditableDataGridContext';
import { Box, Stack } from '@mui/material';
import { LineItem, LineItemsTableColumn } from './types';
import FormControlContext from '@mui/material/FormControl/FormControlContext';
import NoRowsOverlay from '../NoRowsOverlay';
import { useLineItemsTableActions } from './hooks/useLineItemsTableActions';
import { DeepPartial } from 'react-hook-form';
import { useLineItemColumnBuilder } from './hooks/useLineItemColumnBuilder';
import { isNotNil } from '@verticeone/utils/validation';

export type LineItemsTableProps<T extends GridValidRowModel> = {
  values: T[];
  onValuesChange: (values: T[]) => void;
  color?: DesignSystemColor;
  editMode: boolean;
  errorText?: string;
  columns?: GridColDef<T>[];
  getLineItem?: (r: GridRowModel) => T;
  validateLineItem?: (row: GridRowModel<T>) => ValidateRowResult<T>;
  dataGridExtraProps?: Partial<DataGridProps>;
};

export const LineItemsTable = <T extends LineItem>({
  values = [],
  onValuesChange,
  errorText,
  editMode,
  columns,
  getLineItem,
  validateLineItem,
  color = 'primary',
  dataGridExtraProps,
}: LineItemsTableProps<T>) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'ENTITIES.LINE_ITEMS_TABLE' });
  const apiRef = useGridApiRef();
  const { buildColumn } = useLineItemColumnBuilder();

  const { validateRow, onErrorsChange, onAddRow, onUpdateRow, onBeforeDeleteRow, onDeleteRow } =
    useLineItemsTableActions({ values, onValuesChange, getLineItem, validateLineItem });

  const editableDataGrid = useEditableDataGrid<T>({
    apiRef,
    defaultMode: 'confirm',
    validateRow,
    editMode,
    onAddRow,
    onUpdateRow,
    onBeforeDeleteRow,
    onDeleteRow,
    onErrorsChange,
    fieldToFocus: LineItemsTableColumn.LINE_ITEM_TYPE,

    // Add-button-specific
    withAddButton: true,
    createTmpAddRow: (id: string) =>
      ({ id, lineItemType: undefined, description: '', quantity: null } as DeepPartial<T>),
    addItemButtonLabel: t('ADD_NEW_LINE_ITEM'),
    color,
  });

  const defaultColumns = useMemo(
    () =>
      [
        buildColumn(LineItemsTableColumn.LINE_ITEM_TYPE),
        buildColumn(LineItemsTableColumn.DESCRIPTION),
        buildColumn(LineItemsTableColumn.QUANTITY),
      ].filter(isNotNil) as GridColDef<T>[],
    [buildColumn]
  );

  const columnsToUse = useMemo(
    (): GridColDef<T>[] => [
      ...(columns ? columns : defaultColumns),
      ...(editableDataGrid.actionsColumn ? [editableDataGrid.actionsColumn] : []),
    ],
    [columns, defaultColumns, editableDataGrid.actionsColumn]
  );

  const rows = useMemo(
    () => [...values, ...(editableDataGrid.tmpAddRow ? [editableDataGrid.tmpAddRow] : [])],
    [values, editableDataGrid.tmpAddRow]
  );

  return (
    // Workaround: Destroy the FormControl context so that we're able to have multiple inputs inside a FormControl.
    <FormControlContext.Provider value={undefined}>
      <Stack gap={1}>
        <EditableDataGridContextProvider value={editableDataGrid.context}>
          <DataGrid
            {...editableDataGrid.dataGridProps}
            apiRef={apiRef}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columnsToUse}
            slots={{
              ...editableDataGrid.dataGridProps.slots,
              noRowsOverlay: () => <NoRowsOverlay text={t('NO_ROWS')} />,
            }}
            sortingMode="client"
            autoHeight={true}
            color={color}
            {...dataGridExtraProps}
          />
        </EditableDataGridContextProvider>
        {errorText && (
          <Box px={4}>
            <Text color="error2" size="XS" variant="body-regular">
              {errorText}
            </Text>
          </Box>
        )}
      </Stack>
    </FormControlContext.Provider>
  );
};
