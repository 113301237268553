import { FC, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProductList } from '@vertice/core/src/modules/saas/contract/components/ProductList';
import { FormData } from '../../PurchaseIntakeSimpleForm/schema';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';
import { ProductItem, ProductListColumn } from '@vertice/core/src/modules/saas/contract/components/ProductList/types';
import { isNotNil } from '@verticeone/utils/validation';
import { useProductExtraColumnBuilder } from '../../../../../../../saas/contract/components/ProductList/hooks/useProductExtraColumnBuilder';

type FormProductListProps = {
  noBorder?: boolean;
};

export const FormProductList: FC<FormProductListProps> = ({ noBorder }) => {
  const { setValue } = useFormContext<FormData>();
  const { readOnly } = useTaskFormContext();

  const products: ProductItem[] = useWatch<FormData, 'products'>({ name: 'products' });
  const vendor = useWatch<FormData, 'vendor'>({ name: 'vendor' });

  const setProducts = useCallback(
    (productsToSet: ProductItem[]) => {
      setValue('products', productsToSet, { shouldValidate: true });
    },
    [setValue]
  );

  const { buildColumn } = useProductExtraColumnBuilder();

  return (
    <ProductList
      withActions={!readOnly}
      extraColumns={[
        buildColumn(ProductListColumn.NUMBER_OF_LICENSES),
        buildColumn(ProductListColumn.LICENSE_TYPE),
      ].filter(isNotNil)}
      selectedProducts={products || []}
      setSelectedProducts={setProducts}
      vendorId={vendor?.id}
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      dataGridExtraProps={{
        noBorder,
        noBorderRadius: noBorder,
      }}
    />
  );
};
