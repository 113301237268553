import { Stack } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { SummarySection } from '../components/SummarySection';
import { useTranslation } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { TaskContractReviewOutput } from './types';
import {
  useDetailsSummaryRecords,
  useLifecycleSummaryRecords,
  useLineItemsSummaryRecords,
} from './formSteps/ContractSummaryFormStep/hooks';
import { transformFromOutput } from './utils/transformFromOutput';
import { CostModelLineItemsTable } from '../../../../saas/contract/components/CostModelLineItemsTable';
import { getRollingFreqFromContractForm } from './utils/getRollingFreqFromContractForm';
import { useDocumentsSummaryRecord } from './formSteps/ContractSummaryFormStep/hooks/useDocumentsSummaryRecord';

export type TaskContractReviewResultSectionProps = {
  result?: FieldValues;
};

export const TaskContractReviewResultSection: FC<TaskContractReviewResultSectionProps> = (props) => {
  const { result } = props;
  const { t } = useTranslation();

  const values = useMemo(() => {
    return transformFromOutput(result as TaskContractReviewOutput);
  }, [result]);

  const { records: lifecycleRecords } = useLifecycleSummaryRecords({ values });
  const { records: detailsRecords } = useDetailsSummaryRecords({ values });
  const { record: documentsRecord } = useDocumentsSummaryRecord();
  const { records: lineItemsRecords } = useLineItemsSummaryRecords({ values });

  return (
    <Stack flexGrow={1} gap={12} width="100%">
      <SummarySection>
        <SummarySection.Header>
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.CONTRACT_DETAILS')}
        </SummarySection.Header>
        <SummarySection.Body>
          {detailsRecords.map(({ label, value }) => (
            <Fragment key={label}>
              <SummarySection.Label>{label}</SummarySection.Label>
              <SummarySection.Value>{value}</SummarySection.Value>
            </Fragment>
          ))}
        </SummarySection.Body>
      </SummarySection>

      <SummarySection>
        <SummarySection.Header>
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.CONTRACT_DATES')}
        </SummarySection.Header>
        <SummarySection.Body>
          {lifecycleRecords.map(({ label, value }) => (
            <Fragment key={label}>
              <SummarySection.Label>{label}</SummarySection.Label>
              <SummarySection.Value>{value}</SummarySection.Value>
            </Fragment>
          ))}
        </SummarySection.Body>
      </SummarySection>

      <SummarySection>
        <SummarySection.Header>
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.DOCUMENTS')}
        </SummarySection.Header>
        <SummarySection.Body>
          <SummarySection.Label>{documentsRecord.label}</SummarySection.Label>
          <SummarySection.Value>{documentsRecord.value}</SummarySection.Value>
        </SummarySection.Body>
      </SummarySection>

      <SummarySection>
        <SummarySection.Header>
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.CONTRACT_REVIEW.STEPS.LINE_ITEMS')}
        </SummarySection.Header>
        <SummarySection.Body>
          {lineItemsRecords.map(({ label, value }) => (
            <Fragment key={label}>
              <SummarySection.Label>{label}</SummarySection.Label>
              <SummarySection.Value>{value}</SummarySection.Value>
            </Fragment>
          ))}
        </SummarySection.Body>
        <CostModelLineItemsTable
          editMode={false}
          values={values.lineItems.lineItems ?? []}
          onValuesChange={() => {}}
          currency={values.lineItems.currency}
          rollingFrequency={getRollingFreqFromContractForm(values)}
        />
      </SummarySection>
    </Stack>
  );
};
