import { maxBy, minBy } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';

import { QUERY_KEY as CONTRACT_LIST_QUERY_KEY } from './useContractListData';
import { QUERY_KEY as EDP_CONFIGURATION_QUERY_KEY } from '../Configurations/useEDPConfigurationData';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import useValidationErrorHandler from './useValidationErrorHandler';

export type ContractYear = {
  commitment: number;
  discount: number;
  endDate: string;
  startDate: string;
};

export type Contract = {
  contractId: string;
  years: Array<ContractYear>;
};

type UseContractUpdateProps = Partial<Record<'onSuccess' | 'onError' | 'onSettled', () => void>>;

export const QUERY_KEY = 'EDPContractUpdate' as const;

const EDPContractUpdateQuery = graphql(`
  mutation EDPContractUpdate($accountId: ID!, $identifier: ID!, $costModel: CostModelInput!) {
    updateContract(params: { accountId: $accountId, identifier: $identifier, costModel: $costModel }) {
      ... on EDPContract {
        __typename
        identifier
        costModel {
          startDate
          endDate
          costAllocationSpans {
            startDate
            endDate
            commitment
            discount
          }
        }
      }
      ... on ErroredQueryResult {
        __typename
        error
      }
      ... on EDPValidationError {
        __typename
        context
        code
        fieldName
      }
    }
  }
`);

export const useContractUpdate = (options?: UseContractUpdateProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.EDP_CONTRACT_GRID' });
  const { accountId } = useAccountContext();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const handleValidationError = useValidationErrorHandler();
  const { fetchCloudOptimization } = useCloudClient();

  return useMutation({
    mutationKey: [QUERY_KEY],
    mutationFn: (contract: Contract) => {
      return fetchCloudOptimization(EDPContractUpdateQuery, {
        accountId,
        identifier: contract.contractId,
        costModel: {
          startDate: minBy(contract.years, (year) => new Date(year.startDate).getTime())!.startDate,
          endDate: maxBy(contract.years, (year) => new Date(year.endDate).getTime())!.endDate,
          costAllocationSpans: contract.years.map((year, index) => ({
            startDate: year.startDate,
            endDate: year.endDate,
            commitment: year.commitment,
            discount: year.discount,
            name: t('TERM', { term: index + 1 }),
            spanLength: dayjs(year.endDate).add(1, 'day').diff(dayjs(year.startDate), 'month'),
          })),
        },
      });
    },
    onSettled: () => {
      options?.onSettled?.();
    },
    onSuccess: async (response) => {
      if (
        (response?.updateContract?.__typename === 'ErroredQueryResult' ||
          response?.updateContract?.__typename === 'EDPValidationError') &&
        handleValidationError(response.updateContract)
      ) {
        options?.onError?.();
        return;
      }

      enqueueSnackbar({
        variant: 'success',
        message: t('ALERT.CONTRACT_SAVED'),
      });

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [CONTRACT_LIST_QUERY_KEY] }),
        queryClient.invalidateQueries({ queryKey: [EDP_CONFIGURATION_QUERY_KEY] }),
      ]);

      options?.onSuccess?.();
    },
    onError: (errors: Array<Error>) => {
      errors.forEach((e) =>
        enqueueSnackbar({
          variant: 'error',
          message: e.message,
        })
      );
      options?.onError?.();
    },
  });
};
