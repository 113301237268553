import React, { FC, useState } from 'react';
import { useContracts } from '@vertice/core/src/modules/saas/contract/components/ContractList/useContracts';
import { ContractListColumn, FilterView } from '@vertice/core/src/modules/saas/contract/components/ContractList/types';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { useGetColumnBuilder } from '@vertice/core/src/modules/saas/contract/components/ContractList/useGetColumnBuilder';
import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { ContractsTable } from '@vertice/core/src/modules/saas/contract/components/ContractList/ContractsTable';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { GridPaginationModel } from '@mui/x-data-grid-pro';

type ContractsProps = {
  paginationModel: GridPaginationModel | undefined;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel | undefined>>;
  filter: FilterView;
};

const Contracts: FC<ContractsProps> = ({ paginationModel, setPaginationModel, filter }) => {
  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const getContractPath = (contractId: string) => generatePath(routes.CONTRACTS.DETAIL.ABSOLUTE_PATH, { contractId });

  const { viewsOnContracts, isLoading } = useContracts({
    activeFilterView: filter,
  });

  const { buildColumnsFromView } = useGetColumnBuilder();
  const columns: GridColDef<ViewOnContract>[] = buildColumnsFromView?.(filter) ?? [];

  const [sortModel, setSortModel] = useState<GridSortModel | undefined>([
    { field: ContractListColumn.RENEWAL_DATE, sort: 'asc' },
  ]);

  return (
    <ContractsTable
      rows={viewsOnContracts}
      isLoading={isLoading}
      columns={columns}
      getContractPath={getContractPath}
      sortModel={sortModel}
      setSortModel={setSortModel}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      noBorderRadius
      noBorder
    />
  );
};

export default Contracts;
