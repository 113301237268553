import { GetPresenterDefaultProps } from '../types';
import { RepeatingFieldType, RepeatingFieldMetadata } from './types';
import { z, ZodObject, ZodRawShape } from 'zod';
import { mapFieldToSchema } from '../../DynamicFormPresenter/utils/createDynamicSchema';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';
import { fieldDefsByType } from '../fields';
import { PrimitiveDynamicFormField } from '../../primitiveTypes';
import { DynamicFormPresenterConfig } from '../../types';
import { FieldValues } from 'react-hook-form';

export function getRepeatingPresenterSchema(repeatingField: RepeatingFieldType): ZodObject<ZodRawShape> {
  const itemSchema = createArrayItemDefSchema(repeatingField.metadata);
  const schema = z.array(itemSchema).min(repeatingField.metadata.requiredItems).max(repeatingField.metadata.limit);

  return z.object({
    [repeatingField.name]: schema,
  });
}

export function getRepeatingPresenterDefault({
  field,
  defaultValues,
  config,
}: GetPresenterDefaultProps<RepeatingFieldType>) {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }

  const defaultItemValues = getItemDefaultValues({ fields: field.metadata.fields, config, defaultValues });
  return { [field.name]: Array.from({ length: field.metadata.requiredItems }, () => ({ ...defaultItemValues })) };
}

type GetItemDefaultValuesProps = {
  fields?: PrimitiveDynamicFormField[] | null;
  config: DynamicFormPresenterConfig;
  defaultValues?: FieldValues;
};

export function getItemDefaultValues({
  fields,
  config,
  defaultValues,
}: GetItemDefaultValuesProps): Record<string, unknown> | undefined {
  return fields
    ?.map((nestedField) => {
      return fieldDefsByType[nestedField.type]?.getPresenterDefault({
        field: nestedField,
        defaultValues,
        config,
      });
    })
    .reduce((acc, presenterDefault) => {
      return { ...acc, ...presenterDefault };
    });
}

export function getRepeatingJsonSchemaFromMetadata(metadata: RepeatingFieldMetadata): JsonSchema {
  return { type: metadata.requiredItems ? ['array'] : ['array', 'null'], title: metadata.label };
}

export function getRepeatingJsonSchema(field: RepeatingFieldType) {
  return {
    required: [field.name],
    properties: { [field.name]: getRepeatingJsonSchemaFromMetadata(field.metadata) },
  };
}

function createArrayItemDefSchema(repeatingMetadata: RepeatingFieldMetadata) {
  const schemas = (repeatingMetadata.fields ?? []).map(mapFieldToSchema);
  return schemas.reduce((prev, curr) => curr.merge(prev), z.object({}));
}
