import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { PageHeader, BreadcrumbsV2 } from '@verticeone/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { useGetProductByIdQuery, useGetVendorByIdQuery } from '@vertice/slices';
import { RequestBenchmarkingDataButton } from '../../modules/saas/vendor/buttons/RequestBenchmarkingDataButton';
import VendorLogo from '@vertice/core/src/components/VendorLogo';

export type ProductDetailPageHeaderProps = {
  vendorId?: string;
  productId?: string;
};

const ProductDetailPageHeader = ({ vendorId, productId }: ProductDetailPageHeaderProps) => {
  const { t } = useTranslation();
  const { generatePath } = useRouteNavigate();
  const routes = useRoutes();

  const { data: vendor } = useGetVendorByIdQuery({ vendorId: vendorId! }, { skip: !vendorId });

  const { data: product } = useGetProductByIdQuery(
    {
      vendorId: vendorId!,
      productId: productId!,
    },
    { skip: !vendorId || !productId }
  );

  return (
    <PageHeader
      breadcrumb={
        <BreadcrumbsV2
          items={[
            { label: t('SAAS.NAVBAR.SAAS') },
            { label: t('SAAS.NAVBAR.VENDORS'), component: RouterLink, to: generatePath(routes.VENDORS.PATH) },
            {
              label: vendor?.name || <Skeleton width="6em" />,
              startAdornment: <VendorLogo vendorId={vendorId} size={16} logoOnly />,
              component: RouterLink,
              to: vendorId ? generatePath(routes.VENDORS.VENDOR.ABSOLUTE_PATH, { id: vendorId }) : undefined,
            },
            { label: product?.name || <Skeleton width="6em" /> },
          ]}
        />
      }
      color="primary"
      actions={<RequestBenchmarkingDataButton vendorId={vendorId} productId={productId} />}
    />
  );
};

export default ProductDetailPageHeader;
