import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useConfigureAccountIntegrationMutation, useConnectAccountIntegrationMutation } from '@vertice/slices';
import { IntegrationItem, Secret } from '../utils';

export const useUpdateIntegration = <S extends Secret>() => {
  const { accountId } = useAccountContext();
  const [configureWithSecretIntegration] = useConnectAccountIntegrationMutation();
  const [configureIntegration] = useConfigureAccountIntegrationMutation();

  const connect = (hasSecret: boolean, data: Required<IntegrationItem<S>>) => {
    if (hasSecret) {
      return configureWithSecretIntegration({
        accountId,
        integrationId: data.integrationId,
        override: true,
        integrationConnectionInput: {
          parameters: {
            authorizationHeaderName: data.authorizationHeaderName,
            baseUrl: data.baseUrl,
            secret: data.secret,
          },
        },
      });
    }

    return configureIntegration({
      accountId,
      integrationId: data.integrationId,
      override: true,
      integrationConfigInput: {
        parameters: {
          authorizationHeaderName: data.authorizationHeaderName,
          baseUrl: data.baseUrl,
          secret: {
            type: 'ApiKey',
            apiKey: '******',
          },
        },
      },
    });
  };

  return [connect] as const;
};
