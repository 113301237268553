import { Button, IconWrapper } from '@verticeone/design-system';
import { Box, Stack, useTheme } from '@mui/material';
import { Text } from '@verticeone/design-system';
import { AddOutlined } from '@mui/icons-material';

export type AddFieldButtonProps = {
  onClick: () => void;
  label: string;
  disabled?: boolean;
};

export const AddFieldButton = ({ onClick, label, disabled }: AddFieldButtonProps) => {
  const { palette } = useTheme();

  return (
    <Box
      component={Button}
      variant="plain"
      border="1px dashed"
      borderColor={palette.core.color3}
      borderRadius={3}
      justifyContent="flex-start"
      sx={{ padding: '16px !important' }}
      onClick={onClick}
      disabled={disabled ?? false}
    >
      <Stack direction="row" gap={1.5} alignItems="center">
        <IconWrapper icon={AddOutlined} size="XS" opacity={0.4} />
        <Text variant="label" size="XXS" color="text1">
          {label}
        </Text>
      </Stack>
    </Box>
  );
};
