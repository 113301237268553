import { Box, Stack, useTheme } from '@mui/material';
import ChartFilter from './ChartFilter';
import React, { PropsWithChildren, ReactNode } from 'react';

type ChartLayoutProps = PropsWithChildren & {
  filterItems?: ReactNode[];
};

const ChartLayout = ({ children, filterItems }: ChartLayoutProps) => {
  const { palette } = useTheme();

  return (
    <Box bgcolor={palette.core.color1} py={3} borderRadius={3}>
      <Stack gap={1.5}>
        {filterItems && <ChartFilter>{filterItems}</ChartFilter>}
        {children}
      </Stack>
    </Box>
  );
};

export default ChartLayout;
