import React, { useMemo } from 'react';
import FormDateField from '@vertice/core/src/modules/forms/fields/FormDateField';
import { FormControl, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFormContext, useWatch } from 'react-hook-form';
import { SimpleOption, TextField, TextFieldCaption } from '@verticeone/design-system';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import { useFormatDate } from '@verticeone/utils/formatting';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import useBillingFrequencyOptions from '@vertice/core/src/modules/saas/contract/hooks/useBillingFrequencyOptions';
import useRollingFrequencyOptions from '@vertice/core/src/modules/saas/contract/hooks/useRollingFrequencyOptions';
import { isRollingRollFrequency, rollFrequencyToMonths } from '@vertice/core/src/modules/saas/contract/computed';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';

export const RollingContractFields = () => {
  const { t } = useTranslation();
  const formatDate = useFormatDate();
  const { control } = useFormContext<AddExistingContractFormData>();

  const startDate = useWatch({ control, name: 'startDate' });
  const rollFrequency = useWatch({ control, name: 'rollingFrequency' });
  const billingFrequencySameAsRolling = useWatch({ control, name: 'billingFrequencySameAsRolling' });
  const billingFrequency = useWatch({ control, name: 'billingFrequency' });

  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  const { options: rollFrequencyOptionsRaw, optionsMap: rollFrequencyMap } = useRollingFrequencyOptions({
    isOneOffIncluded: false,
  });

  const rollFrequencyOptions = useMemo(
    () => rollFrequencyOptionsRaw.filter((option) => isRollingRollFrequency(option.value)),
    [rollFrequencyOptionsRaw]
  );

  // calculate next roll date based on start date and rolling frequency
  const nextRollDate = useMemo(() => {
    if (startDate && rollFrequency && isRollingRollFrequency(rollFrequency)) {
      return formatDate(dayjs(startDate).add(rollFrequencyToMonths(rollFrequency), 'month').toISOString());
    }
    return '';
  }, [formatDate, startDate, rollFrequency]);

  return (
    <>
      <AddExistingContractFormEntry
        name="signDate"
        label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
        component={FormDateField}
        width={6}
      />
      <AddExistingContractFormEntry
        name="startDate"
        label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
        component={FormDateField}
        required
        width={6}
      />
      <AddExistingContractFormEntry<FormSelectFieldComponentType<AddExistingContractFormData, SimpleOption, false>>
        name="rollingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY')}
        component={FormSelectField2}
        componentProps={{
          isClearable: false,
          options: rollFrequencyOptions,
          boxValue: (value) => (value ? rollFrequencyMap[value as string] : undefined),
          unboxValue: (selectValue) => selectValue?.value,
        }}
      />
      <Grid item xs={6}>
        <Stack pt={5} /* << empty space instead of label */>
          <Stack justifyContent="center" minHeight="38px">
            <FormChecklistItemField<AddExistingContractFormData>
              name="billingFrequencySameAsRolling"
              label={t('ENTITIES.CONTRACT.LABELS.SAME_BILLING_FREQUENCY')}
              size="XS"
              color="neutral"
            />
          </Stack>
        </Stack>
      </Grid>
      {!billingFrequencySameAsRolling && (
        <>
          <AddExistingContractFormEntry<FormSelectFieldComponentType<AddExistingContractFormData, SimpleOption, false>>
            name="billingFrequency"
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
            component={FormSelectField2}
            componentProps={{
              options: billingFrequencyOpts,
              boxValue: (value) => (value ? billingFrequencyMap[value as string] : undefined),
              unboxValue: (selectValue) => selectValue?.value,
            }}
          />
          {billingFrequency === 'OTHER' && (
            <AddExistingContractFormEntry
              name="billingFrequencyOther"
              label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY_OTHER')}
              component={FormTextField}
              width={6}
            />
          )}
        </>
      )}

      {nextRollDate && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <TextFieldCaption htmlFor="nextRollDate" label={t('ENTITIES.CONTRACT.LABELS.NEXT_ROLL_DATE')} size="XS" />
            <TextField
              name="nextRollDate"
              id="nextRollDate"
              disabled
              value={nextRollDate}
              variant="solid"
              hiddenLabel
            />
          </FormControl>
        </Grid>
      )}
    </>
  );
};
