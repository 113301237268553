import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';

import { JournalItemContentComponent } from './types';
import { Card } from '@verticeone/design-system';
import { TaskIcon, useTaskStyle } from '../../../../../components/sharedVisualStyle/taskStyle';
import { useGetTaskOverviewQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useTranslation } from 'react-i18next';
import { TaskDrawer } from '../../../../../task/TaskDrawer/TaskDrawer';
import React, { useMemo, useState } from 'react';
import { getTaskRows } from '../../../../../task/dataSource';
import { useUsersContext } from '@vertice/core/src/contexts/UsersContext';
import { TaskCardPlaceholder } from './TaskCardPlaceholder';
import { useLoggedUser } from '@verticeone/auth/src';
import { useResolveUsersInfo } from '../../../../../../../hooks/useResolveUsersInfo';
import { parseUserRef } from '../../../../../../../hooks/workflows/refUtils';

export const CompletedTaskJournalItem: JournalItemContentComponent = ({ item }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { data: taskOverview, isLoading } = useGetTaskOverviewQuery(
    { accountId, taskId: item.taskId! },
    { skip: !item.taskId }
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const taskStyle = useTaskStyle({
    userId,
    status: 'COMPLETED',
  });

  const tasksAssignees = useMemo(() => {
    return taskOverview?.task.assignment.map((ref) => parseUserRef(ref).userId) || [];
  }, [taskOverview?.task.assignment]);

  const { usersById: accountUsersById } = useUsersContext();
  const { usersById, isLoading: isLoadingUserIds } = useResolveUsersInfo(tasksAssignees, accountUsersById);
  const taskRow = useMemo(() => {
    if (!taskOverview || !isDrawerOpen) {
      return undefined;
    }

    return {
      ...getTaskRows([taskOverview.task], usersById)[0],
      requestRef: taskOverview.request?.ref,
      requestName: taskOverview.request?.name,
    };
  }, [isDrawerOpen, taskOverview, usersById]);

  if (isLoading || isLoadingUserIds) {
    return <TaskCardPlaceholder />;
  }

  return (
    <Card padding={6}>
      <Stack direction="row" alignItems="center" gap={4} justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={4}>
          <TaskIcon userId={userId} status="COMPLETED" />
          <Text variant="heading" size="S" color={taskStyle.colorVariant}>
            {taskOverview?.task.name}
          </Text>
        </Stack>
        <Button variant="outline" color={taskStyle.colorVariant} size="XS" onClick={() => setIsDrawerOpen(true)}>
          {t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.JOURNAL.VIEW_TASK_DETAIL')}
        </Button>
      </Stack>
      {item.taskId && taskOverview && taskRow ? (
        <TaskDrawer onClose={() => setIsDrawerOpen(false)} taskRow={taskRow} />
      ) : null}
    </Card>
  );
};
