import { getVariableTypeLabels } from '../../../../utils';
import { SimpleTypes as JsonSchemaType, CatalogResource } from '@vertice/slices/src/openapi/codegen/catalogAPI';
import { Variable } from '../../../types';
import { countSelectableVariables, findVariableById } from '../../../VariableSelector/utils';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { JiraIssueField } from './types';
import { VariableMapperRowFormBase } from '../../../VariableMapper/formSchema';
import { type OriginsWithVariables } from '../../../VariableSelector/types';
import { TFunction } from 'i18next';
import { WorkflowVariables } from '../../../VariableMapper/types';
import { getVariableBaseType, getVariableXType } from '../../../VariableMapper/utils';
import { TaskDefinition } from '../../../../../definitionsTypes';
import { getTaskServiceConfiguration } from '../../../../../definitions/taskDefinition';
import { isServiceCatalogResource } from '../../../../../catalogResource/utils';
import type { XTypeCatalogResource } from '../../../../../catalogResource/types';

export const ISSUE_TYPE_ID_NAME = 'issueTypeId';
export const PROJECT_ID_NAME = 'projectId';
export const TICKET_ID_NAME = 'ticketId';

export const enhanceInputFieldsName = (
  wfVariables: WorkflowVariables,
  jiraVariables: Variable[],
  variable: VariableMapperRowFormBase,
  fromWorkflow = true,
  prefix = ''
) => {
  const wfVariableType = REQUIRED_JIRA_CREATE_FIELDS.includes(variable['to'])
    ? getVariableBaseType(jiraVariables, variable['to'])
    : findVariableById([...wfVariables.request, ...wfVariables.udfs], variable[fromWorkflow ? 'from' : 'to'])?.type
        .baseType?.[0];
  const jiraVariableXType = getVariableXType(jiraVariables, variable[fromWorkflow ? 'to' : 'from']);

  return fromWorkflow
    ? `${variable.to}<${wfVariableType},${jiraVariableXType}>`
    : `${prefix}${variable.from}<${jiraVariableXType},${wfVariableType}>`;
};

export const getCorrespondingTaskService = (task: TaskDefinition, resources: CatalogResource[]) => {
  const taskServiceConfiguration = getTaskServiceConfiguration(task);

  const resource = resources.find((service) => service.urn === taskServiceConfiguration?.resourceUrn);

  return isServiceCatalogResource(resource) ? resource : undefined;
};

const transformJiraTypeToWorkflow = (jiraVariable: JiraIssueField, xTypeCatalogResources: XTypeCatalogResource[]) => {
  const xTypeCatalogResource = xTypeCatalogResources.find((xTypeResource) => xTypeResource.urn === jiraVariable.xType);

  return {
    baseType: [jiraVariable.type as JsonSchemaType],
    labels: getVariableTypeLabels({
      type: jiraVariable.type as JsonSchemaType,
      xTypeCatalogResource,
    }),
  };
};

export const transformJiraToWorkflowLikeVariable = (
  xTypeCatalogResources: XTypeCatalogResource[],
  jiraVariable?: JiraIssueField
): Variable | undefined => {
  if (!jiraVariable) return;

  const { baseType, labels } = transformJiraTypeToWorkflow(jiraVariable, xTypeCatalogResources);
  const wfVariable: Variable & { name?: string; xType?: string } = {
    ...jiraVariable,
    label: jiraVariable.name,
    path: [jiraVariable.name],
    type: {
      baseType,
      labels,
      xType: jiraVariable.xType,
    },
    isVisible: true,
    isSelectable: true,
    origin: {
      // How origin should be extended? But it's only for picker, would be thrown away on processing.
      id: 'jira',
      label: 'Jira',
      kind: 'jira',
    },
    variables: [],
    required: false,
  };
  delete wfVariable['name'];
  delete wfVariable['xType'];
  return wfVariable;
};

export const transformJiraToWorkflowLikeVariables = (
  variables: JiraIssueField[],
  xTypeCatalogResources: XTypeCatalogResource[]
): Variable[] => {
  return variables
    .map((jiraVariable) => transformJiraToWorkflowLikeVariable(xTypeCatalogResources, jiraVariable))
    .filter((v) => !!v) as Variable[];
};

export const findValueInTaskObj = (searchKey: string | RegExp, taskObj?: object) => {
  return typeof searchKey === 'string'
    ? Object.entries(taskObj ?? {}).find(([key]) => key.includes(searchKey))?.[1]
    : Object.entries(taskObj ?? {}).find(([key]) => searchKey.test(key))?.[1];
};

export const createJiraVariablesGroups = (variables: Variable[], t: TFunction) => {
  const originsWithVariables: OriginsWithVariables = {
    jira: {
      origin: {
        id: 'jira-available',
        label: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.AVAILABLE'),
        kind: 'jira',
      },
      variables,
    },
  };

  return [
    {
      originsWithVariables,
      count: countSelectableVariables(variables),
      title: t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.PICKER_GROUPS.JIRA.JIRA_VARIABLES'),
      id: 'workflow.request',
    },
  ];
};
