import { graphlib } from '@dagrejs/dagre';
import type {
  EndDefinition,
  FlowEdgeDefinition,
  GatewayDefinition,
  LayoutDefinition,
  ProcessDefinition,
  StartDefinition,
  TaskDefinition,
  VisualElement,
} from '../../definitionsTypes';
import { getRandomFlowElementId } from '../../pocWorkflowSchema/utils';
import {
  isEndDefinition,
  isGatewayDefinition,
  isStartDefinition,
  isTaskDefinition,
} from '../../definitions/processDefinition';
import { DEFINITION_VERSION } from '../../definitions/constants';

import type { Point } from '../model/types';
import type { DnDNode } from './DragAndDropExtension/DnDNodesPanel/types';

import { getOriginalNodeId } from '../WorkflowRenderer/modelToRendererGraph';
import { getLabelNodeId, workflowDefinitionsToModel } from '../model/workflowDefinitionsToModel';
import { getDagreGraphFromWorkflowModel } from '../WorkflowRenderer/getDagreGraphFromWorkflowModel';
import { getNodeDimensions } from '../WorkflowRenderer/NodeComponents/getNodeDimensions';
import { WorkflowDefinitions } from '../types';

const createProcessDefinitionNodeFromDnDNode = (
  node: DnDNode
): {
  definition: StartDefinition | EndDefinition | GatewayDefinition | TaskDefinition;
  id: string;
} => {
  switch (node.type) {
    case 'start': {
      const startId = getRandomFlowElementId('start');
      return {
        id: startId,
        definition: {
          kind: 'ProcessEngine:Start',
          version: DEFINITION_VERSION,
          start: {
            id: startId,
            name: node.name,
          },
        } as StartDefinition,
      };
    }
    case 'gateway': {
      const gatewayId = getRandomFlowElementId('gateway');
      return {
        id: gatewayId,
        definition: {
          kind: 'ProcessEngine:Gateway',
          version: DEFINITION_VERSION,
          gateway: {
            id: gatewayId,
            gatewayType: node.gatewayType,
            name: node.name,
          },
        } as GatewayDefinition,
      };
    }
    case 'task': {
      const taskId = getRandomFlowElementId('task');
      return {
        id: taskId,
        definition: {
          kind: 'ProcessEngine:Task',
          version: DEFINITION_VERSION,
          task: {
            name: node.name,
            taskType: node.taskType,
            id: taskId,
            configurations: node.configurations,
            description: node.description,
          },
        } as TaskDefinition,
      };
    }
    case 'end':
    default: {
      const endId = getRandomFlowElementId('end');
      return {
        definition: {
          kind: 'ProcessEngine:End',
          version: DEFINITION_VERSION,
          end: {
            id: endId,
            name: node.name,
          },
        } as EndDefinition,
        id: endId,
      };
    }
  }
};

export const addNodeToWorkflowDefinitions = (
  workflowDefinitions: WorkflowDefinitions,
  node: DnDNode,
  positions: Point
): WorkflowDefinitions => {
  const { processDefinition, layoutDefinition } = workflowDefinitions;

  const { definition, id: definitionRef } = createProcessDefinitionNodeFromDnDNode(node);

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      ...(isTaskDefinition(definition) ? { tasks: [...(processDefinition.process.tasks ?? []), definition] } : {}),
      ...(isGatewayDefinition(definition)
        ? { gateways: [...(processDefinition.process.gateways ?? []), definition] }
        : {}),
      ...(isStartDefinition(definition) ? { start: [...(processDefinition.process.start ?? []), definition] } : {}),
      ...(isEndDefinition(definition) ? { end: [...(processDefinition.process.end ?? []), definition] } : {}),
    },
  };

  const plane = layoutDefinition.layout?.planes?.[0];

  if (!plane) {
    return {
      processDefinition: newProcessDefinition,
      layoutDefinition,
    };
  }

  const newLayoutDefinition: LayoutDefinition = {
    ...layoutDefinition,
    layout: {
      planes: [
        {
          ...plane,
          elements: [
            ...plane.elements,
            {
              id: getRandomFlowElementId('shape'),
              elementType: 'Shape',
              ref: definitionRef,
              boundary: {
                x: positions.x,
                y: positions.y,
              },
            },
          ],
        },
      ],
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
    layoutDefinition: newLayoutDefinition,
  };
};

export const removeNodeFromWorkflowDefinitions = (
  workflowDefinitions: WorkflowDefinitions,
  nodeId: string
): WorkflowDefinitions => {
  const { processDefinition, layoutDefinition } = workflowDefinitions;

  if (!processDefinition || !layoutDefinition) {
    return workflowDefinitions;
  }

  const { process } = processDefinition;

  const edgeIdsToRemove = (process.flow?.flow.edges ?? [])
    .filter(({ edge }) => edge.from === nodeId || edge.to === nodeId)
    .map(({ edge }) => edge.id);

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...process,
      tasks: process.tasks?.filter(({ task }) => task.id !== nodeId),
      gateways: process.gateways?.filter(({ gateway }) => gateway.id !== nodeId),
      start: process.start?.filter(({ start }) => start.id !== nodeId),
      end: process.end?.filter(({ end }) => end.id !== nodeId),
      ...(process.flow
        ? {
            flow: {
              ...process.flow,
              flow: {
                ...process.flow.flow,
                edges: process.flow.flow.edges?.filter(({ edge }) => !edgeIdsToRemove.includes(edge.id)),
              },
            },
          }
        : {}),
    },
  };

  const plane = layoutDefinition.layout?.planes?.[0];

  if (!plane) {
    return {
      ...workflowDefinitions,
      processDefinition: newProcessDefinition,
    };
  }

  const labelIdsToRemove = edgeIdsToRemove.map((edgeId) => getLabelNodeId(edgeId));

  const newLayoutDefinition: LayoutDefinition = {
    ...layoutDefinition,
    layout: {
      planes: [
        {
          ...plane,
          elements: plane.elements.filter((element) => {
            return element.ref !== nodeId && !labelIdsToRemove.includes(element.ref ?? '');
          }),
        },
      ],
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
    layoutDefinition: newLayoutDefinition,
  };
};

export const removeEdgeFromWorkflowDefinitions = (
  workflowDefinitions: WorkflowDefinitions,
  edgeId: string
): WorkflowDefinitions => {
  const { processDefinition, layoutDefinition } = workflowDefinitions;

  if (!processDefinition || !layoutDefinition) {
    return workflowDefinitions;
  }

  const { process } = processDefinition;

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...process,
      ...(process.flow
        ? {
            flow: {
              ...process.flow,
              flow: {
                ...process.flow.flow,
                edges: process.flow.flow.edges?.filter(({ edge }) => edge.id !== edgeId),
              },
            },
          }
        : {}),
    },
  };

  const plane = layoutDefinition.layout?.planes?.[0];

  if (!plane) {
    return {
      ...workflowDefinitions,
      processDefinition: newProcessDefinition,
    };
  }

  const labelId = getLabelNodeId(edgeId);

  const newLayoutDefinition: LayoutDefinition = {
    ...layoutDefinition,
    layout: {
      planes: [
        {
          ...plane,
          elements: plane.elements.filter((element) => {
            return element.ref !== labelId;
          }),
        },
      ],
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
    layoutDefinition: newLayoutDefinition,
  };
};

export const updatePositionOfNodeInWorkflowDefinitions = (
  workflowDefinitions: WorkflowDefinitions,
  nodeId: string,
  position: Point
): WorkflowDefinitions => {
  const { layoutDefinition } = workflowDefinitions;

  const plane = layoutDefinition.layout?.planes?.[0];

  if (!plane) {
    return workflowDefinitions;
  }

  const planeElements = plane.elements;
  const elementToUpdate = planeElements.find((element) => element.ref === nodeId);
  const updatedPlaneElements = elementToUpdate
    ? planeElements.map((element) => (element.ref === nodeId ? { ...element, boundary: position } : element))
    : [
        ...planeElements,
        { id: getRandomFlowElementId('shape'), elementType: 'Shape' as const, ref: nodeId, boundary: position },
      ];

  const newLayoutDefinition: LayoutDefinition = {
    ...layoutDefinition,
    layout: {
      planes: [
        {
          ...plane,
          elements: updatedPlaneElements,
        },
      ],
    },
  };

  return {
    ...workflowDefinitions,
    layoutDefinition: newLayoutDefinition,
  };
};

export const addEdgeToWorkflowDefinitions = (
  workflowDefinitions: WorkflowDefinitions,
  sourceNodeId: string,
  targetNodeId: string
): WorkflowDefinitions => {
  const { processDefinition } = workflowDefinitions;

  const newEdge: FlowEdgeDefinition = {
    kind: 'ProcessEngine:FlowEdge',
    version: DEFINITION_VERSION,
    edge: {
      from: getOriginalNodeId(sourceNodeId),
      to: getOriginalNodeId(targetNodeId),
      id: getRandomFlowElementId('flow'),
    },
  };

  const flow = processDefinition.process.flow;

  if (!flow) {
    return workflowDefinitions;
  }

  const newProcessDefinition: ProcessDefinition = {
    ...processDefinition,
    process: {
      ...processDefinition.process,
      flow: {
        ...flow,
        flow: {
          ...flow.flow,
          edges: [...(flow.flow.edges ?? []), newEdge],
        },
      },
    },
  };

  return {
    ...workflowDefinitions,
    processDefinition: newProcessDefinition,
  };
};

export const autoLayoutNodesInWorkflowDefinitions = (workflowDefinitions: WorkflowDefinitions) => {
  const layoutPlane = workflowDefinitions.layoutDefinition.layout.planes?.[0];

  if (!layoutPlane) {
    return workflowDefinitions;
  }

  const model = workflowDefinitionsToModel({
    workflowDefinitions,
  });

  const positions = getDagreGraphFromWorkflowModel(model);
  const elements: VisualElement[] = positions.nodes().map((id) => {
    const position = positions.node(id);
    const node = model.nodes.find((n) => n.id === id);
    const originalShapeElement = layoutPlane.elements.find((e) => e.ref === id);

    return {
      // We should only update the elements boundary but not it's id
      id: originalShapeElement ? originalShapeElement.id : getRandomFlowElementId('shape'),
      ref: id,
      elementType: 'Shape',
      boundary: {
        x: position.x - (node ? getNodeDimensions(node).width / 2 : 0),
        y: position.y - (node ? getNodeDimensions(node).height / 2 : 0),
      },
    };
  });

  return {
    ...workflowDefinitions,
    layoutDefinition: {
      ...workflowDefinitions.layoutDefinition,
      layout: {
        planes: [
          {
            id: layoutPlane.id,
            elements,
          },
        ],
      },
    },
  };
};

/**
 * Since we are removing auto-layout with introduction of the drag and drop, we need to add initial positions to the nodes and labels.
 * This function will add initial positions to the nodes and labels if they are not present.
 */
export const getWorkflowDefinitionsWithAutoLayoutPositions = (
  workflowDefinitions: WorkflowDefinitions
): WorkflowDefinitions => {
  const { layoutDefinition, processDefinition } = workflowDefinitions;
  const layoutPlane = layoutDefinition?.layout.planes?.[0];

  if (!layoutPlane) {
    return workflowDefinitions;
  }

  const { process } = processDefinition;
  const processNodesCount =
    process.end.length + process.start.length + (process.tasks?.length ?? 0) + (process.gateways?.length ?? 0);
  const layoutNodesCount = layoutPlane.elements.length;

  if (layoutNodesCount > processNodesCount) {
    return workflowDefinitions;
  }

  return autoLayoutNodesInWorkflowDefinitions(workflowDefinitions);
};

export const getAreAllNodesConnected = (workflowDefinitions: WorkflowDefinitions): boolean => {
  const model = workflowDefinitionsToModel({ workflowDefinitions });
  const positions = getDagreGraphFromWorkflowModel(model);

  const graphComponents = graphlib.alg.components(positions);

  return graphComponents.length === 1;
};
