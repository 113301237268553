import { Box, Card, CardActions, CardContent, CardHeader, Stack, styled } from '@mui/material';
import { kebabCase } from 'lodash';
import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useCheckConnectionQuery, useGetAccountIntegrationQuery } from '@vertice/slices';
import { Button, Loader } from '@verticeone/design-system';
import { useDeactivateIntegration } from '../hooks/useDeactivateIntegration';
import { IntegrationsCardContent } from './IntegrationsCardContent';
import { IntegrationSetupDialog } from './IntegrationSetupDialog';
import { StatusBadge, StatusBadgeVariant } from './StatusBadge';

type IntegrationSetupCardProps = {
  providerId: string;
  connectionId?: string;
  providerName: string;
  providerIcon: ReactNode;
  description: string;
  setupRoute: string;
};

const StyledCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  borderRadius: '8px',
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.core.color3}`,
}));

const StyledCardActions = styled(CardActions)({
  padding: '16px',
});

const getBadgeStatus = (integrationStatus?: string) => {
  switch (integrationStatus) {
    case 'MISSING':
      return StatusBadgeVariant.NEW;
    case 'FAILED':
    case 'INACTIVE':
      return StatusBadgeVariant.INACTIVE;
    case 'ACTIVATING':
    case 'TESTING':
      return StatusBadgeVariant.PROGRESS;
    case 'ACTIVE':
    case 'PASSED':
      return StatusBadgeVariant.ACTIVE;
    default:
      return undefined;
  }
};

export const IntegrationSetupCard: FC<IntegrationSetupCardProps> = ({
  providerId,
  connectionId,
  providerName,
  providerIcon,
  description,
  setupRoute,
}) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const navigate = useNavigate();

  const [isDeactivateDialogOpened, setIsDeactivateDialogOpened] = useState(false);

  const {
    data: integration,
    isLoading: isIntegrationLoading,
    isError: isIntegrationError,
  } = useGetAccountIntegrationQuery({ accountId: accountId, integrationId: providerId });

  const { data: connection, isLoading: isConnectionLoading } = useCheckConnectionQuery(
    {
      accountId,
      integrationId: providerId,
      connectionId: connectionId ?? '',
    },
    { skip: !connectionId }
  );

  const {
    deactivateIntegration,
    isDeactivateAccountIntegrationInProgress,
    isRevokeAccountIntegrationInProgress,
    isRemoveWebhookInProgress,
  } = useDeactivateIntegration({
    providerId,
    connectionId,
    closeDialog: () => setIsDeactivateDialogOpened(false),
  });

  const navigateToSetup = () => {
    navigate(setupRoute);
  };

  const integrationStatus = connectionId ? connection?.status : integration?.status;
  const isIntegrationActive = integrationStatus === 'PASSED' || integrationStatus === 'ACTIVE';
  const badgeStatus = getBadgeStatus(integrationStatus);
  const isDeactivationInProgress =
    isDeactivateAccountIntegrationInProgress || isRevokeAccountIntegrationInProgress || isRemoveWebhookInProgress;
  const providerNameKebab = kebabCase(providerName);

  if (isIntegrationLoading || isConnectionLoading)
    return (
      <StyledCard variant="outlined">
        <Box p={24}>
          <Loader />
        </Box>
      </StyledCard>
    );

  return (
    <StyledCard variant="outlined">
      <CardHeader avatar={providerIcon} />
      <StyledCardContent>
        <IntegrationsCardContent
          title={providerName}
          description={description}
          statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
        />
      </StyledCardContent>
      <StyledCardActions>
        {isIntegrationError ? (
          t('INTEGRATIONS.STATUS.ERROR')
        ) : (
          <Stack direction="row" gap={2}>
            <Button
              variant="outline"
              size="S"
              color="neutral"
              onClick={navigateToSetup}
              disabled={isDeactivationInProgress}
              testId={
                isIntegrationActive ? `view-integration-${providerNameKebab}` : `setup-integration-${providerNameKebab}`
              }
            >
              {isIntegrationActive ? t('INTEGRATIONS.VIEW') : t('INTEGRATIONS.SETUP')}
            </Button>
            {isIntegrationActive && (
              <>
                <Button
                  onClick={() => setIsDeactivateDialogOpened(true)}
                  variant="ghost"
                  color="neutral"
                  size="S"
                  disabled={isDeactivationInProgress}
                  testId={`deactivate-integration-${providerNameKebab}`}
                >
                  {t('INTEGRATIONS.REVOKE_ACCESS')}
                </Button>
                <IntegrationSetupDialog
                  header={t('INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
                  content={t('INTEGRATIONS.REVOKE_DIALOG.DESCRIPTION', { providerName })}
                  buttonLabel={t('INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
                  isOpened={isDeactivateDialogOpened}
                  onSubmit={deactivateIntegration}
                  isLoading={isDeactivationInProgress}
                  onClose={() => setIsDeactivateDialogOpened(false)}
                />
              </>
            )}
          </Stack>
        )}
      </StyledCardActions>
    </StyledCard>
  );
};
