import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  Integration,
  useActivateAccountIntegrationMutation,
  useConfigureAccountIntegrationMutation,
  useConnectAccountIntegrationMutation,
  useLazyGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
  useTestAccountIntegrationMutation,
} from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { AWS_INTEGRATION_ID } from '../../AWSSetupCard/AWSSetupCard';

const useAWSTestIntegration = ({
  params,
  setCheckingParams,
  setCheckError,
  onAccountIntegrationError,
  onAccountIntegrationSuccess,
  revoke,
}: {
  params: {
    reportName?: string;
    s3BucketArn?: string;
    roleArn?: string;
  };
  setCheckingParams: (v: boolean) => void;
  setCheckError: (v: boolean) => void;
  onAccountIntegrationError: () => void;
  onAccountIntegrationSuccess: () => void;
  revoke?: boolean;
}) => {
  const { accountId } = useAccountContext();
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [configureAccountIntegrationMutation] = useConfigureAccountIntegrationMutation();
  const [testAccountIntegrationMutation] = useTestAccountIntegrationMutation();
  const [getAccountIntegrationQuery] = useLazyGetAccountIntegrationQuery();
  const [activateAccountIntegrationMutation] = useActivateAccountIntegrationMutation();
  const [revokeAccountIntegrationMutation] = useRevokeAccountIntegrationMutation();
  const { getFeature } = useFeatures();

  const onCheckAWSKeys = async () => {
    setCheckingParams(true);
    setCheckError(false);

    // Revoke any existing AWS integration before connect
    await revokeAccountIntegrationMutation({
      accountId: accountId!,
      integrationId: AWS_INTEGRATION_ID,
    });

    if (getFeature(FEATURES.AWS_COST_INSIGHT)?.properties?.activateIntegration) {
      try {
        const res = await configureAccountIntegrationMutation({
          accountId: accountId!,
          integrationId: AWS_INTEGRATION_ID,
          integrationConfigInput: {
            parameters: {
              secret: {},
              dataType: 'SYNTHETIC',
            },
          },
        });

        if ((res as { error: FetchBaseQueryError | SerializedError }).error) {
          // eslint-disable-next-line no-throw-literal
          throw new Error('configureAccountIntegrationMutation');
        }
      } catch (e) {
        setCheckingParams(false);
        setCheckError(true);
        return;
      }
    } else {
      try {
        const res = await connectAccountIntegrationMutation({
          accountId: accountId!,
          integrationId: AWS_INTEGRATION_ID,
          integrationConnectionInput: {
            parameters: {
              secret: params,
              dataType: 'REAL',
              ...params,
            },
          },
        });

        if ((res as { error: FetchBaseQueryError | SerializedError }).error) {
          // eslint-disable-next-line no-throw-literal
          throw new Error('connectAccountIntegrationMutation');
        }
      } catch (e) {
        setCheckingParams(false);
        setCheckError(true);
        return;
      }
    }

    try {
      const res = await testAccountIntegrationMutation({
        accountId: accountId!,
        integrationId: AWS_INTEGRATION_ID,
        testName: 'connection',
      });

      if ((res as { error: FetchBaseQueryError | SerializedError }).error) {
        // eslint-disable-next-line no-throw-literal
        throw new Error('testAccountIntegrationMutation');
      }
    } catch (e) {
      setCheckingParams(false);
      setCheckError(true);
      return;
    }

    const checkStatus = async () => {
      const accountIntegration = (await getAccountIntegrationQuery({
        accountId: accountId!,
        integrationId: AWS_INTEGRATION_ID,
      })) as unknown as { data: Integration };

      if (accountIntegration?.data?.connection?.status !== 'TESTING') {
        clearInterval(intervalId);

        if (accountIntegration?.data?.connection?.error) {
          onAccountIntegrationError();
        } else {
          try {
            const activateRes = await activateAccountIntegrationMutation({
              accountId: accountId!,
              integrationId: AWS_INTEGRATION_ID,
            });

            if ((activateRes as { error: FetchBaseQueryError | SerializedError }).error) {
              // eslint-disable-next-line no-throw-literal
              throw new Error('activateAccountIntegrationMutation');
            }

            if (revoke) {
              const revokeRes = await revokeAccountIntegrationMutation({
                accountId: accountId!,
                integrationId: AWS_INTEGRATION_ID,
              });

              if ((revokeRes as { error: FetchBaseQueryError | SerializedError }).error) {
                // eslint-disable-next-line no-throw-literal
                throw new Error('revokeAccountIntegrationMutation');
              }
            }

            onAccountIntegrationSuccess();
          } catch (e) {
            setCheckingParams(false);
            setCheckError(true);
          }
        }
      }
    };

    const intervalId = setInterval(checkStatus, 3000);
  };

  return {
    onCheckAWSKeys,
  };
};

export default useAWSTestIntegration;
