import { FileTransformer } from '@verticeone/design-system';
import React from 'react';
import { PrimitiveDynamicFormField, primitiveDynamicFormFieldSchema } from './primitiveTypes';
import { CompositeDynamicFormField, compositeDynamicFormFieldSchema } from './compositeTypes';
import { z } from 'zod';

export type DynamicFormField = PrimitiveDynamicFormField | CompositeDynamicFormField;

export type DynamicFormFieldWithId = DynamicFormField & { id: string };

export type DynamicFormSchema = {
  fields: DynamicFormField[];
};

export const dynamicFormFieldSchema = z.union([
  ...primitiveDynamicFormFieldSchema.options,
  compositeDynamicFormFieldSchema,
]);

// Some fields (e.g. FileUpload) might look slightly different in preview mode (drop area visible) vs. readOnly mode (only file list visible)
export type DynamicFormPresenterMode = 'readOnly' | 'readWrite' | 'preview';

export type DynamicFormPresenterConfig = {
  mode: DynamicFormPresenterMode;
  defaultCurrency: string;
  filePathPrefix: string;
  fileTransformer?: FileTransformer;
};

export type DynamicFormPresenterActions = React.ReactNode;
