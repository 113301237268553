import { createApi } from '@reduxjs/toolkit/query/react';
import prepareBaseQuery from '../baseQuery';
import { API_URLS } from '../constants';

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  tagTypes: ['integrations'],
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.INTEGRATIONS),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});
