import { Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@verticeone/design-system';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { isWorkflowInstanceRef, parseRequestRef } from '../../../../../../hooks/workflows/refUtils';
import { TaskNodeState, WorkflowTaskNodeType } from '../../../model/types';
import { checkServiceTaskHasDetail } from '../../../WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/utils';
import { useWorkflowRendererContext } from '../../WorkflowRendererContext';

type TaskActionProps = {
  state?: TaskNodeState;
  route?: string;
  taskNodeType?: WorkflowTaskNodeType;
};

export const TaskNavigateAction: FC<TaskActionProps> = ({ state, route, taskNodeType }) => {
  const { t } = useTranslation();
  const { accountId, allowVerticeServiceNavigation } = useWorkflowRendererContext();
  const { generatePath } = useRouteNavigate();

  if (taskNodeType === 'VERTICE_SERVICE' && !allowVerticeServiceNavigation) {
    return null;
  }

  if (taskNodeType === 'USER') {
    return null;
  }

  const isExecutedByWorkflow = state?.executionRef ? isWorkflowInstanceRef(state?.executionRef) : false;
  if (!state?.requestRef || !isExecutedByWorkflow || !route) {
    return null;
  }

  if (checkServiceTaskHasDetail(state)) {
    return null;
  }

  const requestId = parseRequestRef(state.requestRef).requestId;
  const requestAccountId = parseRequestRef(state.requestRef).accountId;

  const isCrossAccount = requestAccountId !== accountId;

  return (
    <Stack marginTop={1} sx={{ pointerEvents: 'all' }}>
      <Button
        component={Link}
        to={generatePath(route, { requestId, account_id: requestAccountId })}
        target={isCrossAccount ? '_blank' : '_self'}
      >
        {t('INTELLIGENT_WORKFLOWS.WORKFLOW_SCHEMA.GO_TO_PAGE')}
      </Button>
    </Stack>
  );
};
