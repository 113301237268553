import { P, match } from 'ts-pattern';

const TASK_CUSTOM_FORM_REGEX = /^.*:form\/.+?\/generic\/.+$/;
const TASK_REVIEW_CONTRACT_FORM_REGEX = /^.*:form\/core\/reviewContract\/.+$/;
const VISO_TRUST_RELATIONSHIP_CONTXT_REGEX = /^.*:form\/integrations\/visotrust\/relationshipQuestionnaire\/.+$/;

export type UserTaskFormKind = 'customForm' | 'reviewContractForm' | 'other' | 'visoTrustAssessmentContextForm';

export const classifyTaskFormUrn = (formUrn: string): UserTaskFormKind => {
  return match(formUrn)
    .returnType<UserTaskFormKind>()
    .with(P.string.regex(TASK_CUSTOM_FORM_REGEX), () => 'customForm')
    .with(P.string.regex(TASK_REVIEW_CONTRACT_FORM_REGEX), () => 'reviewContractForm')
    .with(P.string.regex(VISO_TRUST_RELATIONSHIP_CONTXT_REGEX), () => 'visoTrustAssessmentContextForm')
    .otherwise(() => 'other');
};
