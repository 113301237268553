import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';

import HostnameField, { PROTOCOL } from '../components/HostnameField';
import IntegrationNameField from '../components/IntegrationNameField';
import AuthField from '../components/AuthField';
import TestConnectionField from '../components/TestConnectionField';
import TestConnectionButton from '../components/TestConnectionButton';
import FormSection from '../components/FormSection';
import SaveIntegrationButton from '../components/SaveIntegrationButton';
import TestConnectionResponse from '../components/TestConnectionResponse';
import { CreateSchema, createSchema } from './scheme';
import { ApiKeySecret } from './utils';
import useTestConnection from '../../../../dataSource/useTestConnection';
import { useCreateIntegration } from '../../../../dataSource/useCreateIntegration';
import { generateIntegrationId, IntegrationItem, IntegrationType } from '../../../../utils';
import { useChangeIntegrationStatus } from '../../../../dataSource/useChangeIntegrationStatus';

type ApiKeyFormProps = {
  integrationType: IntegrationType;
  onSubmit?: () => void;
  onError?: () => void;
};

export const serialize = (integrationType: IntegrationType, data: CreateSchema) => ({
  integrationId: generateIntegrationId({ integrationName: data.name, integrationType, authType: 'ApiKey' }),
  integrationName: data.name,
  authorizationHeaderName: data.auth.authorizationHeaderName,
  baseUrl: `${data.auth.baseUrl.protocol}://${data.auth.baseUrl.hostname}`,
  secret: {
    type: 'ApiKey' as const,
    apiKey: data.auth.apiKey,
  },
});

const ApiKeyForm = ({ integrationType, onSubmit, onError }: ApiKeyFormProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const [isSaving, setIsSaving] = useState(false);
  const { activateIntegration } = useChangeIntegrationStatus();
  const [createIntegration] = useCreateIntegration<ApiKeySecret>();

  const { register, formState, handleSubmit, setValue, getValues, trigger, control } = useForm<CreateSchema>({
    resolver: zodResolver(createSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      auth: {
        apiKey: '',
        authorizationHeaderName: 'Authorization',
        baseUrl: {
          protocol: PROTOCOL,
          hostname: '',
          path: '',
        },
      },
    },
  });

  const [testConnection, testConnectionState] = useTestConnection({
    serialize: () => ({
      protocol: getValues('auth.baseUrl.protocol'),
      hostname: getValues('auth.baseUrl.hostname'),
      path: getValues('auth.baseUrl.path'),
      auth: {
        authType: 'apiKey',
        authHeaderName: getValues('auth.authorizationHeaderName'),
        apiKey: getValues('auth.apiKey'),
      },
    }),
  });

  const onFormSubmit = useCallback(
    (data: Required<IntegrationItem<ApiKeySecret>>) => {
      setIsSaving(true);

      return createIntegration(data)
        .then((response) => ('error' in response ? Promise.reject() : response))
        .then(() => activateIntegration(data.integrationId))
        .then(() => onSubmit?.())
        .catch(() => onError?.())
        .finally(() => setIsSaving(false));
    },
    [createIntegration, activateIntegration, onSubmit, onError]
  );

  return (
    <form onSubmit={handleSubmit((submittedData) => onFormSubmit(serialize(integrationType, submittedData)))}>
      <Stack direction="column" gap={6}>
        <IntegrationNameField name="name" register={register} control={control} />
        <AuthField authHeaderName="auth.authorizationHeaderName" apiKeyName="auth.apiKey" control={control} />

        <FormSection title={t('SECTION.CONNECTION')}>
          <HostnameField
            name="auth.baseUrl.hostname"
            register={register}
            setValue={setValue}
            control={control}
            trigger={trigger}
          />
          <Stack direction="row" alignItems="flex-end" gap={2}>
            <TestConnectionField
              name="auth.baseUrl.path"
              protocolPath="auth.baseUrl.protocol"
              hostnamePath="auth.baseUrl.hostname"
              register={register}
              control={control}
            />
            <TestConnectionButton
              control={control}
              validateFields={['auth.baseUrl', 'auth.apiKey']}
              onClick={testConnection}
              isLoading={testConnectionState.isLoading}
            />
          </Stack>
        </FormSection>

        <FormSection title={t('SECTION.TEST')}>
          <TestConnectionResponse {...testConnectionState} />
          <Stack direction="row">
            <SaveIntegrationButton isFormValid={formState.isValid} isDirty={formState.isDirty} isLoading={isSaving} />
          </Stack>
        </FormSection>
      </Stack>
    </form>
  );
};

export default ApiKeyForm;
