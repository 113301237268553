import React from 'react';
import ContractFormEntry from '../../ContractFormEntry';
import { ScopeReductionOverrideCheckboxField } from '../ScopeReductionOverrideCheckboxField';

type Component = typeof ScopeReductionOverrideCheckboxField;

export const ScopeReductionCostOverrideFormEntry = () => {
  return (
    <ContractFormEntry<Component, Component>
      name="costModel.negotiated.model.scopeReductionSavingsOverride.enabled"
      width={12}
      component={ScopeReductionOverrideCheckboxField}
      readFormFieldDisabled
      writeComponent={ScopeReductionOverrideCheckboxField}
    />
  );
};
