import { ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { assertExhausted } from '@verticeone/utils/logic';
import RollingFrequency from '../../Deadline/RollingFrequency';

type RollingFrequencyCellProps = {
  viewOnContract: ViewOnContract;
};

export const rollingFrequencyCellSortableValueGetter = (viewOnContract: ViewOnContract) => {
  const rollFrequency = viewOnContract.contract?.parts?.contractual?.lifecycle?.rollFrequency ?? 'NO';
  switch (rollFrequency) {
    case 'MONTHLY':
      return 'A';
    case 'QUARTERLY':
      return 'B';
    case 'BIANNUALLY':
      return 'C';
    case 'ANNUALLY':
      return 'D';
    case 'NO':
      return 'E';
    case 'ONE_OFF':
      return 'F';
    default:
      assertExhausted(rollFrequency);
      return 'G';
  }
};

export const RollingFrequencyCell = ({ viewOnContract }: RollingFrequencyCellProps) => {
  return <RollingFrequency contract={viewOnContract.contract} emptyValue="—" />;
};
