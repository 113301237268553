import { fetchedContractToForm } from '../transformations';
import fetchedContractFromContext from '../utils/fetchedContractFromContext';
import { ContractContextDataRequireFetched, ContractFormData, ContractTasksContextData } from '../types';
import { CancellationReason, Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import {
  contractHasNext,
  hasActiveRenewalRequest,
  isContractLiveOrNotYetLive,
  isContractWorkflowInProgress,
  isLiveContract,
  isRollingContract,
} from '../computed';
import { useState } from 'react';

export type CancellationVariant = 'CANCEL_REQUEST' | 'CANCEL_FIXED_TERM' | 'TERMINATE_ROLLING' | 'NOT_CANCELLABLE';

export const canTerminateContract = (contract: Contract) => isLiveContract(contract) && isRollingContract(contract);

/** Determines what are the available cancellation options for the contract in the given context */
export const getCancellationVariant = ({
  contract,
  tasksContext,
}: {
  contract: Contract;
  tasksContext?: ContractTasksContextData;
}): CancellationVariant => {
  if (hasActiveRenewalRequest(contract)) {
    return 'NOT_CANCELLABLE';
  }

  if (isContractLiveOrNotYetLive(contract) && !isRollingContract(contract) && !contractHasNext(contract)) {
    return 'CANCEL_FIXED_TERM';
  }

  if (canTerminateContract(contract)) {
    return 'TERMINATE_ROLLING';
  }

  if (
    tasksContext &&
    isContractWorkflowInProgress(contract) &&
    !!tasksContext.firstTask &&
    !tasksContext.isFulfillingFirstTask
  ) {
    return 'CANCEL_REQUEST';
  }

  return 'NOT_CANCELLABLE';
};

export type CancellationProperties = {
  reason?: CancellationReason;
  note?: string;
};

export type UseCancelOrTerminateContractProps = {
  contractContext: ContractContextDataRequireFetched;
  tasksContext?: ContractTasksContextData;
};

export type UseCancelOrTerminateContractReturn = {
  isCancellable: boolean;
  isTerminable: boolean;
  cancellationVariant: CancellationVariant;
  saveContractWithCancellationProperties: (cancelProps: CancellationProperties) => void;
  saveInProgress: boolean;
};

const useCancelOrTerminateContract = ({
  contractContext,
  tasksContext,
}: UseCancelOrTerminateContractProps): UseCancelOrTerminateContractReturn => {
  const [saveInProgress, setInProgress] = useState(false);
  const fetchedContract = contractContext.fetchedContract;
  const contract = fetchedContract.contract;

  const cancellationVariant = getCancellationVariant({ contract, tasksContext });
  const isCancellable = cancellationVariant !== 'NOT_CANCELLABLE';
  const isTerminable = cancellationVariant === 'TERMINATE_ROLLING';

  const saveContractWithCancellationProperties = (cancelProps: CancellationProperties) => {
    // store cancellation properties to contract
    const contractFormData = fetchedContractToForm(fetchedContractFromContext(contractContext));
    const newContractFormData: ContractFormData = {
      ...contractFormData,
      parts: {
        ...contractFormData.parts,
        contractual: {
          ...contractFormData.parts.contractual,
          lifecycle: {
            ...(contractFormData.parts.contractual.lifecycle || {}),
            cancellationReason: cancelProps.reason,
            cancellationNote: cancelProps.reason === 'OTHER' ? cancelProps.note : undefined,
            cancellationDate: new Date().toISOString(),
          },
        },
      },
    };
    // do not wait for update to finish for now

    setInProgress(true);
    void contractContext.saving.updateContract(newContractFormData).finally(() => setInProgress(false));
  };

  return {
    isCancellable,
    isTerminable,
    cancellationVariant,
    saveContractWithCancellationProperties,
    saveInProgress,
  };
};

export default useCancelOrTerminateContract;
