import { Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation, useNavigate } from 'react-router-dom';

import useLoggedUserAccountRoles from '@vertice/core/src/hooks/useLoggedUserAccountRoles';
import { Loader, PageHeader, Tab, Tabs, TabsBottomLineWrapper } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { toConstantCase } from '@verticeone/utils/strings';

const getTabLabelKey = (tabId: string) => `PREFERENCES.${toConstantCase(tabId)}.TAB`;

export const Preferences = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { PREFERENCES } = useRoutes();
  const { isUserAdmin } = useLoggedUserAccountRoles();
  const [isFullHeight, setIsFullHeight] = useState(false);

  const availableTabs = useMemo(
    () =>
      [
        {
          key: PREFERENCES.COMPANY.PATH,
          path: PREFERENCES.COMPANY.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.DIRECTORY.PATH,
          path: PREFERENCES.DIRECTORY.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.WORKFLOWS.PATH,
          path: PREFERENCES.WORKFLOWS.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
        {
          key: PREFERENCES.USER.PATH,
          path: PREFERENCES.USER.ABSOLUTE_PATH,
          isVisible: true,
        },
        {
          key: PREFERENCES.CUSTOMIZATION.PATH,
          path: PREFERENCES.CUSTOMIZATION.ABSOLUTE_PATH,
          isVisible: isUserAdmin,
        },
      ].filter(({ isVisible }) => isVisible),
    [PREFERENCES, isUserAdmin]
  );

  const activeTab = availableTabs.find(({ path }) => matchPath(`${path}/*`, location.pathname));

  useEffect(() => {
    if (!activeTab && availableTabs.length > 0) {
      navigate(availableTabs[0].path, { replace: true });
    }
  }, [activeTab, navigate, availableTabs, PREFERENCES]);

  const gap = isFullHeight ? 0 : 8;
  const padding = isFullHeight ? 0 : 6;

  return (
    <>
      {!isFullHeight && (
        <PageHeader
          breadcrumb={<PageHeader.Breadcrumbs items={[{ label: t('PREFERENCES.TITLE') }]} />}
          color="primary"
          actions={undefined}
        />
      )}
      <Stack gap={gap} p={padding} pt={0}>
        {activeTab ? (
          <>
            {!isFullHeight && availableTabs.length > 1 && (
              <TabsBottomLineWrapper>
                <Tabs variant="outlined" value={activeTab.path} onChange={(_, value) => navigate(value)}>
                  {availableTabs.map(({ path, key }) => (
                    <Tab key={path} value={path} label={t(getTabLabelKey(key))} />
                  ))}
                </Tabs>
              </TabsBottomLineWrapper>
            )}
            <Outlet context={{ setIsFullHeight }} />
          </>
        ) : (
          <Loader />
        )}
      </Stack>
    </>
  );
};
