import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import DateContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/DateContractFormEntry';
import BaseCurrencyFormEntry from '@vertice/core/src/modules/saas/contract/components/form/BaseCurrencyFormEntry';
import { ContractLengthComputedField } from '@vertice/core/src/modules/saas/contract/components/ContractLengthComputedField';
import RollFrequencyFormEntry from '@vertice/core/src/modules/saas/contract/components/form/RollFrequencyFormEntry';
import BillingFrequencyFormEntry from '@vertice/core/src/modules/saas/contract/components/form/BillingFrequencyFormEntry';
import CheckboxContractFormEntry from '@vertice/core/src/modules/saas/contract/components/form/CheckboxContractFormEntry';
import DepartmentFormEntry from '@vertice/core/src/modules/saas/contract/components/form/DepartmentFormEntry';
import ContractFormEntry from '@vertice/core/src/modules/saas/contract/ContractFormEntry';
import FormReadOnlyText from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import RenewalDateFormEntry from '@vertice/core/src/modules/saas/contract/components/form/RenewalDateFormEntry';
import NextRollDateComputedField from '@vertice/core/src/modules/saas/contract/components/NextRollDateComputedField/NextRollDateComputedField';
import { Grid } from '@verticeone/design-system';
import { FinancialSection } from '@vertice/core/src/modules/saas/contract/components/FinancialSection';
import AutoRenewalWaivedFormEntry from '@vertice/core/src/modules/saas/contract/components/form/AutoRenewalWaivedFormEntry';
import AutoRenewalDateFormEntry from '@vertice/core/src/modules/saas/contract/components/form/AutoRenewalDateFormEntry';
import PaymentTermsFormEntry from '@vertice/core/src/modules/saas/contract/components/form/PaymentTermsFormEntry';
import { ContractCategoryFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/ContractCategoryFormEntry';
import { NegotiationTierFormEntry } from '@vertice/core/src/modules/saas/contract/components/form/NegotiationTierFormEntry';

const ContractSummaryMainSection = () => {
  const { t } = useTranslation();

  return (
    <Stack py={6} gap={6}>
      <FinancialSection />

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <BaseCurrencyFormEntry
            name="parts.contractual.financial.baseCurrency"
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BillingFrequencyFormEntry
            name="parts.contractual.financial.billingFrequency"
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
            width={12}
          />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.financial.billingFrequencyOther"
            label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY_OTHER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <PaymentTermsFormEntry
            name="parts.contractual.financial.paymentTerms"
            label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS')}
            width={12}
          />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <ContractFormEntry
            name="parts.contractual.financial.paymentTermsOther"
            label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS_OTHER')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <RollFrequencyFormEntry
            name="parts.contractual.lifecycle.rollFrequency"
            label={t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DateContractFormEntry
            label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
            name="parts.contractual.lifecycle.startDate"
            width={12}
          />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <RenewalDateFormEntry />
          <NextRollDateComputedField />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <DateContractFormEntry
            name="parts.contractual.signatory.signingDate"
            label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
            width={12}
          />
        </Grid>
        <Grid item hideIfEmpty xs={12} md={6} lg={4}>
          <AutoRenewalDateFormEntry />
          <AutoRenewalWaivedFormEntry />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractLengthComputedField />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CheckboxContractFormEntry
            name="parts.contractual.renewal.markedForExpiration"
            label={t('ENTITIES.CONTRACT.LABELS.PLANNED_EXPIRATION')}
            width={12}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8} px={6}>
        <Grid item xs={12} md={6} lg={4}>
          <DepartmentFormEntry
            name="classification.departmentId"
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractCategoryFormEntry
            name="classification.category"
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CATEGORY')}
            width={12}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <NegotiationTierFormEntry
            name="classification.negotiationTier"
            label={t('ENTITIES.CONTRACT.LABELS.NEGOTIATION_TIER')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.NEGOTIATION_TIER'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.NEGOTIATION_TIER'),
            }}
            width={12}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
export default ContractSummaryMainSection;
