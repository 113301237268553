import { AdvancedLinearApproximationCostModel, ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { isAdvancedLinearApproximationModel } from '../costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import {
  isAnnualCostOverride,
  isScopeReductionOverride,
  isTotalCostOverride,
} from '../costModels/AdvancedLinearApproximation/utils';

export const filterAnnualOverrideFromCostModel = (costModel?: ContractModel2) => {
  if (!isAdvancedLinearApproximationModel(costModel)) return costModel;

  const model = costModel?.model as AdvancedLinearApproximationCostModel;
  const items = model.items.filter((item) => !isAnnualCostOverride(item));

  return { ...costModel, model: { ...model, items } } as ContractModel2;
};

export const filterTotalCostOverrideFromCostModel = (costModel?: ContractModel2) => {
  if (!isAdvancedLinearApproximationModel(costModel)) return costModel;

  const model = costModel?.model as AdvancedLinearApproximationCostModel;
  const items = model.items.filter((item) => !isTotalCostOverride(item));

  return { ...costModel, model: { ...model, items } } as ContractModel2;
};

export const filterScopeReductionOverrideFromCostModel = (costModel?: ContractModel2) => {
  if (!isAdvancedLinearApproximationModel(costModel)) return costModel;

  const model = costModel?.model as AdvancedLinearApproximationCostModel;
  const items = model.items.filter((item) => !isScopeReductionOverride(item));

  return { ...costModel, model: { ...model, items } } as ContractModel2;
};
