import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@verticeone/design-system';
import { Dialog, DialogActions, DialogContent, DialogHeader } from '@verticeone/design-system';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import ToBeNegotiatedByConfirmation from './ToBeNegotiatedByConfirmation';

type ContractDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contract: Contract;
};

const ToBeNegotiatedByConfirmationDialog = ({ open, onClose, onSuccess, contract }: ContractDialogProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'SAAS.TO_BE_NEGOTIATED_BY_CONFIRMATION.DIALOG' });

  return (
    <Dialog size="XL" open={open}>
      <DialogHeader size="M">{t('HEADER_TITLE')}</DialogHeader>
      <DialogContent>
        <ToBeNegotiatedByConfirmation contract={contract} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outline" size="M">
          {t('CLOSE')}
        </Button>
        <Button onClick={onSuccess} variant="solid" size="M" color="primary">
          {t('ACCEPT')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToBeNegotiatedByConfirmationDialog;
