import { useGetDashboardWidgetQuery } from '@vertice/slices/src/openapi/codegen/bffeDashboardAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { WidgetId } from './types';
import { VALID_WIDGET_IDS } from './constants';
import { mapWidgetIdToDemoData, useDashboardDemoData } from './useDashboardDemoData';
import { useDashboardContext } from './DashboardContext';

const useDashboardWidgetData = (widgetId: WidgetId) => {
  const { accountId } = useAccountContext();
  const { isDemoVisible } = useDashboardContext();

  if (!VALID_WIDGET_IDS.includes(widgetId)) {
    throw new Error(`Invalid widgetId: ${widgetId}`);
  }

  const demoData = useDashboardDemoData({ widgetId, accountId });
  const data = useGetDashboardWidgetQuery(
    { widgetId, accountId },
    {
      skip: isDemoVisible,
    }
  );

  return isDemoVisible && mapWidgetIdToDemoData[widgetId] ? demoData : data;
};

export default useDashboardWidgetData;
