import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { GoodFormOnSubmitCallback } from '@vertice/core/src/modules/forms/utils/goodFormUtils';
import { Integration, ListJiraProjectsApiResponse, useConfigureAccountIntegrationMutation } from '@vertice/slices';
import { useActivateIntegrationAndProvider } from '../../../../utils';
import { ProjectFormData } from '../components/ProjectForm';
import { EDIT_MODE_DISABLED, JIRA_PROVIDER_ID } from '../constants';
import { CardEditMode, JiraSetupStep } from '../types';

export const useActivateIntegration = (
  setStep: (step: JiraSetupStep) => void,
  setCardEditMode: (newValue: CardEditMode) => void,
  jiraIntegrationData?: Integration,
  jiraProjectsData?: ListJiraProjectsApiResponse
) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const { activate, isLoading: isLoadingActivation } = useActivateIntegrationAndProvider(
    JIRA_PROVIDER_ID,
    JIRA_PROVIDER_ID,
    accountId
  );

  const [configureAccountIntegration, { isLoading: isLoadingConfiguration }] = useConfigureAccountIntegrationMutation();

  const activateIntegration: GoodFormOnSubmitCallback<ProjectFormData> = async (formData) => {
    const prevParameters = jiraIntegrationData?.parameters;
    await configureAccountIntegration({
      accountId: accountId,
      integrationId: JIRA_PROVIDER_ID,
      integrationConfigInput: {
        parameters: {
          ...prevParameters,
          projects: jiraProjectsData?.projects?.filter((project) => formData.jiraProject.includes(project.id)) ?? [],
        },
      },
    });

    activate()
      .then(() => {
        enqueueSnackbar({
          description: t('INTEGRATIONS.JIRA.SNACKBAR.ACTIVATE_CONNECTION_SUCCESS_DESC'),
          variant: 'success',
        });
        setCardEditMode(EDIT_MODE_DISABLED);
        setStep(JiraSetupStep.SUCCESS);
      })
      .catch(() => {
        enqueueSnackbar({
          message: t('INTEGRATIONS.JIRA.SNACKBAR.ACTIVATE_CONNECTION_ERROR_MESSAGE'),
          description: t('INTEGRATIONS.JIRA.SNACKBAR.ACTIVATE_CONNECTION_ERROR_DESC'),
          variant: 'error',
        });
      });
  };

  return { activateIntegration, isLoadingActivation, isLoadingConfiguration };
};
