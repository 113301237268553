import { ChangeEvent, FC } from 'react';
import { FieldCard } from '../../DynamicFormBuilder';
import { DataSourcedFieldType } from './types';
import { dataSourcesBySource } from './dataSources';
import { useTranslation } from 'react-i18next';
import { BuilderCardProps } from '../types';

export const DataSourcedFieldBuilderCard: FC<BuilderCardProps<DataSourcedFieldType>> = (props) => {
  const { t } = useTranslation();
  const { field, onRemove, onEdit, onChange } = props;
  const { source, label, required } = field.metadata;
  const fieldTypeLabel = t(dataSourcesBySource[source]?.labelI18nKey);

  const handleRequiredChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (onChange) onChange({ ...field.metadata, required: checked });
  };

  return (
    <FieldCard.Header>
      <FieldCard.Header.Label fieldType={field.type} fieldTypeTextOverride={fieldTypeLabel} required={required}>
        {label}
      </FieldCard.Header.Label>
      <FieldCard.Header.Actions>
        <FieldCard.RequiredSwitchAction checked={required} onChange={handleRequiredChange} />
        <FieldCard.EditAction onClick={onEdit} />
        <FieldCard.RemoveAction onClick={onRemove} />
      </FieldCard.Header.Actions>
    </FieldCard.Header>
  );
};
