import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';
import { VerticeSlackIntegration } from '@vertice/assets';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import WizardStepTemplate from '../../../../../../components/WizardStepTemplate';
import { WizardStepProps } from '../../../../../../components/Wizard';
import { BulletList } from '../../../../../../components/WizardSteps';
import { STEPS, SLACK_PROVIDER_ID } from '../../common';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  Oauth2ConnectionStatus,
  useConnectAccountIntegrationMutation,
  useInitiateOauth2AccountIntegrationMutation,
} from '@vertice/slices';
import { useSnackbar } from 'notistack';
import { Alert } from '@verticeone/design-system';
import { InfoOutlined } from '@mui/icons-material';

type StartStepProps = WizardStepProps & {
  setConnectionWindow: (testingInProgress: any) => void;
};

export const StartStep = ({ setStep, setConnectionWindow }: StartStepProps) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();

  const [processingUrl, setProcessingUrl] = useState(false);
  const [connectAccountIntegrationMutation] = useConnectAccountIntegrationMutation();
  const [initiateOauth2AccountIntegration] = useInitiateOauth2AccountIntegrationMutation();

  const onTestConnection = async () => {
    setProcessingUrl(true);

    await connectAccountIntegrationMutation({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
      integrationConnectionInput: {
        parameters: {
          secret: {},
        },
      },
    });

    const response = await initiateOauth2AccountIntegration({
      accountId,
      integrationId: SLACK_PROVIDER_ID,
    });

    if ((response as { error: FetchBaseQueryError | SerializedError }).error) {
      setStep(STEPS.CONNECTION_ERROR);
    } else {
      const windowReference = window.open(undefined, '_blank');
      if (!windowReference) {
        enqueueSnackbar(t('INTEGRATIONS.SLACK.WIZARD.OPENING_WINDOW_NOT_ALLOWED'), {
          variant: 'error',
        });
      } else {
        windowReference.location = (response as { data: Oauth2ConnectionStatus })?.data?.authorizationUrl as string;
        setConnectionWindow(windowReference);
        setStep(STEPS.CHECK_CONNECTION);
      }
      setProcessingUrl(false);
    }
  };

  const header = (
    <Stack gap={spacing(2)}>
      <Stack direction="row">
        <Text variant="heading">{t('INTEGRATIONS.SLACK.WIZARD.HEADER')}</Text>
        <Text variant="heading" color="text3">
          &nbsp;{t('INTEGRATIONS.ALERTING.SUBTITLE')}
        </Text>
      </Stack>
      <Text variant="body-regular">{t('INTEGRATIONS.SLACK.DESCRIPTION')}</Text>
    </Stack>
  );

  const content = (
    <Stack gap={spacing(4)}>
      <Stack gap={spacing(2)}>
        <Text variant="caption" size={'XS'} color="text-2">
          {t('INTEGRATIONS.SLACK.WIZARD.PREREQUISITES')}
        </Text>
        <BulletList>
          <li>
            <Text variant="body-regular">{t('INTEGRATIONS.SLACK.WIZARD.PREREQUISITE_1')}</Text>
          </li>
          <li>
            <Text variant="body-regular">{t('INTEGRATIONS.SLACK.WIZARD.PREREQUISITE_2')}</Text>
          </li>
          <li>
            <Text variant="body-regular">{t('INTEGRATIONS.SLACK.WIZARD.PREREQUISITE_3')}</Text>
          </li>
        </BulletList>
      </Stack>
      <Alert
        icon={InfoOutlined}
        title={t('INTEGRATIONS.SLACK.WIZARD.INFO_HEADER')}
        subtitle={t('INTEGRATIONS.SLACK.WIZARD.INFO_DESCRIPTION')}
      />
    </Stack>
  );

  return (
    <WizardStepTemplate
      headerImage={<VerticeSlackIntegration />}
      header={header}
      content={content}
      buttons={[
        <Button variant="solid" color="primary" isCaption size="S" onClick={onTestConnection} isLoading={processingUrl}>
          {t('INTEGRATIONS.CONNECT')}
        </Button>,
      ]}
      scrollable={false}
    />
  );
};
