import React from 'react';
import { Stack } from '@mui/material';
import ConcessionsContractFormEntry from './form/ConcessionsContractFormEntry';
import { useTranslation } from 'react-i18next';
import ContractFormEntry from '../ContractFormEntry';
import FormReadOnlyText from '../../../forms/fields/FormReadOnlyText';
import FormTextField from '../../../forms/fields/FormTextField';
import { useAllFieldsValid } from '../hooks/useAllFieldsValid';
import { ScopeReductionCostFormEntry } from './form/ScopeReductionCostFormEntry';
import { ScopeReductionCostOverrideFormEntry } from './form/ScopeReductionCostOverrideFormEntry';
import { ScopeReductionTCVFormEntry } from './form/ScopeReductionTCVFormEntry';
import { Grid } from '@verticeone/design-system';

export const useIsSavingsBreakdownTabValid = () => {
  return useAllFieldsValid([
    'costModel.negotiated.model.scopeReductionSavingsOverride.amount',
    'costModel.negotiated.model.scopeReductionSavingsOverride.description',
    'costModel.negotiated.model.concessions',
  ]);
};

const SavingsBreakdownTab = () => {
  const { t } = useTranslation();

  const descriptionPathName = 'costModel.negotiated.model.scopeReductionSavingsOverride.description';

  return (
    <Stack p={6} gap={6}>
      <Grid container direction="row" spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <>
            <ScopeReductionCostFormEntry />
            <ScopeReductionCostOverrideFormEntry />
          </>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ContractFormEntry
            name={descriptionPathName}
            label={t('ENTITIES.CONTRACT.LABELS.SCOPE_REDUCTION_SAVINGS_DESCRIPTION')}
            component={FormReadOnlyText}
            writeComponent={FormTextField}
            width={12}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={8} hideIfEmpty>
        <Grid item xs={12} md={6} lg={4} hideIfEmpty>
          <ScopeReductionTCVFormEntry />
        </Grid>
      </Grid>
      <ConcessionsContractFormEntry name="costModel.negotiated.model.concessions" width={12} />
    </Stack>
  );
};

export default SavingsBreakdownTab;
