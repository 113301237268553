import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { Enabled, IntegrationItem, parseIntegrationId } from '../utils';

type UseGetIntegrationByIdProps = {
  integrationId?: string;
};

export const useGetIntegrationById = ({ integrationId }: UseGetIntegrationByIdProps) => {
  const { accountId } = useAccountContext();

  return useGetAccountIntegrationQuery(
    {
      accountId,
      integrationId: integrationId!,
    },
    {
      skip: !integrationId,
      selectFromResult: (result) => {
        const { integrationName, authType } = parseIntegrationId(integrationId!);
        return {
          ...result,
          data: {
            integrationName,
            integrationId,
            authorizationHeaderName: result.data?.parameters?.authorizationHeaderName,
            baseUrl: result.data?.parameters?.baseUrl,
            enabled: result.data?.status === 'ACTIVE',
            secret: {
              type: authType,
            },
          } as IntegrationItem & Enabled,
        };
      },
    }
  );
};
