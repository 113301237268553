import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useRevalidateWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';

import { useAccountContext } from '../../../../../account/AccountContext';
import { createServiceIdentifier } from '../../../../../../hooks/workflows/refUtils';
import type { WorkflowVersionRow } from '../../types';

export const useGoToWorkflowEditor = (workflowVersion: WorkflowVersionRow) => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { accountId } = useAccountContext();

  const [revalidate] = useRevalidateWorkflowVersionMutation();

  const goToEditor = () => {
    // lazy load validation data for old definitions or refresh due to validation rule changes
    void revalidate({ accountId, workflowId: workflowVersion.id, workflowVersion: workflowVersion.versionId });

    if (workflowVersion.serviceRef) {
      navigate(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL.VERSION.EDITOR, {
        workflowId: workflowVersion.id,
        versionId: workflowVersion.versionId,
        serviceId: createServiceIdentifier(workflowVersion.serviceRef),
      });
    }
  };

  return { goToEditor };
};
