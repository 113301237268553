import { PaymentTerms } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import usePaymentTermsOptions from '../../../hooks/usePaymentTermsOptions';
import { EllipsisTextCell } from './EllipsisTextCell';

export type PaymentTermsCellProps = { value: PaymentTerms };

export const PaymentTermsCell = ({ value }: PaymentTermsCellProps) => {
  const { getLabel } = usePaymentTermsOptions();
  return <EllipsisTextCell value={getLabel(value) ?? undefined} />;
};
