import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { sum, head } from 'lodash';

export const QUERY_KEY = 'EDPConfiguration' as const;

const EDPConfigurationQuery = graphql(`
  query EDPConfiguration($accountId: String!, $startDate: String!) {
    listEDPContracts(params: { accountId: $accountId, contractStatus: ACTIVE }) {
      __typename
      ... on EDPContractListing {
        items {
          ... on EDPContractFacade {
            costModelContainer {
              ... on CostModelContainer {
                costModel {
                  endDate
                  costAllocationSpans {
                    startDate
                    endDate
                  }
                }
              }
            }
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
    athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_edp_eligibility_v1", parameters: ["{accountId}", $startDate] }
    ) {
      __typename
      ... on DataTableResult {
        table(columns: ["aws_infra_spend", "eligible_marketplace_spend"]) {
          data
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
  }
`);

export type EDPConfigurationData = {
  isActive: boolean;
  eligibleSpend: number;
  endDate: string | null;
  isEligibleSpendLoading: boolean;
  currentTerm?: {
    startDate: string;
    endDate: string;
  };
};

export const useEDPConfigurationData = () => {
  const { accountId } = useAccountContext();
  const startDate = useMemo(() => dayjs().subtract(1, 'year').startOf('month').format('YYYY-MM-DD'), []);
  const { fetchCloudOptimization } = useCloudClient();

  return useQuery({
    queryKey: [QUERY_KEY, startDate],
    queryFn: () =>
      fetchCloudOptimization(EDPConfigurationQuery, {
        accountId: accountId!,
        startDate,
      }),
    enabled: !!accountId,
    placeholderData: keepPreviousData,
    refetchInterval: (data) => {
      return data.state.data?.athenaViewQuery?.__typename === 'DeferredQueryResult' ? 2000 : false;
    },
    select: (data): EDPConfigurationData => {
      const eligibleSpend =
        data.athenaViewQuery?.__typename === 'DataTableResult'
          ? sum(data.athenaViewQuery.table?.data?.[0]?.map((value) => Number(value || 0)))
          : 0;

      const isEligibleSpendLoading = data.athenaViewQuery?.__typename === 'DeferredQueryResult';

      if (data.listEDPContracts?.__typename === 'EDPContractListing') {
        const currentTerm = head(
          data.listEDPContracts.items?.flatMap((item) =>
            item?.costModelContainer.costModel?.costAllocationSpans.filter((span) => {
              const now = dayjs();
              const termStartDate = dayjs(span.startDate);
              const termEndDate = dayjs(span.endDate);
              return termStartDate.isBefore(now) && termEndDate.isAfter(now);
            })
          )
        );

        return {
          isActive: (data?.listEDPContracts?.items || []).length > 0,
          eligibleSpend,
          endDate: data.listEDPContracts.items?.[0]?.costModelContainer?.costModel?.endDate,
          isEligibleSpendLoading,
          currentTerm,
        };
      }

      return {
        isActive: false,
        eligibleSpend,
        endDate: null,
        isEligibleSpendLoading,
      };
    },
  });
};
