import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { parseUserRef, parseUserTaskRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useFulfillUserTaskMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useLoggedUser } from '@verticeone/auth/src';
import { Alert, AlertButton, Loader, Text } from '@verticeone/design-system';
import { isVerticeServiceConfiguration } from '../../pocWorkflowSchema';
import { getLastPartDiversioned } from '../../utils';
import { JiraCreateComplete } from '../../workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraCreate/JiraCreateComplete';
import { JiraSyncComplete } from '../../workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/JiraSync/JiraSyncComplete';
import {
  GENERIC_INTEGRATION_OUTBOUND_SERVICE_URN_SUFFIX,
  JIRA_CREATE_TICKET_SERVICE_URN_SUFFIXES,
  JIRA_SYNC_TICKET_SERVICE_URN_SUFFIXES,
} from '../../workflowSchema/WorkflowEditor/EditNodeDrawers/EditServiceTaskDrawer/utils';
import { FORM_CONFIGURATION_KIND } from './constants';
import { ServiceTaskDetail } from './ServiceTaskDetail/ServiceTaskDetail';
import { useServiceTaskDetailDef } from './ServiceTaskDetail/useServiceTaskDetailDef';
import {
  VISOTRUST_CREATE_ASSESSMENT_SERVICE_URN_SUFFIX,
  VISOTRUST_CREATE_RELATIONSHIP_SERVICE_URN_SUFFIX,
  VISOTRUST_SYNC_ASSESSMENT_SERVICE_URN_SUFFIX,
} from './ServiceTaskDetail/VisoTrust/constants';
import { CreateRelationshipComplete } from './ServiceTaskDetail/VisoTrust/CreateRelationshipComplete';
import { CreateAssessmentComplete } from './ServiceTaskDetail/VisoTrust/CreateAssessmentComplete';
import { SyncAssessmentComplete } from './ServiceTaskDetail/VisoTrust/SyncAssessmentComplete';
import { useTaskContext } from './TaskContext';
import { TaskContractReviewFormSection, TaskContractReviewResultSection } from './TaskContractReviewForm';
import { TaskCustomFormResultSection, TaskCustomFormSection } from './TaskCustomForm';
import TaskOutcomeDetails from './TaskOutcomeDetails';
import TaskPredefinedForm from './TaskPredefinedForm';
import {
  isSaaSApprovalTask,
  SAAS_APPROVAL_FORM_CONFIG,
} from './TaskPredefinedForm/predefinedForms/ApprovalSimpleFormSaaS/formDef';
import { TaskPredefinedFormResult } from './TaskPredefinedForm/TaskPredefinedFormResult';
import { classifyTaskFormUrn } from './utils';
import { AssessmentContextFormSection } from './TaskSpecialForm/VisoTrust/AssessmentContextForm/AssessmentContextFormSection';
import { AssessmentContextResultSection } from './TaskSpecialForm/VisoTrust/AssessmentContextForm/AssessmentContextResultSection';

type TaskDrawerContentProps = {
  onCompleted: () => void;
};

export const TaskDrawerContent = ({ onCompleted }: TaskDrawerContentProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { accountId } = useAccountContext();
  const { userId } = useLoggedUser();
  const { taskOverview } = useTaskContext();

  const [fulfillUserTask] = useFulfillUserTaskMutation();
  const [taskCompletionResult, setTaskCompletionResult] = useState<'SUCCESS' | 'ERROR' | undefined>();

  const handleCompleteTask = useCallback(
    async (resultBody: object) => {
      if (taskOverview) {
        const response = await fulfillUserTask({
          accountId,
          taskId: parseUserTaskRef(taskOverview.task.ref).taskId,
          body: resultBody,
        });

        if ('error' in response) {
          setTaskCompletionResult('ERROR');
        } else {
          setTaskCompletionResult('SUCCESS');

          enqueueSnackbar(
            t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETED_SUCCESS', { taskName: taskOverview?.task.name }),
            {
              variant: 'success',
            }
          );

          onCompleted();
        }
      }
    },
    [accountId, enqueueSnackbar, fulfillUserTask, onCompleted, t, taskOverview]
  );

  const formConfig = isSaaSApprovalTask(taskOverview)
    ? SAAS_APPROVAL_FORM_CONFIG
    : taskOverview?.task.configurations?.find((configuration) => configuration.kind === FORM_CONFIGURATION_KIND);
  const currentUserAssigned = taskOverview?.task.assignment?.some(
    (assignment) => parseUserRef(assignment).userId === userId
  );
  const taskCompleted =
    taskOverview?.task.status === 'COMPLETED' ||
    taskOverview?.task.status === 'TERMINATED' ||
    taskOverview?.task.status === 'FAILED';
  const serviceConfigUrn = taskOverview?.task.configurations?.find(isVerticeServiceConfiguration)?.resourceUrn;
  const urnLastPartDiversioned = getLastPartDiversioned(serviceConfigUrn) ?? '';
  const isGenericIntegrationOutbound = urnLastPartDiversioned === GENERIC_INTEGRATION_OUTBOUND_SERVICE_URN_SUFFIX;
  const isJiraCreateTask = JIRA_CREATE_TICKET_SERVICE_URN_SUFFIXES.includes(urnLastPartDiversioned);
  const isJiraSyncTask = JIRA_SYNC_TICKET_SERVICE_URN_SUFFIXES.includes(urnLastPartDiversioned);
  const isVisoTrustCreateRelationshipTask = VISOTRUST_CREATE_RELATIONSHIP_SERVICE_URN_SUFFIX === urnLastPartDiversioned;
  const isVisoTrustCreateAssessmentTask = VISOTRUST_CREATE_ASSESSMENT_SERVICE_URN_SUFFIX === urnLastPartDiversioned;
  const isVisoTrustSyncAssessmentTask = VISOTRUST_SYNC_ASSESSMENT_SERVICE_URN_SUFFIX === urnLastPartDiversioned;
  const serviceTaskDetailDef = useServiceTaskDetailDef(taskOverview);

  if (!taskOverview) {
    return <Loader size={20} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} />;
  }

  if (serviceTaskDetailDef) {
    return <ServiceTaskDetail taskOverview={taskOverview} definition={serviceTaskDetailDef} />;
  }

  if (isJiraCreateTask && taskCompleted) {
    return <JiraCreateComplete taskOverview={taskOverview} />;
  }

  if (isJiraSyncTask && taskCompleted) {
    return <JiraSyncComplete taskOverview={taskOverview} />;
  }

  if (isVisoTrustCreateRelationshipTask && taskCompleted) {
    return <CreateRelationshipComplete taskOverview={taskOverview} />;
  }

  if (isVisoTrustCreateAssessmentTask && taskCompleted) {
    return <CreateAssessmentComplete taskOverview={taskOverview} />;
  }

  if (isVisoTrustSyncAssessmentTask && taskCompleted) {
    return <SyncAssessmentComplete taskOverview={taskOverview} />;
  }

  if (isGenericIntegrationOutbound) {
    return (
      <TaskOutcomeDetails
        success={taskOverview.task.result?.result.success}
        statusCode={taskOverview.task.result?.statusCode}
        headers={taskOverview.task.result?.headers}
        body={taskOverview.task.result?.body}
      />
    );
  }

  if (!formConfig || (!currentUserAssigned && !taskCompleted)) {
    return (
      <Stack minHeight={0} overflow="hidden" height="100%" direction="row">
        <Stack width="100%" display="flex" />
      </Stack>
    );
  }

  const formKind = classifyTaskFormUrn(formConfig.formUrn);
  return (
    <Stack minHeight={0} overflow="hidden" height="100%">
      {/*completion error*/}
      {taskCompletionResult === 'ERROR' && (
        <Stack p={6}>
          <Alert
            size="S"
            variant="ghost"
            color="error"
            title={t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETION_FAILED')}
          >
            <AlertButton onClick={() => setTaskCompletionResult(undefined)}>{t('DIALOG.BUTTONS.CLOSE')}</AlertButton>
          </Alert>
        </Stack>
      )}

      {/*editable task form*/}
      {taskCompletionResult === undefined && !taskCompleted && (
        <Stack flexDirection="row" minHeight={0} height="100%">
          {match(formKind)
            .with('customForm', () => (
              <TaskCustomFormSection
                formUrn={formConfig.formUrn}
                onSubmit={handleCompleteTask}
                taskOverview={taskOverview}
              />
            ))
            .with('reviewContractForm', () => <TaskContractReviewFormSection onSubmit={handleCompleteTask} />)
            .with('visoTrustAssessmentContextForm', () => (
              <AssessmentContextFormSection onSubmit={handleCompleteTask} />
            ))
            .with('other', () => (
              <TaskPredefinedForm onSubmit={handleCompleteTask} onCancel={() => null} formConfig={formConfig} />
            ))
            .exhaustive()}
        </Stack>
      )}

      {/*read-only task form*/}
      {taskCompletionResult === undefined && taskCompleted && (
        <Stack direction="row" minHeight={0} height="100%">
          <Stack gap={4} p={6} alignItems="center" width="100%" overflow="auto">
            <Text variant="heading" size="M">
              {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.TASK_COMPLETE_HEADING')}
            </Text>
            {match(formKind)
              .with('customForm', () => (
                <TaskCustomFormResultSection formUrn={formConfig.formUrn} result={taskOverview.task.result} />
              ))
              .with('reviewContractForm', () => <TaskContractReviewResultSection result={taskOverview.task.result} />)
              .with('visoTrustAssessmentContextForm', () => (
                <AssessmentContextResultSection result={taskOverview.task.result} />
              ))
              .with('other', () => <TaskPredefinedFormResult formConfig={formConfig} />)
              .exhaustive()}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
