import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { useTheme } from '@mui/material';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { useTranslation } from 'react-i18next';
import { CompletedRequestsByOutcomeData } from './useCompletedRequestsByOutcomeData';

type CompletedRequestsByOutcomeGraphProps = {
  data: CompletedRequestsByOutcomeData['data'];
};

const CompletedRequestsByOutcomeGraph = ({ data }: CompletedRequestsByOutcomeGraphProps) => {
  const [setChartRef] = useChartRef();
  const { palette, typography } = useTheme();
  const applyStyledHighcharts = useStyledHighcharts();
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });

  const succeededLabel = t('COMPLETED_REQUESTS_BY_OUTCOME.LEGEND.SUCCEEDED');
  const rejectedLabel = t('COMPLETED_REQUESTS_BY_OUTCOME.LEGEND.REJECTED');

  const series = useMemo(
    () => [
      {
        name: 'succeeded',
        visible: data.succeeded > 0,
        data: [
          {
            id: 'succeeded',
            name: succeededLabel,
            color: palette.visualization.divergent.tertiary['+10'],
            value: data.succeeded,
            dataLabels: {
              style: {
                color: palette.success.color1,
              },
            },
          },
        ],
      },
      {
        name: 'rejected',
        visible: data.rejected > 0,
        data: [
          {
            id: 'rejected',
            name: rejectedLabel,
            color: palette.visualization.divergent.secondary['+10'],
            value: data.rejected,
            dataLabels: {
              style: {
                color: palette.error.color1,
              },
            },
          },
        ],
      },
    ],
    [succeededLabel, data, palette, rejectedLabel]
  );

  const options = useMemo(
    () =>
      buildOptions([
        applyStyledHighcharts,
        mergeOptions({
          chart: {
            type: 'packedbubble',
            height: 348,
          },
          title: {
            text: '',
          },
          plotOptions: {
            packedbubble: {
              minSize: '45%',
              maxSize: '100%',
              layoutAlgorithm: {
                splitSeries: false,
                enableSimulation: false,
                gravitationalConstant: 0.02,
                bubblePadding: 8,
              },
              dataLabels: {
                enabled: true,
                useHTML: true,
                format:
                  '<div style="display: flex; gap: 0; flex-direction: column; align-items: center;"><span style="font-size: 36px; margin: 0; padding: 0; font-weight: 600">{point.value}</span> <span style="font-size: 14px; font-weight: 500">{point.name}</span></div>',
                filter: {
                  property: 'y',
                  operator: '>',
                  value: 0,
                },
                style: {
                  textOutline: 'none',
                  fontFamily: typography.fontFamily,
                  fontSize: '0px',
                },
              },
            },
          },
          tooltip: { shared: true, enabled: false },
          series: series as SeriesOptionsType[],
        }),
      ]),
    [applyStyledHighcharts, typography.fontFamily, series]
  );

  return <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />;
};

export default CompletedRequestsByOutcomeGraph;
