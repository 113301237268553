import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardHeaderDescription, Text, useSimpleDialogContext } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';

import { parseIntegrationId } from '../utils';
import ApiKeyEditForm from './components/Form/apiKey/EditForm';
import IntegrationSetupContainer from '../../components/IntegrationSetupContainer';
import { OutletContextType } from '../../types';

const EditIntegration = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTEGRATIONS.CUSTOM.FORM' });
  const { getConfirmation } = useSimpleDialogContext();
  const { integrationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  const { setBreadcrumbsItems } = useOutletContext<OutletContextType>();

  const { integrationName, authType } = parseIntegrationId(integrationId || '');

  useEffect(() => {
    setBreadcrumbsItems([
      {
        label: t('EDIT_TITLE', { integrationName }),
      },
    ]);

    return () => {
      setBreadcrumbsItems([]);
    };
  }, [integrationName, setBreadcrumbsItems, t]);

  if (!integrationId) {
    navigate(routes.INTEGRATIONS);
    return null;
  }

  const onSubmit = () => {
    enqueueSnackbar(t('SNACKBAR_MSG.EDIT.SUCCESS'), { variant: 'success' });
    navigate(routes.INTEGRATIONS.CUSTOM);
  };

  const onDismiss = async () => {
    const confirm = await getConfirmation({
      title: t('DISMISS_EDIT_DIALOG.TITLE'),
      description: (
        <Stack gap={2} direction="column" textAlign="center">
          <Text variant="body-bold" size="M" color="text2">
            {t('DISMISS_EDIT_DIALOG.SUB_TITLE')}
          </Text>
          <Text variant="body-regular" size="M" color="text2">
            {t('DISMISS_EDIT_DIALOG.DESCRIPTION')}
          </Text>
        </Stack>
      ),
      okButton: {
        label: t('DISMISS_BUTTON'),
      },
    });
    if (confirm.status === 'ok') {
      navigate(routes.INTEGRATIONS.CUSTOM);
    }
  };

  const onError = () => {
    enqueueSnackbar(t('SNACKBAR_MSG.EDIT.ERROR'), { variant: 'error' });
    navigate(routes.INTEGRATIONS.CUSTOM);
  };

  return (
    <IntegrationSetupContainer>
      <Card maxWidth={1280} width="100%" marginX="auto">
        <CardHeader>
          <CardHeaderDescription>
            <Text variant="heading" size="S" color="text1">
              {t('EDIT_TITLE', { integrationName })}
            </Text>
          </CardHeaderDescription>
        </CardHeader>
        <Stack gap={4} p={6}>
          {authType === 'ApiKey' && (
            <ApiKeyEditForm integrationId={integrationId} onSubmit={onSubmit} onError={onError} onDismiss={onDismiss} />
          )}
        </Stack>
      </Card>
    </IntegrationSetupContainer>
  );
};

export default EditIntegration;
