import React, { useId } from 'react';
import { Card } from '@verticeone/design-system';
import { StatsBody, StyledFieldset, Title, Value } from '@vertice/core/src/components/StatsBar';
import { CardProps, styled } from '@mui/material';
import GradientStatsTile from '@vertice/core/src/components/StatsBar/StatsTile/GradientStatsTile';
import { useFormatCurrency, useFormatPercentage } from '@verticeone/utils/formatting';
import { useTranslation } from 'react-i18next';
import { useGetContractsStatisticsQuery } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';
import useSavingsDashboard from '../../savings/useSavingsDashboard';
import { Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '@verticeone/router/useRoutes';
import { Grid } from '@verticeone/design-system';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';

type TotalSavingsCardProps = CardProps;

const FullHeightGradientStatsTile = styled(GradientStatsTile)(({ theme }) => ({
  padding: 0,
  height: '100%',
  [StyledFieldset]: {
    padding: 0,
    height: '100%',
  },
}));

const TransparentStatsBody = styled(StatsBody)(({ theme }) => ({
  backgroundColor: theme.palette.transparent.color4,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(4),
}));

export const TotalSavingsCard = ({ sx, ...otherProps }: TotalSavingsCardProps) => {
  const { accountId } = useAccountContext();
  const { isLoading, data } = useGetContractsStatisticsQuery({ accountId });
  const stats = data?.stats;
  const totalSavingsAmount = stats?.totalSavings.amount;
  const averageSavingsPercentage = stats?.averageSavingsPercentage;
  const showAverageSavings = totalSavingsAmount && averageSavingsPercentage;

  const firstLabelId = useId();
  const secondLabelId = useId();

  const formatCurrency = useFormatCurrency();
  const formatPercentage = useFormatPercentage();
  const { t } = useTranslation();

  const { enabled: savingsDashboardEnabled } = useSavingsDashboard();
  const routes = useRoutes();

  return (
    <Card
      component={savingsDashboardEnabled ? RouterLink : undefined}
      {...(savingsDashboardEnabled ? { to: routes.DASHBOARD.SAVINGS.ABSOLUTE_PATH } : {})}
      sx={{ textDecoration: 'none', ...sx }}
      {...otherProps}
    >
      <FullHeightGradientStatsTile>
        <Grid container height="100%" p={2}>
          <Grid item xs={7} sm={8} md={9} lg={12} sx={{ flexGrow: 1, padding: 4 }}>
            {totalSavingsAmount || isLoading ? (
              <StatsBody
                isLoading={isLoading}
                title={
                  <Title
                    id={firstLabelId}
                    tooltip={{
                      title: t('DASHBOARD.TOTAL_SAVINGS.TITLE'),
                      content: t('DASHBOARD.TOTAL_SAVINGS.DESCRIPTION'),
                    }}
                  >
                    {t('DASHBOARD.TOTAL_SAVINGS.TITLE')}
                  </Title>
                }
                value={
                  <Value aria-labelledby={firstLabelId}>
                    {formatCurrency(totalSavingsAmount ?? NaN, {
                      currency: stats?.totalSavings.currency ?? DEFAULT_CURRENCY,
                      maximumFractionDigits: 0,
                    })}
                  </Value>
                }
              />
            ) : (
              <Title>{t('DASHBOARD.TOTAL_SAVINGS.EMPTY')}</Title>
            )}
          </Grid>
          <Grid item xs={5} sm={4} md={3} lg={12} sx={{ alignSelf: 'flex-end' }}>
            <TransparentStatsBody
              isLoading={isLoading}
              title={<Title id={secondLabelId}>{t('DASHBOARD.AVERAGE_SAVINGS.TITLE')}</Title>}
              value={
                <Value aria-labelledby={secondLabelId}>
                  {showAverageSavings ? formatPercentage(averageSavingsPercentage / 100) : '-'}
                </Value>
              }
            />
          </Grid>
        </Grid>
      </FullHeightGradientStatsTile>
    </Card>
  );
};
