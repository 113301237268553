import { useLocaleContext } from '../../../contexts/LocaleContext';
import {
  getFormattedDurationBetweenDates,
  getFormattedDuration,
  type DurationFormatOptions,
} from '../utils/durationFormatters';

type LocalizedDurationFormatOptions = Omit<DurationFormatOptions, 'locale'>;

/**
 * Hook that returns two helpers for formatting durations.
 * Internally wraps {@link getFormattedDurationBetweenDates} and {@link getFormattedDuration}
 * to provide a locale from the app's context.
 *
 * @remarks
 * If you need more control over format units or zero values,
 * see the docs for:
 * - {@link getFormattedDurationBetweenDates}
 * - {@link getFormattedDuration}
 *
 * @example
 * ```tsx
 * import { useDurationFormatters } from '...';
 *
 * export function TaskAge(props: { start: Date; end: Date }) {
 *   const { formatDurationBetweenDates } = useDurationFormatters();
 *   return <div>{formatDurationBetweenDates({ start: props.start, end: props.end })}</div>;
 * }
 * ```
 *
 * @returns An object with two methods:
 *  - `formatDurationBetweenDates()`: see {@link getFormattedDurationBetweenDates}
 *  - `formatDuration()`: see {@link getFormattedDuration}
 */
export const useDurationFormatters = () => {
  const { dateFnsLocale } = useLocaleContext();

  const defaultDelimiter = ' ';

  return {
    /**
     * Formats the duration between two dates as a string.
     * see {@link getFormattedDurationBetweenDates}
     */
    formatDurationBetweenDates: ({
      delimiter = defaultDelimiter,
      ...args
    }: {
      end: Date;
      start: Date;
    } & LocalizedDurationFormatOptions) =>
      getFormattedDurationBetweenDates({ ...args, delimiter, locale: dateFnsLocale }),
    /**
     * Formats the duration as a string.
     * see {@link getFormattedDuration}
     */
    formatDuration: ({
      delimiter = defaultDelimiter,
      ...args
    }: {
      duration: Duration;
    } & LocalizedDurationFormatOptions) => getFormattedDuration({ ...args, delimiter, locale: dateFnsLocale }),
  };
};
