import React from 'react';
import { Stack, styled } from '@mui/material';
import { Button } from '@verticeone/design-system';
import EmptyStateNoData from '../EmptyStateNoData';
import { useTranslation } from 'react-i18next';
import { SvgIconComponent } from '@mui/icons-material';
import { EmptyState } from '../../types';

type EmptyStateContainerProps = {
  $fillContent: boolean;
};

const EmptyStateContainer = styled(Stack)<EmptyStateContainerProps>(({ $fillContent }) => ({
  zIndex: 1,
  position: 'absolute',
  width: '100%',
  borderRadius: 12,
  overflow: 'hidden',
  ...($fillContent
    ? {
        backdropFilter: 'blur(4px)',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }
    : { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }),
  alignItems: 'center',
  justifyContent: 'center',
}));

type EmptyStateSettings = {
  icon: SvgIconComponent;
  onClick?: () => void;
};

type ChartWrapperProps = EmptyState & {
  children: React.ReactNode;
  testId: string;
  noFilteredData: EmptyStateSettings;
  noData: EmptyStateSettings;
  tKeyPrefix: string;
};

const ChartWrapper = ({
  isEmpty,
  isFilteredEmpty,
  children,
  testId,
  noFilteredData,
  noData,
  tKeyPrefix,
}: ChartWrapperProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: tKeyPrefix });

  if (isFilteredEmpty || isEmpty) {
    return (
      <Stack position="relative" height={1} flex={1}>
        <EmptyStateContainer $fillContent={isEmpty}>
          {isFilteredEmpty && (
            <EmptyStateNoData
              testId={`${testId}-filtered-empty`}
              title={t('NO_FILTERED_DATA.TITLE')}
              description={t('NO_FILTERED_DATA.DESCRIPTION')}
              icon={noFilteredData.icon}
              button={
                noFilteredData.onClick && (
                  <Button variant="plain" color="neutral" size="S" onClick={noFilteredData.onClick}>
                    {t('NO_FILTERED_DATA.ACTION')}
                  </Button>
                )
              }
            />
          )}
          {isEmpty && (
            <EmptyStateNoData
              testId={`${testId}-empty`}
              title={t('NO_DATA.TITLE')}
              description={t('NO_DATA.DESCRIPTION')}
              icon={noData.icon}
              button={
                noData.onClick && (
                  <Button variant="solid" color="primary" size="S" onClick={noData.onClick}>
                    {t('NO_DATA.ACTION')}
                  </Button>
                )
              }
            />
          )}
        </EmptyStateContainer>
        {children}
      </Stack>
    );
  }

  return children;
};

export default ChartWrapper;
