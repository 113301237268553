import { Grid, Stack } from '@mui/material';
import { Button, Divider } from '@verticeone/design-system';
import { FC } from 'react';
import { DeepPartial, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { ContractDatesForm, contractDatesSchema } from './types';
import {
  FormRadioCardField,
  RadioCardDescription,
  RadioCardIcon,
  RadioCardTitle,
} from '../../../../../../forms/fields/FormRadioCardField/FormRadioCardField';
import { CalendarToday, EventRepeat } from '@mui/icons-material';
import { ContractReviewForm } from '../../types';
import { VendorPreview } from '../../../components/VendorPreview';
import { Vendor } from '../../../../../../vendor/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import FormDateField from '../../../../../../forms/fields/FormDateField';
import { match } from 'ts-pattern';
import { FixedTermFields } from './FixedTermFields';
import { RollingFields } from './RollingFields';

export type ContractDatesFormStepProps = {
  defaultValues?: DeepPartial<ContractDatesForm>;
  contractForm: DeepPartial<ContractReviewForm>;
  onPrevious: () => void;
  onSubmit: (values: ContractDatesForm) => Promise<void>;
};

const ContractDatesFormEntry = createTypedFormEntry<ContractDatesForm>();

export const ContractDatesFormStep: FC<ContractDatesFormStepProps> = (props) => {
  const { onSubmit, onPrevious, contractForm, defaultValues } = props;
  const { contractDetails } = contractForm;
  const { t } = useTranslation();

  const formMethods = useForm<ContractDatesForm>({
    defaultValues,
    resolver: zodResolver(contractDatesSchema),
  });

  const { handleSubmit, setValue, formState, control } = formMethods;

  const contractType = useWatch({ control, name: 'contractType' });

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flex="1 1 0" onSubmit={handleSubmit(onSubmit)}>
        <Stack p={6} flexGrow={1} gap={8} sx={{ overflow: 'auto' }}>
          {contractDetails?.vendor && <VendorPreview vendor={contractDetails.vendor as Vendor} />}

          <Stack direction="row" gap={2} maxHeight="min-content">
            <FormRadioCardField control={control} name="contractType" value="FIXED">
              <RadioCardIcon icon={CalendarToday} />
              <RadioCardTitle>{t('ENTITIES.CONTRACT.LABELS.FIXED')}</RadioCardTitle>
              <RadioCardDescription>{t('ENTITIES.CONTRACT.LABELS.FIXED_DESCRIPTION')}</RadioCardDescription>
            </FormRadioCardField>
            <FormRadioCardField control={control} name="contractType" value="ROLLING">
              <RadioCardIcon icon={EventRepeat} />
              <RadioCardTitle>{t('ENTITIES.CONTRACT.LABELS.ROLLING')}</RadioCardTitle>
              <RadioCardDescription>{t('ENTITIES.CONTRACT.LABELS.ROLLING_DESCRIPTION')}</RadioCardDescription>
            </FormRadioCardField>
            <FormRadioCardField control={control} name="contractType" value="ONE_OFF">
              <RadioCardIcon icon={CalendarToday} />
              <RadioCardTitle>{t('ENTITIES.CONTRACT.LABELS.ONE_OFF')}</RadioCardTitle>
              <RadioCardDescription>{t('ENTITIES.CONTRACT.LABELS.ONE_OFF_DESCRIPTION')}</RadioCardDescription>
            </FormRadioCardField>
          </Stack>

          <Grid container columnSpacing={4} rowGap={6}>
            <ContractDatesFormEntry
              name="signDate"
              label={t('ENTITIES.CONTRACT.LABELS.SIGN_DATE')}
              component={FormDateField}
            />
            <ContractDatesFormEntry
              name="startDate"
              label={t('ENTITIES.CONTRACT.LABELS.START_DATE')}
              required
              component={FormDateField}
            />
            {match(contractType)
              .with('FIXED', () => <FixedTermFields control={control} setValue={setValue} />)
              .with('ROLLING', () => <RollingFields />)
              .with('ONE_OFF', () => <></>)
              .exhaustive()}
          </Grid>
        </Stack>

        <Divider />

        <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
          <Button type="button" variant="plain" size="S" onClick={onPrevious}>
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
          </Button>

          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
