import { FC, useMemo } from 'react';
import { Stack } from '@mui/material';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../fields/FormSelectField2';
import FormEntry from '../../../fields/FormEntry';
import { FieldValues, useWatch } from 'react-hook-form';
import { DepartmentFieldType } from './types';
import { SimpleOption } from '@verticeone/design-system';
import { SingleValue } from 'react-select';
import { isNil, keyBy } from 'lodash';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../intelligentWorkflows/constants';
import { useAccountContext } from '../../../../account/AccountContext';
import { useGetXTypeDatasourceWithParamsQuery } from '@vertice/slices';
import { DynamicFormPresenterProps } from '../types';

export const DepartmentFieldPresenter: FC<DynamicFormPresenterProps<DepartmentFieldType>> = (props) => {
  const { field, config } = props;
  const { label, required, description } = field.metadata;
  const fieldValue = useWatch({ name: field.name });
  const disabled = config.mode === 'readOnly' || config.mode === 'preview';

  const { accountId } = useAccountContext();

  const departmentsQuery = useGetXTypeDatasourceWithParamsQuery({
    resourceUrn: 'urn:verticeone:vertice::services:schema/core/department/id/v1',
    accountId,
    body: { params: {} },
  });

  const options = useMemo<SimpleOption[]>(() => {
    if (departmentsQuery.isLoading || !departmentsQuery.data) return [];

    return departmentsQuery.data.items.map(({ id, title }) => ({
      value: id.toString(),
      label: title,
    }));
  }, [departmentsQuery]);

  const optionsMap = useMemo(() => keyBy(options, 'value'), [options]);

  return (
    <Stack py={3}>
      <FormEntry<FieldValues, FormSelectFieldComponentType<FieldValues, SimpleOption, false>>
        name={field.name}
        component={FormSelectField2}
        componentProps={{
          options,
          boxValue: (value: string | null) => (value ? { value, label: optionsMap[value]?.label } : null),
          unboxValue: (selectValue: SingleValue<SimpleOption>) => selectValue?.value,
          placeholder: disabled && isNil(fieldValue) ? '—' : undefined,
          color: INTELLIGENT_WORKFLOWS_BRAND_COLOR,
        }}
        description={description}
        label={label}
        required={required}
        disabled={disabled}
      />
    </Stack>
  );
};
