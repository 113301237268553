import { z, ZodObject, ZodRawShape } from 'zod';
import { LineItemsFieldMetadata, LineItemsFieldType } from './types';
import { GetPresenterDefaultProps } from '../types';
import { JsonSchema } from '../../../../intelligentWorkflows/workflowSchema/WorkflowViewer/utils';
import { t } from 'i18next';
import { lineItemSchema } from '../../../../saas/contract/components/LineItemsTable';

export const getLineItemsPresenterSchema = (field: LineItemsFieldType): ZodObject<ZodRawShape> => {
  const arraySchema = z
    .array(lineItemSchema, { message: t('DYNAMIC_FORM_BUILDER.FIELD.LINE_ITEMS.ADD_AT_LEAST_ONE_LINE_ITEM') })
    .min(1);
  return z.object({
    [field.name]: field.metadata.required ? arraySchema : z.union([arraySchema, z.null()]),
  });
};

export const getLineItemsPresenterDefault = ({
  field,
  defaultValues,
}: GetPresenterDefaultProps<LineItemsFieldType>) => {
  if (defaultValues && defaultValues[field.name]) {
    return { [field.name]: defaultValues[field.name] };
  }
  return { [field.name]: null };
};

export const getLineItemsJsonSchemaFromMetadata = (metadata: LineItemsFieldMetadata): JsonSchema => ({
  type: metadata.required ? 'array' : ['array', 'null'],
  'x-type': 'urn:verticeone:vertice::services:schema/core/line_items/v0',
});

export const getLineItemsJsonSchema = (field: LineItemsFieldType) => ({
  required: [field.name],
  properties: {
    [field.name]: getLineItemsJsonSchemaFromMetadata(field.metadata),
  },
});
