import React, { PropsWithChildren } from 'react';
import { Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, testProps } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/useRoutes';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { MissingSPBanner, MissingRIBanner, DataLoaderComponent } from '../../../../components';
import { Overview, Body, ActionBar, ButtonGroup, Title, GraphWrapper } from '../../components';
import DataSourceProvider from '../../../../../DataSourceProvider';
import { TotalGraph, EC2Graph, LambdaGraph, FargateGraph } from './Graph';
import Summary from './Summary';
import { Divider } from '@verticeone/design-system';

type GridItemProps = PropsWithChildren & {
  testId: string;
};

const GridItem = ({ children, testId }: GridItemProps) => (
  <Grid bgcolor="core.color2" item xs={4} pt={4} px={4} {...testProps(testId)}>
    {children}
  </Grid>
);

const Compute = () => {
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.RIO_NEW.OVERVIEW.WIDGETS.COMPUTE' });

  const toInventory = () => navigate(routes.CLOUD.RIO.SP.INVENTORY.DETAIL, { product: 'compute' });
  const toOptimize = () => navigate(routes.CLOUD.RIO.SP.OPTIMIZE.DETAIL, { product: 'compute' });

  return (
    <DataSourceProvider datasource="Compute">
      <Overview testId="compute-overview">
        <Stack p={6} pb={0} gap={6}>
          <Title />
          <MissingSPBanner />
          <MissingRIBanner />
        </Stack>
        <DataLoaderComponent>
          <Body>
            <GraphWrapper sx={{ px: 0 }}>
              <Stack px={4} {...testProps('total-graph')}>
                <TotalGraph />
              </Stack>
              <Divider />
              <Grid bgcolor="core.color3" container gap="1px" wrap="nowrap">
                <GridItem testId="ec2-graph">
                  <EC2Graph />
                </GridItem>
                <GridItem testId="lambda-graph">
                  <LambdaGraph />
                </GridItem>
                <GridItem testId="fargate-graph">
                  <FargateGraph />
                </GridItem>
              </Grid>
            </GraphWrapper>
            <Summary />
          </Body>
          <ActionBar>
            <ButtonGroup>
              <Button variant="ghost" size="S" color="neutral" onClick={toInventory}>
                {t('ACTION_BAR.INVENTORY')}
              </Button>
              <Button variant="ghost" size="S" color="tertiary" onClick={toOptimize}>
                {t('ACTION_BAR.OPTIMIZE')}
              </Button>
            </ButtonGroup>
          </ActionBar>
        </DataLoaderComponent>
      </Overview>
    </DataSourceProvider>
  );
};

export default Compute;
