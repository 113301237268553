import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { ContractListContext } from '../../../contexts/ContractListContext';
import { CustomContractFieldSpec, ViewOnContract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import VendorProductNameFilter from './components/VendorProductNameFilter';
import { DepartmentFilter } from './components/DepartmentFilter';
import { OwnerFilter } from './components/OwnerFilter';
import { RenewalStatusFilter } from './components/RenewalStatusFilter';
import { ThresholdFilter } from './components/ThresholdFilter';
import SigningEntityFilter from './components/SigningEntityFilter';
import RegionFilter from './components/RegionFilter';
import RollingFrequencyFilter from './components/RollingFrequencyFilter';
import BillingFrequencyFilter from './components/BillingFrequencyFilter';
import PaymentTermsFilter from './components/PaymentTermsFilter';
import ContractOriginFilter from './components/ContractOriginFilter';
import StageFilter from './components/StageFilter';
import { difference, keyBy, mapValues } from 'lodash';
import CustomFieldFilter from './components/CustomFieldFilter';
import { Filter } from './types';
import { CustomizeFilterButton } from './CustomizeFilterButton';
import useSavedFiltersConfiguration from './hooks/useSavedFiltersConfiguration';
import { Placeholder } from '@verticeone/design-system';
import { useCustomFieldFilters } from './hooks/useCustomFieldFilters';
import { isCustomFieldFilter } from './utils';
import ContractCategoryFilter from './components/ContractCategoryFilter';

export type CustomFieldFilterConfig = CustomContractFieldSpec & { filterName: Filter };

export type FilterPanelProps = {
  viewsOnContracts: ViewOnContract[];
  isLoading: boolean;
  customFiltersEnabled?: boolean;
};

export const FilterPanel = ({ viewsOnContracts, isLoading, customFiltersEnabled = false }: FilterPanelProps) => {
  const { availableFilters, activeFilters, setFiltersEnabled } = useContext(ContractListContext);
  const { isLoading: isLoadingFilterConfig } = useSavedFiltersConfiguration({ skip: !customFiltersEnabled });

  // Configuration of all enabled custom fields
  const customFieldFilters = useCustomFieldFilters({ skip: !customFiltersEnabled });
  const customFieldFilterConfigsByName = useMemo(() => keyBy(customFieldFilters, 'filterName'), [customFieldFilters]);

  useEffect(() => {
    const hasAnyContractAssignedOwner = viewsOnContracts.some((view) => view.computed?.owners?.length);
    setFiltersEnabled([Filter.OWNER], hasAnyContractAssignedOwner);
  }, [viewsOnContracts, setFiltersEnabled]);

  useEffect(() => {
    setFiltersEnabled(
      [
        Filter.SIGNING_ENTITY,
        Filter.REGION,
        Filter.ROLLING_FREQUENCY,
        Filter.BILLING_FREQUENCY,
        Filter.PAYMENT_TERMS,
        Filter.STAGE,
        Filter.CONTRACT_ORIGIN,
        Filter.CONTRACT_CATEGORY,
      ],
      customFiltersEnabled
    );
  }, [customFiltersEnabled, setFiltersEnabled]);

  useEffect(() => {
    // Custom field filters enabled in filter configuration
    const availableCustomFieldFilters =
      availableFilters?.map((filterName) => filterName).filter(isCustomFieldFilter) ?? [];
    // Custom field filters that should be enabled according to custom field configuration
    const selectableCustomFieldFilters = customFieldFilters?.map(({ filterName }) => filterName) ?? [];

    const filtersToEnable = difference(selectableCustomFieldFilters, availableCustomFieldFilters);
    const filtersToDisable = difference(availableCustomFieldFilters, selectableCustomFieldFilters);

    // Enable custom field filters that should be selectable
    if (filtersToEnable && filtersToEnable.length > 0) {
      setFiltersEnabled(filtersToEnable, customFiltersEnabled);
    }

    // Disable custom field filters that shouldn't be selectable
    if (filtersToDisable && filtersToDisable.length > 0) {
      setFiltersEnabled(filtersToDisable, false);
    }
  }, [customFiltersEnabled, setFiltersEnabled, customFieldFilters, availableFilters]);

  const filterComponentByName = useMemo(
    () => ({
      [Filter.VENDOR_PRODUCT]: <VendorProductNameFilter />,
      [Filter.RENEWAL_STATUS]: <RenewalStatusFilter />,
      [Filter.THRESHOLD]: <ThresholdFilter />,
      [Filter.DEPARTMENT]: <DepartmentFilter />,
      [Filter.OWNER]: <OwnerFilter views={viewsOnContracts} />,
      [Filter.SIGNING_ENTITY]: <SigningEntityFilter />,
      [Filter.REGION]: <RegionFilter />,
      [Filter.ROLLING_FREQUENCY]: <RollingFrequencyFilter />,
      [Filter.BILLING_FREQUENCY]: <BillingFrequencyFilter />,
      [Filter.PAYMENT_TERMS]: <PaymentTermsFilter />,
      [Filter.STAGE]: <StageFilter />,
      [Filter.CONTRACT_ORIGIN]: <ContractOriginFilter />,
      [Filter.CONTRACT_CATEGORY]: <ContractCategoryFilter />,
      ...mapValues(customFieldFilterConfigsByName, (filterConfig) => <CustomFieldFilter config={filterConfig} />),
    }),
    [viewsOnContracts, customFieldFilterConfigsByName]
  );

  return (
    <Stack direction="row" gap={2} flexWrap="wrap" alignItems="center">
      {isLoading || isLoadingFilterConfig ? (
        [1, 2, 3].map((i) => <Placeholder width={240} height={30} key={i}></Placeholder>)
      ) : (
        <>
          {activeFilters?.map((filter: Filter) => (
            <Box key={filter}>{filterComponentByName[filter as keyof typeof filterComponentByName]}</Box>
          ))}
          {customFiltersEnabled && <CustomizeFilterButton />}
        </>
      )}
    </Stack>
  );
};
