import { useMemo } from 'react';
import { ServiceTaskDetailDef } from './types';
import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { invokeServiceCompleteDef } from './InvokeService/utils';
import { getUrnLastPart } from './utils';
import { isVerticeServiceConfiguration } from '../../../pocWorkflowSchema';

const availableTaskDefs: ServiceTaskDetailDef<any>[] = [invokeServiceCompleteDef];

export const getTaskServiceUrn = (taskOverview?: TaskOverview) =>
  taskOverview?.task.configurations?.find(isVerticeServiceConfiguration)?.resourceUrn;

export const useServiceTaskDetailDef = (taskOverview?: TaskOverview): ServiceTaskDetailDef | undefined =>
  useMemo(() => {
    if (!taskOverview) {
      return undefined;
    }

    const serviceUrn = getUrnLastPart(getTaskServiceUrn(taskOverview) ?? '');

    return serviceUrn ? availableTaskDefs.find((def) => def.urnRegex.test(serviceUrn)) : undefined;
  }, [taskOverview]);
