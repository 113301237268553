import { Stack } from '@mui/material';
import { Button, Divider, Grid, SimpleOption } from '@verticeone/design-system';
import { FC } from 'react';
import { DeepPartial, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../../../constants';
import { ContractLineItemsForm, contractLineItemsSchema } from './types';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import { ContractReviewForm } from '../../types';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormCurrencyField } from '../../../../../../forms/fields/FormCurrencyField';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../../../../forms/fields/FormSelectField2';
import usePaymentTermsOptions from '../../../../../../saas/contract/hooks/usePaymentTermsOptions';
import { ContractFormData } from '../../../../../../saas/contract/types';
import { getRollingFrequency } from '../../utils/transformToOutput';
import { FormCostModelLineItemsField } from '../../../../../../forms/fields/FormCostModelLineItemsTableField';
import { OverrideCostsPanel } from './OverrideCostsPanel';

export type ContractLineItemsFormStepProps = {
  defaultValues?: DeepPartial<ContractLineItemsForm>;
  contractForm: DeepPartial<ContractReviewForm>;
  onSubmit: (values: ContractLineItemsForm) => Promise<void>;
  onPrevious: () => void;
};

const ContractLineItemsFormEntry = createTypedFormEntry<ContractLineItemsForm>();

export const ContractLineItemsFormStep: FC<ContractLineItemsFormStepProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onPrevious, contractForm, defaultValues } = props;
  const { contractDates } = contractForm;
  const rollingFrequency = contractDates ? getRollingFrequency(contractDates) : undefined;

  const { options: paymentTermsOptions, optionsMap: paymentTermsMap } = usePaymentTermsOptions();

  const formMethods = useForm<ContractLineItemsForm>({
    defaultValues,
    resolver: zodResolver(contractLineItemsSchema),
  });

  const { handleSubmit, formState, control, setValue } = formMethods;

  const currency = useWatch({ control, name: 'currency' });

  return (
    <FormProvider {...formMethods}>
      <Stack component="form" flexGrow={1} onSubmit={handleSubmit(onSubmit)}>
        <Stack p={6} flexGrow={1} gap={8} sx={{ overflow: 'auto' }}>
          <ContractLineItemsFormEntry
            name={'lineItems'}
            component={FormCostModelLineItemsField}
            componentProps={{ editable: true, currency, rollingFrequency }}
          />

          <Grid container columnSpacing={4} rowGap={6}>
            <ContractLineItemsFormEntry<FormSelectFieldComponentType<ContractFormData, SimpleOption, false>>
              name={'paymentTerms'}
              label={t('ENTITIES.CONTRACT.LABELS.PAYMENT_TERMS')}
              component={FormSelectField2}
              componentProps={{
                options: paymentTermsOptions,
                boxValue: (value) => (value ? paymentTermsMap[value] : undefined),
                unboxValue: (selectValue) => selectValue?.value,
              }}
            />

            <ContractLineItemsFormEntry
              name={'currency'}
              label={t('ENTITIES.CONTRACT.LABELS.CURRENCY')}
              component={FormCurrencyField}
              componentProps={{ isClearable: false }}
            />
          </Grid>

          <OverrideCostsPanel control={control} contractForm={contractForm} setValue={setValue} />
        </Stack>
        <Divider />
        <Stack direction="row" padding={6} gap={2} justifyContent="space-between">
          <Button type="button" variant="plain" size="S" onClick={onPrevious}>
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.GO_TO_PREVIOUS_PAGE')}
          </Button>

          <Button
            type="submit"
            variant="solid"
            size="S"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
            disabled={formState.isSubmitting}
            isLoading={formState.isSubmitting}
          >
            {t('INTELLIGENT_WORKFLOWS.TASK_MODAL.CONTINUE_TO_NEXT_PAGE')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
