import { Button, ButtonLabel } from '@verticeone/design-system';
import React from 'react';
import { ToggleButtonFieldProps, ToggleButtonOption } from '../FormToggleButtonField';
import { Stack } from '@mui/material';

export type OptionButtonProps = {
  option: ToggleButtonOption;
  fieldProps: ToggleButtonFieldProps;
  onChange: (...event: any[]) => void;
  value: string;
  maxTextWidth: number;
};

export const VerticalOptionButton = ({ option, fieldProps, onChange, value, maxTextWidth }: OptionButtonProps) => {
  const hasIcon = !!option.icon || !!option.element;

  return (
    <Button
      key={option.value}
      value={option.value}
      variant={value === option.value ? 'ghost' : 'outline'}
      color={option.color ?? fieldProps.color ?? 'primary'}
      isActive={!fieldProps.disabled || option.isActive || option.value === value}
      isLoading={option.isLoading}
      disabled={fieldProps.disabled}
      fullWidth
      onClick={() => {
        onChange(option.value);
        fieldProps.onClick?.(option.value);
      }}
      direction={fieldProps.compact ? 'horizontal' : 'vertical'}
      sx={{
        height: fieldProps.buttonHeight,
        marginTop: option.indentSize ?? 0,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
        {hasIcon && (
          <Stack direction="row" paddingLeft={4}>
            {option.icon && <option.icon />}
            {option.element}
          </Stack>
        )}
        <Stack direction="row" justifyContent={hasIcon ? 'left' : 'center'} gap={2} sx={{ minWidth: maxTextWidth }}>
          {option.title}
          {option.label && <ButtonLabel>{option.label}</ButtonLabel>}
        </Stack>
      </Stack>
    </Button>
  );
};
