import { useEffect } from 'react';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useCoreRoutes } from '../router/routes';

type RoutesType = ReturnType<typeof useCoreRoutes>;

const useNavigateIf = (condition: boolean, route: RoutesType[keyof RoutesType]) => {
  const { navigate } = useRouteNavigate();

  useEffect(() => {
    if (condition) {
      navigate(route);
    }
  }, [condition, navigate, route]);
};

export default useNavigateIf;
