import React, { FC, useContext } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { Select } from '@verticeone/design-system';
import { MultiValue } from 'react-select';
import { ContractListContext } from '../../../../contexts/ContractListContext';
import { Filter } from '../types';
import { useContractThresholdOptions, ThresholdFilterOption } from '../../../../hooks/useContractThresholdOptions';

export const ThresholdFilter: FC = () => {
  const { t } = useTranslation();
  const { filterValues, setFilterValue } = useContext(ContractListContext);
  const setLastContractValues = setFilterValue(Filter.THRESHOLD);
  const { options } = useContractThresholdOptions();

  const onChange = (selection: MultiValue<ThresholdFilterOption>) =>
    setLastContractValues(selection.length ? selection.map((option) => option.value) : undefined);

  return (
    <Stack minWidth={240}>
      <Select<ThresholdFilterOption, true>
        variant="solid"
        size="S"
        isMulti={true}
        maxMultiChips={1}
        isClearable={true}
        options={options}
        value={options?.filter(({ value }) => filterValues?.[Filter.THRESHOLD]?.includes(value))}
        placeholder={t('ENTITIES.FILTERS.PLACEHOLDERS.CONTRACT_VALUE')}
        onChange={onChange}
        testId={'threshold-filter'}
      />
    </Stack>
  );
};
