import React, { useEffect } from 'react';
import { ProductList, ProductListProps } from '../ProductList';
import { CostModelProductItem } from './types';
import { asCostModelProductItem, validateProductItem } from './utils';
import { useExtraColumns } from './useExtraColumns';
import { RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useTranslation } from 'react-i18next';

export type CostModelProductListProps = Omit<
  ProductListProps<CostModelProductItem>,
  'extraColumns' | 'getProductItem'
> & {
  currency?: string;
  rollingFrequency: RollFrequency;
};

export const CostModelProductList = ({ currency, rollingFrequency, ...otherProps }: CostModelProductListProps) => {
  const { t } = useTranslation();
  const { columns, columnVisibilityModel } = useExtraColumns({ currency, rollingFrequency });
  const { selectedProducts, setSelectedProducts } = otherProps;

  useEffect(() => {
    const isFixedTermContract = !rollingFrequency || rollingFrequency === 'NO';
    if (!isFixedTermContract && selectedProducts?.some((product) => !!product.allocationSpan)) {
      setSelectedProducts(selectedProducts?.map((product) => ({ ...product, allocationSpan: undefined })));
    }
  }, [selectedProducts, rollingFrequency, setSelectedProducts]);

  return (
    <ProductList<CostModelProductItem>
      extraColumns={columns}
      getProductItem={asCostModelProductItem(otherProps.vendorId)}
      validateProductItem={validateProductItem(t)}
      {...otherProps}
      dataGridExtraProps={{
        ...otherProps.dataGridExtraProps,
        columnVisibilityModel: columnVisibilityModel,
      }}
    />
  );
};
