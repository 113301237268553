import { verticeConnectAPICodegen } from '../openapi/codegen/verticeConnectAPI';

type GetConnectionApiArg = {
  accountId: string;
  integrationId: string;
  connectionId: string;
  pathPlus: string;
};

type GetConnectionApiResponse = {
  customParams: object | null;
  errorInfo: ErrorInfo | null;
  requestId: string;
  responsePointer: ResponsePointer | null;
  successful: boolean;
};

type AssessmentQuestionsApiArg = {
  accountId: string;
  integrationId: string;
  connectionId: string;
  pathPlus: string;
};

type AssessmentQuestionsApiResponse = {
  name: string;
  description: string;
}[];

type InvokeConnectionApiArg = {
  accountId: string;
  integrationId: string;
  connectionId: string;
  pathPlus: string;
  invokeConnectionRequest: InvokeConnection;
};

export type InvokeConnectionApiResponse = {
  description: string;
  eventTypes: string[];
  id: number;
  serviceType: string;
  webhookUrl: string;
};

type RemoveConnectionApiArg = {
  accountId: string;
  integrationId: string;
  connectionId: string;
  pathPlus: string;
};

export type RemoveConnectionApiResponse = null;

type ErrorInfo = {
  errorMessage: string;
  errorSource: string;
  errorType: string;
  originalError: string | null;
};

type ResponsePointer = {
  bucket: string;
  key: string;
};

type InvokeConnection = {
  serviceType: string;
  webhookUrl: string;
  description: string;
  eventTypes: string[];
};

export const enhancedVerticeConnectAPI = verticeConnectAPICodegen.injectEndpoints({
  endpoints: (build) => ({
    getConnection: build.query<GetConnectionApiResponse, GetConnectionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connections/${queryArg.connectionId}/invoke/${queryArg.pathPlus}`,
      }),
    }),
    invokeConnection: build.mutation<InvokeConnectionApiResponse, InvokeConnectionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connections/${queryArg.connectionId}/invoke/${queryArg.pathPlus}`,
        method: 'POST',
        headers: { 'x-vertice-return-response': 'DIRECT' },
        body: queryArg.invokeConnectionRequest,
      }),
    }),
    removeConnection: build.mutation<RemoveConnectionApiResponse, RemoveConnectionApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connections/${queryArg.connectionId}/invoke/${queryArg.pathPlus}`,
        method: 'DELETE',
        headers: { 'x-vertice-return-response': 'DIRECT' },
      }),
    }),
    assessmentQuestions: build.query<AssessmentQuestionsApiResponse, AssessmentQuestionsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/integrations/${queryArg.integrationId}/connections/${queryArg.connectionId}/invoke/${queryArg.pathPlus}`,
        headers: { 'x-vertice-return-response': 'DIRECT' },
      }),
    }),
  }),
});

export const {
  useGetConnectionQuery,
  useInvokeConnectionMutation,
  useRemoveConnectionMutation,
  useAssessmentQuestionsQuery,
} = enhancedVerticeConnectAPI;
