import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractDatesForm } from './types';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import { SimpleOption } from '@verticeone/design-system';
import FormSelectField2, { FormSelectFieldComponentType } from '../../../../../../forms/fields/FormSelectField2';
import useBillingFrequencyOptions from '../../../../../../saas/contract/hooks/useBillingFrequencyOptions';
import useRollingFrequencyOptions from '../../../../../../saas/contract/hooks/useRollingFrequencyOptions';
import { isRollingRollFrequency } from '../../../../../../saas/contract/computed';

const ContractDatesFormEntry = createTypedFormEntry<ContractDatesForm>();

export const RollingFields: FC = () => {
  const { t } = useTranslation();

  const { options: billingFrequencyOpts, optionsMap: billingFrequencyMap } = useBillingFrequencyOptions();

  const { options: rollFrequencyOptsRaw, optionsMap: rollFrequencyMap } = useRollingFrequencyOptions({
    isOneOffIncluded: false,
  });

  const rollFrequencyOptions = useMemo(
    () => rollFrequencyOptsRaw.filter((option) => isRollingRollFrequency(option.value)),
    [rollFrequencyOptsRaw]
  );

  return (
    <>
      <ContractDatesFormEntry<FormSelectFieldComponentType<ContractDatesForm, SimpleOption, false>>
        name="rollingFrequency"
        required={true}
        label={t('ENTITIES.CONTRACT.LABELS.ROLLING_FREQUENCY')}
        component={FormSelectField2}
        componentProps={{
          options: rollFrequencyOptions,
          boxValue: (value) => (value ? rollFrequencyMap[value as string] : undefined),
          unboxValue: (selectValue) => selectValue?.value,
        }}
      />
      <ContractDatesFormEntry<FormSelectFieldComponentType<ContractDatesForm, SimpleOption, false>>
        name="billingFrequency"
        label={t('ENTITIES.CONTRACT.LABELS.BILLING_FREQUENCY')}
        component={FormSelectField2}
        componentProps={{
          options: billingFrequencyOpts,
          boxValue: (value) => (value ? billingFrequencyMap[value as string] : undefined),
          unboxValue: (selectValue) => selectValue?.value,
        }}
      />
    </>
  );
};
