import React from 'react';
import { Stack, StackProps } from '@mui/material';

type IntegrationSetupContainerProps = Pick<StackProps, 'children' | 'maxWidth'>;

const IntegrationSetupContainer = ({ children, maxWidth = '900px' }: IntegrationSetupContainerProps) => (
  <Stack direction="row" justifyContent="center" p={6} borderTop="1px solid" borderColor="core.color3">
    <Stack width="100%" maxWidth={maxWidth} margin="auto">
      {children}
    </Stack>
  </Stack>
);

export default IntegrationSetupContainer;
