import { Stack, useTheme } from '@mui/material';
import { Text, SelectColor, Placeholder } from '@verticeone/design-system';
import { CommonFormFieldProps } from '../../types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { FormOverrideButtonField } from '../FormOverrideButtonField';
import { FieldPath, useWatch } from 'react-hook-form';
import { useLocaleContext } from '../../../../contexts/LocaleContext';
import { formatCurrency, getCurrencySymbol } from '@verticeone/utils/formatting';
import FormDecimalNumberField from '../FormDecimalNumberField';
import { isNil } from 'lodash';
import { DEFAULT_CURRENCY } from '../../../../constants/currency';
import { useTranslation } from 'react-i18next';

export type FormOverridableCostFieldType<FormDataType extends FieldValues> = (
  props: FormOverridableCostFieldProps<FormDataType>
) => JSX.Element;

type FormOverridableCostFieldProps<FormDataType extends FieldValues> = CommonFormFieldProps<FormDataType> & {
  currency?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  color?: SelectColor;
  totalContractValue?: number | null;
  isLoading?: boolean;
  overrideName?: FieldPath<FormDataType>;
  changeImmediately?: boolean;
};

/**
 * Composition of fields for cost and its override. Unless overrideName param is specified it expects the name param
 * to be the parent path of both fields with 'amount' and 'enabled' sub-paths.
 * @param overrideName If specified it is used for override while name param is used for cost value.
 */
export const FormOverridableCostField = <FormDataType extends FieldValues = never>({
  id,
  required,
  disabled,
  name,
  overrideName,
  currency = DEFAULT_CURRENCY,
  placeholder,
  error,
  helperText,
  color,
  totalContractValue,
  isLoading,
  changeImmediately = false,
}: FormOverridableCostFieldProps<FormDataType>) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { locale } = useLocaleContext();

  const nameCost = overrideName ? name : `${name}.amount`;
  const nameOverride = overrideName ? overrideName : `${name}.enabled`;

  const overrideEnabled = useWatch({ name: nameOverride });

  const formattedTCV =
    isNil(totalContractValue) || isNil(currency) ? null : formatCurrency(totalContractValue, { currency, locale });

  if (isLoading) {
    return (
      <Stack direction="row" gap={2}>
        <Placeholder height={32} width={200} />
        <Placeholder height={32} width={80} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack gap={2} flexGrow={1}>
        <FormDecimalNumberField<any>
          id={`${id}AmountEdit`}
          name={nameCost}
          disabled={!overrideEnabled || disabled}
          color={color}
          sx={{ flex: 1 }}
          placeholder={placeholder}
          error={error}
          required={required}
          helperText={helperText}
          unit={currency ? getCurrencySymbol(locale, currency) : ''}
          unitPosition="start"
          changeImmediately={changeImmediately}
        />
        {formattedTCV && (
          <Text variant="button-regular" size="XS" color={palette.inactive.color2}>
            {t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formattedTCV })}
          </Text>
        )}
      </Stack>
      <FormOverrideButtonField<any> id={`${id}Override`} name={nameOverride} disabled={disabled} />
    </Stack>
  );
};
