import React, { useId, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Text, TextFieldCaption } from '@verticeone/design-system';
import { Stack, styled } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useLocation } from 'react-router-dom';
import { keyBy } from 'lodash';
import { useNegotiationTierOptions } from '../../../saas/contract/hooks/useNegotiationTierOptions';
import { useRouteNavigate } from '@verticeone/router/useRouteNavigate';
import { useRoutes } from '@verticeone/router/useRoutes';

type ConfirmationProps = {
  contract: Contract;
};

const StyledText = styled(Text)({
  wordBreak: 'break-word',

  '& pre': {
    whiteSpace: 'pre-wrap',
  },
});

const ToBeNegotiatedByConfirmation = ({ contract }: ConfirmationProps) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const fromLocation = useLocation();
  const fieldId = useId();

  const contractId = contract?.record?.contractId;
  const negotiationTier = contract.classification?.negotiationTier;
  const negotiationTierOptions = useNegotiationTierOptions();
  const negotiationTierOptionByValue = useMemo(() => keyBy(negotiationTierOptions, 'value'), [negotiationTierOptions]);

  const redirectToContractEdit = () => {
    navigate(
      routes.CONTRACTS.DETAIL.EDIT,
      {
        contractId: contractId,
      },
      { state: { fromLocation } }
    );
  };

  return (
    <Stack gap={6}>
      <StyledText variant="body-regular" size="M">
        <Trans
          i18nKey={'SAAS.TO_BE_NEGOTIATED_BY_CONFIRMATION.MESSAGE'}
          components={{ br: <br />, bold: <Text variant="body-bold" size="M" /> }}
        />
      </StyledText>
      <Stack>
        <TextFieldCaption id={fieldId} label={t('ENTITIES.CONTRACT.LABELS.NEGOTIATION_TIER')} size="XS" />
        <Stack direction="row" spacing={2} alignItems="center">
          <StyledText size="M" aria-labelledby={fieldId} variant="body-regular">
            {negotiationTier ? negotiationTierOptionByValue[negotiationTier]?.label : '-'}
          </StyledText>
          <Button variant="ghost" color={'neutral'} onClick={redirectToContractEdit}>
            <Stack direction="row" gap={1} alignItems="center">
              <EditOutlined sx={{ opacity: 0.6 }} />
              {t('SAAS.TO_BE_NEGOTIATED_BY_CONFIRMATION.BUTTONS.EDIT')}
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ToBeNegotiatedByConfirmation;
