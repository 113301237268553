import { useEffect, useRef } from 'react';
import ContractFormEntry, { ContractFormEntryProps } from '../../ContractFormEntry';
import FormReadOnlyText, { FormReadOnlyTextType } from '@vertice/core/src/modules/forms/fields/FormReadOnlyText';
import FormSelectField2, {
  FormSelectFieldComponentType,
} from '@vertice/core/src/modules/forms/fields/FormSelectField2';
import { ContractFormData } from '../../types';
import useRollingFrequencyOptions from '../../hooks/useRollingFrequencyOptions';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';
import { DEFAULT_CONTRACT_CATEGORY, isOneOffSupportedInCategory } from '../../computed';
import { ContractEntityCategory, RollFrequency } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type RollFrequencyFormEntryProps = Omit<ContractFormEntryProps<any, any>, 'component' | 'writeComponent'>;

type RollFrequencySelectOption = { value: string; label: string };

/**
 * When the contract category changes e.g. to SAAS and rollFrequency is set to an incompatible value (e.g. ONE_OFF),
 * the rollFrequency gets automatically changed to a valid value. (e.g. NO = fixed contract)
 */
const useAutoChangeToValidValue = ({
  rollFrequencyFieldName,
  contractCategory,
}: {
  rollFrequencyFieldName: FieldPath<ContractFormData>;
  contractCategory: ContractEntityCategory;
}) => {
  const { control, setValue } = useFormContext<ContractFormData>();
  const rollFrequency: RollFrequency | undefined = useWatch({ control, name: rollFrequencyFieldName });

  const prevContractCategoryRef = useRef<ContractEntityCategory>();
  useEffect(() => {
    if (contractCategory !== prevContractCategoryRef.current) {
      prevContractCategoryRef.current = contractCategory;
      if (rollFrequency === 'ONE_OFF' && !isOneOffSupportedInCategory(contractCategory)) {
        setValue(rollFrequencyFieldName, 'NO' satisfies RollFrequency);
      }
    }
  }, [contractCategory, rollFrequencyFieldName, rollFrequency, setValue]);
};

const RollFrequencyFormEntry = (props: RollFrequencyFormEntryProps) => {
  const { control } = useFormContext<ContractFormData>();
  const contractCategory = useWatch({ control, name: 'classification.category' }) ?? DEFAULT_CONTRACT_CATEGORY;

  const { options: rollFrequencyOptions, optionsMap: rollFrequencyMap } = useRollingFrequencyOptions({
    isOneOffIncluded: isOneOffSupportedInCategory(contractCategory),
  });

  useAutoChangeToValidValue({ rollFrequencyFieldName: props.name, contractCategory });

  return (
    <ContractFormEntry<
      FormReadOnlyTextType<ContractFormData>,
      FormSelectFieldComponentType<ContractFormData, RollFrequencySelectOption, false>
    >
      {...props}
      component={FormReadOnlyText}
      componentProps={{
        formatter: (value?: string | null) => (value ? rollFrequencyMap[value]?.label : undefined),
        ...props.componentProps,
      }}
      writeComponent={FormSelectField2}
      writeComponentProps={{
        ...props.writeComponentProps,
        options: rollFrequencyOptions,
        boxValue: (value) => (value ? rollFrequencyMap[value as string] : undefined),
        unboxValue: (selectValue) => selectValue?.value,
      }}
    />
  );
};

export default RollFrequencyFormEntry;
