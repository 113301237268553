import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormTextField from '@vertice/core/src/modules/forms/fields/FormTextField';
import { Grid } from '@mui/material';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { EmailField } from '@vertice/core/src/components/EmailField';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { PurchaseRequirements } from '../../SharedComponents/PurchaseRequirements';
import { PurchasingManagerPermissions } from '../../SharedComponents/PurchasingManagerPermissions';
import SelectedContractPanel from '../../SharedComponents/SelectedContractPanel';
import { SharedRenewalFormData, SharedRenewalFormEntry } from '../types';
import { useCreateRenewalRequest } from './useCreateRenewalRequest';
import { getCurrencySymbol } from '@verticeone/utils/formatting';

const useAdditionalRequirementsWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToRenewalInProgress: () => wizard.goToStep('renewal_in_progress'),
  };
};

const AdditionalRequirementsStep: React.FC<WizardStepProps> = () => {
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const { goBack, goToRenewalInProgress } = useAdditionalRequirementsWizard();
  const { locale } = useLocaleContext();
  const { t } = useTranslation();

  const { trigger, watch } = useFormContext<SharedRenewalFormData>();
  const currency = watch('contractCurrency');

  const [createRequest, isCreatingRequest] = useCreateRenewalRequest(goToRenewalInProgress);

  const handleSubmit = useCallback(() => {
    createRequest();
  }, [createRequest]);

  const validationFields = watch(['approvedBudget', 'signatoryEmail', 'vendorContactEmail']);

  useEffect(() => {
    void trigger(['approvedBudget', 'signatoryEmail', 'vendorContactEmail']).then(setNextButtonEnabled);
  }, [trigger, validationFields]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_ADDITIONAL_REQ.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_ADDITIONAL_REQ.HEADING_SUBTITLE')}
      />
      <SelectedContractPanel />
      <InnerScrollable>
        <FormSection>
          <Grid item xs={12}>
            <PurchaseRequirements />
          </Grid>
          <SharedRenewalFormEntry
            name="approvedBudget"
            label={t('ENTITIES.CONTRACT.LABELS.APPROVED_BUDGET')}
            component={FormNumberField}
            componentProps={{
              minValue: 0,
              unit: getCurrencySymbol(locale, currency || ''),
              unitPosition: 'start',
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.APPROVED_BUDGET'),
            }}
            width={12}
          />
          <SharedRenewalFormEntry
            name="signatoryName"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_NAME'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_NAME'),
            }}
            component={FormTextField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_NAME') }}
          />
          <SharedRenewalFormEntry
            name="signatoryEmail"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNATORY_EMAIL'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNATORY_EMAIL'),
            }}
            component={EmailField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNATORY_EMAIL') }}
          />
          <SharedRenewalFormEntry
            name="signingEntity"
            label={t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY')}
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.SIGNING_ENTITY'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.SIGNING_ENTITY'),
            }}
            component={FormTextField}
            width={12}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.SIGNING_ENTITY') }}
          />
          <SharedRenewalFormEntry
            name="vendorContactName"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_NAME')}
            component={FormTextField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR_CONTACT_NAME') }}
          />
          <SharedRenewalFormEntry
            name="vendorContactEmail"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR_CONTACT_EMAIL')}
            component={EmailField}
            componentProps={{ placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR_CONTACT_EMAIL') }}
          />
          <Grid item xs={12}>
            <PurchasingManagerPermissions />
          </Grid>
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: handleSubmit,
          isLoading: isCreatingRequest,
          disabled: !nextButtonEnabled,
          title: t('DIALOG.BUTTONS.NEXT'),
        }}
      />
    </Content>
  );
};

export default AdditionalRequirementsStep;
