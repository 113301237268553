import { DynamicFormField } from '../../../types';
import { FC, useCallback, useMemo } from 'react';
import { fieldDefsByType } from '../../fields';
import { ActiveEditingField } from '../../../../../intelligentWorkflows/workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm/CustomFormFieldDialog';
import { customAlphabet } from 'nanoid';
import {
  PropertyNameBuilderPlugin,
  usePropertyBuilderPluginDefinition,
} from '../../../../../intelligentWorkflows/workflowSchema/WorkflowEditor/forms/EditUserTaskForm/CustomForm/plugins/PropertyName';
import { PrimitiveDynamicFormField } from '../../../primitiveTypes';

const alphabet = 'abcdefghijklmnopgrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; // only upper and lower case
const generateUniqueFieldName = customAlphabet(alphabet, 16);

export type CustomPropertyFieldDialogProps = {
  activeField: ActiveEditingField | null;
  field?: PrimitiveDynamicFormField;
  usedFieldNames: string[];
  onClose: () => void;
  onSubmit: (values: DynamicFormField) => void;
};

export const CustomPropertyFieldDialog: FC<CustomPropertyFieldDialogProps> = (props) => {
  const { activeField, field, onClose, onSubmit, usedFieldNames } = props;

  const propertyBuilderPluginDefinition = usePropertyBuilderPluginDefinition(usedFieldNames);

  const FieldEditDialog = useMemo(() => {
    if (!activeField) return null;
    return fieldDefsByType[activeField.fieldType].builderDialog;
  }, [activeField]);

  const plugins = useMemo<PropertyNameBuilderPlugin[] | null>(() => {
    if (!activeField) return null;
    return [propertyBuilderPluginDefinition.create(field?.name)];
  }, [propertyBuilderPluginDefinition, activeField, field]);

  const handleSubmit = useCallback(
    (values: any) => {
      if (!activeField) return;

      onSubmit({
        ...(field ?? { type: activeField.fieldType }),
        name: propertyBuilderPluginDefinition.overrideName ?? generateUniqueFieldName(),
        metadata: values,
      });
    },
    [activeField, field, onSubmit, propertyBuilderPluginDefinition]
  );

  if (!FieldEditDialog || !plugins) return <></>;

  return (
    <FieldEditDialog
      onClose={onClose}
      onSubmit={handleSubmit}
      defaultValues={field?.metadata ?? activeField?.defaultMetadata}
      plugins={plugins}
    />
  );
};
