import { Trans, useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { VerticeVIcon } from '@vertice/assets';

import { Text } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';
import { AppBadge, MessageWrapper } from './ConnectionStep';
import { MessageButton, VerticalLine } from './SlackPreviewCommon';
import React from 'react';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';

export const SlackPreviewRequestPurchase = () => {
  const { t } = useTranslation();

  const { locale } = useLocaleContext();
  const today = new Date();
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <MessageWrapper>
      <IconWrapper icon={VerticeVIcon} />
      <Stack flex={1} gap={4}>
        <Box>
          <Stack direction="row" gap={1} alignItems="center">
            <Text variant="body-bold" size="XS" color="text1">
              {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_VERTICE')}
            </Text>
            <AppBadge>
              <Text variant="body-regular" size="XXS" color="text2">
                {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_APP')}
              </Text>
            </AppBadge>
            <Text variant="body-regular" size="XS" color="text2">
              {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_HEAD_TIME')}
            </Text>
          </Stack>
          <Text variant="body-bold" size="XS" color="text2">
            {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.HEAD_TITLE')}
          </Text>
        </Box>
        <Stack direction="row" alignItems="stretch">
          <VerticalLine />
          <Stack gap={1}>
            <Text variant={'body-regular'} size="XS" color="text2">
              <Trans
                i18nKey="INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.VENDOR"
                components={{
                  bold: <Text variant="body-bold" size="XS" color="text2" />,
                }}
              />
            </Text>
            <Text variant={'body-regular'} size="XS" color="text2">
              <Trans
                i18nKey="INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.TYPE"
                components={{
                  bold: <Text variant="body-bold" size="XS" color="text2" />,
                }}
              />
            </Text>
            <Text variant={'body-regular'} size="XS" color="text2">
              <Trans
                i18nKey="INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.SUBMITTED_BY"
                components={{
                  bold: <Text variant="body-bold" size="XS" color="text2" />,
                  mention: <Text variant="body-bold" size="XS" color="secondary1" />,
                }}
              />
            </Text>
            <Text variant={'body-regular'} size="XS" color="text2">
              <Trans
                i18nKey="INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.CREATED_AT"
                components={{
                  bold: <Text variant="body-bold" size="XS" color="text2" />,
                }}
                tOptions={{ today: formatter.format(today) }}
              />
            </Text>
            <Text variant={'body-regular'} size="XS" color="text2">
              <Trans
                i18nKey="INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_REQUEST_PURCHASE.DESCRIPTION"
                components={{
                  bold: <Text variant="body-bold" size="XS" color="text2" />,
                }}
              />
            </Text>
          </Stack>
        </Stack>

        <MessageButton>
          <Text variant="body-bold" size="XS" color="text2">
            {t('INTEGRATIONS.SLACK.INTEGRATION_SETUP.MESSAGE_RENEWAL_VIEW_CONTRACT')}
          </Text>
        </MessageButton>
      </Stack>
    </MessageWrapper>
  );
};
