import { FieldDef } from '../types';
import { RepeatingFieldType } from './types';
import { RepeatingFieldBuilderCard } from './RepeatingFieldBuilderCard';
import { RepeatingFieldBuilderDialog } from './RepeatingFieldBuilderDialog';
import { RepeatingFieldPresenter } from './RepeatingFieldPresenter';
import {
  getRepeatingJsonSchema,
  getRepeatingJsonSchemaFromMetadata,
  getRepeatingPresenterDefault,
  getRepeatingPresenterSchema,
} from './utils';

export type { RepeatingFieldType, RepeatingFieldMetadata } from './types';
export { repeatingFieldSchema, repeatingFieldMetadataSchema } from './types';

export const fieldDef: FieldDef<RepeatingFieldType> = {
  type: 'REPEATING',
  typeNameI18nKey: 'DYNAMIC_FORM_BUILDER.FIELD.REPEATING.NAME',
  builderCard: RepeatingFieldBuilderCard,
  builderDialog: RepeatingFieldBuilderDialog,
  presenter: RepeatingFieldPresenter,
  getPresenterDefault: getRepeatingPresenterDefault,
  getPresenterSchema: getRepeatingPresenterSchema,
  getJsonSchemaFromMetadata: getRepeatingJsonSchemaFromMetadata,
  getJsonSchema: getRepeatingJsonSchema,
};
