import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DoneIcon from '@mui/icons-material/Done';
import { ROUTES } from '@vertice/dashboard/src/router/oldConstants';
import { useGetSsoDetailsQuery } from '@vertice/slices';
import { Loader } from '@vertice/components';
import StepsIndicator from './StepsIndicator';

import styles from './SamlIntegrationCreateApp.module.scss';
import commonStyles from './SamlIntegration.module.scss';
import { useAccountContext } from '@vertice/core/src/modules/account/AccountContext';

const SamlIntegrationCreateApp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [callbackCopied, setCallbackCopied] = useState(false);
  const [configCopied, setConfigCopied] = useState(false);
  const toStartIntegrationStep = () => navigate(ROUTES.SAML);
  const toUploadConfigStep = () => navigate(`${ROUTES.SAML}/${ROUTES.SAML_UPLOAD_CONFIG}`);

  const copyToClipboard = (text?: string, setCallback?: (value: boolean) => void) => {
    if (!text || !setCallback) {
      return;
    }

    navigator.clipboard.writeText(text).then(
      () => {
        setCallback(true);
        setTimeout(() => setCallback(false), 2000);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
      }
    );
  };

  const { accountId } = useAccountContext();
  const {
    refetch: refetchSsoDetails,
    data: ssoDetails,
    isFetching: isSsoDetailsFetching,
    isUninitialized: isSsoDetailsUninitialized,
  } = useGetSsoDetailsQuery({ accountId: accountId! }, { skip: !accountId });

  const [waitForRefetch, setWaitForRefetch] = useState(false);
  useEffect(() => {
    if (!waitForRefetch && ssoDetails && !isSsoDetailsFetching && !ssoDetails?.properties?.saml?.callbackUrl) {
      setWaitForRefetch(true);
      setTimeout(() => {
        if (!isSsoDetailsUninitialized) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          refetchSsoDetails();
        }
        setWaitForRefetch(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsoDetailsFetching, refetchSsoDetails, ssoDetails, isSsoDetailsUninitialized]);

  const isWaitForConfig = !ssoDetails?.properties?.saml?.callbackUrl || !ssoDetails?.properties?.saml?.configuration;
  const toPrettyJson = (json?: string) => (json ? JSON.stringify(JSON.parse(json), null, 2) : '');

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="heading-s">{t('SAML.CREATE_APP.TITLE')}</Typography>
      </div>

      <div className={commonStyles['inner-body']}>
        <div className={commonStyles.description}>
          <Typography variant="body-regular-m">{t('SAML.CREATE_APP.BODY')}</Typography>
        </div>

        <div className={styles['callback-url']}>
          <div className={styles.subtitle}>{t('SAML.CREATE_APP.APPLICATION_CALLBACK_URL')}</div>

          <div className={styles['copy-to-clipboard']}>
            <div className={styles['copy-to-clipboard-top']}>
              <div className={styles['copy-to-clipboard-text']}>
                {isWaitForConfig ? (
                  <Loader size={16} />
                ) : (
                  <Typography variant="code-s">{ssoDetails?.properties?.saml?.callbackUrl || ''}</Typography>
                )}
              </div>
              <div
                className={styles['copy-to-clipboard-icon']}
                onClick={() => copyToClipboard(ssoDetails?.properties?.saml?.callbackUrl, setCallbackCopied)}
              >
                {!callbackCopied && <CopyAllIcon />}
                {callbackCopied && <DoneIcon />}
              </div>
            </div>
          </div>

          <div className={styles['copy-to-clipboard']}>
            <div className={styles['copy-to-clipboard-top']}>
              <div className={styles['copy-to-clipboard-title']}>{t('SAML.CREATE_APP.SAML_CONFIGURATION')}</div>
              <div
                className={clsx(styles['copy-to-clipboard-icon'], styles['copy-to-clipboard-icon-titled'])}
                onClick={() => copyToClipboard(ssoDetails?.properties?.saml?.configuration, setConfigCopied)}
              >
                {!configCopied && <CopyAllIcon />}
                {configCopied && <DoneIcon />}
              </div>
            </div>

            <div className={styles['copy-to-clipboard-code']}>
              <pre>
                {isWaitForConfig ? (
                  <Loader size={16} />
                ) : (
                  <Typography variant="code-s">{toPrettyJson(ssoDetails?.properties?.saml?.configuration)}</Typography>
                )}
              </pre>
            </div>
          </div>
        </div>

        <div className={commonStyles['nav-buttons']}>
          <Button onClick={toStartIntegrationStep} className={commonStyles['nav-button']} variant="outlined">
            {t('SAML.BACK')}
          </Button>
          <Button
            onClick={toUploadConfigStep}
            className={commonStyles['nav-button']}
            variant="contained"
            disabled={isWaitForConfig}
          >
            {isWaitForConfig ? <Loader size={16} /> : t('SAML.NEXT')}
          </Button>
        </div>
      </div>

      <div className={commonStyles.steps}>
        <StepsIndicator total={5} current={2} />
      </div>
    </div>
  );
};

export default SamlIntegrationCreateApp;
